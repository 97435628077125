/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import axiosRequest from '../../api';
import { get, isEmpty, omit, assignIn, filter } from 'lodash';
import * as petDetailsAction from '../constants';
import {
  bridgeToCarrierLoader,
  errorHandler,
  petBreedsDetailsLoader,
  petQuotesLoader,
  setHomeState,
  setPetBreedsDetails,
  setPetQuotes,
  storeErrorsForBridgeToCarrier,
  storePetDetails,
  storePetListData,
} from '../actions';
import {
  getSeperateQuoteList,
  removeExistingRate,
  clearLobQuotes,
  createPageTracking,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
} from '../../../utils';
import { INSURANCE_BUSINESS_TYPES, INSURANCE_PRODUCTS } from '../../../constants';

function* fetchPetBreedsDetail(): any {
  try {
    yield put(setPetBreedsDetails({ cat: {}, dog: {}, isBreedError: false }));
    yield put(petBreedsDetailsLoader(true));
    const {
      applicant: { address },
      common: { pconfig },
      home,
      partner: { isProviderOneLogin },
      petDetails,
    } = yield select();
    yield put(
      storePetDetails({
        quoteStatus: '',
      })
    );
    let stateName = address && address.state ? address.state : home?.address?.state;
    const { code } = home?.pconfig?.dale_config;
    let orgCode = !isEmpty(code) ? code : !isEmpty(pconfig?.dale_config?.code) ? pconfig?.dale_config?.code : '';
    let responseData = yield axiosRequest('POST', `/pets/breeds`, isProviderOneLogin, {
      state: stateName,
      herdId: petDetails?.herdId,
      organizationCode: orgCode
    });
    if (!!responseData?.data) {
      const { data, success, error_details, upcomingCarrierList } = responseData?.data;
      if (success && data) {
        data.isBreedError = false;
        yield put(setPetBreedsDetails(data));
      } else if (!success) {
        trackFirstLoadQuoteInHeap();
        let errorQuotes = filter(Object.values(error_details), error => {
          return assignIn(error, { quoteError: true });
        });
        yield put(setPetBreedsDetails({ cat: {}, dog: {}, isBreedError: true }));
        yield put(setPetQuotes({ quoteList: errorQuotes, herdId: petDetails?.herdId }));
        if (sessionStorage.selectedQuoteList) {
          const selectedQuotes = removeExistingRate(
            JSON.parse(sessionStorage.selectedQuoteList),
            INSURANCE_BUSINESS_TYPES.PET
          );
          sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
        }
        trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[4]);
      }
      yield put(
        storePetDetails({
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        })
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    trackFirstLoadQuoteInHeap();
    if(!!axiosError?.response?.data?.errorDetails) {
      const { errorDetails } = axiosError?.response?.data;
      const { errorQuotes } = getSeperateQuoteList(errorDetails, {});
      yield put(setPetQuotes({ quoteList: [...errorQuotes] }));
    } else {
      yield put(
        setHomeState({
          showError: true,
        } as any)
      );
      yield put(errorHandler(error));
    }
    trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[4]);
  }
  yield put(petBreedsDetailsLoader(false));
}

export function* fetchPetDetailsWatcher() {
  yield takeEvery(petDetailsAction.FETCH_PET_BREEDS_DETAILS, fetchPetBreedsDetail);
}

function* fetchPetQuotes({ payload }: any): any {
  try {
    yield put(petQuotesLoader(true));
    const {
      applicant,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails, appointments },
      petDetails,
      common,
      home,
    } = yield select();
    trackFirstLoadQuoteInHeap()
    const keys = Object.keys(appointments);
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
      ? producerDetails?.producerEmail
        : '';
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } = clearLobQuotes();
    let pageTracking = createPageTracking(!clearAutoInfo ? common?.pageTracking : {}, appointments);
    const details = yield axiosRequest('POST', '/pets/quotes', isProviderOneLogin, {
      pageTracking,
      lobQuotesToClear,
      clearPetInfo,
      clearAutoInfo,
      clearLifeInfo,
      clearUmbrellaInfo,
      personal_information: { ...omit(applicant?.personal_details, 'preferredDeductible') },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: petDetails?.herdId || '',
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
      lob: INSURANCE_PRODUCTS[4],
      pet_details: payload,
    });
    if (details?.data) {
      let { upcomingCarrierList, quotes, herdId, error_details } = details?.data;
      let errorQuotes: any = {};
      if (error_details) {
        errorQuotes = filter(Object.values(error_details), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      quotes = !!quotes ? quotes : {};
      yield put(
        setPetQuotes({
          quoteList: [...Object.values(quotes), ...Object.values(errorQuotes)],
          herdId,
        })
      );
      yield put(
        storePetDetails({
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        })
      );
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[4]);
    }
  } catch (error) {
    trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[4]);
    yield put(errorHandler(error));
  }
  yield put(petQuotesLoader(false));
}

export function* fetchPetQuotesWatcher() {
  yield takeEvery(petDetailsAction.FETCH_PET_QUOTES, fetchPetQuotes);
}

function* updateBreedsDetails({ payload }: any) {
  try {
    yield put(storePetListData(payload));
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(petBreedsDetailsLoader(false));
}

export function* updateBreedsDetailsWatcher() {
  yield takeEvery(petDetailsAction.UPDATE_BREEDS_DETAILS, updateBreedsDetails);
}

function* getBridgeToCarrierDetailsForPet({ payload }: any): any {
  let loaderData = {
    loader: true,
    carrier_id: payload?.carrierId,
  };
  try {
    yield put(bridgeToCarrierLoader(loaderData));
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest('POST', '/pets/bridge-carrier', isProviderOneLogin, {
      herdId: payload?.herdId ?? '',
      plan: payload?.plan ?? '',
    });
    if (!!responseDetails?.data) {
      if (!!responseDetails?.data?.paymentLink) {
        window.open(responseDetails?.data?.paymentLink, '_blank');
      } else {
        yield put(storeErrorsForBridgeToCarrier(responseDetails?.data));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (axiosError?.response?.status === 404) {
      yield put(storeErrorsForBridgeToCarrier(axiosError?.response?.data));
    }
    yield put(errorHandler(axiosError));
  }
  loaderData.loader = false;
  yield put(bridgeToCarrierLoader(loaderData));
}

export function* getBridgeToCarrierDetailsForPetWatcher() {
  yield takeEvery(
    petDetailsAction.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_PET,
    getBridgeToCarrierDetailsForPet
  );
}

export default function* rootSaga() {
  yield all([
    fork(fetchPetQuotesWatcher),
    fork(fetchPetDetailsWatcher),
    fork(updateBreedsDetailsWatcher),
    fork(getBridgeToCarrierDetailsForPetWatcher),
  ]);
}
