/* istanbul ignore file */
import * as bulkQuoteType from '../constants';

export const bulkQuoteInit = (): AppRedux.Action => ({
  type: bulkQuoteType.BULK_QUOTE_INIT,
});

export const restoreBulkQuoteDetails = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.RESTORE_BULK_QUOTE_DETAILS,
  payload,
});

export const openBulkQuoteModal = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.OPEN_MODAL,
  payload,
});

export const updateStep = (payload: number): AppRedux.Action => ({
  type: bulkQuoteType.UPDATE_STEP,
  payload,
});

export const setOrganizationDetails = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.SET_ORGANIZATION_DETAILS,
  payload,
});

export const setSelectedCarrierList = (payload: Array<any>) => ({
  type: bulkQuoteType.SET_SELECTED_CARRIER_LIST,
  payload,
});

export const submitBulkQuoteStep = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.SUBMIT_BULK_QUOTE_STEP,
  payload,
});

export const setSelectorLoader = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.SELECTOR_LOADER,
  payload,
});

export const setServerError = (payload: string): AppRedux.Action => ({
  type: bulkQuoteType.SET_SERVER_ERROR,
  payload,
});

export const uploadSelectedFile = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.UPLOAD_SELECTED_FILE,
  payload,
});

export const setStepsCompleted = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.STEPS_COMPLETED,
  payload,
});

export const resetStepDetails = () => ({
  type: bulkQuoteType.RESET_STEP_DETAILS,
});

export const setBulkQuoteTableLoader = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.SET_BULK_QUOTE_TABLE_LOADER,
  payload,
});

export const setBulkQuoteList = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.SET_BULK_QUOTE_LIST,
  payload,
});

export const terminateRecord = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.TERMINATE_RECORD,
  payload,
});

export const setTerminatedRecord = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.SET_TERMINATED_RECORD,
  payload,
});

export const setPageLoader = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.SET_PAGE_LOADER,
  payload,
});

export const showTerminatedMessage = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.SHOW_TERMINATED_MESSAGE,
  payload,
});

export const setTerminateSuccess = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.SET_TERMINATE_SUCCESS,
  payload,
});

export const setTerminateMessage = (payload: string): AppRedux.Action => ({
  type: bulkQuoteType.SET_TERMINATE_MESSAGE,
  payload,
});

export const downloadFile = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.DOWNLOAD_FILE,
  payload,
});

export const processRecord = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.PROCESS_RECORD,
  payload,
});

export const setActionLoader = (payload: boolean): AppRedux.Action => ({
  type: bulkQuoteType.SET_ACTION_LOADER,
  payload,
});

export const updatePartnerConfig = (payload: any): AppRedux.Action => ({
  type: bulkQuoteType.UPDATE_PARTNER_CONFIG,
  payload,
});

export const setServerMessage = (payload: string): AppRedux.Action => ({
  type: bulkQuoteType.SET_SERVER_MESSAGE,
  payload,
});
