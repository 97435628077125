import React from 'react';
import HighlightOff from '@material-ui/icons/HighlightOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import TextField from '@material-ui/core/TextField';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import config from '../../../config/config';
import { getStartCase } from '../../../utils';
import AutoCompleteService from './service';
import { AUTOCOMPLETE, NONE } from '../../../constants';
import './auto-complete.scss';

const styles = (theme: Theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginLeft: '8px',
    marginRight: '8px',
    minWidth: '100%',
  },
  textField: {
    width: '100%',
    marginLeft: '8px',
    marginRight: '8px',
  },
});

class AutoComplete extends React.Component<
  AppComponents.AutoCompleteProps & WithStyles<typeof styles>,
  AppComponents.AutoCompleteState
> {
  static propTypes = {
    value: PropTypes.string,
    error: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onAutoCompleteAddressSelected: PropTypes.func.isRequired,
    clearAutoCompleteInput: PropTypes.func.isRequired,
    onInputBlur: PropTypes.func.isRequired,
    title: PropTypes.string,
  };
  state: AppComponents.AutoCompleteState = {
    scriptLoaded: false,
    street: '',
    enableAutoComplete: true,
    scriptError: false,
  };
  autoCompleteService: any = null;

  UNSAFE_componentWillReceiveProps(nextProps: AppComponents.AutoCompleteProps) {
    if (nextProps.value !== this.state.street) {
      this.setState({ street: nextProps.value });
    }
  }

  handlePlaceChanged = (address: AppComponents.ApplicantAddress) => {
    this.setState({ street: address.street });
    this.props.onAutoCompleteAddressSelected(address);
  };

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  onStreetChange = (event: any) => {
    event.target.setAttribute(AUTOCOMPLETE, NONE?.toLowerCase());
    this.setState({ street: event.target.value });
    this.props.onChange(event);
    if (this.state.scriptLoaded) {
      if (event.target && !this.autoCompleteService) {
        // Initialize google autocomplete service
        this.autoCompleteService = new AutoCompleteService({
          input: event.target,
          onPlaceChanged: this.handlePlaceChanged,
        });
      }
    }
  };

  clearStreetData = () => {
    this.autoCompleteService && this.autoCompleteService.clearAddress();
    this.setState({ street: '' });
    this.props.clearAutoCompleteInput();
  };

  // On field blur auto capitalize state value
  onBlur = (e: any) => {
    this.setState({ street: getStartCase(e.target.value.toLowerCase()) });
    this.props.onInputBlur(e);
  };

  render() {
    const { street, enableAutoComplete } = this.state;
    const { classes, value, error, title, disabled } = this.props;
    return (
      <React.Fragment>
        <form autoComplete={NONE?.toLowerCase()}>
          <div className='autocomplete-address-wrapper '>
            {enableAutoComplete && (
              <Script
                url={`https://maps.googleapis.com/maps/api/js?key=${config.googleAutoCompleteKey}&libraries=places`}
                onCreate={this.handleScriptCreate.bind(this)}
                onError={this.handleScriptError.bind(this)}
                onLoad={this.handleScriptLoad.bind(this)}
              />
            )}
            <TextField
              type='text'
              name='street'
              id='street'
              label={title}
              value={value || street}
              onChange={this.onStreetChange}
              onBlur={this.onBlur}
              className={classes.textField}
              error={error}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={disabled}
              InputProps={
                {
                  spellCheck: false,
                  placeholder: '',
                  inputProps: {
                    maxLength: 100,
                    autocomplete: NONE?.toLowerCase()
                  },
                  endAdornment: (
                    <React.Fragment>
                      <InputAdornment position='end'>
                        {!isEmpty(street) && !disabled && (
                          <HighlightOff
                            className='clear-data-icon'
                            onClick={this.clearStreetData}
                          ></HighlightOff>
                        )}
                      </InputAdornment>
                    </React.Fragment>
                  ),
                } as any
              }
            />
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)<any>(AutoComplete);
