import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import ContentFooter from '../../components/content-footer';
import { fetchPconfig } from '../../redux/actions';
import history from '../../../history';
import ApplicantForm from '../../components/applicant-form';

import './applicant.scss';

class HomeApplicant extends PureComponent<
  AppComponents.HomeApplicantProps,
  AppComponents.HomeApplicantStore
> {

  render() {
    return (
      <div className='wrapper mheight'>
        <ConnectedRouter history={history}>
          <ApplicantForm />
          <ContentFooter />
        </ConnectedRouter>
      </div>
    );
  }
}

const mapStateToProps = ({ home }: AppComponents.HomeApplicantStore) => ({ home });

const mapDispatchToProps = (dispatchProp: any): AppComponents.HomeApplicantDispatch => bindActionCreators({ fetchPconfig }, dispatchProp);

export default connect(mapStateToProps, mapDispatchToProps)(HomeApplicant);
