import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import React from 'react';
import './radio-group-field.scss';

export interface RadioGroupOption<T = string> {
  value: T;
  label: string;
}

interface RadioGroupFieldProps extends RadioGroupProps {
  options: RadioGroupOption[];
  label?: string;
  disabled?: boolean;
}

const RadioGroupField: React.FC<RadioGroupFieldProps> = props => {
  const { name, value, options, defaultValue, onChange, label, disabled } = props;

  const onRadioFieldChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (onChange && typeof onChange === 'function') {
      onChange(event, value);
    }
  };

  return (
    <FormControl component='fieldset' className='radio-group-field-wrapper'>
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <RadioGroup name={name} value={value || defaultValue || ''} onChange={onRadioFieldChange} row>
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            label={option.label}
            control={<Radio color='primary' disabled={!!disabled} />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupField;
