import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import './license-modal.scss';
import { LICENSE_MESSAGE } from '../../../constants';

const LicenseModal = (props: AppComponents.LicenseModalProps) => {
  const [openModal, setOpenModal] = React.useState(false);
  const modalClose = (isDiscard: boolean) => {
    setOpenModal(false);
    props.closehandler(isDiscard);
  };

  useEffect(() => {
    setOpenModal(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        className='license-modal'
        classes={{
          root: 'auto-dialog-container',
        }}
        open={openModal}
        onClose={() => {
          modalClose(false);
        }}
        aria-labelledby='confirmation-dialog-title'
      >
        <DialogTitle className='text-center' id='confirmation-dialog-title'>
          License Status
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{LICENSE_MESSAGE}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              modalClose(false);
            }}
            className='cancel-btn-modal'
            color='primary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

LicenseModal.propTypes = {
  open: PropTypes.bool,
  closehandler: PropTypes.func.isRequired,
};

export default LicenseModal;
