/* istanbul ignore file */
import map from 'lodash/map';
import remove from 'lodash/remove';
import { v4 } from 'uuid';

import * as types from '../constants';

interface IncidentBaseState {
  [key: string]: any;
}

interface AccidentState extends IncidentBaseState {
  accident_uid?: string;
  date_of_accidents: string;
  accident_description: string;
  vehicle_involved: string;
  pd_amount: string;
  bi_amount: string;
  collision_amount: string;
  mp_amount: string;
}

interface ViolationState extends IncidentBaseState {
  violation_uid?: string;
  date_of_violations: string;
  violations_description: string;
}

interface LossState extends IncidentBaseState {
  loss_uid?: string;
  date_of_loss: string;
  loss_description: string;
  loss_vehicle_involved: string;
  loss_amount: string;
}

const INIT_ACCIDENTS_STATE: AccidentState = {
  date_of_accidents: '',
  accident_description: '',
  vehicle_involved: '1',
  pd_amount: '',
  bi_amount: '',
  collision_amount: '',
  mp_amount: '',
};

const INIT_VIOLATIONS_STATE: ViolationState = {
  date_of_violations: '',
  violations_description: '',
};

const INIT_LOSSES_STATE: LossState = {
  date_of_loss: '',
  loss_description: '',
  loss_vehicle_involved: '1',
  loss_amount: '',
};

const INIT_OTHERDETAILS_STATE = {
  accidentErrors: null,
  violationErrors: null,
  lossErrors: null,
  accidentList: [],
  violationList: [],
  lossList: [],
};

export default (state = INIT_OTHERDETAILS_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        accidentErrors: null,
        violationErrors: null,
        lossErrors: null,
      };
    }

    case types.INIT_ACCIDENTS: {
      return {
        ...state,
        accidentList: [...state.accidentList, { accident_uid: v4(), ...INIT_ACCIDENTS_STATE }],
      };
    }

    case types.INIT_VIOLATIONS: {
      return {
        ...state,
        violationList: [...state.violationList, { violation_uid: v4(), ...INIT_VIOLATIONS_STATE }],
      };
    }

    case types.INIT_LOSSES: {
      return {
        ...state,
        lossList: [...state.lossList, { loss_uid: v4(), ...INIT_LOSSES_STATE }],
      };
    }

    // Set selected driver lists
    case types.SET_OTHER_DETAILS: {
      const { accidentList, violationList, lossList } = action.payload;
      return {
        ...INIT_OTHERDETAILS_STATE,
        accidentList,
        violationList,
        lossList,
      };
    }

    case types.ACCIDENT_FIELD_UPDATED: {
      const { accidentList } = state;
      const { name, value, uid } = action.payload;
      const updatedList = map(accidentList, (accident: AccidentState) => {
        if (accident.accident_uid === uid) {
          accident[name] = value;
        }
        return accident;
      });
      return {
        ...state,
        accidentList: [...updatedList],
      };
    }

    case types.ACCIDENT_FIELD_DELETED: {
      const { accidentList } = state;
      const { uid } = action.payload;
      // Mutates the accidents array directly
      remove(accidentList, (accident: AccidentState) => accident.accident_uid === uid);
      return {
        ...state,
        accidentList: [...accidentList],
      };
    }

    case types.VIOLATION_FIELD_UPDATED: {
      const { violationList } = state;
      const { name, value, uid } = action.payload;
      const updatedViolationList = map(violationList, (violation: ViolationState) => {
        if (violation.violation_uid === uid) {
          violation[name] = value;
        }
        return violation;
      });
      return {
        ...state,
        violationList: [...updatedViolationList],
      };
    }

    case types.VIOLATION_FIELD_DELETED: {
      const { violationList } = state;
      const { uid } = action.payload;
      // Mutates the violations array directly
      remove(violationList, (violation: ViolationState) => violation.violation_uid === uid);
      return {
        ...state,
        violationList: [...violationList],
      };
    }

    case types.LOSS_FIELD_UPDATED: {
      const { lossList } = state;
      const { name, value, uid } = action.payload;
      const updatedLossList = map(lossList, (loss: LossState) => {
        if (loss.loss_uid === uid) {
          loss[name] = value;
        }
        return loss;
      });

      return {
        ...state,
        lossList: [...updatedLossList],
      };
    }

    case types.LOSS_FIELD_DELETED: {
      const { lossList } = state;
      const { uid } = action.payload;
      // Mutates the losses array directly
      remove(lossList, (loss: LossState) => loss.loss_uid === uid);
      return {
        ...state,
        lossList: [...lossList],
      };
    }

    case types.SET_OTHER_ERRORS: {
      const { entity, data } = action.payload;
      return {
        ...state,
        [entity]: data,
      };
    }

    case types.CLEAR_ACCIDENT_LIST: {
      return {
        ...state,
        accidentList: [],
      };
    }

    case types.CLEAR_VIOLATION_LIST: {
      return {
        ...state,
        violationList: [],
      };
    }

    case types.CLEAR_LOSS_LIST: {
      return {
        ...state,
        lossList: [],
      };
    }

    default:
      return state;
  }
};
