/* istanbul ignore file */
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as types from '../constants';
import { getQuoteListWithBindDetails, getUpdatedQuoteList, getUniqueQuotes } from '../../../utils';
import { UpcomingCarriersListType } from './cyber-security';

export interface QuoteState {
  quotesLoading: boolean;
  quotingDetails: any;
  quoteList: Array<any>;
  quoteErrorList: Array<any>;
  tempErrorQuoteList: Array<any>;
  quotingCarriers: Array<any>;
  ezlynxError: string;
  pollingDetails: any;
  quotesLoaded: boolean;
  herdId: string;
  quoteCompleted: boolean;
  quoteLobDetails?: any;
  bridgeToCarrierLoader: boolean;
  viewQuoteLoader: boolean;
  isQuoteRefresh: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
  quoteStatus?: string;
}

const INIT_STATE: QuoteState = {
  quotesLoading: false,
  quotingDetails: null,
  quoteList: [],
  quoteErrorList: [],
  tempErrorQuoteList: [],
  quotingCarriers: [],
  ezlynxError: '',
  pollingDetails: null,
  quotesLoaded: false,
  herdId: '',
  quoteCompleted: false,
  bridgeToCarrierLoader: false,
  viewQuoteLoader: false,
  isQuoteRefresh: false,
  upcomingCarrierList: [],
};

export default (state = INIT_STATE, action: AppRedux.Action): QuoteState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        herdId: get(state, 'herdId', ''),
        quotesLoading: false,
      };
    }

    case types.CLEAR_AUTO_QUOTE_DETAILS: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.UPDATE_QUOTE_DETAILS: {
      return {
        ...state,
        quotingDetails: {
          ...action.payload,
        },
        herdId: get(action, 'payload.herd_id', '') || get(action, 'payload.herdId', ''),
      };
    }

    case types.CLEAR_POLLING_DETAILS: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.SET_ERROR_LIST: {
      return {
        ...state,
        quoteErrorList: action.payload,
      };
    }

    case types.QUOTES_LOADING: {
      return {
        ...state,
        quotesLoading: action.payload,
      };
    }

    case types.SET_POLLING_DETAILS: {
      const { quotes, error_details } = action.payload;
      let oldQuotesList = [...state.quoteList];
      let tempErrorQuoteList = [...state.quoteErrorList];
      if (!isEmpty(action.payload.quotes)) {
        oldQuotesList = getUniqueQuotes(quotes, oldQuotesList);
      }
      if (error_details && error_details.display_errors_on_ui) {
        tempErrorQuoteList = filter(error_details.errors, error => error);
      }
      return {
        ...state,
        quoteList: [...oldQuotesList],
        tempErrorQuoteList,
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_POLLING_ERROR_DETAILS: {
      const oldQuoteErrorList = getUpdatedQuoteList(state, action.payload);
      return {
        ...state,
        tempErrorQuoteList: [...oldQuoteErrorList],
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.UPDATE_EZLYNX_ERROR: {
      const oldQuoteErrorList = getUpdatedQuoteList(state, action.payload);
      return {
        ...state,
        quoteErrorList: [...oldQuoteErrorList],
        quoteCompleted: true,
      };
    }

    case types.FLUSH_QUOTE_DETAILS: {
      return {
        ...INIT_STATE,
        herdId: get(state, 'herdId', ''),
        quoteCompleted: state.quoteCompleted,
        quoteLobDetails: state.quoteLobDetails,
      };
    }

    case types.POLLING_COMPLETED:
    case types.SET_QUOTE_COMPLETED: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_QUOTES_LOADED: {
      return {
        ...state,
        ...(!action.payload
          ? {
              ...INIT_STATE,
              herdId: get(state, 'herdId', ''),
              quoteCompleted: state.quoteCompleted,
            }
          : {}),
        quotesLoaded: action.payload,
      };
    }

    case types.SET_AUTO_QUOTE_BIND: {
      return {
        ...state,
        quoteList: getQuoteListWithBindDetails(state.quoteList, action.payload),
      };
    }

    case types.GET_QUOTE_LOB_DETAILS: {
      return {
        ...state,
      };
    }

    case types.SET_QUOTE_LOB_DETAILS: {
      return {
        ...state,
        quoteLobDetails: action.payload,
      };
    }

    case types.SET_AUTO_QUOTE_DETAILS: {
      const { quotes, errors } = action.payload;
      return {
        ...state,
        quoteList: quotes,
        quoteErrorList: errors,
      };
    }

    case types.CLEAR_QUOTE_LOB_DETAILS: {
      return {
        ...state,
        quoteLobDetails: null,
      };
    }

    case types.CLEAR_AUTO_DATA_ON_APPLICANT_CHANGES: {
      return {
        ...INIT_STATE,
        herdId: get(state, 'herdId', ''),
      };
    }

    case types.BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let autoQuoteList = [...state.quoteList];
      const updateIndex = autoQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        autoQuoteList[updateIndex] = {
          ...autoQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: autoQuoteList,
      };
    }

    case types.SET_VIEW_QUOTE_LOADER: {
      return {
        ...state,
        viewQuoteLoader: action.payload,
      };
    }

    case types.SET_AUTO_DOWNLOAD_QUOTE_LOADER: {
      const { carrier_id, loader } = action.payload;
      let autoQuoteList = [...state.quoteList];
      const updateIndex = autoQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        autoQuoteList[updateIndex] = {
          ...autoQuoteList[updateIndex],
          downloadQuoteLoader: loader,
        };
      }
      return {
        ...state,
        quoteList: autoQuoteList,
      };
    }

    default:
      return state;
  }
};
