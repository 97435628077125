import React, { Component } from 'react';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import find from 'lodash/find';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';

import config from '../../../config/config';
import { deductiblesMap, formatCurrency } from '../../home/utils';
import { formatNumberToCurrency } from '../../../utils';
import PolicyBoundRibbon from '../../../assets/images/policy-bound-ribbon.png';
import { NA_VALUE, POLICY_TERMS, TABLE_COVERAGES } from '../../../constants';
import QuoteError from '../quote-error';

class QuoteItem extends Component<AppComponents.QuoteItemStoreProps, {}> {
  static propTypes = {
    quote: PropTypes.object.isRequired,
    minPriceQuote: PropTypes.object,
    headerData: PropTypes.object,
    boundQuote: PropTypes.array,
    floodQuote: PropTypes.bool,
    onFloodPremiumSelect: PropTypes.func,
    selectedFloodQuote: PropTypes.object,
  };

  compareDetails = (deductibles: any) => {
    const { headerData } = this.props;
    let exceptionList: any = [];
    let otherExceptions: any = [];
    !isEmpty(deductibles) &&
      forEach(deductibles, (quoteDeductibleValue, type) => {
        if (quoteDeductibleValue > 0) {
          otherExceptions = [
            ...otherExceptions,
            ...this.compareWithOtherDeductible(
              headerData,
              { type, value: quoteDeductibleValue },
              parseFloat(deductibles.Other) > 0 ? deductibles.Other : 0
            ),
          ];
          if (otherExceptions.length > 0) {
            exceptionList = [...otherExceptions];
          }
        }
      });
    return exceptionList;
  };

  getOtherDeductible = (
    headerDeductibles: any,
    mapSimilarField: any,
    currentDeductible: any,
    compareAllPeril: any
  ) => {
    const exceptions = [];
    if (
      Object.prototype.hasOwnProperty.call(headerDeductibles, mapSimilarField) &&
      parseFloat(headerDeductibles[mapSimilarField]) > 0
    ) {
      if (parseFloat(headerDeductibles[mapSimilarField]) !== parseFloat(currentDeductible.value)) {
        exceptions.push(this.getExceptionMapping(currentDeductible));
      }
    } else {
      if (parseFloat(compareAllPeril) !== parseFloat(currentDeductible.value)) {
        exceptions.push(this.getExceptionMapping(currentDeductible));
      }
    }
    return exceptions;
  };

  compareWithOtherDeductible = (headerData: any, currentDeductible: any, currentAllPeril: any) => {
    let otherExceptions = [];
    const headerDeductibles = headerData.deductibles;
    let compareAllPeril = headerDeductibles.Other;
    const similarDeductible: any = { WindHail: 'Wind' };
    /**
     * if all peril for carrier is different from header all peril
     * then we will compare all peril of carrier itself.
     */
    if (currentAllPeril > 0 && currentAllPeril !== parseFloat(headerDeductibles.Other)) {
      compareAllPeril = currentAllPeril;
    }
    if (!TABLE_COVERAGES.includes(currentDeductible.type)) {
      if (Object.prototype.hasOwnProperty.call(similarDeductible, currentDeductible.type)) {
        otherExceptions = [
          ...this.getOtherDeductible(
            headerDeductibles,
            similarDeductible[currentDeductible.type],
            currentDeductible,
            compareAllPeril
          ),
        ];
      } else {
        if (parseFloat(compareAllPeril) !== parseFloat(currentDeductible.value)) {
          otherExceptions.push(this.getExceptionMapping(currentDeductible));
        }
      }
    } else {
      otherExceptions = [
        ...this.getOtherDeductible(
          headerDeductibles,
          currentDeductible.type,
          currentDeductible,
          compareAllPeril
        ),
      ];
    }
    return otherExceptions;
  };

  getExceptionMapping = ({ type, value }: any) => {
    let _exception = {};
    const deductibleMapping: AppComponents.DeductibleMapData = deductiblesMap;
    if (get(deductibleMapping, type, '')) {
      _exception = { label: deductibleMapping[type].details.label, value };
    } else {
      // In case no mapping found show exception directly
      _exception = { label: type, value };
    }
    return _exception;
  };

  getExceptions = (quote: any) => {
    const { deductibles } = quote;
    let exceptionList: any = [];
    let _html: any = '';
    if (!isEmpty(deductibles)) {
      if (this.compareDetails(deductibles).length > 0) {
        exceptionList = [...this.compareDetails(deductibles)];
      }
    } else {
      if (!isEmpty(quote.coverages)) {
        if (
          Object.prototype.hasOwnProperty.call(quote.coverages, 'deductibles') &&
          this.compareDetails(quote.coverages.deductibles).length > 0
        ) {
          exceptionList = [...this.compareDetails(quote.coverages.deductibles)];
        }
      }
    }
    _html = map(exceptionList, (exception, i) => (
      <div key={i}>
        <span>{`${exception.label} ${formatNumberToCurrency(exception.value)}`}</span>
      </div>
    ));
    return { exceptionList, html: _html };
  };

  // Show less/more handler
  handleExceptionOpen = (e: any) => {
    const savedTarget = e.currentTarget;
    if (savedTarget.classList.contains('bg-blue' && 'expand')) {
      savedTarget.classList.remove('expand');
    } else {
      savedTarget.classList.add('expand');
    }
  };

  checkIfHippoCarrier = (quote: any, minPriceQuote: any) => (
      (quote.carrier_name || quote.name) === (minPriceQuote.carrier_name || minPriceQuote.name) &&
      ((quote.carrier_name || quote.name) === config.hippo.quote_name || quote.show_lowest_price)
    );

  bindHandler = (quote: any) => {
    const { enabled } = quote;
    if (enabled) {
      window.open(!isEmpty(quote.link) ? quote.link : '', '_blank');
    } else {
      if (has(quote, 'agent_url')) {
        window.open(get(quote, 'agent_url', ''), '_blank');
      } else {
        window.open(get(quote, 'quote_agent_url', ''), '_blank');
      }
    }
  };

  activateBindAction = (quote: any): boolean => {
    if (!quote.enabled) {
      if (!get(quote, 'quote_agent_url', '') && !get(quote, 'agent_url', '')) {
        return true;
      }
      return false;
    } else {
      if (!get(quote, 'link', '')) {
        return true;
      }
      return false;
    }
  };

  getBindRibbon = (quote: any) =>
    get(quote, 'policyBounded', false) ? (
      <img
        src={PolicyBoundRibbon}
        alt='policy bind'
        className={`img-fluid policy-bound-ribbon-home ${
          quote.floodQuote ? 'flood-bound-ribbon' : ''
        }`}
      />
    ) : (
      ''
    );

  getExceptionContainer = (quote: any) =>
    !isEmpty(this.getExceptions(quote).exceptionList) ? (
      <div className='ml-4'>
        <div className='bg-blue-div'>
          {this.getExceptions(quote).exceptionList.length > 1 ? (
            <div onClick={this.handleExceptionOpen} className='bg-blue exception-hand'>
              {this.getExceptions(quote).exceptionList.length > 1 && (
                <div>
                  <span className='expand-icon' />
                </div>
              )}
              <div className='exceptions-container'>{this.getExceptions(quote).html}</div>
            </div>
          ) : (
            <div className='bg-blue'>
              <div className='exceptions-container'>{this.getExceptions(quote).html}</div>
            </div>
          )}
        </div>
      </div>
    ) : (
      ''
    );

  getQuoteErrorContainer = (quote: any, quoteError: boolean) =>
    quoteError ? (
      <React.Fragment>
        <QuoteError {...{ quote }} />
      </React.Fragment>
    ) : (
      ''
    );

  getFloodQuoteBindButton = (details: any, quote: any, classList: string) => (
    <button
      className={classList}
      disabled={!has(details, 'link')}
      onClick={() => {
        window.open(get(details, 'link', ''), '_blank');
      }}
    >
      {quote.purchase_type.toUpperCase()}
    </button>
  );

  getNormalPremiumContainer = (quote: any) => (
    <React.Fragment>
      <p className='coverage-label'>Price</p>
      <div className='card-font'>
        <div className='price-font'>{formatNumberToCurrency(quote.price || quote.premium)}</div>
      </div>
      <p className='coverage-label'>{POLICY_TERMS[0].label}</p>
    </React.Fragment>
  );

  getTermMappings = (termValue: string) => {
    const term: any = find(POLICY_TERMS, { value: termValue });
    return term ? term.shortLabel : POLICY_TERMS[0].shortLabel;
  };

  getFloodPremium = (details: any) => {
    const premiumValue = details.premium;
    return premiumValue ? (
      <React.Fragment>
        <span className='premium-value'>{formatNumberToCurrency(details.premium)}</span>
        <span className='flood-premium-term'>/{this.getTermMappings(details.term)}</span>
      </React.Fragment>
    ) : (
      <span className='na-value'>{NA_VALUE}</span>
    );
  };

  onFloodQuotePriceClick = (details: any, quote: any) => {
    if (has(details, 'premium')) {
      this.props.onFloodPremiumSelect(details, quote);
    }
  };

  isFloodPremiumSelected = (quote: any, premium: any) => {
    if (has(this.props.selectedFloodQuote, quote.name)) {
      return this.props.selectedFloodQuote[quote.name] === premium;
    }
    return false;
  };

  getFloodQuotePrices = (quote: any) => {
    const sortedPremiumDetails = sortBy(quote.premiumDetails, ['premium']);
    return (
      <div className='row'>
        {map(sortedPremiumDetails, (details: any, key: string) => (
          <div
            className={`padding-3 col-md-4`}
            key={key}
            onClick={() => this.onFloodQuotePriceClick(details, quote)}
          >
            <div
              className={`${
                this.isFloodPremiumSelected(quote, details.premium)
                  ? 'flood-quote-price'
                  : 'flood-quote-price-not-selected'
              }  adj-price`}
            >
              <div
                className={`${
                  this.isFloodPremiumSelected(quote, details.premium)
                    ? 'check-mark'
                    : 'check-mark-white'
                }  `}
              />
              <p
                className={`coverage-label price-label ${
                  this.isFloodPremiumSelected(quote, details.premium) ? 'text-white' : 'text-grey'
                }`}
              >
                Price
              </p>
              <div className='card-font premium-price'>
                <div className='price-font text-white'>{this.getFloodPremium(details)}</div>
              </div>
              <div
                className={`flood-exception ${
                  this.isFloodPremiumSelected(quote, details.premium)
                    ? 'text-aqua  font-weight-500'
                    : 'text-grey  font-weight-500'
                }`}
              >
                {deductiblesMap.Other.details.label}  {formatNumberToCurrency(details.deductible)}
              </div>

              {this.isFloodPremiumSelected(quote, details.premium) &&
                this.getFloodQuoteBindButton(
                  details,
                  quote,
                  'coverage-label flood-quote-bridge-btn'
                )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  getCarrierName = (quote: any) => quote.carrier_name || quote.name;

  render() {
    const { quote, floodQuote } = this.props;
    const { header_coverages } = quote;
    const isPlaceholder = Object.prototype.hasOwnProperty.call(quote, 'placeholder');
    const quoteError = get(quote, 'quoteError', false);
    return (
      <section className='sec-insurance-list'>
        {!isPlaceholder && !quoteError && (
          <div className='container mx-w quote-item'>
            <div
              className={`${
                quote.enabled ? 'hippo-context-box' : 'other-context-box'
              } content-box p-0`}
              title={this.getCarrierName(quote)}
            >
              <div className='row result-col'>
                <div className='col-md-2 col-lg-2 col-img align-self-center company'>
                  <img
                    src={quote.logo_url}
                    alt={this.getCarrierName(quote)}
                    className='img-fluid'
                  />
                  {this.getBindRibbon(quote)}
                </div>
                <div className='col-md-5 p-0 center-content'>
                  <div className='row w-100'>
                    <div className='col-md-4'>
                      <div className='coverage-label'>Rebuilding Costs</div>
                      <div className='card-font'>
                        {formatCurrency(
                          quote.cov_a || quote?.coverages?.coverage_a,
                          get(header_coverages, 'cov_a', '')
                        )}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='coverage-label'>Personal Property</div>
                      <div className='card-font'>
                        {formatCurrency(
                          quote.cov_c || quote?.coverages?.coverage_c,
                          get(header_coverages, 'cov_c', '')
                        )}
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='coverage-label'>Liability</div>
                      <div className='card-font'>
                        {formatCurrency(
                          quote.cov_e || quote?.coverages?.coverage_e,
                          get(header_coverages, 'cov_e', '')
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-5 p-0'>
                  {!floodQuote ? (
                    <div className='row center-content h-100'>
                      <div
                        className={`col-md-4 h-100 padding-3 ${
                          quote.enabled ? 'green-div' : 'white-div'
                        }`}
                      >
                        <div className='price-div-padding w-100'>
                          {this.getNormalPremiumContainer(quote)}
                        </div>
                        <div className='sep'></div>
                      </div>
                      <div className='col-md-8 d-flex justify-content-center align-items-center'>
                        <button
                          className={`${
                            !quote.enabled
                              ? 'bridge-carrier-btn'
                              : 'purchase-by-phone btn btn-success'
                          } purchase-btn`}
                          disabled={this.activateBindAction(quote)}
                          onClick={() => this.bindHandler(quote)}
                        >
                          {quote.purchase_type.toUpperCase()}
                        </button>
                      </div>
                    </div>
                  ) : (
                    this.getFloodQuotePrices(quote)
                  )}
                </div>
              </div>
            </div>

            {this.getExceptionContainer(quote)}
          </div>
        )}

        {this.getQuoteErrorContainer(quote, quoteError)}
      </section>
    );
  }
}

export default QuoteItem;
