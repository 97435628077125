import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { openBulkQuoteModal, resetStepDetails } from '../redux/actions';
import OrganizationSelector from '../components/organization-selector';
import BulkQuoteIcon from '../../assets/images/quotes.svg';

import './cache.scss';

const styles = (theme: Theme) => ({
  root: {
    width: 'auto',
  },
  cacheButton: {
    marginLeft: '-6px !important',
  },
  buttonDisabled: {
    color: 'white !important',
    opacity: 0.8,
    cursor: 'default',
  },
});

class Cache extends Component<
  AppComponents.CacheProps & WithStyles<typeof styles>,
  AppComponents.CacheState
> {
  state: AppComponents.CacheState = {
    modalOpen: true,
    entity: 'cacheOrignazation',
  };

  componentDidMount() {
    this.props.openBulkQuoteModal(true);
    this.props.resetStepDetails();
  }

  getModalCloseButton = (classStyle: string) => (
    <IconButton
      aria-label='close'
      className={classStyle}
      onClick={this.handleDialogClose}
      disabled={this.props.selectorLoader}
    >
      <CloseIcon />
    </IconButton>
  );

  onOpenDialog = () => {
    this.props.openBulkQuoteModal(true);
  };

  handleDialogClose = () => {
    this.props.openBulkQuoteModal(false);
  };

  showCacheButton = () => {
    const { classes } = this.props;
    return (
      <Button
        variant='outlined'
        className='btnOutlined'
        classes={{
          root: classes.cacheButton,
          disabled: classes.buttonDisabled,
        }}
        disabled={false}
        onClick={this.onOpenDialog}
      >
        <img alt='icon' className='img-fluid icon' src={BulkQuoteIcon} />
        <span>Select Orgnization</span>
      </Button>
    );
  };

  render() {
    return (
      <div className='container-fluid cache-wrapper p-3'>
        <div className='justify-content-center p-5'>
          <div className='cache-dialog-wrap'>{<OrganizationSelector />}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ bulkQuote }: AppComponents.CacheStore) => {
  const { selectorLoader } = bulkQuote;
  return {
    selectorLoader,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.CacheDispatch => {
  return bindActionCreators(
    {
      openBulkQuoteModal,
      resetStepDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(Cache));
