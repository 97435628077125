import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import rawConfig, { AppConfig } from '../../config/config';
import store, { ReduxRootState } from '../../pages/redux/store';
import { userSignOut } from '../../pages/redux/actions';
import { BATCH_TERMINATED_ERROR_RESPONSE } from '../../constants';

const config = rawConfig as AppConfig;
export const client = new GraphQLClient(config.bffBaseUrl + '/graphql');

export const graphqlBaseApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    client,
    prepareHeaders: (headers, api) => {
      const { auth, common, home } = api.getState() as ReduxRootState;
      const token = auth?.authUser;
      const organizationId = (home.pconfig?.dale_config?.id || common.pconfig?.pconfig?.id) ?? '';
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-organization-id', organizationId);
      }
      return headers;
    },
    customErrors: ({ name, response }) => {
      let message = BATCH_TERMINATED_ERROR_RESPONSE;
      let errorCode = 500;
      if (response?.errors?.[0]?.extensions?.statusCode === 401) {
        store.dispatch(userSignOut());
      }
      if (response.errors?.length) {
        message =
          (response.errors[0].extensions?.message as string) ||
          response.errors[0].message ||
          BATCH_TERMINATED_ERROR_RESPONSE;
        errorCode = (response.errors[0].extensions?.statusCode as number) || 500;
      }

      return {
        name,
        message,
        errorCode,
      };
    },
  }),

  endpoints: () => ({}),
});
