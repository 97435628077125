import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { ERROR_TEXT_MAX_LENGTH } from '../../../constants';

import './quote-error.scss';

class QuoteError extends React.Component<
  AppComponents.QuoteErrorProps,
  AppComponents.QuoteErrorState
> {
  static propTypes = {
    quote: PropTypes.object.isRequired,
  };
  state: AppComponents.QuoteErrorState = {
    showText: false,
  };
  render() {
    const { quote } = this.props;
    const { showText } = this.state;
    return (
      <section className='sec-insurance-list'>
        <div className='container mx-w'>
          <div className='content-box other-context-box p-0' title={quote.name}>
            <div className='row result-col'>
              <div className='col-md-12 col-lg-2 col-img align-self-center company'>
                <img
                  className='img-fluid quote-icon'
                  src={get(quote, 'logo_url', '')}
                  alt={get(quote, 'Carrier', '')}
                  title={get(quote, 'tooltip', '')}
                />
              </div>
              <div className={`col-md-10 flex-column width-col-md-10  align-self-center fm`}>
                <React.Fragment>
                  {quote.StatusText.length > ERROR_TEXT_MAX_LENGTH && !showText ? (
                    <div>
                      <span>{quote.StatusText.substring(0, ERROR_TEXT_MAX_LENGTH)}</span>
                      <span>
                        {'...'}
                        &nbsp;&nbsp;
                      </span>
                      <span
                        className='error-status-text'
                        onClick={() => {
                          this.setState({
                            showText: true,
                          });
                        }}
                      >
                        Show More
                      </span>{' '}
                    </div>
                  ) : quote.StatusText.length <= ERROR_TEXT_MAX_LENGTH && !showText ? (
                    <div>
                      <span>{quote.StatusText}</span>{' '}
                    </div>
                  ) : (
                    <div>
                      <span>{quote.StatusText}</span>{' '}
                      <span
                        className='error-status-text'
                        onClick={() => {
                          this.setState({
                            showText: false,
                          });
                        }}
                      >
                        Less More
                      </span>{' '}
                    </div>
                  )}
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default QuoteError;
