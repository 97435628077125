import { isEmpty } from 'lodash';
import { FormEvent, useState } from 'react';
import { resetObjectProperties, EMPTY, ERROR_MESSAGE, INPUT_TYPES } from './form-handler.utils';
import { FormValue, FormValidationOptions } from './form-handler.types';

export const useFormHandler = <Key extends string = string>(
  initFormValues: FormValue<Key>,
  validationOptions: FormValidationOptions<Key> = {
    requiredKeys: [],
    formatCheck: [],
    shouldHandleErrorOnInputChange: false,
    acceptedInputs: [],
  }
) => {
  const {
    shouldHandleErrorOnInputChange,
    requiredKeys,
    formatCheck,
    customValidator,
    acceptedInputs,
  } = validationOptions;
  const [formValues, setFormValues] = useState({
    ...initFormValues,
  });
  const [formErrors, setFormErrors] = useState(resetObjectProperties(initFormValues));

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    if (event && event?.target) {
      let {
        target: { name, value, type, checked },
      } = event;
      /**TODO: Update the value according to input field's type */
      if (type === INPUT_TYPES.CHECKBOX) value = checked;
      if (name) {
        if (value && acceptedInputs && acceptedInputs.map(data => data.key).includes(name)) {
          const [data] = acceptedInputs.filter(data => data.key === name);
          if (!isEmpty(data) && !data.regex.test(value)) {
            if (shouldHandleErrorOnInputChange && !!data.errorMessage)
              setFormErrors(preval => ({
                ...preval,
                [name]: data.errorMessage,
              }));
            return;
          }
        }
        setFormValues(preval => ({
          ...preval,
          [name]: value?.toString() || EMPTY,
        }));
        if (shouldHandleErrorOnInputChange)
          setFormErrors(preval => ({
            ...preval,
            [name]: EMPTY,
          }));
      }
    }
  };

  const customValidatorHandler = (formValues: FormValue<Key>, preFormErrors: FormValue<Key>) => {
    if (customValidator) {
      const tempCustemError = customValidator(
        formValues,
        resetObjectProperties<Key>(initFormValues)
      );
      Object.keys(preFormErrors).forEach(key => {
        if (tempCustemError[key as Key]) {
          preFormErrors[key as Key] = tempCustemError[key as Key];
        }
      });
    }
    return preFormErrors;
  };

  const validator = () => {
    const tempFormValues = { ...formValues };
    const tempFormErrors = { ...formErrors };
    Object.keys(tempFormValues).forEach(key => {
      if (
        requiredKeys &&
        requiredKeys.includes(key as Key) &&
        isEmpty(tempFormValues[key as Key])
      ) {
        tempFormErrors[key as Key] = ERROR_MESSAGE.REQUIRED;
      } else if (
        formatCheck &&
        !isEmpty(tempFormValues[key as Key]) &&
        formatCheck.map(data => data.key).includes(key as Key)
      ) {
        const [data] = formatCheck.filter(data => data.key === key);
        if (!isEmpty(data) && !data.regex.test(tempFormValues[key as Key] ?? ''))
          tempFormErrors[key as Key] = data.errorMessage || ERROR_MESSAGE.INVALID;
      } else {
        tempFormErrors[key as Key] = '';
      }
    });
    setFormErrors({
      ...customValidatorHandler(tempFormValues, tempFormErrors),
    });
    return Object.values(tempFormErrors).every(x => !x);
  };

  const handleOnSubmit = (
    onSubmit: (formValues: FormValue<Key>, isValied: boolean) => void | Promise<void>,
    event?: FormEvent
  ) => {
    if (event) {
      event.preventDefault();
    }
    onSubmit(formValues, validator());
  };

  const resetForm = (resetKeys?: Key[]) => {
    if (!resetKeys || isEmpty(resetKeys)) {
      setFormValues({ ...initFormValues });
      setFormErrors(resetObjectProperties(initFormValues));
    } else {
      setFormValues(preval => {
        const tempValue: Partial<FormValue<Key>> = {};
        Object.keys(preval).forEach(key => {
          if (resetKeys.includes(key as Key)) {
            tempValue[key as Key] = EMPTY;
          }
        });
        setFormErrors(preErr => ({
          ...preErr,
          ...tempValue,
        }));
        return { ...preval, ...tempValue };
      });
    }
  };

  const setFormError = (key: Key, errorMessage: string) => {
    setFormErrors(preval => ({
      ...preval,
      [key]: errorMessage,
    }));
  };

  const setFormValue = (key: Key, newValue: string) => {
    setFormValues(preval => ({
      ...preval,
      [key]: newValue,
    }));
    if (shouldHandleErrorOnInputChange)
      setFormErrors(preval => ({
        ...preval,
        [key]: EMPTY,
      }));
  };

  const setFormData = (formData: FormValue<Key>) => {
    const tempFormData: FormValue<Key> = { ...formValues };
    Object.keys(formData).forEach(key => {
      tempFormData[key as Key] = formData[key as Key] ? formData[key as Key] : EMPTY;
    });
    setFormValues(tempFormData);
  };

  return {
    resetForm,
    formValues,
    formErrors,
    handleOnChange,
    handleOnSubmit,
    setFormValues: setFormData,
    setFormErrors,
    setFormValue,
    setFormError,
    validateForm: validator,
  };
};

export default useFormHandler;
