import { Grid, Switch, Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import SpinnerLoader from '../spinner-loader';
import { allowEditingQuote } from '../../../utils';
const AutoSidePanel = ({
  edithandler,
  toggleEnabled,
  details,
  icon,
  isIncluded,
  includedLimitReached,
  vehicleList,
}: AppComponents.AutoSidePanelProps) => {
  const [excludeIndex, setExcludeIndex] = useState('');
  const [vehicleEnabled, setVehicleEnabled] = useState<boolean>(false);
  const disableAutoEdits = allowEditingQuote();
  const isProgress = (): boolean => !details.loader && !details.disabled;

  useEffect(() => {
    setVehicleEnabled(isIncluded);
  }, [isIncluded]);

  const isVehicleDisabled = () => {
    let isDisabled = false;
    let totalIncludedVehicles = 0;
    if (!isEmpty(vehicleList)) {
      vehicleList.forEach((vehicle: any) => {
        if (vehicle.isIncluded && !isEmpty(vehicle?.own_lease)) { 
          totalIncludedVehicles++;
        }
      });
    }
    if (
      isIncluded &&
      ((totalIncludedVehicles === 1 &&
      !isEmpty(details?.ownLease)) ||
      vehicleList?.length === 1)
    ) {
      isDisabled = true;
    }
    return isDisabled;
  };

  return (
    <>
      <Grid
        item
        xs={9}
        className={`auto-edit d-flex align-items-center ${icon ? 'd-flex' : ''}`}
        onClick={() => isIncluded && (isProgress() || disableAutoEdits?.auto) && edithandler(details)}
      >
        {icon ? (
          <>
            <VerifiedUserOutlinedIcon className='verified-user-icon' />
            <Typography className='list-label disply-label'>
              <span>{details.title}</span>
              {details.gender && <span className='sub-label'>{details.gender}</span>}
            </Typography>
          </>
        ) : (
          <Typography className='list-label'>{details.title}</Typography>
        )}
      </Grid>
      <Grid item xs={3} className='icons'>
        {/* TODO: delete after confirmation */}
        {/* <span className='icon-edit'></span> */}
        {!get(details, 'isPrimaryDriver', false) && (
          <>
            {details.loader && details.index === excludeIndex ? (
              <SpinnerLoader />
            ) : (
              <Switch
                checked={vehicleEnabled}
                // className='icon-cross'
                onClick={() => {
                  if (!includedLimitReached) {
                    setExcludeIndex(details.index);
                    setVehicleEnabled(prevState => !prevState);
                  }
                  return isProgress() && toggleEnabled(details);
                  }}
                disabled={isVehicleDisabled() || disableAutoEdits?.auto}
              ></Switch>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default AutoSidePanel;
