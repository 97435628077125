import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { isEmpty, map, range, startCase } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import policybound from '../../../assets/images/policy-bound-ribbon.png';
import {
  ALREADY_BOUND,
  HEAP_LABELS,
  INSURANCE_PRODUCTS_MAPPING,
  LOB_PRODUCTS_MAPPING,
  NO,
  PRODUCT_CHOOSE_ERROR,
  PRODUCT_OR_ORG_CHOOSE_ERROR,
  PRODUCT_SELECTION_ERROR_MESSAGE,
  QUOTING_STEPS_PAGES_NAME,
  YES,
} from '../../../constants';
import ThemesImages from '../../../pages/components/themes-images';
import {
  setHomeState,
  setOrganizationId,
  storeEmptyLobErrorMessage,
} from '../../../pages/redux/actions';
import { useAppDispatch, useAppSelector } from '../../../pages/redux/hooks';
import { ReduxRootState } from '../../../pages/redux/store';
import {
  adhocLobMapping,
  arrayToStringObject,
  getIsHeapAnalyticsEnabled,
  getThemeType,
  orderLobs,
} from '../../../utils';
import { getAppointmentChecks } from '../../pages/applicant/utils';
import { useLazyOrganizationQuery } from '../../redux/apis/organization/api';
import './organization-modal.scss';
import { QuoteOrganizationModelProps } from './types';

const ActiveMarker: FC = () => (
  <div className='active-marker'>
    <CheckCircleIcon />
  </div>
);

const selectOrgModalState = ({
  applicant: { isAddProductSaveApplicantLoading },
  common: {
    adhocBoundedLobs,
    emptyLobErrorMessage,
    policyBounded,
    quotingListErrorMessage,
    quotingOrganizationList,
    quotingOrganizationlistLoader,
    updateSelectedProductLoader,
  },
  partner: { appointments },
  home: { pconfig },
}: ReduxRootState) => ({
  adhocBoundedLobs,
  appointments,
  emptyLobErrorMessage,
  isAddProductSaveApplicantLoading,
  policyBounded,
  quotingListErrorMessage,
  quotingOrganizationList,
  quotingOrganizationlistLoader,
  updateSelectedProductLoader,
  pconfig,
});

const QuoteOrganizationModel: FC<QuoteOrganizationModelProps> = ({
  addProduct,
  closeModal,
  products,
  selectProduct,
  showModal,
  submitQuote,
  veriskIsLoading,
  selectOrganization,
}) => {
  const {
    adhocBoundedLobs,
    appointments,
    emptyLobErrorMessage,
    isAddProductSaveApplicantLoading,
    policyBounded,
    quotingListErrorMessage,
    quotingOrganizationList,
    quotingOrganizationlistLoader,
    updateSelectedProductLoader,
    pconfig,
  } = useAppSelector(selectOrgModalState);

  const dispatch = useAppDispatch();
  const [productOrgSelected, setProductOrgSelected] = useState(false);
  const [newProducts, setNewProducts] = useState<string[]>([]);
  const [
    fetchOrganization,
    { isLoading: isFetchingOrganization, isError: isGetOrganizationError },
  ] = useLazyOrganizationQuery();
  let insuranceProductList: string[] = [];
  let organization = pconfig || {};
  const themeType = getThemeType();
  const lobDetails = pconfig?.dale_config?.lob || {};
  insuranceProductList = Object.keys(lobDetails).map(lob => lob.toLowerCase());
  const isHeapAnalyticsEnabled =
    !!window.heap && addProduct ? !!getIsHeapAnalyticsEnabled() : false;

  useEffect(() => {
    dispatch(storeEmptyLobErrorMessage(''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isHeapAnalyticsEnabled && addProduct) {
      let appointmentList = !isEmpty(appointments) ? Object.keys(appointments) : [];
      const {
        isAuto,
        isCyberSecurity,
        isFlood,
        isHome,
        isHomeWarranty,
        isLife,
        isPet,
        isQuake,
        isUmbrella,
      } = getAppointmentChecks(appointmentList);
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_ADD_PRODUCT, {
        [HEAP_LABELS.AUTO_QUOTES]: isAuto ? YES : NO,
        [HEAP_LABELS.CYBER_SECURITY_QUOTES]: isCyberSecurity ? YES : NO,
        [HEAP_LABELS.EARTHQUAKE_QUOTES]: isQuake ? YES : NO,
        [HEAP_LABELS.FLOOD_QUOTES]: isFlood ? YES : NO,
        [HEAP_LABELS.HOME_QUOTES]: isHome ? YES : NO,
        [HEAP_LABELS.HOME_WARRANTY_QUOTES]: isHomeWarranty ? YES : NO,
        [HEAP_LABELS.LIFE_QUOTES]: isLife ? YES : NO,
        [HEAP_LABELS.PET_QUOTES]: isPet ? YES : NO,
        [HEAP_LABELS.PRODUCT_COUNT]: appointmentList?.length || 0,
        [HEAP_LABELS.UMBRELLA_QUOTES]: isUmbrella ? YES : NO,
      });
    }
    // eslint-disable-next-line
  }, [addProduct, appointments, isHeapAnalyticsEnabled]);

  const getPolicyBoundedProducts = () => {
    const adhocLobs = Object.values(adhocLobMapping);
    let filteredAdhocLobs = adhocLobs.filter(lob =>
      adhocBoundedLobs.includes(lob.code.toLowerCase())
    );
    let adhocBounded = filteredAdhocLobs.map(lob => lob.key.toLowerCase());
    const tempPolicyBounded = Object.keys(policyBounded).map(lob =>
      INSURANCE_PRODUCTS_MAPPING[
        lob.toUpperCase() as keyof typeof INSURANCE_PRODUCTS_MAPPING
      ].toLowerCase()
    );
    return [...adhocBounded, ...tempPolicyBounded];
  };

  const isProductsSelect = () => {
    if (addProduct) {
      if (!newProducts.length) {
        setProductOrgSelected(true);
      } else {
        if (isHeapAnalyticsEnabled) {
          const newProductList = !isEmpty(arrayToStringObject(newProducts))
            ? startCase(arrayToStringObject(newProducts))
            : '';
          window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_ADD_PRODUCT_SUBMIT, {
            [HEAP_LABELS.ADDED_PRODUCTS]: newProductList,
          });
        }
        submitQuote(newProducts);
      }
    } else if (!products.length || !organization?.name) {
      setProductOrgSelected(true);
    } else {
      submitQuote(newProducts);
    }
  };

  const setClassName = (productsArray: string[], product: string) => {
    let className = ['product-item'];
    if (productsArray.includes(product)) {
      className.push(addProduct ? 'disabled' : 'active');
    }
    return className.join(' ');
  };

  const showError = () => {
    let error = '';
    if (productOrgSelected && !emptyLobErrorMessage) {
      error = addProduct ? PRODUCT_CHOOSE_ERROR : PRODUCT_OR_ORG_CHOOSE_ERROR;
    }
    if (emptyLobErrorMessage) {
      error = emptyLobErrorMessage;
    }
    return error;
  };

  const handleProductChange = (product: string) => {
    if (addProduct && appointments && !Object.keys(appointments).includes(product.toUpperCase())) {
      const currentProducts = [...newProducts];
      const existingProduct = currentProducts.find(item => item === product);
      const addedProducts = existingProduct
        ? currentProducts.filter(p => p !== product)
        : [...currentProducts, product];
      setNewProducts(addedProducts);
    }
    selectProduct(product);
  };

  const handleOrganizationChange = (orgId: string, orgCode: string = '') => {
    setProductOrgSelected(false);
    if (selectOrganization) {
      selectOrganization(orgCode);
    }
    dispatch(setOrganizationId(orgId));
    dispatch(
      setHomeState({
        pconfig: {
          id: orgId,
          dale_config: { id: orgId },
        },
      } as any)
    );
    fetchOrganization();
  };

  const isPolicyBounded = (product: string) =>
    getPolicyBoundedProducts().includes(product?.toLowerCase());

  const isLoading =
    veriskIsLoading ||
    isFetchingOrganization ||
    quotingOrganizationlistLoader ||
    isAddProductSaveApplicantLoading;

  return (
    <div>
      <Dialog
        className='organizationModalWrap'
        classes={{ root: 'organization-dialog-container' }}
        maxWidth='md'
        open={showModal}
        onClose={closeModal}
        aria-labelledby='org-dialog-title'
      >
        <DialogContent className='dialogContentWrap'>
          {!addProduct && (
            <>
              <div id='org-dialog-title' className='padding-16-24'>
                {!quotingOrganizationlistLoader && isEmpty(quotingListErrorMessage) && (
                  <Typography className='text-center heading'>
                    Select an organization to quote
                  </Typography>
                )}
              </div>
              <div className='row d-flex justify-content-center m-0'>
                {!isEmpty(quotingOrganizationList) ? (
                  map(quotingOrganizationList, subOrganization => (
                    <div className='col-md-3 col-sm-3 mb-3' key={subOrganization.id}>
                      <div
                        className={`organization-item ${
                          organization?.id === subOrganization.id ? 'active' : ''
                        }`}
                        onClick={() =>
                          handleOrganizationChange(subOrganization.id, subOrganization?.code)
                        }
                      >
                        {organization?.id === subOrganization.id && <ActiveMarker />}
                        <img
                          src={subOrganization.logoUrl}
                          className='img-fluid'
                          alt={startCase(subOrganization.name)}
                          title={startCase(subOrganization.name)}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='row red-color justify-content-center'>
                    {!isEmpty(quotingListErrorMessage) && quotingListErrorMessage}
                  </div>
                )}
              </div>
              <div className='row red-color justify-content-center'>
                {isGetOrganizationError ? PRODUCT_SELECTION_ERROR_MESSAGE : null}
              </div>
            </>
          )}
          {insuranceProductList?.length ? (
            <>
              <div className='row padding-16-24 mt-3'>
                <div id='org-dialog-title' className='col-lg-12'>
                  <Typography className='text-center heading'>
                    Select one or more products to quote
                  </Typography>
                </div>
              </div>
              <div
                className={`row justify-content-center ${
                  insuranceProductList.length > 3 ? 'org-mdl' : ''
                }`}
              >
                {orderLobs(insuranceProductList).map(product => (
                  <div
                    className={`position-relative ${
                      insuranceProductList.length > 3
                        ? insuranceProductList.length === 4
                          ? 'prod-div-4'
                          : 'prod-div'
                        : 'col-lg-3 col-sm-3 mb-3'
                    }`}
                    key={product}
                  >
                    <Button
                      variant='outlined'
                      className={`position-relative policy-bounded-wrap ${setClassName(
                        products,
                        product
                      )}`}
                      disableRipple
                      onClick={() => handleProductChange(product)}
                      disabled={
                        updateSelectedProductLoader ||
                        isFetchingOrganization ||
                        !!quotingOrganizationlistLoader ||
                        (addProduct && products.includes(product.toUpperCase())) ||
                        isPolicyBounded(product)
                      }
                    >
                      {products.indexOf(product) > -1 && <ActiveMarker />}
                      <div>
                        <img
                          alt='icon'
                          className='img-fluid icon mx-auto'
                          src={ThemesImages[themeType][`${product}Icon`]}
                        />
                        <span className='product-label'>{LOB_PRODUCTS_MAPPING[product]}</span>
                      </div>
                      {isPolicyBounded(product) && (
                        <img
                          alt={ALREADY_BOUND}
                          className='img-fluid policybound-banner'
                          src={policybound}
                        />
                      )}
                    </Button>
                  </div>
                ))}
              </div>
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions className='d-flex flex-column justify-content-center'>
          <div className='row red-color justify-content-center pt-1'>
            {isEmpty(quotingListErrorMessage) ? showError() : ''}
          </div>
          <Button onClick={isProductsSelect} className='btn-continue' variant='contained'>
            {isLoading ? (
              <div className='loader-wrapper'>
                <div className='sk-circle'>
                  {range(12, 0).map(n => (
                    <div key={n} className={`sk-circle${n} sk-child`} />
                  ))}
                </div>
              </div>
            ) : (
              'CONTINUE'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QuoteOrganizationModel;
