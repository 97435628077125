import React from 'react';

import { FOOTER_CONTENT } from '../../../constants';

import './content-footer.scss';

const ContentFooter = () => {
  return (
    <div className='content-footer'>
      <p className='p-footer'>{FOOTER_CONTENT}</p>
    </div>
  );
};

export default React.memo(ContentFooter);
