import { invert } from 'lodash';
import { Occupation } from '../../../../../graphql/generated';

export const gatorToBffOccupation: Record<string, Occupation> = {
  'Homemaker/Houseprsn': Occupation.Homemaker,
  Retired: Occupation.Retired,
  Disabled: Occupation.Disabled,
  Unemployed: Occupation.Unemployed,
  'Graduate Student': Occupation.GraduateStudent,
  'High school': Occupation.HighSchool,
  Undergraduate: Occupation.Undergraduate,
  'Agr Inspect/Grader': Occupation.AgrocultureInspector,
  Arborist: Occupation.Arborist,
  Clerk: Occupation.Clerk,
  'Equip. Operator': Occupation.EquipmentOperator,
  'Farm/Ranch Owner': Occupation.FarmOwner,
  'Farm/Ranch Worker': Occupation.Farmworker,
  Fisherman: Occupation.Fisherman,
  Florist: Occupation.Florist,
  'Laborer/Worker': Occupation.LaborerWorker,
  'Landscape/NursryWkr': Occupation.LandscapeNurseryWorker,
  Landscaper: Occupation.Landscaper,
  Logger: Occupation.Logger,
  Millworker: Occupation.Millworker,
  Supervisor: Occupation.Supervisor,
  'Timber Grader/Scale': Occupation.TimberGrader,
  'Admin Assist': Occupation.AdministrativeAssistant,
  'Announcer/Broadcstr': Occupation.Announcer,
  'Artist/Animator': Occupation.Artist,
  'Author/Writer': Occupation.Author,
  'Choreography/Dancer': Occupation.ChoreographyDancer,
  'Composer/Director': Occupation.Composer,
  Curator: Occupation.Curator,
  Designer: Occupation.Designer,
  Editor: Occupation.Editor,
  'Journalist/Reporter': Occupation.Journalist,
  Printer: Occupation.Printer,
  Producer: Occupation.Producer,
  'Production Crew': Occupation.ProductionCrew,
  Projectionist: Occupation.Projectionist,
  'Receptionist/Sec': Occupation.Receptionist,
  'Ticket Sales/Usher': Occupation.TicketSales,
  'Accountant/Auditor': Occupation.Accountant,
  'Analyst/Broker': Occupation.Analyst,
  Bookkeeper: Occupation.Bookkeeper,
  'Branch Manager': Occupation.BranchManager,
  Collections: Occupation.Collections,
  Consultant: Occupation.Consultant,
  Controller: Occupation.Controller,
  'CSR/Teller': Occupation.Csr,
  'Director/Administr': Occupation.Director,
  Executive: Occupation.Executive,
  'Financial Advisor': Occupation.FinancialAdvisor,
  'Investment Banker': Occupation.InvestmentBanker,
  Investor: Occupation.Investor,
  'Loan/EscrowProcess': Occupation.LoanProcessor,
  'Mgr-Credit/Loan': Occupation.ManagerCredit,
  'Mgr-Portfolio/Prod.': Occupation.ManagerPortfolio,
  'Mgr-Property': Occupation.ManagerProperty,
  Realtor: Occupation.Realtor,
  'SalesAgt/Represent.': Occupation.SalesAgent,
  'Trader,Finan Instru': Occupation.Trader,
  Underwriter: Occupation.Underwriter,
  'Account Executive': Occupation.AccountExecutive,
  Buyer: Occupation.Buyer,
  'Clerk-Office': Occupation.ClerkOffice,
  CSR: Occupation.Csr,
  'H.R. Representative': Occupation.HrRepresentative,
  'Marketing Researchr': Occupation.MarketingResearcher,
  'Messenger/Courier': Occupation.Messenger,
  'Mgr - District': Occupation.ManagerDistrict,
  'Mgr - Finance': Occupation.ManagerFinance,
  'Mgr-Dept/Store': Occupation.ManagerStore,
  'Mgr-General Opers': Occupation.ManagerGeneralOperations,
  'Mgr-H.R./PublicRel': Occupation.ManagerHr,
  'Mgr-Mkt/Sales': Occupation.ManagerMarketingSales,
  'Mgr/Supervisr-Offic': Occupation.ManagerOffice,
  'Sales-Counter/Rentl': Occupation.SalesCounter, // NTC
  'Sales-Home Based': Occupation.SalesHome,
  'Sales-Mfg Rep': Occupation.SalesManufacture,
  'Sales-Retail/Whlsle': Occupation.SalesRetail,
  'Sales-Route/Vendor': Occupation.SalesVendor,
  'Boiler Oper/Maker': Occupation.BoilerOperator,
  'Bricklayer/Mason': Occupation.Bricklayer,
  Carpenter: Occupation.Carpenter,
  'Carpet Installer': Occupation.CarpetInstaller,
  'Concrete Worker': Occupation.ConcreteWorker,
  'Constrct Proj Mgr': Occupation.ConstructionProjectManager,
  Contractor: Occupation.Contractor,
  'Crane Operator': Occupation.CranerOperator,
  'Electrician/Linesmn': Occupation.Electrician,
  'ElevatorTech/Instl': Occupation.ElevatorTechnician,
  'FloorLayer/Finisher': Occupation.FloorLayer,
  'Foreman/Supervisor': Occupation.Foreman,
  Handyman: Occupation.Handyman,
  'Heat/Air Technician': Occupation.HeatAirConditionerRepairman, // NTC
  Inspector: Occupation.Inspector,
  Metalworker: Occupation.Metalworker,
  Miner: Occupation.Miner,
  'Oil/GasDril/RigOpr': Occupation.RefiningOperator, // NTC
  Painter: Occupation.Painter,
  'Plstr/Drywall/Stuc': Occupation.Plaster,
  Plumber: Occupation.Plumber,
  Roofer: Occupation.Roofer,
  'Audio-Visual Tech.': Occupation.AudioVisualTechnician,
  'Child/DayCare Wrker': Occupation.DaycareWorker,
  Counselor: Occupation.Counselor,
  'Grad. Teaching/Asst': Occupation.GraduateTeachingAssistant,
  'Instructor-Vocation': Occupation.InstructorVocation,
  'Librarian/Curator': Occupation.Librarian,
  Other: Occupation.Other,
  'Professor, College': Occupation.Professor,
  Superintendent: Occupation.Superintendent,
  'Teacher, College': Occupation.TeacherCollege,
  'Teacher, K-12': Occupation.TeacherK12,
  'Teaching Asst/Aide': Occupation.TeachingAssistant,
  Tutor: Occupation.Tutor,
  Actuary: Occupation.Actuary,
  Analyst: Occupation.Analyst,
  Architect: Occupation.Architect,
  'Clinical Data Coord.': Occupation.ClinicalDataCoordinator,
  Drafter: Occupation.Drafter,
  Engineer: Occupation.Engineer,
  'Manager-Project': Occupation.ManagerProject,
  'Manager-R&D': Occupation.ManagerRd,
  Mathematician: Occupation.Mathematician,
  'Research Prog. Dir.': Occupation.ResearchProgramDirector,
  Researcher: Occupation.Researcher,
  Scientist: Occupation.Scientist,
  Sociologist: Occupation.Sociologist,
  'Surveyor/Mapmaker': Occupation.Surveyor,
  Technician: Occupation.Technician,
  Attorney: Occupation.Attorney,
  'Chief Executive': Occupation.ChiefExecutive,
  Commissioner: Occupation.Commissioner,
  'Council member': Occupation.CouncilMember,
  'Enlst Mil Prsnl E1-4': Occupation.EnlistedMilitaryPersonnel,
  Legislator: Occupation.Legislator,
  'Mayor/City Manager': Occupation.Mayor,
  'Meter Reader': Occupation.MeterReader,
  'NCO (E5-9)': Occupation.NcoEs9,
  'Officer-Commissiond': Occupation.OfficerCommissioned,
  'Officer-Warrant': Occupation.OfficerWarrant,
  'Park Ranger': Occupation.ParkRanger,
  Planner: Occupation.Planner,
  Postmaster: Occupation.Postmaster,
  Regulator: Occupation.Regulator,
  'US Postal Worker': Occupation.UsPostalWorker,
  'Engineer-Hardware': Occupation.EngineerHardware,
  'Engineer-Software': Occupation.EngineerSoftware,
  'Engineer-Systems': Occupation.EngineerSystems,
  'Manager-Systems': Occupation.ManagerSystems,
  'Network Admin': Occupation.NetworkAdmin,
  Programmer: Occupation.Programmer,
  'Project Coordinator': Occupation.ProjectCoordinator,
  'Support Technician': Occupation.SupportTechnician,
  'Systems Security': Occupation.SystemsSecurity,
  'Technical Writer': Occupation.TechnicalWriter,
  'Web Developer': Occupation.WebDeveloper,
  'Actuarial Clerk': Occupation.ActuarialClerk,
  'Agent/Broker': Occupation.AgentBroker,
  'Claims Adjuster': Occupation.ClaimsAdjuster,
  'Product Manager': Occupation.ProductManager,
  'Sales Rep': Occupation.SalesRepresentative,
  'Airport Sec Ofcr': Occupation.AirportSecurityOfficer,
  'Animal Control Ofcr': Occupation.AnimalControlOfficer,
  Bailiff: Occupation.Bailiff,
  'Corrections Officer': Occupation.CorrectionsOfficer,
  'Court Clrk/Reporter': Occupation.CourtReporter,
  'Deputy Sheriff': Occupation.DeputySheriff,
  Dispatcher: Occupation.Dispatcher,
  Examiner: Occupation.Examiner,
  'Fed Agt/Marshall': Occupation.FederalAgent,
  'Fire Chief': Occupation.FireChief,
  'Fire Fighter/Supv.': Occupation.Firefighter,
  'Gaming Ofcr/Invest': Occupation.GamingOfficer,
  'Highway Patrol Ofcr': Occupation.HighwayPatrolOfficer,
  'Judge/Hearing Ofcr': Occupation.Judge,
  'Legal Asst./Sec': Occupation.LegalAssistant,
  'Paralegal/Law Clerk': Occupation.Paralegal,
  'Police Chief': Occupation.PoliceChief,
  'Police Det/Investgr': Occupation.PoliceInvestigator,
  'Police Offcr/Supvr': Occupation.PoliceOfficer,
  'Process Server': Occupation.ProcessServer,
  'Prvt Invest/Detec.': Occupation.PrivateInvestigator,
  'Security Guard': Occupation.SecurityGuard,
  Sheriff: Occupation.Sheriff,
  'Bldg Maint Engineer': Occupation.BuildingMaintenanceEngineer,
  'Custodian/Janitor': Occupation.Custodian,
  Electrician: Occupation.Electrician,
  'Field Service Tech.': Occupation.FieldServiceTechnician,
  'Heat/AirCond Repair': Occupation.HeatAirConditionerRepairman,
  'Housekeeper/Maid': Occupation.HousekeeperMaid,
  'Lndscpe/Grnds Maint': Occupation.LandscapeMaintenance,
  'Maint Mechanic': Occupation.MaintenanceMechanic,
  Mechanic: Occupation.Mechanic,
  'Factory Worker': Occupation.FactoryWorker,
  'Furniture Finisher': Occupation.FurnitureFinisher,
  Jeweler: Occupation.Jeweler,
  'Machine Operator': Occupation.MachineOperator,
  Packer: Occupation.Packer,
  'Plant Manager': Occupation.PlantManager,
  'Printer/Bookbinder': Occupation.PrinterBookbinder,
  'Quality Control': Occupation.QualityControl,
  'Refining Operator': Occupation.RefiningOperator,
  Shoemaker: Occupation.Shoemaker,
  'Tailor/Custom Sewer': Occupation.TailorSewer,
  'Textile Worker': Occupation.TextileWorker,
  Upholsterer: Occupation.Upholsterer,
  'Assist-Med/Dent/Vet': Occupation.AssistantMedic,
  Clergy: Occupation.Clergy,
  'Client Care Worker': Occupation.ClientCareWorker,
  'Dental Hygenist': Occupation.DentalHygenist,
  Dentist: Occupation.Dentist,
  Doctor: Occupation.Doctor,
  'Hospice Volunteer': Occupation.HospiceVolunteer,
  Mortician: Occupation.Mortician,
  'Nurse - C.N.A.': Occupation.NurseCna,
  'Nurse - LPN': Occupation.NurseLpn,
  'Nurse - RN': Occupation.NurseRn,
  'Nurse Practitioner': Occupation.NursePractitioner,
  Optometrist: Occupation.Optometrist,
  'Paramedic/EM Tech': Occupation.Paramedic,
  Pharmacist: Occupation.Pharmacist,
  'Social Worker': Occupation.SocialWorker,
  'Support Services': Occupation.SupportServices,
  Therapist: Occupation.Therapist,
  Veterinarian: Occupation.Veterinarian,
  Caregiver: Occupation.Caregiver,
  'Dry Cleaner/Laundry': Occupation.DryCleaner,
  'Hair Stylist/Barber': Occupation.HearStylistBarber,
  Housekeeper: Occupation.Housekeeper,
  Manicurist: Occupation.Manicurist,
  Masseuse: Occupation.Masseuse,
  Nanny: Occupation.Nanny,
  'Pet Services': Occupation.PetServices,
  Baker: Occupation.Baker,
  Bartender: Occupation.Bartender,
  Bellhop: Occupation.Bellhop,
  'Bus Person': Occupation.BusPerson,
  Caterer: Occupation.Caterer,
  Chef: Occupation.Chef,
  Concessionaire: Occupation.Concessionaire,
  Concierge: Occupation.Concierge,
  'Cook-Rest/Cafeteria': Occupation.CookRestaurant,
  'Cook/Wrkr-Fast Food': Occupation.CookFastFood,
  'Delivery Person': Occupation.DeliveryPerson,
  'Desk Clerk': Occupation.DeskClerk,
  Dishwasher: Occupation.Dishwasher,
  'Food Prod/Packing': Occupation.FoodProduction,
  'Host/Maitre d': Occupation.HostMaitre,
  Manager: Occupation.Manager,
  Valet: Occupation.Valet,
  'Waiter/Waitress': Occupation.WaiterWaitress,
  'Wine Steward': Occupation.WineSteward,
  'Activity/Recre.Asst': Occupation.ActivityAssistant,
  Agent: Occupation.Agent,
  Athlete: Occupation.Athlete,
  'CampCounselor/Lead': Occupation.CampCounselor,
  Coach: Occupation.Coach,
  'Director, Program': Occupation.DirectorProgram,
  'Event Mgr/Promoter': Occupation.EventManager,
  'Life Guard': Occupation.Lifeguard,
  'Mgr - Fitness Club': Occupation.ManagerFitness,
  'Sales-Tkt/Mmbrshp': Occupation.SalesMembership,
  'SportsBrdcstr/Journ': Occupation.SportBroadcaster,
  'Trainer/Instructor': Occupation.Trainer,
  'Umpire/Referee': Occupation.UmpireReferee,
  'Air Traffic Control': Occupation.AirTrafficControl,
  'Airport Ops Crew': Occupation.AirportOperationsCrew,
  'Bellhop/Porter': Occupation.BellhopPorter,
  'Crane Loader/Oper': Occupation.CraneLoader,
  'Driver-Bus/Strcar': Occupation.DriverBus,
  'Driver-Taxi/Limo': Occupation.DriverTaxi,
  'Driver-Truck/Delvry': Occupation.DriverTruck,
  'Flight Attendant': Occupation.FlightAttendant,
  'Forklift Operator': Occupation.ForkliftOperator,
  Laborer: Occupation.Laborer,
  Longshoreman: Occupation.Longshoreman,
  'Mate/Sailor': Occupation.MateSailor,
  'Mgr Warehse/Dist': Occupation.ManagerWarehouse,
  'Parking Lot Attend': Occupation.ParkingLotAttendant,
  'Pilot/Capt/Eng': Occupation.PilotCaptain,
  'Railroad Worker': Occupation.RailroadWorker,
  'Shipping/RecClk': Occupation.ShippingClerk,
  'Subway/LgtRail Oper': Occupation.SubwayLightOperator,
  'Ticket Agent': Occupation.TicketAgent,
  'Transportation Spec': Occupation.TransportationSpecialist,
};

export const bffOccupationToGator = invert(gatorToBffOccupation);
