export const RELATION_TO_PRIMARY_DRIVER = [
  { label: 'Child', value: 'child' },
  { label: 'Parent', value: 'parent' },
  { label: 'Spouse', value: 'spouse' },
  { label: 'Other Relative', value: 'otherrelative' },
  { label: 'Non Resident Driver', value: 'nonresidentdriver' },
  { label: 'None of the Above', value: 'noneoftheabove' },
];
export const SPOUSE = 'spouse';
export const SPOUSE_ALREADY_ADDED_TOOLTIP_MESSAGE =
  'Spouse already added for the primary applicant.';
