import React, { useState } from 'react';
import { Box, Button, Grid, Popover, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  Add,
  Cancel as CancelIcon,
  EditOutlined as EditOutlinedIcon,
  Help as HelpIcon,
  InfoOutlined,
  Remove,
  WarningOutlined,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { isEmpty, startCase } from 'lodash';
import SpinnerLoader from '../spinner-loader';
import { getCoverageItem } from '../../home/utils';
import {
  formatNumberToCurrency,
  getDownLoadQuoteDetails,
  getIsAdmittedOrNonAdmittedCarrier,
  getLobHerdId,
  getQuoteCarrierErrorCategory,
  quoteErrorCategoryDetails,
} from '../../../utils';
import {
  carrierCategoryListToOpenErrorDetails,
  checkForHomeLobNonAdmittedCarrier,
  errorCategoryMapping,
  getThemeType,
  quoteErrorCategoryClassNameMapping,
} from '../../../utils/common';
import ThemesImages from '../themes-images';
import {
  ADD_PRODUCTS,
  COMING_SOON,
  COVERAGES,
  DETAILS,
  DOWNLOAD_INDICATOR,
  FLOOD_PENALTY,
  INACTIVE,
  INSURANCE_BUSINESS_TYPES,
  VIEW_LESS,
  VIEW_MORE,
} from '../../../constants';
import { NON_ADMITTED_HOVER_DETAILS } from '../../../constants/non-admitted';
import { LineOfBusiness } from '../../../quote-application/enums';
import { isEnabledPRSv2Flow } from '../../../quote-application/utils';

export const GetHomeCoverageDetails = (props: any) => {
  const { quote } = props;
  let homeCoverageList: Array<any> =  getCoverageItem(quote);
  return (
    <>
      {!isEmpty(homeCoverageList) && (
        <Grid item xs={12} sm={12}>
          <Typography className='label text-left'>{startCase(COVERAGES)}</Typography>
          <div className='normal-label text-left'>
            <ul className='dc-list'>
              {homeCoverageList.map((item: any, itemIndex: number) => {
                return (
                  !!item?.value && (
                    <li key={itemIndex}>{`${item?.label}: ${formatNumberToCurrency(
                      item?.value
                    )}`}</li>
                  )
                );
              })}
            </ul>
          </div>
        </Grid>
      )}
    </>
  );
};

export const ErrorQuoteCard = (props: any) => {
  const { quote } = props;
  const carrierErrorType: string = getQuoteCarrierErrorCategory(quote) || '';
  return (
    <>
      <div className='carrier-error'>
        <div className='row m-0 w-100'>
          <div className='col-lg-12 mb-20 justify-content-center price-wraper error-content d-flex'>
            {!isEmpty(carrierErrorType) ? (
              <>
                <Typography className='dark-label'>
                  {quoteErrorCategoryDetails[carrierErrorType]?.label}
                </Typography>
                <Typography className='light-label'>
                  {quoteErrorCategoryDetails[carrierErrorType]?.displayMessage1}
                </Typography>
                <Typography className='light-label mt-3'>
                  {quoteErrorCategoryDetails[carrierErrorType]?.displayMessage2}
                </Typography>
              </>
            ) : (
              <Typography className='error-text error-label text-center'>
                {quote?.StatusText}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const ErrorViewMoreCard = (props: any) => {
  const { quote } = props;
  const carrierErrorType: string = getQuoteCarrierErrorCategory(quote) || '';
  const isOpenViewMore: boolean = carrierCategoryListToOpenErrorDetails.includes(carrierErrorType);
  const [isViewMore, setIsViewMore] = useState(isOpenViewMore);
  return (
    <div className='view-content-wrap'>
      <div className='row w-100 mx-auto view-more-button-div'>
        <div className='col-lg-4'></div>
        <div className='d-md-flex col-lg-8 '>
          <div className='view-more-item resposive-mt-2 w-100 d-flex display-view'>
            <Typography
              onClick={() => setIsViewMore(!isViewMore)}
              className='button-view-more mt-2'
            >
              {!isViewMore ? (
                <>
                  <Add className='add-icon mr-2' />
                  <Typography>{VIEW_MORE}</Typography>
                </>
              ) : (
                <>
                  <Remove className='add-icon mr-2' />
                  <Typography>{VIEW_LESS}</Typography>
                </>
              )}
            </Typography>
          </div>
        </div>
      </div>
      {isViewMore && (
        <div className='row m-0'>
          <div className='col-lg-12 view-more-section pl-0 pr-0'>
            <Typography className='lbl-details'>{`${DETAILS}: `}</Typography>
            <Typography className='sub-para'>{quote?.StatusText}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export const usePreventScrollOnFocus = () => {
  const scrollPos = React.useRef<[number, number]>([0, 0]);

  React.useEffect(() => {
    const scrollHandler = () => {
      scrollPos.current[0] = window.scrollX;
      scrollPos.current[1] = window.scrollY;
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const handleFocus = React.useCallback(() => {
    window.scrollTo(...scrollPos.current);
  }, []);

  return handleFocus;
};

export const DownloadQuote = (props: any) => {
  let { quote, lob } = props;
  const onDownloadIndicationClick = () => {
    if(!!quote?.downloadQuoteLoader) return
    lob = !isEmpty(lob) ? lob?.toLowerCase() : '';
    let payload: any = {
      herdId: getLobHerdId(lob) || '',
      carrierId: quote?.carrier_id?.toString() || quote?.carrierId || '',
      lob: lob,
    }
    getDownLoadQuoteDetails(payload, lob)
  };
  return (
    <Typography
      className={`download-indication mt-2 ${
        !!quote?.downloadQuoteLoader
          ? 'download-indication-disable'
          : 'download-indication-label-clr'
      }`}
      onClick={() => onDownloadIndicationClick()}
    >
      <span className='vertical-line'>|</span>
      {DOWNLOAD_INDICATOR}
      {!!quote?.downloadQuoteLoader && <SpinnerLoader />}
    </Typography>
  );
};

export const AdmittedOrNonAdmittedDetails = (props: any) => {
  const { quote, lob } = props;
  const isAdmitted: boolean | undefined =
    lob?.toLowerCase() === LineOfBusiness.HOME && isEnabledPRSv2Flow()
      ? checkForHomeLobNonAdmittedCarrier(quote)
      : getIsAdmittedOrNonAdmittedCarrier(quote);
  return (
    <>
      {isAdmitted !== undefined && !isAdmitted && (
        <>
          <div className='non-admitted-carrier-div d-flex align-items-center mb-1'>
            <Typography className='mr-2 non-admitted-label'>
              {FLOOD_PENALTY.NON_ADMITTED_LABEL}
            </Typography>
            <NonAdmittedViewMoreDetails lob={lob} />
          </div>
        </>
      )}
    </>
  );
};

export const NonAdmittedViewMoreDetails = (props: { lob: string }) => {
  let { lob } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openNonAdmitedPopHover = Boolean(anchorEl);

  const onNonAdmittedHoverChange = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeNonAdmittedPopHover = () => {
    setAnchorEl(null);
  };
  lob = lob?.toUpperCase() || INSURANCE_BUSINESS_TYPES.FLOOD;
  return (
    <div>
      <div
        aria-owns={openNonAdmitedPopHover ? 'mouse-over-popover' : undefined}
        aria-haspopup='true'
        onMouseEnter={onNonAdmittedHoverChange}
        onMouseLeave={closeNonAdmittedPopHover}
      >
        <InfoIcon className='cursor-pointer non-admitted-info-icon' />
      </div>
      <Popover
        id='mouse-over-popover'
        style={{
          pointerEvents: 'none',
        }}
        open={openNonAdmitedPopHover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={closeNonAdmittedPopHover}
        disableRestoreFocus
        className='non-admitted-view-more-wrapper'
      >
        <Box style={{ maxWidth: '600px', padding: '10px' }}>
          <Typography className='non-admitted-view-more-title mb-2 mt-2'>
            {NON_ADMITTED_HOVER_DETAILS[lob]?.TITLE}
          </Typography>
          <ul style={{ marginLeft: '-10px' }}>
            <li>
              <Typography className='non-admitted-view-more-sub-title mb-2 mt-2'>
                {NON_ADMITTED_HOVER_DETAILS[lob]?.SUB_TITLE}
              </Typography>
            </li>
            <ul className='mb-0' style={{ marginLeft: '-10px' }}>
              {NON_ADMITTED_HOVER_DETAILS[lob]?.BODY.map((item: string) => (
                <li key={item} className='non-admitted-details-list'>
                  <Typography className='list-items' dangerouslySetInnerHTML={{ __html: item }} />
                </li>
              ))}
            </ul>
          </ul>
        </Box>
      </Popover>
    </div>
  );
};

export const AddProductsDetails = (props: {
  isShowAddProductButton: boolean;
  openModal: () => void;
  loader: boolean;
}) => {
  const { loader, openModal, isShowAddProductButton } = props;
  return (
    <Grid item md={6}>
      {isShowAddProductButton && (
        <Button
          variant='outlined'
          size='large'
          className='rounded shadow-sm mt-3 p-3 add-product-button'
          onClick={openModal}
          disabled={loader}
        >
          {ADD_PRODUCTS}
          <AddIcon className={`add-circle-icon ${loader && 'disable'}`} />
        </Button>
      )}
    </Grid>
  );
};

export const getPurchaseIcon = (quote: any) => {
  const carrierId = quote?.carrier_id ?? quote?.carrierId;
  if (quote.enabled || (carrierId && parseInt(carrierId) === 1)) {
    return ThemesImages[getThemeType()].creditCard;
  }
  return ThemesImages[getThemeType()].bridgeIcon;
};

export const PurchaseOnlineButtonDetails = (props: any) => {
  const { quote } = props;
  return (
    <>
      {/* eslint-disable-next-line */}
      <a
        href={quote?.agent_url}
        target='_blank'
        rel='noopener noreferrer'
        className='align-items-center d-flex justify-content-center'
      >
        <img
          src={getPurchaseIcon(quote)}
          alt='credit card'
          className={`img-fluid carrier-img mb-1`}
        />
        <Typography className='position-relative cursor-pointer purchase-online-label coming-soon-carrier-purchase-type'>
          {quote.purchase_type?.toUpperCase()}
        </Typography>
      </a>
    </>
  );
};

export const UpcomingCarrierDetails = (props: any) => {
  const { quote, lob } = props;
  const isUpcomingCarrier: boolean = !!quote?.isUpcomingCarrier;
  const isSinglePremiumLob: boolean = [
    INSURANCE_BUSINESS_TYPES.HOME,
    INSURANCE_BUSINESS_TYPES.AUTO,
  ].includes(lob);
  const isShowBridgeToCarrierButton: boolean = isSinglePremiumLob && !isEmpty(quote?.agent_url);
  return (
    <>
      <div
        className={`${
          isShowBridgeToCarrierButton ? 'col-lg-4' : 'col-lg-8'
        } d-flex align-items-center justify-content-center coming-soon-wrap`}
      >
        {isUpcomingCarrier && (
          <Typography className='coming-soon-text'>
            {!quote?.isAvailableForQuoting ? COMING_SOON : INACTIVE}
          </Typography>
        )}
        <Typography className='error-text error-label text-center'>{quote?.StatusText}</Typography>
      </div>
      {isUpcomingCarrier && isShowBridgeToCarrierButton && (
        <div className='col-lg-4 coming-soon-bridg-carrier-btn d-flex align-items-center justify-content-center'>
          <PurchaseOnlineButtonDetails quote={quote} />
        </div>
      )}
    </>
  );
};

export const ErrorCategoryIconMapping = (props: {
  carrierErrorType: string;
}) => {
  const { carrierErrorType } = props;
  const {
    addressableCarrierError,
    agentAddressable,
    agentAddressableCoverages,
    agentAddressablePropertyData,
    applicationError,
    applicationErrorExternal,
    applicationErrorInternal,
    underwritingExposureManagement,
    underwritingPropertyRisk,
  } = errorCategoryMapping;
  const errorCategory = quoteErrorCategoryClassNameMapping[carrierErrorType] || applicationError;
  if (errorCategory === addressableCarrierError) {
    return <HelpIcon className={errorCategory} />;
  } else if ([underwritingExposureManagement, underwritingPropertyRisk].includes(errorCategory)) {
    return <InfoOutlined className={errorCategory} />;
  } else if (
    [agentAddressable, agentAddressableCoverages, agentAddressablePropertyData].includes(
      errorCategory
    )
  ) {
    return <EditOutlinedIcon className={errorCategory} />
  } else if ([applicationErrorExternal, applicationErrorInternal].includes(errorCategory)) {
    return <WarningOutlined className={errorCategory} />
  } else {
    return <CancelIcon className={errorCategory} />;
  }
};
