/* istanbul ignore file */
import * as partnerType from '../constants';

export const getAppointments = (payload: any): AppRedux.Action => ({
  type: partnerType.PARTNER_REQUEST_APPOINTMENTS,
  payload,
});

export const appointmentsLoaderShow = (flag: boolean): AppRedux.Action => ({
  type: partnerType.ON_SHOW_APPOINTMENTS_LOADER,
  payload: flag,
});

// todo: set normal type definition
export const setAppointments = (appointments: any): AppRedux.Action => ({
  type: partnerType.SET_APPOINTMENTS,
  payload: appointments,
});

export const setRequestId = (requestId: string): AppRedux.Action => ({
  type: partnerType.SET_REQUEST_ID,
  payload: requestId,
});

export const setIsAuthProviderOneLogin = (flag: boolean): AppRedux.Action => ({
  type: partnerType.SET_IS_AUTH_PROVIDER_ONELOGIN,
  payload: flag,
});

export const clearPartnerState = (): AppRedux.Action => ({
  type: partnerType.CLEAR_PARTNER_STATE,
});

export const setPartnerDetails = (payload: any): AppRedux.Action => ({
  type: partnerType.SET_PARTNER_DETAILS,
  payload,
});
