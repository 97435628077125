/* istanbul ignore file */
import * as insuranceActionType from '../constants';

// Add details
export const addDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: insuranceActionType.ADD_INSURANCE_DETAILS,
  payload,
});

export const updateGeneralCoverages = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: insuranceActionType.GENERAL_COVERAGES,
  payload,
});

export const clearInsurenaceDetails = (): AppRedux.Action => ({
  type: insuranceActionType.CLEAR_INSURANCE_DETAILS,
});
