import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import React from 'react';
import { FormFieldProps } from '../form-fields.types';
import { customformatCurrency } from '../../../utils';
import { ASTERISK, NONE } from '../../../../constants';

const PremiumField: React.FC<FormFieldProps> = ({
  fieldDetails: { name, label, isRequired, maxLength, isDisabled },
  value,
  error,
  disabled,
  onChange,
  onInputBlur,
}) => {
  const inputProps = {
    name: name,
    id: name,
    autoFocus: false,
    spellCheck: false,
    maxLength: maxLength || 50,
    autoComplete: NONE.toLowerCase(),
    disabled: disabled || !!isDisabled,
  };

  return (
    <FormControl fullWidth key={name} error={!!error} disabled={inputProps?.disabled}>
      <InputLabel shrink htmlFor={name}>
        {label}
        {isRequired && <span className='error-color'>{ASTERISK}</span>}
      </InputLabel>
      <Input
        autoComplete='off'
        value={customformatCurrency(value ?? '')}
        onChange={(event: React.ChangeEvent<any>) => {
          event.target.value = event.target.value.replace(/[^\d.-]/g, '');
          onChange(event);
        }}
        onBlur={() => onInputBlur && onInputBlur(name, value)}
        inputProps={inputProps}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default PremiumField;
