import React from 'react';
import HcrIframeGrid from './hcr-iframe-grid';

const HcrIframeClientSettings = () => {
  return (
    <div>
      <HcrIframeGrid />
    </div>
  );
};

export default HcrIframeClientSettings;
