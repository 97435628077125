/* global google */
import isEmpty from 'lodash/isEmpty';

import config from '../../../config/config';
import { numberRegex } from '../../../utils';

interface AutoCompleteProps {
  input: any;
  onPlaceChanged: (address: any) => void;
}

type placeOptions = { [key: string]: string };

class AutoCompleteService {
  countries: any;
  componentMap: placeOptions;
  placeDetails: placeOptions;
  address: placeOptions;
  onPlaceChanged: (address: any) => void;
  autocomplete: any;

  constructor(props: AutoCompleteProps) {
    this.countries = config.googleCountryRestriction.split(',');
    this.componentMap = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_3: 'short_name',
      neighborhood: 'short_name',
      sublocality_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
      intersection: 'long_name',
    };

    this.placeDetails = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      administrative_area_level_3: '',
      neighborhood: '',
      sublocality_level_1: '',
      country: '',
      postal_code: '',
      intersection: '',
    };

    this.address = {
      street: '',
      city: '',
      state: '',
      zip: '',
    };
    this.onPlaceChanged = props.onPlaceChanged;
    this.autocomplete = new google.maps.places.Autocomplete(props.input, { types: ['geocode'] });
    this.initializeService(props.input);
  }

  clearAddress = () => {
    this.address = { ...this.address, street: '' };
  };

  initializeService = (input: any) => {
    this.autocomplete.setComponentRestrictions({ country: this.countries });
    this.autocomplete.setFields(['address_component']);
    this.autocomplete.addListener('place_changed', this.onPlaceSelected);
    google.maps.event.addDomListener(input, 'keydown', (e: any) => {
      const query: any = (window as any).jQuery;
      const containerLength = query('.pac-container:visible').length;
      if (e.keyCode === 13 && containerLength > 0) {
        e.preventDefault();
      }
    });
  };

  validateZip = (address: placeOptions) => {
    const { zip } = address;
    if (!isEmpty(zip) && zip.match(numberRegex)) {
      if (zip.length > 0 && zip.length <= 5) {
        return true;
      }
    }
    return false;
  };

  onPlaceSelected = () => {
    const { name, address_components } = this.autocomplete.getPlace();
    if (address_components) {
      /**
       * get each component of the address from the place details,
       * and then fill-in the corresponding field on the object.
       */
      for (const addressComponent of address_components) {
        const addressType = addressComponent.types[0];
        if (this.componentMap[addressType]) {
          this.placeDetails[addressType] = addressComponent[this.componentMap[addressType]];
        }
      }
      const streetName: string =
        `${this.placeDetails.street_number} ${this.placeDetails.route}`.trim() ||
        this?.placeDetails?.intersection?.trim() ||
        '';
      this.address = {
        street: streetName,
        city:
          this.placeDetails.locality ||
          this.placeDetails.sublocality_level_1 ||
          this.placeDetails.administrative_area_level_3 ||
          this.placeDetails.neighborhood,
        state: this.placeDetails.administrative_area_level_1,
        zip: this.placeDetails.postal_code,
      };
    } else {
      if (!isEmpty(name)) {
        this.address = { street: name.trim() };
      }
    }
    if (!this.validateZip(this.address)) {
      this.address = { ...this.address, zip: '' };
    }
    this.onPlaceChanged(this.address);
  };
}

export default AutoCompleteService;
