/* istanbul ignore file */
import * as reportActionType from '../constants';

export const fetchReportDetails = (): AppRedux.Action => ({
  type: reportActionType.FETCH_REPORT_DETAILS,
});

export const setReportLoader = (payload: boolean): AppRedux.Action => ({
  type: reportActionType.SET_REPORT_LOADER,
  payload,
});

export const setReportDetails = (payload: any): AppRedux.Action => ({
  type: reportActionType.SET_REPORT_DETAILS,
  payload,
});

export const removeSavedFilter = (payload: any): AppRedux.Action => ({
  type: reportActionType.REMOVE_SAVED_FILTER,
  payload,
});

export const setRemoveFilterLoader = (payload: boolean): AppRedux.Action => ({
  type: reportActionType.SET_REMOVE_FILTER_LOADER,
  payload,
});

export const updateSavedFilters = (payload: Array<any>): AppRedux.Action => ({
  type: reportActionType.UPDATE_SAVED_FILTERS,
  payload,
});

export const lobFilterUpdated = (payload: any): AppRedux.Action => ({
  type: reportActionType.LOB_FILTER_UPDATED,
  payload,
});

export const savedFilterSelected = (payload: any): AppRedux.Action => ({
  type: reportActionType.SAVED_FILTER_SELECTED,
  payload,
});

export const updateReportCurrentDetails = (payload: any): AppRedux.Action => ({
  type: reportActionType.UPDATE_REPORT_CURRENT_DETAILS,
  payload,
});

export const submitReportDetails = (payload: any): AppRedux.Action => ({
  type: reportActionType.SUBMIT_REPORT_DETAILS,
  payload,
});

export const setSubmitReportLoader = (payload: boolean) => ({
  type: reportActionType.SET_SUBMIT_REPORT_LOADER,
  payload,
});

export const setReportAPIError = (payload: string) => ({
  type: reportActionType.SET_REPORT_API_ERROR,
  payload,
});
