/* istanbul ignore file */
import * as types from '../constants';
import { getUpdatedQuoteList } from '../../../utils';
import { assignIn, isEmpty, filter, find } from 'lodash';
import { UpcomingCarriersListType } from './cyber-security';

export interface UmbrellaDetailsState {
  quoteList: Array<any>;
  quoteErrorList: Array<any>;
  quoteListLoader: boolean;
  quotingCarriers: Array<any>;
  herdId: string;
  umbrellaInfo: null | { questionGroups: unknown };
  umbrellaQuestionsResponse: any;
  umbrellaInfoLoader: boolean;
  quotingDetails: any;
  pollingDetails: any;
  noQuoteAvailable: boolean;
  quotesLoaded: boolean;
  isQuestionError: boolean;
  isQuoteRefresh: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
  quoteStatus?: string;
  bridgeToCarrierLoader?: boolean;
}

const INIT_LIFE_STATE: UmbrellaDetailsState = {
  quoteList: [],
  quoteErrorList: [],
  quoteListLoader: false,
  quotingCarriers: [],
  herdId: '',
  umbrellaInfo: null,
  umbrellaInfoLoader: false,
  quotingDetails: null,
  pollingDetails: null,
  noQuoteAvailable: false,
  quotesLoaded: false,
  umbrellaQuestionsResponse: null,
  isQuestionError: false,
  isQuoteRefresh: false,
  upcomingCarrierList: [],
};

const INIT_STATE = { ...INIT_LIFE_STATE };

export default (state = INIT_STATE, action: AppRedux.Action): UmbrellaDetailsState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        quoteListLoader: false,
      };
    }

    case types.SET_UMBRELLA_QUOTES_DETAILS: {
      const { quoteList, herdId, quoteErrorList } = action.payload;
      return {
        ...state,
        quoteList: quoteList,
        quoteErrorList: quoteErrorList,
        herdId: herdId ? herdId : state.herdId,
      };
    }

    case types.UMBRELLA_DETAILS_LOADER: {
      return {
        ...state,
        quoteListLoader: action.payload,
      };
    }

    case types.UPDATE_UMBRELLA_QUOTE_DETAILS: {
      return {
        ...state,
        quotingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_UMBRELLA_POLLING_ERROR_DETAILS: {
      let oldQuoteErrorList = getUpdatedQuoteList(state, action.payload);
      if (oldQuoteErrorList) {
        oldQuoteErrorList = filter(oldQuoteErrorList, (error: any) => {
          return assignIn(error, { quoteError: true });
        });
      }
      return {
        ...state,
        quoteErrorList: [...oldQuoteErrorList],
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_UMBRELLA_POLLING_DETAILS: {
      const { quotes, error_details } = action.payload;
      let oldQuotesList = [...state.quoteList];
      let quoteErrorList = [...state.quoteErrorList];
      if (!isEmpty(action.payload.quotes)) {
        let newQuoteErrorList: Array<any> = [];
        newQuoteErrorList = filter(
          quotes,
          quote =>
            !find(oldQuotesList, {
              carrier_id: quote.carrier_id,
              name: quote.name,
            }) && !find(oldQuotesList, { carrier_id: quote.carrier_id })
        );
        oldQuotesList = [...oldQuotesList, ...newQuoteErrorList];
      }
      if (error_details && error_details.display_errors_on_ui) {
        quoteErrorList = filter(error_details.errors, error => error);
      }
      return {
        ...state,
        quoteList: [...oldQuotesList],
        quoteErrorList,
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_UMBRELLA_HERD_ID: {
      return {
        ...state,
        herdId: action.payload,
      };
    }

    case types.STORE_UMBRELLA_QUOTE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.CLEAR_UMBRELLA_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.SET_UMBRELLA_INFO_LOADER: {
      return {
        ...state,
        umbrellaInfoLoader: action.payload,
      };
    }

    case types.STORE_UMBRELLA_INFO: {
      return {
        ...state,
        umbrellaInfo: action.payload,
      };
    }

    default:
      return state;
  }
};
