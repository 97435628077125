import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import { every, find, forEach, get, isEmpty, isEqual, map, omit } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import ConfirmationModal from '../confirmation-modal';
import UploadFile from '../upload-file';
import template from './template';
import {
  getSfdcAmsFormData,
  getCarrierUnderwriterListForSfdc,
  setAMSDetailsSuccess,
  setAMSLOBChanged,
  setInsuredDropDownLoader,
  setLineOfBusiness,
  setMaxAttemptReached,
  setPolicyHeadersResponse,
  setSfdcAMSDetailsLoader,
  setSfdcAmsFormData,
  setSfdcOrganizationDetails,
  sfdcAmsDetailsSubmit,
  showAMSModal,
  showLOBAMSFormDetails,
} from '../../redux/actions';
import {
  dateFormat,
  formatNumberToCurrencyWithDecimal,
  getCarrierName,
  getCarrierNameFromCarrierList,
  getCarrierOptionList,
  getCarrierPremium,
  getCarrierPremiumTerm,
  getHerdIdFromUrl,
  getLineOfBusiness,
  getQuoteListOfLob,
  getStartCase,
  numberRegex,
  phoneRegex,
  removeExtraSpaces,
  segregateListOptions,
  showEoiFileUpload,
  stringRegex,
  stringWithSpaceRegex,
  validateAlphaNumeric,
  validateDOB,
  validateEmail,
  validatePhoneNumber,
  validatePremiumValue,
  validateUUID,
} from '../../../utils';
import {
  getIsShowSl2OrDueDiligenceForm,
  isShowCarrierRiskConsentCheckbox,
} from '../../../utils/ams-details';
import {
  AMS_EFFECTIVE_DATE_DURATION,
  AMS_EXCLUDE_PROPS,
  AMS_FORM_FIELD,
  BUSINESS_LINE,
  CANCEL,
  CAROUSEL,
  CITY,
  CONTINUE,
  COUNTRY,
  CRM,
  DROP,
  EARTH_QUAKE_LOB,
  EFFECTIVE_DATE_KEY,
  HOME_OWNERS,
  INSURANCE_PRODUCTS,
  INVALID,
  INVALID_PDF_FILE_ERROR,
  PDF_TYPE,
  PRODUCT,
  PREMIUM_VALIDATION_DETAILS,
  REQUIRED,
  OTHER_CARRIERS,
  POLICY_NUMBER_KEY,
  PREMIUM,
  QUOTED_CARRIERS,
  SELECTED_CARRIER,
  SHOW_LOAN_NUMBER_FOR_LOB,
  STATEDATA,
  UNIT,
  UPLOAD,
  UPLOAD_AMS_DOCUMENT_TITLE,
  ZIP,
} from '../../../constants';
import config from '../../../config/config';
import ThemesImages from '../themes-images';
import './sfdc-ams-details.scss';
const { DUE_DILIGENCE_FORM_DOCUMENT, SL2_FORM_DOCUMENT, EOI_DOCUMENT } = AMS_FORM_FIELD;

const getAddressDetails = (address: any) => {
  return `${get(address, 'street', '')} ${get(address, 'unit', '')} ${get(
    address,
    'city',
    ''
  )} ${get(address, 'state', '').toUpperCase()} ${get(address, 'zip', '')}`;
};

const styles = () =>
  createStyles({
    title: {
      fontSize: 16,
      textAlign: 'center',
    },
    successImg: {
      width: 500,
      height: 500,
      marginBottom: 30,
    },
    lineDivider: {
      marginBottom: 0,
    },
    dialogContent: {
      padding: '8px 24px 20px 24px',
    },
    buttonWrapper: {
      margin: '0 auto',
      marginTop: 15,
      marginBottom: 20,
    },
    fieldError: {
      position: 'relative',
      top: -4,
      color: '#FF0000',
      height: '0px',
      fontSize: '0.70rem',
    },
    closeButton: {
      position: 'absolute',
      width: 20,
      height: 20,
      right: 10,
      top: 10,
      zIndex: 1,
    },
    submitSpinner: {
      color: 'white !important',
      marginBottom: '2px',
      marginRight: '0px',
    },
    commonError: {
      fontSize: '0.75rem',
      padding: '0px 0px 0px 22px',
      top: '5px',
    },
    errorText: {
      fontSize: '12px',
      position: 'relative',
      top: -2,
      color: '#FF0000',
      height: '0px',
      display: 'flex',
    },
    addressTextField: {
      width: '100%',
    },
    formControl: {
      minWidth: '100%',
      marginBottom: '0px',
      margin: '0 0px !important',
    },
    table: {
      minWidth: 650,
    },
    tableHead: {
      whiteSpace: 'nowrap',
      borderBottom: '3px solid #808080a1',
    },
    tableHeadRow: {
      padding: '8px 16px',
      background: '#eee',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      boxShadow: 'inset 0px 0px #000, 0 1px #808080a1',
    },
    tableRow: {
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      '&:nth-of-type(odd)': {
        backgroundColor: '#f4fffa',
      },
    },
    tableBodyRow: {
      position: 'sticky',
      left: 0,
    },
    fieldWidth: {
      whiteSpace: 'pre-line',
      width: '25%',
    },
  });
const headCells = [
  {
    id: 'select',
  },
  {
    id: 'first_name',
    label: 'First Name',
  },
  {
    id: 'last_name',
    label: 'Last Name',
  },
  { id: 'address', label: 'Address' },
  { id: 'email', label: 'Email' },
];

const INIT_FORM_ERRORS: any = {
  effectiveDateError: '',
  carrierNameError: '',
  premiumError: '',
  termError: '',
  carrierUnderwriterError: '',
  policyNumberError: '',
  divisionError: '',
  executiveError: '',
  representativeError: '',
  groupError: '',
  departmentError: '',
  employeeError: '',
  brokerError: '',
  street: '',
  unit: '',
  city: '',
  state: '',
  zip: '',
  firstNameError: '',
  lastNameError: '',
  phoneNumberError: '',
  businessLineError: '',
  emailError: '',
  dobError: '',
  eoiFileError: '',
  productError: '',
  customId: '',
  loanNumber: '',
  salesforceCustomerUUIDErr: '',
  carrierRiskConsentedError: '',
  sl2FormFileNameError: '',
  dueDiigenceFormFileNameError: '',
};

const INIT_FORM_DETAILS: any = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  dob: '',
  address: '',
  addressDetails: {
    street: '',
    unit: '',
    city: '',
    zip: '',
    state: '',
  },
  businessLine: '',
  effectiveDate: '',
  carrierName: '',
  selectedCarrier: '',
  premium: '',
  term: '',
  carrierUnderwriter: '',
  policyNumber: '',
  division: '',
  executive: '',
  representative: '',
  group: '',
  department: '',
  employee: '',
  broker: '',
  carrierOptions: {},
  carrierUnderwriterOptions: [],
  divisionOptions: [],
  executiveOptions: [],
  representativeOptions: [],
  groupOptions: [],
  departmentOptions: [],
  employeeOptions: [],
  brokerOptions: [],
  lobOption: [],
  existingCustomer: [],
  eoiFile: null,
  selectedLOB: '',
  product: '',
  customId: '',
  loanNumber: '',
  assistedBy: '',
  salesforceCustomerUUID: '',
  isCarrierRiskConsented: false,
  sl2FormFile: null,
  dueDiigenceFormFile: null,
};

const INIT_PREMIUM_MODAL_DETAILS: AppComponents.PremiumModalDetails = {
  isShowModal: false,
  heading: '',
  message: '',
  contentValue: '',
};

class SfdcAMSDetails extends React.Component<
  AppComponents.SfdcAMSDetailsProps & WithStyles<typeof styles>,
  AppComponents.SfdcAMSDetailsState
> {
  static propTypes = {
    quoteList: PropTypes.array,
  };
  state: AppComponents.SfdcAMSDetailsState = {
    formSubmitted: false,
    formDetails: { ...INIT_FORM_DETAILS },
    formDetailsErrors: { ...INIT_FORM_ERRORS },
    open: false,
    commonError: '',
    AMSLOBChanged: false,
    stateName: [...STATEDATA.states],
    dataClear: false,
    searchText: '',
    selectedLobQuoteListData: [],
    eoiFileTypeError: '',
    eoiFileName: '',
    selectedCarrierId: '',
    selectedLineOfBuissness: '',
    isAdhocHomeLob: false,
    isEoiFileUploadEnabled: false,
    isShowLoanNumberField: false,
    premiumModalDetails: { ...INIT_PREMIUM_MODAL_DETAILS },
    isPrimumConfirmed: false,
    sl2FormFileName: '',
    dueDiigenceFormFileName: '',
    fileTypeError: '',
    isOpenUploadAMSDocumentModal: false,
    amsUploadDocumentFieldName: '',
  };

  componentDidMount() {
    const { common, ratePageTypes } = this.props;
    const { herdId } = getHerdIdFromUrl();
    let lobOption = getLineOfBusiness();
    let isShowLoanNumberField: boolean = false;
    let selectedLob: string =
      !!ratePageTypes && ratePageTypes !== CAROUSEL
        ? common?.selectedBusinessOption?.toLowerCase()
        : lobOption[0]?.toLowerCase();

    this.props.getSfdcAmsFormData({
      herdId: herdId,
    });

    if (this.props.open !== this.state.open && !this.props.isQuote) {
      this.setState({
        open: this.props.open,
      });
      this.props.showLOBAMSFormDetails(true);
    }
    isShowLoanNumberField = SHOW_LOAN_NUMBER_FOR_LOB.includes(selectedLob) || false;
    if (!isEmpty(selectedLob)) {
      this.props.setLineOfBusiness(selectedLob);
      this.setState((prevState: any) => ({
        ...prevState,
        selectedLineOfBuissness: selectedLob,
        isShowLoanNumberField: isShowLoanNumberField,
        isAdhocHomeLob:
          selectedLob?.toLowerCase() === INSURANCE_PRODUCTS[0] ||
          selectedLob?.toLowerCase() === HOME_OWNERS
            ? true
            : false,
      }));
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.SfdcAMSDetailsProps) {
    const { selectedLineOfBuissness, selectedCarrierId } = this.state;
    if (newProps.open !== this.state.open) {
      this.setState({
        open: newProps.open,
      });
    }
    if (
      !isEqual(newProps.common.sfdcAmsFormData, this.state.formDetails) &&
      !this.state.formSubmitted &&
      isEmpty(this.state.commonError) &&
      !this.state.dataClear
    ) {
      const {
        effectiveDate,
        businessLine,
        policyNumber,
        carrierName,
        executive,
        representative,
        group,
        department,
        employee,
        broker,
        phoneNumber,
        addressDetails,
        firstName,
        lastName,
        email,
        dob,
        product,
        selectedLOB,
        address,
        customId,
        loanNumber,
        eoiFile,
        assistedBy,
        salesforceCustomerUUID,
        sl2FormFile,
        dueDiigenceFormFile,
        carrierUnderwriter,
      } = this.state.formDetails;
      const { sfdcAmsFormData } = newProps.common;
      let selectedLobDetails: any = this.getSelectedLobFromList(sfdcAmsFormData?.lob);
      this.setState(
        prevState => ({
          formDetails: {
            ...this.state.formDetails,
            ...sfdcAmsFormData,
            address:
              removeExtraSpaces(getAddressDetails(sfdcAmsFormData?.address)) || address || '',
            addressDetails: {
              street:
                this.getPropertyValue(addressDetails?.street, sfdcAmsFormData?.address?.street) ||
                '',
              unit:
                this.getPropertyValue(addressDetails?.unit, sfdcAmsFormData?.address?.unit) || '',
              city:
                this.getPropertyValue(addressDetails?.city, sfdcAmsFormData?.address?.city) || '',
              zip: this.getPropertyValue(addressDetails?.zip, sfdcAmsFormData?.address?.zip) || '',
              state:
                this.getPropertyValue(addressDetails?.state, sfdcAmsFormData?.address?.state) || '',
            },
            effectiveDate: this.getPropertyValue(effectiveDate, sfdcAmsFormData?.effectiveDate),
            policyNumber: this.getPropertyValue(policyNumber, sfdcAmsFormData?.policyNumber),
            carrierName: !!prevState?.formDetails?.carrierName
              ? prevState?.formDetails?.carrierName.trim()
              : !!carrierName
              ? carrierName?.trim()
              : this.getPropertyValue(carrierName, sfdcAmsFormData?.carrierName),
            selectedCarrier: prevState?.formDetails?.selectedCarrier,
            premium: prevState?.formDetails?.premium
              ? prevState.formDetails.premium
              : sfdcAmsFormData?.premium,
            division: prevState?.formDetails?.division
              ? prevState.formDetails.division
              : sfdcAmsFormData?.division,
            term: !!prevState?.formDetails?.term
              ? prevState.formDetails.term
              : sfdcAmsFormData?.term,
            executive: this.getPropertyValue(executive, sfdcAmsFormData?.executive),
            representative: this.getPropertyValue(representative, sfdcAmsFormData?.representative),
            group: this.getPropertyValue(group, sfdcAmsFormData?.group),
            department: this.getPropertyValue(department, sfdcAmsFormData?.department),
            employee: this.getPropertyValue(employee, sfdcAmsFormData?.employee),
            broker: this.getPropertyValue(broker, sfdcAmsFormData?.broker),
            businessLine: businessLine || selectedLobDetails?.Description || '',
            phoneNumber: this.getPropertyValue(
              phoneNumber,
              sfdcAmsFormData?.personalInformation?.phone
            ),
            firstName: this.getPropertyValue(
              firstName,
              sfdcAmsFormData?.personalInformation?.firstName
            ),
            lastName: this.getPropertyValue(
              lastName,
              sfdcAmsFormData?.personalInformation?.lastName
            ),
            email: this.getPropertyValue(email, sfdcAmsFormData?.personalInformation?.email),
            dob: this.getPropertyValue(dob, sfdcAmsFormData?.personalInformation?.dateOfBirth),
            product: this.getPropertyValue(product, sfdcAmsFormData?.product) || '',
            selectedLOB: selectedLOB || selectedLobDetails?.LineOfBusinessCode || '',
            customId: this.getPropertyValue(customId, sfdcAmsFormData?.customId) || '',
            loanNumber: this.getPropertyValue(loanNumber, sfdcAmsFormData?.loanNumber) || '',
            salesforceCustomerUUID:
              this.getPropertyValue(
                salesforceCustomerUUID,
                sfdcAmsFormData?.salesforceCustomerUUID
              ) || '',
            eoiFile: eoiFile || null,
            assistedBy: !sfdcAmsFormData?.isPolicyBounded
              ? this.getPropertyValue(assistedBy, sfdcAmsFormData?.assistedBy)
              : !isEmpty(sfdcAmsFormData?.policyData?.assistedByName)
              ? sfdcAmsFormData?.policyData?.assistedByName
              : '',
            sl2FormFile: sl2FormFile || null,
            dueDiigenceFormFile: dueDiigenceFormFile || null,
            isCarrierRiskConsented: !sfdcAmsFormData?.isPolicyBounded
              ? sfdcAmsFormData?.isCarrierRiskConsented
              : sfdcAmsFormData?.policyData?.isCarrierRiskConsented,
            carrierUnderwriter: carrierUnderwriter || '',
          },
          isEoiFileUploadEnabled: this.state.isEoiFileUploadEnabled || false,
          isShowLoanNumberField:
            (!isEmpty(businessLine) &&
              SHOW_LOAN_NUMBER_FOR_LOB.includes(businessLine?.toLowerCase())) ||
            prevState.isShowLoanNumberField ||
            false,
          isAdhocHomeLob:
            (!isEmpty(businessLine) && businessLine?.toLowerCase() === HOME_OWNERS) ||
            this.state.isAdhocHomeLob ||
            false,
        }),
        () => {
          const { isPolicyBounded, policyData } =
            !!newProps?.common?.sfdcAmsFormData && newProps?.common?.sfdcAmsFormData;
          this.checkEoiUploadEnabled();
          if (!isEmpty(policyData) && !!isPolicyBounded) {
            this.setBoundedAMSData(policyData);
          }
        }
      );
    }

    if (newProps.home.AMSLOBChanged !== this.state.AMSLOBChanged) {
      const { formDetails } = this.state;
      !!formDetails?.businessLine &&
        this.props.setLineOfBusiness(formDetails.businessLine.toLowerCase());
      this.setState({
        AMSLOBChanged: newProps.home.AMSLOBChanged,
        formDetails: {
          ...formDetails,
          ...newProps.common.sfdcAmsFormData,
          businessLine: formDetails.businessLine || newProps?.common?.sfdcAmsFormData?.lob,
          division: formDetails.division,
          department: formDetails.department,
        },
      });
    }
    if (!isEmpty(newProps?.common?.SfdcAMSCommonError) && isEmpty(this.state.commonError)) {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierUnderwriterOptions: [...newProps.common.sfdcAmsFormData.carrierUnderwriterOptions],
        },
        commonError: newProps?.common?.SfdcAMSCommonError || '',
      });
    }
    if (
      !isEmpty(selectedLineOfBuissness) &&
      !isEmpty(newProps?.common?.AMSLOBDetails?.carrierList) &&
      !!newProps?.common?.isGetPolicyHeaderSuccess &&
      !!selectedCarrierId
    ) {
      let selectedCarrierName = getCarrierNameFromCarrierList(
        selectedLineOfBuissness === INSURANCE_PRODUCTS[3]
          ? EARTH_QUAKE_LOB
          : selectedLineOfBuissness,
        selectedCarrierId
      );

      this.props.setSfdcAmsFormData({ carrierName: selectedCarrierName });
      this.props.getCarrierUnderwriterListForSfdc({
        carrierId: selectedCarrierId,
      });
      this.props.setPolicyHeadersResponse(false);
      this.setState((prevState: any) => ({
        ...prevState,
        selectedLineOfBuissness: '',
        formDetails: {
          ...this.state.formDetails,
          carrierName: selectedCarrierName,
        },
      }));
    }
  }

  componentWillUnmount() {
    this.props.setSfdcAmsFormData({ ...INIT_FORM_DETAILS, lob: '' });
    this.props.setSfdcOrganizationDetails({});
    this.setState({
      open: false,
      formSubmitted: false,
      formDetails: { ...INIT_FORM_DETAILS },
      formDetailsErrors: { ...INIT_FORM_ERRORS },
      commonError: '',
      AMSLOBChanged: false,
    });
  }

  getSelectedLobFromList = (lob: string) => {
    let selectedDetails: any = {
      Description: '',
      IncomeGroup: '',
      IsInactive: '',
      LineOfBusinessCode: '',
      TypeOfBusinessCode: '',
    };
    if (!isEmpty(lob) && !!this?.props?.common?.sfdcAmsFormData?.lobOption) {
      const { lobOption } = this?.props?.common?.sfdcAmsFormData;
      selectedDetails = lobOption.find(
        (item: any) =>
          item?.Description?.toLowerCase() === lob?.toLowerCase() ||
          item?.LineOfBusinessCode?.toLowerCase() === lob?.toLowerCase() ||
          item?.LineOfBusinessCode === lob
      );
    }
    return selectedDetails;
  };

  setBoundedAMSData = (policyData: any) => {
    const { sfdcAmsFormData } = !!this?.props?.common && this?.props?.common;
    if (!isEmpty(policyData) && !isEmpty(sfdcAmsFormData)) {
      this.setState((prevState: any) => ({
        ...prevState,
        formDetails: {
          ...prevState.formDetails,
          carrierName: policyData?.carrierName || '',
          premium: policyData?.premium || '',
          term: policyData?.term || '',
          carrierUnderwriter: policyData?.carrierUnderwriterName || '',
          policyNumber: policyData?.policyNumber || '',
          division: policyData?.divisionName || '',
          executive: policyData?.executiveName || '',
          representative: policyData?.representativeName || '',
          group: policyData?.groupName || '',
          department: policyData?.departmentName || '',
          employee: policyData?.employeeName || '',
          broker: policyData?.brokerName || '',
          eoiFile: policyData?.eoiFileUrl || null,
          product: policyData?.product || sfdcAmsFormData?.product || '',
          customId: sfdcAmsFormData?.customId || policyData?.customId || '',
          loanNumber: sfdcAmsFormData?.loanNumber || policyData?.loanNumber || '',
          assistedBy: policyData?.assistedByName || '',
          salesforceCustomerUUID:
            sfdcAmsFormData?.salesforceCustomerUUID || policyData?.salesforceCustomerUUID || '',
        },
        eoiFileName: policyData?.eoiFileName || '',

      }));
    }
  };

  checkEoiUploadEnabled = () => {
    const { home } = this.props;
    const { formDetails } = this.state;
    let eoiLob: any = formDetails?.selectedLOB || formDetails?.businessLine || '';
    if (
      !isEmpty(eoiLob) &&
      (eoiLob?.toLowerCase() === INSURANCE_PRODUCTS[0] || eoiLob?.toLowerCase() === HOME_OWNERS)
    ) {
      eoiLob = !isEmpty(formDetails?.product) ? formDetails?.product : home?.productType;
    }

    this.setState((prevState: any) => ({
      ...prevState,
      isEoiFileUploadEnabled: !!showEoiFileUpload(eoiLob) ? true : false,
    }));
  };

  getAutoAMSSuccessModal = () => {
    const { home, classes } = this.props;
    let themeType = !!home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
      ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
      : config.hippo.template_name;
    return (
      <div className='text-center'>
        <img
          alt='AMS Success'
          src={`${ThemesImages[themeType].amsSuccessImage}?time=${new Date().getTime()}`}
          className={`${classes.successImg} bg-white`}
        />
      </div>
    );
  };

  onChangeDocumentModel = (flag: boolean, key: string) => {
    const stateData: any = { ...this.state };
    stateData.isOpenUploadAMSDocumentModal = flag;
    stateData.amsUploadDocumentFieldName = key;
    if (!flag) {
      stateData[`${key}Name`] = '';
      stateData.fileTypeError = '';
      stateData.formDetails[key] = null;
      stateData.amsUploadDocumentFieldName = '';
    }
    this.setState({
      ...stateData,
    });
  };

  getFileName = () => {
    const { amsUploadDocumentFieldName, sl2FormFileName, dueDiigenceFormFileName, eoiFileName } =
      this.state;
    if (isEmpty(amsUploadDocumentFieldName)) {
      return '';
    }
    return amsUploadDocumentFieldName === SL2_FORM_DOCUMENT.KEY
      ? sl2FormFileName
      : amsUploadDocumentFieldName === DUE_DILIGENCE_FORM_DOCUMENT.KEY
      ? dueDiigenceFormFileName
      : amsUploadDocumentFieldName === EOI_DOCUMENT.KEY
      ? eoiFileName
      : '';
  };

  onUploadOrDropAMSDocument = (event: any, key: string, type: string) => {
    event.preventDefault();
    const stateData: any = { ...this.state };
    const { files } = type === UPLOAD ? event.target : event.dataTransfer;
    if (!!files[0]?.type && files[0]?.type !== PDF_TYPE) {
      stateData[`${key}Name`] = '';
      stateData.fileTypeError = INVALID_PDF_FILE_ERROR;
    } else {
      stateData[`${key}Name`] = files[0]?.name;
      stateData.fileTypeError = '';
      stateData.isOpenUploadAMSDocumentModal = false;
      stateData.formDetails[key] = files[0];
      stateData.amsUploadDocumentFieldName = '';
    }
    this.setState({
      ...stateData,
    });
  };

  onClearAmsDocumentUpload = (key: string) => {
    const stateData: any = { ...this.state };
    stateData[`${key}Name`] = '';
    stateData.fileTypeError = '';
    stateData.formDetails[key] = null;
    this.setState({
      ...stateData,
    });
  };

  getPropertyValue = (value: any, propValue: any) => (!!value ? value : propValue);

  dropDownValuesUpdated = (newData: any, label: string) => {
    let formDetails: any = {
      ...this.state.formDetails,
      [label]: !isEmpty(newData) ? newData : '',
    };
    let formDetailsErrors: any = {
      ...this.state.formDetailsErrors,
    };
    let isAdhocHomeLob: boolean = this.state.isAdhocHomeLob;
    let isShowLoanNumberField: boolean = this.state.isShowLoanNumberField;
    if (label === BUSINESS_LINE) {
      const { lobOption } = this.state.formDetails;
      let selectedLob: any = lobOption.find((lob: any) => lob.Description === newData);
      formDetails.selectedLOB = !isEmpty(selectedLob?.LineOfBusinessCode)
        ? selectedLob.LineOfBusinessCode
        : '';
      isShowLoanNumberField = SHOW_LOAN_NUMBER_FOR_LOB.includes(
        formDetails?.selectedLOB?.toLowerCase()
      );
      formDetailsErrors.productError =
        !isEmpty(formDetails.businessLine) &&
        formDetails?.businessLine?.toLowerCase() === HOME_OWNERS
          ? formDetailsErrors.productError
          : '';
      if (!isShowLoanNumberField) {
        formDetails.loanNumber = '';
        formDetailsErrors.loanNumber = '';
      }
      if (
        (!isEmpty(formDetails?.selectedLOB) &&
          formDetails?.selectedLOB?.toLowerCase() !== INSURANCE_PRODUCTS[0]) ||
        (!isEmpty(formDetails?.businessLine) &&
          formDetails?.businessLine?.toLowerCase() !== HOME_OWNERS)
      ) {
        formDetails.product = '';
      }
      isAdhocHomeLob =
        !isEmpty(formDetails.businessLine) &&
        formDetails?.businessLine?.toLowerCase() === HOME_OWNERS
          ? true
          : false;
    }
    this.setState(
      {
        ...this.state,
        formDetails:
          label === SELECTED_CARRIER
            ? {
                ...this.state.formDetails,
                selectedCarrier: newData,
                carrierName: newData?.Name,
                carrierUnderwriter: '',
              }
            : formDetails,
        formDetailsErrors: formDetailsErrors,
        isAdhocHomeLob: isAdhocHomeLob,
        isShowLoanNumberField: isShowLoanNumberField,
      },
      () => {
        label === SELECTED_CARRIER &&
          this.setCarrierUnderwriter(!isEmpty(newData?.Name) ? newData?.Name : '');
        this.checkEoiUploadEnabled();
        if (
          label === BUSINESS_LINE &&
          !showEoiFileUpload(newData === HOME_OWNERS ? INSURANCE_PRODUCTS[0] : newData)
        ) {
          this.onClearAmsDocumentUpload(EOI_DOCUMENT.KEY);
          this.onClearAmsDocumentUpload(DUE_DILIGENCE_FORM_DOCUMENT.KEY);
          this.onClearAmsDocumentUpload(SL2_FORM_DOCUMENT.KEY);
        }
      }
    );
  };

  setCarrierUnderwriter = (selectedValue: string) => {
    this.setState({
      commonError: '',
    });
    const { carrierOptions } = this.state.formDetails;

    if (selectedValue) {
      const selectedCarrier: any = find(
        getCarrierOptionList(carrierOptions),
        (carrierDetails: any) => carrierDetails.Name === selectedValue
      );
      this.setState(
        {
          formDetails: {
            ...this.state.formDetails,
            term: getCarrierPremiumTerm(null),
          },
        },
        () => {
          if (!isEmpty(selectedCarrier.CompanyCode)) {
            this.props.getCarrierUnderwriterListForSfdc({
              carrierCode: selectedCarrier.CompanyCode,
            });
          }
        }
      );
    } else {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierUnderwriter: '',
          carrierUnderwriterOptions: [],
        },
      });
    }
  };

  handleInputChange = ({ target }: any) => {
    if (target.name === BUSINESS_LINE) {
      const amsFormData = {
        ...this.state,
        formDetails: {
          ...this.state.formDetails,
          carrierName: '',
          premium: '',
          term: '',
          [target.name]: target.value,
          isCarrierRiskConsented: false,
        },
        formDetailsErrors: {
          ...this.state.formDetailsErrors,
        },
        selectedLineOfBuissness: '',
        isShowLoanNumberField: false,
      };
      if (!SHOW_LOAN_NUMBER_FOR_LOB.includes(target.value)) {
        amsFormData.formDetails.loanNumber = '';
        amsFormData.formDetailsErrors.loanNumber = '';
      } else amsFormData.isShowLoanNumberField = true;
      this.setState(
        {
          ...amsFormData,
        },
        () => {
          this.props.setAMSLOBChanged(target.value);
          this.props.setSfdcAmsFormData({
            customId: amsFormData?.formDetails?.customId || '',
            loanNumber: amsFormData?.formDetails?.loanNumber || '',
            salesforceCustomerUUID: get(amsFormData, 'formDetails.salesforceCustomerUUID ', ''),
            isCarrierRiskConsented: false,
          });
        }
      );
      this.onClearAmsDocumentUpload(EOI_DOCUMENT.KEY);
      this.onClearAmsDocumentUpload(DUE_DILIGENCE_FORM_DOCUMENT.KEY);
      this.onClearAmsDocumentUpload(SL2_FORM_DOCUMENT.KEY);
    } else {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          [target.name]: target.value,
        },
      });
    }
  };

  handleClose = (isClose: boolean) => {
    this.setState({
      open: isClose,
      formSubmitted: false,
      formDetails: { ...INIT_FORM_DETAILS },
      formDetailsErrors: { ...INIT_FORM_ERRORS },
      commonError: '',
      AMSLOBChanged: false,
    });

    this.props.setMaxAttemptReached(false);
    this.props.setInsuredDropDownLoader(false);
    this.props.closehandler();
  };

  checkDateValidations = (dateValue: string) => {
    const startDate = moment().subtract(AMS_EFFECTIVE_DATE_DURATION, 'months').format(dateFormat);
    if (!moment(dateValue).isValid()) {
      return INVALID;
    } else if (!moment(startDate).isBefore(dateValue)) {
      return INVALID;
    } else {
      return '';
    }
  };

  checkForOtherValidations = (key: string, errorDetails: any) => {
    const details = { ...errorDetails };
    if (key === EFFECTIVE_DATE_KEY) {
      details[`${key}Error`] = this.checkDateValidations(this.state.formDetails[key]);
    } else if (key === POLICY_NUMBER_KEY && /[&"]/.test(String(this.state.formDetails[key]))) {
      details[`${key}Error`] = INVALID;
    } else if (key === PREMIUM.toLowerCase() && parseFloat(this.state.formDetails[key]) === 0) {
      details[`${key}Error`] = INVALID;
    } else {
      details[`${key}Error`] = '';
    }
    return details;
  };

  checkFormValues = () => {
    const { formDetails, formDetailsErrors, isAdhocHomeLob, isEoiFileUploadEnabled } = this.state;
    let errorDetails: any = { ...formDetailsErrors };
    if (!isEoiFileUploadEnabled) {
      this.onClearAmsDocumentUpload(EOI_DOCUMENT.KEY);
    }
    errorDetails.addressError = isEmpty(formDetails?.address) ? REQUIRED : '';
    this.setState({
      formDetailsErrors: { ...INIT_FORM_ERRORS },
    });
    let excludeValdationCheckList: Array<string> = [
      'lob',
      'product',
      'selectedLOB',
      'customId',
      'loanNumber',
      'carrierId',
      'assistedBy',
      'salesforceCustomerUUID',
      'sl2FormFile',
      'dueDiigenceFormFile',
    ];
    forEach(omit(formDetails, AMS_EXCLUDE_PROPS), (element, key) => {
      if (!excludeValdationCheckList.includes(key)) {
        if (isEmpty(String(element).trim())) {
          errorDetails[`${key}Error`] = REQUIRED;
        } else {
          errorDetails = this.checkForOtherValidations(key, errorDetails);
        }
      }
      if (key === PRODUCT) {
        errorDetails[`${PRODUCT}Error`] =
          isAdhocHomeLob && isEmpty(formDetails.product) ? REQUIRED : '';
      }
    });
    errorDetails = { ...this.phoneFieldErrorCheck(errorDetails) };
    errorDetails.customId = !isEmpty(formDetails.customId)
      ? validateAlphaNumeric(formDetails.customId)
      : '';
    errorDetails.loanNumber = !isEmpty(formDetails.loanNumber)
      ? validateAlphaNumeric(formDetails.loanNumber)
      : '';
    if (!isEmpty(formDetails.premium))
      errorDetails.premiumError = validatePremiumValue(parseFloat(formDetails.premium));
    errorDetails = {
      ...this.addressErrorCheck(errorDetails),
    };
    errorDetails.emailError = validateEmail(formDetails.email, true);
    if (isEmpty(formDetails.dob)) {
      errorDetails.dobError = REQUIRED;
    } else {
      if (!isEmpty(formDetails.dob)) {
        errorDetails.dobError = validateDOB(formDetails.dob);
      } else {
        errorDetails.dobError = '';
      }
    }
    errorDetails.eoiFileError = !!isEoiFileUploadEnabled && !formDetails?.eoiFile ? REQUIRED : '';
    errorDetails.salesforceCustomerUUIDErr = validateUUID(formDetails?.salesforceCustomerUUID);
    errorDetails.carrierRiskConsentedError =
      isShowCarrierRiskConsentCheckbox(formDetails) && !formDetails?.isCarrierRiskConsented
        ? REQUIRED
        : '';
    const nonAdmitedDocumentName = getIsShowSl2OrDueDiligenceForm(formDetails);
    errorDetails.sl2FormFileNameError =
      nonAdmitedDocumentName === SL2_FORM_DOCUMENT.KEY && !formDetails?.sl2FormFile ? REQUIRED : '';
    errorDetails.dueDiigenceFormFileNameError =
      nonAdmitedDocumentName === DUE_DILIGENCE_FORM_DOCUMENT.KEY &&
      !formDetails?.dueDiigenceFormFile
        ? REQUIRED
        : '';
    if (!every(errorDetails, isEmpty)) {
      this.setState({
        formDetailsErrors: { ...errorDetails },
      });
      return false;
    }
    return true;
  };

  onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const stateData = { ...this.state };
    stateData.formDetails.isCarrierRiskConsented = checked;
    this.setState({
      ...stateData,
    });
    this.props.setSfdcAmsFormData({ isCarrierRiskConsented: checked });
  };

  addressErrorCheck = (formErrors: any) => {
    const error = { ...formErrors };
    forEach(this.state.formDetails.addressDetails, (value, key) => {
      if ((!value || value.length === 0) && key !== UNIT?.toLowerCase() && key !== COUNTRY) {
        error[`${key}`] = REQUIRED;
      } else {
        error[`${key}`] = '';
      }
    });
    return error;
  };

  phoneFieldErrorCheck = (formErrors: any) => {
    const { phoneNumber } = this.state.formDetails;
    const error = { ...formErrors };
    if (phoneNumber.length === 0) {
      error.phoneNumberError = REQUIRED;
    }
    const validUserPhone = validatePhoneNumber(phoneNumber);
    if (phoneNumber.length > 0 && (!phoneRegex.test(phoneNumber) || !validUserPhone)) {
      error.phoneNumberError = INVALID;
    }
    return error;
  };

  handleRadioButtonChange = (customerSelected: any) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        customerId: customerSelected.customerId,
      },
    });
  };

  selectExistingCustomer = (customerData: any) => {
    if (!isEmpty(get(this.state.formDetails, 'customerId', ''))) {
      const customerSelected: any = find(customerData, o => {
        return o.customerId === this.state.formDetails.customerId;
      });

      if (!isEmpty(customerSelected)) {
        this.setState(
          {
            formDetails: {
              ...this.state.formDetails,
              addressDetails: {
                ...customerSelected.address,
                state: get(customerSelected, 'address.state', '').toUpperCase(),
              },
              address: removeExtraSpaces(getAddressDetails(customerSelected.address)),
              email: get(customerSelected, 'email', ''),
              dob: get(customerSelected, 'dob', ''),
              phoneNumber: get(customerSelected, 'phone_number', ''),
              firstName: get(customerSelected, 'first_name', ''),
              middleName: get(customerSelected, 'middle_name', ''),
              lastName: get(customerSelected, 'last_name', ''),
            },
          },
          () => {
            this.props.setSfdcAmsFormData({
              addressDetails: {
                ...customerSelected.address,
                state: get(customerSelected, 'address.state', '').toUpperCase(),
              },
              address: removeExtraSpaces(getAddressDetails(customerSelected.address)),
              email: get(customerSelected, 'email', ''),
              dob: get(customerSelected, 'dob', ''),
              phoneNumber: get(customerSelected, 'phone_number', ''),
              firstName: get(customerSelected, 'first_name', ''),
              middleName: get(customerSelected, 'middle_name', ''),
              lastName: get(customerSelected, 'last_name', ''),
            });
            this.handleFormSubmit();
          }
        );
      }
    }
  };

  handleClosePhoneNumberModal = () => {
    this.props.setSfdcAmsFormData({
      openPopup: false,
    });
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        customerId: null,
      },
    });
  };

  handleCreateNewCustomer = () => {
    this.setState(
      {
        formDetails: {
          ...this.state.formDetails,
          createCustomerFlag: true,
          customerId: null,
        },
      },
      () => {
        this.handleFormSubmit();
      }
    );
  };

  getHeadCells = () => {
    return headCells;
  };

  handleFormSubmit = () => {
    const { common } = this.props;
    const {
      lineOfBusiness,
      sfdcAmsFormData: { openPopup },
    } = !!common && common;
    let quoteList = getQuoteListOfLob(lineOfBusiness);
    if (this.checkFormValues()) {
      const {
        isPrimumConfirmed,
        formDetails: { premium, email },
      } = this.state;
      if (
        !openPopup &&
        !isPrimumConfirmed &&
        parseFloat(premium) > PREMIUM_VALIDATION_DETAILS.MINIMUM_PREMIUM_VALUE_TO_SHOW_MODAL
      ) {
        this.showPremiumModalHandler(parseFloat(premium));
        return false;
      }
      let productDetails: string = '';
      let lob: string = !isEmpty(this.state.formDetails.businessLine)
        ? this.state.formDetails.businessLine?.toLowerCase()
        : '';
      if (
        !isEmpty(lob) &&
        !isEmpty(this.state.formDetails.product) &&
        (lob?.toLowerCase() === INSURANCE_PRODUCTS[0] || lob?.toLowerCase() === HOME_OWNERS)
      ) {
        productDetails = this.state.formDetails.product.toUpperCase();
      }
      this.setState(
        {
          formDetails: {
            ...this.state.formDetails,
            businessLine: !!this?.state?.formDetails?.businessLine
              ? this.state.formDetails.businessLine
              : !!lineOfBusiness
              ? lineOfBusiness
              : '',
            product: productDetails || '',
            phoneNumber: this?.state?.formDetails?.phoneNumber || '',
            email: email?.trim() || '',
          },
          formSubmitted: true,
          commonError: '',
          isPrimumConfirmed: false,
        },
        () => {
          this.props.sfdcAmsDetailsSubmit({
            entity: this.props.type,
            quoteList: [...quoteList],
            amsBindType: CRM,
            ...this.state.formDetails,
          });
        }
      );
      return true;
    }
    return false;
  };

  getCarrier = (carrierId: any) => {
    const { common } = this.props;
    const { lineOfBusiness } = !!common && common;
    let quoteList = getQuoteListOfLob(lineOfBusiness);
    const id = String(carrierId).trim();
    const { businessLine, carrierOptions } = this.state.formDetails;
    const existingCarrier = find(quoteList, quoteDetails => {
      return (
        quoteDetails?.carrier_id === id ||
        quoteDetails?.carrier_id === parseInt(id) ||
        quoteDetails?.carrier_id === carrierId
      );
    });
    return !isEmpty(existingCarrier)
      ? existingCarrier
      : find(getCarrierOptionList(carrierOptions, businessLine), (quoteDetails: any) => {
          return String(quoteDetails.carrierId).trim() === id;
        });
  };

  onCarrierSelected = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { common } = this.props;
    const { AMSDetails } = !!common && common;
    const { selectedFloodQuote } = AMSDetails;
    const { businessLine, carrierOptions } = this.state.formDetails;
    const selectedValue = event.target.value as string;
    this.setState({
      commonError: '',
    });
    if (selectedValue) {
      const selectedCarrier: any = find(
        getCarrierOptionList(carrierOptions, businessLine),
        (carrierDetails: any) => carrierDetails.name === selectedValue
      );
      this.props.setSfdcAmsFormData({ premium: '' });
      if (selectedFloodQuote && selectedFloodQuote.name === selectedValue.toLowerCase()) {
        this.setCarrierNameWithPremiumAndTerm(selectedFloodQuote, selectedCarrier.name);
        this.props.getCarrierUnderwriterListForSfdc({
          carrierId: selectedFloodQuote.carrier_id,
        });
      } else {
        const getListedCarrier = this.getCarrier(selectedCarrier.carrierId);
        this.setCarrierNameWithPremiumAndTerm(getListedCarrier, selectedCarrier.name);
        this.props.getCarrierUnderwriterListForSfdc({
          carrierId: selectedCarrier.carrierId,
        });
      }
    } else {
      this.setState({
        formDetails: {
          ...this.state.formDetails,
          carrierName: '',
          premium: '',
          term: '',
          carrierUnderwriter: '',
          carrierUnderwriterOptions: [],
          isCarrierRiskConsented: false,
        },
      });
    }
    event.stopPropagation();
  };

  onPremiumTermSelected = (event: React.ChangeEvent<{ value: number }>) => {
    const { AMSDetails } = this.props.common;
    const { selectedFloodQuote } = AMSDetails;
    const { formDetails } = this.state;
    let currentPremium = '';
    let currentTerm;
    if (!isEmpty(formDetails.carrierName)) {
      if (
        selectedFloodQuote &&
        selectedFloodQuote.name.includes(formDetails.carrierName.toLowerCase())
      ) {
        currentTerm = getCarrierPremiumTerm(selectedFloodQuote);
        if (String(currentTerm) === String(event.target.value)) {
          currentPremium = getCarrierPremium(selectedFloodQuote);
        }
      } else {
        const { common } = this.props;
        const { lineOfBusiness } = !!common && common;
        let quoteList = getQuoteListOfLob(lineOfBusiness);
        const quoteDetails = find(quoteList, quote => {
          const currentName = String(getCarrierName(quote)).toLowerCase();
          if (currentName.includes(formDetails.carrierName.toLowerCase())) {
            return quote;
          }
          return undefined;
        });

        currentTerm = getCarrierPremiumTerm(quoteDetails);
        if (String(currentTerm) === String(event.target.value)) {
          currentPremium = getCarrierPremium(quoteDetails);
        }
      }
    }
    this.setState({
      formDetails: {
        ...formDetails,
        premium: currentPremium,
        term: event.target.value,
      },
    });
    event.stopPropagation();
  };

  setCarrierNameWithPremiumAndTerm = (carrierDetails: any, carrierName: string) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        carrierName,
        premium: getCarrierPremium(carrierDetails),
        term: !isEmpty(carrierName) ? getCarrierPremiumTerm(carrierDetails) : '',
        carrierUnderwriter: '',
      },
    });
  };

  handleCalendarDate = (date: any) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        effectiveDate: date,
      },
    });
  };

  getListOptions = (list: Array<any>) =>
    map(list, (quote, key) => {
      if (!quote?.quoteError) {
        return (
          <option key={key} value={getCarrierName(quote)}>
            {getCarrierName(quote)}
          </option>
        );
      }
      return '';
    });

  getCarrierOptions = () => {
    const { common } = this.props;
    let selectedLob = !!common?.lineOfBusiness ? common?.lineOfBusiness.toLowerCase() : '';
    let selectedLobQuoteList: any;
    selectedLobQuoteList = getQuoteListOfLob(selectedLob);
    const { existingOptions, remainingOptions } = segregateListOptions(
      selectedLobQuoteList,
      getCarrierOptionList(
        this.state.formDetails.carrierOptions,
        !!selectedLob && selectedLob === INSURANCE_PRODUCTS[3] ? EARTH_QUAKE_LOB : selectedLob
      )
    );

    return (
      <React.Fragment>
        {!isEmpty(existingOptions) && (
          <optgroup label={QUOTED_CARRIERS}>{this.getListOptions(existingOptions)}</optgroup>
        )}

        {!isEmpty(remainingOptions) && (
          <optgroup label={OTHER_CARRIERS}>{this.getListOptions(remainingOptions)}</optgroup>
        )}
      </React.Fragment>
    );
  };

  getCurrentLobs = () => {
    return getLineOfBusiness();
  };

  handleDateOfBirth = (date: any) => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        dob: date,
      },
    });
  };

  clearData = () => {
    this.setState({ dataClear: true }, () => {
      this.setState({
        formSubmitted: false,
        formDetails: {
          ...this.state.formDetails,
          firstName: '',
          lastName: '',
          email: '',
          dob: '',
          phoneNumber: '',
          address: '',
          addressDetails: {
            street: '',
            unit: '',
            city: '',
            zip: '',
            state: '',
          },
          businessLine: '',
          effectiveDate: '',
          carrierName: '',
          premium: '',
          term: '',
          carrierUnderwriter: '',
          policyNumber: '',
          division: '',
          executive: '',
          representative: '',
          group: '',
          department: '',
          employee: '',
          broker: '',
          herdId: '',
          eoiFile: null,
          assistedBy: '',
          sl2FormFile: null,
          dueDiigenceFormFile: null,
        },
        formDetailsErrors: { ...INIT_FORM_ERRORS },
        commonError: '',
        AMSLOBChanged: false,
        dataClear: false,
        searchText: '',
        eoiFileTypeError: '',
        eoiFileName: '',
        sl2FormFileName: '',
        dueDiigenceFormFileName: '',
      });
    });

    this.props.setMaxAttemptReached(false);
    this.props.setSfdcAmsFormData({
      email: '',
      dob: '',
      firstName: '',
      middleName: '',
      lastName: '',
      addressDetails: {
        street: '',
        unit: '',
        city: '',
        zip: '',
        state: '',
      },
      phoneNumber: '',
      address: '',
      lob: '',
      existingCustomer: [],
    });
    this.props.setInsuredDropDownLoader(false);
  };

  handleHomeAddressDetailsChange = (event: any) => {
    const updatedAddress: any = { ...this.state.formDetails.addressDetails };
    if (
      (event.target.name === ZIP &&
        (event.target.value.length > 5 || event.target.value.match(numberRegex) == null)) ||
      (event.target.name === CITY?.toLowerCase() &&
        event.target.value.match(stringWithSpaceRegex) === null)
    ) {
      return false;
    }
    updatedAddress[event.target.name] = event.target.value;
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        addressDetails: {
          ...updatedAddress,
        },
      },
    });
    event.preventDefault();
    return true;
  };

  handleAddressBlur = (e: any) => {
    const updatedAddress: any = { ...this.state.formDetails.addressDetails };
    if (e.target.name === UNIT?.toLowerCase()) {
      updatedAddress[e.target.name] = e.target.value.trim();
    } else {
      updatedAddress[e.target.name] = getStartCase(e.target.value.toLowerCase());
    }
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        addressDetails: {
          ...updatedAddress,
        },
      },
    });
    e.preventDefault();
  };

  clearAddressInputs = () => {
    this.setState({
      formDetails: {
        ...this.state.formDetails,
        addressDetails: {
          ...this.state.formDetails.addressDetails,
          street: '',
        },
      },
    });
  };

  onAddressSelected = (data: any) => {
    this.setState(state => {
      return {
        formDetails: {
          ...this.state.formDetails,
          addressDetails: {
            ...data,
          },
          address: removeExtraSpaces(getAddressDetails(data)),
        },
      };
    });
  };

  onChangeAddressField = (event: any) => {
    const { value } = event.target;
    this.setState((prevState: any) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        address: value,
      },
    }));
  };

  handlePersonalDetails = (event: any) => {
    const { target } = event;
    const fields: Array<string> = ['phoneNumber', 'email'];

    if (
      !isEmpty(target.value) &&
      !stringRegex.test(target.value) &&
      !fields.includes(target.name)
    ) {
      return;
    }

    const updatedFormDetails: any = { ...this.state.formDetails };
    updatedFormDetails[target.name] = target.value;
    this.setState({
      formDetails: { ...updatedFormDetails },
    });
    event.preventDefault();
  };

  handlePersonalDetailsBlur = (event: any) => {
    const { target } = event;
    const updatedFormDetails: any = { ...this.state.formDetails };
    updatedFormDetails[target.name] = getStartCase(target.value.toLowerCase());
    this.setState({
      formDetails: { ...updatedFormDetails },
    });
    event.preventDefault();
  };

  showPremiumModalHandler = (premium: number) => {
    this.setState({
      isPrimumConfirmed: false,
      premiumModalDetails: {
        isShowModal: true,
        heading: '',
        message:
          PREMIUM_VALIDATION_DETAILS.CONFIRMATION_MESSAGE +
          formatNumberToCurrencyWithDecimal(premium),
        contentValue: '',
      },
    });
  };

  premiumConfirmationCloseHandler = (isConfirmed: boolean) => {
    this.setState(
      prestate => {
        return {
          ...prestate,
          isPrimumConfirmed: isConfirmed,
          premiumModalDetails: {
            isShowModal: false,
            heading: '',
            message: '',
            contentValue: '',
          },
        };
      },
      () => {
        if (isConfirmed) this.handleFormSubmit();
      }
    );
  };

  getPremiumConfirmationModal = () => {
    const { isShowModal, heading, message, contentValue } = this.state.premiumModalDetails;
    return (
      <ConfirmationModal
        open={isShowModal}
        closehandler={this.premiumConfirmationCloseHandler}
        heading={heading ? heading : ' '}
        message={message}
        contentValue={contentValue}
        noText={CANCEL}
        yesText={CONTINUE}
      />
    );
  };

  render() {
    const { isOpenUploadAMSDocumentModal, amsUploadDocumentFieldName, fileTypeError } = this.state;
    const { common } = this.props;
    return (
      <>
        {isOpenUploadAMSDocumentModal && (
          <UploadFile
            open={isOpenUploadAMSDocumentModal}
            onClose={flag => this.onChangeDocumentModel(flag, amsUploadDocumentFieldName)}
            onUploadChanges={event =>
              this.onUploadOrDropAMSDocument(event, amsUploadDocumentFieldName, UPLOAD)
            }
            fileTypeError={fileTypeError}
            fileName={this.getFileName()}
            loader={common?.AMSDetailsLoading || common?.carrierUnderwriterLoader}
            onDrop={event =>
              this.onUploadOrDropAMSDocument(event, amsUploadDocumentFieldName, DROP)
            }
            uploadTitle={UPLOAD_AMS_DOCUMENT_TITLE[amsUploadDocumentFieldName] || ''}
          />
        )}
        {!common.isSfdcSubmitSuccess ? template(this) : this.getAutoAMSSuccessModal()}
        {this.getPremiumConfirmationModal()}
      </>
    );
  }
}

const mapStateToProps = ({
  common,
  dashboard,
  home,
  quote,
  floodDetails,
  quakeDetails,
}: AppComponents.SfdcAMSDetailsStore) => {
  return { common, dashboard, home, quote, floodDetails, quakeDetails };
};

const mapDispatchToProps = (dispatch: any): AppComponents.SfdcAMSDetailsDispatch => {
  return bindActionCreators(
    {
      getSfdcAmsFormData,
      getCarrierUnderwriterListForSfdc,
      sfdcAmsDetailsSubmit,
      setAMSLOBChanged,
      setSfdcAmsFormData,
      setSfdcOrganizationDetails,
      setMaxAttemptReached,
      setSfdcAMSDetailsLoader,
      setLineOfBusiness,
      showLOBAMSFormDetails,
      setInsuredDropDownLoader,
      setPolicyHeadersResponse,
      setAMSDetailsSuccess,
      showAMSModal,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // tslint:disable-next-line: max-file-line-count
)(withStyles(styles)<any>(SfdcAMSDetails));
