import React from 'react';
import config from '../../../config/config';
import { connect } from 'react-redux';
import ThemesImages from '../themes-images';
import { INSURANCE_BUSINESS_TYPES } from '../../../constants';

const LobLoader = (props: AppComponents.LobLoaderProps) => {
  const { home, lob } = props;
  let themeType = !!home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;

  const getLoaderImage = (type: any) => {
    switch (type) {
      case INSURANCE_BUSINESS_TYPES.HOME:
        return ThemesImages[themeType].homeLoader;
      case INSURANCE_BUSINESS_TYPES.FLOOD:
        return ThemesImages[themeType].floodLoader;
      case INSURANCE_BUSINESS_TYPES.AUTO:
        return ThemesImages[themeType].autoLoader;
      case INSURANCE_BUSINESS_TYPES.QUAKE:
        return ThemesImages[themeType].quakeLoader;
      case INSURANCE_BUSINESS_TYPES.PET:
        return ThemesImages[themeType].petLoader;
      case INSURANCE_BUSINESS_TYPES.HOMEWARRANTY:
        return ThemesImages[themeType].homeWarrantyLoader;
      case INSURANCE_BUSINESS_TYPES.CYBERSECURITY:
        return ThemesImages[themeType].cyberSecurityLoader;
      case INSURANCE_BUSINESS_TYPES.LIFE:
        return ThemesImages[themeType].lifeLoader;
      case INSURANCE_BUSINESS_TYPES.UMBRELLA:
        return ThemesImages[themeType].umbrellaLoader;
      default:
        return ThemesImages[themeType].homeLoader;
    }
  };

  return <img src={getLoaderImage(lob.toUpperCase())} alt='loader' />;
};

const mapStateToProps = ({ home }: AppComponents.LobLoaderStore): any => ({
  home,
});

export default connect(mapStateToProps, {})(LobLoader);
