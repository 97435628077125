import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, startCase } from 'lodash';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddEditEoiSettings from '../add-edit-eoi-settings';
import ConfirmationModal from '../../../components/confirmation-modal';
import {
  deleteEoiSetting,
  fetchEoiSettings,
  setOrgUpdatedResponseData,
  storeEoiSettingsList,
} from '../../../redux/actions';
import { getEoiSettingsLobDropDown } from '../../utils';
import { stringSort } from '../../../../utils';
import { tableIcons } from '../../../../utils/table-icons';
import { ACTION, ADD, DELETE, EDIT, EOI_SETTINGS, HTML_EM, LOB_KEY } from '../../../../constants';

const createData = (
  id: string,
  organizationId: string,
  lob: string,
  recipients: string,
  subjectLine: string
) => {
  return {
    id,
    organizationId,
    lob,
    recipients,
    subjectLine,
  };
};

export class EoiSettingsTable extends Component<
  AppComponents.EoiSettingsTableProps,
  AppComponents.EoiSettingsTableState
> {
  state: AppComponents.EoiSettingsTableState = {
    columns: [
      {
        title: LOB_KEY,
        field: LOB_KEY?.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '120px',
        },
        cellStyle: {
          minWidth: '100px',
          maxWidth: '120px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.lob, b.lob),
        render: (rowData: any) => {
          return (
            <span title={!isEmpty(rowData?.lob) ? rowData?.lob : ''}>
              {!isEmpty(rowData?.lob) ? rowData?.lob : HTML_EM}
            </span>
          );
        },
      },
      {
        title: EOI_SETTINGS?.RECIPIENTS,
        field: EOI_SETTINGS?.RECIPIENTS.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '120px',
          minWidth: '150px',
        },
        cellStyle: {
          minWidth: '120px',
          maxWidth: '150px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.recipients, b.recipients),
        render: (rowData: any) => {
          return (
            <span title={!isEmpty(rowData?.recipients) ? rowData.recipients : ''}>
              {!isEmpty(rowData?.recipients) ? rowData.recipients : HTML_EM}
            </span>
          );
        },
      },
      {
        title: EOI_SETTINGS.SUBJECT_LINE_LABEL,
        field: EOI_SETTINGS.SUBJECT_LINE_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '120px',
          maxWidth: '150px',
        },
        cellStyle: {
          minWidth: '120px',
          maxWidth: '150px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.subjectLine, b.subjectLine),
        render: (rowData: any) => {
          return (
            <span title={!isEmpty(rowData?.subjectLine) ? rowData.subjectLine : ''}>
              {!isEmpty(rowData?.subjectLine) ? rowData.subjectLine : HTML_EM}
            </span>
          );
        },
      },
    ],
    data: [],
    eoiSettingsRowData: null,
    openAddEoiSettingModel: false,
    openEditEoiSettingModel: false,
    openDeleteEoiSettingModel: false,
  };

  componentDidMount = () => {
    const { editOrganizationData } = this.props;
    this.props?.fetchEoiSettings(editOrganizationData?.id);
    this.mapEoiSettingData();
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.closeModel();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
    if (!organizations?.addEditEoiSettingloader) {
      this.mapEoiSettingData();
    }
  };

  mapEoiSettingData = () => {
    const { eoiSettingsList } = this.props.organizations;
    let rowDetails: Array<any> = [];
    !isEmpty(eoiSettingsList) &&
      eoiSettingsList.map((item: any) => {
        let row = createData(
          !isEmpty(item?.id) ? item.id : '',
          !isEmpty(item?.organizationId) ? item.organizationId : '',
          !isEmpty(item?.lob) ? item?.lob : '',
          !isEmpty(item.recipients) ? item.recipients : '',
          !isEmpty(item?.subjectLine) ? item?.subjectLine : ''
        );
        return rowDetails.push(row);
      });
    this.setState({
      data: rowDetails,
    });
  };

  onEoiSettingAction = (type: any, rowData?: any) => {
    this.setState({
      openAddEoiSettingModel: type === ADD ? true : false,
      openEditEoiSettingModel: type === EDIT?.toLowerCase() ? true : false,
      openDeleteEoiSettingModel: type === DELETE?.toLowerCase() ? true : false,
      eoiSettingsRowData: !!rowData ? rowData : null,
    });
  };

  closeModel = () => {
    this.setState({
      openAddEoiSettingModel: false,
      openEditEoiSettingModel: false,
      openDeleteEoiSettingModel: false,
      eoiSettingsRowData: null,
    });
  };

  deletEoiSettingsData = (data: any) => {
    if (!!data) {
      const { eoiSettingsRowData } = this.state;
      let payload = {
        id: eoiSettingsRowData?.id,
      };
      this.props.deleteEoiSetting({ ...payload });
    }
    this.closeModel();
  };

  componentWillUnmount = () => {
    this.props.storeEoiSettingsList([]);
  };

  render() {
    const {
      columns,
      data,
      openAddEoiSettingModel,
      openEditEoiSettingModel,
      eoiSettingsRowData,
      openDeleteEoiSettingModel,
    } = this.state;
    const { organizations } = this.props;
    const lobList: Array<any> = !isEmpty(getEoiSettingsLobDropDown())
      ? getEoiSettingsLobDropDown()
      : [];
    return (
      <div className='container carrier-wrapper carrier-appointments-wrapper mt-5'>
        <div className='carrier-apooinment-table iframe-setting-tbl'>
          <MaterialTable
            title={
              <>
                {!isEmpty(lobList) ? (
                  <div className='new-org-btn-div'>
                    <Button
                      variant='outlined'
                      className='new-org-btn'
                      onClick={() => this.onEoiSettingAction(ADD, null)}
                    >
                      <AddIcon className='add-icon-border mr-3' />
                      {EOI_SETTINGS.ADD_EOI_SETTING_LABEL}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </>
            }
            icons={tableIcons}
            columns={columns}
            data={data}
            localization={{
              header: {
                actions: ACTION,
              },
            }}
            options={{
              draggable: false,
              search: true,
              paging: true,
              headerStyle: {
                fontSize: '14px',
                color: 'black',
                position: 'inherit',
              },
              actionsColumnIndex: -1,
              pageSizeOptions: [],
            }}
            isLoading={!!organizations?.eoiSettingsListLoader}
            actions={[
              {
                icon: () => <span className='icon-edit1 edit-svg-tbl text-color'></span>,
                tooltip: EDIT,
                onClick: (_e, rowData: any) =>
                  this.onEoiSettingAction(EDIT?.toLowerCase(), rowData),
              },
              {
                icon: () => <span className='icon-delete edit-svg-tbl text-color'></span>,
                tooltip: DELETE,
                onClick: (_e, rowData: any) =>
                  this.onEoiSettingAction(DELETE?.toLowerCase(), rowData),
              },
            ]}
          />
          {(openAddEoiSettingModel || openEditEoiSettingModel) && (
            <>
              <AddEditEoiSettings
                onCloseModal={this.closeModel}
                openEditEoiSettingModel={openEditEoiSettingModel}
                eoiSettingsRowData={eoiSettingsRowData}
              />
            </>
          )}
          {openDeleteEoiSettingModel && (
            <ConfirmationModal
              open={openDeleteEoiSettingModel}
              closehandler={this.deletEoiSettingsData}
              heading={''}
              message={''}
              contentValue={
                !isEmpty(eoiSettingsRowData?.lob) ? startCase(eoiSettingsRowData?.lob) : ''
              }
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.EoiSettingsTableStore) => {
  const { editOrganizationData } = organizations;
  return { organizations, editOrganizationData };
};

const mapDispatchToProps = (dispatch: any): AppComponents.EoiSettingsTableDispatch => {
  return bindActionCreators(
    {
      deleteEoiSetting,
      fetchEoiSettings,
      setOrgUpdatedResponseData,
      storeEoiSettingsList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EoiSettingsTable);
