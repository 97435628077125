import React from 'react';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { connect } from 'react-redux';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import { BULK_QUOTE_ALLOWED_EXTENSIONS, BULK_QUOTE_ERRORS, PAGES_TEXT } from '../../../constants';
import { submitBulkQuoteStep, updateStep, uploadSelectedFile } from '../../redux/actions';
import SpinnerLoader from '../../components/spinner-loader';
import { replaceWithPlaceholder } from '../../../utils';

import './file-uploader.scss';

const styles = (theme: Theme) => ({
  continueSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
});

class FileUploader extends React.Component<
  AppComponents.FileUploaderProps & WithStyles<typeof styles>,
  AppComponents.FileUploaderState
> {
  state: AppComponents.FileUploaderState = {
    entity: 'fileUploader',
    highlight: false,
    fileName: '',
    file: null,
    fileError: '',
  };
  fileDetails: any;
  fileInputRef: any;
  allowedFileExtensions: Array<string>;

  constructor(props: AppComponents.FileUploaderProps & WithStyles<typeof styles>) {
    super(props);
    this.fileInputRef = React.createRef();
    this.allowedFileExtensions = BULK_QUOTE_ALLOWED_EXTENSIONS;
  }

  componentDidMount() {
    const fileChooser: any = document.getElementById('file-uploader-input');
    if (fileChooser) {
      fileChooser.removeAttribute('onclick');
      fileChooser.onclick = function (event: any) {
        this.value = '';
      };
    }
  }

  componentWillReceiveProps(newProps: AppComponents.FileUploaderProps) {
    if (!isEmpty(newProps.serverError) && isEmpty(this.state.fileError)) {
      this.setState({
        fileError: newProps.serverError,
      });
    }
  }

  openFileDialog() {
    if (this.props.selectorLoader) {
      return false;
    }
    this.setState({ fileError: '', fileName: '' });
    this.fileDetails.innerText = '';
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
    return true;
  }

  onFileAdded(event: any) {
    if (event.target.files.length < 1) {
      return false;
    }
    this.addSelectedFile(event.target.files);
    return true;
  }

  onDragOver(event: any) {
    event.preventDefault();
    this.setState({ highlight: true });
  }

  onDragLeave() {
    this.setState({ highlight: false });
  }

  clearFileDetails(fileName: any) {
    this.setState({ fileName: fileName });
    this.fileDetails.innerText = fileName;
    this.props.uploadSelectedFile(null);
  }

  onDrop(event: any) {
    event.preventDefault();
    this.addSelectedFile(event.dataTransfer.files);
    this.setState({ highlight: false });
  }

  addSelectedFile = (files: any) => {
    const array = this.fileListToArray(files);
    if (array[0] && this.fileChecker(array[0].name)) {
      this.props.uploadSelectedFile([array[0]]);
      this.setState({ fileName: array[0].name, file: array, fileError: '' });
      this.fileDetails.innerText = array[0].name;
    }
  };

  fileListToArray(list: any) {
    const array: Array<any> = [];
    forEach(list, (item, i) => array.push(list.item(i)));
    return array;
  }

  deleteUploadedFile = (event: any) => {
    if (this.props.selectorLoader) {
      return false;
    }
    this.setState({ fileError: '', file: null });
    this.clearFileDetails('');
    return true;
  };

  fileChecker = (fileName: string) => {
    const selectedFileExtension: string = fileName.substring(fileName.lastIndexOf('.'));
    if (this.allowedFileExtensions.indexOf(selectedFileExtension) < 0) {
      this.clearFileDetails('');
      this.setState({
        fileError: replaceWithPlaceholder(
          BULK_QUOTE_ERRORS.invalidFileError,
          this.allowedFileExtensions.join(', ').toString()
        ),
      });
      return false;
    }
    return true;
  };

  onNext = () => {
    const { fileError, fileName } = this.state;
    if (fileError.length > 0) {
      return false;
    }
    if (fileName.length === 0) {
      this.setState({
        fileError: BULK_QUOTE_ERRORS.fileSelectionError,
      });
    } else {
      this.setState(
        {
          fileError: '',
        },
        () => {
          this.props.submitBulkQuoteStep({
            entity: this.state.entity,
            data: {},
          });
        }
      );
    }
    return true;
  };

  render() {
    const { classes, selectorLoader } = this.props;
    const { fileError, fileName } = this.state;

    return (
      <React.Fragment>
        <div className='org-divider'>{PAGES_TEXT.bulkQuoteStepThreeHeading}</div>
        <div className='row file-uploader-wrapper'>
          <div className='file-drop-box'>
            <input
              type='file'
              id='file-uploader-input'
              accept={this.allowedFileExtensions.join(',')}
              className='input-file'
              name='file-uploader'
              ref={this.fileInputRef}
              disabled={selectorLoader}
              onChange={this.onFileAdded.bind(this)}
            />
            <label className='file-label'>
              <span
                className={`btn-file file-upload-area ${this.state.highlight ? 'highlight' : ''}`}
                onDragOver={this.onDragOver.bind(this)}
                onDragLeave={this.onDragLeave.bind(this)}
                onDrop={this.onDrop.bind(this)}
                onClick={this.openFileDialog.bind(this)}
                style={{ cursor: 'pointer' }}
              >
                <span className='file-placeholder'>{PAGES_TEXT.fileUploadPlaceholder}</span>
                <span className='file-upload-icon'>
                  <CloudUploadOutlinedIcon className='upload-icon' />
                </span>
              </span>
            </label>
            <div className='upload-error'>{fileError.length > 0 && <span>{fileError}</span>}</div>
            <div>
              <span
                className='filename'
                ref={ref => {
                  this.fileDetails = ref;
                }}
              ></span>

              {fileName.length > 0 && (
                <Tooltip title='Delete' arrow placement='right'>
                  <button onClick={this.deleteUploadedFile} className='delete-button'>
                    <CloseOutlinedIcon className='file-delete-icon' />
                  </button>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='navigation-wrapper'>
            <Button
              variant='outlined'
              className='navigation-back-btn'
              disabled={selectorLoader}
              onClick={() => {
                this.props.uploadSelectedFile(null);
                this.setState({ fileName: '', file: null, fileError: '' }, () => {
                  this.props.updateStep(1);
                });
              }}
            >
              <span className='link-label'>BACK</span>
            </Button>
            <Button
              variant='outlined'
              className='navigation-next-btn'
              disabled={selectorLoader}
              onClick={this.onNext}
            >
              DONE {selectorLoader && <SpinnerLoader styleData={classes.continueSpinner} />}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  bulkQuote,
}: AppComponents.FileUploaderStore): AppComponents.FileUploaderStoreProps => {
  const { selectorLoader, serverError } = bulkQuote;
  return { selectorLoader, serverError };
};

const mapDispatchToProps = (dispatch: any): AppComponents.FileUploaderDispatch => {
  return bindActionCreators(
    {
      submitBulkQuoteStep,
      uploadSelectedFile,
      updateStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(FileUploader));
