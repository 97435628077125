import { createStyles } from '@material-ui/core';
import { get } from 'lodash';
import {
  DEFAULT_PERSONAL_DETAILS_VALUES,
  EDUCATION,
  INDUSTRY,
  INSURANCE_BUSINESS_TYPES,
  OCCUPATION,
  STATEDATA,
} from '../../../constants';
import { ApplicantState } from '../../../pages/redux/reducers/applicant';
import { HomePersonalDetails } from '../../../pages/redux/reducers/home';
import { ReduxRootState } from '../../../pages/redux/store';
import { getDefaultOccupation } from '../../../utils';
import {
  ApplicantAddress,
  ApplicantFormErrors,
  ApplicantPersonalDetails,
  SelectOption,
} from './types';
export const styles = () =>
  createStyles({
    icon: {
      fontSize: '35px',
    },
    continueSpinner: {
      color: 'white !important',
      marginBottom: '2px',
    },
    errorText: {
      color: '#FF0000',
      fontSize: '12px',
      textAlign: 'left',
      marginLeft: '10px',
    },
    homeFormControl: {
      margin: '8px',
      minWidth: '100%',
      marginBottom: '0px',
    },
    homeTextField: {
      width: '100%',
      marginLeft: '8px',
      marginRight: '8px',
    },
  });

export const INIT_ADDRESS = {
  street: '',
  unit: '',
  city: '',
  zip: '',
  state: '',
};

export const INIT_FORM_ERRORS: ApplicantFormErrors = {
  street: '',
  unit: '',
  city: '',
  zip: '',
  state: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  date_of_birth: '',
  loan_officer_phone: '',
  loan_officer_email: '',
  phone_number: '',
  email: '',
  industry: '',
  occupation: '',
  education: '',
  condo: '',
  previousAddress: INIT_ADDRESS,
};

export const shouldHideProductSelection = (keys: string[]): boolean => {
  const disableProductSelectionLobs = [
    'AUTO',
    'PET',
    'HOMEWARRANTY',
    'LIFE',
    'CYBERSECURITY',
    'UMBRELLA',
  ];
  return keys.some(lob => !disableProductSelectionLobs.includes(lob));
};

export const isLifeOrCyberOrUmbrellaQuote = (keys: string[]): boolean => {
  const { isLife, isCyberSecurity, isUmbrella, isHomeWarranty } = getAppointmentChecks(keys);
  let isLifeCyberUmbrella = isLife || isCyberSecurity || isUmbrella;

  const isDoubleCombination =
    keys.length === 2 &&
    (isHomeWarranty ||
      (isLife && isCyberSecurity) ||
      (isLife && isUmbrella) ||
      (isCyberSecurity && isUmbrella));

  const isTripleCombination =
    keys.length === 3 &&
    ((isHomeWarranty && isCyberSecurity && isLife) ||
      (isHomeWarranty && isCyberSecurity && isUmbrella) ||
      (isLife && isCyberSecurity && isUmbrella) ||
      (isHomeWarranty && isUmbrella && isLife));

  const isFourCombination =
    keys.length === 4 && isHomeWarranty && isCyberSecurity && isLife && isUmbrella;

  return (
    isLifeCyberUmbrella &&
    (keys.length === 1 || isDoubleCombination || isTripleCombination || isFourCombination)
  );
};

export const getApplicantDefaultValues = (appointmentKeys: string[]): ApplicantPersonalDetails => {
  const isDefaultEmpty =
    isLifeOrCyberOrUmbrellaQuote(appointmentKeys) ||
    (appointmentKeys.length === 1 &&
      appointmentKeys.includes(INSURANCE_BUSINESS_TYPES.HOMEWARRANTY));
  return {
    ...DEFAULT_PERSONAL_DETAILS_VALUES,
    industry: isDefaultEmpty ? '' : DEFAULT_PERSONAL_DETAILS_VALUES.industry,
    occupation: isDefaultEmpty ? '' : DEFAULT_PERSONAL_DETAILS_VALUES.occupation,
    education: isDefaultEmpty ? '' : DEFAULT_PERSONAL_DETAILS_VALUES.education,
  };
};

export const getAddressDetails = (address?: ApplicantAddress | null): ApplicantAddress => {
  let updatedAddressDetails: ApplicantAddress = {
    street: '',
    city: '',
    unit: '',
    zip: '',
    state: '',
  };
  Object.keys(updatedAddressDetails).forEach(fieldName => {
    const fieldKey = fieldName as keyof ApplicantAddress;
    let value = address?.[fieldKey] ?? '';
    if (fieldKey === 'state') {
      value = value.toUpperCase();
    }
    updatedAddressDetails[fieldKey] = value;
  });

  return updatedAddressDetails;
};

export const getPersonalDetails = (
  personalDetails: HomePersonalDetails | ApplicantPersonalDetails,
  applicant: ApplicantState,
  occupationData: SelectOption[]
): ApplicantPersonalDetails => {
  let updatedPersonalDetails: ApplicantPersonalDetails = {
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    phone_number: '',
    email: '',
    industry: '',
    education: '',
    occupation: '',
    preferredDeductible: '',
    residenceType: '',
  };

  Object.keys(updatedPersonalDetails).forEach(key => {
    const fieldName = key as keyof ApplicantPersonalDetails;
    let fieldValue = personalDetails[fieldName]?.toString() ?? '';
    if ([EDUCATION, INDUSTRY, OCCUPATION].includes(fieldName)) {
      if (fieldName === OCCUPATION && !fieldValue) {
        fieldValue = getDefaultOccupation(
          get(applicant, `personal_details.${fieldName}`, ''),
          occupationData
        );
      }
      updatedPersonalDetails[fieldName] = fieldValue;
    } else {
      updatedPersonalDetails[fieldName] =
        fieldValue || get(applicant, `personal_details.${fieldName}`, '');
    }
  });
  return updatedPersonalDetails;
};

export const getAppointmentChecks = (appointmentKeys: string[]) => {
  let isFlood = false;
  let isAuto = false;
  let isCyberSecurity = false;
  let isHome = false;
  let isHomeWarranty = false;
  let isLife = false;
  let isPet = false;
  let isQuake = false;
  let isUmbrella = false;

  appointmentKeys.forEach(key => {
    if (key === 'FLOOD') {
      isFlood = true;
    }
    if (key === 'AUTO') {
      isAuto = true;
    }
    if (key === 'CYBERSECURITY') {
      isCyberSecurity = true;
    }
    if (key === 'HOME') {
      isHome = true;
    }
    if (key === 'HOMEWARRANTY') {
      isHomeWarranty = true;
    }
    if (key === 'LIFE') {
      isLife = true;
    }
    if (key === 'PET') {
      isPet = true;
    }
    if (key === 'QUAKE') {
      isQuake = true;
    }
    if (key === 'UMBRELLA') {
      isUmbrella = true;
    }
  });
  return {
    isAuto,
    isHome,
    isCyberSecurity,
    isUmbrella,
    isLife,
    isQuake,
    isPet,
    isHomeWarranty,
    isFlood,
  };
};

export const getStateName = (stateInitials: string) => {
  let stateName = '';

  STATEDATA.states.forEach(state => {
    if (stateInitials === state.value) {
      stateName = state.label;
    }
  });
  return stateName;
};

export const selectApplicantFormState = ({
  applicant: { isMovedInPastMonths: propsIsMovedInPastMonths },
  applicant,
  auth: { userId: authUserId = '', email: authEmail = '' },
  common: {
    isPrefillCall,
    isVeriskFetched,
    isVeriskLoading,
    newProducts,
    policyBounded,
    redirectionStep,
    showGetQuoteButton,
  },
  common,
  home: {
    address: propsAddress,
    is_condo,
    pconfig: homePConfig,
    personal_details: existingPersonalDetails,
    previousAddress: propsPreviousAddress,
    productType,
    quoteList,
    quoteListError,
  },
  driver: { driversList },
  floodDetails,
  partner: { appointments, producerDetails, quoteType, storedRequestId },
  petDetails,
  vehicle: { vinLoader },
  viewQuoteDetails,
  quote: { quotingDetails },
  umbrellaDetails: { umbrellaInfo },
  propertyInfoDetails,
  homeQuoteDetails,
}: ReduxRootState) => ({
  applicant,
  appointments,
  authEmail,
  authUserId,
  common,
  driversList,
  existingPersonalDetails,
  floodDetails,
  homePConfig,
  is_condo,
  isPrefillCall,
  isVeriskFetched,
  isVeriskLoading,
  newProducts,
  petDetails,
  policyBounded,
  producerDetails,
  productType,
  propsAddress,
  propsIsMovedInPastMonths,
  propsPreviousAddress,
  quoteList,
  quoteListError,
  quoteType,
  quotingDetails,
  redirectionStep,
  showGetQuoteButton,
  storedRequestId,
  umbrellaInfo,
  viewQuoteDetails,
  vinLoader,
  propertyInfoDetails,
  homeQuoteDetails,
});

export const getAddressFieldError = (errorText: string, addressInvalid: boolean) =>
  errorText.length > 0 || addressInvalid;

export const INIT_PERSONAL_DETAILS: ApplicantPersonalDetails = {
  first_name: '',
  middle_name: '',
  last_name: '',
  date_of_birth: '',
  phone_number: '',
  email: '',
  industry: '',
  occupation: '',
  education: '',
  preferredDeductible: '',
  residenceType: '',
};

export const isApplicantAddressChanged = (address: AppRedux.ApplicantAddress, state: string) => {
  return state?.toLowerCase() !== address?.state?.toLowerCase();
};
