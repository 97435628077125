/* istanbul ignore file */
import { AxiosError } from 'axios';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import axiosRequest from '../../api';
import { get, isEmpty, omit, assignIn, filter } from 'lodash';
import * as lifeDetailsAction from '../constants';
import config from '../../../config/config';
import {
  lifeBridgeToCarrierLoader,
  errorHandler,
  initializeLifePolling,
  lifeQuotesLoader,
  lifeTimeoutPolling,
  storeLifeQuoteDetails,
  setLifeQuoteDetails,
  setLifePollingDetails,
  setLifePollingErrorDetails,
  stopLifePolling,
  storeErrorsForBridgeToCarrier,
  updateLifeQuoteDetails,
  setLobRefreshLoader,
} from '../actions';
import {
  clearLobQuotes,
  createPageTracking,
  simplifyPhoneNumber,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
} from '../../../utils';
import { INSURANCE_PRODUCTS, LOB_REFRESH_TIMEOUT } from '../../../constants';

const pollConfig: any = {
  pollingTimerTimeout: config.pollingTimerTimeout,
  pollingInterval: config.pollingInterval,
};
const displayErrorProperty = 'error_details.display_errors_on_ui';
const errorProperty = 'error_details.errors';

const pollingDelay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
};

function* initializeQuotesPolling() {
  const { lifeDetails: quotesDetails } = yield select();
  if (quotesDetails?.isQuoteRefresh) {
    yield put(
      storeLifeQuoteDetails({
        isQuoteRefresh: false,
      } as any)
    );
  }
  const quoteLimit = get(quotesDetails, 'quotingDetails.totalQuotesToPoll', 0);
  if (quotesDetails?.quoteErrorList?.length < quoteLimit) {
    yield put(initializeLifePolling());
    yield call(pollingDelay, pollConfig.pollingTimerTimeout);
    yield put(lifeTimeoutPolling());
  }
}

function* fetchLifeQuotes(): any {
  try {
    trackFirstLoadQuoteInHeap();
    yield put(
      storeLifeQuoteDetails({
        quoteList: [],
        quoteErrorList: [],
        quotesLoaded: false,
        upcomingCarrierList: [],
      })
    );
    yield put(lifeQuotesLoader(true));
    const {
      applicant,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails, appointments },
      common,
      home,
      lifeDetails,
    } = yield select();
    const keys = Object.keys(appointments);
    yield put(
      storeLifeQuoteDetails({
        quoteStatus: '',
      } as any)
    );
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
        ? producerDetails?.producerEmail
        : '';
    applicant.personal_details.phone_number = applicant?.personal_details?.phone_number ? simplifyPhoneNumber(applicant?.personal_details?.phone_number) : '';
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } = clearLobQuotes();
    let pageTracking = createPageTracking(!clearAutoInfo ? common?.pageTracking : {}, appointments);
    const details = yield axiosRequest('POST', '/life/quotes', isProviderOneLogin, {
      pageTracking,
      lobQuotesToClear, 
      clearAutoInfo, 
      clearPetInfo,
      clearLifeInfo,
      clearUmbrellaInfo,
      personal_information: { ...omit(applicant?.personal_details, 'preferredDeductible') },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: lifeDetails?.herdId || '',
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
      lob: INSURANCE_PRODUCTS[6],
      partner_code: !isEmpty(producerDetails?.producerCode) ? producerDetails.producerCode : quoteBy,
      life_info: lifeDetails?.lifeInfo,
    });
    if (!isEmpty(details?.data)) {
      const { upcomingCarrierList, quotingCarriers } = details.data;
      yield put(
        storeLifeQuoteDetails({
          quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        } as any)
      );
      yield put(updateLifeQuoteDetails({ ...details.data }));
      if (
        get(details.data, displayErrorProperty, false) &&
        !isEmpty(get(details.data, errorProperty, {}))
      ) {
        yield put(setLifePollingErrorDetails({ ...details.data }));
      }
      yield call(initializeQuotesPolling);
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!isEmpty(axiosError?.response?.data?.error_details)) {
      const { error_details, herdId } = axiosError?.response?.data;
      let errorQuotes: any = filter(Object.values(error_details), errorDetails => {
        return assignIn(errorDetails, { quoteError: true });
      });
      yield put(
        setLifeQuoteDetails({
          quoteErrorList: [...Object.values(errorQuotes)],
          herdId,
        })
      );
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[6]);
    }
    yield put(storeLifeQuoteDetails({ quotesLoaded: true } as any));
    yield put(errorHandler(axiosError));
  }
  yield put(lifeQuotesLoader(false));
}

export function* fetchLifeQuotesWatcher() {
  yield takeEvery(lifeDetailsAction.FETCH_LIFE_QUOTES, fetchLifeQuotes);
}

function* pollQuotes(): any {
  while (true) {
    const {
      lifeDetails,
      partner: { isProviderOneLogin },
    } = yield select();
    try {
      if (lifeDetails?.quotingDetails?.herd_id) {
        const { data } = yield axiosRequest(
          'POST',
          '/quotes/poll-quotes',
          isProviderOneLogin,
          {
            herd_id: lifeDetails.quotingDetails.herd_id,
            lob: INSURANCE_PRODUCTS[6],
          }
        );
        if (!isEmpty(data.quotes)) {
          yield put(setLifePollingDetails({ ...data }));
        }
        if (get(data, displayErrorProperty, false) && !isEmpty(get(data, errorProperty, {}))) {
          yield put(setLifePollingErrorDetails({ ...data }));
        }
        const reduxState = yield select();
        const currentQuotesLength =
          reduxState.lifeDetails.quoteList.length + reduxState.lifeDetails.quoteErrorList.length;
        const quoteLimit = get(lifeDetails, 'quotingDetails.totalQuotesToPoll', 0);
        if (currentQuotesLength >= quoteLimit) {
          yield put(lifeQuotesLoader(false));
          yield put(stopLifePolling());
          if (lifeDetails?.isQuoteRefresh) yield put(setLobRefreshLoader(false));
        } else {
          yield call(pollingDelay, pollConfig.pollingInterval);
        }
      }
    } catch (error) {
      yield put(lifeQuotesLoader(false));
      yield put(stopLifePolling());
    }
  }
}

function* pollQuotesWatcher() {
  while (true) {
    yield take(lifeDetailsAction.INIT_LIFE_POLLING);
    yield race([call(pollQuotes), take(lifeDetailsAction.STOP_LIFE_POLLING)]);
  }
}

function* lifePollingTimeout() {
  const {
    lifeDetails: { quoteList, quoteErrorList },
  } = yield select();
  if (quoteList.length + quoteErrorList.length === 0) {
    yield put(storeLifeQuoteDetails({
      noQuoteAvailable: true,
    }));
  }
  yield put(stopLifePolling());
}

function* lifePollingTimeoutWatcher() {
  yield takeLatest(lifeDetailsAction.LIFE_TIMEOUT_POLLING, lifePollingTimeout);
}

function* stopPollingWorker() {
  const {
    lifeDetails: { quotingDetails, quoteList, quoteErrorList, quotesLoading },
  } = yield select();
  if (quotingDetails && !quotesLoading && quoteList.length + quoteErrorList.length > 0) {
    trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[6]);
    yield put(storeLifeQuoteDetails({ quotesLoaded: true } as any));
  }
}

export function* stopPollingWatcher() {
  yield takeEvery(lifeDetailsAction.STOP_LIFE_POLLING, stopPollingWorker);
}

function* getBridgeToCarrierDetailsForLife({ payload }: any): any {
  let loaderData = {
    loader: true,
    carrier_id: payload?.carrierId,
  };
  try {
    yield put(lifeBridgeToCarrierLoader(loaderData));
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest('POST', '/quotes/bridge-carrier', isProviderOneLogin, {
      herdId: payload?.herdId ?? '',
      lob: payload?.lob ?? '',
      carrierId: payload?.carrierId ?? '',
      premium: payload?.premium ?? '',
      coverage: payload?.coverage ?? '',
      term: payload?.term ?? '',
    });
    if (!!responseDetails?.data) {
      if (!!responseDetails?.data?.paymentLink) {
        window.open(responseDetails?.data?.paymentLink, '_blank');
      } else {
        yield put(storeErrorsForBridgeToCarrier(responseDetails?.data));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (axiosError?.response?.status === 404) {
      yield put(storeErrorsForBridgeToCarrier(axiosError?.response?.data));
    }
    yield put(errorHandler(axiosError));
  }
  loaderData.loader = false;
  yield put(lifeBridgeToCarrierLoader(loaderData));
}

export function* getBridgeToCarrierDetailsForLifeWatcher() {
  yield takeEvery(
    lifeDetailsAction.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_LIFE,
    getBridgeToCarrierDetailsForLife
  );
}

function* refreshLifeQuotesWorker() {
  const {
    umbrellaDetails: {isQuoteRefresh}
  } = yield select();
  if (!isQuoteRefresh) {
    yield put(
      storeLifeQuoteDetails({
        isQuoteRefresh: true,
      } as any)
    );
  }
  yield put(setLobRefreshLoader(true));
  yield put(initializeLifePolling());
  yield call(pollingDelay, LOB_REFRESH_TIMEOUT);
  yield put(
    storeLifeQuoteDetails({
      isQuoteRefresh: false,
    } as any)
  );
  yield put(lifeTimeoutPolling());
  yield put(setLobRefreshLoader(false));
}

function* refreshLifeQuotesWatcher() {
  yield takeEvery(lifeDetailsAction.REFRESH_LIFE_QUOTES, refreshLifeQuotesWorker);
}

export default function* rootSaga() {
  yield all([
    fork(fetchLifeQuotesWatcher),
    fork(pollQuotesWatcher),
    fork(lifePollingTimeoutWatcher),
    fork(stopPollingWatcher),
    fork(getBridgeToCarrierDetailsForLifeWatcher),
    fork(refreshLifeQuotesWatcher),
  ]);
}
