/* istanbul ignore file */
import * as homeActionType from '../constants';

// Fetch partner configuration details from server action creator
export const fetchPconfig = (payload: any): AppRedux.Action => ({
  type: homeActionType.FETCH_PCONFIG,
  payload,
});

// Used to show loader for partner configuration
export const setConfigLoader = (payload: boolean): AppRedux.Action => ({
  type: homeActionType.SET_CONFIG_LOADER,
  payload,
});

// Updates home state
export const setHomeState = (payload: Partial<HomeState>): AppRedux.Action => ({
  type: homeActionType.SET_HOME_STATE,
  payload,
});

// Clear Home State
export const clearHomeState = (): AppRedux.Action => ({
  type: homeActionType.CLEAR_HOME_STATE,
});

// Set home code details to store
export const setHomeCode = (payload: string): AppRedux.Action => ({
  type: homeActionType.SET_HOME_CODE,
  payload,
});

// Home application form submit
export const homeApplicationSubmit = (payload: any): AppRedux.Action => ({
  type: homeActionType.HOME_APPLICATION_SUBMIT,
  payload,
});

// Validating address from server
export const setValidatingAddress = (payload: boolean): AppRedux.Action => ({
  type: homeActionType.SET_VALIDATING_ADDRESS,
  payload,
});

// Set result page details
export const setResultDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: homeActionType.SET_RESULT_DETAILS,
  payload,
});

// Home quote - fetch home quotes details
export const fetchHomeQuoteDetails = (): AppRedux.Action => ({
  type: homeActionType.FETCH_HOME_QUOTE_DETAILS,
});

// Initialize home quotes polling
export const initHomeQuotePolling = (): AppRedux.Action => ({
  type: homeActionType.INIT_HOME_QUOTE_POLLING,
});

// Stops home quote polling
export const stopHomeQuotePolling = (): AppRedux.Action => ({
  type: homeActionType.STOP_HOME_QUOTE_POLLING,
});

// Timeout for polling
export const homeTimeoutPolling = (): AppRedux.Action => ({
  type: homeActionType.HOME_TIMEOUT_POLLING,
});

// Cancel home quote polling and reset details
export const cancelHomeQuotePolling = (): AppRedux.Action => ({
  type: homeActionType.CANCEL_HOME_QUOTE_POLLING,
});

// Updates quote list by added new quote fetched using polling
export const updateHomeQuoteList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: homeActionType.UPDATE_HOME_QUOTE_LIST,
  payload,
});

// Fetch home view quotes
export const fetchHomeViewQuotes = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: homeActionType.FETCH_HOME_VIEW_QUOTES,
  payload,
});

// Set view quote list
export const setHomeViewQuotes = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: homeActionType.SET_HOME_VIEW_QUOTES,
  payload,
});

export const homeSidebarEdit = () => ({
  type: homeActionType.HOME_SIDEBAR_EDIT,
});

// Set flag to set loader while loading view quotes
export const homeViewQuotesLoader = (payload: boolean): AppRedux.Action => ({
  type: homeActionType.HOME_VIEW_QUOTES_LOADER,
  payload,
});

// Flush home details excluding pconfig and default pconfig
export const flushHomeDetails = (): AppRedux.Action => ({
  type: homeActionType.FLUSH_HOME_DETAILS,
});

export const setHomeQuoteBind = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: homeActionType.SET_HOME_QUOTE_BIND,
  payload,
});

export const setLOBUpdated = (payload: boolean): AppRedux.Action => ({
  type: homeActionType.SET_LOB_UPDATED,
  payload,
});

export const setIsCondo = (isCondo: string): AppRedux.Action => ({
  type: homeActionType.SET_IS_CONDO,
  payload: isCondo,
});

export const setPreferredDeductible = (preferredDeductible: any): AppRedux.Action => ({
  type: homeActionType.SET_PREFERRED_DEDUCTIBLE,
  payload: preferredDeductible,
});

export const setTheme = (payload: any): AppRedux.Action => ({
  type: homeActionType.SET_THEME,
  payload,
});

export const clearHomePconfig = (): AppRedux.Action => ({
  type: homeActionType.CLEAR_HOME_PCONFIG,
});

export const setNoQuotesAvailableForHome = (payload: boolean): AppRedux.Action => ({
  type: homeActionType.SET_NO_QUOTE_AVAILABLE_FOR_HOME,
  payload,
});

export const refreshHomeQuotes = (): AppRedux.Action => ({
  type: homeActionType.REFRESH_HOME_QUOTES,
});

export const setDownloadQuoteLoader = (payload: any) => ({
  type: homeActionType.SET_DOWNLOAD_QUOTE_LOADER,
  payload,
});

export const downloadQuoteDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: homeActionType.DOWNLOAD_QUOTE_DETAILS,
  payload,
});
