import React from 'react';
import DateField from './date-field';
import { FormFieldType } from './form-fields.enums';
import { FormFieldProps } from './form-fields.types';
import PhoneField from './phone-field';
import PremiumField from './premium-field';
import SelectField from './select-field';
import TextField from './text-field';

export const FormField: React.FC<FormFieldProps> = props => {
  const { fieldDetails } = props;

  switch (fieldDetails.fieldType) {
    case FormFieldType.TEXT:
      return <TextField {...props} />;
    case FormFieldType.DATE:
      return <DateField {...props} />;
    case FormFieldType.SELECT:
      return <SelectField {...props} />;
    case FormFieldType.PHONE:
      return <PhoneField {...props} />;
    case FormFieldType.PREMIUM:
      return <PremiumField {...props} />
    default:
      return <TextField {...props} />;
  }
};

export default FormField;
