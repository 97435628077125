/* istanbul ignore file */
import { AxiosError } from 'axios';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import axiosRequest from '../../api';
import { get, isEmpty, omit, assignIn, filter } from 'lodash';
import * as umbrellaDetailsAction from '../constants';
import config from '../../../config/config';
import {
  errorHandler,
  initializeUmbrellaPolling,
  setHomeState,
  setLobRefreshLoader,
  setUmbrellaInfoLoader,
  setUmbrellaPollingDetails,
  setUmbrellaPollingErrorDetails,
  setUmbrellaQuoteDetails,
  stopUmbrellaPolling,
  storeUmbrellaInfo,
  storeUmbrellaQuoteDetails,
  umbrellaQuotesLoader,
  umbrellaTimeoutPolling,
  updateUmbrellaQuoteDetails,
} from '../actions';
import {
  clearLobQuotes,
  createPageTracking,
  getLobHerdId,
  getOrgDetails,
  getSeperateQuoteList,
  removeExistingRate,
  simplifyPhoneNumber,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
} from '../../../utils';
import {
  getMappedUmbrellaQuestionnaireGroups,
  setUmbrellaQuotesMapping,
} from '../../../utils/umbrella';
import { INSURANCE_BUSINESS_TYPES, INSURANCE_PRODUCTS, LOB_REFRESH_TIMEOUT } from '../../../constants';

function* fetchUmbrellaQuestions(): any {
  try {
    yield put(setUmbrellaInfoLoader(true));
    const {
      partner: { isProviderOneLogin },
      umbrellaDetails,
    } = yield select();
    const organizationDetails = getOrgDetails();
    yield put(
      storeUmbrellaQuoteDetails({
        quoteStatus: '',
      })
    );
    const herdId = getLobHerdId(INSURANCE_PRODUCTS[8]) || umbrellaDetails?.herdId || '';
    if (isEmpty(herdId)) {
      return;
    }
    const responseDetails = yield axiosRequest(
      'POST',
      '/umbrella/rli-questions',
      isProviderOneLogin,
      {
        herdId: herdId,
        organizationCode: organizationDetails?.organizationCode,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.data?.success) {
      const { data } = responseDetails?.data;
      yield put(
        storeUmbrellaInfo({
          ...data,
          questionGroups: !isEmpty(data?.questionGroups)
            ? getMappedUmbrellaQuestionnaireGroups(data.questionGroups)
            : [],
        })
      );
      yield put(storeUmbrellaQuoteDetails({ isQuestionError: false }));
    } else if (!responseDetails?.data?.success) {
      const { data, error_details, upcomingCarrierList } = responseDetails?.data;
      trackFirstLoadQuoteInHeap();
      let errorQuotes = filter(Object.values(error_details), error => {
        return assignIn(error, { quoteError: true });
      });
      yield put(
        storeUmbrellaInfo({
          ...data,
          questionGroups: !isEmpty(data?.questionGroups)
            ? getMappedUmbrellaQuestionnaireGroups(data.questionGroups)
            : [],
        })
      );
      yield put(
        storeUmbrellaQuoteDetails({
          quoteList: errorQuotes,
          herdId: herdId,
          isQuestionError: true,
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        })
      );
      if (sessionStorage.selectedQuoteList) {
        const selectedQuotes = removeExistingRate(
          JSON.parse(sessionStorage.selectedQuoteList),
          INSURANCE_BUSINESS_TYPES.UMBRELLA
        );
        sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
      }
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[8]);
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    trackFirstLoadQuoteInHeap();
    if (!!axiosError?.response?.data?.errorDetails) {
      const { errorDetails } = axiosError?.response?.data;
      const { errorQuotes } = getSeperateQuoteList(errorDetails, {});
      yield put(storeUmbrellaQuoteDetails({ quoteList: [...errorQuotes], isQuestionError: true }));
    } else {
      yield put(
        setHomeState({
          showError: true,
        } as any)
      );
      yield put(errorHandler(error));
    }
    trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[8]);
  }
  yield put(setUmbrellaInfoLoader(false));
}

export function* fetchUmbrellaQuestionsWatcher() {
  yield takeEvery(umbrellaDetailsAction.FETCH_UMBRELLA_QUESTIONS_INFO, fetchUmbrellaQuestions);
}

const pollConfig: any = {
  pollingTimerTimeout: config.pollingTimerTimeout,
  pollingInterval: config.pollingInterval,
};

const displayErrorProperty = 'error_details.display_errors_on_ui';
const errorProperty = 'error_details.errors';

const pollingDelay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
};

function* initializeQuotesPolling() {
  const { umbrellaDetails: quotesDetails } = yield select();
  if (quotesDetails?.isQuoteRefresh) {
    yield put(
      storeUmbrellaQuoteDetails({
        isQuoteRefresh: false,
      } as any)
    );
  }
  const quoteLimit = get(quotesDetails, 'quotingDetails.totalQuotesToPoll', 0);
  if (quotesDetails?.quoteErrorList?.length < quoteLimit) {
    yield put(initializeUmbrellaPolling());
    yield call(pollingDelay, pollConfig.pollingTimerTimeout);
    yield put(umbrellaTimeoutPolling());
  }
}

function* fetchUmbrellaQuotes(): any {
  try {
    trackFirstLoadQuoteInHeap();
    yield put(
      storeUmbrellaQuoteDetails({
        quoteList: [],
        quoteErrorList: [],
        quotesLoaded: false,
        upcomingCarrierList: [],
        quoteStatus: '',
      })
    );
    yield put(umbrellaQuotesLoader(true));
    const {
      applicant,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails, appointments },
      common,
      home,
      umbrellaDetails,
    } = yield select();
    const keys = Object.keys(appointments);
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
      ? producerDetails?.producerEmail
      : '';
    applicant.personal_details.phone_number = applicant?.personal_details?.phone_number
      ? simplifyPhoneNumber(applicant?.personal_details?.phone_number)
      : '';
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } =
      clearLobQuotes();
    let pageTracking = createPageTracking(!clearAutoInfo ? common?.pageTracking : {}, appointments);
    const details = yield axiosRequest('POST', '/umbrella/quotes', isProviderOneLogin, {
      pageTracking,
      lobQuotesToClear,
      clearAutoInfo,
      clearPetInfo,
      clearLifeInfo,
      clearUmbrellaInfo,
      personal_information: { ...omit(applicant?.personal_details, 'preferredDeductible') },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: umbrellaDetails?.herdId || '',
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
      lob: INSURANCE_PRODUCTS[8],
      partner_code: !isEmpty(producerDetails?.producerCode)
        ? producerDetails.producerCode
        : quoteBy,
      rli_question_info: umbrellaDetails?.umbrellaQuestionsResponse,
    });
    if (!isEmpty(details?.data)) {
      const { upcomingCarrierList, quotingCarriers } = details.data;
      yield put(
        storeUmbrellaQuoteDetails({
          quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        } as any)
      );
      yield put(updateUmbrellaQuoteDetails({ ...details.data }));
      if (
        get(details.data, displayErrorProperty, false) &&
        !isEmpty(get(details.data, errorProperty, {})) &&
        details.data.totalQuotesToPoll === 0
      ) {
        yield put(setUmbrellaPollingErrorDetails({ ...details.data }));
      }
      yield call(initializeQuotesPolling);
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!isEmpty(axiosError?.response?.data?.error_details)) {
      const { error_details, herdId } = axiosError?.response?.data;
      let errorQuotes: any = filter(Object.values(error_details), errorDetails => {
        return assignIn(errorDetails, { quoteError: true });
      });
      yield put(
        setUmbrellaQuoteDetails({
          quoteErrorList: [...Object.values(errorQuotes)],
          herdId,
        })
      );
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[8]);
    }
    yield put(storeUmbrellaQuoteDetails({ quotesLoaded: true } as any));
    yield put(errorHandler(axiosError));
  }
  yield put(umbrellaQuotesLoader(false));
}

export function* fetchUmbrellaQuotesWatcher() {
  yield takeEvery(umbrellaDetailsAction.FETCH_UMBRELLA_QUOTES, fetchUmbrellaQuotes);
}

function* pollQuotes(): any {
  while (true) {
    const {
      umbrellaDetails,
      partner: { isProviderOneLogin },
    } = yield select();
    try {
      if (umbrellaDetails?.quotingDetails?.herd_id || umbrellaDetails?.herdId) {
        const { data } = yield axiosRequest('POST', '/quotes/poll-quotes', isProviderOneLogin, {
          herd_id: umbrellaDetails?.herdId || umbrellaDetails?.quotingDetails?.herd_id || '',
          lob: INSURANCE_PRODUCTS[8],
        });
        if (!isEmpty(data.quotes)) {
          let umbrellaQuotes: any = setUmbrellaQuotesMapping(data.quotes);
          data.quotes = umbrellaQuotes;
          yield put(setUmbrellaPollingDetails({ ...data }));
        }
        if (get(data, displayErrorProperty, false) && !isEmpty(get(data, errorProperty, {}))) {
          yield put(setUmbrellaPollingErrorDetails({ ...data }));
        }
        const reduxState = yield select();
        const currentQuotesLength =
          reduxState.umbrellaDetails.quoteList.length +
          reduxState.umbrellaDetails.quoteErrorList.length;
        const quoteLimit = get(umbrellaDetails, 'quotingDetails.totalQuotesToPoll', 0);
        if (currentQuotesLength >= quoteLimit) {
          yield put(umbrellaQuotesLoader(false));
          yield put(stopUmbrellaPolling());
          if (umbrellaDetails?.isQuoteRefresh) yield put(setLobRefreshLoader(false));
        } else {
          yield call(pollingDelay, pollConfig.pollingInterval);
        }
      }
    } catch (error) {
      yield put(umbrellaQuotesLoader(false));
      yield put(stopUmbrellaPolling());
    }
  }
}

function* pollQuotesWatcher() {
  while (true) {
    yield take(umbrellaDetailsAction.INIT_UMBRELLA_POLLING);
    yield race([call(pollQuotes), take(umbrellaDetailsAction.STOP_UMBRELLA_POLLING)]);
  }
}

function* umbrellaPollingTimeout() {
  const {
    umbrellaDetails: { quoteList, quoteErrorList },
  } = yield select();
  if (quoteList.length + quoteErrorList.length === 0) {
    yield put(
      storeUmbrellaQuoteDetails({
        noQuoteAvailable: true,
      })
    );
  }
  yield put(stopUmbrellaPolling());
}

function* umbrellaPollingTimeoutWatcher() {
  yield takeLatest(umbrellaDetailsAction.UMBRELLA_TIMEOUT_POLLING, umbrellaPollingTimeout);
}

function* stopPollingWorker() {
  const {
    umbrellaDetails: { quotingDetails, quoteList, quoteErrorList, quotesLoading },
  } = yield select();
  if (quotingDetails && !quotesLoading && quoteList.length + quoteErrorList.length > 0) {
    trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[8]);
    yield put(storeUmbrellaQuoteDetails({ quotesLoaded: true } as any));
  }
}

export function* stopPollingWatcher() {
  yield takeEvery(umbrellaDetailsAction.STOP_UMBRELLA_POLLING, stopPollingWorker);
}

function* refreshUmbrellaQuotesWorker() {
  const {
    umbrellaDetails: {isQuoteRefresh}
  } = yield select();
  if (!isQuoteRefresh) {
    yield put(
      storeUmbrellaQuoteDetails({
        isQuoteRefresh: true,
      } as any)
    );
  }
  yield put(setLobRefreshLoader(true));
  yield put(initializeUmbrellaPolling());
  yield call(pollingDelay, LOB_REFRESH_TIMEOUT);
  yield put(
    storeUmbrellaQuoteDetails({
      isQuoteRefresh: false,
    } as any)
  );
  yield put(umbrellaTimeoutPolling());
  yield put(setLobRefreshLoader(false));
}

function* refreshUmbrellaQuotesWatcher() {
  yield takeEvery(umbrellaDetailsAction.REFRESH_UMBRELLA_QUOTES, refreshUmbrellaQuotesWorker);
}

export default function* rootSaga() {
  yield all([
    fork(fetchUmbrellaQuestionsWatcher),
    fork(fetchUmbrellaQuotesWatcher),
    fork(pollQuotesWatcher),
    fork(umbrellaPollingTimeoutWatcher),
    fork(stopPollingWatcher),
    fork(refreshUmbrellaQuotesWatcher),
  ]);
}
