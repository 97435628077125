/* istanbul ignore file */
import * as types from '../constants';

interface ViewQuoteState {
  viewQuoteLoader: boolean;
  isRedirectToApplicant: boolean;
  pollingDetails: any;
  validateQuoteUrlLoader: boolean;
  crmSelectedLob: string;
}

const INIT_VIEW_QUOTE_STATE: ViewQuoteState = {
  viewQuoteLoader: false,
  isRedirectToApplicant: false,
  pollingDetails: {},
  validateQuoteUrlLoader: false,
  crmSelectedLob: '',
};

const INIT_STATE = { ...INIT_VIEW_QUOTE_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        viewQuoteLoader: false,
      };
    }

    case types.SET_VIEW_QUOTES: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.VALIDATE_QUOTE_URL_LOADER: {
      return {
        ...state,
        validateQuoteUrlLoader: action.payload,
      };
    }

    case types.SET_CRM_SELECTED_LOB: {
      return {
        ...state,
        crmSelectedLob: action.payload,
      };
    }

    case types.SET_CRM_VIEW_QUOTE_LOADER: {
      return {
        ...state,
        viewQuoteLoader: action.payload,
      };
    }

    default:
      return state;
  }
};
