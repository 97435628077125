/* istanbul ignore file */
import omit from 'lodash/omit';

import config from '../../../config/config';
import { Address } from '../../../quote-application/redux/apis/applicant';
import { getQuoteListWithBindDetails } from '../../../utils';
import * as types from '../constants';
import { UpcomingCarriersListType } from './cyber-security';
export interface OrganizationLobType {
  [key: string]: {} | { [key: string]: boolean } | boolean;
}
export interface DefaultPartnerConfiguration {
  name: string;
  code: string;
  dale_config: {
    customized_error_page_details: {
      error_logo_url: string;
      error_text: string;
    };
    meta_details: {
      title: string;
      favicon: string;
      description: string;
    };
    auth_token: string;
    contact: {
      email: string;
      phone: string;
    };
    total_number_of_quotes: number;
    hippo_branding_on_dale: boolean;
    direct_appointment_access_details: {
      partner_license: string;
      license_text: string;
    };
    branding_on_dale_details: {
      logo_url: string;
      template_name: string;
    };
    is_verisk_prefill_available: boolean;
    code: string;
  };
  is_verisk_prefill_available: boolean;
  branding_on_dale_details?: { template_name?: string };
  auth_token: string;
  rater_page_type?: string;
  display_errors_on_ui?: boolean;
  crm_allow_add_products?: boolean;
  feedback_enabled?: boolean;
}

export interface DaleConfig {
  name?: string;
  id?: string;
  lob?: OrganizationLobType;
  is_verisk_prefill_available: boolean;
  branding_on_dale_details?: { template_name?: string };
  code: string;
  auth_token: string;
  rater_page_type?: string;
  display_errors_on_ui?: boolean;
  crm_allow_add_products?: boolean;
  feedback_enabled?: boolean;
}

export interface PartnerConfig {
  name?: string;
  id?: string;
  dale_config?: DaleConfig;
  pconfig?: PartnerConfig;
}
export interface HomePersonalDetails {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  date_of_birth?: string;
  phone_number?: string;
  email?: string;
  industry?: string;
  occupation?: string;
  education?: string;
  preferredDeductible: number;
  residenceType?: string;
}
export interface HomeState {
  pconfig?: PartnerConfig | null;
  defaultPConfig: DefaultPartnerConfiguration;
  code: string;
  herd_id: string;
  configLoader: boolean;
  isValidatingAddress: boolean;
  isAlreadyValidated: boolean;
  isPreviousAddressAlreadyValidated: boolean;
  showError: boolean;
  showResult: boolean;
  hippoQuoteLoaded: boolean;
  ezlynxQuotesLoaded: boolean;
  minPriceQuote: any;
  quoteList: Array<any>;
  quoteListError: Array<any>;
  tempErrorQuoteList: Array<any>;
  quotingCarriers: Array<any>;
  headerData: any;
  displayQuoteErrors: boolean;
  address?: Address | null;
  previousAddress?: Address | null;
  personal_details?: HomePersonalDetails | null;
  loanOfficer_details: any;
  is_condo: string;
  isPolling: boolean;
  totalEzlynxQuotes: number;
  ezlynxQuoteExecutionID: Array<any>;
  viewQuotes: boolean;
  viewQuotesLoader: boolean;
  productType: string;
  lob: string;
  AMSLOBChanged: boolean;
  preferredResidencyStatus: string;
  notFoundError: boolean;
  expiredError: boolean;
  old_applicant_details: any;
  isNoQuoteAvailable: boolean;
  bridgeToCarrierLoader: boolean;
  isQuoteRefresh: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
  quoteStatus: string;
  isMovedInPastMonths: boolean;
}

const INIT_HOME_STATE: HomeState = {
  pconfig: null,
  defaultPConfig: {
    name: config.hippo.name,
    code: config.hippo.code,
    dale_config: {
      customized_error_page_details: {
        error_logo_url: config.hippo.error_logo_url,
        error_text: config.hippo.error_text,
      },
      meta_details: {
        title: config.hippo.title,
        favicon: config.hippo.favicon,
        description: config.hippo.description,
      },
      auth_token: '',
      contact: {
        email: config.hippo.email,
        phone: config.hippo.phone,
      },
      total_number_of_quotes: config.hippo.total_number_of_quotes,
      hippo_branding_on_dale: config.hippo.hippo_branding_on_dale,
      direct_appointment_access_details: {
        partner_license: config.hippo.partner_license,
        license_text: config.hippo.license_text,
      },
      branding_on_dale_details: {
        logo_url: config.hippo.logo_url,
        template_name: config.hippo.template_name,
      },
      is_verisk_prefill_available: false,
      code: '',
    },
    auth_token: '',
    is_verisk_prefill_available: false,
    feedback_enabled: false,
  },
  code: '',
  herd_id: '',
  productType: '',
  configLoader: false,
  isValidatingAddress: false,
  isAlreadyValidated: false,
  isPreviousAddressAlreadyValidated: false,
  showError: false,
  showResult: false,
  hippoQuoteLoaded: true,
  ezlynxQuotesLoaded: true,
  minPriceQuote: null,
  quoteList: [],
  quoteListError: [],
  tempErrorQuoteList: [],
  quotingCarriers: [],
  headerData: null,
  displayQuoteErrors: false,
  address: null,
  previousAddress: null,
  personal_details: null,
  loanOfficer_details: null,
  is_condo: '',
  isPolling: false,
  totalEzlynxQuotes: 0,
  ezlynxQuoteExecutionID: [],
  viewQuotes: false,
  viewQuotesLoader: false,
  lob: '',
  AMSLOBChanged: false,
  preferredResidencyStatus: 'PRIMARY',
  notFoundError: false,
  expiredError: false,
  old_applicant_details: {},
  isNoQuoteAvailable: false,
  bridgeToCarrierLoader: false,
  isQuoteRefresh: false,
  upcomingCarrierList: [],
  quoteStatus: '',
  isMovedInPastMonths: false,
};

export default (state = INIT_HOME_STATE, action: AppRedux.Action): HomeState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        configLoader: false,
        isAlreadyValidated: false,
        isPreviousAddressAlreadyValidated: false,
        showError: false,
        showResult: false,
        // hippoQuoteLoaded: false,
        // ezlynxQuotesLoaded: false,
        // quoteList: [],
        lob: '',
      };
    }

    case types.FLUSH_HOME_DETAILS: {
      return {
        ...state,
        ...{ ...omit(INIT_HOME_STATE, ['pconfig', 'defaultPConfig', 'code']) },
      };
    }

    case types.SET_HOME_CODE: {
      return {
        ...state,
        code: action.payload,
      };
    }

    case types.SET_CONFIG_LOADER: {
      return {
        ...state,
        configLoader: action.payload,
      };
    }

    case types.SET_VALIDATING_ADDRESS: {
      return {
        ...state,
        isValidatingAddress: action.payload,
      };
    }

    case types.SET_HOME_STATE:
    case types.SET_RESULT_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.HOME_VIEW_QUOTES_LOADER: {
      return {
        ...state,
        viewQuotesLoader: action.payload,
      };
    }

    case types.SET_HOME_QUOTE_BIND: {
      return {
        ...state,
        quoteList: getQuoteListWithBindDetails(state.quoteList, action.payload),
      };
    }

    case types.SET_REDIRECTED_FROM: {
      return {
        ...state,
        quoteList: [],
        headerData: null,
        showError: false,
        showResult: false,
        isAlreadyValidated: false,
        isPreviousAddressAlreadyValidated: false,
        hippoQuoteLoaded: false,
        ezlynxQuotesLoaded: false,
        viewQuotes: false,
        totalEzlynxQuotes: 0,
        ezlynxQuoteExecutionID: [],
        herd_id: '',
        lob: '',
      };
    }

    case types.SET_LOB_UPDATED: {
      return {
        ...state,
        AMSLOBChanged: action.payload,
      };
    }

    case types.SET_IS_CONDO: {
      return {
        ...state,
        is_condo: action.payload,
      };
    }

    case types.SET_PREFERRED_DEDUCTIBLE: {
      return {
        ...state,
        personal_details: { ...state.personal_details, preferredDeductible: action.payload },
      };
    }

    case types.CLEAR_HOME_STATE: {
      return {
        ...state,
        personal_details: null,
        address: null,
        previousAddress: null,
        isPreviousAddressAlreadyValidated: false,
        hippoQuoteLoaded: true,
        ezlynxQuotesLoaded: true,
        showResult: false,
        quoteList: [],
        quoteListError: [],
        headerData: null,
        is_condo: '',
        totalEzlynxQuotes: 0,
        ezlynxQuoteExecutionID: [],
        viewQuotes: false,
        herd_id: '',
        preferredResidencyStatus: 'PRIMARY',
      };
    }
    case types.SET_THEME: {
      return {
        ...state,
        pconfig: {
          ...state.pconfig,
          dale_config: {
            ...action.payload,
          },
        },
      };
    }

    case types.CLEAR_HOME_PCONFIG: {
      return {
        ...state,
        pconfig: null,
        code: '',
      };
    }

    case types.SET_NO_QUOTE_AVAILABLE_FOR_HOME: {
      return {
        ...state,
        isNoQuoteAvailable: action.payload,
      };
    }

    case types.BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let homeQuoteList = [...state.quoteList];
      const updateIndex = homeQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        homeQuoteList[updateIndex] = {
          ...homeQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: homeQuoteList,
      };
    }

    case types.SET_DOWNLOAD_QUOTE_LOADER: {
      const { carrier_id, loader } = action.payload;
      let homeQuoteList = [...state.quoteList];
      const updateIndex = homeQuoteList.findIndex(
        (item: any) => item?.carrier_id?.toString() === carrier_id?.toString()
      );
      if (updateIndex !== -1) {
        homeQuoteList[updateIndex] = {
          ...homeQuoteList[updateIndex],
          downloadQuoteLoader: loader,
        };
      }
      return {
        ...state,
        quoteList: homeQuoteList,
      };
    }

    default:
      return state;
  }
};
