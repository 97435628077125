import { startCase } from 'lodash';
import { formatNumberToCurrency, numberToCurrency } from '.';
import {
  ALL_PERIL,
  FLOOD_DIRECT_CARRIERS,
  INSURANCE_BUSINESS_TYPES,
  PURCHASE_TYPE,
  QUAKE_COVERAGE_TYPES,
} from '../constants';

export const getCarrierDeductible = (quote: any, premiumDetails: any, type: string) => {
  if (!premiumDetails || !quote || !type) return;
  const { deductible, tier_details, plan, coverageType } = premiumDetails;
  const { QUAKE, FLOOD, PET } = INSURANCE_BUSINESS_TYPES;
  if (type === FLOOD) {
    if (FLOOD_DIRECT_CARRIERS.includes(quote?.carrier_id?.toString())) {
      return `$${numberToCurrency(tier_details?.covA)} / $${deductible}`;
    } else {
      return `${ALL_PERIL} ${formatNumberToCurrency(deductible)}`;
    }
  } else if (type === PET) {
    return startCase(plan);
  } else if (type === QUAKE && deductible?.value && !coverageType) {
    return `${ALL_PERIL} ${formatNumberToCurrency(deductible.value)}`;
  }
  return QUAKE_COVERAGE_TYPES[coverageType];
};

export const formatPurchaseTypeText = (purchasetype: string = '') => {
  purchasetype = purchasetype?.toUpperCase() || '';
  return purchasetype === PURCHASE_TYPE.ONLINE ? PURCHASE_TYPE.PURCHASE_ONLINE : purchasetype;
};
