import { isEmpty } from 'lodash';
import { NUMBER_100, NUMBER_50, NUMBER_ZERO, YEAR_CONSTRUCTED_ERROR_MESSAGE } from '../../constants';
import {
  FoundationType,
  ProductName,
  PropertyData,
  ResidenceType,
  Stories,
} from '../../graphql/generated';
import store from '../../pages/redux/store';
import { getIsLobIncludesInAppointments, getQuoteListOfLob, positiveIntegerRegex } from '../../utils';
import { BasementFinishType, LineOfBusiness, PrefillStatus } from '../enums';
import { mapPropertyInfoStoreToStateData } from '../components/property-info/property-info.mapper';
import { ERROR_MESSAGE } from '../../pages/custom-hooks/useFormHandler/utils';
import { MAXMIMUM_COVERAGE_LIMIT } from '../constants';
import { getNumber } from './common';

export const showPropertyInfoForHomeLob = () => {
  const {
    propertyInfoDetails: { isShowPropertyInfoForHomeLob },
  } = store.getState();
  return getIsLobIncludesInAppointments(LineOfBusiness.HOME) && isShowPropertyInfoForHomeLob;
};

export const showPropertyInfoForFloodLob = () => {
  const {
    propertyInfoDetails: { isShowPropertyInfoForFloodLob },
  } = store.getState();
  return getIsLobIncludesInAppointments(LineOfBusiness.FLOOD) && isShowPropertyInfoForFloodLob;
};

export const getBasementFinishType = (basementFinishPercentage: string | number = 100) => {
  basementFinishPercentage = Number(basementFinishPercentage);
  if (basementFinishPercentage === NUMBER_ZERO) {
    return BasementFinishType.UNFINISHED;
  }
  if (basementFinishPercentage === NUMBER_100) {
    return BasementFinishType.FINISHED;
  }
  return BasementFinishType.PARTIALLY_FINISHED;
};

export const getBasementFinishPercentage = (basementFinishType: string): string => {
  if (basementFinishType === BasementFinishType.UNFINISHED) {
    return String(NUMBER_ZERO);
  }
  if (basementFinishType === BasementFinishType.PARTIALLY_FINISHED) {
    return String(NUMBER_50);
  }
  return String(NUMBER_100);
};


export const getStoriesMapper = {
  [Stories.One]: 1,
  [Stories.OneAndAHalf]: 1,
  [Stories.Two]: 2,
  [Stories.TwoAndAHalf]: 2,
  [Stories.Three]: 3,
  [Stories.ThreeAndAHalf]: 3,
  [Stories.Four]: 4,
  [Stories.BiLevel]: 4,
  [Stories.SplitLevel]: 5
};

export const getFoundationTypeMapper = {
  [FoundationType.Basement]: 'basement',
  [FoundationType.ConcreteSlab]: 'slab',
  [FoundationType.Crawlspace]: 'crawlspace',
  [FoundationType.PierOrPilings]: 'pier',
};

export const getFloodQuestionnairDetailsFromPrefill = () => {
  const {
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  return {
    foundationType: propertyInfo?.building?.foundation?.type
      ? getFoundationTypeMapper[propertyInfo?.building?.foundation?.type]
      : '',
    noOfStories: propertyInfo?.building?.general?.stories
      ? getStoriesMapper[propertyInfo?.building?.general?.stories]
      : 1,
  };
};

export const getPrefillStatus = (propertyInfo: PropertyData) => {
  const { building } = propertyInfo;
  if (!building?.general?.yearBuilt || !building?.general?.squareFootage) {
    return PrefillStatus.INCOMPLETE;
  }
  return PrefillStatus.SUCCESS;
};

export const propertyDataMapper: { [key: string]: ProductName } = {
  dp1: ProductName.Dp1,
  dp3: ProductName.Dp3,
  ho3: ProductName.Ho3,
  ho4: ProductName.Ho4,
  ho5: ProductName.Ho5,
  ho6: ProductName.Ho6,
  DP1: ProductName.Dp1,
  DP3: ProductName.Dp3,
  HO3: ProductName.Ho3,
  HO4: ProductName.Ho4,
  HO5: ProductName.Ho5,
  HO6: ProductName.Ho6,
  'dp-1': ProductName.Dp1,
  'dp-3': ProductName.Dp3,
  'ho-3': ProductName.Ho3,
  'ho-4': ProductName.Ho4,
  'ho-5': ProductName.Ho5,
  'ho-6': ProductName.Ho6,
  [ProductName.Dp1]: ProductName.Dp1,
  [ProductName.Dp3]: ProductName.Dp3,
  [ProductName.Ho3]: ProductName.Ho3,
  [ProductName.Ho4]: ProductName.Ho4,
  [ProductName.Ho5]: ProductName.Ho5,
  [ProductName.Ho6]: ProductName.Ho6,
};

export const residenceTypeMapping: { [key: string]: ResidenceType } = {
  PRIMARY: ResidenceType.Primary,
  RENTED: ResidenceType.Rented,
  SECONDARY: ResidenceType.Secondary,
  VACANT: ResidenceType.Vacant,
};

export const displayDefaultIconForPropertyInfo = (
  fieldName: string,
  fieldValue: string,
  defaultValues: any,
  changedformFieldList: string[]
): boolean => {
  const {
    homeQuoteDetails: { quotes },
  } = store.getState();
  const propertyDetails: any = mapPropertyInfoStoreToStateData();
  const isShowDefaultHomeLob =
    getIsLobIncludesInAppointments(LineOfBusiness.HOME) && !isEmpty(quotes);
  const isShowDefaultFloodLob =
    getIsLobIncludesInAppointments(LineOfBusiness.FLOOD) &&
    !getQuoteListOfLob(LineOfBusiness.FLOOD);
  if (isShowDefaultHomeLob || isShowDefaultFloodLob || isEmpty(propertyDetails)) {
    return false;
  }
  return (
    defaultValues[fieldName] === fieldValue &&
    isEmpty(propertyDetails[fieldName]) &&
    !changedformFieldList.includes(fieldName)
  );
};

export const validateCoverageDetails = (coverage: string, isOptional: boolean) => {
  if (!isOptional && isEmpty(coverage)) {
    return ERROR_MESSAGE.REQUIRED;
  }
  if (coverage) {
    const numeberCoverage = parseInt(coverage);
    if (
      numeberCoverage > MAXMIMUM_COVERAGE_LIMIT ||
      (!isOptional && !positiveIntegerRegex.test(numeberCoverage?.toString()))
    ) {
      return ERROR_MESSAGE.INVALID;
    }
  }
  return '';
};

export const validateUpdatedRoof = (yearBuilt: string, updatedRoof: string) => {
  const yearBuiltDetail: number | undefined = getNumber(yearBuilt);
  const updatedRoofDetail: number | undefined = getNumber(updatedRoof);
  if (!yearBuiltDetail || !updatedRoofDetail) return '';
  return yearBuiltDetail > updatedRoofDetail ? YEAR_CONSTRUCTED_ERROR_MESSAGE : '';
};
