import React from 'react';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';

import {
  autoSubmitAnnex,
  cancelHomeQuotePolling,
  fetchHomeViewQuotes,
  homeApplicationSubmit,
  setAMSDetails,
  setHomeRedirectFlag,
  setLineOfBusiness,
  setRedirectedFrom,
  showFloodConfirmationModal,
  showFloodDetailsModal,
  stepChanged,
  switchFloodModal,
  trackAnnexDetails,
} from '../../redux/actions';
import { checkFloodQuote, getBoundedQuote, goToDashboard } from '../../../utils';
import {
  ANNEX_TRACKING,
  INSURANCE_PRODUCTS,
  QUOTES_LOADING_MESSAGE,
} from '../../../constants';
import Footer from '../../components/footer';
import FloodConfirmationModal from '../../components/flood-confirmation-modal';
import FloodDetailsModal from '../../components/flood-details-modal';
import history from '../../../history';
import HomeIcon from '../../../assets/images/home.svg';
import HomeIconFc from '../../../assets/images/home-fc.svg';
import HomeEditSidebar from '../../components/home-edit-sidebar';
import HomeQuoteLegend from '../../components/home-quote-legend';
import Loader from '../../components/loader';
import PropertyError from '../../components/property-error';
import OtherQuotesButton from '../../components/other-quotes-button';
import QuoteItem from '../../components/quote-item';
import AutoIcon from '../../../assets/images/auto.svg';
import AutoIconFc from '../../../assets/images/auto-fc.svg';
import { FIRST_CONNECT_THEME } from '../../../constants';
import config from '../../../config/config';
import './result.scss';

const HomeButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? HomeIconFc : HomeIcon;
const CarButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? AutoIconFc : AutoIcon;

class ResultComponent extends React.Component<
  AppComponents.ResultComponentProps,
  AppComponents.ResultComponentState
> {
  state: AppComponents.ResultComponentState = {
    showPurchaseModal: false,
    quote_id: '',
    quotesCollapsed: true,
    selectedFloodQuote: {},
    floodConfirmationModal: false,
    floodDetailsModal: false,
  };

  componentDidMount() {
    const { viewQuotes, herd_id, showResult, pconfig } = this.props;
    if (!showResult && !viewQuotes) {
      if (pconfig) {
        history.goBack();
      } else {
        goToDashboard();
      }
      this.props.cancelHomeQuotePolling();
    } else if (viewQuotes && !isEmpty(herd_id)) {
      history.push({ pathname: '/quotes', search: `?herd_id=${herd_id}` });
    }
    this.props.setAMSDetails({
      selectedFloodQuote: null,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.ResultComponentProps) {
    const { lineOfBusiness, floodDetails, showResult } = this.props;
    if (
      newProps.floodDetails.showFloodConfirmation !== floodDetails.showFloodConfirmation &&
      showResult &&
      lineOfBusiness === INSURANCE_PRODUCTS[0]
    ) {
      this.setState({
        floodConfirmationModal: newProps.floodDetails.showFloodConfirmation,
      });
    }

    if (
      newProps.floodDetails.showFloodDetailsModal !== floodDetails.showFloodDetailsModal &&
      showResult &&
      lineOfBusiness === INSURANCE_PRODUCTS[0]
    ) {
      this.setState({
        floodDetailsModal: newProps.floodDetails.showFloodDetailsModal,
      });
    }
  }

  otherQuoteSelected = (product: any) => {
    history.push({
      pathname: '/home',
      search: `?code=${get(this.props, 'pconfig.code')}`,
      state: {
        isRedirectedFromAuto: false,
      },
    });
    this.props.setRedirectedFrom(this.props.lineOfBusiness);
    this.props.setLineOfBusiness(product.value);
    window.scrollTo(0, 0);
  };

  getResultHomeQuoteButton() {
    if (
      this.props.lineOfBusiness !== INSURANCE_PRODUCTS[0] &&
      !this.props.redirectedFrom.includes(INSURANCE_PRODUCTS[0])
    ) {
      return (
        <Button
          variant='outlined'
          className='btnHomeQuotes ml-1 button-width'
          onClick={() => {
            history.push({
              pathname: '/home',
              search: `?code=${get(this.props, 'pconfig.code')}`,
              state: {
                isRedirectedFromAuto: false,
              },
            });
            this.props.setRedirectedFrom(this.props.lineOfBusiness);
            this.props.setLineOfBusiness(INSURANCE_PRODUCTS[0]);
            window.scrollTo(0, 0);
          }}
        >
          <img alt='home quote' className='img-fluid' src={HomeButtonIcon} />
          <span style={{ verticalAlign: 'middle' }}>Home Quote</span>
        </Button>
      );
    } else {
      return '';
    }
  }

  onFloodPremiumSelect = (details: any, quote: any) => {
    this.setState(
      {
        selectedFloodQuote: {
          [quote.name]: details.premium,
        },
      },
      () => {
        this.props.setAMSDetails({
          selectedFloodQuote: { ...quote, premiumDetails: [details] },
        });
      }
    );
  };

  floodConfirmationCloseHandler = (isFlood: boolean) => {
    this.props.showFloodConfirmationModal(false);
    if (isFlood) {
      this.props.switchFloodModal();
      this.props.showFloodDetailsModal(true);
      this.props.trackAnnexDetails(ANNEX_TRACKING.POST_POPUP);
    }
  };

  floodDetailsCloseHandler = () => {
    this.props.showFloodDetailsModal(false);
    this.setState({
      floodDetailsModal: false,
      floodConfirmationModal: false,
    });
  };

  render() {
    const { floodConfirmationModal, floodDetailsModal } = this.state;
    const {
      authUser,
      ezlynxQuotesLoaded,
      hippoQuoteLoaded,
      minPriceQuote,
      quoteList,
      headerData,
      pconfig,
      defaultPConfig,
      showError,
      isAutoRedirect,
      lineOfBusiness,
      floodDetails,
    } = this.props;
    const isLoading = ezlynxQuotesLoaded && hippoQuoteLoaded && quoteList.length > 0;

    return (
      <section className='result-wrapper position-relative'>
        <div className='container'>
          <div className='row adjust-row-width'>
            {isLoading && quoteList.length > 0 && (
              <HomeQuoteLegend showLegends={lineOfBusiness === INSURANCE_PRODUCTS[0]} />
            )}

            <div className='col-md-12'>
              {(!ezlynxQuotesLoaded || !hippoQuoteLoaded) && (
                <BlockUi
                  className='quote-loader'
                  tag='div'
                  blocking={true}
                  message={QUOTES_LOADING_MESSAGE}
                  renderChildren={true}
                  defaults=''
                  loader={<Loader />}
                />
              )}

              {quoteList.length >= 1 && (
                <div className='quote-list-container initial-quote-list'>
                  {map(quoteList, (quote, i) => (
                    <div className='quote-card ' key={i}>
                      <QuoteItem
                        {...({
                          quote,
                          minPriceQuote,
                          headerData,
                          boundQuote: getBoundedQuote(quoteList),
                          floodQuote: checkFloodQuote(quote),
                          onFloodPremiumSelect: this.onFloodPremiumSelect,
                          selectedFloodQuote: this.state.selectedFloodQuote,
                        } as any)}
                      />
                    </div>
                  ))}
                </div>
              )}

              {showError && quoteList.length === 0 && (
                <PropertyError {...({ pstate: { pconfig, defaultPConfig, authUser } } as any)} />
              )}

              {isLoading && (
                <div className='container mx-w'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='btnWrap ml-0'>
                        {!isAutoRedirect && (
                          <Button
                            variant='contained'
                            className='btnContinue button-width'
                            color='primary'
                            onClick={() => {
                              this.props.stepChanged({ entity: 'vehicles', value: true } as any);
                              this.props.setHomeRedirectFlag(true);
                              history.push({
                                pathname: '/auto/vehicle',
                                state: {
                                  pathname: '/auto/vehicle',
                                  fromHomeProduct: true,
                                },
                              });
                              this.props.setRedirectedFrom(this.props.lineOfBusiness);
                              this.props.setLineOfBusiness(INSURANCE_PRODUCTS[1]);
                            }}
                          >
                            <img src={CarButtonIcon} alt='car' className='img-fluid iconCar'></img>
                            Auto Quote
                          </Button>
                        )}

                        {this.getResultHomeQuoteButton()}

                        <OtherQuotesButton onSelect={this.otherQuoteSelected} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-9'>
              <Footer />
            </div>
          </div>
        </div>

        {floodConfirmationModal && (
          <FloodConfirmationModal
            open={floodConfirmationModal}
            closeHandler={this.floodConfirmationCloseHandler}
          />
        )}

        {floodDetailsModal && (
          <FloodDetailsModal
            open={floodDetailsModal}
            closeHandler={this.floodDetailsCloseHandler}
            showSuccess={floodDetails.floodRequestSuccess}
            loader={floodDetails.detailsLoader}
          />
        )}

        <HomeEditSidebar />
      </section>
    );
  }
}

const mapStateToProps = ({
  auth,
  common,
  home,
  floodDetails,
}: AppComponents.ResultComponentStore): AppComponents.ResultComponentStoreProps => {
  const {
    pconfig,
    defaultPConfig,
    showError,
    ezlynxQuotesLoaded,
    hippoQuoteLoaded,
    minPriceQuote,
    quoteList,
    headerData,
    showResult,
    herd_id,
    viewQuotes,
  } = home;
  const { isAutoRedirect, lineOfBusiness, redirectedFrom } = common;
  const { authUser } = auth;
  return {
    authUser,
    pconfig,
    defaultPConfig,
    showError,
    ezlynxQuotesLoaded,
    hippoQuoteLoaded,
    minPriceQuote,
    quoteList,
    headerData,
    showResult,
    herd_id,
    viewQuotes,
    isAutoRedirect,
    lineOfBusiness,
    redirectedFrom,
    floodDetails,
  };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.ResultComponentDispatch => {
  return bindActionCreators(
    {
      homeApplicationSubmit,
      fetchHomeViewQuotes,
      stepChanged,
      cancelHomeQuotePolling,
      setHomeRedirectFlag,
      setLineOfBusiness,
      setAMSDetails,
      setRedirectedFrom,
      showFloodConfirmationModal,
      showFloodDetailsModal,
      autoSubmitAnnex,
      switchFloodModal,
      trackAnnexDetails,
    },
    dispatchProp
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultComponent);
