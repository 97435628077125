import moment from 'moment';
import { BOOLEAN_OPTIONS, INPUT_FIELD_TYPES, INPUT_DATA_TYPES, YEAR } from './common';
import { BasementFinishType } from '../types';
const { DATE, SELECT, TEXT } = INPUT_FIELD_TYPES;
const { NUMBER, STRING, BOOLEAN } = INPUT_DATA_TYPES;
const dateFormat = 'MM/DD/YYYY';
const yearFormat = 'yyyy';
export const PROPERTY_INFO = {
  LABEL: 'Property Info',
  KEY: 'propertyInfo',
  HEADING: 'Confirm the property and coverage details',
  PROPERTY_INFO_DETAILS: {
    SUB_TITLE: 'Property Details',
    INPUT_FIELDS: {
      YEAR_BUILT: {
        LABEL: 'Year Built',
        KEY: 'yearBuilt',
        TYPE: DATE,
        DATE_VIEW_TYPE: YEAR,
        DATE_FORMAT: yearFormat,
        DATA_TYPE: NUMBER,
        MIN_VALUE: moment().subtract(149, YEAR).toString(),
        MAX_VALUE: moment().add(1, YEAR).toString(),
      },
      SQUARE_FOOTAGE: {
        LABEL: 'Square Footage',
        KEY: 'squareFootage',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      NO_OF_FAMILY_UNIT: {
        LABEL: 'Number of Family Units',
        KEY: 'numberOfFamilyUnits',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: '1', value: '1', default: true },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5 or more', value: '5+' },
        ],
      },
      NO_OF_STORIES: {
        LABEL: 'Number of Stories',
        KEY: 'numberOfStories',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: '1', value: '1', default: true },
          { label: '1.5', value: '1.5' },
          { label: '2', value: '2' },
          { label: '2.5', value: '2.5' },
          { label: '3 or more', value: '3+' },
        ],
      },
      FOUNDATION_TYPE: {
        LABEL: 'Foundation Type',
        KEY: 'foundationType',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Basement', value: 'basement' },
          { label: 'Crawlspace', value: 'crawlspace' },
          { label: `Crawlspace/Enclosed Piers up to 6'elevations`, value: 'crawlspace' },
          {
            label: `Crawlspace/Foundations and Piers > 6'elevations`,
            value: 'crawlspace',
          },
          { label: 'Slab', value: 'slab', default: true },
          { label: 'Deep Pilings', value: 'slab' },
          { label: 'Elevated Post/Pier & Beam', value: 'slab' },
          { label: 'Pier & Grade Beam', value: 'slab' },
          { label: 'Pilings-Other', value: 'slab' },
          { label: 'Pilings-Wood', value: 'slab' },
          { label: 'Pilings/Stilts of Reinforced Masonry Construction', value: 'slab' },
          { label: 'Shallow Basement', value: 'basement' },
          { label: `Stilts/Pilings 8'-10'elevations`, value: 'slab' },
          { label: 'Stilts/Pilings other', value: 'slab' },
          { label: 'Stilts with Sweep Away Walls', value: 'slab' },
          { label: 'Wood Stilts/Pilings', value: 'slab' },
        ],
      },
      BASEMENT_FINISH_TYPE: {
        LABEL: 'Basement Finish Type',
        KEY: 'basementFinishType',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: `Finished`, value: BasementFinishType.FINISHED, default: true },
          { label: 'Partially Finished', value: BasementFinishType.PARTIALLY_FINISHED },
          { label: 'Unfinished', value: BasementFinishType.UNFINISHED },
        ],
      },
      BASEMENT_FINISH_PERCENTAGE: {
        LABEL: 'Basement Finish %',
        KEY: 'basementFinishedPercent',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      CONSTRUCTION_TYPE: {
        LABEL: 'Construction Type',
        KEY: 'extWallFinish',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Adobe', value: 'BR' },
          { label: 'Aluminum/Vinyl', value: 'BR' },
          { label: 'Barn Plank', value: 'BR' },
          { label: 'Brick', value: 'BR' },
          { label: 'Brick on Block', value: 'SBR' },
          { label: 'Brick on Block, Custom', value: 'SBC' },
          { label: 'Brick Veneer', value: 'BR', default: true },
          { label: 'Brick Veneer, Custom', value: 'BRC' },
          { label: 'Cement Fiber Shingles', value: 'FCEMS' },
          { label: 'Clapboard', value: 'FCEM' },
          { label: 'Concrete Decorative Block, Painted', value: 'BLD' },
          { label: 'Exterior Insulation and Finish System (EIFS)', value: 'BR' },
          { label: 'Fire Resistant', value: 'BR' },
          { label: 'Frame', value: 'RC' },
          { label: 'Logs', value: 'LOG' },
          { label: 'Poured Concrete', value: 'BR' },
          { label: 'Siding, Aluminum', value: 'AL' },
          { label: 'Siding, Hardboard', value: 'ASB' },
          { label: 'Siding, Plywood', value: 'RC' },
          { label: 'Siding, Steel', value: 'STL' },
          { label: 'Siding, T-111', value: 'T111' },
          { label: 'Siding, Vinyl', value: 'VL' },
          { label: 'Siding, Wood', value: 'BAB' },
          { label: 'Slump Block', value: 'BR' },
          { label: 'Solid Brick', value: 'BR' },
          { label: 'Solid Brick, Custom', value: 'BR' },
          { label: 'Solid Brownstone', value: 'BR' },
          { label: 'Solid Stone', value: 'SSTN' },
          { label: 'Solid Stone, Custom', value: 'SSTN' },
          { label: 'Stone on Block', value: 'BR' },
          { label: 'Stone on Block, Custom Stone', value: 'BR' },
          { label: 'Stone Veneer', value: 'STN' },
          { label: 'Stone Veneer, Custom', value: 'STNC' },
          { label: 'Stucco', value: 'STU' },
          { label: 'Stucco on Block', value: 'SSTU' },
          { label: 'Stucco on Frame', value: 'SSTU' },
          { label: 'Victorian Scalloped Shakes', value: 'SHN' },
          { label: 'Window Wall', value: 'BR' },
          { label: 'Wood Shakes', value: 'SHN' },
        ],
      },
      ROOF_TYPE: {
        LABEL: 'Roof Material',
        KEY: 'roofType',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Asbestos', value: 'asphalt_fiberglass_shingles' },
          { label: 'Foam', value: 'flat_roof' },
          { label: 'Gravel', value: 'flat_roof' },
          { label: 'Metal, Copper (Flat)', value: 'flat_roof' }, // NOTE: In the new Excel sheet, there are multiple labels with identical names, so we are appending (Flat/Pitched) to distinguish the option labels.
          { label: 'Metal, Copper (Pitched)', value: 'steel_or_metal' },
          { label: 'Metal, Corrugated Steel (Flat)', value: 'flat_roof' },
          { label: 'Metal, Corrugated Steel (Pitched)', value: 'steel_or_metal' },
          { label: 'Metal (Flat)', value: 'flat_roof' },
          { label: 'Metal (Pitched)', value: 'steel_or_metal' },
          { label: 'Mineral Fiber Shake', value: 'wood_shingle_or_shake' },
          { label: 'Rock', value: 'flat_roof' },
          { label: 'Rolled Paper (Flat)', value: 'flat_roof' },
          { label: 'Rolled Paper (Pitched)', value: 'asphalt_fiberglass_shingles' },
          { label: 'Rubber (Flat)', value: 'flat_roof' },
          { label: 'Rubber (Pitched)', value: 'flat_roof' },
          { label: 'Shingles, Architectural', value: 'architectural_shingles' },
          { label: 'Shingles, Asphalt', value: 'asphalt_fiberglass_shingles' },
          { label: 'Shingles, Asphalt Fiberglass', value: 'asphalt_fiberglass_shingles' },
          { label: 'Shingles, Composition', value: 'asphalt_fiberglass_shingles' },
          { label: 'Slate', value: 'slate_tile' },
          { label: 'Tar', value: 'flat_roof' },
          { label: 'Tar and Gravel', value: 'flat_roof' },
          { label: 'Tile (Clay)', value: 'clay_tile' },
          { label: 'Tile (Concrete)', value: 'concrete_tile' },
          { label: 'Tile (Spanish)', value: 'concrete_tile' },
          { label: 'Tin (Flat)', value: 'flat_roof' },
          { label: 'Tin (Pitched)', value: 'steel_or_metal' },
          { label: 'Wood Fiberglass Shingles', value: 'wood_shingle_or_shake' },
          { label: 'Wood Shake', value: 'wood_shingle_or_shake' },
          { label: 'Wood Shingles', value: 'wood_shingle_or_shake' },
          { label: 'Other', value: 'flat_roof', default: true },
        ],
      },
      ROOF_SHAPE: {
        LABEL: 'Roof Style',
        KEY: 'roofShape',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Butterfly', value: 'custom' },
          { label: 'Flat', value: 'flat', default: true },
          { label: 'Gable', value: 'gable' },
          { label: 'Gambrel', value: 'gambrel' },
          { label: 'Hip', value: 'hip' },
          { label: 'Mansard', value: 'mansard' },
          { label: 'Shed', value: 'shed' },
          { label: 'Other', value: 'custom' },
        ],
      },
      RESIDENCY_STATUS: {
        LABEL: 'Residency Status',
        KEY: 'residenceType',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          {
            label: 'Primary',
            value: 'PRIMARY',
            default: true,
          },
          {
            label: 'Secondary',
            value: 'SECONDARY',
          },
        ],
      },
      BUILDING_QUALITY: {
        LABEL: 'Building Quality',
        KEY: 'buildingQuality',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          {
            label: 'Economy',
            value: 'economy',
          },
          {
            label: 'Standard',
            value: 'standard',
            default: true,
          },
          {
            label: 'Above Average',
            value: 'above_average',
          },
          {
            label: 'Custom',
            value: 'custom',
          },
          {
            label: 'Premium',
            value: 'premium',
          },
        ],
      },
      BUILDING_CONDITION: {
        LABEL: 'Building Condition',
        KEY: 'buildingCondition',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          {
            label: 'Fair',
            value: 'fair',
          },
          {
            label: 'Average',
            value: 'average',
            default: true,
          },
          {
            label: 'Excellent',
            value: 'excellent',
          },
        ],
      },
      PROTECTION_CLASS: {
        LABEL: 'Protection Class',
        KEY: 'protectionClass',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: '1', value: '1', default: true },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: '4', value: '4' },
          { label: '5', value: '5' },
          { label: '6', value: '6' },
          { label: '7', value: '7' },
          { label: '8', value: '8' },
          { label: '9', value: '9' },
          { label: '10', value: '10' },
        ],
      },
      DISTANCE_TO_FIRE_STATION_IN_MILES: {
        LABEL: 'Distance to Fire Station in Miles',
        KEY: 'milesFromFireDepartment',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      SWIMMING_POOL: {
        LABEL: 'Swimming Pool',
        KEY: 'swimmingPool',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: BOOLEAN_OPTIONS,
      },
      SWIMMING_POOL_FENCED: {
        LABEL: 'Swimming Pool Fenced?',
        KEY: 'poolFenced',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No', default: true },
        ],
      },
      SWIMMING_POOL_TYPE: {
        LABEL: 'Swimming Pool Type',
        KEY: 'swimmingPoolType',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          {
            label: 'Above Ground with Slide',
            value: 'above_ground_with_slide',
            default: true,
          },
          {
            label: 'Above Ground without Slide',
            value: 'above_ground_without_slide',
          },
          {
            label: 'In Ground with Slide',
            value: 'in_ground_with_slide',
          },
          {
            label: 'In Ground without Slide',
            value: 'in_ground_without_slide',
          },
        ]
      },
      TRAMPOLINE: {
        LABEL: 'Trampoline',
        KEY: 'trampoline',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: BOOLEAN_OPTIONS,
      },
      WITH_IN_CITY_LIMITS: {
        LABEL: 'Within City Limits?',
        KEY: 'cityLimit',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Yes', value: 'Yes', default: true },
          { label: 'No', value: 'No', },
        ],
      },
      ROOF_CONSTRUCTED_OR_REPLACED: {
        LABEL: 'Updated Roof?',
        KEY: 'yearRoofBuilt',
        TYPE: DATE,
        DATE_VIEW_TYPE: YEAR,
        DATE_FORMAT: yearFormat,
        DATA_TYPE: NUMBER,
        MIN_VALUE: moment().subtract(99, YEAR).toString(),
        MAX_VALUE: moment().add(1, YEAR).toString(),
      },
      PURCHASE_DATE: {
        LABEL: 'Purchase Date',
        KEY: 'purchaseDate',
        TYPE: DATE,
        DATE_VIEW_TYPE: DATE,
        DATE_FORMAT: dateFormat,
        DATA_TYPE: STRING,
        MIN_VALUE: moment().subtract(99, YEAR).toString(),
        MAX_VALUE: moment().toString(),
      },
      UPDATED_ELECTRIC: {
        LABEL: 'Updated Electric?',
        KEY: 'updatedElectricYear',
        TYPE: DATE,
        DATE_VIEW_TYPE: YEAR,
        DATE_FORMAT: yearFormat,
        DATA_TYPE: STRING,
        MIN_VALUE: moment().subtract(99, YEAR).toString(),
        MAX_VALUE: moment().add(1, YEAR).toString(),
      },
      UPDATED_HEATING: {
        LABEL: 'Updated Heating?',
        KEY: 'updatedHeatingYear',
        TYPE: DATE,
        DATE_VIEW_TYPE: YEAR,
        DATE_FORMAT: yearFormat,
        DATA_TYPE: STRING,
        MIN_VALUE: moment().subtract(99, YEAR).toString(),
        MAX_VALUE: moment().add(1, YEAR).toString(),
      },
      UPDATED_PLUMBING: {
        LABEL: 'Updated Plumbing?',
        KEY: 'updatedPlumbingYear',
        TYPE: DATE,
        DATE_VIEW_TYPE: YEAR,
        DATE_FORMAT: yearFormat,
        DATA_TYPE: STRING,
        MIN_VALUE: moment().subtract(99, YEAR).toString(),
        MAX_VALUE: moment().add(1, YEAR).toString(),
      },
    }
  },
  OTHER_DETAILS: {
    SUB_TITLE: 'Discounts',
    INPUT_FIELDS: {
      FIRE_ALARM: {
        LABEL: 'Fire Alarm',
        KEY: 'smartHomeFireAlarm',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: BOOLEAN_OPTIONS,
      },
      FIRE_EXTINGUISHER: {
        LABEL: 'Fire Extinguisher',
        KEY: 'fireExt',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: BOOLEAN_OPTIONS,
      },
      SPIRNKLER_SYSTEM: {
        LABEL: 'Sprinkler System',
        KEY: 'sprinkler',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: BOOLEAN_OPTIONS,
      },
      SECURITY_GUARD: {
        LABEL: 'Security Guard',
        KEY: 'guard',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: BOOLEAN_OPTIONS,
      },
    }
  },
  COVERAGE_DETAILS: {
    SUB_TITLE: 'Coverage Details',
    INPUT_FIELDS: {
      RECONSTRUCTION_COST_ESTIMATOR: {
        LABEL: 'Reconstruction Cost Estimator (RCE)',
        KEY: 'rebuildingCost',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      DWELLING_LIMIT_COV_A: {
        LABEL: 'Coverage A / Dwelling Limit',
        KEY: 'coverageA',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      OTHER_STRUCTURE_LIMIT_OR_COV_B: {
        LABEL: 'Coverage B / Other Structures Limit',
        KEY: 'coverageB',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      PERSONAL_PROPERTY_LIMIT_OR_COV_C: {
        LABEL: 'Coverage C / Personal Property Limit',
        KEY: 'coverageC',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      LOSS_OF_USE_LIMIT_OR_COV_D: {
        LABEL: 'Coverage D / Loss of Use Limit',
        KEY: 'coverageD',
        TYPE: TEXT,
        DATA_TYPE: NUMBER,
      },
      PERSONAL_LIABILITY_LIMIT_OR_COV_E: {
        LABEL: 'Coverage E / Personal Liability Limit',
        KEY: 'coverageE',
        TYPE: SELECT,
        DATA_TYPE: NUMBER,
        OPTIONS: [
          { label: '$25,000', value: '25000' },
          { label: '$50,000', value: '50000' },
          { label: '$100,000', value: '100000' },
          { label: '$200,000', value: '200000' },
          { label: '$300,000', value: '300000' },
          { label: '$400,000', value: '400000' },
          { label: '$500,000', value: '500000' },
        ],
      },
      MEDICAL_PAYMENTS_LIMIT_OR_COV_F: {
        LABEL: 'Coverage F / Medical Payments Limit',
        KEY: 'coverageF',
        TYPE: SELECT,
        DATA_TYPE: NUMBER,
        OPTIONS: [
          { label: '$1,000', value: '1000' },
          { label: '$2,000', value: '2000' },
          { label: '$3,000', value: '3000' },
          { label: '$4,000', value: '4000' },
          { label: '$5,000', value: '5000' },
        ],
      },
      PREFERRED_DEDUCTIBLE: {
        LABEL: 'Preferred Deductible',
        KEY: 'deductible',
        TYPE: SELECT,
        DATA_TYPE: NUMBER,
        OPTIONS: [
          { label: '$100', value: '100' },
          { label: '$250', value: '250' },
          { label: '$500', value: '500' },
          { label: '$750', value: '750' },
          { label: '$1,000', value: '1000', default: true },
          { label: '$2,000', value: '2000' },
          { label: '$2,500', value: '2500' },
          { label: '$3,000', value: '3000' },
          { label: '$4,000', value: '4000' },
          { label: '$5,000', value: '5000' },
        ],
      },
      WATER_BACKUP_LIMIT: {
        LABEL: 'Water Backup Limit',
        KEY: 'waterBackup',
        TYPE: SELECT,
        DATA_TYPE: NUMBER,
        OPTIONS: [
          { label: '$1,000', value: '1000' },
          { label: '$2,000', value: '2000' },
          { label: '$3,000', value: '3000' },
          { label: '$4,000', value: '4000' },
          { label: '$5,000', value: '5000' },
          { label: '$6,000', value: '6000' },
          { label: '$7,000', value: '7000' },
          { label: '$8,000', value: '8000' },
          { label: '$9,000', value: '9000' },
          { label: '$10,000', value: '10000' },
          { label: '$15,000', value: '15000' },
          { label: '$20,000', value: '20000' },
          { label: '$25,000', value: '25000' },
          { label: '$50,000', value: '50000' },
        ],
      },
      REPLACEMENT_COST: {
        LABEL: 'Replacement Cost',
        KEY: 'personalPropertyReplacementCost',
        TYPE: SELECT,
        DATA_TYPE: BOOLEAN,
        OPTIONS: [
          { label: 'Yes', value: 'true', default: true },
          { label: 'No', value: 'false' },
        ],
      },
      PREVIOUS_POLICY_CANCELED: {
        LABEL: 'Previous Policy Canceled',
        KEY: 'previousPolicyCancelled',
        TYPE: SELECT,
        DATA_TYPE: STRING,
        OPTIONS: [
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No', default: true },
        ],
      },
    }
  },
};
export const RETRY = 'Retry';
export const BASEMENT = 'basement';
export const PREFILL_RETRIES_MAX_COUNT = 3;
export const FLAT_ROOF = 'flat_roof';
export const DEFAULT_ROOT_SHAPE = 'flat~Flat';
export const UNFINISHED = 'unfinished';
export const HUNDRED = '100';
export const FIFTY = '50';
export const PROPERTY_INFO_DEFAULT_DETAILS = {
  DEDUCTIBLE: {
    KEY: 'deductible',
    DEFAULT_VALUE: 1000,
  },
  CONSTRUCTION_TYPE: {
    KEY: 'constructionType',
    DEFAULT_VALUE: 'frame',
  },
  REBUILDING_COST: {
    KEY: 'rebuildingCost',
  },
};
export const PREFERRED_DEDUCTIBLE = {
  AL: [
    { label: '$100', value: '100' },
    { label: '$250', value: '250' },
    { label: '$500', value: '500' },
    { label: '$750', value: '750' },
    { label: '$1,000', value: '1000', default: true },
    { label: '$1,500', value: '1500' },
    { label: '$2,000', value: '2000' },
    { label: '$2,500', value: '2500' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
  ],
  IL: [
    { label: '$100', value: '100' },
    { label: '$250', value: '250' },
    { label: '$500', value: '500' },
    { label: '$750', value: '750' },
    { label: '$1,000', value: '1000', default: true },
    { label: '$1,500', value: '1500' },
    { label: '$2,000', value: '2000' },
    { label: '$2,500', value: '2500' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
    { label: '$10,000', value: '10000' },
  ],
};

export const AL_STATE = 'AL';
export const IL_STATE = 'IL';
export const DEFAULT_DEDUCTABLE = {
  label: '$1,000',
  value: 1000
};
export const DEFAULT_RESIDENCY_STATUS = 'PRIMARY';
