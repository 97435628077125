/* istanbul ignore file */
import { SaveApplicantResponse } from '../../../quote-application/redux/apis/applicant';
import * as quotesActionType from '../constants';

// Initialize quotes for auto
export const initAutoQuotes = (): AppRedux.Action => ({
  type: quotesActionType.INIT_AUTO_QUOTES,
});

// Initialize polling
export const initializePolling = (): AppRedux.Action => ({
  type: quotesActionType.INIT_POLLING,
});

// Stops the polling
export const stopPolling = (): AppRedux.Action => ({
  type: quotesActionType.STOP_POLLING,
});

// Polling completes
export const pollingCompleted = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.POLLING_COMPLETED,
  payload,
});

// Clear all polling related data
export const clearPollingDetails = (): AppRedux.Action => ({
  type: quotesActionType.CLEAR_POLLING_DETAILS,
});

// Timeout for polling
export const autoTimeoutPolling = (): AppRedux.Action => ({
  type: quotesActionType.AUTO_TIMEOUT_POLLING,
});

// Update quoting details for further quotes polling
export const updateQuoteDetails = <T>(payload: T): AppRedux.Action => ({
  type: quotesActionType.UPDATE_QUOTE_DETAILS,
  payload,
});

// Set quotes loading flag
export const setQuotesLoading = (payload: boolean): AppRedux.Action => ({
  type: quotesActionType.QUOTES_LOADING,
  payload,
});

// Quotes loaded flag for view quotes
export const setQuotesLoaded = (payload: boolean): AppRedux.Action => ({
  type: quotesActionType.SET_QUOTES_LOADED,
  payload,
});

// Set polling object details
export const setPollingDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.SET_POLLING_DETAILS,
  payload,
});

// Send call details on purchase modal
export const sendCallDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.SEND_CALL_DETAILS,
  payload,
});

// Removes all quotes details
export const flushQuoteDetails = (): AppRedux.Action => ({
  type: quotesActionType.FLUSH_QUOTE_DETAILS,
});

// For initializing view quotes page
export const initViewQuotes = (payload: string): AppRedux.Action => ({
  type: quotesActionType.INIT_VIEW_QUOTES,
  payload,
});

// Set polling object details
export const setPollingErrorDetails = (payload: SaveApplicantResponse): AppRedux.Action => ({
  type: quotesActionType.SET_POLLING_ERROR_DETAILS,
  payload,
});

// Set ezlynx error
export const updateEzlynxError = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.UPDATE_EZLYNX_ERROR,
  payload,
});

// Set view quote page flag
export const setQuoteCompleted = (payload: any): AppRedux.Action => ({
  type: quotesActionType.SET_QUOTE_COMPLETED,
  payload,
});

export const setAutoQuoteBind = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.SET_AUTO_QUOTE_BIND,
  payload,
});

export const setErrorList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.SET_ERROR_LIST,
  payload,
});

export const getQuoteLobDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.GET_QUOTE_LOB_DETAILS,
  payload,
});

export const setQuoteLobDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.SET_QUOTE_LOB_DETAILS,
  payload,
});

export const setAutoQuoteDetails = (payload: any): AppRedux.Action => ({
  type: quotesActionType.SET_AUTO_QUOTE_DETAILS,
  payload,
});

export const clearQuoteLobDetails = (): AppRedux.Action => ({
  type: quotesActionType.CLEAR_QUOTE_LOB_DETAILS,
});

export const saveAutoQuoteDetails = (payload: string): AppRedux.Action => ({
  type: quotesActionType.SAVE_AUTO_QUOTE_DETAILS,
  payload,
});

export const getVeriskAutoDetails = (): AppRedux.Action => ({
  type: quotesActionType.REQUEST_VERISK_API,
});

export const clearAutoQuoteDetails = (): AppRedux.Action => ({
  type: quotesActionType.CLEAR_AUTO_QUOTE_DETAILS,
});

export const clearAutoDataOnApplicantChanges = (): AppRedux.Action => ({
  type: quotesActionType.CLEAR_AUTO_DATA_ON_APPLICANT_CHANGES,
});

export const refreshAutoQuotes = (): AppRedux.Action => ({
  type: quotesActionType.REFRESH_AUTO_QUOTES,
});

export const setViewQuoteLoader = (payload: boolean): AppRedux.Action => ({
  type: quotesActionType.SET_VIEW_QUOTE_LOADER,
  payload,
});

export const setAutoDownloadQuoteLoader = (payload: {
  carrier_id: string;
  loader: boolean;
}): AppRedux.Action => ({
  type: quotesActionType.SET_AUTO_DOWNLOAD_QUOTE_LOADER,
  payload,
});

export const downloadAutoQuoteDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: quotesActionType.DOWNLOAD_AUTO_QUOTE_DETAILS,
  payload,
});
