import React from 'react';
import OrganizationTable from './organization-table';

const Admin = () => {
  return (
    <div>
      <OrganizationTable />
    </div>
  );
};

export default Admin;
