/* istanbul ignore file */
import { AxiosError } from 'axios';
import { assignIn, filter, find, get, isEmpty, isUndefined, map, omit } from 'lodash';
import { NotificationManager } from 'react-notifications';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { v4 } from 'uuid';
import config from '../../../config/config';
import {
  COVERAGES,
  CARRIER_ID,
  DRIVER,
  HOME_WARRANTY,
  INSURANCE_PRODUCTS,
  VEHICLE,
  LOB_REFRESH_TIMEOUT,
  PRODUCT_SELECTION_ERROR_MESSAGE,
  IN_PROGRESS_STATUS,
} from '../../../constants';
import {
  capitalizeEach,
  clearLobQuotes,
  createPageTracking,
  clearReduxStateOnViewQuote,
  checkSpouseMissing,
  getBoundedQuote,
  getHerdId,
  getSeperateQuoteList,
  inIframe,
  mappedResponseToStoreForPropertyDetails,
  setHeapProjectId,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
  updateApplicantinfo,
  downloadFileUrl,
  getObjectValues,
  shouldApplicantHasPreviousAddress,
  setViewQuoteDetails,
} from '../../../utils';
import {
  createUmbrellaQuestionsMapping,
  getMappedQuestionnaireResponses,
  getMappedUmbrellaQuestionnaireGroups,
  setUmbrellaQuotesMapping,
} from '../../../utils/umbrella';
import history from '../../../history';
import axiosRequest from '../../api';
import {
  addDriver,
  autoTimeoutPolling,
  errorHandler,
  flushHomeDetails,
  setApplicantDetails,
  getAutoQuotesFailed,
  getStoredDriversList,
  getStoredVehiclesList,
  getVeriskAutoDetails,
  initializePolling,
  pollingCompleted,
  setAnnexPrefill,
  setAutoQuoteBind,
  setEditVehicleFlag,
  setFetchAutoQuotes,
  setFloodQuoteBind,
  setHasFormChanged,
  setHomeQuoteBind,
  setHomeState,
  setIsVeriskFetched,
  setIsVeriskLoading,
  setLineOfBusiness,
  setLobRefreshLoader,
  setPconfig,
  setPetPolicyBounded,
  setPolicyBounded,
  setPolicyFlag,
  setPollingDetails,
  setPollingErrorDetails,
  setErrorList,
  setPageTracking,
  setQuakeQuoteBind,
  setQuoteCompleted,
  setQuoteLobDetails,
  setQuotesLoading,
  setQuakeQuotes,
  setRequestId,
  setSelectFirstDriver,
  setShowVeriskDriverModal,
  setShowVeriskVehicleModal,
  setValidatingAddress,
  setViewQuoteLoader,
  stopPolling,
  storeCommonData,
  setAllowEditQuotes,
  setFloodQuotes,
  storeBoundedCarrierDetails,
  setAutoQuoteDetails,
  setHomeWarrantyQuoteDetails,
  setNoQuotesAvailableForHome,
  storeUmbrellaQuoteDetails,
  updateApplicantAddress,
  updateApplicantDetails,
  updateDriverData,
  updateDriverName,
  updateEzlynxError,
  updateGeneralCoverages,
  updateQuoteDetails,
  updateSelectedCarriers,
  updateVehicleName,
  updateRequoteProducts,
  setPrefillDetailsForRequestBind,
  setAutoDownloadQuoteLoader,
  setNextTabStep,
  setFloodDetails,
  flushAppDetails,
} from '../actions';
import * as actions from '../constants';
import { vinSubmitted } from './vehicle';
import {
  setPropertyId,
  setPropertyOtherDetails,
  setPropertyRevision,
  setShowPropertyDataLooseWarning,
} from '../../../quote-application/redux/slices/property-info-details.slice';
import {
  getPConfigDetails,
  residenceTypeMapping,
  updateCoverageDetailsForFlood,
} from '../../../quote-application/utils';
import { setHomeQuoteDetails } from '../../../quote-application/redux/slices';
import { ResidenceType } from '../../../graphql/generated';

const quoteActions: any = actions;
const pollConfig: any = {
  publicUrl: config.serverURL,
  pollingTimerTimeout: config.pollingTimerTimeout,
  pollingInterval: config.pollingInterval,
};
const displayErrorProperty = 'error_details.display_errors_on_ui';
const errorProperty = 'error_details.errors';

const pollingDelay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
};

function* handleQuoteError(error: any) {
  if (!isEmpty(error.response) && error.response.status) {
    if (error.response.status === 404) {
      if (
        get(error.response.data, displayErrorProperty, false) &&
        !isEmpty(get(error.response.data, errorProperty, {}))
      ) {
        yield put(updateEzlynxError(error.response.data));
      } else {
        yield put(getAutoQuotesFailed(true));
      }
    } else {
      yield put(getAutoQuotesFailed(true));
    }
  } else {
    yield put(getAutoQuotesFailed(true));
    yield put(errorHandler(error));
  }
}

function* initializeQuotesPolling() {
  const { quote: quotesDetails } = yield select();
  if (quotesDetails?.isQuoteRefresh) {
    yield put(
      setQuoteCompleted({
        isQuoteRefresh: false,
      } as any)
    );
  }
  const quoteLimit = get(quotesDetails, 'quotingDetails.totalEzlynxQuotes', 0);
  if (quotesDetails.quoteErrorList.length < quoteLimit) {
    yield put(initializePolling());
    yield call(pollingDelay, pollConfig.pollingTimerTimeout);
    yield put(autoTimeoutPolling());
  }
}

function* initQuotes(): any {
  yield put(getAutoQuotesFailed(false));
  yield put(setQuotesLoading(true));
  yield put(setFetchAutoQuotes(false));
  yield put(setHasFormChanged(false));
  yield put(setPolicyFlag(false));
  yield put(setAutoQuoteDetails({ quotes: [], errors: [] }));
  try {
    trackFirstLoadQuoteInHeap();
    const { applicant, common, vehicle, driver, insurance } = yield select();
    let filteredVehicleList: any = map(vehicle.vehiclesList, vehicleData => {
      const { comprehensive, collision, towing_labor, ext_trans_expense } = vehicleData;
      return omit(
        {
          ...vehicleData,
          vin: isEmpty(vehicleData.vin) ? vehicleData.defaultVin : vehicleData.vin,
          coverage: { comprehensive, collision, towing_labor, ext_trans_expense },
        },
        [
          'bodyTypeList',
          'vehicleSelectorDetails',
          'clearedVehicle',
          'selectedVehicle',
          'vinLoader',
          'defaultVin',
          'comprehensive',
          'collision',
          'towing_labor',
          'ext_trans_expense',
        ]
      );
    });
    filteredVehicleList = filter(filteredVehicleList, vehicleDetails => {
      if (vehicleDetails.vehicleAdded) {
        return { ...omit(vehicleDetails, ['vehicleAdded']) };
      }
      return false;
    });
    let filteredDriverList: Array<any> = [...driver.driversList];
    // If drivers added more than required(happens only in case of spouse details)
    if (filteredDriverList.length > config.maxDriverAddLimit) {
      const driverToRemove = find(
        filteredDriverList,
        driverDetails => !driverDetails.isPrimaryDriver && !driverDetails.isSpouse
      );
      filteredDriverList = filter(
        filteredDriverList,
        driverDetails => driverDetails.duid !== driverToRemove.duid
      );
    }
    filteredDriverList = map(filteredDriverList, driverData => {
      return omit(driverData, ['driverLoader']);
    });
    const insuranceDetails: any = {
      ...omit(
        {
          ...insurance,
          coverage: {
            motorist: insurance.motorist,
            injury_limits: insurance.injury_limits,
            property_damage: insurance.property_damage,
            medical_payments: insurance.medical_payments,
          },
        },
        [
          'injury_limits_data',
          'property_damage_data',
          'motorist_damage_data',
          'medical_payments_data',
          'prior_liability_limits_data',
          'motorist',
          'injury_limits',
          'property_damage',
          'medical_payments',
        ]
      ),
    };
    const reduxStore = yield select();
    const {
      partner: { appointments },
    } = reduxStore;
    const keys = Object.keys(appointments);
    const {
      quote,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails },
    } = yield select();
    const daleConfig =
      common.pconfig.pconfig?.dale_config || common.pconfig?.dale_config || getPConfigDetails();
    yield put(
      setQuoteCompleted({
        quoteStatus: '',
      } as any)
    );
    const { carrier_credentials_grid, ...configWithoudCreds } = daleConfig;
    const { code, auth_token } = configWithoudCreds;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
      ? producerDetails?.producerEmail
      : '';
    const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } = clearLobQuotes();
    let pageTracking = createPageTracking(!clearAutoInfo ? common?.pageTracking : {}, appointments);
    const requestedPayload: any = {}
    if (shouldApplicantHasPreviousAddress()) {
      requestedPayload.isMovedInPastMonths = !!applicant.isMovedInPastMonths;
      requestedPayload.previousAddress = !!applicant.isMovedInPastMonths
        ? { ...applicant.previousAddress }
        : null;
    }
    const details = yield axiosRequest('POST', '/auto/quotes', isProviderOneLogin, {
      ...requestedPayload,
      pageTracking,
      lobQuotesToClear,
      clearAutoInfo,
      clearPetInfo,
      clearLifeInfo,
      clearUmbrellaInfo,
      personal_information: { ...omit(applicant.personal_details, 'preferredDeductible') },
      address: { ...applicant.address },
      partner_code: code,
      request_id: v4(),
      auth_token: auth_token,
      vehicle_details: [...filteredVehicleList],
      driver_details: [...filteredDriverList],
      insurance_other_details: { ...insuranceDetails },
      code: code,
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: get(quote, 'herdId', ''),
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
    });
    if (!isEmpty(details?.data) && get(details, 'data.herd_id', null)) {
      const { upcomingCarrierList, quotingCarriers } = details.data;
      yield put(updateQuoteDetails({ ...details.data }));
      yield put(
        setQuoteCompleted({
          quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        } as any)
      );
      if (
        get(details.data, displayErrorProperty, false) &&
        !isEmpty(get(details.data, errorProperty, {}))
      ) {
        yield put(setPollingErrorDetails({ ...details.data }));
      }
      yield call(initializeQuotesPolling);
    }
  } catch (error) {
    yield call(handleQuoteError, error);
    yield put(errorHandler(error));
  }
  yield put(setQuotesLoading(false));
}

export function* initQuotesWatcher() {
  yield takeLatest(quoteActions.INIT_AUTO_QUOTES, initQuotes);
}

function* pollQuotes(): any {
  while (true) {
    const {
      quote,
      partner: { isProviderOneLogin },
    } = yield select();
    try {
      if (quote?.quotingDetails?.herd_id) {
        const { data } = yield axiosRequest(
          'POST',
          '/auto/getezlynxautoquotes',
          isProviderOneLogin,
          {
            herd_id: quote.quotingDetails.herd_id,
          }
        );
        if (!isEmpty(data.quotes)) {
          yield put(setPollingDetails({ ...data }));
        }
        // Sets carrier error details
        if (get(data, displayErrorProperty, false) && !isEmpty(get(data, errorProperty, {}))) {
          yield put(setPollingErrorDetails({ ...data }));
        }
        const reduxState = yield select();
        const currentQuotesLength =
          reduxState.quote.quoteList.length + reduxState.quote.quoteErrorList.length;
        const quoteLimit = get(quote, 'quotingDetails.totalEzlynxQuotes', 0);
        if (currentQuotesLength >= quoteLimit) {
          if(reduxState.quote.tempErrorQuoteList.length) {
            yield put(setErrorList(reduxState.quote.tempErrorQuoteList));
            yield put(pollingCompleted({
              tempErrorQuoteList: [],
            } as any));
          }
          trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[1]);
          yield put(setQuotesLoading(false));
          yield put(stopPolling());
          if (quote?.isQuoteRefresh) yield put(setLobRefreshLoader(false));
        } else if (!quote?.isQuoteRefresh) {
          yield call(pollingDelay, pollConfig.pollingInterval);
        }
      }
    } catch (error) {
      yield put(errorHandler(error));
    }
  }
}

function* pollQuotesWatcher() {
  while (true) {
    yield take(quoteActions.INIT_POLLING);
    yield race([call(pollQuotes), take(quoteActions.STOP_POLLING)]);
  }
}

function* autoPollingTimeout() {
  const {
    quote: { quoteList, quoteErrorList, quotingDetails, tempErrorQuoteList },
  } = yield select();
  if (quoteList.length + quoteErrorList.length === 0) {
    yield put(getAutoQuotesFailed(true));
  }
  if(tempErrorQuoteList.length) {
    yield put(setErrorList(tempErrorQuoteList));
    yield put(pollingCompleted({
      tempErrorQuoteList: [],
    } as any));
  }
  yield put(stopPolling());
  if (
    !isEmpty(quotingDetails) &&
    quoteList.length + quoteErrorList.length < quotingDetails.totalEzlynxQuotes
  ) {
    trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[1]);
  }
}

function* autoPollingTimeoutWatcher() {
  yield takeLatest(quoteActions.AUTO_TIMEOUT_POLLING, autoPollingTimeout);
}

// Get view quotes details
function* initViewQuotes({ payload }: AppRedux.ActionPayload): any {
  yield put(getAutoQuotesFailed(false));
  yield put(setQuotesLoading(true));
  yield put(setFetchAutoQuotes(false));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const quotesDetails = yield axiosRequest('POST', '/auto/view', isProviderOneLogin, {
      herd_id: payload,
    });
    yield put(flushHomeDetails());
    yield put(setPolicyBounded(get(quotesDetails, 'data.quoteBounded', {})));
    if (quotesDetails.data) {
      const { applicant, address, vehicle_details, driver_details, insurance_other_details } =
        quotesDetails.data;
      yield put(setEditVehicleFlag(false));
      yield put(setLineOfBusiness(INSURANCE_PRODUCTS[1]));
      yield put(updateDriverData({ ...driver_details }));
      yield put(updateVehicleName({ ...vehicle_details }));
      yield put(updateApplicantDetails({ ...applicant }));
      yield put(updateApplicantAddress({ ...address }));
      yield put(updateGeneralCoverages({ ...insurance_other_details }));
      yield put(setPollingDetails({ ...quotesDetails.data }));
      const { quote, common, home } = yield select();
      yield put(
        setPconfig({
          defaultPConfig: common.defaultPConfig || home.defaultPConfig,
          pconfig: { pconfig: { ...quotesDetails.data.organization } },
        } as any)
      );
      yield put(
        setHomeState({
          pconfig: { ...quotesDetails.data.organization },
          code: quotesDetails.data.organization.code,
        } as any)
      );
      // Set old herd id for view quotes page
      yield put(updateQuoteDetails({ ...quote.quotingDetails, herd_id: payload }));
      yield put(setQuoteCompleted({ quoteCompleted: true } as any));
    }
  } catch (error) {
    yield put(getAutoQuotesFailed(true));
    yield put(errorHandler(error));
  }
  yield put(setQuotesLoading(false));
}

export function* initViewQuotesWatcher() {
  yield takeEvery(quoteActions.INIT_VIEW_QUOTES, initViewQuotes);
}

function* stopPollingWorker() {
  const {
    quote: { quotingDetails, quoteList, quoteErrorList, quotesLoading },
  } = yield select();
  if (quotingDetails && !quotesLoading && quoteList.length + quoteErrorList.length > 0) {
    yield put(pollingCompleted({ quotesLoaded: true } as any));
    yield put(setQuoteCompleted({ quoteCompleted: true } as any));
  }
}

export function* stopPollingWatcher() {
  yield takeEvery(quoteActions.STOP_POLLING, stopPollingWorker);
}

function* getQuoteLobDetails(details: any): any {
  yield put(flushAppDetails());
  yield put(setViewQuoteLoader(true));
  yield put(storeCommonData({ customId: '', loanNumber: '' }));
  yield put(setNoQuotesAvailableForHome(false));
  yield put(setLobRefreshLoader(false));
  yield put(setNextTabStep(false));
  try {
    const {
      home,
      partner: { isProviderOneLogin },
    } = yield select();
    clearReduxStateOnViewQuote();
    const { defaultPConfig } = home
    let herdId = details?.payload?.herdId || getHerdId();
    yield put(
      setApplicantDetails({
        prefillDetails: null,
        userPropertyInformation: null,
        isApplicantSubmittedOnceForAuto: false,
        isMovedInPastMonths: false,
        previousAddress: null,
      })
    );
    sessionStorage.selectedQuoteList = [];
    const response = yield axiosRequest('POST', '/quotes/view', isProviderOneLogin, {
      herdId: herdId,
    });
    if (response.data) {
      const {
        adhocBoundedLobs,
        annexRequiredPrefill,
        carriersBounded,
        organization,
        providerRequestId,
        quoteBounded,
        quotesByLOB,
        selectedCarriers,
        customId,
        selectedLOBs,
        loanNumber,
        applicant,
        address,
        isMovedInPastMonths,
        previousAddress,
        pageTracking,
        prefillData,
        noOfRetries,
        prefillStatus,
        propertyId,
        propertyRevision,
        showPropertyDataLooseWarning,
      } = response.data;
      if(!!customId || !!loanNumber || !!adhocBoundedLobs) {
        let partnerData: any = {
          customId: !!customId ? customId : '',
          loanNumber: !!loanNumber ? loanNumber : '',
          adhocBoundedLobs:
            !!adhocBoundedLobs && !isEmpty(adhocBoundedLobs) ? adhocBoundedLobs : [],
        };
        yield put(storeCommonData({ ...partnerData, selectedLOBs }));
      }
      yield put(setPropertyId(!!propertyId ? propertyId : ''));
      yield put(setPropertyRevision(propertyRevision ?? 0));
      yield put(setShowPropertyDataLooseWarning(!!showPropertyDataLooseWarning));
      setViewQuoteDetails(quotesByLOB);
      setHeapProjectId(organization, true);
      yield put(updateRequoteProducts([]));
      if (!!selectedCarriers) {
        yield put(updateSelectedCarriers(selectedCarriers));
      }
      updateApplicantinfo(applicant, address, previousAddress);
      let pageTrackings = createPageTracking(pageTracking, selectedLOBs);
      yield put(setPageTracking(pageTrackings));
      yield put(
        setHomeState({
          defaultPConfig,
          pconfig: assignIn(
            {},
            {
              dale_config: organization,
            }
          ),
          ...organization,
        } as any)
      );

      const applicantDetailsPayload: any = {
        prefillDetails: !isEmpty(prefillData)
          ? mappedResponseToStoreForPropertyDetails(prefillData)
          : {
            propertyData: {},
            label: {},
          },
        prefillStatus: prefillStatus || null,
        noOfRetries: noOfRetries || 0,
      };
      if (!isUndefined(isMovedInPastMonths)) {
        applicantDetailsPayload.isApplicantSubmittedOnceForAuto = true;
        applicantDetailsPayload.isMovedInPastMonths = isMovedInPastMonths;
        applicantDetailsPayload.previousAddress = !isEmpty(previousAddress)
          ? { ...previousAddress }
          : null;
      }
      yield put(
        setApplicantDetails(applicantDetailsPayload)
      );
      if (!isEmpty(prefillData)) {
        const prefillDetails: any = mappedResponseToStoreForPropertyDetails(prefillData);
        if (!isEmpty(prefillDetails)) yield put(setPrefillDetailsForRequestBind(prefillDetails));
      }
      yield put(setAllowEditQuotes(true));
      yield put(setQuoteLobDetails({ ...response.data }));
      yield put(setRequestId(providerRequestId));
      yield put(setPolicyBounded(!!quoteBounded ? quoteBounded : {}));
      if (!!annexRequiredPrefill && annexRequiredPrefill?.lob === INSURANCE_PRODUCTS[2]) {
        const annexDetails = annexRequiredPrefill;
        yield put(
          setHomeState({
            totalEzlynxQuotes: 1,
          } as any)
        );
        yield put(setAnnexPrefill(annexDetails));
        updateCoverageDetailsForFlood(annexDetails);
      }
      yield put(
        setPconfig({
          defaultPConfig,
          pconfig: {
            dale_config: { ...organization },
            ...organization
          },
        } as any)
      );
      if (annexRequiredPrefill?.residenceType) {
        const residenceType =
          residenceTypeMapping[
            annexRequiredPrefill?.residenceType?.toUpperCase() || ResidenceType.Primary?.toUpperCase()
          ];
        yield put(
          setPropertyOtherDetails({
            residenceType,
          })
        );
      }
      if (!!quotesByLOB?.home) {
        let homeData: any = {};
        const { quotes, error_details, status } = quotesByLOB.home;
        if (
          isEmpty(quotes) &&
          isEmpty(error_details?.errors) &&
          !!error_details?.display_errors_on_ui &&
          status !== IN_PROGRESS_STATUS
        ) {
          yield put(setNoQuotesAvailableForHome(true));
        }
        const boundedHomeQuote = getBoundedQuote(quotes);
        if (!isEmpty(boundedHomeQuote)) {
          yield put(setHomeQuoteBind(get(boundedHomeQuote[0], CARRIER_ID, '')));
        }
        homeData.quoteList = !isEmpty(quotes) ? [...quotes] : [];
        homeData.quoteListError = error_details && error_details.errors
            ? Object.values(error_details.errors)
            : [];
        homeData.hippoQuoteLoaded = true;
        homeData.ezlynxQuotesLoaded = true;
        homeData.herd_id = quotesByLOB?.home?.herdId;
        yield put(setHomeState(homeData));
        yield put(setHomeQuoteDetails({ isViewingQuote: true }));
      }
      if (!!quotesByLOB?.auto) {
        const { quotes } = quotesByLOB.auto;
        const boundedAutoQuote = getBoundedQuote(quotes);

        if (quotesByLOB.auto.vehicle_details && quotesByLOB.auto.vehicle_details.length) {
          yield put(getStoredVehiclesList(quotesByLOB.auto.vehicle_details));
        }

        if (quotesByLOB.auto.driver_details && quotesByLOB.auto.driver_details.length) {
          yield put(getStoredDriversList(quotesByLOB.auto.driver_details));
        }

        if (!isEmpty(boundedAutoQuote)) {
          yield put(setAutoQuoteBind(get(boundedAutoQuote[0], CARRIER_ID, '')));
        }
      }
      if (!!quotesByLOB?.flood) {
        const { quotes, error_details, selectedCarriers } = quotesByLOB.flood;
        const boundedFloodQuote = getBoundedQuote(quotes);
        if (!isEmpty(boundedFloodQuote)) {
          yield put(setFloodQuoteBind(get(boundedFloodQuote[0], CARRIER_ID, '')));
        }
        const { errorQuotes } = getSeperateQuoteList(error_details, {});
        yield put(setFloodQuotes({
          quoteList: quotes ? quotes : [],
          quoteErrorList: errorQuotes,
          selectedCarrier: selectedCarriers?.flood?.carrierId ? selectedCarriers?.flood : {},
          herdId: quotesByLOB?.flood?.herdId,
        }));
        yield put(
          setFloodDetails({
            isPropertyInfoRequiredForFlood: false,
          })
        );
      }
      if (!!quotesByLOB?.quake) {
        const { quotes, error_details } = quotesByLOB.quake;
        const boundedQuakeQuote = getBoundedQuote(quotes);
        if (!isEmpty(boundedQuakeQuote)) {
          yield put(setQuakeQuoteBind(get(boundedQuakeQuote[0], CARRIER_ID, '')));
        }
        const { errorQuotes } = getSeperateQuoteList(error_details,{});
        setQuakeQuotes({
          herdId: quotesByLOB?.quake?.herdId,
          quoteList: [...getObjectValues(quotes), ...getObjectValues(errorQuotes)],
        });
      }
      if (!!quotesByLOB[HOME_WARRANTY]) {
        const { quotes, error_details, herdId: herd_id } = quotesByLOB[HOME_WARRANTY];
        const { errorQuotes } = getSeperateQuoteList(error_details,{});
        setHomeWarrantyQuoteDetails({
          quoteList: [...getObjectValues(quotes), ...getObjectValues(errorQuotes)],
          herdId: herd_id,
        });
      }
      if (!!quotesByLOB?.pet && quotesByLOB?.pet?.isPolicyBounded) {
        yield put(setPetPolicyBounded(true));
      }
      if (!!quotesByLOB?.umbrella) {
        const { quotes, error_details, herdId: herd_id, rliQuestionInfo } = quotesByLOB.umbrella;
        const { errorQuotes } = getSeperateQuoteList(error_details, {});
        let umbrellaQuestions: any =
          rliQuestionInfo && !isEmpty(rliQuestionInfo)
            ? getMappedUmbrellaQuestionnaireGroups(
                createUmbrellaQuestionsMapping([...rliQuestionInfo])
              )
            : null;
        yield put(
          storeUmbrellaQuoteDetails({
            umbrellaQuestionsResponse: !!umbrellaQuestions
              ? getMappedQuestionnaireResponses(umbrellaQuestions)
              : null,
            quoteList: setUmbrellaQuotesMapping([...Object.values(quotes)]),
            quoteErrorList: [...Object.values(errorQuotes)],
            herdId: herd_id,
            umbrellaInfo: {
              questionGroups: umbrellaQuestions,
            },
          })
        );
      }
      if (!isEmpty(carriersBounded)) {
        if (!!carriersBounded?.earthquake) {
          carriersBounded.quake = carriersBounded?.earthquake;
          delete carriersBounded.earthquake;
        }
        yield put(storeBoundedCarrierDetails(carriersBounded));
      }
      if (
        !quotesByLOB?.[INSURANCE_PRODUCTS[0]] &&
        !quotesByLOB?.[INSURANCE_PRODUCTS[2]] &&
        !quotesByLOB?.[INSURANCE_PRODUCTS[3]] &&
        selectedLOBs?.includes(INSURANCE_PRODUCTS[0]?.toUpperCase()) &&
        isEmpty(home?.quoteList) &&
        isEmpty(home?.quoteListError)
      ) {
        yield put(
          setHomeState({
            productType: '',
          } as any)
        );
        yield put(setValidatingAddress(false));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!inIframe() && axiosError?.response?.status === 404) {
      yield put(
        setHomeState({
          showError: true,
          notFoundError: false,
        } as any)
      );
      history.push('/error');
    }
    yield put(errorHandler(axiosError));
  }
  yield put(setViewQuoteLoader(false));
}

export function* getQuoteLobDetailsWatcher() {
  yield takeEvery(quoteActions.GET_QUOTE_LOB_DETAILS, getQuoteLobDetails);
}

function* saveAutoQuote({ payload }: AppRedux.ActionPayload): any {
  const { applicant, common, vehicle, driver, insurance, home } = yield select();
  try {
    let filteredVehicleList: any = map(vehicle.vehiclesList, vehicleData => {
      const { comprehensive, collision, towing_labor, ext_trans_expense } = vehicleData;
      return omit(
        {
          ...vehicleData,
          vin: isEmpty(vehicleData.vin) ? vehicleData.defaultVin : vehicleData.vin,
          coverage: { comprehensive, collision, towing_labor, ext_trans_expense },
        },
        [
          'bodyTypeList',
          'vehicleSelectorDetails',
          'clearedVehicle',
          'selectedVehicle',
          'vinLoader',
          'defaultVin',
          'comprehensive',
          'collision',
          'towing_labor',
          'ext_trans_expense',
        ]
      );
    });
    filteredVehicleList = filter(filteredVehicleList, vehicleDetails => {
      if (vehicleDetails.vehicleAdded) {
        return { ...omit(vehicleDetails, ['vehicleAdded']) };
      }
      return false;
    });
    let filteredDriverList: Array<any> = [...driver.driversList];
    if (filteredDriverList.length > config.maxDriverAddLimit) {
      const driverToRemove = find(
        filteredDriverList,
        driverDetails => !driverDetails.isPrimaryDriver && !driverDetails.isSpouse
      );
      filteredDriverList = filter(
        filteredDriverList,
        driverDetails => driverDetails.duid !== driverToRemove.duid
      );
    }
    filteredDriverList = map(filteredDriverList, driverData => {
      return omit(driverData, ['driverLoader']);
    });
    const insuranceDetails: any = {
      ...omit(
        {
          ...insurance,
          coverage: {
            motorist: insurance.motorist,
            injury_limits: insurance.injury_limits,
            property_damage: insurance.property_damage,
            medical_payments: insurance.medical_payments,
          },
        },
        [
          'injury_limits_data',
          'property_damage_data',
          'motorist_damage_data',
          'medical_payments_data',
          'prior_liability_limits_data',
          'motorist',
          'injury_limits',
          'property_damage',
          'medical_payments',
        ]
      ),
    };
    const reduxStore = yield select();
    const {
      partner: { appointments },
    } = reduxStore;
    const keys = Object.keys(appointments);
    const {
      quote,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails },
      driver: { driversList }
    } = yield select();
    const daleConfig = common.pconfig.pconfig?.dale_config || home.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
      ? producerDetails?.producerEmail
        : '';
    let pageTracking = createPageTracking(common?.pageTracking, appointments);
    if (!isEmpty(pageTracking)) {
      if (VEHICLE === payload) {
        pageTracking.auto = DRIVER;
      } else if (DRIVER === payload) {
        let { missingSpouse } = checkSpouseMissing(driversList);
        pageTracking.auto = missingSpouse ? DRIVER : COVERAGES;
      }
    }
    yield put(setPageTracking(pageTracking))
    const requestedPayload: any = {}
    if (shouldApplicantHasPreviousAddress()) {
      requestedPayload.isMovedInPastMonths = !!applicant.isMovedInPastMonths;
      requestedPayload.previousAddress = !!applicant.isMovedInPastMonths
        ? { ...applicant.previousAddress }
        : null;
    }
    const details = yield axiosRequest(
      'POST',
      '/auto/save-auto-questionnaire',
      isProviderOneLogin,
      {
        ...requestedPayload,
        pageTracking,
        personal_information: { ...omit(applicant.personal_details, 'preferredDeductible') },
        address: { ...applicant.address },
        partner_code: code,
        request_id: v4(),
        auth_token: auth_token,
        vehicle_details: [...filteredVehicleList],
        driver_details: [...filteredDriverList],
        insurance_other_details: { ...insuranceDetails },
        code: code,
        organization: { config: { code, auth_token, name: organizationName, organizationId } },
        herd_id: get(quote, 'herdId', ''),
        quote_by: quoteBy,
        id: get(auth, 'userId', ''),
        provider_request_id: storedRequestId,
        selected_lob: keys,
        questionnairePage: payload,
      }
    );

    if (get(details, 'data.herd_id', null)) {
      if (quote?.quotesLoading) {
        yield put(stopPolling());
        yield put(setQuotesLoading(false));
      }
      yield put(setAutoQuoteDetails({ quotes: [], errors: [] }));
      let { requoteLobs }= common;
      if (!isEmpty(requoteLobs) && requoteLobs?.includes(INSURANCE_PRODUCTS[1])) {
        const lobIndex = requoteLobs.indexOf([INSURANCE_PRODUCTS[1]]);
        requoteLobs.splice(lobIndex, 1);
        yield put(updateRequoteProducts(requoteLobs));
      }
      yield put(
        updateQuoteDetails({ ...quote.quotingDetails, herd_id: get(details, 'data.herd_id', null) })
      );
      yield put(setRequestId(get(details, 'data.provider_request_id', null)));
      if (
        get(details.data, displayErrorProperty, false) &&
        !isEmpty(get(details.data, errorProperty, {}))
      ) {
        yield put(setPollingErrorDetails({ ...details.data }));
      }

      if (is_verisk_prefill_available && !driver.driversList.length) {
        yield put(getVeriskAutoDetails());
      }
    }
  } catch (error) {
    yield call(handleQuoteError, error);
    yield put(errorHandler(error));
  }
}

export function* saveAutoQuoteWatcher() {
  yield takeLatest(quoteActions.SAVE_AUTO_QUOTE_DETAILS, saveAutoQuote);
}

function* fetchVersikDetails(): any {
  const {
    quote,
    home,
    driver,
    applicant,
    common,
    partner: { isProviderOneLogin },
  } = yield select();
  if (home.pconfig?.dale_config?.is_verisk_prefill_available && quote.herdId) {
    const { is_verisk_prefill_available, code } = home.pconfig?.dale_config;
    const { herdId } = quote;
    try {
      const body = {
        herdId,
        firstName: applicant?.personal_details?.first_name,
        lastName: applicant?.personal_details?.last_name,
        dateOfBirth: applicant?.personal_details?.date_of_birth,
        organizationCode: code,
        ...omit(applicant.address, ['validated', 'unit', 'addressLine1', 'addressLine2']),
      };

      yield put(setIsVeriskLoading(true));
      if (is_verisk_prefill_available && !driver.driversList.length && !common.isVeriskFetched) {
        const res = yield axiosRequest('POST', '/hippo/auto/prefill', isProviderOneLogin, body);
        if (res.data && res.data.success) {
          const { data } = res.data;
          const updatedDriverFromVerisk: any = {
            first_name: capitalizeEach(get(data, 'primaryDriver.firstName', '')),
            last_name: capitalizeEach(get(data, 'primaryDriver.lastName', '')),
            marital_status:
              data.primaryDriver?.maritalStatus === 'Unknown'
                ? ''
                : get(data, 'primaryDriver.maritalStatus', ''),
            driver_licence: get(data, 'primaryDriver.driverLicenseNumber', ''),
            gender: get(data, 'primaryDriver.gender', 'male').toLowerCase(),
            isPrimaryDriver: true,
            hasSpouse: data.primaryDriver?.maritalStatus === 'Married' ? true : false,
            date_of_birth: get(data, 'primaryDriver.dateOfBirth', null) || driver.date_of_birth,
            claims: get(data, 'primaryDriver.claims', 'no'),
            violations: get(data, 'primaryDriver.violations', 'no'),
            losses: get(data, 'primaryDriver.losses', 'no'),
            accidentList: get(data, 'primaryDriver.accidentList', []),
            violationList: get(data, 'primaryDriver.violationList', []),
            lossList: get(data, 'primaryDriver.lossList', []),
            us_license_status: get(data, 'primaryDriver.driverLicenseStatus', 'Valid'),
            age_licensed: get(data, 'primaryDriver.ageLicensed', '16'),
            spouseId: get(data, 'primaryDriver.spouseId', ''),
            relationship_status: get(data, 'primaryDriver.relationshipStatus', 'Insured'),
            relation_to_primary_driver: 'applicant',
          };

          yield put(updateDriverName(updatedDriverFromVerisk));

          const primaryDriver = {
            clear: true,
            ...omit(driver, ['entity', 'detailsErrors', 'driversList']),
            ...updatedDriverFromVerisk,
            hasSpouse: false,
            isFetchedFromVerisk: true,
            isIncluded: true,
          };

          yield put(addDriver(primaryDriver));

          if (data.vehicleVinCodes && data.vehicleVinCodes.length) {
            const promises: any[] = [];
            for (const vinValue of data.vehicleVinCodes) {
              promises.push(call(vinSubmitted, { payload: vinValue }));
            }

            yield all(promises);

            const { vehicle } = yield select();
            if (vehicle.vehiclesList && vehicle.vehiclesList.length) {
              yield put(setShowVeriskVehicleModal(true));
            }
          }

          if (data.secondaryDrivers && data.secondaryDrivers.length) {
            const getNullishfield = (source: any, field: string, fallback: any) => {
              const result = get(source, field) || get(source, field, fallback);
              if (result) return result;
              else return fallback;
            };
            for (const secondaryDriver of data.secondaryDrivers) {
              const secondaryDriverInfo: any = {
                clear: true,
                first_name: capitalizeEach(get(secondaryDriver, 'firstName', '')),
                last_name: capitalizeEach(get(secondaryDriver, 'lastName', '')),
                date_of_birth: get(secondaryDriver, 'dateOfBirth', ''),
                gender: getNullishfield(secondaryDriver, 'gender', 'male').toLowerCase(),
                driver_licence: get(secondaryDriver, 'driverLicenseNumber', ''),
                us_license_status: get(secondaryDriver, 'driverLicenseStatus', 'Valid'),
                age_licensed: get(secondaryDriver, 'ageLicensed', '16'),
                occupation: get(secondaryDriver, 'occupation', ''),
                marital_status:
                  secondaryDriver.maritalStatus === 'Unknown'
                    ? ''
                    : get(secondaryDriver, 'maritalStatus', ''),

                hasSpouse: get(secondaryDriver, 'hasSpouse', false),
                isSpouseAdded: get(secondaryDriver, 'isSpouseAdded', false),
                isSpouse: get(secondaryDriver, 'isSpouse', false),
                isPrimaryDriver: get(secondaryDriver, 'isPrimaryDriver', false),
                accidentList: get(secondaryDriver, 'accidentList', []),
                violationList: get(secondaryDriver, 'violationList', []),
                lossList: get(secondaryDriver, 'lossList', []),
                spouseId: get(secondaryDriver, 'spouseId', ''),
                education: get(secondaryDriver, 'education', ''),
                industry: get(secondaryDriver, 'industry', ''),
                relation_to_primary_driver: get(secondaryDriver, 'relation_to_primary_driver', ''),
                claims: get(secondaryDriver, 'claims', 'no'),
                violations: get(secondaryDriver, 'violations', 'no'),
                losses: get(secondaryDriver, 'losses', 'no'),
                isFetchedFromVerisk: true,
                isIncluded: false,
              };
              yield put(addDriver(secondaryDriverInfo));
            }

            yield put(setSelectFirstDriver(true));
          }

          yield put(setShowVeriskDriverModal(true));
        }
      }

      const {
        common: { isVeriskLoading },
      } = yield select();

      if (isVeriskLoading) {
        yield put(setIsVeriskFetched(true));
        yield put(setIsVeriskLoading(false));
      }
    } catch (error) {
      if (home.pconfig?.dale_config?.is_verisk_prefill_available && !driver.driversList.length) {
        yield put(setIsVeriskFetched(true));
        yield put(setIsVeriskLoading(false));
      }
      yield call(handleQuoteError, error);
      yield put(errorHandler(error));
    }
  }
}

export function* fetchVeriskDetailsWatcher() {
  yield takeLatest(quoteActions.REQUEST_VERISK_API, fetchVersikDetails);
}

function* refreshAutoQuotesWorker() {
  const {
    quote: {isQuoteRefresh}
  } = yield select();
  if (!isQuoteRefresh) {
    yield put(
      setQuoteCompleted({
        isQuoteRefresh: true,
      } as any)
    );
  }
  yield put(setLobRefreshLoader(true));
  yield put(initializePolling());
  yield call(pollingDelay, LOB_REFRESH_TIMEOUT);
  yield put(setQuoteCompleted({ isQuoteRefresh: false } as any));
  yield put(autoTimeoutPolling());
  yield put(setLobRefreshLoader(false));
}

function* refreshAutoQuotesWatcher() {
  yield takeEvery(quoteActions.REFRESH_AUTO_QUOTES, refreshAutoQuotesWorker);
}

export function* downloadAutoQuote({ payload }: any): any {
  yield put(setAutoDownloadQuoteLoader({
    carrier_id: payload?.carrierId || '',
    loader: true,
  }));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details: any = yield axiosRequest('POST', '/documents/quote-document', isProviderOneLogin, {
      ...payload,
    });
    if (!!details?.data?.success && details?.data?.response) {
      downloadFileUrl(details?.data?.response)
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data && [400, 404, 500].includes(axiosError?.response?.status)) {
      yield NotificationManager.error(PRODUCT_SELECTION_ERROR_MESSAGE);
      yield put(setAutoDownloadQuoteLoader({
        carrier_id: payload?.carrierId || '',
        loader: false,
      }));
      return
    }
    yield put(errorHandler(axiosError));
  }
  yield put(setAutoDownloadQuoteLoader({
    carrier_id: payload?.carrierId || '',
    loader: false,
  }));
}

function* downloadAutoQuoteWatcher() {
  yield takeEvery(quoteActions.DOWNLOAD_AUTO_QUOTE_DETAILS, downloadAutoQuote);
}

export default function* rootSaga() {
  yield all([
    fork(initQuotesWatcher),
    fork(pollQuotesWatcher),
    fork(autoPollingTimeoutWatcher),
    fork(initViewQuotesWatcher),
    fork(stopPollingWatcher),
    fork(getQuoteLobDetailsWatcher),
    fork(saveAutoQuoteWatcher),
    fork(fetchVeriskDetailsWatcher),
    fork(refreshAutoQuotesWatcher),
    fork(downloadAutoQuoteWatcher),
  ]);
}
