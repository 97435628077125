import { isEmpty } from 'lodash';
import config from '../../../config/config';
import store from '../../redux/store';
import { INSURANCE_PRODUCTS } from '../../../constants';

export const getCode = (clientId: string, lob?: string): string => `
  <!-- Paste the external script -->
  <div id="hcr-app" data-client-id="${clientId}" data-lob="${!!lob ? lob : ''}"></div>
  <script src="${config.hcrIframeUrl}" id='hippo-hcr-script' /></script>
  <script>
      window.hippoHCRiFrame({
          width: '100%',
          height: '600px'
      });
  </script>
`;

export const getIframeLobDropDown = (selectedLob?: string) => {
  const { organizations } = store.getState();
  let list: Array<string> = ['home', 'flood', 'auto'];
  let selectedLobInList: Array<any> = [];
  if (!isEmpty(organizations?.editOrganizationData?.hcrIframeClientList)) {
    organizations?.editOrganizationData?.hcrIframeClientList.map((item: any) =>
      selectedLobInList.push(item?.lob)
    );
  }
  let lobList: Array<any> = list.filter(x => !selectedLobInList.includes(x));
  if (!isEmpty(selectedLob)) {
    lobList.push(selectedLob);
  }
  return lobList;
};

export const hcrIframeThemeList: Array<any> = ['green', 'blue', 'darkgreen'];

export const getSelectLobListAsPerOrganization = () => {
  const {
    organizations: { editOrganizationData },
  } = store.getState();
  let lobList: Array<any> = !isEmpty(editOrganizationData?.lob)
    ? Object.keys(editOrganizationData.lob)
    : [];
  let homeProductList: Array<any> = !isEmpty(editOrganizationData?.lob?.HOME)
    ? Object.keys(editOrganizationData?.lob?.HOME)
    : [];
  lobList = [...lobList, ...homeProductList];
  let updatedList: Array<any> = [...lobList];
  if (!isEmpty(updatedList)) {
    const index = updatedList.findIndex(
      (element: any) => !isEmpty(element) && element?.toLowerCase() === INSURANCE_PRODUCTS[0]
    );
    if (index > -1) {
      updatedList.splice(index, 1);
    }
    updatedList.sort();
  }
  return updatedList;
};

export const getEoiSettingsLobDropDown = (eoiId?: string) => {
  const { organizations } = store.getState();
  let list: Array<string> = !isEmpty(getSelectLobListAsPerOrganization())
    ? getSelectLobListAsPerOrganization()
    : [];
  let selectedLobInList: Array<any> = [];
  if (!isEmpty(organizations?.eoiSettingsList)) {
    organizations?.eoiSettingsList.map((item: any) => selectedLobInList.push(item?.lob));
  }
  let lobList: Array<any> = list.filter(x => !selectedLobInList.includes(x));
  let selectedLob: string = '';
  if (!isEmpty(organizations?.eoiSettingsList)) {
    let seletctedEoiDetails = organizations.eoiSettingsList.find((item: any) => item?.id === eoiId);
    selectedLob = seletctedEoiDetails?.lob || '';
  }
  if (!isEmpty(selectedLob)) {
    lobList.push(selectedLob);
  }
  return lobList;
};