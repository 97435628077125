import React, { useEffect } from 'react';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import FloodQuestionnaire from '../flood-questionnaire';
import { FLOOD_PAGE_TITLE, FLOOD_REQUEST_SUCCESS_TITLE } from '../../../constants';

import './flood-details-modal.scss';

const detailsTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      width: '20px',
      height: '20px',
    },
    closeIcon: {
      fontSize: '16px',
    },
    detailsTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: 18,
      paddingTop: 10,
    },
  });

const DetailsDialogTitle = withStyles(detailsTitleStyles)((props: any) => {
  const { children, classes, loader, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
      {!loader && (
        <Typography className={classes.detailsTitle} variant='h6'>
          {children}
        </Typography>
      )}
    </MuiDialogTitle>
  );
});

const FloodDetailsModal = (props: AppComponents.FloodDetailsModalProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = (isDiscard: boolean) => {
    setOpen(false);
    props.closeHandler(isDiscard);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={!props.showSuccess ? 'md' : 'xs'}
        open={open}
        aria-labelledby='flood-details-dialog-title'
        classes={{
          root: 'flood-details-container',
        }}
        onClose={() => {
          handleClose(false);
        }}
        disableBackdropClick={!props.showSuccess}
        disableEscapeKeyDown={!props.showSuccess}
      >
        {!props.showSuccess ? (
          <>
            <DetailsDialogTitle
              loader={props.loader}
              onClose={() => {
                handleClose(false);
              }}
            >
              {FLOOD_PAGE_TITLE}
            </DetailsDialogTitle>
            <DialogContent>
              <FloodQuestionnaire />
            </DialogContent>
          </>
        ) : (
          <>
            <DetailsDialogTitle
              onClose={() => {
                handleClose(false);
              }}
            ></DetailsDialogTitle>
            <DialogContent>
              <div className='flood-success-wrapper text-center'>
                <CheckCircleOutlineIcon className='success-icon' />
                <Typography variant='h4' className='success-message'>
                  {FLOOD_REQUEST_SUCCESS_TITLE}
                </Typography>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

FloodDetailsModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  showSuccess: PropTypes.bool.isRequired,
  loader: PropTypes.bool,
};

export default FloodDetailsModal;
