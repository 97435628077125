import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { findIndex, isEmpty, map } from 'lodash';
import React, { MouseEvent } from 'react';
import config from '../../../config/config';
import {
  INSURANCE_PRODUCTS,
  MAXIMUM_ANNUAL_MILES,
  MINIMUM_ANNUAL_MILES,
  MISSING_INFORMATION,
  MISSING_VEHICLE_MESSAGE,
  PAGES_TEXT,
  UNSAVED_VEHICLE_MESSAGE,
  VEHICLE_DATA,
  VEHICLE_INCLUDE_LIMIT,
} from '../../../constants';
import ConfirmationModal from '../../components/confirmation-modal';
import NumberFormatCustom from '../../components/currency-mask';
import GenericModal from '../../components/generic-modal';
import SpinnerLoader from '../../components/spinner-loader';
import VehicleSelector from '../../components/vehicle-selector';
import VehicleSidePanel from '../../components/vehicle-side-panel';
import VersicModal from '../../components/Versik-modal';
import { allowEditingQuote } from '../../../utils';

const getVehicleFormNavigation = (component: any, disableControls: boolean) => {
  const { classes, stepSubmitLoader, vehicle, showGetQuoteButton, setEditVehicleFlag } =
    component.props;
  const isLastVehicle =
    vehicle.vehiclesList.length &&
    vehicle.vuid === vehicle.vehiclesList[vehicle.vehiclesList.length - 1].vuid;
  const disableAutoEditing = allowEditingQuote();
  disableControls = disableAutoEditing?.auto ? false : disableControls;
  return (
    <div className='col-md-12'>
      <div className='btnwrap'>
        <Button
          variant='outlined'
          className='btnBackLink'
          disabled={disableControls}
          onClick={() => {
            if (showBackNavigation(vehicle) > 0) {
              setEditVehicleFlag(true);
            }
            component.vehicleBackHandler();
          }}
        >
          <span className='link-label'>
            <ArrowBackIosIcon className='mr-2' />
            Back
          </span>
        </Button>
        <Button
          variant='contained'
          className='btnContinue'
          onClick={event => {
            component.submitStep(event, false);
          }}
          disabled={
            disableControls ||
            vehicle.vehicleSelectorDetails.vehicleSelectorLoader ||
            (!component.isVehiclesEnabled() && isLastVehicle)
          }
        >
          Continue{' '}
          {stepSubmitLoader ||
            (vehicle.vehicleSelectorDetails.vehicleSelectorLoader && (
              <SpinnerLoader styleData={classes.continueSpinner} />
            ))}
        </Button>
        {showGetQuoteButton && (
          <Button
            variant='contained'
            className='btnContinue'
            onClick={event => {
              component.submitStep(event, true);
            }}
            disabled={
              disableControls ||
              vehicle.vehicleSelectorDetails.vehicleSelectorLoader ||
              !component.isVehiclesEnabled()
            }
          >
            Get Quote{' '}
            {stepSubmitLoader ||
              (vehicle.vehicleSelectorDetails.vehicleSelectorLoader && (
                <SpinnerLoader styleData={classes.continueSpinner} />
              ))}
          </Button>
        )}
      </div>
    </div>
  );
};

const showBackNavigation = (vehicle: any) => {
  if (vehicle.vehiclesList.length === 0) {
    return false;
  }
  let currentVehicleIndex = 0;
  currentVehicleIndex = findIndex(vehicle.vehiclesList, {
    vuid: vehicle.vuid,
  });
  return currentVehicleIndex;
};

const getVINFormNavigation = (component: any, disableControls: boolean) => {
  const {
    classes,
    isVehicleEdit,
    vehicle,
    onPrevTab,
    currentIndex,
    isAddingProducts,
    policyBounded,
    partner,
    annexPrefill,
  } = component.props;
  const { showVehicleForm, continueButtonClick } = component.state;
  const appointmentKeys = Object.keys(partner?.appointments);
  const disableAutoEditing = allowEditingQuote();
  disableControls = disableAutoEditing?.auto ? false : disableControls;
  return (
    <>
      <div className='col-md-12 btnwrap'>
        {(!showVehicleForm && isAddingProducts && currentIndex === 1) ||
        (!isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[2]] && appointmentKeys.includes('FLOOD')) ||
        (appointmentKeys.includes('FLOOD') && (isEmpty(annexPrefill) || !annexPrefill?.isFloodInfo)) ? null : (
          <Button
            variant='outlined'
            className='btnBackLink'
            disabled={
              disableControls ||
              !!vehicle.vinLoader ||
              !!(
                vehicle.vehicleSelectorDetails.vehicleSelectorLoader &&
                vehicle.vehicleSelectorDetails.year &&
                vehicle.vehicleSelectorDetails.make &&
                vehicle.vehicleSelectorDetails.model
              )
            }
            onClick={(event: MouseEvent) => {
              const { vinBack, restoreVehicleDetails } = component.props;
              restoreVehicleDetails();
              isVehicleEdit && component.setState({ showVehicleForm: true });
              vinBack(isVehicleEdit);
              event.preventDefault();
              if (!showBackNavigation(vehicle)) {
                onPrevTab(currentIndex);
              }
            }}
          >
            <span className='link-label'>
              <ArrowBackIosIcon className='mr-2' />
              Back
            </span>
          </Button>
        )}
        <Button
          variant='contained'
          className='btnContinue'
          onClick={e => component.vinSelectorContinueHandler(e, false)}
          disabled={
            disableControls ||
            !!vehicle.vinLoader ||
            !!(
              vehicle.vehicleSelectorDetails.vehicleSelectorLoader &&
              vehicle.vehicleSelectorDetails.year &&
              vehicle.vehicleSelectorDetails.make &&
              vehicle.vehicleSelectorDetails.model
            )
          }
        >
          Continue
          {vehicle.vinLoader || continueButtonClick ? (
            <SpinnerLoader styleData={classes.continueSpinner} />
          ) : vehicle.vehicleSelectorDetails.vehicleSelectorLoader &&
            vehicle.vehicleSelectorDetails.year &&
            vehicle.vehicleSelectorDetails.make &&
            vehicle.vehicleSelectorDetails.model ? (
            <SpinnerLoader styleData={classes.continueSpinner} />
          ) : (
            ''
          )}
        </Button>
      </div>
    </>
  );
};

const isError = (errorMessage: string) => {
  return errorMessage !== '';
};

const showVehicleMissingModal = (showModal: boolean, component: any) => {
  return showModal ? (
    <GenericModal
      title='Information Missing'
      message={MISSING_VEHICLE_MESSAGE}
      open={showModal}
      closehandler={component.vehicleMissingModalCloseHandler}
    />
  ) : (
    ''
  );
};

const showVehicleDetailsMissingDialog = (showDialog: boolean, component: any) => {
  return showDialog ? (
    <ConfirmationModal
      open={showDialog}
      closehandler={component.vehicleDetailsModalCloseHandler}
      heading={'Unsaved Changes'}
      message={UNSAVED_VEHICLE_MESSAGE}
      contentValue=''
    />
  ) : (
    ''
  );
};

const getVehicleDetailsFields = (component: any, disableControls: boolean) => {
  const { vehicle, classes } = component.props;
  const {
    vin,
    body_type,
    own_lease,
    how_long,
    vehicleErrors,
    primary_use,
    approx_miles,
    defaultVin,
  } = component.state;
  const {
    body_type_error,
    own_lease_error,
    how_long_error,
    vin_error,
    primary_use_error,
    approx_miles_error,
  } = vehicleErrors;

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={`w-70 ${classes.formControl}`}
            disabled={
              disableControls ||
              (vehicle.vinInput && vehicle.body_type) ||
              vehicle.vehicleSelectorDetails.vehicleSelectorLoader ||
              !vehicle.isIncluded
            }
          >
            <InputLabel shrink htmlFor='body_type'>
              Body Type<span className='star-error'>*</span>
            </InputLabel>
            <Select
              native
              value={body_type}
              inputProps={{
                name: 'body_type',
                id: 'body_type',
              }}
              onChange={component.handleFieldChange}
              error={isError(body_type_error)}
            >
              <option value=''>Select</option>

              {!isEmpty(vehicle.bodyTypeList) &&
                vehicle.bodyTypeList.map((bodyType: { label: string; value: any }, index: any) => (
                  <option
                    value={bodyType.value}
                    key={`${index}${bodyType.label.split('|')[1]}` || bodyType.label.split('|')[0]}
                  >
                    {bodyType.label.split('|')[0]}
                  </option>
                ))}
            </Select>
            <FormHelperText className={classes.errorMsg}>{body_type_error}</FormHelperText>
          </FormControl>
        </div>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={classes.formControl}
            disabled={disableControls || !vehicle.isIncluded}
          >
            <InputLabel shrink htmlFor='own_lease'>
              Ownership Status<span className='star-error'>*</span>
            </InputLabel>
            <Select
              native
              value={own_lease}
              defaultValue={own_lease}
              inputProps={{
                name: 'own_lease',
                id: 'own_lease',
              }}
              onChange={component.handleFieldChange}
              error={isError(own_lease_error)}
            >
              <option value=''>Select</option>
              {map(VEHICLE_DATA.own_lease, data => (
                <option key={data.value} value={data.value}>
                  {data.label}
                </option>
              ))}
            </Select>
            <FormHelperText className={classes.errorMsg}>{own_lease_error}</FormHelperText>
          </FormControl>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={classes.formControl}
            disabled={disableControls || !vehicle.isIncluded}
          >
            <InputLabel shrink htmlFor='how_long' title='How long have you had this vehicle?*'>
              How long have you had this vehicle?<span className='star-error'>*</span>
            </InputLabel>
            <Select
              native
              value={how_long.toLocaleLowerCase()}
              inputProps={{
                name: 'how_long',
                id: 'how_long',
              }}
              onChange={component.handleFieldChange}
              error={isError(how_long_error)}
            >
              <option value=''>Select</option>
              {map(VEHICLE_DATA.how_long, data => (
                <option key={`how_long_${data.value}`} value={data.value}>
                  {data.label}
                </option>
              ))}
            </Select>
            <FormHelperText className={`error-ht ${classes.errorMsg}`}>{how_long_error}</FormHelperText>
          </FormControl>
        </div>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={classes.formControl}
            disabled={
              disableControls ||
              vehicle.vinInput ||
              !vehicle.isIncluded ||
              (!isEmpty(defaultVin) && isEmpty(vin))
            }
          >
            <InputLabel shrink htmlFor='vin'>
              VIN#
            </InputLabel>
            <Input
              value={!isEmpty(defaultVin) ? defaultVin : vin}
              inputProps={{
                id: 'vin',
                name: 'vin',
                maxLength: 17,
                className: 'vin-auto-capitalize fs-exclude',
                readOnly: true,
              }}
              onChange={component.handleFieldChange}
              error={isError(vin_error)}
            />
            <FormHelperText className={classes.errorMsg}>{vin_error}</FormHelperText>
          </FormControl>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={classes.formControl}
            disabled={disableControls || !vehicle.isIncluded}
          >
            <InputLabel shrink htmlFor='primary_use'>
              Vehicle Usage<span className='star-error'>*</span>
            </InputLabel>
            <Select
              native
              value={primary_use}
              defaultValue={primary_use}
              inputProps={{
                name: 'primary_use',
                id: 'primary_use',
              }}
              onChange={component.handleFieldChange}
              error={isError(primary_use_error)}
            >
              <option value=''>Select</option>
              {map(VEHICLE_DATA.vehicleUsage, data => (
                <option key={`primary_use_${data.value}`} value={data.value}>
                  {data.label}
                </option>
              ))}
            </Select>
            <FormHelperText className={classes.errorMsg}>{primary_use_error}</FormHelperText>
          </FormControl>
        </div>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={classes.formControl}
            disabled={disableControls || !vehicle.isIncluded}
          >
            <InputLabel shrink htmlFor='approx_miles'>
              Annual Miles<span className='star-error'>*</span>
            </InputLabel>
            <Input
              value={approx_miles}
              inputProps={{
                id: 'approx_miles',
                name: 'approx_miles',
                placeholder: `min: ${MINIMUM_ANNUAL_MILES} or max: ${MAXIMUM_ANNUAL_MILES}`,
              }}
              onChange={component.handleFieldChange}
              inputComponent={NumberFormatCustom as any}
              error={isError(approx_miles_error)}
            />
            <FormHelperText className={classes.errorMsg}>{approx_miles_error}</FormHelperText>
          </FormControl>
        </div>
      </div>
    </>
  );
};

const drawVehicleSidePanel = (component: any, disbaleAutoEdit: boolean) => {
  const { vehicle } = component.props;
  const { showVehicleForm } = component.state;
  return (
    <div className='col-lg-3 p-lg-0 right-side-list order-1'>
      <Card elevation={0} className='right-vehicle-info-box'>
        <CardContent className='card-padding'>
          <Typography className='right-vehicle-info-box-label'>Vehicle(s)</Typography>
          <VehicleSidePanel
            validateBeforeEditHandler={component.validateBeforeEditHandler}
            toggleVehicleHandler={component.updateVehicleErrors}
            openVehicleLimitModal={component.openVehicleLimitModal}
          />
          {/* add vehicle buttton */}
          {!disbaleAutoEdit && showVehicleForm && vehicle.vehiclesList.length < config.maxVehicleAddLimit ? (
            <Grid
              container
              spacing={2}
              className='m-0 w-100 list-container list-wrap align-items-center'
            >
              <Grid item xs={12}>
                <Link className='add-link' onClick={component.addAnotherVehicleHandler}>
                  <AddIcon className='add-icon-border mr-2' />
                  <span className='link-label'>Add Vehicle</span>
                </Link>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </CardContent>
      </Card>
      {!component.isVehiclesEnabled() && (
        <Typography
          align='center'
          display='block'
          variant='caption'
          color='error'
          style={{ fontSize: '0.7rem' }}
        >
          At least 1 vehicle must be included for quoting
        </Typography>
      )}
    </div>
  );
};

const showConfirmationModal = (component: any) => {
  return (
    <ConfirmationModal
      open={component.state.saveDialog}
      closehandler={component.modalClose}
      message='Would you like to save the changes?'
      contentValue=''
      heading='Save Changes?'
    />
  );
};

const showModal = (component: any) => {
  const {
    setShowVeriskVehicleModal,
    showVeriskVehicleModal,
    showVeriskDriverModal,
    vehicle,
    driversList,
    setShowVeriskDriverModal,
  } = component.props;

  const { showVehicleForm } = component.state;

  const shouldOpen = showVeriskVehicleModal || showVeriskDriverModal;

  return showVehicleForm && (showVeriskVehicleModal || showVeriskDriverModal) ? (
    <VersicModal
      open={shouldOpen}
      closehandler={() => {
        setShowVeriskVehicleModal(false);
        setShowVeriskDriverModal(false);
      }}
      vehiclesQty={vehicle.vehiclesList.length}
      driversQty={driversList.length}
      vehicle={showVeriskVehicleModal}
      driver={showVeriskDriverModal}
    />
  ) : (
    ''
  );
};

const showMissingInformationModal = (display: boolean, component: any) =>
  display ? (
    <GenericModal
      title='Missing Information'
      message={`<p>${MISSING_INFORMATION}</p>`}
      open={display}
      closehandler={component.closeMissingInformationModal}
    />
  ) : (
    ''
  );

const showVehicleLimitModal = (display: boolean, component: any) =>
  display ? (
    <GenericModal
      title='Vehicle Limit Reached'
      message={`<p>${VEHICLE_INCLUDE_LIMIT}</p>`}
      open={display}
      closehandler={component.closeVehicleLimitModal}
    />
  ) : (
    ''
  );

const template = (component: any) => {
  const { vehicle, stepSubmitLoader, deleteDetailsLoader, vehiclesMissing, classes } =
    component.props;
  const {
    vehicleDetailsMissingDialog,
    showVehicleForm,
    vehicleErrors,
    comprehensive,
    collision,
    towing_labor,
    ext_trans_expense,
    comprehensive_data,
    collision_data,
    towing_labor_data,
    ext_trans_expense_data,
    saveDialog,
    missingInformation,
    vehicleLimitReached,
  } = component.state;
  const { modal_error } = vehicleErrors;
  const disableAutoEditing = allowEditingQuote();
  const disableControls = vehicle.vehicleLoader || deleteDetailsLoader || stepSubmitLoader || disableAutoEditing?.auto;
  return (
    <div className='container'>
      <div className='autoline-steps autoline-step-1-wrapper row d-flex justify-content-center position-relative'>
        <div className='col-lg-9 order-2'>
          <form
            className='vehicle-box-shodow position-relative'
            onSubmit={event => {
              event.preventDefault();
            }}
          >
            <Paper elevation={0}>
              <div className='row d-flex justify-content-center m-0'>
                <div className='col-md-10 applicant-home-title'>
                  <span className='icon-Car title-icon'></span>
                  <Typography variant='h6' className='title-2'>
                    {PAGES_TEXT.vehiclePageHeading}
                  </Typography>
                </div>
              </div>

              {!showVehicleForm && vehicle.vehiclesList.length <= config.maxVehicleAddLimit ? (
                <div className='vehicleDiv mt-4'>
                  <VehicleSelector />
                  <div className='row mb-2 m-0 d-flex justify-content-center'>
                    <div className='col-md-10 offset-md-1'>
                      <FormHelperText className={classes.modalErrorMsg}>
                        {modal_error}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='row m-0 vehicleFormTwo d-flex justify-content-center align-center'>
                  <div className='col-lg-10'>
                    {getVehicleDetailsFields(component, disableControls)}
                    <div className='row sub-title-blue mt-4'>
                      <div className='col-md-12'>
                        <Typography className='VoiletHeading'>Deductibles</Typography>
                      </div>
                      <div className='col-md-6'>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          disabled={disableControls || !vehicle.isIncluded}
                        >
                          <InputLabel shrink htmlFor='comprehensive'>
                            Comprehensive
                          </InputLabel>
                          <Select
                            native
                            value={comprehensive}
                            inputProps={{
                              id: 'comprehensive',
                              name: 'comprehensive',
                            }}
                            onChange={component.handleFieldChange}
                          >
                            {map(comprehensive_data, type => (
                              <option value={type.value} key={`comprehensive_${type.value}`}>
                                {type.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-6'>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          disabled={disableControls || !vehicle.isIncluded}
                        >
                          <InputLabel shrink htmlFor='collision'>
                            Collision
                          </InputLabel>
                          <Select
                            native
                            value={collision}
                            inputProps={{
                              id: 'collision',
                              name: 'collision',
                            }}
                            onChange={component.handleFieldChange}
                          >
                            {map(collision_data, type => (
                              <option value={type.value} key={`collision_${type.value}`}>
                                {type.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          disabled={disableControls || !vehicle.isIncluded}
                        >
                          <InputLabel shrink htmlFor='towing_labor'>
                            Towing &amp; Labor
                          </InputLabel>
                          <Select
                            native
                            value={towing_labor}
                            inputProps={{
                              id: 'towing_labor',
                              name: 'towing_labor',
                            }}
                            onChange={component.handleFieldChange}
                          >
                            {map(towing_labor_data, type => (
                              <option value={type.value} key={`towing_labor_${type.value}`}>
                                {type.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-md-6'>
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          disabled={disableControls || !vehicle.isIncluded}
                        >
                          <InputLabel shrink htmlFor='ext_trans_expense'>
                            Extra Transportation Expense
                          </InputLabel>
                          <Select
                            native
                            value={ext_trans_expense}
                            inputProps={{
                              id: 'ext_trans_expense',
                              name: 'ext_trans_expense',
                            }}
                            onChange={component.handleFieldChange}
                          >
                            {map(ext_trans_expense_data, type => (
                              <option value={type.value} key={`ext_trans_expense_${type.value}`}>
                                {type.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Paper>
            <div className='row margin-top m-0'>
              {showVehicleForm
                ? getVehicleFormNavigation(component, disableControls)
                : getVINFormNavigation(component, disableControls)}
            </div>
          </form>
        </div>

        {showVehicleForm && drawVehicleSidePanel(component, disableAutoEditing?.auto)}
        {showVehicleMissingModal(vehiclesMissing, component)}
        {showModal(component)}

        {showVehicleDetailsMissingDialog(vehicleDetailsMissingDialog, component)}
        {saveDialog && showConfirmationModal(component)}
        {showMissingInformationModal(missingInformation, component)}
        {showVehicleLimitModal(vehicleLimitReached, component)}
      </div>
    </div>
  );
};

export default template;
