/* istanbul ignore file */
import * as PetActionType from '../constants';

export const setPetBreedsDetails = (payload: any): AppRedux.Action => ({
  type: PetActionType.SET_PET_BREEDS_DETAILS,
  payload,
});

export const fetchPetBreedsDetails = (): AppRedux.Action => ({
  type: PetActionType.FETCH_PET_BREEDS_DETAILS,
});

export const petBreedsDetailsLoader = (payload: any): AppRedux.Action => ({
  type: PetActionType.PET_BREEDS_DETAILS_LOADER,
  payload,
});

export const fetchPetQuotes = (payload: any): AppRedux.Action => ({
  type: PetActionType.FETCH_PET_QUOTES,
  payload,
});

export const setPetQuotes = (payload: any): AppRedux.Action => ({
  type: PetActionType.SET_PET_QUOTES,
  payload,
});

export const petQuotesLoader = (payload: any): AppRedux.Action => ({
  type: PetActionType.PET_QUOTES_LOADER,
  payload,
});

export const clearPetState = (): AppRedux.Action => ({
  type: PetActionType.CLEAR_PET_STATE,
});

export const updateBreedsDetails = (payload: any): AppRedux.Action => ({
  type: PetActionType.UPDATE_BREEDS_DETAILS,
  payload,
});

export const storePetListData = (payload: any): AppRedux.Action => ({
  type: PetActionType.STORE_PET_LIST_DATA,
  payload,
});

export const bridgeToCarrierLoader = (payload: any): AppRedux.Action => ({
  type: PetActionType.BRIDGE_TO_CARRIER_LOADER,
  payload,
});

export const getBridgeToCarrierDetailsForPet = (payload: any): AppRedux.Action => ({
  type: PetActionType.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_PET,
  payload,
});

export const storeErrorsForBridgeToCarrier = (payload: any): AppRedux.Action => ({
  type: PetActionType.STORE_ERRORS_FOR_BRIDGE_TO_CARRIER,
  payload,
});

export const setPetPolicyBounded = (payload: any): AppRedux.Action => ({
  type: PetActionType.SET_POLICY_BOUNDED_FOR_PET,
  payload,
});

export const storePetDetails = (payload: any): AppRedux.Action => ({
  type: PetActionType.STORE_PET_DETAILS,
  payload,
});
