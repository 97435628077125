import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import { storeErrorsForBridgeToCarrier } from '../../redux/actions';
import { CONTINUE, MD } from '../../../constants';
import './bridge-to-carrier-modal.scss';

class BridgeToCarrierModal extends PureComponent<AppComponents.BridgeToCarrierModalProps> {
  componentDidMount = () => {
    this.onCloseBridgeToCarrierModal()
  }

  onCloseBridgeToCarrierModal = () => {
    this.props.storeErrorsForBridgeToCarrier(null);
  };

  render() {
    const { bridgeToCarrierErrorDetails } = !!this?.props?.petDetails && this?.props?.petDetails;
    let isShowErrorBridgeToCarrierModal = !!bridgeToCarrierErrorDetails;
    return (
      <Dialog
        className='bridge-modal'
        maxWidth={MD}
        onClose={this.onCloseBridgeToCarrierModal}
        open={!!isShowErrorBridgeToCarrierModal ? true : false}
      >
        <DialogContent>
          <div className='text-center mt-1 api-error-p'>
            <Typography>
              <span>
                {!!bridgeToCarrierErrorDetails?.errorText
                  ? bridgeToCarrierErrorDetails?.errorText
                  : ''}
              </span>
            </Typography>
          </div>

          <div className='mt-2 mb-4 row justify-content-center'>
            <Button
              className='continue-btn'
              onClick={this.onCloseBridgeToCarrierModal}
              variant='contained'
            >
              {CONTINUE}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ petDetails }: AppComponents.BridgeToCarrierModalStore) => {
  return {
    petDetails,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.BridgeToCarrierModalDispatch => {
  return bindActionCreators({ storeErrorsForBridgeToCarrier }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BridgeToCarrierModal);
