import { Container } from '@material-ui/core';
import { isEmpty, isEqual, startCase } from 'lodash';
import React, { FormEvent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from '../../../components/modal';
import AddEditEoiSettingsForm from './add-edit-eoi-settings-form';
import { addEditEoiSetting, setOrgUpdatedResponseData } from '../../../redux/actions';
import { validateEmail } from '../../../../utils';
import {
  EOI_SETTINGS,
  MAX_LENGTH_REACHED,
  REQUIRED,
  SELECT,
  SM,
} from '../../../../constants';

const INIT_FORM_ERROR = {
  lobError: '',
  recipientsError: '',
  subjectLineError: '',
};

export class AddEditEoiSettings extends React.Component<
  AppComponents.AddEditEoiSettingsProps,
  AppComponents.AddEditEoiSettingsState
> {
  state: AppComponents.AddEditEoiSettingsState = {
    form: {
      id: '',
      organizationId: '',
      lob: startCase(SELECT),
      recipients: '',
      subjectLine: '',
    },
    formError: INIT_FORM_ERROR,
  };

  componentDidMount = () => {
    const props: any = this.props;
    const { eoiSettingsRowData, openEditEoiSettingModel, organizationData } = props;
    if (!!openEditEoiSettingModel || !isEmpty(organizationData?.id)) {
      this.setState({
        ...this.state,
        form: {
          id: eoiSettingsRowData?.id || '',
          organizationId: eoiSettingsRowData?.organizationId || organizationData.id || '',
          lob: eoiSettingsRowData?.lob || startCase(SELECT),
          recipients: eoiSettingsRowData?.recipients || '',
          subjectLine: eoiSettingsRowData?.subjectLine || '',
        },
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps: AppComponents.AddEditEoiSettingsProps): void => {
    const { organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.props.onCloseModal();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
  };

  mapOriginList = (list: any) => {
    let originList: any = [];
    list.forEach((element: any) => {
      originList.push({
        value: element,
        error: '',
      });
    });
    return originList;
  };

  onFormChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  userEmailInput = (val: any) => {
    this.setState({
      form: {
        ...this.state.form,
        recipients: val.length > 0 ? val.join(',') : '',
      },
    });
  };
  checkEmailId = (val: any) => {
    let emailError: string = validateEmail(val, false);
    if (!isEmpty(val) && !isEmpty(emailError)) {
      this.setState({
        formError: {
          ...this.state.formError,
          recipientsError: emailError,
        },
      });
      return false;
    }
    if (!isEmpty(val) && val.length >= 50) {
      this.setState({
        formError: {
          ...this.state.formError,
          recipientsError: MAX_LENGTH_REACHED,
        },
      });
      return false;
    }
    this.setState({
      formError: {
        ...this.state.formError,
        recipientsError: '',
      },
    });
    return true;
  };

  userEmailInputBlur = () => {
    this.setState({
      formError: {
        ...this.state.formError,
        recipientsError: '',
      },
    });
  };

  validate = () => {
    const { form } = this.state;
    let error: any = {
      ...INIT_FORM_ERROR,
      recipientsError: this.state.formError.recipientsError,
    };
    error.lobError =
      isEmpty(form?.lob) || (!isEmpty(form?.lob) && form?.lob?.toLowerCase() === SELECT)
        ? REQUIRED
        : '';
    if (isEmpty(error?.recipientsError)) {
      if (isEmpty(form?.recipients)) {
        error.recipientsError = REQUIRED;
      }
    } else {
      error.recipientsError = !isEmpty(error?.recipientsError) ? error?.recipientsError : '';
    }

    error.subjectLineError = isEmpty(form?.subjectLine) ? REQUIRED : '';
    this.setState({
      ...this.state,
      formError: {
        ...error,
      },
    });
    if (!isEqual(error, INIT_FORM_ERROR)) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    let isValid = this.validate();
    if (!isValid) {
      return;
    }
    const { id, organizationId, lob, recipients, subjectLine } = this.state.form;
    let payload: any = {
      id: id || '',
      organizationId: organizationId || '',
      lob: !isEmpty(lob) && lob?.toLowerCase() !== SELECT ? lob : '',
      recipients: !isEmpty(recipients) ? recipients?.trim() : '',
      subjectLine: !isEmpty(subjectLine) ? subjectLine?.trim() : '',
    };
    this.props.addEditEoiSetting(payload);
  };

  render() {
    const { organizations } = this.props;
    return (
      <Modal
        isOpen={true}
        onClose={this.props.onCloseModal}
        title={EOI_SETTINGS.TAB_LABEL}
        navButtons={true}
        maxWidth={SM}
        onSave={this.onSubmit}
        loader={!!organizations?.addEditOrgLoader || organizations?.addEditEoiSettingloader}
      >
        <Container className='pl-0'>
          <AddEditEoiSettingsForm
            onChange={this.onFormChange}
            userEmailInput={this.userEmailInput}
            userEmailInputBlur={this.userEmailInputBlur}
            checkEmailId={this.checkEmailId}
            formValues={this.state.form || {}}
            isLoading={!!organizations?.addEditOrgLoader}
            openEditEoiSettingModel={this.props.openEditEoiSettingModel}
            formError={this.state.formError}
          />
        </Container>
      </Modal>
    );
  }
}

const mapStateToProps = (props: AppComponents.AddEditEoiSettingsStore) => {
  const { organizations } = props;
  const { editOrganizationData } = organizations;
  return { organizationData: editOrganizationData, organizations };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AddEditEoiSettingsDispatch =>
  bindActionCreators({ addEditEoiSetting, setOrgUpdatedResponseData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEoiSettings);
