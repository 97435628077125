export const UMBRELLA_QUESTION_GROUPS = {
  ADDRESS: {
    KEY: 'ADDRESS',
    LABEL: 'Risk Address',
  },
  RVADDRESS: {
    KEY: 'RVADDRESS',
    LABEL: 'RV Address',
  },
  AGENTQUESTIONS: {
    KEY: 'AGENTQUESTIONS',
    LABEL: 'Agent Questions',
  },
  LIMITLABELS: {
    KEY: 'LIMITLABELS',
    LABEL: 'Limit Questions',
  },
  LIMTQUESTIONS: {
    KEY: 'LIMTQUESTIONS',
    LABEL: 'Limit Questions',
  },
  MAILINGADDRESS: {
    KEY: 'MAILINGADDRESS',
    LABEL: 'Mailing Address',
  },
  RATINGQUESTIONS: {
    KEY: 'RATINGQUESTIONS',
    LABEL: 'Rating Questions',
  },
  UWQUESTIONS: {
    KEY: 'UWQUESTIONS',
    LABEL: 'Underwriting Questions',
  },
  MEMBERSOFHOUSEHOLD: {
    KEY: 'MEMBERSOFHOUSEHOLD',
    LABEL: 'Member of Household',
  },
};
export const UMBRELLA_QUESTION_SUB_GROUPS = {
  REQRATING: {
    KEY: 'REQRATING',
    LABEL: 'Rating Requirements',
  },
  LIMITSOFLIABILITY: {
    KEY: 'LIMITSOFLIABILITY',
    LABEL: 'Liability Limits',
  },
  AUTOLIABILITY: {
    KEY: 'AUTOLIABILITY',
    LABEL: 'Auto Liability Limits',
  },
  ADDRESS: {
    KEY: 'ADDRESS',
    LABEL: 'Physical Address',
  },
  RVADDRESS: {
    KEY: 'RVADDRESS',
    LABEL: 'RV Address',
  },
  RATERESPONSE: {
    KEY: 'RATERESPONSE',
    LABEL: 'Rate Response',
  },
  ELIGIBILITY: {
    KEY: 'ELIGIBILITY',
    LABEL: 'Eligibility',
  },
  LIMIT: {
    KEY: 'LIMIT',
    LABEL: 'Limit',
  },
  CONFIRMATION: {
    KEY: 'CONFIRMATION',
    LABEL: 'Confirmation',
  },
};
export const UMBRELLA_QUESTION_FIELD_TYPES = {
  INTEGER: 'Integer',
  DROPDOWN: 'Dropdown',
  TEXT_SINGLE: 'Text:Single',
  DATE_PICKER: 'DatePicker',
};
export const OFF = {
  KEY: 'off',
  LABEL: 'Off',
};
export const UM_UIM = 'umUim';
export const NO_UM_UIM = 'noUmUim';
export const UM_UIM_COVERAGE = 'UM/UIM Coverage';
export const ADD_MORE = 'Add Member';
export const MAX_REPEATABLE_MEMBERS = 20;
export const SUB_GROUP = 'sub-group';
export const GROUP = 'group';
export const PRIMARY_MEMBER = 'Primary Member';
export const ADDITIONAL_MEMBER = 'Additional Member';
export const UMBRELLA_LOB_CODE = 'pumbr';
