import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import './flood-plan-details.scss';
import { formatNumberToCurrency } from '../../../utils';

const FloodPlanModal = (props: AppComponents.FloodPlanModalProps) => {
  const { elite, enhanced, essential } = props?.floodPlansList;
  return (
    <>
      <div>
        <Dialog
          aria-labelledby='org-dialog-title'
          className='flood-plan-modal'
          fullWidth
          maxWidth='lg'
          onClose={props.closeModal}
          open={true}
        >
          <HighlightOffIcon className="close-icon" onClick={props.closeModal}/>
          <DialogTitle className='d-flex justify-content-center pb-0'>
            <Typography className='heading'>Plan Details</Typography>
          </DialogTitle>

          <DialogContent className='flood-plan-content'>
            <TableContainer className='flood-plan-tbl-container'>
              <Table aria-label='simple table' className='flood-plan-table'>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Essential</TableCell>
                    <TableCell>Enhanced</TableCell>
                    <TableCell>Elite</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Coverage A</TableCell>
                    <TableCell>{formatNumberToCurrency(essential?.covA)}</TableCell>
                    <TableCell>{formatNumberToCurrency(enhanced?.covA)}</TableCell>
                    <TableCell>{formatNumberToCurrency(elite?.covA)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Coverage B</TableCell>
                    <TableCell>{formatNumberToCurrency(essential?.covB)}</TableCell>
                    <TableCell>{formatNumberToCurrency(enhanced?.covB)}</TableCell>
                    <TableCell>{formatNumberToCurrency(elite?.covB)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Coverage C</TableCell>
                    <TableCell>
                      {formatNumberToCurrency(essential?.covC?.content)}
                    </TableCell>
                    <TableCell>
                      {formatNumberToCurrency(enhanced?.covC?.content)}
                    </TableCell>
                    <TableCell>{formatNumberToCurrency(elite?.covC?.content)}</TableCell>
                  </TableRow>
                  <TableRow className='multiline-row'>
                    <TableCell>
                      Coverage D<Typography>Debris Removal</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.debris)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.debris)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.debris)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Loss Avoid Measures</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.lossAvoid)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.lossAvoid)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.lossAvoid)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Property Removed to Safety</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.propertyRemoved)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.propertyRemoved)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.propertyRemoved)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Loss Assessment</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.lossAssessor)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.lossAssessor)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.lossAssessor)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Fire Department Service Charge</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.fireDepartmentService)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.fireDepartmentService)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.fireDepartmentService)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Deck and Ramp Coverage</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.deckRamp)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.deckRamp)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.deckRamp)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Law and Ordinance Endorsement</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.laword)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.laword)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.laword)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Trees, Shrubs, Other Plants</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covD?.trees)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covD?.trees)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covD?.trees)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Increased Cost of Compliance</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covE?.icc)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covE?.icc)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{formatNumberToCurrency(elite?.covE?.icc)}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography>Loss of Use Coverage</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(essential?.covF?.lossOfUse)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(enhanced?.covF?.lossOfUse)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {formatNumberToCurrency(elite?.covF?.lossOfUse)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default FloodPlanModal;
