import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grow from '@material-ui/core/Grow';
import get from 'lodash/get';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';

import { OTHER_PRODUCTS } from '../../../constants';
import SpinnerLoader from '../../components/spinner-loader';

import './other-quotes-button.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuPaper: {
      zIndex: 2,
      minWidth: 162,
    },
    spinner: {
      color: 'black !important',
      marginBottom: '2px',
      marginRight: '10px',
    },
  })
);

const OtherQuotesButton = (props: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(openFlag => !openFlag);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>, product?: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    if (product) {
      props.onSelect(product);
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const updateProductList = () => {
    const availableProducts = OTHER_PRODUCTS.filter(
      product =>
        !props.common.redirectedFrom.includes(product.value) &&
        props.common.lineOfBusiness !== product.value
    );
    return availableProducts;
  };

  const productList = updateProductList();

  return (
    <div className='other-quotes-container'>
      {productList.length > 0 && (
        <>
          <Button
            className='other-quotes-button'
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleToggle}
            disabled={get(props, 'isDisabled', false)}
            disableRipple
          >
            {get(props, 'showLoader', false) ? (
              <SpinnerLoader styleData={classes.spinner} />
            ) : (
              <AddCircleOutlineIcon className='plus-icon' />
            )}
            Other quotes <ExpandMoreIcon />
          </Button>
          <Popper
            placement='bottom-start'
            className={classes.menuPaper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                    >
                      {productList.map(product => (
                        <MenuItem
                          key={product.value}
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            handleClose(event, product);
                          }}
                          className='other-quote-item'
                        >
                          {product.label}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </div>
  );
};

OtherQuotesButton.propTypes = {
  onSelect: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

const mapStateToProps = ({ common }: any) => {
  return { common };
};

export default connect(mapStateToProps, null)(OtherQuotesButton);
