export const EMPTY = '';
export const INPUT_TYPES = {
  CHECKBOX: 'checkbox',
};
export const ERROR_MESSAGE = {
  INVALID: 'Invalid',
  REQUIRED: 'Required',
  PERCENTAGE: 'Should be between 1 to 100%'
};
export const resetObjectProperties = <T extends Record<string, string>>(object: T) => {
  const tempObject: Record<string, string> = { ...object };
  Object.keys(object).forEach(key => {
    tempObject[key] = EMPTY;
  });
  return tempObject as T;
};
