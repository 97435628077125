/* istanbul ignore file */
import * as CyberSecurityActionType from '../constants';

export const setCyberSecurityQuoteDetails = (payload: any): AppRedux.Action => ({
  type: CyberSecurityActionType.SET_CYBER_SECURITY_QUOTES_DETAILS,
  payload,
});

export const fetchCyberSecurityQuotes = (): AppRedux.Action => ({
  type: CyberSecurityActionType.FETCH_CYBER_SECURITY_QUOTES,
});

export const cyberSecurityQuotesLoader = (payload: any): AppRedux.Action => ({
  type: CyberSecurityActionType.CYBER_SECURITY_DETAILS_LOADER,
  payload,
});

export const storeCyberSecurityQuoteDetails = (payload: any): AppRedux.Action => ({
  type: CyberSecurityActionType.STORE_CYBER_SECURITY_QUOTE_LIST,
  payload,
});

export const clearCyberSecurityState = (): AppRedux.Action => ({
  type: CyberSecurityActionType.CLEAR_CYBER_SECURITY_STATE,
});

export const updateCyberSecurityQuoteDetails = (
  payload: AppRedux.ActionPayload
): AppRedux.Action => ({
  type: CyberSecurityActionType.UPDATE_CYBER_SECURITY_QUOTE_DETAILS,
  payload,
});

export const setCyberSecurityPollingErrorDetails = (
  payload: AppRedux.ActionPayload
): AppRedux.Action => ({
  type: CyberSecurityActionType.SET_CYBER_SECURITY_POLLING_ERROR_DETAILS,
  payload,
});

export const initializeCyberSecurityPolling = (): AppRedux.Action => ({
  type: CyberSecurityActionType.INIT_CYBER_SECURITY_POLLING,
});

export const cyberSecurityTimeoutPolling = (): AppRedux.Action => ({
  type: CyberSecurityActionType.CYBER_SECURITY_TIMEOUT_POLLING,
});

export const setCyberSecurityPollingDetails = (
  payload: AppRedux.ActionPayload
): AppRedux.Action => ({
  type: CyberSecurityActionType.SET_CYBER_SECURITY_POLLING_DETAILS,
  payload,
});

export const stopCyberSecurityPolling = (): AppRedux.Action => ({
  type: CyberSecurityActionType.STOP_CYBER_SECURITY_POLLING,
});

export const getBridgeToCarrierDetailsForCyber = (payload: any): AppRedux.Action => ({
  type: CyberSecurityActionType.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_CYBER_SECRUITY,
  payload,
});

export const cyberBridgeToCarrierLoader = (payload: any): AppRedux.Action => ({
  type: CyberSecurityActionType.CYBER_BRIDGE_TO_CARRIER_LOADER,
  payload,
});

export const refreshCyberSecurityQuotes = (): AppRedux.Action => ({
  type: CyberSecurityActionType.REFRESH_CYBERSECURITY_QUOTES,
});
