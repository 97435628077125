import React, { ChangeEvent, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import Modal from '../../components/modal';
import CarrierAppointmentForm from './carrier-appointment-form';
import { setOrgUpdatedResponseData, updateOrganizationData } from '../../redux/actions';
import { getCarrierDataByCarrierId } from '../../../utils';
import {
  ANNEX,
  CARRIERS_TO_EXCLUDE_PASSWORD_VALIDATION,
  EZLYNX,
  INVALID,
  LOB_KEY,
  POD_ORGANIZATION_CODE,
  REQUIRED,
  SHOW_PRODUCT_FOR_LOBS,
  SM,
} from '../../../constants';

const INIT_FORM_DETAILS = {
  id: '',
  name: '',
  code: '',
  auth_token: '',
  carrier_credentials_grid: {
    id: '',
    organization_id: '',
    name: '',
    code: '',
    username: '',
    password: '',
    producer_code: '',
    market: '',
    consumer_market: '',
    lob: '',
    products: [],
    integration_type: '',
    is_enable_for_lead_flow: false,
    is_active: false,
    is_available_for_quoting: false,
  },
};
const INIT_FORM_DETAILS_ERRORS = {
  nameError: '',
  codeError: '',
  usernameError: '',
  passwordError: '',
  apiKeyError: '',
  producerCodeError: '',
  marketError: '',
  productError: '',
  commonErrorMessage: '',
};

export class AddEditCarrierAppointment extends Component<
  AppComponents.AddEditCarrierAppointmentsProps,
  AppComponents.AddEditCarrierAppointmentState
> {
  state: AppComponents.AddEditCarrierAppointmentState = {
    formDetails: { ...INIT_FORM_DETAILS },
    errors: {
      ...INIT_FORM_DETAILS_ERRORS,
    },
  };

  componentDidMount = () => {
    const { carrierAppointmentRowData, editOrganizationData } = this.props;
    if (!!carrierAppointmentRowData) {
      this.setCarrierCredentialsGrid(carrierAppointmentRowData);
    }
    this.setState((prevState: AppComponents.AddEditCarrierAppointmentState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        id: editOrganizationData?.id,
        name: editOrganizationData?.name,
        code: editOrganizationData?.code,
        auth_token: editOrganizationData?.auth_token,
      },
    }));
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { organizations, onCloseAddEditCarrierAppointment } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      onCloseAddEditCarrierAppointment();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
    if (!!organizations?.orgResponseData?.message) {
      this.setState({
        errors: {
          ...this.state.errors,
          commonErrorMessage: organizations?.orgResponseData?.message,
        },
      });
    }
  };

  isProductRequired = (lob: string) => !lob || SHOW_PRODUCT_FOR_LOBS.includes(lob?.toLowerCase());

  setCarrierCredentialsGrid = (data: any) => {
    if (!!data) {
      this.setState((prevState: AppComponents.AddEditCarrierAppointmentState) => ({
        ...prevState,
        formDetails: {
          ...prevState.formDetails,
          carrier_credentials_grid: {
            ...data,
          },
        },
      }));
    }
  };

  onCarrierApoointmentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { editOrganizationData } = this.props;
    const { errors } = this.state;
    const { name, value } = event.target;
    let updatedValue: Record<string, any> = {
      [name]: value,
    };
    if (name === LOB_KEY?.toLowerCase() && !this.isProductRequired(value)) {
      errors.productError = '';
      updatedValue.products = [];
    }
    if (name === POD_ORGANIZATION_CODE?.KEY) {
      let selectedCarrier =
        !isEmpty(editOrganizationData?.carrierList) && value
          ? getCarrierDataByCarrierId(value, editOrganizationData?.carrierList)
          : '';
      updatedValue.name = selectedCarrier?.name || '';
    }

    this.setState((prevState: AppComponents.AddEditCarrierAppointmentState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        carrier_credentials_grid: {
          ...prevState.formDetails.carrier_credentials_grid,
          ...updatedValue,
        },
      },
      errors,
    }));
  };

  isValidatePassword = () => {
    const {
      formDetails: {
        carrier_credentials_grid: { name, integration_type, is_available_for_quoting },
      },
    } = this.state;
    return (
      (integration_type !== ANNEX &&
        !CARRIERS_TO_EXCLUDE_PASSWORD_VALIDATION.includes(name) &&
        is_available_for_quoting) ||
      false
    );
  };

  validateForm = () => {
    const {
      formDetails: {
        carrier_credentials_grid: { products, lob, name, password },
      },
    } = this.state;
    let errors = {
      ...INIT_FORM_DETAILS_ERRORS,
    };
    errors.productError = isEmpty(products) && this.isProductRequired(lob) ? REQUIRED : '';
    errors.nameError = isEmpty(name) ? REQUIRED : '';
    if (this.isValidatePassword() && isEmpty(password?.trim())) {
      errors.passwordError = REQUIRED;
    } else {
      errors.passwordError = password?.length > 0 && isEmpty(password?.trim()) ? INVALID : '';
    }

    this.setState({
      ...this.state,
      errors: {
        ...errors,
        commonErrorMessage: '',
      },
    });
    if (
      !!errors?.nameError ||
      !!errors?.marketError ||
      !!errors?.usernameError ||
      !!errors?.passwordError ||
      !!errors?.apiKeyError ||
      !!errors?.producerCodeError ||
      !!errors?.productError
    ) {
      return false;
    } else {
      return true;
    }
  };

  onSaveCarrierAppointMents = () => {
    const { carrierAppointmentRowData } = this.props;
    const isValid = this.validateForm();
    this.props.setOrgUpdatedResponseData({ message: '' });
    if (!!isValid) {
      const { formDetails } = this.state;
      let payload = {
        id: formDetails.id,
        name: formDetails.name,
        code: formDetails.code,
        auth_token: formDetails.auth_token,
        carrier_credentials_grid: [
          {
            id: formDetails.carrier_credentials_grid.id,
            organization_id: formDetails.id,
            name: formDetails.carrier_credentials_grid.name,
            code: !isEmpty(formDetails?.carrier_credentials_grid?.code?.trim())
              ? formDetails.carrier_credentials_grid.code
              : null,
            username: !isEmpty(formDetails?.carrier_credentials_grid?.username?.trim())
              ? formDetails.carrier_credentials_grid.username
              : null,
            password: formDetails.carrier_credentials_grid.password,
            producer_code: !isEmpty(formDetails?.carrier_credentials_grid?.producer_code?.trim())
              ? formDetails.carrier_credentials_grid.producer_code
              : null,
            market: !isEmpty(formDetails?.carrier_credentials_grid?.market?.trim())
              ? formDetails.carrier_credentials_grid.market
              : null,
            consumer_market: !isEmpty(
              formDetails?.carrier_credentials_grid?.consumer_market?.trim()
            )
              ? formDetails.carrier_credentials_grid.consumer_market
              : null,
            lob: !isEmpty(formDetails?.carrier_credentials_grid?.lob)
              ? formDetails.carrier_credentials_grid.lob
              : null,
            products: !isEmpty(formDetails?.carrier_credentials_grid?.products)
              ? formDetails.carrier_credentials_grid.products
              : null,
            integration_type: !isEmpty(formDetails?.carrier_credentials_grid?.integration_type)
              ? formDetails.carrier_credentials_grid.integration_type
              : EZLYNX,
            is_enable_for_lead_flow:
              !!formDetails?.carrier_credentials_grid?.is_enable_for_lead_flow,
            is_active: !!formDetails?.carrier_credentials_grid?.is_active,
            is_available_for_quoting:
              !!formDetails?.carrier_credentials_grid?.is_available_for_quoting,
          },
        ],
      };
      if (!!carrierAppointmentRowData) {
        this.props.updateOrganizationData(payload);
      } else {
        delete payload.carrier_credentials_grid[0].id;
        this.props.updateOrganizationData(payload);
      }
    }
  };

  onCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    this.setState((prevState: AppComponents.AddEditCarrierAppointmentState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        carrier_credentials_grid: {
          ...prevState.formDetails.carrier_credentials_grid,
          [name]: checked,
        },
      },
    }));
  };

  render() {
    const { isOpen, onCloseAddEditCarrierAppointment, orgTitle, loader, editOrganizationData } =
      this.props;
    const { formDetails, errors } = this.state;
    return (
      <div>
        <Modal
          isOpen={isOpen}
          onClose={onCloseAddEditCarrierAppointment}
          title={orgTitle}
          navButtons={true}
          onSave={this.onSaveCarrierAppointMents}
          loader={loader}
          maxWidth={SM}
        >
          <CarrierAppointmentForm
            formDetails={formDetails}
            isProductRequired={this.isProductRequired}
            onCarrierApoointmentChange={this.onCarrierApoointmentChange}
            nameList={!!editOrganizationData?.carrierList ? editOrganizationData?.carrierList : []}
            loader={loader}
            errors={errors}
            onCheckBoxChange={this.onCheckBoxChange}
            isValidatePassword={this.isValidatePassword()}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.AddEditCarrierAppointmentStore) => {
  const { editOrganizationData } = organizations;
  return { organizations, editOrganizationData };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AddEditCarrierAppointmentDispatch => {
  return bindActionCreators({ setOrgUpdatedResponseData, updateOrganizationData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCarrierAppointment);
