/* istanbul ignore file */
import * as types from '../constants';

interface DashboardState {
  perPageRecords: 10;
  currentPage: number;
  searchText: string;
  insuredListDetails: {
    total: number;
    list: Array<any>;
  };
  insuredTableLoader: boolean;
  insuredVisitedPages: Array<number>;
  appliedFilters: any;
  appliedDateRange: {
    startDate: string;
    endDate: string;
  };
  insuredDropDownLoader: boolean;
  adhocOrganizationLoader: boolean;
  adhocOrganizationList: Array<any>;
}

const INIT_DASHBOARD_STATE: DashboardState = {
  perPageRecords: 10,
  currentPage: 0,
  searchText: '',
  insuredListDetails: {
    total: 0,
    list: [],
  },
  insuredTableLoader: false,
  insuredVisitedPages: [0], // Pagination current page 0 for home table
  appliedFilters: {
    lob: [],
    product: [],
  },
  appliedDateRange: {
    startDate: '',
    endDate: '',
  },
  insuredDropDownLoader: false,
  adhocOrganizationLoader: false,
  adhocOrganizationList: [],
};

const getUpdatedInsuredList = (state: any, currentPage: number, quotes: any, totalRecords: any) => {
  let startIndex = currentPage;
  const currentList = [...state.insuredListDetails.list];
  const perPageRecords = state.perPageRecords;
  const totalQuotes = quotes.length;
  let endIndex = totalQuotes + currentPage * perPageRecords;
  const previousValue = currentPage * perPageRecords - 1;
  if (startIndex !== 0 && (endIndex > totalRecords || !currentList[previousValue])) {
    startIndex = (startIndex - Math.ceil(totalQuotes / perPageRecords) + 1) * perPageRecords;
  } else {
    const startOffset = currentPage === 0 ? 1 : currentPage;
    const endOffset = currentPage === 0 ? perPageRecords : 0;
    startIndex = startOffset * perPageRecords - endOffset;
  }
  endIndex = startIndex + totalQuotes - 1;
  for (let j = startIndex, i = 0; j <= endIndex; j++, i++) {
    if (quotes[i]) {
      currentList[j] = { ...quotes[i] };
    }
  }
  return currentList;
};

export default (state = INIT_DASHBOARD_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...INIT_DASHBOARD_STATE,
      };
    }

    case types.FLUSH_DASHBOARD_DETAILS: {
      return {
        ...INIT_DASHBOARD_STATE,
        searchText: state.searchText,
        appliedFilters: [...state.appliedFilters],
        appliedDateRange: {
          startDate: state.appliedDateRange.startDate,
          endDate: state.appliedDateRange.endDate,
        },
      };
    }

    case types.CLEAR_INSURED_LIST:
    case types.DASHBOARD_INIT: {
      return {
        ...state,
        insuredListDetails: {
          total: 0,
          list: [],
        },
        insuredVisitedPages: [0],
      };
    }

    case types.SET_INSURED_LIST: {
      const { count, quotes, currentPage } = action.payload;
      const totalRecords = count ? count : state.insuredListDetails.total;
      return {
        ...state,
        insuredListDetails: {
          total: totalRecords,
          list: [...getUpdatedInsuredList(state, currentPage, quotes, totalRecords)],
        },
      };
    }

    case types.AUTOFILL_VISITED_PAGES: {
      return {
        ...state,
        ...{ insuredVisitedPages: [...state.insuredVisitedPages, ...action.payload] },
      };
    }

    case types.SET_INSURED_TABLE_LOADER: {
      return {
        ...state,
        insuredTableLoader: action.payload,
      };
    }

    case types.STORE_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }

    case types.SET_APPLIED_FILTERS: {
      return {
        ...state,
        appliedFilters: { ...action.payload },
      };
    }

    case types.SET_DATE_RANGE: {
      return {
        ...state,
        appliedDateRange: {
          ...action.payload,
        },
      };
    }

    case types.SET_INSURED_DROP_DOWN_LOADER: {
      return {
        ...state,
        insuredDropDownLoader: action.payload,
      };
    }

    case types.ADHOC_BIND_ORGANIZATION_LIST_LOADER: {
      return {
        ...state,
        adhocOrganizationLoader: action.payload,
      };
    }

    case types.STORE_ADHOC_BIND_ORGANIZATION_LIST: {
      return {
        ...state,
        adhocOrganizationList: action.payload,
      };
    }

    default:
      return state;
  }
};
