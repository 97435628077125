/* istanbul ignore file */
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { STATE_COVERAGES } from '../../../constants';
import axiosRequest from '../../api';
import { coverageDataMapper, getCoverageDefaultValue } from '../../auto/utils';
import {
  addCurrentVehicle,
  addVehicle,
  autolineDetailsLoader,
  deleteDetailsLoader,
  deleteVehicleDetails,
  deleteVehicleDetailsSuccess,
  errorHandler,
  initVehicle,
  setBodyTypeFormViewQuote,
  setShowVeriskVehicleModal,
  setVehicleLoader,
  setVehicleSelectorLoader,
  setVinLoader,
  setVinServerError,
  stepSubmit,
  storeAutolineDetails,
  storeQuoteVehicleTypeList,
  storeVehicleTypeList,
  toggleVehicleIncludedSuccess,
  updateContinueVehicleList,
  updateVehicleList,
  vehicleSelect,
  vehicleSelectedForEdit,
  vehicleTypeSelected,
  vinCleared,
  vinSubmitSuccess,
} from '../actions';
import * as actions from '../constants';

const vehicleActions: any = actions;

function* fetchVehicleDetails(): any {
  yield put(autolineDetailsLoader(true));
  try {
    const {
      vehicle,
      partner: { isProviderOneLogin },
    } = yield select();
    const { vehiclesList } = vehicle;
    const currentVehicleIndex = findIndex(vehiclesList, { vuid: vehicle.vuid });
    if (isEmpty(get(vehiclesList[currentVehicleIndex], 'bodyTypeList'))) {
      yield put(setBodyTypeFormViewQuote({} as any));
    }
    if (vehicle && vehicle.vehicleSelectorDetails.yearList.length === 0) {
      const details = yield axiosRequest('GET', '/auto/autoyears', isProviderOneLogin, {});
      if (details.data) {
        yield put(storeAutolineDetails(details.data.responseData));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(autolineDetailsLoader(false));
}

export function* fetchAutolineDetailsWatcher() {
  yield takeEvery(vehicleActions.FETCH_AUTOLINE_DETAILS, fetchVehicleDetails);
}

// Update vehicle details on Year, Make and Model selection from Vehicle selector
function* updateVehicleDetails({ payload }: AppRedux.ActionPayload): any {
  yield put(setVehicleSelectorLoader(true));
  try {
    const reduxState = yield select();
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const { year, make, model } = reduxState.vehicle.vehicleSelectorDetails;
    let apiDetails: any = { url: '', type: '', listEntity: '' };
    yield put(setVinServerError(false));
    switch (payload.type) {
      case 'year':
        if (!isEmpty(year)) {
          apiDetails = {
            url: '/auto/automakes',
            listEntity: 'makeList',
            postData: { year },
          };
        }
        break;
      case 'make':
        if (!isEmpty(make)) {
          apiDetails = {
            url: '/auto/automodels',
            listEntity: 'modelList',
            postData: { year, make },
          };
        }
        break;
      case 'model':
        if (!isEmpty(model)) {
          apiDetails = {
            url: '/auto/autosubmodels',
            listEntity: 'bodyTypeList',
            postData: { year, make, model },
          };
        }
        break;
      default:
        return;
    }
    if (!isEmpty(apiDetails.url)) {
      const details = yield axiosRequest('POST', apiDetails.url, isProviderOneLogin, {
        ...apiDetails.postData,
      });
      if (details.data) {
        yield put(
          storeVehicleTypeList({
            entityList: apiDetails.listEntity,
            list: [...details.data],
          } as any)
        );
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setVehicleSelectorLoader(false));
}

export function* updateVehicleDetailsWatcher() {
  yield takeEvery(vehicleActions.VEHICLE_TYPE_SELECTED, updateVehicleDetails);
}

function* setBodyTypeViewQuote(): any {
  yield put(setVehicleSelectorLoader(true));
  try {
    const reduxState = yield select();
    const {
      vehicle,
      partner: { isProviderOneLogin },
    } = reduxState;
    const { year, make, model } = vehicle;
    if (!isEmpty(year)) {
      const apiDetails = {
        url: '/auto/autosubmodels',
        listEntity: 'bodyTypeList',
        postData: { year, make, model },
      };
      const details = yield axiosRequest('POST', apiDetails.url, isProviderOneLogin, {
        ...apiDetails.postData,
      });
      if (details.data) {
        yield put(
          storeQuoteVehicleTypeList({
            entityList: apiDetails.listEntity,
            list: [...details.data],
          } as any)
        );
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setVehicleSelectorLoader(false));
}

export function* setBodyTypeViewQuoteWatcher() {
  yield takeEvery(vehicleActions.SET_BODY_TYPE_FROM_VIEW_QUOTE, setBodyTypeViewQuote);
}

export function* vinSubmitted({ payload }: AppRedux.ActionPayload): any {
  yield put(setVinLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
      common: { isVeriskLoading },
      home,
      applicant,
      vehicle,
    } = yield select();
    const details = yield axiosRequest('POST', '/auto/vinvalidate', isProviderOneLogin, {
      vin: payload,
    });
    if (details.data) {
      const { Make, Year, Model, BodyStyle } = details.data.VehicleResult[0];
      const upperCaseState = applicant.address?.state?.toUpperCase();
      const collision =
        get(details.data.VehicleResult[0], 'Collision', '') ||
        getCoverageDefaultValue('', [
          ...coverageDataMapper(STATE_COVERAGES[upperCaseState].CollisionDeductible),
        ]);
      const comprehensive =
        get(details.data.VehicleResult[0], 'Comprehensive', '') ||
        getCoverageDefaultValue('', [
          ...coverageDataMapper(STATE_COVERAGES[upperCaseState].OtherCollisionDeductible),
        ]);
      const towing_labor =
        get(details.data.VehicleResult[0], 'TowingLabor', '') ||
        getCoverageDefaultValue('', [
          ...coverageDataMapper(STATE_COVERAGES[upperCaseState].TowingDeductible),
        ]);
      const ext_trans_expense =
        get(details.data.VehicleResult[0], 'Rental', '') ||
        getCoverageDefaultValue('', [
          ...coverageDataMapper(STATE_COVERAGES[upperCaseState].RentalDeductible),
        ]);

      const body: any = {
        vinInput: true,
        vin: payload,
        year: Year,
        make: Make,
        model: Model,
        body_type: BodyStyle,
        how_long: get(details.data.VehicleResult[0], 'HowLong', ''),
        approx_miles: get(details.data.VehicleResult[0], 'ApproxMiles', ''),
        own_lease: '',
        primary_use: '',
        collision,
        comprehensive,
        towing_labor,
        ext_trans_expense,
        bodyTypeList: [
          {
            label: BodyStyle,
            value: BodyStyle,
          },
        ],
      };
      yield put(vinSubmitSuccess(body));
      yield put(setVinServerError(false));
      yield put(addCurrentVehicle());

      if (home.pconfig?.dale_config?.is_verisk_prefill_available && isVeriskLoading) {
        body.clear = true;
        body.vehicleAdded = true;
        body.isIncluded = false;
        yield put(addVehicle(body));
        if (vehicle.vehiclesList && !vehicle.vehiclesList.length) {
          yield put(setShowVeriskVehicleModal(true));
        }
      }
    }
  } catch (error) {
    yield put(vinCleared());
    yield put(setVinServerError(true));
    yield put(errorHandler(error));
  }
  yield put(setVinLoader(false));
}

export function* vinSubmittedWatcher() {
  yield takeEvery(vehicleActions.VIN_SUBMIT, vinSubmitted);
}

function* addNewVehicle({ payload }: AppRedux.ActionPayload) {
  yield put(setVehicleLoader(true));
  try {
    const { vehicle, coverage } = yield select();
    const { vehicleSelectorDetails, vinInput } = vehicle;
    let vehicleDetails = null;
    if (!vinInput) {
      vehicleDetails = {
        year: vehicleSelectorDetails.year,
        make: vehicleSelectorDetails.make,
        model: vehicleSelectorDetails.model,
      };
    } else {
      vehicleDetails = {
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
      };
    }
    yield put(
      updateVehicleList({
        ...omit(payload, ['new']),
        ...vehicleDetails,
        coverage: { ...coverage },
      })
    );
    if (payload.clear) {
      yield put(initVehicle());
    } else {
      // yield put(push('driver'));
      // todo: add newxt tab
      // const { currentIndex, onNewTab } = this.props;
      // onNewTab(currentIndex);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setVehicleLoader(false));
}

export function* addNewVehicleWatcher() {
  yield takeEvery(vehicleActions.ADD_VEHICLE, addNewVehicle);
}

// TODO: Delete if no needed after "include/exclude switch"
function* deleteVehicleDetailsHandler({ payload: { vuid } }: AppRedux.ActionPayload) {
  yield put(deleteDetailsLoader(true));
  try {
    yield put(deleteVehicleDetailsSuccess(vuid));
    const { vehicle } = yield select();
    if (vehicle.vehiclesList.length === 0) {
      // todo: do something
      // yield put(push('vehicle'));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(deleteDetailsLoader(false));
}

export function* deleteVehicleDetailsWatcher() {
  yield takeEvery(vehicleActions.DELETE_VEHICLE_DETAILS, deleteVehicleDetailsHandler);
}

function* toggleVehicleIncludedStatus({ payload: { vuid } }: AppRedux.ActionPayload) {
  try {
    yield put(toggleVehicleIncludedSuccess(vuid));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* toggleVehicleIncludedStatusWatcher() {
  yield takeEvery(vehicleActions.TOGGLE_VEHICLE_INCLUDED_STATUS, toggleVehicleIncludedStatus);
}

function* editSelectedVehicle() {
  const { vehicle } = yield select();
  const { viewQuote, year, make, model, vinInput, vehicleSelectorDetails } = vehicle;
  if (
    viewQuote &&
    !vinInput &&
    isEmpty(vehicleSelectorDetails.makeList) &&
    isEmpty(vehicleSelectorDetails.modelList)
  ) {
    yield put(vehicleTypeSelected({ type: 'year', value: year } as any));
    yield put(vehicleTypeSelected({ type: 'make', value: make } as any));
    yield put(vehicleTypeSelected({ type: 'model', value: model } as any));
  }
}

// Updates coverages of selected vehicle
export function* editSelectedVehicleWatcher() {
  yield takeEvery(vehicleActions.EDIT_SELECTED_VEHICLE, editSelectedVehicle);
}

// If vehicle page continue then select next vehicle otherwise go to next page
function* vehicleContinueHandler({ payload }: AppRedux.ActionPayload) {
  try {
    const { vehicle } = yield select();
    const { vehiclesList } = vehicle;
    const currentVehicleIndex = findIndex(vehiclesList, { vuid: vehicle.vuid });
    if (currentVehicleIndex < vehiclesList.length - 1 && !payload.getQuoteClick) {
      const nextVehicleIndex = currentVehicleIndex + 1;
      yield put(updateContinueVehicleList({ ...payload.data, vehiclesList }));
      yield put(vehicleSelect(vehiclesList[nextVehicleIndex].vuid));
      if (isEmpty(get(vehiclesList[nextVehicleIndex], 'bodyTypeList'))) {
        yield put(setBodyTypeFormViewQuote({} as any));
      }
    } else if (currentVehicleIndex === vehiclesList.length - 1 || payload.getQuoteClick) {
      yield put(
        stepSubmit({
          entity: payload.data.entity,
          getQuoteClick: payload.getQuoteClick,
          data: {
            ...omit(payload.data, [
              'entity',
              'vehiclesList',
              'selectedVehicle',
              'showVehicleForm',
              'vehicleErrors',
              'comprehensive_data',
              'collision_data',
              'towing_labor_data',
              'ext_trans_expense_data',
              'missingVehicleDialog',
              'vehicleDetailsMissingDialog',
              'operationDetails',
            ]),
            vehicleAdded: true,
          },
        } as any)
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* vehicleContinueWatcher() {
  yield takeEvery(vehicleActions.VEHICLE_CONTINUE, vehicleContinueHandler);
}

function* vehicleBackHandler() {
  try {
    const { vehicle } = yield select();
    const { vehiclesList } = vehicle;
    const currentVehicleIndex = findIndex(vehiclesList, { vuid: vehicle.vuid });
    if (currentVehicleIndex > 0) {
      const nextVehicleIndex = currentVehicleIndex - 1;
      if (!vehicle.vehicleAdded) {
        yield put(deleteVehicleDetails({ vuid: vehicle.vuid } as any));
      }
      yield put(vehicleSelect(vehiclesList[nextVehicleIndex].vuid));
      if (isEmpty(get(vehiclesList[nextVehicleIndex], 'bodyTypeList'))) {
        yield put(setBodyTypeFormViewQuote({} as any));
      }
    } else {
      yield put(vehicleSelectedForEdit(vehiclesList[0].vuid));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* vehicleBackWatcher() {
  yield takeEvery(vehicleActions.VEHICLE_BACK, vehicleBackHandler);
}

function* vinBackHandler({ payload: isVinEdit }: AppRedux.ActionPayload) {
  try {
    const { vehicle } = yield select();
    const { vehiclesList } = vehicle;
    if (!vehicle?.vehicleAdded || !isVinEdit) {
      if (!isVinEdit) {
        // todo: check this
        // yield put(goBack());
        // yield delay(20);
      }
      const currentVehicle = !isEmpty(vehiclesList) && find(vehiclesList, { vuid: vehicle?.vuid });
      if (!isEmpty(currentVehicle)) {
        if (currentVehicle?.vuid === vehicle?.vuid) {
          yield put(vehicleSelect(currentVehicle.vuid));
        }
      } else {
        yield put(deleteVehicleDetails({ vuid: vehicle?.vuid } as any));
        yield put(vehicleSelect(vehiclesList[0]?.vuid));
      }
    } else {
      yield put(vehicleSelect(vehicle?.vuid));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* vinBackWatcher() {
  yield takeEvery(vehicleActions.VIN_BACK, vinBackHandler);
}

export default function* rootSaga() {
  yield all([
    fork(fetchAutolineDetailsWatcher),
    fork(updateVehicleDetailsWatcher),
    fork(vinSubmittedWatcher),
    fork(addNewVehicleWatcher),
    fork(deleteVehicleDetailsWatcher),
    fork(editSelectedVehicleWatcher),
    fork(vehicleContinueWatcher),
    fork(vehicleBackWatcher),
    fork(setBodyTypeViewQuoteWatcher),
    fork(vinBackWatcher),
    fork(toggleVehicleIncludedStatusWatcher),
  ]);
}
