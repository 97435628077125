/* istanbul ignore file */
import * as coverageActionType from '../constants';

// Add coverage action creator
export const addCoverage = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: coverageActionType.ADD_COVERAGE,
  payload,
});

// Clear coverages
export const clearCoverages = (): AppRedux.Action => ({
  type: coverageActionType.CLEAR_COVERAGE,
});

// Set coverages of current vehicle
export const setCoverages = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: coverageActionType.SET_COVERAGE,
  payload,
});
