import React from 'react';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CallIcon from '@material-ui/icons/Call';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import forEach from 'lodash/forEach';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import sortBy from 'lodash/sortBy';
import Typography from '@material-ui/core/Typography';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

import AMSButton from '../../components/ams-button';
import AMSDetailsModal from '../../components/ams-details-modal';
import AMSSuccessModal from '../../components/ams-success-modal';
import ConfirmationModal from '../../components/confirmation-modal';
import { deductiblesMap } from '../../home/utils';
import { checkPolicyBounded, formatNumberToCurrency, formatPhoneNumber } from '../../../utils';
import { homeSidebarEdit, setAMSDetailsSuccess, showAMSModal } from '../../redux/actions';
import { PAGES_TEXT, TABLE_COVERAGES } from '../../../constants';

import './home-edit-sidebar.scss';

class HomeEditSidebar extends React.PureComponent<
  AppComponents.HomeEditSidebarProps,
  AppComponents.HomeEditSidebarState
> {
  state: AppComponents.HomeEditSidebarState = {
    deductiblesDetails: [],
    showConfirmationDialog: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps: AppComponents.HomeEditSidebarProps) {
    if (
      (!nextProps.headerData && this.props.headerData) ||
      nextProps.headerData !== this.props.headerData
    ) {
      this.setState(
        {
          deductiblesDetails: [],
        },
        () => {
          if (!isEmpty(nextProps.headerData)) {
            this.mapDeductibles(nextProps.headerData);
          }
        }
      );
    }
  }

  mapDeductibles = (headerData: any) => {
    const map: any = Object.assign({}, deductiblesMap);
    const { deductibles } = headerData;
    !isEmpty(headerData) &&
      forEach(deductibles, (coverageValue, coverageType) => {
        if (TABLE_COVERAGES.includes(coverageType)) {
          const details = Object.assign({}, map[coverageType].details);
          if (isEmpty(details.value)) {
            details.value = coverageValue;
            this.setState((state: AppComponents.HomeEditSidebarState) => {
              return {
                deductiblesDetails: [...state.deductiblesDetails, details],
              };
            });
          }
        }
      });
  };

  getAddressDetails = ({ street, city, state, zip, unit }: AppComponents.ApplicantAddress) => {
    const formattedAddressLine1 =
      (!isEmpty(street) && (!isEmpty(city) || !isEmpty(state) || !isEmpty(zip) || !isEmpty(unit))
        ? `${street} `
        : street) + (!isEmpty(unit) ? `${unit}` : '');
    const formattedAddressLine2 =
      (!isEmpty(city) && (!isEmpty(state) || !isEmpty(zip)) ? `${city} ` : city) +
      (!isEmpty(state) && !isEmpty(zip) ? `${state.toUpperCase()} ` : state.toUpperCase()) +
      (!isEmpty(zip) ? `${zip} ` : '');
    return {
      addressFirstLine: formattedAddressLine1,
      addressSecondLine: formattedAddressLine2,
    };
  };

  isValidValue = (coverage: any): boolean => {
    return (
      !isEmpty(coverage) &&
      !isUndefined(coverage.value) &&
      !isEmpty(String(coverage.value)) &&
      parseFloat(coverage.value) !== 0 &&
      !isNaN(Number(coverage.value))
    );
  };

  getApplicantName = ({ first_name, middle_name, last_name }: any): string => {
    return `${first_name.trim()} ${middle_name.trim()} ${last_name.trim()}`.trim();
  };

  getDeductiblesList = () => {
    const deductibleSequence = ['All Peril', 'Hurricane', 'Wind'];
    const { deductiblesDetails } = this.state;
    let indexedCoverages = [...deductiblesDetails];
    if (!isEmpty(deductiblesDetails)) {
      const coveragesArray: any = [];
      deductiblesDetails.forEach((coverage: any) => {
        const index = findIndex(deductibleSequence, val => {
          return val.toLowerCase() === coverage.label.toLowerCase();
        });
        coveragesArray.push({
          ...coverage,
          index,
        });
      });
      if (coveragesArray.length > 0) {
        indexedCoverages = sortBy(coveragesArray, ['index']);
      }
    }
    if (!isEmpty(indexedCoverages)) {
      const coveragesList = indexedCoverages.map(
        coverage =>
          this.isValidValue(coverage) && (
            <tr key={coverage.label}>
              <td>
                <span>{coverage.label}</span>
              </td>
              <td className='highlight-text text-align-right text-weight'>
                <span>{formatNumberToCurrency(coverage.value)}</span>
              </td>
            </tr>
          )
      );
      return (
        <React.Fragment>
          <table className='deductibles-table'>
            <thead>
              <tr>
                <th className='text-weight deductible-font'>Deductibles</th>
              </tr>
            </thead>
            <tbody>{coveragesList}</tbody>
          </table>
          <div className='pl-3 pr-3'>
            <hr className='divider' />
          </div>
        </React.Fragment>
      );
    }
    return '';
  };

  getAddress = () => {
    const { address } = this.props;
    const { addressFirstLine, addressSecondLine } = this.getAddressDetails(address);
    const fullAddress = `${addressFirstLine}${addressSecondLine}`;
    return fullAddress.length > 0 ? (
      <li>
        <div className='sub-title'>
          <LocationOnOutlinedIcon className='mr-3' />
          Address
        </div>

        <div className='personal-details address-lines'>{addressFirstLine}</div>
        <div className='personal-details address-lines'>{addressSecondLine}</div>
      </li>
    ) : (
      ''
    );
  };

  AMSModalCloseHandler = () => {
    this.props.showAMSModal(false);
  };

  validQuotesPresent = () => {
    const {
      quoteList,
      hippoQuoteLoaded,
      ezlynxQuotesLoaded,
      policyBounded,
      lineOfBusiness,
    } = this.props;
    const validQuotes = filter(quoteList, quote => !quote.quoteError && !quote.policyBounded);
    return (
      validQuotes.length > 0 &&
      hippoQuoteLoaded &&
      ezlynxQuotesLoaded &&
      !checkPolicyBounded(policyBounded, lineOfBusiness, quoteList)
    );
  };

  policyConfirmationCloseHandler = (isPolicyBound: boolean) => {
    if (isPolicyBound) {
      this.props.showAMSModal(true);
    }
    this.setState({
      showConfirmationDialog: false,
    });
  };

  showHomePolicyBindConfirmationDialog = () => (
    <ConfirmationModal
      open={this.state.showConfirmationDialog}
      closehandler={this.policyConfirmationCloseHandler}
      heading={PAGES_TEXT.AMSPolicyBoundModalTitle}
      message={PAGES_TEXT.AMSPolicyBoundModalContent}
      contentValue=''
    />
  );

  getHomeAMSSuccessModal = () =>
    this.props.AMSSuccess ? (
      <AMSSuccessModal
        open={this.props.AMSSuccess}
        closeModal={() => {
          this.props.setAMSDetailsSuccess(false);
        }}
      />
    ) : (
      ''
    );

  render() {
    const { showConfirmationDialog } = this.state;
    const { productType, personal_details, quoteList, openAMSModal, lineOfBusiness } = this.props;

    return (
      <div className='home-edit-sidebar'>
        <div className='sidebar-wrapper'>
          {this.validQuotesPresent() && (
            <React.Fragment>
              <AMSButton
                handleclick={() => {
                  this.setState({
                    showConfirmationDialog: true,
                  });
                }}
              />
            </React.Fragment>
          )}

          <React.Fragment>{this.getDeductiblesList()}</React.Fragment>
          <div className='applicant-details-container'>
            <Typography className='applicant-heading'>Applicant</Typography>
            {!isEmpty(personal_details) && (
              <ul>
                {!isEmpty(this.getApplicantName(personal_details)) && (
                  <li>
                    <div className=''>
                      <div className='sub-title'>
                        <AccountCircleOutlinedIcon className='mr-3' />
                        Name
                      </div>
                    </div>

                    <div className='personal-details applicant-name'>
                      {this.getApplicantName(personal_details)}
                    </div>
                  </li>
                )}

                {!isEmpty(personal_details.phone_number) && (
                  <li>
                    <div className='sub-title'>
                      <CallIcon className='mr-3' />
                      Phone Number
                    </div>
                    <div className='personal-details'>
                      <span>{formatPhoneNumber(personal_details.phone_number)}</span>
                    </div>
                  </li>
                )}

                {!isEmpty(personal_details.email) && (
                  <li>
                    <div className='sub-title'>
                      <MailOutlineIcon className='mr-3' />
                      Email Address
                    </div>
                    <div className='personal-details'>
                      <div className='email-address'>{personal_details.email}</div>
                    </div>
                  </li>
                )}

                {!isEmpty(personal_details.date_of_birth) && (
                  <li>
                    <div className='sub-title'>
                      <CalendarTodayIcon className='mr-3' />
                      Date of Birth
                    </div>
                    <div className='personal-details'>
                      <span>{personal_details.date_of_birth}</span>
                    </div>
                  </li>
                )}

                {this.getAddress()}

                {!isEmpty(productType) && (
                  <li>
                    <div className='sub-title'>
                      <HomeOutlinedIcon className='mr-3' />
                      House Type
                    </div>
                    <div className='personal-details'>
                      <span>{productType.toUpperCase()}</span>
                    </div>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>

        {this.validQuotesPresent() && openAMSModal && (
          <AMSDetailsModal
            open={openAMSModal}
            type={lineOfBusiness}
            quoteList={quoteList}
            closehandler={this.AMSModalCloseHandler}
            isQuote={true}
          />
        )}

        {this.getHomeAMSSuccessModal()}

        {showConfirmationDialog && this.showHomePolicyBindConfirmationDialog()}
      </div>
    );
  }
}

const mapStateToProps = ({
  home,
  common,
}: AppComponents.HomeEditSidebarStore): AppComponents.HomeEditSidebarStoreProps => {
  const {
    pconfig,
    defaultPConfig,
    showResult,
    viewQuotes,
    address,
    headerData,
    personal_details,
    productType,
    quoteList,
    ezlynxQuotesLoaded,
    hippoQuoteLoaded,
  } = home;
  const { AMSSuccess, openAMSModal, policyBounded, lineOfBusiness } = common;

  return {
    pconfig,
    defaultPConfig,
    showResult,
    viewQuotes,
    address,
    headerData,
    personal_details,
    productType,
    quoteList,
    ezlynxQuotesLoaded,
    hippoQuoteLoaded,
    openAMSModal,
    AMSSuccess,
    policyBounded,
    lineOfBusiness,
  };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.HomeEditSidebarDispatch => {
  return bindActionCreators(
    {
      homeSidebarEdit,
      setAMSDetailsSuccess,
      showAMSModal,
    },
    dispatchProp
  );
};

export default connect(mapStateToProps, mapDispatchToProps)<any>(HomeEditSidebar);
