import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import "./generic-model.scss";

const GenericModal = (props: AppComponents.GenericModalProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.closehandler();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog className="generic-model"
        classes={{
          root: 'auto-dialog-container',
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby='confirmation-dialog-title'
      >
        <DialogTitle className="text-center" id='confirmation-dialog-title'>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText className="text-center"
            dangerouslySetInnerHTML={{
              __html: props.message,
            }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button
            onClick={() => {
              handleClose();
            }}
             className="close-button"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GenericModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  closehandler: PropTypes.func.isRequired,
};

export default GenericModal;
