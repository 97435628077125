/* eslint-disable max-lines-per-function */
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import { CheckCircle, FiberManualRecord } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PolicyBoundImageUrl from '../../../assets/images/policy-bound-ribbon.png';
import { HOME, POLICY_BOUND } from '../../../constants';
import { ErrorCategoryIconMapping } from '../../../pages/components/rate-page-common-component';
import ViewAmsBoundData from '../../../pages/components/view-bound-quote';
import { useAppSelector } from '../../../pages/redux/hooks';
import { ReduxRootState } from '../../../pages/redux/store';
import '../product-rates/products-rates.scss';
import RatesBlock from './rates-block-inner';
import { HomeQuoteListProps } from './types/home-quote.type';
import { getPrsv2ErrorCategory } from '../../utils';

const HomeQuoteList: React.FC<HomeQuoteListProps> = ({
  quoteList,
  updateSelectedQuoteList,
  quote,
  selectedQuoteList,
  position,
  errorQuotes,
  successQuotes,
}) => {
  const {
    common,
    partner: { appointments },
  }: ReduxRootState = useAppSelector((state: ReduxRootState) => state);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [disableCheckBox, setDisableCheckBox] = useState(false);
  const [openViewBoundQuote, setOpenViewBoundQuote] = useState(false);
  const isErrorQuote: boolean = !!quote.error;
  let showPolicyBoundTag = quote.data?.policyBounded ?? false;
  let carrierErrorType = getPrsv2ErrorCategory(quote);

  const openPolicyBoundDetailsModal = () => {
    setOpenViewBoundQuote(true);
  };

  useEffect(() => {
    const { boundedPolicyDetails } = common;
    if (showPolicyBoundTag || !!boundedPolicyDetails?.home) {
      setDisableCheckBox(true);
    } else {
      setDisableCheckBox(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPolicyBoundTag]);

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>, details?: string) => {
    setIsCheckboxChecked(e.target.checked);
    if (quoteList[position]?.error) {
      return;
    }
    if (common?.selectedCarriers[HOME] && details) {
      const carrierDetails = common?.selectedCarriers[HOME];
      const selectedQuote = quoteList.find(
        element => element.carrierId?.toString() === carrierDetails?.carrierId?.toString()
      );
      if (selectedQuote) {
        updateSelectedQuoteList(selectedQuote, e.target.checked, HOME);
      }
    } else {
      updateSelectedQuoteList(quote, e.target.checked, HOME);
    }
  };

  const drawRatesBlocks = (): ReactNode => {
    return (
      <RatesBlock
        key={`rates_${quote.carrierId}_${HOME}`}
        quote={quote}
        errorQuotes={errorQuotes}
        successQuotes={successQuotes}
        appointmentKeys={Object.keys(appointments)}
        rank={position}
      />
    );
  };

  const verifyChecked = (): boolean => {
    if (disableCheckBox) {
      return Boolean(quote.data?.policyBounded);
    }
    return !isEmpty(selectedQuoteList) && selectedQuoteList.some((quoteSelected: any) => {
      const policyBounded = quoteSelected?.quote?.data?.policyBounded;
      const selectedCarrierId = quoteSelected?.quote?.carrierId?.toString();
      const currentCarrierId = quote?.carrierId?.toString();
      return !policyBounded && selectedCarrierId === currentCarrierId;
    });
  };

  const navigationContent = () => (
    <Grid item xs={quote.error ? 12 : 11} className='home-rates-blocks'>
      <Grid container spacing={0} className='h-100'>
        <Grid item xs={12} className='d-flex'>
          {drawRatesBlocks()}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={2} className='mt-3'>
      {!!openViewBoundQuote && (
        <ViewAmsBoundData
          open={openViewBoundQuote}
          onClose={() => setOpenViewBoundQuote(false)}
          lob={HOME}
        />
      )}
      <Grid item xs={12}>
        <Card
          className={`${
            isCheckboxChecked
              ? 'rates-block rates-block-active'
              : 'rates-block rates-block-deactive '
          }
          `}
          elevation={0}
        >
          <>
            {!!showPolicyBoundTag && (
              <div className='row'>
                <div className='col-lg-12'>
                  <img
                    src={PolicyBoundImageUrl}
                    alt={POLICY_BOUND}
                    className='img-fluid position-absolute policy-bound-label'
                    onClick={() => openPolicyBoundDetailsModal()}
                  />
                </div>
              </div>
            )}
            <CardContent className='rli-padding-0'>
              <Grid container spacing={0}>
                {!isErrorQuote || isEmpty(carrierErrorType) ? (
                  <FormControl component='fieldset' className='checkbox-wrap-radio'>
                    <RadioGroup row name='lines'>
                      <FormControlLabel
                        className='radio-button-box'
                        control={
                          <Checkbox
                            icon={<FiberManualRecord />}
                            checkedIcon={<CheckCircle className='check-icon' />}
                            name='checkedH'
                            checked={verifyChecked()}
                            disabled={disableCheckBox ? true : isErrorQuote}
                            onChange={e => onCheckboxChange(e, '')}
                          />
                        }
                        label=''
                      />
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <>
                    {!isEmpty(carrierErrorType) && (
                      <ErrorCategoryIconMapping carrierErrorType={carrierErrorType} />
                    )}
                  </>
                )}
                {navigationContent()}
              </Grid>
            </CardContent>
          </>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HomeQuoteList;
