import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import history from '../../../history';
import { validateQuoteUrl, setHomeState } from '../../redux/actions';
import Loader from '../loader';

export class ValidateQuoteURL extends Component<AppComponents.ValidateQuoteURLsProps> {

  componentDidMount = () => {
    const { pathname } = history.location;
    const quoteToken = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (!isEmpty(quoteToken) && pathname.includes(quoteToken) && quoteToken !== 'view') {
      this.props.validateQuoteUrl({ token: quoteToken });
    } else {
      this.props.setHomeState({
        showError: true,
        notFoundError: true,
        expiredError: false,
      } as any);
      history.push('/error');
    }
  };

  render() {
    return (
      <div className='loader-content'>
          {this.props.validateQuoteUrlLoader && <Loader />}
      </div>
    );
  };
}
const mapStateToProps = ({ viewQuoteDetails }: AppComponents.ValidateQuoteURLStore) => {
  const { validateQuoteUrlLoader } = viewQuoteDetails;
  return { validateQuoteUrlLoader };
};

const mapDispatchToProps = (dispatch: any): AppComponents.ValidateQuoteURLDispatch => {
  return bindActionCreators({ validateQuoteUrl, setHomeState }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateQuoteURL);
