import { createApi } from '@reduxjs/toolkit/query/react';
import { isEmpty } from 'lodash';
import { SUCCESSFULLY } from '../../../../constants';
import {
  fetchUmbrellaQuestionListInfo,
  getVeriskAutoDetails,
  petBreedsDetailsLoader,
  saveApplicantError,
  setAddProductSaveApplicantLoader,
  setApplicantSuccess,
  setNoQuotesAvailableForHome,
  setPetBreedsDetails,
  setPetQuotes,
  setPollingErrorDetails,
  setRequestId,
  setUmbrellaHerdId,
  storeLifeQuoteDetails,
  updateQuoteDetails,
} from '../../../../pages/redux/actions';
import { LineOfBusiness } from '../../../enums';
import { axiosBaseQuery } from '../base-query';
import { APILifeCycle } from '../types';
import {
  Address,
  SaveApplicantRequest,
  SaveApplicantResponse,
  ValidateAddressResponse,
} from './types';

const onSaveApplicantQueryStarted = async (
  {
    request: { lob, clearAutoInfo, clearUmbrellaInfo },
    state: { quotingDetails, isVeriskPrefillAvailable, driversList, umbrellaInfo },
  }: SaveApplicantRequest,
  { dispatch, queryFulfilled }: APILifeCycle<SaveApplicantResponse>
) => {
  try {
    dispatch(saveApplicantError(false));
    dispatch(setNoQuotesAvailableForHome(false));
    if (lob === LineOfBusiness.PET) {
      dispatch(setPetBreedsDetails({ cat: {}, dog: {}, isBreedError: false }));
    }
    const { data } = await queryFulfilled;
    const herdId = data.herdId || data.herd_id;
    if (!data.message?.includes(SUCCESSFULLY)) {
      dispatch(setAddProductSaveApplicantLoader(false));
      return;
    }
    if (lob === LineOfBusiness.AUTO) {
      dispatch(
        updateQuoteDetails({
          ...quotingDetails,
          herd_id: herdId,
        })
      );

      if (data.error_details?.display_errors_on_ui && !isEmpty(data.error_details.errors)) {
        dispatch(setPollingErrorDetails({ ...data }));
      }
      if (isVeriskPrefillAvailable && (!driversList.length || clearAutoInfo)) {
        dispatch(getVeriskAutoDetails());
      }
    } else if (lob === LineOfBusiness.PET) {
      dispatch(petBreedsDetailsLoader(true));
      dispatch(setApplicantSuccess(true));
      dispatch(
        setPetQuotes({
          quoteList: [],
          herdId,
          upcomingCarrierList: [],
        })
      );
    } else if (lob === LineOfBusiness.LIFE) {
      dispatch(storeLifeQuoteDetails({ herdId }));
    } else if (lob === LineOfBusiness.UMBRELLA) {
      dispatch(setUmbrellaHerdId(herdId ?? ''));
      if (isEmpty(umbrellaInfo?.questionGroups) || clearUmbrellaInfo) {
        dispatch(fetchUmbrellaQuestionListInfo());
      }
    }
    if (data.provider_request_id) {
      dispatch(setRequestId(data.provider_request_id));
    }
  } catch (error) {
    dispatch(saveApplicantError(true));
  }
  dispatch(setAddProductSaveApplicantLoader(false));
};

export const applicantApi = createApi({
  reducerPath: 'applicantApi',
  baseQuery: axiosBaseQuery(),

  endpoints: builder => ({
    validateAddress: builder.mutation<ValidateAddressResponse, Address>({
      query: body => ({ url: `/address/validate`, method: 'POST', body }),
      transformResponse: (data: ValidateAddressResponse) => {
        return { ...data, state: data.state.toUpperCase() };
      },
    }),

    saveApplicant: builder.mutation<SaveApplicantResponse, SaveApplicantRequest>({
      query: body => ({
        url: '/applicant/save-applicant-info',
        method: 'POST',
        body: body.request,
      }),
      onQueryStarted: onSaveApplicantQueryStarted,
    }),
  }),
});

export const { useValidateAddressMutation, useSaveApplicantMutation } = applicantApi;
