import React, { Component } from 'react';
import { FormHelperText, Grid, InputLabel, Switch } from '@material-ui/core';
import { isEmpty, startCase, debounce } from 'lodash';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { bindActionCreators } from 'redux';
import {
  ASTERISK,
  CANCEL,
  CONFIRMATION_MESSAGE,
  CONTINUE,
  REQUIRED,
  SELECT,
  SFDC_KEY,
  SFDC_ORG_ACCOUNT_INFO_MESSAGE,
  SM,
  XS,
} from '../../../constants';
import axiosRequest from '../../api';
import CommonModal from '../../components/common-modal';
import Modal from '../../components/modal';
import SelectAutoComplete from '../../components/select-autocomplete';
import {
  errorHandler,
  fetchAccountRecordTypeList,
  fetchLeadTypeListData,
  setAllowUpdateLeadRecordType,
  setDuplicateLeadIdErrorMessage,
  setLeadTypeListLoader,
  setOrgAccountsListData,
  setOrgUpdatedResponseData,
  storeOrganizationData,
  updateOrganizationData,
} from '../../redux/actions';
import './crm.styles.scss';

const INIT_CRM_ENTITY = {
  salesForceEnabled: false,
  iframeEditingEnabled: false,
  leadRecordTypeId: '',
  orgAccountId: { label: startCase(SELECT), value: '' },
  accountRecordTypeId: '',
  allowAddingAddProduct: false,
};

const INIT_CRM_ERROR_ENTITY = {
  leadTypeIdError: '',
  orgAccountIdError: '',
};

export class CRMComponent extends Component<
  AppComponents.CRMComponentDataProps,
  AppComponents.CRMComponentState
> {
  state: AppComponents.CRMComponentState = {
    crm: { ...INIT_CRM_ENTITY },
    crmErrors: { ...INIT_CRM_ERROR_ENTITY },
  };

  componentDidMount = () => {
    this.props.setDuplicateLeadIdErrorMessage(null);
    const { accountRecordTypeList, editOrganizationData, leadTypeList } = this.props;
    this.setState((prevState: any) => ({
      ...prevState,
      crm: {
        salesForceEnabled: !!editOrganizationData?.salesforce_enabled ? true : false,
        leadRecordTypeId: !!editOrganizationData?.crm_lead_record_type_id
          ? editOrganizationData?.crm_lead_record_type_id
          : '',
        iframeEditingEnabled: !!editOrganizationData?.iframe_editing_enabled,
        accountRecordTypeId: !!editOrganizationData?.crm_account_record_type_id
          ? editOrganizationData?.crm_account_record_type_id
          : '',
        orgAccountId: {
          label: editOrganizationData?.crm_account_name || startCase(SELECT),
          value: editOrganizationData?.crm_account_id || '',
        },
        allowAddingAddProduct: !!editOrganizationData?.crm_allow_add_products,
      },
    }));
    if (!!editOrganizationData?.salesforce_enabled) {
      isEmpty(leadTypeList) && this.props.fetchLeadTypeListData();
      isEmpty(accountRecordTypeList) && this.props.fetchAccountRecordTypeList();
      if (
        editOrganizationData?.crm_account_id &&
        !isEmpty(editOrganizationData?.crm_account_name)
      ) {
        this.props.setOrgAccountsListData([
          { value: '', label: startCase(SELECT) },
          {
            value: editOrganizationData?.crm_account_id,
            label: editOrganizationData?.crm_account_name,
          },
        ]);
      }
    }
  };

  componentWillReceiveProps = (newProps: any) => {
    const { organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.props.closeModal();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
  };

  onCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    const { accountRecordTypeList, leadTypeList } = this.props;
    this.setState(
      (prevState: any) => ({
        ...prevState,
        crm: {
          ...prevState.crm,
          [name]: checked,
        },
      }),
      () => {
        if (!isEmpty(name) && name === SFDC_KEY.SALES_FORCE_ENABLED_KEY && !!checked) {
          isEmpty(leadTypeList) && this.props.fetchLeadTypeListData();
          isEmpty(accountRecordTypeList) && this.props.fetchAccountRecordTypeList();
          this.setState((prevState: AppComponents.CRMComponentState) => ({
            ...prevState,
            crm: {
              ...prevState.crm,
              allowAddingAddProduct: false,
            },
          }));
        }
      }
    );
  };

  validate = () => {
    let errors = { ...INIT_CRM_ERROR_ENTITY };
    const { leadRecordTypeId, salesForceEnabled } = this.state.crm;
    errors.leadTypeIdError = !!salesForceEnabled && isEmpty(leadRecordTypeId) ? REQUIRED : '';
    this.setState((prevState: any) => ({
      ...prevState,
      crmErrors: { ...errors },
    }));
    if (!isEmpty(errors?.leadTypeIdError)) {
      return false;
    } else return true;
  };

  onSaveCrmFormData = () => {
    let isValid = this.validate();
    const { editOrganizationData } = this.props;
    const {
      accountRecordTypeId,
      allowAddingAddProduct,
      iframeEditingEnabled,
      leadRecordTypeId,
      orgAccountId,
      salesForceEnabled,
    } = this.state.crm;

    if (!!isValid) {
      let payload: any = {
        code: editOrganizationData?.code,
        auth_token: editOrganizationData?.auth_token,
        id: editOrganizationData?.id,
        name: editOrganizationData?.name,
        salesforce_enabled: !!salesForceEnabled ? true : false,
        iframe_editing_enabled: !!iframeEditingEnabled,
        crm_lead_record_type_id:
          !isEmpty(leadRecordTypeId) && !!salesForceEnabled ? leadRecordTypeId : null,
        crm_account_record_type_id: !!salesForceEnabled ? accountRecordTypeId || null : null,
        crm_account_id:
          !!salesForceEnabled && !isEmpty(accountRecordTypeId) ? orgAccountId.value || null : null,
        crm_allow_add_products: !!allowAddingAddProduct,
      };
      if (
        !!this?.props?.organizations?.leadRecordTypeAllowUpdateDetails
          ?.isOpenAllowUpdateLeadRecordType
      ) {
        payload.allowUpdate = true;
        this.props.setAllowUpdateLeadRecordType(null);
      }
      if (!salesForceEnabled && !isEmpty(accountRecordTypeId)) {
        this.props.storeOrganizationData({
          ...editOrganizationData,
          crm_account_name: '',
        });
      }
      this.props.updateOrganizationData(payload);
    }
  };

  onCloseConfirmationModal = () => {
    this.props.setAllowUpdateLeadRecordType(null);
  };

  onCrmDataChange = (event: any) => {
    const { name, value } = event.target;
    let changedOrgAccId = this.state.crm.orgAccountId;
    if (name === SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_KEY) {
      changedOrgAccId = { label: startCase(SELECT), value: '' };
      this.props.setOrgAccountsListData([{ label: startCase(SELECT), value: '' }]);
    }
    this.setState((prevState: any) => ({
      ...prevState,
      crm: {
        ...prevState.crm,
        [name]: value,
        orgAccountId: changedOrgAccId,
      },
    }));
  };

  loadOrgAccountOptions = (inputValue: string, callback: (options: Array<any>) => void) => {
    this.props.setLeadTypeListLoader(true);
    this.props.setDuplicateLeadIdErrorMessage(null);
    const { isProviderOneLogin } = this.props;
    const { accountRecordTypeId } = this.state.crm;
    if (accountRecordTypeId) {
      if (inputValue && inputValue.length > 2) {
        axiosRequest(
          'GET',
          `/api/v1/crm/get-organization-accounts/${accountRecordTypeId}?name=${inputValue}`,
          isProviderOneLogin
        )
          .then((response: any) => {
            if (response?.data && response?.status === 200) {
              const { records } = response?.data;
              const orgAccountsList = [{ label: startCase(SELECT), value: '' }];
              orgAccountsList.push(
                ...records.map((record: any) => ({ label: record.name, value: record.id }))
              );
              callback(orgAccountsList);
              this.props.setOrgAccountsListData(orgAccountsList);
            }
          })
          .catch(async (error: any) => {
            let errorMessage: string =
              error?.response?.data?.message || error?.response?.data?.Message || '';
            if ([404, 422].includes(error?.response?.status) && !isEmpty(errorMessage)) {
              await NotificationManager.error(errorMessage);
              this.props.setDuplicateLeadIdErrorMessage(errorMessage);
            } else {
              this.props.errorHandler(error);
            }
            callback([]);
          });
      } else {
        callback([]);
      }
    } else {
      callback([]);
    }

    this.props.setLeadTypeListLoader(false);
  };

  debouncedOrgListLoader = debounce(this.loadOrgAccountOptions, 500);
  onChangeOrgAccount = (orgAccountId: any) => {
    this.setState(prevState => ({ crm: { ...prevState.crm, orgAccountId } }));
    this.props.storeOrganizationData({
      ...this.props.editOrganizationData,
      crm_account_name: orgAccountId.label,
    });
  };

  DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <svg
        className='MuiSvgIcon-root MuiNativeSelect-icon'
        focusable='false'
        viewBox='0 0 24 24'
        aria-hidden='true'
      >
        <path d='M7 10l5 5 5-5z'></path>
      </svg>{' '}
    </components.DropdownIndicator>
  );

  render() {
    const {
      addEditOrgLoader,
      leadTypeList = [],
      leadTypeListLoader,
      organizations,
      orgAccountsList = [],
      accountRecordTypeList = [],
    } = this.props;
    const { crm, crmErrors } = this.state;
    const {
      accountRecordTypeId,
      allowAddingAddProduct,
      iframeEditingEnabled,
      leadRecordTypeId,
      orgAccountId,
      salesForceEnabled,
    } = crm;
    const { leadTypeIdError, orgAccountIdError } = crmErrors;

    return (
      <>
        <CommonModal
          isOpen={
            !!organizations?.leadRecordTypeAllowUpdateDetails?.isOpenAllowUpdateLeadRecordType
          }
          onClose={() => this.onCloseConfirmationModal()}
          navButtons
          maxWidth={XS}
          onSave={() => this.onSaveCrmFormData()}
          loader={addEditOrgLoader}
          actionButtonLabel={CONTINUE}
          children={
            !!organizations?.leadRecordTypeAllowUpdateDetails?.message
              ? `${organizations?.leadRecordTypeAllowUpdateDetails?.message}. ${CONFIRMATION_MESSAGE}`
              : ''
          }
          cancelButtonLabel={CANCEL}
        />
        <Modal
          isOpen={true}
          onClose={this.props.closeModal}
          title={SFDC_KEY?.SFDC}
          navButtons={true}
          maxWidth={SM}
          onSave={this.onSaveCrmFormData}
          loader={!!organizations?.addEditOrgLoader || leadTypeListLoader ? true : false}
        >
          <div className='row crm-dialog-wraper sfdc-wrap'>
            <div className='col-lg-12 mb-3'>
              <Grid container spacing={2} className='d-flex align-items-center'>
                <Grid item xs={6} lg={6} className='form-item'>
                  <InputLabel className='form-item__label'>
                    {SFDC_KEY.ENABLE_SFDC_INTEGRATION_LABEL}:
                  </InputLabel>
                </Grid>
                <Grid item xs={4} lg={6}>
                  <span className='p-0 switch-margin-left'>
                    <Switch
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      value={!!salesForceEnabled ? true : false}
                      checked={!!salesForceEnabled ? true : false}
                      onChange={(event: any) => this.onCheckBoxChange(event)}
                      name={SFDC_KEY.SALES_FORCE_ENABLED_KEY}
                      disabled={leadTypeListLoader || addEditOrgLoader}
                    />
                  </span>
                </Grid>
              </Grid>
            </div>
            <div className='col-lg-12 mb-3'>
              <Grid container spacing={2} className='d-flex align-items-center'>
                <Grid item xs={6} lg={6} className='form-item'>
                  <InputLabel className='form-item__label'>
                    {SFDC_KEY.ALLOW_EDITING_QUOTES_LABEL}:
                  </InputLabel>
                </Grid>
                <Grid item xs={4} lg={6}>
                  <span className='p-0 switch-margin-left'>
                    <Switch
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      value={!!iframeEditingEnabled}
                      checked={!!iframeEditingEnabled}
                      onChange={(event: any) => this.onCheckBoxChange(event)}
                      name={SFDC_KEY.ALLOW_EDITING_QUOTES_KEY}
                      disabled={leadTypeListLoader || addEditOrgLoader}
                    />
                  </span>
                </Grid>
              </Grid>
            </div>
            <div className='col-lg-12 mb-3'>
              <Grid container spacing={2} className='d-flex align-items-center'>
                <Grid item xs={6} lg={6} className='form-item'>
                  <InputLabel className='form-item__label'>
                    {SFDC_KEY.ALLOW_ADDING_ADD_PRODUCT_LABEL}:
                  </InputLabel>
                </Grid>
                <Grid item xs={4} lg={6}>
                  <span className='p-0 switch-margin-left'>
                    <Switch
                      color='primary'
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      value={!!allowAddingAddProduct}
                      checked={!!allowAddingAddProduct}
                      onChange={(event: any) => this.onCheckBoxChange(event)}
                      name={SFDC_KEY.ALLOW_ADDING_ADD_PRODUCT_KEY}
                      disabled={leadTypeListLoader || addEditOrgLoader}
                    />
                  </span>
                </Grid>
              </Grid>
            </div>
            <div className='col-lg-12 mb-3'>
              <Grid container spacing={2} className='d-flex align-items-center'>
                <Grid item xs={6} lg={6} className='form-item lead-type-rec-label'>
                  <InputLabel
                    className='form-item__label d-flex'
                    htmlFor={SFDC_KEY.LEAD_TYPE_ID_KEY}
                    error={!isEmpty(leadTypeIdError) ? true : false}
                  >
                    <span className='padding-1'>
                      {SFDC_KEY.LEAD_RECORD_TYPE_LABEL}:
                      {!!salesForceEnabled ? (
                        <span className='small-star-error'>{ASTERISK}</span>
                      ) : (
                        ''
                      )}
                    </span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <SelectAutoComplete
                    className='native-select w-100'
                    value={leadRecordTypeId || ''}
                    name={SFDC_KEY.LEAD_TYPE_ID_KEY}
                    onChange={this.onCrmDataChange}
                    disabled={leadTypeListLoader || addEditOrgLoader || !crm?.salesForceEnabled}
                    error={!isEmpty(leadTypeIdError) ? true : false}
                    options={[{ name: startCase(SELECT), id: '' }, ...leadTypeList]}
                    getOptionLabel={(option: any) => option?.name}
                    getOptionValue={(option: any) => option?.id}
                  />
                  <FormHelperText className='text-red'>{leadTypeIdError}</FormHelperText>
                </Grid>
              </Grid>
            </div>
            <div className='col-lg-12 mb-3'>
              <Grid container spacing={2} className='d-flex align-items-center'>
                <Grid item xs={6} lg={6} className='form-item lead-type-rec-label'>
                  <InputLabel
                    className='form-item__label d-flex'
                    htmlFor={SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_KEY}
                  >
                    <span className='padding-1'>{SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_LABEL}:</span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <SelectAutoComplete
                    className='native-select w-100'
                    value={accountRecordTypeId || ''}
                    name={SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_KEY}
                    onChange={this.onCrmDataChange}
                    disabled={leadTypeListLoader || addEditOrgLoader || !crm?.salesForceEnabled}
                    options={[{ name: startCase(SELECT), id: '' }, ...accountRecordTypeList]}
                    getOptionLabel={(option: any) => option?.name}
                    getOptionValue={(option: any) => option?.id}
                  />
                </Grid>
              </Grid>
            </div>
            <div className='col-lg-12 mb-3'>
              <Grid container spacing={2} className='d-flex align-items-start minh-260'>
                <Grid item xs={6} lg={6} className='form-item lead-type-rec-label mt-3'>
                  <InputLabel
                    className='form-item__label d-flex'
                    htmlFor={SFDC_KEY.ORGANIZATION_ACCOUNT_ID_KEY}
                    error={!!orgAccountIdError ? true : false}
                  >
                    <span className='padding-1'>{SFDC_KEY.ORGANIZATION_ACCOUNT_ID_LABEL}:</span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} lg={6}>
                  <AsyncSelect
                    className='org-acc-select'
                    classNamePrefix='org-select'
                    components={{ DropdownIndicator: this.DropdownIndicator }}
                    loadOptions={this.debouncedOrgListLoader}
                    defaultOptions={[{ options: orgAccountsList }]}
                    onChange={this.onChangeOrgAccount}
                    value={orgAccountId}
                    noOptionsMessage={() => SFDC_ORG_ACCOUNT_INFO_MESSAGE}
                    isDisabled={!salesForceEnabled || leadTypeListLoader || addEditOrgLoader}
                  />
                  <FormHelperText className='text-red'>{orgAccountIdError}</FormHelperText>
                </Grid>
              </Grid>
            </div>
          </div>
          <FormHelperText className='text-red'>
            {organizations?.duplicateLeadIdErrorMessage}
          </FormHelperText>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ organizations, partner }: AppComponents.CRMComponentStore) => {
  const {
    addEditOrgLoader,
    editOrganizationData,
    leadTypeList,
    leadTypeListLoader,
    orgAccountsList,
    accountRecordTypeList,
  } = organizations;
  const { isProviderOneLogin } = partner;
  return {
    addEditOrgLoader,
    editOrganizationData,
    organizations,
    leadTypeList,
    leadTypeListLoader,
    orgAccountsList,
    accountRecordTypeList,
    isProviderOneLogin,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.CRMComponentDispatch => {
  return bindActionCreators(
    {
      fetchAccountRecordTypeList,
      fetchLeadTypeListData,
      setAllowUpdateLeadRecordType,
      setDuplicateLeadIdErrorMessage,
      setOrgUpdatedResponseData,
      updateOrganizationData,
      setOrgAccountsListData,
      errorHandler,
      setLeadTypeListLoader,
      storeOrganizationData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMComponent);
