import { AxiosError } from 'axios';
import { EMPTY_LOB_ERROR_MESSAGE } from '../../../../constants';
import { api, OrganizationQuery, OrganizationQueryVariables } from '../../../../graphql/generated';
import {
  errorHandler,
  setConfigLoader,
  setHeapDetailsLoaded,
  setHomeCode,
  setHomeState,
  setOrgConfigLoader,
  setPconfig,
  storeEmptyLobErrorMessage,
} from '../../../../pages/redux/actions';
import { ReduxDispatch } from '../../../../pages/redux/store';
import { getHeapIdentity } from '../../../../utils';
import { APILifeCycle } from '../types';
import { ratePageTypes } from '../../../utils';
import { mapOrganizationLOB } from './mapper';

const setHeapProjectIdFromOrg = (
  organization: OrganizationQuery['organization'],
  dispatch: ReduxDispatch
) => {
  const isHeapAnalyticsEnabled = organization.heapAnalyticsEnabled;
  const heapProjectId = organization.heapProjectId ?? '';
  let heapId = getHeapIdentity();
  try {
    if (isHeapAnalyticsEnabled && heapProjectId) {
      dispatch(setHeapDetailsLoaded(true));
      window.heap.load(heapProjectId, { secureCookie: true });
      window.heap.identify(heapId);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

const onOrganizationQuery = async (
  request: OrganizationQueryVariables,
  { queryFulfilled, dispatch }: APILifeCycle<OrganizationQuery>
) => {
  dispatch(setOrgConfigLoader(true));
  dispatch(storeEmptyLobErrorMessage(''));
  try {
    const {
      data: { organization },
    } = await queryFulfilled;
    if (organization) {
      const daleConfig = {
        auth_token: organization?.authToken ?? '',
        code: organization.code ?? '',
        is_verisk_prefill_available: organization.isVeriskPrefillAvailable,
        branding_on_dale_details: {
          template_name: organization.templateName ?? '',
          logo_url: organization.logoUrl ?? '',
        },
        id: organization.id,
        name: organization.name,
        adhoc_bind_enabled: organization.adhocBindEnabled ?? false,
        hippo_branding_on_dale: {
          logo_url: organization.logoUrl ?? '',
          template_name: organization.templateName ?? '',
        },
        lob: mapOrganizationLOB(organization?.lob)  ?? {},
        ams_enabled: organization?.amsEnabled ?? false,
        ams_for_gator_enabled: organization?.amsForGatorEnabled ?? false,
        ams_for_salesforce_enabled: organization?.amsForSalesforceEnabled ?? false, 
        carrier_timeout_message_enabled: organization?.carrierTimeoutMessageEnabled ?? false,
        check_agent_license_compliance: '',
        crm_allow_add_products: organization?.crmAllowAddProducts ?? false,
        display_errors_on_ui: organization?.displayErrorsOnUi ?? false,
        enable_auto_quote_doc_download: organization?.enableAutoQuoteDocDownload ?? false,
        enable_home_quote_doc_download: organization?.enableHomeQuoteDocDownload ?? false,
        feedback_enabled: organization?.feedbackEnabled ?? false,
        heap_analytics_enabled: organization?.heapAnalyticsEnabled ?? false,
        heap_project_id: organization?.heapProjectId ?? '',
        hippo_only: false,
        iframe_editing_enabled: true,
        rater_page_type: ratePageTypes.navigation,
      };
      dispatch(
        setHomeState({
          pconfig: {
            id: organization.id,
            name: organization.name,
            dale_config: daleConfig,
          },
        })
      );
      dispatch(setHomeCode(organization.code ?? ''));
      dispatch(
        setPconfig({
          pconfig: {
            pconfig: daleConfig,
          },
        })
      );
    }
    setHeapProjectIdFromOrg(organization, dispatch);
  } catch (error) {
    const axiosError: any = error as AxiosError<any>;
    if (!!axiosError?.response && axiosError?.response?.status === 404) {
      dispatch(storeEmptyLobErrorMessage(EMPTY_LOB_ERROR_MESSAGE));
    } else {
      dispatch(errorHandler(axiosError));
    }
  }
  dispatch(setOrgConfigLoader(false));
  dispatch(setConfigLoader(false));
};

const organizationApi = api.enhanceEndpoints({
  endpoints: {
    organization: {
      onQueryStarted: onOrganizationQuery,
    },
  },
  addTagTypes: ['organization'],
});

export const { useOrganizationQuery, useLazyOrganizationQuery } = organizationApi;
