/* istanbul ignore file */
import * as LifeActionType from '../constants';

export const setLifeQuoteDetails = (payload: any): AppRedux.Action => ({
  type: LifeActionType.SET_LIFE_QUOTES_DETAILS,
  payload,
});

export const fetchLifeQuotes = (): AppRedux.Action => ({
  type: LifeActionType.FETCH_LIFE_QUOTES,
});

export const lifeQuotesLoader = (payload: any): AppRedux.Action => ({
  type: LifeActionType.LIFE_DETAILS_LOADER,
  payload,
});

export const storeLifeQuoteDetails = (payload: any): AppRedux.Action => ({
  type: LifeActionType.STORE_LIFE_QUOTE_LIST,
  payload,
});

export const clearLifeState = (): AppRedux.Action => ({
  type: LifeActionType.CLEAR_LIFE_STATE,
});

export const updateLifeQuoteDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: LifeActionType.UPDATE_LIFE_QUOTE_DETAILS,
  payload,
});

export const setLifePollingErrorDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: LifeActionType.SET_LIFE_POLLING_ERROR_DETAILS,
  payload,
});

export const initializeLifePolling = (): AppRedux.Action => ({
  type: LifeActionType.INIT_LIFE_POLLING,
});

export const lifeTimeoutPolling = (): AppRedux.Action => ({
  type: LifeActionType.LIFE_TIMEOUT_POLLING,
});

export const setLifePollingDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: LifeActionType.SET_LIFE_POLLING_DETAILS,
  payload,
});

export const stopLifePolling = (): AppRedux.Action => ({
  type: LifeActionType.STOP_LIFE_POLLING,
});

export const getBridgeToCarrierDetailsForLife = (payload: any): AppRedux.Action => ({
  type: LifeActionType.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_LIFE,
  payload,
});

export const lifeBridgeToCarrierLoader = (payload: any): AppRedux.Action => ({
  type: LifeActionType.LIFE_BRIDGE_TO_CARRIER_LOADER,
  payload,
});

export const refreshLifeQuotes = (): AppRedux.Action => ({
  type: LifeActionType.REFRESH_LIFE_QUOTES,
});
