import { DOMA_THEME, FIRST_CONNECT_THEME, HIPPO_THEME, PENNYMAC_THEME } from '../../../constants';

// FirstConnectTheme images
import homeInsuranceFirstConnectTheme from '../../../assets/images/home-fc.svg';
import autoIconFirstConnectTheme from '../../../assets/images/auto-fc.svg';
import floodIconFirstConnectTheme from '../../../assets/images/flood-insurance-fc.svg';
import homeIconFirstConnectTheme from '../../../assets/images/home-fc.svg';
import carIconFirstConnectTheme from '../../../assets/images/auto-fc.svg';
import userStepFirstConnectTheme from '../../../assets/images/gator/User-fc.svg';
import homeStepIconFirstConnectTheme from '../../../assets/images/gator/home-step-icon-fc.svg';
import floodStepIconFirstConnectTheme from '../../../assets/images/gator/flood-step-icon-fc.svg';
import carStepIconFirstConnectTheme from '../../../assets/images/gator/Car-step-icon-fc.svg';
import quotesStepIconFirstConnectTheme from '../../../assets/images/gator/quotes-step-icon-fc.svg';
import petsStepIconFirstConnectTheme from '../../../assets/images/gator/pets-step-info-fc.svg';
import driverStepIconFirstConnectTheme from '../../../assets/images/gator/driver-step-icon-fc.svg';
import policyStepIconFirstConnectTheme from '../../../assets/images/gator/policy-step-icon-fc.svg';
import ratesHomeFirstConnectTheme from '../../../assets/images/gator/rates-home.svg';
import homegatorFirstConnectTheme from '../../../assets/images/gator/home-fc-gator.svg';
import autogatorFirstConnectTheme from '../../../assets/images/gator/auto-fc-gator.svg';
import floodgatorFirstConnectTheme from '../../../assets/images/gator/flood-fc-gator.svg';
import creditcardBlack from '../../../assets/images/credit-card.svg';
import requestBindBlack from '../../../assets/images/req-binding.svg';
import bridgeblack from '../../../assets/images/bridge.svg';
import addDriverFirstConnectTheme from "../../../assets/images/sky-blue-add-driver.svg";
import addVehicleFirstConnectTheme from "../../../assets/images/sky-blue-add-vehicle.svg";
import quakeIconFirstConnectTheme from "../../../assets/images/earthquake-sky-blue.svg";
import petIconFirstConnectTheme from "../../../assets/images/pet-sky-blue-icon.svg";
import homeWarrantyIconFirstConnectTheme from "../../../assets/images/Home-warranty-blue.svg";
import lifeIconFirstConnectTheme from "../../../assets/images/Life-sky-blue.svg";
import cyberSecurityIconFirstConnectTheme from "../../../assets/images/cyber-sky-blue.svg";
import amsSuccessImageFirstConnectTheme from "../../../assets/images/ams-success-img-sky-blue.png";
import umbrellaFirstConnectTheme from "../../../assets/images/umbrella-sky-blue.svg";
import requestedBindBlack from "../../../assets/images/requested-bind.svg";

// HippoTheme images
import homeIconHippoTheme from '../../../assets/images/home.svg';
import carIconHippoTheme from '../../../assets/images/auto.svg';
import floodIconHippoTheme from '../../../assets/images/flood-green.svg';
import userStepHippoTheme from '../../../assets/images/gator/User.svg';
import homeStepIconHippoTheme from '../../../assets/images/gator/home-step-icon.svg';
import floodStepIconHippoTheme from '../../../assets/images/gator/flood-step-icon.svg';
import carStepIconHippoTheme from '../../../assets/images/gator/Car-step-icon.svg';
import quotesStepIconHippoTheme from '../../../assets/images/gator/quotes-step-icon.svg';
import petsStepIconHippoTheme from '../../../assets/images/gator/pets-step-info.svg';
import driverStepIconHippoTheme from '../../../assets/images/gator/driver-step-icon.svg';
import policyStepIconHippoTheme from '../../../assets/images/gator/policy-step-icon.svg';
import ratesHomeHippoTheme from '../../../assets/images/gator/rates-home-hippo.svg';
import homegatorHippoTheme from '../../../assets/images/gator/home-hippo-gator.svg';
import autogatorHippoTheme from '../../../assets/images/gator/auto-hippo-gator.svg';
import floodgatorHippoTheme from '../../../assets/images/flood-green.svg';
import creditcardBlackGreen from '../../../assets/images/credit-card-black-green.svg';
import bridgeblackGreen from '../../../assets/images/bridge-black-green.svg';
import addDriverHippoTheme from "../../../assets/images/green-add-driver.svg";
import addVehicleHippoTheme from "../../../assets/images/green-add-vehicle.svg";
import quakeIconHippoTheme from "../../../assets/images/quakeIcon-green.svg";
import petIconHippoTheme from "../../../assets/images/pet-green-icon.svg";
import homeWarrantyIconHippoTheme from "../../../assets/images/home-warranty.svg";
import lifeIconHippoTheme from "../../../assets/images/Life.svg";
import cyberSecurityIconHippoTheme from "../../../assets/images/Cyber.svg";
import amsSuccessImageHippoTheme from "../../../assets/images/ams-success-img.png";
import umbrellaIconHippoTheme from "../../../assets/images/umbrella-green.svg";

// white icon
import userIconWhite from '../../../assets/images/gator/User-white.svg';
import homeStepIconWhite from '../../../assets/images/gator/home-step-icon-white.svg';
import floodStepIconWhite from '../../../assets/images/gator/flood-step-icon-white.svg';
import carStepIconWhite from '../../../assets/images/gator/Car-step-icon-white.svg';
import driverStepIconWhite from '../../../assets/images/gator/driver-step-icon-white.svg';
import policyStepIconWhite from '../../../assets/images/gator/policy-step-icon-white.svg';
import quotesStepIconWhite from '../../../assets/images/gator/quotes-step-icon-white.svg';

// FIRSTCONNECT LOADER
import FChomeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-FC-Home.gif';
import FCfloodLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-FC-Flood.gif';
import FCautoLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-FC-Auto-Driver.gif';
import FCquakeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-FC-Earthquake.gif';
import FCpetLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-FC-Pet.gif';
import FChomeWarrantyLoader from '../../../assets/images/loader/Home-warranty-sky-blue.gif';
import FClifeLoader from '../../../assets/images/loader/life-loader-sky-blue.gif';
import FCcyberSecurityLoader from '../../../assets/images/loader/cyber-security-sky-blue.gif';
import firstconnectCombinedLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-FC-Combined.gif';
import firstconnectLaunchLoader from '../../../assets/images/loader/preloader-Big-FC-withText-combined.gif';
import FCfloodInfoLoader from '../../../assets/images/loader/preloader-Big-FC-withText-Flood.gif';
import FCpetInfoLoader from '../../../assets/images/loader/preloader-Big-FC-withText-Pet.gif';
import FCAMSSuccessAnimation from '../../../assets/images/ams-success-sky-blue.gif';
import FCUmbrellaLoader from '../../../assets/images/loader/umbrella-loader-sky-blue.gif';
import FCPropertyInfoLoader from '../../../assets/images/loader/prefill-gif-sky-blue.gif';

// PENNYMAC LOADER
import PMhomeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-PennyMac-Home.gif';
import PMfloodLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-PennyMac-Flood.gif';
import PMautoLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-PennyMac-Auto-Driver.gif';
import PMquakeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-PennyMac-Earthquake.gif';
import PMpetLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-PennyMac-Pet.gif';
import pennymacCombinedLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-PennyMac-Combined.gif';
import pennymacLaunchLoader from '../../../assets/images/loader/preloader-Big-PennyMac-withText-combined.gif';
import PMfloodInfoLoader from '../../../assets/images/loader/preloader-Big-PennyMac-withText-Flood.gif';
import addDriverPennymacTheme from "../../../assets/images/navy-blue-add-driver-btn.svg";
import addVehiclePennymacTheme from "../../../assets/images/navy-blue-add-vehicle-btn.svg";
import FMpetInfoLoader from '../../../assets/images/loader/preloader-Big-PennyMac-withText-Pet.gif';
import PMAMSSuccessAnimation from '../../../assets/images/ams-success-navy-blue.gif';
import PMhomeWarrantyLoader from '../../../assets/images/loader/Home-warranty-navy-blue.gif';
import PMlifeLoader from '../../../assets/images/loader/life-loader-navy-blue.gif';
import PMcyberSecurityLoader from '../../../assets/images/loader/cyber-security-navy-blue.gif';
import PMUmbrellaLoader from '../../../assets/images/loader/umbrella-loader-navy-blue.gif';
import PMPropertyInfoLoader from '../../../assets/images/loader/prefill-gif-navy-blue.gif';

//pennymac image
import homeInsurancePennymacTheme from '../../../assets/images/homeinsurancepennymac.svg';
import autoIconPennymacTheme from '../../../assets/images/carinsurancepennymac.svg';
import floodIconPennymacTheme from '../../../assets/images/floodinsurencepennymac.svg';
import quakeIconPennymacTheme from "../../../assets/images/earthquake-navy-blue.svg";
import petIconPennymacTheme from "../../../assets/images/pet-navy-blue-icon.svg";
import lifeIconPennymacTheme from "../../../assets/images/Life-navy-blue.svg";
import cyberSecurityIconPennymacTheme from "../../../assets/images/Cyber-navy-blue.svg";
import amsSuccessImagePennymacTheme from "../../../assets/images/ams-success-img-navy-blue.png";
import homewarrantyIcoPennymacTheme from "../../../assets/images/homeinsurancepennymac.svg";
import umbrellaIconPennymacTheme from "../../../assets/images/umbrella-navy-blue.svg";

//Doma Images
import homeInsuranceDomaTheme from '../../../assets/images/homeinsurancedoma.svg';
import autoIconDomaTheme from '../../../assets/images/carinsurancedoma.svg';
import floodIconDomaTheme from '../../../assets/images/floodinsurencedoma.svg';
import quakeIconDomaTheme from "../../../assets/images/earthquake-purplish-blue.svg";
import petIconDomaTheme from "../../../assets/images/pet-purple-icon.svg";
import homewarrantyIconDomaTheme from "../../../assets/images/HW-purplish-blue.svg";
import lifeIconDomaTheme from "../../../assets/images/Life-purplish-blue.svg";
import cyberSecurityIconDomaTheme from "../../../assets/images/Cyber-purplish-blue.svg";
import amsSuccessImageDomaTheme from "../../../assets/images/ams-success-img-purplish-blue.png";
import umbrellaIconDomaTheme from "../../../assets/images/umbrella-Purplish-blue.svg";

//Doma loaders
import DMhomeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-Doma-Home.gif';
import DMfloodLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-Doma-Flood.gif';
import DMautoLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-Doma-Auto-Driver.gif';
import DMquakeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-Doma-Earthquake.gif';
import DMpetLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-Doma-Pet.gif';
import DMfloodInfoLoader from '../../../assets/images/loader/gif-small/preloader-Big-DOMA-withText-Flood.gif';
import domaCombinedLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-Doma-Combined.gif';
import DMpetInfoLoader from '../../../assets/images/loader/preloader-Big-DOMA-withText-Pet.gif';
import DMAMSSuccessAnimation from '../../../assets/images/ams-success-purplish-blue.gif';
import DMhomeWarrantyLoader from '../../../assets/images/loader/Home-warranty-purplish-blue.gif';
import DMlifeLoader from '../../../assets/images/loader/life-loader-purplish-blue.gif';
import DMcyberSecurityLoader from '../../../assets/images/loader/cyber-security-purplish-blue.gif';
import DMUmbrellaLoader from '../../../assets/images/loader/umbrella-loader-purplish-blue.gif';
import DMPropertyInfoLoader from '../../../assets/images/loader/prefill-gif-purplish-blue.gif';

// HIPPO LOADER
import HIPhomeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-HIPPO-Home.gif';
import HIPfloodLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-HIPPO-Flood.gif';
import HIPautoLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-HIPPO-Auto-Driver.gif';
import HIPquakeLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-HIPPO-Earthquake.gif';
import HIPpetLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-HIPPO-Pet.gif';
import hippoCombinedLoader from '../../../assets/images/loader/gif-small/preloader-SMALL-HIPPO-Combined.gif';
import hippoLaunchLoader from '../../../assets/images/loader/preloader-Big-HIPPO-withText-combined.gif';
import HIPfloodInfoLoader from '../../../assets/images/loader/preloader-Big-HIPPO-withText-Flood.gif';
import HIPpetInfoLoader from '../../../assets/images/loader/preloader-Big-HIPPO-withText-Pet.gif';
import HIPAMSSuccessAnimation from '../../../assets/images/ams-success.gif';
import HIPhomeWarrantyLoader from '../../../assets/images/loader/Home-warranty-hippo-green.gif';
import HIPlifeLoader from '../../../assets/images/loader/life-loader-green.gif';
import HIPcyberSecurityLoader from '../../../assets/images/loader/cyber-security-green.gif';
import HIPUmbrellaLoader from '../../../assets/images/loader/umbrella-loader-green.gif';
import HIPPropertyInfoLoader from '../../../assets/images/loader/prefill-gif-hippo-green.gif';

const ThemesImages: any = {
  [FIRST_CONNECT_THEME]: {
    homeInsuranceIcon: homeInsuranceFirstConnectTheme,
    autoIcon: autoIconFirstConnectTheme,
    floodIcon: floodIconFirstConnectTheme,
    homeIcon: homeIconFirstConnectTheme,
    quakeIcon: quakeIconFirstConnectTheme,
    petIcon: petIconFirstConnectTheme,
    homewarrantyIcon: homeWarrantyIconFirstConnectTheme,
    lifeIcon: lifeIconFirstConnectTheme,
    cybersecurityIcon: cyberSecurityIconFirstConnectTheme,
    carIcon: carIconFirstConnectTheme,
    RatesHome: ratesHomeFirstConnectTheme,
    homegatorIcon: homegatorFirstConnectTheme,
    autogatorIcon: autogatorFirstConnectTheme,
    floodgatorIcon: floodgatorFirstConnectTheme,
    userStepIcon: userStepFirstConnectTheme,
    homeStepIcon: homeStepIconFirstConnectTheme,
    floodStepIcon: floodStepIconFirstConnectTheme,
    carStepIcon: carStepIconFirstConnectTheme,
    quotesStepIcon: quotesStepIconFirstConnectTheme,
    petsStepIcon: petsStepIconFirstConnectTheme,
    driverStepIcon: driverStepIconFirstConnectTheme,
    policyStepIcon: policyStepIconFirstConnectTheme,
    userStepIconWhite: userIconWhite,
    homeStepIconWhite: homeStepIconWhite,
    floodStepIconWhite: floodStepIconWhite,
    carStepIconWhite: carStepIconWhite,
    driverStepIconWhite: driverStepIconWhite,
    policyStepIconWhite: policyStepIconWhite,
    quotesStepIconWhite: quotesStepIconWhite,
    creditCard: creditcardBlack,
    requestBindIcon: requestBindBlack,
    requestedBindIcon: requestedBindBlack,
    bridgeIcon: bridgeblack,
    stepWhiteIcon: userIconWhite,
    homeLoader: FChomeLoader,
    floodLoader: FCfloodLoader,
    autoLoader: FCautoLoader,
    quakeLoader: FCquakeLoader,
    petLoader: FCpetLoader,
    homeWarrantyLoader: FChomeWarrantyLoader,
    lifeLoader: FClifeLoader,
    cyberSecurityLoader: FCcyberSecurityLoader,
    combinedLoader: firstconnectCombinedLoader,
    launchingLoader: firstconnectLaunchLoader,
    floodInfoLoader: FCfloodInfoLoader,
    petInfoLoader: FCpetInfoLoader,
    AddDriver:addDriverFirstConnectTheme,
    AddVehicle:addVehicleFirstConnectTheme,
    amsSuccess: FCAMSSuccessAnimation,
    amsSuccessImage: amsSuccessImageFirstConnectTheme,
    umbrellaIcon: umbrellaFirstConnectTheme,
    umbrellaLoader: FCUmbrellaLoader,
    propertyInfoLoader: FCPropertyInfoLoader,
  },
  [PENNYMAC_THEME]: {
    homeInsuranceIcon: homeInsurancePennymacTheme,
    autoIcon: autoIconPennymacTheme,
    floodIcon: floodIconPennymacTheme,
    homeIcon: homeInsurancePennymacTheme,
    carIcon: carIconFirstConnectTheme,
    quakeIcon: quakeIconPennymacTheme,
    petIcon: petIconPennymacTheme,
    homewarrantyIcon: homewarrantyIcoPennymacTheme,
    lifeIcon: lifeIconPennymacTheme,
    cybersecurityIcon: cyberSecurityIconPennymacTheme,
    RatesHome: ratesHomeFirstConnectTheme,
    homegatorIcon: homegatorFirstConnectTheme,
    autogatorIcon: autogatorFirstConnectTheme,
    floodgatorIcon: floodgatorFirstConnectTheme,
    userStepIcon: userStepFirstConnectTheme,
    homeStepIcon: homeStepIconFirstConnectTheme,
    floodStepIcon: floodStepIconFirstConnectTheme,
    carStepIcon: carStepIconFirstConnectTheme,
    quotesStepIcon: quotesStepIconFirstConnectTheme,
    petsStepIcon: petsStepIconFirstConnectTheme,
    driverStepIcon: driverStepIconFirstConnectTheme,
    policyStepIcon: policyStepIconFirstConnectTheme,
    userStepIconWhite: userIconWhite,
    homeStepIconWhite: homeStepIconWhite,
    floodStepIconWhite: floodStepIconWhite,
    carStepIconWhite: carStepIconWhite,
    driverStepIconWhite: driverStepIconWhite,
    policyStepIconWhite: policyStepIconWhite,
    quotesStepIconWhite: quotesStepIconWhite,
    creditCard: creditcardBlack,
    requestBindIcon: requestBindBlack,
    requestedBindIcon: requestedBindBlack,
    bridgeIcon: bridgeblack,
    stepWhiteIcon: userIconWhite,
    homeLoader: PMhomeLoader,
    floodLoader: PMfloodLoader,
    autoLoader: PMautoLoader,
    quakeLoader: PMquakeLoader,
    petLoader: PMpetLoader,
    homeWarrantyLoader: PMhomeWarrantyLoader,
    lifeLoader: PMlifeLoader,
    cyberSecurityLoader: PMcyberSecurityLoader,
    combinedLoader: pennymacCombinedLoader,
    launchingLoader: pennymacLaunchLoader,
    floodInfoLoader: PMfloodInfoLoader,
    petInfoLoader: FMpetInfoLoader,
    AddDriver:addDriverPennymacTheme,
    AddVehicle:addVehiclePennymacTheme,
    amsSuccess: PMAMSSuccessAnimation,
    amsSuccessImage: amsSuccessImagePennymacTheme,
    umbrellaIcon: umbrellaIconPennymacTheme,
    umbrellaLoader: PMUmbrellaLoader,
    propertyInfoLoader: PMPropertyInfoLoader,
  },
  [HIPPO_THEME]: {
    homeInsuranceIcon: homeIconHippoTheme,
    autoIcon: carIconHippoTheme,
    floodIcon: floodIconHippoTheme,
    homeIcon: homeIconHippoTheme,
    carIcon: carIconHippoTheme,
    quakeIcon: quakeIconHippoTheme,
    petIcon: petIconHippoTheme,
    homewarrantyIcon: homeWarrantyIconHippoTheme,
    lifeIcon: lifeIconHippoTheme,
    cybersecurityIcon: cyberSecurityIconHippoTheme,
    userStepIcon: userStepHippoTheme,
    homeStepIcon: homeStepIconHippoTheme,
    floodStepIcon: floodStepIconHippoTheme,
    carStepIcon: carStepIconHippoTheme,
    quotesStepIcon: quotesStepIconHippoTheme,
    petsStepIcon: petsStepIconHippoTheme,
    driverStepIcon: driverStepIconHippoTheme,
    policyStepIcon: policyStepIconHippoTheme,
    RatesHome: ratesHomeHippoTheme,
    homegatorIcon: homegatorHippoTheme,
    autogatorIcon: autogatorHippoTheme,
    floodgatorIcon: floodgatorHippoTheme,
    creditCard: creditcardBlackGreen,
    requestBindIcon: requestBindBlack,
    requestedBindIcon: requestedBindBlack,
    bridgeIcon: bridgeblackGreen,
    stepWhiteIcon:userStepHippoTheme,
    userStepIconWhite: userStepHippoTheme,
    homeLoader: HIPhomeLoader,
    floodLoader: HIPfloodLoader,
    autoLoader: HIPautoLoader,
    quakeLoader: HIPquakeLoader,
    petLoader: HIPpetLoader,
    homeWarrantyLoader: HIPhomeWarrantyLoader,
    lifeLoader: HIPlifeLoader,
    cyberSecurityLoader: HIPcyberSecurityLoader,
    combinedLoader: hippoCombinedLoader,
    launchingLoader: hippoLaunchLoader,
    floodInfoLoader: HIPfloodInfoLoader,
    petInfoLoader: HIPpetInfoLoader,
    AddDriver:addDriverHippoTheme,
    AddVehicle:addVehicleHippoTheme,
    amsSuccess: HIPAMSSuccessAnimation,
    amsSuccessImage: amsSuccessImageHippoTheme,
    umbrellaIcon: umbrellaIconHippoTheme,
    umbrellaLoader: HIPUmbrellaLoader,
    propertyInfoLoader: HIPPropertyInfoLoader,
  },
  [DOMA_THEME]: {
    homeInsuranceIcon: homeInsuranceDomaTheme,
    autoIcon: autoIconDomaTheme,
    floodIcon: floodIconDomaTheme,
    homeIcon: homeInsuranceDomaTheme,
    quakeIcon: quakeIconDomaTheme,
    petIcon: petIconDomaTheme,
    homewarrantyIcon: homewarrantyIconDomaTheme,
    lifeIcon: lifeIconDomaTheme,
    cybersecurityIcon: cyberSecurityIconDomaTheme,
    carIcon: carIconFirstConnectTheme,
    RatesHome: ratesHomeFirstConnectTheme,
    homegatorIcon: homegatorFirstConnectTheme,
    autogatorIcon: autogatorFirstConnectTheme,
    floodgatorIcon: floodgatorFirstConnectTheme,
    userStepIcon: userStepFirstConnectTheme,
    homeStepIcon: homeStepIconFirstConnectTheme,
    floodStepIcon: floodStepIconFirstConnectTheme,
    carStepIcon: carStepIconFirstConnectTheme,
    quotesStepIcon: quotesStepIconFirstConnectTheme,
    petsStepIcon: petsStepIconFirstConnectTheme,
    driverStepIcon: driverStepIconFirstConnectTheme,
    policyStepIcon: policyStepIconFirstConnectTheme,
    userStepIconWhite: userIconWhite,
    homeStepIconWhite: homeStepIconWhite,
    floodStepIconWhite: floodStepIconWhite,
    carStepIconWhite: carStepIconWhite,
    driverStepIconWhite: driverStepIconWhite,
    policyStepIconWhite: policyStepIconWhite,
    quotesStepIconWhite: quotesStepIconWhite,
    creditCard: creditcardBlack,
    requestBindIcon: requestBindBlack,
    requestedBindIcon: requestedBindBlack,
    bridgeIcon: bridgeblack,
    stepWhiteIcon: userIconWhite,
    homeLoader: DMhomeLoader,
    floodLoader: DMfloodLoader,
    autoLoader: DMautoLoader,
    quakeLoader: DMquakeLoader,
    petLoader: DMpetLoader,
    homeWarrantyLoader: DMhomeWarrantyLoader,
    lifeLoader: DMlifeLoader,
    cyberSecurityLoader: DMcyberSecurityLoader,
    combinedLoader: domaCombinedLoader,
    launchingLoader: pennymacLaunchLoader,
    floodInfoLoader: DMfloodInfoLoader,
    petInfoLoader: DMpetInfoLoader,
    AddDriver:addDriverPennymacTheme,
    AddVehicle:addVehiclePennymacTheme,
    amsSuccess: DMAMSSuccessAnimation,
    amsSuccessImage: amsSuccessImageDomaTheme,
    umbrellaIcon: umbrellaIconDomaTheme,
    umbrellaLoader: DMUmbrellaLoader,
    propertyInfoLoader: DMPropertyInfoLoader,
  },
};

export default ThemesImages;
