import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';
const useStyles = makeStyles(() => ({
  skeletonText: {
    marginTop: '0.8em',
    borderRadius: '4px',
    marginBottom: '0.8em',
    transform: 'none',
  },
}));

const CardSkeleton = () => {
  const classes = useStyles();
  return (
    <Card elevation={0}>
      <CardContent className='pb-16'>
        <div className='card-skeleton'>
          <Skeleton classes={{ text: classes.skeletonText }} height={49} width='100%' />
          <Skeleton classes={{ text: classes.skeletonText }} height={20} width='100%' />
          <Skeleton classes={{ text: classes.skeletonText }} height={10} width='100%' />
          <Skeleton classes={{ text: classes.skeletonText }} height={10} width='100%' />
          <Skeleton classes={{ text: classes.skeletonText }} height={10} width='90%' />
          <Skeleton classes={{ text: classes.skeletonText }} height={6} width='80%' />
        </div>
      </CardContent>
    </Card>
  );
};
export default CardSkeleton;
