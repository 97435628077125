export const parseToObject = <T = Record<string, unknown>>(
  value: string | object | undefined,
  defaultValue: T
): T => {
  if (value === undefined) {
    return defaultValue;
  }

  return typeof value === 'object'? value : JSON.parse(value);
};
