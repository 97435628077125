import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, omit } from 'lodash';
import ThemesImages from '../themes-images';
import Template from './template';
import { FormDataType, useFormHandler } from '../../custom-hooks';
import { EMPTY, ERROR_MESSAGE } from '../../custom-hooks/useFormHandler/utils';
import {
  fetchHomeQuoteDetails,
  savePropertyInfoDetails,
  setApplicantDetails,
  setFloodDetails,
  setHomeState,
  setIsUpdatedPropertyInfo,
  setLineOfBusiness,
  setPrefillDetails,
  setQuotesLoaded,
  setUserPropertyInformation,
  updateApplicantDetails,
} from '../../redux/actions';
import { PrefillDetails } from '../../redux/reducers/applicant';
import {
  clearSelectedQuoteOnPropertyInfoEdit,
  disabledApplicantFormField,
  formatDate,
  getAppointmentList,
  getBasementFinishTypeByBasementFinishPercentage,
  getDefaultOptionFromOptions,
  getIsLobIncludesInAppointments,
  getIsSubmitPropertyInfoForLob,
  getPropertyInfoFields,
  getQuoteListOfLob,
  getSelectedOptionFromDropDownList,
  getThemeType,
  getUserPropertyInfoPayload,
  integerRegex,
  isIncludesPropertyCoverage,
  isProdcuctTypeHO6,
  mappedResponseToStoreForPropertyDetails,
  parseSelectedValueToOption,
  parseValueToPropertyData,
  positiveIntegerRegex,
  removePropertyInfoLabelFromKeys,
  validateBasementFinishPercentage,
  validateYearBuiltAndRoofConstructed,
} from '../../../utils';
import { InputFieldDetails, PropertyInfoPayload } from './type';
import {
  BASEMENT,
  DEFAULT_ROOT_SHAPE,
  FALSE,
  FIFTY,
  FLAT_ROOF,
  HUNDRED,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS_MAPPING,
  LOB_PRODUCTS_MAPPING,
  NO,
  PROPERTY_INFO,
  PROPERTY_INFO_DEFAULT_DETAILS,
  UNFINISHED,
  YEAR,
  YES,
  ZERO,
} from '../../../constants';
import moment from 'moment';
import { BasementFinishType } from '../../../types';
const { COVERAGE_DETAILS, PROPERTY_INFO_DETAILS } = PROPERTY_INFO;
const {
  BASEMENT_FINISH_TYPE,
  BASEMENT_FINISH_PERCENTAGE,
  DISTANCE_TO_FIRE_STATION_IN_MILES,
  FOUNDATION_TYPE,
  NO_OF_FAMILY_UNIT,
  PURCHASE_DATE,
  ROOF_CONSTRUCTED_OR_REPLACED,
  ROOF_SHAPE,
  ROOF_TYPE,
  SQUARE_FOOTAGE,
  SWIMMING_POOL,
  SWIMMING_POOL_FENCED,
  UPDATED_ELECTRIC,
  UPDATED_HEATING,
  UPDATED_PLUMBING,
  WITH_IN_CITY_LIMITS,
} = PROPERTY_INFO_DETAILS.INPUT_FIELDS;
const {
  DWELLING_LIMIT_COV_A,
  LOSS_OF_USE_LIMIT_OR_COV_D,
  MEDICAL_PAYMENTS_LIMIT_OR_COV_F,
  OTHER_STRUCTURE_LIMIT_OR_COV_B,
  PERSONAL_LIABILITY_LIMIT_OR_COV_E,
  PERSONAL_PROPERTY_LIMIT_OR_COV_C,
  RECONSTRUCTION_COST_ESTIMATOR,
  PREFERRED_DEDUCTIBLE,
  REPLACEMENT_COST,
  WATER_BACKUP_LIMIT,
} = COVERAGE_DETAILS.INPUT_FIELDS;

/** adjustments keys */
const keysForAdjustmentsObj = [
  WATER_BACKUP_LIMIT.KEY,
  DWELLING_LIMIT_COV_A.KEY,
  OTHER_STRUCTURE_LIMIT_OR_COV_B.KEY,
  PERSONAL_PROPERTY_LIMIT_OR_COV_C.KEY,
  LOSS_OF_USE_LIMIT_OR_COV_D.KEY,
  PERSONAL_LIABILITY_LIMIT_OR_COV_E.KEY,
  MEDICAL_PAYMENTS_LIMIT_OR_COV_F.KEY,
  REPLACEMENT_COST.KEY,
  PREFERRED_DEDUCTIBLE.KEY,
];

const isCoverageBOptional = (key: string): boolean =>
  isProdcuctTypeHO6() && key === OTHER_STRUCTURE_LIMIT_OR_COV_B.KEY;

const propertyInfoFieldList: InputFieldDetails[] = getPropertyInfoFields();

const getInitialPropertyInfoDetails = () => {
  const initialPropertyInfoDetails: Record<string, string> = {};
  const excludeDefaultValueList: Array<string> = [];
  propertyInfoFieldList.forEach((field: InputFieldDetails) => {
    const selectedOption = getSelectedOptionFromDropDownList(field?.OPTIONS || []);
    if (!!selectedOption && !excludeDefaultValueList.includes(field.KEY)) {
      initialPropertyInfoDetails[field.KEY] = `${selectedOption?.value}~${selectedOption?.label}`;
    } else initialPropertyInfoDetails[field.KEY] = EMPTY;
  });
  initialPropertyInfoDetails[PURCHASE_DATE.KEY] = formatDate(moment().subtract(1, YEAR).toString());
  return initialPropertyInfoDetails;
};

const getRequiredKeys = () => {
  const optionalKey = [
    BASEMENT_FINISH_PERCENTAGE.KEY,
    NO_OF_FAMILY_UNIT.KEY,
    PURCHASE_DATE.KEY,
    ROOF_CONSTRUCTED_OR_REPLACED.KEY,
    SWIMMING_POOL_FENCED.KEY,
    UPDATED_ELECTRIC.KEY,
    UPDATED_HEATING.KEY,
    UPDATED_PLUMBING.KEY,
    WITH_IN_CITY_LIMITS.KEY,
    RECONSTRUCTION_COST_ESTIMATOR.KEY,
    REPLACEMENT_COST.KEY,
  ];
  if (isProdcuctTypeHO6()) optionalKey.push(OTHER_STRUCTURE_LIMIT_OR_COV_B.KEY);
  return Object.keys(initialFormValues).filter(key => !optionalKey.includes(key));
};

const customValidator = (formData: FormDataType, errors: FormDataType) => {
  const keysForMaxIntegerValidate = [
    SQUARE_FOOTAGE.KEY,
    DWELLING_LIMIT_COV_A.KEY,
    OTHER_STRUCTURE_LIMIT_OR_COV_B.KEY,
    PERSONAL_PROPERTY_LIMIT_OR_COV_C.KEY,
    LOSS_OF_USE_LIMIT_OR_COV_D.KEY,
    PERSONAL_LIABILITY_LIMIT_OR_COV_E.KEY,
    MEDICAL_PAYMENTS_LIMIT_OR_COV_F.KEY,
  ];
  keysForMaxIntegerValidate.forEach(key => {
    const value = formData[key] || EMPTY;
    if (
      value &&
      (Number(value) > 10000000 ||
        (keysShouldAcceptPositiveInteger.includes(key) &&
          !isCoverageBOptional(key) &&
          !positiveIntegerRegex.test(value)))
    ) {
      errors[key] = ERROR_MESSAGE.INVALID;
    }
  });
  const basementFinishPercentageValue = formData[BASEMENT_FINISH_PERCENTAGE.KEY];
  if (
    parseSelectedValueToOption(formData.foundationType).value === BASEMENT &&
    parseSelectedValueToOption(formData.basementFinishType)?.value?.toLowerCase() !== UNFINISHED
  ) {
    errors[BASEMENT_FINISH_PERCENTAGE.KEY] = validateBasementFinishPercentage(
      basementFinishPercentageValue
    );
  }
  return validateYearBuiltAndRoofConstructed(formData, errors);
};

const keysShouldAcceptPositiveInteger: Array<string> = [
  DWELLING_LIMIT_COV_A.KEY,
  SQUARE_FOOTAGE.KEY,
  OTHER_STRUCTURE_LIMIT_OR_COV_B.KEY,
  PERSONAL_PROPERTY_LIMIT_OR_COV_C.KEY,
  LOSS_OF_USE_LIMIT_OR_COV_D.KEY,
];

const valueAcceptedForFormHandler = () => [
  ...keysShouldAcceptPositiveInteger.map(key => {
    if (isCoverageBOptional(key))
      return {
        regex: integerRegex,
        key: key,
      };
    return {
      regex: positiveIntegerRegex,
      key: key,
    };
  }),
  {
    regex: integerRegex,
    key: DISTANCE_TO_FIRE_STATION_IN_MILES.KEY,
  },
];

const selectFieldDetailsByKey = (key: string): InputFieldDetails =>
  propertyInfoFieldList.filter(fieldDetails => fieldDetails.KEY === key)[0];

const initialFormValues: FormDataType = getInitialPropertyInfoDetails();

/**Get updated values to set updated payload state*/
const getDefaultValueToSetUpdatedPayloadState = (prefillDetails?: PrefillDetails | null) => {
  const tempUpdatedPayload: PropertyInfoPayload = {
    propertyData: {},
    label: {},
  };
  Object.keys(initialFormValues).forEach((key: string) => {
    const tempValue = initialFormValues[key];
    if (!prefillDetails?.propertyData[key] && tempValue) {
      const { value, label } = parseSelectedValueToOption(tempValue);
      if (value) tempUpdatedPayload.propertyData[key] = value;
      if (label) tempUpdatedPayload.label[key] = label;
    }
  });
  return tempUpdatedPayload;
};

const PropertyInfo = (props: AppComponents.PropertyInfoProps) => {
  const {
    currentIndex,
    onPrevTab,
    goToLastTab,
    applicant,
    setPrefillDetails,
    setUserPropertyInformation,
    fetchHomeQuoteDetails,
    savePropertyInfoDetails,
    setFloodDetails,
    floodDetails,
    setIsUpdatedPropertyInfo,
    common,
    updateApplicantDetails,
  } = props;

  const [isPropertyInfoChanged, setIsPropertyInfoChanged] = useState<boolean>(false);

  const [updatedPayload, setUpdatedPayload] = useState<PropertyInfoPayload>({
    propertyData: {},
    label: {},
  });

  /**Implemented useFormHandler hooks to handle form*/
  const {
    formValues,
    formErrors,
    setFormValues,
    setFormCustomValue,
    setFormCustomError,
    handleOnSubmit,
    handleOnChange,
  } = useFormHandler(initialFormValues, {
    customValidator,
    required: getRequiredKeys(),
    shouldErrorHandleOnInputChange: false,
    valueAccepted: valueAcceptedForFormHandler(),
  });
  const getRequiredUpdatedPayload = () => {
    const { propertyData, label } = updatedPayload;
    if (
      propertyData[FOUNDATION_TYPE.KEY] &&
      parseSelectedValueToOption(propertyData[FOUNDATION_TYPE.KEY]).value !== BASEMENT
    ) {
      delete propertyData[BASEMENT_FINISH_TYPE.KEY];
      delete label[BASEMENT_FINISH_TYPE.KEY];
    }
    return { propertyData, label };
  };

  const subHandler = (submittedData: FormDataType, isSubmitted: boolean) => {
    const disableFieldData = disabledApplicantFormField();
    const { prefillDetails } = applicant;
    if (disableFieldData?.isDisableFormField) {
      goToLastTab(currentIndex);
    } else if (isSubmitted) {
      setPrefillDetails({
        propertyData: removePropertyInfoLabelFromKeys(submittedData),
        label: updatedPayload.label,
      });
      const { propertyData, label } = getRequiredUpdatedPayload();
      const {
        payload: { adjustments, ...restData },
      } = getUserPropertyInfoPayload(propertyData, keysForAdjustmentsObj);
      let tempPayload: any = {};
      const appointmentList = getAppointmentList();
      const isFloodLob: boolean = appointmentList.includes(INSURANCE_PRODUCTS_MAPPING.FLOOD);
      if (!!restData) tempPayload.propertyData = { ...restData };
      if (!!adjustments && adjustments[REPLACEMENT_COST.KEY]) {
        adjustments[REPLACEMENT_COST.KEY].value = true;
      }
      if (!isEmpty(adjustments)) tempPayload.adjustments = { ...adjustments };
      if (!isEmpty(label)) tempPayload.label = { ...label };
      const isSubmitHomeLob: boolean = getIsSubmitPropertyInfoForLob(
        !!isPropertyInfoChanged,
        LOB_PRODUCTS_MAPPING.home
      );
      let isSubmitFloodLob: boolean = getIsSubmitPropertyInfoForLob(
        !!isPropertyInfoChanged,
        LOB_PRODUCTS_MAPPING.flood
      );
      const isNotFloodOverride: boolean =
        getIsLobIncludesInAppointments(INSURANCE_BUSINESS_TYPES.FLOOD) &&
        getIsLobIncludesInAppointments(INSURANCE_BUSINESS_TYPES.HOME) &&
        common?.selectedBusinessOption?.toLowerCase() !== LOB_PRODUCTS_MAPPING.flood &&
        isEmpty(getQuoteListOfLob(INSURANCE_BUSINESS_TYPES.FLOOD));
      isSubmitFloodLob = isSubmitFloodLob && !isNotFloodOverride;
      isFloodLob &&
        setFloodDetails({
          isPropertyInfoRequiredForFlood: false,
        });
      const { annexPrefill } = floodDetails;
      if (!!tempPayload) {
        const deductible = !!tempPayload?.adjustments?.deductible?.value
          ? tempPayload?.adjustments?.deductible?.value
          : applicant?.personal_details?.preferredDeductible;
        const residenceType = !isEmpty(tempPayload?.propertyData?.residenceType)
          ? tempPayload?.propertyData?.residenceType
          : applicant?.personal_details?.residenceType;
        updateApplicantDetails({
          ...applicant?.personal_details,
          preferredDeductible: deductible || '',
          residenceType,
        });
      }
      if (isSubmitHomeLob || isSubmitFloodLob) {
        tempPayload = {
          ...tempPayload,
          propertyData: {
            ...omit(tempPayload?.propertyData),
            constructionType: PROPERTY_INFO_DEFAULT_DETAILS.CONSTRUCTION_TYPE.DEFAULT_VALUE,
            rebuildingCost: Number(
              tempPayload?.adjustments?.coverageA?.value || prefillDetails?.propertyData?.coverageA
            ),
          },
        };
        setUserPropertyInformation(!isEmpty(tempPayload) && isPropertyInfoChanged ? tempPayload : null);
        if (isSubmitFloodLob) {
          savePropertyInfoDetails();
          setFloodDetails({ quoteList: [], quoteErrorList: [], upcomingCarrierList: [] });
          clearSelectedQuoteOnPropertyInfoEdit(INSURANCE_BUSINESS_TYPES.FLOOD);
        }
        if (isSubmitHomeLob) {
          fetchHomeQuoteDetails();
          goToLastTab(currentIndex);
          clearSelectedQuoteOnPropertyInfoEdit(INSURANCE_BUSINESS_TYPES.HOME);
        }
      } else if (
        !isPropertyInfoChanged &&
        getIsLobIncludesInAppointments(INSURANCE_BUSINESS_TYPES.FLOOD) &&
        !getIsLobIncludesInAppointments(INSURANCE_BUSINESS_TYPES.HOME) &&
        !!annexPrefill?.isFloodInfo
      ) {
        setIsUpdatedPropertyInfo(true);
      } else {
        goToLastTab(currentIndex);
      }
    }
  };

  /**Form submit handler*/
  const onFormSubmit = () => handleOnSubmit(subHandler);

  /**On back button click go to Applicant form*/
  const onBackClick = () => onPrevTab(1);

  const setUpdatedValuesToState = (key: string, newValue: any) => {
    const selectedFieldDetails = selectFieldDetailsByKey(key);
    /**Updated the data type of value as payload data type needed*/
    if (isEmpty(selectedFieldDetails)) {
      return;
    }
    const { newLabel, parsedValue } = parseValueToPropertyData(newValue, selectedFieldDetails, key);
    setUpdatedPayload(preval => {
      let { propertyData, label } = preval;
      propertyData[key] = parsedValue;
      return {
        propertyData,
        label: {
          ...label,
          ...newLabel,
        },
      };
    });
  };

  /**Handler the user input change by selecting date*/
  const datePickerOnChangeHandler = (value: string, key: string) => {
    value = value ? value.replaceAll('_', '') : EMPTY;
    setIsPropertyInfoChanged(true);
    setFormCustomValue(key, value);
    setUpdatedPayload(preval => ({
      ...preval,
      propertyData: {
        ...preval.propertyData,
        [key]: value,
      },
    }));
  };

  const setNoForSwimmingPoolFanced = () => {
    const value = `${NO}~${NO}`;
    setFormCustomValue(SWIMMING_POOL_FENCED.KEY, value);
    setUpdatedValuesToState(SWIMMING_POOL_FENCED.KEY, value);
  };

  const setDefaultValueToBasementFinishType = () => {
    const defaultBasementFinishTypeDetails = getDefaultOptionFromOptions(
      BASEMENT_FINISH_TYPE.OPTIONS
    );
    const defaultBasementFinishTypeValue =
      parseSelectedValueToOption(defaultBasementFinishTypeDetails)?.value || '';
    setDefaultBasementFinishTypePercentage(
      updatedPayload?.propertyData[BASEMENT_FINISH_TYPE.KEY] || defaultBasementFinishTypeValue
    );
    if (updatedPayload.propertyData[BASEMENT_FINISH_TYPE.KEY]) return;
    setFormCustomValue(BASEMENT_FINISH_TYPE.KEY, defaultBasementFinishTypeDetails);
    setUpdatedValuesToState(BASEMENT_FINISH_TYPE.KEY, defaultBasementFinishTypeDetails);
  };

  const setDefaultBasementFinishTypePercentage = (basementFinishTypeValue: string = '') => {
    let defaultBasementFinishPercentage = HUNDRED;
    if (basementFinishTypeValue === BasementFinishType.UNFINISHED) {
      defaultBasementFinishPercentage = ZERO;
    }
    if (basementFinishTypeValue === BasementFinishType.PARTIALLY_FINISHED) {
      defaultBasementFinishPercentage = FIFTY;
    }
    setFormCustomValue(BASEMENT_FINISH_PERCENTAGE.KEY, defaultBasementFinishPercentage);
    setUpdatedValuesToState(BASEMENT_FINISH_PERCENTAGE.KEY, defaultBasementFinishPercentage);
  };

  const setFlatRoofShape = () => {
    setFormCustomValue(ROOF_SHAPE.KEY, DEFAULT_ROOT_SHAPE);
    setUpdatedValuesToState(ROOF_SHAPE.KEY, DEFAULT_ROOT_SHAPE);
  };

  const resetBasementFinishPercentage = (isClear: boolean) => {
    const propertyData = applicant.prefillDetails?.propertyData;
    if (isClear && propertyData) {
      const basementFinishTypePercentage: string = propertyData?.basementFinishedPercent || '';
      setFormCustomValue(BASEMENT_FINISH_PERCENTAGE.KEY, basementFinishTypePercentage);
      setUpdatedValuesToState(BASEMENT_FINISH_PERCENTAGE.KEY, basementFinishTypePercentage);
      setFormCustomError(BASEMENT_FINISH_PERCENTAGE.KEY, '');
    }
  };

  const setBasementFinishTypeOnBasementPercentageChange = (basemenyPercentage: string) => {
    if (isEmpty(basemenyPercentage)) {
      return;
    }
    const basementTypeDetails = getBasementFinishTypeByBasementFinishPercentage(
      Number(basemenyPercentage)
    );
    const basementTypeValue = `${basementTypeDetails}~${basementTypeDetails}`;
    setFormCustomValue(BASEMENT_FINISH_TYPE.KEY, basementTypeValue);
    setUpdatedValuesToState(BASEMENT_FINISH_TYPE.KEY, basementTypeValue);
    if (basemenyPercentage === ZERO) {
      setFormCustomError(BASEMENT_FINISH_PERCENTAGE.KEY, '');
    }
  };

  /**Handler the user input change*/
  const onChangeHandler = (event: React.ChangeEvent<any>) => {
    let { name, value } = event.target;
    setIsPropertyInfoChanged(true);
    if (name === SWIMMING_POOL.KEY && parseSelectedValueToOption(value).value === FALSE) {
      setNoForSwimmingPoolFanced();
    }
    if (name === ROOF_TYPE.KEY && parseSelectedValueToOption(value).value === FLAT_ROOF) {
      setFlatRoofShape();
    }
    if (name === FOUNDATION_TYPE.KEY) {
      resetBasementFinishPercentage(true);
      if (parseSelectedValueToOption(value).value === BASEMENT) {
        setDefaultValueToBasementFinishType();
      }
    }
    if (name === BASEMENT_FINISH_TYPE.KEY) {
      setDefaultBasementFinishTypePercentage(parseSelectedValueToOption(value).value);
    }
    handleOnChange(event);
    setUpdatedValuesToState(name, value);
  };

  const onBlurPropertyDataFormField = (event: React.ChangeEvent<any>) => {
    let { name, value } = event.target;
    if (name === BASEMENT_FINISH_PERCENTAGE.KEY) {
      setBasementFinishTypeOnBasementPercentageChange(value);
    }
  };

  /**mapped all fields value with label*/
  const getMappedValueWithLabelForSelectedField = useCallback(
    (key: string, value: string) => {
      const label = applicant.prefillDetails?.label;
      const selectedFieldDetails = selectFieldDetailsByKey(key);
      if (isEmpty(selectedFieldDetails)) {
        return value;
      }
      if (!isEmpty(label) && label?.[key]) {
        const labelKeys = Object.keys(label);
        if (labelKeys.includes(key)) {
          value = label[key] ? `${value}~${label[key]}` : value;
        }
        return value;
      }
      const options = selectedFieldDetails?.OPTIONS;
      if (!options || isEmpty(options)) {
        return value;
      }
      const selectedOption = getSelectedOptionFromDropDownList(options, value);
      if (!selectedOption) {
        return value;
      }
      if (value !== selectedOption?.value) {
        setUpdatedPayload(prevValue => ({
          propertyData: {
            ...prevValue.propertyData,
            [key]: selectedOption?.value,
          },
          label: {
            ...prevValue.label,
            [key]: selectedOption?.label,
          },
        }));
      }
      value = `${selectedOption?.value}~${selectedOption.label}`;
      return value;
    },
    // eslint-disable-next-line
    [applicant]
  );

  /**Set all fields value from store to form state */
  const mappedPrefillDetailsFromStoreToState = useCallback(() => {
    const { prefillDetails, userPropertyInformation } = applicant;
    let prefillInfoDetails: any = mappedResponseToStoreForPropertyDetails(prefillDetails);
    const basementFinishPercentage = Number(
      prefillInfoDetails?.propertyData?.[BASEMENT_FINISH_PERCENTAGE.KEY] || HUNDRED
    );
    let basementFinishType: string =
      getBasementFinishTypeByBasementFinishPercentage(basementFinishPercentage);
    if (!prefillInfoDetails?.propertyData?.[SWIMMING_POOL_FENCED.KEY]) {
      setUpdatedPayload({
        propertyData: {
          [SWIMMING_POOL_FENCED.KEY]: YES,
        },
        label: {
          [SWIMMING_POOL_FENCED.KEY]: YES,
        },
      });
    }
    const isEmptyHomeOrFloodLob: boolean =
      (isEmpty(getQuoteListOfLob(LOB_PRODUCTS_MAPPING.home)) &&
        getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.home)) ||
      (isEmpty(getQuoteListOfLob(LOB_PRODUCTS_MAPPING.flood)) &&
        getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.flood));
    if (isEmptyHomeOrFloodLob && isPropertyInfoChanged) {
      const { propertyData, label } =
        mappedResponseToStoreForPropertyDetails(userPropertyInformation);
      const defaultFormDetails: any = getDefaultValueToSetUpdatedPayloadState(prefillDetails);
      setUpdatedPayload({
        propertyData: { ...defaultFormDetails.propertyData, ...propertyData },
        label: { ...defaultFormDetails.label, ...label },
      });
    }
    if (prefillDetails && !isEmpty(prefillDetails)) {
      const { propertyData } = prefillDetails;
      const updatedFormData: FormDataType = {};
      if (!isEmpty(propertyData)) {
        Object.keys(formValues).forEach(key => {
          let value = propertyData[key] ? propertyData[key].toString() : formValues[key];
          if (!!value) {
            updatedFormData[key] = getMappedValueWithLabelForSelectedField(key, value);
          }
        });
        if (basementFinishType) {
          updatedFormData[BASEMENT_FINISH_TYPE.KEY] = `${basementFinishType}~${basementFinishType}`;
          setUpdatedPayload(prevValue => ({
            propertyData: {
              ...prevValue.propertyData,
              [BASEMENT_FINISH_TYPE.KEY]: basementFinishType,
            },
            label: {
              ...prevValue.label,
              [BASEMENT_FINISH_TYPE.KEY]: basementFinishType,
            },
          }));
        }
        setFormValues(updatedFormData);
      }
    }
  }, [
    applicant,
    formValues,
    isPropertyInfoChanged,
    setFormValues,
    setUpdatedPayload,
    getMappedValueWithLabelForSelectedField,
  ]);

  /**mapped StoreDataToState during component did mount*/
  useEffect(() => {
    mappedPrefillDetailsFromStoreToState();
    // eslint-disable-next-line
  }, [applicant?.prefillDetails]);

  const isShowRetryButton: boolean =
    !isIncludesPropertyCoverage(formValues) && !isPropertyInfoChanged;

  return (
    <div>
      {!!applicant?.saveApplicantLoader || applicant?.propertyInfoLoader ? (
        <div className='launch-loader-wrapper row justify-content-center align-items-start'>
          <img
            className='launch-loader-border'
            src={ThemesImages[getThemeType()].propertyInfoLoader}
            alt='Please wait...'
          />
        </div>
      ) : (
        <Template
          {...{
            formValues,
            formErrors,
            onFormSubmit,
            onChangeHandler,
            datePickerOnChangeHandler,
            onBackClick,
            getRequiredKeys,
            isShowRetryButton,
            onBlurPropertyDataFormField,
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.PropertyInfoDispatch =>
  bindActionCreators(
    {
      fetchHomeQuoteDetails,
      savePropertyInfoDetails,
      setApplicantDetails,
      setFloodDetails,
      setHomeState,
      setLineOfBusiness,
      setPrefillDetails,
      setQuotesLoaded,
      setUserPropertyInformation,
      setIsUpdatedPropertyInfo,
      updateApplicantDetails,
    },
    dispatch
  );

const mapStateToProps = ({
  applicant,
  common,
  floodDetails,
  home,
}: AppComponents.PropertyInfoStoreProps) => ({
  applicant,
  common,
  floodDetails,
  home,
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyInfo);
