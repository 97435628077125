import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../../config/config';
import history from '../../../history';
import {
  setHasFormChanged,
  toggleVehicleIncludedStatus,
  vehicleSelectedForEdit,
} from '../../redux/actions';
import AutoSidePanel from '../auto-side-panel';
import { allowEditingQuote } from '../../../utils';

const VehicleSidePanel: FC<AppComponents.VehicleSidePanelProps> = ({
  vehicle,
  validateBeforeEditHandler,
  toggleVehicleIncludedStatus,
  toggleVehicleHandler,
  deleteDetailsLoader,
  stepSubmitLoader,
  vehicleSelectedForEdit,
  setHasFormChanged,
  openVehicleLimitModal,
}) => {
  const isVehicleLimitReached = () => {
    let isLimitReached = false;
    let totalIncludedVehicles = 0;
    vehicle.vehiclesList.forEach((data: any) => {
      if (data.isIncluded) {
        totalIncludedVehicles++;
      }
    });

    if (totalIncludedVehicles === config.maxVehicleLimit) {
      isLimitReached = true;
    }

    return isLimitReached;
  };

  const shouldDisableSwitch = (isIncluded: any) => {
    let shouldDisable = false;

    if (isVehicleLimitReached() && !isIncluded) {
      shouldDisable = true;
    }
    return shouldDisable;
  };

  const toggleEnabled = (details: any) => {
    if (isVehicleLimitReached() && !details.isIncluded) {
      openVehicleLimitModal();
    } else {
      toggleVehicleHandler();
      toggleVehicleIncludedStatus({ vuid: details.vuid });
      setHasFormChanged(true);
    }
  };

  const editVehicleHandler = (details: any) => {
    const disableAutoEdits = allowEditingQuote();
    if (validateBeforeEditHandler && !disableAutoEdits?.auto) {
      validateBeforeEditHandler({ vuid: details.vuid, vinInput: details.vinInput });
    } else {
      vehicleSelectedForEdit(details.vuid);
    }
    if (history.location.pathname.includes('coverages')) {
      history.push('vehicle');
    }
  };

  return (
    <>
      {vehicle &&
        vehicle.vehiclesList.map((currentVehicle: any, i: number) => (
          <Grid
            container
            spacing={2}
            className={`m-0 w-100 list-wrap ${
              currentVehicle.vuid === vehicle.vuid ? 'active-container' : 'list-container'
            }`}
            key={`${i}-${currentVehicle.year}-${currentVehicle.make}-${currentVehicle.model}`}
          >
            <AutoSidePanel
              includedLimitReached={shouldDisableSwitch(currentVehicle.isIncluded)}
              isIncluded={currentVehicle.isIncluded}
              icon={false}
              details={{
                vuid: currentVehicle.vuid,
                title: `${currentVehicle.year} ${currentVehicle.make}
                  ${currentVehicle.model}`,
                index: i,
                loader: deleteDetailsLoader,
                disabled: currentVehicle.vehicleLoader || stepSubmitLoader,
                vinInput: currentVehicle.vinInput,
                isIncluded: currentVehicle.isIncluded,
                ownLease: currentVehicle.own_lease,
              }}
              toggleEnabled={toggleEnabled}
              edithandler={editVehicleHandler}
              vehicleList={vehicle?.vehiclesList}
            />
          </Grid>
        ))}
    </>
  );
};

const mapStateToProps = ({ common, vehicle }: AppComponents.VehicleSidePanelStore) => {
  const { deleteDetailsLoader, stepSubmitLoader } = common;
  return { vehicle, deleteDetailsLoader, stepSubmitLoader };
};

const mapDispatchToProps = (dispatch: any): AppComponents.VehicleSidePanelDispatch =>
  bindActionCreators(
    { vehicleSelectedForEdit, toggleVehicleIncludedStatus, setHasFormChanged },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VehicleSidePanel);
