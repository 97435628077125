export const EDUCATION_DATA = [
  { label: 'No High School Diploma', value: 'No High School Diploma' },
  { label: 'High School Diploma', value: 'High School Diploma' },
  { label: 'Some College - No Degree', value: 'Some College - No Degree' },
  { label: 'Vocational/Technical Degree', value: 'Vocational/Technical Degree' },
  { label: 'Associates Degree', value: 'Associates Degree' },
  { label: 'Bachelors', value: 'Bachelors' },
  { label: 'Masters', value: 'Masters' },
  { label: 'PhD', value: 'Phd' },
  { label: 'Medical Degree', value: 'Medical Degree' },
  { label: 'Law Degree', value: 'Law Degree' },
];
