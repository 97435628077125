/* istanbul ignore file */
import * as otherDetailsType from '../constants';

// Initialize accidents details
export const initAccidents = (): AppRedux.Action => ({
  type: otherDetailsType.INIT_ACCIDENTS,
});

// Initialize Violations details
export const initViolations = (): AppRedux.Action => ({
  type: otherDetailsType.INIT_VIOLATIONS,
});

// Initialize Losses details
export const initLosses = (): AppRedux.Action => ({
  type: otherDetailsType.INIT_LOSSES,
});

// Accident entity - any field change
export const accidentFieldUpdated = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.ACCIDENT_FIELD_UPDATED,
  payload,
});

// Accident entity - any field delete
export const accidentFieldDeleted = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.ACCIDENT_FIELD_DELETED,
  payload,
});

// Set other details when driver selected
export const setOtherDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.SET_OTHER_DETAILS,
  payload,
});

// Violation entity - any field change
export const violationFieldUpdated = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.VIOLATION_FIELD_UPDATED,
  payload,
});

// Violation entity - any field delete
export const violationFieldDeleted = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.VIOLATION_FIELD_DELETED,
  payload,
});

// Losses entity - any field change
export const lossFieldUpdated = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.LOSS_FIELD_UPDATED,
  payload,
});

// Loss entity - any field delete
export const lossFieldDeleted = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.LOSS_FIELD_DELETED,
  payload,
});

// Set respective field errors
export const setOtherErrors = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.SET_OTHER_ERRORS,
  payload,
});

// Clear Accidents Editor Data action creator
export const clearAccidentList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.CLEAR_ACCIDENT_LIST,
  payload,
});

// Clear Violations Editor Data action creator
export const clearViolationList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.CLEAR_VIOLATION_LIST,
  payload,
});

// Clear Losses Editor Data action creator
export const clearLossList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: otherDetailsType.CLEAR_LOSS_LIST,
  payload,
});
