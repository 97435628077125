import { v4 } from 'uuid';
import {
  api,
  CreateHomeQuoteRequestMutation,
  HomeQuoteRequestPollQuery,
  HomeQuoteRequestQuery,
  MutationCreateHomeQuoteRequestArgs,
  MutationUpdateHomeQuoteRequestArgs,
  QueryHomeQuoteRequestArgs,
  QueryHomeQuoteRequestPollArgs,
  QuoteRequestStatus,
  UpdateHomeQuoteRequestMutation,
} from '../../../../graphql/generated';
import {
  saveApplicantError,
  setAddProductSaveApplicantLoader,
  setAnnexPrefill,
  setApplicantDetails,
  setHomeState,
  setNoQuotesAvailableForHome,
  setRequestId,
} from '../../../../pages/redux/actions';
import { ReduxDispatch } from '../../../../pages/redux/store';
import { setHomeQuoteDetails, setQuoteRequestStatus } from '../../slices';
import {
  setCoverageDetails,
  setDeductibleDetails,
  setPrefillStatus,
  setPropertyDetails,
  setPropertyOtherDetails,
} from '../../slices/property-info-details.slice';
import { APILifeCycle, GraphqlError } from '../types';
import { mapPersonalDetails } from './mapper/personal-details.mapper';
import { extractGraphQlErrorMessage, getAnnexDetails, getPrefillStatus } from '../../../utils';
import { PrefillStatus } from '../../../enums';

const POLL_HOME_QUOTES = 'POLL_HOME_QUOTES';

const updateHomeState = (
  dispatch: ReduxDispatch,
  herdId?: string | null,
  providerRequestId?: string | null
) => {
  dispatch(setHomeState({ herd_id: herdId ?? v4() }));
  if (providerRequestId) {
    dispatch(setRequestId(providerRequestId));
  }
};

const onCreateHomeQuote = async (
  request: MutationCreateHomeQuoteRequestArgs,
  { dispatch, queryFulfilled }: APILifeCycle<CreateHomeQuoteRequestMutation>
) => {
  try {
    dispatch(saveApplicantError(false));
    dispatch(setNoQuotesAvailableForHome(false));
    dispatch(
      setApplicantDetails({
        prefillDetails: null,
        userPropertyInformation: null,
      })
    );
    const { data } = await queryFulfilled;
    const { applicant, coverage, deductible, productName, propertyData, id, referenceId, status } =
      data.createHomeQuoteRequest || {};
    if (!id) {
      throw new Error('Reference id is not present');
    }
    if (coverage) {
      dispatch(setCoverageDetails(coverage));
    }
    if (deductible) {
      dispatch(setDeductibleDetails(deductible));
    }
    if (propertyData) {
      dispatch(setPropertyDetails(propertyData));
      const prefillStatus = getPrefillStatus(propertyData);
      dispatch(setPrefillStatus(prefillStatus));
    }
    if (applicant && productName) {
      mapPersonalDetails(applicant, productName);
      dispatch(
        setApplicantDetails({
          personal_details: { ...mapPersonalDetails(applicant, productName) },
        })
      );
    }
    if (status) {
      dispatch(setQuoteRequestStatus(status));
    }
    updateHomeState(dispatch, id, referenceId);
  } catch (err) {
    const error = err as GraphqlError;
    dispatch(saveApplicantError(true));
    const message = extractGraphQlErrorMessage(error);
    console.log(message);
  }
  dispatch(setAddProductSaveApplicantLoader(false));
};

const onUpdateHomeQuote = async (
  request: MutationUpdateHomeQuoteRequestArgs,
  { dispatch, queryFulfilled }: APILifeCycle<UpdateHomeQuoteRequestMutation>
) => {
  try {
    dispatch(saveApplicantError(false));
    dispatch(
      setApplicantDetails({
        prefillDetails: null,
        userPropertyInformation: null,
      })
    );

    const { data } = await queryFulfilled;
    const { applicant, coverage, deductible, id, productName, propertyData, referenceId } =
      data.updateHomeQuoteRequest || {};
    if (!id) {
      throw new Error('Reference id is not present');
    }
    updateHomeState(dispatch, id, referenceId);
    if (coverage) {
      dispatch(setCoverageDetails(coverage));
    }
    if (deductible) {
      dispatch(setDeductibleDetails(deductible));
    }
    if (propertyData) {
      dispatch(setPropertyDetails(propertyData));
      const prefillStatus = getPrefillStatus(propertyData);
      dispatch(setPrefillStatus(prefillStatus));
    }
    if (applicant && productName) {
      mapPersonalDetails(applicant, productName);
      dispatch(
        setApplicantDetails({
          personal_details: { ...mapPersonalDetails(applicant, productName) },
        })
      );
    }
    dispatch(setQuoteRequestStatus(QuoteRequestStatus.InProgress));
  } catch (err) {
    const error = err as GraphqlError;
    dispatch(saveApplicantError(true));
    const message = extractGraphQlErrorMessage(error);
    console.log(message);
  }
  dispatch(setAddProductSaveApplicantLoader(false));
};

const onHomeQuoteRequestPoll = async (
  request: QueryHomeQuoteRequestPollArgs,
  { dispatch, queryFulfilled }: APILifeCycle<HomeQuoteRequestPollQuery>
) => {
  try {
    const {
      data: {
        homeQuoteRequestPoll: {
          continuePolling,
          pendingQuotes,
          quotes,
          quoteRequestStatus,
          upcomingCarriers,
        },
      },
    } = await queryFulfilled;
    dispatch(
      setHomeQuoteDetails({
        continuePolling,
        pendingQuotes,
        quotes,
        quoteRequestStatus,
        upcomingCarriers,
        isPolling: continuePolling,
      })
    );
  } catch (err) {
    const error = err as GraphqlError;
    const message = extractGraphQlErrorMessage(error);
    console.log(message);
  }
};

const onHomeQuoteRequest = async (
  request: QueryHomeQuoteRequestArgs,
  { dispatch, queryFulfilled }: APILifeCycle<HomeQuoteRequestQuery>
) => {
  try {
    const {
      data: { homeQuoteRequest },
    } = await queryFulfilled;
    const { propertyData, coverage, deductible, residenceType, status } = homeQuoteRequest;
    if (propertyData) {
      dispatch(setPropertyDetails(propertyData));
      const annexData: any = getAnnexDetails();
      annexData.floodQuestionnaireAdditionalData = null;
      dispatch(setAnnexPrefill(annexData || {}));
      const prefillStatus = getPrefillStatus(propertyData);
      dispatch(setPrefillStatus(prefillStatus));
    }
    if (residenceType) {
      dispatch(setPropertyOtherDetails({ residenceType }));
    }
    if (coverage) {
      dispatch(setCoverageDetails(coverage));
    }
    if (deductible) {
      dispatch(setDeductibleDetails(deductible));
    }
    if (status) {
      dispatch(setQuoteRequestStatus(status));
    }
    dispatch(
      setHomeQuoteDetails({
        continuePolling: true,
        isPolling: true,
      })
    );
  } catch (err) {
    const error = err as GraphqlError;
    const message = extractGraphQlErrorMessage(error);
    dispatch(setPrefillStatus(PrefillStatus.INCOMPLETE));
    console.log(message);
  }
};

const homeApi = api.enhanceEndpoints({
  addTagTypes: [POLL_HOME_QUOTES],
  endpoints: {
    createHomeQuoteRequest: {
      onQueryStarted: onCreateHomeQuote,
    },
    updateHomeQuoteRequest: {
      onQueryStarted: onUpdateHomeQuote,
    },
    executeQuoteRequest: {
      invalidatesTags: (_) => [POLL_HOME_QUOTES],
    },
    homeQuoteRequestPoll: {
      onQueryStarted: onHomeQuoteRequestPoll,
      providesTags: (_) => [POLL_HOME_QUOTES],
    },
    homeQuoteRequest: {
      onQueryStarted: onHomeQuoteRequest,
    },
  },
});

export const {
  useCreateHomeQuoteRequestMutation,
  useUpdateHomeQuoteRequestMutation,
  useExecuteQuoteRequestMutation,
  useHomeQuoteRequestPollQuery,
  useLazyHomeQuoteRequestPollQuery,
  useHomeQuoteRequestQuery,
} = homeApi;
