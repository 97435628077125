import React from 'react';
import { Typography } from '@material-ui/core';
import { PAGES_TEXT } from '../../../constants';
import '../not-found-error';

const QuoteExpiredError = () => {
  return (
    <div className='quote-expired-error-page-wrapper'>
      <div className='container error-wrapper'>
        <div className='error-div'>
          <div className='row'>
            <div className='col-lg-12'>
              <Typography className='ops-label text-center'>{PAGES_TEXT.notFoundOops}</Typography>
              <Typography className='error-msg text-center'>
                {PAGES_TEXT.quoteExpiredErrors}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(QuoteExpiredError);

