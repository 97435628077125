/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import axiosRequest from '../../api';
import * as propertyInfoActions from '../constants';
import { errorHandler, questionnaireSubmitLoader, savePropertyInfoDetailsLoader, setAnnexPrefill, setFloodDetails, setFloodQuotes, setIsUpdatedPropertyInfo, } from '../actions';
import { getLobHerdId, getLobToOverridePropertyInfo, setAnnexInitialQuestion, } from '../../../utils';
import { get, isEmpty } from 'lodash';
import { LOB_PRODUCTS_MAPPING } from '../../../constants';

function* savePropertyInfoDetail(): any {
  yield put(savePropertyInfoDetailsLoader(true));
  try {
    const {
      applicant: { address, userPropertyInformation },
      common: { selectedBusinessOption },
      home,
      partner: { isProviderOneLogin },
    } = yield select();
    const lob = [LOB_PRODUCTS_MAPPING.flood, LOB_PRODUCTS_MAPPING.home].includes(
      selectedBusinessOption?.toLowerCase()
    )
      ? selectedBusinessOption
      : getLobToOverridePropertyInfo();
    const { street, city, county, state, zip } = address || {};
    let herdId = getLobHerdId(lob) || '';
    const requstedPayload = {
      herdId,
      homeType: get(home, 'productType', ''),
      lob,
      street,
      city,
      county,
      state,
      zip,
      prefillData: !isEmpty(userPropertyInformation) ? { ...userPropertyInformation } : null,
    };
    const responseDetails: any = yield axiosRequest(
      'POST',
      '/property-prefill/override',
      isProviderOneLogin,
      requstedPayload
    );
    if (
      !!responseDetails?.data &&
      !!responseDetails?.data.success &&
      responseDetails?.status === 200
    ) {
      yield put(setIsUpdatedPropertyInfo(true));
      const { annexRequiredPrefill } = responseDetails.data;
      if (!isEmpty(annexRequiredPrefill)) {
        setAnnexInitialQuestion(responseDetails.data)
        yield put(setAnnexPrefill(annexRequiredPrefill));
      }
      yield put(
        setFloodDetails({
          isPropertyInfoRequiredForFlood: false,
        })
      );
      if (isEmpty(annexRequiredPrefill)) {
        yield put(questionnaireSubmitLoader(false));
        yield put(
          setFloodQuotes({ quoteList: [], quoteErrorList: [], upcomingCarrierList: [] })
        );
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
    yield put(setIsUpdatedPropertyInfo(false));
  }
  yield put(savePropertyInfoDetailsLoader(false));
}

export function* savePropertyInfoDetailWatcher() {
  yield takeEvery(propertyInfoActions.SAVE_PROPERTY_INFO_DETAILS, savePropertyInfoDetail);
}

export default function* rootSaga() {
  yield all([fork(savePropertyInfoDetailWatcher)]);
}
