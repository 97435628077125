import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const placeholderChar = '\u2000';
const placeholder = '';
const inputMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PhoneMask = (props: any) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={inputMask}
      placeholderChar={placeholderChar}
      placeholder={placeholder}
    />
  );
};

PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default PhoneMask;
