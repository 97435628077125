import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { dateFormat } from '../../../utils';

import './date-range-selector.scss';

const getDateLabelValue = (date: string) => {
  return date ? date : '<span class="date-holder">-</span>';
};

const createDateMarkup = (startDate: any, endDate: any) => {
  return {
    __html: `${getDateLabelValue(startDate)}<span class='to-label'>To</span>${getDateLabelValue(
      endDate
    )}`,
  };
};

const DateRangeSelector = (props: any) => {
  const [oldStartDate, setOldStartDate] = useState(props.dateRange.startDate);
  const [oldEndDate, setOldEndDate] = useState(props.dateRange.endDate);
  const [startDate, setStartDate] = useState(props.dateRange.startDate || undefined);
  const [endDate, setEndDate] = useState(props.dateRange.endDate || undefined);
  const datePickerRef: any = useRef();

  useEffect(() => {
    if (props.dateRange.startDate && props.dateRange.endDate) {
      setStartDate(props.dateRange.startDate);
      setEndDate(props.dateRange.endDate);
      setOldStartDate(props.dateRange.startDate);
      setOldEndDate(props.dateRange.endDate);
      datePickerRef.current.setStartDate(props.dateRange.startDate);
      datePickerRef.current.setEndDate(props.dateRange.endDate);
    } else {
      setStartDate('');
      setEndDate('');
      setOldStartDate('');
      setOldEndDate('');
      datePickerRef.current.setStartDate(moment());
      datePickerRef.current.setEndDate(moment());
    }
  }, [props.dateRange]);

  const onHideCalendar = (e: any, picker: any) => {
    setStartDate(oldStartDate);
    setEndDate(oldEndDate);
  };

  const onShowCalendar = (e: any, picker: any) => {
    setStartDate(oldStartDate);
    setEndDate(oldEndDate);
  };

  const onApplyDateRange = (e: any, picker: any) => {
    const formattedStartDate = moment(picker.startDate).format(dateFormat);
    const formattedEndDate = moment(picker.endDate).format(dateFormat);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setOldStartDate(formattedStartDate);
    setOldEndDate(formattedEndDate);
    datePickerRef.current.setStartDate(formattedStartDate);
    datePickerRef.current.setEndDate(formattedEndDate);
    props.onDateRangeApply({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
    e.stopPropagation();
  };

  const onCancelDateRange = (e: any, picker: any) => {
    datePickerRef.current.setStartDate(picker.oldStartDate);
    datePickerRef.current.setEndDate(picker.oldEndDate);
  };

  return (
    <div className='date-range-container'>
      <DateRangePicker
        ref={datePickerRef}
        onHide={onHideCalendar}
        onShow={onShowCalendar}
        onApply={onApplyDateRange}
        onCancel={onCancelDateRange}
        initialSettings={{
          opens: 'right',
          showDropdowns: true,
          startDate,
          endDate,
          locale: {
            format: dateFormat,
          },
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month'),
            ],
            YTD: [moment().startOf('year'), moment()],
          },
          alwaysShowCalendars: true,
          showCustomRangeLabel: false,
          autoUpdateInput: false,
          linkedCalendars: true,
        }}
      >
        <button type='button' className='btn' disabled={props.disableRange} defaultValue=''>
          <span className='d-flex'>
            <span className="w-100" dangerouslySetInnerHTML={createDateMarkup(startDate, endDate)} />
            <DateRangeIcon />
          </span>
        </button>
      </DateRangePicker>
    </div>
  );
};

DateRangeSelector.propTypes = {
  onDateRangeApply: PropTypes.func.isRequired,
  disableRange: PropTypes.bool,
  dateRange: PropTypes.object,
};

export default React.memo(DateRangeSelector);
