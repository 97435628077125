let windowObj = {} as Window
if (typeof globalThis !== 'undefined') {
  windowObj = globalThis.window;
} else if (typeof window !== 'undefined') {
  windowObj = window;
} else if (typeof global !== 'undefined') {
  windowObj = global.window;
}

export default windowObj;
