import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const LicenseModal = (props: AppComponents.ProducerLicenseModalProps) => {
  const [openModal, setOpenModal] = React.useState(false);

  const modalClose = (isDiscard: boolean) => {
    setOpenModal(false);
    props.closehandler(isDiscard);
  };

  useEffect(() => {
    setOpenModal(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        classes={{
          root: 'auto-dialog-container',
        }}
        open={openModal}
        onClose={() => {
          modalClose(false);
        }}
        aria-labelledby='confirmation-dialog-title'
      >
        <DialogTitle id='confirmation-dialog-title'>Invalid Producer License</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Quoting is not allowed in the state of ${props.state} as we were unable to verify the validity of your individual/business license for this state. Please update your license information on NIPR in order to start quoting in this state.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              modalClose(false);
            }}
            color='primary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LicenseModal;
