import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import isEmpty from 'lodash/isEmpty';

import FloodQuestionnaire from '../../components/flood-questionnaire';
import Footer from '../../components/footer';
import history from '../../../history';

import './flood-details.scss';

const FloodDetails = (props: any) => {
  useEffect(() => {
    if (!props.detailsLoader && isEmpty(props.currentQuestion)) {
      history.push('questionnaire');
    }
  }, [props]);

  return (
    <section className='result-wrapper position-relative flood-details-wrapper'>
      <div className='container'>
        <div className='row adjust-row-width'>
          <Card className='card-wrapper'>
            <CardContent>
              <FloodQuestionnaire />
            </CardContent>
          </Card>
          {/* <HomeEditSidebar />*/}
        </div>
        <div className='row'>
          <div className='col-9'>
            <Footer />
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = ({ floodDetails }: any) => floodDetails;

export default connect(mapStateToProps, null)(FloodDetails);
