import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import { connect } from 'react-redux';
import filter from 'lodash/filter';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CompletedIcon from '../../../assets/images/completed-status.svg';
import {
  BULK_QUOTE_ALERT_TIMEOUT,
  BULK_QUOTE_BATCH_STATUS,
  DEFAULT_MISSING_DATA,
  PROCESS_MODAL_HEADING,
  PROCESS_MODAL_MESSAGE,
  TERMINATE_MODAL_HEADING,
  TERMINATE_MODAL_MESSAGE,
} from '../../../constants';
import ConfirmationModal from '../../components/confirmation-modal';
import {
  downloadFile,
  processRecord,
  showTerminatedMessage,
  terminateRecord,
} from '../../redux/actions';
import ExcelIcon from '../../../assets/images/excel.svg';
import {
  getComparator,
  getEmptyRowCount,
  replaceWithPlaceholder,
  stableSort,
} from '../../../utils';
import PendingIcon from '../../../assets/images/pending-status.svg';
import ProcessingIcon from '../../../assets/images/processing-status.svg';
import TablePaginationActions from '../../components/table-pagination';
import TerminatedIcon from '../../../assets/images/terminated-status.svg';

// Bulk quote table head title
const tableHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Batch No.',
  },
  { id: 'organizationName', numeric: false, disablePadding: false, label: 'Org' },
  {
    id: 'originalFileName',
    numeric: false,
    disablePadding: false,
    label: 'Original File',
  },
  { id: 'outputFileName', numeric: false, disablePadding: false, label: 'Output File' },
  {
    id: 'dateCreated',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  { id: 'quotedBy', numeric: false, disablePadding: false, label: 'Quoted By' },
  { id: 'quoteAnalyticsDetails', numeric: false, disablePadding: false, label: 'Analytics' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
];

const getEmptyTableRow = (emptyRows: number, props: any, classes: any) => (
  <TableRow style={{ height: 53 * emptyRows }}>
    {emptyRows === 5 ? (
      <TableCell colSpan={10} align='center'>
        {props.bulkQuote.bulkQuoteTableLoader ? (
          <CircularProgress size={30} classes={{ root: classes.progressLoader }} />
        ) : (
          <span>No records to display</span>
        )}
      </TableCell>
    ) : (
      <TableCell colSpan={10} align='center'></TableCell>
    )}
  </TableRow>
);

const checkInactiveColumn = (columnId: string) => {
  return [
    'action',
    'quoteAnalyticsDetails',
    'originalFileName',
    'outputFileName',
    'status',
  ].includes(columnId);
};

const checkCenterColumn = (columnId: string) => {
  return ['status', 'action', 'originalFileName', 'outputFileName'].includes(columnId);
};

type ActionProp = {
  action: any;
};

const EnhancedTableHead = (props: AppComponents.TableHeadProps & ActionProp) => {
  const { action, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    if (!checkInactiveColumn(property)) {
      onRequestSort(event, property);
    }
  };
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {tableHeadCells.map(tableHeadCell => (
          <TableCell
            key={tableHeadCell.id}
            align={checkCenterColumn(tableHeadCell.id) ? 'center' : 'left'}
            padding={tableHeadCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === tableHeadCell.id ? order : false}
          >
            <TableSortLabel
              active={checkInactiveColumn(tableHeadCell.id) ? false : orderBy === tableHeadCell.id}
              direction={orderBy === tableHeadCell.id ? order : 'asc'}
              onClick={createSortHandler(tableHeadCell.id)}
              hideSortIcon={checkInactiveColumn(tableHeadCell.id) ? true : false}
              style={{
                cursor: checkInactiveColumn(tableHeadCell.id) ? 'default' : 'hand',
                display: tableHeadCell.id === 'action' && !action ? 'none' : '',
              }}
            >
              {tableHeadCell.label}

              {orderBy === tableHeadCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: 'rgb(245,245,245)',
    whiteSpace: 'nowrap',
  },
  tableRow: {
    cursor: 'auto',
    whiteSpace: 'nowrap',
  },
  paginationRow: {
    '& td': {
      borderBottom: 0,
    },
  },
  backdrop: {
    zIndex: 1111,
    color: '#fff',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  progressLoader: {
    color: '#00cf83',
  },
  statusCompleted: {
    backgroundColor: '#00cf83',
  },
  statusProcessing: {
    backgroundColor: '#f2a862',
  },
  statusTerminated: {
    backgroundColor: '#f44336',
  },
  phoneNumberColumn: {
    minWidth: '140px',
  },
  alertBox: { width: '100%', marginTop: '5px' },
});

const getCombinedValue = (data: any) => {
  const searchColumns = ['organizationName', 'dateCreated'];
  let combinedValue = '';
  searchColumns.forEach((column: string) => (combinedValue = `${combinedValue} ${data[column]}`));
  return combinedValue.toLowerCase();
};

const getFilteredList = (rows: Array<any>, searchText: string) => {
  let updatedRows = [...rows];
  if (searchText.length > 0) {
    updatedRows = filter(rows, (row: any) => {
      return getCombinedValue(row).includes(searchText.toLowerCase());
    });
  }
  return updatedRows;
};

const hideAlertBox = (props: any) => {
  props.showTerminatedMessage(false);
};

const getAnalyticsCell = (row: any, fieldName: string) => {
  if (isEmpty(get(row, fieldName, ''))) {
    return DEFAULT_MISSING_DATA;
  }
  let totalRecordsCount = get(row, `${fieldName}.totalCounts`, DEFAULT_MISSING_DATA);
  let completedRecordsCount = get(row, `${fieldName}.completedCounts`, DEFAULT_MISSING_DATA);
  totalRecordsCount =
    String(totalRecordsCount).trim().length > 0 ? totalRecordsCount : DEFAULT_MISSING_DATA;
  completedRecordsCount =
    String(completedRecordsCount).trim().length > 0 ? completedRecordsCount : DEFAULT_MISSING_DATA;
  return `${completedRecordsCount}/${totalRecordsCount}`;
};

const getFileCell = (id: number, fileName: string, props: any) => {
  return !isEmpty(fileName) ? (
    <TableCell align='left'>
      <Tooltip title={fileName} arrow placement='top'>
        <div
          className='file-link'
          onClick={() => props.downloadFile({ bulkQuoteId: id, fileName })}
        >
          <img alt='excel-icon' src={ExcelIcon} />
        </div>
      </Tooltip>
    </TableCell>
  ) : (
    <TableCell className='text-center'>{DEFAULT_MISSING_DATA}</TableCell>
  );
};

const showAlertBox = (props: any, classes: any) => {
  return props.bulkQuote.terminateMessageShow ? (
    <Collapse in={props.bulkQuote.terminateMessageShow} className={classes.alertBox}>
      <Alert
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => {
              props.showTerminatedMessage(false);
            }}
          >
            <CloseIcon fontSize='inherit' />
          </IconButton>
        }
        severity={props.bulkQuote.terminateSuccess ? 'success' : 'error'}
      >
        {props.bulkQuote.terminateMessage}
      </Alert>
    </Collapse>
  ) : (
    ''
  );
};

const getPageLoader = (props: any, classes: any) => {
  return props.bulkQuote.terminateRecordLoader ? (
    <Backdrop className={`${classes.backdrop}`} open={props.bulkQuote.terminateRecordLoader}>
      <CircularProgress size={30} classes={{ root: classes.progressLoader }} />
    </Backdrop>
  ) : (
    ''
  );
};

const getStatusIcon = (status: string) => {
  switch (status) {
    case BULK_QUOTE_BATCH_STATUS.pending:
      return PendingIcon;
    case BULK_QUOTE_BATCH_STATUS.inprogress:
      return ProcessingIcon;
    case BULK_QUOTE_BATCH_STATUS.completed:
      return CompletedIcon;
    case BULK_QUOTE_BATCH_STATUS.terminated:
      return TerminatedIcon;
  }
  return DEFAULT_MISSING_DATA;
};

const getStatusChip = (row: any, classes: any) => {
  const tooltipLabel =
    row.status.toLowerCase().trim() === BULK_QUOTE_BATCH_STATUS.inprogress
      ? 'Processing'
      : startCase(row.status);
  return (
    <Tooltip title={tooltipLabel} aria-label={tooltipLabel} arrow placement='top'>
      <img src={getStatusIcon(row.status.trim().toLowerCase())} alt={tooltipLabel} />
    </Tooltip>
  );
};

const BulkQuoteTable = (props: AppComponents.BulkQuoteTableProps) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [actionPresent, setActionPresent] = React.useState(false);
  const [processPresent, setProcessPresent] = React.useState(false);
  const [processAction, setProcessAction] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowData, setRowData] = React.useState(props.recordsList);
  const [totalRecords, setTotalRecords] = React.useState(props.totalRecords);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [open, confirmationModal] = React.useState(false);
  const [batchNo, setBatchNo] = React.useState('');
  const alertTimeoutRef = React.useRef(null);
  let emptyRows = rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage);

  useEffect(() => {
    setRowData([...props.recordsList]);
    const actionRecords = filter(props.recordsList, record =>
      [BULK_QUOTE_BATCH_STATUS.inprogress, BULK_QUOTE_BATCH_STATUS.pending].includes(
        String(record.status).toLowerCase()
      )
    );
    const processRecords = filter(props.recordsList, record =>
      [BULK_QUOTE_BATCH_STATUS.inprogress].includes(String(record.status).toLowerCase())
    );
    setActionPresent(actionRecords.length > 0);
    setProcessPresent(processRecords.length > 0);
  }, [props.recordsList]);

  useEffect(() => {
    let records = [];
    let recordsTotal = 0;
    if (!isEmpty(props.recordSearchText)) {
      const updatedRows = getFilteredList(props.recordsList, props.recordSearchText);
      records = [...updatedRows];
      recordsTotal = updatedRows.length;
      setOrderBy('');
      setPage(0);
    } else {
      records = [...props.recordsList];
      recordsTotal = props.recordsList.length;
    }
    setRowData(records);
    setTotalRecords(recordsTotal);
  }, [props.recordSearchText, props.recordsList]);

  const changePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (alertTimeoutRef.current !== null) {
      clearTimeout(alertTimeoutRef.current as any);
    }
    if (props.bulkQuote.terminateMessageShow) {
      alertTimeoutRef.current = setTimeout(() => {
        hideAlertBox(props);
      }, BULK_QUOTE_ALERT_TIMEOUT) as any;
    }
  }, [props]);

  const actionClick = (row: any, isActive: boolean) => {
    props.showTerminatedMessage(false);
    setBatchNo(get(row, 'id', ''));
    setProcessAction(isActive);
    confirmationModal(true);
  };

  const confirmationModalCloseHandler = (isDiscard: boolean) => {
    if (isDiscard) {
      if (processAction) {
        props.processRecord(batchNo);
      } else {
        props.terminateRecord(batchNo);
      }
    } else {
      setBatchNo('');
    }
    confirmationModal(false);
    setProcessAction(false);
  };

  const changeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortClick = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const list: Array<any> = stableSort(rowData, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  emptyRows = getEmptyRowCount(emptyRows, list, rowsPerPage);

  const getCellData = (row: any, fieldName: string) => {
    return !isEmpty(get(row, fieldName, '')) ? row[fieldName] : DEFAULT_MISSING_DATA;
  };

  const getActionButton = (row: any) => {
    return (
      <TableCell align='center'>
        {row.status.toLowerCase().trim() === BULK_QUOTE_BATCH_STATUS.inprogress ||
        row.status.toLowerCase().trim() === BULK_QUOTE_BATCH_STATUS.pending ? (
          <React.Fragment>
            <Chip
              variant='outlined'
              label={'Terminate'}
              style={{ marginLeft: '5px' }}
              color='primary'
              onClick={() => {
                !props.bulkQuote.actionLoader && actionClick(row, false);
              }}
              disabled={props.bulkQuote.actionLoader}
            />
            {get(row, 'processFlag', false) &&
              row.status.toLowerCase().trim() !== BULK_QUOTE_BATCH_STATUS.inprogress && (
                <Chip
                  variant='outlined'
                  label={'Process'}
                  style={{ marginLeft: '5px' }}
                  color='primary'
                  onClick={() => {
                    !props.bulkQuote.actionLoader && actionClick(row, true);
                  }}
                  disabled={props.bulkQuote.actionLoader || processPresent}
                />
              )}
          </React.Fragment>
        ) : (
          <span>{DEFAULT_MISSING_DATA}</span>
        )}
      </TableCell>
    );
  };

  return (
    <React.Fragment>
      {getPageLoader(props, classes)}

      <TableContainer>
        <Table className={classes.table} aria-label='bulk-quote-table'>
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={sortClick}
            action={actionPresent}
          />
          <TableBody>
            {stableSort(rowData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                row =>
                  !isEmpty(row) && (
                    <TableRow key={row.id} classes={{ root: classes.tableRow }}>
                      <TableCell component='th' scope='row'>
                        {row.id}
                      </TableCell>
                      <TableCell align='left'>{getCellData(row, 'organizationName')}</TableCell>
                      {getFileCell(row.id, row.originalFileName, props)}
                      {getFileCell(row.id, row.outputFileName, props)}
                      <TableCell align='left'>{getCellData(row, 'dateCreated')}</TableCell>
                      <TableCell align='left'>{getCellData(row, 'quotedBy')}</TableCell>
                      <TableCell align='left'>
                        {getAnalyticsCell(row, 'quoteAnalyticsDetails')}
                      </TableCell>
                      <TableCell align='center'>{getStatusChip(row, classes)}</TableCell>
                      {actionPresent && getActionButton(row)}
                    </TableRow>
                  )
              )}

            {emptyRows >= 5 && getEmptyTableRow(emptyRows, props, classes)}
          </TableBody>
          <TableFooter>
            <TableRow classes={{ root: classes.paginationRow }}>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={12}
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={changePage}
                onChangeRowsPerPage={changeRowsPerPage}
                onPageChange={changePage}
                onRowsPerPageChange={changeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {open && (
        <ConfirmationModal
          open={open}
          closehandler={confirmationModalCloseHandler}
          heading={processAction ? PROCESS_MODAL_HEADING : TERMINATE_MODAL_HEADING}
          message={
            processAction
              ? PROCESS_MODAL_MESSAGE
              : replaceWithPlaceholder(TERMINATE_MODAL_MESSAGE, batchNo)
          }
          contentValue=''
        />
      )}

      {showAlertBox(props, classes)}
    </React.Fragment>
  );
};

BulkQuoteTable.propTypes = {
  entity: PropTypes.string.isRequired,
  totalRecords: PropTypes.number.isRequired,
  recordsList: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  recordSearchText: PropTypes.string,
};

const mapStateToProps = ({ bulkQuote }: AppComponents.BulkQuoteTableStore) => {
  return { bulkQuote };
};

const mapDispatchToProps = (dispatch: any): AppComponents.BulkQuoteTableDispatch => {
  return bindActionCreators(
    { downloadFile, processRecord, showTerminatedMessage, terminateRecord },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkQuoteTable);
