import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { FLOOD_CONFIRMATION_MODAL_TITLE } from '../../../constants';

const titleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      width: '20px',
      height: '20px',
    },
    closeIcon: {
      fontSize: '16px',
    },
    confirmationTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: 18,
      paddingTop: 10,
    },
  });

const ConfirmationDialogTitle = withStyles(titleStyles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      ) : null}
      <Typography variant='h6' className={classes.confirmationTitle}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const FloodConfirmationModal = (props: AppComponents.FloodConfirmationModalProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = (isDiscard: boolean) => {
    setOpen(false);
    props.closeHandler(isDiscard);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        aria-labelledby='flood-confirmation-dialog-title'
        classes={{
          root: 'flood-confirmation-container',
        }}
        onClose={() => {
          handleClose(false);
        }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <ConfirmationDialogTitle
          onClose={() => {
            handleClose(false);
          }}
        >
          {FLOOD_CONFIRMATION_MODAL_TITLE}
        </ConfirmationDialogTitle>
        <DialogActions className='justify-content-center pb-4'>
          <Button
            autoFocus
            onClick={() => {
              handleClose(false);
            }}
            color='primary'
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleClose(true);
            }}
            color='primary'
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

FloodConfirmationModal.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
};

export default FloodConfirmationModal;
