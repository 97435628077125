import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import {
  carrierCategoryListToOpenErrorDetails,
  quoteCarrierErrorTypes,
  quoteErrorCategoryDetails,
} from '../../../utils';
import { isEmpty } from 'lodash';
import {
  COMING_SOON,
  DETAILS,
  INACTIVE,
  INSURANCE_BUSINESS_TYPES,
  VIEW_LESS,
  VIEW_MORE,
} from '../../../constants';
import { getPurchaseIcon } from '../../../pages/components/rate-page-common-component';
import { UPCOMING_CARRIER_TEXT } from '../../constants';

export const ErrorQuoteCard = (props: { statusText: string; errorCategory: string }) => {
  const { statusText, errorCategory } = props;
  const carrierErrorType: string = quoteCarrierErrorTypes.includes(errorCategory)
    ? errorCategory
    : '';
  return (
    <div className='carrier-error'>
      <div className='row m-0 w-100'>
        <div className='col-lg-12 mb-20 justify-content-center price-wraper error-content d-flex'>
          {!isEmpty(carrierErrorType) ? (
            <>
              <Typography className='dark-label'>
                {quoteErrorCategoryDetails[carrierErrorType]?.label}
              </Typography>
              <Typography className='light-label'>
                {quoteErrorCategoryDetails[carrierErrorType]?.displayMessage1}
              </Typography>
              <Typography className='light-label mt-3'>
                {quoteErrorCategoryDetails[carrierErrorType]?.displayMessage2}
              </Typography>
            </>
          ) : (
            <Typography className='error-text error-label text-center'>
              {statusText || ''}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export const ErrorViewMoreCard = (props: { statusText: string; errorCategory: string }) => {
  const { statusText, errorCategory } = props;
  const carrierErrorType: string = quoteCarrierErrorTypes.includes(errorCategory)
    ? errorCategory
    : '';
  const isOpenViewMore: boolean = carrierCategoryListToOpenErrorDetails.includes(carrierErrorType);
  const [isViewMore, setIsViewMore] = useState(isOpenViewMore);

  return (
    <div className='view-content-wrap'>
      <div className='row w-100 mx-auto view-more-button-div'>
        <div className='col-lg-4'></div>
        <div className='d-md-flex col-lg-8 '>
          <div className='view-more-item resposive-mt-2 w-100 d-flex display-view'>
            <Typography
              onClick={() => setIsViewMore(!isViewMore)}
              className='button-view-more mt-2'
            >
              {!isViewMore ? (
                <>
                  <Add className='add-icon mr-2' />
                  <Typography>{VIEW_MORE}</Typography>
                </>
              ) : (
                <>
                  <Remove className='add-icon mr-2' />
                  <Typography>{VIEW_LESS}</Typography>
                </>
              )}
            </Typography>
          </div>
        </div>
      </div>
      {isViewMore && (
        <div className='row m-0'>
          <div className='col-lg-12 view-more-section pl-0 pr-0'>
            <Typography className='lbl-details'>{`${DETAILS}: `}</Typography>
            <Typography className='sub-para'>{statusText}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export const UpcomingCarrierDetails = (props: any) => {
  const { quote, lob } = props;
  const statusText: string = quote?.error?.statusText || '';
  const isUpcomingCarrier = [COMING_SOON, INACTIVE].includes(statusText);
  const isSinglePremiumLob: boolean = [
    INSURANCE_BUSINESS_TYPES.HOME,
    INSURANCE_BUSINESS_TYPES.AUTO,
  ].includes(lob?.toUpperCase());
  const isShowBridgeToCarrierButton: boolean = isSinglePremiumLob && !isEmpty(quote?.agentUrl);
  return (
    <>
      <div
        className={`${
          isShowBridgeToCarrierButton ? 'col-lg-4' : 'col-lg-8'
        } d-flex align-items-center justify-content-center coming-soon-wrap`}
      >
        {isUpcomingCarrier && <Typography className='coming-soon-text'>{statusText}</Typography>}
        <Typography className='error-text error-label text-center'>
          {statusText === COMING_SOON
            ? UPCOMING_CARRIER_TEXT.NOT_INTEGRATED
            : UPCOMING_CARRIER_TEXT.INACTIVE}
        </Typography>
      </div>
      {isUpcomingCarrier && isShowBridgeToCarrierButton && (
        <div className='col-lg-4 coming-soon-bridg-carrier-btn d-flex align-items-center justify-content-center'>
          <PurchaseOnlineButtonDetails quote={quote} />
        </div>
      )}
    </>
  );
};

export const PurchaseOnlineButtonDetails = (props: any) => {
  const { quote } = props;
  return (
    <>
      {/* eslint-disable-next-line */}
      <a
        href={quote?.agentUrl}
        target='_blank'
        rel='noopener noreferrer'
        className='align-items-center d-flex justify-content-center'
      >
        <img
          src={getPurchaseIcon(quote)}
          alt='credit card'
          className={`img-fluid carrier-img mb-1`}
        />
        <Typography className='position-relative cursor-pointer purchase-online-label coming-soon-carrier-purchase-type'>
          {UPCOMING_CARRIER_TEXT.BTC}
        </Typography>
      </a>
    </>
  );
};
