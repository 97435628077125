import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React from 'react';
import {
  BUSINESS_TYPE_ICON_MAPPING,
  INSURANCE_BUSINESS_TYPES,
  LOB_PRODUCTS_MAPPING,
} from '../../../constants';
import ThemesImages from '../../../pages/components/themes-images';
import { excludeAmsForLobs, getThemeType, showAmsButtonAsPerLob } from '../../../utils';
import { DrawIncompleteQuoteProps, IncompleteQuoteDrawerProps } from './types/rates-step-details';
const { AUTO, CYBERSECURITY, FLOOD, HOME, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
  INSURANCE_BUSINESS_TYPES;

export const DrawNoQuoteAvailable: React.FC<{
  type: string;
}> = ({ type }) => {
  const themeType = getThemeType();
  return (
    <Grid container spacing={2} className='mt-3'>
      <Grid item xs={12}>
        <Card className='rates-block incomplete-quote-card' elevation={0}>
          <CardContent className='padding-left-0'>
            <div className='row m-0 incomplete-quote-wrap'>
              <Grid item xs={12} sm={3} className='padding-left-0'>
                <div className='insurence-logo-div'>
                  <img
                    src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type?.toUpperCase()]]}
                    alt='business'
                    className='img-fluid mx-auto d-flex'
                  />
                  <Typography className='product-label'>
                    {LOB_PRODUCTS_MAPPING[type.toLowerCase()]}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={9}>
                <div className='d-flex w-100 justify-content-center align-items-center flex-column incomplete-quote'>
                  <h1 className='cursor-default'>No Quotes Available</h1>
                </div>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const DrawIncompleteQuote: React.FC<DrawIncompleteQuoteProps> = ({
  type,
  targetTab,
  redirectToCertainTab,
  incompleteQuoteText,
  isVeriskLoading,
}) => {
  const boundedPolicy: any = showAmsButtonAsPerLob();
  const isDisabledIncompleteQuoteBtn: boolean =
    (!isEmpty(type) &&
      !boundedPolicy?.[type?.toLowerCase()] &&
      !excludeAmsForLobs.includes(type?.toLowerCase())) ||
    false;
  const themeType = getThemeType();
  return (
    <Grid container spacing={2} className='mt-3'>
      <Grid item xs={12}>
        <Card className='rates-block incomplete-quote-card' elevation={0}>
          <CardContent className='padding-left-0'>
            <div className='row m-0 incomplete-quote-wrap'>
              <Grid item xs={12} sm={4} className='padding-left-0'>
                <div className='insurence-logo-div'>
                  <img
                    src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type?.toUpperCase()]]}
                    alt='business'
                    className='img-fluid mx-auto d-flex'
                  />
                  <Typography className='product-label'>
                    {LOB_PRODUCTS_MAPPING[type.toLowerCase()]}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div
                  className={`d-flex w-100 justify-content-center align-items-center flex-column incomplete-quote ${
                    !!isDisabledIncompleteQuoteBtn ? 'disabled-imcomplete-quote' : ''
                  }`}
                  onClick={() => {
                    if (!isDisabledIncompleteQuoteBtn) {
                      redirectToCertainTab(targetTab, type);
                    }
                  }}
                >
                  {incompleteQuoteText(type, isVeriskLoading)}
                </div>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const IncompleteQuoteDrawer: React.FC<IncompleteQuoteDrawerProps> = ({
  currentQuoteTab,
  incompleteQuoteText,
  redirectToCertainTab,
  productType = '',
  isVeriskLoading,
}) => {
  if ([QUAKE, HOMEWARRANTY, CYBERSECURITY, HOME].includes(currentQuoteTab?.toUpperCase()))
    return (
      <DrawIncompleteQuote
        type={currentQuoteTab || ''}
        targetTab={0}
        incompleteQuoteText={incompleteQuoteText}
        redirectToCertainTab={redirectToCertainTab}
        isVeriskLoading={isVeriskLoading}
      />
    );
  if ([PET, LIFE, UMBRELLA, AUTO].includes(currentQuoteTab))
    return (
      <DrawIncompleteQuote
        type={currentQuoteTab || ''}
        targetTab={1}
        incompleteQuoteText={incompleteQuoteText}
        redirectToCertainTab={redirectToCertainTab}
        isVeriskLoading={isVeriskLoading}
      />
    );

  if (currentQuoteTab === FLOOD) {
    return (
      <DrawIncompleteQuote
        type={currentQuoteTab || ''}
        targetTab={isEmpty(productType) ? 0 : 1}
        incompleteQuoteText={incompleteQuoteText}
        redirectToCertainTab={redirectToCertainTab}
        isVeriskLoading={isVeriskLoading}
      />
    );
  }
  return null;
};
