/* istanbul ignore file */
import * as commonType from '../constants';

export const appInit = (): AppRedux.Action => ({
  type: commonType.APP_INIT,
});

// Reset entire app
export const flushAppDetails = (): AppRedux.Action => ({
  type: commonType.FLUSH_APP_DETAILS,
});

// Common error handling
export const errorHandler = (error: any): AppRedux.Action => ({
  type: commonType.ERROR_OCCURRED,
  payload: error,
});

// Set partner config with herd_id to store
export const setPconfig = (payload: HelperState['pconfig']): AppRedux.Action => ({
  type: commonType.SET_PCONFIG,
  payload,
});

// Updates pconfig in common for quote exceeded message and flag
export const updatePconfig = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: commonType.UPDATE_PCONFIG,
  payload,
});

export const stepChanged = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: commonType.STEP_CHANGED,
  payload,
});

// Step submit on each step
export const stepSubmit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: commonType.STEP_SUBMITTED,
  payload,
});

// Step submit successful
export const stepSubmitSuccess = (): AppRedux.Action => ({
  type: commonType.STEP_SUBMITTED_SUCCESS,
});

// Step submit loader
export const stepSubmitLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.STEP_SUBMITTED_LOADER,
  payload,
});

// Set edit vehicle flag
export const setEditVehicleFlag = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_EDIT_VEHICLE_FLAG,
  payload,
});

export const setHomeQuoteFlag = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_HOMEQUOTE_FLAG,
  payload,
});

// Set loader while check home address after redirect
export const setHomeAddressCheck = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_HOMEADDRESS_CHECK,
  payload,
});

// Updates valid driver flag
export const setValidDriverFlag = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_VALID_DRIVER_FLAG,
  payload,
});

// Set flag for primary driver's missing spouse
export const setMissingSpouse = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_MISSING_SPOUSE,
  payload,
});

// Set missing other details driver ids list
export const setMissingDetailsDrivers = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: commonType.MISSING_DETAILS_DRIVERS,
  payload,
});

// Set flag if missing vehicles to show modal
export const setVehiclesMissing = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_VEHICLES_MISSING,
  payload,
});

// Get selected org config for quoting
export const fetchOrgConfig = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: commonType.FETCH_ORG_CONFIG,
  payload,
});

// Add new product to existing quote
export const addProduct = (payload: {
  isNewProduct: boolean;
  products: Array<string>;
  newProducts?: Array<string>;
}): AppRedux.Action => ({
  type: commonType.ADD_NEW_PRODUCT_TO_APPOINTMENTS,
  payload,
});

// Add new product to helper function
export const addHelperProduct = (payload: any): AppRedux.Action => ({
  type: commonType.ADD_NEW_PRODUCT_TO_APPOINTMENTS_HELPER,
  payload,
});

// clean products to helper function
export const cleanHelperProduct = (): AppRedux.Action => ({
  type: commonType.CLEAN_APPOINTMENTS_HELPER,
});

// Set org config loader flag
export const setOrgConfigLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_ORG_CONFIG_LOADER,
  payload,
});

// Set redirect flag for auto product
export const setAutoRedirectFlag = (payload: boolean): AppRedux.Action => ({
  type: commonType.AUTO_REDIRECT_FLAG,
  payload,
});

// Set redirect flag for home product
export const setHomeRedirectFlag = (payload: boolean): AppRedux.Action => ({
  type: commonType.HOME_REDIRECT_FLAG,
  payload,
});

export const getAutoQuotesFailed = (payload: boolean): AppRedux.Action => ({
  type: commonType.GET_AUTO_QUOTES_FAILED,
  payload,
});

export const setAMSDetailsLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_AMS_DETAILS_LOADER,
  payload,
});

export const setSfdcAMSDetailsLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_SFDC_AMS_DETAILS_LOADER,
  payload,
});

export const setLineOfBusiness = (payload: string): AppRedux.Action => ({
  type: commonType.SET_LINE_OF_BUSINESS,
  payload,
});

export const setRedirectedFrom = (payload: string): AppRedux.Action => ({
  type: commonType.SET_REDIRECTED_FROM,
  payload,
});

export const showLOBAMSDetails = (payload: boolean): AppRedux.Action => ({
  type: commonType.SHOW_LOB_AMS_DETAILS,
  payload,
});

export const showLOBAMSFormDetails = (payload: boolean): AppRedux.Action => ({
  type: commonType.SHOW_LOB_AMS_FORM_DETAILS,
  payload,
});

export const setRedirectToViewQuoteFlag = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_REDIRECT_TO_VIEW_QUOTE_VALUE,
  payload,
});

export const setShowGetQuoteButton = (payload: boolean): AppRedux.Action => ({
  type: commonType.SHOW_GET_QUOTE_BUTTON_FLAG,
  payload,
});

export const setSelectedBusinessOption = (payload: string): AppRedux.Action => ({
  type: commonType.SET_SELECTED_BUSINESS_OPTION,
  payload,
});

export const setHasFormChanged = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_FORM_HAS_CHANGED,
  payload,
});

export const setPolicyHeadersResponse = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_POLICY_CARRIER_RESPONSE,
  payload,
});

export const setApplicantFormChange = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_APPLICANT_FORM_CHANGE,
  payload,
});

export const setIsVeriskFetched = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_IS_VERISK_FETCHED,
  payload,
});

export const setIsVeriskLoading = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_IS_VERISK_LOADING,
  payload,
});

export const setShowVeriskVehicleModal = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_SHOW_VERISK_VEHICLE_MODAL,
  payload,
});

export const setShowVeriskDriverModal = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_SHOW_VERISK_DRIVER_MODAL,
  payload,
});

export const setSelectFirstDriver = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_SELECT_FIRST_DRIVER,
  payload,
});

export const submitNewProducts = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: commonType.SUBMIT_ORG_PRODUCTS,
  payload,
});

export const clearQuotes = (): AppRedux.Action => ({
  type: commonType.CLEAR_QUOTES,
});

export const clearHelpers = (): AppRedux.Action => ({
  type: commonType.CLEAR_HELPERS,
});

export const viewBoundedPolicyLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.VIEW_POLICY_BOUND_LOADER,
  payload,
});

export const fetchBoundedPolicyDetails = (payload: any): AppRedux.Action => ({
  type: commonType.FETCH_BOUNDED_POLICY_DATA,
  payload,
});

export const storeBoundedPolicyDetails = (payload: any): AppRedux.Action => ({
  type: commonType.STORE_BOUNDED_POLICY_DATA,
  payload,
});

export const saveSelectedCarrier = (payload: any): AppRedux.Action => ({
  type: commonType.SAVE_SELECTED_CARRIER,
  payload,
});

export const saveSlectedCarrierLoader = (payload: any): AppRedux.Action => ({
  type: commonType.SAVE_SELECTED_CARRIER_LOADER,
  payload,
});

export const updateSelectedCarriers = (payload: any): AppRedux.Action => ({
  type: commonType.UPDATE_SELECTED_CARRIERS,
  payload,
});

export const storeBoundedCarrierDetails = (payload: any): AppRedux.Action => ({
  type: commonType.BOUNDED_POLICY_DETAILS,
  payload,
});

export const clearAutoQuoteSate = (): AppRedux.Action => ({
  type: commonType.CLEAR_AUTO_QUOTE_STATE,
});

export const setSwitchDetailFromDriver = (payload: any): AppRedux.Action => ({
  type: commonType.SET_SWITCH_DETAIL_FROM_DRIVER,
  payload,
});

export const fetchQuotingOrganizationList = (): AppRedux.Action => ({
  type: commonType.FETCH_QUOTING_ORGANIZATION_LIST,
});

export const quotingOrganizationListLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.QUOTING_ORGANIZATION_LIST_LOADER,
  payload,
});

export const storeQuotingOrganizationList = (payload: any): AppRedux.Action => ({
  type: commonType.STORE_QUOTING_ORGANIZATION_LIST,
  payload,
});

export const storeQuotingErrorMessage = (payload: any): AppRedux.Action => ({
  type: commonType.STORE_QUOTING_ORGANIZATION_LIST_ERROR,
  payload,
});

export const setRatePageEnable = (payload: any): AppRedux.Action => ({
  type: commonType.SET_RATE_PAGE_ENABLE,
  payload,
});

export const storeEmptyLobErrorMessage = (payload: any): AppRedux.Action => ({
  type: commonType.STORE_EMPTY_LOB_ERROR_MESSAGE,
  payload,
});

export const updateSelectedProducts = (payload: any): AppRedux.Action => ({
  type: commonType.UPDATE_SELECTED_PRODUCTS,
  payload,
});

export const updateSelectedProductLoader = (payload: boolean): AppRedux.Action => ({
  type: commonType.UPDATE_SELECTED_PRODUCT_LOADER,
  payload,
});

export const updateRequoteProducts = (payload: any): AppRedux.Action => ({
  type: commonType.UPDATE_REQUOTE_PRODUCTS,
  payload,
});

export const saveApplicantError = (payload: boolean): AppRedux.Action => ({
  type: commonType.SAVE_APPLICANT_ERROR,
  payload,
});

export const storeCommonData = (payload: any): AppRedux.Action => ({
  type: commonType.STORE_COMMON_DATA,
  payload,
});

export const trackCarrierBridging = (payload: any): AppRedux.Action => ({
  type: commonType.TRACK_CARRIER_BRIDGING,
  payload,
});

export const setAllowEditQuotes = (payload: boolean): AppRedux.Action => ({
  type: commonType.SET_ALLOW_EDIT_QUOTES,
  payload,
});

export const setPageTracking = (payload: any): AppRedux.Action => ({
  type: commonType.SET_PAGE_TRACKING,
  payload,
});

export const setLobPriority = (payload: any): AppRedux.Action => ({
  type: commonType.SET_LOB_PRIORITY,
  payload,
});

export const setHeapDetailsLoaded = (payload: any): AppRedux.Action => ({
  type: commonType.SET_HEAP_DETAILS_LOADED,
  payload,
});

export const setAdhocOrganizationDetails = (payload: any): AppRedux.Action => ({
  type: commonType.SET_ADHOC_ORGANIZATION_DETAILS,
  payload,
});

export const setSfdcOrganizationDetails = (payload: any): AppRedux.Action => ({
  type: commonType.SET_SFDC_ORGANIZATION_DETAILS,
  payload,
});

export const stopAllLobPolling = (): AppRedux.Action => ({
  type: commonType.STOP_ALL_LOB_POLLING,
});

export const getSfdcAmsFormData = (payload: Array<any>): AppRedux.Action => ({
  type: commonType.FETCH_SFDC_AMS_FORM_DATA,
  payload,
});

export const setSfdcAmsFormData = (payload: any): AppRedux.Action => ({
  type: commonType.SET_SFDC_AMS_FORM_DATA,
  payload,
});

export const setIsSfdcSubmitSuccess = (payload: boolean) => ({
  type: commonType.IS_SFDC_SUBMIT_SUCCESS,
  payload,
});

export const setPrefillCall = (payload: boolean) => ({
  type: commonType.SET_PREFILL_CALL,
  payload,
});

export const requestBindForWholesale = (payload: any) => ({
  type: commonType.REQUEST_BIND_FOR_WHOLESALE,
  payload,
});

export const setRequestBindResponseDetails = (payload: any) => ({
  type: commonType.SET_REQUEST_BIND_RESPONSE_DETAILS,
  payload,
});

export const setRequestBindLoader = (payload: boolean) => ({
  type: commonType.REQUEST_BIND_FOR_WHOLESALE_LOADER,
  payload,
});

export const setLobRefreshLoader = (payload: boolean) => ({
  type: commonType.SET_LOB_REFRESH_LOADER,
  payload,
});

export const setPrefillDetailsForRequestBind = (payload: any): AppRedux.Action => ({
  type: commonType.SET_PREFILL_DETAILS_FOR_REQUEST_BIND,
  payload,
});

export const setOrganizationId = (payload: string): AppRedux.Action => ({
  type: commonType.SET_ORGANIZATION_ID,
  payload,
});
