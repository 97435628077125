import React, { CSSProperties, FC, ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import clsx from 'clsx';
import './select-autocomplete.scss';

const SelectAutoComplete: FC<AppComponents.SelectAutoCompleteProps> = ({
  name,
  options,
  getOptionLabel,
  getOptionValue,
  className,
  isSearchable = false,
  disabled = false,
  onChange,
  value,
  error = false,
}) => {
  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id: name,
      options,
      getOptionLabel,
      value: options?.find((option: any) => (getOptionValue(option) || '') === value),
      onChange: (_, value) => {
        onChange?.({ target: { value: getOptionValue(value), name } });
      },
    });

  const inputRef = useRef<HTMLInputElement>(null);
  const [pos, setPos] = useState<CSSProperties>({});
  const Portal: FC<{ children: ReactNode }> = ({ children }) =>
    ReactDOM.createPortal(
      <div className='select-portal'>{children}</div>,
      document.getElementById('root')! || document.createElement('div')
    );

  useEffect(() => {
    const pos = inputRef?.current?.getBoundingClientRect();
    setPos({
      top: (pos?.top || 0) + window.scrollY + (pos?.height || 0) + 'px',
      left: (pos?.left || 0) + window.scrollX + 'px',
      width: (pos?.width || 0) + 'px',
    });
    // eslint-disable-next-line
  }, [inputRef, window.innerWidth, inputRef?.current?.getBoundingClientRect()?.top]);

  return (
    <div className='select-autocomplete'>
      <div
        {...getRootProps()}
        className={clsx(
          'MuiInputBase-root MuiInput-root MuiInput-underline ',
          className,
          disabled && 'Mui-disabled',
          error && 'Mui-error'
        )}
        ref={inputRef}
      >
        <input
          readOnly={!isSearchable}
          disabled={disabled}
          className={clsx(
            'MuiNativeSelect-root MuiNativeSelect-select MuiInputBase-input MuiInput-input',
            !isSearchable && 'hide-search',
            disabled && 'Mui-disabled'
          )}
          {...getInputProps()}
        />
        <svg
          className='MuiSvgIcon-root MuiNativeSelect-icon'
          focusable='false'
          viewBox='0 0 24 24'
          aria-hidden='true'
        >
          <path d='M7 10l5 5 5-5z'></path>
        </svg>
      </div>
      <Portal>
        {groupedOptions.length > 0 && (
          <ul {...getListboxProps()} style={pos ?? {}}>
            {groupedOptions.map((option, index) => (
              <li {...getOptionProps({ option, index })} key={index}>
                {getOptionLabel(option) || ''}
              </li>
            ))}
          </ul>
        )}
      </Portal>
    </div>
  );
};

export default SelectAutoComplete;
