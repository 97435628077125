import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { CANCEL, CONTINUE, FLOOD_PENALTY, NO, YES } from '../../../constants';

const ConfirmationModal = (props: AppComponents.ConfirmationModalProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = (isDiscard: boolean) => {
    setOpen(false);
    props.closehandler(isDiscard);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const isConfirmButton = [
    CONTINUE.toLowerCase(),
    FLOOD_PENALTY.NON_ADMITTED_CONFIRMATION_BUTTON_TEXT.toLowerCase(),
  ].includes(props.yesText?.toLowerCase() || '');

  return (
    <div>
      <Dialog
        classes={{
          root: 'auto-dialog-container',
        }}
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        aria-labelledby='confirmation-dialog-title'
        disableBackdropClick={true}
      >
        {!props?.isHideHeader && (
          <>
            {!isEmpty(props.heading) ? (
              <DialogTitle id='confirmation-dialog-title'>{props.heading}</DialogTitle>
            ) : (
              <DialogTitle id='confirmation-dialog-title'>Delete</DialogTitle>
            )}
          </>
        )}

        <DialogContent>
          {isEmpty(props.message) ? (
            <DialogContentText>
              Are you sure you want to delete <em>{props.contentValue}</em>
              &nbsp;?
            </DialogContentText>
          ) : (
            <DialogContentText>{props.message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose(false);
            }}
            color='primary'
            className={`btn-no ${props.noText?.toLowerCase() === CANCEL.toLowerCase() && 'px-3'}`}
          >
            {props.noText}
          </Button>
          <Button
            onClick={() => {
              handleClose(true);
            }}
            color='primary'
            className={`btn-yes ${isConfirmButton ? 'px-3' : ''}`}
          >
            {props.yesText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  closehandler: PropTypes.func.isRequired,
  heading: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  contentValue: PropTypes.string,
  noText: PropTypes.string,
  yesText: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  open: false,
  noText: NO,
  yesText: YES,
}

export default ConfirmationModal;
