import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AppBar from '@material-ui/core/AppBar';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import adminQuoteIcon from '../../../assets/images/admin.svg';
import bulkQuoteIcon from '../../../assets/images/bulk-quote.png';
import gatorIcon from '../../../assets/images/gator-logo.svg';
import reportIcon from '../../../assets/images/gator-reports-logo.svg';
import history from '../../../history';
import {
  ADMIN_ROUTE,
  DEFAULT_BULK_QUOTE_PATH,
  DEFAULT_LOB_PATH,
  DEFAULT_REPORT_PATH,
} from '../../../constants';
import { userSignOut } from '../../redux/actions';

import './header.scss';

class HeaderComponent extends React.Component<
  AppComponents.HeaderComponentProps,
  AppComponents.HeaderComponentState
> {
  state: AppComponents.HeaderComponentState = {
    isAuto: false,
    anchorEl: null,
    open: false,
    isHomePage: false,
  };
  unlisten: any;

  componentDidMount() {
    this.unlisten = history.listen((location: any) => {
      if (location.pathname.includes('/home')) {
        this.setState({ isHomePage: true });
      } else {
        this.setState({ isHomePage: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  isAutoLine = (): boolean => history.location.pathname.includes('auto');

  isLogout = (): boolean => history.location.pathname.includes('logout');

  isLob = (): boolean =>
    [DEFAULT_LOB_PATH, DEFAULT_REPORT_PATH, '/login'].includes(history.location.pathname);

  isBulkQuote = (): boolean =>
    [DEFAULT_BULK_QUOTE_PATH, `${DEFAULT_BULK_QUOTE_PATH}/logout`].includes(
      history.location.pathname
    );

  isReport = (): boolean =>
    [DEFAULT_REPORT_PATH, `${DEFAULT_REPORT_PATH}/logout`].includes(history.location.pathname);

  isAdminUser = (): boolean => {
    const { adminUser } = this.props;
    return history.location.pathname.includes(ADMIN_ROUTE) && adminUser;
  };

  getSubTitleDetails = () => {
    if (this.isBulkQuote()) {
      return (
        <div className='bulk-quote-logo-wrapper'>
          <div className='divider'></div>
          <img src={bulkQuoteIcon} alt='bulk-quote-logo' className='img-fluid bulk-quote-logo' />
        </div>
      );
    } else if (this.isReport()) {
      return (
        <div className='report-logo-wrapper'>
          <div className='divider'></div>
          <img src={reportIcon} alt='report-logo' className='img-fluid report-logo' />
        </div>
      );
    }
    if (this.isAdminUser()) {
      return (
        <div className='admin-logo-wrapper'>
          <div className='divider'></div>
          <img src={adminQuoteIcon} alt='admin-quote-logo' className='img-fluid admin-logo' />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className='report-logo-wrapper'>
            <div className='divider'></div>
            <img src={gatorIcon} alt='report-logo' className='img-fluid report-logo' />
          </div>
          {!isEmpty(this.getCoBrandingLogoUrl()) && (!this.isLob() || this.state.isHomePage) && (
            <div className="d-flex coBranding-logo">
            <img className='img-fluid' src={this.getCoBrandingLogoUrl()} alt='' />
            </div>
          )}
        </React.Fragment>
      );
    }
  };

  toolBarDetails = () => {
    const { authUser } = this.props;
    return (
      <div className='container-fluid'>
        <div className='row header-ht'>
          <div
            className={
              !isEmpty(authUser) &&
              (this.isLob() || this.isBulkQuote() || this.isReport() || this.isAdminUser())
                ? 'col-lg-7 col-sm-7 d-flex'
                : 'col-lg-12 col-sm-12 d-flex'
            }
          >
            <img src={this.getLogoUrl()} alt='' className='img-fluid logo' />

            {this.getSubTitleDetails()}
          </div>
          <div className='col-lg-5 col-sm-5'>{this.userLogoutMenu()}</div>
        </div>
      </div>
    );
  };

  getLogoUrl = () => {
    const { pconfig, defaultPConfig } = this.props;
    if (pconfig) {
      const { dale_config } = pconfig;
      if (dale_config) {
        const { hippo_branding_on_dale, branding_on_dale_details } = dale_config;
        if (!isUndefined(hippo_branding_on_dale) && !hippo_branding_on_dale) {
          return !isEmpty(branding_on_dale_details.logo_url)
            ? branding_on_dale_details.logo_url
            : defaultPConfig.dale_config.branding_on_dale_details.logo_url;
        }
      }
      return defaultPConfig.dale_config.branding_on_dale_details.logo_url;
    }
    return defaultPConfig.dale_config.branding_on_dale_details.logo_url;
  };

  getCoBrandingLogoUrl = (): string => {
    const { pconfig, commonPconfig, defaultPConfig } = this.props;
    const configuration: AppComponents.HeaderConfig = (
      !isEmpty(pconfig) ? pconfig : !isEmpty(commonPconfig) ? commonPconfig : defaultPConfig
    ) as AppComponents.HeaderConfig;
    if (configuration) {
      const { hippo_branding_on_dale, branding_on_dale_details } =
        !!configuration?.dale_config && configuration.dale_config;
      let isCobranding = hippo_branding_on_dale;
      if (typeof hippo_branding_on_dale === 'string') {
        isCobranding = hippo_branding_on_dale === 'true' ? true : false;
      }
      if (!isUndefined(hippo_branding_on_dale) && isCobranding) {
        return !isEmpty(branding_on_dale_details.logo_url) ? branding_on_dale_details.logo_url : '';
      }
    }
    return '';
  };

  userLogoutMenu = () => {
    const { authUser, email } = this.props;
    return !isEmpty(authUser) &&
      (this.isLob() || this.isBulkQuote() || this.isReport() || this.isAdminUser()) ? (
      <div className='userWrap '>
        <Typography className='userName'>
          <span onClick={this.handleClick} style={{ cursor: 'pointer' }}>
            {email} <KeyboardArrowDownIcon className='downIcon' />
          </span>
        </Typography>
        <Menu
          className={`user-info`}
          id='simple-menu'
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 130,
              paddingTop: 0,
              paddingBottom: 0,
              top: '0px !important',
            },
            className: 'menu-list',
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.logOutAction();
            }}
          >
            <ExitToAppIcon />
            <span style={{ paddingLeft: '10px' }}>Logout</span>
          </MenuItem>
        </Menu>
        <IconButton
          edge='end'
          aria-label='account of current user'
          aria-haspopup='true'
          color='inherit'
          className='profile-icon'
          disabled={true}
        >
          <AccountCircle className='userIcon' style={{ color: 'white' }} />
        </IconButton>
      </div>
    ) : (
      ''
    );
  };

  handleClick = (event: any) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  logOutAction = () => {
    const { authUser } = this.props;
    this.props.userSignOut({ authUser });
  };

  render() {
    return (
      <div className='main header-wrapper'>
        <AppBar position='static'>{this.toolBarDetails()}</AppBar>
      </div>
    );
  }
}

const mapStateToProps = ({
  home,
  auth,
  common,
}: AppComponents.HeaderComponentStore): AppComponents.HeaderComponentStoreProps => {
  const { pconfig, defaultPConfig, showResult, viewQuotes, address, headerData } = home;
  const { authUser, email, adminUser } = auth;
  const { isHomeRedirect } = common;
  const commonPconfig = get(common, 'pconfig.pconfig', defaultPConfig);
  return {
    adminUser,
    authUser,
    email,
    pconfig,
    defaultPConfig,
    commonPconfig,
    showResult,
    viewQuotes,
    address,
    headerData,
    isHomeRedirect,
  };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.HeaderComponentDispatch => {
  return bindActionCreators({ userSignOut }, dispatchProp);
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
