import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { findIndex, forEach, get, isEmpty, isEqual, omit, values } from 'lodash';
import React, { MouseEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../../config/config';
import {
  DEFAULT_VEHICLE_INFO_VALUES,
  HEAP_LABELS,
  INVALID,
  MAXIMUM_ANNUAL_MILES,
  MINIMUM_ANNUAL_MILES,
  QUOTING_STEPS_PAGES_NAME,
  REQUIRED,
  STATE_COVERAGES,
  VEHICLE,
  VEHICLE_AGE_EXCEED_ERR,
  VEHICLE_DATA,
} from '../../../constants';
import { deleteDiffKeys, allowEditingQuote, getIsHeapAnalyticsEnabled } from '../../../utils';
import {
  addCurrentVehicle,
  addVehicle,
  deleteVehicleDetails,
  restoreVehicleDetails,
  saveAutoQuoteDetails,
  saveVehicleUpdates,
  setEditVehicleFlag,
  setFetchAutoQuotes,
  setHasFormChanged,
  setNextTabStep,
  setPolicyFlag,
  setQuotesLoaded,
  setShowVeriskDriverModal,
  setShowVeriskVehicleModal,
  setVehicleSelected,
  setVehiclesMissing,
  setSwitchDetailFromDriver,
  sortVehiclesList,
  updateContinueVehicleList,
  vehicleBack,
  vehicleContinue,
  vehicleSelectedForEdit,
  vinBack,
  setVehicleDetails,
} from '../../redux/actions';
import { coverageDataMapper, getCoverageDefaultValue, validateVin } from '../utils';
import template from './template';
import './vehicle.scss';

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      fontSize: '35px',
    },
    formControl: {
      margin: '10px 0px',
      minWidth: '100%',
    },
    continueSpinner: {
      color: 'white !important',
      marginBottom: '2px',
    },
    errorMsg: {
      color: '#FF0000',
      width: '100%',
      height: '0px',
      [theme.breakpoints.down('xs')]: {
        height:'inherit',
      },
    },
    radioErrorMsg: {
      position: 'relative',
      top: '-19px',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      boxShadow: '0px 4px 6px #0000004D',
      border: '1px solid #C4C4C4',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    modalErrorMsg: {
      color: '#FF0000',
      width: '100%',
      height: '100%',
    },
  });

class Vehicle extends React.PureComponent<
  AppComponents.VehicleProps & WithStyles<typeof styles>,
  AppComponents.VehicleState
> {
  initVehicleErrors: AppComponents.VehicleErrors = {
    body_type_error: '',
    own_lease_error: '',
    how_long_error: '',
    vin_error: '',
    modal_error: '',
    primary_use_error: '',
    approx_miles_error: '',
  };

  state: AppComponents.VehicleState = {
    showVehicleForm: false,
    missingVehicleDialog: false,
    vehicleDetailsMissingDialog: false,
    operationDetails: '',
    entity: 'vehicle',
    body_type: '',
    own_lease: '',
    how_long: '',
    vin: '',
    defaultVin: '',
    comprehensive: '',
    collision: '',
    towing_labor: '',
    ext_trans_expense: '',
    primary_use: '',
    approx_miles: '',
    vehicleAdded: false,
    comprehensive_data: [],
    collision_data: [],
    towing_labor_data: [],
    ext_trans_expense_data: [],
    vehicleErrors: { ...this.initVehicleErrors },
    continueButtonClick: false,
    getQuoteButtonClick: false,
    deleteDialog: false,
    deleteSelectedDetails: null,
    saveDialog: false,
    selectedVuid: '',
    missingInformation: false,
    previousVehicle: false,
    vehicleLimitReached: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { vehicle, address, add, showVeriskVehicleModal, isVeriskLoading } = this.props;
    const { year, make, model } = vehicle.vehicleSelectorDetails;
    const { state } = address;
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
   

    this.props.sortVehiclesList();
    // todo: Find better way to avoid skipping driver info
    this.props.setPolicyFlag(false);
    if (!this.props.quoteCompleted && !this.props.quotesLoading) {
      this.props.setQuotesLoaded(false);
    }
    const showForm =
      vehicle.vinInput ||
      (!isEmpty(year) && !isEmpty(make) && !isEmpty(model) && vehicle.vehicleAdded) ||
      vehicle.viewQuote || showVeriskVehicleModal;
    if (!showForm) {
      if (isHeapAnalyticsEnabled) {
        window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P1);
      }
      if (vehicle.vehiclesList.length === config.maxVehicleLimit) {
        this.props.vehicleSelectedForEdit(
          vehicle.vehiclesList[vehicle.vehiclesList.length - 1].vuid
        );
      }
    }
    if(showForm && vehicle.vehiclesList.length && showVeriskVehicleModal && !isVeriskLoading) {
      this.props.vehicleSelectedForEdit(vehicle.vehiclesList[0].vuid);
      this.props.setVehicleSelected(true);
    }
    if (!isEmpty(state)) {
      const upperCaseState = state.toUpperCase();
      this.setState(
        {
          ...omit(vehicle, [
            'getQuoteButtonClick',
            'selectedVuid',
            'vehicleLimitReached',
            'getQuoteButtonClick',
          ]),
          comprehensive_data: [
            ...coverageDataMapper(STATE_COVERAGES[upperCaseState].OtherCollisionDeductible),
          ],
          collision_data: [
            ...coverageDataMapper(STATE_COVERAGES[upperCaseState].CollisionDeductible),
          ],
          towing_labor_data: [
            ...coverageDataMapper(STATE_COVERAGES[upperCaseState].TowingDeductible),
          ],
          ext_trans_expense_data: [
            ...coverageDataMapper(STATE_COVERAGES[upperCaseState].RentalDeductible),
          ],
        },
        () => {
          this.setState({
            showVehicleForm: showForm,
            ...omit(vehicle, [
              'getQuoteButtonClick',
              'selectedVuid',
              'vehicleLimitReached',
              'getQuoteButtonClick',
            ]),
            ...this.getVehicleCoveragesDetails(),
            own_lease: get(vehicle, 'own_lease', '')
              ? vehicle.own_lease
              : VEHICLE_DATA.own_lease[0].value,
            primary_use: vehicle?.primary_use ? vehicle?.primary_use : VEHICLE_DATA.vehicleUsage[3].value,
          });
          if (add) {
            this.addAnotherVehicleHandler();
          }
        }
      );
    }
    if (!this.props.vehiclesMissing) {
      this.setState({ missingVehicleDialog: true });
    }
  }
  
  componentDidUpdate(prevProps: AppComponents.VehicleProps, prevState: AppComponents.VehicleState) {
    if (prevState.showVehicleForm !== this.state.showVehicleForm) {

      let isHeapAnalyticsEnabled: boolean =
        !!window.heap && getIsHeapAnalyticsEnabled();
      if (isHeapAnalyticsEnabled) {
        if (this.state.showVehicleForm) {
          window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P2);
        } else {
          window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P1);
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.VehicleProps) {
    const { vehicle, switchFromDriver } = newProps;
    if (!isEmpty(switchFromDriver) ) {
      if (switchFromDriver?.isVehicleToDriver) {
        this.submitStep(null, false);
      } else if (switchFromDriver?.isRedirectToRatePage) {
        newProps.goToLastTab(newProps.currentIndex);
      }
      this.props.setSwitchDetailFromDriver({});
    }
    if (
      !newProps.loading &&
      !newProps.stepSubmitLoader &&
      vehicle &&
      !vehicle.vehicleLoader &&
      !vehicle.vinLoader &&
      !isEqual(this.props.vehicle, vehicle)
    ) {
      const { vehicle } = newProps;
      let vinNo = get(vehicle, 'vin', '');
      this.setState(
        {
          ...omit(vehicle, [
            'getQuoteButtonClick',
            'selectedVuid',
            'vehicleLimitReached',
            'getQuoteButtonClick',
          ]),
          body_type: get(vehicle, 'body_type', ''),
          own_lease: get(vehicle, 'own_lease', '')
            ? vehicle.own_lease
            : VEHICLE_DATA.own_lease[0].value,
          how_long: !isEmpty(get(vehicle, 'how_long', '')) ?
            get(vehicle, 'how_long') : DEFAULT_VEHICLE_INFO_VALUES.how_long,
          vin: !!vehicle?.isVinCleared ? '' : vinNo,
          defaultVin:
            !isEmpty(vinNo) && !!vehicle?.vinInput ? vinNo : get(vehicle, 'defaultVin', ''),
          comprehensive: get(vehicle, 'comprehensive', ''),
          collision: get(vehicle, 'collision', ''),
          towing_labor: get(vehicle, 'towing_labor', ''),
          ext_trans_expense: get(vehicle, 'ext_trans_expense', ''),
          primary_use: get(vehicle, 'primary_use', '')
            ? vehicle.primary_use
            : VEHICLE_DATA.vehicleUsage[3].value,
          approx_miles: !isEmpty(get(vehicle, 'approx_miles', '')) ?
            get(vehicle, 'approx_miles') : DEFAULT_VEHICLE_INFO_VALUES.approx_miles,
          vehicleAdded: get(vehicle, 'vehicleAdded', false),
        },
        () => {
          const { comprehensive_data, collision_data, towing_labor_data, ext_trans_expense_data } =
            this.state;
          this.setState({
            comprehensive: getCoverageDefaultValue(this.state.comprehensive, comprehensive_data),
            collision: getCoverageDefaultValue(this.state.collision, collision_data),
            towing_labor: getCoverageDefaultValue(this.state.towing_labor, towing_labor_data),
            ext_trans_expense: getCoverageDefaultValue(
              this.state.ext_trans_expense,
              ext_trans_expense_data
            ),
            saveDialog: false,
          });
        }
      );
    }
    if (newProps.vehicle.vinInput && newProps.vehicle.bodyTypeList.length > 0) {
      this.setState({ body_type: newProps.vehicle.bodyTypeList[0].value });
    }

    if (
      !newProps.vehicle.selectedVehicle ||
      (newProps.vehicle.vinInput && !newProps.vehicle.vinLoader)
    ) {
      this.setState(
        {
          showVehicleForm: newProps.vehicle.selectedVehicle,
        },
        () => {
          const { isVeriskLoading, showVeriskVehicleModal, vehicle, vehicleSelectedForEdit } = newProps;
          if (
            !isVeriskLoading &&
            showVeriskVehicleModal &&
            vehicle.vehiclesList.length &&
            vehicle.vuid !== vehicle.vehiclesList[0].vuid
          ) {
            vehicleSelectedForEdit(vehicle.vehiclesList[0].vuid);
            this.setState({
              ...omit(vehicle.vehiclesList[0], [
                'getQuoteButtonClick',
                'selectedVuid',
                'vehicleLimitReached',
                'getQuoteButtonClick',
              ]),
            });
          }
        }
      );
    }
    if (!newProps.vehiclesMissing) {
      this.setState({ missingVehicleDialog: true });
    }
    const { getQuoteButtonClick } = this.state;
    const { currentIndex, goToLastTab } = this.props;

    if (getQuoteButtonClick && this.validateInputs(this.state)) {
      const { vehicleIndex, shouldContinue } = this.validateVehiclesList(
        newProps.vehicle.vehiclesList
      );
      if (!shouldContinue && vehicleIndex !== null) {
        this.props.vehicleSelectedForEdit(vehicle.vehiclesList[vehicleIndex].vuid);
        this.props.setPolicyFlag(false);
        setTimeout(() => {
          this.setState({ ...this.state, missingInformation: true, getQuoteButtonClick: false });
          this.validateInputs(this.state);
        }, 100);
      } else {
        goToLastTab(currentIndex);
      }
    }
  }

  componentWillUnmount() {
    this.props.setEditVehicleFlag(false);
  }

  getVehicleCoveragesDetails = () => {
    const {
      comprehensive_data,
      collision_data,
      towing_labor_data,
      ext_trans_expense_data,
      comprehensive,
      collision,
      towing_labor,
      ext_trans_expense,
    } = this.state;
    return {
      comprehensive: getCoverageDefaultValue(comprehensive, comprehensive_data),
      collision: getCoverageDefaultValue(collision, collision_data),
      towing_labor: getCoverageDefaultValue(towing_labor, towing_labor_data),
      ext_trans_expense: getCoverageDefaultValue(ext_trans_expense, ext_trans_expense_data),
    };
  };

  handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (target.name === 'body_type') {
      this.setState({
        defaultVin: !isEmpty(target.value.split('|'))
          ? target.value.split('|')[1]
          : this.state.defaultVin,
      });
    }
    target.name && this.setState({ [target.name]: target.value });
  };

  vinSelectorContinueHandler = (event: MouseEvent, isGetQuote: boolean) => {
    const { vehicle } = this.props;
    const detailsAdded =
      vehicle.isVinErrorCleared || !!vehicle?.isVinCleared
        ? false
        : vehicle.vinInput ||
          (!isEmpty(vehicle.vehicleSelectorDetails.year) &&
            !isEmpty(vehicle.vehicleSelectorDetails.make) &&
            !isEmpty(vehicle.vehicleSelectorDetails.model));
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P1_SUBMIT, {
        [HEAP_LABELS.ENTRY_TYPE]: !!vehicle?.vinInput ? HEAP_LABELS?.VIN : HEAP_LABELS?.DETAILS,
      });
    }
    this.setState(
      {
        showVehicleForm: detailsAdded,
        vehicleErrors: {
          body_type_error: '',
          own_lease_error: '',
          how_long_error: '',
          vin_error: '',
          modal_error: '',
          primary_use_error: '',
          approx_miles_error: '',
        },
      },
      () => {
        if (!this.state.showVehicleForm) {
          this.setState({
            vehicleErrors: {
              modal_error: vehicle.isVinErrorCleared
                ? ''
                : '*Type valid VIN or Select Year, Make and Model',
              body_type_error: '',
              own_lease_error: '',
              how_long_error: '',
              vin_error: '',
              primary_use_error: '',
              approx_miles_error: '',
            },
          });
        } else {
          this.props.addCurrentVehicle();
        }
        this.props.setEditVehicleFlag(false);
      }
    );
    if (isGetQuote) {
      this.setState({
        getQuoteButtonClick: true,
      });
    }

    event.preventDefault();
  };

  validateInputs = (state: any): boolean => {
    const { isIncluded, vehicleSelectorDetails } = state;
    const { vehicle } = this.props;
    if (isIncluded) {
      const fieldNames = ['body_type', 'own_lease', 'how_long', 'primary_use', 'vin'];
      const defaultErrors: AppComponents.VehicleErrors = { ...this.initVehicleErrors };
      let currentYear = new Date().getFullYear();
      let carYear: any =
        currentYear -
        (parseInt(
          !isEmpty(vehicleSelectorDetails?.year) || !isEmpty(state?.year)
            ? vehicleSelectorDetails?.year || state?.year
            : !!vehicle?.year && !!vehicle?.vinInput
            ? vehicle?.year
            : ''
        ) -
          1);
      if (carYear === 0) {
        carYear = 1;
      }
      forEach(fieldNames, fieldName => {
        if (state[fieldName].length === 0 && fieldName !== fieldNames[4]) {
          defaultErrors[`${fieldName}_error`] = REQUIRED;
        }
        if (
          fieldName === fieldNames[2] &&
          !isEmpty(state[fieldName]) &&
          parseInt(state[fieldName]) > parseInt(carYear)
        ) {
          defaultErrors.how_long_error = VEHICLE_AGE_EXCEED_ERR;
        }
      });
      if (state.vin.length > 0) {
        if (!validateVin(state.vin)) {
          defaultErrors['vin_error'] = INVALID;
        } else {
          defaultErrors['vin_error'] = '';
        }
      }
      if (state.approx_miles.length === 0) {
        defaultErrors[`approx_miles_error`] = REQUIRED;
      } else if (
        !isEmpty(state.approx_miles) &&
        (parseInt(state.approx_miles) < MINIMUM_ANNUAL_MILES ||
          parseInt(state.approx_miles) > MAXIMUM_ANNUAL_MILES)
      ) {
        defaultErrors[
          `approx_miles_error`
        ] = `min: ${MINIMUM_ANNUAL_MILES} or max: ${MAXIMUM_ANNUAL_MILES}`;
      }
      this.setState({ vehicleErrors: { ...defaultErrors } });
      return values(defaultErrors).every(isEmpty);
    } else {
      return true;
    }
  };

  hasFormChanged = () => {
    let hasChanged = false;
    const copiedState = {
      ...omit(deleteDiffKeys(this.state, this.props.vehicle), [
        'getQuoteButtonClick',
        'vehicleSelectorDetails.yearList',
        'defaultVin',
        'saveDialog',
        'selectedVuid',
        'vehiclesList',
        'bodyTypeList',
      ]),
    };

    const copiedVehicle = {
      ...omit(this.props.vehicle, [
        'getQuoteButtonClick',
        'vehicleSelectorDetails.yearList',
        'defaultVin',
        'saveDialog',
        'selectedVuid',
        'vehiclesList',
        'bodyTypeList',
      ]),
    };

    if (!isEqual(copiedState, copiedVehicle)) {
      hasChanged = true;
    }
    return hasChanged;
  };

  isVehiclesEnabled = () => {
    const { vehicle } = this.props;
    let isEnabled = true;
    let totalExcludedVehicles = 0;
    vehicle.vehiclesList.forEach((vehicle: any) => {
      if (!vehicle.isIncluded) totalExcludedVehicles++;
    });

    if (totalExcludedVehicles === vehicle.vehiclesList?.length) {
      isEnabled = false;
    }

    return isEnabled;
  };

  openVehicleLimitModal = () => {
    this.setState({ ...this.state, vehicleLimitReached: true });
  };

  closeVehicleLimitModal = () => {
    this.setState({ ...this.state, vehicleLimitReached: false });
  };

  validateVehiclesList = (
    list: Array<any>
  ): { shouldContinue: boolean; vehicleIndex: null | number } => {
    let shouldContinue = true;
    let vehicleIndex: number | null = null;
    if (list && list.length) {
      for (let index = 0; index < list.length; index++) {
        if (!list[index].isIncluded) continue;
        if (!this.validateInputs(list[index])) {
          vehicleIndex = index;
          shouldContinue = false;
          break;
        }
      }
    }
    return { shouldContinue, vehicleIndex };
  };

  submitStep = (event: any, getQuoteClicked: boolean) => {
    const {
      onNextTab,
      currentIndex,
      vehicle,
      quoteList,
      setHasFormChanged,
      vehicleContinue,
      saveAutoQuoteDetails,
    } = this.props;
    const lastVehicleInList = vehicle.vehiclesList[vehicle.vehiclesList.length - 1];
    const disableAutoEditing = allowEditingQuote();
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P2_SUBMIT);
    }
      
    if (disableAutoEditing?.auto) {
      onNextTab(currentIndex);
    } else if (this.isVehiclesEnabled() && this.validateInputs(this.state)) {
      if (getQuoteClicked) {
        this.setState({
          getQuoteButtonClick: true,
        });
      }
      this.setState(
        {
          vehicleErrors: {
            body_type_error: '',
            own_lease_error: '',
            how_long_error: '',
            vin_error: '',
            modal_error: '',
            primary_use_error: '',
            approx_miles_error: '',
          },
        },
        () => {
          if (this.hasFormChanged() || !quoteList.length) {
            setHasFormChanged(true);
          }

          vehicleContinue({
            getQuoteClick: getQuoteClicked,
            data: { ...omit(this.state, ['getQuoteButtonClick', 'missingInformation']) },
          });

          if (this.hasFormChanged()) {
            saveAutoQuoteDetails(VEHICLE);
          }

          if (!getQuoteClicked && this.state.vuid && this.state.vuid === lastVehicleInList.vuid) {
            const vehiclesListCopy = vehicle.vehiclesList.slice(0, -1);
            const { vehicleIndex, shouldContinue } = this.validateVehiclesList(vehiclesListCopy);
            if (!shouldContinue && vehicleIndex !== null) {
              this.props.vehicleSelectedForEdit(vehicle.vehiclesList[vehicleIndex].vuid);
              this.props.setPolicyFlag(false);
              setTimeout(() => {
                this.setState({ ...this.state, missingInformation: true });
                this.validateInputs(this.state);
              }, 100);
            } else {
              onNextTab(currentIndex);
            }
          }
        }
      );
    } else if (
      !vehicle.isIncluded &&
      this.state.vuid &&
      this.state.vuid !== lastVehicleInList.vuid
    ) {
      vehicleContinue({
        getQuoteClick: getQuoteClicked,
        data: { ...this.state },
      });
    }
    if (event) {
      event.preventDefault();
    }
  };

  saveCurrentVehicleDetails = () => {
    const {
      body_type,
      own_lease,
      how_long,
      vin,
      defaultVin,
      comprehensive,
      collision,
      towing_labor,
      ext_trans_expense,
      primary_use,
      approx_miles,
    } = this.state;
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P2_SUBMIT);
    }
    this.props.addVehicle({
      clear: true,
      body_type,
      own_lease,
      how_long,
      vin,
      comprehensive,
      collision,
      towing_labor,
      ext_trans_expense,
      primary_use,
      approx_miles,
      defaultVin,
      vehicleAdded: true,
    });
  };

  validateBeforeEditHandler = ({ vuid, vinInput }: { vuid: string; vinInput: boolean }) => {
    const { vehicleAdded, body_type } = this.state;

    if (this.hasFormChanged()) {
      this.setState({
        ...this.state,
        saveDialog: true,
        selectedVuid: vuid,
        previousVehicle: false,
      });
    } else {
      !vinInput && this.props.setEditVehicleFlag(true);
      vehicleAdded && this.props.saveVehicleUpdates({ body_type } as any);
      this.props.vehicleSelectedForEdit(vuid);
      this.props.setVehicleSelected(false);
      this.setState({
        ...this.state,
        vehicleErrors: {
          ...this.initVehicleErrors
        }
      })
    }
  };

  modalClose = (shouldSave: boolean) => {
    const { vehicleAdded, body_type, previousVehicle } = this.state;
    if (shouldSave) {
      this.setState({
        ...this.state,
        saveDialog: false,
        previousVehicle: false,
      });
      if (this.validateInputs(this.state)) {
        this.props.setHasFormChanged(true);
        this.props.saveAutoQuoteDetails(VEHICLE);
        this.props.updateContinueVehicleList(this.state);
        this.setState(
          {
            ...this.state,
            vehicleErrors: {
              body_type_error: '',
              own_lease_error: '',
              how_long_error: '',
              vin_error: '',
              modal_error: '',
              primary_use_error: '',
              approx_miles_error: '',
              saveDialog: false,
            },
          },
          () => {
            vehicleAdded && this.props.saveVehicleUpdates({ body_type } as any);
            this.props.setEditVehicleFlag(true);
            if (!previousVehicle) {
              this.props.setVehicleSelected(false);
            }
            this.props.vehicleSelectedForEdit(this.state.selectedVuid);
            if (previousVehicle) {
              this.props.setVehicleSelected(true);
            }
          }
        );
      }
    } else {
      this.setState({
        ...this.state,
        saveDialog: false,
        vehicleErrors: {
          ...this.initVehicleErrors
        }
      });
      vehicleAdded && this.props.saveVehicleUpdates({ body_type } as any);
      this.props.vehicleSelectedForEdit(this.state.selectedVuid);
      this.props.setEditVehicleFlag(true);
      if (!previousVehicle) {
        this.props.setVehicleSelected(false);
      } else {
        this.props.setVehicleSelected(true);
      }
    }
  };

  updateVehicleErrors = () => {
    this.setState({
      vehicleErrors: {
        body_type_error: '',
        own_lease_error: '',
        how_long_error: '',
        vin_error: '',
        modal_error: '',
        primary_use_error: '',
        approx_miles_error: '',
      },
    });
  };

  addAnotherVehicleHandler = (event?: MouseEvent) => {
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.ADD_VEHICLE);
    }
    const { add } = this.props;
    if (add || this.validateInputs(this.state)) {
      
      this.props.setEditVehicleFlag(true);
      if (!add || (add && this.validateInputs(this.state))) {
        this.saveCurrentVehicleDetails();
      }
      this.resetVehicleForm();
      window.scrollTo(0, 0);
    }
    if (event) {
      event.preventDefault();
    }
  };

  resetVehicleForm = () => {
    this.setState({
      showVehicleForm: false,
      missingVehicleDialog: false,
      vehicleDetailsMissingDialog: false,
      operationDetails: '',
      entity: 'vehicle',
      body_type: '',
      own_lease: '',
      how_long: '',
      vin: '',
      defaultVin: '',
      comprehensive: '',
      collision: '',
      towing_labor: '',
      ext_trans_expense: '',
      primary_use: '',
      approx_miles: '',
      vehicleAdded: false,
      // comprehensive_data: [],
      // collision_data: [],
      // towing_labor_data: [],
      // ext_trans_expense_data: [],
    });
  };

  vehicleMissingModalCloseHandler = () => {
    this.setState({ missingVehicleDialog: false }, () => this.props.setVehiclesMissing(false));
  };

  vehicleDetailsModalCloseHandler = (isDiscard: boolean) => {
    if (isDiscard) {
      this.setState({ showVehicleForm: false });
      if (this.state.operationDetails === 'back') {
        this.props.setEditVehicleFlag(true);
        this.props.vehicleBack();
        setTimeout(() => {
          this.addAnotherVehicleHandler();
        }, 100);
      } else {
        this.props.vehicleSelectedForEdit(this.state.operationDetails);
        this.props.deleteVehicleDetails({ vuid: this.state.vuid });
      }
    }
    this.setState({ vehicleDetailsMissingDialog: false, operationDetails: '' });
  };

  vehicleBackHandler = (event: MouseEvent) => {
    const { vehicle, onPrevTab, currentIndex } = this.props;
    const { body_type, vehicleAdded } = this.state;
    const currentVehicleIndex = findIndex(vehicle.vehiclesList, { vuid: vehicle.vuid });
    if (this.hasFormChanged() && currentVehicleIndex > 0) {
      this.setState({
        ...this.state,
        saveDialog: true,
        selectedVuid: vehicle.vehiclesList[currentVehicleIndex - 1].vuid,
        previousVehicle: true,
        vehicleErrors: {
          ...this.initVehicleErrors
        },
      });
      this.props.setVehicleSelected(true);
    } else {
      this.setState(
        {
          vehicleErrors: {
            body_type_error: '',
            own_lease_error: '',
            how_long_error: '',
            vin_error: '',
            modal_error: '',
            primary_use_error: '',
            approx_miles_error: '',
          },
        },
        () => {
          if (vehicleAdded || vehicle.vehiclesList.length === 1) {
            vehicleAdded && this.props.saveVehicleUpdates({ body_type } as any);
            this.props.vehicleBack();
            if (currentVehicleIndex === 0) {
              this.props.setVehicleSelected(false);
            }
            (!vehicleAdded || vehicle.vehiclesList.length === 1) &&
              this.props.setEditVehicleFlag(true);
          } else if (currentVehicleIndex > 0) {
            this.props.vehicleSelectedForEdit(vehicle.vehiclesList[currentVehicleIndex - 1].vuid);
            this.props.setVehicleSelected(true);
          }
          this.props.setNextTabStep(false);
          if (!this.state.showVehicleForm) {
            onPrevTab(currentIndex);
          }
        }
      );
    }
  };

  closeMissingInformationModal = () => {
    this.setState({ ...this.state, missingInformation: false });
  };

  render() {
    return template(this);
  }
}

const mapStateToProps = ({
  common,
  quote,
  applicant,
  vehicle,
  floodDetails,
  home,
  driver,
  partner,
}: AppComponents.VehicleStore & AppComponents.FloodQuestionnaireProps) => {
  const {
    deleteDetailsLoader,
    loading,
    stepSubmitLoader,
    vehiclesMissing,
    isVehicleEdit,
    isAddingProducts,
    showGetQuoteButton,
    showVeriskVehicleModal,
    showVeriskDriverModal,
    switchFromDriver,
    policyBounded,
    isVeriskLoading,
  } = common;
  const { quoteCompleted, quoteErrorList, quoteList, quotesLoading } = quote;
  const { address } = applicant;
  const { submitLoader, annexPrefill } = floodDetails;
  const { driversList } = driver;
  return {
    deleteDetailsLoader,
    loading,
    stepSubmitLoader,
    vehiclesMissing,
    quoteCompleted,
    address,
    vehicle,
    isVehicleEdit,
    submitLoader,
    isAddingProducts,
    showGetQuoteButton,
    home,
    quoteErrorList,
    quoteList,
    quotesLoading,
    showVeriskVehicleModal,
    driversList,
    showVeriskDriverModal,
    switchFromDriver,
    policyBounded,
    partner,
    annexPrefill,
    isVeriskLoading,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.VehicleDispatch => {
  return bindActionCreators(
    {
      addCurrentVehicle,
      addVehicle,
      deleteVehicleDetails,
      setVehiclesMissing,
      setEditVehicleFlag,
      vehicleBack,
      vehicleContinue,
      vehicleSelectedForEdit,
      saveVehicleUpdates,
      vinBack,
      restoreVehicleDetails,
      setNextTabStep,
      setPolicyFlag,
      setQuotesLoaded,
      setFetchAutoQuotes,
      setHasFormChanged,
      saveAutoQuoteDetails,
      setVehicleSelected,
      setShowVeriskVehicleModal,
      updateContinueVehicleList,
      setShowVeriskDriverModal,
      sortVehiclesList,
      setSwitchDetailFromDriver,
      setVehicleDetails
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(Vehicle));
