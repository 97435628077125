/* istanbul ignore file */
import { isUndefined } from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_DEDUCTABLE } from '../../../constants';
import { formatDob } from '../../../utils';
import { PrefillStatus } from '../../components/property-info/property-info-status-banner';
import * as types from '../constants';

export interface PrefillDetails {
  propertyData: Record<string, any>;
  updatedPropertyData?: Record<string, string>;
  label: Record<string, string>;
}
export interface ApplicantState {
  formLoader: boolean;
  addressInvalid: boolean;
  personal_details: AppRedux.PersonalDetails;
  address: AppRedux.ApplicantAddress;
  isMovedInPastMonths: boolean;
  isApplicantSubmittedOnceForAuto: boolean;
  previousAddress: AppRedux.ApplicantAddress | null;
  isApplicantSuccess: boolean;
  saveApplicantLoader: boolean;
  prefillDetails: PrefillDetails | null;
  prefillStatus: PrefillStatus | null;
  noOfRetries: number;
  propertyInfoLoader: boolean;
  isPropertyInfoRequired?: boolean;
  userPropertyInformation: any;
  isApplicantFilled: boolean;
  isAddProductSaveApplicantLoading: boolean;
  savePropertyInfoloader: boolean;
  isUpdatedPropertyInfo: boolean;
  previousAddressInvalid?: boolean;
}

const INIT_APPLICANT_STATE: ApplicantState = {
  formLoader: false,
  addressInvalid: false, // Flag used to indicates invalid address
  personal_details: {
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    phone_number: '',
    phone: '',
    email: '',
    industry: '',
    occupation: '',
    education: '',
    residenceType: '',
    preferredDeductible: DEFAULT_DEDUCTABLE.value,
  },
  address: {
    street: '',
    unit: '',
    city: '',
    zip: '',
    state: '',
    county: '',
  },
  isMovedInPastMonths: false,
  isApplicantSubmittedOnceForAuto: false,
  previousAddress: null,
  isApplicantSuccess: false,
  saveApplicantLoader: false,
  prefillDetails: {
    propertyData: {},
    updatedPropertyData: {},
    label: {},
  },
  prefillStatus: null,
  noOfRetries: 0,
  propertyInfoLoader: false,
  userPropertyInformation: null,
  isApplicantFilled: true,
  isAddProductSaveApplicantLoading: false,
  savePropertyInfoloader: false,
  isUpdatedPropertyInfo: false,
};

export default (state = INIT_APPLICANT_STATE, action: AppRedux.Action): ApplicantState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        formLoader: false,
        addressInvalid: false,
      };
    }

    case types.APPLICANT_FORM_SUBMIT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        addressInvalid: false,
      };
    }

    case types.APPLICANT_FORM_SUBMIT_FAIL: {
      return {
        ...state,
        addressInvalid: true,
      };
    }

    case types.APPLICANT_FORM_LOADER: {
      return {
        ...state,
        formLoader: action.payload,
      };
    }

    case types.APPLICANT_DETAILS_FROM_HOME_INSURANCE: {
      const {
        personal_details,
        address,
        previousAddress,
        isMovedInPastMonths,
        isApplicantSubmittedOnceForAuto,
      } = action.payload as ApplicantState;
      return {
        ...state,
        address: {
          ...address,
          state: address.state.toUpperCase().trim(),
        },
        isMovedInPastMonths: !isUndefined(isMovedInPastMonths)
          ? isMovedInPastMonths
          : state.isMovedInPastMonths,
        isApplicantSubmittedOnceForAuto: !isUndefined(isApplicantSubmittedOnceForAuto)
          ? isApplicantSubmittedOnceForAuto
          : state.isApplicantSubmittedOnceForAuto,
        previousAddress: !!previousAddress
          ? {
              ...previousAddress,
              state: previousAddress.state.toUpperCase().trim(),
            }
          : state.previousAddress,
        personal_details: {
          ...personal_details,
          phone_number: !isEmpty(get(personal_details, 'phone', ''))
            ? personal_details.phone ?? ''
            : personal_details.phone_number,
          date_of_birth: formatDob(personal_details?.date_of_birth),
        },
      };
    }

    // Update applicant details
    case types.UPDATE_APPLICANT_DETAILS: {
      return {
        ...state,
        personal_details: {
          ...state.personal_details,
          ...action.payload,
        },
      };
    }

    // View page - update applicant address
    case types.UPDATE_APPLICANT_ADDRESS: {
      return {
        ...state,
        address: {
          ...state.address,
          ...action.payload,
        },
      };
    }

    case types.UPDATE_ADDRESS_INVALID_FLAG: {
      return {
        ...state,
        addressInvalid: action.payload,
      };
    }

    case types.CLEAR_APPLICANT_STATE: {
      return {
        ...INIT_APPLICANT_STATE,
      };
    }

    case types.SET_APPLICANT_SUCCESS: {
      return {
        ...state,
        isApplicantSuccess: action.payload,
      };
    }

    case types.SAVE_APPLICANT_LOADER: {
      return {
        ...state,
        saveApplicantLoader: action.payload,
      };
    }

    case types.SET_APPLICANT_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_PREFILL_DETAILS: {
      const { propertyData, label, updatedPropertyData } = action.payload;
      return {
        ...state,
        prefillDetails: {
          propertyData: {
            ...state?.prefillDetails?.propertyData,
            ...propertyData,
          },
          updatedPropertyData: {
            ...state?.prefillDetails?.updatedPropertyData,
            ...updatedPropertyData,
          },
          label: { ...state?.prefillDetails?.label, ...label },
        },
      };
    }

    case types.SET_USER_PROPERTY_INFORMATION: {
      return {
        ...state,
        userPropertyInformation: action.payload,
      };
    }

    case types.SET_ADD_PRODUCT_SAVE_APPLICANT_LOADER: {
      return {
        ...state,
        isAddProductSaveApplicantLoading: action.payload,
      };
    }

    case types.SET_PROPERTY_INFO_LOADER: {
      return {
        ...state,
        propertyInfoLoader: action.payload,
      };
    }

    case types.SAVE_PROPERTY_INFO_DETAILS_LOADER: {
      return {
        ...state,
        savePropertyInfoloader: action.payload,
      };
    }

    case types.SET_IS_UPDATED_PROPERTY_INFO: {
      return {
        ...state,
        isUpdatedPropertyInfo: action.payload,
      };
    }

    default:
      return state;
  }
};
