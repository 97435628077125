/* istanbul ignore file */
import * as AMSActionType from '../constants';

export const setAMSDetails = (payload: any): AppRedux.Action => ({
  type: AMSActionType.SET_AMS_DETAILS,
  payload,
});

export const AMSDetailsSubmit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: AMSActionType.AMS_DETAILS_SUBMIT,
  payload,
});

export const sfdcAmsDetailsSubmit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: AMSActionType.SFDC_AMS_DETAILS_SUBMIT,
  payload,
});

export const setAMSDetailsSuccess = (payload: boolean) => ({
  type: AMSActionType.AMS_DETAILS_SUCCESS,
  payload,
});

export const showAMSModal = (payload: boolean): AppRedux.Action => ({
  type: AMSActionType.SHOW_AMS_MODAL,
  payload,
});

export const setAMSError = (payload: string): AppRedux.Action => ({
  type: AMSActionType.SET_AMS_ERROR,
  payload,
});

export const setSfdcAMSError = (payload: string): AppRedux.Action => ({
  type: AMSActionType.SET_SFDC_AMS_ERROR,
  payload,
});

export const fetchAMSDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: AMSActionType.FETCH_AMS_DETAILS,
  payload,
});

export const getCarrierUnderwriterList = (payload: any): AppRedux.Action => ({
  type: AMSActionType.GET_CARRIER_UNDERWRITER_LIST,
  payload,
});

export const setCarrierUnderwriterList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: AMSActionType.SET_CARRIER_UNDERWRITER_LIST,
  payload,
});

export const getCarrierUnderwriterListForSfdc = (payload: any): AppRedux.Action => ({
  type: AMSActionType.GET_CARRIER_UNDERWRITER_LIST_FOR_SFDC,
  payload,
});

export const setCarrierUnderwriterListForSfdc = (
  payload: AppRedux.ActionPayload
): AppRedux.Action => ({
  type: AMSActionType.SET_CARRIER_UNDERWRITER_LIST_FOR_SFDC,
  payload,
});

export const setCarrierUnderwriterLoader = (payload: boolean): AppRedux.Action => ({
  type: AMSActionType.SET_CARRIER_UNDERWRITER_LOADER,
  payload,
});

export const setMaxAttemptReached = (payload: boolean): AppRedux.Action => ({
  type: AMSActionType.SET_MAX_ATTEMPT_REACHED,
  payload,
});

export const setPolicyBounded = (payload: any): AppRedux.Action => ({
  type: AMSActionType.SET_POLICY_BOUNDED,
  payload,
});

export const disableDivision = (payload: boolean): AppRedux.Action => ({
  type: AMSActionType.DISABLE_DIVISION,
  payload,
});

export const setAMSLOBDetails = (payload: any): AppRedux.Action => ({
  type: AMSActionType.SET_AMS_LOB_DETAILS,
  payload,
});

export const setAMSLOBChanged = (payload: any): AppRedux.Action => ({
  type: AMSActionType.SET_AMS_LOB_CHANGED,
  payload,
});

export const fetchLOBAMSDetails = (): AppRedux.Action => ({
  type: AMSActionType.FETCH_LOB_AMS_DETAILS,
});

export const fetchSFDCLOBDetails = (): AppRedux.Action => ({
  type: AMSActionType.FETCH_SFDC_LOB_DETAILS,
});
