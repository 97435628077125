/* istanbul ignore file */
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import axios, { AxiosError } from 'axios';
import axiosRequest from '../../api';
import * as organizationsActions from '../constants';
import {
  addEditEoiSettingloader,
  addEditOrganizationLoader,
  errorHandler,
  getOrganizationDataLoader,
  setLeadTypeListLoader,
  organizationLoader,
  removeCarrierAppointmentFromlist,
  removeEoiSettingsFromList,
  removeHcrIframeSettingFormList,
  saveUpdatedEoiSettingtoList,
  setAccountRecordTypeList,
  setAllowUpdateLeadRecordType,
  setDuplicateLeadIdErrorMessage,
  setEoiSettingsListLoader,
  setHcrIframeClientLoader,
  setOrgUpdatedResponseData,
  storeCarrierAppointmentsData,
  storeEoiSettingsList,
  storeHcrIframeSettings,
  storeHcrIframeSettingsList,
  storeLeadTypeListData,
  storeNewOrganizationData,
  storeOrganizationData,
  storeOrganizationListData,
  storeUpdatedOrganizationData,
} from '../actions';
import { ADMIN_ROUTE, NO_ORGANIZATION_DATA_MESSAGE } from '../../../constants';

function* fetchOrganozationsListData(): any {
  yield put(organizationLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/admin/get-organizations',
      isProviderOneLogin
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      yield put(
        storeOrganizationListData(
          !!responseDetails?.data?.organizationData ? responseDetails?.data?.organizationData : []
        ) as any
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response && axiosError?.response?.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(organizationLoader(false));
}

export function* fetchOrganozationsListDataWatcher() {
  yield takeEvery(organizationsActions.FETCH_ORGANIZATION_LIST, fetchOrganozationsListData);
}

function* getOrganizationDataById(payload: any): any {
  yield put(getOrganizationDataLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/admin/get-organizations',
      isProviderOneLogin,
      {
        id: payload?.payload?.id,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      yield put(
        storeOrganizationData(
          !!responseDetails?.data?.organizationData &&
            !isEmpty(responseDetails?.data?.organizationData)
            ? { ...responseDetails?.data?.organizationData[0] }
            : {}
        ) as any
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response && axiosError?.response?.status === 404) {
      yield put(push(ADMIN_ROUTE));
      yield NotificationManager.error(
        axiosError?.response?.data?.Message.includes('invalid input syntax for type uuid')
          ? NO_ORGANIZATION_DATA_MESSAGE
          : axiosError?.response?.data?.Message
      );
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(getOrganizationDataLoader(false));
}

export function* getOrganizationDataByIdDataWatcher() {
  yield takeEvery(organizationsActions.GET_ORGANIZATION_DATA, getOrganizationDataById);
}

function* addNewOrganization({ payload }: any): any {
  yield put(addEditOrganizationLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
      auth: { email, userId },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/admin/create-organization',
      isProviderOneLogin,
      {
        ...payload,
        updated_by_id: userId,
        updated_by_email: email,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      const { data } = !!responseDetails?.data && responseDetails?.data;
      if (
        !!responseDetails?.data?.Message &&
        responseDetails?.data?.Message.includes('successfully')
      ) {
        payload.id = data?.id;
        payload.created_at = new Date();
        yield put(storeNewOrganizationData(payload));
        yield put(setOrgUpdatedResponseData({ message: '' }));
        yield NotificationManager.success(responseDetails?.data?.Message);
      } else {
        yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
     if (!!axiosError.response && axiosError?.response?.status === 404) {
      yield put(setOrgUpdatedResponseData({ message: axiosError?.response?.data?.Message }));
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(addEditOrganizationLoader(false));
}

export function* addNewOrganizationWatcher() {
  yield takeEvery(organizationsActions.ADD_NEW_ORGANIZATION, addNewOrganization);
}

function* updateOrganizationData({ payload }: any): any {
  yield put(addEditOrganizationLoader(true));
  yield put(setDuplicateLeadIdErrorMessage(null));
  try {
    const {
      partner: { isProviderOneLogin },
      auth: { email, userId },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/admin/update-organization',
      isProviderOneLogin,
      {
        ...payload,
        updated_by_id: userId,
        updated_by_email: email,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      if (
        !!responseDetails?.data?.Message &&
        responseDetails?.data?.Message.includes('successfully')
      ) {
        const { data } = !!responseDetails?.data && responseDetails?.data;
        // Carrier appointments add/edit
        if (!isEmpty(payload?.carrier_credentials_grid)) {
          if (!payload?.carrier_credentials_grid[0]?.id) {
            // Append appointment carrier id in new carrier aapointment after update
            payload.carrier_credentials_grid[0].id = data?.id;
          }
          yield put(storeCarrierAppointmentsData(payload));
        } else {
          // Other child data updates
          yield put(storeUpdatedOrganizationData(payload));
        }
        if (!isEmpty(payload?.crm_lead_record_type_id)) {
          yield put(setAllowUpdateLeadRecordType(null));
        }
        yield put(setOrgUpdatedResponseData({ message: '' }));
        yield NotificationManager.success(responseDetails?.data?.Message);
      } else {
        yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (
      !!axiosError.response &&
      (axiosError?.response?.status === 404 || axiosError?.response?.status === 422) &&
      !!payload?.salesforce_enabled
    ) {
      yield put(setDuplicateLeadIdErrorMessage(axiosError?.response?.data?.Message));
    } else if (!!axiosError.response && axiosError?.response?.status === 409) {
      yield put(
        setAllowUpdateLeadRecordType({
          message: axiosError?.response?.data?.Message,
          isOpenAllowUpdateLeadRecordType: true,
        })
      );
    } else if (
      !!axiosError.response &&
      (axiosError.response.status === 404 || axiosError?.response?.status === 422)
    ) {
      yield put(setOrgUpdatedResponseData({ message: axiosError?.response?.data?.Message }));
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(addEditOrganizationLoader(false));
}

export function* updateOrganizationDataWatcher() {
  yield takeEvery(organizationsActions.UPDATE_ORGANIZATION_DATA, updateOrganizationData);
}

function* deleteCarrierAppointmentData({ payload }: any): any {
  yield put(addEditOrganizationLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/admin/delete-organization-carrier-appointment',
      isProviderOneLogin,
      {
        ...payload,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      if (
        !!responseDetails?.data?.Message &&
        responseDetails?.data?.Message.includes('successfully')
      ) {
        yield put(removeCarrierAppointmentFromlist(payload));
        yield put(setOrgUpdatedResponseData({ message: '' }));
        yield NotificationManager.success(responseDetails?.data?.Message);
      } else {
        yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(addEditOrganizationLoader(false));
}

export function* deleteCarrierAppointmentDataWatcher() {
  yield takeEvery(
    organizationsActions.DELETE_CARRIER_APPOINTMENTS_DATA,
    deleteCarrierAppointmentData
  );
}

function* fetchLeadTypeListData(): any {
  yield put(setLeadTypeListLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'GET',
      '/api/v1/crm/get-lead-record-types',
      isProviderOneLogin
    );
    if (!!responseDetails?.data?.success && !!responseDetails?.status && responseDetails?.status === 200) {
      const { records } = responseDetails?.data;
      yield put(storeLeadTypeListData(records));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(setLeadTypeListLoader(false));
}

export function* fetchLeadTypeListDataWatcher() {
  yield takeEvery(organizationsActions.FETCH_LEAD_TYPE_LIST, fetchLeadTypeListData);
}

function* fetchAccountRecordTypeListData(): any {
  yield put(setLeadTypeListLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'GET',
      '/api/v1/crm/get-account-record-types',
      isProviderOneLogin
    );
    if (
      !!responseDetails?.data?.success &&
      !!responseDetails?.status &&
      responseDetails?.status === 200
    ) {
      const { records } = responseDetails?.data;
      yield put(setAccountRecordTypeList(records));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(setLeadTypeListLoader(false));
}

export function* fetchAccountRecordTypeListDataWatcher() {
  yield takeEvery(
    organizationsActions.FETCH_ACCOUNT_RECORD_TYPE_LIST,
    fetchAccountRecordTypeListData
  );
}

function* fetchHcrIFrameClient({ payload }: AppRedux.Action<string>): any {
  try {
    yield put(setHcrIframeClientLoader(true));
    const {
      partner: { isProviderOneLogin },
    } = yield select();

    let responseData = yield axiosRequest(
      'GET',
      `/admin/get-iframe-client-settings/${payload}`,
      isProviderOneLogin,
      {}
    );
    if (!responseData?.data) {
      yield put(setHcrIframeClientLoader(false));
      return;
    }
    yield put(storeHcrIframeSettingsList(responseData?.data));
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    yield put(setHcrIframeClientLoader(false));
    yield put(errorHandler(axiosError));
    if (!axios.isAxiosError(axiosError)) {
      NotificationManager.error((axiosError as Record<string, unknown>).message);
      return;
    }
    if (axiosError.response?.status === 404) {
      yield put(storeHcrIframeSettingsList([]));
      return;
    }
    if (axiosError.response?.status === 400) {
      NotificationManager.error((axiosError as AxiosError<any>).response?.data?.join(', '));
      return;
    }
    NotificationManager.error((axiosError as AxiosError<any>).response?.data?.Message);
  }
  yield put(setHcrIframeClientLoader(false));
}

export function* fetchHcrIFrameClientWatcher() {
  yield takeEvery(organizationsActions.FETCH_HCR_IFRAME_CLIENT, fetchHcrIFrameClient);
}

function* addAndUpdateHcrIframeSetting({ payload }: any): any {
  yield put(addEditOrganizationLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
      auth: { email, userId },
    } = yield select();
    if(isEmpty(payload?.id)) {
      delete payload.id
    }
    const responseDetails = yield axiosRequest(
      `${!isEmpty(payload?.id) ? 'PUT' : 'POST'}`,
      '/admin/hcr-iframe-client-settings',
      isProviderOneLogin,
      {
        ...payload,
        updated_by: {
          id: userId,
          name: email
        }
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      if (!!responseDetails?.data?.success) {
        const { data } = !!responseDetails?.data && responseDetails?.data;
        if(isEmpty(payload?.id)) {
          payload.id = data?.id
        }
        yield put(storeHcrIframeSettings(payload));
        yield put(setOrgUpdatedResponseData({ message: '' }));
        yield NotificationManager.success(responseDetails?.data?.Message);
      } else {
        yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield put(setOrgUpdatedResponseData({ message: axiosError?.response?.data?.Message }));
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(addEditOrganizationLoader(false));
}

export function* addAndUpdateHcrIframeSettingsWatcher() {
  yield takeEvery(organizationsActions.ADD_AND_UPDATE_HCR_IFRAME_SETTINGS, addAndUpdateHcrIframeSetting);
}

function* deleteHcrIframeClientData({ payload }: any): any {
  yield put(setHcrIframeClientLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'DELETE',
      '/admin/hcr-iframe-client-settings',
      isProviderOneLogin,
      {
        ...payload,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      if (!!responseDetails?.data?.success) {
        yield put(removeHcrIframeSettingFormList(payload));
        yield put(setOrgUpdatedResponseData({ message: '', isUpdatedSuccessfully: true }));
        yield NotificationManager.success(responseDetails?.data?.Message);
      } else {
        yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(setHcrIframeClientLoader(false));
}

export function* deleteHcrIframeClientDataWatcher() {
  yield takeEvery(
    organizationsActions.DELETE_HCR_IFRAME_SETTINGS,
    deleteHcrIframeClientData
  );
}


function* fetchEoiSettings({ payload }: AppRedux.Action<string>): any {
  try {
    yield put(setEoiSettingsListLoader(true));
    const {
      partner: { isProviderOneLogin },
    } = yield select();

    let responseData = yield axiosRequest(
      'GET',
      `/admin/eoi-settings/${payload}`,
      isProviderOneLogin,
      {}
    );
    if (!responseData?.data) {
      yield put(setEoiSettingsListLoader(false));
      return;
    }
    yield put(storeEoiSettingsList(responseData?.data));
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    let statusCodeList: Array<any> = [400,404,500];
    yield put(setEoiSettingsListLoader(false));
    yield put(errorHandler(axiosError));
    if (!axios.isAxiosError(axiosError)) {
      NotificationManager.error((axiosError as Record<string, unknown>).message);
      return;
    }
    if (statusCodeList.includes(axiosError.response?.status)) {
      yield put(storeEoiSettingsList([]));
      return;
    }
    NotificationManager.error((axiosError as AxiosError<any>).response?.data?.Message);
  }
  yield put(setEoiSettingsListLoader(false));
}

export function* fetchEoiSettingsWatcher() {
  yield takeEvery(organizationsActions.FETCH_EOI_SETTINGS, fetchEoiSettings);
}

function* addAndUpdateEoiSettings({ payload }: any): any {
  yield put(addEditEoiSettingloader(true));
  try {
    const {
      partner: { isProviderOneLogin },
      auth: { email, userId },
    } = yield select();
    if(isEmpty(payload?.id)) {
      delete payload.id
    }
    const responseDetails = yield axiosRequest(
      `${!isEmpty(payload?.id) ? 'PUT' : 'POST'}`,
      '/admin/eoi-settings',
      isProviderOneLogin,
      {
        ...payload,
        updated_by: {
          id: userId,
          name: email
        }
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      const { data } = responseDetails?.data;
      if(isEmpty(payload?.id)) {
        payload.id = data.id
      }
      yield put(saveUpdatedEoiSettingtoList(payload));
      if(!isEmpty(responseDetails?.data?.Message)) {
        yield NotificationManager.success(responseDetails?.data?.Message);
      }
    } else {
      yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
      yield NotificationManager.error(responseDetails?.data?.Message);
    }
    yield put(addEditEoiSettingloader(false));
  } catch (error) {
    let statusCodeList: Array<any> = [400,404,409,500];
    const axiosError = error as AxiosError<any>;
    if (statusCodeList.includes(axiosError?.response?.status)) {
      yield put(addEditEoiSettingloader(false));
      yield put(setOrgUpdatedResponseData({ message: axiosError?.response?.data?.Message }));
      NotificationManager.error(axiosError?.response?.data?.Message);
      return;
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(addEditEoiSettingloader(false));
}

export function* addAndUpdateEoiSettingsWatcher() {
  yield takeEvery(organizationsActions.ADD_EDIT_EOI_SETTING, addAndUpdateEoiSettings);
}

function* deleteEoiSettingsData({ payload }: any): any {
  yield put(addEditEoiSettingloader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'DELETE',
      `/admin/eoi-settings/${payload.id}`,
      isProviderOneLogin
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      yield put(removeEoiSettingsFromList(payload));
      if(!isEmpty(responseDetails?.data?.Message)) {
        yield NotificationManager.success(responseDetails?.data?.Message);
      }
    } else {
      yield put(setOrgUpdatedResponseData({ message: responseDetails?.data?.Message }));
      if(!isEmpty(responseDetails?.data?.Message)) {
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(addEditEoiSettingloader(false));
}

export function* deleteEoiSettingsDataWatcher() {
  yield takeEvery(organizationsActions.DELETE_EOI_SETTING, deleteEoiSettingsData);
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrganozationsListDataWatcher),
    fork(getOrganizationDataByIdDataWatcher),
    fork(addNewOrganizationWatcher),
    fork(updateOrganizationDataWatcher),
    fork(deleteCarrierAppointmentDataWatcher),
    fork(fetchLeadTypeListDataWatcher),
    fork(fetchHcrIFrameClientWatcher),
    fork(addAndUpdateHcrIframeSettingsWatcher),
    fork(deleteHcrIframeClientDataWatcher),
    fork(fetchAccountRecordTypeListDataWatcher),
    fork(fetchEoiSettingsWatcher),
    fork(addAndUpdateEoiSettingsWatcher),
    fork(deleteEoiSettingsDataWatcher)
  ]);
}
