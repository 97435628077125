import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import {
  accidentFieldDeleted,
  accidentFieldUpdated,
  setHasFormChanged,
} from '../../redux/actions';
import {
  ACCIDENT_MAXIMUM_AMOUNT,
  ACCIDENT_MINIMUM_AMOUNT,
  OTHER_DETAILS_DATA,
} from '../../../constants';
import DatePicker from '../date-picker';
import { getIncidentFieldError, setOptionDetails, validateIncidentDate } from '../../auto/utils';
import NumberFormatCustom from '../currency-mask';

import './accidents-editor.scss';
import { hasViolationChanged, allowEditingQuote } from '../../../utils';

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      margin: '10px 0px',
      minWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    errorMsg: {
      color: '#FF0000',
      height: '0px',
    },
  });

class AccidentsEditor extends React.Component<
  AppComponents.AccidentDetailsProps & WithStyles<typeof styles>,
  AppComponents.AccidentDetailsState
> {
  static propTypes = {
    uid: PropTypes.string.isRequired,
  };
  initAccidentsErrors: AppComponents.AccidentDetailsErrors = {
    date_of_accidents_error: '',
    accident_description_error: '',
    vehicle_involved_error: '',
    pd_amount_error: '',
    bi_amount_error: '',
    collision_amount_error: '',
    mp_amount_error: '',
  };
  state: AppComponents.AccidentDetailsState = {
    date_of_accidents: '',
    accident_description: '',
    vehicle_involved: '',
    pd_amount: '',
    bi_amount: '',
    collision_amount: '',
    mp_amount: '',
    otherDetailsAccidentsErrors: { ...this.initAccidentsErrors },
  };

  getCurrentAccident = (list: Array<any>, uid: string) => find(list, ['accident_uid', uid]);

  checkAccidentAmountError = (accidentError: string, currentAccidentDetails: any) => {
    const list = ['pd_amount', 'bi_amount', 'collision_amount', 'mp_amount'];
    let errorFlag = false;
    forEach(list, item => {
      if (
        accidentError === `${item}_error` &&
        !isEmpty(currentAccidentDetails[item]) &&
        (currentAccidentDetails[item] < ACCIDENT_MINIMUM_AMOUNT ||
          currentAccidentDetails[item] > ACCIDENT_MAXIMUM_AMOUNT)
      ) {
        if (!errorFlag) {
          errorFlag = true;
        }
      }
    });
    return errorFlag;
  };

  componentDidMount() {
    const currentAccidentDetails = this.getCurrentAccident(this.props.accidentList, this.props.uid);
    this.setState({ ...this.state, ...currentAccidentDetails });
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.AccidentDetailsProps) {
    const { accidentList, accidentErrors, uid, stepSubmitLoader } = newProps;
    const currentAccidentDetails = this.getCurrentAccident(accidentList, uid);
    this.setState({ ...this.state, ...currentAccidentDetails });
    if (accidentErrors && !isEmpty(accidentErrors) && !stepSubmitLoader) {
      this.setState({ otherDetailsAccidentsErrors: { ...this.initAccidentsErrors } });
      const errorDetails: any = { ...this.state.otherDetailsAccidentsErrors };
      forEach(accidentErrors[uid], accidentError => {
        if (
          accidentError === 'date_of_accidents_error' &&
          !isEmpty(currentAccidentDetails.date_of_accidents)
        ) {
          const accidentDateErrorMessage = validateIncidentDate(
            currentAccidentDetails.date_of_accidents
          );
          errorDetails[accidentError] = !isUndefined(accidentDateErrorMessage)
            ? accidentDateErrorMessage
            : '';
        } else if (this.checkAccidentAmountError(accidentError, currentAccidentDetails)) {
          errorDetails[accidentError] = 'min: 1 or max: 999999';
        } else {
          errorDetails[accidentError] = getIncidentFieldError(
            this.state,
            accidentError.split('_error')[0]
          );
        }
      });
      this.setState({ otherDetailsAccidentsErrors: { ...errorDetails } });
    }
  }

  handleFieldChange = (event: any) => {
    const { uid, setHasFormChanged } = this.props;
    const { name, value } = event.target;
    this.setState({ [name]: value } as any, () => {
      if (
        hasViolationChanged({
          field: name,
          list: this.props.accidentList,
          value,
        })
      ) {
        setHasFormChanged(true);
      }
      this.props.accidentFieldUpdated({ name, value, uid });
    });
  };

  handleCalendarDate = (dateFromCalender: any) => {
    const { uid, setHasFormChanged } = this.props;
    const name = 'date_of_accidents';
    const value = dateFromCalender;
    this.setState({ [name]: value } as any, () => {
      if (
        hasViolationChanged({
          field: 'date_of_accidents',
          list: this.props.accidentList,
          value,
        })
      ) {
        setHasFormChanged(true);
      }
      this.props.accidentFieldUpdated({ name, value, uid });
    });
  };

  deleteAccidentList = () => {
    const { uid } = this.props;
    this.props.accidentFieldDeleted({ uid });
    this.props.setHasFormChanged(true);
  };

  render() {
    const { classes, accidentList, uid, isIncluded } = this.props;
    const {
      date_of_accidents,
      accident_description,
      vehicle_involved,
      pd_amount,
      bi_amount,
      collision_amount,
      mp_amount,
      otherDetailsAccidentsErrors,
    } = this.state;
    const {
      date_of_accidents_error,
      accident_description_error,
      vehicle_involved_error,
      pd_amount_error,
      bi_amount_error,
      collision_amount_error,
      mp_amount_error,
    } = otherDetailsAccidentsErrors;
    const disableAutoEdits = allowEditingQuote();
    return (
      <React.Fragment>
        <div className='row sub-title-blue mt-3 mb-3 mx-auto'>
          <div className='col-lg-10 d-flex align-items-center'>
            <Typography className='small-label-heading'>
              Accident {findIndex(accidentList, ['accident_uid', uid]) + 1}
            </Typography>
          </div>
          {accidentList.length > 1 && !disableAutoEdits?.auto && (
            <div className='col-lg-2 logo-container d-flex'>
              <Tooltip title='Delete' arrow placement='top'>
                <DeleteOutlinedIcon className='delete-logo' onClick={this.deleteAccidentList} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className='row mx-auto'>
          <div className='col-md-6'>
            <FormControl
              fullWidth
              className={`mb-0 ${classes.formControl} date_of_accidents ${
                date_of_accidents_error.length > 0 ? 'labelError' : ''
              }`}
              disabled={!isIncluded || disableAutoEdits?.auto}
            >
              <DatePicker
                disabled={!isIncluded || disableAutoEdits?.auto}
                label={
                  <span>
                    Date of Accidents<span className='star-error'>*</span>
                  </span>
                }
                name='date_of_accidents'
                onChange={this.handleCalendarDate}
                dateValue={date_of_accidents}
                error={date_of_accidents_error === '' ? false : true}
              />
            </FormControl>

            <FormHelperText className={classes.errorMsg}>{date_of_accidents_error}</FormHelperText>
          </div>
          <div className='col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor=''>
                Vehicle Involved
              </InputLabel>
              <Select
                native
                value={vehicle_involved}
                inputProps={{
                  id: 'vehicle_involved',
                  name: 'vehicle_involved',
                }}
                onChange={this.handleFieldChange}
                error={vehicle_involved_error === '' ? false : true}
              >
                {setOptionDetails(OTHER_DETAILS_DATA.vehicle_involved)}
              </Select>
              <FormHelperText className={classes.errorMsg}>{vehicle_involved_error}</FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className='row mx-auto'>
          <div className='col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor=''>
                Accident Description<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={accident_description}
                inputProps={{
                  id: 'accident_description',
                  name: 'accident_description',
                }}
                onChange={this.handleFieldChange}
                error={accident_description_error === '' ? false : true}
              >
                <option value=''>Select</option>
                {setOptionDetails(OTHER_DETAILS_DATA.accident_description)}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {accident_description_error}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className='row mx-auto'>
          <div className='col-lg-3 col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={`w-70 ${classes.formControl}`}>
              <InputLabel shrink htmlFor=''>
                PD Amount<span className='star-error'>*</span>
              </InputLabel>
              <Input
                value={pd_amount}
                inputProps={{
                  id: 'pd_amount',
                  name: 'pd_amount',
                }}
                onChange={this.handleFieldChange}
                error={pd_amount_error === '' ? false : true}
                inputComponent={NumberFormatCustom as any}
              />
              <FormHelperText className={classes.errorMsg}>{pd_amount_error}</FormHelperText>
            </FormControl>
          </div>
          <div className='col-lg-3 col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={`w-70 ${classes.formControl}`}>
              <InputLabel shrink htmlFor=''>
                BI Amount<span className='star-error'>*</span>
              </InputLabel>
              <Input
                value={bi_amount}
                inputProps={{
                  id: 'bi_amount',
                  name: 'bi_amount',
                }}
                onChange={this.handleFieldChange}
                error={bi_amount_error === '' ? false : true}
                inputComponent={NumberFormatCustom as any}
              />
              <FormHelperText className={classes.errorMsg}>{bi_amount_error}</FormHelperText>
            </FormControl>
          </div>
          <div className='col-lg-3 col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={`w-70 ${classes.formControl}`}>
              <InputLabel shrink htmlFor='' title='Collision Amount'>
                Collision Amount<span className='star-error'>*</span>
              </InputLabel>
              <Input
                value={collision_amount}
                inputProps={{
                  id: 'collision_amount',
                  name: 'collision_amount',
                }}
                onChange={this.handleFieldChange}
                error={collision_amount_error === '' ? false : true}
                inputComponent={NumberFormatCustom as any}
              />
              <FormHelperText className={classes.errorMsg}>{collision_amount_error}</FormHelperText>
            </FormControl>
          </div>
          <div className='col-lg-3 col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={`w-70 ${classes.formControl}`}>
              <InputLabel shrink htmlFor=''>
                MP Amount<span className='star-error'>*</span>
              </InputLabel>
              <Input
                value={mp_amount}
                inputProps={{
                  id: 'mp_amount',
                  name: 'mp_amount',
                }}
                onChange={this.handleFieldChange}
                error={mp_amount_error === '' ? false : true}
                inputComponent={NumberFormatCustom as any}
              />
              <FormHelperText className={classes.errorMsg}>{mp_amount_error}</FormHelperText>
            </FormControl>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  state: AppComponents.AccidentDetailsStore
): AppComponents.AccidentDetailsStoreProps => {
  const { stepSubmitLoader } = state.common;
  const { isIncluded } = state.driver;
  const { accidentList, accidentErrors } = state.otherDetails;
  return { accidentList, accidentErrors, stepSubmitLoader, isIncluded };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AccidentDetailsDispatch => {
  return bindActionCreators(
    { accidentFieldUpdated, accidentFieldDeleted, setHasFormChanged },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(AccidentsEditor));
