/* istanbul ignore file */
import * as types from '../constants';
import { isEmpty } from 'lodash';
interface OrganizationsState {
  organizationListLoader: boolean;
  organizationsList: Array<any>;
  editOrganizationData: any;
  addEditOrgLoader: boolean;
  orgResponseData: any;
  getOrganizationLoader: boolean;
  leadTypeListLoader: boolean;
  leadTypeList: Array<any>;
  orgAccountsList: Array<Record<string, string>>;
  hcrIframeClientListLoader: boolean;
  accountRecordTypeList: Array<Record<string, string>>;
  eoiSettingsList: Array<any>;
  eoiSettingsListLoader: boolean;
  addEditEoiSettingloader: boolean;
}

const INIT_FLOOD_STATE: OrganizationsState = {
  organizationListLoader: false,
  organizationsList: [],
  editOrganizationData: null,
  addEditOrgLoader: false,
  orgResponseData: null,
  getOrganizationLoader: false,
  leadTypeListLoader: false,
  leadTypeList: [],
  orgAccountsList: [],
  hcrIframeClientListLoader: false,
  accountRecordTypeList: [],
  eoiSettingsList: [],
  eoiSettingsListLoader: false,
  addEditEoiSettingloader: false
};

const INIT_STATE = { ...INIT_FLOOD_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        organizationListLoader: false,
        organizationsList: [],
        editOrganizationData: null,
        addEditOrgLoader: false,
      };
    }
    case types.ORGANIZATION_LIST_LOADER: {
      return {
        ...state,
        organizationListLoader: action.payload,
      };
    }
    case types.STORE_ORGANIZATION_LIST: {
      return {
        ...state,
        organizationsList: action.payload,
      };
    }
    case types.ADD_EDIT_ORGANIZATION_LOADER: {
      return {
        ...state,
        addEditOrgLoader: action.payload,
      };
    }
    case types.STORE_ORGANIZATION_DATA: {
      return {
        ...state,
        editOrganizationData: action.payload,
      };
    }
    case types.STORE_NEW_ORGANIZATION: {
      return {
        ...state,
        organizationsList: [action.payload, ...state.organizationsList],
        orgResponseData: {
          isUpdatedSuccessfully: true,
        },
      };
    }
    case types.STORE_UPDATED_ORGANIZATION_DATA: {
      const { id } = action.payload;
      let updatedData = state.editOrganizationData;
      if (!!id && state?.editOrganizationData?.id === id) {
        updatedData = {
          ...updatedData,
          ...action.payload,
        };
      }
      return {
        ...state,
        editOrganizationData: updatedData,
        orgResponseData: {
          isUpdatedSuccessfully: true,
        },
      };
    }
    case types.SET_ORG_UPDATED_RESPONSE_DATA: {
      return {
        ...state,
        orgResponseData: {
          ...action.payload,
        },
      };
    }
    case types.STORE_CARRIER_APPOINTMENTS_DATA: {
      let updatedData = state.editOrganizationData;
      let credientialGridData = [...state.editOrganizationData.carrier_credentials_grid];
      const elementsIndex = state.editOrganizationData.carrier_credentials_grid.findIndex(
        (element: any) => element.id === action.payload.carrier_credentials_grid[0].id
      );
      if (action?.payload?.id === updatedData.id && elementsIndex === -1) {
        updatedData = {
          ...updatedData,
          carrier_credentials_grid: [
            { ...action.payload.carrier_credentials_grid[0] },
            ...updatedData.carrier_credentials_grid,
          ],
        };
      } else {
        credientialGridData[elementsIndex] = {
          ...credientialGridData[elementsIndex],
          ...action.payload.carrier_credentials_grid[0],
        };
        updatedData = {
          ...updatedData,
          carrier_credentials_grid: [...credientialGridData],
        };
      }
      return {
        ...state,
        editOrganizationData: updatedData,
        orgResponseData: {
          isUpdatedSuccessfully: true,
        },
      };
    }
    case types.REMOVE_CARRIER_APPOINTMENTS_FROM_LIST: {
      let carrierAppointmentList = [...state.editOrganizationData.carrier_credentials_grid];
      const index = carrierAppointmentList.findIndex(
        (element: any) => element.id === action.payload.id
      );
      if (index > -1) {
        carrierAppointmentList.splice(index, 1);
      }
      return {
        ...state,
        editOrganizationData: {
          ...state.editOrganizationData,
          carrier_credentials_grid: !isEmpty(carrierAppointmentList) ? carrierAppointmentList : [],
        },
      };
    }
    case types.GET_ORGANIZATION_LOAGER: {
      return {
        ...state,
        getOrganizationLoader: action.payload,
      };
    }

    case types.LEAD_TYPE_LIST_LOADER: {
      return {
        ...state,
        leadTypeListLoader: action.payload,
      };
    }

    case types.STORE_LEAD_TYPE_LIST_DATA: {
      return {
        ...state,
        leadTypeList: action.payload,
      };
    }

    case types.SET_ORG_ACCOUNTS_LIST_DATA: {
      return {
        ...state,
        orgAccountsList: action.payload || [],
      };
    }

    case types.SET_DUPLICATE_LEAD_ID_ERROR_MESSAGE: {
      return {
        ...state,
        duplicateLeadIdErrorMessage: action.payload
      }
    }

    case types.HCR_IFRAME_CLIENT_LIST_LOADER: {
      return {
        ...state,
        hcrIframeClientListLoader: action.payload
      }
    }

    case types.STORE_HCR_IFRAME_SEETINGS_LIST: {
      return {
        ...state,
        editOrganizationData: {
          ...state.editOrganizationData,
          hcrIframeClientList: action.payload
        }
      }
    }

    case types.STORE_HCR_IFRAME_SETTINGS: {
      let updatedData: any = [...state.editOrganizationData.hcrIframeClientList];
      const elementsIndex = updatedData.findIndex(
        (element: any) => element.id === action.payload.id
      );
      if (elementsIndex === -1) {
        updatedData = [{ ...action.payload }, ...updatedData];
      } else {
        updatedData[elementsIndex] = {
          ...action.payload,
        };
      }
      return {
        ...state,
        editOrganizationData: {
          ...state.editOrganizationData,
          hcrIframeClientList: [...updatedData],
        },
        orgResponseData: {
          isUpdatedSuccessfully: true,
        },
      }
    }

    case types.REMOVE_HCR_IFRAME_SETTINGS_FROM_LIST: {
      let updatedList: Array<any> = [...state.editOrganizationData?.hcrIframeClientList];
      const index = updatedList.findIndex(
        (element: any) => element.id === action.payload.id
      );
      if (index > -1) {
        updatedList.splice(index, 1);
      }
      return {
        ...state,
        editOrganizationData: {
          ...state.editOrganizationData,
          hcrIframeClientList: [...updatedList],
        },
        orgResponseData: {
          isUpdatedSuccessfully: true,
        },
      }
    }

    case types.SET_ALLOW_UPDATE_LEAD_RECORD_TYPE: {
      return {
        ...state,
        leadRecordTypeAllowUpdateDetails: action.payload
      }
    }

    case types.SET_ACCOUNT_RECORD_TYPE_LIST: {
      return {
        ...state,
        accountRecordTypeList: action.payload
      }
    }

    case types.EOI_SETTINGS_LIST_LOADER: {
      return {
        ...state,
        eoiSettingsListLoader: action.payload
      }
    }

    case types.STORE_EOI_SETTINGS_LIST: {
      return {
        ...state,
        eoiSettingsList: action.payload
      }
    }

    case types.ADD_EDIT_EOI_SETTING_LOADER: {
      return {
        ...state,
        addEditEoiSettingloader: action.payload
      }
    }

    case types.SAVE_UPDATED_EOI_SETTINGS_TO_LIST: {
      let updatedData: any = [...state.eoiSettingsList];
      const elementsIndex = updatedData.findIndex(
        (element: any) => element?.id === action?.payload?.id
      );
      if (elementsIndex === -1) {
        updatedData = [{ ...action.payload }, ...updatedData];
      } else {
        updatedData[elementsIndex] = {
          ...action.payload,
        };
      }
      return {
        ...state,
        eoiSettingsList: updatedData,
        orgResponseData: {
          isUpdatedSuccessfully: true,
          message: ''
        },
      }
    }

    case types.REMOVE_EOI_SETTING_FROM_LIST: {
      let updatedList: Array<any> = [...state.eoiSettingsList];
      const index = updatedList.findIndex(
        (element: any) => element.id === action.payload.id
      );
      if (index > -1) {
        updatedList.splice(index, 1);
      }
      return {
        ...state,
        eoiSettingsList: updatedList,
        orgResponseData: {
          isUpdatedSuccessfully: true,
          message: ''
        },
      }
    }

    default:
      return state;
  }
};
