/* istanbul ignore file */
import * as viewQuoteActionType from '../constants';

export const fetchViewQuotes = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: viewQuoteActionType.FETCH_VIEW_QUOTES,
  payload,
});

export const viewQuoteLoader = (payload: boolean): AppRedux.Action => ({
  type: viewQuoteActionType.SET_CRM_VIEW_QUOTE_LOADER,
  payload,
});

export const setViewQuote = (payload: any): AppRedux.Action => ({
  type: viewQuoteActionType.SET_VIEW_QUOTES,
  payload,
});

export const startHomePolling = (): AppRedux.Action => ({
  type: viewQuoteActionType.START_HOME_QUOTE_POLLING,
});

export const validateQuoteUrl = (payload: any): AppRedux.Action => ({
  type: viewQuoteActionType.VALIDATE_QUOTE_URL,
  payload,
});

export const setValidateQuoteUrlLoader = (payload: boolean): AppRedux.Action => ({
  type: viewQuoteActionType.VALIDATE_QUOTE_URL_LOADER,
  payload,
});

export const setCrmSelectedLob = (payload: any) => ({
  type: viewQuoteActionType.SET_CRM_SELECTED_LOB,
  payload,
});
