import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import './show-image-after-loading.scss';

const ShowImageAfterLoading = (props: any) => {
  const { imageName, url } = props;
  const [loading, setLoading] = React.useState(true);
  return (
    <React.Fragment>
      <div className='show-image-wrapper'>
        <img
          src={url}
          className='image-rendering-logo'
          onLoad={() => {
            setLoading(false);
          }}
          title={imageName}
          alt={imageName}
        />
        {loading && <Skeleton className='skeleton-css' height={40} width={60} />}
      </div>
    </React.Fragment>
  );
};

export default ShowImageAfterLoading;
