import React from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const tablePaginationStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const getButtonStyle = () => ({ display: 'inline-block' });

const TablePagination = (props: AppComponents.TablePaginationActionsProps) => {
  const classes = tablePaginationStyles();
  const themeData = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const firstPageButtonClick = (event: any) => {
    onChangePage(event, 0);
  };

  const backButtonClick = (event: any) => {
    onChangePage(event, page - 1);
  };

  const nextButtonClick = (event: any) => {
    onChangePage(event, page + 1);
  };

  const lastPageButtonClick = (event: any) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title='First Page' aria-label='first page' arrow={true}>
        <div style={getButtonStyle()}>
          <IconButton onClick={firstPageButtonClick} disabled={page === 0} aria-label='first page'>
            {themeData.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title='Previous Page' aria-label='previous page' arrow={true}>
        <div style={getButtonStyle()}>
          <IconButton onClick={backButtonClick} disabled={page === 0} aria-label='previous page'>
            {themeData.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title='Next Page' aria-label='next page' arrow={true}>
        <div style={getButtonStyle()}>
          <IconButton
            onClick={nextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='next page'
          >
            {themeData.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title='Last Page' aria-label='last page' arrow={true}>
        <div style={getButtonStyle()}>
          <IconButton
            onClick={lastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label='last page'
          >
            {themeData.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default React.memo(TablePagination);
