import { v4 } from 'uuid';
import {
  createPageTracking,
  getPreferredDeductible,
  getResidencyStatus,
  shouldApplicantHasPreviousAddress,
} from '../../../../../utils';
import { LineOfBusiness } from '../../../../enums';
import { MapSaveApplicantRequestParams, SaveApplicantRequest } from '../types';

export const mapSaveApplicantRequest = ({
  applicant,
  appointmentKeys,
  appointments,
  authEmail,
  authUserId,
  clearDetails: { clearAutoInfo, clearLifeInfo, clearPetInfo, clearUmbrellaInfo, lobQuotesToClear },
  common,
  daleConfig,
  herdId,
  is_condo,
  lob,
  producerDetails,
  productType,
  storedRequestId,
  driversList,
  isVeriskPrefillAvailable,
  quotingDetails,
  umbrellaInfo,
}: MapSaveApplicantRequestParams) => {
  const { code, auth_token, name, id } = daleConfig || {};
  const organizationName = common.pconfig?.pconfig?.name || name;
  const organizationId = common.pconfig?.pconfig?.id || id;
  const quoteBy = (authEmail || producerDetails?.producerEmail) ?? '';

  let pageTracking = createPageTracking(!clearAutoInfo ? common.pageTracking : {}, appointments);
  let requestPayload: SaveApplicantRequest = {
    request: {
      pageTracking,
      lobQuotesToClear,
      clearAutoInfo,
      clearPetInfo,
      clearLifeInfo,
      clearUmbrellaInfo,
      personal_information: { ...applicant.personal_details },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: herdId,
      quote_by: quoteBy,
      id: authUserId,
      provider_request_id: storedRequestId,
      selected_lob: appointmentKeys,
      lob,
    },
    state: {
      driversList,
      isVeriskPrefillAvailable,
      quotingDetails,
      umbrellaInfo,
    },
  };

  if (shouldApplicantHasPreviousAddress()) {
    requestPayload.request.isMovedInPastMonths = applicant.isMovedInPastMonths;
    requestPayload.request.previousAddress =
      applicant.isMovedInPastMonths && applicant.previousAddress
        ? { ...applicant.previousAddress }
        : undefined;
  }

  if (lob === LineOfBusiness.HOME) {
    requestPayload.request.home_type = productType;
    requestPayload.request.is_condo = is_condo;
    requestPayload.request.preferredDeductible = getPreferredDeductible();
    requestPayload.request.request_id = v4();
    requestPayload.request.residency_status = getResidencyStatus();
    requestPayload.request.retrieveQuote = false;
  }
  return requestPayload;
};
