import React from 'react';
import PropTypes from 'prop-types';

import AMSButtonImg from '../../../assets/images/ams-button.png';

import './ams-button.scss';

const AMSButton = (props: any) => {
  const handleClick = () => {
    props.handleclick();
  };

  return (
    <div className='ams-button-wrapper'>
      <div onClick={handleClick} className='ams-div'>
        <img alt='ams button' className='ams-button-text' src={AMSButtonImg} />
      </div>
    </div>
  );
};

AMSButton.propTypes = {
  handleclick: PropTypes.func.isRequired,
};

export default AMSButton;
