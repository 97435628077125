/* istanbul ignore file */
import * as types from '../constants';

export interface AppointmentsItem {
  // todo: understand maybe we just need to store LOBS only and delete username and pass
  [key: string]: {
    quotingEngineCarrierId?: string;
    code?: string;
    displayName?: string;
    state?: string;
    username?: string;
    password?: string;
  };
}
interface PartnerState {
  appointments: AppointmentsItem;
  producerDetails: any;
  loader: boolean;
  storedRequestId: string;
  isProviderOneLogin: boolean;
  isProviderAms: boolean;
  quoteType?: any;
  role?: string;
}

const INIT_PARTNER_STATE: PartnerState = {
  appointments: {},
  producerDetails: null,
  loader: false,
  storedRequestId: '',
  isProviderOneLogin: true,
  isProviderAms: false,
};

const INIT_STATE = { ...INIT_PARTNER_STATE };

export default (state = INIT_STATE, action: AppRedux.Action): PartnerState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
      };
    }

    case types.ON_SHOW_APPOINTMENTS_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case types.SET_APPOINTMENTS: {
      const { appointments, producerDetails } = action.payload;
      return {
        ...state,
        appointments: appointments,
        producerDetails: producerDetails ? producerDetails : state.producerDetails,
      };
    }

    case types.SET_REQUEST_ID: {
      return {
        ...state,
        storedRequestId: action.payload,
      };
    }

    case types.SET_IS_AUTH_PROVIDER_ONELOGIN: {
      return {
        ...state,
        isProviderOneLogin: action.payload,
      };
    }

    case types.CLEAR_PARTNER_STATE: {
      return {
        ...INIT_STATE,
      };
    }

    case types.SET_PARTNER_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
