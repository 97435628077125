import store from '../pages/redux/store';
import {
  ADHOC_NON_ADMITTED_AMS_LOB_LIST,
  AMS_FORM_FIELD,
  CA,
} from '../constants/common';
import { EARTH_QUAKE_LOB, LOB_PRODUCTS_MAPPING } from '../constants';
import { isEmpty, isUndefined } from 'lodash';
import config from '../config/config';
import { ObjectEntity } from '../pages/redux/reducers/helper';

export const getIsAdhocAmsIsNonAdmitted = (
  formDetails: AppComponents.AMSFormDetails | AppComponents.SfdcAMSLandingDetails
) => {
  const {
    common: { AMSLOBDetails, AMSDetails, sfdcAmsFormData },
  } = store.getState();
  const { nonAdmittedLOBList, carriersWithAMSCodes, companyList } = AMSLOBDetails;
  const companyDetails = getDetailsFromArrayUsingKey(companyList, 'Name', formDetails?.carrierName);
  const carrierDetails = getDetailsFromArrayUsingKey(
    carriersWithAMSCodes,
    'amsCode',
    companyDetails?.CompanyCode
  );
  if (isEmpty(companyDetails) || isEmpty(carrierDetails) || !nonAdmittedLOBList) {
    return false;
  }
  const lob = formDetails?.businessLine?.toUpperCase() || '';
  const state: string =
    formDetails?.addressDetails?.state ||
    AMSDetails?.addressDetails?.state ||
    sfdcAmsFormData?.address?.state ||
    '';
  const nonAdmittedCarrierDetails = getDetailsFromArrayUsingKey(
    nonAdmittedLOBList[lob] || [],
    'carrierId',
    carrierDetails?.carrierId
  );
  return nonAdmittedCarrierDetails?.nonAdmittedStates?.includes(state?.toUpperCase()) || false;
};

export const isShowCarrierRiskConsentCheckbox = (
  formDetails: AppComponents.AMSFormDetails | AppComponents.SfdcAMSLandingDetails
) => {
  if (!config?.enableAdmittedOrNonAdmittedCarrier) {
    return false;
  }
  const lob = formDetails?.businessLine?.toLowerCase() || '';
  const isAdhocAms = ADHOC_NON_ADMITTED_AMS_LOB_LIST.includes(lob) || false;
  if (isAdhocAms) {
    return getIsAdhocAmsIsNonAdmitted(formDetails);
  }
  const carrierDetails = getAMSCarrierDetails(formDetails?.businessLine, formDetails?.carrierId);
  return !!carrierDetails && !isUndefined(carrierDetails.isAdmitted) && !carrierDetails.isAdmitted;
};

export const getAMSCarrierDetails = (lob: string, carrierId: string | number = '') => {
  const { common } = store.getState();
  lob = lob === LOB_PRODUCTS_MAPPING.quake ? EARTH_QUAKE_LOB : lob;
  if (isEmpty(common?.AMSLOBDetails?.carrierList) || isEmpty(lob)) {
    return null;
  }
  let carrierList: Array<ObjectEntity> = common?.AMSLOBDetails?.carrierList[lob] || [];
  let carrierDetails: any = carrierList.find(
    (item: any) => item?.carrierId?.toString() === carrierId?.toString()
  );
  return carrierDetails || null;
};

export const getAdhocAmsCarrierDetails = (companyCode: string = '') => {
  const { common } = store.getState();
  if (isEmpty(common?.AMSLOBDetails?.carriersWithAMSCodes) || isEmpty(companyCode)) {
    return null;
  }
  const carrierList: Array<ObjectEntity> = common?.AMSLOBDetails?.carriersWithAMSCodes || [];
  const carrierDetails = carrierList.find((item: ObjectEntity) => item?.amsCode === companyCode);
  return carrierDetails || null;
};

export const getDetailsFromArrayUsingKey = (
  list: Array<ObjectEntity> = [],
  key: string = '',
  value: string = ''
) => {
  return !isEmpty(key) && !isEmpty(value) && !isEmpty(list)
    ? list.find(item => item[key] === value)
    : {};
};

export const getIsShowSl2OrDueDiligenceForm = (
  formDetails: AppComponents.AMSFormDetails | AppComponents.SfdcAMSLandingDetails
) => {
  const {
    common: { AMSDetails, sfdcAmsFormData },
  } = store.getState();
  let carrierDetails = getAMSCarrierDetails(formDetails?.businessLine, formDetails?.carrierId);
  if (!carrierDetails && !isEmpty(formDetails?.selectedCarrier?.CompanyCode)) {
    carrierDetails = getAdhocAmsCarrierDetails(formDetails?.selectedCarrier?.CompanyCode);
  }
  const lob = formDetails?.businessLine?.toLowerCase() || '';
  const state: string =
    formDetails?.addressDetails?.state ||
    AMSDetails?.addressDetails?.state ||
    sfdcAmsFormData?.address?.state ||
    '';
  const excludeDocUploadForNonAdmittedCarriers = config?.excludeDocUploadForNonAdmittedCarriers || [];
  const isNonAdmittedCarrier: boolean = isShowCarrierRiskConsentCheckbox(formDetails) || false;
  if (
    !isNonAdmittedCarrier ||
    excludeDocUploadForNonAdmittedCarriers.includes(carrierDetails?.carrierId?.toString()) ||
    ![LOB_PRODUCTS_MAPPING.home, ...ADHOC_NON_ADMITTED_AMS_LOB_LIST].includes(lob)
  ) {
    return '';
  }
  if (state === CA) {
    return AMS_FORM_FIELD.SL2_FORM_DOCUMENT.KEY;
  }
  return AMS_FORM_FIELD.DUE_DILIGENCE_FORM_DOCUMENT.KEY;
};

export const getAmsSignatureForm = (key: string) => {
  const signatureDetails = {
    [AMS_FORM_FIELD.SL2_FORM_DOCUMENT.KEY]: !isEmpty(config?.nonAdmittedSL2PowerFormLink)
      ? config?.nonAdmittedSL2PowerFormLink
      : '',
    [AMS_FORM_FIELD.DUE_DILIGENCE_FORM_DOCUMENT.KEY]: !isEmpty(config?.nonAdmittedDueDiligencePowerFormLink)
      ? config?.nonAdmittedDueDiligencePowerFormLink
      : '',
  };
  return signatureDetails[key];
};
