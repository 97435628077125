import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import { isEmpty, startCase } from 'lodash';
import PhoneMask from '../../../components/phone-mask';
import { getIframeLobDropDown, hcrIframeThemeList } from '../../utils';
import {
  ASTERISK,
  DARK_GREEN_THEME,
  HCR_IFRAME_LABELS,
  LOB_KEY,
  PHONE_NUMBER,
  PHONE_NUMBER_LABEL,
  THEME,
} from '../../../../constants';
import '../style.scss';

export interface FormProps {
  addOrigin: () => void;
  formError: any;
  formValues: AppComponents.IFrameSettingsForm;
  isLoading: boolean;
  onChange: (name: string, value: unknown) => void;
  onChangeOrigin: (index: number, value: string) => void;
  openEditHcrIframeModel?: boolean;
  removeOrigin: (index: number) => void;
}

const Form: React.FC<FormProps> = ({
  addOrigin,
  formError,
  formValues,
  isLoading,
  onChange,
  onChangeOrigin,
  openEditHcrIframeModel,
  removeOrigin,
}) => {
  let lobList: Array<any> = getIframeLobDropDown(!!openEditHcrIframeModel ? formValues?.lob : '');
  return (
    <>
      <form className='iframe-setting-modal'>
        {!!openEditHcrIframeModel ? (
          <Grid item md={12} className='mb-4'>
            <FormControl fullWidth disabled>
              <InputLabel shrink>{HCR_IFRAME_LABELS.CLIENT_ID}</InputLabel>
              <Input name='client_id' value={formValues.client_id || ''} />
            </FormControl>
          </Grid>
        ) : (
          ''
        )}
        <Grid item md={12} className='mb-4'>
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel shrink>{THEME?.LABEL}</InputLabel>
            <Select
              name='theme'
              value={formValues.theme || hcrIframeThemeList[0]}
              onChange={evt => onChange('theme', evt.target.value)}
            >
              {hcrIframeThemeList.map((item: string, i: number) => (
                <MenuItem key={i} value={item}>
                  {item === DARK_GREEN_THEME.value ? DARK_GREEN_THEME.label : startCase(item)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{formError?.themeError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={12} className='mb-4'>
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel shrink>
              {LOB_KEY}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              name={LOB_KEY?.toLowerCase()}
              value={formValues.lob}
              onChange={evt => onChange(LOB_KEY?.toLowerCase(), evt.target.value)}
            >
              {!isEmpty(lobList)
                ? lobList.map((item: string, i: number) => (
                    <MenuItem value={item} key={i}>
                      {startCase(item)}
                    </MenuItem>
                  ))
                : ''}
            </Select>
            <FormHelperText error>{formError?.lobError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={12} className='mb-4'>
          <FormControl fullWidth disabled={isLoading}>
            <InputLabel shrink>{PHONE_NUMBER_LABEL}</InputLabel>
            <Input
              name={PHONE_NUMBER}
              id={PHONE_NUMBER}
              value={formValues.phone_number || ''}
              onChange={event => onChange(PHONE_NUMBER, event.target.value)}
              inputComponent={PhoneMask}
            />
            <FormHelperText error>{formError?.phoneNumberError}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={12} className='mb-4'>
          <InputLabel shrink>
            <div className='origin-label'>
              <span>{HCR_IFRAME_LABELS.ORIGINS}</span>
              <div className='tooltip-container'>
                <Tooltip className='tooltip' title={HCR_IFRAME_LABELS.ORIGIN_TOOLTIP}>
                  <HelpIcon />
                </Tooltip>
              </div>
            </div>
          </InputLabel>
          {formValues.origins.map((origin, i) => {
            return (
              <Grid item className='mb-4' key={i}>
                <FormControl fullWidth disabled={isLoading}>
                  <Input
                    onChange={evt => onChangeOrigin(i, evt.currentTarget.value)}
                    value={origin?.value || ''}
                    error={!!origin?.error}
                    endAdornment={
                      <Close onClick={() => removeOrigin(i)} color='error' className='close-icon' />
                    }
                  />
                  <FormHelperText error>{origin?.error}</FormHelperText>
                </FormControl>
              </Grid>
            );
          })}

          <Button
            onClick={() => addOrigin()}
            variant='outlined'
            className='add-origin-btn'
            disabled={isLoading}
          >
            {HCR_IFRAME_LABELS.ADD_ORIGIN}
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default Form;
