import { Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isValidHttpUrl, getNestedTernaryData, phoneRegex, validatePhoneNumber } from '../../../../utils';
import Modal from '../../../components/modal';
import { addAndUpdateHcrIframeSettings, setOrgUpdatedResponseData } from '../../../redux/actions';
import Form from './form';
import { getCode, hcrIframeThemeList } from '../../utils';
import {
  ACTIVE,
  HCR_IFRAME_CLIENT_LABEL,
  INVALID,
  ORIGIN_URL_ERROR_MSG,
  REQUIRED,
  SM,
} from '../../../../constants';
import '../style.scss';

const INIT_FORM_ERROR = {
  lobError: '',
  themeError: '',
  phoneNumberError: '',
};

export class AddEditHcrClientSettings extends React.Component<
  AppComponents.AddEditHcrClientSettingsProps,
  AppComponents.IFrameSettingsState
> {
  state: AppComponents.IFrameSettingsState = {
    form: {
      client_id: '',
      code: '',
      origins: [],
      lob: '',
      phone_number: '',
      status: ACTIVE,
      theme: hcrIframeThemeList[0],
    },
    formError: INIT_FORM_ERROR,
  };

  componentDidMount = () => {
    const props: any = this.props;
    const { openEditHcrIframeModel, hcrIframeRowData } = props;
    if (!!openEditHcrIframeModel) {
      const id = !isEmpty(hcrIframeRowData?.clientId) ? hcrIframeRowData?.clientId : '';
      const newState = {
        form: {
          client_id: id,
          code: getCode(id, hcrIframeRowData?.lob),
          origins: !isEmpty(hcrIframeRowData?.origins)
            ? this.mapOriginList(hcrIframeRowData?.origins)
            : [],
          lob: hcrIframeRowData?.lob,
          phone_number: hcrIframeRowData?.phone_number || '',
          status: hcrIframeRowData?.status,
          disclaimer: hcrIframeRowData?.disclaimer,
          theme: hcrIframeRowData?.theme,
        },
      };
      this.setState(newState);
    }
  };

  UNSAFE_componentWillReceiveProps = (
    newProps: AppComponents.AddEditHcrClientSettingsProps
  ): void => {
    const { organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.props.onCloseModal();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
  };

  mapOriginList = (list: any) => {
    let originList: any = [];
    list.forEach((element: any) => {
      originList.push({
        value: element,
        error: '',
      });
    });
    return originList;
  };

  validateOriginURL = () => {
    const { origins } = this.state.form;
    if (!isEmpty(origins)) {
      let origin = !isEmpty(origins) ? [...origins] : [];
      !isEmpty(origin) &&
        origin.forEach((item: any, index: number) => {
          const duplicates = origins?.filter(
            (data: any, key: number) =>
              key !== index && item?.value?.trim() === data?.value?.trim() && !isEmpty(item.value)
          );
          item.error = isEmpty(item?.value)
            ? ORIGIN_URL_ERROR_MSG.EMPTY
            : getNestedTernaryData(
                !isValidHttpUrl(item?.value),
                ORIGIN_URL_ERROR_MSG?.INVALID,
                getNestedTernaryData(duplicates?.length > 0, ORIGIN_URL_ERROR_MSG?.DUPLICATE, '')
              );
        });

      this.setState({
        form: {
          ...this.state.form,
          origins: origin,
        },
      });
    }
    let originErrors = origins.filter(item => !isEmpty(item?.error));
    return isEmpty(origins) ? true : isEmpty(originErrors);
  };

  // eslint-disable-next-line no-undef
  onFormChange = (name: string, value: unknown): void => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  onAddOrigin = () => {
    let isValid = this.validateOriginURL();
    if (!isValid) {
      return;
    }
    this.setState({
      form: {
        ...this.state.form,
        origins: this.state.form.origins.concat({ value: '', error: '' }),
      },
    });
  };

  onRemoveOrigin = (index: number) => {
    const origins = this.state.form.origins.filter((_, i) => i !== index);
    this.setState({
      form: {
        ...this.state.form,
        origins: origins,
      },
    });
  };

  onOriginChange = (index: number, value: string) => {
    const origins = [...this.state.form.origins];
    origins[index].value = value;
    this.setState({
      form: {
        ...this.state.form,
        origins: origins,
      },
    });
  };

  validate = () => {
    const { form } = this.state;
    let error: any = {
      ...INIT_FORM_ERROR,
    };
    error.lobError = isEmpty(form?.lob) ? REQUIRED : '';
    error.themeError = isEmpty(form?.theme) ? REQUIRED : '';
    const validUserPhone =
      !isEmpty(form?.phone_number?.trim()) && validatePhoneNumber(form?.phone_number);
    error.phoneNumberError = !isEmpty(form?.phone_number?.trim())
      ? getNestedTernaryData(
          !phoneRegex.test(form?.phone_number || '') || !validUserPhone,
          INVALID,
          ''
        )
      : '';
    this.setState({
      ...this.state,
      formError: {
        ...error,
      },
    });
    if (
      !isEmpty(error.lobError) ||
      !isEmpty(error.themeError) ||
      !isEmpty(error.phoneNumberError)
    ) {
      return false;
    } else {
      return true;
    }
  };

  onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const { origins } = this.state.form;
    let isValid = this.validateOriginURL() && this.validate();
    if (!isValid) {
      return;
    }
    let originList: any = [];
    !isEmpty(origins) &&
      origins.forEach((item: any) => {
        if (!isEmpty(item?.value)) {
          originList?.push(item?.value?.trim());
        }
      });
    const data: AppComponents.HcrIFrameClientModel = {
      id: this.state.form.client_id,
      org_id: this.props.organizationData.id,
      status: !isEmpty(this.state.form.status) ? this.state.form.status : ACTIVE,
      lob: this.state.form.lob,
      phone_number: this?.state?.form?.phone_number ?? '',
      origins: originList,
      disclaimer: this.state.form.disclaimer || '',
      theme: this.state.form.theme || hcrIframeThemeList[0],
    };
    this.props?.addAndUpdateHcrIframeSettings(data);
  };

  render() {
    const { organizations } = this.props;
    return (
      <Modal
        isOpen={true}
        onClose={this.props.onCloseModal}
        title={HCR_IFRAME_CLIENT_LABEL}
        navButtons={true}
        maxWidth={SM}
        onSave={this.onSubmit}
        loader={!!organizations?.addEditOrgLoader}
      >
        <Container className='pl-0'>
          <Form
            onChange={this.onFormChange}
            formValues={this.state.form || {}}
            addOrigin={this.onAddOrigin}
            removeOrigin={this.onRemoveOrigin}
            onChangeOrigin={this.onOriginChange}
            isLoading={!!organizations?.addEditOrgLoader}
            openEditHcrIframeModel={this.props.openEditHcrIframeModel}
            formError={this.state.formError}
          />
        </Container>
      </Modal>
    );
  }
}

const mapStateToProps = (props: AppComponents.AddEditHcrClientSettingsStore) => {
  const { organizations } = props;
  const { editOrganizationData } = organizations;
  return { organizationData: editOrganizationData, organizations };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AddEditHcrClientSettingsDispatch =>
  bindActionCreators({ addAndUpdateHcrIframeSettings, setOrgUpdatedResponseData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEditHcrClientSettings);
