import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import DatePicker from '../date-picker';
import {
  lossFieldDeleted,
  lossFieldUpdated,
  setHasFormChanged,
} from '../../redux/actions';
import { LOSSES_MINIMUM_AMOUNT, OTHER_DETAILS_DATA } from '../../../constants';
import NumberFormatCustom from '../currency-mask';
import { setOptionDetails, validateIncidentDate } from '../../auto/utils';

import './losses-editor.scss';
import { hasViolationChanged, allowEditingQuote } from '../../../utils';

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      margin: '10px 0px',
      minWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    errorMsg: {
      color: '#FF0000',
      height: '0px',
    },
  });

class LossesEditor extends React.Component<
  AppComponents.LossesDetailsProps & WithStyles<typeof styles>,
  AppComponents.LossesDetailsState
> {
  static propTypes = {
    uid: PropTypes.string.isRequired,
  };
  initLossErrors: AppComponents.LossesDetailsErrors = {
    date_of_loss_error: '',
    loss_description_error: '',
    loss_vehicle_involved_error: '',
    loss_amount_error: '',
  };
  state: AppComponents.LossesDetailsState = {
    date_of_loss: '',
    loss_description: '',
    loss_vehicle_involved: '',
    loss_amount: '',
    otherDetailsLossesErrors: { ...this.initLossErrors },
  };

  getCurrentLoss = (list: Array<any>, uid: string) => find(list, ['loss_uid', uid]);

  getDateOfLossError = (errorData: any, lossError: string, dateOfLoss: any) => {
    const errorDetails = { ...errorData };
    if (lossError === 'date_of_loss_error') {
      if (isEmpty(dateOfLoss)) {
        errorDetails[lossError] = 'Required';
      } else {
        const lossDateErrorMessage = validateIncidentDate(dateOfLoss);
        errorDetails[lossError] = !isUndefined(lossDateErrorMessage) ? lossDateErrorMessage : '';
      }
      return { [lossError]: errorDetails[lossError] };
    }
    return {};
  };

  getLossAmountError = (errorData: any, lossError: string, lossAmount: any) => {
    const errorDetails = { ...errorData };
    if (lossError === 'loss_amount_error') {
      if (parseInt(lossAmount) < LOSSES_MINIMUM_AMOUNT) {
        errorDetails[lossError] = 'Invalid';
      } else if (!parseInt(lossAmount)) {
        errorDetails[lossError] = 'Required';
      } else {
        errorDetails[lossError] = '';
      }
      return { [lossError]: errorDetails[lossError] };
    }
    return {};
  };

  getLossDescriptionError = (errorData: any, lossError: string, lossDescription: any) => {
    const errorDetails = { ...errorData };
    if (lossError === 'loss_description_error') {
      if (isEmpty(lossDescription)) {
        errorDetails[lossError] = 'Required';
      } else {
        errorDetails[lossError] = '';
      }
      return { [lossError]: errorDetails[lossError] };
    }
    return {};
  };

  componentDidMount() {
    const currentLossDetails = this.getCurrentLoss(this.props.lossList, this.props.uid);
    this.setState({ ...this.state, ...currentLossDetails });
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.LossesDetailsProps) {
    const { lossList, lossErrors, uid, stepSubmitLoader } = newProps;
    const currentLossDetails = this.getCurrentLoss(lossList, uid);
    this.setState({ ...this.state, ...currentLossDetails });
    if (lossErrors && !isEmpty(lossErrors) && !stepSubmitLoader) {
      this.setState({ otherDetailsLossesErrors: { ...this.initLossErrors } });
      let errorDetails = { ...this.state.otherDetailsLossesErrors };
      forEach(lossErrors[uid], lossError => {
        errorDetails = {
          ...errorDetails,
          ...this.getDateOfLossError(errorDetails, lossError, currentLossDetails.date_of_loss),
          ...this.getLossAmountError(errorDetails, lossError, currentLossDetails.loss_amount),
          ...this.getLossDescriptionError(
            errorDetails,
            lossError,
            currentLossDetails.loss_description
          ),
        };
      });
      this.setState({ otherDetailsLossesErrors: { ...errorDetails } });
    }
  }

  handleFieldChange = (event: any) => {
    const { uid, setHasFormChanged } = this.props;
    const { name, value } = event.target;
    this.setState({ [name]: value } as any, () => {
      if (
        hasViolationChanged({
          field: name,
          list: this.props.lossList,
          value,
        })
      ) {
        setHasFormChanged(true);
      }
      this.props.lossFieldUpdated({ name, value, uid });
    });
  };

  handleCalendarDate = (dateFromCalender: any) => {
    const { uid, setHasFormChanged } = this.props;
    const name = 'date_of_loss';
    const value = dateFromCalender;
    this.setState({ [name]: value } as any, () => {
      if (
        hasViolationChanged({
          field: 'date_of_loss',
          list: this.props.lossList,
          value,
        })
      ) {
        setHasFormChanged(true);
      }
      this.props.lossFieldUpdated({ name, value, uid });
    });
  };

  deleteLossDetails = () => {
    const { uid } = this.props;
    this.props.lossFieldDeleted({ uid });
    this.props.setHasFormChanged(true);
  };

  render() {
    const { classes, lossList, uid, isIncluded } = this.props;
    const {
      date_of_loss,
      loss_description,
      loss_vehicle_involved,
      loss_amount,
      otherDetailsLossesErrors,
    } = this.state;
    const {
      date_of_loss_error,
      loss_description_error,
      loss_vehicle_involved_error,
      loss_amount_error,
    } = otherDetailsLossesErrors;
    const disableAutoEdits = allowEditingQuote();
    return (
      <React.Fragment>
        <div className='row sub-title-blue sub-other-heading mb-3 mt-3 mx-auto'>
          <div className='col-lg-10 col-sm-10 d-flex align-items-center'>
            <Typography className='small-label-heading'>
              Loss {findIndex(lossList, ['loss_uid', uid]) + 1}
            </Typography>
          </div>
          {lossList.length > 1 && !disableAutoEdits?.auto && (
            <div className='col-lg-2 col-sm-2 logo-container'>
              <Tooltip title='Delete' arrow placement='top'>
                <DeleteOutlinedIcon className='delete-logo' onClick={this.deleteLossDetails} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className='row mx-auto'>
          <div className='col-md-6'>
            <FormControl
              fullWidth
              className={`mb-0 ${classes.formControl} date_of_loss ${
                date_of_loss_error.length > 0 ? 'labelError' : ''
              }`}
              disabled={!isIncluded || disableAutoEdits?.auto}
            >
              <DatePicker
                disabled={!isIncluded || disableAutoEdits?.auto}
                label={
                  <span>
                    Date of Loss<span className='star-error'>*</span>
                  </span>
                }
                name='date_of_loss'
                onChange={this.handleCalendarDate}
                dateValue={date_of_loss}
                error={date_of_loss_error === '' ? false : true}
              />
            </FormControl>
            <FormHelperText className={classes.errorMsg}>{date_of_loss_error}</FormHelperText>
          </div>
          <div className='col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor=''>
                Vehicle Involved
              </InputLabel>
              <Select
                native
                value={loss_vehicle_involved}
                inputProps={{
                  id: 'loss_vehicle_involved',
                  name: 'loss_vehicle_involved',
                }}
                onChange={this.handleFieldChange}
                error={loss_vehicle_involved_error === '' ? false : true}
              >
                {setOptionDetails(OTHER_DETAILS_DATA.loss_vehicle_involved)}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {loss_vehicle_involved_error}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className='row mx-auto'>
          <div className='col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor=''>
                Loss Description<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={loss_description}
                inputProps={{
                  id: 'loss_description',
                  name: 'loss_description',
                }}
                onChange={this.handleFieldChange}
                error={loss_description_error === '' ? false : true}
              >
                <option value=''>Select</option>
                {setOptionDetails(OTHER_DETAILS_DATA.loss_description)}
              </Select>
              <FormHelperText className={classes.errorMsg}>{loss_description_error}</FormHelperText>
            </FormControl>
          </div>
          <div className='col-md-6'>
            <FormControl disabled={!isIncluded || disableAutoEdits?.auto} fullWidth className={classes.formControl}>
              <InputLabel shrink htmlFor='loss_amount'>
                Amount<span className='star-error'>*</span>
              </InputLabel>
              <Input
                value={loss_amount === '' ? '' : loss_amount}
                onChange={this.handleFieldChange}
                inputProps={{
                  id: 'loss_amount',
                  name: 'loss_amount',
                }}
                inputComponent={NumberFormatCustom as any}
                error={loss_amount_error === '' ? false : true}
              />
              <FormHelperText className={classes.errorMsg}>{loss_amount_error}</FormHelperText>
            </FormControl>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  state: AppComponents.LossesDetailsStore
): AppComponents.LossesDetailsStoreProps => {
  const { stepSubmitLoader } = state.common;
  const { isIncluded } = state.driver;
  const { lossList, lossErrors } = state.otherDetails;
  return { lossList, lossErrors, stepSubmitLoader, isIncluded };
};

const mapDispatchToProps = (dispatch: any): AppComponents.LossesDetailsDispatch => {
  return bindActionCreators(
    { lossFieldDeleted, lossFieldUpdated, setHasFormChanged },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(LossesEditor));
