import React from 'react';
import { Tab } from '@material-ui/core';
import { FLOOD_LABEL_NAME } from '../../constants/flood-questionnaire';
import TabPanel from '../../pages/components/questionnaire-tab-wrapper';
import CustomIcon from './icon';
import FloodDetails from '../pages/flood';
import AutoForm from '../pages/auto';
import DriverForm from '../pages/driver';
import PolicyForm from '../pages/policy';
import PetDetails from '../pages/pet';
import LifeDetails from '../pages/life';
import UmbrellaDetails from '../pages/umbrella';
import {
  AUTO_LABEL_NAME,
  DRIVER_LABEL_NAME,
  POLICY_LABEL_NAME,
} from '../../constants/auto-questionnaire';
import { LIFE_LABEL_NAME, PET_LABEL_NAME, UMBRELLA } from '../../constants';
import { TabParamsType, TabsType } from './types';

export const tabElements = ({
  floodDetails,
  value,
  handleBack,
  handleNext,
  goToLastTab,
  add,
  disabledSteps,
  tabsCount,
  redirectToStep,
}: TabParamsType): TabsType => ({
  FLOOD: {
    tab: (index: number) => (
      <Tab
        disabled={disabledSteps.flood}
        value={index}
        label={FLOOD_LABEL_NAME}
        icon={
          <CustomIcon
            label={FLOOD_LABEL_NAME}
            icon='Flood'
            index={index}
            value={value}
            enableStep={disabledSteps.flood}
          />
        }
        {...allProps(index)}
        key={`tab-${index}`}
      />
    ),
    tabPanel: (index: number) => (
      <TabPanel label={FLOOD_LABEL_NAME} value={value} index={index} key={`tab-panel-${index}`}>
        <FloodDetails
          onPrevTab={(currValue: number) => handleBack(currValue)}
          onNextTab={(currValue: number) => handleNext(currValue)}
          currentIndex={value}
          goToLastTab={goToLastTab}
          redirectToStep={redirectToStep}
        />
      </TabPanel>
    ),
  },
  AUTO: {
    tab: (index: number) => [
      <Tab
        value={index}
        disabled={disabledSteps.vehicle}
        label={AUTO_LABEL_NAME}
        {...allProps(index)}
        icon={
          <CustomIcon
            label={AUTO_LABEL_NAME}
            icon='Vehicle'
            index={index}
            value={value}
            enableStep={disabledSteps.vehicle}
          />
        }
        key={`tab-${index}`}
      />,
      <Tab
        value={index + 1}
        disabled={disabledSteps.drvier}
        label={DRIVER_LABEL_NAME}
        {...allProps(index + 1)}
        icon={
          <CustomIcon
            label={DRIVER_LABEL_NAME}
            icon='Driver'
            index={index + 1}
            value={value}
            enableStep={disabledSteps.drvier}
          />
        }
        key={`tab-${index + 1}`}
      />,
      <Tab
        value={index + 2}
        disabled={disabledSteps.policy}
        label={POLICY_LABEL_NAME}
        {...allProps(index + 2)}
        icon={
          <CustomIcon
            label={POLICY_LABEL_NAME}
            icon='Policy'
            index={index + 2}
            value={value}
            enableStep={disabledSteps.policy}
          />
        }
        key={`tab-${index + 2}`}
      />,
    ],
    tabPanel: (index: number) => [
      <TabPanel label={AUTO_LABEL_NAME} value={value} index={index} key={`tab-${index}`}>
        <AutoForm
          onNextTab={(currValue: number) => handleNext(currValue)}
          onPrevTab={(currValue: number) => {
            let updatedCurrValue = floodDetails?.annexPrefill
              ? floodDetails?.isFloodInfoRequired
                ? currValue
                : currValue - 1
              : currValue;
            handleBack(updatedCurrValue);
          }}
          currentIndex={value}
          add={add}
          goToLastTab={goToLastTab}
        />
      </TabPanel>,
      <TabPanel label={DRIVER_LABEL_NAME} value={value} index={index + 1} key={`tab-${index + 1}`}>
        <DriverForm
          onNextTab={(currValue: number) => handleNext(currValue)}
          onPrevTab={(currValue: number) => handleBack(currValue)}
          currentIndex={value}
          add={add}
          goToLastTab={goToLastTab}
        />
      </TabPanel>,
      <TabPanel label={POLICY_LABEL_NAME} value={value} index={index + 2} key={`tab-${index + 2}`}>
        <PolicyForm
          onNextTab={(currValue: number) => handleNext(currValue)}
          onPrevTab={(currValue: number) => handleBack(currValue)}
          currentIndex={value}
          goToLastTab={goToLastTab}
        />
      </TabPanel>,
    ],
  },
  PET: {
    tab: (index: number) => (
      <Tab
        disabled={disabledSteps.pet}
        value={index}
        label={PET_LABEL_NAME}
        icon={
          <CustomIcon
            label={PET_LABEL_NAME}
            icon='Pet'
            index={index}
            value={value}
            enableStep={disabledSteps.pet}
          />
        }
        {...allProps(index)}
        key={`tab-${index}`}
      />
    ),
    tabPanel: (index: number) => (
      <TabPanel label={PET_LABEL_NAME} value={value} index={index} key={`tab-panel-${index}`}>
        <PetDetails
          onPrevTab={(currValue: number) => handleBack(currValue)}
          onNextTab={(currValue: number) => handleNext(currValue)}
          currentIndex={value}
          goToLastTab={goToLastTab}
          add={add}
        />
      </TabPanel>
    ),
  },
  LIFE: {
    tab: (index: number) => (
      <Tab
        disabled={disabledSteps.life}
        value={index}
        label={LIFE_LABEL_NAME}
        icon={
          <CustomIcon
            label={LIFE_LABEL_NAME}
            icon='Life'
            index={index}
            value={value}
            enableStep={disabledSteps.life}
          />
        }
        {...allProps(index)}
        key={`tab-${index}`}
      />
    ),
    tabPanel: (index: number) => (
      <TabPanel label={LIFE_LABEL_NAME} value={value} index={index} key={`tab-panel-${index}`}>
        <LifeDetails
          onPrevTab={(currValue: number) => handleBack(currValue)}
          onNextTab={(currValue: number) => handleNext(currValue)}
          currentIndex={value}
          goToLastTab={goToLastTab}
          add={add}
        />
      </TabPanel>
    ),
  },
  UMBRELLA: {
    tab: (index: number) => (
      <Tab
        disabled={disabledSteps?.umbrella}
        value={index}
        label={UMBRELLA}
        icon={
          <CustomIcon
            label={UMBRELLA}
            icon={UMBRELLA}
            index={index}
            value={value}
            enableStep={disabledSteps?.umbrella}
          />
        }
        {...allProps(index)}
        key={`tab-${index}`}
      />
    ),
    tabPanel: (index: number) => (
      <TabPanel label={UMBRELLA} value={value} index={index} key={`tab-panel-${index}`}>
        <UmbrellaDetails
          onPrevTab={(currValue: number) => handleBack(currValue)}
          onNextTab={(currValue: number) => handleNext(currValue)}
          currentIndex={value}
          goToLastTab={goToLastTab}
          add={add}
        />
      </TabPanel>
    ),
  },
});

export const allProps = (index: number) => ({
  id: `wrapped-tab-${index}`,
  'aria-controls': `wrapped-tabpanel-${index}`,
});
