import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import './drop-down-autocomplete.scss';
import { CARRIER } from '../../../constants';

const useStyles = makeStyles({
  option: {
    cursor: 'default',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingBottom: '0px',
    justifyContent: 'flex-start',
    '&:hover, &:active, &:focus': {
      backgroundColor: 'dodgerblue',
      color: 'white',
    },
  },
});

const DropDownAutocomplete = (props: AppComponents.DropDownAutocompleteProps) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [options, setOption] = React.useState(props.recordsList);

  useEffect(() => {
    setValue(props.value);
    setOption(props.recordsList);
  }, [props.value, props.recordsList]);

  return (
    <div className='drop-down-autocomplete'>
      <Autocomplete
        classes={{
          option: classes.option,
        }}
        fullWidth
        disableClearable
        value={value}
        onChange={(event: any, newValue: any) => {
          setValue(newValue);
          props.onChange(newValue);
        }}
        getOptionLabel={(option: any) => (props.label === CARRIER ? option.Name : option)}
        id={`controllable-${props.label}`}
        options={options}
        renderInput={params => (
          <TextField
            {...params}
            error={props.error}
            label={
              <>
                {props.label} {!!props?.isRequired && <span className='star-error'>*</span>}
              </>
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        disabled={props.disabled}
        key={props.label}
      />
    </div>
  );
};

DropDownAutocomplete.propTypes = {
  recordsList: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  isRequired:PropTypes.bool,

};

export default DropDownAutocomplete;
