import React from 'react';

const Loader = () => {
  return (
    <div className='loader-wrapper'>
      <div className='hippo-anim-loader' />
    </div>
  );
};

export default React.memo(Loader);
