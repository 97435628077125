import React, { useRef } from 'react';
import ReactSelect, { components, createFilter } from 'react-select';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import find from 'lodash/find';

import './filter-selector.scss';

const selectAllOption = {
  value: '<SELECT_ALL>',
  label: 'All',
};

const Option = React.memo((props: any) => {
  const { innerProps, isFocused, ...otherProps } = props;
  const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
  const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };
  return (
    <div>
      <components.Option {...newProps} className='list-parent'>
        <Checkbox
          checked={newProps.isSelected}
          onChange={() => null}
          color='default'
          disableRipple={true}
        />
        <label>{newProps.label}</label>
      </components.Option>
    </div>
  );
});

const MultiValue = React.memo((props: any) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
));

const MenuDropdownList = React.memo((props: any) => {
  const selectedOpts = props.selectProps.value;
  return (
    <React.Fragment>
      <components.MenuList {...props}>{props.children}</components.MenuList>
      <div className='btn-container'>
        <Button
          className='btn apply-btn'
          size='small'
          variant='outlined'
          disableRipple={true}
          disabled={!selectedOpts || selectedOpts.length === 0}
          onClick={(e: any) => {
            const isAllSelected = find(props.selectProps.value, ['value', selectAllOption.value]);
            if (isAllSelected) {
              const allOptions = [...props.selectProps.options];
              allOptions.shift();
              props.onApplyFilter(allOptions);
            } else {
              props.onApplyFilter(props.selectProps.value);
            }
            setTimeout(() => {
              props.selectProps.onMenuClose();
            }, 10);
            e.stopPropagation();
          }}
        >
          Apply
        </Button>
        <Button
          className='btn clear-btn'
          size='small'
          variant='outlined'
          disableRipple={true}
          disabled={!selectedOpts || selectedOpts.length === 0}
          onClick={(e: any) => {
            props.clearValue();
            setTimeout(() => {
              props.selectProps.onMenuClose();
            }, 10);
            e.stopPropagation();
          }}
        >
          Clear
        </Button>
      </div>
    </React.Fragment>
  );
});

const MenuList = React.memo((menuProps: any) => (
  <MenuDropdownList
    className='menuDropdownList'
    {...menuProps}
    onApplyFilter={menuProps.selectProps.selectProps.onApplyFilter}
    onClearFilter={menuProps.selectProps.selectProps.onClearFilter}
  />
));

const FilterSelector = (props: any) => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length && props.options.length > 0;

  const isOptionSelected = (option: any) =>
    valueRef.current.some(({ value }: any) => value === option.value) || isSelectAllSelected();

  const getOptions = () => [selectAllOption, ...props.options];

  const getValue = () => (isSelectAllSelected() ? [selectAllOption] : props.value);

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;
    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
    } else if (
      (action === 'deselect-option' && option.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      props.onChange(
        props.options.filter(({ value }: any) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
    if (actionMeta.action === 'clear') {
      props.onClearFilter();
    } else if (actionMeta.action === 'remove-value') {
      props.onApplyFilter(newValue || []);
    }
  };

  const onMenuClose = () => {
    props.onFilterMenuClose();
  };

  return (
    <ReactSelect
      className='filter-selector-container'
      classNamePrefix='filter-selector'
      selectProps={{
        onApplyFilter: props.onApplyFilter,
        onClearFilter: props.onClearFilter,
      }}
      components={{
        Option,
        MultiValue,
        MenuList,
        ClearIndicator: null,
      }}
      isOptionSelected={isOptionSelected}
      filterOption={createFilter({ ignoreAccents: false })}
      options={getOptions()}
      value={getValue()}
      onChange={onChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      placeholder={'Select Filter'}
      isDisabled={props.disabled}
      onMenuClose={onMenuClose}
      isSearchable={false}
      backspaceRemovesValue={false}
      maxMenuHeight={252}
      menuPlacement={'auto'}
      isMulti
    />
  );
};

export default FilterSelector;
