import React, { useEffect } from 'react';

import Driver from '../../auto/driver';
import history from '../../../history';

const DriverForm = ({ onNextTab, onPrevTab, currentIndex, add, goToLastTab }: AppComponents.DriverFormProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Driver
        onNextTab={onNextTab}
        onPrevTab={onPrevTab}
        currentIndex={currentIndex}
        add={add}
        history={history}
        goToLastTab={goToLastTab}
      />
    </div>
  );
};

export default DriverForm;
