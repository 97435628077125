/* istanbul ignore file */
import { AxiosError } from 'axios';
import moment from 'moment';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import get from 'lodash/get';
import range from 'lodash/range';
import * as actions from '../constants';
import { ADHOC, PRODUCT, QUOTES } from '../../../constants';
import axiosRequest from '../../api';
import {
  adhocOrganizationListLoader,
  autoFillVisitedPages,
  clearInsuredListDetails,
  errorHandler,
  setAMSDetails,
  setAMSDetailsLoader,
  setAppliedFilters,
  setDateRange,
  setInsuredDropDownLoader,
  setInsuredList,
  setInsuredTableLoader,
  storeAdhocOrganizationList,
  storeQuotingErrorMessage,
} from '../actions';
import { covertStringToArray, dateFormat, simplifyPhoneNumber } from '../../../utils';

const dashboardActions: any = actions;
const phoneWithBracketsRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const insuredCountProperty = 'data.count';
const insuredQuotesProperty = 'data.quotes';

const getVisitedPageRange = (dashboard: any, currentPage: any, quoteList: any) => {
  let pagesRange = [];
  const perPageRecords = dashboard.perPageRecords;
  const startIndex = currentPage;
  const quoteLength = quoteList.length;
  let endIndex = quoteLength + startIndex * perPageRecords;
  const totalRecords = dashboard.insuredListDetails.total;
  const previousValue = (startIndex - 1) * perPageRecords - 1;
  const pageOffset = Math.ceil(quoteLength / perPageRecords);
  if (
    startIndex !== 0 &&
    (endIndex > totalRecords || !dashboard.insuredListDetails.list[previousValue])
  ) {
    endIndex = startIndex - pageOffset;
    pagesRange = range(endIndex, startIndex);
  } else {
    endIndex = (startIndex - pageOffset) * perPageRecords + quoteLength - perPageRecords;
    pagesRange = range(
      Math.ceil(endIndex / perPageRecords),
      Math.ceil((endIndex + quoteLength - 1) / perPageRecords)
    );
  }
  return pagesRange;
};

const getUpdatedSearchText = (searchText: string) => {
  let userInput = searchText;
  if (phoneWithBracketsRegex.test(userInput)) {
    userInput = simplifyPhoneNumber(userInput);
  }
  return userInput;
};

function* setInitTableDetails(details: any) {
  const { dashboard } = yield select();
  const quoteList = get(details, insuredQuotesProperty, []);
  yield put(autoFillVisitedPages([]));
  yield put(
    setInsuredList({
      count: get(details, insuredCountProperty, 0),
      quotes: [...quoteList],
      currentPage: 0,
    } as any)
  );
  const pagesRange = range(1, quoteList.length / dashboard.perPageRecords);
  yield put(autoFillVisitedPages(pagesRange));
}

function* resetTableDetails(searchData: string): any {
  const { dashboard } = yield select();
  const filterData = dashboard.appliedFilters
    ? dashboard.appliedFilters
    : {
      product: [],
      lob: [],
      states: [],
    };
  const details = yield call(fetchInsuredDetails, {
    lob: '',
    current_page: 1,
    search_text: searchData,
    filterData,
    dateRange: {
      startDate: dashboard.appliedDateRange.startDate,
      endDate: dashboard.appliedDateRange.endDate,
    },
    amsBindType: QUOTES,
  });
  if (details.data) {
    yield call(setInitTableDetails, details);
  }
}

function* fetchInsuredDetails(details: any): any {
  const { partner: { isProviderOneLogin } } = yield select();
  return yield axiosRequest('POST', '/dashboard/details', isProviderOneLogin, details);
}

function* dashboardInitialization() {
  yield put(setInsuredTableLoader(true));
  try {
    yield put(
      setDateRange({
        startDate: moment().subtract(29, 'days').format(dateFormat),
        endDate: moment().format(dateFormat),
      })
    );
    yield call(resetTableDetails, '');
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setInsuredTableLoader(false));
}

export function* dashboardInitWatcher() {
  yield takeEvery(dashboardActions.DASHBOARD_INIT, dashboardInitialization);
}

function* insuredTablePageChanged({ payload }: AppRedux.ActionPayload): any {
  try {
    const { pageNumber } = payload;
    const { dashboard } = yield select();
    const userSearchText = getUpdatedSearchText(dashboard.searchText.trim());
    const filterData = dashboard.appliedFilters
      ? dashboard.appliedFilters
      : {
        product: [],
        lob: [],
        states: [],
      };
    yield put(setInsuredTableLoader(true));
    if (!dashboard.insuredVisitedPages.includes(pageNumber)) {
      const currentPage = pageNumber + 1;
      const details = yield call(fetchInsuredDetails, {
        lob: '',
        current_page: Math.ceil(currentPage / dashboard.perPageRecords),
        search_text: userSearchText,
        filterData,
        dateRange: {
          startDate: dashboard.appliedDateRange.startDate,
          endDate: dashboard.appliedDateRange.endDate,
        },
        amsBindType: QUOTES,
      });
      if (details.data) {
        const quoteList = get(details, insuredQuotesProperty, []);
        yield put(
          setInsuredList({
            quotes: [...quoteList],
            currentPage: pageNumber,
          } as any)
        );
        const pageRange = getVisitedPageRange(dashboard, currentPage, quoteList);
        yield put(autoFillVisitedPages(pageRange));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setInsuredTableLoader(false));
}

export function* tablePageChangeWatcher() {
  yield takeEvery(dashboardActions.TABLE_PAGE_CHANGED, insuredTablePageChanged);
}

function* dashboardSearchWorker({ payload }: AppRedux.ActionPayload): any {
  yield put(clearInsuredListDetails());
  yield put(setInsuredTableLoader(true));
  try {
    let userSearchText = payload.searchText.trim();
    const { dashboard } = yield select();
    if (phoneWithBracketsRegex.test(userSearchText)) {
      userSearchText = simplifyPhoneNumber(userSearchText);
    }
    const details = yield call(fetchInsuredDetails, {
      lob: '',
      current_page: 1,
      search_text: userSearchText,
      filterData: dashboard.appliedFilters,
      dateRange: {
        startDate: dashboard.appliedDateRange.startDate,
        endDate: dashboard.appliedDateRange.endDate,
      },
      amsBindType: QUOTES,
    });
    if (details.data) {
      yield call(setInitTableDetails, details);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setInsuredTableLoader(false));
}

export function* dashboardSearchWatcher() {
  yield takeEvery(dashboardActions.DASHBOARD_SEARCH, dashboardSearchWorker);
}

function* adHocSearch({ payload }: AppRedux.ActionPayload): any {
  yield put(setAMSDetailsLoader(true));

  yield put(setInsuredDropDownLoader(true));

  try {
    let userSearchText = payload.searchText.trim();
    if (phoneWithBracketsRegex.test(userSearchText)) {
      userSearchText = simplifyPhoneNumber(userSearchText);
    }
    const details = yield call(fetchInsuredDetails, {
      search_text: userSearchText,
      amsBindType: ADHOC,
    });
    if (details.data) {
      yield put(
        setAMSDetails({
          existingCustomer: get(details, 'data.quotes', []),
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAMSDetailsLoader(false));
}

export function* adHocSearchWatcher() {
  yield takeEvery(dashboardActions.ADHOC_BIND_SEARCH, adHocSearch);
}

function* getInsuredFilteredList({ payload }: AppRedux.ActionPayload): any {
  yield put(clearInsuredListDetails());
  yield put(setInsuredTableLoader(true));
  try {
    let appliedFilters = {
      product: [],
      lob: [],
      states: [],
    };
    const filters = payload.filterList;
    const { dashboard } = yield select();
    if (filters.length > 0) {
      appliedFilters = filters.reduce((data: any, item: any) => {
        const keyVal = data[item.key] || [];
        const obj = {
          [item.key]:
            item?.key === PRODUCT
              ? covertStringToArray(item?.value)
              : [...keyVal, item?.value?.toLowerCase()],
        };
        return Object.assign({}, data, obj);
      }, {});

      // lob has to be added, in order to receive result with no line-of-business filter. The
      // problem is that backend returns all line-of-business types when empty lob array is passed.
      // This is one of the ways to make it work, and not break dashboard without big refactoring.
      if (!appliedFilters.lob && !appliedFilters.product) {
        appliedFilters.lob = [];
      }
    }
    yield put(setAppliedFilters(appliedFilters));
    const userSearchText = getUpdatedSearchText(dashboard.searchText.trim());
    const details = yield call(fetchInsuredDetails, {
      lob: '',
      current_page: 1,
      search_text: userSearchText,
      filterData: {
        ...appliedFilters,
      },
      dateRange: {
        startDate: dashboard.appliedDateRange.startDate,
        endDate: dashboard.appliedDateRange.endDate,
      },
      amsBindType: QUOTES,
    });
    if (details.data) {
      yield call(setInitTableDetails, details);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setInsuredTableLoader(false));
}

export function* getInsuredFilteredListWatcher() {
  yield takeEvery(dashboardActions.GET_FILTERED_LIST, getInsuredFilteredList);
}

function* getInsuredListByDateRange({ payload }: any): any {
  yield put(setInsuredTableLoader(true));
  try {
    yield put(clearInsuredListDetails());
    yield put(setDateRange(payload));
    const { dashboard } = yield select();
    const userSearchText = getUpdatedSearchText(dashboard.searchText.trim());
    const details = yield call(fetchInsuredDetails, {
      lob: '',
      current_page: 1,
      search_text: userSearchText,
      filterData: dashboard.appliedFilters,
      dateRange: {
        startDate: dashboard.appliedDateRange.startDate,
        endDate: dashboard.appliedDateRange.endDate,
      },
      amsBindType: QUOTES,
    });
    if (details.data) {
      yield call(setInitTableDetails, details);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setInsuredTableLoader(false));
}

export function* dateRangeChangeWatcher() {
  yield takeEvery(dashboardActions.CHANGE_DATE_RANGE, getInsuredListByDateRange);
}

function* fetchAdhocOrganizationList(): any {
  try {
    yield put(storeQuotingErrorMessage(''));
    yield put(adhocOrganizationListLoader(true));
    const responseDetails = yield axiosRequest('GET', '/adhoc/adhoc-organizations');
    if (!!responseDetails?.data && responseDetails?.status === 200) {
      yield put(storeAdhocOrganizationList(responseDetails?.data));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data && axiosError?.response?.status === 404) {
      yield put(storeQuotingErrorMessage(axiosError?.response?.data?.errorMessage));
      yield put(storeAdhocOrganizationList([]));
    }
    yield put(errorHandler(axiosError));
  }
  yield put(adhocOrganizationListLoader(false));
}

export function* fetchAdhocOrganizationListWatcher() {
  yield takeEvery(dashboardActions.FETCH_ADHOC_BIND_ORGANIZATION_LIST, fetchAdhocOrganizationList);
}

export default function* rootSaga() {
  yield all([
    fork(dashboardInitWatcher),
    fork(tablePageChangeWatcher),
    fork(dashboardSearchWatcher),
    fork(getInsuredFilteredListWatcher),
    fork(dateRangeChangeWatcher),
    fork(adHocSearchWatcher),
    fork(fetchAdhocOrganizationListWatcher),
  ]);
}
