import React from 'react';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { Redirect, Route, Switch } from 'react-router-dom';
import some from 'lodash/some';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import values from 'lodash/values';

import {
  applicantDetailsFromHomeInsurance,
  setConfigLoader,
  setHomeQuoteFlag,
  setHomeState,
  setPconfig,
  stepChanged,
  updateDriverName,
  validateHomeAddress,
} from '../../redux/actions';
import AutoApplicant from '../applicant';
import AutoQuotes from '../quotes';
import { formatDob, goToDashboard } from '../../../utils';
import EditSidebar from '../../components/auto-edit-sidebar';
import Footer from '../../components/footer';
import history from '../../../history';
import Loader from '../../components/loader';
import StepSidebar from '../../components/step-sidebar';

const styles = (theme: Theme) => ({
  formControl: {
    margin: '10px 0px',
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class AutoWrapper extends React.PureComponent<
  AppComponents.AutoWrapperProps & WithStyles<typeof styles>,
  AppComponents.AutoWrapperState
> {
  state: AppComponents.AutoWrapperState = {
    loading: true,
    isViewQuotes: false,
  };
  applicantRoute = 'auto/applicant';

  UNSAFE_componentWillMount() {
    const { location, pstate, pconfig, home } = this.props;
    const configurations = get(location, 'state', null);
    const { herd_id } = queryString.parse(this.props.location.search);
    const isViewQuotes = location.pathname.includes('/auto/quotes') && !isEmpty(herd_id);
    if (location.pathname.includes('/auto') && !isEmpty(configurations) && !isViewQuotes) {
      this.props.setConfigLoader(false);
      if (some(home.personal_details, isEmpty) || some(home.address, isEmpty)) {
        this.props.applicantDetailsFromHomeInsurance({
          personal_details: home.personal_details,
          address: home.address,
        });
        this.props.setHomeState({ showError: false, isAlreadyValidated: false });
      }
      if (isEmpty(pconfig)) {
        this.props.setPconfig({
          herd_id: home.herd_id,
          pconfig: {
            pconfig: { ...home.pconfig },
            defaultPConfig: { ...home.defaultPConfig },
          },
          defaultPConfig: { ...home.defaultPConfig },
        });
      } else {
        this.props.setPconfig({
          defaultPConfig: home.defaultPConfig,
          pconfig: { pconfig: { ...home.pconfig } },
        });
      }
      this.updateApplicantData(pstate, configurations, home);
    } else {
      isViewQuotes &&
        this.props.setPconfig({
          herd_id: home.herd_id,
          pconfig: { pconfig: { ...home.pconfig } },
          defaultPConfig: { ...home.defaultPConfig },
        });
    }
    this.setState({ loading: false, isViewQuotes });
  }

  UNSAFE_componentWillUpdate() {
    const { location, pconfig } = this.props;
    const configurations = get(location, 'state', null);
    if (isEmpty(pconfig) && isEmpty(configurations) && !this.state.isViewQuotes) {
      goToDashboard();
    }
  }

  updateApplicantData = (pstate: any, configurations: any, home: any) => {
    if (!isEmpty(pstate) && configurations.pathname === '/auto/vehicle') {
      const { personal_details, address } = home;
      this.props.setHomeQuoteFlag(true);
      this.props.stepChanged({ entity: 'vehicles', value: true });
      // Check if county missing
      if (isEmpty(address.county)) {
        this.props.validateHomeAddress({ personal_details, address });
      } else {
        this.props.applicantDetailsFromHomeInsurance({ personal_details, address });
        if (
          values([
            personal_details.education,
            personal_details.industry,
            personal_details.occupation,
          ]).some(isEmpty)
        ) {
          this.props.stepChanged({ entity: 'vehicles', value: false });
          history.push('/applicant');
        }
      }
      this.props.updateDriverName({
        first_name: personal_details.first_name,
        last_name: personal_details.last_name,
        date_of_birth: formatDob(personal_details?.date_of_birth),
        industry: personal_details.industry,
        occupation: personal_details.occupation,
        education: personal_details.education,
      });
    }
    configurations.pathname ? history.push(configurations.pathname) : history.push('/auto');
  };

  routePage = (completedSteps: boolean, component: any, redirect: string) => {
    return completedSteps ? component : <Redirect to={redirect} />;
  };

  displaySidebar = (location: any) => {
    return (
      !location.pathname.includes(this.applicantRoute) && !location.pathname.includes('auto/quotes')
    );
  };

  pageClass = (showSidebar: boolean, match: any) => {
    return showSidebar
      ? 'col-md-9'
      : this.props.location.pathname.includes(this.applicantRoute)
      ? 'col-md-10 offset-md-1'
      : `${match.url}/quotes` === this.props.location.pathname
      ? 'col-md-12'
      : 'col-md-9';
  };

  diplayStepSidebar = (showSidebar: boolean) => {
    return showSidebar ? (
      <div className={showSidebar ? 'col-md-7 offset-md-1' : ''}>
        <StepSidebar {...(this.props as any)} />
      </div>
    ) : (
      ''
    );
  };

  getQuotesRowClasses = (match: any) => {
    let rowClasses = '';
    rowClasses = `${match.url}/quotes` === this.props.location.pathname ? 'adjust-row-width' : '';
    return `row ${rowClasses}`;
  };

  render() {
    const { match, completed_steps, homeAddressCheck, location } = this.props;
    const { isViewQuotes } = this.state;
    const showSidebar = this.displaySidebar(location);

    return (
      <div className='auto-steps-container'>
        {homeAddressCheck ? (
          <section>
            <BlockUi
              className='quote-loader'
              tag='div'
              blocking={true}
              message='Validating address, please wait'
              renderChildren={true}
              defaults=''
              loader={<Loader />}
            />
          </section>
        ) : (
          <React.Fragment>
            <section className='position-relative'>
              <div className='container'>
                <div className={this.getQuotesRowClasses(match)}>
                  {this.diplayStepSidebar(showSidebar)}
                  <div className={this.pageClass(showSidebar, match)}>
                    <Switch>
                      <Redirect exact from={`${match.url}/`} to={`${match.url}/applicant`} />
                      <Route path={`${match.url}/applicant`} render={() => <AutoApplicant />} />
                      {/*<Route*/}
                      {/*  path={`${match.url}/vehicle`}*/}
                      {/*  render={props => {*/}
                      {/*    return this.routePage(*/}
                      {/*      completed_steps.vehicles,*/}
                      {/*      <Vehicle {...props} />,*/}
                      {/*      'applicant'*/}
                      {/*    );*/}
                      {/*  }}*/}
                      {/*/>*/}
                      {/*<Route*/}
                      {/*  path={`${match.url}/driver`}*/}
                      {/*  render={props => {*/}
                      {/*    return this.routePage(*/}
                      {/*      completed_steps.vehicles && completed_steps.drivers,*/}
                      {/*      <Driver {...props} />,*/}
                      {/*      'vehicle'*/}
                      {/*    );*/}
                      {/*  }}*/}
                      {/*/>*/}
                      {/*<Route*/}
                      {/*  path={`${match.url}/otherDetails`}*/}
                      {/*  render={props => {*/}
                      {/*    return this.routePage(*/}
                      {/*      completed_steps.vehicles && completed_steps.drivers,*/}
                      {/*      <Incidents {...props} />,*/}
                      {/*      'driver'*/}
                      {/*    );*/}
                      {/*  }}*/}
                      {/*/>*/}
                      {/*<Route*/}
                      {/*  path={`${match.url}/details`}*/}
                      {/*  render={props => {*/}
                      {/*    return this.routePage(*/}
                      {/*      completed_steps.vehicles &&*/}
                      {/*        completed_steps.drivers &&*/}
                      {/*        completed_steps.details,*/}
                      {/*      <InsuranceDetails {...props} />,*/}
                      {/*      'driver'*/}
                      {/*    );*/}
                      {/*  }}*/}
                      {/*/>*/}
                      <Route
                        path={`${match.url}/quotes`}
                        render={props => {
                          return this.routePage(
                            (completed_steps.vehicles &&
                              completed_steps.drivers &&
                              completed_steps.details) ||
                              isViewQuotes,
                            <AutoQuotes {...props} />,
                            'details'
                          );
                        }}
                      />
                      <Redirect to={`${match.url}/vehicle`} />
                    </Switch>
                  </div>
                </div>
              </div>

              {!this.props.location.pathname.includes(this.applicantRoute) && (
                <EditSidebar {...this.props} />
              )}

              <Footer />
            </section>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ common, home }: AppComponents.AutoWrapperStore) => {
  const { pconfig, completed_steps, homeAddressCheck } = common;
  return { pconfig, completed_steps, homeAddressCheck, home };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AutoWrapperDispatch => {
  return bindActionCreators(
    {
      stepChanged,
      setPconfig,
      applicantDetailsFromHomeInsurance,
      setHomeQuoteFlag,
      updateDriverName,
      validateHomeAddress,
      setConfigLoader,
      setHomeState,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AutoWrapper));
