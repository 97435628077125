/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* istanbul ignore file */
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { v4 } from 'uuid';
import config from '../../../config/config';
import * as types from '../constants';

interface DriverState {
  duid: string;
  hasSpouse: boolean;
  isPrimaryDriver: boolean;
  isSpouseAdded: boolean;
  isSpouse: boolean;
  first_name: string;
  last_name: string;
  gender: string;
  date_of_birth: string;
  marital_status: string;
  driver_licence: string;
  us_license_status: string;
  age_licensed: string;
  claims: string;
  violations: string;
  losses: string;
  relationship_status: string;
  relation_to_primary_driver: string;
  accidentList: Array<any>;
  violationList: Array<any>;
  lossList: Array<any>;
  spouseId: string;
  industry: string;
  occupation: string;
  education: string;
  isIncidentAvailable: boolean;
  isPolicyAvailable: boolean;
  isFetchAutoQuotes: boolean;
  isNeedToGoToRatesTab: boolean;
  isFetchedFromVerisk: boolean;
  isIncluded: boolean;
}

export interface DriverExtraState extends DriverState {
  driverLoader: false;
  driversList: Array<any>;
}

const INIT_DRIVER_STATE: DriverState = {
  duid: '',
  hasSpouse: false,
  isPrimaryDriver: false,
  isSpouseAdded: false,
  isSpouse: false,
  first_name: '',
  last_name: '',
  gender: 'male',
  date_of_birth: '',
  marital_status: '',
  driver_licence: '',
  us_license_status: '',
  age_licensed: '',
  claims: 'no',
  violations: 'no',
  losses: 'no',
  relationship_status: 'Other',
  accidentList: [],
  violationList: [],
  lossList: [],
  spouseId: '',
  industry: 'Other',
  occupation: 'Other',
  education: 'High School Diploma',
  relation_to_primary_driver: '',
  isIncidentAvailable: false,
  isPolicyAvailable: false,
  isFetchAutoQuotes: false,
  isNeedToGoToRatesTab: false,
  isFetchedFromVerisk: false,
  isIncluded: true,
};

const INIT_STATE: DriverExtraState = {
  ...INIT_DRIVER_STATE,
  duid: v4(),
  isPrimaryDriver: true,
  driverLoader: false,
  driversList: [],
};

const driverInitialize = (state: DriverExtraState, action: AppRedux.Action) => {
  const { hasSpouse, isSpouse, marital_status, duid, spouseId } = action.payload;
  let marriedDetails = {};
  if (hasSpouse && !isSpouse && isEmpty(spouseId) && !get(action, 'payload.sidebarEdit', false)) {
    marriedDetails = !isEmpty(action.payload)
      ? {
          marital_status: marital_status,
          relationship_status: 'Spouse',
          isSpouse: true,
          hasSpouse: hasSpouse,
          spouseId: duid,
        }
      : {};
  }

  const includedLimitReached = () => {
    const count = state.driversList.reduce(
      (total, driver) => (driver.isIncluded ? total + 1 : total),
      0
    );
    return count;
  };

  const isIncluded = includedLimitReached() >= config.maxVehicleLimit ? false : true;

  // If clear flag is true then only initialize new driver
  const currentDriverDetails = action.payload.clear
    ? { ...INIT_DRIVER_STATE, ...marriedDetails, duid: v4(), isIncluded }
    : { ...omit(action.payload, ['clear', 'sidebarEdit']) };
  return {
    ...state,
    ...currentDriverDetails,
    driversList: [...state.driversList],
  };
};

const updateDriversList = (state: DriverExtraState, action: AppRedux.Action) => {
  let driversList = [...state.driversList];
  let currentDriverDetails = action.payload.clear
    ? { ...omit(action.payload, ['clear']), duid: state.duid }
    : { ...omit(action.payload, ['clear']) };
  const existingDriverIndex = findIndex(driversList, { duid: currentDriverDetails.duid });
  if (existingDriverIndex >= 0) {
    driversList[existingDriverIndex] = {
      ...driversList[existingDriverIndex],
      ...currentDriverDetails,
    };
  } else {
    driversList = [
      ...driversList,
      {
        ...currentDriverDetails,
      },
    ];
  }
  const { duid, hasSpouse, isSpouseAdded, isPrimaryDriver, spouseId, marital_status } =
    action.payload;

  if (
    !hasSpouse &&
    isSpouseAdded &&
    isPrimaryDriver &&
    !isEmpty(spouseId) &&
    marital_status !== 'Married'
  ) {
    driversList = filter(driversList, driver => {
      if (driver.duid !== spouseId) {
        return driver;
      }
      return false;
    });
    driversList = map(driversList, driver => {
      if (driver.duid === duid) {
        driver = {
          ...driver,
          spouseId: '',
          isSpouseAdded: false,
          hasSpouse: false,
          isSpouse: false,
        };
      }
      return driver;
    });
    if (state.duid === duid) {
      currentDriverDetails = {
        ...currentDriverDetails,
        isSpouseAdded: false,
        hasSpouse: false,
        isSpouse: false,
        spouseId: '',
      };
    }
  }
  return {
    ...state,
    ...(action.payload.clear ? {} : { ...currentDriverDetails }),
    driversList,
  };
};

const updateSpecificDriver = (state: DriverExtraState, action: AppRedux.Action) => {
  let driversList = [...state.driversList];
  let currentDriverDetails = { ...omit(action.payload, ['clear']) };
  const existingDriverIndex = findIndex(driversList, { duid: currentDriverDetails.duid });
  if (existingDriverIndex >= 0) {
    driversList[existingDriverIndex] = {
      ...driversList[existingDriverIndex],
      ...currentDriverDetails,
    };
  }
  const { duid, hasSpouse, isSpouseAdded, isPrimaryDriver, spouseId, marital_status } =
    action.payload;
  if (
    !hasSpouse &&
    isSpouseAdded &&
    isPrimaryDriver &&
    !isEmpty(spouseId) &&
    marital_status !== 'Married'
  ) {
    driversList = filter(driversList, driver => {
      if (driver.duid !== spouseId) {
        return driver;
      }
      return false;
    });
    driversList = map(driversList, driver => {
      if (driver.duid === duid) {
        driver = {
          ...driver,
          spouseId: '',
          isSpouseAdded: false,
          hasSpouse: false,
          isSpouse: false,
        };
      }
      return driver;
    });
    if (state.duid === duid) {
      currentDriverDetails = {
        ...currentDriverDetails,
        isSpouseAdded: false,
        hasSpouse: false,
        isSpouse: false,
        spouseId: '',
      };
    }
  }
  return {
    ...state,
    driversList,
  };
};

const toggleDriverIncludedFlagSuccess = (state: DriverExtraState, action: AppRedux.Action) => {
  let updatedIsIncluded = state.isIncluded;

  const updatedDriversList = map(state.driversList, (driver: any) => {
    if (driver.duid === action.payload && !driver.isPrimaryDriver) {
      driver.isIncluded = !driver.isIncluded;
    }

    return driver;
  });

  if (action.payload === state.duid && !state.isPrimaryDriver) {
    updatedIsIncluded = !state.isIncluded;
  }

  return {
    ...state,
    isIncluded: updatedIsIncluded,
    driversList: updatedDriversList,
  };
};

const sortDrivers = (state: DriverExtraState, action: AppRedux.Action) => {
  const sortList = (driver: any) => {
    if (driver.isPrimaryDriver) {
      return -1;
    } else if (driver.isIncluded) {
      return 0;
    } else {
      return 1;
    }
  };

  let driversList = cloneDeep(state.driversList);

  driversList = sortBy(driversList, sortList);

  return {
    ...state,
    driversList,
  };
};

const getStoredDriversList = (state: DriverExtraState, action: AppRedux.Action) => {
  const updatedList = action.payload;
  return {
    ...state,
    driversList: updatedList,
  };
};

const checkSpouse = (hasSpouse: any, isSpouseAdded: any, isSpouse: any, spouseId: any) =>
  hasSpouse && (isSpouseAdded || isSpouse) && !isEmpty(spouseId);

const getCurrentDriverDetails = (
  driver: any,
  updatedDriverList: Array<any>,
  state: any,
  action: any,
  deletedDriver: any
) => {
  let currentDriver = { ...driver };
  if (updatedDriverList.length === 0) {
    currentDriver = {
      ...INIT_DRIVER_STATE,
      duid: v4(),
    };
  } else {
    if (action.payload === state.duid) {
      currentDriver = updatedDriverList[updatedDriverList.length - 1];
    }
  }
  if (deletedDriver.spouseId === currentDriver.duid && currentDriver.isSpouse === true) {
    currentDriver = {
      ...currentDriver,
      spouseId: '',
      isSpouse: false,
      hasSpouse: true,
      isSpouseAdded: false,
    };
  }
  return currentDriver;
};

const deleteDriverDetailsSuccess = (state: DriverExtraState, action: AppRedux.Action) => {
  const { driversList } = state;
  let currentDriver: any = { ...state };
  let updatedDriverList = filter(driversList, driver => driver.duid !== action.payload && driver);
  const deletedDriver = find(driversList, ['duid', action.payload]);
  const { spouseId, hasSpouse, isSpouseAdded, isSpouse } = deletedDriver;
  if (checkSpouse(hasSpouse, isSpouseAdded, isSpouse, spouseId)) {
    updatedDriverList = map(updatedDriverList, driver => {
      if (driver.duid === spouseId) {
        driver = {
          ...driver,
          spouseId: '',
          hasSpouse: true,
          isSpouse: false,
          isSpouseAdded: false,
        };
      }
      return driver;
    });
    if (currentDriver.duid === spouseId && currentDriver.isPrimaryDriver) {
      currentDriver = {
        ...currentDriver,
        isSpouseAdded: false,
        spouseId: '',
      };
    }
  }
  currentDriver = {
    ...getCurrentDriverDetails(currentDriver, updatedDriverList, state, action, deletedDriver),
  };
  return {
    ...state,
    ...currentDriver,
    driversList: [...updatedDriverList],
  };
};

const getUpdatedDriverList = (list: Array<any>, state: any) => {
  let updatedList = [...list];
  if (state.hasSpouse && !isEmpty(state.spouseId)) {
    const spouseDriver = find(updatedList, ['duid', state.duid]);
    if (isEmpty(spouseDriver)) {
      updatedList = map(updatedList, driver => {
        if (driver.duid === state.spouseId && driver.isSpouseAdded) {
          driver.spouseId = '';
          driver.isSpouseAdded = false;
        }
        return driver;
      });
    }
  }
  return updatedList;
};

const editSelectedDriver = (state: DriverExtraState, action: AppRedux.Action) => {
  const { driversList } = state;
  let selectedDriver = find(driversList, { duid: action.payload });
  let updatedList = [...getUpdatedDriverList(driversList, state)];
  if (!isEmpty(selectedDriver.spouseId) && selectedDriver.isSpouseAdded) {
    const spouseIndex = findIndex(driversList, ['duid', selectedDriver.spouseId]);
    if (spouseIndex === -1) {
      selectedDriver = {
        ...selectedDriver,
        isSpouseAdded: false,
        spouseId: '',
      };
      updatedList = map(updatedList, driver => {
        if (driver.duid === selectedDriver.duid) {
          driver = { ...selectedDriver };
        }
        return driver;
      });
    }
  }
  return {
    ...state,
    ...selectedDriver,
    driversList: [...updatedList],
  };
};

export default (state = INIT_STATE, action: AppRedux.Action): DriverExtraState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        driverLoader: false,
      };
    }

    case types.DRIVER_INIT: {
      return driverInitialize(state, action);
    }

    case types.UPDATE_DRIVER_NAME: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.UPDATE_DRIVER_LIST: {
      return updateDriversList(state, action);
    }

    case types.DRIVER_LOADER: {
      return {
        ...state,
        driverLoader: action.payload,
      };
    }

    case types.DELETE_DRIVER_DETAILS_SUCCESS: {
      return deleteDriverDetailsSuccess(state, action);
    }

    case types.EDIT_SELECTED_DRIVER: {
      return editSelectedDriver(state, action);
    }

    case types.UPDATE_ACCIDENT_DETAILS: {
      const { driversList } = state;
      const { duid, data } = action.payload;
      const updatedList = map(driversList, driver => {
        if (driver.duid === duid) {
          driver.accidentList = data.accidentList;
          driver.violationList = data.violationList;
          driver.lossList = data.lossList;
        }
        return driver;
      });

      const updateCurrentDetails =
        state.duid === duid
          ? {
              ...state,
              accidentList: [...data.accidentList],
              violationList: [...data.violationList],
              lossList: [...data.lossList],
            }
          : { ...state };
      return {
        ...state,
        ...updateCurrentDetails,
        driversList: [...updatedList],
      };
    }

    // Clear accident list for respective driver
    case types.CLEAR_DRIVER_ACCIDENT_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        accidentList: [...data.accidentList],
      };
    }

    // Clear violation list for respective driver
    case types.CLEAR_DRIVER_VIOLATION_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        violationList: [...data.violationList],
      };
    }

    // Clear losses list for respective driver
    case types.CLEAR_DRIVER_LOSS_LIST: {
      const { data } = action.payload;
      return {
        ...state,
        lossList: [...data.lossList],
      };
    }

    case types.SET_INCIDENTS_FLAG: {
      return {
        ...state,
        isIncidentAvailable: action.payload,
      };
    }

    case types.SET_POLICY_FLAG: {
      return {
        ...state,
        isPolicyAvailable: action.payload,
      };
    }

    case types.SET_FETCH_AUTO_QUOTES: {
      return {
        ...state,
        isFetchAutoQuotes: action.payload,
      };
    }

    case types.SET_GO_TO_RATES_TAB_FROM_POLICY: {
      return {
        ...state,
        isNeedToGoToRatesTab: action.payload,
      };
    }

    case types.ASSIGN_SPOUSE: {
      const { spouseId, driverId } = action.payload;
      const { driversList } = state;
      const updatedList = map(driversList, driver => {
        if (driver.duid === driverId) {
          driver.spouseId = spouseId;
          driver.isSpouseAdded = true;
        }
        return driver;
      });
      return {
        ...state,
        driversList: [...updatedList],
      };
    }

    case types.UPDATE_DRIVER_OTHER_DETAILS: {
      const { duid, driversList } = state;
      const { entity, list } = action.payload;
      const updatedDriversList = map(driversList, driver => {
        if (driver.duid === duid) {
          driver = {
            ...driver,
            [entity]: [...list],
          };
        }
        return driver;
      });
      const currentDriver = {
        ...state,
        [entity]: [...list],
      };
      return {
        ...currentDriver,
        driversList: [...updatedDriversList],
      };
    }

    // Update driver details if redirected from view quote
    case types.UPDATE_DRIVER_DATA: {
      const defaultObjectValues = (source: any) => ({
        duid: get(source, 'duid', ''),
        hasSpouse: get(source, 'hasSpouse', false),
        isPrimaryDriver: get(source, 'isPrimaryDriver', false),
        isSpouseAdded: get(source, 'isSpouseAdded', false),
        isSpouse: get(source, 'isSpouse', false),
        first_name: get(source, 'first_name', ''),
        last_name: get(source, 'last_name', ''),
        gender: get(source, 'gender', ''),
        date_of_birth: get(source, 'date_of_birth', ''),
        marital_status: get(source, 'marital_status', ''),
        driver_licence: get(source, 'driver_licence', ''),
        us_license_status: get(source, 'us_license_status', ''),
        age_licensed: get(source, 'age_licensed', ''),
        claims: get(source, 'claims', 'no'),
        violations: get(source, 'violations', 'no'),
        losses: get(source, 'losses', 'no'),
        relationship_status: get(source, 'relationship_status', 'other'),
        accidentList: get(source, 'accidentList', []),
        violationList: get(source, 'violationList', []),
        lossList: get(source, 'lossList', []),
        spouseId: get(source, 'spouseId', ''),
        driverLoader: get(source, 'driverLoader', false),
        industry: get(source, 'industry', ''),
        occupation: get(source, 'occupation', ''),
        education: get(source, 'education', ''),
        relation_to_primary_driver: get(source, 'relation_to_primary_driver', ''),
      });
      const updatedDriversList = map(action.payload, defaultObjectValues);
      return {
        ...state,
        ...updatedDriversList[0],
        driversList: [...updatedDriversList],
      };
    }

    case types.CLEAR_DRIVER_STATE: {
      return {
        ...INIT_STATE,
      };
    }

    case types.UPDATE_SPECIFIC_DRIVER: {
      return updateSpecificDriver(state, action);
    }

    case types.TOGGLE_DRIVER_INCLUDED_STATUS_SUCCESS: {
      return toggleDriverIncludedFlagSuccess(state, action);
    }

    case types.SORT_DRIVER: {
      return sortDrivers(state, action);
    }

    case types.GET_STORED_DRIVERS_LIST: {
      return getStoredDriversList(state, action);
    }

    default:
      return state;
  }
};
