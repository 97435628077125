import { Checkbox, ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { ALL_PRODUCT } from '../../../constants/common';

interface MultiSelectProps {
  id: string;
  name: string;
  values: string[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  options: string[];
}

const drawMenuItem = (itemValue: string, isChecked: boolean) => {
  return (
    <MenuItem key={itemValue.toLowerCase()} value={itemValue}>
      <ListItemIcon>
        <Checkbox checked={isChecked} />
      </ListItemIcon>
      <ListItemText primary={itemValue} />
    </MenuItem>
  );
};

export const MultiSelect = ({
  id,
  name,
  onChange,
  className,
  options = [],
  values = [],
}: MultiSelectProps) => {
  const isAllSelected = options.length > 0 && values.length === options.length;
  const handleChange = (event: any) => {
    let value = event.target.value;
    if (value[value.length - 1] === ALL_PRODUCT) {
      event.target.value = value.length === options.length + 1 ? [] : options;
    }
    onChange(event);
  };

  return (
    <Select
      id={id}
      name={name}
      multiple
      value={values}
      onChange={handleChange}
      className={className}
      renderValue={selected => {
        const tempSelected = selected as string[];
        return tempSelected.join(', ');
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      {drawMenuItem(ALL_PRODUCT, isAllSelected)}
      {options.map(option => drawMenuItem(option, values.includes(option)))}
    </Select>
  );
};

export default MultiSelect;
