import { parseToObject } from "../parsers/strings";
import { stringToArray } from "../parsers/string-to-array";
import { stringToBoolean } from "../parsers/string-to-boolean";
import windowObj from "./window";

const production = {
  serverURL: process.env.REACT_APP_SERVER_URL || 'https://api.gator.myhippo.com',
  hcrIframeUrl: process.env.REACT_APP_HCR_IFRAME_URL || 'https://hcr-iframe.hippo.com/static/iframe/iframe.js',
  heapId: process.env.REACT_APP_HEAP_ID || '1903874346',
  googleAnalyticsId: process.env.REACT_APP_GA_ID || 'UA-74697382-10',
  delayMsgTime: process.env.REACT_APP_DELAYMSG_TIME || 2000,
  requestTimeout: process.env.REACT_APP_REQUEST_TIMEOUT || 200000, // Request timeout after 20 sec.
  contentTimeout: process.env.REACT_APP_CONTENT_TIMEOUT || 4000, // Content timeout
  pollingTimerTimeout: process.env.REACT_APP_POLLING_TIMEOUT || 60000,
  pollingInterval: process.env.REACT_APP_POLLING_INTERVAL || 1000,
  googleAutoCompleteKey:
    process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY ||
    windowObj?.appConfig?.REACT_APP_GOOGLE_AUTOCOMPLETE_KEY,
  googleCountryRestriction: process.env.REACT_APP_GOOGLE_COUNTRY_RESTRICTIONS || 'us',
  hippo: {
    carrier_id: process.env.REACT_APP_DEFAULT_CARRIER_ID || '1',
    name: process.env.REACT_APP_DEFAULT_CONFIG_NAME || 'Hippo Insurance',
    quote_name: process.env.REACT_APP_HIPPO_NAME || 'Hippo',
    code: process.env.REACT_APP_DEFAULT_CONFIG_CODE || 'hippoagency',
    error_logo_url:
      process.env.REACT_APP_DEFAULT_CONFIG_ERROR_LOGO_URL ||
      'https://static.hippo.com/hcr/Hippo-logo.png',
    error_text:
      process.env.REACT_APP_DEFAULT_CONFIG_ERROR_TEXT ||
      'Hippo has an expedited policy binding process, so you can secure your new homeowners ' +
        'insurance in a little as 3-minutes online or with the support of a licensed agent.',
    title:
      process.env.REACT_APP_DEFAULT_CONFIG_META_TITLE || 'Hippo - Better, Cheaper Home Insurance.',
    favicon:
      process.env.REACT_APP_DEFAULT_CONFIG_META_FAVICON ||
      'https://static.hippo.com/hcr/hippo_favicon.ico',
    description:
      process.env.REACT_APP_DEFAULT_CONFIG_META_DESCRIPTION ||
      'Simpler, smarter home and condo insurance for up to 25% less. Hippo has modernized home ' +
        'insurance. We calculate quotes instantly and allow you to buy homeowners insurance ' +
        'entirely online or purchase on mobile. We save cost by eliminating commissioned agents ' +
        'and pass on the savings with better coverage at cheaper prices.',
    email: process.env.REACT_APP_DEFAULT_CONFIG_CONTACT_EMAIL || 'sales@myhippo.com',
    phone: process.env.REACT_APP_DEFAULT_CONFIG_CONTACT_PHONE || '1-800-886-0318',
    total_number_of_quotes: process.env.REACT_APP_DEFAULT_CONFIG_TOTAL_NUMBER_OF_QUOTES || 4,
    hippo_branding_on_dale: stringToBoolean(
      process.env.REACT_APP_DEFAULT_CONFIG_HIPPO_BRANDING_ON_DALE,
      false
    ),
    partner_license:
      process.env.REACT_APP_DEFAULT_CONFIG_DIRECT_APPOINTMENT_PARTNER_LICENSE ||
      'https://api.myhippo.com/v1/herd/compare/view/license?hippobranding=true',
    license_text:
      process.env.REACT_APP_DEFAULT_CONFIG_DIRECT_APPOINTMENT_LICENSE_TEXT ||
      'Insurance quotes provided by Hippo Insurance Services',
    license_prefix_text:
      process.env.REACT_APP_HIPPO_LICENSE_PREFIX_TEXT || 'Insurance quotes provided by',
    logo_url:
      process.env.REACT_APP_DEFAULT_CONFIG_HIPPO_BRANDING_DETAILS_LOGO_URL ||
      'https://static.hippo.com/hcr/Hippo-white-logo.png',
    template_name:
      process.env.REACT_APP_DEFAULT_CONFIG_HIPPO_BRANDING_DETAILS_TEMPLATE_NAME || 'hippo-green',
    host: process.env.REACT_APP_HIPPO_HOST || 'https://api.myhippo.com',
    quote_logo_url:
      process.env.REACT_APP_HIPPO_LOGO_URL || 'https://static.hippo.com/hcr/Hippo-logo.png',
    purchase_type: process.env.REACT_APP_HIPPO_PURCHASE_TYPE || 'Purchase Online',
    purchase_online_link:
      process.env.REACT_APP_HIPPO_PURCHASE_LINK ||
      '/v1/lead/__LEADID_PLACEHOLDER__/returnlead/your-home?event=herd&pageId=your-home',
    popup_text:
      process.env.REACT_APP_HIPPO_POPUP_TEXT ||
      '<a href="https://myhippo.com/?utm_campaign=__CODE_PLACEHOLDER__" target="_blank" ' +
        'rel="noopener noreferrer">Hippo Insurance</a> is working to offer you better home ' +
        'insurance quotes you can instantly compare so you can save time and money. To provide the ' +
        'most accurate quotes, we need the following information for the person whose name will ' +
        'be on the policy:',
    find_insurance_title:
      process.env.REACT_APP_HIPPO_FIND_INSURANCE_TITLE || 'Find your best home insurance',
    find_insurance_flood_title:
      process.env.REACT_APP_HIPPO_FIND_INSURANCE_FLOOD_TITLE || 'Find your best Flood insurance',
    find_insurance_subtitle:
      process.env.REACT_APP_HIPPO_FIND_INSURANCE_SUBTITLE ||
      'Quote comparison, top coverage details at your fingertips, and savings in seconds',
    moreinfo_title:
      process.env.REACT_APP_HIPPO_MOREINFO_TITLE ||
      "We'll need more information to verify your home address",
    moreinfo_subtitle:
      process.env.REACT_APP_HIPPO_MOREINFO_SUBTITLE ||
      'Some newly constructed or rural homes require a couple extra steps',
  },
  ezlynxPurchaseType: process.env.REACT_APP_EZLYNX_PURCHASE_TYPE || 'Bridge to carrier',
  placeholderGetYourQuotes: process.env.REACT_APP_PLACEHOLDER_GET_YOUR_QUOTES || 'Get Your Quotes',
  maxAccidentLimit: process.env.REACT_APP_AUTO_MAX_ACCIDENT_LIMIT || 10,
  maxVehicleLimit: process.env.REACT_APP_VEHICLE_MAX_LIMIT || 6,
  maxDriverLimit: process.env.REACT_APP_DRIVER_MAX_LIMIT || 6,
  maxVehicleAddLimit: process.env.REACT_APP_VEHICLE_MAX_LIMIT || 20,
  maxDriverAddLimit: process.env.REACT_APP_DRIVER_MAX_LIMIT || 20,
  loginUrl: process.env.REACT_APP_LOGIN_URL || 'https://api.gator.myhippo.com/auth/login',
  logoutUrl: process.env.REACT_APP_LOGOUT_URL || 'https://api.gator.myhippo.com/auth/logout',
  reportLoginUrl: process.env.REACT_APP_REPORT_LOGIN_URL || 'https://api.gator.myhippo.com/auth/report/login',
  reportLogoutUrl: process.env.REACT_APP_REPORT_LOGOUT_URL || 'https://api.gator.myhippo.com/auth/report/logout',
  reportName: 'Report',
  bulkQuoteLoginUrl:
    process.env.REACT_BULK_QUOTE_LOGIN_URL || 'https://api.gator.myhippo.com/auth/bulk-quote/login',
  bulkQuoteLogoutUrl:
    process.env.REACT_BULK_QUOTE_LOGOUT_URL ||
    'https://api.gator.myhippo.com/auth/bulk-quote/logout',
  bulkQuoteName: 'Bulk Quote',
  adminUser: 'Admin',
  adminLogInUrl: 'https://api.gator.myhippo.com/auth/admin/login',
  adminLogOutUrl: 'https://api.gator.myhippo.com/auth/admin/logout',
  // Define partner companies for quotes
  subOrganizationList: [
    {
      name: 'Hippo Insurance',
      logoUrl: 'https://static.hippo.com/hcr/hippo_sub.png',
      code: 'hippoagency',
      AMSCode: '^^^',
    },
    {
      name: 'Lennar',
      logoUrl: 'https://static.hippo.com/hcr/lennar_sub.png',
      code: '3pslennar',
      AMSCode: '!!"',
    },
    {
      name: 'NAAIS',
      logoUrl: 'https://static.hippo.com/hcr/naais_logo.png',
      code: 'naais',
      AMSCode: '!!"',
    },
  ],
  divisionList: [
    {
      name: 'Hippo Insurance',
      logoUrl: 'https://static.hippo.com/hcr/hippo_sub.png',
      code: 'hippoagency',
      AMSCode: '^^^',
    },
    {
      name: 'Lennar',
      logoUrl: 'https://static.hippo.com/hcr/lennar_sub.png',
      code: '3pslennar',
      AMSCode: '!!"',
    },
    {
      name: 'NAAIS',
      logoUrl: 'https://static.hippo.com/hcr/naais_logo.png',
      code: 'naais',
      AMSCode: '!!"',
    },
    {
      name: 'PennyMac',
      code: 'pennymacinsurance',
      AMSCode: '!!$',
    },
  ],
  prioritizeFloodCarrier: 'Hippo',
  floodBridgingCarriersList: ['A107', 'NGEN1001'],
  enableCarrierCategorization: stringToBoolean(process.env.REACT_APP_ENABLE_CARRIER_CATEGORIZATION, true),
  enablePrefillOverride: stringToBoolean(process.env.REACT_APP_ENABLE_PREFILL_OVERRIDE, true),
  enableDownloadQuote: stringToBoolean(process.env.REACT_APP_ENABLE_DOWNLOAD_QUOTE, true),
  enableAdmittedOrNonAdmittedCarrier: stringToBoolean(process.env.REACT_APP_ENABLE_ADMITTED_OR_NON_ADMITTED_CARRIER, true),
  enablePrefillBanner: stringToBoolean(process.env.REACT_APP_ENABLE_PREFILL_BANNER, true),
  amsHeaderDefaultSettings: parseToObject(
    process.env.REACT_APP_AMS_HEADER_DEFAULT_SETTINGS ||
      windowObj?.appConfig?.REACT_APP_AMS_HEADER_DEFAULT_SETTINGS,
    {}
  ),
  nonAdmittedSL2PowerFormLink:
    process.env.REACT_APP_NON_ADMITTED_SL2_POWER_FORM_LINK ||
    windowObj?.appConfig?.REACT_APP_NON_ADMITTED_SL2_POWER_FORM_LINK ||
    '',
  nonAdmittedDueDiligencePowerFormLink:
    process.env.REACT_APP_NON_ADMITTED_DUE_DILIGENCE_POWER_FORM_LINK ||
    windowObj?.appConfig?.REACT_APP_NON_ADMITTED_DUE_DILIGENCE_POWER_FORM_LINK ||
    '',
  excludeDocUploadForNonAdmittedCarriers: stringToArray(
    process.env.REACT_APP_EXCLUDE_DOC_UPLOAD_FOR_NON_ADMITTED_CARRIERS ||
      windowObj?.appConfig?.REACT_APP_EXCLUDE_DOC_UPLOAD_FOR_NON_ADMITTED_CARRIERS,
    []
  ),
  bffBaseUrl:
    process.env.REACT_APP_BFF_BASE_URL ||
    windowObj?.appConfig?.REACT_APP_BFF_BASE_URL,
  featureEnablePRSv2: stringToBoolean(
    process.env.REACT_APP_FEATURE_ENABLE_PRS_V2 ||
      windowObj?.appConfig?.REACT_APP_FEATURE_ENABLE_PRS_V2,
    false
  ),
};

export default production;
