/* istanbul ignore file */
import * as organizationActionType from '../constants';

export const organizationLoader = (payload: boolean): AppRedux.Action => ({
  type: organizationActionType.ORGANIZATION_LIST_LOADER,
  payload,
});

export const fetchOrganizationsList = (): AppRedux.Action => ({
  type: organizationActionType.FETCH_ORGANIZATION_LIST,
});

export const storeOrganizationListData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_ORGANIZATION_LIST,
  payload,
});

export const getOrganizationData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.GET_ORGANIZATION_DATA,
  payload,
});

export const storeOrganizationData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_ORGANIZATION_DATA,
  payload,
});

export const addEditOrganizationLoader = (payload: boolean): AppRedux.Action => ({
  type: organizationActionType.ADD_EDIT_ORGANIZATION_LOADER,
  payload,
});

export const addNewOrganization = (payload: any): AppRedux.Action => ({
  type: organizationActionType.ADD_NEW_ORGANIZATION,
  payload,
});

export const storeNewOrganizationData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_NEW_ORGANIZATION,
  payload,
});

export const updateOrganizationData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.UPDATE_ORGANIZATION_DATA,
  payload,
});

export const storeUpdatedOrganizationData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_UPDATED_ORGANIZATION_DATA,
  payload,
});

export const setOrgUpdatedResponseData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.SET_ORG_UPDATED_RESPONSE_DATA,
  payload,
});

export const storeCarrierAppointmentsData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_CARRIER_APPOINTMENTS_DATA,
  payload,
});

export const deletCarrierAppointment = (payload: any): AppRedux.Action => ({
  type: organizationActionType.DELETE_CARRIER_APPOINTMENTS_DATA,
  payload,
});

export const removeCarrierAppointmentFromlist = (payload: any): AppRedux.Action => ({
  type: organizationActionType.REMOVE_CARRIER_APPOINTMENTS_FROM_LIST,
  payload,
});

export const getOrganizationDataLoader = (payload: boolean): AppRedux.Action => ({
  type: organizationActionType.GET_ORGANIZATION_LOAGER,
  payload,
});

export const fetchLeadTypeListData = (): AppRedux.Action => ({
  type: organizationActionType.FETCH_LEAD_TYPE_LIST,
});

export const setLeadTypeListLoader = (payload: boolean): AppRedux.Action => ({
  type: organizationActionType.LEAD_TYPE_LIST_LOADER,
  payload,
});

export const storeLeadTypeListData = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_LEAD_TYPE_LIST_DATA,
  payload,
});

export const setOrgAccountsListData = (
  payload: Array<Record<string, string>>
): AppRedux.Action => ({
  type: organizationActionType.SET_ORG_ACCOUNTS_LIST_DATA,
  payload,
});

export const setDuplicateLeadIdErrorMessage = (payload: any): AppRedux.Action => ({
  type: organizationActionType.SET_DUPLICATE_LEAD_ID_ERROR_MESSAGE,
  payload,
});

export const setHcrIframeClientLoader = (payload: boolean): AppRedux.Action => ({
  type: organizationActionType.HCR_IFRAME_CLIENT_LIST_LOADER,
  payload,
});

export const fetchHcrIFrameClient = (organizationId: string): AppRedux.Action => ({
  type: organizationActionType.FETCH_HCR_IFRAME_CLIENT,
  payload: organizationId,
});

export const storeHcrIframeSettingsList = (payload: Array<any>): AppRedux.Action => ({
  type: organizationActionType.STORE_HCR_IFRAME_SEETINGS_LIST,
  payload,
});

export const addAndUpdateHcrIframeSettings = (payload: any): AppRedux.Action => ({
  type: organizationActionType.ADD_AND_UPDATE_HCR_IFRAME_SETTINGS,
  payload,
});

export const storeHcrIframeSettings = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_HCR_IFRAME_SETTINGS,
  payload,
});

export const deleteHcrIframeSetting = (payload: any): AppRedux.Action => ({
  type: organizationActionType.DELETE_HCR_IFRAME_SETTINGS,
  payload,
});

export const removeHcrIframeSettingFormList = (payload: any): AppRedux.Action => ({
  type: organizationActionType.REMOVE_HCR_IFRAME_SETTINGS_FROM_LIST,
  payload,
});

export const setAllowUpdateLeadRecordType = (payload: any): AppRedux.Action => ({
  type: organizationActionType.SET_ALLOW_UPDATE_LEAD_RECORD_TYPE,
  payload,
});

export const fetchAccountRecordTypeList = (): AppRedux.Action => ({
  type: organizationActionType.FETCH_ACCOUNT_RECORD_TYPE_LIST,
});

export const setAccountRecordTypeList = (payload: any): AppRedux.Action => ({
  type: organizationActionType.SET_ACCOUNT_RECORD_TYPE_LIST,
  payload,
});

export const fetchEoiSettings = (payload: any): AppRedux.Action => ({
  type: organizationActionType.FETCH_EOI_SETTINGS,
  payload,
});

export const setEoiSettingsListLoader = (payload: any): AppRedux.Action => ({
  type: organizationActionType.EOI_SETTINGS_LIST_LOADER,
  payload,
});

export const storeEoiSettingsList = (payload: any): AppRedux.Action => ({
  type: organizationActionType.STORE_EOI_SETTINGS_LIST,
  payload,
});

export const addEditEoiSetting = (payload: any): AppRedux.Action => ({
  type: organizationActionType.ADD_EDIT_EOI_SETTING,
  payload,
});

export const addEditEoiSettingloader = (payload: any): AppRedux.Action => ({
  type: organizationActionType.ADD_EDIT_EOI_SETTING_LOADER,
  payload,
});

export const saveUpdatedEoiSettingtoList = (payload: any): AppRedux.Action => ({
  type: organizationActionType.SAVE_UPDATED_EOI_SETTINGS_TO_LIST,
  payload,
});

export const deleteEoiSetting = (payload: any): AppRedux.Action => ({
  type: organizationActionType.DELETE_EOI_SETTING,
  payload,
});

export const removeEoiSettingsFromList = (payload: any): AppRedux.Action => ({
  type: organizationActionType.REMOVE_EOI_SETTING_FROM_LIST,
  payload,
});
