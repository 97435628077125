import React from 'react';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import TextField from '@material-ui/core/TextField';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { BULK_QUOTE_ERRORS, PAGES_TEXT, SPACEBAR_KEY_CODE } from '../../../constants';
import {
  fetchQuotingOrganizationList,
  setServerMessage,
  storeQuotingErrorMessage,
  submitBulkQuoteStep,
  updatePartnerConfig,
} from '../../redux/actions';
import SpinnerLoader from '../../components/spinner-loader';

import './organization-selector.scss';
import { Typography } from '@material-ui/core';

const styles = (theme: Theme) => ({
  continueSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
  deleteSpinner: {
    color: '#00cf83 !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
});

class OrganizationSelector extends React.Component<
  AppComponents.OrganizationSelectorProps & WithStyles<typeof styles>,
  AppComponents.OrganizationSelectorState
> {
  state: AppComponents.OrganizationSelectorState = {
    entity: 'organizationSelector',
    selectedOrganization: null,
    inputCode: '',
    selectorError: '',
    successMessage: '',
    type: '',
  };

  componentDidMount() {
    const { inputCode, selectedOrganization } = this.props;
    this.props.fetchQuotingOrganizationList();
    this.setState({
      inputCode,
      selectedOrganization,
    });
  }

  componentWillReceiveProps(newProps: AppComponents.OrganizationSelectorProps) {
    if (!isEmpty(newProps.serverError) && isEmpty(this.state.selectorError)) {
      this.setState({
        selectorError: newProps.serverError,
        successMessage: '',
      });
    }
    if (!isEmpty(newProps.successMessage) && isEmpty(this.state.successMessage)) {
      this.setState({
        successMessage: newProps.successMessage,
        selectorError: '',
      });
    }
  }

  componentWillUnmount = () => {
    this.props.storeQuotingErrorMessage('');
  }

  onOrganizationSelect = (organization: any) => {
    const { bulkUser, selectorLoader } = this.props;
    this.props.setServerMessage('');

    if (!selectorLoader) {
      this.setState(
        {
          inputCode: '',
          selectorError: '',
          selectedOrganization: { ...organization },
          type: '',
          successMessage: '',
        },
        () => {
          if (bulkUser) {
            this.props.submitBulkQuoteStep({
              entity: this.state.entity,
              data: { ...organization },
            });
          }
        }
      );
    }
  };

  onOrganizationSelectKeyDown = (e: any, organization: any) => {
    const { selectorLoader } = this.props;
    if (!selectorLoader && e.type === 'keydown' && e.keyCode === SPACEBAR_KEY_CODE) {
      e.preventDefault();
      this.onOrganizationSelect(organization);
    }
  };

  onOrganizationCodeInput = (event: any) => {
    this.setState({
      selectedOrganization: null,
      selectorError: '',
      inputCode: event.target.value.trim(),
      type: '',
      successMessage: '',
    });
  };

  onNext = (type: any) => {
    const { inputCode, selectorError, selectedOrganization } = this.state;
    if (this.props.bulkUser && !isEmpty(selectorError)) {
      return false;
    }

    this.props.setServerMessage('');
    if (isEmpty(inputCode) && isEmpty(selectedOrganization)) {
      this.setState({
        selectorError: BULK_QUOTE_ERRORS.organizationInputError,
        successMessage: '',
      });
    } else {
      this.setState(
        {
          selectorError: '',
          successMessage: '',
          type: type,
        },
        () => {
          const organizationData = !isEmpty(inputCode)
            ? { inputCode }
            : { ...selectedOrganization };
          if (this.props.bulkUser) {
            this.props.submitBulkQuoteStep({
              entity: this.state.entity,
              data: {
                ...organizationData,
              },
            });
          } else {
            this.props.updatePartnerConfig({
              data: { ...organizationData },
              type,
            });
          }
        }
      );
    }
    return true;
  };

  onOrganizationInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { selectorError, selectedOrganization } = this.state;
    if (event.key === 'Enter' && isEmpty(selectorError) && isEmpty(selectedOrganization)) {
      return this.onNext(this.props.bulkUser ? 'next' : 'rebuild');
    }
    return false;
  };

  getOrganizationBoxClasses = (organization: any): string =>
    `${
      this.state.selectedOrganization &&
      this.state.selectedOrganization.code === organization.code &&
      'selected-org'
    } col-md-3 col-sm-3 mb-2`;

  render() {
    const { inputCode, selectorError, type, successMessage } = this.state;
    const { classes, selectorLoader, common } = this.props;
    const { 
      quotingOrganizationlistLoader,
      quotingOrganizationList,
      quotingListErrorMessage 
    } = common;
    return (
      <React.Fragment>
        <div className='org-divider'>{PAGES_TEXT.bulkQuoteStepOneHeading}</div>
        <div className='row justify-content-md-center'>
          {!isEmpty(quotingOrganizationList) && !quotingOrganizationlistLoader ? (
            map(quotingOrganizationList, subOrganization => (
              <div
                className={this.getOrganizationBoxClasses(subOrganization)}
                key={subOrganization.name}
                onClick={this.onOrganizationSelect.bind(this, subOrganization)}
              >
                <div
                  className={`org-container ${
                    (selectorLoader || !!quotingOrganizationlistLoader) && 'disabled-org'
                  }`}
                  tabIndex={0}
                  onKeyDown={e => this.onOrganizationSelectKeyDown(e, subOrganization)}
                >
                  <img
                    src={subOrganization.logoUrl}
                    className='img-fluid'
                    alt={startCase(subOrganization.name)}
                    title={startCase(subOrganization.name)}
                  />
                </div>
              </div>
            ))
          ) : (
            <Typography className='text-center error-lbl'>
              {!!quotingListErrorMessage ? quotingListErrorMessage : ''}
            </Typography>
          )}
        </div>
        <div className='org-divider'>OR</div>
        <div className='row'>
          <TextField
            className='org-code-input'
            variant='outlined'
            placeholder={PAGES_TEXT.organizationInputPlaceholder}
            disabled={selectorLoader || !!quotingOrganizationlistLoader}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 50,
              spellCheck: false,
            }}
            onChange={this.onOrganizationCodeInput}
            onKeyUp={this.onOrganizationInputKeyUp}
            value={inputCode}
          />
        </div>

        <div className='row error-row'>
          {!isEmpty(selectorError) && (
            <span className='error-message org-error'>{selectorError}</span>
          )}
          {!isEmpty(successMessage) && (
            <span className='error-message org-success'>{successMessage}</span>
          )}
        </div>

        <div className='row'>
          <div className='navigation-wrapper'>
            {!this.props.bulkUser && (
              <Button
                variant='outlined'
                className='navigation-delete-btn'
                disabled={selectorLoader || !!quotingOrganizationlistLoader}
                onClick={() => {
                  this.onNext('delete');
                }}
              >
                FLUSH CACHE{' '}
                {(selectorLoader || !!quotingOrganizationlistLoader) && type === 'delete' && (
                  <SpinnerLoader styleData={classes.deleteSpinner} />
                )}
              </Button>
            )}
            <Button
              variant='outlined'
              className='navigation-next-btn'
              disabled={selectorLoader || !!quotingOrganizationlistLoader}
              onClick={() => {
                this.onNext(this.props.bulkUser ? 'next' : 'rebuild');
              }}
            >
              {this.props.bulkUser ? 'NEXT' : 'REBUILD CACHE'}{' '}
              {(selectorLoader || !!quotingOrganizationlistLoader) && type !== 'delete' && (
                <SpinnerLoader styleData={classes.continueSpinner} />
              )}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  bulkQuote,
  auth,
  common
}: AppComponents.OrganizationSelectorStore): AppComponents.OrganizationSelectorStoreProps => {
  const {
    inputCode,
    selectedOrganization,
    selectorLoader,
    serverError,
    successMessage,
  } = bulkQuote;
  const { bulkUser } = auth;
  return { inputCode, selectedOrganization, selectorLoader, serverError, bulkUser, successMessage, common };
};

const mapDispatchToProps = (dispatch: any): AppComponents.OrganizationSelectorDispatch => {
  return bindActionCreators(
    {
      fetchQuotingOrganizationList,
      submitBulkQuoteStep,
      updatePartnerConfig,
      storeQuotingErrorMessage,
      setServerMessage,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(OrganizationSelector));
