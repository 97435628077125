import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import config from '../../../config/config';
import history from '../../../history';

import './footer.scss';

class FooterComponent extends React.Component<
  AppComponents.FooterComponentStoreProps,
  AppComponents.FooterComponentState
> {
  state: AppComponents.FooterComponentState = {
    autoLine: false,
  };

  getLicenseDetails = () => {
    const { pconfig, defaultPConfig, commonPConfig } = this.props;
    const configuration = pconfig || get(commonPConfig, 'pconfig', defaultPConfig);
    let licenseText = '';
    let partnerLicense = '';
    if (!isEmpty(configuration) &&
      configuration?.dale_config?.direct_appointment_access &&
      configuration?.dale_config?.direct_appointment_access_details) {
      if (!isEmpty(configuration.dale_config.direct_appointment_access_details.partner_license)) {
        partnerLicense =
          configuration.dale_config.direct_appointment_access_details.partner_license;
        if (!isEmpty(configuration.dale_config.direct_appointment_access_details.license_text)) {
          licenseText = configuration.dale_config.direct_appointment_access_details.license_text;
        } else {
          licenseText = `${config.hippo.license_prefix_text} ${configuration.name}`;
        }
      }
    } else {
      licenseText = defaultPConfig.dale_config.direct_appointment_access_details.license_text;
      partnerLicense = defaultPConfig.dale_config.direct_appointment_access_details.partner_license;
    }
    return { licenseText, partnerLicense };
  };

  render() {
    const { licenseText, partnerLicense } = this.getLicenseDetails();
    const isAutoPage = history.location.pathname.includes('auto');
    const isApplicantPage = history.location.pathname.includes('auto/applicant');
    return (
      <footer>
        <div className='footer-wrapper footer-copyright text-center'>
          <div className={isAutoPage && !isApplicantPage ? 'container' : 'container-fluid'}>
            <div className='row'>
              {!isEmpty(partnerLicense) && (
                <div className={isAutoPage && !isApplicantPage ? 'col-md-9' : 'col-md-12'}>
                  <p>
                    {licenseText}{' '}
                    <a href={partnerLicense} target='_blank' rel='noopener noreferrer'>
                      License #
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = ({
  router,
  home,
  common,
}: AppComponents.FooterComponentStore): AppComponents.FooterComponentStoreProps => {
  const { pconfig, defaultPConfig } = home;
  const { pconfig: commonPConfig } = common;
  return { pconfig, defaultPConfig, commonPConfig, router };
};

export default connect(mapStateToProps, {})(FooterComponent);
