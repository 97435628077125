import React from 'react';
import { isEmpty } from 'lodash';
import { InputLabel, Switch, Grid, Typography } from '@material-ui/core';
import { HTML_EM, LENDER_SETTING } from '../../../constants';
import { withStyles } from '@material-ui/core/styles';
import { getNestedTernaryData } from '../../../utils';
import './lender-setting.scss';

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#ccc',
    '&$checked': {
      color: '#00cf83',
    },
    '&$checked + $track': {
      backgroundColor: '#00cf83',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ViewLenderSettings = (props: any) => {
  const { editOrganizationData } = props;
  let address: string =
    !isEmpty(editOrganizationData?.lender_street) &&
    !isEmpty(editOrganizationData?.lender_city) &&
    !isEmpty(editOrganizationData?.lender_state) &&
    !isEmpty(editOrganizationData?.lender_zip)
      ? `${editOrganizationData?.lender_street}, ${editOrganizationData?.lender_city}, ${editOrganizationData?.lender_state}, ${editOrganizationData?.lender_zip}`
      : HTML_EM;
  return (
    <div>
      <div className='lender-setting-wrap'>
        <div className='row d-flex align-items-center mb-10 mt-3'>
          <div className='col-lg-8 col-md-12'>
            <Grid container spacing={2} className='d-flex align-items-center'>
              <Grid item xs={10} md={4} className='form-item'>
                <InputLabel className='form-item__label'>
                  {LENDER_SETTING.STATIC_SETTING_LABEL}:
                </InputLabel>
              </Grid>
              <Grid item xs={2} md={7} className='m-padding-left-0 m-flex'>
                <span className='p-0 switch-margin-left bb'>
                  <CustomSwitch
                    value={!!editOrganizationData?.lender_enabled ? true : false}
                    checked={!!editOrganizationData?.lender_enabled ? true : false}
                    name={LENDER_SETTING.STATIC_SETTING_KEY}
                    disabled={true}
                  />
                </span>
              </Grid>
            </Grid>
          </div>
          {!!editOrganizationData?.lender_enabled ? (
            <>
              <div className='col-lg-8 mt-4 mb-3'>
                <div className='row form-item'>
                  <div className='col-lg-4'>
                    <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_NAME_LABEL}>
                      <span className='padding-1'>{LENDER_SETTING.LENDER_NAME_LABEL}:</span>
                    </InputLabel>
                  </div>
                  <div className='col-lg-8'>
                    <Typography
                      className='normal-font border-lender text-elipsis'
                      title={getNestedTernaryData(
                        !isEmpty(editOrganizationData?.lender_name),
                        editOrganizationData?.lender_name,
                        ''
                      )}
                    >
                      {getNestedTernaryData(
                        !isEmpty(editOrganizationData?.lender_name),
                        editOrganizationData?.lender_name,
                        HTML_EM
                      )}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className='col-lg-8 mt-3'>
                <div className='row form-item'>
                  <div className='col-lg-4'>
                    <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_ADDRESS_LABEL}>
                      <span className='padding-1'>{LENDER_SETTING.LENDER_ADDRESS_LABEL} :</span>
                    </InputLabel>
                  </div>
                  <div className='col-lg-8'>
                    <Typography
                      className='normal-font border-lender text-elipsis'
                      title={getNestedTernaryData(!!address && address !== HTML_EM, address, '')}
                    >
                      {address}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewLenderSettings;
