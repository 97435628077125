import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import forEach from 'lodash/forEach';
import { FormControl, Input, InputLabel, NativeSelect, Select, TextField } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import range from 'lodash/range';
import some from 'lodash/some';

import {
  applicantFormSubmit,
  setVehiclesMissing,
  updateAddressInvalidFlag,
} from '../../redux/actions';
import AutoComplete from '../auto-complete';
import {
  AUTO_APPLICANT_FORM_SUBTITLE,
  AUTO_APPLICANT_FORM_TITLE,
  STATEDATA,
} from '../../../constants';
import DatePicker from '../date-picker';
import { EDUCATION_DATA } from '../../../constants/education';
import {
  getDefaultOccupation,
  getOccupationList,
  getStartCase,
  numberRegex,
  phoneRegex,
  stringRegex,
  stringWithSpaceRegex,
  validateDOB,
  validateEmail,
  validatePhoneNumber,
} from '../../../utils';
import { INDUSTRY_DATA } from '../../../constants/industry';
import PhoneMask from '../phone-mask';

import './auto-applicant-form.scss';

const styles = (theme: Theme) =>
  createStyles({
    icon: {
      fontSize: '35px',
    },
    continueSpinner: {
      color: 'white !important',
      marginBottom: '2px',
    },
    errorMsg: {
      color: '#FF0000',
      height: '0px',
      fontSize: '12px',
      textAlign: 'left',
      marginLeft: '10px',
    },
    formControl: {
      marginLeft: '8px',
      marginRight: '8px',
      minWidth: '100%',
    },
    textField: {
      width: '100%',
      marginLeft: '8px',
      marginRight: '8px',
    },
  });

class AutoApplicantForm extends React.Component<
  AppComponents.AutoContentProps & WithStyles<typeof styles>,
  AppComponents.AutoContentState
> {
  initFormErrors: AppComponents.AutoContentFormErrors = {
    street: '',
    unit: '',
    city: '',
    zip: '',
    state: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    phone_number: '',
    email: '',
    industry: '',
    occupation: '',
    education: '',
  };
  state: AppComponents.AutoContentState = {
    disableForm: false,
    addressInvalid: false,
    dataLoader: false,
    formErrors: { ...this.initFormErrors },
    address: {
      street: '',
      unit: '',
      city: '',
      zip: '',
      state: '',
    },
    personal_details: {
      first_name: '',
      middle_name: '',
      last_name: '',
      date_of_birth: '',
      phone_number: '',
      email: '',
      industry: '',
      occupation: '',
      education: '',
    },
    stateName: [...STATEDATA.states],
    disableOccupation: true,
    occupationData: [],
  };

  componentDidMount() {
    const { address, personal_details } = this.props;
    window.scrollTo(0, 0);
    this.props.updateAddressInvalidFlag(false);
    this.setState(
      {
        occupationData: this.getOccupationDetails(get(personal_details, 'industry', '')),
      },
      () => {
        const { occupationData } = this.state;
        this.setState({
          disableForm: false,
          personal_details: {
            first_name: get(personal_details, 'first_name', ''),
            middle_name: get(personal_details, 'middle_name', ''),
            last_name: get(personal_details, 'last_name', ''),
            date_of_birth: get(personal_details, 'date_of_birth', ''),
            phone_number: get(personal_details, 'phone_number', ''),
            email: get(personal_details, 'email', ''),
            industry: get(personal_details, 'industry', ''),
            occupation: getDefaultOccupation(
              get(personal_details, 'occupation', ''),
              occupationData
            ),
            education: get(personal_details, 'education', ''),
          },
          address: {
            street: get(address, 'street', ''),
            unit: get(address, 'unit', ''),
            city: get(address, 'city', ''),
            zip: get(address, 'zip', ''),
            state: get(address, 'state', ''),
          },
        });
      }
    );
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.AutoContentProps) {
    if (newProps.addressInvalid) {
      this.setState({ disableForm: false, addressInvalid: newProps.addressInvalid });
    } else {
      this.setState({ addressInvalid: newProps.addressInvalid });
    }
  }

  handlePersonalDetailsChange = (e: any) => {
    if (
      !isEmpty(e.target.value) &&
      !stringRegex.test(e.target.value) &&
      e.target.name !== 'date_of_birth' &&
      e.target.name !== 'phone_number' &&
      e.target.name !== 'email' &&
      e.target.name !== 'industry' &&
      e.target.name !== 'occupation' &&
      e.target.name !== 'education'
    ) {
      return;
    }
    const personalDetails: any = { ...this.state.personal_details };
    personalDetails[e.target.name] = e.target.value;
    this.setState(prevState => ({ ...prevState, personal_details: { ...personalDetails } }));
    if (e.target.name === 'industry') {
      this.setState({
        personal_details: { ...personalDetails, occupation: '' },
        occupationData: this.getOccupationDetails(e.target.value),
      });
    }
    e.preventDefault();
  };

  getOccupationDetails = (value: string) => {
    const formErrorsDetails = { ...this.state.formErrors };
    this.setState({
      disableOccupation: isEmpty(value) ? true : false,
      formErrors: { ...formErrorsDetails, occupation: '' },
    });
    return getOccupationList(INDUSTRY_DATA, value);
  };

  handlePersonalDetailsBlur = (e: any) => {
    const personalDetails: any = { ...this.state.personal_details };
    personalDetails[e.target.name] = getStartCase(e.target.value.toLowerCase());
    this.setState(prevState => ({ ...prevState, personal_details: { ...personalDetails } }));
    e.preventDefault();
  };

  personalInformationErrorCheck = (formErrors: any) => {
    const { first_name, last_name, phone_number, email } = this.state.personal_details;
    const validUserPhone = validatePhoneNumber(phone_number);
    const error = { ...formErrors };
    if (isEmpty(phone_number)) {
      error.phone_number = 'Required';
    }
    if (!isEmpty(phone_number) && (!phoneRegex.test(phone_number) || !validUserPhone)) {
      error.phone_number = 'Invalid';
    }
    error.email = validateEmail(email, true);
    if (first_name.length === 0) {
      error.first_name = 'Required';
    }
    if (last_name.length === 0) {
      error.last_name = 'Required';
    }
    return error;
  };

  addressDataErrorCheck = (formErrors: any) => {
    const error = { ...formErrors };
    forEach(this.state.address, (value, key) => {
      if ((!value || value.length === 0) && key !== 'unit') {
        error[`${key}`] = 'Required';
      }
    });
    return error;
  };

  validateInputs = () => {
    const { address } = this.state;
    let formErrors: AppComponents.AutoContentFormErrors = { ...this.initFormErrors };
    const { date_of_birth, industry, occupation, education } = this.state.personal_details;

    formErrors = {
      ...this.personalInformationErrorCheck(formErrors),
    };
    formErrors = {
      ...this.addressDataErrorCheck(formErrors),
    };
    formErrors.date_of_birth = validateDOB(date_of_birth);
    if (address.zip.length > 0 && address.zip.length < 4) {
      formErrors.zip = 'Invalid';
    }
    if (!industry) {
      formErrors.industry = 'Required';
    }
    if (!education) {
      formErrors.education = 'Required';
    }
    if (!this.state.disableOccupation) {
      if (!occupation) {
        formErrors.occupation = 'Required';
      }
    }
    if (
      formErrors.street ||
      formErrors.city ||
      formErrors.zip ||
      formErrors.state ||
      formErrors.first_name ||
      formErrors.last_name ||
      formErrors.date_of_birth ||
      formErrors.phone_number ||
      formErrors.email ||
      formErrors.industry ||
      formErrors.occupation ||
      formErrors.education
    ) {
      this.setState({ formErrors });
      return false;
    }
    return true;
  };

  handleSubmit = (e: any, getQuoteClicked: boolean) => {
    e.preventDefault();
    if (this.validateInputs()) {
      const { city, unit, street } = this.state.address;
      const { quoteCompleted, vehiclesList } = this.props;
      const isBodyTypeEmpty = some(vehiclesList, ['body_type', '']);
      let isVehicleInfoIncomplete = getQuoteClicked;
      if ((quoteCompleted && isBodyTypeEmpty) || (quoteCompleted && isEmpty(vehiclesList))) {
        isVehicleInfoIncomplete = false;
        this.props.setVehiclesMissing(true);
      }
      this.setState(
        {
          ...this.state,
          formErrors: { ...this.initFormErrors },
          disableForm: true,
          address: {
            ...this.state.address,
            city: !isEmpty(city) ? city.trim() : '',
            unit: !isEmpty(unit) ? unit.trim() : '',
            street: !isEmpty(street) ? street.trim() : '',
          },
          dataLoader: isVehicleInfoIncomplete,
        },
        () => {
          this.props.applicantFormSubmit(
            omit({ ...this.state, getQuoteClick: isVehicleInfoIncomplete }, [
              'disableForm',
              'formErrors',
              'stateName',
              'dataLoader',
              'disableOccupation',
              'occupationData',
            ])
          );
        }
      );
    }
  };

  handleAddressDetailsChange = (e: any) => {
    const updatedAddress: any = { ...this.state.address };
    if (
      (e.target.name === 'zip' &&
        (e.target.value.length > 5 || e.target.value.match(numberRegex) == null)) ||
      (e.target.name === 'city' && e.target.value.match(stringWithSpaceRegex) === null)
    ) {
      return false;
    }
    updatedAddress[e.target.name] = e.target.value;
    this.setState({ address: updatedAddress });
    e.preventDefault();
    return true;
  };

  onAutoCompleteAddressSelected = (address: any) => {
    this.setState(state => {
      return {
        address: {
          ...state.address,
          ...address,
          zip: !isEmpty(address.zip) ? address.zip : state.address.zip,
        },
      };
    });
  };

  clearAutoCompleteInput = () => {
    this.setState(state => {
      return { address: { ...state.address, street: '' } };
    });
  };

  handleAddressDetailsBlur = (e: any) => {
    const updatedAddress: any = { ...this.state.address };
    if (e.target.name === 'unit') {
      updatedAddress[e.target.name] = e.target.value.trim();
    } else {
      updatedAddress[e.target.name] = getStartCase(e.target.value.toLowerCase());
    }
    this.setState({ address: updatedAddress });
    e.preventDefault();
  };

  handleCalendarDate = (dateFromCalender: any) => {
    const personalDetails: any = { ...this.state.personal_details };
    personalDetails['date_of_birth'] = dateFromCalender;
    this.setState(prevState => ({ ...prevState, personal_details: { ...personalDetails } }));
  };

  getQuoteButton = () => {
    const { disableForm, dataLoader } = this.state;
    const { formLoader, quoteCompleted } = this.props;
    return (
      quoteCompleted && (
        <button
          className='btn mh-button btnGetQuoteLink'
          disabled={disableForm || formLoader}
          type='button'
          onClick={event => {
            this.handleSubmit(event, true);
          }}
        >
          {dataLoader && formLoader && (
            <div className='loader-wrapper-quote'>
              <div className='sk-circle'>
                {range(12, 0).map(n => {
                  return <div key={n} className={`sk-circle${n} sk-child`} />;
                })}
              </div>
            </div>
          )}
          {(!dataLoader || !formLoader) && <span className='link-label'>GET QUOTE</span>}
        </button>
      )
    );
  };

  showAddressError = (formErrors: any) => {
    return isEmpty(formErrors) ? (
      <div className={this.props.classes.errorMsg} />
    ) : (
      <div className={this.props.classes.errorMsg}>{formErrors}</div>
    );
  };

  getNameFields = () => {
    const {
      formErrors,
      personal_details: { first_name, middle_name, last_name },
    } = this.state;
    const { classes } = this.props;
    return (
      <div className='personal-details-wrapper'>
        <div className=' row'>
          <div className='first_name fs-exclude div-display col-md-4 m-16'>
            <TextField
              type='text'
              label='First Name*'
              autoComplete='off'
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: 'first_name',
                id: 'first_name',
                spellCheck: 'false',
                maxLength: 50,
              }}
              error={formErrors.first_name.length > 0}
              value={first_name}
              onChange={this.handlePersonalDetailsChange}
              onBlur={this.handlePersonalDetailsBlur}
            />
            <div className={classes.errorMsg}>{formErrors.first_name}</div>
          </div>
          <div className='middle_name fs-exclude div-display col-md-4 m-16'>
            <TextField
              type='text'
              label='Middle Name'
              autoComplete='off'
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: 'middle_name',
                id: 'middle_name',
                spellCheck: 'false',
                maxLength: 50,
              }}
              value={middle_name}
              onChange={this.handlePersonalDetailsChange}
              onBlur={this.handlePersonalDetailsBlur}
            />
          </div>
          <div className='last_name fs-exclude div-display col-md-4 m-16'>
            <TextField
              type='text'
              label='Last Name*'
              autoComplete='off'
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: 'last_name',
                id: 'last_name',
                spellCheck: 'false',
                maxLength: 50,
              }}
              error={formErrors.last_name.length > 0}
              name='last_name'
              value={last_name}
              onChange={this.handlePersonalDetailsChange}
              onBlur={this.handlePersonalDetailsBlur}
            />
            <div className={classes.errorMsg}>{formErrors.last_name}</div>
          </div>
        </div>
      </div>
    );
  };

  getAddressFields = () => {
    const {
      disableForm,
      formErrors,
      address: { street, city, zip, unit, state },
    } = this.state;
    const { formLoader, addressInvalid, classes } = this.props;
    return (
      <div className='address-container mb-2'>
        <div className='row'>
          <div className='street fs-exclude col-md-8 m-16'>
            <AutoComplete
              value={street}
              onChange={this.handleAddressDetailsChange}
              onAutoCompleteAddressSelected={this.onAutoCompleteAddressSelected}
              onInputBlur={this.handleAddressDetailsBlur}
              clearAutoCompleteInput={this.clearAutoCompleteInput}
              error={formErrors.street.length > 0 || addressInvalid}
              title={
                <>
                  STREET ADDRESS<span className='star-error'>*</span>
                </>
              }
            />
            {this.showAddressError(formErrors.street)}
          </div>
          <div className='unit fs-exclude col-md-4 m-16'>
            <TextField
              type='text'
              label='Unit #'
              autoComplete='off'
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: 'unit',
                id: 'unit',
                spellCheck: 'false',
                maxLength: 50,
              }}
              error={formErrors.unit.length > 0}
              value={unit}
              onChange={this.handleAddressDetailsChange}
              onBlur={this.handleAddressDetailsBlur}
            />
            <div className={classes.errorMsg}>{formErrors.unit}</div>
          </div>
        </div>
        <div className='row'>
          <div className='city fs-exclude col-md-4 m-16'>
            <TextField
              type='text'
              label='City*'
              autoComplete='off'
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                id: 'city',
                name: 'city',
                spellCheck: 'false',
                maxLength: 50,
              }}
              error={formErrors.city.length > 0 || addressInvalid}
              value={city}
              onChange={this.handleAddressDetailsChange}
              onBlur={this.handleAddressDetailsBlur}
            />
            {this.showAddressError(formErrors.city)}
          </div>
          <div className='state fs-exclude col-md-4 m-16'>
            <FormControl
              className={`${classes.formControl} classmargin`}
              error={formErrors.state.length > 0 || addressInvalid}
            >
              <InputLabel shrink htmlFor='state-simple'>
                State<span className='star-error'>*</span>
              </InputLabel>
              <NativeSelect
                className='state-class'
                value={state}
                onChange={this.handleAddressDetailsChange}
                disabled={disableForm || formLoader}
                inputProps={{
                  name: 'state',
                  id: 'state-simple',
                }}
              >
                <option value=''>Select</option>
                {this.state.stateName.map(stateName => (
                  <option key={stateName.label} value={stateName.value}>
                    {stateName.value}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            {this.showAddressError(formErrors.state)}
          </div>
          <div className='zip fs-exclude col-md-4 m-16'>
            <TextField
              type='text'
              label='Zip Code*'
              autoComplete='off'
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                id: 'zip',
                name: 'zip',
              }}
              error={formErrors.zip.length > 0 || addressInvalid}
              value={zip}
              onChange={this.handleAddressDetailsChange}
            />
            {this.showAddressError(formErrors.zip)}
          </div>
        </div>
      </div>
    );
  };

  getOtherFields = () => {
    const {
      formErrors,
      disableOccupation,
      occupationData,
      personal_details: { date_of_birth, industry, occupation, education },
    } = this.state;
    const { classes } = this.props;
    return (
      <div className='personal-details-wrapper  '>
        <div className=' row'>
          <div className='zip fs-exclude col-md-4 m-16'>
            <FormControl
              fullWidth
              className={`${classes.formControl} w-90-ie`}
              error={formErrors.industry.length > 0}
            >
              <InputLabel shrink htmlFor='industry'>
                Industry<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={industry}
                inputProps={{
                  name: 'industry',
                  id: 'industry',
                }}
                onChange={this.handlePersonalDetailsChange}
                error={formErrors.industry === '' ? false : true}
              >
                <option value=''>Select</option>
                {map(INDUSTRY_DATA, data => (
                  <option key={`industry_${data.value}`} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <div className={classes.errorMsg}>{formErrors.industry}</div>
          </div>
          <div className='zip col-md-4 m-16'>
            <FormControl
              fullWidth
              className={classes.formControl}
              error={formErrors.occupation.length > 0}
              disabled={disableOccupation}
            >
              <InputLabel shrink htmlFor='occupation'>
                Occupation<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={occupation}
                inputProps={{
                  name: 'occupation',
                  id: 'occupation',
                }}
                onChange={this.handlePersonalDetailsChange}
                error={formErrors.occupation === '' ? false : true}
              >
                <option value=''>Select</option>
                {map(occupationData, data => (
                  <option key={`occupation_${data.value}`} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <div className={classes.errorMsg}>{formErrors.occupation}</div>
          </div>
          <div className='zip col-md-4 m-16'>
            <FormControl
              fullWidth
              className={`${classes.formControl} w-90-ie`}
              error={formErrors.education.length > 0}
            >
              <InputLabel shrink htmlFor='education'>
                Education<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={education}
                inputProps={{
                  name: 'education',
                  id: 'education',
                }}
                onChange={this.handlePersonalDetailsChange}
                error={formErrors.education === '' ? false : true}
              >
                <option value=''>Select</option>
                {map(EDUCATION_DATA, data => (
                  <option key={`education_${data.value}`} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <div className={classes.errorMsg}>{formErrors.education}</div>
          </div>
        </div>
        <div className='row'>
          <div className='fs-exclude date_of_birth col-md-12 m-16'>
            <FormControl className={classes.formControl}>
              <DatePicker
                styles={{
                  marginBottom: '0px !important',
                  marginTop: '0px !important',
                }}
                label='Date of Birth'
                name='date_of_birth'
                onChange={this.handleCalendarDate}
                dateValue={date_of_birth}
                error={formErrors.date_of_birth.length > 0 ? true : false}
              />
            </FormControl>
            <div className={classes.errorMsg}>{formErrors.date_of_birth}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      formErrors,
      personal_details: { phone_number, email },
      disableForm,
      dataLoader,
    } = this.state;
    const { formLoader, classes } = this.props;
    return (
      <div className='applicant-form'>
        <div className='applicant-form-title'>
          <h2 className='title-2'>{AUTO_APPLICANT_FORM_TITLE}</h2>
          <p className='sub-title col-lg-12 col-md-12'>{AUTO_APPLICANT_FORM_SUBTITLE}</p>
        </div>
        <div className='form-wrapper'>
          <form
            onSubmit={event => {
              this.handleSubmit(event, false);
            }}
            className={'col-lg-10 offset-lg-3 mx-auto'}
          >
            <fieldset disabled={disableForm || formLoader}>
              {this.getNameFields()}

              {this.getAddressFields()}

              {this.getOtherFields()}

              <div className='personal-details-wrapper '>
                <div className='row'>
                  <div className={`fs-exclude phone_number div-display col-md-6 m-16`}>
                    <FormControl
                      className={`w-100 ${classes.formControl}`}
                      error={formErrors.phone_number.length > 0}
                    >
                      <InputLabel shrink htmlFor='phone_number'>
                        Phone Number<span className='star-error'>*</span>
                      </InputLabel>
                      <Input
                        autoComplete='off'
                        value={phone_number === '' ? '' : phone_number}
                        onChange={this.handlePersonalDetailsChange}
                        name='phone_number'
                        id='phone_number'
                        inputComponent={PhoneMask}
                      />
                    </FormControl>
                    <div className={classes.errorMsg}>{formErrors.phone_number}</div>
                  </div>
                  <div className={`fs-exclude email div-display col-md-6 m-16`}>
                    <FormControl className={`w-100 ${classes.formControl}`}>
                      <TextField
                        error={formErrors.email.length > 0}
                        type='text'
                        label='Email Address*'
                        autoComplete='off'
                        className={`${classes.textField} text-margin-class`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          name: 'email',
                          id: 'email',
                          spellCheck: 'false',
                          maxLength: 50,
                        }}
                        value={email}
                        onChange={this.handlePersonalDetailsChange}
                      />
                    </FormControl>
                    <div className={classes.errorMsg}>{formErrors.email}</div>
                  </div>
                </div>
              </div>
              <div className='form-submit-action'>
                <button className='btn btn-success mh-button' type='submit'>
                  {formLoader && !dataLoader ? (
                    <div className='loader-wrapper'>
                      <div className='sk-circle'>
                        {range(12, 0).map(n => {
                          return <div key={n} className={`sk-circle${n} sk-child`} />;
                        })}
                      </div>
                    </div>
                  ) : (
                    <span className='submit-label'>Submit</span>
                  )}
                </button>

                {this.getQuoteButton()}
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: AppComponents.AutoContentStore
): AppComponents.AutoContentStoreProps => {
  const { formLoader, addressInvalid, personal_details, address } = state.applicant;
  const { quoteCompleted } = state.quote;
  const { vehiclesList } = state.vehicle;
  return { formLoader, addressInvalid, personal_details, address, quoteCompleted, vehiclesList };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.AutoContentDispatch => {
  return bindActionCreators(
    { applicantFormSubmit, updateAddressInvalidFlag, setVehiclesMissing },
    dispatchProp
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(AutoApplicantForm));
