import React from 'react';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { formatUSPhoneNumber } from '../../../utils';
import history from '../../../history';
import { INSURANCE_PRODUCTS, PAGES_TEXT } from '../../../constants';
import { setLineOfBusiness, setRedirectedFrom } from '../../redux/actions';
import config from '../../../config/config';
import ThemesImages from '../themes-images';
import './property-error.scss';

// const CarButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? AutoIconFc : AutoIcon;

class PropertyError extends React.Component<
  AppComponents.PropertyErrorProps,
  AppComponents.PropertyErrorState
> {
  state: AppComponents.PropertyErrorState = {
    phone: '',
    email: '',
  };

  componentDidMount() {
    this.setState({
      phone: this.getContactDetails().phone,
      email: this.getContactDetails().email,
    } as any);
    this.resize = throttle(this.resize.bind(this), 100);
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    this.setState({ phone: this.getContactDetails().phone } as any);
  };

  getPhoneNumberHyperLink = (contactDetails: any) => {
    if (window.innerWidth <= 768) {
      return (
        <a href={`tel:${formatUSPhoneNumber(contactDetails.phone)}`}>
          {formatUSPhoneNumber(contactDetails.phone)}
        </a>
      );
    } else {
      return <span>{formatUSPhoneNumber(contactDetails.phone)}</span>;
    }
  };

  getEmailHyperLink = (contactDetails: any, partnerName: string) => {
    return (
      <a href={`mailto:${contactDetails.email}?subject=${partnerName}: Request for Quote`}>
        email{' '}
      </a>
    );
  };

  // Get error details based on enable customized error page flag from partner configuration
  getErrorDetails = () => {
    const { pconfig, defaultPConfig } = this.props.pstate;
    let customizedErrorPageDetails = null;
    if (!isEmpty(pconfig) && pconfig.dale_config.enable_customized_error_page) {
      customizedErrorPageDetails = pconfig.dale_config.customized_error_page_details;
    } else {
      customizedErrorPageDetails = defaultPConfig.dale_config.customized_error_page_details;
    }
    if (!isEmpty(pconfig) && !isEmpty(pconfig.dale_config.customized_error_page_details)) {
      return {
        error_logo_url: !isEmpty(customizedErrorPageDetails.error_logo_url)
          ? customizedErrorPageDetails.error_logo_url
          : defaultPConfig.dale_config.customized_error_page_details.error_logo_url,
        error_text: !isEmpty(customizedErrorPageDetails.error_text)
          ? customizedErrorPageDetails.error_text
          : defaultPConfig.dale_config.customized_error_page_details.error_text,
      };
    } else {
      return defaultPConfig.dale_config.customized_error_page_details;
    }
  };

  // Get contact email/phone details for error page
  getContactDetails = () => {
    const { pconfig, defaultPConfig } = this.props.pstate;
    let contactDetails = null;
    if (!isEmpty(pconfig) && pconfig.dale_config.enable_customized_error_page) {
      contactDetails = !isEmpty(pconfig.dale_config.contact)
        ? pconfig.dale_config.contact
        : defaultPConfig.dale_config.contact;
    } else {
      contactDetails = defaultPConfig.dale_config.contact;
    }
    if (isEmpty(contactDetails.email)) {
      contactDetails.email = defaultPConfig.dale_config.contact.email;
    }
    if (isEmpty(contactDetails.phone)) {
      contactDetails.phone = defaultPConfig.dale_config.contact.phone;
    }
    return {
      phone: this.getPhoneNumberHyperLink(contactDetails),
      email: this.getEmailHyperLink(contactDetails, this.getPartnerName()),
    };
  };

  getPartnerName = (): string => {
    const { pconfig, defaultPConfig } = this.props.pstate;
    return pconfig && !isEmpty(pconfig.name) ? pconfig.name : defaultPConfig.name;
  };

  isAutoLine = (): boolean => history.location.pathname.includes('/auto');

  otherQuoteSelected = (product: any) => {
    const { lineOfBusiness } = this.props;
    history.push({
      pathname: '/home',
      search: `?code=${get(this.props, 'pstate.pconfig.code')}`,
      state: {
        isRedirectedFromAuto: false,
      },
    });
    this.props.setRedirectedFrom(lineOfBusiness);
    this.props.setLineOfBusiness(product.value);
    window.scrollTo(0, 0);
  };

  getErrorHomeButton = () => {
    const { lineOfBusiness, redirectedFrom } = this.props;
    if (
      lineOfBusiness !== INSURANCE_PRODUCTS[0] &&
      !redirectedFrom.includes(INSURANCE_PRODUCTS[0])
    ) {
      return (
        <Button
          variant='outlined'
          className='btnHomeQuotes ml-1'
          onClick={() => {
            history.push({
              pathname: '/home',
              search: `?code=${get(this.props, 'pstate.pconfig.code')}`,
              state: {
                isRedirectedFromAuto: false,
              },
            });
            this.props.setRedirectedFrom(lineOfBusiness);
            this.props.setLineOfBusiness(INSURANCE_PRODUCTS[0]);
            window.scrollTo(0, 0);
          }}
        >
          <img
            alt='home quote'
            className='img-fluid'
            src={ThemesImages[config.hippo.template_name].homeIcon}
          />
          <span style={{ verticalAlign: 'middle' }}>Home Quote</span>
        </Button>
      );
    } else {
      return '';
    }
  };

  render() {
    const { error_logo_url, error_text } = this.getErrorDetails();
    const { phone, email } = this.state;
    const errorDetails = this.isAutoLine() ? error_text.replace(/homeowners/g, 'auto') : error_text;
    return (
      <div className='error-page-wrapper'>
        {!this.isAutoLine() && (
          <div className='container-fluid error-div'>
            <div className='row'>
              <div className='col-md-12'>
                <h5>{PAGES_TEXT.propertyError}</h5>
              </div>
            </div>
          </div>
        )}

        <section className='sec-insurance-list'>
          <div className='container-fluid '>
            <div className='content-box'>
              <div className='row'>
                <div className='col-md-2 col-img'>
                  <img
                    alt={this.getPartnerName()}
                    src={error_logo_url}
                    className='img-fluid error-img'
                  />
                </div>
                <div className='col-md-7 d-flex justify-content-center align-items-center'>
                  <p className='col-p '>{errorDetails}</p>
                </div>
                <div className='col-md-3'>
                  <h4 x-ms-format-detection='none' className='p-call'>
                    Call {phone}
                    <span>&nbsp;or {email}</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          {/* 
          Commenting this out in case we need it later
          <div className='container-fluid'>
            {!isEmpty(authUser) && (
              <div className='row'>
                <div className='col-12'>
                  <div className='btnWrap d-flex justify-content-left'>
                    {this.getErrorHomeButton()}

                    <OtherQuotesButton onSelect={this.otherQuoteSelected} />

                    {!this.isAutoLine() && !get(this.props, 'isAutoRedirect', false) && (
                      <Button
                        variant='outlined'
                        className='btnContinue'
                        color='primary'
                        onClick={() => {
                          history.push({
                            pathname: '/auto',
                            state: {
                              pathname: '/auto',
                              fromHomeProduct: true,
                            },
                          });
                          this.props.setRedirectedFrom(this.props.lineOfBusiness);
                          this.props.setLineOfBusiness(INSURANCE_PRODUCTS[1]);
                        }}
                      >
                        <img src={CarButtonIcon} alt='car' className='img-fluid iconCar'></img>
                        Auto Quote
                      </Button>
                    )}

                    {!this.isAutoLine() && (
                      <Button
                        variant='outlined'
                        className='btnHomeQuotes ml-1'
                        onClick={() => {
                          history.push({
                            pathname: DEFAULT_LOB_PATH,
                          });
                          window.scrollTo(0, 0);
                        }}
                      >
                        <DashboardOutlinedIcon className='dashboard-icon' />
                        <span style={{ verticalAlign: 'middle' }}>DASHBOARD</span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div> 
          */}
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({
  common,
}: AppComponents.PropertyErrorStore): AppComponents.PropertyErrorStoreProps => {
  const { lineOfBusiness, redirectedFrom } = common;
  return { lineOfBusiness, redirectedFrom };
};

const mapDispatchToProps = (dispatch: any): AppComponents.PropertyErrorDispatch => {
  return bindActionCreators({ setRedirectedFrom, setLineOfBusiness }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyError);
