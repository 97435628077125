/* istanbul ignore file */
import * as QuakeActionType from '../constants';

export const quakeDetailSubmitLoader = (payload: boolean): AppRedux.Action => ({
  type: QuakeActionType.QUAKE_SUBMIT_LOADER,
  payload,
});

export const setQuakeQuotes = (payload: any): AppRedux.Action => ({
  type: QuakeActionType.SET_QUAKE_QUOTES,
  payload,
});

export const fetchQuakeQuotes = (): AppRedux.Action => ({
  type: QuakeActionType.FETCH_QUAKE_QUOTES,
});

export const clearQuakeState = (): AppRedux.Action => ({
  type: QuakeActionType.CLEAR_QUAKE_STATE,
});

export const setQuakeQuoteBind = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: QuakeActionType.SET_QUAKE_QUOTE_BIND,
  payload,
});

export const setQuakeHerdId = (payload: any): AppRedux.Action => ({
  type: QuakeActionType.SET_QUAKE_HERD_ID,
  payload,
});

export const fetchQuakeQuoteDetails = (): AppRedux.Action => ({
  type: QuakeActionType.FETCH_QUAKE_QUOTE_DETAILS,
});

export const storeQuakeDetails = (payload: any): AppRedux.Action => ({
  type: QuakeActionType.STORE_QUAKE_DETAILS,
  payload,
});
