/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import xorWith from 'lodash/xorWith';
import * as XLSX from 'xlsx';
import * as actions from '../constants';
import {
  bulkQuoteInit,
  errorHandler,
  flushAppDetails,
  restoreBulkQuoteDetails,
  setActionLoader,
  setBulkQuoteList,
  setBulkQuoteTableLoader,
  setOrganizationDetails,
  setPageLoader,
  setSelectedCarrierList,
  setSelectorLoader,
  setServerError,
  setServerMessage,
  setStepsCompleted,
  setTerminatedRecord,
  setTerminateMessage,
  setTerminateSuccess,
  showTerminatedMessage,
  updateStep,
} from '../actions';
import axiosRequest from '../../api';
import {
  BATCH_TERMINATED_ERROR_RESPONSE,
  BATCH_TERMINATED_SUCCESS_RESPONSE,
  BULK_QUOTE_ERRORS,
  DEFAULT_SELECTED_CARRIER_IDS,
  EXCEL_FILE_COLUMNS,
  INSURANCE_PRODUCTS,
} from '../../../constants';
import { downloadFile } from '../../../utils';

const bulkQuoteActions: any = actions;

const getFileErrors = (workSheet: any) => {
  const headers: any = [];
  let errorDetails = '';
  if (workSheet['!ref']) {
    const range = XLSX.utils.decode_range(workSheet['!ref']);
    const R = range.s.r;
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell = workSheet[XLSX.utils.encode_cell({ c: C, r: R })];
      let hdr = `UNKNOWN ${C}`;
      if (cell && cell.t) {
        hdr = XLSX.utils.format_cell(cell);
      }
      headers.push(hdr);
    }

    const arrayToLowerCase = (array: Array<string>) =>
      map(array, (title) => title.toLowerCase().trim());

    const missingColumns = differenceBy(
      arrayToLowerCase(EXCEL_FILE_COLUMNS),
      arrayToLowerCase(headers)
    );

    if (!isEmpty(missingColumns)) {
      errorDetails = BULK_QUOTE_ERRORS.missingColumnsError;
    }
  } else {
    errorDetails = BULK_QUOTE_ERRORS.emptySheetError;
  }
  return errorDetails;
};

const validateFileDetails = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const readAsBinaryString = !!reader.readAsBinaryString;
    reader.onload = (e: any) => {
      const binaryString = e.target.result;
      const workBook = XLSX.read(binaryString, {
        type: readAsBinaryString ? 'binary' : 'array',
        bookVBA: true,
      });
      const workSheetName: string = workBook.SheetNames[0];
      resolve(getFileErrors(workBook.Sheets[workSheetName]));
    };
    reader.onerror = reject;
    if (readAsBinaryString) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });

function* fetchOrganizationConfiguration(code: string): any {
  const { partner: { isProviderOneLogin } } = yield select();
  return yield axiosRequest('POST', '/partners/config', isProviderOneLogin,{
    code,
    lob: INSURANCE_PRODUCTS[0],
  });
}

function* clearServerError() {
  const {
    bulkQuote: { serverError },
  } = yield select();
  if (serverError.length > 0) {
    yield put(setServerError(''));
  }
}

const getOrganizationUpdates = (organization: any, data: any) =>
  has(organization, 'inputCode')
    ? {
        selectedOrganization: null,
        inputCode: organization.inputCode,
        inputOrganization: {
          ...data,
        },
      }
    : {
        selectedOrganization: { ...organization },
        inputCode: '',
        inputOrganization: null,
      };

const getDefaultSelectedCarriers = (list: Array<any>) => {
  if (list.length > 0) {
    return filter(list, (carrier) => {
      const carrierId = parseFloat(get(carrier, 'id', ''));
      return DEFAULT_SELECTED_CARRIER_IDS.includes(carrierId);
    });
  }
  return [];
};

function* getOrganizationDetails(organization: any): any {
  const {
    bulkQuote: { activeStep, selectedOrganization, inputCode },
  } = yield select();
  try {
    const selectedOrganizationCode = !isEmpty(get(organization, 'inputCode', ''))
      ? organization.inputCode
      : get(organization, 'code', '');
    if (
      get(organization, 'inputCode', '') === get(selectedOrganization, 'code', '') ||
      (inputCode !== selectedOrganizationCode &&
        selectedOrganizationCode !== get(selectedOrganization, 'code', ''))
    ) {
      const response = yield call(fetchOrganizationConfiguration, selectedOrganizationCode);
      if (response.data) {
        const { data } = response;
        const carrierList = get(data, 'dale_config.carrier_credentials', []);
        yield put(
          setOrganizationDetails({
            ...getOrganizationUpdates(
              { ...organization, name: get(data, 'name', organization.name) },
              data
            ),
            carrierList,
          })
        );
        yield put(setSelectedCarrierList([...getDefaultSelectedCarriers(carrierList)]));
      }
    }
    yield put(updateStep(activeStep + 1));
  } catch (error) {
    yield put(
      setServerError(
        get(error, 'response.data.ERROR', BULK_QUOTE_ERRORS.organizationCodeServerError)
      )
    );
    yield put(errorHandler(error));
  }
}

function* setSelectedCarriers(carrierList: Array<any>) {
  const {
    bulkQuote: { activeStep, selectedCarrierList },
  } = yield select();
  const skip: boolean = isEmpty(xorWith(selectedCarrierList, carrierList, isEqual));
  if (!skip) {
    yield put(setSelectedCarrierList(carrierList));
  }
  yield put(updateStep(activeStep + 1));
}

function* uploadFileDetails(): any {
  const {
    auth: { email, userId },
    bulkQuote: {
      selectedFile,
      selectedOrganization,
      inputCode,
      inputOrganization,
      selectedCarrierList,
    },
  } = yield select();
  try {
    const file = selectedFile[0];
    const fileError = yield call(validateFileDetails, file);
    yield delay(50);
    if (!isEmpty(fileError)) {
      throw Object.assign(new Error(fileError), { name: 'fileError' });
    }
    const { partner: { isProviderOneLogin } } = yield select();
    let formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("bulkQuoteFile", file);
    const details = yield axiosRequest('POST', '/bulk/upload-bulk-quote-file', isProviderOneLogin, formData);
    if (has(details, 'data') && !isEmpty(details?.data?.filePath)) {
        const { displayName, fileName, filePath } = details?.data;
        yield axiosRequest('POST', '/bulk/create', isProviderOneLogin, {
          id: userId,
          carriers: [...map(selectedCarrierList, 'id')],
          organizationCode: get(selectedOrganization, 'code', inputCode),
          organizationName: get(selectedOrganization, 'name', get(inputOrganization, 'name', '')),
          lob: INSURANCE_PRODUCTS[0],
          quoteBy: email,
          fileDetails: {
            displayName,
            fileName,
            filePath,
          },
        });
        yield put(setStepsCompleted(true));
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (has(axiosError, 'message')) {
      yield put(setServerError(get(axiosError, 'response.data.response', axiosError.message)));
    } else {
      yield put(setServerError(BULK_QUOTE_ERRORS.fileUploadServerError));
    }
    yield put(errorHandler(axiosError));
  }
}

function* bulkQuoteStepSubmit({ payload }: AppRedux.ActionPayload) {
  yield put(setSelectorLoader(true));
  try {
    const { entity, data } = payload;
    if (entity === 'organizationSelector') {
      yield call(getOrganizationDetails, data);
    } else if (entity === 'carrierSelector') {
      yield call(setSelectedCarriers, data.selectedCarrierList);
    } else if (entity === 'fileUploader') {
      yield call(uploadFileDetails);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield call(clearServerError);
  yield put(setSelectorLoader(false));
}

export function* bulkQuoteStepSubmitWatcher() {
  yield takeEvery(bulkQuoteActions.SUBMIT_BULK_QUOTE_STEP, bulkQuoteStepSubmit);
}

function* bulkQuoteInitialization(): any {
  yield put(setBulkQuoteTableLoader(true));
  yield put(showTerminatedMessage(false));
  try {
    const { bulkQuote, common, home } = yield select();
    if (!isEmpty(common.pconfig) || !isEmpty(home.pconfig)) {
      yield put(flushAppDetails());
      yield put(restoreBulkQuoteDetails(bulkQuote));
    }

    const { partner: { isProviderOneLogin } } = yield select();
    const details = yield axiosRequest('POST', '/bulk/dashboard', isProviderOneLogin,{});
    if (details.data) {
      yield put(
        setBulkQuoteList({
          quotes: [...details.data],
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setBulkQuoteTableLoader(false));
}

export function* bulkQuoteInitWatcher() {
  yield takeEvery(bulkQuoteActions.BULK_QUOTE_INIT, bulkQuoteInitialization);
}

function* terminateRecord({ payload }: AppRedux.ActionPayload): any {
  try {
    yield put(setActionLoader(true));
    const { auth, partner: { isProviderOneLogin } } = yield select();
    const details = yield axiosRequest('POST', '/bulk/terminate', isProviderOneLogin,{
      bulkQuoteId: payload,
      userId: auth.userId,
      userEmail: auth.email,
    });
    if (details.data) {
      yield put(setTerminatedRecord({ batchNo: payload }));
      yield put(showTerminatedMessage(true));
      yield put(setTerminateSuccess(true));
      if (!isEmpty(get(details.data, 'message', ''))) {
        yield put(setTerminateMessage(details.data.message));
      } else {
        yield put(setTerminateMessage(BATCH_TERMINATED_SUCCESS_RESPONSE));
      }
      yield put(setActionLoader(false));
      yield put(bulkQuoteInit());
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    yield put(showTerminatedMessage(true));
    yield put(setTerminateSuccess(false));
    if (has(axiosError, 'message')) {
      yield put(setTerminateMessage(get(axiosError, 'response.data[0]', axiosError.message)));
    } else {
      yield put(setTerminateMessage(BATCH_TERMINATED_ERROR_RESPONSE));
    }
    yield put(errorHandler(axiosError));
  }
  yield put(setActionLoader(false));
}

export function* terminateRecordWatcher() {
  yield takeEvery(bulkQuoteActions.TERMINATE_RECORD, terminateRecord);
}

function* downloadBatchFile({ payload }: AppRedux.ActionPayload): any {
  yield put(setPageLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details = yield axiosRequest('POST', '/bulk/download', isProviderOneLogin, {
      ...payload,
    });
    if (has(details, 'data.response')) {
      downloadFile(details?.data?.response);
    }
  } catch (error) {
    const axiosError = error as any;
    const { data } = axiosError?.response;
    const errorMessage = !isEmpty(data?.response?.message) ? data?.response?.message : '';
    if (!isEmpty(errorMessage)) {
      yield put(showTerminatedMessage(true));
      yield put(setTerminateSuccess(false));
      yield put(setTerminateMessage(errorMessage));
    }
    yield put(errorHandler(axiosError));
  }
  yield put(setPageLoader(false));
}

export function* downloadFileWatcher() {
  yield takeEvery(bulkQuoteActions.DOWNLOAD_FILE, downloadBatchFile);
}

function* processRecord({ payload }: AppRedux.ActionPayload): any {
  try {
    yield put(setActionLoader(true));
    const { partner: { isProviderOneLogin } } = yield select();
    const details = yield axiosRequest('POST', '/bulk/process-bulk-quote', isProviderOneLogin, {
      batchId: payload,
    });

    if (details.data) {
      yield put(setActionLoader(false));
      yield put(bulkQuoteInit());
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setActionLoader(false));
}

export function* processRecordWatcher() {
  yield takeEvery(bulkQuoteActions.PROCESS_RECORD, processRecord);
}

function* updatePartnerConfig({ payload }: AppRedux.ActionPayload): any {
  yield put(setSelectorLoader(true));
  try {
    const { data, type } = payload;
    const selectedOrganizationCode = !isEmpty(get(data, 'inputCode', ''))
      ? data.inputCode
      : get(data, 'code', '');

    const { partner: { isProviderOneLogin } } = yield select();
    const response = yield axiosRequest('POST', '/partners/update-partner-config', isProviderOneLogin,{
      code: selectedOrganizationCode,
      cacheMethod: type,
    });

    if (response.data) {
      yield call(clearServerError);
      yield put(
        setServerMessage(get(response, 'data.message', 'Organization cache rebuild successfully'))
      );
    }
  } catch (error) {
    yield put(
      setServerError(
        get(error, 'response.data.message', BULK_QUOTE_ERRORS.organizationCodeServerError)
      )
    );
    yield put(errorHandler(error));
  }
  yield call(clearServerError);
  yield put(setSelectorLoader(false));
}

export function* updatePartnerConfigWatcher() {
  yield takeEvery(bulkQuoteActions.UPDATE_PARTNER_CONFIG, updatePartnerConfig);
}

export default function* rootSaga() {
  yield all([
    fork(bulkQuoteStepSubmitWatcher),
    fork(bulkQuoteInitWatcher),
    fork(terminateRecordWatcher),
    fork(downloadFileWatcher),
    fork(processRecordWatcher),
    fork(updatePartnerConfigWatcher),
  ]);
}
