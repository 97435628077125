import { invert } from 'lodash';
import { Industry } from '../../../../../graphql/generated';

export const gatorToBffIndustry: Record<string, Industry> = {
  'Homemaker/Houseprsn': Industry.Homemaker,
  Retired: Industry.Retired,
  Disabled: Industry.Disabled,
  Unemployed: Industry.Unemployed,
  Student: Industry.Student,
  'Agriclt/Forestry/Fish': Industry.Agriculture,
  'Art/Design/Media': Industry.Art,
  'Banking/Finance/RE': Industry.Banking,
  'Business/Sales/Offi': Industry.Business,
  'Construct/EnrgyTrds': Industry.Construction,
  'Education/Library': Industry.Education,
  'Engr/Archt/Sci/Math': Industry.Stem, // NTC
  'Government/Military': Industry.Government,
  'Info Tech': Industry.InfoTech,
  Insurance: Industry.Insurance,
  'Lgl/Law Enfcmt/Sec': Industry.Legal,
  'Maint/Rpr/Hsekeep': Industry.Maintenance,
  'Mfg/Production': Industry.Manufacturing,
  'Med/Soc Svcs/Relig': Industry.MedicalService,
  'Person.Care/Service': Industry.PersonalCare,
  'Rest/Hotel Services': Industry.HotelServices,
  'Sports/Recreation': Industry.Sports,
  'Trvl/Trnsprt/Warehs': Industry.Travel,
  Other: Industry.Other,
};

export const bffIndustryToGator = invert(gatorToBffIndustry);
