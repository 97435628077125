import { Theme, WithStyles, withStyles } from '@material-ui/core';
import { filter, find, findIndex, forEach, get, isEmpty, isEqual, map, omit, values } from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../../config/config';
import { DRIVER, DRIVER_DATA, NO, QUOTING_STEPS_PAGES_NAME, STATE_AGE_LICENSED, YES } from '../../../constants';
import { INDUSTRY_DATA } from '../../../constants/industry';
import {
  allowEditingQuote,
  deleteDiffKeys,
  getDateDifference,
  getDefaultOccupation,
  getIsHeapAnalyticsEnabled,
  getOccupationList,
  getStartCase,
  stringRegex,
} from '../../../utils';
import {
  addDriver,
  addDriverFromOtherDetails,
  driverBack,
  driverSelectedForEdit,
  initAccidents,
  initLosses,
  initViolations,
  saveAutoQuoteDetails,
  setFetchAutoQuotes,
  setHasFormChanged,
  setMissingDetailsDrivers,
  setMissingSpouse,
  setOtherDetails,
  setPolicyFlag,
  setSelectFirstDriver,
  setShowVeriskDriverModal,
  setValidDriverFlag,
  setSwitchDetailFromDriver,
  sortDrivers,
  stepSubmit,
  updateDriverList,
  updateSpecificDriver,
} from '../../redux/actions';
import './driver.scss';
import template from './template';

const styles = (theme: Theme) => ({
  icon: {
    fontSize: '35px',
  },
  formControl: {
    margin: '10px 0px',
    minWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  continueSpinner: {
    color: 'white !important',
    marginBottom: '2px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  errorMsg: {
    color: '#FF0000',
    height: '0px',
  },
  radioErrorMsg: {
    position: 'relative',
    top: '-19px',
  },
  paper: {
    boxShadow: '0px 4px 6px #0000004D',
    border: '1px solid #C4C4C4',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  warningPaper: {
    width: '100%',
    margin: '18px',
    marginBottom: '0px',
    border: '1px solid #C4C4C4',
    backgroundColor: 'rgb(255, 244, 229)',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  warningIcon: {
    color: '#f44336',
  },
  warningText: {
    fontWeight: 500,
    color: 'rgb(97, 26, 21)',
    verticalAlign: 'bottom',
    marginLeft: 10,
  },
});

class Driver extends React.PureComponent<
  AppComponents.DriverProps & WithStyles,
  AppComponents.DriverState
> {
  initDriverErrors: AppComponents.DriverErrors = {
    first_name_error: '',
    last_name_error: '',
    gender_error: '',
    marital_status_error: '',
    us_license_status_error: '',
    age_licensed_error: '',
    claims_error: '',
    violations_error: '',
    losses_error: '',
    relation_to_primary_driver_error: '',
    date_of_birth_error: '',
    industry: '',
    occupation: '',
    education: '',
    driver_licence_error: '',
  };
  state: AppComponents.DriverState = {
    entity: 'driver',
    duid: '',
    licenseDialog: false,
    hasSpouse: false, // Indicates main driver
    isSpouseAdded: false, // Indicates spouse is already added to driver list or not
    isSpouse: false, // Indicates spouse
    isPrimaryDriver: false,
    missingSpouseDialog: false, // Missing spouse dialog
    missingOtherDetailsDialog: false, // Missing driver other details list dialog
    first_name: '',
    last_name: '',
    gender: '',
    marital_status: '',
    relationship_status: '',
    driver_licence: '',
    us_license_status: '',
    age_licensed: '',
    claims: '',
    violations: '',
    losses: '',
    accidentList: [],
    violationList: [],
    lossList: [],
    detailsErrors: { ...this.initDriverErrors },
    relation_to_primary_driver: '',
    date_of_birth: '',
    industry: '',
    occupation: '',
    education: '',
    disableOccupation: true,
    showIncident: false,
    // shouldChangeTab: false,
    continueButtonClick: false,
    getQuoteButtonClick: false,
    saveDialog: false,
    selectedDuid: '',
    spouseId: '',
    missingInformation: false,
    driverLimitReached: false,
    tabSwitchConfirmationModal: false,
  };
  notLicensedProperty = 'not licensed';

  stateAgeDataMapper = (list: Array<any>) =>
    map(list, value => {
      const ageValue = value.split('||');
      const ageValueLength = ageValue.length;
      return {
        label: ageValueLength >= 2 ? ageValue[1].trim() : value.trim(),
        value: ageValueLength >= 1 ? ageValue[0].trim() : value.trim(),
      };
    });

  setAgeLicensed = (age: any, list: Array<any>) => {
    const previousValue = filter(list, o => {
      return o.value === age;
    });
    if (isEmpty(previousValue)) {
      return list[0]?.value;
    } else {
      return age;
    }
  };

  componentDidMount() {
    const { driver, address, add, common } = this.props;
    const { state } = address;
    const upperCaseState = state.toUpperCase();
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled && !!common?.isHeapDetailLoaded) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.DRIVER_INFO);
    }
    this.props.sortDrivers();
    this.setState(
      {
        ...driver,
        stateAgeLicensedData: [...this.stateAgeDataMapper(STATE_AGE_LICENSED[upperCaseState])],
        occupationData: this.getOccupationDetails(get(driver, 'industry', '')),
      },
      () => {
        const { age_licensed, stateAgeLicensedData } = this.state;
        this.setState({
          ...driver,
          age_licensed: this.setAgeLicensed(age_licensed, stateAgeLicensedData),
          us_license_status: get(
            driver,
            'us_license_status',
            DRIVER_DATA.us_license_status[0].value
          ),
          claims: driver?.accidentList?.length ? YES?.toLowerCase() : NO?.toLowerCase(),
          violations: driver?.violationList?.length ? YES?.toLowerCase() : NO?.toLowerCase(),
          losses: driver?.lossList?.length ? YES?.toLowerCase() : NO?.toLowerCase(),
          // shouldChangeTab: false,
        });
        if (this.props.spouseMissing) {
          this.props.addDriver(this.getAddDriverData());
        } else if (this.props.selectFirstDriver && driver.driversList.length) {
          this.props.driverSelectedForEdit(driver.driversList[0].duid);
          this.props.setSelectFirstDriver(false);

          // TODO: needs to review in case they need to show verisk modal in driver tab
          this.props.setShowVeriskDriverModal(false);
        } else if (driver.driversList.length === config.maxDriverAddLimit) {
          this.props.driverSelectedForEdit(driver.driversList[driver.driversList.length - 1].duid);
        } else if (add) {
          this.addAnotherDriverHandler();
        } else if (driver.driversList.length) {
          this.props.driverSelectedForEdit(driver.driversList[0].duid);
        }
      }
    );

    // set current driver's accidents,violation and losses details.
    this.props.setOtherDetails({
      accidentList: get(driver, 'accidentList', []),
      violationList: get(driver, 'violationList', []),
      lossList: get(driver, 'lossList', []),
    });
    if (!isEmpty(this.props.missingDetailsDrivers)) {
      this.setState({ missingOtherDetailsDialog: true });
    }
    if (!this.props.isValidDriverPresent) {
      this.setState({ licenseDialog: true });
    }
    if (this.props.spouseMissing) {
      this.setState({ missingSpouseDialog: true });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.DriverProps) {
    const { driver, selectFirstDriver, switchFromDriver } = newProps;
    const { onNextTab, currentIndex } = this.props;
    if (!isEmpty(switchFromDriver) ) {
      const { isSpouseInformationMissing } = this.validateDriversList(
        driver.driversList
      );
      if (switchFromDriver?.isDriverToPolicy) {
        this.submitStep(null, false);
      } else if (switchFromDriver?.isRedirectToRatePage) {
        isSpouseInformationMissing ? this.openTabSwitchConfirmationModal() : newProps.goToLastTab(newProps.currentIndex);
      }
      this.props.setSwitchDetailFromDriver({});
    }
    if (selectFirstDriver && driver.driversList.length) {
      this.props.driverSelectedForEdit(driver.driversList[0].duid);
      this.props.setSelectFirstDriver(false);
    } else if (driver.driversList.length === config.maxDriverAddLimit && !driver.duid) {
      this.props.driverSelectedForEdit(driver.driversList[driver.driversList.length - 1].duid);
    }
    if (
      !newProps.loading &&
      driver &&
      !driver.driverLoader &&
      !isEqual(this.props.driver.duid, driver.duid)
    ) {
      this.setState(
        {
          occupationData: this.getOccupationDetails(get(driver, 'industry', '')),
        },
        () => {
          const { occupationData } = this.state;
          this.setState({
            ...driver,
            claims: get(driver, 'claims', 'no'),
            violations: get(driver, 'violations', 'no'),
            losses: get(driver, 'losses', 'no'),
            first_name: get(driver, 'first_name', ''),
            last_name: get(driver, 'last_name', ''),
            date_of_birth: get(driver, 'date_of_birth', ''),
            gender: get(driver, 'gender', 'male'),
            marital_status: get(driver, 'marital_status', ''),
            relationship_status: get(driver, 'relationship_status', ''),
            driver_licence: get(driver, 'driver_licence', ''),
            us_license_status: get(driver, 'us_license_status', '')
              ? driver.us_license_status
              : DRIVER_DATA.us_license_status[0].value,
            age_licensed: this.setAgeLicensed(
              get(driver, 'age_licensed', ''),
              this.state.stateAgeLicensedData
            ),
            hasSpouse: get(driver, 'hasSpouse', false),
            isSpouseAdded: get(driver, 'isSpouseAdded', false),
            isSpouse: get(driver, 'isSpouse', false),
            isPrimaryDriver: get(driver, 'isPrimaryDriver', false),
            accidentList: get(driver, 'accidentList', []),
            violationList: get(driver, 'violationList', []),
            lossList: get(driver, 'lossList', []),
            spouseId: get(driver, 'spouseId', ''),
            education: get(driver, 'education', ''),
            industry: get(driver, 'industry', ''),
            relation_to_primary_driver: get(driver, 'relation_to_primary_driver', ''),
            occupation: getDefaultOccupation(get(driver, 'occupation', ''), occupationData),
            detailsErrors: { ...this.initDriverErrors },
          });
        }
      );

      this.props.setOtherDetails({
        accidentList: get(driver, 'accidentList', []),
        violationList: get(driver, 'violationList', []),
        lossList: get(driver, 'lossList', []),
      });
    }

    if (
      newProps.driver.duid === this.state.duid &&
      newProps.driver.isIncluded !== this.state.isIncluded
    ) {
      this.setState({ ...this.state, isIncluded: newProps.driver.isIncluded });
    }
    if (!newProps.isValidDriverPresent) {
      this.setState({ licenseDialog: true });
    }
    if (newProps.spouseMissing) {
      this.setState({ missingSpouseDialog: true });
    }
    if (!isEmpty(newProps.missingDetailsDrivers)) {
      this.setState({ missingOtherDetailsDialog: true });
    }

    const primaryDriver = this.selectPrimaryDriver(driver.driversList);
    const spouse = this.selectSpouse(driver.driversList);

    if (primaryDriver && primaryDriver.duid === primaryDriver.spouseId) {
      let spouseId = '';
      if (spouse) {
        spouseId = spouse.duid;
      }

      if (driver.duid !== primaryDriver.duid && driver.isSpouse) {
        spouseId = driver.duid;
      }
      const isSpouseAdded = spouseId ? true : false;
      this.props.updateSpecificDriver({
        ...primaryDriver,
        isSpouseAdded,
        spouseId,
      });
    }
    if (driver.isPolicyAvailable) {
      const { driverIndex, shouldContinue, isSpouseInformationMissing } = this.validateDriversList(
        driver.driversList
      );

      const validLicenseStatus = this.driversLicenseCheck(driver.driversList);
      if (!shouldContinue && driverIndex !== null) {
        this.props.driverSelectedForEdit(driver.driversList[driverIndex].duid);
        if (isSpouseInformationMissing) {
          setTimeout(() => {
            this.setState({ ...this.state, missingSpouseDialog: true });
          }, 100);
        } else {
          setTimeout(() => {
            this.setState({ ...this.state, missingInformation: true });
            this.validateInputs(this.state);
          }, 100);
        }
        this.setState({ getQuoteButtonClick: false });
      } else if (!validLicenseStatus) {
        this.setState({ licenseDialog: true });
        this.props.setPolicyFlag(false);
      } else if (this.state.getQuoteButtonClick) {
        newProps.goToLastTab(newProps.currentIndex);
      } else {
        onNextTab(currentIndex);
      }
      this.props.setPolicyFlag(false);
    }
  }

  validateDriversList = (driversList: any): AppComponents.DriversListValidation => {
    let shouldContinue = true;
    let driverIndex: number | null = null;
    let isSpouseInformationMissing = false;
    const spouse = this.selectSpouse(driversList);
    const { driver } = this.props;
    if (driver && driver.driversList) {
      for (let index = 0; index < driversList.length; index++) {
        if (!driversList[index].isIncluded) continue;
        let missingSpouse = false;
        if (
          driversList[index].spouseId &&
          driversList[index].isPrimaryDriver &&
          !driversList[index].isSpouse &&
          driversList[index].marital_status === 'Married'
        ) {
          let getDriverSpouse = driversList.find((driver: any) =>
            driver.duid === driversList[index].spouseId && driver.isSpouse);
          if (!getDriverSpouse) {
            missingSpouse = true;
          }
        }
        if (
          driversList[index].isPrimaryDriver &&
          driversList[index].marital_status === 'Married' &&
          (!driversList[index].spouseId ||
            (driversList[index].spouseId && spouse && !spouse.isIncluded) || missingSpouse)
        ) {
          driverIndex = index;
          shouldContinue = false;
          isSpouseInformationMissing = true;
          break;
        }
        if (!this.validateInputs(driversList[index])) {
          driverIndex = index;
          shouldContinue = false;
          break;
        }
      }
    }
    return { shouldContinue, driverIndex, isSpouseInformationMissing };
  };

  driversLicenseCheck = (driversList: Array<any>) => {
    let hasValidLicense = false;

    driversList.forEach((driver: any) => {
      if (driver && driver.us_license_status === 'Valid' && driver.isIncluded) {
        hasValidLicense = true;
      }
    });

    return hasValidLicense;
  };

  selectPrimaryDriver = (driversList: Array<any>) => {
    const primaryDriver = driversList.find(driver => driver.isPrimaryDriver);
    return primaryDriver;
  };

  isPrimaryDriverMarried = () => {
    let result = false;
    const primaryDriver = this.selectPrimaryDriver(this.props.driver.driversList);
    if (primaryDriver) {
      result = primaryDriver.marital_status === 'Married';
    }
    return result;
  };

  selectSpouse = (driversList: Array<any>) => {
    const spouse = driversList.find(driver => driver.isSpouse);

    return spouse;
  };

  selectCurrentDriverFromList = (driversList: Array<any>) => {
    const driver = driversList.find((driver: any) => driver.duid === this.props.driver.duid);
    return driver;
  };

  onAddDriverOrAddSpouse = () => {
    if (
      this.hasFormChanged() &&
      this.incidentsValidation({
        accidentList: this.props.accidentList,
        lossList: this.props.lossList,
        violationList: this.props.violationList,
      }) &&
      this.validateInputs(this.state)
    ) {
      this.props.saveAutoQuoteDetails(DRIVER);
    }
  }

  getOccupationDetails = (value: string) => {
    const detailsErrors = { ...this.state.detailsErrors };
    this.setState({
      disableOccupation: !!isEmpty(value),
      detailsErrors: { ...detailsErrors, occupation: '' },
    });
    return getOccupationList(INDUSTRY_DATA, value);
  };

  handlePersonalDetailsBlur = (e: any) => {
    e.target.name && this.setState({ [e.target.name]: getStartCase(e.target.value.toLowerCase()) });
  };

  handleFieldChange = (event: any) => {
    const { target } = event;
    const { isPrimaryDriver, isSpouse } = this.state;
    const { driver } = this.props;
    if (
      !isEmpty(target.value) &&
      (target.name === 'first_name' || target.name === 'last_name') &&
      !stringRegex.test(target.value)
    ) {
      return;
    }
    if (target.name === 'marital_status') {
      if (isPrimaryDriver) {
        this.setState({ relationship_status: 'Insured' });
        const spouse = this.selectSpouse(driver.driversList);
        const currentDriver = this.selectCurrentDriverFromList(driver.driversList);
        if (spouse) {
          if (
            driver.isFetchedFromVerisk &&
            driver.driversList &&
            driver.driversList.length > 1 &&
            target.value !== 'Married'
          ) {
            this.props.updateSpecificDriver({
              ...spouse,
              isSpouse: false,
              hasSpouse: false,
              relation_to_primary_driver: '',
              marital_status: '',
              relationship_status: 'Other',
              spouseId: '',
            });

            this.props.updateDriverList({
              ...currentDriver,
              isSpouse: false,
              hasSpouse: false,
              marital_status: target.value,
              relationship_status: 'Insured',
              spouseId: '',
            });
          } else if (spouse.spouseId === driver.duid && !driver.spouseId) {
            this.props.updateSpecificDriver({
              ...spouse,
              isSpouse: false,
              hasSpouse: false,
              relation_to_primary_driver: '',
              marital_status: '',
              relationship_status: 'Other',
              spouseId: '',
            });
          }
        }
      } else if (isSpouse) {
        this.setState({ relationship_status: 'Spouse' });
      } else {
        this.setState({ relationship_status: 'Other' });
      }
    }

    if (target.name === 'relation_to_primary_driver') {
      this.setState({ relation_to_primary_driver: target.value }, () => {
        if (this.isPrimaryDriverMarried() && driver.driversList && driver.driversList.length > 1) {
          if (!isPrimaryDriver) {
            const primaryDriver = this.selectPrimaryDriver(driver.driversList);
            const currentDriver = this.selectCurrentDriverFromList(driver.driversList);

            if (this.state.relation_to_primary_driver === 'spouse') {
              this.setState({
                marital_status: 'Married',
                relationship_status: 'Spouse',
                hasSpouse: true,
                isSpouse: true,
              }, () => {
                if(!currentDriver) {
                  this.setState({
                    ...this.state,
                    spouseId: primaryDriver.duid,
                  });
                }
              });

              this.props.updateSpecificDriver({
                ...primaryDriver,
                isSpouseAdded: true,
                hasSpouse: true,
                spouseId: driver.duid,
              });
              if(!!currentDriver) {
                this.props.updateDriverList({
                  ...currentDriver,
                  isSpouse: true,
                  hasSpouse: true,
                  relation_to_primary_driver: 'spouse',
                  marital_status: 'Married',
                  relationship_status: 'Spouse',
                  spouseId: primaryDriver.duid,
                });
              }
            } else if (
              currentDriver &&
              currentDriver.isSpouse &&
              this.state.relation_to_primary_driver !== 'spouse'
            ) {
              this.setState({
                relationship_status: 'Other',
                isSpouse: false,
                spouseId: '',
                hasSpouse: false,
              });
              this.props.updateSpecificDriver({
                ...primaryDriver,
                isSpouseAdded: false,
                hasSpouse: false,
                spouseId: '',
              });

              this.props.updateDriverList({
                ...currentDriver,
                isSpouse: false,
                hasSpouse: false,
                relation_to_primary_driver: this.state.relation_to_primary_driver,
                marital_status: this.state.marital_status,
                relationship_status: 'Other',
                spouseId: '',
              });
            }
            this.props.setHasFormChanged(true);
          }
        }
      });
    }
    if (target.name === 'us_license_status') {
      if (target.value.toLowerCase() === this.notLicensedProperty) {
        this.setState({
          age_licensed: '',
          driver_licence: '',
          claims: 'no',
          violations: 'no',
          losses: 'no',
          detailsErrors: { ...this.state.detailsErrors, age_licensed_error: '' },
        });
      }
    }
    if (target.name === 'industry') {
      this.setState({
        occupation: '',
        occupationData: this.getOccupationDetails(target.value),
      });
    }
    target.name && this.setState({ [target.name]: target.value });
  };

  handleCalendarDate = (dateFromCalender: any) => {
    this.setState({ date_of_birth: dateFromCalender });
  };

  setDriverFieldsError = (fieldNames: Array<any>, defaultErrors: any, state: any) => {
    const errorDetails = { ...defaultErrors };
    forEach(fieldNames, fieldName => {
      if (fieldName !== 'driver_licence') {
        if (!state[fieldName] || state[fieldName].length === 0) {
          if (fieldName !== 'industry' && fieldName !== 'education') {
            errorDetails[`${fieldName}_error`] = 'Required';
          } else {
            errorDetails[`${fieldName}`] = 'Required';
          }
        }
      } else if (state['driver_licence'].length > 50) {
        errorDetails[`driver_licence_error`] = 'License number is too large';
      }
    });
    return errorDetails;
  };

  // todo: check maybe we need this
  setDOBError = (dob: any, defaultErrors: any) => {
    const differenceYear = getDateDifference(dob);
    const stateMinimumAgeLicensed = parseInt(this.state.stateAgeLicensedData[0].value);
    const error = { ...defaultErrors };

    if (isEmpty(error.date_of_birth_error)) {
      if ((!isEmpty(dob) && !moment(dob).isValid()) || !differenceYear || differenceYear > 99) {
        error.date_of_birth_error = 'Invalid';
      } else if (moment(dob).isValid() && moment().diff(dob, 'years') < stateMinimumAgeLicensed) {
        error.date_of_birth_error = `You must be at least ${stateMinimumAgeLicensed}
          years old`;
      }
    }
    return error;
  };

  setLicenseStatusError = (state: any, defaultErrors: any) => {
    const { date_of_birth } = state;
    const currentDate = moment(new Date(), 'MM/DD/YYYY');
    const differenceAgeLicensed = currentDate.diff(date_of_birth, 'year');
    const licenseError = { ...defaultErrors };

    if (state.us_license_status.toLowerCase() !== this.notLicensedProperty) {
      if (!state.age_licensed || state.age_licensed.length === 0) {
        licenseError.age_licensed_error = 'Required';
      }
      if (parseInt(state.age_licensed) > differenceAgeLicensed) {
        licenseError.age_licensed_error = 'Invalid';
      }
    }
    return licenseError;
  };

  validateInputs = (state: any): boolean => {
    const { isIncluded } = state;
    let fieldNames = ['first_name', 'last_name', 'gender'];
    let defaultErrors: AppComponents.DriverErrors = { ...this.initDriverErrors };
    if (isIncluded) {
      fieldNames = [
        ...fieldNames,
        'marital_status',
        'us_license_status',
        'claims',
        'violations',
        'losses',
        'industry',
        'education',
        'date_of_birth',
        'driver_licence',
      ];
      const { isPrimaryDriver, isSpouse } = state;
      if (!isPrimaryDriver && !isSpouse) {
        fieldNames.push('relation_to_primary_driver');
      }
      defaultErrors = { ...this.setDriverFieldsError(fieldNames, defaultErrors, state) };
      defaultErrors = { ...this.setDOBError(state.date_of_birth, defaultErrors) };
      defaultErrors = { ...this.setLicenseStatusError(state, defaultErrors) };
      if (!this.state.disableOccupation) {
        if (!this.state.occupation) {
          defaultErrors.occupation = 'Required';
        }
      }
      if (defaultErrors.date_of_birth_error) {
        this.setState({ detailsErrors: { ...defaultErrors } });
        return false;
      }
      this.setState({ detailsErrors: { ...defaultErrors } });
      return values(defaultErrors).every(isEmpty);
    } else {
      defaultErrors = { ...this.setDriverFieldsError(fieldNames, defaultErrors, state) };
      this.setState({ detailsErrors: { ...defaultErrors } });
      return values(defaultErrors).every(isEmpty);
    }
  };

  hasIncident = (state: any) => {
    let incident = false;
    if (state.violations === YES?.toLowerCase()) incident = true;
    if (state.claims === YES?.toLowerCase()) incident = true;
    if (state.losses === YES?.toLowerCase()) incident = true;

    return incident;
  };

  getAddDriverData = () => {
    const { driver } = this.props;
    const {
      hasSpouse,
      isSpouse,
      isPrimaryDriver,
      first_name,
      last_name,
      gender,
      date_of_birth,
      marital_status,
      driver_licence,
      us_license_status,
      age_licensed,
      claims,
      violations,
      losses,
      accidentList,
      violationList,
      lossList,
      relationship_status,
      education,
      industry,
      occupation,
      relation_to_primary_driver,
      duid,
    } = this.state;
    const isClearDetails = claims !== YES?.toLowerCase() && violations !== YES?.toLowerCase() && losses !== YES?.toLowerCase();
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.ADD_DRIVER);
    }
    return {
      duid,
      clear: isClearDetails,
      hasSpouse: isPrimaryDriver ? marital_status === 'Married' : hasSpouse,
      isSpouse,
      isSpouseAdded: this.props.driver.isSpouseAdded,
      isPrimaryDriver,
      first_name,
      last_name,
      gender,
      date_of_birth,
      marital_status,
      driver_licence,
      us_license_status,
      age_licensed:
        us_license_status.toLowerCase() === this.notLicensedProperty
          ? this.state.stateAgeLicensedData[0].value
          : age_licensed,
      claims,
      violations,
      losses,
      spouseId: this.props.driver.spouseId,
      accidentList: claims === 'no' ? [] : accidentList,
      violationList: violations === 'no' ? [] : violationList,
      lossList: losses === 'no' ? [] : lossList,
      relationship_status,
      education,
      industry,
      occupation,
      relation_to_primary_driver:
        isPrimaryDriver && !isSpouse
          ? 'applicant'
          : isSpouse
          ? 'spouse'
          : relation_to_primary_driver,
      isIncluded: driver.isIncluded,
    };
  };

  addAnotherDriverHandler = (event?: any) => {
    const { add } = this.props;
    if (this.validateInputs(this.state)) {
      if (!add || !this.hasIncident(this.state)) {
        this.props.addDriver(this.getAddDriverData());
      } else if (this.hasIncident(this.state)) {
        this.props.addDriverFromOtherDetails(this.getAddDriverData());
      }
      (document as any).getElementById('first_name').focus();
    }
    if (!add && event) {
      event.preventDefault();
    }
  };

  checkHasSpouse = (isPrimaryDriver: boolean, maritalStatus: string, hasSpouse: boolean) => {
    return isPrimaryDriver ? maritalStatus === 'Married' : hasSpouse;
  };

  vehicleBackHandler = () => {
    const { driver, driverSelectedForEdit, onPrevTab, currentIndex } = this.props;
    this.setState(
      {
        detailsErrors: {
          ...this.initDriverErrors,
        },
      },
      () => {
        window.scrollTo(0, 0);
        const currentDriverIndex: number = findIndex(driver.driversList, {
          duid: driver.duid,
        });
        if (currentDriverIndex > 0) {
          const previousDriver = currentDriverIndex - 1;
          driverSelectedForEdit(driver.driversList[previousDriver].duid);
        } else {
          onPrevTab(currentIndex);
        }
      }
    );
  };

  hasiIncidentChange = () => {
    const { accidentList, violationList, lossList } = this.props;
    const { claims, violations, losses } = this.state;

    let isIncidentAvailable = false;

    if (violations.toLowerCase() === YES?.toLowerCase() && violationList.length) {
      violationList.forEach(violation => {
        if (!violation.date_of_violations || !violation.violations_description) {
          isIncidentAvailable = true;
        }
      });
    }
    if (claims.toLowerCase() === YES?.toLowerCase() && accidentList.length) {
      accidentList.forEach(accident => {
        if (
          !accident.date_of_accidents ||
          !accident.accident_description ||
          !accident.pd_amount ||
          !accident.bi_amount ||
          !accident.collision_amount ||
          !accident.mp_amount
        ) {
          isIncidentAvailable = true;
        }
      });
    }
    if (losses.toLowerCase() === YES?.toLowerCase() && lossList.length) {
      lossList.forEach(loss => {
        if (!loss.date_of_loss || !loss.loss_description || !loss.loss_amount) {
          isIncidentAvailable = true;
        }
      });
    }
    return isIncidentAvailable;
  };

  hasFormChanged = () => {
    let hasChanged = false;
    const copiedState = {
      ...omit(deleteDiffKeys(this.state, this.props.driver), [
        'isFetchAutoQuotes',
        'isNeedToGoToRatesTab',
        'isPolicyAvailable',
        'getQuoteButtonClick',
        'shouldChangeTab',
        'saveDialog',
        'selectedDuid',
        'driversList',
        'driverLimitReached',
      ]),
    };
    const copiedDriverProps = {
      ...omit(this.props.driver, [
        'isFetchAutoQuotes',
        'isNeedToGoToRatesTab',
        'isPolicyAvailable',
        'getQuoteButtonClick',
        'shouldChangeTab',
        'saveDialog',
        'selectedDuid',
        'driversList',
        'driverLimitReached',
      ]),
    };

    if (!isEqual(copiedState, copiedDriverProps)) {
      hasChanged = true;
    }

    return hasChanged;
  };

  validateIncidentList = (list: Array<any>) => {
    let propertyHasValue = true;
    list.forEach(incident => {
      forEach(incident, value => {
        if (!value || isEmpty(value)) {
          propertyHasValue = false;
        }
      });
    });

    return propertyHasValue;
  };

  incidentsValidation = ({
    accidentList,
    lossList,
    violationList,
  }: {
    accidentList: Array<any>;
    lossList: Array<any>;
    violationList: Array<any>;
  }) => {
    let isValid = true;
    if (accidentList && Array.isArray(accidentList)) {
      if ((this.state.claims === YES?.toLowerCase()) && !this.validateIncidentList(accidentList)) {
        isValid = false;
      }
    }

    if (lossList && Array.isArray(lossList)) {
      if ((this.state.losses === YES?.toLowerCase()) && !this.validateIncidentList(lossList)) {
        isValid = false;
      }
    }

    if (violationList && Array.isArray(violationList)) {
      if ((this.state.violations === YES?.toLowerCase()) && !this.validateIncidentList(violationList)) {
        isValid = false;
      }
    }
    return isValid;
  };

  submitStep = (event: any, getQuoteClicked: boolean) => {
    const {
      entity,
      hasSpouse,
      marital_status,
      isSpouseAdded,
      isSpouse,
      claims,
      violations,
      losses,
      accidentList,
      violationList,
      lossList,
      isPrimaryDriver,
      relation_to_primary_driver,
      spouseId,
    } = this.state;

    const { isVeriskFetched, driver, onNextTab, currentIndex } = this.props;
    const usLicenseStatus = this.state.us_license_status;
    const ageLicensed = this.state.age_licensed;
    const disableAutoEdits = allowEditingQuote();
    if (disableAutoEdits?.auto) {
      onNextTab(currentIndex);
    } else if (this.validateInputs(this.state)) {
      if (getQuoteClicked) {
        this.setState({ getQuoteButtonClick: true }, () => {
          this.props.setPolicyFlag(true);
        });
      }
      const driverHasSpouse = this.checkHasSpouse(isPrimaryDriver, marital_status, hasSpouse);
      if (
        this.hasFormChanged() ||
        !this.props.quoteList.length
      ) {
        this.props.setHasFormChanged(true);
      }
      const isClear = () => {
        let clear =
          driverHasSpouse &&
          !isSpouseAdded &&
          !isSpouse &&
          claims === 'no' &&
          violations === 'no' &&
          losses === 'no';

        if (
          isVeriskFetched &&
          driver.driversList &&
          driver.driversList.length > 1 &&
          isPrimaryDriver
        ) {
          clear = false;
        }

        return clear;
      };
      let isHeapAnalyticsEnabled: boolean =
        !!window.heap && getIsHeapAnalyticsEnabled();
      if (isHeapAnalyticsEnabled) {
        window.heap.track(QUOTING_STEPS_PAGES_NAME?.DRIVER_INFO_SUBMIT);
      }
      if (this.incidentsValidation({
        accidentList: this.props.accidentList, 
        lossList: this.props.lossList, 
        violationList: this.props.violationList}))
      this.props.stepSubmit({
        entity,
        getQuoteClick: getQuoteClicked,
        clear: isClear(),
        data: {
          ...omit(this.state, [
            'entity',
            'licenseDialog',
            'missingSpouseDialog',
            'missingOtherDetailsDialog',
            'detailsErrors',
            'driversList',
            'stateAgeLicensedData',
            'occupationData',
            'disableOccupation',
            'saveDialog',
            'selectedDuid',
            'showIncident',
            'continueButtonClick',
            'getQuoteButtonClick',
            'missingInformation',
            'isIncidentAvailable',
            'isPolicyAvailable',
            'isFetchAutoQuotes',
            'isNeedToGoToRatesTab',
            'driverLoader',
            'driverLimitReached',
            'tabSwitchConfirmationModal'
          ]),
          hasSpouse: this.checkHasSpouse(isPrimaryDriver, marital_status, hasSpouse),
          spouseId: !isEmpty(driver.spouseId) ? driver.spouseId : spouseId,
          accidentList: claims === 'no' ? [] : accidentList,
          violationList: violations === 'no' ? [] : violationList,
          lossList: losses === 'no' ? [] : lossList,
          isSpouseAdded: this.props.driver.isSpouseAdded ? isSpouseAdded : false,
          relation_to_primary_driver:
            isPrimaryDriver && !isSpouse
              ? 'applicant'
              : isSpouse
              ? 'spouse'
              : relation_to_primary_driver,
          age_licensed:
            usLicenseStatus.toLowerCase() === this.notLicensedProperty
              ? this.state.stateAgeLicensedData[0].value
              : ageLicensed,
          isIncluded: driver.isIncluded,
        },
      });

      if (this.hasIncident(this.state)) {
        this.props.stepSubmit({
          entity: 'otherDetails',
          getQuoteClick: getQuoteClicked,
          data: { navigation: 'continue', isIncluded: driver.isIncluded },
        });
      }

      if (
        this.hasFormChanged() &&
        this.incidentsValidation({
          accidentList: this.props.accidentList,
          lossList: this.props.lossList,
          violationList: this.props.violationList,
        })
      ) {
        this.props.saveAutoQuoteDetails(DRIVER);
      }
      // TODO: delete this part after everything after review
      if (!this.state.showIncident) {
        window.scrollTo(0, 0);
        (document as any).getElementById('first_name').focus();
      }
    }
    if (event) {
      event.preventDefault();
    }
  };

  modalClose = (shouldSave: boolean) => {
    if (shouldSave) {
      this.submitStep(null, false);
      this.setState({
        ...this.state,
        detailsErrors: { ...this.initDriverErrors },
        saveDialog: false,
      });
      if (
        this.validateInputs(this.state) &&
        !this.incidentsValidation({
          accidentList: this.state.accidentList,
          lossList: this.state.lossList,
          violationList: this.state.violationList,
        })
      ) {
        this.props.driverSelectedForEdit(this.state.selectedDuid);
      }
    } else {
      this.setState({
        ...this.state,
        detailsErrors: { ...this.initDriverErrors },
        saveDialog: false,
      });
      this.props.driverSelectedForEdit(this.state.selectedDuid);
    }
  };

  validateBeforeEditHandler = (duid: string) => {
    let isFormChange = false;
    const disableAutoEdits = allowEditingQuote();
    const { accidentList, driver, lossList, violationList } = this.props;
    const currentDriverIndex: number =
      !isEmpty(driver?.driversList) && !isEmpty(driver.duid)
        ? findIndex(driver.driversList, {
            duid: driver.duid,
          })
        : -1;
    if (currentDriverIndex !== -1) {
      isFormChange =
        !isEqual(driver.driversList[currentDriverIndex].accidentList, accidentList) ||
        !isEqual(driver.driversList[currentDriverIndex].lossList, lossList) ||
        !isEqual(driver.driversList[currentDriverIndex].violationList, violationList);
    }
    if ((this.hasFormChanged() || isFormChange) && !disableAutoEdits?.auto) {
      this.setState({
        saveDialog: true,
        selectedDuid: duid,
      });
    } else {
      this.props.driverSelectedForEdit(duid);
      this.setState({
        ...this.state,
        detailsErrors: { ...this.initDriverErrors },
      });
    }
  };

  spouseExists = () => {
    const { spouseId, driverList } = this.state;
    if (isEmpty(spouseId)) {
      return false;
    }
    return isEmpty(find(driverList, ['duid', spouseId]));
  };

  modalLicenseCloseHandler = () => {
    this.setState({ licenseDialog: false }, () => this.props.setValidDriverFlag(true));
  };

  modalSpouseCloseHandler = () => {
    this.setState({ missingSpouseDialog: false }, () => this.props.setMissingSpouse(false));
  };

  getDriverMissingDetails = () => {
    const { missingDetailsDrivers } = this.props;
    const list = (
      <ul style={{ fontSize: 16, marginBottom: 0 }}>
        {map(missingDetailsDrivers, driver => (
          <li key={driver.duid}>{driver.full_name}</li>
        ))}
      </ul>
    );
    return ReactDOMServer.renderToString(list);
  };

  missingDetailsModalCloseHandler = () => {
    this.setState({ missingOtherDetailsDialog: false }, () =>
      this.props.setMissingDetailsDrivers([])
    );
  };

  showDriverAddAction = () => {
    const { driver } = this.props;
    const currentDriverExists = find(driver.driversList, ['duid', driver.duid]);
    if (currentDriverExists) {
      return driver.driversList.length < config.maxDriverAddLimit;
    } else {
      return driver.driversList.length + 1 < config.maxDriverAddLimit;
    }
  };

  closeMissingInformationModal = () => {
    this.setState({ ...this.state, missingInformation: false });
  };

  openDriverLimitModal = () => {
    this.setState({ ...this.state, driverLimitReached: true });
  };

  closeDriverLimitModal = () => {
    this.setState({ ...this.state, driverLimitReached: false });
  };

  openTabSwitchConfirmationModal = () => {
    this.setState({ ...this.state, tabSwitchConfirmationModal: true });
  };

  closeTabSwitchConfirmationModal = () => {
    this.setState({ ...this.state, tabSwitchConfirmationModal: false });
  };

  redirectToRatePage = () => {
    const { goToLastTab, currentIndex } = this.props;
    goToLastTab(currentIndex);
  }

  checkNotLicensed = (value: string): boolean => value.toLowerCase() !== this.notLicensedProperty;
  render() {
    return template(this);
  }
}

const mapStateToProps = ({
  applicant,
  common,
  driver,
  quote,
  home,
  otherDetails,
}: AppComponents.DriverStore) => {
  const { address, personal_details } = applicant;
  const { accidentList, lossList, violationList } = otherDetails;
  const {
    loading,
    isValidDriverPresent,
    missingDetailsDrivers,
    spouseMissing,
    stepSubmitLoader,
    showGetQuoteButton,
    showVeriskDriverModal,
    isVeriskFetched,
    selectFirstDriver,
    switchFromDriver,
  } = common;
  const { quoteCompleted, quoteErrorList, quoteList } = quote;
  return {
    address,
    loading,
    isValidDriverPresent,
    missingDetailsDrivers,
    spouseMissing,
    stepSubmitLoader,
    driver,
    quoteCompleted,
    personal_details,
    showGetQuoteButton,
    home,
    quoteErrorList,
    quoteList,
    accidentList,
    lossList,
    violationList,
    showVeriskDriverModal,
    isVeriskFetched,
    selectFirstDriver,
    switchFromDriver,
    common
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.DriverDispatch => {
  return bindActionCreators(
    {
      addDriver,
      driverBack,
      driverSelectedForEdit,
      setOtherDetails,
      setMissingSpouse,
      setMissingDetailsDrivers,
      stepSubmit,
      setValidDriverFlag,
      setPolicyFlag,
      setFetchAutoQuotes,
      saveAutoQuoteDetails,
      setHasFormChanged,
      addDriverFromOtherDetails,
      initAccidents,
      initViolations,
      initLosses,
      setShowVeriskDriverModal,
      updateSpecificDriver,
      updateDriverList,
      setSelectFirstDriver,
      sortDrivers,
      setSwitchDetailFromDriver,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(Driver));
