import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, startCase } from 'lodash';
import { Button, InputBase, Switch } from '@material-ui/core';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AddEditCarrierAppointment from '../add-edit-carrier-appointments';
import ConfirmationModal from '../../components/confirmation-modal';
import ShowImageAfterLoading from '../../components/show-image-after-loading';
import { deletCarrierAppointment } from '../../redux/actions';
import { getNestedTernaryData, getCarrierDataByCarrierId, stringSort } from '../../../utils';
import { tableIcons } from '../../../utils/table-icons';
import {
  ADD,
  ALL_LOB,
  CARRIER_APPOINTMENT,
  DELETE,
  EDIT,
  ENCRYPTED_DATA,
  HTML_EM,
  LOB_KEY,
  NAME,
  PASSWORD,
  PRODUCER_CODE,
  PRODUCER_CODE_KEY,
  PRODUCTS,
  USERNAME,
} from '../../../constants';
import './carrier-appointments-table.scss';

const createData = (data: AppComponents.CarrierAppointmentsTableRowData) => {
  return {
    ...data,
  };
};

export class CarrierAppointmentsTable extends Component<
  AppComponents.CarrierAppointmentsProps,
  AppComponents.CarrierAppointmentsTableState
> {
  state: AppComponents.CarrierAppointmentsTableState = {
    columns: [
      {
        title: CARRIER_APPOINTMENT.CARRIER_LOGO_LABEL,
        field: CARRIER_APPOINTMENT.CARRIER_LOGO_KEY,
        searchable: false,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '75px',
          maxWidth: '85px',
        },
        cellStyle: {
          minWidth: '75px',
          maxWidth: '85px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        render: (rowData: any) => {
          const { name } = rowData;
          return (
            <>
              {!isEmpty(rowData?.carrier_logo) ? (
                <ShowImageAfterLoading url={rowData?.carrier_logo} imageName={name} />
              ) : (
                <span title={!isEmpty(name) && name}>
                  {getNestedTernaryData(!!name, name, HTML_EM)}
                </span>
              )}
            </>
          );
        },
      },
      {
        title: startCase(NAME),
        field: NAME,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '80px',
          maxWidth: '85px',
        },
        cellStyle: {
          minWidth: '80px',
          maxWidth: '85px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.name, b.name),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.name ? rowData.name : ''}>
              {!!rowData?.name ? rowData.name : HTML_EM}
            </span>
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.MARKETS_LABEL,
        field: CARRIER_APPOINTMENT.MARKET_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '85px',
        },
        cellStyle: {
          maxWidth: '85px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        customSort: (a: any, b: any) => stringSort(a.market, b.market),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.market ? rowData.market : ''}>
              {!!rowData?.market ? rowData.market : HTML_EM}
            </span>
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.CONSUMER_MARKET_LABEL,
        field: CARRIER_APPOINTMENT.CONSUMER_MARKET_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '85px',
        },
        cellStyle: {
          maxWidth: '85px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        customSort: (a: any, b: any) => stringSort(a.consumer_market, b.consumer_market),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.consumer_market ? rowData.consumer_market : ''}>
              {!!rowData?.consumer_market ? rowData.consumer_market : HTML_EM}
            </span>
          );
        },
      },
      {
        title: LOB_KEY,
        field: LOB_KEY?.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '100px',
        },
        cellStyle: {
          minWidth: '40px',
          maxWidth: '100px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        customSort: (a: any, b: any) => stringSort(a.lob, b.lob),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.lob ? rowData.lob : ''}>
              {!!rowData?.lob ? rowData?.lob : ALL_LOB}
            </span>
          );
        },
      },
      {
        title: startCase(PRODUCTS),
        field: PRODUCTS?.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '100px',
        },
        cellStyle: {
          minWidth: '40px',
          maxWidth: '100px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        render: (rowData: AppComponents.CarrierAppointmentsTableRowData) => {
          return (
            <span title={!!rowData?.products ? rowData.products.join(',') : ''}>
              {!!rowData?.products ? rowData.products.join(',') : HTML_EM}
            </span>
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.INTEGRATION_TYPE_LABEL,
        field: CARRIER_APPOINTMENT.INTEGRATION_TYPE_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '90px',
          maxWidth: '100px',
        },
        cellStyle: {
          minWidth: '90px',
          maxWidth: '100px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        customSort: (a: any, b: any) => stringSort(a.integration_type, b.integration_type),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.integration_type ? rowData?.integration_type : ''}>
              {!!rowData?.integration_type ? startCase(rowData?.integration_type) : HTML_EM}
            </span>
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.ENABLED_FOR_LEAD_FLOW_LABEL,
        field: CARRIER_APPOINTMENT.ENABLED_FOR_LEAD_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '100px',
          maxWidth: '120px',
        },
        sorting: true,
        cellStyle: {
          minWidth: '100px',
          maxWidth: '120px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        render: (rowData: any) => {
          return (
            <Switch
              color='primary'
              checked={!!rowData?.is_enable_for_lead_flow ? true : false}
              disabled={true}
            />
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.API_USERNAME,
        field: USERNAME,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '95px',
          maxWidth: '100px',
        },
        cellStyle: {
          minWidth: '80px',
          maxWidth: '95px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.username, b.username),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.username ? rowData.username : ''}>
              {!!rowData?.username ? rowData.username : HTML_EM}
            </span>
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.API_PASSWORD,
        field: PASSWORD,
        headerStyle: {
          maxWidth: '100px',
        },
        searchable: false,
        sorting: false,
        cellStyle: {
          minWidth: '100px',
          maxWidth: '110px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.password, b.password),
        render: (rowData: any) => {
          const { isViewPassword, password } = rowData;
          return (
            <>
              {!isEmpty(password) ? (
                <div className='d-flex api-password-div align-items-center justify-content-start'>
                  <InputBase
                    value={getNestedTernaryData(!!isViewPassword, password, ENCRYPTED_DATA)}
                    disabled={true}
                  />
                  <div onClick={() => this.showPassword(rowData)}>
                    {getNestedTernaryData(
                      !isViewPassword,
                      <VisibilityIcon />,
                      <VisibilityOffIcon />
                    )}
                  </div>
                </div>
              ) : (
                <span>{HTML_EM}</span>
              )}
            </>
          );
        },
      },
      {
        title: PRODUCER_CODE,
        field: PRODUCER_CODE_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '80px',
          maxWidth: '90px',
        },
        cellStyle: {
          minWidth: '80px',
          maxWidth: '90px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        customSort: (a: any, b: any) => stringSort(a.producer_code, b.producer_code),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.producer_code ? rowData.producer_code : ''}>
              {!!rowData?.producer_code ? rowData.producer_code : HTML_EM}
            </span>
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.IS_ACTIVE_LABEL,
        field: CARRIER_APPOINTMENT.IS_ACTIVE_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '100px',
          maxWidth: '120px',
        },
        sorting: true,
        cellStyle: {
          minWidth: '100px',
          maxWidth: '120px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        render: (rowData: any) => {
          return (
            <Switch
              color='primary'
              checked={!!rowData?.is_active ? true : false}
              disabled={true}
            />
          );
        },
      },
      {
        title: CARRIER_APPOINTMENT.IS_AVAILABLE_FOR_QUOTING_LABEL,
        field: CARRIER_APPOINTMENT.IS_AVAILABLE_FOR_QUOTING_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '100px',
          maxWidth: '120px',
        },
        sorting: true,
        cellStyle: {
          minWidth: '100px',
          maxWidth: '120px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        },
        render: (rowData: any) => {
          return (
            <Switch
              color='primary'
              checked={!!rowData?.is_available_for_quoting}
              disabled={true}
            />
          );
        },
      },
    ],
    data: [],
    carrierAppointmentRowData: null,
    openAddCarrierAppointment: false,
    openEditCarrierAppointment: false,
    openDeleteCarrierAppointment: false,
  };

  componentDidMount = () => {
    const { editOrganizationData } = this.props;
    if (!isEmpty(editOrganizationData?.carrier_credentials_grid)) {
      this.setState({
        data: [...this.mapCarrierAppointmentsData(editOrganizationData?.carrier_credentials_grid)],
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { editOrganizationData } = newProps;
    if (!isEmpty(editOrganizationData?.carrier_credentials_grid)) {
      this.setState({
        data: [...this.mapCarrierAppointmentsData(editOrganizationData?.carrier_credentials_grid)],
      });
    } else {
      this.setState({
        data: [],
      });
    }
  };

  showPassword = (rowData: any) => {
    const { id, isViewPassword } = rowData;
    let carrierList = [...this.state.data];
    const carrierIndex = carrierList.findIndex(item => item.id === id);
    if (carrierIndex !== -1) {
      carrierList[carrierIndex].isViewPassword = !isViewPassword;
    }
    this.setState((prevState: any) => ({
      ...prevState,
      data: carrierList,
    }));
  };

  mapCarrierAppointmentsData = (list: any) => {
    const { editOrganizationData } = this.props;
    let rowDetails: Array<any> = [];
    !isEmpty(list) &&
      list.map((item: any) => {
        let carrierData =
          !isEmpty(editOrganizationData?.carrierList) &&
          getCarrierDataByCarrierId(item.code, editOrganizationData?.carrierList);
        let row = createData({
          id: !!item?.id ? item.id : '',
          code: !!item?.code ? item.code : '',
          name: !!item?.name ? item.name : '',
          username: !!item?.username ? item.username : '',
          password: !!item?.password ? item?.password : '',
          market: !!item?.market ? item.market : '',
          consumer_market: !!item?.consumer_market ? item.consumer_market : '',
          api_key: !!item?.api_key ? item.api_key : '',
          carrier_logo: !!carrierData?.logo ? carrierData.logo : '',
          producer_code: !!item?.producer_code ? item.producer_code : '',
          organization_id: !!item?.organization_id ? item.organization_id : '',
          lob: !!item?.lob ? item?.lob : '',
          products: !!item?.products ? item?.products : '',
          isViewPassword: false,
          integration_type: !!item?.integration_type ? item?.integration_type : '',
          is_enable_for_lead_flow: !!item?.is_enable_for_lead_flow ? true : false,
          is_active: !!item?.is_active,
          is_available_for_quoting: !!item?.is_available_for_quoting,
        });
        return rowDetails.push(row);
      });
    return rowDetails;
  };

  onCarrierAppointmentRowClick = (type: any, rowData?: any) => {
    this.setState({
      openAddCarrierAppointment: type === ADD ? true : false,
      openEditCarrierAppointment: type === EDIT?.toLowerCase() ? true : false,
      openDeleteCarrierAppointment: type === DELETE?.toLowerCase() ? true : false,
      carrierAppointmentRowData: !!rowData ? rowData : null,
    });
  };

  deletCarrierAppointmentData = (data: any) => {
    if (!!data) {
      const { editOrganizationData } = this.props;
      let payload = {
        id: this.state.carrierAppointmentRowData.id,
        organizationCode: editOrganizationData.code,
      };
      this.props.deletCarrierAppointment({ ...payload });
    }
    this.closeCarrierAppointent();
  };

  closeCarrierAppointent = () => {
    this.setState({
      openAddCarrierAppointment: false,
      openEditCarrierAppointment: false,
      openDeleteCarrierAppointment: false,
      carrierAppointmentRowData: null,
    });
  };

  render() {
    const {
      columns,
      data,
      openAddCarrierAppointment,
      openDeleteCarrierAppointment,
      openEditCarrierAppointment,
      carrierAppointmentRowData,
    } = this.state;
    const { organizations } = this.props;
    return (
      <div className='container carrier-wrapper carrier-appointments-wrapper mt-5'>
        <div className='carrier-apooinment-table'>
          <MaterialTable
            title={
              <Button
                variant='outlined'
                className='new-org-btn'
                onClick={() => this.onCarrierAppointmentRowClick('add', null)}
              >
                <AddIcon className='add-icon-border mr-3' />
                Add Appointment
              </Button>
            }
            icons={tableIcons}
            columns={columns}
            data={data}
            localization={{
              header: {
                actions: 'Action',
              },
            }}
            options={{
              draggable: false,
              search: true,
              paging: true,
              headerStyle: {
                fontSize: '14px',
                color: 'black',
                position: 'inherit',
              },
              actionsColumnIndex: -1,
              pageSizeOptions: [],
            }}
            isLoading={!!organizations?.addEditOrgLoader}
            actions={[
              {
                icon: () => <span className='icon-edit1 edit-svg-tbl text-color'></span>,
                tooltip: EDIT,
                onClick: (_e: any, rowData: any) =>
                  this.onCarrierAppointmentRowClick(EDIT?.toLowerCase(), rowData),
              },
              {
                icon: () => <span className='icon-delete edit-svg-tbl text-color'></span>,
                tooltip: DELETE,
                onClick: (_e, rowData: any) =>
                  this.onCarrierAppointmentRowClick(DELETE?.toLowerCase(), rowData),
              },
            ]}
          />
          {(openAddCarrierAppointment || openEditCarrierAppointment) && (
            <AddEditCarrierAppointment
              isOpen={openAddCarrierAppointment || openEditCarrierAppointment}
              onCloseAddEditCarrierAppointment={this.closeCarrierAppointent}
              orgTitle={!!openAddCarrierAppointment ? 'Add Appointment' : 'Update Appointment'}
              loader={organizations?.addEditOrgLoader}
              carrierAppointmentRowData={carrierAppointmentRowData}
            />
          )}
          {openDeleteCarrierAppointment && (
            <ConfirmationModal
              open={openDeleteCarrierAppointment}
              closehandler={this.deletCarrierAppointmentData}
              heading={''}
              message={''}
              contentValue={carrierAppointmentRowData.name}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.CarrierAppointmentsTableStore) => {
  const { editOrganizationData } = organizations;
  return { organizations, editOrganizationData };
};

const mapDispatchToProps = (dispatch: any): AppComponents.CarrierAppointmentsTableDispatch => {
  return bindActionCreators({ deletCarrierAppointment }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CarrierAppointmentsTable);
