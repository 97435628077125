import React from 'react';
import Button from '@material-ui/core/Button';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import every from 'lodash/every';
import find from 'lodash/find';
import get from 'lodash/get';
import IconButton from '@material-ui/core/IconButton';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import sortBy from 'lodash/sortBy';
import ConfirmationModal from '../components/confirmation-modal';
import SpinnerLoader from '../components/spinner-loader';
import {
  fetchReportDetails,
  lobFilterUpdated,
  removeSavedFilter,
  savedFilterSelected,
  setReportAPIError,
  submitReportDetails,
} from '../redux/actions';
import {
  FILTER_REMOVE_MODAL_HEADING,
  FILTER_REMOVE_MODAL_MESSAGE,
  NO_SAVED_FILTERS_MESSAGE,
  NON_RETURNING_QUOTE_OPTION,
  REPORT_ACTIONS,
  REPORT_ERROR_TIMEOUT,
} from '../../constants';
import { replaceWithPlaceholder } from '../../utils';
import template from './template';
import './report.scss';

const ALPHA_NUMERIC_SPACE_REGEX = /^[a-zA-Z0-9 ]*$/;
const SPACE_REGEX = /\s+/g;

const styles = (theme: Theme) =>
  createStyles({
    submitSpinner: {
      color: 'white !important',
      marginBottom: '2px',
      marginRight: '0px',
    },
    filterTooltip: {
      width: '250px',
    },
  });

const reportDetailsInitState: AppComponents.ReportDetails = {
  startDate: '',
  endDate: '',
  dateFilterType: 'quoteDate',
  states: [],
  lobs: [],
  carriers: [],
  divisions: [],
  executives: [],
  organizations: [],
  filterName: '',
  appliedLobs: [],
  appliedStates: [],
  appliedDivisions: [],
  appliedExecutives: [],
  appliedCarriers: [],
  appliedOrganizations: [],
  nonReturningQuotes: NON_RETURNING_QUOTE_OPTION.EXCLUDE,
};

const reportDetailsErrorInitState: AppComponents.ReportDetailsError = {
  rangeError: '',
  filterNameError: '',
};

class Report extends React.Component<
  AppComponents.ReportDetailsProps & WithStyles<typeof styles>,
  AppComponents.ReportState
> {
  state: AppComponents.ReportState = {
    reportDetails: { ...reportDetailsInitState },
    reportDetailsError: { ...reportDetailsErrorInitState },
    reportAPIError: '',
    removedFilter: [],
    selectedSavedFilters: [],
    selectedAction: '',
    selectedDeleteFilter: '',
    deleteDialog: false,

    savedFilterList: [],
    stateFilterApplied: true,
    stateFilterOptions: [],
    stateFilterList: [],
    stateAppliedFilter: [],
    lobFilterApplied: true,
    lobFilterOptions: [],
    lobFilterList: [],
    lobAppliedFilter: [],
    carrierFilterApplied: true,
    carrierFilterOptions: [],
    carrierFilterList: [],
    carrierAppliedFilter: [],
    divisionFilterApplied: true,
    divisionFilterOptions: [],
    divisionFilterList: [],
    divisionAppliedFilter: [],
    executiveFilterApplied: true,
    executiveFilterOptions: [],
    executiveFilterList: [],
    executiveAppliedFilter: [],
    organizationFilterApplied: true,
    organizationFilterList: [],
    organizationAppliedFilter: [],
    organizationFilterOptions: [],
    alertTimeoutRef: null,
  };

  componentDidMount() {
    this.props.fetchReportDetails();
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.ReportDetailsProps) {
    if (newProps.amsReport.loader !== this.props.amsReport.loader && !newProps.amsReport.loader) {
      const {
        lobList,
        stateList,
        carrierList,
        divisionList,
        executiveList,
        savedFilterList,
        currentDetails,
        organizationList
      } = newProps.amsReport;
      
      this.setState({
        reportDetails: {
          ...this.state.reportDetails,
          dateFilterType: currentDetails.dateFilterType,
        },
        savedFilterList: [...savedFilterList],
        stateFilterOptions: [...stateList],
        stateFilterList: [...stateList],
        stateAppliedFilter: [...stateList],
        lobFilterOptions: [...lobList],
        lobFilterList: [...lobList],
        lobAppliedFilter: [...lobList],
        carrierFilterOptions: [...carrierList],
        carrierFilterList: [...carrierList],
        carrierAppliedFilter: [...carrierList],
        divisionFilterOptions: [...divisionList],
        divisionFilterList: [...divisionList],
        divisionAppliedFilter: [...divisionList],
        executiveFilterOptions: [...executiveList],
        executiveFilterList: [...executiveList],
        executiveAppliedFilter: [...executiveList],
        organizationFilterList: [...organizationList],
        organizationAppliedFilter: [...organizationList],
        organizationFilterOptions: [...organizationList],
      });
    }

    if (
      newProps.amsReport.removeFilterLoader !== this.props.amsReport.removeFilterLoader &&
      !newProps.amsReport.removeFilterLoader
    ) {
      this.setState({
        removedFilter: [],
      });
    }

    if (!isEqual(newProps.amsReport.carrierList, this.state.carrierFilterOptions)) {
      const { carrierList } = newProps.amsReport;
      this.setState({
        carrierFilterOptions: [...carrierList],
        carrierFilterList: [...carrierList],
        carrierAppliedFilter: [...carrierList],
      });
    }

    if (!isEqual(this.props.amsReport.currentDetails, newProps.amsReport.currentDetails)) {
      const {
        appliedDivisions,
        appliedExecutives,
        appliedLobs,
        appliedStates,
        appliedCarriers,
        appliedOrganizations,
        startDate,
        endDate,
        dateFilterType,
        filterName,
        nonReturningQuotes
      } = newProps.amsReport.currentDetails;
      
      this.setState({
        reportDetails: {
          ...this.state.reportDetails,
          filterName,
          startDate,
          endDate,
          dateFilterType,
          nonReturningQuotes: !isEmpty(nonReturningQuotes) ? nonReturningQuotes : NON_RETURNING_QUOTE_OPTION.EXCLUDE
        },
        divisionAppliedFilter: [...appliedDivisions],
        divisionFilterList: [...appliedDivisions],
        executiveAppliedFilter: [...appliedExecutives],
        executiveFilterList: [...appliedExecutives],
        lobFilterList: [...appliedLobs],
        lobAppliedFilter: [...appliedLobs],
        stateFilterList: [...appliedStates],
        stateAppliedFilter: [...appliedStates],
        carrierFilterList: [...appliedCarriers],
        carrierAppliedFilter: [...appliedCarriers],
        organizationFilterList: !isEmpty(appliedOrganizations) ? [...appliedOrganizations] : [],
        organizationAppliedFilter: !isEmpty(appliedOrganizations) ? [...appliedOrganizations] : [],
      });
    }

    if (!isEmpty(newProps.amsReport.reportAPIError) && isEmpty(this.state.reportAPIError)) {
      const { alertTimeoutRef } = this.state;
      this.setState({
        reportAPIError: newProps.amsReport.reportAPIError,
      });
      if (!isEmpty(get(alertTimeoutRef, 'current', null))) {
        clearTimeout(alertTimeoutRef.current as any);
      }
      if (!isEmpty(newProps.amsReport.reportAPIError)) {
        this.setState(
          {
            alertTimeoutRef: {
              current: setTimeout(() => {
                this.setState({ reportAPIError: '' });
              }, REPORT_ERROR_TIMEOUT) as any,
            },
          },
          () => this.props.setReportAPIError('')
        );
      }
    }

    if (!isEqual(newProps.amsReport.savedFilterList, this.state.savedFilterList)) {
      const { savedFilterList } = newProps.amsReport;
      this.setState({
        savedFilterList: [...savedFilterList],
      });
    }

    this.checkSavedFilterSelected(newProps.amsReport);
  }

  checkSavedFilterSelected = (details: any) => {
    const { selectedSavedFilters } = this.state;
    if (details.currentDetails.filterId && isEmpty(selectedSavedFilters)) {
      this.setState({
        selectedSavedFilters: [details.currentDetails.filterId],
      });
    } else if (!isEmpty(selectedSavedFilters) && isEmpty(`${details.currentDetails.filterId}`)) {
      this.setState({
        selectedSavedFilters: [],
      });
    }
  };

  dateRangeChange = (date: any, field: string) => {
    this.setState({
      reportDetails: {
        ...this.state.reportDetails,
        [field]: date,
      },
    });
  };

  fieldChange = ({ target }: any) => {
    if (target.name === 'filterName' && !ALPHA_NUMERIC_SPACE_REGEX.test(target.value)) {
      return false;
    }
    this.setState({
      reportDetails: {
        ...this.state.reportDetails,
        [target.name]: target.value,
      },
    });
    return true;
  };

  onDropdownFilterChange = (list: any, entity: string) => {
    this.setState({
      [`${entity}FilterList`]: [...list],
    });
  };

  onDropdownApplyFilter = (filters: Array<any>, entity: string) => {
    const newFilters = [...filters].sort();
    const currentAppliedFilters = [...this.state[`${entity}AppliedFilter`]].sort();
    if (!isEqual(newFilters, currentAppliedFilters)) {
      this.setState({
        [`${entity}FilterApplied`]: true,
        [`${entity}AppliedFilter`]: [...filters],
      });
    }
    if (entity === 'lob') {
      this.props.lobFilterUpdated(filters);
    }
  };

  onDropdownClearFilter = (entity: string) => {
    if (this.state[`${entity}FilterApplied`]) {
      this.setState({
        [`${entity}FilterApplied`]: false,
        [`${entity}AppliedFilter`]: [],
      });
    }
  };

  onDropdownFilterMenuClose = (entity: string) => {
    let list: any = [];
    if (this.state[`${entity}FilterApplied`]) {
      list = [...this.state[`${entity}AppliedFilter`]];
    }
    this.setState({
      [`${entity}FilterList`]: [...list],
    });
  };

  handleFilterOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({
      reportDetails: {
        ...this.state.reportDetails,
        [name]: value,
      },
    });
  };

  handleDateRangeChange = (range: any) => {
    this.setState({
      reportDetails: {
        ...this.state.reportDetails,
        startDate: range.startDate,
        endDate: range.endDate,
      },
    });
  };

  handleFilterClick = (value: string) => {
    const { selectedSavedFilters } = this.state;
    const currentIndex = selectedSavedFilters.indexOf(value);
    const newChecked = [];
    if (currentIndex === -1) {
      newChecked.push(value);
      this.props.savedFilterSelected(value);
    } else {
      this.props.savedFilterSelected('');
    }
    this.setState({
      selectedSavedFilters: [...newChecked],
    });
  };

  handleRemoveFilter = (value: string) => {
    this.setState({
      selectedDeleteFilter: value,
      deleteDialog: true,
    });
  };

  renderFilterSidebar = () => {
    return (
      <Card className='filter-card-wrapper'>
        <CardContent className='pb-1'>
          <div className='card-title card-filter-title mb-1'>Saved Filters</div>
          {this.getFilterList()}
        </CardContent>
      </Card>
    );
  };

  checkRemovedFilter = (value: any) => this.state.removedFilter.includes(value);

  renderSavedFilterList = () => {
    const { savedFilterList, selectedSavedFilters } = this.state;
    const { removeFilterLoader, submitLoader } = this.props.amsReport;
    const disableFilters = removeFilterLoader || submitLoader;
    const sortedFilterList = sortBy(savedFilterList, (filterItem: any) =>
      String(filterItem.label).toLowerCase()
    );

    return isEmpty(sortedFilterList) ? (
      <ListItem className='filter-item filer-loading'>
        <span className='no-filters-msg'>{NO_SAVED_FILTERS_MESSAGE}</span>
      </ListItem>
    ) : (
      <>
        {sortedFilterList.map(({ label, value, key }: any) => {
          const labelId = `checkbox-list-label-${key}`;
          return (
            <ListItem
              key={key}
              role={undefined}
              dense
              button
              className='filter-item'
              disabled={disableFilters}
              onClick={(event: any) => {
                this.handleFilterClick(value);
                event.preventDefault();
              }}
            >
              <ListItemIcon className='filter-item-checkbox'>
                <Checkbox
                  edge='start'
                  color='primary'
                  checked={selectedSavedFilters.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  disableFocusRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>

              <ListItemText id={labelId} primary={label} />

              {!this.checkRemovedFilter(value) ? (
                <ListItemSecondaryAction
                  onClick={() => {
                    if (!disableFilters) {
                      this.handleRemoveFilter(value);
                    }
                  }}
                >
                  <IconButton
                    disabled={disableFilters}
                    edge='end'
                    aria-label='comments'
                    disableRipple
                    className='trash-btn'
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : (
                this.checkRemovedFilter(value) && (
                  <CircularProgress thickness={4} size={20} className='filter-loader' />
                )
              )}
            </ListItem>
          );
        })}
      </>
    );
  };

  getFilterList = () => {
    const { loader } = this.props.amsReport;
    return (
      <List className='filter-list-wrapper'>
        {loader ? (
          <ListItem className='filter-item filer-loading'>
            <CircularProgress thickness={3} size={30} className='filter-loader' />
          </ListItem>
        ) : (
          this.renderSavedFilterList()
        )}
      </List>
    );
  };

  getFormattedFilterName = (name: string) => name.replace(SPACE_REGEX, ' ').trim();

  validateFields = (isFilterCheck: boolean = false) => {
    const { reportDetails, reportDetailsError, savedFilterList, selectedSavedFilters } = this.state;
    let updatedErrors = { ...reportDetailsErrorInitState };
    if (isEmpty(reportDetails.startDate) || isEmpty(reportDetails.endDate)) {
      updatedErrors = {
        ...updatedErrors,
        rangeError: 'Required',
      };
    }
    if (isFilterCheck) {
      const { filterName } = reportDetails;
      if (isEmpty(filterName.trim())) {
        updatedErrors = {
          ...updatedErrors,
          filterNameError: 'Required',
        };
      } else {
        const existingFilter = find(
          savedFilterList,
          (filterDetails: any) =>
            String(filterDetails.label).toLowerCase() ===
            this.getFormattedFilterName(String(filterName)).toLowerCase()
        );
        if (existingFilter && !selectedSavedFilters.includes(existingFilter.value)) {
          updatedErrors = {
            ...updatedErrors,
            filterNameError: 'Filter name already exists',
          };
        }
      }
    }
    this.setState({
      reportDetailsError: {
        ...reportDetailsError,
        ...updatedErrors,
      },
    });
    if (!every(updatedErrors, isEmpty)) {
      return false;
    }
    return true;
  };

  getMappedSubmitData = () => {
    const {
      reportDetails: { startDate, endDate, dateFilterType, filterName, nonReturningQuotes },
      carrierAppliedFilter,
      stateAppliedFilter,
      lobAppliedFilter,
      divisionAppliedFilter,
      executiveAppliedFilter,
      selectedSavedFilters,
      organizationAppliedFilter
    } = this.state;
    return {
      dateFilterType,
      startDate,
      endDate,
      selectedSavedFilter: selectedSavedFilters,
      states: [...stateAppliedFilter],
      lobs: [...lobAppliedFilter],
      carriers: [...carrierAppliedFilter],
      divisions: [...divisionAppliedFilter],
      executives: [...executiveAppliedFilter],
      filterName: this.getFormattedFilterName(filterName),
      organizations: [...organizationAppliedFilter],
      nonReturningQuotes
    };
  };

  saveFilterAndGenerateReportSubmit = () => {
    if (this.validateFields(true)) {
      this.setState(
        {
          selectedAction: REPORT_ACTIONS.SAVE_AND_GENERATE_REPORT,
        },
        () => {
          this.props.submitReportDetails({
            action: REPORT_ACTIONS.SAVE_AND_GENERATE_REPORT,
            ...this.getMappedSubmitData(),
          });
        }
      );
    }
    this.setState({ reportAPIError: '' });
  };

  generateReportSubmit = () => {
    if (this.validateFields()) {
      this.setState(
        {
          selectedAction: REPORT_ACTIONS.GENERATE_REPORT,
        },
        () => {
          this.props.submitReportDetails({
            action: REPORT_ACTIONS.GENERATE_REPORT,
            ...this.getMappedSubmitData(),
          });
        }
      );
    }
    this.setState({ reportAPIError: '' });
  };

  deleteFilterCloaseHandler = (isDelete: boolean) => {
    const { removedFilter, selectedDeleteFilter } = this.state;
    if (isDelete) {
      this.setState(
        {
          removedFilter: [...removedFilter, selectedDeleteFilter],
        },
        () => {
          this.props.removeSavedFilter(selectedDeleteFilter);
        }
      );
    }
    this.setState({ deleteDialog: false, selectedDeleteFilter: '' });
  };

  getSelectedFilterName = (id: any) =>
    find(this.state.savedFilterList, savedFilterItem => savedFilterItem.filterId === id);

  render() {
    const { classes } = this.props;
    const { loader, submitLoader, removeFilterLoader } = this.props.amsReport;
    const { selectedAction, deleteDialog, selectedDeleteFilter } = this.state;
    const disableButtons = loader || submitLoader || removeFilterLoader;
    return (
      <div className='report-page-wrapper container'>
        <div className='row'>
          <div className='col-lg-9 col-sm-12 mt-5'>{template(this)}</div>
          <div className='col-lg-3 col-sm-12 mt-5'>{this.renderFilterSidebar()}</div>
        </div>
        <div className='row global-error-row'>
          {!isEmpty(this.state.reportAPIError) && (
            <div className='col-12 global-error'>{this.state.reportAPIError}</div>
          )}
        </div>
        <div className='row'>
          <div className='col-lg-9 col-md-12 col-sm-12'>
            <div className='row'>
              <div className='col-lg-12 col-sm-12'>
                <Button
                  variant='outlined'
                  className='report-submit-btn'
                  onClick={this.generateReportSubmit}
                  disabled={disableButtons}
                >
                  Generate Report{' '}
                  {submitLoader && selectedAction === REPORT_ACTIONS.GENERATE_REPORT && (
                    <SpinnerLoader styleData={classes.submitSpinner} />
                  )}
                </Button>
                <Button
                  variant='outlined'
                  className='save-submit-btn'
                  onClick={this.saveFilterAndGenerateReportSubmit}
                  disabled={disableButtons}
                >
                  Save Filter and Generate Report{' '}
                  {submitLoader && selectedAction === REPORT_ACTIONS.SAVE_AND_GENERATE_REPORT && (
                    <SpinnerLoader styleData={classes.submitSpinner} />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {deleteDialog && (
          <ConfirmationModal
            open={deleteDialog}
            closehandler={this.deleteFilterCloaseHandler}
            heading={FILTER_REMOVE_MODAL_HEADING}
            message={replaceWithPlaceholder(
              FILTER_REMOVE_MODAL_MESSAGE,
              this.getSelectedFilterName(selectedDeleteFilter).filterName
            )}
            contentValue=''
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ amsReport }: AppComponents.ReportDetailsStore) => {
  return { amsReport };
};

const mapDispatchToProps = (dispatch: any): AppComponents.ReportDetailsDispatch => {
  return bindActionCreators(
    {
      fetchReportDetails,
      removeSavedFilter,
      lobFilterUpdated,
      savedFilterSelected,
      setReportAPIError,
      submitReportDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(Report));
