import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HomeQuoteDetailsState } from './types/home-quote';
import { QuoteRequestStatus } from '../../../graphql/generated';

const initialState: HomeQuoteDetailsState = {
  isNoQuotesAvailable: false,
  isViewingQuote: false,
  isPolling: false,
  quotes: [],
  pendingQuotes: [],
  upcomingCarriers: [],
  quoteRequestStatus: QuoteRequestStatus.InProgress,
  continuePolling: false,
  isRefetchHomePollQuote: false,
};

export const homeQuoteSlice = createSlice({
  initialState,
  name: 'homeQuoteDetails',
  reducers: {
    setHomeQuoteDetails: (state, action: PayloadAction<Partial<HomeQuoteDetailsState>>) => {
      Object.assign(state, action.payload);
    },
    setQuoteRequestStatus(state, action: PayloadAction<QuoteRequestStatus>) {
      state.quoteRequestStatus = action.payload;
    },
    resetHomeQuoteDetails: state => {
      return initialState;
    },
  },
});

export const { setHomeQuoteDetails, resetHomeQuoteDetails, setQuoteRequestStatus } =
  homeQuoteSlice.actions;
