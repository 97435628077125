export const ME_AGE_LICENSED = ['16', '17', '18', '19', '20', '21 || 21+'];
export const ME_STATE_COVERAGES = {
  BI: [
    '50/100 || 50/100 (State Minimum) || false',
    '100/100 || 100/100 || false',
    '100/300 || 100/300 || true',
    '250/500 || 250/500 || false',
    '300/300 || 300/300 || false',
    '500/500 || 500/500 || false',
    '1000/1000 || 1000/1000 || false',
    '55 CSL || 55 CSL || false',
    '100 CSL || 100 CSL || false',
    '300 CSL || 300 CSL || false',
    '500 CSL || 500 CSL || false',
  ],
  UM: [
    '50/100 || 50/100 (State Minimum) || false',
    '100/100 || 100/100 || false',
    '100/300 || 100/300 || true',
    '250/500 || 250/500 || false',
    '300/300 || 300/300 || false',
    '500/500 || 500/500 || false',
    '1000/1000 || 1000/1000 || false',
    '55 CSL || 55 CSL || false',
    '100 CSL || 100 CSL || false',
    '300 CSL || 300 CSL || false',
    '500 CSL || 500 CSL || false',
  ],
  UIM: [
    '50/100 || 50/100 (State Minimum) || false',
    '100/100 || 100/100 || false',
    '100/300 || 100/300 || true',
    '250/500 || 250/500 || false',
    '300/300 || 300/300 || false',
    '500/500 || 500/500 || false',
    '1000/1000 || 1000/1000 || false',
    '55 CSL || 55 CSL || false',
    '100 CSL || 100 CSL || false',
    '300 CSL || 300 CSL || false',
    '500 CSL || 500 CSL || false',
  ],
  PD: [
    '25000 || $25,000 (State Minimum) || false',
    '50000 || $50,000 || false',
    '100000 || $100,000 || true',
    '250000 || $250,000 || false',
    '500000 || $500,000  || false',
  ],
  MP: [
    '2000 || $2,000 (State Minimum) || true',
    '2500 || $2,500 || false',
    '5000 || $5,000 || false',
    '10000 || $10,000 || false',
    '15000 || $15,000 || false',
    '25000 || $25,000 || false',
    '50000 || $50,000 || false',
    '100000 || $100,000 || false',
  ],
  OtherCollisionDeductible: [
    'No Coverage || No Coverage || false',
    '0 || 0 || false',
    '50 || 50 || false',
    '100 || 100 || false',
    '200 || 200 || false',
    '250 || 250 || false',
    '500 || 500 || false',
    '1000 || 1000 || true',
    '2000 || 2000 || false',
    '2500 || 2500 || false',
  ],
  CollisionDeductible: [
    'No Coverage || No Coverage || false',
    '0 || 0 || false',
    '50 || 50 || false',
    '100 || 100 || false',
    '200 || 200 || false',
    '250 || 250 || false',
    '500 || 500 || false',
    '1000 || 1000 || true',
    '2000 || 2000 || false',
    '2500 || 2500 || false',
  ],
  TowingDeductible: [
    'No Coverage || No Coverage || true',
    '25 || 25 || false',
    '50 || 50 || false',
    '75 || 75 || false',
    '100 || 100 || false',
    '200 || 200 || false',
    'Unlimited || Unlimited || false',
  ],
  RentalDeductible: [
    'No Coverage || No Coverage || true',
    '20/600 || 20/600 || false',
    '30/900 || 30/900 || false',
    '40/1200 || 40/1200 || false',
    '50/1500 || 50/1500 || false',
  ],
  PriorLiabilityLimit: [
    '50/100 || 50/100 (State Minimum) || false',
    '100/100 || 100/100 || false',
    '100/300 || 100/300 || true',
    '250/500 || 250/500 || false',
    '300/300 || 300/300 || false',
    '500/500 || 500/500 || false',
    '1000/1000 || 1000/1000 || false',
    '55CSL || 55 CSL || false',
    '100CSL || 100 CSL || false',
    '300CSL || 300 CSL || false',
    '500CSL || 500 CSL || false',
  ],
};
export const ME_PREFERRED_DEDUCTIBLE = [
  '100 || 100 || false',
  '250 || 250 || false',
  '500 || 500 || false',
  '750 || 750 || false',
  '1000 || 1000 || true',
  '2000 || 2000 || false',
  '2500 || 2500 || false',
  '3000 || 3000 || false',
  '4000 || 4000 || false',
  '5000 || 5000 || false',
];
