import React, { useEffect, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  search: {
    position: 'relative',
    border: '1px solid #D9D9D9',
    marginLeft: '0px !important',
    borderRadius: '4px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    paddingRight: '6px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `0.7em`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const InsuredDetailsSearch = (props: AppComponents.InsuredDetailsSearchProps) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');

  const searchSubmit = (event: any) => {
    if (event) {
      event.preventDefault();
    }
    props.handleSearch(searchValue.trim());
  };

  useEffect(() => {
    setSearchValue(props.searchText);
  }, [props.searchText]);

  return (
    <form className={`searchDiv`} noValidate autoComplete='off' onSubmit={searchSubmit}>
      <div className={classes.search}>
        <InputBase
          value={searchValue}
          placeholder='Search by name, phone, email'
          disabled={props.disableSearch}
          inputProps={{
            id: 'insured-search',
            label: 'Search',
            type: 'search',
            maxLength: 100,
          }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          endAdornment={
            <Tooltip title='Search' placement='top' aria-label='search' arrow={true}>
              <InputAdornment
                position='end'
                className='search-submit-btn'
                onClick={event => {
                  setSearchValue((document as any).getElementById('insured-search').value);
                  searchSubmit(event);
                }}
              >
                <SearchIcon className='SearchIconclr' />
              </InputAdornment>
            </Tooltip>
          }
          onChange={event => setSearchValue(event.target.value)}
          onBlur={event => {
            if (searchValue.trim().length === 0) {
              searchSubmit(event);
            }
          }}
        />
      </div>
    </form>
  );
};

InsuredDetailsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  disableSearch: PropTypes.bool,
};

export default InsuredDetailsSearch;
