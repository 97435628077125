import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, startCase } from 'lodash';
import {
  Backdrop,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import GraphLoader from '../graph-loder';
import { fetchBoundedPolicyDetails, storeBoundedPolicyDetails } from '../../redux/actions';
import {
  downloadFileUrl,
  formatNumberToCurrencyWithDecimal,
  getAmsBoundedDetailSList,
  getLobHerdId,
} from '../../../utils';
import {
  ADDRESS,
  AMS_FILE_TYPE,
  AMS_FORM_FIELD,
  BROKER,
  CARRIER,
  CARRIER_WRITING_COMPANY,
  DEPARTMENT,
  DIVISION,
  EARTH_QUAKE_LOB,
  EFFECTIVE_DATE,
  EMPLOYEE,
  EOI_DOCUMENT,
  EXECUTIVE,
  FETCHING_POLICY_DETAILS,
  GROUP,
  INSURANCE_PRODUCTS,
  LOB_KEY,
  LOB_PRODUCTS_MAPPING,
  NAME,
  PHONE_NUMBER_LABEL,
  POLICY_NUMBER,
  PREMIUM,
  REPRESENTATIVE,
  SM,
  TERM,
  VIEW_AMS_POLICY_HEADER,
} from '../../../constants';
import './view-bound-quote.scss';
import config from '../../../config/config';
import { NON_ADMITTED_AMS_CONSENT } from '../../../constants/non-admitted';
const { DUE_DILIGENCE_FORM_DOCUMENT, SL2_FORM_DOCUMENT } = AMS_FORM_FIELD;

const GetFileFieldDetails = (props: { label: string; fileName: string; fileUrl: string }) => {
  const { fileName, fileUrl, label } = props;
  return (
    <Grid item xs={12} md={6} lg={6} sm={6} className='eoi-document-file'>
      <InputLabel className='document-label' htmlFor={label}>
        {label}
      </InputLabel>
      <Input
        id={label}
        onClick={() => downloadFileUrl(fileUrl)}
        startAdornment={
          <InputAdornment position='start'>
            <GetAppIcon className='download-icon' />
            <span className='cursor-pointer' title={!!fileName ? fileName : ''}>
              {!!fileName ? fileName : ''}
            </span>
          </InputAdornment>
        }
      />
    </Grid>
  );
};

export class ViewAmsBoundQuote extends Component<
  AppComponents.ViewAmsBoundDataProps,
  AppComponents.ViewAmsBoundDataState
> {
  state: AppComponents.ViewAmsBoundDataState = {
    boundedPolicyData: null,
  };

  componentDidMount = () => {
    const { lob, boundedPolicyDetails } = this.props;
    let selectedLob = lob?.toLowerCase();
    if (!!boundedPolicyDetails && !isEmpty(boundedPolicyDetails[selectedLob])) {
      let boundedData = boundedPolicyDetails[selectedLob];
      let lineOfBusiness =
        (!!boundedData?.lob && boundedData?.lob?.toLowerCase() === INSURANCE_PRODUCTS[3]) ||
        (!!selectedLob && selectedLob === INSURANCE_PRODUCTS[3])
          ? EARTH_QUAKE_LOB
          : boundedData?.lob?.toLowerCase() ?? selectedLob;
      let payload = {
        herdId: boundedData?.herdId || getLobHerdId(lineOfBusiness || '') || '',
        lob: lineOfBusiness,
      };
      this.props.fetchBoundedPolicyDetails(payload);
    }
  };

  componentWillReceiveProps = (newProps: any) => {
    this.setState({
      boundedPolicyData: !!newProps?.boundedPolicyData ? newProps?.boundedPolicyData : null,
    });
  };

  onCloseModal = () => {
    const { boundedPolicyData } = this.props;
    this.props.onClose(false);
    !!boundedPolicyData && this.props.storeBoundedPolicyDetails('');
  };

  render() {
    const { open, viewBoundedPolicyLoader } = this.props;
    const { boundedPolicyData } = this.state;
    const {
      address,
      brokerName,
      carrierName,
      carrierUnderwriterName,
      departmentName,
      divisionName,
      effectiveDate,
      employeeName,
      eoiFileName,
      eoiFileUrl,
      executiveName,
      groupName,
      lob,
      name,
      phoneNumber,
      policyNumber,
      premium,
      representativeName,
      term,
      isCarrierRiskConsented
    } = !!boundedPolicyData && boundedPolicyData;
    let viewBoundDetailsList: Array<any> = getAmsBoundedDetailSList(boundedPolicyData) || [];
    const nonAdmittedConcent: string =
      lob?.toLowerCase() === LOB_PRODUCTS_MAPPING.home
        ? NON_ADMITTED_AMS_CONSENT.HOME
        : NON_ADMITTED_AMS_CONSENT.FLOOD;
    return (
      <div className='text-center'>
        <Dialog
          maxWidth={SM}
          className='view-bound-modal'
          onClose={this.onCloseModal}
          aria-labelledby='customized-dialog-title'
          open={open}
        >
          {!!viewBoundedPolicyLoader && (
            <Backdrop className='graph-loader' open={true}>
              <GraphLoader color='inherit' content={FETCHING_POLICY_DETAILS} />
            </Backdrop>
          )}
          <DialogTitle id='form-dialog-title'>
            <Typography className='view-ams-policy-heading'>
              {VIEW_AMS_POLICY_HEADER}
              <IconButton aria-label='close' className='closeButton' onClick={this.onCloseModal}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Typography>
            <Divider />
          </DialogTitle>
          <DialogContent className='view-bound-model-content'>
            <div className='row mb-3'>
              <div className='col-lg-6 mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={startCase(NAME)}
                  value={!!name ? name : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={PHONE_NUMBER_LABEL}
                  value={!!phoneNumber ? phoneNumber : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-12'>
                <TextField
                  fullWidth
                  label={ADDRESS}
                  value={!!address ? address : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-6  mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={LOB_KEY}
                  value={
                    !!lob
                      ? lob?.toLowerCase() === EARTH_QUAKE_LOB
                        ? INSURANCE_PRODUCTS[3]?.toUpperCase()
                        : lob?.toUpperCase()
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={EFFECTIVE_DATE}
                  value={!!effectiveDate ? effectiveDate : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-6  mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={CARRIER}
                  value={!!carrierName ? carrierName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={POLICY_NUMBER}
                  value={!!policyNumber ? policyNumber : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-3 mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={PREMIUM}
                  value={!!premium ? formatNumberToCurrencyWithDecimal(premium) : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-3 mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={TERM}
                  value={!!term ? term : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={CARRIER_WRITING_COMPANY}
                  value={!!carrierUnderwriterName ? carrierUnderwriterName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-6 mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={DIVISION}
                  value={!!divisionName ? divisionName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={EXECUTIVE}
                  value={!!executiveName ? executiveName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-6 mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={REPRESENTATIVE}
                  value={!!representativeName ? representativeName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={GROUP}
                  value={!!groupName ? groupName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='row mb-3'>
              <div className='col-lg-6 mob-margin-bottom-20'>
                <TextField
                  fullWidth
                  label={DEPARTMENT}
                  value={!!departmentName ? departmentName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className='col-lg-6'>
                <TextField
                  fullWidth
                  label={EMPLOYEE}
                  value={!!employeeName ? employeeName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
            <div className='mb-3'>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} sm={6}>
                  <TextField fullWidth label={BROKER} value={!!brokerName ? brokerName : ''} />
                </Grid>
                {!!eoiFileUrl && (
                  <GetFileFieldDetails
                    label={EOI_DOCUMENT}
                    fileName={eoiFileName}
                    fileUrl={eoiFileUrl}
                  />
                )}
                {!isEmpty(viewBoundDetailsList) &&
                  viewBoundDetailsList.map((item: any, itemIndex: number) => {
                    return (
                      <Grid item xs={12} md={6} lg={6} sm={6}>
                        <TextField
                          fullWidth
                          label={item.label}
                          value={item.value}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    );
                  })}
                {boundedPolicyData?.amsFileName &&
                  boundedPolicyData?.amsFileUrl &&
                  boundedPolicyData?.amsFileType && (
                    <GetFileFieldDetails
                      label={
                        boundedPolicyData?.amsFileType === AMS_FILE_TYPE.SL2
                          ? SL2_FORM_DOCUMENT.LABEL
                          : DUE_DILIGENCE_FORM_DOCUMENT.LABEL
                      }
                      fileName={boundedPolicyData?.amsFileName}
                      fileUrl={boundedPolicyData?.amsFileUrl}
                    />
                  )}
              </Grid>
            </div>
            {!!isCarrierRiskConsented && !!config?.enableAdmittedOrNonAdmittedCarrier && (
              <div className='mb-3'>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box className='d-flex align-items-start'>
                      <Checkbox className='p-0 mr-1' checked={true} disabled />
                      <Typography className='carrier-risk-consent-label'>
                        {nonAdmittedConcent}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ common, home }: AppComponents.ViewAmsBoundDataStore) => {
  const { boundedPolicyData, boundedPolicyDetails, viewBoundedPolicyLoader } = common;
  return { boundedPolicyData, boundedPolicyDetails, home, viewBoundedPolicyLoader };
};

const mapDispatchToProps = (dispatch: any): AppComponents.ViewAmsBoundDataDispatch => {
  return bindActionCreators({ fetchBoundedPolicyDetails, storeBoundedPolicyDetails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewAmsBoundQuote);
