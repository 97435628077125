import { FormControl, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { AMS_FORM_FIELD, ASTERISK } from '../../../constants';
const { EOI_DOCUMENT } = AMS_FORM_FIELD;

export const CommonUploadFile = (props: AppComponents.CommonUploadFileTypes) => {
  const { label, name, fileName, error, isLoading, onChange, onClear, className } = props;
  return (
    <div className={`col-md-6 Res-display resp-mb-20 ${label !== EOI_DOCUMENT.LABEL ? 'mt-4' : ''}`}>
      <FormControl fullWidth error={!!error} disabled={isLoading}>
        <InputLabel shrink htmlFor={name}>
          {label}
          <span className='star-error'>{ASTERISK}</span>
        </InputLabel>
      </FormControl>
      {!fileName && (
        <div className='d-flex justify-content-center'>
          <PublishIcon
            className={`publish-icon ${isLoading ? 'cursor-default' : 'cursor-pointer'}`}
            onClick={() => (!isLoading ? onChange(true, name) : '')}
          />
        </div>
      )}
      {!!fileName && (
        <div className='d-flex'>
          <Typography className='eoi-file-name-label'>{fileName}</Typography>
          <span className='color-red'>
            <CloseIcon className='ml-2' onClick={() => onClear(name)} />
          </span>
        </div>
      )}
      <FormHelperText className={className}>{error}</FormHelperText>
    </div>
  );
};
