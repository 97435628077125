/* istanbul ignore file */
import { v4 } from 'uuid';

import * as types from '../constants';

interface CoverageState {
  cuid: string;
  comprehensive: string;
  collision: string;
  towing_labor: string;
  ext_trans_expense: string;
  motorist: string;
  injury_limits: string;
  property_damage: string;
  medical_payments: string;
}

const INIT_COVERAGE_STATE: CoverageState = {
  cuid: v4(),
  comprehensive: '',
  collision: '',
  towing_labor: '',
  ext_trans_expense: '',
  motorist: '',
  injury_limits: '',
  property_damage: '',
  medical_payments: '',
};

const INIT_STATE = { ...INIT_COVERAGE_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
      };
    }

    case types.ADD_COVERAGE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.CLEAR_COVERAGE: {
      return {
        ...INIT_STATE,
        cuid: v4(),
      };
    }

    case types.SET_COVERAGE: {
      return {
        ...state,
        ...(action.payload
          ? action.payload
          : {
              ...INIT_STATE,
              cuid: v4(),
            }),
      };
    }

    default:
      return state;
  }
};
