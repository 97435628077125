/* istanbul ignore file */
import React from 'react';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import moment from 'moment';

import { dateFormat } from '../../../utils';

export const isNumberCheck = /^-?[\d.]+(?:e-?\d+)?$/;

// Checks for valid VIN number provided
export const validateVin = (vin: any) => {
  const regex = new RegExp('[a-hj-npr-zA-HJ-NPR-Z0-9]{13}[0-9]{4}');
  return vin.match(regex);
};

export const validateIncidentDate = (IncidentDate: any) => {
  if (moment(IncidentDate).isAfter(moment(new Date()).format(dateFormat))) {
    return 'Must be past date';
  } else if (!moment(IncidentDate, dateFormat).isValid()) {
    return 'Invalid';
  }
  return '';
};

export const getIncidentFieldError = (state: any, field: string) =>
  !isEmpty(get(state, field, '')) ? '' : 'Required';

// Map coverage data
export const coverageDataMapper = (list: Array<any>) =>
  map(list, value => {
    const coverageValue = value.split('||');
    const coverageLength = coverageValue.length;
    return {
      label: coverageLength >= 2 ? coverageValue[1].trim() : value.trim(),
      value: coverageLength >= 1 ? coverageValue[0].trim() : value.trim(),
      default: coverageLength === 3 ? coverageValue[2].trim() : 'false',
    };
  });

export const getCoverageDefaultValue = (currentValue: any, list: Array<any>) => {
  currentValue = currentValue?.toString() || '';
  const previousValue = filter(list, item => {
    return item?.value?.toString() === currentValue;
  });
  if (isEmpty(currentValue) || isEmpty(previousValue)) {
    map(list, (value, index) => {
      if (value.default === 'true') {
        currentValue = list[index].value;
      }
    });
    return currentValue;
  } else {
    return currentValue;
  }
};

export const setOptionDetails = (optionDetails: any) => {
  return map(optionDetails, (data, index) => (
    <option key={`${index}${data.value}`} value={data.value}>
      {data.label}
    </option>
  ));
};
