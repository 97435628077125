import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { APPLICANT_LABEL_NAME, RATES_LABEL_NAME } from '../../../constants';
import { ReduxRootState } from '../../../pages/redux/store';
import '../../../fonts/style.css';

const ICONS_LIST: any = {
  User: 'user',
  Flood: 'flood',
  Vehicle: 'Car',
  Driver: 'driver',
  Policy: 'policy',
  Pet: 'Pet',
  Life: 'Life',
  Rates: 'quotes',
  Umbrella: 'Umbrella',
  Property: 'Property',
};

interface TabIcon {
  label: string;
  icon: string;
  index: number;
  value: number;
  enableStep: boolean;
}

const CustomIcon: React.FC<TabIcon> = ({ label, icon, index, value, enableStep }) => {
  const iconName = index <= value ? `${ICONS_LIST[icon]}` : ICONS_LIST[icon];
  const showStepStyle = index === value || !enableStep ? 'step active' : 'step';
  const showLine =
    label === APPLICANT_LABEL_NAME
      ? 'first-child'
      : label === RATES_LABEL_NAME
      ? 'last-child'
      : 'devider';
  return (
    <div className='steper-list-wrap'>
      <div className='list-div'>
        <div className={showStepStyle}>
          <span className={`icon-${iconName}`}></span>
        </div>
        <div className='step-text d-flex justify-content-center'>
          <Typography
            className={`${showStepStyle !== 'step' ? 'steps-label' : 'steps-label-deactive'}`}
          >
            {label}
          </Typography>
        </div>
      </div>
      <span className={showLine} />
    </div>
  );
};

const mapStateToProps = ({ auth, common }: ReduxRootState): AppComponents.AppStoreProps => ({
  auth,
  common,
});

export default connect(mapStateToProps, {})(CustomIcon);
