import React from 'react';
import { Card, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { formatNumberToCurrency } from '../../../utils';
import { GOLD, NONE, PET_PLAN_CONSTANTS as CONSTS, PLATINUM, SILVER, SM } from '../../../constants';
import './premium-plans-modal.scss';

const PlanCard = ({
  plan,
  type,
}: {
  plan?: AppComponents.IPetPlanDetails;
  type: AppComponents.TPetPlanTypes;
}) => {
  const planConfig: Record<AppComponents.TPetPlanTypes, any> = {
    gold: {
      borderClass: 'gold-border',
      head: CONSTS.GOLD_PLAN,
    },
    platinum: {
      borderClass: 'platinum-border',
      head: CONSTS.PLATINUM_PLAN,
    },
    silver: {
      borderClass: 'silver-border',
      head: CONSTS.SILVER_PLAN,
    },
  };

  const getEndorsements = () => {
    const { breeding_endorsement, boarding_advertising, exam_fee, holistic_alternative } =
      plan || {};

    if (!(exam_fee || holistic_alternative || boarding_advertising || breeding_endorsement)) {
      return (
        <ul>
          <li>{NONE}</li>
        </ul>
      );
    }
    return (
      <ul>
        {exam_fee && <li>{CONSTS.EXAM_FEES}</li>}
        {boarding_advertising && (
          <>
            <li>{CONSTS.BOARDING}</li>
            <li>{CONSTS.PET_ADVERTISING}</li>
          </>
        )}
        {breeding_endorsement && <li>{CONSTS.TRIP_CANCELLATION}</li>}
        {holistic_alternative && <li>{CONSTS.HOLISTIC_TREATMENTS}</li>}
      </ul>
    );
  };

  return (
    <Grid item xs={12} lg={4}>
      <Card className={`plan-card text-center ${planConfig[type].borderClass}`}>
        <Typography className='plan-label text-center'>{planConfig[type].head}</Typography>
        <div className='plan-list'>
          <span className='plan-price-label'>{CONSTS.POLICY_LIMIT}</span>
          <span className='plan-price'>{formatNumberToCurrency(plan?.policy_limit)}</span>
        </div>
        <div className='plan-list'>
          <span className='plan-price-label'>{CONSTS.DEDUCTIBLE}</span>
          <span className='plan-price'>{formatNumberToCurrency(plan?.deductible)}</span>
        </div>
        <div className='plan-list'>
          <span className='plan-price-label'>{CONSTS.CO_INSURANCE}</span>
          <span className='plan-price'>{`${plan?.coinsurance}%`}</span>
        </div>
        <div className='plan-list'>
          <div className='plan-price-label'>{CONSTS.ENDORSEMENTS}</div>
          <div className='plan-price'>{getEndorsements()}</div>
        </div>
      </Card>
    </Grid>
  );
};

const PetPlanModal = ({
  premiumPlansList,
  showModal,
  closeModal,
}: AppComponents.IPetPlanModalProps) => {
  return (
    <div>
      <Dialog
        aria-labelledby='org-dialog-title'
        className='plan-modal'
        fullWidth
        maxWidth={SM}
        onClose={closeModal}
        open={showModal}
      >
        <DialogTitle className='d-flex justify-content-center pb-0'>
          <h1 className='plans-heading'>{CONSTS.AVAILABLE_PLANS}</h1>
        </DialogTitle>

        <DialogContent className='plan-modal-content'>
          <Grid container spacing={2}>
            <PlanCard plan={premiumPlansList?.silver} type={SILVER} />
            <PlanCard plan={premiumPlansList?.gold} type={GOLD} />
            <PlanCard plan={premiumPlansList?.platinum} type={PLATINUM} />
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PetPlanModal;
