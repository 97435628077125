export const ERRORS = {
  SOMETHING_WENT_WRONG_PLS_TRY_AGAIN: 'Something went wrong, please try again.',
  CREATE_PROPERTY_INFO:
    'Something went wrong while retrieving property info. Please try again later.',
  CREATE_HOME_QUOTE_REQUEST: 'Something went wrong while creating home quote. Please try again later',
  UPDATE_HOME_QUOTE_REQUEST: 'Something went wrong while updating home quote. Please try again later',
  UPDATE_PROPERTY_INFO: 'Something went wrong while updating property info. Please try again later',
};
export const UPCOMING_CARRIER_TEXT = {
  INACTIVE:
    'Carrier quoting disabled upon request. Please reach out to Carrier BD or Gator Support',
  NOT_INTEGRATED: 'Carrier Appointment available for the product and the state.',
  BTC: 'NAVIGATE TO CARRIER PORTAL',
};
