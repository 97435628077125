import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import './auto-prefill-modal.scss';
import Button from '@material-ui/core/Button';

const VersicModal = (props: AppComponents.VeriskModalProps) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    props.closehandler();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const content = () => {
    const { vehicle, driversQty, vehiclesQty, driver } = props;
    let paragraph = 'Prefill data has been retrieved for ';
    if (vehicle && vehiclesQty > 0) {
      paragraph += `${vehiclesQty} ${vehiclesQty > 1 ? 'vehicles' : 'vehicle'}`;
    }

    if (vehicle && vehiclesQty > 0 && driver && driversQty > 0) {
      paragraph += ' and ';
    }
    if (driver && driversQty > 0) {
      paragraph += `${driversQty} ${driversQty > 1 ? 'drivers' : 'driver'}`;
    }
    return <Typography className='text-left mt-3'>{paragraph}</Typography>;
  };

  return (
    <div>
      <Dialog
        className='auto-prefill-modal'
        classes={{
          root: 'auto-dialog-container',
        }}
        open={open}
        aria-labelledby='confirmation-dialog-title'
        onClose={() => {
          handleClose();
        }}
      >
        <DialogContent className='auto-prefill-content'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <div className='circle-div'>
                <span className='icon-done'></span>
              </div>
            </div>
            <div className='col-lg-12 '>{content()}</div>
            <div className='col-lg-12 d-flex justify-content-center'>
              <Typography className='mt-4 text-2'>
                Please verify the information for accuracy and enter any other required values.
              </Typography>
            </div>
            <div className='col-lg-12 d-flex justify-content-center mt-3'>
              <Button className='btn-yes mt-3' onClick={handleClose}>
                Ok
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VersicModal;
