import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { fetchAutolineDetails, vehicleTypeSelected, setHasFormChanged, setVehicleDetails } from '../../redux/actions';
import VINInput from '../vin-input';
import { deleteDiffKeys, allowEditingQuote } from '../../../utils';

const styles = (theme: Theme) => ({
  selectionlabel: {
    paddingTop: '15px',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '500',
    opacity: 0.8,
  },
  ListItemdiv: {
    minHeight: '232px',
    width: '100%',
    backgroundColor: '#fff',
    position: 'relative',
    overflow: 'auto',
    maxHeight: '232px',
    border: '1px solid #CDDBEB',
    borderRadius: '6px',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  vehicleList: {
    backgroundColor: 'inherit',
    padding: 0,
  },
});

const ListSpinner = (props: any) => {
  return (
    <div className='list-spinner'>
      <div className='bounce1'></div>
      <div className='bounce2'></div>
      <div className='bounce3'></div>
    </div>
  );
};

class VehicleSelector extends React.PureComponent<
  AppComponents.VehicleSelectorProps & WithStyles,
  AppComponents.VehicleSelectorState
> {
  state: AppComponents.VehicleSelectorState = {
    year: '',
    make: '',
    model: '',
    yearList: [],
    makeList: [],
    modelList: [],
  };
  unlisten: any;

  componentDidMount() {
    const { vehicle } = this.props;
    this.setState({
      ...this.state,
      year: get(vehicle, 'vehicleSelectorDetails.year', ''),
      make: get(vehicle, 'vehicleSelectorDetails.make', ''),
      model: get(vehicle, 'vehicleSelectorDetails.model', ''),
      yearList: get(vehicle, 'vehicleSelectorDetails.yearList', []),
      makeList: get(vehicle, 'vehicleSelectorDetails.makeList', []),
      modelList: get(vehicle, 'vehicleSelectorDetails.modelList', []),
    });
  }

  UNSAFE_componentWillMount() {
    this.props.fetchAutolineDetails();
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.VehicleSelectorProps) {
    const { vehicle } = newProps;
    const { vinInput, vinLoader } = vehicle;
    this.setState(
      {
        ...this.state,
        year: get(vehicle, 'vehicleSelectorDetails.year', ''),
        make: get(vehicle, 'vehicleSelectorDetails.make', ''),
        model: get(vehicle, 'vehicleSelectorDetails.model', ''),
        yearList: get(vehicle, 'vehicleSelectorDetails.yearList', []),
        makeList: get(vehicle, 'vehicleSelectorDetails.makeList', []),
        modelList: get(vehicle, 'vehicleSelectorDetails.modelList', []),
      },
      () => {
        const copiedState = {
          ...deleteDiffKeys(this.state, vehicle),
        };
        const copiedVehicle = { ...deleteDiffKeys(vehicle, this.state) };

        if (!isEqual(copiedState, copiedVehicle) && !vinInput && !vinLoader) {
          this.props.setHasFormChanged(true);
        }
      }
    );
  }

  listItemSelected = (type: string, value: string) => {
    if (type === 'model') {
      this.props.setVehicleDetails({
        isVinCleared: false,
        how_long: '',
        approx_miles: '',
        vin: '',
        vinInput: false,
      });
    }
    this.props.vehicleTypeSelected({ type, value });
  };

  getEmptyModelContent = (selectorLoader: boolean, classes: any, make: any) => {
    return !selectorLoader ? (
      <div className='selectionlabel'>Select Model</div>
    ) : (
      <React.Fragment>
        {!isEmpty(make) ? <ListSpinner /> : <div className='selectionlabel'>Select Model</div>}
      </React.Fragment>
    );
  };

  render() {
    const {
      vehicle: { vehicleSelectorDetails, vinInput, vinLoader, isVinCleared },
      classes,
    } = this.props;
    const { year, make, model, yearList, makeList, modelList } = this.state;
    const disableAutoEditing = allowEditingQuote();
    return (
      <React.Fragment>
        <div className='row vin-wrapper d-flex justify-content-center position-relative'>
          <VINInput />
        </div>
        <div className='row m-0 d-flex justify-content-center'>
          <div className='col-md-9 position-relative margin-top-bottom'>
            <Divider />
            <Typography variant='h6' className='text-center devider-label position-absolute'>
              Or
            </Typography>
          </div>
        </div>
        <div className='row vehicle-selector-wrapper d-flex justify-content-center'>
          <div className='col-md-3 '>
            <List className={classes.ListItemdiv} subheader={<li />}>
              {!isEmpty(yearList) ? (
                yearList.map(sectionId => (
                  <li key={`section-${sectionId.value}`} className={classes.listSection}>
                    <ul className={classes.vehicleList}>
                      <ListItem
                        button
                        disabled={(vinInput && !isVinCleared) || vinLoader || disableAutoEditing?.auto}
                        autoFocus={String(year) === String(sectionId.value)}
                        key={`item-${sectionId.value}`}
                        className='vehicleListItem'
                        selected={String(year) === String(sectionId.value)}
                        onClick={() => {
                          String(year) !== String(sectionId.value) &&
                            this.listItemSelected('year', sectionId.value);
                        }}
                      >
                        <ListItemText primary={` ${sectionId.value}`} />
                      </ListItem>
                    </ul>
                  </li>
                ))
              ) : (
                <ListSpinner />
              )}
            </List>
          </div>

          <div className='col-md-3'>
            <List className={classes.ListItemdiv} subheader={<li />}>
              {!isEmpty(makeList) ? (
                makeList.map(sectionId => (
                  <li key={`section-${sectionId.value}`} className={classes.listSection}>
                    <ul className={classes.vehicleList}>
                      <ListItem
                        button
                        autoFocus={make === sectionId.value}
                        disabled={year === '' || (vinInput && !isVinCleared) || vinLoader || disableAutoEditing?.auto}
                        key={`item-${sectionId.value}`}
                        className='vehicleListItem'
                        selected={make === sectionId.value}
                        onClick={() => {
                          make !== sectionId.value &&
                            this.listItemSelected('make', sectionId.value);
                        }}
                      >
                        <ListItemText primary={` ${sectionId.value}`} />
                      </ListItem>
                    </ul>
                  </li>
                ))
              ) : !vehicleSelectorDetails.vehicleSelectorLoader ? (
                <div className='selectionlabel'>Select Make</div>
              ) : (
                <ListSpinner />
              )}
            </List>
          </div>

          <div className='col-md-3'>
            <List className={classes.ListItemdiv} subheader={<li />}>
              {!isEmpty(modelList) && !isEmpty(make)
                ? modelList.map(sectionId => (
                    <li key={`section-${sectionId.value}`} className={classes.listSection}>
                      <ul className={classes.vehicleList}>
                        <ListItem
                          button
                          autoFocus={model === sectionId.value}
                          disabled={make === '' || (vinInput && !isVinCleared) || vinLoader || disableAutoEditing?.auto}
                          key={`item-${sectionId.value}`}
                          className='vehicleListItem'
                          selected={model === sectionId.value}
                          onClick={() => {
                            model !== sectionId.value &&
                              this.listItemSelected('model', sectionId.value);
                          }}
                        >
                          <ListItemText primary={` ${sectionId.value}`} />
                        </ListItem>
                      </ul>
                    </li>
                  ))
                : this.getEmptyModelContent(
                    vehicleSelectorDetails.vehicleSelectorLoader,
                    classes,
                    make
                  )}
            </List>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ vehicle }: AppComponents.VehicleSelectorStore) => {
  return { vehicle };
};

const mapDispatchToProps = (dispatch: any): AppComponents.VehicleSelectorDispatch => {
  return bindActionCreators(
    { fetchAutolineDetails, vehicleTypeSelected, setHasFormChanged, setVehicleDetails },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(VehicleSelector));
