import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import rawConfig, { AppConfig } from '../../../config/config';
import { userSignOut } from '../../../pages/redux/actions';
import store from '../../../pages/redux/store';
import { getAuthToken } from '../../../utils';

const config = rawConfig as AppConfig;
export type AxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
>;

export const axiosBaseQuery = (): AxiosBaseQuery => {
  return async ({ url, method, body, params, headers }) => {
    try {
      const result = await axios({
        url: config.serverURL + url,
        method,
        data: body,
        params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getAuthToken()}`,
          ...headers,
        },
        timeout: Number(config.requestTimeout),
        withCredentials: true,
      });
      return { data: result.data };
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 401) {
        store.dispatch(userSignOut());
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
};
