import React, { useEffect } from 'react';
import { setHomeState, setQuotesLoaded, setLineOfBusiness } from '../../redux/actions';

import ApplicantFormComponent from '../../components/applicant-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const ApplicantForm = ({
  onNextTab,
  onPrevTab,
  currentIndex,
  setQuotesLoaded,
  setHomeState,
  goToLastTab,
  tabsCount,
  setLineOfBusiness,
  quoteType,
  redirectToStep,
}: AppComponents.ApplicantFormProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='wrapper mheight applicant-home-form'>
      <ApplicantFormComponent
        onNextTab={onNextTab}
        onPrevTab={onPrevTab}
        currentIndex={currentIndex}
        goToLastTab={goToLastTab}
        tabsCount={tabsCount}
        quoteType={quoteType}
        redirectToStep={redirectToStep}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.ApplicantFormDispatch =>
  bindActionCreators({ setQuotesLoaded, setHomeState, setLineOfBusiness }, dispatch);

export default connect(null, mapDispatchToProps)(ApplicantForm);
