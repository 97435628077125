import React, { useState } from 'react';
import { Card } from '@material-ui/core';

const Tabs = ({ children, active = 0 }: any) => {
  const [activeTabIndex, setActiveTabIndex] = useState(active);
  const activeTab = children[activeTabIndex];

  return (
    <>
      <ul className='nav nav-tabs admin-step'>
        {children.map((tab: any, index: any) => (
          <li key={index} className='nav-item'>
            <button
              className={`nav-link ${index === activeTabIndex ? 'active' : ''}`}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.props.title}
            </button>
          </li>
        ))}
      </ul>
      <Card className='tab-container'>
        <div className='tab-content p-3'>{activeTab.props.children}</div>
      </Card>
    </>
  );
};

export default Tabs;
