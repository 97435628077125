import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Vehicle from '../../../pages/auto/vehicle';
import { setErrorList, setLineOfBusiness } from '../../../pages/redux/actions';
import history from '../../../history';
import { INSURANCE_PRODUCTS } from '../../../constants';

const AutoForm = ({
  onNextTab,
  onPrevTab,
  currentIndex,
  setLineOfBusiness,
  setErrorList,
  add,
  goToLastTab,
}: AppComponents.AutoFormProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    setLineOfBusiness(INSURANCE_PRODUCTS[1]);
  }, [setLineOfBusiness]);

  return (
    <div>
      <Vehicle
        onNextTab={onNextTab}
        onPrevTab={onPrevTab}
        currentIndex={currentIndex}
        history={history}
        add={add}
        goToLastTab={goToLastTab}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.AutoFormDispatch =>
  bindActionCreators({ setLineOfBusiness, setErrorList }, dispatch);

export default connect(null, mapDispatchToProps)(AutoForm);
