export default {
  stories: {
    title: 'Stories',
    name: 'stories',
    type: 'numberStepper',
    value: 1,
    checkFoundationType: true,
  },
  foundationType: {
    title: 'Please select a foundation type?',
    name: 'foundationType',
    type: 'dropdown',
    value: '',
    options: [],
    foundation: true,
  },
  crawlspace: {
    title: 'Crawlspace type',
    name: 'structureType',
    type: 'dropdown',
    value: '',
    options: [
      { value: 'aboveCrawlspaceUnfinished', label: 'Above Grade Unfinished' },
      { value: 'aboveCrawlspaceFinished', label: 'Above Grade Finished' },
      { value: 'belowCrawlspaceUnfinished', label: 'Below Grade Unfinished' },
      { value: 'belowCrawlspaceFinished', label: 'Below Grade Finished' },
    ],
    linkValues: ['aboveCrawlspaceFinished', 'belowCrawlspaceFinished'],
    linkQuestion: {
      title: 'Crawlspace Sq. Ft.',
      name: 'enclosureSqft',
      type: 'numberInput',
      value: '',
    },
    submit: true,
  },
  pier: {
    title: 'Crawlspace type',
    name: 'structureType',
    type: 'dropdown',
    value: '',
    options: [
      { value: 'abovePierUnfinished', label: 'Above Grade Unfinished' },
      { value: 'abovePierFinished', label: 'Above Grade Finished' },
    ],
    linkValues: ['abovePierFinished'],
    linkQuestion: {
      title: 'Crawlspace Sq. Ft.',
      name: 'enclosureSqft',
      type: 'numberInput',
      value: '',
    },
    submit: true,
  },
  pilings: {
    title: 'Crawlspace type',
    name: 'structureType',
    type: 'dropdown',
    value: '',
    options: [
      { value: 'abovePileUnfinished', label: 'Above Grade Unfinished' },
      { value: 'abovePileFinished', label: 'Above Grade Finished' },
    ],
    linkValues: ['abovePileFinished'],
    linkQuestion: {
      title: 'Crawlspace Sq. Ft.',
      name: 'enclosureSqft',
      type: 'numberInput',
      value: '',
    },
    submit: true,
  },
  basement: {
    title: 'Is the basement finished?',
    name: 'structureType',
    type: 'dropdown',
    value: '',
    options: [
      { value: 'basementYes', label: 'Yes' },
      { value: 'basementNo', label: 'No' },
    ],
    submit: true,
  },
  'half basement': {
    title: 'Is the basement finished?',
    name: 'structureType',
    type: 'dropdown',
    value: '',
    options: [
      { value: 'halfBasementYes', label: 'Yes' },
      { value: 'halfBasementNo', label: 'No' },
    ],
    submit: true,
  },
  stilts: {
    title: 'Enclosure type',
    name: 'structureType',
    type: 'dropdown',
    value: '',
    options: [
      { value: 'stiltsFinishedGarage', label: 'Finished Garage' },
      { value: 'stiltsUnfinishedGarage', label: 'Unfinished Garage' },
      { value: 'stiltsFinishedOther', label: 'Finished Other' },
      { value: 'stiltsUnfinishedOther', label: 'Unfinished Other' },
    ],
    linkValues: ['stiltsFinishedGarage', 'stiltsFinishedOther'],
    linkQuestion: {
      title: 'Enclosure Sq. Ft.',
      name: 'enclosureSqft',
      type: 'numberInput',
      value: '',
    },
    submit: true,
  },
};

export const FLOOD_LABEL_NAME = 'Flood Info';
