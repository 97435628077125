/* eslint-disable max-lines-per-function */
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  Link,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';
import config from '../../../config/config';
import {
  DRIVER_DATA,
  DRIVER_INCLUDE_LIMIT,
  MISSING_INFORMATION,
  MISSING_SPOUSE_MESSAGE,
  PAGES_TEXT,
  XS
} from '../../../constants';
import { EDUCATION_DATA } from '../../../constants/education';
import { INDUSTRY_DATA } from '../../../constants/industry';
import {
  RELATION_TO_PRIMARY_DRIVER,
  SPOUSE,
  SPOUSE_ALREADY_ADDED_TOOLTIP_MESSAGE,
} from '../../../constants/relation_to_driver';
import AccidentsEditor from '../../components/accidents-editor';
import ConfirmationModal from '../../components/confirmation-modal';
import DatePicker from '../../components/date-picker';
import DriverSidePanel from '../../components/driver-side-panel';
import GenericModal from '../../components/generic-modal';
import LicenseModal from '../../components/license-modal';
import LossesEditor from '../../components/losses-editor';
import SpinnerLoader from '../../components/spinner-loader';
import ViolationsEditor from '../../components/violations-editor';
import { allowEditingQuote } from '../../../utils';
const setDropDownOption = (optionDetails: any) =>
  map(optionDetails, data => (
    <option key={data.value} value={data.value}>
      {data.label}
    </option>
  ));

const showMissingSpouseDialog = (display: boolean, component: any) =>
  display ? (
    <GenericModal
      title='Missing Information'
      message={`<span>${MISSING_SPOUSE_MESSAGE}</span>`}
      open={display}
      closehandler={component.modalSpouseCloseHandler}
    />
  ) : (
    ''
  );

const displayError = (errorText: string) => errorText !== '';

const getTitle = (isPrimaryDriver: boolean, isSpouse: boolean) =>
  isPrimaryDriver ? (
    <Typography variant='h6' className='title-2'>
      <span>{PAGES_TEXT.primaryDriverHeading}</span>
    </Typography>
  ) : isSpouse ? (
    <Typography variant='h6' className='title-2'>
      <span>{PAGES_TEXT.addSpouseHeading}</span>
    </Typography>
  ) : (
    <Typography variant='h6' className='title-2'>
      <span>{PAGES_TEXT.anotherDriverInformationHeading}</span>
    </Typography>
  );

const showMissingOtherDetailsDialog = (show: boolean, component: any) =>
  show ? (
    <GenericModal
      title="Following driver's information is missing"
      message={component.getDriverMissingDetails()}
      open={show}
      closehandler={component.missingDetailsModalCloseHandler}
    />
  ) : (
    ''
  );

const getDriverPersonalDetailsFields = (
  component: any,
  disableControls: boolean,
  disableInputs: boolean
) => {
  const { classes } = component.props;
  const { first_name, last_name, gender, detailsErrors, date_of_birth } = component.state;
  const { first_name_error, last_name_error, gender_error, date_of_birth_error } = detailsErrors;
  return (
    <>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={`fs-exclude ${classes.formControl}`}
          disabled={disableControls || disableInputs}
        >
          <InputLabel shrink htmlFor='first_name'>
            First Name<span className='star-error'>*</span>
          </InputLabel>
          <Input
            value={first_name}
            inputProps={{
              id: 'first_name',
              name: 'first_name',
            }}
            onChange={component.handleFieldChange}
            onBlur={component.handlePersonalDetailsBlur}
            error={first_name_error !== ''}
          />
          <FormHelperText className={classes.errorMsg}>{first_name_error}</FormHelperText>
        </FormControl>
      </div>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={`fs-exclude ${classes.formControl}`}
          disabled={disableControls || disableInputs}
        >
          <InputLabel shrink htmlFor='last_name'>
            Last Name<span className='star-error'>*</span>
          </InputLabel>
          <Input
            value={last_name}
            inputProps={{
              id: 'last_name',
              name: 'last_name',
            }}
            onChange={component.handleFieldChange}
            onBlur={component.handlePersonalDetailsBlur}
            error={last_name_error !== ''}
          />
          <FormHelperText className={classes.errorMsg}>{last_name_error}</FormHelperText>
        </FormControl>
      </div>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={classes.formControl}
          disabled={disableControls || disableInputs}
        >
          <InputLabel shrink htmlFor=''>
            Gender
          </InputLabel>
        </FormControl>
        <RadioGroup row aria-label='gender' name='gender' onChange={component.handleFieldChange}>
          <div className='row w-100'>
            <div className='col-sm-6 col-lg-6'>
              <FormControlLabel
                className='mr-5'
                value='male'
                control={
                  <Radio
                    inputProps={{
                      id: 'gender_male',
                    }}
                    checked={gender.toLocaleLowerCase() === 'male'}
                    color='default'
                    disabled={disableControls || disableInputs}
                  />
                }
                label='Male'
                labelPlacement='end'
                disabled={disableControls || disableInputs}
              />
            </div>
            <div className='col-sm-6 col-lg-6'>
              <FormControlLabel
                value='female'
                control={
                  <Radio
                    inputProps={{
                      id: 'gender_female',
                    }}
                    checked={gender.toLocaleLowerCase() === 'female'}
                    color='default'
                    disabled={disableControls || disableInputs}
                  />
                }
                label='Female'
                labelPlacement='end'
                disabled={disableControls || disableInputs}
              />
            </div>
          </div>
        </RadioGroup>
        <FormHelperText className={`${classes.errorMsg} ${classes.radioErrorMsg} mt-4`}>
          {gender_error}
        </FormHelperText>
      </div>
      <div className='date_of_birth fs-exclude col-md-6 padding-10'>
        <FormControl
          disabled={disableControls || disableInputs}
          fullWidth
          className={`mb-0 ${classes.formControl} date_of_birth ${
            date_of_birth_error.length > 0 ? 'labelError' : ''
          }`}
        >
          <DatePicker
            disabled={disableControls || disableInputs}
            label={
              <span>
                Date Of Birth<span className='star-error'>*</span>
              </span>
            }
            name='date_of_birth'
            onChange={component.handleCalendarDate}
            dateValue={!isEmpty(date_of_birth) ? date_of_birth : null}
            error={date_of_birth_error.length > 0}
          />
        </FormControl>

        <FormHelperText className={classes.errorMsg}>{date_of_birth_error}</FormHelperText>
      </div>
    </>
  );
};

const getMaritalStatusField = (
  component: any,
  disableControls: boolean,
  disableInputs: boolean
) => {
  const { classes, isVeriskFetched } = component.props;
  const { marital_status, detailsErrors, isSpouse, relation_to_primary_driver } = component.state;

  const disableButton = () => {
    let shouldDisable = isSpouse;
    if (isVeriskFetched) {
      if (relation_to_primary_driver === 'spouse' || isSpouse) {
        shouldDisable = true;
      }
    }
    return shouldDisable;
  };

  return (
    <>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={classes.formControl}
          disabled={disableControls || disableInputs}
        >
          <InputLabel shrink htmlFor='marital_status'>
            Marital Status<span className='star-error'>*</span>
          </InputLabel>
          <Select
            native
            value={marital_status}
            inputProps={{
              name: 'marital_status',
              id: 'marital_status',
            }}
            onChange={component.handleFieldChange}
            disabled={disableButton() || disableInputs || disableControls}
            error={displayError(detailsErrors.marital_status_error)}
          >
            <option value=''>Select</option>
            {setDropDownOption(DRIVER_DATA.marital_status)}
          </Select>
          <FormHelperText className={classes.errorMsg}>
            {detailsErrors.marital_status_error}
          </FormHelperText>
        </FormControl>
      </div>
    </>
  );
};

const isSpouseSelected = (driversList: Array<any>) => {
  let hasSpouse = false;

  driversList.forEach(driver => {
    if (driver.relation_to_primary_driver === 'spouse') {
      hasSpouse = true;
    }
  });

  return hasSpouse;
};

const getOtherDetailsFields = (
  component: any,
  disableControls: boolean,
  disableInputs: boolean
) => {
  const { classes, driver } = component.props;
  const {
    detailsErrors,
    relation_to_primary_driver,
    isSpouse,
    isPrimaryDriver,
    education,
    industry,
    disableOccupation,
    occupation,
    occupationData,
  } = component.state;

  return (
    <>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={classes.formControl}
          disabled={disableControls || disableInputs}
        >
          <InputLabel shrink htmlFor='education'>
            Education<span className='star-error'>*</span>
          </InputLabel>
          <Select
            native
            value={education}
            inputProps={{
              name: 'education',
              id: 'education',
            }}
            onChange={component.handleFieldChange}
            error={displayError(detailsErrors.education)}
          >
            <option value=''>Select</option>
            {map(EDUCATION_DATA, data => (
              <option key={`education_${data.value}`} value={data.value}>
                {data.label}
              </option>
            ))}
          </Select>
          <FormHelperText className={classes.errorMsg}>{detailsErrors.education}</FormHelperText>
        </FormControl>
      </div>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={classes.formControl}
          disabled={disableControls || disableInputs}
        >
          <InputLabel shrink htmlFor='industry'>
            Industry<span className='star-error'>*</span>
          </InputLabel>
          <Select
            native
            value={industry}
            inputProps={{
              name: 'industry',
              id: 'industry',
            }}
            onChange={component.handleFieldChange}
            error={displayError(detailsErrors.industry)}
          >
            <option value=''>Select</option>
            {map(INDUSTRY_DATA, data => (
              <option key={`industry_${data.value}`} value={data.value}>
                {data.label}
              </option>
            ))}
          </Select>
          <FormHelperText className={classes.errorMsg}>{detailsErrors.industry}</FormHelperText>
        </FormControl>
      </div>
      <div className='col-md-6'>
        <FormControl
          fullWidth
          className={classes.formControl}
          disabled={disableControls || disableOccupation || disableInputs}
        >
          <InputLabel shrink htmlFor='occupation'>
            Occupation{!isEmpty(occupationData) && <span className='star-error'>*</span>}
          </InputLabel>
          <Select
            native
            value={occupation}
            inputProps={{
              name: 'occupation',
              id: 'occupation',
            }}
            onChange={component.handleFieldChange}
            error={displayError(detailsErrors.occupation)}
          >
            <option value=''>Select</option>

            {map(occupationData, data => (
              <option key={`occupation_${data.value}`} value={data.value}>
                {data.label}
              </option>
            ))}
          </Select>
          <FormHelperText className={classes.errorMsg}>{detailsErrors.occupation}</FormHelperText>
        </FormControl>
      </div>
      {!isPrimaryDriver &&
        ((!isSpouse && !driver.isFetchedFromVerisk) || driver.isFetchedFromVerisk) && (
          <div className='col-md-6'>
            <FormControl
              fullWidth
              className={classes.formControl}
              disabled={disableControls || disableInputs}
            >
              <InputLabel shrink htmlFor='relation_to_primary_driver'>
                Relation to Primary Driver<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={relation_to_primary_driver}
                inputProps={{
                  name: 'relation_to_primary_driver',
                  id: 'relation_to_primary_driver',
                }}
                onChange={component.handleFieldChange}
                error={displayError(detailsErrors.relation_to_primary_driver_error)}
              >
                <option value=''>Select</option>
                {map(RELATION_TO_PRIMARY_DRIVER, data => (
                  <>
                    {data.value !== SPOUSE ? (
                      <option key={`relation_to_primary_driver_${data.value}`} value={data.value}>
                        {data.label}
                      </option>
                    ) : (
                      <>
                        {component.isPrimaryDriverMarried() && (
                          <Tooltip
                            arrow
                            title={
                              !isSpouse && isSpouseSelected(driver.driversList)
                                ? SPOUSE_ALREADY_ADDED_TOOLTIP_MESSAGE
                                : ''
                            }
                          >
                            <option
                              value={data.value}
                              disabled={!isSpouse && isSpouseSelected(driver.driversList)}
                            >
                              {data.label}
                            </option>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                ))}

                {/** TODO DELETE AFTER CONFIRMATION */}
                {/* {!isPrimaryDriver && !isSpouse && (
        <div className='col-md-6'>
          <FormControl fullWidth className={classes.formControl} disabled={disableControls}>
            <InputLabel shrink htmlFor='relation_to_primary_driver'>
              Relation to Primary Driver<span className='star-error'>*</span>
            </InputLabel>
            <Select
              native
              value={relation_to_primary_driver}
              inputProps={{
                name: 'relation_to_primary_driver',
                id: 'relation_to_primary_driver',
              }}
              onChange={component.handleFieldChange}
              error={displayError(detailsErrors.relation_to_primary_driver_error)}
            >
              <option value=''>Select</option>
              {map(RELATION_TO_PRIMARY_DRIVER, data => (
                <option key={`relation_to_primary_driver_${data.value}`} value={data.value}>
                  {data.label}
                </option>
              ))} */}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {detailsErrors.relation_to_primary_driver_error}
              </FormHelperText>
            </FormControl>
          </div>
        )}
    </>
  );
};

const getDrivingHistoryFields = (
  component: any,
  disableControls: boolean,
  disableInputs: boolean
) => {
  const { classes } = component.props;
  const {
    stateAgeLicensedData,
    isPrimaryDriver,
    us_license_status,
    age_licensed,
    detailsErrors,
    driver_licence,
  } = component.state;
  const { us_license_status_error, age_licensed_error, driver_licence_error } = detailsErrors;
  return (
    <>
      <div className='row sub-title-blue'>
        <div className='col-md-6'>
          <FormControl
            fullWidth
            className={classes.formControl}
            disabled={disableControls || disableInputs}
          >
            <InputLabel shrink htmlFor=''>
              U.S. License Status<span className='star-error'>*</span>
            </InputLabel>
            <Select
              native
              value={us_license_status}
              defaultValue={us_license_status}
              inputProps={{
                id: 'us_license_status',
                name: 'us_license_status',
              }}
              onChange={component.handleFieldChange}
              error={displayError(us_license_status_error)}
            >
              <option value=''>Select</option>
              {map(DRIVER_DATA.us_license_status, data =>
                isPrimaryDriver ? (
                  component.checkNotLicensed(data.value) && (
                    <option key={data.value} value={data.value}>
                      {data.label}
                    </option>
                  )
                ) : (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                )
              )}
            </Select>
            <FormHelperText className={classes.errorMsg}>{us_license_status_error}</FormHelperText>
          </FormControl>
        </div>

        {component.checkNotLicensed(us_license_status) && (
          <div className='col-md-6'>
            <FormControl
              fullWidth
              className={`fs-exclude ${classes.formControl}`}
              disabled={disableControls || disableInputs}
            >
              <InputLabel shrink htmlFor='driver_licence'>
                Driver's License #
              </InputLabel>
              <Input
                value={driver_licence}
                inputProps={{
                  id: 'driver_licence',
                  name: 'driver_licence',
                  maxLength: 50,
                }}
                onChange={component.handleFieldChange}
              />
              <FormHelperText className={classes.errorMsg}>{driver_licence_error}</FormHelperText>
            </FormControl>
          </div>
        )}
        {component.checkNotLicensed(us_license_status) && (
          <div className='col-md-6'>
            <FormControl
              fullWidth
              className={classes.formControl}
              disabled={disableControls || disableInputs}
            >
              <InputLabel shrink htmlFor=''>
                Age Licensed<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={age_licensed}
                inputProps={{
                  id: 'age_licensed',
                  name: 'age_licensed',
                }}
                onChange={component.handleFieldChange}
                error={displayError(age_licensed_error)}
              >
                <option value=''>Select</option>
                {map(stateAgeLicensedData, data => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Select>
              <FormHelperText className={classes.errorMsg}>{age_licensed_error}</FormHelperText>
            </FormControl>
          </div>
        )}
      </div>
    </>
  );
};

const addIncidents = (event: any, type: string, component: any) => {
  event.preventDefault();
  switch (type) {
    case 'addAccident':
      component.props.initAccidents();
      break;
    case 'addViolation':
      component.props.initViolations();
      break;
    case 'addLosses':
      component.props.initLosses();
      break;
  }
};

const getIncidentsList = (
  list: Array<any>,
  title: string,
  key: string,
  uid: string,
  Component: any,
  parentComponent: any,
  disableAutoEdit: boolean,
) => (
  <>
    <div className='section w-100 shodow-bx'>
      <div className='row sub-title-blue mx-auto'>
        <div className='col-6'>
          <Typography className='VoiletHeading'>{title}</Typography>
        </div>
        <div className='col-6'>
          <Typography className='VoiletSubHeading'>
            <span className='VoiletSubHeading-text'>
              (No. Of {title}: {list.length})
            </span>
            <span>
              <Tooltip title='Add' arrow placement='top'>
                {list.length < config.maxAccidentLimit && !disableAutoEdit ? (
                  <AddIcon
                    onClick={(event: any) => {
                      addIncidents(event, key, parentComponent);
                    }}
                    className='addIcon'
                  />
                ) : (
                  <AddIcon className='d-none' />
                )}
              </Tooltip>
            </span>
          </Typography>
        </div>
      </div>

      {map(list, entity => (
        <Component uid={entity[uid]} key={entity[uid]} />
      ))}
    </div>
  </>
);

const getViolations = (component: any, disableAutoEdit: boolean) => {
  const { violationList } = component.props;
  const { violations } = component.state;
  if (violations.toLowerCase() === 'yes' && violationList.length === 0) {
    component.props.initViolations();
  }
  return (
    violations.toLowerCase() === 'yes' &&
    getIncidentsList(
      violationList,
      'Violations',
      'addViolation',
      'violation_uid',
      ViolationsEditor,
      component,
      disableAutoEdit,
    )
  );
};

const getLosses = (component: any, disableAutoEdit: boolean) => {
  const { lossList } = component.props;
  const { losses } = component.state;
  if (losses.toLowerCase() === 'yes' && lossList.length === 0) {
    component.props.initLosses();
  }
  return (
    losses.toLowerCase() === 'yes' &&
    getIncidentsList(lossList, 'Losses', 'addLosses', 'loss_uid', LossesEditor, component, disableAutoEdit)
  );
};

const getClaims = (component: any, disableAutoEdit: boolean) => {
  const { accidentList } = component.props;
  const { claims } = component.state;
  if (claims.toLowerCase() === 'yes' && accidentList.length === 0) {
    component.props.initAccidents();
  }
  return (
    claims.toLowerCase() === 'yes' &&
    getIncidentsList(
      accidentList,
      'Accidents',
      'addAccident',
      'accident_uid',
      AccidentsEditor,
      component,
      disableAutoEdit,
    )
  );
};

const getIncidentsFields = (component: any, disableControls: boolean, disableInputs: boolean, disableAutoEdit: boolean) => {
  const { classes } = component.props;
  const { us_license_status, claims, violations, losses, detailsErrors } = component.state;
  const { claims_error, violations_error, losses_error } = detailsErrors;
  return (
    <>
      {component.checkNotLicensed(us_license_status) ? (
        <>
          <div className='row sub-title-blue mt-4'>
            <div className='col-lg-12'>
              <Typography className='VoiletHeading'>Accidents, Violations, and Claims</Typography>
            </div>
          </div>
          <div className='row display-block'>
            <div className='col-md-12'>
              <Typography className='mt-3 mb-3 que-label'>
                In the last 5 years, have you had Accidents, Claims, or other damages you had to a
                vehicle?
              </Typography>
            </div>
          </div>
          <div className='row display-block'>
            <div className='col-lg-8'>
              <Typography className='mt-2 mb-2 label-accident'>
                Accidents, Claims, or other damages you had to a vehicle?
              </Typography>
            </div>
            <div className='col-lg-4 pr-0'>
              <RadioGroup
                row
                aria-label='claims'
                name='claims'
                onChange={component.handleFieldChange}
              >
                <FormControlLabel
                  className='margin-right option-label'
                  value='yes'
                  control={
                    <Radio
                      inputProps={{
                        id: 'claims_yes',
                      }}
                      color='default'
                      checked={claims.toLocaleLowerCase() === 'yes'}
                      disabled={disableControls || disableInputs}
                    />
                  }
                  label='Yes'
                  labelPlacement='end'
                  disabled={disableControls || disableInputs}
                />

                <FormControlLabel
                  className='option-label'
                  value='no'
                  control={
                    <Radio
                      inputProps={{
                        id: 'claims_no',
                      }}
                      color='default'
                      checked={claims.toLocaleLowerCase() === 'no'}
                      disabled={disableControls || disableInputs}
                    />
                  }
                  label='No'
                  labelPlacement='end'
                  disabled={disableControls || disableInputs}
                />
              </RadioGroup>
              <FormHelperText className={`${classes.errorMsg} ${classes.radioErrorMsg}`}>
                {claims_error}
              </FormHelperText>
            </div>
            {component.state.claims.toLocaleLowerCase() === 'yes' && getClaims(component, disableAutoEdit)}
          </div>
          <div className='row display-block mt-3'>
            <div className='col-lg-8'>
              <Typography className='mt-2 mb-2 Violationslbl'>Tickets/Violations?</Typography>
            </div>
            <div className='col-lg-4 pr-0'>
              <RadioGroup
                row
                aria-label='violations'
                name='violations'
                onChange={component.handleFieldChange}
              >
                <FormControlLabel
                  className='margin-right option-label'
                  value='yes'
                  control={
                    <Radio
                      color='default'
                      checked={violations.toLocaleLowerCase() === 'yes'}
                      disabled={disableControls || disableInputs}
                    />
                  }
                  label='Yes'
                  labelPlacement='end'
                  disabled={disableControls || disableInputs}
                />
                <FormControlLabel
                  className='option-label'
                  value='no'
                  control={
                    <Radio
                      color='default'
                      checked={violations.toLocaleLowerCase() === 'no'}
                      disabled={disableControls || disableInputs}
                    />
                  }
                  label='No'
                  labelPlacement='end'
                  disabled={disableControls || disableInputs}
                />
              </RadioGroup>
              <FormHelperText className={`${classes.errorMsg} ${classes.radioErrorMsg}`}>
                {violations_error}
              </FormHelperText>
            </div>
            {component.state.violations.toLocaleLowerCase() === 'yes' && getViolations(component, disableAutoEdit)}
          </div>
          <div className='row display-block mt-3'>
            <div className='col-lg-8'>
              <Typography className='mt-2 mb-2 Violationslbl'>
                Losses
                <span className='highlight-text d-block'>Need an SR22 / FR44?</span>
              </Typography>
            </div>
            <div className='col-lg-4 pr-0'>
              <RadioGroup
                row
                aria-label='losses'
                name='losses'
                onChange={component.handleFieldChange}
              >
                <FormControlLabel
                  className='margin-right option-label'
                  value='yes'
                  control={
                    <Radio
                      color='default'
                      checked={losses.toLocaleLowerCase() === 'yes'}
                      disabled={disableControls || disableInputs}
                    />
                  }
                  label='Yes'
                  labelPlacement='end'
                  disabled={disableControls || disableInputs}
                />
                <FormControlLabel
                  className='option-label'
                  value='no'
                  control={
                    <Radio
                      color='default'
                      checked={losses.toLocaleLowerCase() === 'no'}
                      disabled={disableControls || disableInputs}
                    />
                  }
                  label='No'
                  labelPlacement='end'
                  disabled={disableControls || disableInputs}
                />
              </RadioGroup>
              <FormHelperText className={`${classes.errorMsg} ${classes.radioErrorMsg}`}>
                {losses_error}
              </FormHelperText>
            </div>
            {component.state.losses.toLocaleLowerCase() === 'yes' && getLosses(component, disableAutoEdit)}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};

const addDriverHandler = (event: MouseEvent, component: any) => {
  const { marital_status, isPrimaryDriver, isSpouse } = component.state;
  if (
    !component.hasIncident(component.state) &&
    (marital_status !== 'Married' || !isPrimaryDriver || isSpouse || component.spouseExists())
  ) {
    component.addAnotherDriverHandler(event);
  } else if (component.hasIncident(component.state)) {
    if (component.validateInputs(component.state)) {
      component.props.addDriverFromOtherDetails(component.getAddDriverData());
    }
    event.preventDefault();
  } else {
    if (component.validateInputs(component.state)) {
      component.addAnotherDriverHandler(event);
    }
  }
  component.onAddDriverOrAddSpouse();
};

const drawDriverSidePanel = (component: any, disableAutoEdit: boolean) => {
  const { marital_status: marital_status_state } = component.state;
  const { marital_status: marital_status_props } = component.props.driver;
  const { driver } = component.props;

  const label = () => {
    let content = '';
    if (
      driver.isPrimaryDriver &&
      !driver.isSpouseAdded &&
      (marital_status_state === 'Married' || marital_status_props === 'Married')
    ) {
      content = 'Add Spouse';
    } else {
      content = 'Add Driver';
    }

    return content;
  };
  return (
    <div className='col-lg-3 p-lg-0 right-side-list order-1'>
      <Card elevation={0} className='right-driver-info-box'>
        <CardContent className='card-padding'>
          <Typography className='right-driver-info-box-label'>Driver(s)</Typography>
          <DriverSidePanel
            openDriverLimitModal={component.openDriverLimitModal}
            validateBeforeEditHandler={component.validateBeforeEditHandler}
          />

          {/* add vehicle buttton */}
          {
            // TODO: delete this part after everything after review
            // claims === 'no' &&
            //   violations === 'no' &&
            //   losses === 'no' &&
            //   (marital_status !== 'Married' ||
            //     !isPrimaryDriver ||
            //     isSpouse ||
            //     component.spouseExists()) &&
            component.showDriverAddAction() && !disableAutoEdit && (
              <Grid
                container
                spacing={2}
                className='m-0 w-100 list-container list-wrap align-items-center'
              >
                <Grid item xs={12}>
                  <Link className='add-link' onClick={(e: any) => addDriverHandler(e, component)}>
                    <AddIcon className='add-icon-border mr-2' />
                    <span className='link-label'>{label()}</span>
                  </Link>
                </Grid>
              </Grid>
            )
          }
        </CardContent>
      </Card>
    </div>
  );
};

const showConfirmationModal = (component: any) => (
  <ConfirmationModal
    open={component.state.saveDialog}
    closehandler={component.modalClose}
    message='Would you like to save the changes?'
    contentValue=''
    heading='Save Changes?'
  />
);

const showMissingInformationModal = (display: boolean, component: any) =>
  display ? (
    <GenericModal
      title='Missing Information'
      message={`<p>${MISSING_INFORMATION}</p>`}
      open={display}
      closehandler={component.closeMissingInformationModal}
    />
  ) : (
    ''
  );

const showVehicleLimitModal = (display: boolean, component: any) =>
  display ? (
    <GenericModal
      title='Drivers Limit Reached'
      message={`<p>${DRIVER_INCLUDE_LIMIT}</p>`}
      open={display}
      closehandler={component.closeDriverLimitModal}
    />
  ) : (
    ''
  );


const getDriverSwitchModal = (tabSwitchConfirmationModal: boolean, component: any) => {
  const { classes } = component.props;
    return (
      <div>
        <Dialog className='driver-switch-modal'
          fullWidth={true}
          maxWidth={XS}
          open={tabSwitchConfirmationModal}
          classes={{
            root: 'dialog-container',
          }}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogTitle id='dialog-title'>
            <IconButton
              aria-label='close'
              className="float-right"
              onClick={() => component.closeTabSwitchConfirmationModal()}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </DialogTitle>

          <DialogContent classes={{ root: classes.dialogContent }}>
            <Typography className="text-center">Spouse info is required to continue quoting auto</Typography>
            <Typography className="text-center">Click <b>"Continue"</b> to do so now or <b>"Ok"</b> to navigate</Typography>
            <Typography className="text-center">away anyways</Typography>
          </DialogContent>
          <DialogActions>
            <div className='btn-wraper'>
              <Button
                variant='outlined'
                className='navigation-back-btn'
                disabled={false}
                onClick={() => component.redirectToRatePage()}
              >
                Ok
              </Button>
              <Button
                variant='outlined'
                className='navigation-next-btn submit-btn'
                disabled={false}
                onClick={() => component.closeTabSwitchConfirmationModal()}
              >
                Continue
                {false && <SpinnerLoader styleData={classes.submitSpinner} />}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
};

const template = (component: any) => {
  // todo: fix buttons
  const { driver, stepSubmitLoader, classes, showGetQuoteButton } = component.props;
  const {
    isPrimaryDriver,
    licenseDialog,
    missingSpouseDialog,
    missingOtherDetailsDialog,
    isSpouse,
    continueButtonClick,
    getQuoteButtonClick,
    saveDialog,
    missingInformation,
    driverLimitReached,
    tabSwitchConfirmationModal,
  } = component.state;
  const disableAutoEdits = allowEditingQuote();
  const disableControls = driver.driverLoader || stepSubmitLoader;
  const disableInputs =
    (!driver.isIncluded && driver.driversList?.filter((x: any) => driver.duid === x.duid).length) || disableAutoEdits?.auto
      ? true
      : false;
  return (
    <div className='container'>
      <div className='autoline-steps autoline-step-1-wrapper d-flex justify-content-center row driverDiv position-relative'>
        <div className='col-lg-9 order-2'>
          <form className='vehicle-box-shodow position-relative'>
            <Paper elevation={0}>
              <div className='row m-0'>
                <div className='col-md-12 applicant-home-title'>
                  <span className='icon-driver title-icon'></span>
                  <Typography variant='h6' className='title-2'>
                    {getTitle(isPrimaryDriver, isSpouse)}
                  </Typography>
                </div>
              </div>

              <div className='row d-flex justify-content-center m-0'>
                <div className='col-lg-10'>
                  <div className='row'>
                    {getDriverPersonalDetailsFields(component, disableControls, disableInputs)}
                    {getMaritalStatusField(component, disableControls, disableInputs)}
                    {getOtherDetailsFields(component, disableControls, disableInputs)}
                  </div>
                  {getDrivingHistoryFields(component, disableControls, disableInputs)}

                  {getIncidentsFields(component, disableControls, disableInputs, disableAutoEdits?.auto)}
                </div>
              </div>
            </Paper>
            <div className='row margin-top m-0'>
              <div className='col-md-12'>
                <div className='btnwrap'>
                  <Button
                    variant='outlined'
                    className='btnBackLink'
                    disabled={disableControls}
                    onClick={component.vehicleBackHandler}
                  >
                    <span className='link-label'>
                      <ArrowBackIosIcon className='mr-2' />
                      Back
                    </span>
                  </Button>
                  <>
                    <Button
                      variant='contained'
                      className='btnContinue'
                      disabled={disableControls}
                      onClick={event => {
                        component.submitStep(event, false);
                      }}
                    >
                      Continue
                      {stepSubmitLoader && continueButtonClick && (
                        <SpinnerLoader styleData={classes.continueSpinner} />
                      )}
                    </Button>
                    {showGetQuoteButton && (
                      <Button
                        variant='contained'
                        className='btnContinue'
                        disabled={disableControls}
                        onClick={event => {
                          component.submitStep(event, true);
                        }}
                      >
                        Get Quote
                        {stepSubmitLoader && getQuoteButtonClick && (
                          <SpinnerLoader styleData={classes.continueSpinner} />
                        )}
                      </Button>
                    )}
                  </>
                </div>
              </div>
            </div>
          </form>
        </div>
        {drawDriverSidePanel(component, disableAutoEdits?.auto)}
        {licenseDialog && (
          <LicenseModal open={licenseDialog} closehandler={component.modalLicenseCloseHandler} />
        )}

        {showMissingSpouseDialog(missingSpouseDialog, component)}
        {showMissingInformationModal(missingInformation, component)}

        {showMissingOtherDetailsDialog(missingOtherDetailsDialog, component)}
        {saveDialog && showConfirmationModal(component)}
        {showVehicleLimitModal(driverLimitReached, component)}
        {getDriverSwitchModal(tabSwitchConfirmationModal, component)}
      </div>
    </div>
  );
};

export default template;
