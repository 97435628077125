/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import axiosRequest from '../../api';
import { get, isEmpty, omit, assignIn, filter } from 'lodash';
import * as homeWarrantyDetailsAction from '../constants';
import {
  HOME_WARRANTY,
  INSURANCE_PRODUCTS,
} from '../../../constants';
import {
  errorHandler,
  homeWarrantyQuotesLoader,
  setHomeWarrantyQuoteDetails,
  storeHomeWarrantyQuoteList,
} from '../actions';
import {
  createPageTracking,
  clearLobQuotes,
  simplifyPhoneNumber,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
} from '../../../utils';

function* fetchHomeWarrantyQuotes(): any {
  trackFirstLoadQuoteInHeap();
  try {
    yield put(homeWarrantyQuotesLoader(true));
    const {
      applicant,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails, appointments },
      homeWarrantyDetails,
      common,
      home,
    } = yield select();
    const keys = Object.keys(appointments);
    yield put(
      storeHomeWarrantyQuoteList({
        quoteStatus: '',
      })
    );
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
        ? producerDetails?.producerEmail
        : '';
    applicant.personal_details.phone_number = applicant?.personal_details?.phone_number ? simplifyPhoneNumber(applicant?.personal_details?.phone_number) : '';
    let pageTracking = createPageTracking({}, appointments);
    let requestPayload = {
      pageTracking,
      personal_information: { ...omit(applicant?.personal_details, 'preferredDeductible') },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: homeWarrantyDetails?.herdId || '',
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
      lob: HOME_WARRANTY,
      partner_code: !isEmpty(producerDetails?.producerCode) ? producerDetails.producerCode : quoteBy,
    };
    let isClearQuote = keys.includes('HOME') || keys.includes('QUAKE') || keys.includes('FLOOD');
    if (!isClearQuote) {
      const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } = clearLobQuotes();
      Object.assign(requestPayload, {
        lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo
      });
    }
    const details = yield axiosRequest('POST', '/home-warranty/quotes', isProviderOneLogin, requestPayload);
    if (details?.data) {
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[5]);
      let { upcomingCarrierList, quotes, herdId, error_details } = details?.data;
      let errorQuotes: any = {};
      if (error_details) {
        errorQuotes = filter(Object.values(error_details), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      quotes = !!quotes ? quotes : {};
      yield put(
        setHomeWarrantyQuoteDetails({
          quoteList: [...Object.values(quotes), ...Object.values(errorQuotes)],
          herdId,
        })
      );
      yield put(
        storeHomeWarrantyQuoteList({
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        })
      );
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!isEmpty(axiosError?.response?.data?.error_details)) {
      const { error_details, herdId } = axiosError?.response?.data;
      let errorQuotes: any = filter(Object.values(error_details), errorDetails => {
          return assignIn(errorDetails, { quoteError: true });
      });
      yield put(
        setHomeWarrantyQuoteDetails({
          quoteList: [...Object.values(errorQuotes)],
          herdId,
        })
      );
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[5]);
    }
    yield put(errorHandler(axiosError));
  }
  yield put(homeWarrantyQuotesLoader(false));
}

export function* fetchHomeWarrantyQuotesWatcher() {
  yield takeEvery(homeWarrantyDetailsAction.FETCH_HOME_WARRANTY_QUOTES, fetchHomeWarrantyQuotes);
}

export default function* rootSaga() {
  yield all([
    fork(fetchHomeWarrantyQuotesWatcher),
  ]);
}
