import React from 'react';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import { Typography } from '@material-ui/core';
import config from '../../config/config';
import adminQuoteIcon from '../../assets/images/admin-logout.svg';
import bulkQuoteLogo from '../../assets/images/bulk-quote-logout.png';
import hippoLogo from '../../assets/images/hippo-logout.png';
import reportLogo from '../../assets/images/gator-reports-logoout.png';
import history from '../../history';
import { goToDashboard, inIframe } from '../../utils';
import {
  ADMIN_ROUTE,
  DEFAULT_BULK_QUOTE_PATH,
  DEFAULT_REPORT_PATH,
  LOGOUT_SESSION_EXPIRED_MESSAGE,
  LOGOUT_SUCCESSFULLY_MESSAGE,
} from '../../constants';

import './logout.scss';

class Logout extends React.Component<AppComponents.LogoutStoreProps> {
  componentDidMount() {
    const { adminUser, authUser, bulkUser, reportUser, autoLogout } = this.props;
    if (inIframe() && autoLogout) {
      const url = `${
        window.location !== window.parent.location ? document.referrer : document.location.href
      }app/producer/dashboard`;
      window.parent.location.href = url;
    }

    if (!isEmpty(authUser)) {
      if (bulkUser) {
        history.push(DEFAULT_BULK_QUOTE_PATH);
      } else if (reportUser) {
        history.push(DEFAULT_REPORT_PATH);
      } else if (adminUser) {
        history.push(ADMIN_ROUTE);
      } else {
        goToDashboard();
      }
    }
  }

  getLogo = () => {
    const { adminUser, bulkUser, reportUser } = this.props;
    if (bulkUser) {
      return <img title={config.bulkQuoteName} alt={config.bulkQuoteName} src={bulkQuoteLogo} />;
    } else if (reportUser) {
      return (
        <img
        title={config.hippo.name}
        alt={config.hippo.name}
        src={reportLogo} className="img-fluid report-logo"
      />
      );
    } else if (adminUser) {
      return (
        <img
          title={config.adminUser}
          alt={config.adminUser}
          src={adminQuoteIcon}
          className="img-fluid"
         />
      );
    } else {
      return <img title={config.hippo.name} alt={config.hippo.name} src={hippoLogo} />;
    }
  };

  render() {
    const { adminUser, autoLogout, bulkUser, reportUser } = this.props;
    return (
      !inIframe() && (
        <Container component='main' maxWidth='xs'>
          <div className='logout-wrapper'>
            <div className='center-div'>
              <Grid container spacing={3}>
                <Grid item xs={12} className='d-flex justify-content-center'>
                  {this.getLogo()}
                </Grid>
                <Grid item xs={12}>
                  {autoLogout ? (
                    <Typography className='text-center'>
                      {LOGOUT_SESSION_EXPIRED_MESSAGE}
                    </Typography>
                  ) : (
                    <Typography className='text-center'>{LOGOUT_SUCCESSFULLY_MESSAGE}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} className='text-center'>
                  <div className='login'>
                    <button
                      onClick={() => {
                        let url = config.loginUrl;
                        if (bulkUser) {
                          url = config.bulkQuoteLoginUrl;
                        } else if (reportUser) {
                          url = config.reportLoginUrl;
                        } else if (!!adminUser) {
                          url = config.adminLogInUrl;
                        }
                        window.location.assign(url);
                      }}
                      className='login-btn btn btn-success'
                    >
                      Login
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      )
    );
  }
}

const mapStateToProps = (state: AppComponents.LogoutStore): AppComponents.LogoutStoreProps => {
  const { adminUser, authUser, autoLogout, bulkUser, reportUser } = state.auth;
  return { adminUser, authUser, autoLogout, bulkUser, reportUser };
};

export default connect(mapStateToProps, {})(Logout);
