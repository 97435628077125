export const EMPTY = '';
export const INPUT_TYPES = {
  CHECKBOX: 'checkbox',
};
export const ERROR_MESSAGE = {
  INVALID: 'Invalid',
  REQUIRED: 'Required',
  PERCENTAGE: 'Should be between 1 to 100%',
};
export const resetObjectProperties = <Key extends string>(object: Record<Key, string>) => {
  const tempObject: Record<Key, string> = { ...object };
  Object.keys(object).forEach(key => {
    tempObject[key as Key] = EMPTY;
  });
  return tempObject as Record<Key, string>;
};
