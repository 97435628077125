/* istanbul ignore file */
import { AxiosError } from 'axios';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import axiosRequest from '../../api';
import { get, isEmpty, omit, assignIn, filter } from 'lodash';
import config from '../../../config/config';
import * as cyberSecurityDetailsAction from '../constants';
import {
  cyberSecurityQuotesLoader,
  cyberSecurityTimeoutPolling,
  errorHandler,
  cyberBridgeToCarrierLoader,
  initializeCyberSecurityPolling,
  setCyberSecurityQuoteDetails,
  setLobRefreshLoader,
  storeCyberSecurityQuoteDetails,
  setCyberSecurityPollingDetails,
  setCyberSecurityPollingErrorDetails,
  stopCyberSecurityPolling,
  storeErrorsForBridgeToCarrier,
  updateCyberSecurityQuoteDetails,
} from '../actions';
import {
  createPageTracking,
  clearLobQuotes,
  simplifyPhoneNumber,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
} from '../../../utils';
import { CYBER_SECURITY, INSURANCE_PRODUCTS, LOB_REFRESH_TIMEOUT } from '../../../constants';


const pollConfig: any = {
  publicUrl: config.serverURL,
  pollingTimerTimeout: config.pollingTimerTimeout,
  pollingInterval: config.pollingInterval,
};
const displayErrorProperty = 'error_details.display_errors_on_ui';
const errorProperty = 'error_details.errors';

const pollingDelay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
};

function* initializeQuotesPolling() {
  const { cyberSecurityDetails: quotesDetails } = yield select();
  if (quotesDetails?.isQuoteRefresh) {
    yield put(
      storeCyberSecurityQuoteDetails({
        isQuoteRefresh: false,
      } as any)
    );
  }
  const quoteLimit = get(quotesDetails, 'quotingDetails.totalQuotesToPoll', 0);
  if (quotesDetails.quoteErrorList.length < quoteLimit) {
    yield put(initializeCyberSecurityPolling());
    yield call(pollingDelay, pollConfig.pollingTimerTimeout);
    yield put(cyberSecurityTimeoutPolling());
  }
}

function* fetchCyberSecurityQuotes(): any {
  try {
    trackFirstLoadQuoteInHeap();
    yield put(cyberSecurityQuotesLoader(true));
    const {
      applicant,
      auth,
      partner: { isProviderOneLogin, storedRequestId, producerDetails, appointments },
      cyberSecurityDetails,
      common,
      home,
    } = yield select();
    yield put(
      storeCyberSecurityQuoteDetails({
        quoteStatus: '',
      })
    );
    const keys = Object.keys(appointments);
    const daleConfig = common?.pconfig?.pconfig?.dale_config || home?.pconfig?.dale_config;
    const { carrier_credentials_grid, is_verisk_prefill_available, ...configWithoudCreds } =
      daleConfig;
    const organizationName = common.pconfig.pconfig?.name || daleConfig?.name;
    const organizationId = common?.pconfig?.pconfig?.id || daleConfig?.id;
    const { code, auth_token } = configWithoudCreds;
    const quoteBy = !isEmpty(get(auth, 'email', ''))
      ? get(auth, 'email')
      : !isEmpty(producerDetails?.producerEmail)
        ? producerDetails?.producerEmail
        : '';
    applicant.personal_details.phone_number = applicant?.personal_details?.phone_number ? simplifyPhoneNumber(applicant?.personal_details?.phone_number) : '';
    let pageTracking = createPageTracking({}, appointments);
    let isClearQuote = keys.includes('HOME') || keys.includes('QUAKE') || keys.includes('FLOOD');
    let requestPayload = {
      pageTracking,
      personal_information: { ...omit(applicant?.personal_details, 'preferredDeductible') },
      address: { ...applicant.address },
      organization: { config: { code, auth_token, name: organizationName, organizationId } },
      herd_id: cyberSecurityDetails?.herdId || '',
      quote_by: quoteBy,
      id: get(auth, 'userId', ''),
      provider_request_id: storedRequestId,
      selected_lob: keys,
      lob: CYBER_SECURITY,
      partner_code: !isEmpty(producerDetails?.producerCode) ? producerDetails.producerCode : quoteBy,
    };
    if (!isClearQuote) {
      const { lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo } = clearLobQuotes();
      Object.assign(requestPayload, {
        lobQuotesToClear, clearAutoInfo, clearPetInfo, clearLifeInfo, clearUmbrellaInfo
      });
    }
    const details = yield axiosRequest('POST', '/cyber/quotes', isProviderOneLogin, requestPayload);
    if (!isEmpty(details?.data)) {
      const { upcomingCarrierList, quotingCarriers } = details.data;
      yield put(
        storeCyberSecurityQuoteDetails({
          herdId: details?.data?.herd_id,
          quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
          quoteStatus: '',
        })
      );
      yield put(updateCyberSecurityQuoteDetails({ ...details.data }));
      if (
        get(details.data, displayErrorProperty, false) &&
        !isEmpty(get(details.data, errorProperty, {}))
      ) {
        yield put(setCyberSecurityPollingErrorDetails({ ...details.data }));
      }
      yield call(initializeQuotesPolling);
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!isEmpty(axiosError?.response?.data?.error_details)) {
      const { error_details, herdId } = axiosError?.response?.data;
      let errorQuotes: any = filter(Object.values(error_details), errorDetails => {
        return assignIn(errorDetails, { quoteError: true });
      });
      yield put(
        setCyberSecurityQuoteDetails({
          quoteErrorList: [...Object.values(errorQuotes)],
          herdId,
        })
      );
    }
    yield put(storeCyberSecurityQuoteDetails({ quotesLoaded: true } as any));
    yield put(errorHandler(axiosError));
  }
  trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[7]);
  yield put(cyberSecurityQuotesLoader(false));
}

export function* fetchCyberSecurityQuotesWatcher() {
  yield takeEvery(cyberSecurityDetailsAction.FETCH_CYBER_SECURITY_QUOTES, fetchCyberSecurityQuotes);
}


function* pollQuotes(): any {
  while (true) {
    const {
      cyberSecurityDetails,
      partner: { isProviderOneLogin },
    } = yield select();
    try {
      if (cyberSecurityDetails?.quotingDetails?.herd_id) {
        const { data } = yield axiosRequest(
          'POST',
          '/quotes/poll-quotes',
          isProviderOneLogin,
          {
            herd_id: cyberSecurityDetails.quotingDetails.herd_id,
            lob: CYBER_SECURITY,
          }
        );
        if (!isEmpty(data.quotes)) {
          yield put(setCyberSecurityPollingDetails({ ...data }));
        }
        if (get(data, displayErrorProperty, false) && !isEmpty(get(data, errorProperty, {}))) {
          yield put(setCyberSecurityPollingErrorDetails({ ...data }));
        }
        const reduxState = yield select();
        const currentQuotesLength =
          reduxState.cyberSecurityDetails.quoteList.length + reduxState.cyberSecurityDetails.quoteErrorList.length;
        const quoteLimit = get(cyberSecurityDetails, 'quotingDetails.totalQuotesToPoll', 0);
        if (currentQuotesLength >= quoteLimit) {
          yield put(cyberSecurityQuotesLoader(false));
          yield put(stopCyberSecurityPolling());
          if (cyberSecurityDetails?.isQuoteRefresh) yield put(setLobRefreshLoader(false));
        } else {
          yield call(pollingDelay, pollConfig.pollingInterval);
        }
      }
    } catch (error) {
      yield put(errorHandler(error));
    }
  }
}

function* pollQuotesWatcher() {
  while (true) {
    yield take(cyberSecurityDetailsAction.INIT_CYBER_SECURITY_POLLING);
    yield race([call(pollQuotes), take(cyberSecurityDetailsAction.STOP_CYBER_SECURITY_POLLING)]);
  }
}

function* cyberSecurityPollingTimeout() {
  const {
    cyberSecurityDetails: { quoteList, quoteErrorList },
  } = yield select();
  if (quoteList.length + quoteErrorList.length === 0) {
    yield put(storeCyberSecurityQuoteDetails({
      noQuoteAvailable: true,
    }));
  }
  yield put(stopCyberSecurityPolling());
}

function* cyberSecurityPollingTimeoutWatcher() {
  yield takeLatest(cyberSecurityDetailsAction.CYBER_SECURITY_TIMEOUT_POLLING, cyberSecurityPollingTimeout);
}

function* stopPollingWorker() {
  const {
    cyberSecurityDetails: { quotingDetails, quoteList, quoteErrorList, quotesLoading },
  } = yield select();
  if (quotingDetails && !quotesLoading && quoteList.length + quoteErrorList.length > 0) {
    yield put(storeCyberSecurityQuoteDetails({ quotesLoaded: true } as any));
  }
}

export function* stopPollingWatcher() {
  yield takeEvery(cyberSecurityDetailsAction.STOP_CYBER_SECURITY_POLLING, stopPollingWorker);
}


function* getBridgeToCarrierDetailsForCyberSecurity({ payload }: any): any {
  let loaderData = {
    loader: true,
    carrier_id: payload?.carrierId,
  };
  try {
    yield put(cyberBridgeToCarrierLoader(loaderData));
    const {
      partner: { isProviderOneLogin, producerDetails },
      auth,
    } = yield select();
    const quoteBy = !isEmpty(get(auth, 'email', ''))
    ? get(auth, 'email')
    : !isEmpty(producerDetails?.producerEmail)
    ? producerDetails?.producerEmail
    : '';
    const responseDetails = yield axiosRequest('POST', '/quotes/bridge-carrier', isProviderOneLogin, {
      herdId: payload?.herdId ?? '',
      lob: payload?.lob ?? '',
      carrierId: payload?.carrierId ?? '',
      premium: payload?.premium ?? '',
      coverage: payload?.coverage ?? '',
      selectedPlan: payload?.selectedPlan ?? '',
      selectedGroup: payload?.selectedGroup ?? '',
      quoteBy, 
    });
    if (!!responseDetails?.data) {
      if (!!responseDetails?.data?.paymentLink) {
        window.open(responseDetails?.data?.paymentLink, '_blank');
      } else {
        yield put(storeErrorsForBridgeToCarrier(responseDetails?.data));
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (axiosError?.response?.status === 404) {
      yield put(storeErrorsForBridgeToCarrier(axiosError?.response?.data));
    }
    yield put(errorHandler(axiosError));
  }
  loaderData.loader = false;
  yield put(cyberBridgeToCarrierLoader(loaderData));
}

export function* getBridgeToCarrierDetailsForCyberSecurityWatcher() {
  yield takeEvery(
    cyberSecurityDetailsAction.GET_BRIDGE_TO_CARRIER_DETAILS_FOR_CYBER_SECRUITY,
    getBridgeToCarrierDetailsForCyberSecurity
  );
}

function* refreshCyberSecurityQuotesWorker() {
  const {
    cyberSecurityDetails: {isQuoteRefresh}
  } = yield select();
  if (!isQuoteRefresh) {
    yield put(
      storeCyberSecurityQuoteDetails({
        isQuoteRefresh: true,
      } as any)
    );
  }
  yield put(setLobRefreshLoader(true));
  yield put(initializeCyberSecurityPolling());
  yield call(pollingDelay, LOB_REFRESH_TIMEOUT);
  yield put(
    storeCyberSecurityQuoteDetails({
      isQuoteRefresh: false,
    } as any)
  );
  yield put(cyberSecurityTimeoutPolling());
  yield put(setLobRefreshLoader(false));
}

function* refreshCyberSecurityQuotesWatcher() {
  yield takeEvery(cyberSecurityDetailsAction.REFRESH_CYBERSECURITY_QUOTES, refreshCyberSecurityQuotesWorker);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCyberSecurityQuotesWatcher),
    fork(pollQuotesWatcher),
    fork(cyberSecurityPollingTimeoutWatcher),
    fork(stopPollingWatcher),
    fork(getBridgeToCarrierDetailsForCyberSecurityWatcher),
    fork(refreshCyberSecurityQuotesWatcher),
  ]);
}
