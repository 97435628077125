import omit from 'lodash/omit';

const saveState = (state: any) => {
  try {
    const serializedEncryptedState = omit(state, ['router']);
    sessionStorage.setItem('state', JSON.stringify(serializedEncryptedState));
  } catch (e) {}
};

export const loadState = () => {
  try {
    const serializedEncryptedState = sessionStorage.getItem('state');
    return JSON.parse(serializedEncryptedState as string);
  } catch (e) {
    return undefined;
  }
};

export const persistedState = loadState();

export const persistanceStateConfig = (store: any) => {
  store.subscribe(() => saveState(store.getState()));
};
