/* istanbul ignore file */
import * as HomeWarrantyActionType from '../constants';

export const setHomeWarrantyQuoteDetails = (payload: any): AppRedux.Action => ({
  type: HomeWarrantyActionType.SET_HOME_WARRANTY_QUOTES_DETAILS,
  payload,
});

export const fetchHomeWarrantyQuotes = (): AppRedux.Action => ({
  type: HomeWarrantyActionType.FETCH_HOME_WARRANTY_QUOTES,
});

export const homeWarrantyQuotesLoader = (payload: any): AppRedux.Action => ({
  type: HomeWarrantyActionType.HOME_WARRANTY_DETAILS_LOADER,
  payload,
});

export const storeHomeWarrantyQuoteList = (payload: any): AppRedux.Action => ({
  type: HomeWarrantyActionType.STORE_HOME_WARRANTY_QUOTE_LIST,
  payload,
});

export const clearHomeWarrantyState = (): AppRedux.Action => ({
  type: HomeWarrantyActionType.CLEAR_HOME_WARRANTY_STATE,
});
