/* istanbul ignore file */
import * as umbrellaAction from '../constants';

export const fetchUmbrellaQuotes = (): AppRedux.Action => ({
  type: umbrellaAction.FETCH_UMBRELLA_QUOTES,
});

export const umbrellaQuotesLoader = (payload: any): AppRedux.Action => ({
  type: umbrellaAction.UMBRELLA_DETAILS_LOADER,
  payload,
});

export const storeUmbrellaQuoteDetails = (payload: any): AppRedux.Action => ({
  type: umbrellaAction.STORE_UMBRELLA_QUOTE_DETAILS,
  payload,
});

export const setUmbrellaQuoteDetails = (payload: any): AppRedux.Action => ({
  type: umbrellaAction.SET_UMBRELLA_QUOTES_DETAILS,
  payload,
});

export const clearUmbrellaState = (): AppRedux.Action => ({
  type: umbrellaAction.CLEAR_UMBRELLA_STATE,
});

export const updateUmbrellaQuoteDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: umbrellaAction.UPDATE_UMBRELLA_QUOTE_DETAILS,
  payload,
});

export const setUmbrellaPollingErrorDetails = (
  payload: AppRedux.ActionPayload
): AppRedux.Action => ({
  type: umbrellaAction.SET_UMBRELLA_POLLING_ERROR_DETAILS,
  payload,
});

export const initializeUmbrellaPolling = (): AppRedux.Action => ({
  type: umbrellaAction.INIT_UMBRELLA_POLLING,
});

export const stopUmbrellaPolling = (): AppRedux.Action => ({
  type: umbrellaAction.STOP_UMBRELLA_POLLING,
});

export const umbrellaTimeoutPolling = (): AppRedux.Action => ({
  type: umbrellaAction.UMBRELLA_TIMEOUT_POLLING,
});

export const setUmbrellaPollingDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: umbrellaAction.SET_UMBRELLA_POLLING_DETAILS,
  payload,
});

export const setUmbrellaHerdId = (payload: string): AppRedux.Action => ({
  type: umbrellaAction.SET_UMBRELLA_HERD_ID,
  payload,
});

export const setUmbrellaInfoLoader = (payload: boolean): AppRedux.Action => ({
  type: umbrellaAction.SET_UMBRELLA_INFO_LOADER,
  payload,
});

export const storeUmbrellaInfo = (payload: any): AppRedux.Action => ({
  type: umbrellaAction.STORE_UMBRELLA_INFO,
  payload,
});

export const fetchUmbrellaQuestionListInfo = (): AppRedux.Action => ({
  type: umbrellaAction.FETCH_UMBRELLA_QUESTIONS_INFO,
});

export const refreshUmbrellaQuotes = (): AppRedux.Action => ({
  type: umbrellaAction.REFRESH_UMBRELLA_QUOTES,
});
