import React from 'react';
import PropertyInfoComponent from '../../components/property-info';

const PropertyInfo = ({
  onNextTab,
  onPrevTab,
  currentIndex,
  goToLastTab,
  tabsCount,
  quoteType,
  redirectToStep,
}: AppComponents.PropertyInfoWrapperProps) => {
  return (
    <div>
      <PropertyInfoComponent
        onNextTab={onNextTab}
        onPrevTab={onPrevTab}
        currentIndex={currentIndex}
        goToLastTab={goToLastTab}
        tabsCount={tabsCount}
        quoteType={quoteType}
        redirectToStep={redirectToStep}
      />
    </div>
  );
};

export default PropertyInfo;
