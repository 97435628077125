import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, has, isEmpty, map, sortBy, startCase } from 'lodash';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import { Add, CheckCircle, FiberManualRecord, Remove } from '@material-ui/icons';
import config from '../../../config/config';
import ConfirmationModal from '../confirmation-modal';
import RequestBindModel from '../request-bind-model';
import ThemesImages from '../themes-images';
import ViewAmsBoundData from '../view-bound-quote';
import ViewMore from './view-more';
import {
  AdmittedOrNonAdmittedDetails,
  DownloadQuote,
  ErrorQuoteCard,
  ErrorViewMoreCard,
  UpcomingCarrierDetails,
} from '../rate-page-common-component';
import { formatCurrency } from '../../home/utils';
import {
  formatNumberToCurrency,
  getCarrierAndPremiumRank,
  getIsAdmittedOrNonAdmittedCarrier,
  getIsDownloadQuoteEnabled,
  getIsHeapAnalyticsEnabled,
  getPremiumTerm,
  getQuoteAndErrQuoteCount,
  getQuoteCarrierErrorCategory,
  getSelectedQuote,
  numberFormatter,
  numberToCurrency,
  onBridgeToCarrierClick,
  trackMoreDetails,
} from '../../../utils';
import { formatPurchaseTypeText, getCarrierDeductible } from '../../../utils/rate-page';
import { getUmbrellaCoverage } from '../../../utils/umbrella';
import { trackCarrierBridging } from '../../redux/actions';
import PolicyBoundImageUrl from '../../../assets/images/policy-bound-ribbon.png';
import {
  BIND_REQUESTED,
  CANCEL,
  CARRIER,
  DIC_POLICY,
  HEAP_LABELS,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  MONTHLY,
  FLOOD_DIRECT_CARRIERS,
  FLOOD_PENALTY,
  NA_VALUE,
  PARAMETRIC_KEY,
  PLAN_DETAILS,
  POLICY_BOUND,
  PREMIUM,
  PRODUCT_MAPPING,
  QUOTING_STEPS_PAGES_NAME,
  REQUEST_BIND,
  UNDER_WRITER_ROLE,
  VIEW_LESS,
  VIEW_MORE,
} from '../../../constants';
import './products-rates.scss';

const RatesBlock = (props: any) => {
  const {
    quote,
    type,
    chooseCarrierOption,
    resetViewMore,
    ratePageType,
    ratePageTypes,
    premiumDetails,
    home,
    verifyChecked,
    onCheckboxChange,
    common,
    isQuoteWithMultiplePremium,
    isCheckboxChecked,
    handlePremiumPlanModal,
    singleFloodPremium,
    onCheckboxChangeForCyber,
    partner,
    openFloodPlanModal,
  }: AppComponents.RateBlockProps = props;
  let themeType = home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;
  let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
  let isUnderWriterProducerRole = !!partner?.role ? partner?.role : '';
  let showRequestBind: boolean = quote?.isWholeSale
    ? !isEmpty(isUnderWriterProducerRole) &&
      isUnderWriterProducerRole?.toLowerCase() === UNDER_WRITER_ROLE
      ? false
      : true
    : false;
  const [viewMore, setViewMore] = useState(false);
  const [selectedCarrierQuotePriceOption, setSelectedCarrierQuotePriceOption] = useState(0);
  const [selectedCarrierPremiumType, setSelectedCarrierPremiumType] = useState<any>('');
  const [carrierPremiumTerm, setCarrierPremiumTerm] = useState<any>('');
  const [disableCheckBox, setDisableCheckBox] = useState(false);
  const [boundedFloodQuotePrice, setBoundedFloodQuotePrice] = useState(0);
  const { header_coverages, floodQuote, earthquakeQuote } = quote;
  const isAutoQuote = type === INSURANCE_BUSINESS_TYPES.AUTO;
  const isPlaceholder = Object.prototype.hasOwnProperty.call(quote, 'placeholder');
  const quoteError = get(quote, 'quoteError', false);
  const quoteStatusError = get(quote, 'Status', false);
  const [rebuildingCost, setRebuildingCost] = useState('');
  const [personalPropertyCost, setPersonalPropertyCost] = useState('');
  const [liabilityCost, setLiabilityCost] = useState('');
  const [coverageLimit, setCoverageLimit] = useState('');
  const [viewDeductibles, setViewDeductibles] = useState({});
  const [viewOtherCoverages, setViewOtherCoverages] = useState({});
  const [petPlanDetails, setPetPlanDetails] = useState({});
  const [petsViewMoreList, setPetsViewMoreList] = useState([]);
  const [cyberPlanSelected, setCyberPlanSelected] = useState('');
  const [cyberViewMoreDetails, setCyberViewMoreDetails] = useState({});
  const [isOpenRequestBindModal, setIsOpenRequestBindModal] = useState(false);
  const [isOpenNonAdmittedConfirmationModal, setIsOpenNonAdmittedConfirmationModal] = useState<boolean>(false);
  const isPetQuote = type === INSURANCE_BUSINESS_TYPES.PET;
  const isHomeWarranty = type === INSURANCE_BUSINESS_TYPES.HOMEWARRANTY;
  const isUmbrellaQuote =  type === INSURANCE_BUSINESS_TYPES.UMBRELLA;
  const isFloodDirectCarrier = type === INSURANCE_BUSINESS_TYPES.FLOOD && FLOOD_DIRECT_CARRIERS.includes(quote?.carrier_id?.toString());
  const isAdmitted: boolean | undefined = getIsAdmittedOrNonAdmittedCarrier(quote);
  const getCarrierName = (quote: any) => quote.carrier_name || quote.name || quote?.Carrier;
  useEffect(() => {
    const { policyBounded } = common;
    if (
      (!!quote?.policyBounded && !!type) ||
      (!!policyBounded?.auto && type.toLowerCase() === INSURANCE_PRODUCTS[1]) ||
      (!!policyBounded?.home && type.toLowerCase() === INSURANCE_PRODUCTS[0]) ||
      (!!policyBounded?.flood && type.toLowerCase() === INSURANCE_PRODUCTS[2]) ||
      (!!policyBounded?.earthquake && type.toLowerCase() === INSURANCE_PRODUCTS[3])
    ) {
      setDisableCheckBox(true);
      const quotePrice: any = !!quote?.price ? !!quote?.price : premiumDetails?.premium;
      !boundedFloodQuotePrice && setBoundedFloodQuotePrice(quotePrice);
    }
    // eslint-disable-next-line
  }, [quote, setDisableCheckBox, common.policyBounded, type, premiumDetails]);

  useEffect(() => {
    if (type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY) {
      let selectedQuoteList = sessionStorage.selectedQuoteList
        ? JSON.parse(sessionStorage.selectedQuoteList)
        : [];
      let cyberSelectedPlan = '';
      if (selectedQuoteList) {
        const cyberQuote = selectedQuoteList.find((quote: any) => quote?.type === type);
        cyberSelectedPlan = cyberQuote && cyberQuote?.selectedPlan;
      }
      if (quote?.selectedPlan || cyberSelectedPlan) {
        setCyberPlanSelected(cyberSelectedPlan || quote?.selectedPlan);
      }
    }
    // eslint-disable-next-line
  }, [quote?.selectedPlan, premiumDetails]);

  useEffect(() => {
    setViewMore(false);
  }, [resetViewMore]);

  const setCoverageValues = (coverageTypeValue?: any) => {
    let rebuildingCost = '';
    let personalPropertyCost = '';
    let liabilityCost = '';
    if (type === INSURANCE_BUSINESS_TYPES.QUAKE) {
      rebuildingCost = formatCurrency(premiumDetails?.dwelling, get(header_coverages, 'cov_a', ''));
      personalPropertyCost = formatCurrency(
        premiumDetails?.personal_property,
        get(header_coverages, 'cov_c', '')
      );
      liabilityCost = formatCurrency(
        premiumDetails?.other_structures,
        get(header_coverages, 'cov_e', '')
      );
      const otherCoverages: any = {
        loss_of_use: premiumDetails?.loss_of_use,
        building_code_upgrade: premiumDetails?.building_code_upgrade,
        loss_assessment: premiumDetails?.loss_assessment,
      };
      setViewDeductibles(premiumDetails?.deductible);
      setViewOtherCoverages(otherCoverages);
      if (premiumDetails?.coverageType === PARAMETRIC_KEY) {
        setCoverageLimit(formatCurrency(premiumDetails?.coverage_limit, ''));
      }
    } else if (type === INSURANCE_BUSINESS_TYPES.PET) {
      const availablePlans =
        quote?.available_plans &&
        premiumDetails?.plan &&
        quote?.available_plans[premiumDetails?.plan?.toLowerCase()];
      const petList: any =
        premiumDetails?.quotesDetails &&
        premiumDetails?.quotesDetails?.length &&
        sortBy(premiumDetails?.quotesDetails, ['order']);
      setPetsViewMoreList(petList ? petList : []);
      availablePlans && setPetPlanDetails(availablePlans);
    } else if (
      type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
      (premiumDetails?.coverageType || coverageTypeValue)
    ) {
      const selectedQuote = getSelectedQuote(type);
      const coverageDetails =
        quote?.coverages[
          selectedQuote?.quote?.selectedPremiumType ||
            coverageTypeValue ||
            premiumDetails?.coverageType
        ];
      !isEmpty(coverageDetails) && setCyberViewMoreDetails(coverageDetails);
    } else {
      rebuildingCost = formatCurrency(
        quote.cov_a || quote?.coverages?.coverage_a,
        get(header_coverages, 'cov_a', '')
      );
      personalPropertyCost = formatCurrency(
        quote.cov_c || quote?.coverages?.coverage_c,
        get(header_coverages, 'cov_c', '')
      );
      liabilityCost = formatCurrency(
        quote.cov_e || quote?.coverages?.coverage_e,
        get(header_coverages, 'cov_e', '')
      );
    }
    setRebuildingCost(rebuildingCost);
    setPersonalPropertyCost(personalPropertyCost);
    setLiabilityCost(liabilityCost);
  };

  useEffect(() => {
    let coverageTypeValue = '';
    if (isQuoteWithMultiplePremium) {
      if (
        type === INSURANCE_BUSINESS_TYPES.QUAKE ||
        type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
      ) {
        coverageTypeValue = premiumDetails?.coverageType ? premiumDetails?.coverageType : '';
        setSelectedCarrierPremiumType(coverageTypeValue);
      } else if (type === INSURANCE_BUSINESS_TYPES.PET) {
        setSelectedCarrierPremiumType(premiumDetails?.plan ? premiumDetails?.plan : '');
      } else if (
        type === INSURANCE_BUSINESS_TYPES.LIFE ||
        type === INSURANCE_BUSINESS_TYPES.UMBRELLA
      ) {
        setCarrierPremiumTerm(premiumDetails?.term ? premiumDetails?.term : '');
        setSelectedCarrierPremiumType(premiumDetails?.coverage ? premiumDetails?.coverage : '');
      }
      setSelectedCarrierQuotePriceOption(premiumDetails?.premium);
    }
    setCoverageValues(coverageTypeValue);
    // eslint-disable-next-line
  }, [premiumDetails?.premium, premiumDetails?.plan, premiumDetails?.coverageType]);

  useEffect(() => {
    if (isQuoteWithMultiplePremium) {
      quote.price = premiumDetails?.premium
        ? premiumDetails?.premium
        : selectedCarrierQuotePriceOption;
      quote.selectedPremiumType = selectedCarrierPremiumType;
      quote.term = carrierPremiumTerm;
      if (ratePageType !== ratePageTypes.navigation && isCheckboxChecked) {
        chooseCarrierOption();
      }
    }
    // eslint-disable-next-line
  }, [selectedCarrierQuotePriceOption]);

  const activateBindAction = (q: any): boolean => {
    if (!q.enabled) {
      return !get(q, 'quote_agent_url', '') && !get(q, 'agent_url', '');
    } else {
      return !get(q, 'link', '');
    }
  };

  const getCoverageValue = (coverageValue: any): number | string => {
    let value: number | string = 0;
    if (
      String(coverageValue).toLowerCase() === 'none' ||
      String(coverageValue).toLowerCase() === 'no coverage'
    ) {
      value = '-NA-';
    } else {
      if (
        Number.isInteger(parseInt(coverageValue)) &&
        !String(coverageValue).includes('/') &&
        !String(coverageValue).includes('CSL')
      ) {
        value = `$${numberFormatter(String(coverageValue).replace(/,/g, ''))}`;
      } else {
        value = coverageValue;
      }
    }
    return value;
  };

  const bindHandler = (quote: any) => {
    const { enabled } = quote;
    if (enabled) {
      window.open(!isEmpty(quote.link) ? quote.link : '', '_blank');
    } else {
      if (has(quote, 'agent_url')) {
        window.open(get(quote, 'agent_url', ''), '_blank');
      } else {
        window.open(get(quote, 'quote_agent_url', ''), '_blank');
      }
    }
  };

  const showViewMoreSection = () => {
    let selectedQuoteList = sessionStorage.selectedQuoteList
      ? JSON.parse(sessionStorage.selectedQuoteList)
      : [];
    if (
      type === INSURANCE_BUSINESS_TYPES.QUAKE ||
      type === INSURANCE_BUSINESS_TYPES.PET ||
      type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
    ) {
      return selectedQuoteList.find((quote: any) => quote?.type === type);
    } else {
      return true;
    }
  };

  const getPurchaseIcon = () => {
    if (showRequestBind) {
      return !!quote?.isBindRequested
      ? ThemesImages[themeType].requestedBindIcon
      : ThemesImages[themeType].requestBindIcon;
    } else if (quote.enabled || (quote.carrier_id && parseInt(quote.carrier_id) === 1)) {
      return ThemesImages[themeType].creditCard;
    }
    return ThemesImages[themeType].bridgeIcon;
  };

  const policyBoundPremium = (premiumValue: any, carrierId: any) => {
    let lobType = type.toLowerCase();
    let policyBounded = !!common?.boundedPolicyDetails[lobType]
      ? common?.boundedPolicyDetails[lobType]
      : '';
    return (
      (premiumValue === boundedFloodQuotePrice &&
        lobType !== INSURANCE_PRODUCTS[2] &&
        lobType !== INSURANCE_PRODUCTS[3]) ||
      (!!policyBounded &&
        premiumValue === Number(policyBounded?.premium) &&
        policyBounded?.carrierId?.toString() === quote?.carrier_id?.toString()) ||
      premiumValue ===
        (!!common?.selectedCarriers[lobType] &&
          Number(common?.selectedCarriers[lobType]?.premium) &&
          common?.selectedCarriers[lobType]?.carrierId?.toString() ===
            quote?.carrier_id?.toString())
    );
  };

  const getCarrierPriceElements = (details: any, premiumLength: number) => {
    const premiumValue = details.premium;
    const { term, coverage, termLabel, coverageLabel } = details;
    return premiumValue ? (
      <FormControlLabel
        className={`${
          type === INSURANCE_BUSINESS_TYPES.UMBRELLA && premiumLength > 2
            ? 'rli-form-control-price'
            : 'form-control-price'
        }  
       `}
        value={premiumValue}
        control={
          <Checkbox
            icon={<FiberManualRecord />}
            checkedIcon={<CheckCircle className='check-icon' />}
            name='checkedH'
            checked={
              policyBoundPremium(premiumValue, details?.carrier_id)
                ? true
                : verifyChecked
                ? verifyChecked(premiumValue)
                : false
            }
            onChange={onCheckboxChange ? (e: any) => onCheckboxChange(e, details) : undefined}
            disabled={disableCheckBox}
          />
        }
        label={
          <>
          <div className='flood-rates-price-wraps'>
            <span className='premium-value-label text-center'>
              {isFloodDirectCarrier ? `${startCase(details?.plan)}` : `${'Price'}`}
            </span>
            <span className='premium-value text-center'>{` ${formatNumberToCurrency(
              premiumValue
            )}`}</span>
            {type === INSURANCE_BUSINESS_TYPES.LIFE ||
            type === INSURANCE_BUSINESS_TYPES.UMBRELLA ? (
              <span className='premium-value-label text-center'>
                {`${termLabel ? termLabel : MONTHLY}`}
              </span>
            ) : (
              ''
            )}
            {type !== INSURANCE_BUSINESS_TYPES.UMBRELLA ? (
              <span className='premium-value-label text-center'>
                {type === INSURANCE_BUSINESS_TYPES.LIFE
                  ? `$${numberToCurrency(coverage)} / ${getPremiumTerm(term)}`
                  : getPremiumTerm(term)}
              </span>
            ) : (
              <span className='premium-value-label text-center'>
                {`$${numberToCurrency(details?.limit)} / $${numberToCurrency(details?.deductible)}`}
              </span>
            )}
            {type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ? (
              <span className='light-label text-center'>{`${coverageLabel}`}</span>
            ) : (
              ''
            )}
          </div>
          <div className='quake-cov-label'>
            <span className='premium-value-label text-center'>
              {getCarrierDeductible(quote, details, type)}
            </span>
          </div>
          </>
        }
        key={`flood_${premiumValue}`}
      />
    ) : (
      <span className='na-value'>{NA_VALUE}</span>
    );
  };

  const onCarrierOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // just for convenience to use it in another place
    quote.price = parseFloat(e.target.value);
    setSelectedCarrierQuotePriceOption(quote.price);
    setCoverageValues();
    chooseCarrierOption();
  };

  const drawPremiumOptions = (sortedPremiumDetails: any) => (
    <>
      <Grid container spacing={2} direction='row' justify='center' alignItems='center'>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              className='price-radio-button'
              value={selectedCarrierQuotePriceOption}
              onChange={onCarrierOptionChange}
            >
              {map(sortedPremiumDetails, (details: any) =>
                getCarrierPriceElements(details, sortedPremiumDetails?.length)
              )}
            </RadioGroup>{' '}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );

  const drawOneElementCarrierOption = (sortedPremiumDetails: any) =>
    sortedPremiumDetails.length ? (
      <>
        <Typography className='price-label'>Price</Typography>
        <Typography className='price-amount'>{formatNumberToCurrency(quote.price)}</Typography>
        <Typography className='price-label'>Annually</Typography>
      </>
    ) : (
      <span className='na-value'>{NA_VALUE}</span>
    );

  const getCarrierPrice = () => {
    const sortedPremiumDetails = sortBy(quote.premiumDetails, ['premium']);
    return sortedPremiumDetails.length > 1 || floodQuote || earthquakeQuote || isUmbrellaQuote
      ? drawPremiumOptions(sortedPremiumDetails)
      : drawOneElementCarrierOption(sortedPremiumDetails);
  };

  const getCarrierQuotePrice = () =>
    premiumDetails.premium ? (
      <>
        <Typography className='price-label'>Price</Typography>
        <Typography className='price-amount'>
          {formatNumberToCurrency(premiumDetails.premium)}
        </Typography>
        {type === INSURANCE_BUSINESS_TYPES.LIFE || type === INSURANCE_BUSINESS_TYPES.UMBRELLA ? (
          <span className='price-label'>
            {`${premiumDetails?.termLabel ? premiumDetails?.termLabel : MONTHLY}`}
          </span>
        ) : (
          ''
        )}
        <span className='price-label'>
          {type === INSURANCE_BUSINESS_TYPES.LIFE
            ? `$${numberToCurrency(premiumDetails?.coverage)} / ${getPremiumTerm(premiumDetails?.term)}`
            : getPremiumTerm(premiumDetails?.term)}
        </span>
        {type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ? (
          <span className='light-label'>{`${premiumDetails?.coverageLabel}`}</span>
        ) : (
          ''
        )}
      </>
    ) : (
      <span className='na-value'>{NA_VALUE}</span>
    );

  const getFloodDeductibles = () => {
    if (type === INSURANCE_BUSINESS_TYPES.QUAKE) {
      return viewDeductibles;
    } else {
      const sortedPremiumDetails = sortBy(quote.premiumDetails, ['premium']);
      return map(sortedPremiumDetails, (details: any) => details.deductible);
    }
  };

  const getHomeDeductibles = () => quote.deductibles || quote?.coverages?.deductibles || [];

  const getHomeQuotePrice = () => (
    <>
      {!!quote?.dicPresent && quote?.dicPresent === DIC_POLICY.YES && (
        <Typography className='dic-polices'>{DIC_POLICY.LABEL}</Typography>
      )}
      <Typography className='price-label'>Price</Typography>
      <Typography className='price-amount'>{formatNumberToCurrency(quote.price)}</Typography>
      <Typography className='price-label'>{isAutoQuote ? quote.term_lable : `Annually`}</Typography>
    </>
  );

  const checkIfQuoteButtonIsActiveAndProceed = (details?: any) => {
    let trackingPayload: any = {
      carrierId: quote?.carrier_id,
      carrierName: quote.carrier_name || quote.name,
    };
    let lob = !!floodQuote
      ? INSURANCE_PRODUCTS[2]
      : !!earthquakeQuote
      ? INSURANCE_PRODUCTS[3]
      : !!isAutoQuote
      ? INSURANCE_PRODUCTS[1]
      : !!isPetQuote
      ? INSURANCE_PRODUCTS[4]
      : isUmbrellaQuote
      ? INSURANCE_PRODUCTS[8]
      : INSURANCE_PRODUCTS[0];
    lob = PRODUCT_MAPPING[type] || lob;
    lob = lob?.toLowerCase();
    const selectedQuoteDetails = getSelectedQuote(lob);
    if (floodQuote || earthquakeQuote || isHomeWarranty || isUmbrellaQuote) {
      let bridgeUrl = '';
      if (has(details, 'link')) {
        bridgeUrl = get(details, 'link', '');
      } else if ((isHomeWarranty || isUmbrellaQuote) && get(quote, 'bridgeUrl', '')) {
        bridgeUrl = get(quote, 'bridgeUrl', '');
      }
      if (bridgeUrl) {
        window.open(bridgeUrl, '_blank');
      }
      trackingPayload.premium =
        !!selectedQuoteDetails?.quote?.price &&
        quote?.carrier_id?.toString() === selectedQuoteDetails?.quote?.carrier_id?.toString()
          ? selectedQuoteDetails?.quote?.price
          : null;
    } else {
      if (!activateBindAction(quote)) {
        bindHandler(quote);
      }
      trackingPayload.premium = !!quote?.price ? quote.price?.toString() : null;
    }
    let getQuoteCount = getQuoteAndErrQuoteCount(lob);
    let quoteDetails = getCarrierAndPremiumRank(lob, quote?.carrier_id, quote?.price);
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_BRIDGE_TO_CARRIER, {
        [HEAP_LABELS?.PRODUCT]: !isEmpty(lob) ? startCase(lob?.toLowerCase()) : '',
        [HEAP_LABELS.NO_OF_PRODUCT_QUOTES]: getQuoteCount.quoteCount,
        [HEAP_LABELS.NO_OF_PRODUCT_NON_RETURN_QUOTE]: getQuoteCount.errorCount,
        [PREMIUM]: !!quote?.price ? quote.price?.toString() : null,
        [HEAP_LABELS.RESULT_RANK]: quoteDetails?.quoteRank || 0,
        [HEAP_LABELS.PREMIUM_RANK]: quoteDetails?.premiumRank || 0,
        [CARRIER]: quote?.carrierName || quote?.name || quote?.carrier_name || '',
        [HEAP_LABELS?.PRODUCT_COUNT]: getQuoteCount?.productCount,
      });
    }
    props.trackCarrierBridging({ ...trackingPayload });
  };

  const onBridgeToCarrierHandler = () => {
    if (isAdmitted !== undefined && !isAdmitted) {
      setIsOpenNonAdmittedConfirmationModal(true);
    } else {
      return onClickPurchaseLink();
    }
  };

  const onClickPurchaseLink = () => {
    if (!!quote?.isUpcomingCarrier) {
      return bindHandler(quote);
    } else if (
      !!isPetQuote ||
      type === INSURANCE_BUSINESS_TYPES.LIFE ||
      type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ||
      showRequestBind ||
      (quote?.carrier_id &&
        config?.floodBridgingCarriersList?.includes(quote?.carrier_id?.toString()))
    ) {
      if (!!quote?.bridgeToCarrierLoader || !!common?.selectedCarrierLoader) {
        return '';
      } else if (showRequestBind) {
        if (!!quote?.isBindRequested) {
          return setIsOpenRequestBindModal(false);
        }
        return setIsOpenRequestBindModal(true);
      } else {
        return onBridgeToCarrierClick(quote, type, false)
      }
    } else {
      return checkIfQuoteButtonIsActiveAndProceed(premiumDetails);
    }
  };

  const onClickingBridgeToCarrierClick = () => {
    if (isAdmitted !== undefined && !isAdmitted) {
      return setIsOpenNonAdmittedConfirmationModal(true);
    }
    return onClickPurchaseLink()
  };

  const getQuotePurchaseButtonElement = () => {
    let isRequestedBind: boolean = (showRequestBind && !!quote?.isBindRequested) || false; 
    if (
      floodQuote ||
      earthquakeQuote ||
      type === INSURANCE_BUSINESS_TYPES.PET ||
      type === INSURANCE_BUSINESS_TYPES.HOMEWARRANTY ||
      type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ||
      type === INSURANCE_BUSINESS_TYPES.LIFE ||
      type === INSURANCE_BUSINESS_TYPES.UMBRELLA
    ) {
      return (
        <div onClick={() => onClickingBridgeToCarrierClick()}>
          {/* eslint-disable-next-line */}
          <a
            className={
              ratePageType === ratePageTypes.navigation && isQuoteWithMultiplePremium
                ? 'linkButtonFlood'
                : ''
            }
          >
            <img
              src={getPurchaseIcon()}
              alt='credit card'
              className={`${showRequestBind ? 'mb-13' : 'mb-1'} ${
                !!isRequestedBind ? 'cursor-default' : 'cursor-pointer'
              } img-fluid carrier-img ${
                ratePageType === ratePageTypes.navigation && isQuoteWithMultiplePremium
                  ? 'imgButtonFlood'
                  : ''
              }`}
            />
            <Typography
              className={`${
                !!isRequestedBind
                  ? 'cursor-default requested-bind-label'
                  : 'cursor-pointer purchase-online-label'
              } position-relative`}
            >
              <span>
                {!quote?.bridgeToCarrierLoader &&
                  (showRequestBind
                    ? !!quote?.isBindRequested
                      ? BIND_REQUESTED
                      : REQUEST_BIND
                    : formatPurchaseTypeText(quote?.purchase_type))}
                {!!quote?.bridgeToCarrierLoader && (
                  <>
                    &nbsp;
                    <CircularProgress size={20} className='submit-loader' />
                  </>
                )}
              </span>
            </Typography>
          </a>
        </div>
      );
    } else {
      return (
        <>
          {/* eslint-disable-next-line */}
          <a
            className={`${
              ratePageType === ratePageTypes.navigation &&
              isQuoteWithMultiplePremium &&
              !!quote?.isUpcomingCarrier
                ? 'linkButtonFlood'
                : ''
            }`}
            onClick={() =>
              showRequestBind
                ? !!quote?.isBindRequested
                  ? setIsOpenRequestBindModal(false)
                  : setIsOpenRequestBindModal(true)
                : onBridgeToCarrierHandler()
            }
          >
            <img
              src={getPurchaseIcon()}
              alt='credit card'
              className={`${showRequestBind ? 'mb-13' : 'mb-1'} ${
                !!isRequestedBind ? 'cursor-default' : 'cursor-pointer'
              } img-fluid carrier-img pointer ${
                ratePageType === ratePageTypes.navigation &&
                isQuoteWithMultiplePremium &&
                !!quote?.isUpcomingCarrier
                  ? 'imgButtonFlood'
                  : ''
              }`}
            />
            <Typography
              className={`${
                !!isRequestedBind
                  ? 'cursor-default requested-bind-label'
                  : 'cursor-pointer purchase-online-label'
              }  pointer`}
            >
              {!!quote?.bridgeToCarrierLoader ? (
                <>
                  &nbsp;
                  <CircularProgress size={20} className='submit-loader' />
                </>
              ) : showRequestBind ? (
                !!quote?.isBindRequested ? (
                  BIND_REQUESTED
                ) : (
                  REQUEST_BIND
                )
              ) : (
                formatPurchaseTypeText(quote?.purchase_type)
              )}
            </Typography>
          </a>
        </>
      );
    }
  };

  const openViewMore = () => {
    setViewMore(!viewMore);
    if (isHeapAnalyticsEnabled) {
      trackMoreDetails(type, quote);
    }
  }

  const nonAdmittedConfirmationHandler = () => {
    setIsOpenNonAdmittedConfirmationModal(false);
    if (
      quote?.carrier_id &&
      config?.floodBridgingCarriersList?.includes(quote?.carrier_id?.toString())
    ) {
      onBridgeToCarrierClick(quote, type, false);
    } else {
      onClickPurchaseLink();
    }
  };

  const getQuotePrice = () =>
    !floodQuote &&
    !earthquakeQuote &&
    type !== INSURANCE_BUSINESS_TYPES.PET &&
    type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
    type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
    type !== INSURANCE_BUSINESS_TYPES.LIFE &&
    type !== INSURANCE_BUSINESS_TYPES.UMBRELLA
      ? getHomeQuotePrice()
      : ratePageType !== ratePageTypes.navigation
      ? getCarrierQuotePrice()
      : getCarrierPrice();
  const liabilityBI = isAutoQuote ? getCoverageValue(quote?.cov_bi) : null;
  const propertyDamage = isAutoQuote ? getCoverageValue(quote?.cov_pd) : null;
  const medicalPayments = isAutoQuote ? getCoverageValue(quote?.cov_mp) : null;
  const [openViewBoundQuote, setViewBoundQuote] = useState(false);

  const openPolicyBoundDetailsModal = () => {
    setViewBoundQuote(true);
  };
  const errorCategory: string = getQuoteCarrierErrorCategory(quote) || '';
  const isDownloadQuoteEnabled: boolean = getIsDownloadQuoteEnabled(type);
  const isShowBridgeToCarrierForUpcomingCarrier: boolean =
    !!quote?.isUpcomingCarrier && !isEmpty(quote?.agent_url);
  return (
    <div
      className={ratePageType === ratePageTypes.navigation ? 'wrapper-rates-block-inner w-100' : ''}
    >
      {!!quote?.policyBounded &&
        ratePageType === ratePageTypes.navigation &&
        !floodQuote &&
        !earthquakeQuote &&
        type !== INSURANCE_BUSINESS_TYPES.PET &&
        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
        type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA && (
          <div className='row'>
            {!!openViewBoundQuote && (
              <ViewAmsBoundData
                open={openViewBoundQuote}
                onClose={() => setViewBoundQuote(false)}
                lob={type}
              />
            )}
            <div className='col-lg-12'>
              <img
                src={PolicyBoundImageUrl}
                alt={POLICY_BOUND}
                className='img-fluid position-absolute policy-bound-label'
                onClick={() => openPolicyBoundDetailsModal()}
              />
            </div>
          </div>
        )}
      <div
        className={`w-100 rates-block-inner bg-light-green d-flex align-items-center ${
          ratePageType === ratePageTypes.navigation
            ? 'rates-block-inner__height'
            : type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
            ? 'mb-2'
            : ''
        } ${
          quote.isHippoQuote ||
          (quote.carrier_id && parseInt(quote.carrier_id) === 1) ||
          (quote.CarrierId && parseInt(quote.CarrierId) === 1)
            ? 'bg-light-green'
            : 'bg-light-blue'
        }`}
      >
        <div className='row m-0 w-100'>
          <div
            className={`product-logo-div mb-20  d-flex justify-content-center align-items-center ${
              ratePageType === ratePageTypes.navigation &&
              isQuoteWithMultiplePremium &&
              !singleFloodPremium
                ? 'product-logo-column'
                : 'flex-column'
            } ${type === INSURANCE_BUSINESS_TYPES.UMBRELLA ? 'col-lg-3' : 'col-lg-4'}`}
          >
            <AdmittedOrNonAdmittedDetails quote={quote} lob={type} />
            <div className='carrier-logo-div'>
              <img
                src={get(quote, 'logo_url', '')}
                alt={getCarrierName(quote)}
                title={getCarrierName(quote)}
                className='img-fluid'
              />
            </div>
            {ratePageType === ratePageTypes.navigation &&
              isQuoteWithMultiplePremium &&
              ((!quote.quoteError && !quote?.isUpcomingCarrier && !singleFloodPremium) ||
                isShowBridgeToCarrierForUpcomingCarrier) && (
                <div
                  className='d-flex align-items-center h-100 justify-content-center'
                  style={{ cursor: 'pointer' }}
                >
                  {getQuotePurchaseButtonElement()}
                </div>
              )}
          </div>
          {!isPlaceholder && !quoteError && !quoteStatusError ? (
            <>
              <div
                className={`${
                  ratePageType === ratePageTypes.navigation &&
                  isQuoteWithMultiplePremium &&
                  type === INSURANCE_BUSINESS_TYPES.UMBRELLA
                    ? 'col-lg-9'
                    : isQuoteWithMultiplePremium
                    ? 'col-lg-8 '
                    : 'col-lg-4'
                } text-center price-wraper`}
              >
                <div className='center-div price-list-wrap'>{getQuotePrice()}</div>
              </div>
              {(ratePageType === ratePageTypes.carousel ||
                (ratePageType === ratePageTypes.navigation &&
                  (type !== INSURANCE_BUSINESS_TYPES.FLOOD || singleFloodPremium) &&
                  type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
                  type !== INSURANCE_BUSINESS_TYPES.PET &&
                  type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
                  type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
                  type !== INSURANCE_BUSINESS_TYPES.LIFE &&
                  type !== INSURANCE_BUSINESS_TYPES.UMBRELLA)) &&
                !quote.quoteError && (
                  <div
                    className={`col-lg-4 mb-20 text-center ${
                      ratePageType === ratePageTypes.navigation ? 'purchaseButtonFlood' : ''
                    }`}
                  >
                    <div className='d-flex align-items-center purchase-button-height justify-content-center'>
                      {getQuotePurchaseButtonElement()}
                    </div>
                  </div>
                )}
            </>
          ) : (
            <>
              {!!quote?.isUpcomingCarrier ? (
                <UpcomingCarrierDetails quote={quote} lob={type} />
              ) : (
                <div className='col-lg-8 mb-20 justify-content-center price-wraper d-flex align-items-center'>
                  <ErrorQuoteCard quote={quote} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!quoteError &&
        !quoteStatusError &&
        showViewMoreSection() &&
        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA && (
          <div className='row w-100 mx-auto view-more-button-div'>
            <div className='col-lg-4'>
              <div
                className={`${
                  type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                    ? 'm-display'
                    : 'blnk-select-view-more'
                }`}
              >
                {type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ? (
                  <div className='blnk-select-field'>
                    <FormControl className='w-100' variant='outlined'>
                      <Select
                        native
                        value={cyberPlanSelected}
                        inputProps={{
                          name: 'cyberPlans',
                          id: 'cyberPlans',
                        }}
                        onChange={
                          onCheckboxChangeForCyber
                            ? (e: any) => onCheckboxChangeForCyber(e)
                            : undefined
                        }
                      >
                        {map(quote?.availablePlans, data => (
                          <option key={`cyberPlans_${data.value}`} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div
              className={`d-md-flex ${
                (type === INSURANCE_BUSINESS_TYPES.PET &&
                  ratePageType === ratePageTypes.carousel) ||
                type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                  ? `col-lg-8 ${ratePageType === ratePageTypes.carousel ? 'p-0' : ''}`
                  : 'col-lg-8 '
              }`}
            >
              {type === INSURANCE_BUSINESS_TYPES.PET && (
                <div className='width-50'>
                  <Typography
                    className='plan-details-label mt-2 mb-2'
                    onClick={() => handlePremiumPlanModal()}
                  >
                    {PLAN_DETAILS}
                  </Typography>
                </div>
              )}
              {type === INSURANCE_BUSINESS_TYPES.FLOOD &&
                FLOOD_DIRECT_CARRIERS.includes(quote?.carrier_id?.toString()) && (
                  <div className='width-50'>
                    <Typography
                      className='plan-details-label mt-2 mb-2'
                      onClick={() => openFloodPlanModal()}
                    >
                      {PLAN_DETAILS}
                    </Typography>
                  </div>
                )}
              {!isFloodDirectCarrier && (
                <div
                  className={`view-more-item resposive-mt-2 ${
                    type === INSURANCE_BUSINESS_TYPES.PET
                      ? 'width-50 justify-content-end'
                      : `${
                          type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                            ? 'w-100 d-flex jc-proper'
                            : !!isDownloadQuoteEnabled
                            ? 'view-more-item-responsive'
                            : 'w-100 d-flex display-view'
                        }`
                  }`}
                >
                  <Typography
                    className={`label-view-more ${
                      type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ? 'mt-0 text-center' : 'mt-2'
                    }`}
                    onClick={() => openViewMore()}
                  >
                    {viewMore ? (
                      <>
                        <Remove className='mr-2 add-icon' />
                        {VIEW_LESS}
                      </>
                    ) : (
                      <>
                        <Add className='mr-2 add-icon' />
                        {VIEW_MORE}
                      </>
                    )}
                  </Typography>
                  {!!isDownloadQuoteEnabled && <DownloadQuote quote={quote} lob={type} />}
                </div>
              )}
            </div>
          </div>
        )}
      {!quoteStatusError &&
        !quoteError &&
        !showViewMoreSection() &&
        type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY && (
          <div className='row w-100 mx-auto view-more-button-div'>
            <div className='col-lg-4'>
              <div
                className={`${
                  type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                    ? 'm-display'
                    : 'blnk-select-view-more'
                }`}
              >
                {type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ? (
                  <div className='blnk-select-field'>
                    <FormControl className='w-100' variant='outlined'>
                      <Select
                        native
                        value={cyberPlanSelected}
                        inputProps={{
                          name: 'cyberPlans',
                          id: 'cyberPlans',
                        }}
                        onChange={
                          onCheckboxChangeForCyber
                            ? (e: any) => onCheckboxChangeForCyber(e)
                            : undefined
                        }
                      >
                        {map(quote?.availablePlans, data => (
                          <option key={`cyberPlans_${data.value}`} value={data.value}>
                            {data.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div
              className={`d-md-flex ${
                (type === INSURANCE_BUSINESS_TYPES.PET &&
                  ratePageType === ratePageTypes.carousel) ||
                type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                  ? `col-lg-8 ${ratePageType === ratePageTypes.carousel ? 'p-0' : ''}`
                  : 'col-lg-8 '
              }`}
            >
              <div
                className={`view-more-item resposive-mt-2 ${
                  type === INSURANCE_BUSINESS_TYPES.PET
                    ? 'width-50'
                    : `${
                        type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
                          ? 'w-100 d-flex jc-proper'
                          : !!isDownloadQuoteEnabled
                          ? 'view-more-item-responsive'
                          : 'w-100 d-flex display-view'
                      }`
                }`}
              ></div>
            </div>
          </div>
        )}
      {viewMore &&
        !quoteError &&
        !quoteStatusError &&
        showViewMoreSection() &&
        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA && (
          <div className='row m-0'>
            <div className='col-lg-12 view-more-section pl-0 pr-0'>
              <ViewMore
                rebuildingCost={rebuildingCost}
                quote={quote}
                personalPropertyCost={personalPropertyCost}
                liabilityCost={liabilityCost}
                deductibles={
                  floodQuote || earthquakeQuote ? getFloodDeductibles() : getHomeDeductibles()
                }
                isFlood={floodQuote}
                isQuake={earthquakeQuote}
                isPet={type === INSURANCE_BUSINESS_TYPES.PET}
                liabilityBI={liabilityBI}
                propertyDamage={propertyDamage}
                medicalPayments={medicalPayments}
                isAuto={isAutoQuote}
                appliedDiscounts={quote.applied_discounts ? quote.applied_discounts : []}
                viewOtherCoverages={viewOtherCoverages}
                selectedPremiumType={selectedCarrierPremiumType}
                coverageLimit={coverageLimit}
                petPlanDetails={petPlanDetails}
                petsViewMoreList={petsViewMoreList}
                singleFloodPremium={singleFloodPremium}
                cyberViewMoreDetails={cyberViewMoreDetails}
                isCyberSecurity={type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY}
                lob={type}
                isFloodDirectCarrier={isFloodDirectCarrier}
              />
            </div>
          </div>
        )}
      {/* Error quote view more card */}
      {!isEmpty(errorCategory) && <ErrorViewMoreCard quote={quote} />}
      {type === INSURANCE_BUSINESS_TYPES.UMBRELLA && !quoteError && (
        <div className='text-center coverage-for-one-lob'>
          <span>{getUmbrellaCoverage(quote)}</span>
        </div>
      )}

      {isOpenRequestBindModal && (
        <RequestBindModel
          isOpen={isOpenRequestBindModal}
          onClose={setIsOpenRequestBindModal}
          quote={quote}
          lob={type}
        />
      )}
      <ConfirmationModal
        open={isOpenNonAdmittedConfirmationModal}
        closehandler={(isDiscard: boolean) => {
          !isDiscard
            ? setIsOpenNonAdmittedConfirmationModal(false)
            : nonAdmittedConfirmationHandler();
        }}
        isHideHeader
        message={
          <Typography className='non-admitted-confirmation-text'>
            {FLOOD_PENALTY.NON_ADMITTED_CONFIRMATION_TEXT}
          </Typography>
        }
        contentValue=''
        noText={CANCEL}
        yesText={FLOOD_PENALTY.NON_ADMITTED_CONFIRMATION_BUTTON_TEXT}
      />
    </div>
  );
};

const mapStateToProps = ({ auth, common, home, petDetails, partner }: AppComponents.RateBlocksStore) => ({
  auth,
  common,
  home,
  petDetails,
  partner,
});

export default connect(mapStateToProps, { trackCarrierBridging })(RatesBlock);
