import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { getPropertyInfoDetaills, setApplicantDetails } from '../../../redux/actions';
import { getBannerDetailsByPrefillStatus, getLobHerdId } from '../../../../utils';
import { LOB_PRODUCTS_MAPPING, PREFILL_RETRIES_MAX_COUNT, RETRY } from '../../../../constants';
import './property-info-status-banner.scss';
import SpinnerLoader from '../../spinner-loader';

export enum PrefillStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  INCOMPLETE = 'incomplete',
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  submitSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
}));

const PrefillInfoStatusBanner = (props: AppComponents.PrefillInfoStatusBannerProps) => {
  const {
    applicant: { noOfRetries, prefillStatus, propertyInfoLoader },
    isShowRetryButton,
  } = props;
  const bannerDetails = prefillStatus ? getBannerDetailsByPrefillStatus(prefillStatus) : null;
  const classes = useStyles();

  return (
    <div className='property-info-status-banner-wrapper'>
      {!!bannerDetails && (
        <div className={`mb-4 ${classes.root}`}>
          <Alert
            severity={bannerDetails?.className}
            variant='filled'
            icon={bannerDetails?.icon}
            className='property-info-status-banner'
            action={
              <>
                {bannerDetails?.isRetryButtonVisible &&
                  noOfRetries < PREFILL_RETRIES_MAX_COUNT &&
                  isShowRetryButton && (
                    <Button
                      variant='contained'
                      color='default'
                      size='small'
                      className='retry-button'
                      onClick={() => {
                        props.getPropertyInfoDetaills({
                          herdId:
                            getLobHerdId(LOB_PRODUCTS_MAPPING.home) ||
                            getLobHerdId(LOB_PRODUCTS_MAPPING.flood),
                        });
                      }}
                      disabled={!!propertyInfoLoader}
                    >
                      {RETRY}
                      {!!propertyInfoLoader && <SpinnerLoader styleData={classes.submitSpinner} />}
                    </Button>
                  )}
              </>
            }
          >
            {bannerDetails?.message}
          </Alert>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.PrefillInfoStatusBannerDispatch => {
  return bindActionCreators({ getPropertyInfoDetaills, setApplicantDetails }, dispatch);
};

const mapStateToProps = ({
  partner,
  applicant,
}: AppComponents.PrefillInfoStatusBannerStoreProps) => ({
  partner,
  applicant,
});

export default connect(mapStateToProps, mapDispatchToProps)(PrefillInfoStatusBanner);
