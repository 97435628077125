import { Grid, Typography } from '@material-ui/core';
import { isEmpty, startCase } from 'lodash';
import React from 'react';
import {
    COVERAGES
} from '../../../constants';
import { HomeQuote } from '../../../graphql/generated';
import {
    formatNumberToCurrency
} from '../../../utils';

export const getCoverageItem = (quote: HomeQuote) => {
  return [
    {
      label: 'Dwelling',
      value: quote.data?.covA ?? 0,
    },
    {
      label: 'Other Structures',
      value: quote.data?.covB ?? 0,
    },
    {
      label: 'Personal Property',
      value: quote.data?.covC ?? 0,
    },
    {
      label: 'Loss Of Use',
      value: quote.data?.covD ?? 0,
    },
    {
      label: 'Personal Liability',
      value: quote.data?.covE ?? 0,
    },
    {
      label: 'Medical Payment to Others',
      value: quote.data?.covF ?? 0,
    },
  ];
};

export const HomeCoverageDetails = ({ quote }: { quote: HomeQuote }) => {
  let homeCoverageList = getCoverageItem(quote);
  return (
    <>
      {!isEmpty(homeCoverageList) && (
        <Grid item xs={12} sm={12}>
          <Typography className='label text-left'>{startCase(COVERAGES)}</Typography>
          <div className='normal-label text-left'>
            <ul className='dc-list'>
              {homeCoverageList.map(item => {
                return (
                  !!item?.value && (
                    <li key={item.label}>{`${item?.label}: ${formatNumberToCurrency(
                      item?.value
                    )}`}</li>
                  )
                );
              })}
            </ul>
          </div>
        </Grid>
      )}
    </>
  );
};
