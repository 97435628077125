import React from 'react';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import filter from 'lodash/filter';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { withStyles } from '@material-ui/core/styles';

import {
  BULK_QUOTE_ERRORS,
  DEFAULT_MISSING_MARKET,
  EMPTY_CARRIER_LIST_MESSAGE,
  NA_VALUE,
  PAGES_TEXT,
  SPACEBAR_KEY_CODE,
} from '../../../constants';
import { setSelectedCarrierList, submitBulkQuoteStep, updateStep } from '../../redux/actions';
import { replaceWithPlaceholder } from '../../../utils';

import './carrier-selector.scss';

const ROWS_TO_DISPLAY = 4;
const TOTAL_CARRIERS_TO_DISPLAY = 12;

const SelectAllCheckbox = withStyles({
  root: {
    color: '#00CF83',
    margin: '0px 5px 0px 0px',
    padding: '0px',
    '&$checked': {
      color: '#00CF83',
    },
  },
})((props: CheckboxProps) => (
  <FormControlLabel
    classes={{ root: 'select-all-label' }}
    control={<Checkbox color='default' {...props} />}
    label='Select All'
  />
));

class CarrierSelector extends React.Component<
  AppComponents.CarrierSelectorProps,
  AppComponents.CarrierSelectorState
> {
  state: AppComponents.CarrierSelectorState = {
    entity: 'carrierSelector',
    selectorError: '',
    carrierList: [],
    selectAll: false,
    altOrganizationText: 'selected',
  };

  componentDidMount() {
    const { selectedCarrierList, carrierList } = this.props;
    const updatedList = map(cloneDeep(carrierList), carrier => {
      if (find(selectedCarrierList, { id: carrier.id })) {
        carrier.selected = true;
      }
      return carrier;
    });
    this.setState({
      selectAll: updatedList.length === selectedCarrierList.length,
      carrierList: [...updatedList],
    });
  }

  onCarrierSelected = (e: any, selectedCarrier: any) => {
    const { carrierList } = this.state;
    let list: Array<any> = [];
    if (
      (e.type === 'keydown' && e.keyCode === SPACEBAR_KEY_CODE) ||
      (e.type !== 'keydown' && e.keyCode !== SPACEBAR_KEY_CODE)
    ) {
      list = map(carrierList, carrier => {
        if (carrier.id === selectedCarrier.id) {
          carrier.selected = !carrier.selected;
        }
        return carrier;
      });
      const selectedList = filter(list, { selected: true });
      if (list.length > 0) {
        this.setState({
          selectAll: selectedList.length === carrierList.length,
          selectorError: '',
          carrierList: [...list],
        });
      }
      e.preventDefault();
    }
  };

  getTooltipContainerClasses = (index: number) =>
    index === 1 || index % ROWS_TO_DISPLAY === 0 || index % ROWS_TO_DISPLAY === 1
      ? 'rightside-tooltip'
      : 'leftside-tooltip';

  getTooltipTextBoxClasses = (carrierTooltipData: any, index: any) =>
    `tooltip-text-box ${
      this.checkLastRow(parseInt(index))
        ? carrierTooltipData.length > 1
          ? 'lastRow'
          : 'lastRowSingleData'
        : ''
    } ${carrierTooltipData.length > 2 && 'multiple-row-details'}`;

  checkLastRow = (index: number): boolean => {
    const carrierListLength: number = this.state.carrierList.length;
    const carrierGridLastRow: number = carrierListLength % ROWS_TO_DISPLAY;
    let lastRow = false;
    if (
      (carrierListLength - index <= carrierGridLastRow ||
        (carrierGridLastRow === 0 && carrierListLength - index <= ROWS_TO_DISPLAY)) &&
      carrierListLength > ROWS_TO_DISPLAY
    ) {
      lastRow = true;
    } else {
      lastRow = false;
    }
    return lastRow;
  };

  getTooltipDataValue = (value: string, defaultValue: string) => (value ? value : defaultValue);

  onNext = () => {
    const { carrierList } = this.state;
    const selectedCarrierList: Array<any> = filter(carrierList, carrier => carrier.selected);
    if (isEmpty(selectedCarrierList)) {
      this.setState({
        selectorError: BULK_QUOTE_ERRORS.carrierSelectorError,
      });
    } else {
      this.setState(
        {
          selectorError: '',
        },
        () => {
          this.props.submitBulkQuoteStep({
            entity: this.state.entity,
            data: {
              selectedCarrierList,
            },
          });
        }
      );
    }
  };

  onBack = () => {
    const { carrierList } = this.state;
    const selectedCarrierList: Array<any> = filter(carrierList, carrier => carrier.selected);
    if (!isEmpty(selectedCarrierList)) {
      this.setState(
        {
          selectorError: '',
        },
        () => {
          this.props.setSelectedCarrierList([...selectedCarrierList]);
          this.props.updateStep(0);
        }
      );
    } else {
      this.props.setSelectedCarrierList([]);
      this.props.updateStep(0);
    }
  };

  getTooltipContent = (carrier: any): any =>
    map(carrier.tooltipData, (value: any, index: any) => (
      <div key={`tooltip-data-${index}`} className='tooltip-detail'>
        <ul>
          <li>
            {PAGES_TEXT.producerCodeTooltipLabel}:&nbsp;
            <span className='highlight-text-color'>
              {this.getTooltipDataValue(value.producerCode, NA_VALUE)}
            </span>
          </li>
          <li>
            {PAGES_TEXT.userNameTooltipLabel}:&nbsp;
            <span className='highlight-text-color'>
              {this.getTooltipDataValue(value.userName, NA_VALUE)}
            </span>
          </li>
          <li>
            {PAGES_TEXT.marketTooltipLabel}:&nbsp;
            <span className='highlight-text-color'>
              {this.getTooltipDataValue(value.market, DEFAULT_MISSING_MARKET)}
            </span>
          </li>
        </ul>
        <hr className='hr-divider' />
      </div>
    ));

  checkCarrierListLength = () => this.state.carrierList.length > TOTAL_CARRIERS_TO_DISPLAY;

  selectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected: boolean = event.target.checked;
    const updatedCarrierList = map(this.state.carrierList, carrier => {
      carrier.selected = isSelected;
      return carrier;
    });
    this.setState({
      selectorError: '',
      selectAll: isSelected,
      carrierList: [...updatedCarrierList],
    });
  };

  getEmptyCarrierListMessage() {
    const { inputOrganization, selectedOrganization } = this.props;
    let organizationName =
      get(inputOrganization, 'name', '') || get(selectedOrganization, 'name', '');
    organizationName = isEmpty(organizationName)
      ? this.state.altOrganizationText
      : organizationName;
    return replaceWithPlaceholder(EMPTY_CARRIER_LIST_MESSAGE, organizationName);
  }

  render() {
    const { carrierList, selectorError, selectAll } = this.state;

    return (
      <React.Fragment>
        <div className={`org-divider ${this.checkCarrierListLength() && 'carrier-org-title'}`}>
          {PAGES_TEXT.bulkQuoteStepTwoHeading}
        </div>
        <div
          className={`row carrier-grid-wrapper ${
            this.checkCarrierListLength() && 'grid-inner-shadow'
          }`}
        >
          {carrierList.length > 0 ? (
            map(carrierList, (carrier: any, index: any) => (
              <div className='col-md-3 carrier-grid-column' key={`${carrier.id}-${index}`}>
                <div
                  tabIndex={0}
                  className={`${
                    carrier.selected === true ? 'active' : ''
                  } carrier-tooltip-wrapper h-100`}
                  onClick={e => this.onCarrierSelected(e, carrier)}
                  onKeyDown={e => this.onCarrierSelected(e, carrier)}
                >
                  <div className='card-container'>
                    <div className='custom-tooltip-container'>
                      <div
                        className={`${
                          carrier.selected === true ? 'check-mark' : 'uncheck-mark'
                        } d-inline-block`}
                      />

                      {!isEmpty(carrier.tooltipData) && (
                        <div className={this.getTooltipContainerClasses(parseInt(index))}>
                          <div
                            className={this.getTooltipTextBoxClasses(carrier.tooltipData, index)}
                            onClick={e => e.stopPropagation()}
                          >
                            <div className='tooltip-scroll'>{this.getTooltipContent(carrier)}</div>
                          </div>
                        </div>
                      )}

                      <img
                        src={carrier.logoUrl}
                        className='img-fluid d-inline-block carrier-logo'
                        alt={carrier.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='empty-carrier-list-message'>{this.getEmptyCarrierListMessage()}</div>
          )}
        </div>

        <div className='row error-row'>
          <div className='col-md-8 remove-padding'>
            {!isEmpty(selectorError) && (
              <span className='error-message carrier-error-message'>{selectorError}</span>
            )}
          </div>
          {carrierList.length > 1 && (
            <div className='col-md-4 select-all-container'>
              <SelectAllCheckbox size='small' onChange={this.selectAllChange} checked={selectAll} />
            </div>
          )}
        </div>

        <div className='row'>
          <div className='navigation-wrapper'>
            <Button
              variant='outlined'
              className={`navigation-back-btn ${carrierList.length === 0 && 'mr-0'}`}
              onClick={this.onBack}
            >
              <span className='link-label'>BACK</span>
            </Button>

            {carrierList.length !== 0 && (
              <Button variant='outlined' className='navigation-next-btn' onClick={this.onNext}>
                NEXT
              </Button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  bulkQuote,
}: AppComponents.CarrierSelectorStore): AppComponents.CarrierSelectorStoreProps => {
  const { carrierList, inputOrganization, selectedCarrierList, selectedOrganization, serverError } =
    bulkQuote;
  return {
    carrierList,
    inputOrganization,
    selectedCarrierList,
    selectedOrganization,
    serverError,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.CarrierSelectorDispatch => {
  return bindActionCreators(
    {
      setSelectedCarrierList,
      submitBulkQuoteStep,
      updateStep,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CarrierSelector);
