/* istanbul ignore file */
import * as types from '../constants';
import { getUpdatedQuoteList } from '../../../utils';
import { assignIn, isEmpty, filter, find } from 'lodash';
import { UpcomingCarriersListType } from './cyber-security';
import { CommonQuote } from '../../../quote-application/utils/types';

export interface LifeDetailsState {
  quoteStatus: string;
  quoteList: Array<CommonQuote>;
  quoteErrorList: Array<any>;
  quoteListLoader: boolean;
  quotingCarriers: Array<any>;
  herdId: string;
  lifeInfo: any;
  quotingDetails: any;
  pollingDetails: any;
  noQuoteAvailable: boolean;
  quotesLoaded: boolean;
  bridgeToCarrierLoader: boolean;
  isQuoteRefresh: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
}

const INIT_LIFE_STATE: LifeDetailsState = {
  quoteList: [],
  quoteErrorList: [],
  quoteListLoader: false,
  quotingCarriers: [],
  herdId: '',
  lifeInfo: {},
  quotingDetails: null,
  pollingDetails: null,
  noQuoteAvailable: false,
  quotesLoaded: false,
  bridgeToCarrierLoader: false,
  isQuoteRefresh: false,
  upcomingCarrierList: [],
  quoteStatus: '',
};

const INIT_STATE = { ...INIT_LIFE_STATE };

export default (state = INIT_STATE, action: AppRedux.Action): LifeDetailsState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        quoteListLoader: false,
      };
    }

    case types.SET_LIFE_QUOTES_DETAILS: {
      const { quoteList, herdId, quoteErrorList } = action.payload;
      return {
        ...state,
        quoteList: quoteList,
        quoteErrorList: quoteErrorList,
        herdId: herdId ? herdId : state.herdId,
      };
    }

    case types.LIFE_DETAILS_LOADER: {
      return {
        ...state,
        quoteListLoader: action.payload,
      };
    }

    case types.STORE_LIFE_QUOTE_LIST: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.CLEAR_LIFE_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.UPDATE_LIFE_QUOTE_DETAILS: {
      return {
        ...state,
        quotingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_LIFE_POLLING_ERROR_DETAILS: {
      let oldQuoteErrorList = getUpdatedQuoteList(state, action.payload);
      if (oldQuoteErrorList) {
        oldQuoteErrorList = filter(oldQuoteErrorList, error => {
          return assignIn(error, { quoteError: true });
        });
      }
      return {
        ...state,
        quoteErrorList: [...oldQuoteErrorList],
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.SET_LIFE_POLLING_DETAILS: {
      const { quotes, error_details } = action.payload;
      let oldQuotesList = [...state.quoteList];
      let quoteErrorList = [...state.quoteErrorList];
      if (!isEmpty(action.payload.quotes)) {
        let newQuoteErrorList: Array<any> = [];
        newQuoteErrorList = filter(
          quotes,
          quote =>
            !find(oldQuotesList, {
              carrier_id: quote.carrier_id,
              name: quote.name,
            }) && !find(oldQuotesList, { carrier_id: quote.carrier_id })
        );
        oldQuotesList = [...oldQuotesList, ...newQuoteErrorList];
      }
      if (error_details && error_details.display_errors_on_ui) {
        quoteErrorList = filter(error_details.errors, error => error);
      }
      return {
        ...state,
        quoteList: [...oldQuotesList],
        quoteErrorList,
        pollingDetails: {
          ...action.payload,
        },
      };
    }

    case types.LIFE_BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let lifeQuoteList = [...state.quoteList];
      const updateIndex = lifeQuoteList.findIndex((item: any) => item.carrier_id === carrier_id);
      if (updateIndex !== -1) {
        lifeQuoteList[updateIndex] = {
          ...lifeQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: lifeQuoteList,
      };
    }

    default:
      return state;
  }
};
