import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import RemoveIcon from '@material-ui/icons/Remove';
import { positiveIntegerRegex } from '../../../utils';
import './number-stepper.scss';

const getDefaultValue = (initialValue: any, current: any) =>
  current.length === 0 || current <= 0 ? Number(initialValue) : Number(current);

const NumberStepper = (props: any) => {
  const [initValue] = useState(1);
  const [counter, setCounter] = useState(props.value);

  const handleIncrement = () => {
    if (!props.disabled) {
      const updatedValue = getDefaultValue(initValue, counter + 1);
      setCounter(updatedValue);
      props.onChange({ target: { value: updatedValue } });
    }
  };

  const handleDecrement = () => {
    if (!props.disabled) {
      const updatedValue = getDefaultValue(initValue, counter - 1);
      setCounter(updatedValue);
      props.onChange({ target: { value: updatedValue } });
    }
  };

  const handleInput = ({ target }: any) => {
    const userInput = String(target.value).trim();
    if (userInput.match(positiveIntegerRegex) || userInput.length === 0) {
      const updatedValue = userInput.length === 0 ? '' : Number(userInput);
      setCounter(updatedValue);
      props.onChange({ target: { value: updatedValue } });
    }
  };

  return (
    <div className={`number-stepper-wrapper ${props.styleName}`}>
      <div onClick={handleDecrement} className={`fab-btn ${props.disabled ? 'cursor-default' : 'cursor-pointer'}`}>
        <RemoveIcon className='svg-icon' />
      </div>
      <Input
        value={counter}
        autoComplete='off'
        onChange={handleInput}
        className='number-input'
        inputProps={{ maxLength: 4 }}
      />
      <div onClick={handleIncrement} className={`fab-btn ${props.disabled ? 'cursor-default' : 'cursor-pointer'}`}>
        <AddIcon className='svg-icon' />
      </div>
    </div>
  );
};

export default NumberStepper;
