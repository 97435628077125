import React from 'react';
import { isEmpty } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SpinnerLoader from '../spinner-loader';
import { makeStyles } from '@material-ui/core/styles';
import './common-modal.scss';

const useStyles = makeStyles(() => ({
  lineDivider: {
    marginBottom: 0,
  },
  dialogContent: {
    padding: '0px 24px',
  },
  closeButton: {
    position: 'absolute',
    width: 20,
    height: 20,
    right: 10,
    top: 10,
    zIndex: 1,
  },
  submitSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
}));

const getNavButtons = (
  classes: any,
  onClose: any,
  onSave: any,
  loader: any,
  actionButtonLabel: string,
  cancelButtonLabel: string
) => {
  return (
    <div className='btn-wraper plr-24'>
      <Button
        variant='outlined'
        className='navigation-back-btn'
        disabled={loader}
        onClick={onClose}
      >
        {cancelButtonLabel}
      </Button>
      <Button
        variant='outlined'
        className='navigation-next-btn submit-btn'
        disabled={loader}
        onClick={onSave}
      >
        {actionButtonLabel}
        {loader && <SpinnerLoader styleData={classes.submitSpinner} />}
      </Button>
    </div>
  );
};

const modalBody = (
  classes: any,
  title: string,
  onSave: any,
  onClose: any,
  navButtons: boolean,
  children: any,
  loader: boolean,
  actionButtonLabel: string,
  cancelButtonLabel: string
) => {
  return (
    <div>
      <DialogTitle id='dialog-title'>
        {!isEmpty(title) && (
          <>
            {title}
            <hr className={classes.lineDivider}></hr>
          </>
        )}
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>{children}</DialogContent>
      <DialogActions>
        {navButtons &&
          getNavButtons(classes, onClose, onSave, loader, actionButtonLabel, cancelButtonLabel)}
      </DialogActions>
    </div>
  );
};

export const CommonModal = (props: any) => {
  const {
    isOpen,
    onClose,
    onSave,
    maxWidth,
    title,
    navButtons = false,
    children,
    loader,
    actionButtonLabel,
    cancelButtonLabel,
  } = props;
  const classes = useStyles();

  if (!props.isOpen) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={!!maxWidth ? maxWidth : 'xs'}
      open={isOpen}
      classes={{
        root: 'common-modal-container',
      }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      {modalBody(
        classes,
        title,
        onSave,
        onClose,
        navButtons,
        children,
        loader,
        actionButtonLabel,
        cancelButtonLabel
      )}
    </Dialog>
  );
};

export default CommonModal;
