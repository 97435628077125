import { FormHelperText, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  CANCEL,
  CONTINUE,
  INSURANCE_PRODUCTS,
  MAPPED_PREFILL_INFORMATION_PAYLOAD_KEYS,
  MD,
  REQUEST_BIND_PREFILL_INFORMATION,
  REQUEST_BIND_SUCCESS_MESSAGE,
  SM,
  SUBMIT,
  XS,
} from '../../../constants';
import {
  getApplicantDetails,
  getNestedTernaryData,
  getOptionsLabelByValue,
  positiveIntegerRegex,
  validateYearBuiltAndRoofConstructed,
} from '../../../utils';
import { FormDataType, useFormHandler } from '../../custom-hooks';
import { EMPTY, ERROR_MESSAGE } from '../../custom-hooks/useFormHandler/utils';
import { requestBindForWholesale, setRequestBindResponseDetails } from '../../redux/actions';
import CommonModal from '../common-modal';
import { InputFieldDetails } from '../property-info/type';
import PrefillInformationForm from './prefill-information-form';
import './request-bind-model.scss';

const { INPUT_FIELDS, STATE_REQUIRED_TRAMPOLINE_PRESENCE } = REQUEST_BIND_PREFILL_INFORMATION;
const {
  NO_OF_FAMILY_UNIT,
  ROOF_TYPE,
  SQUARE_FOOTAGE,
  NO_OF_STORIES,
  CONSTRUCTION_TYPE,
  TRAMPOLINE,
} = INPUT_FIELDS;

const RequestBindBody = (propData: any) => {
  const { common } = propData;
  return (
    <Grid container spacing={2} className='content MuiDialog-paperWidthSm'>
      <Grid item xs={12}>
        <Typography className='request-bind-heading'>Request Bind</Typography>
        <span className='icon-request-bind'></span>
        <Typography className='request-bind-para'>
          All quote information will be sent to a First Connect Underwriter for review. You will be
          contacted via email to gather additional information and proceed with the bind request
          upon review.
        </Typography>
        <Typography className='request-bind-para-que'>Do you wish to proceed?</Typography>
        <FormHelperText className='text-red'>
          {common?.requestBindDetails?.requestBindErrorMessage || ''}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

const RequestBindSuccessFully = () => (
  <>
    <Typography className='request-bind-success pb-4'>
      {REQUEST_BIND_SUCCESS_MESSAGE.REQUESR_SENT}
    </Typography>
    <Typography className='request-bind-success_msg'>
      {REQUEST_BIND_SUCCESS_MESSAGE.UNDERWRITER}
    </Typography>
  </>
);

const shouldRenderTrampoline = () => {
  const applicantDetails = getApplicantDetails();
  return (
    !!applicantDetails?.address &&
    !isEmpty(applicantDetails.address) &&
    STATE_REQUIRED_TRAMPOLINE_PRESENCE.includes(applicantDetails.address.state?.toUpperCase())
  );
};

const getInitialPropertyInfoDetails = () => {
  const initialPropertyInfoDetails: Record<string, string> = {};
  Object.values(INPUT_FIELDS).forEach(field => {
    if (NO_OF_STORIES.KEY === field.KEY) {
      initialPropertyInfoDetails[field.KEY] = NO_OF_STORIES.DEFAULT_VALUE;
    } else if (CONSTRUCTION_TYPE.KEY === field.KEY) {
      initialPropertyInfoDetails[field.KEY] = CONSTRUCTION_TYPE.DEFAULT_VALUE;
    } else if (NO_OF_FAMILY_UNIT.KEY === field.KEY) {
      initialPropertyInfoDetails[field.KEY] = NO_OF_FAMILY_UNIT.DEFAULT_VALUE;
    } else if (ROOF_TYPE.KEY === field.KEY) {
      initialPropertyInfoDetails[field.KEY] = ROOF_TYPE.DEFAULT_VALUE;
    } else initialPropertyInfoDetails[field.KEY] = EMPTY;
  });
  return initialPropertyInfoDetails;
};

const getRequiredKeys = () => {
  const optionalKey = [TRAMPOLINE.KEY];
  return Object.keys(initialFormValues).filter(key => !optionalKey.includes(key));
};

const customValidator = (formData: FormDataType, errors: FormDataType) => {
  const keysForMaxIntegerValidate = [SQUARE_FOOTAGE.KEY];
  keysForMaxIntegerValidate.forEach(key => {
    const value = formData[key] || EMPTY;
    if (value && Number(value) > 9999999) {
      errors[key] = ERROR_MESSAGE.INVALID;
    }
  });
  /**Check required field for Trampoline */
  if (shouldRenderTrampoline() && !formData[TRAMPOLINE.KEY])
    errors[TRAMPOLINE.KEY] = ERROR_MESSAGE.REQUIRED;
  return validateYearBuiltAndRoofConstructed(formData, errors, false);
};

const valueAcceptedForFormHandler = () =>
  [SQUARE_FOOTAGE.KEY].map(key => ({
    regex: positiveIntegerRegex,
    key: key,
  }));

const selectFieldDetailsByKey = (key: string): InputFieldDetails =>
  Object.values(INPUT_FIELDS).filter(fieldDetails => fieldDetails.KEY === key)[0];

const initialFormValues: FormDataType = getInitialPropertyInfoDetails();

const getPropertyInformationPayload = (formValues: FormDataType, prefillValues: FormDataType) => {
  const tempPropertyInformation: any = {};
  const { trampoline, ...restValues } = formValues;
  Object.keys(shouldRenderTrampoline() ? formValues : restValues).forEach(key => {
    const [value, label] = formValues[key]?.split('~');
    const payloadKey = MAPPED_PREFILL_INFORMATION_PAYLOAD_KEYS[key] || key;
    tempPropertyInformation[payloadKey] = {
      value: label || value,
      isUpdated: value !== prefillValues[key],
    };
  });
  return tempPropertyInformation;
};

const RequestBindModel = (props: AppComponents.RequestBindModelProps) => {
  const { common, isOpen, lob, quote } = props;
  const { selectedBusinessOption } = common;
  const [isVisiblePrefillInformationForm, setIsVisiblePrefillInformationForm] = useState(true);

  /**Implemented useFormHandler hooks to handle form*/
  const {
    formValues,
    formErrors,
    setFormValues,
    setFormCustomValue,
    handleOnSubmit,
    handleOnChange,
  } = useFormHandler(initialFormValues, {
    customValidator,
    required: getRequiredKeys(),
    shouldErrorHandleOnInputChange: false,
    valueAccepted: valueAcceptedForFormHandler(),
  });

  const subHandler = (_submittedData: FormDataType, isSubmitted: boolean) => {
    if (selectedBusinessOption?.toLowerCase() === INSURANCE_PRODUCTS[0]) {
      if (isSubmitted) {
        if (!isVisiblePrefillInformationForm) {
          const { prefillDetailsForRequestBind } = common;
          let payload: any = {
            ...quote,
            lob: lob,
            propertyInformation: getPropertyInformationPayload(
              formValues,
              prefillDetailsForRequestBind?.propertyData
            ),
          };
          props.requestBindForWholesale(payload);
        } else setIsVisiblePrefillInformationForm(false);
      }
    } else {
      let payload: any = {
        ...quote,
        lob: lob,
      };
      props.requestBindForWholesale(payload);
    }
  };

  /**Form submit handler*/
  const onContinueClick = () => handleOnSubmit(subHandler);

  /**Handler the user input change by selecting date*/
  const datePrckerOnChangeHandler = (value: string, key: string) => {
    value = value ? value.replaceAll('_', '') : EMPTY;
    setFormCustomValue(key, value);
  };

  const onCloseRequestBindModel = () => {
    props.setRequestBindResponseDetails({
      successRequestBind: false,
      requestBindErrorMessage: '',
    });
    props.onClose(false);
  };

  /**mapped all fields value with label*/
  const getMappedValueWithLabelForSelectedField = useCallback(
    (key: string, value: string) => {
      const {
        prefillDetailsForRequestBind: { label },
      } = common;
      const selectedFieldDetails = selectFieldDetailsByKey(key);
      if (!isEmpty(selectedFieldDetails)) {
        if (!isEmpty(label) && label[key]) {
          const labelKeys = Object.keys(label);
          if (key === NO_OF_FAMILY_UNIT.KEY) {
            const tempLabel = getOptionsLabelByValue(NO_OF_FAMILY_UNIT.OPTIONS, value);
            if (labelKeys.includes(key)) value = label[key] ? `${value}~${tempLabel}` : value;
          } else if (key === ROOF_TYPE.KEY) {
            const tempLabel = getOptionsLabelByValue(ROOF_TYPE.OPTIONS, value);
            if (!tempLabel) {
              value = formValues[key];
            } else {
              value = label[key] ? `${value}~${label[key]}` : value;
            }
          } else if (labelKeys.includes(key)) {
            value = label[key] ? `${value}~${label[key]}` : value;
          }
        } else {
          const options = selectedFieldDetails?.OPTIONS;
          if (options && !isEmpty(options)) {
            const [selectedOption] = options.filter(option => option.value === value);
            if (selectedOption && selectedOption.label) {
              value = `${value}~${selectedOption.label}`;
            } else {
              value = formValues[key];
            }
          }
        }
      }
      return value;
    },
    // eslint-disable-next-line
    [common.prefillDetailsForRequestBind]
  );

  useEffect(() => {
    if (selectedBusinessOption?.toLowerCase() === INSURANCE_PRODUCTS[0]) {
      const { prefillDetailsForRequestBind } = common;
      if (prefillDetailsForRequestBind && !isEmpty(prefillDetailsForRequestBind)) {
        const { propertyData } = prefillDetailsForRequestBind;
        const tempFormvalues: FormDataType = {};
        Object.keys(formValues).forEach((key: string) => {
          let value = propertyData[key] ? propertyData[key].toString() : formValues[key];
          if (!!value) {
            tempFormvalues[key] = getMappedValueWithLabelForSelectedField(key, value);
          }
        });
        setFormValues(tempFormvalues);
      }
    } else setIsVisiblePrefillInformationForm(false);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <CommonModal
        isOpen={isOpen}
        onClose={() => onCloseRequestBindModel()}
        navButtons={!!common?.requestBindDetails?.successRequestBind ? false : true}
        maxWidth={getNestedTernaryData(
          isVisiblePrefillInformationForm,
          MD,
          getNestedTernaryData(!!common?.requestBindDetails?.successRequestBind, XS, SM)
        )}
        onSave={() => onContinueClick()}
        loader={!!common?.requestBindLoader}
        actionButtonLabel={isVisiblePrefillInformationForm ? SUBMIT : CONTINUE}
        children={
          <div className='request-bind-wrap'>
            {getNestedTernaryData(
              isVisiblePrefillInformationForm,
              <PrefillInformationForm
                {...{
                  formValues,
                  formErrors,
                  handleOnChange,
                  datePrckerOnChangeHandler,
                  getRequiredKeys,
                  shouldRenderTrampoline,
                }}
              />,
              getNestedTernaryData(
                !common?.requestBindDetails?.successRequestBind,
                <RequestBindBody common={common} />,
                <RequestBindSuccessFully />
              )
            )}
          </div>
        }
        cancelButtonLabel={CANCEL}
      />
    </div>
  );
};

const mapStateToProps = ({ common }: AppComponents.RequestBindModelStore) => {
  return {
    common,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.RequestBindModelDispatch => {
  return bindActionCreators({ requestBindForWholesale, setRequestBindResponseDetails }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestBindModel);
