/* istanbul ignore file */
import filter from 'lodash/filter';
import flatMapDeep from 'lodash/flatMapDeep';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { put } from 'redux-saga/effects';
import {
  ACCIDENT_MAXIMUM_AMOUNT,
  ACCIDENT_MINIMUM_AMOUNT,
  LOSSES_MINIMUM_AMOUNT,
} from '../../../constants';
import { dateFormat } from '../../../utils';
import { setOtherErrors } from '../actions';

const checkAccidentValues = (value: any, property: any) =>
  isEmpty(value) ||
  (property === 'date_of_accidents' &&
    !isEmpty(value) &&
    (!moment(value, dateFormat, true).isValid() ||
      moment(value).isAfter(moment(new Date()).format(dateFormat)))) ||
  ((property === 'pd_amount' ||
    property === 'bi_amount' ||
    property === 'collision_amount' ||
    property === 'mp_amount') &&
    (parseInt(value) < ACCIDENT_MINIMUM_AMOUNT || parseInt(value) > ACCIDENT_MAXIMUM_AMOUNT));

const checkViolationValues = (value: any, property: any) =>
  isEmpty(value) ||
  (property === 'date_of_violations' &&
    !isEmpty(value) &&
    (!moment(value, dateFormat, true).isValid() ||
      moment(value).isAfter(moment(new Date()).format(dateFormat))));

const checkLossValues = (value: any, property: any) =>
  isEmpty(value) ||
  (property === 'date_of_loss' &&
    !isEmpty(value) &&
    (!moment(value, dateFormat, true).isValid() ||
      moment(value).isAfter(moment(new Date()).format(dateFormat)))) ||
  (property === 'loss_amount' && parseInt(value) < LOSSES_MINIMUM_AMOUNT);

function* getIncidentsErrors(
  accidentList: Array<any>,
  violationList: Array<any>,
  lossList: Array<any>
) {
  // Validate accident details
  const accidentErrors: any = {};
  let errorList: Array<any> = [];
  filter(accidentList, accident => {
    errorList = [];
    forEach(accident, (value, property) => {
      if (checkAccidentValues(value, property)) {
        errorList = [...errorList, `${property}_error`];
      }
    });
    accidentErrors[accident.accident_uid] = errorList;
  });
  if (!isEmpty(accidentErrors)) {
    errorList = [...flatMapDeep(accidentErrors, accident => [accident])];
    yield put(setOtherErrors({ entity: 'accidentErrors', data: { ...accidentErrors } } as any));
  }
  // Validate violation details
  const violationErrors: any = {};
  let errorViolationList: Array<any> = [];
  filter(violationList, violation => {
    errorViolationList = [];
    forEach(violation, (value, property) => {
      if (checkViolationValues(value, property)) {
        errorViolationList = [...errorViolationList, `${property}_error`];
      }
    });
    violationErrors[violation.violation_uid] = errorViolationList;
  });
  if (!isEmpty(violationErrors)) {
    errorViolationList = [...flatMapDeep(violationErrors, violation => [violation])];
    yield put(setOtherErrors({ entity: 'violationErrors', data: { ...violationErrors } } as any));
  }
  // Validate loss details
  const lossErrors: any = {};
  let errorLossList: Array<any> = [];
  filter(lossList, loss => {
    errorLossList = [];
    forEach(loss, (value, property) => {
      if (checkLossValues(value, property)) {
        errorLossList = [...errorLossList, `${property}_error`];
      }
    });
    lossErrors[loss.loss_uid] = errorLossList;
  });
  if (!isEmpty(lossErrors)) {
    errorLossList = [...flatMapDeep(lossErrors, loss => [loss])];
    yield put(setOtherErrors({ entity: 'lossErrors', data: { ...lossErrors } } as any));
  }
  return { errorList, errorViolationList, errorLossList };
}

export default getIncidentsErrors;
