/* istanbul ignore file */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  filter,
  find,
  flatMap,
  get,
  isEmpty,
  map,
  omit,
  uniqBy,
} from 'lodash';
import axiosRequest from '../../api';
import * as reportActions from '../constants';
import {
  errorHandler,
  savedFilterSelected,
  setRemoveFilterLoader,
  setReportAPIError,
  setReportDetails,
  setReportLoader,
  setSubmitReportLoader,
  updateReportCurrentDetails,
  updateSavedFilters,
} from '../actions';
import { AMS_GLOBAL_ERROR_MESSAGE, NON_RETURNING_QUOTE_OPTION, REPORT_ACTIONS, STATEDATA } from '../../../constants';
import { downloadFile, getValidNumber } from '../../../utils';

const getFullName = (data: any) =>
  `${get(data, 'FirstName', '')} ${get(data, 'MiddleName', '')} ${get(data, 'LastName', '')}`;

const getMappedList = (list: any, name: string, code: string) => map(list, (item) => {
    if (name) {
      return { label: item[name], value: item[code], key: item[code] };
    } else {
      return { label: getFullName(item), value: item[code], key: item[code] };
    }
});

const getMappedOrganizationList = (list: any, label: string, value: string) => map(list, (item) => {
  return { label: item[label], value: item[value], key: item[value] };
});


const getMappedSavedFilers = (list: any) => {
  if (isEmpty(list)) {
    return [];
  }
  return map(list, (item) => ({ label: item.filterName, value: item.filterId, key: item.filterId, ...item }));
};

const getCarrierMappedList = (list: any, name: string, code: string, ParentCode: string) => map(list, (item) => ({ label: item[name], value: item[code], key: item[code], parentId: item[ParentCode] }));
const getStateList = () => map(STATEDATA.states, (state: any) => ({ ...state, label: state.value, key: state.value }));
const stateList = [...getStateList()];

function* updateSavedFilterList(data: any, currentFilter: any) {
  const updatedFilters = getMappedSavedFilers(data?.filterList);
  if (!isEmpty(updatedFilters)) {
    yield put(
      setReportDetails({
        savedFilterList: [...updatedFilters],
      })
    );
    const selectedFilterDetails = find(
      data?.filterList,
      (newFilter) => String(newFilter.filterName).trim() === currentFilter.filterName
    );
    yield put(savedFilterSelected(selectedFilterDetails?.filterId || ''));
  }
}

function* showCustomErrorMessage(error?: any, currentFilter?: any) {
  const errorMessage = error?.response?.data?.Message;
  yield call(updateSavedFilterList, error?.response?.data, currentFilter);
  yield put(setReportAPIError(errorMessage ? errorMessage : AMS_GLOBAL_ERROR_MESSAGE));
}

function* fetchAMSReportDetails(): any {
  yield put(setReportLoader(true));
  try {
    const { partner: { isProviderOneLogin } } = yield select();
    const responseDetails = yield axiosRequest('GET', '/report/ams-bind-report-dropdown-data', isProviderOneLogin);
    const details = {
      ...responseDetails.data,
    };

    const allCarrierList = [
      ...getCarrierMappedList(details.carrierList, 'Name', 'CompanyCode', 'ParentCompanyCode'),
    ];
    const lineOfBusinessList = [
      ...getMappedList(details.lobList, 'Description', 'LineOfBusinessCode'),
    ];
    
    yield put(
      setReportDetails({
        stateList: [...stateList],
        lobList: [...lineOfBusinessList],
        carrierList: [...uniqBy(allCarrierList, 'value')],
        divisionList: [...getMappedList(details.divisionList, 'Name', 'GLDivisionCode')],
        executiveList: [...getMappedList(details.executiveList, '', 'EmployeeCode')],
        savedFilterList: [...getMappedSavedFilers(details.filterList)],
        organizationList:  [...getMappedOrganizationList(details?.organizationList, 'organizationName', 'organizationId')],
      })
    );
  } catch (error) {
    yield put(errorHandler(error));
    yield call(showCustomErrorMessage);
  }
  yield put(setReportLoader(false));
}

export function* fetchReportDetailsWatcher() {
  yield takeEvery(reportActions.FETCH_REPORT_DETAILS, fetchAMSReportDetails);
}

function* removeSavedFilterDetails({ payload }: any): any {
  yield put(setRemoveFilterLoader(true));
  try {
    const {
      amsReport,
      auth: { userId, email },
    } = yield select();
    const { partner: { isProviderOneLogin } } = yield select();
    const response = yield axiosRequest('POST', '/report/ams-report-delete-filter', isProviderOneLogin,{
      filterId: getValidNumber(payload),
      userId,
      email,
    });
    if (response.data) {
      if (amsReport.currentDetails.filterId && amsReport.currentDetails.filterId === payload) {
        yield put(savedFilterSelected(''));
      }
      const updatedSavedFilterList = filter(
        amsReport.savedFilterList,
        (savedFilterItem) => savedFilterItem.filterId !== payload
      );
      yield put(updateSavedFilters(updatedSavedFilterList));
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield call(showCustomErrorMessage);
  }
  yield put(setRemoveFilterLoader(false));
}

export function* removeSavedFilterDetailsWatcher() {
  yield takeEvery(reportActions.REMOVE_SAVED_FILTER, removeSavedFilterDetails);
}

function* lobFilterUpdatedDetails() {
  const { amsReport } = yield select();
  let updatedList: any = [];

  const list = amsReport.carrierList;
  updatedList = [...uniqBy(flatMap(list), 'value')];
  yield put(
    setReportDetails({
      carrierList: [...updatedList],
    })
  );
}

export function* lobFilterUpdatedWatcher() {
  yield takeEvery(reportActions.LOB_FILTER_UPDATED, lobFilterUpdatedDetails);
}

const getUpdatedFilterList = (amsReport: any, selectedDetails: any, entity: string) => {
  let appliedValues: any = [...amsReport[`${entity}List`]];
  if (!isEmpty(selectedDetails[`${entity}s`])) {
    appliedValues = appliedValues.filter((data: any) =>
      selectedDetails[`${entity}s`].includes(
        entity === 'state' ? String(data.value).toLowerCase() : data.value
      )
    );
  }
  return appliedValues;
};

function* savedFilterSelectedDetails({ payload }: any): any {
  const { amsReport } = yield select();
  let selectedDetails: any = {
    dateFilterType: 'quoteDate',
    startDate: '',
    endDate: '',
    carriers: [],
    lobs: [],
    states: [],
    divisions: [],
    executives: [],
    organizations: [],
    filterName: '',
    value: '',
    key: '',
    filterId: '',
    nonReturningQuotes: NON_RETURNING_QUOTE_OPTION.EXCLUDE
  };
  if (payload) {
    const filterData: any = amsReport.savedFilterList.find(
      (data: any) => String(data.value) === String(payload)
    );
    filterData.nonReturningQuotes = !isEmpty(filterData?.nonReturningQuotes) ? filterData.nonReturningQuotes : NON_RETURNING_QUOTE_OPTION.EXCLUDE;
    selectedDetails = { ...filterData };
  }
  const appliedLobs = getUpdatedFilterList(amsReport, selectedDetails, 'lob');
  yield call(lobFilterUpdatedDetails);

  const store: any = yield select();
  let carrierOptions: any = [...store.amsReport.carrierList];
  let appliedCarriers: any = [...store.amsReport.carrierList];
  if (!isEmpty(appliedLobs) && !isEmpty(selectedDetails.carriers)) {
    const list = amsReport.carrierList;
    carrierOptions = [...uniqBy(flatMap(list), 'value')];
    appliedCarriers = carrierOptions.filter((carrier: any) =>
      selectedDetails.carriers.find((item: any) => item.CompanyCode.includes(String(carrier.value)))
    );
  }

  const appliedStates = getUpdatedFilterList(amsReport, selectedDetails, 'state');
  const appliedDivisions = getUpdatedFilterList(amsReport, selectedDetails, 'division');
  const appliedExecutives = getUpdatedFilterList(amsReport, selectedDetails, 'executive');
  const appliedOrganizations = getUpdatedFilterList(amsReport, selectedDetails, 'organization');
  yield put(
    updateReportCurrentDetails({
      ...selectedDetails,
      appliedLobs,
      appliedStates,
      appliedDivisions,
      appliedExecutives,
      appliedCarriers,
      appliedOrganizations
    })
  );
}

export function* savedFilterSelectedWatcher() {
  yield takeEvery(reportActions.SAVED_FILTER_SELECTED, savedFilterSelectedDetails);
}

const getNormalizedReportDetails = (details: any) => {
  const { lobs, states, carriers, executives, divisions, organizations } = details;
  const getPureValueList = (list: Array<any>, key: string) => map(list, (item) => String(item[key]));
  const getStateValueList = (list: Array<any>, key: string) =>
    map(list, (item) => String(item[key]).toLowerCase());
  return {
    ...details,
    lobs: getPureValueList(lobs, 'value'),
    states: getStateValueList(states, 'value'),
    carriers: getCarrierList(carriers, 'label', 'value', 'parentId'),
    executives: getPureValueList(executives, 'value'),
    divisions: getPureValueList(divisions, 'value'),
    organizations: getPureValueList(organizations, 'value'),
  };
};

const getCarrierList = (list: any, name: string, code: string, parentCode: string) => map(list, (item) => ({ Name: item[name], CompanyCode: item[code], ParentCompanyCode: item[parentCode] }));

const checkAllSelected = (options: Array<any>, selectedOptions: Array<any>) => {
  if (isEmpty(selectedOptions)) {
    return selectedOptions;
  }

  return options.length === selectedOptions.length ? [] : selectedOptions;
};

function* submitReport({ payload }: any): any {
  yield put(setSubmitReportLoader(true));
  try {
    const { amsReport, auth, partner: { isProviderOneLogin } } = yield select();
    const { action, ...reportDetails } = payload;
    let normalizedDetails = getNormalizedReportDetails(reportDetails);
    if (action === REPORT_ACTIONS.SAVE_AND_GENERATE_REPORT) {
      const { userId, email } = auth;
      if (!isEmpty(reportDetails.selectedSavedFilter)) {
        normalizedDetails = {
          ...omit(normalizedDetails, ['selectedSavedFilter']),
          filterId: reportDetails.selectedSavedFilter[0],
        };
      }
      normalizedDetails = {
        ...omit(normalizedDetails, ['selectedSavedFilter']),
        userId,
        email,
      };
    } else {
      normalizedDetails = {
        ...omit(normalizedDetails, ['filterName', 'selectedSavedFilter']),
      };
    }
    normalizedDetails = {
      ...normalizedDetails,
      states: checkAllSelected(amsReport.stateList, normalizedDetails.states),
      lobs: checkAllSelected(amsReport.lobList, normalizedDetails.lobs),
      carriers: checkAllSelected(amsReport.carrierList, normalizedDetails.carriers),
      divisions: checkAllSelected(amsReport.divisionList, normalizedDetails.divisions),
      executives: checkAllSelected(amsReport.executiveList, normalizedDetails.executives),
      organizations:  checkAllSelected(amsReport.organizationList, normalizedDetails.organizations),
    };
    const response = yield axiosRequest('POST', '/report/ams-bind-report', isProviderOneLogin, normalizedDetails);
    if (response?.data) {
      if (action === REPORT_ACTIONS.SAVE_AND_GENERATE_REPORT) {
        yield call(updateSavedFilterList, response.data, reportDetails);
      }
      if (response.data?.fileUrl) {
        downloadFile(response.data.fileUrl);
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield call(showCustomErrorMessage, error, payload);
  }
  yield put(setSubmitReportLoader(false));
}

export function* submitReportWatcher() {
  yield takeEvery(reportActions.SUBMIT_REPORT_DETAILS, submitReport);
}

export default function* rootSaga() {
  yield all([
    fork(fetchReportDetailsWatcher),
    fork(removeSavedFilterDetailsWatcher),
    fork(lobFilterUpdatedWatcher),
    fork(savedFilterSelectedWatcher),
    fork(submitReportWatcher),
  ]);
}
