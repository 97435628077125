/* istanbul ignore file */
import { CommonQuote } from '../../../quote-application/utils/types';
import * as types from '../constants';
import { UpcomingCarriersListType } from './cyber-security';

export interface PetDetailsState {
  quoteStatus?: string;
  petBreedsDetailsLoader: boolean;
  petBreedsDetails: any;
  petList: Array<any>;
  quoteListLoader: boolean;
  quoteList: Array<CommonQuote>;
  herdId: string;
  bridgeToCarrierErrorDetails: any;
  bridgeToCarrierLoader: boolean;
  isBreedError: boolean;
  isPolicyBounded: boolean;
  upcomingCarrierList: Array<UpcomingCarriersListType>;
}

const INIT_PET_STATE: PetDetailsState = {
  petBreedsDetailsLoader: false,
  petBreedsDetails: {
    cat: {},
    dog: {},
  },
  petList: [],
  quoteListLoader: false,
  quoteList: [],
  herdId: '',
  bridgeToCarrierErrorDetails: null,
  bridgeToCarrierLoader: false,
  isBreedError: false,
  isPolicyBounded: false,
  upcomingCarrierList: [],
};

const INIT_STATE = { ...INIT_PET_STATE };

export default (state = INIT_STATE, action: AppRedux.Action): PetDetailsState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        petBreedsDetailsLoader: false,
        quoteListLoader: false,
      };
    }

    case types.SET_PET_BREEDS_DETAILS: {
      const { cat, dog, isBreedError } = action.payload;
      return {
        ...state,
        petBreedsDetails: {
          cat: cat,
          dog: dog,
        },
        isBreedError: isBreedError,
      };
    }

    case types.SET_PET_QUOTES: {
      const { quoteList, herdId } = action.payload;
      return {
        ...state,
        quoteList: quoteList,
        herdId: herdId ? herdId : state.herdId,
      };
    }

    case types.PET_BREEDS_DETAILS_LOADER: {
      return {
        ...state,
        petBreedsDetailsLoader: action.payload,
      };
    }

    case types.PET_QUOTES_LOADER: {
      return {
        ...state,
        quoteListLoader: action.payload,
      };
    }

    case types.STORE_PET_LIST_DATA: {
      return {
        ...state,
        petList: action.payload,
      };
    }

    case types.STORE_ERRORS_FOR_BRIDGE_TO_CARRIER: {
      return {
        ...state,
        bridgeToCarrierErrorDetails: action.payload,
      };
    }

    case types.BRIDGE_TO_CARRIER_LOADER: {
      const { carrier_id, loader } = action.payload;
      let petQuoteList = [...state.quoteList];
      const updateIndex = petQuoteList.findIndex((item: any) => item.carrier_id === carrier_id);
      if (updateIndex !== -1) {
        petQuoteList[updateIndex] = {
          ...petQuoteList[updateIndex],
          bridgeToCarrierLoader: loader,
        };
      }
      return {
        ...state,
        bridgeToCarrierLoader: loader,
        quoteList: petQuoteList,
      };
    }

    case types.CLEAR_PET_STATE: {
      return {
        ...state,
        ...INIT_STATE,
      };
    }

    case types.SET_POLICY_BOUNDED_FOR_PET: {
      return {
        ...state,
        isPolicyBounded: action.payload,
      };
    }

    case types.STORE_PET_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
