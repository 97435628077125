import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RatesDetailsState } from './types/rates-details';
import { Appointment } from '../../../graphql/generated';

const initialState: RatesDetailsState = {
  appointmentsDetails: [],
};

export const ratesDetailsSlice = createSlice({
  initialState,
  name: 'ratesDetails',
  reducers: {
    setOrgAppointmentsDetails: (state, action: PayloadAction<Appointment[]>) => {
      state.appointmentsDetails = action.payload;
    },

    resetRatesDetails: () => {
      return initialState;
    },
  },
});

export const { setOrgAppointmentsDetails, resetRatesDetails } = ratesDetailsSlice.actions;