import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import { FormControl, FormHelperText, InputLabel, Select, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import DatePicker from '../date-picker';
import { getIncidentFieldError, validateIncidentDate } from '../../auto/utils';
import { OTHER_DETAILS_DATA } from '../../../constants';
import {
  violationFieldDeleted,
  violationFieldUpdated,
  setHasFormChanged,
} from '../../redux/actions';

import './violations-editor.scss';
import { hasViolationChanged, allowEditingQuote } from '../../../utils';

const styles = (theme: Theme) =>
  createStyles({
    formControl: {
      margin: '10px 0px',
      minWidth: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    errorMsg: {
      color: '#FF0000',
      height: '0px',
    },
  });

class ViolationsEditor extends React.Component<
  AppComponents.ViolationDetailsProps & WithStyles<typeof styles>,
  AppComponents.ViolationDetailsState
> {
  static propTypes = {
    uid: PropTypes.string.isRequired,
  };
  initViolationErrors: AppComponents.ViolationDetailsErrors = {
    date_of_violations_error: '',
    violations_description_error: '',
  };
  state: AppComponents.ViolationDetailsState = {
    date_of_violations: '',
    violations_description: '',
    otherDetailsViolationsErrors: { ...this.initViolationErrors },
  };

  getCurrentViolation = (list: Array<any>, uid: string) => find(list, ['violation_uid', uid]);

  componentDidMount() {
    const currentViolationDetails = this.getCurrentViolation(
      this.props.violationList,
      this.props.uid
    );
    this.setState({ ...this.state, ...currentViolationDetails });
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.ViolationDetailsProps) {
    const { violationList, violationErrors, uid, stepSubmitLoader } = newProps;
    const currentViolationDetails = this.getCurrentViolation(violationList, uid);
    this.setState({ ...this.state, ...currentViolationDetails });
    if (violationErrors && !isEmpty(violationErrors) && !stepSubmitLoader) {
      this.setState({ otherDetailsViolationsErrors: { ...this.initViolationErrors } });
      const errorDetails = { ...this.state.otherDetailsViolationsErrors };
      forEach(violationErrors[uid], violationError => {
        if (
          violationError === 'date_of_violations_error' &&
          !isEmpty(currentViolationDetails.date_of_violations)
        ) {
          const violationErrorMessage = validateIncidentDate(
            currentViolationDetails.date_of_violations
          );
          errorDetails[violationError] = !isUndefined(violationErrorMessage)
            ? violationErrorMessage
            : '';
        } else {
          errorDetails[violationError] = getIncidentFieldError(
            this.state,
            violationError.split('_error')[0]
          );
        }
      });
      this.setState({ otherDetailsViolationsErrors: { ...errorDetails } });
    }
  }

  handleFieldChange = (event: any) => {
    const { uid, setHasFormChanged } = this.props;
    const { name, value } = event.target;
    this.setState({ [name]: value } as any, () => {
      if (
        hasViolationChanged({
          field: name,
          list: this.props.violationList,
          value,
        })
      ) {
        setHasFormChanged(true);
      }
      this.props.violationFieldUpdated({ name, value, uid });
    });
  };

  handleCalendarDate = (dateFromCalender: any) => {
    const { uid, setHasFormChanged } = this.props;
    const name = 'date_of_violations';
    const value = dateFromCalender;
    this.setState({ [name]: value } as any, () => {
      if (
        hasViolationChanged({
          field: 'date_of_violations',
          list: this.props.violationList,
          value,
        })
      ) {
        setHasFormChanged(true);
      }
      this.props.violationFieldUpdated({ name, value, uid });
    });
  };

  deleteViolationDetails = () => {
    const { uid, setHasFormChanged } = this.props;
    this.props.violationFieldDeleted({ uid });
    setHasFormChanged(true);
  };

  render() {
    const { classes, violationList, uid, isIncluded } = this.props;
    const { date_of_violations, violations_description, otherDetailsViolationsErrors } = this.state;
    const { date_of_violations_error, violations_description_error } = otherDetailsViolationsErrors;
    const disableAutoEdits = allowEditingQuote();
    return (
      <React.Fragment>
        <div className='row sub-title-blue sub-other-heading mb-3 mt-3 mx-auto'>
          <div className='col-lg-10 col-sm-10 d-flex align-items-center'>
            <Typography className='small-label-heading'>
              Violation {findIndex(violationList, ['violation_uid', uid]) + 1}
            </Typography>
          </div>

          {violationList.length > 1 && !disableAutoEdits?.auto && (
            <div className='col-lg-2 col-sm-2 logo-container'>
              <Tooltip title='Delete' arrow placement='top'>
                <DeleteOutlinedIcon className='delete-logo' onClick={this.deleteViolationDetails} />
              </Tooltip>
            </div>
          )}
        </div>
        <div className='row mx-auto'>
          <div className='col-md-6'>
            <FormControl
              fullWidth
              className={`mb-0 ${classes.formControl} date_of_violations ${
                date_of_violations_error.length > 0 ? 'labelError' : ''
              }`}
              disabled={!isIncluded || disableAutoEdits?.auto}
            >
              <DatePicker
                disabled={!isIncluded || disableAutoEdits?.auto}
                label={
                  <span>
                    Date of Violations<span className='star-error'>*</span>
                  </span>
                }
                name='date_of_violations'
                onChange={this.handleCalendarDate}
                dateValue={date_of_violations}
                error={date_of_violations_error === '' ? false : true}
              />
            </FormControl>
            <FormHelperText className={classes.errorMsg}>{date_of_violations_error}</FormHelperText>
          </div>
          <div className='col-md-6'>
            <FormControl fullWidth className={classes.formControl} disabled={!isIncluded || disableAutoEdits?.auto}>
              <InputLabel shrink htmlFor=''>
                Violation Description<span className='star-error'>*</span>
              </InputLabel>
              <Select
                native
                value={violations_description}
                inputProps={{
                  id: 'violations_description',
                  name: 'violations_description',
                }}
                onChange={this.handleFieldChange}
                error={violations_description_error === '' ? false : true}
              >
                <option value=''>Select</option>
                {map(OTHER_DETAILS_DATA.violations_description, data => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Select>
              <FormHelperText className={classes.errorMsg}>
                {violations_description_error}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (
  state: AppComponents.ViolationDetailsStore
): AppComponents.ViolationDetailsStoreProps => {
  const { stepSubmitLoader } = state.common;
  const { isIncluded } = state.driver;
  const { violationList, violationErrors } = state.otherDetails;
  return { stepSubmitLoader, violationList, violationErrors, isIncluded };
};

const mapDispatchToProps = (dispatch: any): AppComponents.ViolationDetailsDispatch => {
  return bindActionCreators(
    { violationFieldDeleted, violationFieldUpdated, setHasFormChanged },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)<any>(ViolationsEditor));
