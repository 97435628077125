import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Input from '@material-ui/core/Input';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  InputAdornment,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  withStyles,
} from '@material-ui/core';
import {
  ArrowBackIos as ArrowBackIosIcon
} from '@material-ui/icons';
import {
  ASTERISK,
  BACK,
  CONTINUE,
  FEMALE,
  GENDER,
  HOUSEHOLD_INCOME,
  HEIGHT,
  INSURANCE_BUSINESS_TYPES,
  LIFE_INFO,
  INSURANCE_PRODUCTS,
  MALE,
  REQUIRED,
  SELECT,
  TOBACCO_USE,
  QUOTING_STEPS_PAGES_NAME,
  WEIGHT,
} from '../../../constants';
import { map, isEmpty, isEqual, startCase } from 'lodash';
import NumberFormatCustom from '../currency-mask';
import { TOBACCO_USE_DATA } from '../../../constants/life-questionnaire';
import { fetchLifeQuotes, storeLifeQuoteDetails, stopLifePolling, updateRequoteProducts } from '../../redux/actions';
import { 
  allowEditingQuote, 
  getIsHeapAnalyticsEnabled,
  numberRegex,
  hideStepperBackButton, 
  removeExistingRate,
} from '../../../utils';
import './life-form.scss';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  submitSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
});

const Life = (props: any) => {
  const {
    common,
    currentIndex,
    onPrevTab,
    goToLastTab,
    fetchLifeQuotes,
    storeLifeQuoteDetails,
    lifeDetails,
    updateRequoteProducts,
  }: AppComponents.LifeProps = props;
  const [formValues, setFormValues] = useState({
    gender: MALE,
    height_ft: '',
    height_in: '',
    weight_lbs: '',
    household_income: '',
    tobacco_use: '',
 });
  const [formErrors, setFormErrors] = useState({
    height_ft_in_error: '',
    weight_lbs_error: '',
    household_income_error: '',
    tobacco_use_error: '',
  });
  const disablePetEdit = allowEditingQuote();

  useEffect(() => {
    const { lifeInfo } = lifeDetails;
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (!!isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.LIFE_INFO);
    }
    let heightInch: any = lifeInfo?.height?.in ? Number(lifeInfo?.height?.in) : !isEmpty(lifeInfo?.tobacco_use) ? 0 : '';
    let heightFeet: any = lifeInfo?.height?.ft ? Number(lifeInfo?.height?.ft) : !isEmpty(lifeInfo?.tobacco_use) ? 0 : '';
   setFormValues({
      ...formValues,
      gender: lifeInfo?.gender || MALE,
      height_ft: heightFeet,
      height_in: heightInch,
      weight_lbs: lifeInfo?.weight_lbs || '',
      household_income: lifeInfo?.household_income || '',
      tobacco_use: lifeInfo?.tobacco_use || '',
    });
    // eslint-disable-next-line
  }, []);

  const onFieldChange = (event: React.ChangeEvent<any>) => {
    const { target } = event;
    const { name, value } = target;
    if(
      name === LIFE_INFO.HEIGHT_FT_KEY ||
      name === LIFE_INFO.HEIGHT_IN_KEY ||
      name === LIFE_INFO.WEIGHT_LBS_KEY ||
      name === LIFE_INFO.HOUSEHOLD_INCOME_KEY
    ) {
      if (!numberRegex.test(value)) {
        return false;
      }
      const rangeMapping: any = {
        height_ft: 9,
        height_in: 11,
        weight_lbs: 1400,
        household_income: 10000000,
      }
      const rangeFrom = name === LIFE_INFO.WEIGHT_LBS_KEY || name === LIFE_INFO.HOUSEHOLD_INCOME_KEY ? 1 : 0;
      const rangeTo = rangeMapping[name];
      if (!isEmpty(value) && !(value >= rangeFrom && value <= rangeTo)) {
        return false;
      }
    }
    setFormValues({
      ...formValues,
      [name]: value,
    } as any);
  };

  const onBackClick = () => {
    onPrevTab(currentIndex);
  };

  const validate = () => {
    const errors = {
      height_ft_in_error: '',
      weight_lbs_error: '',
      household_income_error: '',
      tobacco_use_error: '',
    };
    const { 
      height_ft,
      height_in,
      weight_lbs,
      household_income,
      tobacco_use,
    } = formValues;
    errors.height_ft_in_error = isEmpty(String(height_ft)) ||  isEmpty(String(height_in)) ? REQUIRED : '';
    errors.weight_lbs_error = isEmpty(String(weight_lbs)) ? REQUIRED : '';
    errors.household_income_error = isEmpty(String(household_income)) ? REQUIRED : '';
    errors.tobacco_use_error = isEmpty(tobacco_use) || tobacco_use === 'select' ? REQUIRED : '';
    setFormErrors(errors);
    if (
      !isEmpty(errors.height_ft_in_error) ||
      !isEmpty(errors.weight_lbs_error) ||
      !isEmpty(errors.household_income_error) ||
      !isEmpty(errors.tobacco_use_error)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const submitStep = () => {
    let isValid = validate();
    if (disablePetEdit?.life) {
      goToLastTab(currentIndex);
    } else if (isValid) {
      let StateInfo = {
        gender: formValues.gender,
        height: {
          ft: Number(formValues.height_ft),
          in: Number(formValues.height_in)
        },
        weight_lbs: Number(formValues.weight_lbs),
        household_income: Number(formValues.household_income),
        tobacco_use: formValues.tobacco_use,
      }
      if(!isEqual(StateInfo, lifeDetails?.lifeInfo) || !lifeDetails?.quoteList?.length) {
        let { requoteLobs }= common;
        if (!isEmpty(requoteLobs) && requoteLobs?.includes(INSURANCE_PRODUCTS[6])) {
          const lobList = requoteLobs?.filter((item: string) => item !== INSURANCE_PRODUCTS[6]);
          updateRequoteProducts(lobList);
        }
        stopLifePolling();
        storeLifeQuoteDetails({
          lifeInfo: StateInfo,
          quotesLoaded: false,
          quoteList: [],
          quoteErrorList: [],
          quotingDetails: null,
          pollingDetails: null,
          upcomingCarrierList: [],
        });
        if (sessionStorage.selectedQuoteList) {
          const selectedQuotes = removeExistingRate(
            JSON.parse(sessionStorage.selectedQuoteList),
            INSURANCE_BUSINESS_TYPES.LIFE
          );
          sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
        }
        fetchLifeQuotes();
      }
      goToLastTab(currentIndex);
    }
  }
  
  return (
    <>
      <div className='lifeDetails-wrapper pt-0'>
        <div className='row form-wrapper d-flex justify-content-center'>
          <div className='col-lg-10'>
            <div className='row'>
              <div className='col-lg-4 mb-6'>
                <FormControl className='radio-wrap w-100' component='fieldset'>
                  <FormLabel
                    disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}
                    className='radio-label'
                    component='legend'
                  >
                    {GENDER}
                  </FormLabel>
                  <RadioGroup
                    className='w-100 life-radio'
                    row
                    id={LIFE_INFO.GENDER_KEY}
                    name={LIFE_INFO.GENDER_KEY}
                    onChange={event => onFieldChange(event)}
                    value={formValues.gender}
                  >
                    <FormControlLabel
                      control={<Radio size='small' />}
                      disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}
                      label={startCase(MALE)}
                      value={MALE}
                    />
                    <FormControlLabel
                      control={<Radio size='small' />}
                      disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}
                      label={startCase(FEMALE)}
                      value={FEMALE}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className='col-lg-4 mb-6'>
                <FormControl className='height-textfield'>
                  <TextField 
                    className='ft-lbl'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name={LIFE_INFO.HEIGHT_FT_KEY}
                    id={LIFE_INFO.HEIGHT_FT_KEY}
                    value={formValues.height_ft}
                    label={
                      <>
                        {HEIGHT}
                        <span className='red-star-20'>{ASTERISK}</span>
                      </>
                    }
                    inputProps={{
                      maxLength: 1,
                    }}
                    onChange={event => onFieldChange(event)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                    }}
                    disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}
                  />
                 

                  <Input className='in-lbl'
                    id={LIFE_INFO.HEIGHT_IN_KEY}
                    value={formValues.height_in}
                    onChange={event => onFieldChange(event)}
                    name={LIFE_INFO.HEIGHT_IN_KEY}
                    endAdornment={<InputAdornment position="end">in</InputAdornment>}
                    aria-describedby="standard-weight-helper-text"
                    disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}
                    inputProps={{
                      maxLength: 2,
                    }}
                  />
                   <div className='error'>{formErrors.height_ft_in_error}</div>
                </FormControl>
              </div>
              <div className='col-lg-4 mb-6'>
                <FormControl className='w-100'>
                  <TextField className='wt-lbl'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id={LIFE_INFO.WEIGHT_LBS_KEY}
                    name={LIFE_INFO.WEIGHT_LBS_KEY}
                    value={formValues.weight_lbs}
                    label={
                      <>
                        {WEIGHT}
                        <span className='red-star-20'>{ASTERISK}</span>
                      </>
                    }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">lbs</InputAdornment>
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                    onChange={event => onFieldChange(event)}
                    disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}
                  />
                  <div className='error ml-0'>{formErrors.weight_lbs_error}</div>
                </FormControl>
              </div>
            </div>
            <div className='row mb-4'>
              <div className='col-lg-6 mb-6 houshold-field'>
                <InputLabel disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life} shrink htmlFor={LIFE_INFO.HOUSEHOLD_INCOME_KEY} className='lbl-household'>
                  {HOUSEHOLD_INCOME}<span className='red-star-20'>*</span>
                </InputLabel>
                <Input
                  value={formValues.household_income}
                  inputProps={{
                    id: `${LIFE_INFO.HOUSEHOLD_INCOME_KEY}`,
                    name: `${LIFE_INFO.HOUSEHOLD_INCOME_KEY}`,
                    maxLength: 11,
                  }}
                  onChange={event => onFieldChange(event)}
                  inputComponent={NumberFormatCustom as any}
                  disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life} 
                />
                <div className='error ml-0'>{formErrors.household_income_error}</div>
              </div>
              <div className='col-lg-6 mb-6'>
                <FormControl className='w-100'>
                  <InputLabel id="demo-simple-select-label" disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life}>{TOBACCO_USE}<span className='star-error'>{ASTERISK}</span></InputLabel>
                  <NativeSelect
                    disabled={lifeDetails?.quoteListLoader || disablePetEdit?.life} 
                    id={LIFE_INFO.TOBACCO_USE_KEY}
                    name={LIFE_INFO.TOBACCO_USE_KEY}
                    value={formValues.tobacco_use}
                    onChange={event => onFieldChange(event)}
                  >
                    <option value='select'>{startCase(SELECT)}</option>
                    {map(TOBACCO_USE_DATA, (data, index) => (
                      <option key={`${index}${data.value}`} value={data.value}>
                        {data.label}
                      </option>
                    ))}
                  </NativeSelect>
                  <div className='error ml-0'>{formErrors.tobacco_use_error}</div>
                </FormControl>
              </div>
            </div>
            <div className="row form-submit-action d-flex justify-content-center">
              <div className="col-lg-12">
                {(common?.isAddingProducts && currentIndex === 1) || hideStepperBackButton(INSURANCE_BUSINESS_TYPES.LIFE) ? ('') : (
                  <button 
                    className='btn m-2 btn-back mh-button'
                    disabled={lifeDetails?.quoteListLoader}
                    onClick={() => onBackClick()}
                  >
                    <ArrowBackIosIcon className='mr-2' />
                    {BACK}
                  </button>
                )}
                <button
                 className="btn m-2 btn-success mh-button"
                 disabled={lifeDetails?.quoteListLoader}
                 onClick={() => submitStep()}
                >
                  <span>{CONTINUE}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({
  common,
  applicant,
  lifeDetails,
}: AppComponents.LifeStore): AppComponents.LifeStore => ({
  common,
  applicant,
  lifeDetails,
});

const mapDispatchToProps = (dispatchProp: any): AppComponents.LifeDispatch =>
  bindActionCreators(
    {
      fetchLifeQuotes,
      storeLifeQuoteDetails,
      stopLifePolling,
      updateRequoteProducts,
    },
    dispatchProp
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(Life));
