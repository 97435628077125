import { startCase } from 'lodash';
import { StateName } from '../../../../../graphql/generated';

export const createPropertyDataRequestMapper = (address: Record<string, string>) => {
  return {
    input: {
      street: address?.street,
      street2: '',
      city: address?.city,
      state: (StateName as any)[startCase(address?.state?.toLowerCase() || '')],
      county: '',
      zipcode: address?.zip,
    },
  };
};
