import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  NativeSelect,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import { forEach, get, has, isEmpty, map, startCase } from 'lodash';
import { CommonUploadFile } from '../ams-details-common-component';
import NumberFormatCustom from '../currency-mask';
import AutoComplete from '../auto-complete';
import DatePicker from '../date-picker';
import DropDownAutocomplete from '../drop-down-autocomplete';
import SpinnerLoader from '../spinner-loader';
import PhoneMask from '../phone-mask';
import {
  amsProductList,
  downloadFileUrl,
  formatPhoneNumberWithBrackets,
  getSalesforceCustomerUUID,
  openUrlInNewTab,
} from '../../../utils';
import {
  ASSISTED_BY,
  ASTERISK,
  BROKER,
  BROKER_CODE_KEY,
  CUSTOM_ID,
  LOAN_NUMBER_LABEL,
  LOAN_NUMBER_KEY,
  BUSINESS_LINE,
  CANCEL,
  CARRIER,
  CARRIER_WRITING_COMPANY,
  CITY,
  CREATE_NEW_CUSTOMER,
  DATE_OF_BIRTH,
  DEPARTMENT,
  DESCRIPTION,
  DIVISION,
  DOB,
  EFFECTIVE_DATE,
  EFFECTIVE_DATE_KEY,
  EMAIL,
  EMAIL_ADDRESS,
  EMPLOYEE,
  EXECUTIVE,
  FIRST_NAME,
  FIRST_NAME_KEY,
  GROUP,
  LAST_NAME,
  LAST_NAME_KEY,
  LOB_KEY,
  MD,
  NAME,
  NONE,
  PAGES_TEXT,
  PHONE_NUMBER_LABEL,
  PHONE_NUMBER_KEY,
  POLICY_NUMBER,
  POLICY_TERMS,
  PREMIUM,
  REQUIRED,
  PRODUCT,
  REPRESENTATIVE,
  SALESFORCE_CUSTOMER_ID,
  SELECT,
  STATE,
  STREET_ADDRESS,
  SUBMIT,
  TERM,
  TEXT_TYPE,
  UNIT,
  ZIP,
  ZIP_CODE,
  SELECTED_CARRIER,
  AMS_FORM_FIELD,
  LOB_PRODUCTS_MAPPING,
  ADHOC_NON_ADMITTED_AMS_LOB_LIST,
  AMS_FILE_TYPE,
} from '../../../constants';
import { NON_ADMITTED_AMS_CONSENT } from '../../../constants/non-admitted';
import {
  getAmsSignatureForm,
  getIsShowSl2OrDueDiligenceForm,
  isShowCarrierRiskConsentCheckbox,
} from '../../../utils/ams-details';
import './sfdc-ams-details.scss';
const { DUE_DILIGENCE_FORM_DOCUMENT, EOI_DOCUMENT, SL2_FORM_DOCUMENT } = AMS_FORM_FIELD;

const AMSDROPDOWNCLASS = 'ams-dropdown';
const addressFieldError = (errorText: string) => {
  return errorText.length > 0;
};
const addressErrorShow = (component: any, formErrors: any) => {
  const { classes } = component.props;
  return isEmpty(formErrors) ? (
    <div className={classes.errorText} />
  ) : (
    <div className={classes.errorText}>{formErrors}</div>
  );
};

const fileDetails = (label: string, fileName: string, fileUrl: string) => {
  return (
    <div className={`col-md-6 resp-mb-20 ${label !== EOI_DOCUMENT.LABEL ? 'mt-4' : ''}`}>
      <FormControl fullWidth>
        <InputLabel shrink>
          {label}
          <span className='star-error'>{ASTERISK}</span>
        </InputLabel>
      </FormControl>
      <Typography
        className='eoi-file-name-label cursor-pointer'
        onClick={() => downloadFileUrl(fileUrl)}
        title={fileName}
      >
        <GetAppIcon className='download-icon' />
        {fileName}
      </Typography>
    </div>
  );
};

const getApplicantAddressFields = (
  component: any,
  formDetails: any,
  formDetailsErrors: any,
  common: any
) => {
  const {
    addressDetails: { street, city, zip, unit, state },
  } = component.state.formDetails;
  const { classes } = component.props;
  let isPolicyBounded: boolean = !!common?.sfdcAmsFormData?.isPolicyBounded;
  return (
    <React.Fragment>
      <fieldset>
        <div className='row rw-mb-res-4'>
          <div className='col-md-4 resp-mb-20'>
            <FormControl
              fullWidth
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              error={formDetailsErrors.firstNameError.length > 0}
            >
              <InputLabel shrink htmlFor={FIRST_NAME_KEY}>
                {FIRST_NAME}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: FIRST_NAME_KEY,
                  id: FIRST_NAME_KEY,
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                value={formDetails?.firstName}
                onChange={component.handlePersonalDetails}
                onBlur={component.handlePersonalDetailsBlur}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.firstNameError}
            </FormHelperText>
          </div>
          <div className='col-md-4 resp-mb-20'>
            <FormControl
              fullWidth
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              error={formDetailsErrors.lastNameError.length > 0}
            >
              <InputLabel shrink htmlFor={LAST_NAME_KEY}>
                {LAST_NAME}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  name: LAST_NAME_KEY,
                  id: LAST_NAME_KEY,
                  spellCheck: false,
                  maxLength: 20,
                  autoComplete: NONE?.toLowerCase(),
                }}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                value={formDetails?.lastName}
                onChange={component.handlePersonalDetails}
                onBlur={component.handlePersonalDetailsBlur}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.lastNameError}
            </FormHelperText>
          </div>
          <div className='col-md-4 resp-mb-20'>
            <FormControl
              fullWidth
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              error={formDetailsErrors.phoneNumberError.length > 0}
            >
              <InputLabel shrink htmlFor={PHONE_NUMBER_KEY}>
                {PHONE_NUMBER_LABEL}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                inputProps={{
                  id: PHONE_NUMBER_KEY,
                  name: PHONE_NUMBER_KEY,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                value={formDetails?.phoneNumber}
                inputComponent={PhoneMask}
                onChange={component.handlePersonalDetails}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.phoneNumberError}
            </FormHelperText>
          </div>
        </div>

        <div className='row rw-mb-res-4'>
          <div className='col-md-6 resp-mb-20'>
            <FormControl fullWidth>
              <TextField
                error={formDetailsErrors.emailError.length > 0}
                type={TEXT_TYPE}
                label={
                  <>
                    {EMAIL_ADDRESS}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                autoComplete='off'
                className={`${classes.textField} text-margin-class`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  name: EMAIL,
                  id: EMAIL,
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={formDetails?.email}
                onChange={component.handlePersonalDetails}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.emailError}
            </FormHelperText>
          </div>
          <div className='col-md-6 resp-mb-20'>
            <FormControl fullWidth>
              <DatePicker
                styles={{
                  marginBottom: '0px !important',
                  marginTop: '0px !important',
                }}
                label={
                  <>
                    {DATE_OF_BIRTH}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                name={DOB}
                onChange={component.handleDateOfBirth}
                dateValue={formDetails?.dob}
                error={formDetailsErrors.dobError.length > 0 ? true : false}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              />
            </FormControl>
            <FormHelperText className={classes.fieldError}>
              {formDetailsErrors.dobError}
            </FormHelperText>
          </div>
        </div>
        <div className='address-container'>
          <div className='row rw-mb-res-4'>
            <div className='col-md-8'>
              <div className='street '>
                <AutoComplete
                  value={street}
                  onChange={component.handleHomeAddressDetailsChange}
                  onAutoCompleteAddressSelected={component.onAddressSelected}
                  onInputBlur={component.handleAddressBlur}
                  disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                  clearAutoCompleteInput={component.clearAddressInputs}
                  error={addressFieldError(formDetailsErrors.street)}
                  title={
                    <>
                      {STREET_ADDRESS}
                      <span className='star-error'>{ASTERISK}</span>
                    </>
                  }
                />
                {addressErrorShow(component, formDetailsErrors.street)}
              </div>
            </div>

            <div className=' col-md-4'>
              <div className='unit'>
                <TextField
                  type={TEXT_TYPE}
                  label={`${UNIT} #`}
                  autoComplete='off'
                  className={classes.addressTextField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    name: UNIT?.toLowerCase(),
                    id: UNIT?.toLowerCase(),
                    spellCheck: false,
                    maxLength: 50,
                    autoComplete: NONE?.toLowerCase(),
                  }}
                  error={formDetailsErrors.unit.length > 0}
                  value={unit}
                  onChange={component.handleHomeAddressDetailsChange}
                  onBlur={component.handleAddressBlur}
                  disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                />
                <div className={classes.errorText}>{formDetailsErrors.unit}</div>
              </div>
            </div>
          </div>

          <div className='row rw-mb-res-4'>
            <div className='city col-md-4 '>
              <TextField
                type={TEXT_TYPE}
                label={
                  <>
                    {CITY}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                autoComplete='off'
                className={classes.addressTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  id: CITY?.toLowerCase(),
                  name: CITY?.toLowerCase(),
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={city}
                onChange={component.handleHomeAddressDetailsChange}
                onBlur={component.handleAddressBlur}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                error={addressFieldError(formDetailsErrors.city)}
              />
              {addressErrorShow(component, formDetailsErrors.city)}
            </div>
            <div className='state col-md-4 '>
              <FormControl
                className={`${classes.formControl}`}
                error={addressFieldError(formDetailsErrors.state)}
              >
                <InputLabel shrink htmlFor='state-simple'>
                  {STATE}
                  <span className='star-error'>{ASTERISK}</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  className='state-class'
                  value={!isEmpty(state) ? state?.toUpperCase() : ''}
                  onChange={component.handleHomeAddressDetailsChange}
                  disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                  inputProps={{
                    name: 'state',
                    id: 'state-simple',
                  }}
                >
                  <option value=''>{startCase(SELECT)}</option>

                  {component.state.stateName.map((stateName: any) => (
                    <option key={stateName.label} value={stateName.value}>
                      {stateName.value}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>

              {addressErrorShow(component, formDetailsErrors.state)}
            </div>
            <div className='zip col-md-4 '>
              <TextField
                type={TEXT_TYPE}
                label={
                  <>
                    {ZIP_CODE}
                    <span className='star-error'>{ASTERISK}</span>
                  </>
                }
                autoComplete='off'
                className={classes.addressTextField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  id: ZIP,
                  name: ZIP,
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={zip}
                onChange={component.handleHomeAddressDetailsChange}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                error={addressFieldError(formDetailsErrors.zip)}
              />
              {addressErrorShow(component, formDetailsErrors.zip)}
            </div>
          </div>
        </div>
      </fieldset>{' '}
    </React.Fragment>
  );
};

const getDropdownOptions = (list: any, codeKey: any) => {
  return map(list, (details: any, key: string) => (
    <option value={details[codeKey]} key={`${details.ShortName}-${key}`}>
      {has(details, startCase(NAME)) ? details.Name : `${details.FirstName} ${details.LastName}`}
    </option>
  ));
};

const getRepresentativeAndGroupFields = (
  component: any,
  formDetails: any,
  formDetailsErrors: any
) => {
  const { classes, common } = component.props;
  let isPolicyBounded: boolean = !!common?.sfdcAmsFormData?.isPolicyBounded;
  return (
    <div className='row rw-mb-res-4'>
      <div className='col-md-6 resp-mb-20'>
        <FormControl fullWidth error={formDetailsErrors.representativeError.length > 0}>
          <InputLabel shrink htmlFor={REPRESENTATIVE?.toLowerCase()}>
            {REPRESENTATIVE}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          {!isPolicyBounded ? (
            <Select
              native
              inputProps={{
                id: REPRESENTATIVE?.toLowerCase(),
                name: REPRESENTATIVE?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.representative}
              onChange={component.handleInputChange}
              disabled={common?.SfdcAMSDetailsLoading}
            >
              <option value='' key='representative-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.representativeOptions, 'EmployeeCode')}
            </Select>
          ) : (
            <Input
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails?.representative}
            />
          )}
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.representativeError}
        </FormHelperText>
      </div>
      <div className='col-md-6 resp-mb-20'>
        <FormControl fullWidth error={formDetailsErrors.groupError.length > 0}>
          <InputLabel shrink htmlFor={GROUP?.toLowerCase()}>
            {GROUP}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          {!isPolicyBounded ? (
            <Select
              native
              inputProps={{
                id: GROUP?.toLowerCase(),
                name: GROUP?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.group}
              onChange={component.handleInputChange}
              disabled={common?.SfdcAMSDetailsLoading}
            >
              <option value='' key='group-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.groupOptions, 'GLGroupCode')}
            </Select>
          ) : (
            <Input
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails?.group}
            />
          )}
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.groupError}
        </FormHelperText>
      </div>
    </div>
  );
};

const getDepartmentAndEmployeeFields = (
  component: any,
  formDetails: any,
  formDetailsErrors: any
) => {
  const { classes, common } = component.props;
  let isPolicyBounded: boolean = !!common?.sfdcAmsFormData?.isPolicyBounded;
  return (
    <div className='row rw-mb-res-4'>
      <div className='col-md-6 resp-mb-20'>
        <FormControl fullWidth error={formDetailsErrors.departmentError.length > 0}>
          <InputLabel shrink htmlFor={DEPARTMENT?.toLowerCase()}>
            {DEPARTMENT}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          {!isPolicyBounded ? (
            <Select
              native
              inputProps={{
                id: DEPARTMENT?.toLowerCase(),
                name: DEPARTMENT?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.department}
              onChange={component.handleInputChange}
              disabled={common?.SfdcAMSDetailsLoading}
            >
              <option value='' key='department-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.departmentOptions, 'GLDepartmentCode')}
            </Select>
          ) : (
            <Input
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails?.department}
            />
          )}
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.departmentError}
        </FormHelperText>
      </div>
      <div className='col-md-6 resp-mb-20'>
        <FormControl fullWidth error={formDetailsErrors.employeeError.length > 0}>
          <InputLabel shrink htmlFor={EMPLOYEE?.toLowerCase()}>
            {EMPLOYEE}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          {!isPolicyBounded ? (
            <Select
              native
              inputProps={{
                id: EMPLOYEE?.toLowerCase(),
                name: EMPLOYEE?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.employee}
              onChange={component.handleInputChange}
              disabled={common?.SfdcAMSDetailsLoading}
            >
              <option value='' key='employee-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.employeeOptions, 'EmployeeCode')}
            </Select>
          ) : (
            <Input
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails?.employee}
            />
          )}
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.employeeError}
        </FormHelperText>
      </div>
    </div>
  );
};

const getBrokerField = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  const {
    eoiFileName,
    isEoiFileUploadEnabled,
    isShowLoanNumberField,
    sl2FormFileName,
    dueDiigenceFormFileName,
  } = component.state;
  const isPolicyBounded: boolean = !!common?.sfdcAmsFormData?.isPolicyBounded;
  const policyData = common?.sfdcAmsFormData?.policyData || null;
  const salesforceCustomerUUID: string = getSalesforceCustomerUUID(common?.sfdcAmsFormData);
  const nonAdmitedDocumentName = getIsShowSl2OrDueDiligenceForm(formDetails);
  return (
    <div className='row rw-mb-res-4'>
      <div className='col-md-6 resp-mb-20'>
        <FormControl
          fullWidth
          error={formDetailsErrors.brokerError.length > 0}
          disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
        >
          <InputLabel shrink htmlFor={BROKER?.toLowerCase()}>
            {BROKER}
            <span className='star-error'>{ASTERISK}</span>
          </InputLabel>
          {!isPolicyBounded ? (
            <Select
              native
              inputProps={{
                id: BROKER?.toLowerCase(),
                name: BROKER?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.broker}
              onChange={component.handleInputChange}
            >
              <option value='' key='broker-empty'>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.brokerOptions, BROKER_CODE_KEY)}
            </Select>
          ) : (
            <Input
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails?.broker}
            />
          )}
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors.brokerError}
        </FormHelperText>
      </div>
      {isEoiFileUploadEnabled && !isPolicyBounded && (
        <CommonUploadFile
          label={EOI_DOCUMENT.LABEL}
          name={EOI_DOCUMENT.KEY}
          fileName={eoiFileName}
          error={formDetailsErrors?.eoiFileError}
          isLoading={common?.SfdcAMSDetailsLoading || isPolicyBounded}
          onChange={component.onChangeDocumentModel}
          onClear={component.onClearAmsDocumentUpload}
          className={classes.fieldError}
        />
      )}
      {!isEmpty(eoiFileName) &&
        isPolicyBounded &&
        fileDetails(EOI_DOCUMENT.LABEL, eoiFileName, formDetails?.eoiFile)}
      <div
        className={`${
          isEoiFileUploadEnabled || (!isEmpty(eoiFileName) && isPolicyBounded)
            ? 'custom-id-res'
            : ''
        } col-md-6`}
      >
        <FormControl
          fullWidth
          error={formDetailsErrors.customId.length > 0}
          disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
        >
          <InputLabel shrink htmlFor={CUSTOM_ID.KEY?.toLowerCase()}>
            {CUSTOM_ID.LABEL}
          </InputLabel>
          <Input
            inputProps={{
              id: CUSTOM_ID.KEY,
              name: CUSTOM_ID.KEY,
              maxLength: 50,
              autoComplete: NONE?.toLowerCase(),
            }}
            value={formDetails?.customId}
            onChange={component.handleInputChange}
            disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
          />
        </FormControl>
        <FormHelperText className={classes.fieldError}>{formDetailsErrors.customId}</FormHelperText>
      </div>
      {isShowLoanNumberField && (
        <div className='col-md-6 custom-id-res resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.loanNumber.length > 0}
            disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
          >
            <InputLabel shrink htmlFor={LOAN_NUMBER_LABEL?.toLowerCase()}>
              {LOAN_NUMBER_LABEL}
            </InputLabel>
            <Input
              inputProps={{
                id: LOAN_NUMBER_KEY,
                name: LOAN_NUMBER_KEY,
                maxLength: 50,
                autoComplete: NONE?.toLowerCase(),
              }}
              value={formDetails?.loanNumber}
              onChange={component.handleInputChange}
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
            />
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.loanNumber}
          </FormHelperText>
        </div>
      )}
      <div className='col-md-6 custom-id-res'>
        <FormControl fullWidth disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}>
          <InputLabel shrink htmlFor={ASSISTED_BY.KEY}>
            {ASSISTED_BY.LABEL}
          </InputLabel>
          {!isPolicyBounded ? (
            <Select
              native
              inputProps={{
                id: ASSISTED_BY.KEY,
                name: ASSISTED_BY.KEY,
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails.assistedBy}
              onChange={component.handleInputChange}
            >
              <option value='' key={ASSISTED_BY.KEY}>
                {startCase(SELECT)}
              </option>
              {getDropdownOptions(formDetails.brokerOptions, BROKER_CODE_KEY)}
            </Select>
          ) : (
            <Input
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails?.assistedBy}
            />
          )}
        </FormControl>
      </div>
      <div className='col-md-6 custom-id-res resp-mb-20'>
        <FormControl
          fullWidth
          disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
          error={!isEmpty(formDetailsErrors?.salesforceCustomerUUIDErr)}
        >
          <InputLabel shrink htmlFor={SALESFORCE_CUSTOMER_ID.KEY.toLowerCase()}>
            {SALESFORCE_CUSTOMER_ID.LABEL}
          </InputLabel>
          <Input
            inputProps={{
              id: SALESFORCE_CUSTOMER_ID.KEY,
              name: SALESFORCE_CUSTOMER_ID.KEY,
              maxLength: SALESFORCE_CUSTOMER_ID.MAX_LENGTH,
              autoComplete: NONE?.toLowerCase(),
            }}
            value={formDetails?.salesforceCustomerUUID}
            onChange={component.handleInputChange}
            disabled={
              !isEmpty(salesforceCustomerUUID) || common?.SfdcAMSDetailsLoading || isPolicyBounded
            }
          />
        </FormControl>
        <FormHelperText className={classes.fieldError}>
          {formDetailsErrors?.salesforceCustomerUUIDErr}
        </FormHelperText>
      </div>
      {nonAdmitedDocumentName === SL2_FORM_DOCUMENT.KEY && !isPolicyBounded && (
        <>
          <CommonUploadFile
            label={SL2_FORM_DOCUMENT.LABEL}
            name={SL2_FORM_DOCUMENT.KEY}
            fileName={sl2FormFileName}
            error={formDetailsErrors?.sl2FormFileNameError}
            isLoading={common?.SfdcAMSDetailsLoading || isPolicyBounded}
            onChange={component.onChangeDocumentModel}
            onClear={component.onClearAmsDocumentUpload}
            className={classes.fieldError}
          />
          {!isEmpty(getAmsSignatureForm(SL2_FORM_DOCUMENT.KEY)) && (
            <div className='col-md-6 Res-display resp-mb-20 mt-4'>
              <FormControl fullWidth disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}>
                <InputLabel shrink htmlFor={SL2_FORM_DOCUMENT.SIGNATURE_LABEL}>
                  {SL2_FORM_DOCUMENT.SIGNATURE_LABEL}
                </InputLabel>
              </FormControl>
              <div
                className='d-flex justify-content-center'
                title={SL2_FORM_DOCUMENT.SIGNATURE_TOOLTIP}
              >
                <OpenInNewOutlinedIcon
                  className={`publish-icon ${
                    common?.SfdcAMSDetailsLoading || isPolicyBounded
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                  onClick={() =>
                    !common?.SfdcAMSDetailsLoading || !isPolicyBounded
                      ? openUrlInNewTab(getAmsSignatureForm(SL2_FORM_DOCUMENT.KEY))
                      : ''
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
      {nonAdmitedDocumentName === DUE_DILIGENCE_FORM_DOCUMENT.KEY && !isPolicyBounded && (
        <>
          <CommonUploadFile
            label={DUE_DILIGENCE_FORM_DOCUMENT.LABEL}
            name={DUE_DILIGENCE_FORM_DOCUMENT.KEY}
            fileName={dueDiigenceFormFileName}
            error={formDetailsErrors?.dueDiigenceFormFileNameError}
            isLoading={common?.SfdcAMSDetailsLoading || isPolicyBounded}
            onChange={component.onChangeDocumentModel}
            onClear={component.onClearAmsDocumentUpload}
            className={classes.fieldError}
          />
          {!isEmpty(getAmsSignatureForm(DUE_DILIGENCE_FORM_DOCUMENT.KEY)) && (
            <div className='col-md-6 Res-display resp-mb-20 mt-4'>
              <FormControl fullWidth disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}>
                <InputLabel shrink htmlFor={DUE_DILIGENCE_FORM_DOCUMENT.SIGNATURE_LABEL}>
                  {DUE_DILIGENCE_FORM_DOCUMENT.SIGNATURE_LABEL}
                </InputLabel>
              </FormControl>
              <div
                className='d-flex justify-content-center'
                title={DUE_DILIGENCE_FORM_DOCUMENT.SIGNATURE_TOOLTIP}
              >
                <OpenInNewOutlinedIcon
                  className={`publish-icon ${
                    common?.SfdcAMSDetailsLoading || isPolicyBounded
                      ? 'cursor-default'
                      : 'cursor-pointer'
                  }`}
                  onClick={() =>
                    !common?.SfdcAMSDetailsLoading || !isPolicyBounded
                      ? openUrlInNewTab(getAmsSignatureForm(DUE_DILIGENCE_FORM_DOCUMENT.KEY))
                      : ''
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
      {!isEmpty(policyData?.amsFileName) &&
        isPolicyBounded &&
        fileDetails(
          policyData?.amsFileType === AMS_FILE_TYPE.SL2
            ? SL2_FORM_DOCUMENT.LABEL
            : DUE_DILIGENCE_FORM_DOCUMENT.LABEL,
          policyData?.amsFileName,
          policyData?.amsFileUrl
        )}
    </div>
  );
};

const getAMSDetailsFields = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  let isPolicyBounded: boolean = !!common?.sfdcAmsFormData?.isPolicyBounded;
  return (
    <React.Fragment>
      <div className='row rw-mb-res-4'>
        <div className='col-md-6 resp-mb-20'>
          <FormControl fullWidth error={formDetailsErrors.divisionError.length > 0}>
            <InputLabel shrink htmlFor={DIVISION?.toLowerCase()}>
              {DIVISION}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            {!isPolicyBounded ? (
              <Select
                native
                inputProps={{
                  id: DIVISION?.toLowerCase(),
                  name: DIVISION?.toLowerCase(),
                }}
                classes={{ root: AMSDROPDOWNCLASS }}
                value={formDetails?.division}
                onChange={component.handleInputChange}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              >
                <option value='' key='division-empty'>
                  {startCase(SELECT)}
                </option>
                {getDropdownOptions(formDetails.divisionOptions, 'GLDivisionCode')}
              </Select>
            ) : (
              <Input
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                value={formDetails?.division}
              />
            )}
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.divisionError}
          </FormHelperText>
        </div>
        <div className='col-md-6 resp-mb-20'>
          <FormControl fullWidth error={formDetailsErrors.executiveError.length > 0}>
            <InputLabel shrink htmlFor={EXECUTIVE?.toLowerCase()}>
              {EXECUTIVE}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            {!isPolicyBounded ? (
              <Select
                native
                inputProps={{
                  id: EXECUTIVE?.toLowerCase(),
                  name: EXECUTIVE?.toLowerCase(),
                }}
                classes={{ root: AMSDROPDOWNCLASS }}
                value={formDetails?.executive}
                onChange={component.handleInputChange}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              >
                <option value='' key='executive-empty'>
                  {startCase(SELECT)}
                </option>
                {getDropdownOptions(formDetails.executiveOptions, 'EmployeeCode')}
              </Select>
            ) : (
              <Input
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                value={formDetails?.executive}
              />
            )}
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.executiveError}
          </FormHelperText>
        </div>
      </div>
      {getRepresentativeAndGroupFields(component, formDetails, formDetailsErrors)}

      {getDepartmentAndEmployeeFields(component, formDetails, formDetailsErrors)}

      {getBrokerField(component, formDetails, formDetailsErrors)}
    </React.Fragment>
  );
};

const disableCarrierFields = (common: any) =>
  common.SfdcAMSDetailsLoading || common.carrierUnderwriterLoader;

const getCarrierFields = (component: any, formDetails: any, formDetailsErrors: any) => {
  const { classes, common } = component.props;
  let isPolicyBounded: boolean = !!common?.sfdcAmsFormData?.isPolicyBounded;
  return (
    <React.Fragment>
      <div className='row rw-mb-res-4'>
        <div className='col-md-6 resp-mb-20'>
          {!isPolicyBounded ? (
            <>
              <DropDownAutocomplete
                isRequired={true}
                value={formDetails.selectedCarrier}
                onChange={event => {
                  component.dropDownValuesUpdated(event, SELECTED_CARRIER);
                }}
                recordsList={getDropdownValues(formDetails.carrierOptions, CARRIER)}
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                label={CARRIER}
                error={formDetailsErrors.carrierNameError.length > 0}
              />
            </>
          ) : (
            <FormControl fullWidth error={formDetailsErrors.policyNumberError.length > 0}>
              <InputLabel shrink htmlFor='carrierName'>
                {CARRIER}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <Input
                value={formDetails?.carrierName}
                disabled={true}
              />
            </FormControl>
          )}
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.carrierNameError}
          </FormHelperText>
        </div>
        <div className='col-md-6 resp-mb-20'>
          <FormControl fullWidth error={formDetailsErrors.policyNumberError.length > 0}>
            <InputLabel shrink htmlFor='policyNumber'>
              {POLICY_NUMBER}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Input
              inputProps={{
                id: 'policyNumber',
                name: 'policyNumber',
                maxLength: 25,
                autoComplete: NONE?.toLowerCase(),
              }}
              value={formDetails?.policyNumber}
              onChange={component.handleInputChange}
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
            />
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.policyNumberError}
          </FormHelperText>
        </div>
      </div>
      <div className={`row rw-mb-res-4 ${formDetailsErrors.premiumError && formDetailsErrors.premiumError.toLowerCase() !== REQUIRED.toLowerCase() && 'pb-3'}`}>
        <div className='col-md-3 resp-mb-20'>
          <FormControl fullWidth error={formDetailsErrors.premiumError.length > 0}>
            <InputLabel shrink htmlFor={PREMIUM?.toLowerCase()}>
              {PREMIUM}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Input
              inputProps={{
                id: PREMIUM?.toLowerCase(),
                name: PREMIUM?.toLowerCase(),
                maxLength: 12,
                decimalScale: 2,
                autoComplete: NONE?.toLowerCase(),
              }}
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
              value={formDetails.premium}
              inputComponent={NumberFormatCustom as any}
              onChange={component.handleInputChange}
            />
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.premiumError}
          </FormHelperText>
        </div>
        <div className='col-md-3 resp-mb-20'>
          <FormControl fullWidth error={formDetailsErrors.termError.length > 0}>
            <InputLabel shrink htmlFor={TERM?.toLowerCase()}>
              {TERM}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <Select
              native
              inputProps={{
                id: TERM?.toLowerCase(),
                name: TERM?.toLowerCase(),
              }}
              classes={{ root: AMSDROPDOWNCLASS }}
              value={formDetails?.term}
              onChange={component.onPremiumTermSelected}
              disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
            >
              <option value=''>{startCase(SELECT)}</option>
              {map(POLICY_TERMS, termDetails => (
                <option key={termDetails.label} value={termDetails.value}>
                  {termDetails.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.termError}
          </FormHelperText>
        </div>
        <div className='col-md-6 resp-mb-20'>
          <FormControl
            fullWidth
            error={formDetailsErrors.carrierUnderwriterError.length > 0}
            disabled={
              formDetails.carrierUnderwriterOptions.length === 0 ||
              disableCarrierFields(common) ||
              isPolicyBounded
            }
          >
            <InputLabel shrink htmlFor='carrierUnderwriter'>
              {CARRIER_WRITING_COMPANY}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            {!isPolicyBounded ? (
              <>
                <Select
                  native
                  inputProps={{
                    id: 'carrierUnderwriter',
                    name: 'carrierUnderwriter',
                  }}
                  classes={{ root: AMSDROPDOWNCLASS }}
                  value={formDetails?.carrierUnderwriter}
                  onChange={component.handleInputChange}
                >
                  <option value='' key='carrierUnderwriter-empty'>
                    Select
                  </option>
                  {getDropdownOptions(formDetails.carrierUnderwriterOptions, 'CompanyCode')}
                </Select>
              </>
            ) : (
              <Input
                disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                value={formDetails?.carrierUnderwriter}
              />
            )}
          </FormControl>
          <FormHelperText className={classes.fieldError}>
            {formDetailsErrors.carrierUnderwriterError}
          </FormHelperText>
        </div>
      </div>
    </React.Fragment>
  );
};

const getSubmitButtonLoader = (common: any) =>
  common.SfdcAMSDetailsLoading || common.carrierUnderwriterLoader;

const getNavButtons = (component: any, validNumber = false, customerData: any) => {
  const { classes, common } = component.props;
  return (
    <div className={classes.buttonWrapper}>
      <Button
        variant='outlined'
        className='navigation-back-btn'
        disabled={getSubmitButtonLoader(common)}
        onClick={() => {
          validNumber ? component.handleClosePhoneNumberModal() : component.handleClose(false);
        }}
      >
        <span className='link-label'>{CANCEL}</span>
      </Button>
      <Button
        variant='outlined'
        className='navigation-next-btn ams-submit-btn'
        disabled={
          getSubmitButtonLoader(common) ||
          common.maxAttemptReached ||
          (validNumber && !component.state.formDetails.customerId)
        }
        onClick={
          validNumber
            ? () => {
                component.selectExistingCustomer(customerData);
              }
            : component.handleFormSubmit
        }
      >
        {SUBMIT}
        {getSubmitButtonLoader(common) && <SpinnerLoader styleData={classes.submitSpinner} />}
      </Button>
    </div>
  );
};
const getSubmitButtons = (component: any, validNumber = false, customerData: any) => {
  const { classes, common } = component.props;
  return (
    <div className={classes.buttonWrapper}>
      <Button
        variant='outlined'
        className='navigation-next-btn ams-submit-btn'
        onClick={
          validNumber
            ? () => {
                component.selectExistingCustomer(customerData);
              }
            : () => {
                component.handleFormSubmit();
              }
        }
        disabled={getSubmitButtonLoader(common)}
      >
        {SUBMIT}
        {getSubmitButtonLoader(common) && <SpinnerLoader styleData={classes.submitSpinner} />}
      </Button>
    </div>
  );
};

const getDropdownValues = (list: Array<any>, key: string) => {
  const updatedArray: Array<any> = [];
  forEach(list, item => {
    key === CARRIER ? item && updatedArray.push(item) : item[key] && updatedArray.push(item[key]);
  });
  return updatedArray;
};

const table = (
  diferentCustomer: any,
  classes: any,
  handleRadioButtonChange: any,
  customerId: any,
  getHeadCells: any
) => {
  const headCells = getHeadCells();
  return (
    <div className='table-wrapper'>
      <Table classes={{ root: classes.table }}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {headCells.map((headCell: any) => (
              <TableCell className={classes.tableHeadRow} key={headCell.id}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {diferentCustomer?.map((customer: any) => (
            <TableRow
              key={customer.customerId}
              classes={{ root: classes.tableRow }}
              onClick={() => {
                handleRadioButtonChange(customer);
              }}
            >
              <TableCell className={classes.tableBodyRow}>
                <Radio
                  className='ams-radio-btn'
                  checked={customer.customerId === customerId}
                  name={customer.first_name}
                  color='default'
                />
              </TableCell>
              <TableCell>{get(customer, 'first_name', '-')}</TableCell>
              <TableCell>{get(customer, 'last_name', '-')}</TableCell>
              <TableCell className={classes.fieldWidth}>
                {`${customer.address.street.trim()},
                ${customer.address.city.trim()}, ${customer.address.state.trim()}, ${
                  customer.address.zip
                }`}
              </TableCell>
              <TableCell className={classes.fieldWidth}>
                {!isEmpty(get(customer, 'email', '')) ? customer.email : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const body = (component: any) => {
  const { classes, common } = component.props;
  const { formDetails } = component.state;
  const { customerData } = common.sfdcAmsFormData;
  return (
    <div>
      <div className='amsContainer'>
        <div className='row mx-auto'>
          <div className='col-sm-12 mt-4'>
            <p className='modal-description'>
              An existing customer with phone number{' '}
              <span>
                <b className='modal-description__emphasis'>{`"${formatPhoneNumberWithBrackets(
                  formDetails.phoneNumber
                )}"`}</b>
              </span>{' '}
              was found.
              <br />
              Would you like to use an existing customer or proceed with creating a new customer?
            </p>
          </div>
        </div>
        <div className='row addInsured rw-mb-res-4 mx-auto'>
          <div className='col-sm-12 textAlignCenter'>
            <Button
              variant='contained'
              className='btnAddInsured'
              onClick={() => {
                component.handleCreateNewCustomer();
              }}
              disabled={getSubmitButtonLoader(common)}
            >
              <AddIcon className='add-icon-border mr-2' />
              <span style={{ verticalAlign: 'middle' }}>{CREATE_NEW_CUSTOMER}</span>
            </Button>
          </div>
        </div>
        {table(
          customerData,
          classes,
          component.handleRadioButtonChange,
          formDetails.customerId,
          component.getHeadCells
        )}
      </div>
      <div className='form-model'>{getNavButtons(component, true, customerData)}</div>
    </div>
  );
};

const template = (component: any) => {
  const { classes, common } = component.props;
  const { formDetails, formDetailsErrors, commonError, isAdhocHomeLob } = component?.state;
  const { openPopup, isPolicyBounded } = !!common?.sfdcAmsFormData && common?.sfdcAmsFormData;
  const nonAdmittedConcent: string = [LOB_PRODUCTS_MAPPING.home, ...ADHOC_NON_ADMITTED_AMS_LOB_LIST].includes(
    formDetails?.businessLine?.toLowerCase()
  )
    ? NON_ADMITTED_AMS_CONSENT.HOME
    : NON_ADMITTED_AMS_CONSENT.FLOOD;
  return (
    <div className='sfdc-ams-details-wrapper'>
      <div className='wrapper mheight applicant-home-form'>
        <div className='container sfdc-form-container'>
          <div className='ams-form'>
            <div id='ams-dialog-title' className={classes.title}>
              {PAGES_TEXT.AMSModalTitle}
              <hr className={classes.lineDivider}></hr>
            </div>
            <div className='amsContainer'>
              {getApplicantAddressFields(component, formDetails, formDetailsErrors, common)}
              <fieldset>
                <div className='row rw-mb-res-4'>
                  <div className={`resp-mb-20 ${isAdhocHomeLob ? 'col-md-4' : 'col-md-6'}`}>
                    <DropDownAutocomplete
                      value={formDetails?.businessLine}
                      onChange={event => {
                        component.dropDownValuesUpdated(event, BUSINESS_LINE);
                      }}
                      recordsList={getDropdownValues(formDetails.lobOption, startCase(DESCRIPTION))}
                      disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                      label={LOB_KEY}
                      isRequired={true}
                      error={formDetailsErrors.businessLineError.length > 0}
                    />
                    <FormHelperText className={classes.fieldError}>
                      {formDetailsErrors.businessLineError}
                    </FormHelperText>
                  </div>
                  {isAdhocHomeLob && (
                    <div className='col-md-4 resp-mb-20'>
                      <FormControl
                        className={`${classes.formControl}`}
                        error={!isEmpty(formDetailsErrors.productError)}
                      >
                        <InputLabel shrink htmlFor={PRODUCT}>
                          {startCase(PRODUCT)}
                          <span className='star-error'>{ASTERISK}</span>
                        </InputLabel>
                        <NativeSelect
                          fullWidth
                          value={formDetails?.product}
                          onChange={event => {
                            component.dropDownValuesUpdated(event.target.value, PRODUCT);
                          }}
                          disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                          inputProps={{
                            name: PRODUCT,
                            id: PRODUCT,
                          }}
                        >
                          <option value=''>{startCase(SELECT)}</option>
                          {amsProductList.map((productName: string) => (
                            <option key={productName} value={productName}>
                              {productName}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                      <FormHelperText className={classes.fieldError}>
                        {formDetailsErrors.productError}
                      </FormHelperText>
                    </div>
                  )}
                  <div className={`resp-mb-20 ${isAdhocHomeLob ? 'col-md-4' : 'col-md-6'}`}>
                    <FormControl
                      fullWidth
                      className='effective-date-control'
                      error={formDetailsErrors.effectiveDateError.length > 0}
                    >
                      <DatePicker
                        label={
                          <>
                            {EFFECTIVE_DATE}
                            <span className='star-error'>{ASTERISK}</span>
                          </>
                        }
                        name={EFFECTIVE_DATE_KEY}
                        onChange={component.handleCalendarDate}
                        dateValue={formDetails.effectiveDate}
                        disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                        error={formDetailsErrors.effectiveDateError.length > 0}
                      />
                    </FormControl>
                    <FormHelperText className={classes.fieldError}>
                      {formDetailsErrors.effectiveDateError}
                    </FormHelperText>
                  </div>
                </div>
                {getCarrierFields(component, formDetails, formDetailsErrors)}

                {getAMSDetailsFields(component, formDetails, formDetailsErrors)}
                {isShowCarrierRiskConsentCheckbox(formDetails) && (
                  <>
                    <Box className='d-flex align-items-start'>
                      <Checkbox
                        className='p-0 mr-1'
                        checked={formDetails?.isCarrierRiskConsented}
                        value={formDetails?.isCarrierRiskConsented}
                        onChange={component.onCheckboxChange}
                        disabled={common?.SfdcAMSDetailsLoading || isPolicyBounded}
                      />
                      <Typography className='carrier-risk-consent-label'>
                        {nonAdmittedConcent}
                      </Typography>
                    </Box>
                    <FormHelperText
                      className={`${classes.fieldError} ${
                        formDetailsErrors?.carrierRiskConsentedError ? 'mb-3' : ''
                      }`}
                    >
                      {formDetailsErrors?.carrierRiskConsentedError}
                    </FormHelperText>
                  </>
                )}
              </fieldset>
              <Typography
                className={`pl-0 text-left line-height-16 ${classes.fieldError} ${classes.commonError}`}
              >
                {commonError}
              </Typography>
            </div>
            {!isPolicyBounded && (
              <div className='action-button'>{getSubmitButtons(component, false, [])}</div>
            )}
            {openPopup && (
              <Dialog
                classes={{
                  root: 'ams-dialog-container',
                }}
                fullWidth={true}
                maxWidth={MD}
                open={!!openPopup ? true : false}
                onClose={() => {
                  component.handleClosePhoneNumberModal();
                }}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
              >
                {' '}
                {body(component)}
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// tslint:disable-next-line: max-file-line-count
export default template;
