/* istanbul ignore file */
import * as dashboardActionType from '../constants';

// Init dashboard
export const dashboardInit = (): AppRedux.Action => ({
  type: dashboardActionType.DASHBOARD_INIT,
});

// Set home insured list
export const setInsuredList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: dashboardActionType.SET_INSURED_LIST,
  payload,
});

// On pagination - page changed
export const tablePageChanged = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: dashboardActionType.TABLE_PAGE_CHANGED,
  payload,
});

// Sets home table loader
export const setInsuredTableLoader = (payload: boolean): AppRedux.Action => ({
  type: dashboardActionType.SET_INSURED_TABLE_LOADER,
  payload,
});

export const autoFillVisitedPages = (payload: any): AppRedux.Action => ({
  type: dashboardActionType.AUTOFILL_VISITED_PAGES,
  payload,
});

// Search dashboard event
export const dashboardSearch = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: dashboardActionType.DASHBOARD_SEARCH,
  payload,
});

// Clears all the details before search result shown
export const flushDashboardDetails = (): AppRedux.Action => ({
  type: dashboardActionType.FLUSH_DASHBOARD_DETAILS,
});

// Search text store for future hits
export const storeSearchText = (payload: string): AppRedux.Action => ({
  type: dashboardActionType.STORE_SEARCH_TEXT,
  payload,
});

export const getFilteredList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: dashboardActionType.GET_FILTERED_LIST,
  payload,
});

export const setAppliedFilters = (payload: any): AppRedux.Action => ({
  type: dashboardActionType.SET_APPLIED_FILTERS,
  payload,
});

export const changeDateRange = (payload: any): AppRedux.Action => ({
  type: dashboardActionType.CHANGE_DATE_RANGE,
  payload,
});

export const setDateRange = (payload: any): AppRedux.Action => ({
  type: dashboardActionType.SET_DATE_RANGE,
  payload,
});

export const clearInsuredListDetails = (): AppRedux.Action => ({
  type: dashboardActionType.CLEAR_INSURED_LIST,
});

export const adhocBindSearch = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: dashboardActionType.ADHOC_BIND_SEARCH,
  payload,
});

export const setInsuredDropDownLoader = (payload: boolean): AppRedux.Action => ({
  type: dashboardActionType.SET_INSURED_DROP_DOWN_LOADER,
  payload,
});

export const adhocOrganizationListLoader = (payload: boolean): AppRedux.Action => ({
  type: dashboardActionType.ADHOC_BIND_ORGANIZATION_LIST_LOADER,
  payload,
});

export const getAdhocOrganizationList = (): AppRedux.Action => ({
  type: dashboardActionType.FETCH_ADHOC_BIND_ORGANIZATION_LIST,
});

export const storeAdhocOrganizationList = (payload: Array<any>) => ({
  type: dashboardActionType.STORE_ADHOC_BIND_ORGANIZATION_LIST,
  payload,
});
