import { invert } from 'lodash';
import { Education } from '../../../../../graphql/generated';

export const gatorToBffEducation: Record<string, Education> = {
  'Associates Degree': Education.AssociatesDegree,
  'High School Diploma': Education.HighSchoolDiploma,
  'Law Degree': Education.LawDegree,
  'Medical Degree': Education.MedicalDegree,
  'No High School Diploma': Education.NoHighSchoolDiploma,
  'Some College - No Degree': Education.SomeCollege,
  'Vocational/Technical Degree': Education.TechnicalDegree,
  Bachelors: Education.Bachelors,
  Masters: Education.Masters,
  Phd: Education.Phd,
};

export const bffEducationToGator = invert(gatorToBffEducation);
