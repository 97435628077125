export enum DateViewType {
  YEAR = 'year',
  DATE = 'date',
}

export enum FormFieldDataType {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
}

export enum FormFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PASSWORD = 'password',
  SELECT = 'select',
  DATE = 'date',
  YEAR = 'year',
  PHONE = 'phone',
  PREMIUM = 'premium', 
}
