import React, { useEffect } from 'react';

import InsuranceDetails from '../../auto/insurance-details';
import history from '../../../history';

const PolicyForm = ({ onNextTab, onPrevTab, currentIndex, goToLastTab }: AppComponents.PolicyFormProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // todo: change name to Policy info form
  return (
    <div className='applicant-form-wrapper'>
      <InsuranceDetails
        onNextTab={onNextTab}
        onPrevTab={onPrevTab}
        currentIndex={currentIndex}
        history={history}
        goToLastTab={goToLastTab}
      />
    </div>
  );
};

export default PolicyForm;
