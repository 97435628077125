import React, { useEffect, useState } from 'react';
import {
  Card,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import get from 'lodash/get';

import { formatPhoneNumberWithBrackets } from '../../../utils';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: '365px',
    overflowY: 'scroll',
    marginBottom:'30px',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },

  progressLoader: {
    color: '#00cf83',
  },
});

const REQUEST_STATES = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
};

const InsuredDetailsDropdown = (props: AppComponents.InsuredDetailsDropdownProps) => {
  const classes = useStyles();
  const [filteredCustomer, setFilteredCustomer] = useState<Array<AppComponents.FilterCostumer>>([]);
  const [state, setState] = useState({
    status: '',
    error: '',
  });
  const { status, error } = state;

  useEffect(() => {
    setState({
      ...state,
      status: REQUEST_STATES.PENDING,
    });

    const filtered = props.existCustomer;
    setFilteredCustomer(filtered);
    if (filtered.length === 0) {
      setState({
        ...state,
        status: REQUEST_STATES.REJECTED,
        error: 'No records to display',
      });
    } else {
      setState({ ...state, status: REQUEST_STATES.RESOLVED });
    }
    // eslint-disable-next-line
  }, [props.existCustomer]);

  if (status === REQUEST_STATES.PENDING || props.loader) {
    return (
      <Card
        className={classes.root}
        style={{ display: 'flex', justifyContent: 'center', padding: '1em' }}
      >
        <CircularProgress size={30} classes={{ root: classes.progressLoader }} />
      </Card>
    );
  } else if (status === REQUEST_STATES.REJECTED) {
    return (
      <Card
        className={classes.root}
        style={{ display: 'flex', justifyContent: 'center', padding: '1em' }}
      >
        {error}
      </Card>
    );
  }
  return (
    <Card className={classes.root}>
      <Table className='insureed-details-table'>
        <TableBody>
          {filteredCustomer &&
            filteredCustomer.map((row) => (
              <TableRow
                key={row.id}
                className='tableRow cursor-pointer'
                onClick={() => {
                  props.insuredDetailsSearchHandler('');
                  props.setInputCustomer(row);
                }}
              >
                <TableCell component='th' scope='row'>
                  {get(row, 'name', '-')}
                </TableCell>
                <TableCell>{formatPhoneNumberWithBrackets(row.phone_number)}</TableCell>
                <TableCell>{get(row, 'email', '-')}</TableCell>
                <TableCell>{get(row, 'state', '-')}</TableCell>
                <TableCell>{get(row, 'lob', '-').toUpperCase()}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default InsuredDetailsDropdown;
