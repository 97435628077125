import React from 'react';
import { bindActionCreators } from 'redux';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { forEach, isEmpty, map, omit, values, isEqual } from 'lodash';
import moment from 'moment';
import { coverageDataMapper, getCoverageDefaultValue, setOptionDetails } from '../utils';
import {
  allowEditingQuote,
  deleteDiffKeys,
  dateFormat,
  getIsHeapAnalyticsEnabled,
  getDateFromToday,
} from '../../../utils';
import DatePicker from '../../components/date-picker';
import {
  DEFAULT_INSURANCE_DETAILS_VALUES,
  DETAILS_DATA,
  INSURANCE_PRODUCTS,
  OTHER_DETAILS_DATA,
  PAGES_TEXT,
  QUOTING_STEPS_PAGES_NAME,
  STATE_COVERAGES,
} from '../../../constants';
import {
  driverSelectedForEdit,
  stepSubmit,
  setHasFormChanged,
  setPolicyFlag,
  updateRequoteProducts,
} from '../../redux/actions';
import { PRIOR_LIABILITY_LIMIT } from '../../../constants/prior-liability-limit';
import { RESIDENCY_STATUS_DATA } from '../../../constants/residency-status';
import SpinnerLoader from '../../components/spinner-loader';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './insurance-details.scss';

const styles = (theme: Theme) => ({
  icon: {
    fontSize: '35px',
  },
  formControl: {
    margin: '10px 0px',
    minWidth: '100%',
  },
  continueSpinner: {
    color: 'white !important',
    marginBottom: '2px',
  },
  errorMsg: {
    color: '#FF0000',
    height: '0px',
  },
  radioErrorMsg: {
    position: 'relative',
    top: '-19px',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    boxShadow: '0px 4px 6px #0000004D',
    border: '1px solid #C4C4C4',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
});

class InsuranceDetails extends React.PureComponent<
  AppComponents.InsuranceDetailsProps & WithStyles,
  AppComponents.InsuranceDetailsState
> {
  initialInsuranceDetailsState: AppComponents.InsuranceDetailsState = {
    entity: 'details',
    insurance_today: '',
    expiration_date: '',
    new_policy_term: '',
    policy_start_date: '',
    reason_no_prior: '',
    reason_for_lapse: '',
    motorist: '',
    injury_limits: '',
    property_damage: '',
    medical_payments: '',
    injury_limits_data: [],
    property_damage_data: [],
    motorist_damage_data: [],
    medical_payments_data: [],
    prior_liability_limits_data: [],
    prior_carrier: '',
    prior_liability_limits: '',
    detailsErrors: {
      insurance_today_error: '',
      expiration_date_error: '',
      new_policy_term_error: '',
      policy_start_date_error: '',
      reason_no_prior_error: '',
      reason_for_lapse_error: '',
      prior_carrier_error: '',
      prior_liability_limits_error: '',
      residency_status_error: '',
      years_with_current_carrier_error: '',
    },
    isLapseDisplay: false,
    residency_status: '',
    years_with_current_carrier: '',
  };
  state: AppComponents.InsuranceDetailsState = { ...this.initialInsuranceDetailsState };

  updateState = (insurance: any, state: any, mountData: any, receiveData: any) => {
    const upperCaseState = state.toUpperCase();
    this.setState(
      {
        ...insurance,
        ...receiveData,
        injury_limits_data: [...coverageDataMapper(STATE_COVERAGES[upperCaseState].BI)],
        property_damage_data: [...coverageDataMapper(STATE_COVERAGES[upperCaseState].PD)],
        motorist_damage_data: [...coverageDataMapper(STATE_COVERAGES[upperCaseState].UM)],
        medical_payments_data: [...coverageDataMapper(STATE_COVERAGES[upperCaseState].MP)],
        prior_liability_limits_data: [
          ...coverageDataMapper(STATE_COVERAGES[upperCaseState].PriorLiabilityLimit),
        ],
      },
      () => {
        const {
          injury_limits_data,
          property_damage_data,
          motorist_damage_data,
          medical_payments_data,
          prior_liability_limits_data,
        } = this.state;
        this.setState({
          ...mountData,
          injury_limits: getCoverageDefaultValue(this.state.injury_limits, injury_limits_data),
          motorist: getCoverageDefaultValue(this.state.motorist, motorist_damage_data),
          property_damage: getCoverageDefaultValue(
            this.state.property_damage,
            property_damage_data
          ),
          medical_payments: getCoverageDefaultValue(
            this.state.medical_payments,
            medical_payments_data
          ),
          prior_liability_limits: getCoverageDefaultValue(
            this.state.prior_liability_limits,
            prior_liability_limits_data
          ),
        });
      }
    );
  };

  getInsuranceDetails = (insurance: any) => ({
    ...(isEmpty(insurance.policy_start_date)
      ? { policy_start_date: getDateFromToday(7) }
      : { policy_start_date: insurance.policy_start_date }),
    ...(isEmpty(insurance.expiration_date)
      ? { expiration_date: getDateFromToday(8) }
      : { expiration_date: insurance.expiration_date }),
    ...(isEmpty(insurance.reason_for_lapse) ? { isLapseDisplay: false } : { isLapseDisplay: true }),
    ...(isEmpty(insurance.prior_carrier) ?
      { prior_carrier: DEFAULT_INSURANCE_DETAILS_VALUES.prior_carrier }
      : { prior_carrier: insurance.prior_carrier }),
    ...(isEmpty(insurance.residency_status) ?
        { residency_status: DEFAULT_INSURANCE_DETAILS_VALUES.residency_status }
        : { residency_status: insurance.residency_status }),
    years_with_current_carrier:
      insurance?.years_with_current_carrier?.toString() ||
      DEFAULT_INSURANCE_DETAILS_VALUES.years_with_current_carrier,
  })

  componentDidMount() {
    window.scrollTo(0, 0);
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.POLICY_INFO);
    }
    const { insurance, address, productType } = this.props;
    const { state } = address;
    const upperCaseState = state.toUpperCase();
    this.updateState(insurance, upperCaseState, this.getInsuranceDetails(insurance), {});
    if (productType && !this.state.residency_status) {
      this.setState({
        residency_status: productType === 'ho3' ? 'home' : 'condo',
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.InsuranceDetailsProps) {
    const { insurance, loading, address, driver } = newProps;
    const { isNeedToGoToRatesTab } = driver;
    const { goToLastTab, currentIndex } = this.props;
    const { state } = address;
    if (!loading && insurance) {
      this.updateState(insurance, state, {}, this.getInsuranceDetails(insurance));
    }
    if (isNeedToGoToRatesTab) {
      goToLastTab(currentIndex);
    }
  }

  handleFieldChange = (event: React.ChangeEvent<any>) => {
    const { target } = event;
    target.name && this.setState({ [target.name]: target.value } as any);
    if (target.name === 'insurance_today' && target.value === 'no') {
      this.setState({
        expiration_date: '',
        reason_for_lapse: '',
        prior_carrier: '',
        residency_status: this.state.residency_status,
        prior_liability_limits: '',
        isLapseDisplay: false,
        policy_start_date: getDateFromToday(7),
        detailsErrors: {
          ...this.state.detailsErrors,
          expiration_date_error: '',
          reason_for_lapse_error: '',
          prior_carrier_error: '',
          prior_liability_limits_error: '',
          residency_status_error: '',
        },
      });
    }
    if (target.name === 'insurance_today' && target.value === 'yes') {
      this.setState({
        reason_no_prior: '',
        residency_status: this.state.residency_status,
        detailsErrors: {
          ...this.state.detailsErrors,
          reason_no_prior_error: '',
          residency_status_error: '',
        },
      });
    }
  };

  handlePolicyStartDate = (date: any) => {
    this.setState({ policy_start_date: date });
  };

  handleCalendarDate = (date: any) => {
    this.setState({ expiration_date: date }, () => {
      if (this.state['expiration_date'].length > 0) {
        let policyStartDate = moment(new Date()).format(dateFormat);
        if (
          moment(this.state['expiration_date'], dateFormat, true).isValid() &&
          moment(this.state['expiration_date']).isBefore(moment(new Date()).format(dateFormat))
        ) {
          this.setState({
            isLapseDisplay: true,
            reason_for_lapse: '',
            policy_start_date: policyStartDate,
            detailsErrors: { ...this.state.detailsErrors, reason_for_lapse_error: '' },
          } as any);
        } else {
          if (
            moment(date, dateFormat, true).isValid() &&
            moment(new Date()).format(dateFormat) !== date
          ) {
            policyStartDate = getDateFromToday(7);
          }
          this.setState({
            isLapseDisplay: false,
            reason_for_lapse: '',
            policy_start_date: policyStartDate,
            detailsErrors: { ...this.state.detailsErrors, reason_for_lapse_error: '' },
          });
        }
      }
    });
  };

  setInsuranceFieldError = (insuranceFieldNames: any, defaultErrors: any) => {
    const errorDetails = { ...defaultErrors };

    forEach(insuranceFieldNames, fieldName => {
      if (!this.state[fieldName] || this.state[fieldName].length === 0) {
        errorDetails[`${fieldName}_error`] = 'Required';
      }
    });
    return errorDetails;
  };

  setCarrierFieldError = (defaultErrors: any) => {
    let errorDetails = { ...defaultErrors };

    if (this.state.insurance_today.toLocaleLowerCase() === 'yes') {
      if (!this.state.expiration_date || this.state.expiration_date.length === 0) {
        errorDetails.expiration_date_error = 'Required';
      }
      if (
        (!this.state.reason_for_lapse || this.state.reason_for_lapse.length === 0) &&
        this.state.isLapseDisplay
      ) {
        errorDetails.reason_for_lapse_error = 'Required';
      }
      if (!this.state.prior_carrier || this.state.prior_carrier.length === 0) {
        errorDetails.prior_carrier_error = 'Required';
      }
      if (!this.state.prior_liability_limits || this.state.prior_liability_limits.length === 0) {
        errorDetails.prior_liability_limits_error = 'Required';
      }
    } else if (this.state.insurance_today.toLocaleLowerCase() === 'no') {
      if (!this.state.reason_no_prior || this.state.reason_no_prior.length === 0) {
        errorDetails.reason_no_prior_error = 'Required';
      }
      if (!this.state.residency_status || this.state.residency_status.length === 0) {
        errorDetails.residency_status_error = 'Required';
      }
    }
    return errorDetails;
  };

  validateInputs = (): boolean => {
    const commonFieldNames = ['insurance_today', 'new_policy_term', 'policy_start_date'];
    let defaultErrors = { ...this.initialInsuranceDetailsState.detailsErrors };
    defaultErrors = { ...this.setInsuranceFieldError(commonFieldNames, defaultErrors) };
    if (
      this.state['policy_start_date'].length > 0 &&
      !moment(this.state['policy_start_date'], dateFormat, true).isValid()
    ) {
      defaultErrors[`policy_start_date_error`] = 'Invalid';
    }
    if (
      this.state['expiration_date'].length > 0 &&
      !moment(this.state['expiration_date'], dateFormat, true).isValid()
    ) {
      defaultErrors[`expiration_date_error`] = 'Invalid';
    }
    if (
      moment(this.state['policy_start_date']).isBefore(
        moment(new Date()).format(dateFormat)
      )
    ) {
      defaultErrors[`policy_start_date_error`] = 'Must be future date';
    }
    if (
      this.state['expiration_date'].length > 0 &&
      moment(new Date(), dateFormat).diff(this.state['policy_start_date'], 'year') < 0
    ) {
      defaultErrors[`policy_start_date_error`] = 'Date cannot be more than 1 year';
    }
    if (this.state['residency_status'].length === 0) {
      defaultErrors[`residency_status_error`] = 'Required';
    }
    if (
      this.state['years_with_current_carrier'].length === 0 &&
      this.state['insurance_today'] === 'yes'
    ) {
      defaultErrors[`years_with_current_carrier_error`] = 'Required';
    }
    defaultErrors = { ...this.setCarrierFieldError(defaultErrors) };

    this.setState({ detailsErrors: { ...defaultErrors } });
    if (!values(defaultErrors).every(isEmpty)) {
      return false;
    }
    return true;
  };

  handleContinue = (event: any) => {
    event.preventDefault();
    const disableAutoEdits = allowEditingQuote();
    if (disableAutoEdits?.auto) {
      const { goToLastTab, currentIndex } = this.props;
      goToLastTab(currentIndex);
    } else if (this.validateInputs()) {
      let { requoteLobs } = this.props;
      if (!isEmpty(requoteLobs) && requoteLobs?.includes(INSURANCE_PRODUCTS[1])) {
        const lobList = requoteLobs?.filter((item: string) => item !== INSURANCE_PRODUCTS[1]);
        updateRequoteProducts(lobList);
      }
      let isHeapAnalyticsEnabled: boolean =
        !!window.heap && getIsHeapAnalyticsEnabled();
      if (isHeapAnalyticsEnabled) {
        window.heap.track(QUOTING_STEPS_PAGES_NAME?.POLICY_INFO_SUBMIT, {
          Term: `${this?.state?.new_policy_term} months` || '',
        });
      }
      let copiedState = deleteDiffKeys(this.state, this.props.insurance);
      let copiedInsurance = { ...this.props.insurance };

      if (this.state.insurance_today === 'no') {
        copiedState = { ...omit(copiedState, ['expiration_date']) };
        copiedInsurance = { ...omit(copiedInsurance, ['expiration_date']) };
      }

      if (
        !isEqual(copiedState, copiedInsurance) ||
        !this.props.quoteList.length
      ) {
        this.props.setHasFormChanged(true);
      }

      this.props.stepSubmit({
        entity: this.state.entity,
        data: { ...omit(this.state, ['entity', 'detailsErrors', 'isLapseDisplay']) },
      });
      this.props.setPolicyFlag(false);
    }
  };

  getInsuranceHistoryFields = (disableAutoEdit: boolean) => {
    const { detailsErrors, insurance_today } = this.state;
    const { insurance_today_error } = detailsErrors;
    const { classes, stepSubmitLoader } = this.props;
    return (
      <React.Fragment>
        <div className='row ResidencyDiv'>
          <div className='col-md-12'>
            <Typography className='VoiletHeading'>Auto Insurance History</Typography>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-6'>
            <FormControl fullWidth className={classes.formControl} disabled={stepSubmitLoader || disableAutoEdit}>
              <InputLabel shrink htmlFor='insurance_today' title="Is The Primary Driver Insured Today?">
                Is The Primary Driver Insured Today?
              </InputLabel>
            </FormControl>
            <RadioGroup
              row
              aria-label='insurance_today'
              name='insurance_today'
              onChange={this.handleFieldChange}
            >
              <FormControlLabel
                className='mr-5'
                value='yes'
                control={
                  <Radio
                    color='default'
                    checked={insurance_today.toLocaleLowerCase() === 'yes'}
                    disabled={stepSubmitLoader || disableAutoEdit}
                  />
                }
                label='Yes'
                labelPlacement='end'
              />
              <FormControlLabel
                value='no'
                control={
                  <Radio
                    color='default'
                    checked={insurance_today.toLocaleLowerCase() === 'no'}
                    disabled={stepSubmitLoader || disableAutoEdit}
                  />
                }
                label='No'
                labelPlacement='end'
              />
            </RadioGroup>
            <FormHelperText className={`${classes.errorMsg} ${classes.radioErrorMsg}`}>
              {insurance_today_error}
            </FormHelperText>
          </div>
        </div>
      </React.Fragment>
    );
  };

  getAdditionalInformationFields = (disableAutoEdit: boolean) => {
    const { detailsErrors, new_policy_term, policy_start_date } = this.state;
    const { new_policy_term_error, policy_start_date_error } = detailsErrors;
    const { classes, stepSubmitLoader } = this.props;
    return (
      <React.Fragment>
        {/* <Divider
          className={
            insurance_today.toLocaleLowerCase() === 'yes' ? 'devider-spacing' : 'devider-spacing-2'
          }
        /> */}
        <div className='row ResidencyDiv mt-4'>
          <div className='col-md-12'>
            <Typography className='VoiletHeading'>Additional Information</Typography>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 col-lg-6'>
            <FormControl fullWidth className={classes.formControl} disabled={stepSubmitLoader || disableAutoEdit}>
              <InputLabel shrink htmlFor='new_policy_term'>
                New Policy Term
              </InputLabel>
            </FormControl>
            <RadioGroup
              row
              aria-label='new_policy_term'
              name='new_policy_term'
              onChange={this.handleFieldChange}
            >
              <FormControlLabel
                className='mr-5'
                value='6'
                control={
                  <Radio
                    color='default'
                    checked={new_policy_term.toLocaleLowerCase() === '6'}
                    disabled={stepSubmitLoader || disableAutoEdit}
                  />
                }
                label='6 months'
                labelPlacement='end'
              />
              <FormControlLabel
                value='12'
                control={
                  <Radio
                    color='default'
                    checked={new_policy_term.toLocaleLowerCase() === '12'}
                    disabled={stepSubmitLoader || disableAutoEdit}
                  />
                }
                label='12 months'
                labelPlacement='end'
              />
            </RadioGroup>
            <FormHelperText className={`${classes.errorMsg} ${classes.radioErrorMsg}`}>
              {new_policy_term_error}
            </FormHelperText>
          </div>
          <div className='col-md-12 col-lg-6 '>
            <FormControl
              fullWidth
              className={`mb-0 ${classes.formControl} policy_start_date ${
                policy_start_date_error.length > 0 ? 'labelError' : ''
              }`}
            >
              <DatePicker
                label={
                  <span>
                    Requested Effective Date<span className='star-error'>*</span>
                  </span>
                }
                name='policy_start_date'
                onChange={this.handlePolicyStartDate}
                dateValue={policy_start_date}
                error={policy_start_date_error.length > 0}
                disabled={stepSubmitLoader || disableAutoEdit}
              />
              <FormHelperText className={classes.errorMsg}>
                {policy_start_date_error}
              </FormHelperText>
            </FormControl>
          </div>
        </div>
      </React.Fragment>
    );
  };

  getGeneralCoveragesFields = (disableAutoEdit: boolean) => {
    const {
      motorist,
      injury_limits,
      property_damage,
      medical_payments,
      injury_limits_data,
      property_damage_data,
      motorist_damage_data,
      medical_payments_data,
    } = this.state;
    const { classes, stepSubmitLoader } = this.props;
    return (
      <React.Fragment>
        <div className='row sub-title-blue mt-4'>
          <div className='col-md-12'>
            <Typography className='VoiletHeading'>General Coverages</Typography>
          </div>
          <div className='col-md-6'>
            <FormControl fullWidth className={classes.formControl} disabled={stepSubmitLoader || disableAutoEdit}>
              <InputLabel shrink htmlFor='injury_limits'>
                Bodily Injury
              </InputLabel>
              <Select
                native
                value={injury_limits}
                inputProps={{
                  name: 'injury_limits',
                  id: 'injury_limits',
                }}
                onChange={this.handleFieldChange}
              >
                {map(injury_limits_data, data => (
                  <option key={data.value} value={data.value}>
                    {data.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='col-md-6'>
            <FormControl fullWidth className={classes.formControl} disabled={stepSubmitLoader || disableAutoEdit}>
              <InputLabel shrink htmlFor='property_damage'>
                Property Damage
              </InputLabel>
              <Select
                native
                value={property_damage}
                inputProps={{
                  id: 'property_damage',
                  name: 'property_damage',
                }}
                onChange={this.handleFieldChange}
              >
                {map(property_damage_data, type => (
                  <option value={type.value} key={`property_damage_${type.value}`}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <FormControl fullWidth className={classes.formControl} disabled={stepSubmitLoader || disableAutoEdit}>
              <InputLabel shrink htmlFor='motorist'>
                Uninsured&#47;Underinsured Motorist
              </InputLabel>
              <Select
                native
                value={motorist}
                inputProps={{
                  id: 'motorist',
                  name: 'motorist',
                }}
                onChange={this.handleFieldChange}
              >
                {map(motorist_damage_data, type => (
                  <option value={type.value} key={`motorist_${type.value}`}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className='col-md-6'>
            <FormControl fullWidth className={classes.formControl} disabled={stepSubmitLoader || disableAutoEdit}>
              <InputLabel shrink htmlFor='medical_payments'>
                Medical Payments
              </InputLabel>
              <Select
                native
                value={medical_payments}
                inputProps={{
                  id: 'medical_payments',
                  name: 'medical_payments',
                }}
                onChange={this.handleFieldChange}
              >
                {map(medical_payments_data, type => (
                  <option value={type.value} key={`medical_payments_${type.value}`}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { stepSubmitLoader, classes } = this.props;
    const {
      detailsErrors,
      insurance_today,
      prior_liability_limits_data,
      prior_carrier,
      prior_liability_limits,
      residency_status,
      years_with_current_carrier,
      expiration_date,
      reason_no_prior,
      reason_for_lapse,
    } = this.state;
    const {
      prior_carrier_error,
      prior_liability_limits_error,
      residency_status_error,
      years_with_current_carrier_error,
      expiration_date_error,
      reason_no_prior_error,
      reason_for_lapse_error,
    } = detailsErrors;
    const disableAutoEdits = allowEditingQuote();
    const disableControls = stepSubmitLoader;
    return (
      <div className='container'>
        <div className='autoline-steps autoline-step-1-wrapper row detialsDiv d-flex justify-content-center'>
          <div className='col-md-10'>
            <form className='vehicle-box-shodow position-relative'>
              <Paper elevation={0}>
                <div className='row m-0'>
                  <div className='col-md-12 applicant-home-title'>
                    <span className='icon-policy title-icon'></span>
                    <Typography variant='h6' className='title-2'>
                      {PAGES_TEXT.insurancePageHeading}
                    </Typography>
                  </div>
                </div>
                <div className='row m-0 d-flex justify-content-center'>
                  <div className='col-lg-9'>
                    {this.getInsuranceHistoryFields(disableAutoEdits?.auto)}
                    {insurance_today.toLocaleLowerCase() === 'yes' && (
                      <>
                        <div className='row'>
                          <div className='col-md-6'>
                            <FormControl
                              fullWidth
                              className={`${classes.formControl} w-90-ie`}
                              disabled={disableControls || disableAutoEdits?.auto}
                            >
                              <InputLabel shrink htmlFor='prior_carrier'>
                                Carrier<span className='star-error'>*</span>
                              </InputLabel>
                              <Select
                                native
                                value={prior_carrier}
                                inputProps={{
                                  name: 'prior_carrier',
                                  id: 'prior_carrier',
                                }}
                                onChange={this.handleFieldChange}
                                error={prior_carrier_error !== ''}
                              >
                                <option value=''>Select</option>
                                {setOptionDetails(PRIOR_LIABILITY_LIMIT)}
                              </Select>
                              <FormHelperText className={classes.errorMsg}>
                                {prior_carrier_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className='col-md-6'>
                            <FormControl
                              fullWidth
                              className={classes.formControl}
                              disabled={disableControls || disableAutoEdits?.auto}
                            >
                              <InputLabel shrink htmlFor='years_with_current_carrier'>
                                Prior Liability Limit<span className='star-error'>*</span>
                              </InputLabel>
                              <Select
                                native
                                value={prior_liability_limits}
                                inputProps={{
                                  name: 'prior_liability_limits',
                                  id: 'prior_liability_limits',
                                }}
                                onChange={this.handleFieldChange}
                                error={prior_liability_limits_error !== ''}
                              >
                                <option value=''>Select</option>
                                {setOptionDetails(prior_liability_limits_data)}
                              </Select>
                              <FormHelperText className={classes.errorMsg}>
                                {prior_liability_limits_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <FormControl
                              fullWidth
                              className={classes.formControl}
                              disabled={disableControls || disableAutoEdits?.auto}
                            >
                              <InputLabel shrink htmlFor='years_with_current_carrier' title="Years With Current Carrier*">
                                Years With Current Carrier<span className='star-error'>*</span>
                              </InputLabel>
                              <Select
                                native
                                value={years_with_current_carrier}
                                inputProps={{
                                  name: 'years_with_current_carrier',
                                  id: 'years_with_current_carrier',
                                }}
                                onChange={this.handleFieldChange}
                                error={years_with_current_carrier_error !== ''}
                              >
                                <option value=''>Select</option>
                                {setOptionDetails(OTHER_DETAILS_DATA.years_with_current_carrier)}
                              </Select>
                              <FormHelperText className={classes.errorMsg}>
                                {years_with_current_carrier_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className='col-md-6'>
                            <FormControl
                              fullWidth
                              className={classes.formControl}
                              disabled={disableControls || disableAutoEdits?.auto}
                            >
                              <InputLabel shrink htmlFor='residency_status'>
                                Residency Status<span className='star-error'>*</span>
                              </InputLabel>
                              <Select
                                native
                                value={residency_status}
                                defaultValue={residency_status}
                                inputProps={{
                                  name: 'residency_status',
                                  id: 'residency_status',
                                }}
                                onChange={this.handleFieldChange}
                                error={residency_status_error !== ''}
                              >
                                <option value=''>Select</option>
                                {setOptionDetails(RESIDENCY_STATUS_DATA)}
                              </Select>
                              <FormHelperText className={classes.errorMsg}>
                                {residency_status_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-md-6'>
                            <FormControl
                              fullWidth
                              className={`mb-0 ${classes.formControl} expiration_date ${
                                expiration_date_error.length > 0 ? 'labelError' : ''
                              }`}
                            >
                              <DatePicker
                                label={
                                  <span>
                                    Expiration Date<span className='star-error'>*</span>
                                  </span>
                                }
                                name='expiration_date'
                                onChange={this.handleCalendarDate}
                                dateValue={expiration_date}
                                error={expiration_date_error.length > 0}
                                disabled={stepSubmitLoader || disableAutoEdits?.auto}
                              />

                              <FormHelperText className={classes.errorMsg}>
                                {expiration_date_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          {this.state.isLapseDisplay && (
                            <div className='col-md-6'>
                              <FormControl
                                fullWidth
                                className={`w-70 ${classes.formControl}`}
                                disabled={stepSubmitLoader || disableAutoEdits?.auto}
                              >
                                <InputLabel shrink htmlFor='reason_no_prior'>
                                  Reason For Lapse<span className='star-error'>*</span>
                                </InputLabel>
                                <Select
                                  native
                                  value={reason_for_lapse}
                                  inputProps={{
                                    name: 'reason_for_lapse',
                                    id: 'reason_for_lapse',
                                  }}
                                  onChange={this.handleFieldChange}
                                  error={reason_for_lapse_error !== ''}
                                >
                                  <option value=''>Select</option>
                                  {setOptionDetails(DETAILS_DATA.reason_no_prior)}
                                </Select>
                                <FormHelperText className={classes.errorMsg}>
                                  {reason_for_lapse_error}
                                </FormHelperText>
                              </FormControl>
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {insurance_today.toLocaleLowerCase() === 'no' && (
                      <>
                        <div className='row'>
                          <div className='col-md-6'>
                            <FormControl
                              fullWidth
                              className={`w-70 ${classes.formControl}`}
                              disabled={stepSubmitLoader || disableAutoEdits?.auto}
                            >
                              <InputLabel shrink htmlFor='reason_no_prior'>
                                Reason No Prior<span className='star-error'>*</span>
                              </InputLabel>
                              <Select
                                native
                                value={reason_no_prior}
                                inputProps={{
                                  name: 'reason_no_prior',
                                  id: 'reason_no_prior',
                                }}
                                onChange={this.handleFieldChange}
                                error={reason_no_prior_error !== ''}
                              >
                                <option value=''>Select</option>
                                {setOptionDetails(DETAILS_DATA.reason_no_prior)}
                              </Select>
                              <FormHelperText className={classes.errorMsg}>
                                {reason_no_prior_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                          <div className='col-md-6'>
                            <FormControl fullWidth className={classes.formControl}>
                              <InputLabel shrink htmlFor='residency_status'>
                                Residency Status<span className='star-error'>*</span>
                              </InputLabel>
                              <Select
                                native
                                value={residency_status}
                                defaultValue={residency_status}
                                inputProps={{
                                  name: 'residency_status',
                                  id: 'residency_status',
                                }}
                                onChange={this.handleFieldChange}
                                error={residency_status_error !== ''}
                              >
                                <option value=''>Select</option>
                                {setOptionDetails(RESIDENCY_STATUS_DATA)}
                              </Select>
                              <FormHelperText className={classes.errorMsg}>
                                {residency_status_error}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        </div>
                      </>
                    )}
                    {this.getAdditionalInformationFields(disableAutoEdits?.auto)}

                    {this.getGeneralCoveragesFields(disableAutoEdits?.auto)}
                  </div>
                </div>
              </Paper>
              <div className='row margin-top m-0'>
                <div className='col-md-12'>
                  <div className='btnwrap'>
                    <Button
                      variant='outlined'
                      className='btnBackLink'
                      onClick={() => {
                        const {
                          driver: { driversList },
                          onPrevTab,
                          currentIndex,
                        } = this.props;
                        if (driversList.length > 1) {
                          this.props.driverSelectedForEdit(
                            driversList[driversList.length - 1].duid
                          );
                        }
                        this.props.setPolicyFlag(false);
                        onPrevTab(currentIndex);
                        // todo: on back click here
                        // history.push('driver');
                      }}
                      disabled={disableControls}
                    >
                      <span className='link-label'>
                        <ArrowBackIosIcon className='mr-2' />
                        Back
                      </span>
                    </Button>
                    <Button
                      variant='contained'
                      className='btnContinue'
                      onClick={this.handleContinue}
                      disabled={disableControls}
                    >
                      Continue
                      {stepSubmitLoader && <SpinnerLoader styleData={classes.continueSpinner} />}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  common,
  driver,
  insurance,
  applicant,
  home,
  quote,
}: AppComponents.InsuranceDetailsStore) => {
  const { address } = applicant;
  const { loading, stepSubmitLoader, requoteLobs } = common;
  const { productType } = home;
  const { quoteErrorList, quoteList } = quote;
  return {
    loading,
    insurance,
    stepSubmitLoader,
    address,
    driver,
    productType,
    quoteErrorList,
    quoteList,
    requoteLobs,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.InsuranceDetailsDispatch => {
  return bindActionCreators(
    { stepSubmit, driverSelectedForEdit, setHasFormChanged, setPolicyFlag, updateRequoteProducts },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles as any)(InsuranceDetails));
