import React from 'react';
import EoiSettingsTable from './eoi-settings-table';

const EoiSettings = () => {
  return (
    <div>
      <EoiSettingsTable />
    </div>
  );
};

export default EoiSettings;
