/* istanbul ignore file */
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import isString from 'lodash/isString';
import startCase from 'lodash/startCase';
import { numberFormatter } from '../../../utils';
import { NA_VALUE, PRODUCT_TYPE_MAPPING } from '../../../constants';
import findKey from 'lodash/findKey';

// Constructs the view quote url based on current location
export const getViewQuoteURL = (herdId: any) => `${window.location.protocol}//${window.location.host}/quotes?herd_id=${herdId}`;

export const stringNumberRegex = /^[a-zA-Z0-9]*$/;

// Convert apostrophe in different unicode character
export const updateApostrophe = (name: string) => {
  let userInput = name;
  // Specially for iphone 'Smart Punctuation'
  if (!isEmpty(userInput)) {
    userInput = userInput.replace(/\u2019/g, "'");
  }
  return userInput;
};

// Hippo lead data adjustments mapping with below deductibles
export const hippoLeadDataDeductiblesMap: any = {
  deductible: 'Other',
  wind_deductible: 'Wind',
  hurricane_deductible: 'Hurricane',
  theft_deductible: 'Theft',
};

// Deductibles mapping
export const deductiblesMap = {
  Hurricane: { details: { label: 'Hurricane', value: '' } },
  Other: { details: { label: 'All Peril', value: '' } },
  Wind: { details: { label: 'Wind', value: '' } },
  Theft: { details: { label: 'Theft', value: '' } },
  WindHail: { details: { label: 'Wind & Hail', value: '' } },
  Earthquake: { details: { label: 'Earthquake', value: '' } },
  default: { details: { label: '', value: '' } },
};

// Show email and phone number fields based on configuration
export const showFields = (fieldName: string, config: any) => {
  if (isEmpty(config)) {
    return true;
  }
  if (!Object.prototype.hasOwnProperty.call(config, fieldName)) {
    return true;
  }
  return config[fieldName].toString() === 'true';
};

export const formatCurrency = (cvalue: any, headerValue: any): string => {
  if (!cvalue) {
    return NA_VALUE;
  }
  if (isString(cvalue) && cvalue.toLowerCase() !== 'included') {
    // Only for formatted price value
    if (cvalue.split(',').length > 0) {
      cvalue = cvalue.split(',').join('');
    }
  } else if (isString(cvalue) && cvalue.toLowerCase() === 'included') {
    cvalue = headerValue;
  }
  return !isNaN(Number(cvalue))
    ? `$${numberFormatter(String(cvalue).replace(/,/g, ''))}`
    : startCase(cvalue);
};

export const getProductTypeByKey = (isCondo: string): any => PRODUCT_TYPE_MAPPING[isCondo.toLowerCase()];

export const getProductTypeByValue = (product: string): any =>
  findKey(PRODUCT_TYPE_MAPPING, (type: any) => type.indexOf(product) !== -1);

export const getCoverageItem = (quote: any) => {
  const { coverages, header_coverages } = quote;
  return [
    {
      label: 'Dwelling',
      value: coverages?.coverage_a || quote?.cov_a || header_coverages?.cov_a || 0,
    },
    {
      label: 'Other Structures',
      value: coverages?.coverage_b || quote?.cov_b || header_coverages?.cov_b || 0,
    },
    {
      label: 'Personal Property',
      value: coverages?.coverage_c || quote?.cov_c || header_coverages?.cov_c || 0,
    },
    {
      label: 'Loss Of Use',
      value: coverages?.coverage_d || quote?.cov_d || header_coverages?.cov_d || 0,
    },
    {
      label: 'Personal Liability',
      value: coverages?.coverage_e || quote?.cov_e || header_coverages?.cov_e || 0,
    },
    {
      label: 'Medical Payment to Others',
      value: coverages?.coverage_f || quote?.cov_f || header_coverages?.cov_f || 0,
    },
  ];
};
