import React from 'react';
import { startCase } from 'lodash';
import { FormHelperText, Input, InputLabel, Grid, NativeSelect, Switch } from '@material-ui/core';
import { ASTERISK, LENDER_SETTING, SELECT, STATEDATA } from '../../../constants';
import { getNestedTernaryData } from '../../../utils';
import './lender-setting.scss';

const template = (props: any) => {
  const { component, disabled, addEditOrgLoader } = props;
  const { lenderCity, lenderEnabled, lenderName, lenderState, lenderStreet, lenderZip } =
    component.state.lenderSetting;
  const { lenderNameError, lenderStreetError, lenderCityError, lenderStateError, lenderZipError } =
    component.state.lenderSettingErrors;
  return (
    <div className='lender-setting-wrap mob-ptb-0 p-0'>
      <div className='row m-0'>
        <div className='col-lg-12 mb-3 p-0'>
          <Grid
            container
            spacing={2}
            className={`row d-flex align-items-center ${!!lenderEnabled ? 'mb-10' : ''}`}
          >
            <Grid item xs={6} className='form-item'>
              <InputLabel className='form-item__label'>
                {LENDER_SETTING.STATIC_SETTING_LABEL}
              </InputLabel>
            </Grid>
            <Grid item xs={6} className='m-padding-left-0'>
              <span className='p-0 switch-margin-left'>
                <Switch
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  value={!!lenderEnabled}
                  checked={!!lenderEnabled}
                  onChange={event => component.onCheckBoxChange(event)}
                  name={LENDER_SETTING.STATIC_SETTING_KEY}
                  disabled={disabled}
                />
              </span>
            </Grid>
          </Grid>

          {!!lenderEnabled ? (
            <>
              <Grid container spacing={2} className='row d-flex align-items-center mb-10'>
                <Grid item xs={6} className='form-item'>
                  <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_NAME_LABEL}>
                    <span className='padding-1'>
                      {LENDER_SETTING.LENDER_NAME_LABEL}
                      <span className='small-star-error'>
                        {getNestedTernaryData(!!lenderEnabled, ASTERISK, '')}
                      </span>
                    </span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} className='m-padding-left-0'>
                  <Input
                    className='native-select w-100'
                    value={lenderName}
                    inputProps={{
                      name: LENDER_SETTING.LENDER_NAME_KEY,
                      id: LENDER_SETTING.LENDER_NAME_KEY,
                      maxLength: 50,
                    }}
                    onChange={event => component.onChangeLenderSettings(event)}
                    disabled={disabled || addEditOrgLoader}
                  />
                  <FormHelperText className='text-red'>{lenderNameError}</FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='d-flex align-items-center mb-10'>
                <Grid item xs={6} className='form-item'>
                  <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_STREET_LABEL}>
                    <span className='padding-1'>
                      {LENDER_SETTING.LENDER_STREET_LABEL}
                      <span className='small-star-error'>
                        {getNestedTernaryData(!!lenderEnabled, ASTERISK, '')}
                      </span>
                    </span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} className='m-padding-left-0'>
                  <Input
                    className='native-select w-100'
                    value={lenderStreet}
                    inputProps={{
                      name: LENDER_SETTING.LENDER_STREET_KEY,
                      id: LENDER_SETTING.LENDER_STREET_KEY,
                      maxLength: 50,
                    }}
                    onChange={event => component.onChangeLenderSettings(event)}
                    disabled={disabled || addEditOrgLoader}
                  />
                  <FormHelperText className='text-red'>{lenderStreetError}</FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='d-flex align-items-center mb-10'>
                <Grid item xs={6} className='form-item'>
                  <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_CITY_LABEL}>
                    <span className='padding-1'>
                      {LENDER_SETTING.LENDER_CITY_LABEL}
                      <span className='small-star-error'>
                        {getNestedTernaryData(!!lenderEnabled, ASTERISK, '')}
                      </span>
                    </span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} className='m-padding-left-0'>
                  <Input
                    className='native-select w-100'
                    value={lenderCity}
                    inputProps={{
                      name: LENDER_SETTING.LENDER_CITY_KEY,
                      id: LENDER_SETTING.LENDER_CITY_KEY,
                      maxLength: 50,
                    }}
                    onChange={event => component.onChangeLenderSettings(event)}
                    disabled={disabled || addEditOrgLoader}
                  />
                  <FormHelperText className='text-red'>{lenderCityError}</FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='d-flex align-items-center mb-10'>
                <Grid item xs={6} className='form-item'>
                  <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_STATE_LABEL}>
                    <span className='padding-1'>
                      {LENDER_SETTING.LENDER_STATE_LABEL}
                      <span className='small-star-error'>
                        {getNestedTernaryData(!!lenderEnabled, ASTERISK, '')}
                      </span>
                    </span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} className='m-padding-left-0'>
                  <NativeSelect
                    className='native-select w-100'
                    value={lenderState}
                    name={LENDER_SETTING.LENDER_STATE_KEY}
                    onChange={event => component.onChangeLenderSettings(event)}
                    disabled={disabled || addEditOrgLoader}
                  >
                    <option value=''>{startCase(SELECT)}</option>
                    {STATEDATA.states.map((stateName: any) => (
                      <option key={stateName.value} value={stateName.value}>
                        {stateName.value}
                      </option>
                    ))}
                  </NativeSelect>
                  <FormHelperText className='text-red'>{lenderStateError}</FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='d-flex align-items-center mb-10'>
                <Grid item xs={6} className='form-item'>
                  <InputLabel className='d-flex' htmlFor={LENDER_SETTING.LENDER_ZIP_LABEL}>
                    <span className='padding-1'>
                      {LENDER_SETTING.LENDER_ZIP_LABEL}
                      <span className='small-star-error'>
                        {getNestedTernaryData(!!lenderEnabled, ASTERISK, '')}
                      </span>
                    </span>
                  </InputLabel>
                </Grid>
                <Grid item xs={6} className='m-padding-left-0'>
                  <Input
                    className='native-select w-100'
                    value={lenderZip}
                    inputProps={{
                      name: LENDER_SETTING.LENDER_ZIP_KEY,
                      id: LENDER_SETTING.LENDER_ZIP_KEY,
                      maxLength: 50,
                    }}
                    onChange={event => component.onChangeLenderSettings(event)}
                    disabled={disabled || addEditOrgLoader}
                  />
                  <FormHelperText className='text-red'>{lenderZipError}</FormHelperText>
                </Grid>
              </Grid>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default template;
