import React, { forwardRef, memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import config from '../../../config/config';
import AutoIcon from '../../../assets/images/auto.svg';
import CarWhiteIcon from '../../../assets/images/white-car.svg';
import DetailsIcon from '../../../assets/images/details-icon.svg';
import DetailsIconFc from '../../../assets/images/details-icon-fc.svg';
import DetailsWhiteIcon from '../../../assets/images/details-white.svg';
import DriverIcon from '../../../assets/images/driver.svg';
import DriverIconFc from '../../../assets/images/driver-fc.svg';
import DriverWhiteIcon from '../../../assets/images/driver-white.svg';
import QuotesIcon from '../../../assets/images/quotes.svg';
import QuotesIconFc from '../../../assets/images/quotes-fc.svg';
import QuotesWhiteIcon from '../../../assets/images/quotes-white.svg';
import { FIRST_CONNECT_THEME } from '../../../constants';
import './step-sidebar.scss';

const driverIconButton =
  config.hippo.template_name === FIRST_CONNECT_THEME ? DriverIconFc : DriverIcon;
const DetailsButtonIcon =
  config.hippo.template_name === FIRST_CONNECT_THEME ? DetailsIconFc : DetailsIcon;
const QuoteButtonIcon =
  config.hippo.template_name === FIRST_CONNECT_THEME ? QuotesIconFc : QuotesIcon;

const stepBoxes = [
  {
    label: 'Vehicles',
    type: 'vehicles',
    disabledIcon: forwardRef((props: any, ref: any) => (
      <img alt='vehicle' src={AutoIcon} {...props} ref={ref} />
    )),
    activeIcon: forwardRef((props: any, ref: any) => (
      <img alt='vehicle' src={CarWhiteIcon} {...props} ref={ref} />
    )),
    routeLink: 'vehicle',
  },
  {
    label: 'Drivers',
    type: 'drivers',
    disabledIcon: forwardRef((props: any, ref: any) => (
      <img alt='driver' src={driverIconButton} {...props} ref={ref} />
    )),
    activeIcon: forwardRef((props: any, ref: any) => (
      <img alt='driver' src={DriverWhiteIcon} {...props} ref={ref} />
    )),
    routeLink: 'driver',
  },
  {
    label: 'Details',
    type: 'details',
    disabledIcon: forwardRef((props: any, ref: any) => (
      <img alt='details' src={DetailsButtonIcon} {...props} ref={ref} />
    )),
    activeIcon: forwardRef((props: any, ref: any) => (
      <img alt='details' src={DetailsWhiteIcon} {...props} ref={ref} />
    )),
    routeLink: 'details',
  },
  {
    label: 'Quotes',
    type: 'quotes',
    disabledIcon: forwardRef((props: any, ref: any) => (
      <img alt='quote' src={QuoteButtonIcon} {...props} ref={ref} />
    )),
    activeIcon: forwardRef((props: any, ref: any) => (
      <img alt='quote' src={QuotesWhiteIcon} {...props} ref={ref} />
    )),
    routeLink: 'quotes',
  },
];

const StepSidebar = (props: AppComponents.StepSidebarProps) => {
  const [completedSteps, setCompletedSteps] = useState(null);
  useEffect(() => {
    setCompletedSteps(props.completed_steps);
  }, [props.completed_steps]);

  return (
    <div className='stepsideBarDiv mt-3'>
      <div className=' divided'>
        {stepBoxes.map((step: any, key: number) => (
          <React.Fragment key={`stepsideBarDiv-divided-${step.label}`}>
            <div
              className={`${
                completedSteps && (completedSteps as any)[step.type] ? 'active' : 'disabled'
              } ${step.type === 'quotes' && 'quotes-step'} vehicle-div`}
              key={`stepsideBarDiv-${step.label}`}
            >
              <div className='step'>
                {completedSteps && (completedSteps as any)[step.type] ? (
                  <step.activeIcon className='step-img step-icon' />
                ) : (
                  <step.disabledIcon className='step-img step-icon' />
                )}
              </div>
              <p className='label-name complete'>{step.label}</p>
            </div>

            {key !== stepBoxes.length - 1 && (
              <span
                key={`${key}-${step.label}`}
                className={
                  completedSteps && (completedSteps as any)[step.type]
                    ? 'divider-green'
                    : 'divider-grey'
                }
              ></span>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ common }: AppComponents.StepSidebarStore) => {
  const { completed_steps } = common;
  return { completed_steps };
};

export default connect(mapStateToProps, null)(memo(StepSidebar));
