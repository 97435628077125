import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import React from 'react';
import { ASTERISK, NONE } from '../../../../constants';
import PhoneMask from '../../../../pages/components/phone-mask';
import { FormFieldProps } from '../form-fields.types';

const PhoneNumberField: React.FC<FormFieldProps> = ({
  fieldDetails: { name, label, isDisabled, isRequired, maxLength },
  value,
  error,
  disabled,
  onChange,
  onInputBlur,
}) => {
  const inputProps = {
    name: name,
    id: name,
    autoFocus: false,
    spellCheck: false,
    maxLength: maxLength || 50,
    autoComplete: NONE.toLowerCase(),
    disabled: disabled || !!isDisabled,
  };

  return (
    <FormControl fullWidth key={name} error={!!error}>
      <InputLabel shrink htmlFor={name}>
        {label}
        {isRequired && <span className='error-color'>{ASTERISK}</span>}
      </InputLabel>
      <Input
        autoComplete='off'
        value={value || ''}
        onChange={onChange}
        onBlur={() => onInputBlur && onInputBlur(name, value)}
        inputComponent={PhoneMask}
        inputProps={inputProps}
        disabled={disabled}
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default PhoneNumberField;
