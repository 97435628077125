import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import history from '../../history';
import {
  partnerSignIn,
  setIsAuthProviderOneLogin,
  setRequestId,
  flushAppDetails,
} from '../redux/actions';
import { inIframe, useBeforeFirstRender } from '../../utils';

const PartnerLaunch = (props: AppComponents.PartnerProps) => {
  useBeforeFirstRender(() => {
    const { partnerSignIn, setRequestId, setIsAuthProviderOneLogin, flushAppDetails } = props;
    const { hashToken, username, requestId, provider, code, quoteUrl, isProviderAms, role, quoteType } = queryString.parse(
      history.location.search
    );
    if (!inIframe()) {
      history.push('/error');
    }
    flushAppDetails();
    setIsAuthProviderOneLogin(false);
    if (!(hashToken && username && requestId)) {
      history.push('/error');
    }
    if (sessionStorage.selectedQuoteList) {
      sessionStorage.removeItem('selectedQuoteList');
    }
    setRequestId(requestId);
    partnerSignIn({ hashToken, username, provider, requestId, code, quoteUrl, isProviderAms, role, quoteType });
  });

  return null;
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.PartnerDispatch =>
  bindActionCreators(
    {
      partnerSignIn,
      setRequestId,
      setIsAuthProviderOneLogin,
      flushAppDetails,
    },
    dispatchProp
  );

export default connect(null, mapDispatchToProps)(PartnerLaunch);
