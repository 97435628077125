import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import SpinnerLoader from '../../components/spinner-loader';
import { makeStyles } from '@material-ui/core/styles';

import './modal.scss';

const useStyles = makeStyles(theme => ({
  lineDivider: {
    marginBottom: 0,
  },
  dialogContent: {
    padding: '0px 24px',
  },
  closeButton: {
    position: 'absolute',
    width: 20,
    height: 20,
    right: 10,
    top: 10,
    zIndex: 1,
  },

  submitSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
}));

const getNavButtons = (classes: any, onClose: any, onSave: any, loader: any) => {
  return (
    <div className='btn-wraper plr-24'>
      <Button
        variant='outlined'
        className='navigation-back-btn'
        disabled={loader}
        onClick={onClose}
      >
        Cancel
      </Button>
      <Button
        variant='outlined'
        className='navigation-next-btn submit-btn'
        disabled={loader}
        onClick={onSave}
      >
        Save
        {loader && <SpinnerLoader styleData={classes.submitSpinner} />}
      </Button>
    </div>
  );
};

const modalBody = (
  classes: any,
  title: string,
  onSave: any,
  onClose: any,
  navButtons: boolean,
  children: any,
  loader: boolean
) => {
  return (
    <div>
      <DialogTitle id='dialog-title'>
        {title}
        <hr className={classes.lineDivider}></hr>
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize='small' />
        </IconButton>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>{children}</DialogContent>
      <DialogActions>{navButtons && getNavButtons(classes, onClose, onSave, loader)}</DialogActions>
    </div>
  );
};

export const Modal = (props: any) => {
  const { isOpen, onClose, onSave, maxWidth, title, navButtons = false, children, loader } = props;
  const classes = useStyles();

  if (!props.isOpen) {
    return null;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={!!maxWidth ? maxWidth : 'xs'}
      open={isOpen}
      classes={{
        root: 'dialog-container',
      }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      {modalBody(classes, title, onSave, onClose, navButtons, children, loader)}
    </Dialog>
  );
};

export default Modal;
