import { isEmpty } from 'lodash';
import { FormEvent, useState } from 'react';
import { resetObjectProperties, EMPTY, ERROR_MESSAGE, INPUT_TYPES } from './utils';

export type FormDataType = Record<string, string>;

export type FormateCheck = {
  regex: RegExp;
  key: string;
  errorMessage?: string;
};

export const useFormHandler = (
  initFormValues: FormDataType,
  validationOptions: {
    shouldErrorHandleOnInputChange?: boolean;
    required?: string[];
    formateCheck?: FormateCheck[];
    customValidator?: (formValues: FormDataType, formErrors: FormDataType) => FormDataType;
    valueAccepted?: FormateCheck[];
  } = { required: [], formateCheck: [], shouldErrorHandleOnInputChange: false, valueAccepted: [] }
) => {
  const { shouldErrorHandleOnInputChange, required, formateCheck, customValidator, valueAccepted } =
    validationOptions;
  const [formValues, setFormValues] = useState<FormDataType>({
    ...initFormValues,
  });
  const [formErrors, setFormErrors] = useState<FormDataType>(resetObjectProperties(initFormValues));

  const handleOnChange = (event: React.ChangeEvent<any>) => {
    if (event && event?.target) {
      let {
        target: { name, value, type, checked },
      } = event;
      /**TODO: Update the value according to input field's type */
      if (type === INPUT_TYPES.CHECKBOX) value = checked;
      if (name) {
        if (value && valueAccepted && valueAccepted.map(data => data.key).includes(name)) {
          const [data] = valueAccepted.filter(data => data.key === name);
          if (!isEmpty(data) && !data.regex.test(value)) {
            if (shouldErrorHandleOnInputChange && !!data.errorMessage)
              setFormErrors(preval => ({
                ...preval,
                [name]: data.errorMessage,
              }));
            return;
          }
        }
        setFormValues(preval => ({
          ...preval,
          [name]: value?.toString() || EMPTY,
        }));
        if (shouldErrorHandleOnInputChange)
          setFormErrors(preval => ({
            ...preval,
            [name]: EMPTY,
          }));
      }
    }
  };

  const customValidatorHandler = (formValues: FormDataType, preFormErrors: FormDataType) => {
    if (customValidator) {
      const tempCustemError = customValidator(formValues, resetObjectProperties(initFormValues));
      Object.keys(preFormErrors).forEach(key => {
        if (tempCustemError[key]) preFormErrors[key] = tempCustemError[key];
      });
    }
    return preFormErrors;
  };

  const validator = () => {
    const tempFormValues = { ...formValues };
    const tempFormErrors = { ...formErrors };
    Object.keys(tempFormValues).forEach(key => {
      if (required && required.includes(key) && isEmpty(tempFormValues[key])) {
        tempFormErrors[key] = ERROR_MESSAGE.REQUIRED;
      } else if (
        formateCheck &&
        !isEmpty(tempFormValues[key]) &&
        formateCheck.map(data => data.key).includes(key)
      ) {
        const [data] = formateCheck.filter(data => data.key === key);
        if (!isEmpty(data) && !data.regex.test(tempFormValues[key]))
          tempFormErrors[key] = data.errorMessage || ERROR_MESSAGE.INVALID;
      } else {
        tempFormErrors[key] = '';
      }
    });
    setFormErrors({
      ...customValidatorHandler(tempFormValues, tempFormErrors),
    });
    return Object.values(tempFormErrors).every(x => !x);
  };

  const handleOnSubmit = (
    onSubmit: (data: FormDataType, isValied: boolean) => void,
    event?: FormEvent
  ) => {
    if (onSubmit) onSubmit(formValues, validator());
    if (event) event.preventDefault();
  };

  const resetForm = (resetKeys?: string[]) => {
    setFormErrors({ ...initFormValues });
    if (!resetKeys || isEmpty(resetKeys)) {
      setFormValues({ ...initFormValues });
    } else {
      setFormValues(preval => {
        const tempValue: FormDataType = {};
        Object.keys(preval).forEach(key => {
          tempValue[key] = resetKeys.includes(key) ? EMPTY : preval[key];
        });
        return tempValue;
      });
    }
  };

  const setFormCustomError = (key: string, errorMessage: string) => {
    setFormErrors(preval => ({
      ...preval,
      [key]: errorMessage,
    }));
  };

  const setFormCustomValue = (key: string, newValue: string) => {
    setFormValues(preval => ({
      ...preval,
      [key]: newValue,
    }));
    if (shouldErrorHandleOnInputChange)
      setFormErrors(preval => ({
        ...preval,
        [key]: EMPTY,
      }));
  };

  const setFormData = (formData: FormDataType) => {
    const tempFormData: FormDataType = { ...formValues };
    Object.keys(formData).forEach(key => {
      tempFormData[key] = formData[key] ? formData[key] : EMPTY;
    });
    setFormValues(tempFormData);
  };

  return {
    resetForm,
    formValues,
    formErrors,
    handleOnChange,
    handleOnSubmit,
    setFormValues: setFormData,
    setFormErrors: setFormErrors,
    setFormCustomValue,
    setFormCustomError,
    validateForm: validator,
  };
};

export default useFormHandler;
