/* istanbul ignore file */
import * as driverActionType from '../constants';
import { DriverExtraState } from '../reducers/driver';

// Initialize driver details
export const driverInit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.DRIVER_INIT,
  payload,
});

// Update first driver name(if not given/updated) to applicant name
export const updateDriverName = (payload: Partial<DriverExtraState>): AppRedux.Action => ({
  type: driverActionType.UPDATE_DRIVER_NAME,
  payload,
});

// Save current driver details before edit other driver
export const saveDriverBeforeEdit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.SAVE_DRIVER_BEFORE_EDIT,
  payload,
});

// On successful driver added in driver list
export const addDriver = (payload: any): AppRedux.Action => ({
  type: driverActionType.ADD_DRIVER,
  payload,
});

// On successful driver added in driver list
export const setDriverLoader = (payload: boolean): AppRedux.Action => ({
  type: driverActionType.DRIVER_LOADER,
  payload,
});

// Store new driver in driver list
export const updateDriverList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.UPDATE_DRIVER_LIST,
  payload,
});

// On delete of particular driver details from server
export const deleteDriverDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.DELETE_DRIVER_DETAILS,
  payload,
});

// On delete of particular driver details update driver list in store
export const deleteDriverDetailsSuccess = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.DELETE_DRIVER_DETAILS_SUCCESS,
  payload,
});

// Edit selected driver from list
export const driverSelectedForEdit = (payload: string): AppRedux.Action => ({
  type: driverActionType.EDIT_SELECTED_DRIVER,
  payload,
});

export const updateAccidentDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.UPDATE_ACCIDENT_DETAILS,
  payload,
});

// Clear Driver Accident List
export const clearDriverAccidentList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.CLEAR_DRIVER_ACCIDENT_LIST,
  payload,
});

// Clear Driver Violation List
export const clearDriverViolationList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.CLEAR_DRIVER_VIOLATION_LIST,
  payload,
});

// Clear Driver Losses List
export const clearDriverLossList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.CLEAR_DRIVER_LOSS_LIST,
  payload,
});

// Assign spouse details
export const assignSpouse = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.ASSIGN_SPOUSE,
  payload,
});

// Add driver from other details
export const addDriverFromOtherDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.ADD_DRIVER_FROM_OTHERDETAILS,
  payload,
});

// Update other details on delete of any list item
export const updateDriverOtherDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.UPDATE_DRIVER_OTHER_DETAILS,
  payload,
});

// On driver page continue, get next driver for edit
export const driverContinue = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.DRIVER_CONTINUE,
  payload,
});

// On driver page back, get previous driver for edit
export const driverBack = (): AppRedux.Action => ({
  type: driverActionType.DRIVER_BACK,
});

// Checks primary driver spouse exists or not
export const checkSpouseExists = (): AppRedux.Action => ({
  type: driverActionType.SPOUSE_EXISTS,
});

// Update driver data when come from view quote
export const updateDriverData = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.UPDATE_DRIVER_DATA,
  payload,
});

export const setIncidentsFlag = (payload: any): AppRedux.Action => ({
  type: driverActionType.SET_INCIDENTS_FLAG,
  payload,
});

export const setPolicyFlag = (payload: any): AppRedux.Action => ({
  type: driverActionType.SET_POLICY_FLAG,
  payload,
});

export const setFetchAutoQuotes = (payload: any): AppRedux.Action => ({
  type: driverActionType.SET_FETCH_AUTO_QUOTES,
  payload,
});

export const setGoToRatesTabFromPolicy = (payload: any): AppRedux.Action => ({
  type: driverActionType.SET_GO_TO_RATES_TAB_FROM_POLICY,
  payload,
});

export const updateSpecificDriver = (payload: any): AppRedux.Action => ({
  type: driverActionType.UPDATE_SPECIFIC_DRIVER,
  payload,
});

export const clearDriverInfo = (): AppRedux.Action => ({
  type: driverActionType.CLEAR_DRIVER_STATE,
});

export const toggleDriverIncludedStatus = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.TOGGLE_DRIVER_INCLUDED_STATUS,
  payload,
});

export const toggleDriverIncludedSuccess = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.TOGGLE_DRIVER_INCLUDED_STATUS_SUCCESS,
  payload,
});

export const sortDrivers = (): AppRedux.Action => ({
  type: driverActionType.SORT_DRIVER,
});

export const getStoredDriversList = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: driverActionType.GET_STORED_DRIVERS_LIST,
  payload,
});
