import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  Switch,
} from '@material-ui/core';
import Modal from '../../components/modal';
import { setOrgUpdatedResponseData, updateOrganizationData } from '../../redux/actions';
import AMSButtonImg from '../../../assets/images/ams-button.png';
import {
 ALLOW_ADHOC_BIND,
 ALLOW_AMS_FOR_GATOR,
 ALLOW_AMS_FOR_SALESFORCE,
 AMS,
 AMS_ENABLED,
 ASTERISK,
 INVALID,
 MD,
 REQUIRED
} from '../../../constants';
import { getNestedTernaryData } from '../../../utils';

const INIT_AMS_FORM_DATA = {
  ams_enabled: false,
  adhoc_bind_enabled: false,
  ams_for_gator_enabled: false,
  ams_for_salesforce_enabled: false,
  ams_credentials: {
    loginId: '',
    password: '',
    v2Host: '',
    v3Host: '',
    executive: '',
    groupList: '',
    agencyNumber: '',
    divisionList: '',
    departmentList: '',
    branch: '',
    executiveList: '',
    brokerList: '',
    representativeList: '',
    employeeList: '',
  },
};
const INIT_AMS_FORM_ERROR = {
  loginIdError: '',
  passwordError: '',
  v2HostError: '',
  v3HostError: '',
  executiveError: '',
  groupListError: '',
  agencyNumberError: '',
  divisionListError: '',
  departmentListError: '',
  branchError: '',
  errorResponse: '',
};
export class AmsComponent extends Component<
  AppComponents.AmsComponentDataProps,
  AppComponents.AmsComponentState
> {
  state: AppComponents.AmsComponentState = {
    amsFormData: { ...INIT_AMS_FORM_DATA },
    errors: { ...INIT_AMS_FORM_ERROR },
  };

  componentDidMount = () => {
    this.setAmsCredientials();
  };

  componentWillReceiveProps = (newProps: any) => {
    const { organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.props.closeModal();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
    if (!!organizations?.orgResponseData?.message) {
      this.setState({
        errors: {
          ...this.state.errors,
          errorResponse: organizations?.orgResponseData?.message,
        },
      });
    }
  };

  setAmsCredientials = () => {
    const { editOrganizationData } = this.props;
    const { amsFormData } = this.state;
    if (!!editOrganizationData?.ams_credentials) {
      this.setState((prevState: AppComponents.AmsComponentState) => ({
        amsFormData: {
          ams_enabled: !!editOrganizationData?.ams_enabled || !!amsFormData?.ams_enabled || false,
          adhoc_bind_enabled: !!editOrganizationData?.adhoc_bind_enabled,
          ams_for_gator_enabled: !!editOrganizationData?.ams_for_gator_enabled,
          ams_for_salesforce_enabled: !!editOrganizationData?.ams_for_salesforce_enabled,
          ams_credentials: {
            ...prevState.amsFormData.ams_credentials,
            loginId: !!editOrganizationData?.ams_credentials?.loginId
              ? editOrganizationData?.ams_credentials?.loginId
              : '',
            password: !!editOrganizationData?.ams_credentials?.password
              ? editOrganizationData?.ams_credentials?.password
              : '',
            v2Host: !!editOrganizationData?.ams_credentials?.v2Host
              ? editOrganizationData?.ams_credentials?.v2Host
              : '',
            v3Host: !!editOrganizationData?.ams_credentials?.v3Host
              ? editOrganizationData.ams_credentials.v3Host
              : '',
            executive: !!editOrganizationData?.ams_credentials?.executive
              ? editOrganizationData?.ams_credentials?.executive
              : '',
            groupList: !!editOrganizationData?.ams_credentials?.groupList
              ? editOrganizationData?.ams_credentials?.groupList
              : '',
            agencyNumber: !!editOrganizationData?.ams_credentials?.agencyNumber
              ? editOrganizationData?.ams_credentials?.agencyNumber
              : '',
            divisionList: !!editOrganizationData?.ams_credentials?.divisionList
              ? editOrganizationData?.ams_credentials?.divisionList
              : '',
            departmentList: !!editOrganizationData?.ams_credentials?.departmentList
              ? editOrganizationData?.ams_credentials?.departmentList
              : '',
            branch: !!editOrganizationData?.ams_credentials?.branch
              ? editOrganizationData?.ams_credentials?.branch
              : '',
            executiveList: !!editOrganizationData?.ams_credentials?.executiveList
              ? editOrganizationData?.ams_credentials?.executiveList
              : '',
            brokerList: !!editOrganizationData?.ams_credentials?.brokerList
              ? editOrganizationData?.ams_credentials?.brokerList
              : '',
            representativeList: !!editOrganizationData?.ams_credentials?.representativeList
              ? editOrganizationData?.ams_credentials?.representativeList
              : '',
            employeeList: !!editOrganizationData?.ams_credentials?.employeeList
              ? editOrganizationData?.ams_credentials?.employeeList
              : '',
          },
        },
      }));
    }
  };

  onChangeAMSEnabledCheckbox = (event: any) => {
    const { name, checked } = event.target;
    let amsFormDetails: any = {
      amsFormData: {
        ...this.state.amsFormData,
        [name]: checked,
      },
      errors: {
        ...this.state.errors,
      },
    };
    if (name === AMS_ENABLED.KEY && !checked) {
      amsFormDetails.amsFormData.adhoc_bind_enabled = false;
      amsFormDetails.amsFormData.ams_for_salesforce_enabled = false;
      amsFormDetails.amsFormData.ams_for_gator_enabled = false;
      amsFormDetails.errors = INIT_AMS_FORM_ERROR;
    }
    this.setState({
      ...amsFormDetails,
    });
  };

  onChangeAMSInput = (event?: any) => {
    const { name, value } = event?.target;
    this.setState({
      amsFormData: {
        ...this.state.amsFormData,
        ams_credentials: {
          ...this.state.amsFormData.ams_credentials,
          [name]: value,
        },
      },
    });
  };

  validate = () => {
    let errors = { ...INIT_AMS_FORM_ERROR };
    const { amsFormData } = this.state;
    if(amsFormData.ams_enabled) {
      errors.loginIdError = isEmpty(amsFormData?.ams_credentials?.loginId?.trim()) ? REQUIRED : '';
      errors.passwordError = isEmpty(amsFormData.ams_credentials.password)
        ? REQUIRED
        : getNestedTernaryData(isEmpty(amsFormData?.ams_credentials?.password?.trim()), INVALID, '');
      errors.agencyNumberError = isEmpty(amsFormData?.ams_credentials?.agencyNumber?.trim())
        ? REQUIRED
        : '';
    }

    this.setState({
      ...this.state,
      errors: {
        ...errors,
        errorResponse: '',
      },
    });
    if (
      !!errors?.loginIdError ||
      !!errors?.passwordError ||
      !!errors?.v2HostError ||
      !!errors?.v3HostError ||
      !!errors?.executiveError ||
      !!errors?.groupListError ||
      !!errors?.agencyNumberError ||
      !!errors?.divisionListError ||
      !!errors?.departmentListError ||
      !!errors?.branchError
    ) {
      return false;
    } else {
      return true;
    }
  };

  onSaveAmsForData = () => {
    const { editOrganizationData } = this.props;
    const { amsFormData } = this.state;
    this.props.setOrgUpdatedResponseData({ message: '' });
    const isValid = this.validate();
    if (!!isValid) {
      let payload = {
        id: editOrganizationData?.id,
        code: editOrganizationData?.code,
        name: editOrganizationData?.name,
        auth_token: editOrganizationData?.auth_token,
        ams_enabled: amsFormData?.ams_enabled,
        adhoc_bind_enabled: !!amsFormData?.adhoc_bind_enabled,
        ams_for_gator_enabled: !!amsFormData?.ams_for_gator_enabled,
        ams_for_salesforce_enabled: !!amsFormData?.ams_for_salesforce_enabled,
        ams_credentials: {
          loginId: amsFormData?.ams_credentials.loginId?.trim(),
          password: amsFormData?.ams_credentials.password?.trim(),
          executive: !isEmpty(amsFormData?.ams_credentials?.executive?.trim())
            ? amsFormData?.ams_credentials?.executive?.trim()
            : null,
          groupList: !isEmpty(amsFormData?.ams_credentials?.groupList?.trim())
            ? amsFormData?.ams_credentials?.groupList?.trim()
            : null,
          agencyNumber: amsFormData?.ams_credentials?.agencyNumber?.trim(),
          divisionList: !isEmpty(amsFormData?.ams_credentials?.divisionList?.trim())
            ? amsFormData?.ams_credentials?.divisionList?.trim()
            : null,
          departmentList: !isEmpty(amsFormData?.ams_credentials?.departmentList?.trim())
            ? amsFormData?.ams_credentials?.departmentList?.trim()
            : null,
          branch: !isEmpty(amsFormData?.ams_credentials?.branch?.trim())
            ? amsFormData?.ams_credentials?.branch?.trim()
            : null,
          v2Host: !isEmpty(amsFormData?.ams_credentials?.v2Host?.trim())
            ? amsFormData.ams_credentials.v2Host?.trim()
            : null,
          v3Host: !isEmpty(amsFormData?.ams_credentials?.v3Host?.trim())
            ? amsFormData.ams_credentials.v3Host?.trim()
            : null,
          executiveList: !isEmpty(amsFormData?.ams_credentials?.executiveList?.trim())
            ? amsFormData?.ams_credentials?.executiveList?.trim()
            : null,
          brokerList: !isEmpty(amsFormData?.ams_credentials?.brokerList?.trim())
            ? amsFormData?.ams_credentials?.brokerList?.trim()
            : null,
          representativeList: !isEmpty(amsFormData?.ams_credentials?.representativeList?.trim())
            ? amsFormData?.ams_credentials?.representativeList?.trim()
            : null,
          employeeList: !isEmpty(amsFormData?.ams_credentials?.employeeList?.trim())
            ? amsFormData?.ams_credentials?.employeeList?.trim()
            : null,
        },
      };
      this.props.updateOrganizationData({ ...payload });
    }
  };

  render() {
    const { addEditOrgLoader, organizations } = this.props;
    const { amsFormData, errors } = this.state;
    const isDisabledFormField: boolean = !!addEditOrgLoader || !amsFormData?.ams_enabled || false;
    return (
      <div>
        <Modal
          isOpen={true}
          onClose={this.props.closeModal}
          title={AMS}
          navButtons={true}
          maxWidth={MD}
          onSave={this.onSaveAmsForData}
          loader={!!organizations?.addEditOrgLoader ? true : false}
        >
          <form className='form'>
            <div className='col-md-12 mb-4 form-item text pt-3'>
              <div className='col-md-2 p-0 mob-wdt-50'>
                <img alt='ams button' className='ams-button' src={AMSButtonImg} />
              </div>
              <div className='col-md-2 p-0 sm-right-align mob-wdt-50'>
                <Switch
                  color='primary'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  name={AMS_ENABLED.KEY}
                  value={!!amsFormData?.ams_enabled ? true : false}
                  checked={!!amsFormData?.ams_enabled ? true : false}
                  onChange={event => this.onChangeAMSEnabledCheckbox(event)}
                  disabled={addEditOrgLoader}
                />
              </div>
            </div>
            <div className='m-0 ams-tab-form ams-modal-form'>
              <div className='row'>
                <div className='col-lg-3 mob-style pl-lg-0'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='loginId'
                      error={!!errors?.loginIdError ? true : false}
                    >
                      Username{amsFormData.ams_enabled && <span className='star-error'>{ASTERISK}</span>}
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'loginId',
                        id: 'loginId',
                        spellCheck: false,
                        maxLength: 50,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData.ams_credentials.loginId
                          ? amsFormData.ams_credentials.loginId
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                  </FormControl>
                  <FormHelperText className='text-red'>{errors?.loginIdError}</FormHelperText>
                </div>
                <div className='col-lg-3 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label '
                      htmlFor='password'
                      error={!!errors?.passwordError ? true : false}
                    >
                      Password{amsFormData.ams_enabled && <span className='star-error'>{ASTERISK}</span>}
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'password',
                        id: 'password',
                        spellCheck: false,
                        maxLength: 20,
                      }}
                      type='password'
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.password
                          ? amsFormData?.ams_credentials?.password
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>{errors?.passwordError}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-lg-3 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='agencyNumber'
                      error={!!errors?.agencyNumberError ? true : false}
                    >
                      Agency Number{amsFormData.ams_enabled && <span className='star-error'>{ASTERISK}</span>}
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'agencyNumber',
                        id: 'agencyNumber',
                        spellCheck: false,
                        maxLength: 50,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.agencyNumber
                          ? amsFormData.ams_credentials.agencyNumber
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>
                      {errors?.agencyNumberError}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className='col-lg-3 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='divisionList'
                      error={!!errors?.divisionListError ? true : false}
                    >
                      Division
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'divisionList',
                        id: 'divisionList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.divisionList
                          ? amsFormData.ams_credentials.divisionList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>
                      {errors?.divisionListError}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-3 mob-style pl-lg-0'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='executive'
                      error={!!errors?.divisionListError ? true : false}
                    >
                      Executive
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'executive',
                        id: 'executive',
                        spellCheck: false,
                        maxLength: 50,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.executive
                          ? amsFormData.ams_credentials.executive
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>
                      {errors?.divisionListError}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className='col-lg-3 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='groupList'
                      error={!!errors?.groupListError ? true : false}
                    >
                      Group
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'groupList',
                        id: 'groupList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.groupList
                          ? amsFormData?.ams_credentials?.groupList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>{errors?.groupListError}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-lg-3 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label '
                      htmlFor='branch'
                      error={!!errors?.branchError ? true : false}
                    >
                      Branch
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'branch',
                        id: 'branch',
                        spellCheck: false,
                      }}
                      type='branch'
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.branch
                          ? amsFormData.ams_credentials.branch
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>{errors?.branchError}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-lg-3 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='departmentList'
                      error={!!errors?.departmentListError ? true : false}
                    >
                      Department
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'departmentList',
                        id: 'departmentList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.departmentList
                          ? amsFormData?.ams_credentials?.departmentList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>
                      {errors?.departmentListError}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mob-style pl-lg-0'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='v2Host'
                      error={!!errors?.v2HostError ? true : false}
                    >
                      Policy Header End Point
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'v2Host',
                        id: 'v2Host',
                        spellCheck: false,
                        maxLength: 200,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.v2Host
                          ? amsFormData?.ams_credentials?.v2Host
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>{errors?.v2HostError}</FormHelperText>
                  </FormControl>
                </div>
                <div className='col-lg-6 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel
                      shrink
                      className='form-item__label'
                      htmlFor='v3Host'
                      error={!!errors?.v3HostError ? true : false}
                    >
                      Get All Details End Point
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'v3Host',
                        id: 'v3Host',
                        maxLength: 200,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.v3Host
                          ? amsFormData?.ams_credentials?.v3Host
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                    <FormHelperText className='text-red'>{errors?.v3HostError}</FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mob-style pl-lg-0'>
                  <FormControl fullWidth>
                    <InputLabel shrink className='form-item__label' htmlFor='executiveList'>
                      Executive List
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'executiveList',
                        id: 'executiveList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.executiveList
                          ? amsFormData?.ams_credentials?.executiveList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                  </FormControl>
                </div>
                <div className='col-lg-6 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel shrink className='form-item__label' htmlFor='employeeList'>
                      Employee List
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'employeeList',
                        id: 'employeeList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.employeeList
                          ? amsFormData?.ams_credentials?.employeeList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                  </FormControl>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6 mob-style pl-lg-0'>
                  <FormControl fullWidth>
                    <InputLabel shrink className='form-item__label' htmlFor='brokerList'>
                      Broker List
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'brokerList',
                        id: 'brokerList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.brokerList
                          ? amsFormData?.ams_credentials?.brokerList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                  </FormControl>
                </div>
                <div className='col-lg-6 mob-style'>
                  <FormControl fullWidth>
                    <InputLabel shrink className='form-item__label' htmlFor='representativeList'>
                      Representative List
                    </InputLabel>
                    <Input
                      inputProps={{
                        name: 'representativeList',
                        id: 'representativeList',
                        spellCheck: false,
                      }}
                      className='form-item__input ams-border-bottom'
                      value={
                        !!amsFormData?.ams_credentials?.representativeList
                          ? amsFormData?.ams_credentials?.representativeList
                          : ''
                      }
                      onChange={this.onChangeAMSInput}
                      disabled={isDisabledFormField}
                      required
                    />
                  </FormControl>
                </div>
              </div>
              <div className='row mt-4'>
                <div className='col-lg-4 mob-style pl-lg-0'>
                  <FormControlLabel
                    className='ml-0 radio-mob-style '
                    value='start'
                    control={
                      <Switch
                        className='form-item'
                        color='primary'
                        name={ALLOW_AMS_FOR_GATOR.KEY}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        value={!!amsFormData?.ams_for_gator_enabled}
                        checked={!!amsFormData?.ams_for_gator_enabled}
                        onChange={event => this.onChangeAMSEnabledCheckbox(event)}
                        disabled={isDisabledFormField}
                      />
                    }
                    label={`${ALLOW_AMS_FOR_GATOR.LABEL}:`}
                    labelPlacement='start'
                  />
                </div>
                <div className='col-lg-4 mob-style pl-lg-0 d-lg-flex justify-content-lg-center'>
                  <FormControlLabel
                    className='ml-0 radio-mob-style '
                    value='start'
                    control={
                      <Switch
                        className='form-item'
                        color='primary'
                        name={ALLOW_AMS_FOR_SALESFORCE.KEY}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        value={!!amsFormData?.ams_for_salesforce_enabled}
                        checked={!!amsFormData?.ams_for_salesforce_enabled}
                        onChange={event => this.onChangeAMSEnabledCheckbox(event)}
                        disabled={isDisabledFormField}
                      />
                    }
                    label={`${ALLOW_AMS_FOR_SALESFORCE.LABEL}:`}
                    labelPlacement='start'
                  />
                </div>
                <div className='col-lg-4 mob-style pl-lg-0 d-lg-flex justify-content-lg-end'>
                  <FormControlLabel
                    className='ml-0 radio-mob-style '
                    value='start'
                    control={
                      <Switch
                        className='form-item'
                        color='primary'
                        name={ALLOW_ADHOC_BIND.KEY}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        value={!!amsFormData?.adhoc_bind_enabled}
                        checked={!!amsFormData?.adhoc_bind_enabled}
                        onChange={event => this.onChangeAMSEnabledCheckbox(event)}
                        disabled={isDisabledFormField}
                      />
                    }
                    label={`${ALLOW_ADHOC_BIND.LABEL}:`}
                    labelPlacement='start'
                  />
                </div>
              </div>
            </div>
            <FormHelperText className='text-red'>{errors?.errorResponse}</FormHelperText>
          </form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.AMSComponentStore) => {
  const { addEditOrgLoader, editOrganizationData } = organizations;
  return { addEditOrgLoader, editOrganizationData, organizations };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AmsComponentDispatch => {
  return bindActionCreators({ setOrgUpdatedResponseData, updateOrganizationData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AmsComponent);
