import { isEmpty } from 'lodash';
import React, { FC, ReactNode } from 'react';
import rawConfig, { AppConfig } from '../../../config/config';
import {
  CYBER_SECURITY,
  HOME_WARRANTY,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
} from '../../../constants';
import { HomeQuote } from '../../../graphql/generated';
import store from '../../../pages/redux/store';
import {
  getLobDetails,
  getSortedQuoteList,
  isNoQuotesAvailableForLob,
  sortAdmittedAndNonAdmittedCarrier,
} from '../../../utils';
import { LineOfBusiness } from '../../enums';
import { compareBusinessType, getLoaderImage } from '../../utils';
import { CommonQuote, PremiumDetails } from '../../utils/types';
import { DrawNoQuoteAvailable, IncompleteQuoteDrawer } from '../rate-details/draw-quote-detail';
import { HomeRates } from './home-rates';
import QuoteList from './quotes-list';
import {
  DrawBusinessOptionRatesProps,
  SelectedCarrierResponse,
  UpdateCommonQuoteList,
} from './types';
import { setHomeQuoteDetails } from '../../redux/slices';

const config = rawConfig as AppConfig;
const Loader: FC<{ businessOption: string }> = ({ businessOption }) => {
  return (
    <div className='w-100 mt-4 d-flex justify-content-center'>
      <img src={getLoaderImage(businessOption)} alt='loader' />
    </div>
  );
};

const drawRatesAsLoaded = (
  quoteList: CommonQuote[],
  loading: boolean,
  businessOption: string,
  updateSelectedQuoteList: (
    selectedQuote: HomeQuote | CommonQuote,
    checked: boolean,
    type: string
  ) => void
) => {
  return quoteList.map((carrier, index: number) => (
    <QuoteList
      type={businessOption}
      loading={loading}
      quoteList={quoteList}
      carrierSelected={index}
      carrier={carrier}
      key={`_${carrier.carrier_id}${index}`}
      updateSelectedQuoteList={updateSelectedQuoteList}
      selectedQuoteList={
        sessionStorage.selectedQuoteList ? JSON.parse(sessionStorage.selectedQuoteList) : []
      }
    />
  ));
};

const incompleteQuoteText = (type: string, isVeriskLoading?: boolean) => {
  if (type === INSURANCE_BUSINESS_TYPES.AUTO && isVeriskLoading) {
    return <Loader businessOption={type} />;
  } else {
    return (
      <>
        <h1>Incomplete Quote</h1>
        <p className='clickhere-text'>Please Click Here To Finish</p>
      </>
    );
  }
};

export const drawBusinessOptionRates = ({
  businessOption,
  redirectToCertainTab,
  updateSelectedQuoteList,
  showApplicationEdit,
  isVeriskLoading,
}: DrawBusinessOptionRatesProps): ReactNode => {
  const {
    quote,
    home: { productType },
    floodDetails,
  } = store.getState();
  const { quoteList: autoQuoteList } = quote;
  const {
    annexPrefill,
    quoteList: floodQuoteList,
    detailsLoader,
    herdId: floodHerdId,
  } = floodDetails;
  if (businessOption.toLowerCase() === LineOfBusiness.HOME) {
    return (
      <HomeRates
        redirectToCertainTab={redirectToCertainTab}
        updateSelectedQuoteList={updateSelectedQuoteList}
      />
    );
  }
  let isFloodNoQuotesAvailable =
    !detailsLoader && isEmpty(annexPrefill) && !!productType && !!floodHerdId;
  let { loading, quoteList, currentQuoteTab, displayWithErrors } = compareBusinessType(
    businessOption,
    showApplicationEdit
  );
  quoteList = getSortedQuoteList(quoteList, currentQuoteTab);
  quoteList = sortAdmittedAndNonAdmittedCarrier(quoteList);
  if (
    [LineOfBusiness.AUTO, LineOfBusiness.FLOOD].includes(
      currentQuoteTab.toLowerCase() as LineOfBusiness
    ) &&
    quoteList.length
  ) {
    const quotesDraw = !displayWithErrors
      ? !!autoQuoteList.length || !!floodQuoteList.length
      : true;
    if (quotesDraw) {
      const list: ReactNode[] = [];
      list.unshift(
        drawRatesAsLoaded(
          quoteList,
          loading,
          businessOption,
          updateSelectedQuoteList as UpdateCommonQuoteList
        )
      );
      if (loading) {
        list.push(<Loader businessOption={businessOption} />);
      }
      return list;
    }
  }
  if (loading) {
    return <Loader businessOption={businessOption} />;
  }
  let isNoQuotesAvailable: boolean =
    currentQuoteTab === INSURANCE_BUSINESS_TYPES.FLOOD
      ? isFloodNoQuotesAvailable
      : isNoQuotesAvailableForLob(displayWithErrors, currentQuoteTab);
  if (isNoQuotesAvailable && !getLobDetails(currentQuoteTab, 'upcomingCarrierList')) {
    return <DrawNoQuoteAvailable type={currentQuoteTab} />;
  } else if (!quoteList.length) {
    return (
      <IncompleteQuoteDrawer
        currentQuoteTab={currentQuoteTab}
        incompleteQuoteText={incompleteQuoteText}
        redirectToCertainTab={redirectToCertainTab}
        productType={productType}
        isVeriskLoading={isVeriskLoading}
      />
    );
  } else {
    return drawRatesAsLoaded(
      quoteList,
      loading,
      businessOption,
      updateSelectedQuoteList as UpdateCommonQuoteList
    );
  }
};

const getSelectedCarrierDetails = (
  type: string,
  premiumDetails: PremiumDetails[],
  carrierId: string | number,
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let premiumDetail = null;
  let carrierPrice = Number.MAX_SAFE_INTEGER;
  let selectedCarrierResponse: SelectedCarrierResponse = {};
  if (boundedPolicyDetails[type]?.premium) {
    carrierPrice = Number(boundedPolicyDetails[type]?.premium);
    premiumDetail = premiumDetails?.find(el => Number(el?.premium) === carrierPrice);
  }
  if (!premiumDetail && !!selectedCarriers[type]?.premium) {
    carrierPrice = Number(selectedCarriers[type]?.premium);
    premiumDetail = premiumDetails?.find(el => Number(el?.premium) === carrierPrice);
  }
  if (
    !!premiumDetail &&
    carrierPrice === Number(premiumDetail?.premium) &&
    (carrierId?.toString() === boundedPolicyDetails[type]?.carrierId?.toString() ||
      carrierId?.toString() === selectedCarriers[type]?.carrierId?.toString())
  ) {
    selectedCarrierResponse.carrierPrice = premiumDetail?.premium;
    if (type === INSURANCE_PRODUCTS[6]) {
      selectedCarrierResponse.term = premiumDetail?.term;
      selectedCarrierResponse.selectedPremiumType = premiumDetail?.coverage ?? '';
    } else if (type === CYBER_SECURITY) {
      selectedCarrierResponse.selectedPremiumType = premiumDetail?.coverageType ?? '';
    } else if (type === INSURANCE_PRODUCTS[4]) {
      selectedCarrierResponse.selectedPremiumType = premiumDetail?.plan ?? '';
    } else {
      selectedCarrierResponse.selectedPremiumType = premiumDetail?.selectedPremiumType ?? '';
      selectedCarrierResponse.coverageType = premiumDetail?.coverageType ?? '';
    }
  }
  return selectedCarrierResponse;
};

export const getFloodDefault = (
  floodQuoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
): CommonQuote => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  const result = floodQuoteList.find(
    carrier =>
      carrier.carrier_name === config.prioritizeFloodCarrier ||
      carrier.name === config.prioritizeFloodCarrier
  );
  floodQuoteList.forEach(carrier => {
    if (!isEmpty(boundedPolicyDetails?.flood) || !isEmpty(selectedCarriers?.flood)) {
      const carrierSelectedData = getSelectedCarrierDetails(
        INSURANCE_PRODUCTS[2],
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice } = carrierSelectedData;
        defaultQuote = { ...carrier, price: carrierPrice };
      }
    } else if (result) {
      result?.premiumDetails?.forEach(detail => {
        if (detail?.premium && detail?.premium < price) {
          price = detail?.premium;
          defaultQuote = { ...carrier, price };
        }
      });
    } else {
      carrier?.premiumDetails?.forEach(detail => {
        if (detail?.premium && detail?.premium < price) {
          price = detail?.premium;
          defaultQuote = { ...carrier, price };
        }
      });
    }
  });

  return { type: INSURANCE_BUSINESS_TYPES.FLOOD, quote: defaultQuote };
};

export const getQuakeDefault = (
  quoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  quoteList?.forEach(carrier => {
    if (!isEmpty(boundedPolicyDetails?.quake) || !isEmpty(selectedCarriers?.quake)) {
      const carrierSelectedData = getSelectedCarrierDetails(
        INSURANCE_PRODUCTS[3],
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice, coverageType } = carrierSelectedData;
        defaultQuote = {
          ...carrier,
          price: carrierPrice,
          selectedPremiumType: coverageType,
          coverageType,
        };
      }
    } else {
      carrier?.premiumDetails?.forEach(detail => {
        if (detail.premium && detail.premium < price) {
          price = detail.premium;
          defaultQuote = { ...carrier, price };
        }
      });
    }
  });
  return { type: INSURANCE_BUSINESS_TYPES.QUAKE, quote: defaultQuote };
};

export const getAutoDefault = (
  autoQuoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  autoQuoteList.forEach(carrier => {
    if (!isEmpty(boundedPolicyDetails?.auto) || !isEmpty(selectedCarriers?.auto)) {
      const autoSelectedCarrier = boundedPolicyDetails?.auto || selectedCarriers?.auto;
      if (carrier.carrier_id?.toString() === autoSelectedCarrier?.carrierId?.toString()) {
        price = carrier.price ?? 0;
        defaultQuote = carrier;
      }
    } else if (carrier.price && carrier.price < price) {
      price = carrier.price;
      defaultQuote = carrier;
    }
  });

  return { type: INSURANCE_BUSINESS_TYPES.AUTO, quote: defaultQuote };
};

export const getPetDefault = (
  quoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  let isNoQuoteSelected: boolean = false;
  quoteList?.forEach(carrier => {
    if (!isEmpty(boundedPolicyDetails?.pet) || !isEmpty(selectedCarriers?.pet)) {
      const carrierSelectedData = getSelectedCarrierDetails(
        INSURANCE_PRODUCTS[4],
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice, selectedPremiumType } = carrierSelectedData;
        defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType };
      } else {
        isNoQuoteSelected = true;
      }
    }
    if (
      isNoQuoteSelected ||
      (isEmpty(boundedPolicyDetails?.pet) && isEmpty(selectedCarriers?.pet))
    ) {
      carrier?.premiumDetails?.forEach(detail => {
        if (detail.premium && detail?.premium < price) {
          price = detail?.premium;
          let selectedPremiumType = detail?.plan;
          defaultQuote = { ...carrier, price, selectedPremiumType };
        }
      });
    }
  });
  return { type: INSURANCE_BUSINESS_TYPES.PET, quote: defaultQuote };
};

export const getHomeWarrantyDefault = (
  quoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  let isNoQuoteSelected: boolean = false;
  quoteList?.forEach(carrier => {
    if (
      !isEmpty(boundedPolicyDetails[HOME_WARRANTY]) ||
      !isEmpty(selectedCarriers[HOME_WARRANTY])
    ) {
      const carrierSelectedData = getSelectedCarrierDetails(
        HOME_WARRANTY,
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice } = carrierSelectedData;
        defaultQuote = { ...carrier, price: carrierPrice };
      } else {
        isNoQuoteSelected = true;
      }
    }
    if (
      isNoQuoteSelected ||
      (isEmpty(boundedPolicyDetails[HOME_WARRANTY]) && isEmpty(selectedCarriers[HOME_WARRANTY]))
    ) {
      carrier?.premiumDetails?.forEach(detail => {
        if (detail.premium && detail.premium < price) {
          price = detail.premium;
          defaultQuote = { ...carrier, price };
        }
      });
    }
  });
  return { type: INSURANCE_BUSINESS_TYPES.HOMEWARRANTY, quote: defaultQuote };
};

export const getCyberSecurityDefault = (
  quoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  let isNoQuoteSelected: boolean = false;
  quoteList?.forEach(carrier => {
    if (
      !isEmpty(boundedPolicyDetails[CYBER_SECURITY]) ||
      !isEmpty(selectedCarriers[CYBER_SECURITY])
    ) {
      const carrierSelectedData = getSelectedCarrierDetails(
        CYBER_SECURITY,
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice, selectedPremiumType } = carrierSelectedData;
        defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType };
      } else {
        isNoQuoteSelected = true;
      }
    }
    if (
      isNoQuoteSelected ||
      (isEmpty(boundedPolicyDetails[CYBER_SECURITY]) && isEmpty(selectedCarriers[CYBER_SECURITY]))
    ) {
      carrier?.premiumDetails?.forEach(detail => {
        if (detail.premium && detail.premium < price) {
          price = detail.premium;
          let selectedPremiumType = detail.coverageType;
          defaultQuote = { ...carrier, price, selectedPremiumType };
        }
      });
    }
  });
  return { type: INSURANCE_BUSINESS_TYPES.CYBERSECURITY, quote: defaultQuote };
};

const getSelectedCarrier = (carrier: CommonQuote) => {
  let defaultQuote = {};
  let price = Number.MAX_SAFE_INTEGER;
  carrier?.premiumDetails?.forEach(detail => {
    if (detail.premium && detail.premium < price) {
      price = detail.premium;
      const term = detail.term;
      const selectedPremiumType = detail.coverage;
      defaultQuote = { ...carrier, price, selectedPremiumType, term };
    }
  });
  return defaultQuote;
};

export const getUmbrellaDefault = (
  quoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
): CommonQuote => {
  let defaultQuote = {};
  let isCarrierSelected: boolean = false;
  quoteList?.forEach(carrier => {
    if (!isEmpty(boundedPolicyDetails?.umbrella) || !isEmpty(selectedCarriers?.umbrella)) {
      const carrierSelectedData = getSelectedCarrierDetails(
        INSURANCE_PRODUCTS[8],
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice, selectedPremiumType, term } = carrierSelectedData;
        defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType, term };
        return;
      }
    }
    if (
      isEmpty(boundedPolicyDetails?.umbrella) &&
      isEmpty(selectedCarriers?.umbrella) &&
      !isCarrierSelected
    ) {
      isCarrierSelected = true;
      defaultQuote = getSelectedCarrier(carrier);
    }
  });
  if (isEmpty(defaultQuote) && quoteList?.length) {
    defaultQuote = getSelectedCarrier(quoteList[0]);
  }
  return { type: INSURANCE_BUSINESS_TYPES.UMBRELLA, quote: defaultQuote };
};

export const getLifeDefault = (
  quoteList: CommonQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
): CommonQuote => {
  let defaultQuote = {};
  quoteList?.forEach(carrier => {
    if (!isEmpty(boundedPolicyDetails?.life) || !isEmpty(selectedCarriers?.life)) {
      const carrierSelectedData = getSelectedCarrierDetails(
        INSURANCE_PRODUCTS[6],
        carrier.premiumDetails ?? [],
        carrier.carrier_id ?? '',
        boundedPolicyDetails,
        selectedCarriers
      );
      if (!isEmpty(carrierSelectedData)) {
        const { carrierPrice, selectedPremiumType, term } = carrierSelectedData;
        defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType, term };
        return;
      }
    }
    if (isEmpty(boundedPolicyDetails?.life) && isEmpty(selectedCarriers?.life)) {
      defaultQuote = getSelectedCarrier(carrier);
    }
  });
  if (isEmpty(defaultQuote) && quoteList?.length) {
    defaultQuote = getSelectedCarrier(quoteList[0]);
  }
  return { type: INSURANCE_BUSINESS_TYPES.LIFE, quote: defaultQuote };
};

export const getDefaultHomeQuote = (
  homeQuoteList: HomeQuote[],
  boundedPolicyDetails: Record<string, CommonQuote>,
  selectedCarriers: Record<string, CommonQuote>
) => {
  let price = Number.MAX_SAFE_INTEGER;
  let defaultQuote = {};
  homeQuoteList.forEach((carrier: HomeQuote) => {
    if (!isEmpty(boundedPolicyDetails?.home) || !isEmpty(selectedCarriers?.home)) {
      const homeSelectedCarrier = boundedPolicyDetails?.home || selectedCarriers?.home;
      if (carrier.carrierId === homeSelectedCarrier?.carrierId?.toString()) {
        price = carrier?.data?.price ?? 0;
        defaultQuote = carrier;
      }
    } else if (carrier?.data?.price && carrier?.data?.price < price) {
      price = carrier?.data?.price;
      defaultQuote = carrier;
    }
  });

  return { type: INSURANCE_BUSINESS_TYPES.HOME, quote: defaultQuote };
};

export const updateQuoteListOnPolicyBound = (carrierId: string = '') => {
  const {
    homeQuoteDetails: { quotes },
  } = store.getState();
  let quoteList: any[] = quotes ?? [];
  carrierId = carrierId ?? '';
  const quoteIndex = quoteList.findIndex(item => item.carrierId === carrierId);
  if (quoteIndex > -1 && quoteList[quoteIndex].data) {
    const existingData = quoteList[quoteIndex].data;
    const updatedQuote = {
      ...quoteList[quoteIndex],
      data: {
        ...existingData,
        policyBounded: true,
      },
    };
    quoteList = [
      ...quoteList.slice(0, quoteIndex),
      updatedQuote,
      ...quoteList.slice(quoteIndex + 1),
    ];
  }
  store.dispatch(setHomeQuoteDetails({ quotes: quoteList as HomeQuote[] }));
};
