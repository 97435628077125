/* istanbul ignore file */
import * as propertyInfoAction from '../constants';

export const savePropertyInfoDetailsLoader = (payload: boolean): AppRedux.Action => ({
  type: propertyInfoAction.SAVE_PROPERTY_INFO_DETAILS_LOADER,
  payload,
});

export const savePropertyInfoDetails = (): AppRedux.Action => ({
  type: propertyInfoAction.SAVE_PROPERTY_INFO_DETAILS,
});

export const setIsUpdatedPropertyInfo = (payload: boolean): AppRedux.Action => ({
  type: propertyInfoAction.SET_IS_UPDATED_PROPERTY_INFO,
  payload,
});
