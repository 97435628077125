import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import Modal from '../../components/modal';
import Template from './template';
import { getNestedTernaryData, numberRegex, stringWithSpaceRegex } from '../../../utils';
import { setOrgUpdatedResponseData, updateOrganizationData } from '../../redux/actions';
import { INVALID, LENDER_SETTING, REQUIRED, SM } from '../../../constants';
import ViewLenderSettings from './view-lender-settings';

const INIT_LENDER_SETTINGS_DETAILS = {
  lenderEnabled: false,
  lenderName: '',
  lenderStreet: '',
  lenderCity: '',
  lenderState: '',
  lenderZip: '',
};

const INIT_LENDER_SETTINGS_ERRORS = {
  lenderNameError: '',
  lenderStreetError: '',
  lenderCityError: '',
  lenderStateError: '',
  lenderZipError: '',
};

export class LenderSetting extends Component<
  AppComponents.LenderSettingDataProps,
  AppComponents.LenderSettingState
> {
  state: AppComponents.LenderSettingState = {
    lenderSetting: { ...INIT_LENDER_SETTINGS_DETAILS },
    lenderSettingErrors: { ...INIT_LENDER_SETTINGS_ERRORS },
  };

  componentDidMount = () => {
    this.setLenderSettingsDetails();
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { orgResponseData } = newProps?.organizations;
    if (!!orgResponseData?.isUpdatedSuccessfully) {
      this.onCloseModal();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
  };

  onCloseModal = () => {
    this.setState(
      (prevState: any) => ({
        ...prevState,
        lenderSettingErrors: {
          ...INIT_LENDER_SETTINGS_ERRORS,
        },
      }),
      () => {
        this.setLenderSettingsDetails();
      }
    );
    this.props.closeModal();
  };

  setLenderSettingsDetails = () => {
    const { editOrganizationData } = this.props;
    this.setState((prevState: any) => ({
      ...prevState,
      lenderSetting: {
        lenderEnabled: !!editOrganizationData?.lender_enabled ? true : false,
        lenderName: !!editOrganizationData?.lender_name ? editOrganizationData?.lender_name : '',
        lenderStreet: !!editOrganizationData?.lender_street
          ? editOrganizationData?.lender_street
          : '',
        lenderCity: !!editOrganizationData?.lender_city ? editOrganizationData?.lender_city : '',
        lenderState: !!editOrganizationData?.lender_state ? editOrganizationData?.lender_state : '',
        lenderZip: !!editOrganizationData?.lender_zip ? editOrganizationData?.lender_zip : '',
      },
    }));
  };

  onCheckBoxChange = (event: any) => {
    const { name, checked } = event.target;
    this.setState((prevState: any) => ({
      ...prevState,
      lenderSetting: {
        ...prevState.lenderSetting,
        [name]: checked,
      },
    }));
  };

  onChangeLenderSettings = (event: any) => {
    const { name, value } = event.target;
    if (name === LENDER_SETTING.LENDER_ZIP_KEY) {
      if (!numberRegex.test(value)) {
        return false;
      }
      if (!isEmpty(value) && value.length > 5) {
        return false;
      }
    }
    if (name === LENDER_SETTING.LENDER_CITY_LABEL && !stringWithSpaceRegex.test(value)) {
      return false;
    }

    this.setState((prevState: any) => ({
      ...prevState,
      lenderSetting: {
        ...prevState.lenderSetting,
        [name]: value,
      },
    }));
  };

  validate = () => {
    const { lenderSetting } = this.state;
    let errors: any = { ...INIT_LENDER_SETTINGS_ERRORS };
    if (!!lenderSetting?.lenderEnabled) {
      errors.lenderNameError = isEmpty(lenderSetting.lenderName) ? REQUIRED : '';
      errors.lenderStreetError = isEmpty(lenderSetting.lenderStreet) ? REQUIRED : '';
      errors.lenderCityError = isEmpty(lenderSetting.lenderCity) ? REQUIRED : '';
      errors.lenderStateError = isEmpty(lenderSetting.lenderState) ? REQUIRED : '';
      errors.lenderZipError = isEmpty(lenderSetting.lenderZip)
        ? REQUIRED
        : getNestedTernaryData(lenderSetting.lenderZip.length < 5, INVALID, '');
    }
    this.setState((prevState: any) => ({
      ...prevState,
      lenderSettingErrors: {
        ...errors,
      },
    }));
    if (!isEqual(errors, INIT_LENDER_SETTINGS_ERRORS)) {
      return false;
    } else {
      return true;
    }
  };

  saveLenderSettings = () => {
    const { editOrganizationData } = this.props;
    const { lenderSetting } = this.state;
    const isValid = this.validate();
    if (!!isValid) {
      let payload: any = {
        code: editOrganizationData?.code,
        auth_token: editOrganizationData?.auth_token,
        id: editOrganizationData?.id,
        name: editOrganizationData?.name,
        lender_enabled: !!lenderSetting?.lenderEnabled ? true : false,
      };
      if (!!lenderSetting?.lenderEnabled) {
        payload.lender_name = !isEmpty(lenderSetting?.lenderName)
          ? lenderSetting?.lenderName?.trim()
          : '';
        payload.lender_street = !isEmpty(lenderSetting?.lenderStreet)
          ? lenderSetting?.lenderStreet?.trim()
          : '';
        payload.lender_city = !isEmpty(lenderSetting?.lenderCity)
          ? lenderSetting?.lenderCity?.trim()
          : '';
        payload.lender_state = !isEmpty(lenderSetting?.lenderState)
          ? lenderSetting?.lenderState?.trim()
          : '';
        payload.lender_zip = !isEmpty(lenderSetting?.lenderZip)
          ? lenderSetting?.lenderZip?.trim()
          : '';
      } else {
        payload.lender_name = !isEmpty(editOrganizationData?.lender_name)
          ? editOrganizationData?.lender_name
          : '';
        payload.lender_street = !isEmpty(editOrganizationData?.lender_street)
          ? editOrganizationData?.lender_street
          : '';
        payload.lender_city = !isEmpty(editOrganizationData?.lender_city)
          ? editOrganizationData?.lender_city
          : '';
        payload.lender_state = !isEmpty(editOrganizationData?.lender_state)
          ? editOrganizationData?.lender_state
          : '';
        payload.lender_zip = !isEmpty(editOrganizationData?.lender_zip)
          ? editOrganizationData?.lender_zip
          : '';
      }
      this.props.updateOrganizationData(payload);
    }
  };

  render() {
    const { editOrganizationData, isEditLenderSetting, organizations } = this.props;
    return (
      <>
        <Modal
          isOpen={isEditLenderSetting}
          onClose={() => this.onCloseModal()}
          title={LENDER_SETTING?.LABEL}
          navButtons={true}
          maxWidth={SM}
          onSave={() => this.saveLenderSettings()}
          loader={!!organizations?.addEditOrgLoader ? true : false}
        >
          <Template component={this} disabled={!!organizations?.addEditOrgLoader} />
        </Modal>
        <div>
          <ViewLenderSettings editOrganizationData={editOrganizationData} />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.LenderSettingStore) => {
  const { addEditOrgLoader, editOrganizationData } = organizations;
  return {
    addEditOrgLoader,
    editOrganizationData,
    organizations,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.LenderSettingDispatch => {
  return bindActionCreators({ setOrgUpdatedResponseData, updateOrganizationData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(LenderSetting);
