import React from 'react';
import { startCase } from 'lodash';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import sortBy from 'lodash/sortBy';
import Tooltip from '@material-ui/core/Tooltip';
import DateRangeSelector from '../components/date-range-selector';
import FilterSelector from '../components/filter-selector';
import { FILTER_NAME_TOOLTIP, NON_RETURNING_QUOTE_OPTION, ORGANIZATION } from '../../constants';

const getSortedList = (list: any) => sortBy(list, 'label');

const renderFirstLineFilters = (component: any) => {
  const {
    reportDetailsError,
    reportDetails: { startDate, endDate, dateFilterType, nonReturningQuotes },
  } = component.state;
  const { submitLoader, loader, removeFilterLoader } = component.props.amsReport;
  const disableFields = loader || submitLoader || removeFilterLoader;
  return (
    <div className='row mb-3'>
      <div className='col-lg-4 col-md-12 col-sm-12 mob-mb'>
        <FormControl fullWidth>
          <div className='field-label'>Select Date<span className="star-error">*</span></div>
          <DateRangeSelector
            dateRange={{ startDate, endDate }}
            onDateRangeApply={component.handleDateRangeChange}
            disableRange={disableFields}
          />
        </FormControl>
        <FormHelperText>{reportDetailsError.rangeError}</FormHelperText>
      </div>
      <div className='col-lg-4 col-md-6'>
        <div className='field-label'>Filter On</div>
        <FormControl component='fieldset' disabled={disableFields}>
          <RadioGroup
            row
            aria-label='dateFilterType'
            name='dateFilterType'
            value={dateFilterType}
            onChange={component.handleFilterOnChange}
          >
            <FormControlLabel
              value='quoteDate'
              control={<Radio className='quote-radio' />}
              label='Quote Date'
              className='radio-label'
            />
            <FormControlLabel
              value='effectiveDate'
              control={<Radio className='quote-radio' />}
              label='Effective Date'
              className='radio-label'
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className='col-lg-4 col-md-6'>
      <div className='field-label'>{NON_RETURNING_QUOTE_OPTION.LABEL}</div>
        <FormControl component='fieldset' disabled={disableFields}>
          <RadioGroup
            row
            aria-label={NON_RETURNING_QUOTE_OPTION.KEY}
            name={NON_RETURNING_QUOTE_OPTION.KEY}
            value={nonReturningQuotes}
            onChange={component.handleFilterOnChange}
          >
            <FormControlLabel
              value={NON_RETURNING_QUOTE_OPTION.EXCLUDE}
              control={<Radio className='quote-radio' />}
              label={startCase(NON_RETURNING_QUOTE_OPTION.EXCLUDE)}
              className='radio-label'
            />
            <FormControlLabel
              value={NON_RETURNING_QUOTE_OPTION.INCLUDE}
              control={<Radio className='quote-radio' />}
              label={startCase(NON_RETURNING_QUOTE_OPTION.INCLUDE)}
              className='radio-label'
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

const renderSecondLineFilters = (component: any) => {
  const {
    stateFilterOptions,
    stateFilterList,
    stateAppliedFilter,
    lobFilterOptions,
    lobFilterList,
    lobAppliedFilter,
    carrierFilterOptions,
    carrierFilterList,
    carrierAppliedFilter,
  } = component.state;
  const { submitLoader, loader, removeFilterLoader } = component.props.amsReport;
  const disableFields = loader || submitLoader || removeFilterLoader;
  return (
    <div className='row mb-4'>
      <div className='col-md-4 col-sm-12 mob-mb'>
        <div className='field-label'>State</div>
        <FilterSelector
          options={getSortedList(stateFilterOptions)}
          value={stateFilterList}
          appliedFilter={stateAppliedFilter}
          onChange={(list: any) => component.onDropdownFilterChange(list, 'state')}
          onClearFilter={() => component.onDropdownClearFilter('state')}
          onFilterMenuClose={() => component.onDropdownFilterMenuClose('state')}
          disabled={disableFields || stateFilterOptions.length === 0}
          onApplyFilter={(filters: Array<any>) => component.onDropdownApplyFilter(filters, 'state')}
        />
      </div>
      <div className='col-md-4 col-sm-12 mob-mb'>
        <div className='field-label'>LOB</div>
        <FilterSelector
          options={getSortedList(lobFilterOptions)}
          value={lobFilterList}
          appliedFilter={lobAppliedFilter}
          onChange={(list: any) => component.onDropdownFilterChange(list, 'lob')}
          onClearFilter={() => component.onDropdownClearFilter('lob')}
          onFilterMenuClose={() => component.onDropdownFilterMenuClose('lob')}
          disabled={disableFields || lobFilterOptions.length === 0}
          onApplyFilter={(filters: Array<any>) => component.onDropdownApplyFilter(filters, 'lob')}
        />
      </div>
      <div className='col-md-4 col-sm-12'>
        <div className='field-label'>Carrier</div>
        <FilterSelector
          options={getSortedList(carrierFilterOptions)}
          value={carrierFilterList}
          appliedFilter={carrierAppliedFilter}
          onChange={(list: any) => component.onDropdownFilterChange(list, 'carrier')}
          onClearFilter={() => component.onDropdownClearFilter('carrier')}
          onFilterMenuClose={() => component.onDropdownFilterMenuClose('carrier')}
          disabled={disableFields || carrierFilterOptions.length === 0}
          onApplyFilter={(filters: Array<any>) =>
            component.onDropdownApplyFilter(filters, 'carrier')
          }
        />
      </div>
    </div>
  );
};

const renderThirdLineFilters = (component: any) => {
  const {
    divisionFilterOptions,
    divisionFilterList,
    divisionAppliedFilter,
    executiveFilterOptions,
    executiveFilterList,
    executiveAppliedFilter,
    organizationFilterList,
    organizationAppliedFilter,
    organizationFilterOptions
  } = component.state;
  const { submitLoader, loader, removeFilterLoader } = component.props.amsReport;
  const disableFields = loader || submitLoader || removeFilterLoader;
 
  return (
    <div className='row mb-4'>
      <div className='col-md-4 col-sm-12 mob-mb'>
        <div className='field-label'>Division</div>
        <FilterSelector
          options={getSortedList(divisionFilterOptions)}
          value={divisionFilterList}
          appliedFilter={divisionAppliedFilter}
          onChange={(list: any) => component.onDropdownFilterChange(list, 'division')}
          onClearFilter={() => component.onDropdownClearFilter('division')}
          onFilterMenuClose={() => component.onDropdownFilterMenuClose('division')}
          disabled={disableFields || divisionFilterOptions.length === 0}
          onApplyFilter={(filters: Array<any>) =>
            component.onDropdownApplyFilter(filters, 'division')
          }
        />
      </div>
      <div className='col-md-4 col-sm-12 mob-mb'>
        <div className='field-label'>Executive</div>
        <FilterSelector
          options={getSortedList(executiveFilterOptions)}
          value={executiveFilterList}
          appliedFilter={executiveAppliedFilter}
          onChange={(list: any) => component.onDropdownFilterChange(list, 'executive')}
          onClearFilter={() => component.onDropdownClearFilter('executive')}
          onFilterMenuClose={() => component.onDropdownFilterMenuClose('executive')}
          disabled={disableFields || executiveFilterOptions.length === 0}
          onApplyFilter={(filters: Array<any>) =>
            component.onDropdownApplyFilter(filters, 'executive')
          }
        />
      </div>
      <div className='col-md-4 col-sm-12'>
        <div className='field-label'>{ORGANIZATION}</div>
        <FilterSelector
          options={getSortedList(organizationFilterOptions)}
          value={organizationFilterList}
          appliedFilter={organizationAppliedFilter}
          onChange={(list: any) => component.onDropdownFilterChange(list, ORGANIZATION?.toLowerCase())}
          onClearFilter={() => component.onDropdownClearFilter(ORGANIZATION?.toLowerCase())}
          onFilterMenuClose={() => component.onDropdownFilterMenuClose(ORGANIZATION?.toLowerCase())}
          disabled={disableFields || organizationFilterOptions?.length === 0}
          onApplyFilter={(filters: Array<any>) =>
            component.onDropdownApplyFilter(filters, ORGANIZATION?.toLowerCase())
          }
        />
      </div>
    </div>
  );
};

const renderFilterNameField = (component: any) => {
  const {
    reportDetails: { filterName },
    reportDetailsError,
  } = component.state;
  const { submitLoader, loader, removeFilterLoader } = component.props.amsReport;
  const disableFields = loader || submitLoader || removeFilterLoader;
  return (
    <div className='row mb-4'>
      <div className='col-md-8 col-sm-12'>
        <FormControl fullWidth disabled={disableFields}>
          <InputLabel shrink htmlFor='filterName'>
            <span>
              Filter Name
              <Tooltip
                title={FILTER_NAME_TOOLTIP}
                placement='right'
                classes={{ popper: component.props.classes.filterTooltip }}
              >
                <InfoOutlinedIcon className='info-icon' />
              </Tooltip>
            </span>
          </InputLabel>
          <Input
            inputProps={{
              id: 'filterName',
              name: 'filterName',
              maxLength: 50,
            }}
            value={filterName}
            onChange={component.fieldChange}
          />
        </FormControl>
        <FormHelperText>{reportDetailsError.filterNameError}</FormHelperText>
      </div>
    </div>
  );
};

const getReportDetailsContent = (component: any) => {
  return (
    <Card className='details-card-wrapper'>
      <CardContent className='pb-1'>
        <div className='card-title'>Details</div>

        {renderFirstLineFilters(component)}

        {renderSecondLineFilters(component)}

        {renderThirdLineFilters(component)}

        {renderFilterNameField(component)}
      </CardContent>
    </Card>
  );
};

const template = (component: any) => {
  return (
    <div className='row mb-2'>
      <div className='col-12'>{getReportDetailsContent(component)}</div>
    </div>
  );
};

export default template;
