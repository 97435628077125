import { PropertyFormKey, PropertyFormValues } from './property-info.types';
import store from '../../../pages/redux/store';
import { FormValue } from '../../custom-hooks';
import { getBasementFinishType, getBoolean, getBooleanToYesNo, getNumber } from '../../utils';
import { isEqual, omit } from 'lodash';
import moment from 'moment';
import { FoundationType, LineOfBusiness, PoolInstallation } from '../../../graphql/generated';
import { dateFormat, getLobHerdId } from '../../../utils';
import { selectedLobMapping } from '../../redux/apis';
import { DEFAULT_PAGE_TRACKING } from '../../../constants';
import { DateViewType } from '../form-fields';
import { HUNDRED } from '../../constants';

export const SWIMMING_POOL_INSTALLATION_MAPPING: any = {
  ABOVE_GROUND: PoolInstallation.AboveGround,
  IN_GROUND: PoolInstallation.InGround,
  [PoolInstallation.AboveGround]: PoolInstallation.AboveGround,
  [PoolInstallation.InGround]: PoolInstallation.InGround,
};

export const mapFormValuesToPropertyInfoReqPayload = (formValues: PropertyFormValues) => {
  const {
    homeownerLastPurchaseDate,
    buildingFoundationBasementFinishedPercent,
    buildingFoundationType,
    buildingGeneralCondition,
    buildingGeneralFamilyUnits,
    buildingGeneralStories,
    buildingGeneralYearBuilt,
    buildingGeneralSquareFootage,
    buildingExteriorWallFinish,
    buildingExteriorWallStructure,
    buildingRoofMaterial,
    buildingRoofStyle,
    buildingGeneralQuality,
    locationDistanceToFireStation,
    siteHasSwimmingPool,
    siteSwimmingPoolIsFenced,
    siteSwimmingPoolInstallation,
    siteHasTrampoline,
    locationWithinCityLimit,
    buildingRoofYearBuilt,
    buildingUtilityElectricYearUpdated,
    buildingUtilityHeatingYearUpdated,
    buildingUtilityPlumbingYearUpdated,
    buildingInteriorHasFireAlarm,
    buildingInteriorHasFireExtinguisher,
    buildingInteriorHasFireSprinkler,
    siteHasSecurityGuard,
  } = formValues;
  const {
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  const swimmingPoolInstallation =
    siteSwimmingPoolInstallation && getBoolean(siteHasSwimmingPool)
      ? SWIMMING_POOL_INSTALLATION_MAPPING[siteSwimmingPoolInstallation]
      : null;
  const payload = {
    propertyId: propertyInfo?.propertyId,
    input: {
      building: {
        general: {
          quality: buildingGeneralQuality ?? null,
          condition: buildingGeneralCondition ?? null,
          familyUnits: buildingGeneralFamilyUnits ?? null,
          yearBuilt: getNumber(buildingGeneralYearBuilt) ?? null,
          squareFootage: getNumber(buildingGeneralSquareFootage) ?? null,
          stories: buildingGeneralStories ?? null,
        },
        foundation: {
          type: buildingFoundationType,
          basement: {
            finishedPercent:
              buildingFoundationType === FoundationType.Basement
                ? getNumber(buildingFoundationBasementFinishedPercent)
                : null,
          },
        },
        exteriorWall: {
          finish: buildingExteriorWallFinish ?? null,
          structure: buildingExteriorWallStructure ?? null,
        },
        roof: {
          material: buildingRoofMaterial ?? null,
          style: buildingRoofStyle ?? null,
          yearBuilt: getNumber(buildingRoofYearBuilt) ?? null,
        },
        interior: {
          hasFireAlarm: getBoolean(buildingInteriorHasFireAlarm),
          hasFireExtinguisher: getBoolean(buildingInteriorHasFireExtinguisher),
          hasFireSprinkler: getBoolean(buildingInteriorHasFireSprinkler),
        },
        utility: {
          electricYearUpdated: getNumber(buildingUtilityElectricYearUpdated) ?? null,
          heatingYearUpdated: getNumber(buildingUtilityHeatingYearUpdated) ?? null,
          plumbingYearUpdated: getNumber(buildingUtilityPlumbingYearUpdated) ?? null,
        },
      },
      homeowner: {
        lastPurchaseDate: homeownerLastPurchaseDate
          ? moment(homeownerLastPurchaseDate).utc().format()
          : moment(new Date()).utc().format(),
      },
      location: {
        distanceToFireStation: getNumber(locationDistanceToFireStation) ?? null,
        withinCityLimit: getBoolean(locationWithinCityLimit),
      },
      site: {
        hasSwimmingPool: getBoolean(siteHasSwimmingPool),
        hasSecurityGuard: getBoolean(siteHasSecurityGuard),
        hasTrampoline: getBoolean(siteHasTrampoline),
        swimmingPool: {
          installation: swimmingPoolInstallation ?? null,
          isFenced: getBoolean(siteSwimmingPoolIsFenced),
        },
      },
    },
  };
  return payload;
};

export const getMappedValuesFromPropertyInfoStoreToState = (
  initFormValues: PropertyFormValues,
  formValues: FormValue<PropertyFormKey>
): PropertyFormValues => {
  const {
    propertyInfoDetails: { coverageDetails, deductibleDetails, propertyOtherDetails, propertyInfo },
  } = store.getState();
  const checkForPropertyFormValue = (key: PropertyFormKey) => {
    return formValues[key] ?? initFormValues[key] ?? '';
  };
  const { previousPolicyCancelled, residenceType } =
    propertyOtherDetails || {};
  const { building, homeowner, location, risk, site, rebuildingCost } = propertyInfo;
  const swimmingPoolInstallation = site?.swimmingPool?.installation
    ? SWIMMING_POOL_INSTALLATION_MAPPING[site?.swimmingPool?.installation]
    : checkForPropertyFormValue('siteSwimmingPoolInstallation');
  const purchaseDate =
    homeowner?.lastPurchaseDate ?? checkForPropertyFormValue('homeownerLastPurchaseDate') ?? null;
  const rebuildingCostData = rebuildingCost?.verisk?.medium ?? rebuildingCost?.hippo?.medium ?? '';
  const mappedformValues = {
    buildingGeneralYearBuilt:
      building?.general?.yearBuilt?.toString() ??
      checkForPropertyFormValue('buildingGeneralYearBuilt'),
    buildingGeneralSquareFootage:
      building?.general?.squareFootage?.toString() ??
      checkForPropertyFormValue('buildingGeneralSquareFootage'),
    buildingGeneralFamilyUnits:
      building?.general?.familyUnits?.toString() ??
      checkForPropertyFormValue('buildingGeneralFamilyUnits'),
    buildingGeneralStories:
      building?.general?.stories?.toString() ?? checkForPropertyFormValue('buildingGeneralStories'),
    buildingFoundationType:
      building?.foundation?.type?.toString() ?? checkForPropertyFormValue('buildingFoundationType'),
    basementFinishType: getBasementFinishType(
      building?.foundation?.basement?.finishedPercent?.toString() ?? HUNDRED
    ),
    buildingFoundationBasementFinishedPercent:
      building?.foundation?.basement?.finishedPercent?.toString() ?? HUNDRED,
    buildingExteriorWallFinish:
      building?.exteriorWall?.finish?.toString() ??
      checkForPropertyFormValue('buildingExteriorWallFinish'),
    buildingExteriorWallStructure:
      building?.exteriorWall?.structure?.toString() ??
      checkForPropertyFormValue('buildingExteriorWallStructure'),
    buildingRoofMaterial:
      building?.roof?.material?.toString() ?? checkForPropertyFormValue('buildingRoofMaterial'),
    buildingRoofStyle:
      building?.roof?.style?.toString() ?? checkForPropertyFormValue('buildingRoofStyle'),
    residenceType: residenceType?.toString() ?? checkForPropertyFormValue('residenceType'),
    buildingGeneralQuality:
      building?.general?.quality?.toString() ?? checkForPropertyFormValue('buildingGeneralQuality'),
    buildingGeneralCondition:
      building?.general?.condition ?? checkForPropertyFormValue('buildingGeneralCondition'),
    riskPpcProtectionClass:
      risk?.ppc?.protectionClass?.toString() ?? checkForPropertyFormValue('riskPpcProtectionClass'),
    locationDistanceToFireStation:
      location?.distanceToFireStation?.toString() ??
      checkForPropertyFormValue('locationDistanceToFireStation'),
    siteHasSwimmingPool:
      getBooleanToYesNo(site?.hasSwimmingPool) ?? checkForPropertyFormValue('siteHasSwimmingPool'),
    siteSwimmingPoolIsFenced:
      getBooleanToYesNo(site?.swimmingPool?.isFenced) ??
      checkForPropertyFormValue('siteSwimmingPoolIsFenced'),
    siteSwimmingPoolInstallation: swimmingPoolInstallation,
    siteHasTrampoline:
      getBooleanToYesNo(site?.hasTrampoline) ?? checkForPropertyFormValue('siteHasTrampoline'),
    siteHasSecurityGuard:
      getBooleanToYesNo(site?.hasSecurityGuard) ??
      checkForPropertyFormValue('siteHasSecurityGuard'),
    locationWithinCityLimit:
      getBooleanToYesNo(location?.withinCityLimit) ??
      checkForPropertyFormValue('locationWithinCityLimit'),
    buildingRoofYearBuilt:
      building?.roof?.yearBuilt?.toString() ?? checkForPropertyFormValue('buildingRoofYearBuilt'),
    homeownerLastPurchaseDate: purchaseDate
      ? moment(purchaseDate).format(dateFormat)
      : moment().subtract(1, DateViewType.YEAR).utc().format(dateFormat),
    buildingUtilityElectricYearUpdated:
      building?.utility?.electricYearUpdated?.toString() ??
      checkForPropertyFormValue('buildingUtilityElectricYearUpdated'),
    buildingUtilityHeatingYearUpdated:
      building?.utility?.heatingYearUpdated?.toString() ??
      checkForPropertyFormValue('buildingUtilityHeatingYearUpdated'),
    buildingUtilityPlumbingYearUpdated:
      building?.utility?.plumbingYearUpdated?.toString() ??
      checkForPropertyFormValue('buildingUtilityPlumbingYearUpdated'),
    rebuildingCost: rebuildingCostData?.toString() ?? checkForPropertyFormValue('rebuildingCost'),
    coverageA: coverageDetails?.coverageA?.toString() ?? checkForPropertyFormValue('coverageA'),
    coverageB: coverageDetails?.coverageB?.toString() ?? checkForPropertyFormValue('coverageB'),
    coverageC: coverageDetails?.coverageC?.toString() ?? checkForPropertyFormValue('coverageC'),
    coverageD: coverageDetails?.coverageD?.toString() ?? checkForPropertyFormValue('coverageD'),
    coverageE: coverageDetails?.coverageE?.toString() ?? checkForPropertyFormValue('coverageE'),
    coverageF: coverageDetails?.coverageF?.toString() ?? checkForPropertyFormValue('coverageF'),
    deductible: deductibleDetails?.base?.toString() ?? checkForPropertyFormValue('deductible'),
    waterBackup:
      coverageDetails?.waterBackup?.toString() ?? checkForPropertyFormValue('waterBackup'),
    previousPolicyCancelled:
      previousPolicyCancelled?.toString() ?? checkForPropertyFormValue('previousPolicyCancelled'),
    buildingInteriorHasFireAlarm:
      getBooleanToYesNo(building?.interior?.hasFireAlarm) ??
      checkForPropertyFormValue('buildingInteriorHasFireAlarm'),
    buildingInteriorHasFireExtinguisher:
      getBooleanToYesNo(building?.interior?.hasFireExtinguisher) ??
      checkForPropertyFormValue('buildingInteriorHasFireExtinguisher'),
    buildingInteriorHasFireSprinkler:
      getBooleanToYesNo(building?.interior?.hasFireSprinkler) ??
      checkForPropertyFormValue('buildingInteriorHasFireSprinkler'),
  };

  return mappedformValues as PropertyFormValues;
};

export const mapCoveragesAndPropertyInfoPaylod = (formValues: PropertyFormValues) => {
  const {
    coverageA,
    coverageB,
    coverageC,
    coverageD,
    coverageE,
    coverageF,
    deductible,
    previousPolicyCancelled,
    residenceType,
    waterBackup,
  } = formValues;
  return {
    coverageDetailsPayload: {
      coverageA: getNumber(coverageA),
      coverageB: getNumber(coverageB) || null,
      coverageC: getNumber(coverageC),
      coverageD: getNumber(coverageD),
      coverageE: getNumber(coverageE),
      coverageF: getNumber(coverageF),
      waterBackup: getNumber(waterBackup),
    },
    deductibleDetailsPayload: {
      base: getNumber(deductible),
    },
    otherDetailsPayload: {
      previousPolicyCancelled,
      residenceType,
    },
  };
};

export const mapPropertyInfoStoreToStateData = () => {
  const {
    propertyInfoDetails: { coverageDetails, deductibleDetails, propertyOtherDetails, propertyInfo },
  } = store.getState();
  const { previousPolicyCancelled, residenceType } = propertyOtherDetails || {};
  const { building, homeowner, location, risk, site, rebuildingCost } = propertyInfo;
  const swimmingPoolInstallation = site?.swimmingPool?.installation
    ? SWIMMING_POOL_INSTALLATION_MAPPING[site?.swimmingPool?.installation]
    : '';
  const rebuildingCostData = rebuildingCost?.verisk?.medium ?? rebuildingCost?.hippo?.medium ?? '';
  const mappedformValues = {
    buildingGeneralYearBuilt: building?.general?.yearBuilt?.toString() ?? '',
    buildingGeneralSquareFootage: building?.general?.squareFootage?.toString() ?? '',
    buildingGeneralFamilyUnits: building?.general?.familyUnits?.toString() ?? '',
    buildingGeneralStories: building?.general?.stories?.toString() ?? '',
    buildingFoundationType: building?.foundation?.type?.toString() ?? '',
    basementFinishType: getBasementFinishType(
      building?.foundation?.basement?.finishedPercent?.toString() || HUNDRED
    ),
    buildingFoundationBasementFinishedPercent:
      building?.foundation?.basement?.finishedPercent?.toString() ?? HUNDRED,
    buildingExteriorWallFinish: building?.exteriorWall?.finish?.toString() ?? '',
    buildingExteriorWallStructure: building?.exteriorWall?.structure?.toString() ?? '',
    buildingRoofMaterial: building?.roof?.material?.toString() ?? '',
    buildingRoofStyle: building?.roof?.style?.toString() ?? '',
    residenceType: residenceType?.toString() ?? '',
    buildingGeneralQuality: building?.general?.quality?.toString() ?? '',
    buildingGeneralCondition: building?.general?.condition ?? '',
    riskPpcProtectionClass: risk?.ppc?.protectionClass?.toString() ?? '',
    locationDistanceToFireStation: location?.distanceToFireStation?.toString() ?? '',
    siteHasSwimmingPool: getBooleanToYesNo(site?.hasSwimmingPool) ?? '',
    siteSwimmingPoolIsFenced: getBooleanToYesNo(site?.swimmingPool?.isFenced) ?? '',
    siteSwimmingPoolInstallation: swimmingPoolInstallation,
    siteHasTrampoline: getBooleanToYesNo(site?.hasTrampoline) ?? '',
    siteHasSecurityGuard: getBooleanToYesNo(site?.hasSecurityGuard) ?? '',
    locationWithinCityLimit: location?.withinCityLimit?.toString() ?? '',
    buildingRoofYearBuilt: building?.roof?.yearBuilt?.toString() ?? '',
    homeownerLastPurchaseDate: homeowner?.lastPurchaseDate
      ? moment(homeowner?.lastPurchaseDate).format(dateFormat)
      : '',
    buildingUtilityElectricYearUpdated: building?.utility?.electricYearUpdated?.toString() ?? '',
    buildingUtilityHeatingYearUpdated: building?.utility?.heatingYearUpdated?.toString() ?? '',
    buildingUtilityPlumbingYearUpdated: building?.utility?.plumbingYearUpdated?.toString() ?? '',
    rebuildingCost: rebuildingCostData?.toString(),
    coverageA: coverageDetails?.coverageA?.toString() ?? '',
    coverageB: coverageDetails?.coverageB?.toString() ?? '',
    coverageC: coverageDetails?.coverageC?.toString() ?? '',
    coverageD: coverageDetails?.coverageD?.toString() ?? '',
    coverageE: coverageDetails?.coverageE?.toString() ?? '',
    coverageF: coverageDetails?.coverageF?.toString() ?? '',
    deductible: deductibleDetails?.base?.toString() ?? '',
    waterBackup: coverageDetails?.waterBackup?.toString() ?? '',
    previousPolicyCancelled: previousPolicyCancelled?.toString() ?? '',
    buildingInteriorHasFireAlarm: getBooleanToYesNo(building?.interior?.hasFireAlarm) ?? '',
    buildingInteriorHasFireExtinguisher:
      getBooleanToYesNo(building?.interior?.hasFireExtinguisher) ?? '',
    buildingInteriorHasFireSprinkler: getBooleanToYesNo(building?.interior?.hasFireSprinkler) ?? '',
  };
  return mappedformValues as PropertyFormValues;
};

export const getIsPropertyInfoChanged = (formValues: PropertyFormValues) => {
  const removeKeys = [
    'coverageA',
    'coverageB',
    'coverageC',
    'coverageD',
    'coverageE',
    'coverageF',
    'deductible',
    'waterBackup',
    'previousPolicyCancelled',
    'residenceType',
    'basementFinishType',
  ];
  const mappedPropertyInfo = { ...omit(mapPropertyInfoStoreToStateData(), removeKeys) };
  const reqformValues = { ...omit(formValues, removeKeys) };
  return isEqual(mappedPropertyInfo, reqformValues);
};

export const mapUpdateHomeRequestPayloadFromPropertyInfo = (formValues: PropertyFormValues) => {
  const {
    partner: { appointments },
    propertyInfoDetails: { propertyInfo },
  } = store.getState();
  const { coverageDetailsPayload, deductibleDetailsPayload, otherDetailsPayload } =
    mapCoveragesAndPropertyInfoPaylod(formValues);
  const selectedLob = Object.keys(appointments).map(
    (key: string) => selectedLobMapping[key] || LineOfBusiness.Home
  );
  const herdId = getLobHerdId(LineOfBusiness.Home);
  return {
    id: herdId,
    input: {
      propertyId: propertyInfo.propertyId,
      propertyRevision: propertyInfo?.revision,
      pageTracking: [{ key: LineOfBusiness.Home, value: DEFAULT_PAGE_TRACKING.propertyInfo }],
      residenceType: otherDetailsPayload.residenceType,
      selectedLob: selectedLob,
      coverage: { ...coverageDetailsPayload },
      deductible: { ...deductibleDetailsPayload, },
    },
  };
};
