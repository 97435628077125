import DateFnsUtils from '@date-io/date-fns';
import { FormLabel, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { FC } from 'react';
import { ASTERISK, REQUEST_BIND_PREFILL_INFORMATION } from '../../../constants';
import { InputFieldDetails, Options } from '../property-info/type';
import './request-bind-model.scss';

const { HEADING, INPUT_FIELDS, TITLE } = REQUEST_BIND_PREFILL_INFORMATION;
const { ROOF_CONSTRUCTED_OR_REPLACED, YEAR_BUILT, SQUARE_FOOTAGE, TRAMPOLINE } = INPUT_FIELDS;

/** Creating drop-down options */
const createMenuItems = (options: Options[]) =>
  options.map((option: Options) => (
    <MenuItem key={option?.label} value={`${option?.value}~${option?.label}`}>
      {option?.label}
    </MenuItem>
  ));

const PrefillInformationForm: FC<AppComponents.PrefillInformationFormPropes> = ({
  formValues,
  formErrors,
  handleOnChange,
  datePrckerOnChangeHandler,
  getRequiredKeys,
  shouldRenderTrampoline,
}) => {
  const requireFields = [...getRequiredKeys(), TRAMPOLINE.KEY];

  /** Common function to create TextField*/
  const drawTextField = (fieldDetails: InputFieldDetails, props: Record<string, any> = {}) => {
    const { LABEL, KEY, OPTIONS } = fieldDetails;
    return (
      <>
        <FormLabel htmlFor={KEY} className={`form-label ${!!formErrors[KEY] && 'error-color'}`}>
          {LABEL}
          {requireFields.includes(KEY) && <span className='error-color'>{ASTERISK}</span>}
        </FormLabel>
        <TextField
          className='prop-info-text-filled'
          id={KEY}
          variant='standard'
          onChange={handleOnChange}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: KEY === SQUARE_FOOTAGE.KEY && (
              <InputAdornment position='start'>sq ft</InputAdornment>
            ),
          }}
          inputProps={{
            name: KEY,
            maxLength: 7,
          }}
          value={formValues[KEY]}
          error={!!formErrors[KEY]}
          helperText={formErrors[KEY]}
          select={!!OPTIONS?.length}
          {...props}
        >
          {OPTIONS ? createMenuItems(OPTIONS) : []}
        </TextField>
      </>
    );
  };

  /** Common function to create DatePicker*/
  const drawDatePicker = (fieldDetails: InputFieldDetails, props: Record<string, any> = {}) => {
    const { LABEL, KEY, MIN_VALUE, MAX_VALUE } = fieldDetails;
    return (
      <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <FormLabel htmlFor={KEY} className={`form-label ${!!formErrors[KEY] && 'error-color'}`}>
            {LABEL}
            {requireFields.includes(KEY) && <span className='error-color'>{ASTERISK}</span>}
          </FormLabel>
          <KeyboardDatePicker
            views={['year']}
            id={KEY}
            value={formValues[KEY] || null}
            onChange={(_date, value) => datePrckerOnChangeHandler(value, KEY)}
            error={!!formErrors[KEY]}
            helperText={formErrors[KEY]}
            format='yyyy'
            minDate={MIN_VALUE}
            maxDate={MAX_VALUE}
            inputValue={formValues[KEY]}
            autoOk
            fullWidth
            {...props}
          />
        </MuiPickersUtilsProvider>
      </>
    );
  };

  return (
    <div className='prefill-information-form-wrapper'>
      <div className='container'>
        <Typography className='form-title'>{TITLE}</Typography>
        <hr />
        <Typography className='form-heading'>{HEADING}</Typography>
        <br />
        <div className='row'>
          {Object.values(INPUT_FIELDS).map((details: InputFieldDetails) => {
            if (!shouldRenderTrampoline() && details.KEY === TRAMPOLINE.KEY) return null;
            if ([YEAR_BUILT.KEY, ROOF_CONSTRUCTED_OR_REPLACED.KEY].includes(details.KEY))
              return (
                <div key={details.KEY} className='col-lg-6 mb-4'>
                  {drawDatePicker(details)}
                </div>
              );
            return (
              <div key={details.KEY} className='col-lg-6'>
                {drawTextField(details)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PrefillInformationForm;
