/* istanbul ignore file */
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { graphqlBaseApi } from '../../../graphql/services/baseApi';
import { applicantApi } from '../../../quote-application/redux/apis/applicant';
import { homeQuoteSlice } from '../../../quote-application/redux/slices/home-quote.slice';
import { ratesDetailsSlice } from '../../../quote-application/redux/slices';
import propertyInfoSlice from '../../../quote-application/redux/slices/property-info-details.slice';
import AMSReport from './ams-report';
import Applicant from './applicant';
import Auth from './auth';
import BulkQuote from './bulk-quote';
import Coverage from './coverage';
import cyberSecurityDetails from './cyber-security';
import Dashboard from './dashboard';
import Driver from './driver';
import Feedback from './feedback';
import FloodDetails from './flood-details';
import Common from './helper';
import Home from './home';
import homeWarrantyDetails from './home-warranty';
import OtherDetails from './incidents';
import Insurance from './insurance';
import lifeDetails from './life';
import Organizations from './organizations';
import Partner from './partner';
import PetDetails from './pet-details';
import QuakeDetails from './quake-details';
import Quote from './quote';
import umbrellaDetails from './umbrella-details';
import Vehicle from './vehicle';
import ViewQuoteDetails from './view-quote';

export default (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    applicant: Applicant,
    common: Common,
    vehicle: Vehicle,
    driver: Driver,
    otherDetails: OtherDetails,
    insurance: Insurance,
    coverage: Coverage,
    quote: Quote,
    dashboard: Dashboard,
    auth: Auth,
    home: Home,
    bulkQuote: BulkQuote,
    amsReport: AMSReport,
    floodDetails: FloodDetails,
    partner: Partner,
    organizations: Organizations,
    quakeDetails: QuakeDetails,
    feedback: Feedback,
    petDetails: PetDetails,
    viewQuoteDetails: ViewQuoteDetails,
    homeWarrantyDetails: homeWarrantyDetails,
    lifeDetails: lifeDetails,
    cyberSecurityDetails: cyberSecurityDetails,
    umbrellaDetails: umbrellaDetails,
    [applicantApi.reducerPath]: applicantApi.reducer,
    [graphqlBaseApi.reducerPath]: graphqlBaseApi.reducer,
    [propertyInfoSlice.name]: propertyInfoSlice.reducer,
    [homeQuoteSlice.name]: homeQuoteSlice.reducer,
    [ratesDetailsSlice.name]: ratesDetailsSlice.reducer,
  });
