import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import { CheckCircle, FiberManualRecord } from '@material-ui/icons';
import { isEmpty, sortBy, startCase } from 'lodash';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PolicyBoundImageUrl from '../../../assets/images/policy-bound-ribbon.png';
import {
  FAILED,
  FLOOD_DIRECT_CARRIERS,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  POLICY_BOUND,
} from '../../../constants';
import FloodPlanModal from '../../../pages/components/flood-plan-details';
import PetPlanModal from '../../../pages/components/premium-plans-modal';
import { ErrorCategoryIconMapping } from '../../../pages/components/rate-page-common-component';
import ViewAmsBoundData from '../../../pages/components/view-bound-quote';
import { storeCyberSecurityQuoteDetails } from '../../../pages/redux/actions';
import { useAppDispatch, useAppSelector } from '../../../pages/redux/hooks';
import { getFloodPlansList, getQuoteCarrierErrorCategory } from '../../../utils';
import { CommonQuote, FloodPlan, PetPlans, PremiumDetails } from '../../utils/types';
import './products-rates.scss';
import RatesBlock from './rates-block-inner';
import { QuoteListProps } from './types';

// eslint-disable-next-line max-lines-per-function
const QuoteList: FC<QuoteListProps> = ({
  type,
  loading,
  quoteList,
  updateSelectedQuoteList,
  carrier,
  selectedQuoteList,
  carrierSelected,
}) => {
  const { common, petDetails, cyberSecurityDetails, lifeDetails } = useAppSelector(state => state);
  const dispatch = useAppDispatch();
  const [selectedCarrier, setSelectedCarrier] = useState(carrierSelected);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [premium, setPremium] = useState<PremiumDetails>({});
  const [disableCheckBox, setDisableCheckBox] = useState(false);
  const [showPremiumPlanModal, setShowPremiumPlanModal] = useState(false);
  const [availablePlanList, setAvailablePlanList] = useState<FloodPlan | PetPlans>({});
  const [showFloodPlanModal, setShowFloodPlanModal] = useState(false);
  const [floodPlanList, setFloodPlanList] = useState({});
  const isQuoteWithMultiplePremium =
    type === INSURANCE_BUSINESS_TYPES.FLOOD ||
    type === INSURANCE_BUSINESS_TYPES.QUAKE ||
    type === INSURANCE_BUSINESS_TYPES.PET ||
    type === INSURANCE_BUSINESS_TYPES.HOMEWARRANTY ||
    type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY ||
    type === INSURANCE_BUSINESS_TYPES.LIFE ||
    type === INSURANCE_BUSINESS_TYPES.UMBRELLA;
  const singleFloodPremium = false;
  const handlePremiumPlanModal = () => {
    setShowPremiumPlanModal(true);
  };
  const closeModal = () => {
    setShowPremiumPlanModal(false);
  };

  const openFloodPlanModal = () => {
    setShowFloodPlanModal(true);
  };
  const closeFloodPlanModal = () => {
    setShowFloodPlanModal(false);
  };

  useEffect(() => {
    const quote = carrier || quoteList?.[selectedCarrier];
    if (type === INSURANCE_BUSINESS_TYPES.PET && quote) {
      setAvailablePlanList(quote?.available_plans ?? {});
    }
    const isFloodDirectCarrier =
      type === INSURANCE_BUSINESS_TYPES.FLOOD &&
      FLOOD_DIRECT_CARRIERS.includes(quote?.carrier_id?.toString() as string);
    if (isFloodDirectCarrier && quote) {
      const premiumDetails = quote?.premiumDetails || [];
      setFloodPlanList(getFloodPlansList(premiumDetails as AppComponents.FloodPremiumDetails[]));
    }
    // eslint-disable-next-line
  }, [carrier, selectedCarrier]);

  useEffect(() => {
    const { selectedCarriers } = common;
    if (!!selectedCarriers && !!type && selectedCarriers[type?.toLowerCase()]) {
      setSelectedCarrier(carrierSelected);
    }
    // eslint-disable-next-line
  }, [carrierSelected]);

  useEffect(() => {
    const { policyBounded } = common;
    if (
      ((!!carrier?.policyBounded || !!quoteList[selectedCarrier]?.policyBounded) && !!type) ||
      (!!policyBounded?.home && type?.toLowerCase() === INSURANCE_PRODUCTS[0]) ||
      (!!policyBounded?.auto && type?.toLowerCase() === INSURANCE_PRODUCTS[1]) ||
      (!!policyBounded?.flood && type?.toLowerCase() === INSURANCE_PRODUCTS[2]) ||
      (!!policyBounded?.earthquake && type?.toLowerCase() === INSURANCE_PRODUCTS[3])
    ) {
      setDisableCheckBox(true);
    } else {
      setDisableCheckBox(false);
    }
    // eslint-disable-next-line
  }, [carrier, setDisableCheckBox, common, type, quoteList]);

  const extractPremiumDetails = () => {
    let premiumDetails: PremiumDetails[] = [];
    quoteList.forEach((carrierOfQuote, index: number) => {
      if (index === selectedCarrier && carrierOfQuote.premiumDetails) {
        carrierOfQuote.premiumDetails = sortBy(carrierOfQuote.premiumDetails, ['premium']);
        premiumDetails = [...carrierOfQuote.premiumDetails];
      }
    });
    return premiumDetails;
  };

  const getCarrierPremium = (deductibleIndex: number) => {
    let premiumDetails = extractPremiumDetails();
    if (premiumDetails.length) {
      premiumDetails = sortBy(premiumDetails, ['premium']);
      setPremium(premiumDetails[deductibleIndex]);
    }
  };

  useEffect(() => {
    if (isQuoteWithMultiplePremium && !loading) {
      let premiumIndex = 0;
      if (
        (type === INSURANCE_BUSINESS_TYPES.QUAKE || type === INSURANCE_BUSINESS_TYPES.PET) &&
        selectedQuoteList?.length
      ) {
        const quoteIndex = selectedQuoteList.findIndex(quote => quote?.type === type);
        const selectedPremiumType = selectedQuoteList[quoteIndex]?.quote?.selectedPremiumType;
        let premiumDetails = selectedQuoteList[quoteIndex]?.quote?.premiumDetails;
        if (premiumDetails && selectedPremiumType && premiumDetails.length) {
          premiumDetails = sortBy(premiumDetails, ['premium']);
          premiumIndex = premiumDetails.findIndex(
            premium =>
              (type === INSURANCE_BUSINESS_TYPES.QUAKE ? premium?.coverageType : premium?.plan) ===
              selectedPremiumType
          );
        }
        getCarrierPremium(premiumIndex);
      } else {
        getCarrierPremium(0);
      }
    }
    // eslint-disable-next-line
  }, [
    loading,
    petDetails.bridgeToCarrierLoader,
    cyberSecurityDetails.bridgeToCarrierLoader,
    lifeDetails.bridgeToCarrierLoader,
  ]);

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index?: number,
    details?: PremiumDetails
  ) => {
    setIsCheckboxChecked(e.target.checked);
    let carrierWithPrice: CommonQuote | null = null;
    if (isQuoteWithMultiplePremium && index !== undefined && details) {
      if (details?.premium) {
        carrierWithPrice = { ...carrier, price: details?.premium };
        type === INSURANCE_BUSINESS_TYPES.PET || type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
          ? setPremium(details)
          : getCarrierPremium(0);
        type !== INSURANCE_BUSINESS_TYPES.PET && setSelectedCarrier(index);
        let selectionType =
          type === INSURANCE_BUSINESS_TYPES.QUAKE || type === INSURANCE_BUSINESS_TYPES.CYBERSECURITY
            ? details?.coverageType
            : ((type === INSURANCE_BUSINESS_TYPES.LIFE ||
                type === INSURANCE_BUSINESS_TYPES.UMBRELLA) &&
                details?.coverage) ||
              details?.plan;
        let term =
          type === INSURANCE_BUSINESS_TYPES.LIFE || type === INSURANCE_BUSINESS_TYPES.UMBRELLA
            ? details?.term
            : 12;
        updateSelectedQuoteList(carrierWithPrice, e.target.checked, type, selectionType, term);
      }
    }

    if (
      (isQuoteWithMultiplePremium && !quoteList[selectedCarrier]?.price) ||
      quoteList[selectedCarrier]?.quoteError
    ) {
      return;
    }
    if (!carrierWithPrice) {
      if (!!type && common?.selectedCarriers?.[type?.toLowerCase()] && !isEmpty(details)) {
        const carrierDetails = common?.selectedCarriers[type?.toLowerCase()];
        const selectedQuoteIndex = quoteList.findIndex(
          el => el?.carrier_id?.toString() === carrierDetails?.carrierId?.toString()
        );
        updateSelectedQuoteList(quoteList[selectedQuoteIndex], e.target.checked, type);
      } else {
        updateSelectedQuoteList(carrier || quoteList[selectedCarrier], e.target.checked, type);
      }
    }
  };

  const onCheckboxChangeForCyber = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!isEmpty(event.target.value)) {
      let quoteObj = carrier || quoteList[selectedCarrier];
      let premiumDetails = quoteObj[event.target.value as keyof CommonQuote] as PremiumDetails[];
      quoteObj.selectedPlan = event.target.value as string;
      quoteObj.premiumDetails = premiumDetails;
      if (premiumDetails.length) {
        dispatch(
          storeCyberSecurityQuoteDetails({
            quoteList: [quoteObj],
          })
        );
      }
    }
  };

  const drawRatesBlocks = () => {
    return (
      <RatesBlock
        key={`rates_${carrier.carrier_id ? carrier.carrier_id : carrier.CarrierId}_${type}`}
        quote={carrier}
        type={type}
        premiumDetails={premium}
        chooseCarrierOption={updateSelectedQuoteList}
        resetViewMore={0}
        onCheckboxChange={onCheckboxChange}
        verifyChecked={verifyChecked}
        isQuoteWithMultiplePremium={isQuoteWithMultiplePremium}
        handlePremiumPlanModal={handlePremiumPlanModal}
        singleFloodPremium={singleFloodPremium}
        onCheckboxChangeForCyber={onCheckboxChangeForCyber}
        openFloodPlanModal={openFloodPlanModal}
      />
    );
  };

  const verifyChecked = (premiumCarrier?: number): boolean => {
    let checked = false;
    if (!disableCheckBox) {
      !isEmpty(selectedQuoteList) &&
        selectedQuoteList.forEach(quoteSelected => {
          if (isQuoteWithMultiplePremium && !singleFloodPremium && type === quoteSelected.type) {
            if (
              premiumCarrier === quoteSelected.quote?.price &&
              !quoteSelected?.quote?.policyBounded
            ) {
              checked = true;
            }
          } else if (type === quoteSelected.type) {
            if (
              !quoteSelected?.quote?.policyBounded &&
              ((quoteSelected.quote?.carrier_id &&
                quoteSelected.quote?.carrier_id === carrier.carrier_id) ||
                (quoteSelected.quote?.CarrierId &&
                  quoteSelected.quote?.CarrierId === carrier.CarrierId))
            ) {
              checked = true;
            }
          }
        });
    } else if (
      !!carrier?.policyBounded &&
      (!carrier?.floodQuote || singleFloodPremium) &&
      !carrier.earthquakeQuote &&
      type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
      type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
      type !== INSURANCE_BUSINESS_TYPES.LIFE &&
      type !== INSURANCE_BUSINESS_TYPES.UMBRELLA
    ) {
      checked = true;
    }
    return checked;
  };

  const getRatePageClassName = (pageType: string) => {
    if (isQuoteWithMultiplePremium && !singleFloodPremium) {
      return 'flood-quake-rates-blocks';
    } else if (pageType === INSURANCE_BUSINESS_TYPES.HOME || singleFloodPremium) {
      return 'home-rates-blocks';
    } else if (pageType === INSURANCE_BUSINESS_TYPES.AUTO) {
      return 'auto-rates-blocks';
    } else {
      return 'rates-left-block';
    }
  };

  const navigationContent = () => (
    <Grid
      item
      xs={(isQuoteWithMultiplePremium && !singleFloodPremium) || carrier.quoteError ? 12 : 11}
      className={getRatePageClassName(type)}
    >
      <Grid container spacing={0} className='h-100'>
        <Grid item xs={12} className='d-flex'>
          {drawRatesBlocks()}
        </Grid>
      </Grid>
    </Grid>
  );

  let showPolicyBoundTag = false;
  if (!!carrier?.policyBounded || !!quoteList[selectedCarrier]?.policyBounded) {
    if (
      !!type &&
      (type === INSURANCE_BUSINESS_TYPES.HOME || type === INSURANCE_BUSINESS_TYPES.AUTO)
    ) {
      showPolicyBoundTag = false;
    } else {
      showPolicyBoundTag = true;
    }
  }
  const [openViewBoundQuote, setOpenViewBoundQuote] = useState(false);

  const openPolicyBoundDetailsModal = () => {
    setOpenViewBoundQuote(true);
  };
  const isErrorQuote = carrier?.quoteError ?? carrier?.Status?.includes(startCase(FAILED));
  const carrierErrorType: string =
    isErrorQuote && !!carrier && !isEmpty(getQuoteCarrierErrorCategory(carrier))
      ? getQuoteCarrierErrorCategory(carrier)
      : '';

  return (
    <Grid container spacing={2} className='mt-3'>
      {!!openViewBoundQuote && (
        <ViewAmsBoundData
          open={openViewBoundQuote}
          onClose={() => setOpenViewBoundQuote(false)}
          lob={type}
        />
      )}
      <Grid item xs={12}>
        <Card
          className={`${
            type === INSURANCE_BUSINESS_TYPES.UMBRELLA
              ? 'rli-rates-block'
              : (isCheckboxChecked && 'rates-block rates-block-active') ||
                'rates-block rates-block-deactive '
          }
          `}
          elevation={0}
        >
          {
            <>
              {!!showPolicyBoundTag && (
                <div className='row'>
                  <div className='col-lg-12'>
                    <img
                      src={PolicyBoundImageUrl}
                      alt={POLICY_BOUND}
                      className='img-fluid position-absolute policy-bound-label'
                      onClick={() => openPolicyBoundDetailsModal()}
                    />
                  </div>
                </div>
              )}
              <CardContent className='rli-padding-0'>
                <Grid container spacing={0}>
                  {!isErrorQuote || isEmpty(carrierErrorType) ? (
                    <>
                      {(type !== INSURANCE_BUSINESS_TYPES.FLOOD || singleFloodPremium) &&
                        type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
                        type !== INSURANCE_BUSINESS_TYPES.PET &&
                        type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
                        type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
                        type !== INSURANCE_BUSINESS_TYPES.LIFE &&
                        type !== INSURANCE_BUSINESS_TYPES.UMBRELLA && (
                          <FormControl component='fieldset' className='checkbox-wrap-radio'>
                            <RadioGroup row name='lines'>
                              <FormControlLabel
                                className='radio-button-box'
                                control={
                                  <Checkbox
                                    icon={<FiberManualRecord />}
                                    checkedIcon={<CheckCircle className='check-icon' />}
                                    name='checkedH'
                                    checked={verifyChecked()}
                                    disabled={
                                      disableCheckBox
                                        ? true
                                        : ((type !== INSURANCE_BUSINESS_TYPES.FLOOD ||
                                            singleFloodPremium) &&
                                            type !== INSURANCE_BUSINESS_TYPES.QUAKE &&
                                            type !== INSURANCE_BUSINESS_TYPES.PET &&
                                            type !== INSURANCE_BUSINESS_TYPES.HOMEWARRANTY &&
                                            type !== INSURANCE_BUSINESS_TYPES.CYBERSECURITY &&
                                            type !== INSURANCE_BUSINESS_TYPES.LIFE &&
                                            type !== INSURANCE_BUSINESS_TYPES.UMBRELLA &&
                                            !!isErrorQuote) ||
                                          false
                                    }
                                    onChange={e => onCheckboxChange(e)}
                                  />
                                }
                                label=''
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                    </>
                  ) : (
                    <>
                      {!isEmpty(carrierErrorType) && (
                        <ErrorCategoryIconMapping carrierErrorType={carrierErrorType} />
                      )}
                    </>
                  )}
                  {navigationContent()}
                </Grid>
              </CardContent>
            </>
          }
        </Card>
      </Grid>
      {showPremiumPlanModal && (
        <PetPlanModal
          premiumPlansList={availablePlanList as PetPlans}
          showModal={showPremiumPlanModal}
          closeModal={closeModal}
        />
      )}
      {showFloodPlanModal && !isEmpty(floodPlanList) && (
        <FloodPlanModal floodPlansList={floodPlanList} closeModal={closeFloodPlanModal} />
      )}
    </Grid>
  );
};

export default QuoteList;
