import { PROPERTY_INFO } from './property-info';
const { PROPERTY_INFO_DETAILS } = PROPERTY_INFO;
const {
  CONSTRUCTION_TYPE,
  NO_OF_FAMILY_UNIT,
  NO_OF_STORIES,
  ROOF_CONSTRUCTED_OR_REPLACED,
  ROOF_TYPE,
  SQUARE_FOOTAGE,
  SWIMMING_POOL,
  TRAMPOLINE,
  YEAR_BUILT,
} = PROPERTY_INFO_DETAILS.INPUT_FIELDS;

export const REQUEST_BIND_PREFILL_INFORMATION = {
  TITLE: 'Property Information',
  KEY: 'propertyInformation',
  HEADING: 'Take a look and update anything that is missing or inaccurate.',
  INPUT_FIELDS: {
    YEAR_BUILT,
    SQUARE_FOOTAGE,
    ROOF_TYPE: {
      ...ROOF_TYPE,
      DEFAULT_VALUE: 'flat_roof~Other',
    },
    ROOF_CONSTRUCTED_OR_REPLACED: {
      ...ROOF_CONSTRUCTED_OR_REPLACED,
      LABEL: 'Roof Constructed/Replaced',
    },
    NO_OF_FAMILY_UNIT: {
      ...NO_OF_FAMILY_UNIT,
      LABEL: 'Family Units',
      DEFAULT_VALUE: '1~Single Family',
      OPTIONS: [
        { label: 'Single Family', value: '1' },
        { label: '2 units', value: '2' },
        { label: '3 units', value: '3' },
        { label: '4 units', value: '4' },
        { label: '5 units or more', value: '5+' },
      ],
    },
    NO_OF_STORIES: {
      ...NO_OF_STORIES,
      LABEL: 'Stories',
      DEFAULT_VALUE: '1~1',
    },
    SWIMMING_POOL,
    CONSTRUCTION_TYPE: {
      ...CONSTRUCTION_TYPE,
      KEY: 'constructionType',
      LABEL: 'Constructed Type of Home',
      DEFAULT_VALUE: 'brick_veneer~Brick Veneer',
      OPTIONS: [
        { label: 'Brick Veneer', value: 'brick_veneer' },
        { label: 'Concrete', value: 'concrete' },
        { label: 'Frame', value: 'frame' },
        { label: 'Masonry', value: 'masonry' },
        { label: 'Steel', value: 'steel' },
      ],
    },
    TRAMPOLINE,
  },
  STATE_REQUIRED_TRAMPOLINE_PRESENCE: ['CO', 'MI', 'NY', 'RI', 'WA', 'WV'],
};

export const MAPPED_PREFILL_INFORMATION_PAYLOAD_KEYS = {
  [ROOF_CONSTRUCTED_OR_REPLACED.KEY]: 'roofConstructed',
  [NO_OF_FAMILY_UNIT.KEY]: 'familyUnits',
  [NO_OF_STORIES.KEY]: 'stories',
  [CONSTRUCTION_TYPE.KEY]: 'constructedTypeOfHome',
};
