/* istanbul ignore file */
// @ts-nocheck
import ReactGA from 'react-ga';

import config from '../config/config';

const configApi = { gaId: config.googleAnalyticsId, heapId: config.heapId };
const initializeGoogleAnalytics = () => {
  ReactGA.initialize(configApi.gaId);
};
const windowObj: any = Object.assign({}, window || this);

windowObj.heap && windowObj.heap.load(configApi.heapId);

export default initializeGoogleAnalytics;
