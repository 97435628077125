import moment from 'moment';
import { NO, StateAbbreviation, YES } from '../../../constants';
import {
  DateViewType,
  FieldOption,
  FormFieldDataType,
  FormFieldType,
} from '../../components/form-fields';
import { PropertyFormDetails, PropertyFormKey } from '../../components/property-info';
import { BasementFinishType } from '../../enums';
import {
  BuildingCondition,
  BuildingQuality,
  ExteriorWallFinish,
  ExteriorWallStructure,
  FamilyUnits,
  FoundationType,
  PoolInstallation,
  ResidenceType,
  RoofMaterial,
  RoofStyle,
  Stories,
} from '../../../graphql/generated';

const dateFormat = 'MM/DD/YYYY';
const yearFormat = 'yyyy';

export const stateBasedDeductibleOptions: Partial<Record<StateAbbreviation, FieldOption[]>> = {
  AL: [
    { label: '$100', value: '100' },
    { label: '$250', value: '250' },
    { label: '$500', value: '500' },
    { label: '$750', value: '750' },
    { label: '$1,000', value: '1000', default: true },
    { label: '$1,500', value: '1500' },
    { label: '$2,000', value: '2000' },
    { label: '$2,500', value: '2500' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
  ],
  IL: [
    { label: '$100', value: '100' },
    { label: '$250', value: '250' },
    { label: '$500', value: '500' },
    { label: '$750', value: '750' },
    { label: '$1,000', value: '1000', default: true },
    { label: '$1,500', value: '1500' },
    { label: '$2,000', value: '2000' },
    { label: '$2,500', value: '2500' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
    { label: '$10,000', value: '10000' },
  ],
};

const dropdownOptions: Partial<Record<PropertyFormKey, FieldOption[]>> = {
  buildingExteriorWallFinish: [
    { label: 'Brick Veneer', value: ExteriorWallFinish.BrickVeneer },
    {
      label: 'Cement Fiber Clapboard Siding',
      value: ExteriorWallFinish.CementFiberClapboardSiding,
    },
    { label: 'Cement Fiber Shingle', value: ExteriorWallFinish.CementFiberShingle },
    { label: 'Clapboard Wood Siding', value: ExteriorWallFinish.ClapboardWoodSiding },
    { label: 'Hardboard Siding', value: ExteriorWallFinish.HardboardSiding },
    { label: 'Masonry Stucco', value: ExteriorWallFinish.MasonryStucco },
    { label: 'Metal Siding', value: ExteriorWallFinish.MetalSiding },
    { label: 'Stone Veneer', value: ExteriorWallFinish.StoneVeneer },
    { label: 'Synthetic Stucco', value: ExteriorWallFinish.SyntheticStucco },
    { label: 'Tongue And Groove Wood Siding', value: ExteriorWallFinish.TongueAndGrooveWoodSiding },
    { label: 'Vinyl Siding', value: ExteriorWallFinish.VinylSiding },
    { label: 'Wood Shingle', value: ExteriorWallFinish.WoodShingle },
    { label: 'None', value: ExteriorWallFinish.None },
  ],
  buildingExteriorWallStructure: [
    { label: 'Light Gauge Steel Framing', value: ExteriorWallStructure.LightGaugeSteelFraming },
    { label: 'Pre - Engineered Metal', value: ExteriorWallStructure.PreEngineeredMetal },
    { label: 'Solid Brick', value: ExteriorWallStructure.SolidBrick },
    {
      label: 'Solid Concrete or Concrete Block',
      value: ExteriorWallStructure.SolidConcreteOrConcreteBlock,
    },
    { label: 'Solid Log', value: ExteriorWallStructure.SolidLog },
    { label: 'Solid Stone', value: ExteriorWallStructure.SolidStone },
    { label: 'Timber Framing', value: ExteriorWallStructure.TimberFraming },
    { label: 'Wood Framing', value: ExteriorWallStructure.WoodFraming },
    { label: 'None', value: ExteriorWallStructure.None },
  ],
  basementFinishType: [
    { label: 'Finished', value: BasementFinishType.FINISHED },
    { label: 'Partially Finished', value: BasementFinishType.PARTIALLY_FINISHED },
    { label: 'Unfinished', value: BasementFinishType.UNFINISHED },
  ],
  buildingFoundationType: [
    { label: 'Basement', value: FoundationType.Basement },
    { label: 'Concrete Slab', value: FoundationType.ConcreteSlab },
    { label: 'Crawlspace', value: FoundationType.Crawlspace },
    { label: 'Piers or Pilings', value: FoundationType.PierOrPilings },
  ],
  buildingGeneralFamilyUnits: [
    { label: 'Single Family', value: FamilyUnits.SingleFamily },
    { label: 'Two Family', value: FamilyUnits.TwoFamily },
    { label: 'Three Family', value: FamilyUnits.ThreeFamily },
    { label: 'Four Family', value: FamilyUnits.FourFamily },
    { label: 'Multi Family', value: FamilyUnits.MultiFamily },
  ],
  buildingGeneralQuality: [
    { label: 'Economy', value: BuildingQuality.Economy },
    { label: 'Standard', value: BuildingQuality.Standard },
    { label: 'Premium', value: BuildingQuality.Premium },
    { label: 'Luxury', value: BuildingQuality.Luxury },
  ],
  buildingGeneralStories: [
    { label: 'One', value: Stories.One },
    { label: 'One And A Half', value: Stories.OneAndAHalf },
    { label: 'Two', value: Stories.Two },
    { label: 'Two And A Half', value: Stories.TwoAndAHalf },
    { label: 'Three', value: Stories.Three },
    { label: 'Three And A Half', value: Stories.ThreeAndAHalf },
    { label: 'Four', value: Stories.Four },
    { label: 'Bi Level', value: Stories.BiLevel },
    { label: 'Split Level', value: Stories.SplitLevel },
  ],
  buildingRoofMaterial: [
    { label: 'Architectural Asphalt Shingles', value: RoofMaterial.ArchitecturalAsphaltShingles },
    { label: 'Asphalt Membrane', value: RoofMaterial.AsphaltMembrane },
    { label: 'Basic Asphalt Shingles', value: RoofMaterial.BasicAsphaltShingles },
    { label: 'Basic Metal', value: RoofMaterial.BasicMetal },
    { label: 'Built Up', value: RoofMaterial.BuiltUp },
    { label: 'Cement Fiber Tile', value: RoofMaterial.CementFiberTile },
    { label: 'Clay Tile', value: RoofMaterial.ClayTile },
    { label: 'Composite Shingles', value: RoofMaterial.CompositeShingles },
    { label: 'Concrete Tile', value: RoofMaterial.ConcreteTile },
    { label: 'Copper Or Other Premium Metal', value: RoofMaterial.CopperOrOtherPremiumMetal },
    { label: 'Foam', value: RoofMaterial.Foam },
    {
      label: 'Impact Resistant Asphalt Shingles',
      value: RoofMaterial.ImpactResistantAsphaltShingles,
    },
    { label: 'Plastic Membrane', value: RoofMaterial.PlasticMembrane },
    { label: 'Slate Tile', value: RoofMaterial.SlateTile },
    { label: 'Wood Shingles', value: RoofMaterial.WoodShingles },
  ],
  buildingRoofStyle: [
    { label: 'Flat', value: RoofStyle.Flat },
    { label: 'Gable', value: RoofStyle.Gable },
    { label: 'Gambrel', value: RoofStyle.Gambrel },
    { label: 'Hip', value: RoofStyle.Hip },
    { label: 'Mansard', value: RoofStyle.Mansard },
    { label: 'Shed', value: RoofStyle.Shed },
    { label: 'Custom', value: RoofStyle.Custom },
  ],
  riskPpcProtectionClass: [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
  ],
  siteSwimmingPoolInstallation: [
    { label: 'Above Ground', value: PoolInstallation.AboveGround },
    { label: 'In Ground', value: PoolInstallation.InGround },
  ],
  residenceType: [
    {
      label: 'Primary',
      value: ResidenceType.Primary,
    },
    {
      label: 'Secondary',
      value: ResidenceType.Secondary,
    },
    {
      label: 'Rented',
      value: ResidenceType.Rented,
    },
    {
      label: 'Vacant',
      value: ResidenceType.Vacant,
    },
  ],
  buildingGeneralCondition: [
    {
      label: 'Fair',
      value: BuildingCondition.Fair,
    },
    {
      label: 'Average',
      value: BuildingCondition.Average,
    },
    {
      label: 'Excellent',
      value: BuildingCondition.Excellent,
    },
  ],
  coverageE: [
    { label: '$25,000', value: '25000' },
    { label: '$50,000', value: '50000' },
    { label: '$100,000', value: '100000' },
    { label: '$200,000', value: '200000' },
    { label: '$300,000', value: '300000' },
    { label: '$400,000', value: '400000' },
    { label: '$500,000', value: '500000' },
  ],
  coverageF: [
    { label: '$1,000', value: '1000' },
    { label: '$2,000', value: '2000' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
  ],
  deductible: [
    { label: '$100', value: '100' },
    { label: '$250', value: '250' },
    { label: '$500', value: '500' },
    { label: '$750', value: '750' },
    { label: '$1,000', value: '1000' },
    { label: '$2,000', value: '2000' },
    { label: '$2,500', value: '2500' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
  ],
  waterBackup: [
    { label: '$1,000', value: '1000' },
    { label: '$2,000', value: '2000' },
    { label: '$3,000', value: '3000' },
    { label: '$4,000', value: '4000' },
    { label: '$5,000', value: '5000' },
    { label: '$6,000', value: '6000' },
    { label: '$7,000', value: '7000' },
    { label: '$8,000', value: '8000' },
    { label: '$9,000', value: '9000' },
    { label: '$10,000', value: '10000' },
    { label: '$15,000', value: '15000' },
    { label: '$20,000', value: '20000' },
    { label: '$25,000', value: '25000' },
    { label: '$50,000', value: '50000' },
  ],
};

const yesNoOptions = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

const getFieldOptions = (key: PropertyFormKey): FieldOption[] => {
  return dropdownOptions[key] ?? [];
};

export const getStateBasedOptions = (
  key: PropertyFormKey,
  state: StateAbbreviation
): FieldOption[] | undefined => {
  if (key === 'deductible') {
    return stateBasedDeductibleOptions[state] ?? [];
  }
};

export const PROPERTY_FORM_DETAILS: PropertyFormDetails = {
  label: 'Confirm the property and coverage details',
  key: 'propertyInfo',
  propertyDetails: {
    label: 'Property Details',
    key: 'propertyDetails',
    fields: {
      buildingGeneralYearBuilt: {
        label: 'Year Built',
        name: 'buildingGeneralYearBuilt',
        fieldType: FormFieldType.DATE,
        dateViewType: DateViewType.YEAR,
        dateFormat: yearFormat,
        dataType: FormFieldDataType.NUMBER,
        minDate: moment().subtract(149, DateViewType.YEAR).toString(),
        maxDate: moment().add(1, DateViewType.YEAR).toString(),
        isRequired: true,
      },
      buildingGeneralSquareFootage: {
        label: 'Square Footage',
        name: 'buildingGeneralSquareFootage',
        fieldType: FormFieldType.TEXT,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
      },
      buildingGeneralFamilyUnits: {
        label: 'Number of Family Units',
        name: 'buildingGeneralFamilyUnits',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        defaultValue: FamilyUnits.SingleFamily,
        options: getFieldOptions('buildingGeneralFamilyUnits'),
        isRequired: true,
      },
      buildingGeneralStories: {
        label: 'Number Of Stories',
        name: 'buildingGeneralStories',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        defaultValue: Stories.One,
        options: getFieldOptions('buildingGeneralStories'),
        isRequired: true,
      },
      buildingFoundationType: {
        label: 'Foundation Type',
        name: 'buildingFoundationType',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        defaultValue: FoundationType.ConcreteSlab,
        options: getFieldOptions('buildingFoundationType'),
        isRequired: true,
      },
      basementFinishType: {
        label: 'Basement Finish Type',
        name: 'basementFinishType',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        defaultValue: BasementFinishType.FINISHED,
        options: getFieldOptions('basementFinishType'),
        isRequired: true,
      },
      buildingFoundationBasementFinishedPercent: {
        label: 'Basement Finish %',
        name: 'buildingFoundationBasementFinishedPercent',
        fieldType: FormFieldType.TEXT,
        dataType: FormFieldDataType.NUMBER,
        maxLength: 3,
        isRequired: true,
      },
      buildingExteriorWallFinish: {
        label: 'Exterior wall finish',
        name: 'buildingExteriorWallFinish',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: ExteriorWallFinish.VinylSiding,
        options: getFieldOptions('buildingExteriorWallFinish'),
      },
      buildingExteriorWallStructure: {
        label: 'Wall structure',
        name: 'buildingExteriorWallStructure',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: ExteriorWallStructure.SolidConcreteOrConcreteBlock,
        options: getFieldOptions('buildingExteriorWallStructure'),
      },
      buildingRoofMaterial: {
        label: 'Roof Material',
        name: 'buildingRoofMaterial',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: RoofMaterial.BasicAsphaltShingles,
        options: getFieldOptions('buildingRoofMaterial'),
      },
      buildingRoofStyle: {
        label: 'Roof Style',
        name: 'buildingRoofStyle',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: RoofStyle.Gable,
        options: getFieldOptions('buildingRoofStyle'),
      },
      residenceType: {
        label: 'Residency Status',
        name: 'residenceType',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: ResidenceType.Primary,
        options: getFieldOptions('residenceType'),
      },
      buildingGeneralQuality: {
        label: 'Building Quality',
        name: 'buildingGeneralQuality',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: BuildingQuality.Standard,
        options: getFieldOptions('buildingGeneralQuality'),
      },
      buildingGeneralCondition: {
        label: 'Building Condition',
        name: 'buildingGeneralCondition',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: BuildingCondition.Average,
        options: getFieldOptions('buildingGeneralCondition'),
      },
      riskPpcProtectionClass: {
        label: 'Protection Class',
        name: 'riskPpcProtectionClass',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: false,
        defaultValue: '1',
        options: getFieldOptions('riskPpcProtectionClass'),
        isDisabled: true,
      },
      locationDistanceToFireStation: {
        label: 'Distance to Fire Station in Miles',
        name: 'locationDistanceToFireStation',
        fieldType: FormFieldType.TEXT,
        dataType: FormFieldDataType.NUMBER,
        defaultValue: '1',
        isRequired: false,
        isDisabled: true,
      },
      siteHasSwimmingPool: {
        label: 'Swimming Pool',
        name: 'siteHasSwimmingPool',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        isRequired: true,
        defaultValue: NO,
        options: yesNoOptions,
      },
      siteSwimmingPoolIsFenced: {
        label: 'Swimming Pool Fenced?',
        name: 'siteSwimmingPoolIsFenced',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        defaultValue: NO,
        options: yesNoOptions,
      },
      siteSwimmingPoolInstallation: {
        label: 'Swimming Pool Type',
        name: 'siteSwimmingPoolInstallation',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: PoolInstallation.InGround,
        options: getFieldOptions('siteSwimmingPoolInstallation'),
      },
      siteHasTrampoline: {
        label: 'Trampoline',
        name: 'siteHasTrampoline',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        isRequired: true,
        options: yesNoOptions,
      },
      locationWithinCityLimit: {
        label: 'Within City Limits?',
        name: 'locationWithinCityLimit',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        defaultValue: YES,
        options: yesNoOptions,
      },
      buildingRoofYearBuilt: {
        label: 'Updated Roof?',
        name: 'buildingRoofYearBuilt',
        fieldType: FormFieldType.DATE,
        dateViewType: DateViewType.YEAR,
        dateFormat: yearFormat,
        dataType: FormFieldDataType.NUMBER,
        minDate: moment().subtract(99, DateViewType.YEAR).toString(),
        maxDate: moment().add(1, DateViewType.YEAR).toString(),
      },
      homeownerLastPurchaseDate: {
        label: 'Purchase Date',
        name: 'homeownerLastPurchaseDate',
        fieldType: FormFieldType.DATE,
        dateViewType: DateViewType.DATE,
        dateFormat: dateFormat,
        dataType: FormFieldDataType.STRING,
        defaultValue: moment().subtract(1, DateViewType.YEAR).utc().format(),
        minDate: moment().subtract(99, DateViewType.YEAR).toString(),
        maxDate: moment().toString(),
      },
      buildingUtilityElectricYearUpdated: {
        label: 'Updated Electric?',
        name: 'buildingUtilityElectricYearUpdated',
        fieldType: FormFieldType.DATE,
        dateViewType: DateViewType.YEAR,
        dateFormat: yearFormat,
        dataType: FormFieldDataType.STRING,
        minDate: moment().subtract(99, DateViewType.YEAR).toString(),
        maxDate: moment().add(1, DateViewType.YEAR).toString(),
      },
      buildingUtilityHeatingYearUpdated: {
        label: 'Updated Heating?',
        name: 'buildingUtilityHeatingYearUpdated',
        fieldType: FormFieldType.DATE,
        dateViewType: DateViewType.YEAR,
        dateFormat: yearFormat,
        dataType: FormFieldDataType.STRING,
        minDate: moment().subtract(99, DateViewType.YEAR).toString(),
        maxDate: moment().add(1, DateViewType.YEAR).toString(),
      },
      buildingUtilityPlumbingYearUpdated: {
        label: 'Updated Plumbing?',
        name: 'buildingUtilityPlumbingYearUpdated',
        fieldType: FormFieldType.DATE,
        dateViewType: DateViewType.YEAR,
        dateFormat: yearFormat,
        dataType: FormFieldDataType.STRING,
        minDate: moment().subtract(99, DateViewType.YEAR).toString(),
        maxDate: moment().add(1, DateViewType.YEAR).toString(),
      },
    },
  },
  coverageDetails: {
    label: 'Coverage Details',
    key: 'coverageDetails',
    fields: {
      rebuildingCost: {
        label: 'Reconstruction Cost Estimator (RCE)',
        name: 'rebuildingCost',
        fieldType: FormFieldType.PREMIUM,
        dataType: FormFieldDataType.NUMBER,
        isDisabled: true,
      },
      coverageA: {
        label: 'Coverage A / Dwelling Limit',
        name: 'coverageA',
        fieldType: FormFieldType.PREMIUM,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
      },
      coverageB: {
        label: 'Coverage B / Other Structures Limit',
        name: 'coverageB',
        fieldType: FormFieldType.PREMIUM,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
      },
      coverageC: {
        label: 'Coverage C / Personal Property Limit',
        name: 'coverageC',
        fieldType: FormFieldType.PREMIUM,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
      },
      coverageD: {
        label: 'Coverage D / Loss of Use Limit',
        name: 'coverageD',
        fieldType: FormFieldType.PREMIUM,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
      },
      coverageE: {
        label: 'Coverage E / Personal Liability Limit',
        name: 'coverageE',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
        options: getFieldOptions('coverageE'),
      },
      coverageF: {
        label: 'Coverage F / Medical Payments Limit',
        name: 'coverageF',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
        options: getFieldOptions('coverageF'),
      },
      deductible: {
        label: 'Preferred Deductible',
        name: 'deductible',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
        defaultValue: '1000',
        options: getFieldOptions('deductible'),
      },
      waterBackup: {
        label: 'Water Backup Limit',
        name: 'waterBackup',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.NUMBER,
        isRequired: true,
        options: getFieldOptions('waterBackup'),
      },
      previousPolicyCancelled: {
        label: 'Previous Policy Canceled',
        name: 'previousPolicyCancelled',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.STRING,
        isRequired: true,
        defaultValue: NO,
        options: yesNoOptions,
      },
    },
  },
  otherDetails: {
    label: 'Discounts',
    key: 'otherDetails',
    fields: {
      buildingInteriorHasFireAlarm: {
        label: 'Fire Alarm',
        name: 'buildingInteriorHasFireAlarm',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        isRequired: true,
        options: yesNoOptions,
      },
      buildingInteriorHasFireExtinguisher: {
        label: 'Fire Extinguisher',
        name: 'buildingInteriorHasFireExtinguisher',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        isRequired: true,
        options: yesNoOptions,
      },
      buildingInteriorHasFireSprinkler: {
        label: 'Sprinkler System',
        name: 'buildingInteriorHasFireSprinkler',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        isRequired: true,
        options: yesNoOptions,
      },
      siteHasSecurityGuard: {
        label: 'Security Guard',
        name: 'siteHasSecurityGuard',
        fieldType: FormFieldType.SELECT,
        dataType: FormFieldDataType.BOOLEAN,
        isRequired: true,
        options: yesNoOptions,
      },
    },
  },
};

/** TODO: need to delete unused variables below */
export const RETRY = 'Retry';
export const BASEMENT = 'basement';
export const PREFILL_RETRIES_MAX_COUNT = 3;
export const FLAT_ROOF = 'flat_roof';
export const DEFAULT_ROOT_SHAPE = 'flat~Flat';
export const UNFINISHED = 'unfinished';
export const HUNDRED = '100';
export const FIFTY = '50';

export const AL_STATE = 'AL';
export const IL_STATE = 'IL';
export const DEFAULT_DEDUCTABLE = {
  label: '$1,000',
  value: 1000,
};
export const DEFAULT_RESIDENCY_STATUS = 'PRIMARY';
export const MAXMIMUM_COVERAGE_LIMIT = 10000000;
