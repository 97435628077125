/* istanbul ignore file */
import * as types from '../constants';

interface ReportState {
  loader: boolean;
  removeFilterLoader: boolean;
  submitLoader: boolean;
  reportAPIError: string;
  currentDetails: {
    dateFilterType: string;
    startDate: string;
    endDate: string;
    carriers: Array<any>;
    lobs: Array<any>;
    states: Array<any>;
    divisions: Array<any>;
    executives: Array<any>;
    filterName: string;
    organizations: Array<any>
    appliedLobs: Array<any>;
    appliedStates: Array<any>;
    appliedDivisions: Array<any>;
    appliedExecutives: Array<any>;
    appliedCarriers: Array<any>;
    appliedOrganizations: Array<any>
  };
  stateList: Array<any>;
  lobList: Array<any>;
  carrierList: Array<any>;
  divisionList: Array<any>;
  executiveList: Array<any>;
  savedFilterList: Array<any>;
  organizationList: Array<any>;
  homeCarrierList: Array<any>;
  floodCarrierList: Array<any>;
  autoCarrierList: Array<any>;
}

const INIT_REPORT_STATE: ReportState = {
  loader: false,
  removeFilterLoader: false,
  submitLoader: false,
  reportAPIError: '',
  currentDetails: {
    dateFilterType: 'quoteDate',
    startDate: '',
    endDate: '',
    carriers: [],
    lobs: [],
    states: [],
    divisions: [],
    executives: [],
    filterName: '',
    organizations: [],
    appliedLobs: [],
    appliedStates: [],
    appliedDivisions: [],
    appliedExecutives: [],
    appliedCarriers: [],
    appliedOrganizations: []
  },
  stateList: [],
  lobList: [],
  carrierList: [],
  divisionList: [],
  executiveList: [],
  savedFilterList: [],
  homeCarrierList: [],
  floodCarrierList: [],
  autoCarrierList: [],
  organizationList: []
};

export default (state = INIT_REPORT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        ...INIT_REPORT_STATE,
      };
    }

    case types.SET_REPORT_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case types.SET_REPORT_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_REMOVE_FILTER_LOADER: {
      return {
        ...state,
        removeFilterLoader: action.payload,
      };
    }

    case types.UPDATE_SAVED_FILTERS: {
      return {
        ...state,
        savedFilterList: [...action.payload],
      };
    }

    case types.UPDATE_REPORT_CURRENT_DETAILS: {
      return {
        ...state,
        currentDetails: {
          ...state.currentDetails,
          ...action.payload,
        },
      };
    }

    case types.SET_SUBMIT_REPORT_LOADER: {
      return {
        ...state,
        submitLoader: action.payload,
      };
    }

    case types.SET_REPORT_API_ERROR: {
      return {
        ...state,
        reportAPIError: action.payload,
      };
    }

    default:
      return state;
  }
};
