import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { DEDUCTIBLES, LIABILITY, PERSONAL_PROPERTY, REBUILDING_COST } from '../../../constants';
import { HomeQuote } from '../../../graphql/generated';
import { deductiblesMap, formatCurrency } from '../../../pages/home/utils';
import { formatNumberToCurrency } from '../../../utils';
import '../product-rates/products-rates.scss';
import { OptionTypes } from '../rate-details/types/rates-step-details';
import { HomeCoverageDetails } from './home-coverage';

const getDeductibleItems = (quote: HomeQuote) => {
  const deductiblesArr = [];
  const deductibles = quote.data?.deductibles;
  if (deductibles?.base) {
    deductiblesArr.push(
      <li>{`${deductiblesMap.Other.details.label}: ${formatNumberToCurrency(
        deductibles.base
      )}`}</li>
    );
  }
  if (deductibles?.earthquake) {
    deductiblesArr.push(
      <li>{`${deductiblesMap.Earthquake.details.label}: ${formatNumberToCurrency(
        deductibles.earthquake
      )}`}</li>
    );
  }
  if (deductibles?.hurricane) {
    deductiblesArr.push(
      <li>{`${deductiblesMap.Hurricane.details.label}: ${formatNumberToCurrency(
        deductibles.hurricane
      )}`}</li>
    );
  }
  if (deductibles?.theft) {
    deductiblesArr.push(
      <li>{`${deductiblesMap.Theft.details.label}: ${formatNumberToCurrency(
        deductibles.theft
      )}`}</li>
    );
  }
  if (deductibles?.wind) {
    deductiblesArr.push(
      <li>{`${deductiblesMap.Wind.details.label}: ${formatNumberToCurrency(deductibles.wind)}`}</li>
    );
  }
  if (deductibles?.windHail) {
    deductiblesArr.push(
      <li>{`${deductiblesMap.WindHail.details.label}: ${formatNumberToCurrency(
        deductibles.windHail
      )}`}</li>
    );
  }

  return deductiblesArr;
};

const ViewMore: FC<{ quote: HomeQuote }> = ({ quote }) => {
  const deductibles = getDeductibleItems(quote);
  const coverageBlockList: OptionTypes[] = [
    { label: REBUILDING_COST, value: formatCurrency(quote.data?.covA, '') },
    { label: PERSONAL_PROPERTY, value: formatCurrency(quote.data?.covC, '') },
    { label: LIABILITY, value: formatCurrency(quote.data?.covE, '') },
  ];

  return (
    <>
      <Grid container spacing={2} className='mt-2 mb-2'>
        {coverageBlockList.map((item: OptionTypes) => {
          return (
            <Grid key={item.label} item xs={12} sm={4}>
              <div className='block-wraper'>
                <Typography className='rebuilding-cost-label text-center'>{item.label}</Typography>
                <Typography className='cost-number-label text-center'>{item.value}</Typography>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2} className='mb-2'>
        <HomeCoverageDetails quote={quote} />
        {deductibles.length > 0 && (
          <Grid item xs={12} sm={12}>
            <Typography className='label text-left'>{DEDUCTIBLES}</Typography>
            <div className='normal-label text-left'>
              <ul className='dc-list'>{deductibles}</ul>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ViewMore;
