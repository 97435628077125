/* istanbul ignore file */
// @ts-nocheck
import { find, isEmpty, sortBy, startCase } from 'lodash';
import { v4 } from 'uuid';
import { getNestedTernaryData, numberToCurrency } from './index';
import { FAILED, NO } from '../constants';
import {
  NO_UM_UIM,
  UM_UIM,
  UM_UIM_COVERAGE,
  UMBRELLA_QUESTION_GROUPS,
  UMBRELLA_QUESTION_SUB_GROUPS,
} from '../constants/umbrella-questionnaire';

export const getQuestionGroupLabelByGroupName = (groupName: string) => {
  const group = find(UMBRELLA_QUESTION_GROUPS, g => {
    return g.KEY === groupName;
  });
  return group ? group.LABEL : startCase(groupName.toLowerCase());
};

export const getQuestionGroupLabelBySubGroupName = (groupName: string) => {
  const group = find(UMBRELLA_QUESTION_SUB_GROUPS, g => {
    return g.KEY === groupName;
  });
  return group ? group?.LABEL : startCase(groupName?.toLowerCase());
};

export const sortUmbrellaQuestionsBySortOrder = (
  questions: Array<AppComponents.UmbrellaQuestionnaire>
) => {
  return questions?.sort((preQuestion, nextQuestion) => {
    if (preQuestion?.sortOrder < nextQuestion?.sortOrder) return -1;
    if (preQuestion?.sortOrder > nextQuestion?.sortOrder) return 1;
    return 0;
  });
};

export const getMappedUmbrellaQuestionnaires = (
  questions: Array<AppComponents.UmbrellaQuestionnaire>,
  group_name: string,
  sub_group_name?: string,
  repeatable_questions_id?: string
) => {
  const tempQuestions: Array<AppComponents.UmbrellaQuestionnaire> =
    sortUmbrellaQuestionsBySortOrder(questions);
  return tempQuestions?.map((question: any) => {
    const {
      response,
      validationErrorMessage,
      isDisabled,
      groupName,
      repeatableQuestionsId,
      subGroupName,
    } = question;
    return {
      ...question,
      groupName: getNestedTernaryData(!!groupName, groupName, group_name),
      subGroupName: getNestedTernaryData(
        !!subGroupName,
        subGroupName,
        getNestedTernaryData(!!sub_group_name, sub_group_name, '')
      ),
      response: getNestedTernaryData(!!response || !isEmpty(response), response, ''),
      validationErrorMessage: getNestedTernaryData(
        !!validationErrorMessage,
        validationErrorMessage,
        ''
      ),
      repeatableQuestionsId: getNestedTernaryData(
        !!repeatableQuestionsId,
        repeatableQuestionsId,
        getNestedTernaryData(!!repeatable_questions_id, repeatable_questions_id, '')
      ),
      isDisabled: !!isDisabled,
    };
  });
};

export const getMappedUmbrellaQuestionnaireGroups = (questionGroups: any) => {
  return questionGroups?.map((group: any) => {
    const { questions, groupName, subGroups } = group;
    if (!!subGroups && !isEmpty(subGroups)) {
      const tempQuestionSubGroups = subGroups?.map((subGroup: any) => {
        const repeatableData = subGroup?.questions.find(
          (item: any) => !isEmpty(item?.repeatableQuestions)
        );
        if (subGroup.repeatable && isEmpty(repeatableData)) {
          const uid = v4();
          return {
            ...subGroup,
            questions: [
              {
                repeatableQuestionsId: uid,
                repeatableQuestions: getMappedUmbrellaQuestionnaires(
                  subGroup?.questions,
                  groupName,
                  subGroup?.groupName,
                  uid
                ),
              },
            ],
          };
        } else if (!isEmpty(repeatableData)) {
          const tempRepeatableQuestionSets = subGroup?.questions.map(questSet => {
            const tempRepeatableQuestions = getMappedUmbrellaQuestionnaires(
              questSet.repeatableQuestions
            );
            return {
              ...questSet,
              repeatableQuestions: [...tempRepeatableQuestions],
            };
          });
          return {
            ...subGroup,
            questions: [...tempRepeatableQuestionSets],
          };
        }
        return {
          ...subGroup,
          questions: getMappedUmbrellaQuestionnaires(
            subGroup?.questions,
            groupName,
            subGroup?.groupName
          ),
        };
      });

      const repeatableData = group?.questions.find(
        (item: any) => !isEmpty(item?.repeatableQuestions)
      );
      if (group.repeatable && isEmpty(repeatableData)) {
        const uid = v4();
        return {
          ...group,
          questions: [
            {
              repeatableQuestionsId: uid,
              repeatableQuestions: getMappedUmbrellaQuestionnaires(
                subGroup?.questions,
                groupName,
                subGroup?.groupName,
                uid
              ),
            },
          ],
          subGroups: [...tempQuestionSubGroups],
        };
      } else if (!isEmpty(repeatableData)) {
        return {
          ...group,
          questions,
          subGroups: [...tempQuestionSubGroups],
        };
      }
      return {
        ...group,
        questions: getMappedUmbrellaQuestionnaires(questions, groupName, subGroups?.groupName),
        subGroups: [...tempQuestionSubGroups],
      };
    } else {
      const repeatableData = group?.questions.find(
        (item: any) => !isEmpty(item?.repeatableQuestions)
      );
      if (group.repeatable && isEmpty(repeatableData)) {
        const uid = v4();
        return {
          ...group,
          questions: [
            {
              repeatableQuestionsId: uid,
              repeatableQuestions: getMappedUmbrellaQuestionnaires(questions, groupName, null, uid),
            },
          ],
          subGroups: [],
        };
      } else if (!isEmpty(repeatableData)) {
        const tempRepeatableQuestionSets = questions.map(questSet => {
          const tempRepeatableQuestions = getMappedUmbrellaQuestionnaires(
            questSet.repeatableQuestions
          );
          return {
            ...questSet,
            repeatableQuestions: [...tempRepeatableQuestions],
          };
        });
        return {
          ...group,
          questions: tempRepeatableQuestionSets,
          subGroups: [],
        };
      }
      return {
        ...group,
        questions: getMappedUmbrellaQuestionnaires(questions, groupName),
        subGroups: [],
      };
    }
  });
};

export const setUmbrellaQuotesMapping = (quotes?: Array<any>) => {
  let list: Array<any> = [];
  if (!isEmpty(quotes)) {
    let umUimPremiumList = [];
    let noUmUimPremiumList = [];
    quotes?.forEach((item: any) => {
      umUimPremiumList = item?.premiumDetails?.filter(
        (premiumData: any) => premiumData?.premiumType === UM_UIM
      );
      noUmUimPremiumList = item?.premiumDetails?.filter(
        (premiumData: any) => premiumData?.premiumType === NO_UM_UIM
      );
      if (!isEmpty(noUmUimPremiumList)) {
        let quoteDetailsForNoUmUim = { ...item };
        quoteDetailsForNoUmUim.premiumDetails = noUmUimPremiumList;
        list.push(quoteDetailsForNoUmUim);
      }
      if (!isEmpty(umUimPremiumList)) {
        let quoteDetailsForUm = { ...item };
        quoteDetailsForUm.premiumDetails = umUimPremiumList;
        list.push(quoteDetailsForUm);
      }
    });
  }
  return list;
};

export const getUmbrellaCoverage = (quote: any) => {
  let sortedPremiumDetails = sortBy(quote.premiumDetails, ['premium']);
  let coverageData: any = '';
  if (!isEmpty(sortedPremiumDetails) && sortedPremiumDetails[0]?.premiumType === UM_UIM) {
    coverageData = `$${numberToCurrency(sortedPremiumDetails[0]?.limit)} / $${numberToCurrency(sortedPremiumDetails[0]?.deductible)}`;
  } else {
    coverageData = NO?.toUpperCase();
  }
  return quote?.Status?.toLowerCase() === FAILED || !!quote?.quoteError
    ? ''
    : `${coverageData} ${UM_UIM_COVERAGE}`;
};

export const createUmbrellaQuestionsMapping = (questionGroups: Array<any>) => {
  if (!isEmpty(questionGroups)) {
    const tempQuestionGroups = questionGroups.map((group: any) => {
      let tempQuestions: any = [];
      let tempQuestionSubGroups: any = [];
      if (!isEmpty(group?.questions)) {
        if (!group?.repeatable) {
          tempQuestions = Object.values(group?.questions)[0];
        } else {
          tempQuestions = Object.values(group?.questions).map((quest, index) => {
            const uid = v4();
            return {
              repeatableQuestionsId: uid,
              repeatableQuestions: getMappedUmbrellaQuestionnaires(
                quest,
                group.groupName,
                null,
                uid
              ),
            };
          });
        }
      }
      if (!isEmpty(group?.subGroups)) {
        tempQuestionSubGroups = group?.subGroups.map((subGroup: any) => {
          let tempSubGroupQuestions: any = [];
          if (!isEmpty(subGroup?.questions)) {
            if (!subGroup?.repeatable) {
              tempSubGroupQuestions = Object.values(subGroup?.questions)[0];
            } else {
              tempSubGroupQuestions = Object.values(subGroup?.questions).map((quest, index) => {
                const uid = v4();
                return {
                  repeatableQuestionsId: uid,
                  repeatableQuestions: getMappedUmbrellaQuestionnaires(
                    quest,
                    group.groupName,
                    null,
                    uid
                  ),
                };
              });
            }
          }
          return {
            ...subGroup,
            questions: [...tempSubGroupQuestions],
          };
        });
      }
      return {
        ...group,
        questions: [...tempQuestions],
        subGroups: [...tempQuestionSubGroups],
      };
    });
    return [...tempQuestionGroups];
  }
  return [];
};
export const filterUniqueCodeString = (questionText: string) => questionText.replace(/\uFFFD/g, ' ');

export const getMappedQuestionnaireResponses = (
  questSet: AppComponents.UmbrellaQuestionnaireSet
) => {
  if (!!questSet && !isEmpty(questSet)) {
    let tempQuestionGroups: Array<AppComponents.UmbrellaQuestionnaireGroup> =
      questSet.questionGroups;
    const responses = tempQuestionGroups?.map((group: AppComponents.UmbrellaQuestionnaireGroup) => {
      let questionSetKey: number = 0;
      const { groupName } = group;
      let subGroupResponses: Array<any> = [];
      let tempResponses: any = {};
      if (group.repeatable) {
        group?.questions.forEach((repeatableQuestionSet: any) => {
          if (!isEmpty(repeatableQuestionSet?.repeatableQuestions)) {
            tempResponses = {
              ...tempResponses,
              [questionSetKey.toString()]: repeatableQuestionSet?.repeatableQuestions.map(
                (quest: any) => {
                  return {
                    questionId: quest?.questionId,
                    response: quest?.response,
                  };
                }
              ),
            };
            questionSetKey = questionSetKey + 1;
          }
        });
      } else {
        if (!isEmpty(group?.questions)) {
          tempResponses = {
            ...tempResponses,
            [questionSetKey.toString()]: group?.questions?.map((question: any) => ({
              questionId: question?.questionId,
              response: question?.response,
            })),
          };
          questionSetKey = questionSetKey + 1;
        }
      }
      if (!isEmpty(group?.subGroups)) {
        subGroupResponses = group?.subGroups?.map((subGroup: any) => {
          let subQuestionSetKey: number = 0;
          let tempSubResponses: any = {};
          if (subGroup.repeatable) {
            subGroup?.questions.forEach((repeatableQuestionSet: any) => {
              if (!isEmpty(repeatableQuestionSet?.repeatableQuestions)) {
                tempSubResponses = {
                  ...tempSubResponses,
                  [subQuestionSetKey.toString()]: repeatableQuestionSet?.repeatableQuestions.map(
                    (quest: any) => {
                      return {
                        questionId: quest?.questionId,
                        response: quest?.response,
                      };
                    }
                  ),
                };
                subQuestionSetKey = subQuestionSetKey + 1;
              }
            });
          } else {
            if (!isEmpty(subGroup?.questions)) {
              tempSubResponses = {
                ...tempSubResponses,
                [subQuestionSetKey.toString()]: subGroup?.questions?.map((quest: any) => ({
                  questionId: quest?.questionId,
                  response: quest?.response,
                })),
              };
              subQuestionSetKey = subQuestionSetKey + 1;
            }
          }
          return {
            groupName: subGroup?.groupName,
            responses: tempSubResponses,
          };
        });
      }
      return {
        groupName: groupName,
        subGroupResponses: subGroupResponses,
        responses: tempResponses,
      };
    });
    return {
      questionSetId: questSet?.questionSetId,
      groupResponses: responses,
    };
  }
  return {
    questionSetId: '',
    groupResponses: [],
  };
};

export const getUniqueKeyForQuestionnaire = (question: AppComponents.UmbrellaQuestionnaire) => {
  const {
    questionId: { questionKey },
    repeatableQuestionsId,
    groupName,
    subGroupName,
  } = question;
  return `${questionKey}+${groupName}+${subGroupName}+${repeatableQuestionsId}`;
};

export const validateUmbrellaDropdown = (selectedValue: string) => {
  return Number(selectedValue) <= 0;
};
