/* istanbul ignore file */
import * as authType from '../constants';

export const userLoginPage = (): AppRedux.Action => ({
  type: authType.USER_LOGIN_PAGE,
});

export const userSignIn = (user: any): AppRedux.Action => ({
  type: authType.SIGNIN_USER,
  payload: { ...user },
});

export const partnerSignIn = (partner: any): AppRedux.Action => ({
  type: authType.SIGNIN_PARTNER,
  payload: { ...partner },
});

export const authLoaderShow = (flag: boolean): AppRedux.Action => ({
  type: authType.ON_SHOW_LOADER,
  payload: flag,
});

export const userSignInSuccess = (details: any): AppRedux.Action => ({
  type: authType.SIGNIN_USER_SUCCESS,
  payload: details,
});

export const userSignOut = (details?: any): AppRedux.Action => ({
  type: authType.SIGNOUT_USER,
  payload: details,
});

export const autoLogout = (flag: boolean): AppRedux.Action => ({
  type: authType.AUTO_LOGOUT,
  payload: flag,
});

export const setBulkUser = (isBulkUser: boolean): AppRedux.Action => ({
  type: authType.SET_BULK_USER,
  payload: isBulkUser,
});

export const setReportUser = (isReportUser: boolean): AppRedux.Action => ({
  type: authType.SET_REPORT_USER,
  payload: isReportUser,
});

export const switchApp = (): AppRedux.Action => ({
  type: authType.SWITCH_APP,
});

export const clearQuoteDetails = (): AppRedux.Action => ({
  type: authType.CLEAR_QUOTE_DETAILS,
});

export const setAdminUser = (isAdminUser: boolean): AppRedux.Action => ({
  type: authType.SET_ADMIN_UESR,
  payload: isAdminUser,
});
