/* istanbul ignore file */
import * as feedbackAction from '../constants';

export const feedbackLoader = (payload: boolean): AppRedux.Action => ({
  type: feedbackAction.FEEDBACK_LOADER,
  payload,
});

export const addFeedback = (payload: any): AppRedux.Action => ({
  type: feedbackAction.ADD_FEEDBACK,
  payload,
});

export const storeFeedbackResponse = (payload: any): AppRedux.Action => ({
  type: feedbackAction.STORE_FEEDBACK_RESPONSE,
  payload,
});
