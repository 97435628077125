import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import ProductRates from '../../components/product-rates';
import { getIsHeapAnalyticsEnabled } from '../../../utils';
import {
  HEAP_LABELS,
  INSURANCE_BUSINESS_TYPES,
  NO,
  QUOTING_STEPS_PAGES_NAME,
  YES,
} from '../../../constants';

const ProductRatesDetails = ({
  appointments,
  onPrevTab,
  goToLastTab,
  goBackToCertainTab,
  tabsCount,
  setTab,
  tabValue,
  quoteType,
  veriskIsLoading,
  redirectToStep,
}: AppComponents.ProductRatesProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled && tabsCount >= 1 && tabsCount === tabValue) {
      let appontmentList: any = !isEmpty(appointments) ? Object.keys(appointments) : [];
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_PAGE, {
        [HEAP_LABELS?.PRODUCT_COUNT]: appontmentList?.length || 0,
        [HEAP_LABELS?.HOME_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.HOME)
          ? YES
          : NO,
        [HEAP_LABELS?.AUTO_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.AUTO)
          ? YES
          : NO,
        [HEAP_LABELS?.FLOOD_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.FLOOD)
          ? YES
          : NO,
        [HEAP_LABELS?.EARTHQUAKE_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.QUAKE)
          ? YES
          : NO,
        [HEAP_LABELS?.PET_QUOTES]: !!appontmentList.includes(INSURANCE_BUSINESS_TYPES.PET)
          ? YES
          : NO,
        [HEAP_LABELS?.HOME_WARRANTY_QUOTES]: !!appontmentList.includes(
          INSURANCE_BUSINESS_TYPES.HOMEWARRANTY
        )
          ? YES
          : NO,
        [HEAP_LABELS?.CYBER_SECURITY_QUOTES]: !!appontmentList.includes(
          INSURANCE_BUSINESS_TYPES.CYBERSECURITY
        )
          ? YES
          : NO,
        [HEAP_LABELS?.LIFE_QUOTES]: !!appontmentList.includes(HEAP_LABELS?.LIFE) ? YES : NO,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className='container'>
      <ProductRates
        veriskIsLoading={veriskIsLoading}
        appointments={appointments}
        onPrevTab={onPrevTab}
        goToLastTab={goToLastTab}
        goBackToCertainTab={goBackToCertainTab}
        tabsCount={tabsCount}
        setTab={setTab}
        tabValue={tabValue}
        quoteType={quoteType}
        redirectToStep={redirectToStep}
      />
    </div>
  );
};

export default ProductRatesDetails;
