import { FormControl, TextField } from '@material-ui/core';
import React from 'react';
import { ASTERISK, NONE } from '../../../../constants';
import { FormFieldProps } from '../form-fields.types';

const TextFieldForm: React.FC<FormFieldProps> = ({
  fieldDetails: { name, label, isDisabled, isRequired, maxLength, fieldType },
  value,
  error,
  disabled,
  onChange,
  onInputBlur,
}) => {
  const commonInputProps = {
    name: name,
    id: name,
    autoFocus: false,
    spellCheck: false,
    maxLength: maxLength || 50,
    autoComplete: NONE.toLowerCase(),
  };
  disabled = disabled || !!isDisabled;

  return (
    <FormControl fullWidth disabled={disabled}>
      <TextField
        label={
          <>
            {label}
            {isRequired && <span className='error-color'>{ASTERISK}</span>}
          </>
        }
        variant='standard'
        type={fieldType}
        value={value}
        onChange={onChange}
        onBlur={() => onInputBlur && onInputBlur(name, value)}
        fullWidth
        InputLabelProps={{ shrink: true }}
        autoComplete='off'
        inputProps={commonInputProps}
        helperText={error}
        error={!!error}
        disabled={!!disabled}
      />
    </FormControl>
  );
};

export default TextFieldForm;
