import React from 'react';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = (props: AppComponents.NumberFormatProps) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({ target: { value: values.value, name: props.name } });
      }}
      allowNegative={false}
      decimalScale={props.decimalScale || 0}
      maxLength={props.maxLength || 8}
      thousandSeparator
      prefix={props.name !== 'approx_miles' ? '$' : ''}
    />
  );
};

export default NumberFormatCustom;
