/* istanbul ignore file */
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { push } from 'connected-react-router';
import * as actions from '../constants';
import {
  authLoaderShow,
  autoLogout,
  clearApplicantState,
  clearDriverInfo,
  clearFloodState,
  clearQuakeState,
  clearHomeState,
  clearInsurenaceDetails,
  clearPetState,
  clearHomeWarrantyState,
  clearCyberSecurityState,
  clearLifeState,
  clearUmbrellaState,
  clearVehiclesList,
  errorHandler,
  fetchPconfig,
  flushAppDetails,
  getAppointments,
  setAdminUser,
  setBulkUser,
  setPartnerDetails,
  setReportUser,
  userSignInSuccess,
  vinCleared,
} from '../actions';
import config from '../../../config/config';
import {
  ADMIN_ROUTE,
  DEFAULT_BULK_QUOTE_PATH,
  DEFAULT_LOB_PATH,
  DEFAULT_REPORT_PATH,
  QUESTIONNAIRE_PATH,
  QUESTIONNAIRE_VIEW_PATH_ROUTE,
  QUOTE_APPLICANTION_VIEW_ROUTE,
} from '../../../constants';
import { getRequestedUrlFromCookie, removeRequestedUrlFromCookie } from '../../../utils';
import axiosRequest from '../../api';
import { TokenVerifyResponse } from '../../../types';
import { isEnabledPRSv2Flow } from '../../../quote-application/utils';

const commonActions: any = actions;
const setInitialData = {
  authUser: null,
  email: '',
  userId: '',
  sessionIndex: '',
};
function* userLogin() {
  try {
    yield put(authLoaderShow(true));
    const { auth } = yield select();
    const { authUser } = auth;
    if (isEmpty(authUser)) {
      const { location } = window;
      if (!isEmpty(location?.href)) {
        const cookies = new Cookies();
        /* eslint-disable-next-line */
        cookies.set('requestedUrl', location.href.replace(/^.*\/\/[^\/]+/, ''), {
          secure: true,
          sameSite: 'none',
          path: '/'
        });
      }
      if (
        [DEFAULT_BULK_QUOTE_PATH, `${DEFAULT_BULK_QUOTE_PATH}/login`].includes(location.pathname)
      ) {
        yield put(setBulkUser(true));
        yield put(setReportUser(false));
        yield put(setAdminUser(false));
        location.assign(config.bulkQuoteLoginUrl);
      } else if (
        [DEFAULT_REPORT_PATH, `${DEFAULT_REPORT_PATH}/login`].includes(location.pathname)
      ) {
        yield put(setReportUser(true));
        yield put(setBulkUser(false));
        yield put(setAdminUser(false));
        location.assign(config.reportLoginUrl);
      } else if ([ADMIN_ROUTE, `${ADMIN_ROUTE}/login`].includes(location.pathname)) {
        yield put(setAdminUser(true));
        yield put(setBulkUser(false));
        yield put(setReportUser(false));
        location.assign(config.adminLogInUrl);
      } else {
        yield put(setBulkUser(false));
        yield put(setReportUser(false));
        yield put(setAdminUser(false));
        location.assign(config.loginUrl);
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(authLoaderShow(false));
}

export function* userLoginWatcher() {
  yield takeEvery(commonActions.USER_LOGIN_PAGE, userLogin);
}

function* logoutCleanup() {
  const {
    auth: { adminUser, bulkUser, reportUser },
  } = yield select();
  const isBulkQuoteRoute =
    bulkUser || window.location.pathname.includes(`${DEFAULT_BULK_QUOTE_PATH}/login`);
  const isReportRoute =
    reportUser || window.location.pathname.includes(`${DEFAULT_REPORT_PATH}/login`);
  const isAdminRoute = adminUser || window.location.pathname.includes(`${ADMIN_ROUTE}/login`);
  yield put(userSignInSuccess(setInitialData));
  yield put(flushAppDetails());
  if (isBulkQuoteRoute) {
    yield put(setBulkUser(true));
    yield put(push(`${DEFAULT_BULK_QUOTE_PATH}/logout`));
  } else if (isReportRoute) {
    yield put(setReportUser(true));
    yield put(push(`${DEFAULT_REPORT_PATH}/logout`));
  } else if (isAdminRoute) {
    yield put(setAdminUser(true));
    yield put(push(`${ADMIN_ROUTE}/logout`));
  } else {
    yield put(push('/logout'));
  }
}

function* signInUser({ payload }: AppRedux.ActionPayload) {
  try {
    yield put(authLoaderShow(true));
    if (isEmpty(payload) || isEmpty(payload.authToken)) {
      yield call(logoutCleanup);
      yield put(authLoaderShow(false));
      return;
    }
    // if it fails will throw an error with 403, will be handled with the catch
    const decoded = (yield axiosRequest('POST', '/token/verify', true, {
      token: payload.authToken,
    })) as TokenVerifyResponse;

    localStorage.setItem('Authorization', payload.authToken);
    axios.defaults.headers.common.Authorization = `Bearer ${payload.authToken}`;

    if (decoded?.data) {
      if (window.location.pathname.includes(`${DEFAULT_BULK_QUOTE_PATH}/login`)) {
        yield put(setBulkUser(true));
        yield put(push(DEFAULT_BULK_QUOTE_PATH));
      } else if (window.location.pathname.includes(`${DEFAULT_REPORT_PATH}/login`)) {
        yield put(setReportUser(true));
        yield put(push(DEFAULT_REPORT_PATH));
      } else if (window.location.pathname.includes(`${ADMIN_ROUTE}/login`)) {
        yield put(setAdminUser(true));
        yield put(push(ADMIN_ROUTE));
      } else {
        yield put(setAdminUser(false));
        yield put(setBulkUser(false));
        yield put(setReportUser(false));
        let requestedUrl = getRequestedUrlFromCookie();
        if (!isEmpty(requestedUrl)) {
          yield put(push(requestedUrl));
          removeRequestedUrlFromCookie();
        } else {
          yield put(push(DEFAULT_LOB_PATH));
        }
      }

      yield put(
        userSignInSuccess({
          authUser: payload.authToken,
          email: decoded.data.email,
          userId: decoded.data.id,
          sessionIndex: decoded.data.sessionIndex,
        })
      );      
    }

  } catch (error) {
    yield call(logoutCleanup);
  }
  yield put(authLoaderShow(false));
}

export function* signInUserWatcher() {
  yield takeEvery(commonActions.SIGNIN_USER, signInUser);
}

function* signOutUser({ payload }: AppRedux.ActionPayload) {
  try {
    const {
      auth: { adminUser, bulkUser, reportUser },
    } = yield select();
    yield put(userSignInSuccess(setInitialData));
    delete axios.defaults.headers.common.Authorization;
    if (!isEmpty(payload)) {
      yield put(authLoaderShow(true));
      let logoutUrl = config.logoutUrl;
      if (bulkUser) {
        logoutUrl = config.bulkQuoteLogoutUrl;
      } else if (reportUser) {
        logoutUrl = config.reportLogoutUrl;
      } else if (!!adminUser) {
        logoutUrl = config.adminLogOutUrl;
      }
      window.location.assign(logoutUrl.concat(`?token=${payload.authUser}`));
    } else {
      yield put(autoLogout(true));
      if (bulkUser) {
        yield put(push(`${DEFAULT_BULK_QUOTE_PATH}/logout`));
      } else if (reportUser) {
        yield put(push(`${DEFAULT_REPORT_PATH}/logout`));
      } else if (!!adminUser) {
        yield put(push(`${ADMIN_ROUTE}/logout`));
      } else {
        yield put(push('/logout'));
      }
    }
  } catch (error) {
    yield put(userSignInSuccess(setInitialData));
    delete axios.defaults.headers.common.Authorization;
    yield put(errorHandler(error));
  }
  localStorage.clear();
}

export function* signOutUserWatcher() {
  yield takeEvery(commonActions.SIGNOUT_USER, signOutUser);
}

function* switchUserApp() {
  const {
    auth: { adminUser, bulkUser, reportUser },
  } = yield select();
  const { location } = window;
  let redirectUrl = config.loginUrl;
  yield put(setBulkUser(false));
  yield put(setReportUser(false));
  yield put(setAdminUser(false));
  if ((!bulkUser || reportUser || adminUser) && location.pathname === DEFAULT_BULK_QUOTE_PATH) {
    redirectUrl = config.bulkQuoteLoginUrl;
    yield put(setBulkUser(!bulkUser));
  } else if ((!reportUser || bulkUser || adminUser) && location.pathname === DEFAULT_REPORT_PATH) {
    redirectUrl = config.reportLoginUrl;
    yield put(setReportUser(!reportUser));
  } else if ((!adminUser || bulkUser || reportUser) && location.pathname === ADMIN_ROUTE) {
    redirectUrl = config.adminLogInUrl;
    yield put(setAdminUser(!adminUser));
  }
  window.location.assign(redirectUrl);
}

export function* switchAppWatcher() {
  yield takeEvery(commonActions.SWITCH_APP, switchUserApp);
}

function* clearQuoteDetails(): any {
  yield all([
    yield put(clearApplicantState()),
    yield put(clearDriverInfo()),
    yield put(clearHomeState()),
    yield put(clearInsurenaceDetails()),
    yield put(clearVehiclesList()),
    yield put(vinCleared()),
    yield put(clearFloodState()),
    yield put(clearQuakeState()),
    yield put(clearPetState()),
    yield put(clearHomeWarrantyState()),
    yield put(clearCyberSecurityState()),
    yield put(clearLifeState()),
    yield put(clearUmbrellaState()),
  ]);
}

function* signInPartner({ payload }: AppRedux.ActionPayload): any {
  try {
    const { hashToken, username, provider, code, requestId, quoteUrl, isProviderAms, role, quoteType } = payload;
    if (hashToken && username && provider) {
      const details = yield axiosRequest(
        'POST',
        '/authorize/provider',
        true,
        {
          username: username,
          password: hashToken,
          provider: provider,
        },
        { provider: 'onelogin' }
      );
      const { authToken } = details.data;
      localStorage.setItem('Authorization', authToken);
      axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
      // use user auth object to store token etc
      yield put(
        userSignInSuccess({
          authUser: authToken,
        })
      );
      yield put(getAppointments({ requestId: requestId, code: code }));
      clearQuoteDetails();
      yield put(fetchPconfig({ code: code, product: 'home' }));
      yield put(
        setPartnerDetails({
          isProviderAms: !!JSON.parse(isProviderAms) ? true : false,
          role: role || '',
          quoteType: quoteType || '',
        })
      );
      if (quoteUrl) {
        if (isEnabledPRSv2Flow()) {
          yield put(push(`${QUOTE_APPLICANTION_VIEW_ROUTE}=${quoteUrl}`));
        } else {
          yield put(push(`${QUESTIONNAIRE_VIEW_PATH_ROUTE}=${quoteUrl}`));
        }
      } else {
        yield put(push(QUESTIONNAIRE_PATH));
      }
    } else {
      yield call(logoutCleanup);
      // todo: maybe show some error
    }
  } catch (error) {
    yield call(logoutCleanup);
  }
}

export function* signInPartnerWatcher() {
  yield takeEvery(commonActions.SIGNIN_PARTNER, signInPartner);
}

export default function* rootSaga() {
  yield all([
    fork(userLoginWatcher),
    fork(signInUserWatcher),
    fork(signOutUserWatcher),
    fork(switchAppWatcher),
    fork(signInPartnerWatcher),
  ]);
}
