/* istanbul ignore file */
import get from 'lodash/get';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import history from '../../../history';
import { inIframe } from '../../../utils';
import { userSignOut } from '../actions';
import * as errorAction from '../constants';

function* errorDetails({ payload }: AppRedux.ActionPayload) {
  if (get(payload, 'response.status', 0) === 401) {
    yield put(userSignOut());
  } else {
    if (inIframe() && get(payload, 'response.status', 0) !== 404) {
      history.push('/error');
    }
  }
}

export function* errorDetailsWatcher() {
  yield takeEvery(errorAction.ERROR_OCCURRED as any, errorDetails);
}

export default function* rootSaga() {
  yield all([fork(errorDetailsWatcher)]);
}
