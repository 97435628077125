import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { isEmpty, startCase } from 'lodash';
import React from 'react';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { FieldOption, FormFieldProps } from '../form-fields.types';
import { ASTERISK, NONE, PROPERTY_PREFILL_MESSAGE, SELECT } from '../../../../constants';
import './select-field.scss';

const SelectField: React.FC<FormFieldProps> = ({
  fieldDetails: { name, label, isDisabled, isRequired, maxLength, options },
  value,
  error,
  disabled,
  onChange,
  onInputBlur,
  showDefaultIcon,
}) => {
  const optionList = options || [];
  const commonProps = {
    name: name,
    id: name,
    autoFocus: false,
    spellCheck: false,
    maxLength: maxLength || 50,
    autoComplete: NONE.toLowerCase(),
  };
  disabled = disabled || !!isDisabled;

  return (
    <FormControl
      fullWidth
      key={name}
      disabled={disabled}
      error={!!error}
      className='select-field-form-contron'
    >
      <InputLabel shrink>
        {label}
        {isRequired && <span className='error-color'>{ASTERISK}</span>}
        {!!showDefaultIcon && (
          <span title={PROPERTY_PREFILL_MESSAGE.DEFAULT_ICON_TEXT}>
            <AddCircleOutlineOutlinedIcon className='property-info-default-icon' />
          </span>
        )}
      </InputLabel>
      <Select
        name={name}
        value={value || SELECT}
        style={{ textAlign: 'left' }}
        onChange={onChange}
        onBlur={() => onInputBlur && onInputBlur(name, value)}
        inputProps={commonProps}
      >
        <MenuItem disabled value={SELECT} key={startCase(SELECT)}>
          {startCase(SELECT)}
        </MenuItem>
        {!isEmpty(optionList) &&
          optionList.map((item: FieldOption, i: number) => (
            <MenuItem value={item.value} key={i}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
