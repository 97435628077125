import { Typography } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { isEmpty, startCase } from 'lodash';
import React, { useState } from 'react';
import {
  CANCEL,
  CARRIER,
  COMING_SOON,
  DIC_POLICY,
  FLOOD_PENALTY,
  HEAP_LABELS,
  HOME,
  INACTIVE,
  LOB_PRODUCTS_MAPPING,
  PREMIUM,
  QUOTING_STEPS_PAGES_NAME,
  VIEW_LESS,
  VIEW_MORE,
} from '../../../constants';
import { HomeQuote } from '../../../graphql/generated';
import ConfirmationModal from '../../../pages/components/confirmation-modal';
import { AdmittedOrNonAdmittedDetails } from '../../../pages/components/rate-page-common-component';
import ThemesImages from '../../../pages/components/themes-images';
import { trackCarrierBridging } from '../../../pages/redux/actions';
import { useAppDispatch } from '../../../pages/redux/hooks';
import {
  checkForHomeLobNonAdmittedCarrier,
  formatNumberToCurrency,
  getIsHeapAnalyticsEnabled,
  getThemeType,
  trackMoreDetails,
} from '../../../utils';
import { useOrganizationQuery } from '../../redux/apis/organization/api';
import '../product-rates/products-rates.scss';
import { HomeRatesBlockProps } from './types/home-quote.type';
import ViewMore from './view-more';
import {
  ErrorQuoteCard,
  ErrorViewMoreCard,
  UpcomingCarrierDetails,
} from '../rate-page-common-component';
import { getPrsv2ErrorCategory, getPurchaseType } from '../../utils';
import { LineOfBusiness } from '../../enums';

const RatesBlock: React.FC<HomeRatesBlockProps> = ({
  quote,
  appointmentKeys,
  errorQuotes,
  successQuotes,
  rank,
}) => {
  const dispatch = useAppDispatch();
  let themeType = getThemeType();
  let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
  const [viewMore, setViewMore] = useState(false);
  const [isOpenNonAdmittedConfirmationModal, setIsOpenNonAdmittedConfirmationModal] =
    useState<boolean>(false);
  const isAdmitted: boolean | undefined = checkForHomeLobNonAdmittedCarrier(quote);
  const carrierId = quote.carrierId;
  const carrierName = quote.carrierName;
  const { data: { organization } = {} } = useOrganizationQuery();

  const bindHandler = (quote: HomeQuote) => {
    const agentUrl = quote.data?.agentUrl ?? quote.data?.link;
    window.open(agentUrl ?? '_blank');
  };

  const getPurchaseIcon = () => {
    if (parseInt(carrierId) === 1) {
      return ThemesImages[themeType].creditCard;
    }
    return ThemesImages[themeType].bridgeIcon;
  };

  const getHomeQuotePrice = () => (
    <>
      {quote.data?.dicPresent === DIC_POLICY.YES && (
        <Typography className='dic-polices'>{DIC_POLICY.LABEL}</Typography>
      )}
      <Typography className='price-label'>Price</Typography>
      <Typography className='price-amount'>{formatNumberToCurrency(quote?.data?.price)}</Typography>
      <Typography className='price-label'>Annually</Typography>
    </>
  );

  const checkIfQuoteButtonIsActiveAndProceed = () => {
    let trackingPayload = {
      carrierId,
      carrierName,
      premium: quote.data?.price?.toString(),
    };
    const lob = LOB_PRODUCTS_MAPPING.home;
    if (quote.data?.agentUrl) {
      bindHandler(quote);
    }

    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.RATES_BRIDGE_TO_CARRIER, {
        [HEAP_LABELS?.PRODUCT]: !isEmpty(lob) ? startCase(lob?.toLowerCase()) : '',
        [HEAP_LABELS.NO_OF_PRODUCT_QUOTES]: successQuotes?.length ?? 0,
        [HEAP_LABELS.NO_OF_PRODUCT_NON_RETURN_QUOTE]: errorQuotes?.length ?? 0,
        [PREMIUM]: quote.data?.price ? quote.data?.price?.toString() : null,
        [HEAP_LABELS.RESULT_RANK]: rank,
        [HEAP_LABELS.PREMIUM_RANK]: rank,
        [CARRIER]: carrierName,
        [HEAP_LABELS?.PRODUCT_COUNT]: appointmentKeys?.length,
      });
    }
    dispatch(trackCarrierBridging({ ...trackingPayload }));
  };

  const onBridgeToCarrierHandler = () => {
    if (isAdmitted !== undefined && !isAdmitted) {
      setIsOpenNonAdmittedConfirmationModal(true);
    } else {
      return onClickPurchaseLink();
    }
  };

  const onClickPurchaseLink = () => {
    if (quote.data?.link) {
      return bindHandler(quote);
    }
    return checkIfQuoteButtonIsActiveAndProceed();
  };

  const getQuotePurchaseButtonElement = () => {
    return (
      <>
        {/* eslint-disable-next-line */}
        <a onClick={() => onBridgeToCarrierHandler()}>
          <img
            src={getPurchaseIcon()}
            alt='credit card'
            className={`mb-1 cursor-pointer img-fluid carrier-img pointer`}
          />
          <Typography className='cursor-pointer purchase-online-label pointer'>
            {getPurchaseType(quote)}
          </Typography>
        </a>
      </>
    );
  };

  const openViewMore = () => {
    setViewMore(!viewMore);
    if (isHeapAnalyticsEnabled) {
      trackMoreDetails(HOME, quote);
    }
  };

  const nonAdmittedConfirmationHandler = () => {
    setIsOpenNonAdmittedConfirmationModal(false);
    onClickPurchaseLink();
  };
  const errorCategory: string = getPrsv2ErrorCategory(quote);
  const statusText: string = quote?.error?.statusText || '';
  const isUpcomingError = [COMING_SOON, INACTIVE].includes(statusText);
  return (
    <div className={'wrapper-rates-block-inner w-100'}>
      <div
        className={`w-100 rates-block-inner bg-light-green d-flex align-items-center rates-block-inner__height ${
          parseInt(carrierId) === 1 ? 'bg-light-green' : 'bg-light-blue'
        }`}
      >
        <div className='row m-0 w-100'>
          <div className='product-logo-div mb-20 d-flex justify-content-center align-items-center col-lg-4 flex-column'>
            <AdmittedOrNonAdmittedDetails quote={quote} lob={HOME} />
            <div className='carrier-logo-div'>
              <img
                src={quote?.logoUrl}
                alt={carrierName}
                title={carrierName}
                className='img-fluid'
              />
            </div>
          </div>
          {!quote.error ? (
            <>
              <div className='col-lg-4 text-center price-wraper'>
                <div className='center-div price-list-wrap'>{getHomeQuotePrice()}</div>
              </div>
              <div className={`col-lg-4 mb-20 text-center purchaseButtonFlood`}>
                <div className='d-flex align-items-center purchase-button-height justify-content-center'>
                  {getQuotePurchaseButtonElement()}
                </div>
              </div>
            </>
          ) : (
            <>
              {!!isUpcomingError ? (
                <UpcomingCarrierDetails quote={quote} lob={LineOfBusiness.HOME} />
              ) : (
                <div className='col-lg-8 mb-20 justify-content-center price-wraper d-flex align-items-center'>
                  <ErrorQuoteCard statusText={statusText} errorCategory={errorCategory} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!quote.error && (
        <div className='row w-100 mx-auto view-more-button-div'>
          <div className='col-lg-4'>
            <div className='m-display'></div>
          </div>
          <div className='d-md-flex col-lg-8'>
            <div
              className={`view-more-item resposive-mt-2 ${
                organization?.enableHomeQuoteDocDownload
                  ? 'view-more-item-responsive'
                  : 'w-100 d-flex display-view'
              }`}
            >
              <Typography className='label-view-more mt-2' onClick={() => openViewMore()}>
                {viewMore ? (
                  <>
                    <Remove className='mr-2 add-icon' />
                    {VIEW_LESS}
                  </>
                ) : (
                  <>
                    <Add className='mr-2 add-icon' />
                    {VIEW_MORE}
                  </>
                )}
              </Typography>
            </div>
          </div>
        </div>
      )}
      {viewMore && !quote.error && (
        <div className='row m-0'>
          <div className='col-lg-12 view-more-section pl-0 pr-0'>
            <ViewMore quote={quote} />
          </div>
        </div>
      )}
      {/* Error quote view more card */}
      {!isEmpty(errorCategory) && (
        <ErrorViewMoreCard statusText={statusText} errorCategory={errorCategory} />
      )}
      <ConfirmationModal
        open={isOpenNonAdmittedConfirmationModal}
        closehandler={(isDiscard: boolean) => {
          !isDiscard
            ? setIsOpenNonAdmittedConfirmationModal(false)
            : nonAdmittedConfirmationHandler();
        }}
        isHideHeader
        message={
          <Typography className='non-admitted-confirmation-text'>
            {FLOOD_PENALTY.NON_ADMITTED_CONFIRMATION_TEXT}
          </Typography>
        }
        contentValue=''
        noText={CANCEL}
        yesText={FLOOD_PENALTY.NON_ADMITTED_CONFIRMATION_BUTTON_TEXT}
      />
    </div>
  );
};

export default RatesBlock;
