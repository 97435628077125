export enum PrefillStatus {
  FAILED = 'failed',
  INCOMPLETE = 'incomplete',
  SUCCESS = 'success',
}

export enum BasementFinishType {
  FINISHED = "Finished",
  UNFINISHED = "Unfinished",
  PARTIALLY_FINISHED = "Partially Finished"
}
