/* istanbul ignore file */
import * as types from '../constants';

interface AuthState {
  loader: boolean;
  authUser: string;
  userId: string;
  email: string;
  sessionIndex: string;
  autoLogout: boolean;
  bulkUser: boolean;
  reportUser: boolean;
  adminUser: boolean;
}

const AUTH_INIT_STATE: AuthState = {
  loader: false,
  authUser: '',
  userId: '',
  email: '',
  sessionIndex: '',
  autoLogout: false,
  bulkUser: false,
  reportUser: false,
  adminUser: false,
};

export default (state = AUTH_INIT_STATE, action: AppRedux.Action): AuthState => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
      };
    }

    case types.ON_SHOW_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case types.SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loader: false,
      };
    }

    case types.AUTO_LOGOUT: {
      return {
        ...state,
        autoLogout: action.payload,
      };
    }

    case types.SET_BULK_USER: {
      return {
        ...state,
        bulkUser: action.payload,
      };
    }

    case types.SET_REPORT_USER: {
      return {
        ...state,
        reportUser: action.payload,
      };
    }

    case types.SET_ADMIN_UESR: {
      return {
        ...state,
        adminUser: action.payload,
      };
    }

    default:
      return state;
  }
};
