import { isEmpty } from "lodash";
import { INSURANCE_PRODUCTS_MAPPING } from "../../../../constants";
import { OrganizationLobType } from "../../../../pages/redux/reducers/home";


export const mapOrganizationLOB = (lobData: any): OrganizationLobType => {
  const transformed: OrganizationLobType = {};
  !isEmpty(lobData) &&
    Object.keys(lobData).forEach((originalKey: string) => {
      const originalValue = lobData[originalKey];
      const mappedKey: string =
        INSURANCE_PRODUCTS_MAPPING[originalKey.toUpperCase() as keyof typeof INSURANCE_PRODUCTS_MAPPING] ||
        originalKey.toUpperCase();
      if (typeof originalValue === 'object' && originalValue !== null) {
        const transformedValue: any = {};
        Object.keys(originalValue).forEach((nestedKey: string) => {
          if (originalValue[nestedKey] === true) {
            transformedValue[nestedKey.toUpperCase()] = true;
          }
        });
        transformed[mappedKey] = transformedValue;
      } else {
        if (originalValue === true) {
          transformed[mappedKey] = true;
        }
      }
    });
  return transformed;
};
