import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
  Typography,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { EOI_FILE, FILE_TYPE, PDF_TYPE, SM, UPLOAD_FILE } from '../../../constants';
import './upload-modal.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    input: {
      display: 'none',
    },
  })
);

const UploadFile = (props: AppComponents.UploadFileProps) => {
  const classes = useStyles();
  const { open } = props;
  const onCloseModal = () => {
    props.onClose(false);
  };
  const onDragLeave = (e: any) => {
    e.preventDefault();
  };

  const onDragEnter = (e: any) => {
    e.preventDefault();
  };

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  return (
    <div className='text-center'>
      <Dialog
        maxWidth={SM}
        className='upload-eoi-modal'
        onClose={onCloseModal}
        aria-labelledby='customized-dialog-title'
        disableBackdropClick={true}
        open={open}
      >
        <DialogTitle id='form-dialog-title'>
          <Typography className='eoi-heading'>
            {!!props?.uploadTitle ? props?.uploadTitle : ''}
          </Typography>
          <Divider />
        </DialogTitle>
        <DialogContent className='text-center m-5 d-flex justify-content-center align-items-center'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <div
                className='upload-div d-flex justify-content-center align-items-center dropzone'
                onDragOver={e => onDragOver(e)}
                onDragEnter={e => onDragEnter(e)}
                onDragLeave={e => onDragLeave(e)}
                onDrop={event => props.onDrop(event)}
              >
                <input
                  id='icon-button-file'
                  className={`${classes.input} draggable-container`}
                  type={FILE_TYPE}
                  name={EOI_FILE}
                  accept={PDF_TYPE}
                  multiple={false}
                  onChange={event => props.onUploadChanges(event)}
                  disabled={props?.loader}
                />
                <label className="text-center" htmlFor='icon-button-file'>
                  <IconButton color='primary' aria-label='upload picture' component='span'>
                    <PublishIcon className='icon' />
                  </IconButton>
                  <Typography className='eoi-file-name-label'>{UPLOAD_FILE}</Typography>
                </label>
              </div>
            </div>
            <div className='col-lg-12  mt-3'>
              <div className='d-flex justify-content-center'>
                <Typography className='eoi-file-name-label text-center'>
                  {!!props?.fileName ? props?.fileName : ''}
                </Typography>
              </div>
              <FormHelperText className='text-red text-center'>
                {!!props?.fileTypeError ? props?.fileTypeError : ''}
              </FormHelperText>
            </div>
          </div>
        </DialogContent>
        <DialogActions className='d-flex justify-content-center'>
          <Button
            variant='outlined'
            className='cancel-btn mb-5'
            onClick={onCloseModal}
            disabled={props?.loader}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadFile;
