import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import has from 'lodash/has';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { goToDashboard } from '../../../utils';
import './home-quote-legend.scss';

const isShowLegends = (props: any) => (has(props, 'showLegends') ? props.showLegends : true);

const HomeQuoteLegend = (props: any) => {
  const goToDashboardAndScroll = () => {
    goToDashboard();
  }

  return (
    <React.Fragment>
      <div className={`legend-wrapper col-lg-12 ${props.styleClasses}`}>
        <Typography
          className='mt-4 dashboard-label'
          onClick={goToDashboardAndScroll}
        >
          <ArrowBackIcon className='mr-2' />
          <span>Dashboard</span>
        </Typography>
      </div>

      {isShowLegends(props) && (
        <div className='col-lg-12 product-listing'>
          <Typography className='mr-3'>
            HOME<span className='ml-2 dot-home'></span>
          </Typography>
          <Typography className='mr-3'>
            FLOOD<span className='ml-2 dot-flood'></span>
          </Typography>
        </div>
      )}
    </React.Fragment>
  );
};

HomeQuoteLegend.propTypes = {
  showLegends: PropTypes.bool,
  styleClasses: PropTypes.string,
};

export default React.memo(HomeQuoteLegend);
