import { HomeApplicantInput, MaritalStatus, ProductName } from '../../../../../graphql/generated';
import store from '../../../../../pages/redux/store';
import { formatDate } from '../../../../../utils';
import { bffEducationToGator } from './education.mapper';
import { bffIndustryToGator } from './industry.mapper';
import { bffOccupationToGator } from './occupation.mapper';

export const mapPersonalDetails = (applicant: HomeApplicantInput, productName: ProductName) => {
  const { applicant: applicantDetails } = store.getState();
  const { personal_details } = applicantDetails;
  const dob = applicant.dateOfBirth
    ? formatDate(applicant.dateOfBirth)
    : personal_details?.date_of_birth;
  const industry = applicant.industry
    ? bffIndustryToGator[applicant.industry]
    : personal_details?.industry;
  const occupation = applicant.occupation
    ? bffOccupationToGator[applicant.occupation]
    : personal_details?.occupation;
  const education = applicant.education
    ? bffEducationToGator[applicant.education]
    : personal_details?.education;
  const product = productName ? productName?.toUpperCase() : personal_details?.product;
  return {
    first_name: applicant.firstName || personal_details?.first_name,
    middle_name: applicant.middleName || personal_details?.middle_name,
    last_name: applicant.lastName || personal_details?.last_name,
    date_of_birth: dob,
    phone_number: applicant.phone || personal_details?.phone_number,
    phone: applicant.phone || personal_details?.phone,
    email: applicant.email || personal_details?.email,
    residenceType: personal_details.residenceType,
    preferredDeductible: personal_details.preferredDeductible,
    industry,
    occupation,
    education,
    product,
    maritalStatus: applicant?.maritalStatus || MaritalStatus.Single,
  };
};
