/* istanbul ignore file */
import { filter, find, findIndex, forEach, isEmpty, omit, uniq } from 'lodash';
import values from 'lodash/values';
import { all, call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  addDriver,
  assignSpouse,
  deleteDetailsLoader,
  deleteDriverDetailsSuccess,
  driverInit,
  driverSelectedForEdit,
  errorHandler,
  setDriverLoader,
  setEditVehicleFlag,
  setFetchAutoQuotes,
  setGoToRatesTabFromPolicy,
  setMissingDetailsDrivers,
  setMissingSpouse,
  setPolicyFlag,
  setValidDriverFlag,
  stepChanged,
  toggleDriverIncludedSuccess,
  updateDriverList,
  vehicleSelect,
} from '../actions';
import * as actions from '../constants';
import getIncidentsErrors from './incidents';

const driverActions: any = actions;

const getIncidentMissingDriver = (incidentList: Array<any>, driver: any): Array<any> => {
  const detailsMissingDriverIds: Array<any> = [];
  let isAccidentDetailsMissing = false;
  forEach(incidentList, accident => {
    if (values(accident).some(isEmpty)) {
      isAccidentDetailsMissing = true;
    }
    isAccidentDetailsMissing && detailsMissingDriverIds.push(driver.duid);
  });
  return detailsMissingDriverIds;
};

function* addNewDriver({ payload }: AppRedux.ActionPayload) {
  yield put(setDriverLoader(true));
  try {
    const { hasSpouse, isSpouse, isSpouseAdded, claims, violations, losses, isPrimaryDriver } =
      payload;
    const { driver, common } = yield select();

    const driverHasClaims = () => {
      let hasClaims = true;
      if (claims === 'no' && violations === 'no' && losses === 'no') {
        hasClaims = false;
      }
      return hasClaims;
    };

    const driverHasSpouseAdded = () => {
      let hasSpouseBeenAdded = true;
      if (hasSpouse && !isSpouse && !isSpouseAdded) {
        hasSpouseBeenAdded = false;
      }
      return hasSpouseBeenAdded;
    };

    const shouldAddSpouse = [isPrimaryDriver, !driverHasClaims(), !driverHasSpouseAdded()].every(
      Boolean
    );

    const spouseAdded = () => {
      let isSpouseAddedForThisDriver = isSpouseAdded ? isSpouseAdded : shouldAddSpouse;
      if (driver.driversList && driver.driversList.length > 1) {
        const primaryDriver = driver.driversList.find((item: any) => item.isPrimaryDriver);
        if (common.isVeriskFetched && isPrimaryDriver && !primaryDriver.isSpouseAdded) {
          isSpouseAddedForThisDriver = false;
        }
      }

      return isSpouseAddedForThisDriver;
    };
    yield put(
      updateDriverList({
        ...payload,
        isPrimaryDriver,
        isSpouseAdded: spouseAdded(),
      })
    );
    if (payload.clear) {
      yield put(
        driverInit({
          ...payload,
          isPrimaryDriver,
          isSpouseAdded: spouseAdded(),
        })
      );
      const { driver: driverData } = yield select();
      if (payload.hasSpouse && driverData.hasSpouse) {
        yield put(
          assignSpouse({
            driverId: payload.duid,
            spouseId: driverData.duid,
          } as any)
        );
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setDriverLoader(false));
}

export function* addNewDriverWatcher() {
  yield takeEvery(driverActions.ADD_DRIVER, addNewDriver);
}

// TODO: Delete if no needed after "include/exclude switch"
function* deleteDriverDetails({ payload: { duid } }: AppRedux.ActionPayload) {
  yield put(deleteDetailsLoader(true));
  try {
    yield put(deleteDriverDetailsSuccess(duid));
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(deleteDetailsLoader(false));
}

export function* deleteDriverDetailsWatcher() {
  yield takeEvery(driverActions.DELETE_DRIVER_DETAILS, deleteDriverDetails);
}

function* toggleDriverIncludedStatus({ payload: { duid } }: AppRedux.ActionPayload) {
  try {
    yield put(toggleDriverIncludedSuccess(duid));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* toggleDriverIncludedStatusWatcher() {
  yield takeEvery(driverActions.TOGGLE_DRIVER_INCLUDED_STATUS, toggleDriverIncludedStatus);
}

function* saveDriverBeforeEdit({ payload }: AppRedux.ActionPayload) {
  try {
    yield put(addDriver({ ...payload }));
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* saveDriverBeforeEditWatcher() {
  yield takeEvery(driverActions.SAVE_DRIVER_BEFORE_EDIT, saveDriverBeforeEdit);
}

function* addDriverFromOtherDetails({ payload }: AppRedux.ActionPayload): any {
  try {
    const { otherDetails } = yield select();
    const { accidentList, violationList, lossList } = otherDetails;
    const incidentsErrors: any = yield getIncidentsErrors(accidentList, violationList, lossList);
    if (
      isEmpty(incidentsErrors.errorList) &&
      isEmpty(incidentsErrors.errorViolationList) &&
      isEmpty(incidentsErrors.errorLossList)
    ) {
      yield put(
        addDriver({
          ...omit(payload, ['entity', 'detailsErrors', 'driversList']),
          clear: true, // Indicates to add new spouse with new data
          accidentList,
          violationList,
          lossList,
        })
      );
      // todo: need to go to the driver tab
      // yield put(push('driver'));
    }
    // Creating throttle for state update for component
    yield delay(50);
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* addDriverFromOtherDetailsWatcher() {
  yield takeEvery(driverActions.ADD_DRIVER_FROM_OTHERDETAILS, addDriverFromOtherDetails);
}

const checkIncidentValues = (
  claims: any,
  accidentList: Array<any>,
  violations: any,
  violationList: Array<any>,
  losses: any,
  lossList: Array<any>
) =>
  (claims === 'yes' && accidentList.length === 0) ||
  (violations === 'yes' && violationList.length === 0) ||
  (losses === 'yes' && lossList.length === 0);

const getMissingDrivers = (driversList: Array<any>) => {
  const missingDriverIds: Array<any> = [];
  let firstMissingDriver: any = null;
  forEach(driversList, driverData => {
    if (driverData.isIncluded) {
      const { accidentList, claims, losses, lossList, violations, violationList, duid } =
        driverData;
      if (checkIncidentValues(claims, accidentList, violations, violationList, losses, lossList)) {
        missingDriverIds.push(duid);
        if (isEmpty(firstMissingDriver)) {
          firstMissingDriver = { ...driverData };
        }
      } else {
        accidentList.length > 0 &&
          missingDriverIds.push(...getIncidentMissingDriver(accidentList, driverData));
        violationList.length > 0 &&
          missingDriverIds.push(...getIncidentMissingDriver(violationList, driverData));
        lossList.length > 0 &&
          missingDriverIds.push(...getIncidentMissingDriver(lossList, driverData));
        if (isEmpty(firstMissingDriver) && missingDriverIds.length > 0) {
          firstMissingDriver = { ...driverData };
        }
      }
    }
  });
  return {
    missingDriverIds,
    firstMissingDriver,
  };
};

function* checkDriverOtherDetails(
  otherDetailsMissingDriverIds: Array<any>,
  firstMissingInfoDriver: any,
  driversList: Array<any>,
  payload: any
) {
  if (isEmpty(otherDetailsMissingDriverIds)) {
    yield put(setMissingDetailsDrivers([] as any));
    if (payload.lastDriver) {
      yield put(stepChanged({ entity: 'quotes', value: true } as any));
      yield put(setGoToRatesTabFromPolicy(true));
      // todo: should be configured to move to the last tab not page
      yield put(setFetchAutoQuotes(true));
      // yield put(push('quotes'));
    } else {
      yield put(stepChanged({ entity: 'details', value: true } as any));
      // todo: go to next step
      yield put(setPolicyFlag(true));
      // yield put(push('details'));
    }
  } else {
    const driverNames: Array<any> = [];
    otherDetailsMissingDriverIds = uniq(otherDetailsMissingDriverIds);
    filter(driversList, driverData => {
      if (otherDetailsMissingDriverIds.includes(driverData.duid)) {
        driverNames.push({
          duid: driverData.duid,
          full_name: `${driverData.first_name} ${driverData.last_name}`,
        });
      }
    });
    yield put(setMissingDetailsDrivers(driverNames as any));
    yield put(driverSelectedForEdit(firstMissingInfoDriver.duid));
    if (payload.entity === 'otherDetails') {
      // todo: go to the driver tab
      // yield put(push('driver'));
    }
  }
}

const getPrimaryDriverDetails = (driversList: Array<any>, index: any) =>
  driversList[index].isPrimaryDriver &&
  driversList[index].hasSpouse &&
  !driversList[index].isSpouseAdded &&
  driversList[index].marital_status === 'Married'
    ? { ...driversList[index] }
    : {};

function* validateDriverDetails(driversList: Array<any>, primaryDriverIndex: number, payload: any) {
  const validLicenseDriver = find(driversList, ['us_license_status', 'Valid']);
  if (!isEmpty(validLicenseDriver)) {
    const primaryDriver = getPrimaryDriverDetails(driversList, primaryDriverIndex);
    if (!isEmpty(primaryDriver)) {
      yield put(setMissingSpouse(true));
      yield put(driverSelectedForEdit(driversList[primaryDriverIndex].duid));
      if (payload.entity === 'otherDetails') {
        // todo" go to the driver tab
        // yield put(push('driver'));
      }
    } else {
      yield put(setMissingSpouse(false));
      const { firstMissingDriver, missingDriverIds } = getMissingDrivers(driversList);
      yield call(
        checkDriverOtherDetails,
        missingDriverIds,
        firstMissingDriver,
        driversList,
        payload
      );
    }
  } else {
    if (payload.lastDriver && payload.entity === 'otherDetails') {
      // yield put(push('driver'));
    }
    yield put(setValidDriverFlag(false));
  }
}

function* driverContinueHandler({ payload }: AppRedux.ActionPayload) {
  try {
    const { driver } = yield select();
    const { driversList } = driver;
    const primaryDriverIndex = 0;
    let currentDriverIndex: number = findIndex(driversList, { duid: driver.duid });
    if (payload.lastDriver) {
      currentDriverIndex = driversList.length - 1;
    }
    if (currentDriverIndex < driversList.length - 1) {
      const nextDriverIndex = currentDriverIndex + 1;
      yield put(driverSelectedForEdit(driversList[nextDriverIndex].duid));
      if (payload.entity === 'otherDetails') {
        // yield put(push('driver'));
      }
    } else if (currentDriverIndex === driversList.length - 1) {
      yield call(validateDriverDetails, driversList, primaryDriverIndex, payload);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* driverContinueWatcher() {
  yield takeEvery(driverActions.DRIVER_CONTINUE, driverContinueHandler);
}

function* driverBackHandler() {
  try {
    const { driver, vehicle } = yield select();
    const { driversList } = driver;
    const currentDriverIndex = findIndex(driversList, { duid: driver.duid });
    if (currentDriverIndex > 0) {
      const nextDriverIndex = currentDriverIndex - 1;
      yield put(driverSelectedForEdit(driversList[nextDriverIndex].duid));
    } else {
      if (vehicle.vehiclesList.length > 1) {
        yield put(vehicleSelect(vehicle.vehiclesList[vehicle.vehiclesList.length - 1].vuid));
      }
      if (driversList.length > 0 && currentDriverIndex === -1) {
        yield put(driverSelectedForEdit(driversList[driversList.length - 1].duid));
      } else {
        yield put(setEditVehicleFlag(true));
        // yield put(push('vehicle'));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* driverBackWatcher() {
  yield takeEvery(driverActions.DRIVER_BACK, driverBackHandler);
}

export default function* rootSaga() {
  yield all([
    fork(addNewDriverWatcher),
    fork(deleteDriverDetailsWatcher),
    fork(saveDriverBeforeEditWatcher),
    fork(addDriverFromOtherDetailsWatcher),
    fork(driverContinueWatcher),
    fork(driverBackWatcher),
    fork(toggleDriverIncludedStatusWatcher),
  ]);
}
