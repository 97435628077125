import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import { Theme, withStyles } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons';
import LobLoader from '../lob-loader';
import UmbrellaFormCompoment from './umbrella-form';
import {
  fetchUmbrellaQuestionListInfo,
  fetchUmbrellaQuotes,
  stopUmbrellaPolling,
  storeUmbrellaQuoteDetails,
  updateRequoteProducts,
} from '../../redux/actions';
import {
  allowEditingQuote,
  getIsHeapAnalyticsEnabled,
  getNestedTernaryData,
  hideStepperBackButton,
  removeExistingRate,
} from '../../../utils';
import {
  getMappedQuestionnaireResponses,
  getUniqueKeyForQuestionnaire,
  validateUmbrellaDropdown,
} from '../../../utils/umbrella';
import {
  BACK,
  CONTINUE,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  QUOTING_STEPS_PAGES_NAME,
  REQUIRED,
  UMBRELLA,
} from '../../../constants';
import { UMBRELLA_QUESTION_FIELD_TYPES } from '../../../constants/umbrella-questionnaire';
const { DROPDOWN } = UMBRELLA_QUESTION_FIELD_TYPES;

const styles = (_theme: Theme) => ({
  root: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  submitSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
});

const Umbrella = (props: any) => {
  const {
    applicant,
    common,
    currentIndex,
    onPrevTab,
    goToLastTab,
    umbrellaDetails,
  }: AppComponents.UmbrellaProps = props;
  const [questionnaireSet, setQuestionnaireSet] =
    useState<AppComponents.UmbrellaQuestionnaireSet>();
  const [isQuestionGroupsMapped, setQuestionGroupsMapped] = useState<boolean>(false);
  const [errorQuestionKeys, setErrorQuestionKeys] = useState<any>([]);
  const disableUmbrellaEdit = allowEditingQuote();

  useEffect(() => {
    let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
    if (!!isHeapAnalyticsEnabled) {
      window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.UMBRELLA_INFO);
    }
    if (
      !isEmpty(umbrellaDetails?.herdId) &&
      isEmpty(umbrellaDetails?.umbrellaInfo?.questionGroups) &&
      !applicant?.saveApplicantLoader &&
      !umbrellaDetails?.isQuestionError &&
      !umbrellaDetails?.umbrellaInfoLoader
    ) {
      props.fetchUmbrellaQuestionListInfo();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const { saveApplicantLoader } = applicant;
    const { umbrellaInfoLoader } = umbrellaDetails;
    if (!!umbrellaDetails?.isQuestionError && !umbrellaInfoLoader && !saveApplicantLoader) {
      goToLastTab(currentIndex);
      props.storeUmbrellaQuoteDetails({ isQuestionError: false });
    }
    // eslint-disable-next-line
  }, [
    applicant.saveApplicantLoader,
    umbrellaDetails.isQuestionError,
    umbrellaDetails.umbrellaInfo,
    umbrellaDetails.umbrellaInfoLoader,
  ]);

  useEffect(() => {
    const { umbrellaInfo } = umbrellaDetails;
    if (!!umbrellaInfo && !isEmpty(umbrellaInfo)) {
      setQuestionnaireSet(umbrellaInfo);
    }
    // eslint-disable-next-line
  }, [umbrellaDetails.umbrellaInfo]);

  const onBackClick = () => {
    onPrevTab(currentIndex);
  };

  const validate = () => {
    let isValied: boolean = true;
    setErrorQuestionKeys([]);
    if (!!questionnaireSet && !isEmpty(questionnaireSet)) {
      let tempQuestionGroups: Array<AppComponents.UmbrellaQuestionnaireGroup> =
        questionnaireSet.questionGroups;
      tempQuestionGroups.forEach((group, groupIndex) => {
        if (group?.repeatable) {
          group?.questions.forEach((repeatableQuestionSet, repeatableQuestionSetIndex) => {
            repeatableQuestionSet?.repeatableQuestions.forEach(
              (question: any, questionIndex: any) => {
                if (
                  question?.required &&
                  (!question?.response ||
                    (question.type === DROPDOWN && validateUmbrellaDropdown(question?.response)))
                ) {
                  isValied = false;
                  tempQuestionGroups[groupIndex].questions[
                    repeatableQuestionSetIndex
                  ].repeatableQuestions[questionIndex].validationErrorMessage = REQUIRED;
                  tempQuestionGroups[groupIndex].questions[
                    repeatableQuestionSetIndex
                  ].repeatableQuestions[questionIndex].onFocus = true;
                  setErrorQuestionKeys((prevState: any) => [
                    ...prevState,
                    getUniqueKeyForQuestionnaire(question),
                  ]);
                } else {
                  tempQuestionGroups[groupIndex].questions[
                    repeatableQuestionSetIndex
                  ].repeatableQuestions[questionIndex].validationErrorMessage = '';
                }
              }
            );
          });
        } else {
          group?.questions.forEach((question, questionIndex) => {
            if (
              question?.required &&
              (!question?.response ||
                (question.type === DROPDOWN && validateUmbrellaDropdown(question?.response)))
            ) {
              isValied = false;
              tempQuestionGroups[groupIndex].questions[questionIndex].validationErrorMessage =
                REQUIRED;
              tempQuestionGroups[groupIndex].questions[questionIndex].onFocus = true;
              setErrorQuestionKeys((prevState: any) => [
                ...prevState,
                getUniqueKeyForQuestionnaire(question),
              ]);
            } else {
              tempQuestionGroups[groupIndex].questions[questionIndex].validationErrorMessage = '';
            }
          });
        }
        if (!isEmpty(group?.subGroups)) {
          group?.subGroups?.forEach((subGroup, subGroupIndex) => {
            if (subGroup?.repeatable) {
              subGroup?.questions.forEach((repeatableQuestionSet, repeatableQuestionSetIndex) => {
                repeatableQuestionSet?.repeatableQuestions.forEach(
                  (quest: any, questIndex: any) => {
                    const tempSubGroups = tempQuestionGroups[groupIndex]?.subGroups;
                    if (!!tempSubGroups && !isEmpty(tempSubGroups)) {
                      if (
                        quest?.required &&
                        (!quest?.response ||
                          (quest?.type === DROPDOWN && validateUmbrellaDropdown(quest?.response)))
                      ) {
                        isValied = false;
                        tempSubGroups[subGroupIndex].questions[
                          repeatableQuestionSetIndex
                        ].repeatableQuestions[questIndex].validationErrorMessage = REQUIRED;
                        tempSubGroups[subGroupIndex].questions[
                          repeatableQuestionSetIndex
                        ].repeatableQuestions[questIndex].onFocus = true;
                        setErrorQuestionKeys((prevState: any) => [
                          ...prevState,
                          getUniqueKeyForQuestionnaire(quest),
                        ]);
                      } else {
                        tempSubGroups[subGroupIndex].questions[
                          repeatableQuestionSetIndex
                        ].repeatableQuestions[questIndex].validationErrorMessage = '';
                      }
                    }
                  }
                );
              });
            } else {
              subGroup?.questions?.forEach((quest, questIndex) => {
                const tempSubGroups = tempQuestionGroups[groupIndex]?.subGroups;
                if (!!tempSubGroups && !isEmpty(tempSubGroups)) {
                  if (
                    quest?.required &&
                    (!quest?.response ||
                      (quest?.type === DROPDOWN && validateUmbrellaDropdown(quest?.response)))
                  ) {
                    isValied = false;
                    tempSubGroups[subGroupIndex].questions[questIndex].validationErrorMessage =
                      REQUIRED;
                    tempSubGroups[subGroupIndex].questions[questIndex].onFocus = true;
                    setErrorQuestionKeys((prevState: any) => [
                      ...prevState,
                      getUniqueKeyForQuestionnaire(quest),
                    ]);
                  } else {
                    tempSubGroups[subGroupIndex].questions[questIndex].validationErrorMessage = '';
                  }
                }
              });
            }
          });
        }
      });
      setQuestionnaireSet({ ...questionnaireSet, questionGroups: tempQuestionGroups });
    }
    return isValied;
  };

  const submitStep = () => {
    let isValid = validate();
    if (disableUmbrellaEdit?.umbrella) {
      goToLastTab(currentIndex);
    } else if (isValid) {
      let setQuestionnaireResonse: any = questionnaireSet
        ? { ...getMappedQuestionnaireResponses(questionnaireSet) }
        : null;
      props.storeUmbrellaQuoteDetails({
        umbrellaQuestionsResponse: setQuestionnaireResonse,
      });
      let questionsResponse: any = questionnaireSet
        ? getMappedQuestionnaireResponses(questionnaireSet)
        : null;
      if (
        !isEqual(questionsResponse?.groupResponses, umbrellaDetails?.umbrellaQuestionsResponse) ||
        !umbrellaDetails?.quoteList?.length
      ) {
        let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
        if (isHeapAnalyticsEnabled) {
          window.heap.track(QUOTING_STEPS_PAGES_NAME?.UMBRELLA_INFO_SUBMIT);
        }
        let { requoteLobs } = common;
        if (!isEmpty(requoteLobs) && requoteLobs?.includes(INSURANCE_PRODUCTS[8])) {
          const lobList = requoteLobs?.filter((item: string) => item !== INSURANCE_PRODUCTS[8]);
          props.updateRequoteProducts(lobList);
        }
        props.stopUmbrellaPolling();
        props.storeUmbrellaQuoteDetails({
          umbrellaQuestionsResponse: questionsResponse?.groupResponses,
          umbrellaInfo: {
            questionGroups: questionnaireSet?.questionGroups,
          },
          quotesLoaded: false,
          quoteList: [],
          quoteErrorList: [],
          quotingDetails: null,
          pollingDetails: null,
          upcomingCarrierList: [],
        });
        if (sessionStorage.selectedQuoteList) {
          const selectedQuotes = removeExistingRate(
            JSON.parse(sessionStorage.selectedQuoteList),
            INSURANCE_BUSINESS_TYPES.UMBRELLA
          );
          sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
        }
        props.fetchUmbrellaQuotes();
      }
      goToLastTab(currentIndex);
    }
  };

  const onAnswerHandler = (questSet: AppComponents.UmbrellaQuestionnaireSet) => {
    setQuestionnaireSet(questSet);
    if (!isQuestionGroupsMapped) {
      setQuestionGroupsMapped(true);
    }
  };

  return (
    <>
      <div className='umbrellaDetails-wrapper pt-0'>
        <div className='row form-wrapper d-flex justify-content-center'>
          <div className='col-lg-10 mob-padding-0'>
            <div className='row m-0 d-flex justify-content-center'>
              <div className='col-lg-12 mob-padding-0'>
                {getNestedTernaryData(
                  umbrellaDetails?.umbrellaInfoLoader &&
                    !umbrellaDetails?.umbrellaInfo?.questionGroups?.length,
                  <div className='w-100 mt-4 d-flex justify-content-center'>
                    <LobLoader lob={UMBRELLA} />
                  </div>,
                  questionnaireSet &&
                    !isEmpty(questionnaireSet) &&
                    !isEmpty(umbrellaDetails?.umbrellaInfo?.questionGroups) && (
                      <>
                        <UmbrellaFormCompoment
                          loader={umbrellaDetails?.umbrellaInfoLoader}
                          questionnaireSet={questionnaireSet}
                          onAnswer={onAnswerHandler}
                          errorQuestionKeys={errorQuestionKeys}
                        />
                        <div className='col-lg-12 umbrell-button-wrap'>
                          {(common?.isAddingProducts && currentIndex === 1) ||
                          hideStepperBackButton(INSURANCE_BUSINESS_TYPES.UMBRELLA) ? (
                            ''
                          ) : (
                            <button
                              className='btn m-2 btn-back mh-button'
                              disabled={umbrellaDetails?.quoteListLoader}
                              onClick={() => onBackClick()}
                            >
                              <ArrowBackIosIcon className='mr-2' />
                              {BACK}
                            </button>
                          )}
                          <button
                            className='btn m-2 btn-success mh-button'
                            disabled={umbrellaDetails?.quoteListLoader || !isQuestionGroupsMapped}
                            onClick={() => submitStep()}
                          >
                            <span>{CONTINUE}</span>
                          </button>
                        </div>
                      </>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  applicant,
  common,
  umbrellaDetails,
}: AppComponents.UmbrellaStore): AppComponents.UmbrellaStore => ({
  applicant,
  common,
  umbrellaDetails,
});

const mapDispatchToProps = (dispatchProp: any): AppComponents.UmbrellaDispatch =>
  bindActionCreators(
    {
      fetchUmbrellaQuestionListInfo,
      fetchUmbrellaQuotes,
      stopUmbrellaPolling,
      storeUmbrellaQuoteDetails,
      updateRequoteProducts,
    },
    dispatchProp
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(Umbrella));
