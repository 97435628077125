import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import { fetchHomeViewQuotes, setRedirectToViewQuoteFlag } from '../../redux/actions';
import ProductRates from '../../components/product-rates';
import './quotes.scss';

class Quotes extends React.Component<AppComponents.ViewQuotesProps, AppComponents.ViewQuotesState> {
  componentDidMount() {
    const { location, redirectToViewQuote, herd_id, quotingDetails } = this.props;
    let herdId: string = '';
    let requestId: any = '';
    // Dashboard row click flow
    if (!isEmpty(location?.location?.search)) {
      herdId = location.location.search.split('?')[1].split('=')[1];
      requestId = queryString.parse(location.search).requestId;
    } else if (location?.location?.pathname && location.location.pathname === '/quotes') {
      //Rates page refresh flow
      herdId = get(quotingDetails, 'herd_id', herd_id);
    }
    const isViewQuotes =
      location?.location?.pathname &&
      location.location.pathname.includes('/quotes') &&
      !isEmpty(herdId);
    (redirectToViewQuote || isViewQuotes) &&
      this.props.fetchHomeViewQuotes({ herdId: herdId, requestId: requestId });
    setRedirectToViewQuoteFlag(false);
  }

  render() {
    const { appointments, viewQuotesLoader } = this.props;
    return (
      <div className='container'>
        {!viewQuotesLoader && (
          <ProductRates
            appointments={appointments}
            setTab={() => {}}
            onPrevTab={() => {}}
            goToLastTab={() => {}}
            goBackToCertainTab={() => {}}
            redirectToStep={() => {}}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (
  state: AppComponents.ViewQuotesStore
): AppComponents.ViewQuotesStoreProps => {
  const { viewQuotesLoader, herd_id } = state.home;
  const { appointments } = state.partner;
  const { redirectToViewQuote } = state.common;
  const { quotingDetails } = state.quote;

  return {
    viewQuotesLoader,
    location: state.router,
    appointments,
    redirectToViewQuote,
    herd_id,
    quotingDetails,
  };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.ViewQuotesDispatch => {
  return bindActionCreators(
    {
      fetchHomeViewQuotes,
      setRedirectToViewQuoteFlag,
    },
    dispatchProp
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
