import React from 'react';
import { startCase } from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import SpinnerLoader from '../../components/spinner-loader';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  ASTERISK,
  CANCEL,
  DESCRIPTION,
  ENTER,
  FEEDBACK,
  SUBJECT,
  SUBMIT,
  XS,
} from '../../../constants';
import './feedback.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
    submitSpinner: {
      color: 'white !important',
      marginBottom: '2px',
      marginRight: '0px',
    },
  })
);
const Feedback = (props: AppComponents.FeedBackProps) => {
  const classes = useStyles();
  const { feedBackFormData, feedBackFormDataError, loader, onInputChanges, open, onSaveFeedback } =
    props;

  const onCloseModal = () => {
    props.onClose();
  };

  return (
    <div>
      <Dialog
        className='feedback-form-model'
        maxWidth={XS}
        onClose={onCloseModal}
        aria-labelledby='max-width-dialog-title'
        open={open}
        disableBackdropClick={true}
      >
        <DialogTitle id='form-dialog-title'>
          <Typography className='feedback-title'>{FEEDBACK}</Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form>
            <div className='row'>
              <div className='col-lg-12'>
                <Typography className='subject-label'>
                  {startCase(SUBJECT)}
                  <span className='star-error'>{ASTERISK}</span>
                </Typography>
              </div>
              <div className='col-lg-12 mb-4'>
                <FormControl variant='filled' fullWidth className='subject-textfield'>
                  <TextField
                    name={SUBJECT}
                    value={feedBackFormData?.subject}
                    onChange={event => onInputChanges(event)}
                    error={!!feedBackFormDataError?.subjectError ? true : false}
                    inputProps={{
                      maxLength: 100,
                    }}
                    disabled={loader}
                    onKeyPress={event => {
                      if (event.key === ENTER) {
                        event.preventDefault();
                      }
                    }}
                  />
                </FormControl>
                <FormHelperText className='text-red'>
                  {feedBackFormDataError?.subjectError}
                </FormHelperText>
              </div>
              <div className='col-lg-12'>
                <Typography className='subject-label'>
                  {startCase(DESCRIPTION)}
                  <span className='star-error'>{ASTERISK}</span>
                </Typography>
              </div>
              <div className='col-lg-12'>
                <FormControl variant='filled' fullWidth className='decription-textfield multiline'>
                  <TextField
                    name={DESCRIPTION}
                    value={feedBackFormData?.description}
                    onChange={event => onInputChanges(event)}
                    error={!!feedBackFormDataError?.descriptionError ? true : false}
                    disabled={loader}
                    inputProps={{
                      maxLength: 800,
                    }}
                    multiline
                    required
                  />
                </FormControl>
                <FormHelperText className='text-red'>
                  {feedBackFormDataError?.descriptionError}
                </FormHelperText>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions className='d-flex justify-content-center pt-4 pb-4'>
          <Button
            variant='contained'
            className='submit-button'
            disabled={loader}
            onClick={() => onSaveFeedback()}
          >
            {SUBMIT} {loader && <SpinnerLoader styleData={classes.submitSpinner} />}
          </Button>
          <Button
            variant='outlined'
            className='cancel-button'
            disabled={loader}
            onClick={onCloseModal}
          >
            {CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Feedback;
