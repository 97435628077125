/* istanbul ignore file */
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import config from '../../config/config';
import { FILE_SERVER_TYPE } from '../../constants';
import store from '../redux/store';
import { userSignOut } from '../redux/actions';
import { getAuthToken } from '../../utils';

const configApi = { publicUrl: config.serverURL, timeout: config.requestTimeout };
const errorResponseInterceptor = (error: any) => {
  if (
    !isEmpty(error.config) &&
    Object.prototype.hasOwnProperty.call(error.config, 'errorHandle') &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }
  if (!isEmpty(error.response)) {
    if (error?.response?.status === 401) {
      store.dispatch(userSignOut());
    }
  }
  if (!axios.isCancel(error)) {
    return Promise.reject(error);
  } else {
    return Promise.resolve({});
  }
};

// Apply error interceptor on response
axios.interceptors.response.use((response) => response, errorResponseInterceptor);
// Set timout for every request
axios.defaults.timeout = parseFloat(configApi.timeout);
axios.defaults.withCredentials = true;

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const cancelAllRequests = () => {
  !isEmpty(source) && source.cancel('cancel_requests');
  source = CancelToken.source();
};

const axiosInstance = axios.create({ baseURL: configApi.publicUrl });

axiosInstance.interceptors.request.use((settings: any) => {
  if (settings.type === FILE_SERVER_TYPE) {
    const headers = { ...settings.headers.headers };
    settings.headers = { ...headers };
  } else {
    settings.headers.common = {
      ...settings.headers.common,
      Authorization: `Bearer ${getAuthToken()}`,
    };
  }
  return settings;
});

const axiosRequest = (
  method: string,
  url: string,
  isProviderOneLogin: boolean = true,
  data?: any,
  options?: any,
  type?: string
) => {
  let details: any = {
    url: configApi.publicUrl + url,
  };
  if (!isProviderOneLogin) {
    details.headers = { provider: 'auth0_jwt' };
  }
  if (type === FILE_SERVER_TYPE) {
    details = {
      url,
      headers: { ...options },
      type,
    };
  }
  return axiosInstance.request({
    method,
    ...details,
    data,
    cancelToken: source.token,
  });
};

export default axiosRequest;
