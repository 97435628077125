import React, { useEffect, useMemo, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, isEmpty, keys, startCase } from 'lodash';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableHead from '../enhanced-table-head';
import {
  arrayToStringObject,
  formatPhoneNumberWithBrackets,
  getComparator,
  getEmptyRowCount,
  productFromOtherQuotes,
  stableSort,
} from '../../../utils';
import { tablePageChanged, setRedirectToViewQuoteFlag } from '../../redux/actions';
import { BOUND, COMPLETE, DEFAULT_MISSING_DATA, DASHBOARD_LOB_MAPPING, IN_PROGRESS, QUOTE_APPLICANTION_VIEW_ROUTE, QUESTIONNAIRE_VIEW_PATH_ROUTE } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import './insured-details-table.scss';
import { isEnabledPRSv2Flow } from '../../../quote-application/utils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    whiteSpace: 'nowrap',
    borderBottom: '3px solid #808080a1',
  },

  backdrop: {
    zIndex: 1,
    color: '#fff',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  progressLoader: {
    color: '#00cf83',
  },
  phoneNumberColumn: {
    minWidth: '140px',
  },
});

const getEmptyRowContent = (emptyRows: number, props: any, classes: any) => (
  <TableRow style={{ height: 53 * emptyRows }}>
    {emptyRows === props.dashboard.perPageRecords ? (
      <TableCell colSpan={10} className='records-cell'>
        {props.dashboard.insuredTableLoader ? (
          <CircularProgress size={30} className='circular-progress' />
        ) : (
          <span>No records to display</span>
        )}
      </TableCell>
    ) : (
      <TableCell colSpan={10} align='center'></TableCell>
    )}
  </TableRow>
);

const getCarrierBound = (details: any, currentLob: any) => {
  const lobs = keys(details);
  let tempCarrierBoundData = lobs?.map((lob: string) => {
    return details[lob]
      ? !productFromOtherQuotes(currentLob)
        ? `${lob?.toUpperCase()} - ${details[lob]?.carrierName}`
        : details[lob]?.carrierName
      : '';
  });
  return !isEmpty(tempCarrierBoundData) ? arrayToStringObject(tempCarrierBoundData) : '';
};

const getPaginationCount = (total: any, rows: any, loader: boolean) => {
  if (loader && total === 0) {
    return 0;
  }
  const result = Math.max(0, Math.ceil(total / rows));
  return result === 0 ? 1 : result;
};

const InsuredDetailsTable = (props: AppComponents.InsuredDetailsTableProps) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(props.dashboard.perPageRecords);
  const [rowData, setRowData] = React.useState(props.recordsList);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const previousState: any = useRef();

  let emptyRows = useMemo(
    () => rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage),
    [rowsPerPage, rowData, page]
  );

  useEffect(() => {
    setRowData([...props.recordsList]);
  }, [props.recordsList]);

  useEffect(() => {
    setPage(0);
  }, [props.filterList, props.statesFilterList, props.dateRange]);

  useEffect(() => {
    if (previousState.current !== props.searchText) {
      setPage(0);
    }
    previousState.current = props.searchText;
  }, [props.searchText]);

  const handleChangePage = (event: any, newPage: number) => {
    props.tablePageChanged({ pageNumber: newPage - 1, filterList: props.filterList });
    setPage(newPage - 1);
  };

  const rowClickAction = (row: any) => {
    // window.open(row.view_quote, '_blank');
    setRedirectToViewQuoteFlag(false);
    if (isEnabledPRSv2Flow()) {
      window.open(`${QUOTE_APPLICANTION_VIEW_ROUTE}=${row.herd_id}`);
    } else {
      window.open(`${QUESTIONNAIRE_VIEW_PATH_ROUTE}=${row.herd_id}`);
    }
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const getCellContent = (row: any, key: any) => {
    return !isEmpty(get(row, 'product', '') && row[key])
      ? getProductValue(row[key].toUpperCase())
      : DEFAULT_MISSING_DATA;
  };

  const getProductValue = (value: any) => {
    return value.split(', ').map((e: any) => DASHBOARD_LOB_MAPPING[e] || e).join(', ');
  };

  const list: Array<any> = stableSort(rowData, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  emptyRows = useMemo(
    () => getEmptyRowCount(emptyRows, list, rowsPerPage),
    [emptyRows, list, rowsPerPage]
  );

  const paginationCount = useMemo(
    () => getPaginationCount(props.totalRecords, rowsPerPage, props.dashboard.insuredTableLoader),
    [props.totalRecords, rowsPerPage, props.dashboard.insuredTableLoader]
  );

  return (
    <TableContainer className='insured-details-table-wrapper'>
      <Table className={classes.table} aria-label='insured quotes table'>
        <EnhancedTableHead
          classes={classes}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(rowData, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              row =>
                !isEmpty(row) && (
                  <TableRow
                    key={row.id}
                    onClick={rowClickAction.bind(window as any, row)}
                    className='tableRow pointer'
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      title={!!row?.name && row?.name !== DEFAULT_MISSING_DATA ? row.name : ''}
                    >
                      {!!row.name ? row.name : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      x-ms-format-detection='none'
                      title={
                        !!row?.phone_number ? formatPhoneNumberWithBrackets(row.phone_number) : ''
                      }
                    >
                      {!isEmpty(formatPhoneNumberWithBrackets(row.phone_number))
                        ? formatPhoneNumberWithBrackets(row.phone_number)
                        : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={!!row?.email && row?.email !== DEFAULT_MISSING_DATA ? row.email : ''}
                    >
                      {!!row?.email ? row.email : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={!!row?.state && row?.state !== DEFAULT_MISSING_DATA ? row.state : ''}
                    >
                      {!!row?.state ? row.state : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={
                        !!row?.quote_date && row?.quote_date !== DEFAULT_MISSING_DATA
                          ? row.quote_date
                          : ''
                      }
                    >
                      {!!row.quote_date ? row.quote_date : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={
                        !!row?.quote_by && row?.quote_by !== DEFAULT_MISSING_DATA
                          ? row.quote_by
                          : ''
                      }
                    >
                      {!!row?.quote_by ? row.quote_by : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={
                        !isEmpty(getCellContent(row, 'lob')) &&
                        getCellContent(row, 'lob') !== DEFAULT_MISSING_DATA
                          ? getCellContent(row, 'lob')
                          : ''
                      }
                    >
                      {getCellContent(row, 'lob')}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={!!row?.org && row?.org !== DEFAULT_MISSING_DATA ? row.org : ''}
                    >
                      {!isEmpty(row.org) ? row.org : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      className='carrier-cell'
                      title={
                        !isEmpty(row.carrierBound) ? getCarrierBound(row.carrierBound, row.lob) : ''
                      }
                    >
                      {!isEmpty(row.carrierBound)
                        ? getCarrierBound(row.carrierBound, row.lob)
                        : DEFAULT_MISSING_DATA}
                    </TableCell>
                    <TableCell
                      align='left'
                      title={
                        !!row?.status && row.status && row.status === COMPLETE
                          ? !isEmpty(row.carrierBound)
                            ? startCase(BOUND)
                            : startCase(COMPLETE)
                          : startCase(IN_PROGRESS)
                      }
                    >
                      {row.status && row.status === COMPLETE
                        ? !isEmpty(row.carrierBound)
                          ? startCase(BOUND)
                          : startCase(COMPLETE)
                        : startCase(IN_PROGRESS)}
                    </TableCell>
                  </TableRow>
                )
            )}

          {emptyRows >= rowsPerPage && getEmptyRowContent(emptyRows, props, classes)}
        </TableBody>
        <TableFooter>
          <TableRow className='table-pagination-container'>
            <TableCell align='left' colSpan={10} className='footer-cell pl-2'>
              <Pagination
                size='small'
                color='primary'
                page={page + 1}
                count={paginationCount}
                onChange={handleChangePage}
                disabled={props.dashboard.insuredTableLoader || paginationCount === 0}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

InsuredDetailsTable.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  recordsList: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  searchText: PropTypes.string,
  filterList: PropTypes.array,
  statesFilterList: PropTypes.array,
};

const mapStateToProps = ({ dashboard }: AppComponents.InsuredDetailsTableStore) => {
  return { dashboard };
};

const mapDispatchToProps = (dispatch: any): AppComponents.InsuredDetailsTableDispatch => {
  return bindActionCreators({ tablePageChanged, setRedirectToViewQuoteFlag }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuredDetailsTable);
