import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import clsx from 'clsx';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import {
  bulkQuoteInit,
  openBulkQuoteModal,
  resetStepDetails,
  showTerminatedMessage,
} from '../redux/actions';
import { BULK_QUOTE_STEP_LABELS, PAGES_TEXT } from '../../constants';
import BulkQuoteTable from '../components/bulk-quote-table';
import CarrierSelector from '../components/carrier-selector';
import FileUploader from '../components/file-uploader';
import InsuredDetailsSearch from '../components/insured-details-search';
import OrganizationSelector from '../components/organization-selector';
import BulkQuoteIcon from '../../assets/images/quotes.svg';

import './bulk-quote.scss';

const styles = (theme: Theme) => ({
  root: {
    width: 'auto',
  },
  cardContent: {
    paddingBottom: '0px !important',
  },
  dialogTitle: {
    borderBottom: '1.5px solid #cdcdce87',
    padding: '15px 15px 5px 15px',
  },
  continueSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '10px',
  },
  bulkQuoteButton: {
    marginLeft: '-6px !important',
  },
  buttonDisabled: {
    color: 'white !important',
    opacity: 0.8,
    cursor: 'default',
  },
  stepperRoot: {
    margin: '0 auto',
    width: '450px',
    padding: '10px 10px 10px 10px',
  },
  stepperLabel: {
    marginTop: '10px !important',
  },
  stepperLabelActive: {
    color: '#00CF83 !important',
  },
  stepperLabelCompleted: {
    color: '#00CF83 !important',
  },
});

const LineConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 15px)',
    right: 'calc(50% + 15px)',
  },
  active: {
    '& $line': {
      borderColor: '#00CF83',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#00CF83',
    },
  },
  line: {
    borderColor: '#cdcdce',
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

const useStepIconStyles = makeStyles({
  root: {
    color: '#cdcdce',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 400,
  },
  stepDot: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    textAlign: 'center',
    border: '1.5px solid #cdcdce',
  },
  active: {
    color: '#00CF83',
    zIndex: 1,
    '& $stepDot': {
      color: 'white',
      border: '2px solid #00CF83',
      backgroundColor: '#00CF83',
    },
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#00CF83',
    zIndex: 1,
    '& $stepDot': {
      color: 'white',
      border: '2px solid #00CF83',
      backgroundColor: '#00CF83',
    },
  },
});

const StepIcon = (props: StepIconProps) => {
  const classes = useStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      <div className={classes.stepDot}>{props.icon}</div>
    </div>
  );
};

class BulkQuote extends Component<
  AppComponents.BulkQuoteProps & WithStyles<typeof styles>,
  AppComponents.BulkQuoteState
> {
  state: AppComponents.BulkQuoteState = {
    modalOpen: false,
    activeStep: 0,
    steps: [],
    entity: 'bulkQuoteTable',
    searchText: '',
  };

  componentDidMount() {
    const { activeStep, modalOpen } = this.props;
    this.setState({
      activeStep,
      modalOpen,
      steps: this.getStepLabels(),
    });
    this.props.bulkQuoteInit();
  }

  componentWillReceiveProps(nextProps: AppComponents.BulkQuoteProps) {
    if (nextProps.modalOpen !== this.props.modalOpen) {
      this.setState({
        modalOpen: nextProps.modalOpen,
      });
    }
    if (nextProps.activeStep !== this.props.activeStep) {
      this.setState({
        activeStep: nextProps.activeStep,
      });
    }
  }

  getStepLabels = () => [...BULK_QUOTE_STEP_LABELS];

  getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return <OrganizationSelector />;
      case 1:
        return <CarrierSelector />;
      case 2:
        return <FileUploader />;
    }
    return '';
  };

  getModalCloseButton = (classStyle: string) => (
    <IconButton
      aria-label='close'
      className={classStyle}
      onClick={this.handleDialogClose}
      disabled={this.props.selectorLoader}
    >
      <CloseIcon />
    </IconButton>
  );

  onOpenDialog = () => {
    this.props.showTerminatedMessage(false);
    this.props.openBulkQuoteModal(true);
  };

  handleDialogClose = () => {
    this.props.openBulkQuoteModal(false);
  };

  handleDialogExit = () => {
    this.props.resetStepDetails();
    if (this.props.stepsCompleted) {
      this.props.bulkQuoteInit();
    }
  };

  bulkQuoteDetailsSearchHandler = (searchText: string) => {
    if (this.state.searchText !== searchText) {
      this.setState({
        searchText,
      });
    }
  };

  showBulkQuoteButton = () => {
    const { classes, bulkQuoteTableLoader } = this.props;
    return !bulkQuoteTableLoader ? (
      <Button
        variant='outlined'
        className='btnOutlined'
        classes={{
          root: classes.bulkQuoteButton,
          disabled: classes.buttonDisabled,
        }}
        disabled={bulkQuoteTableLoader}
        onClick={this.onOpenDialog}
      >
        <img alt='icon' className='img-fluid icon' src={BulkQuoteIcon} />
        <span>New Bulk Quote</span>
      </Button>
    ) : (
      ''
    );
  };

  getDialogWidth() {
    const { stepsCompleted } = this.props;
    const { activeStep } = this.state;
    if (stepsCompleted) {
      return 'xs';
    } else if (activeStep === 0 || activeStep === 1) {
      return 'md';
    } else {
      return 'sm';
    }
  }

  render() {
    const { classes, stepsCompleted, bulkQuoteTableLoader } = this.props;
    const { activeStep, steps, modalOpen, entity } = this.state;
    return (
      <div className='container-fluid bulk-quote-wrapper p-3'>
        <div className='container-fluid bulkquote-table-wrapper mt-3'>
          <div className='row pb-5'>
            <div className='col-md-12'>
              <Card className='CardWrapper'>
                <CardContent classes={{ root: classes.cardContent }}>
                  <div className='row ml-0 mb-3'>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 bulk-quote-button'>
                      {this.showBulkQuoteButton()}
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 new-bulk-quote'>
                      <InsuredDetailsSearch
                        handleSearch={this.bulkQuoteDetailsSearchHandler}
                        searchText={''}
                        disableSearch={bulkQuoteTableLoader}
                      />
                    </div>
                  </div>
                  <div className='row m-2'>
                    <BulkQuoteTable
                      entity={entity.toLowerCase()}
                      totalRecords={this.props.quoteList.length}
                      recordsList={this.props.quoteList}
                      loader={false}
                      recordSearchText={this.state.searchText}
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <Dialog
          fullWidth
          maxWidth={this.getDialogWidth()}
          disableEscapeKeyDown={true}
          disableBackdropClick={true}
          classes={{
            root: 'bulk-quote-dialog-container',
          }}
          open={modalOpen}
          onClose={this.handleDialogClose}
          onExited={this.handleDialogExit}
          aria-labelledby='bulk-quote-dialog-title'
        >
          {!stepsCompleted ? (
            <React.Fragment>
              <DialogTitle id='bulk-quote-dialog-title' classes={{ root: classes.dialogTitle }}>
                {this.getModalCloseButton('close-icon')}

                <Stepper
                  className={classes.stepperRoot}
                  activeStep={activeStep}
                  alternativeLabel
                  connector={<LineConnector />}
                >
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel
                        classes={{
                          label: classes.stepperLabel,
                          active: classes.stepperLabelActive,
                          completed: classes.stepperLabelCompleted,
                        }}
                        StepIconComponent={StepIcon}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </DialogTitle>
              <DialogContent className='bulk-quote-dialog-wrap'>
                {this.getStepContent(activeStep)}
              </DialogContent>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.getModalCloseButton('success-close-icon')}

              <DialogContent className='bulk-quote-dialog-wrap dialog-final-wrap'>
                <CloudDoneOutlinedIcon className='upload-success-icon' />
                <div className='upload-message'>{PAGES_TEXT.fileUploadSuccessMessage}</div>
                <Button
                  variant='outlined'
                  className='navigation-btn modal-close-btn'
                  onClick={this.handleDialogClose}
                >
                  GO TO DASHBOARD
                </Button>
              </DialogContent>
            </React.Fragment>
          )}
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ bulkQuote }: AppComponents.BulkQuoteStore) => {
  const {
    activeStep,
    bulkQuoteTableLoader,
    modalOpen,
    quoteList,
    stepsCompleted,
    selectorLoader,
  } = bulkQuote;
  return {
    activeStep,
    modalOpen,
    quoteList,
    stepsCompleted,
    selectorLoader,
    bulkQuoteTableLoader,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.BulkQuoteDispatch => {
  return bindActionCreators(
    {
      bulkQuoteInit,
      openBulkQuoteModal,
      resetStepDetails,
      showTerminatedMessage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(BulkQuote));
