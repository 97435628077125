import { Grid } from '@material-ui/core';
import isBoolean from 'lodash/isBoolean';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../../config/config';
import history from '../../../history';
import { setHasFormChanged, toggleDriverIncludedStatus } from '../../redux/actions';
import AutoSidePanel from '../auto-side-panel';
import ConfirmationModal from '../confirmation-modal';

const DriverSidePanel = ({
  deleteDetailsLoader,
  toggleDriverIncludedStatus,
  driver,
  validateBeforeEditHandler,
  setHasFormChanged,
  openDriverLimitModal,
}: AppComponents.DriverSidePanelProps) => {
  const [state, setState] = useState<AppComponents.DriverSidePanelState>({
    driversList: [],
    deleteDialog: false,
    deleteSelectedDetails: null,
  });

  const isDriverLimitReached = () => {
    let isLimitReached = false;
    let totalIncludedDrivers = 0;
    driver.driversList.forEach((data: any) => {
      if (data.isIncluded) {
        totalIncludedDrivers++;
      }
    });

    if (totalIncludedDrivers === config.maxDriverLimit) {
      isLimitReached = true;
    }

    return isLimitReached;
  };

  const shouldDisableSwitch = (isIncluded: any) => {
    let shouldDisable = false;

    if (isDriverLimitReached() && !isIncluded) {
      shouldDisable = true;
    }
    return shouldDisable;
  };

  const toggleEnabled = (details: any) => {
    // TODO: delete after confirmation
    // setState({ ...state, deleteSelectedDetails: { ...details }, deleteDialog: true });
    if (isDriverLimitReached() && !details.isIncluded) {
      openDriverLimitModal();
    } else {
      toggleDriverIncludedStatus({ duid: details.duid });
      setHasFormChanged(true);
    }
  };

  const editDriverHandler = (details: any) => {
    validateBeforeEditHandler(details.duid);
    if (history.location.pathname.includes('otherDetails')) {
      history.push('driver');
    }
  };

  // TODO: delete after confirmation
  /**
   *  Close the modal.
   *  isDelete: true: deletes the selected driver
   *  isDelete: false: cancel the delete operation
   */
  const modalCloseHandler = (isDelete: boolean) => {
    const { deleteSelectedDetails } = state;
    isBoolean(isDelete) &&
      isDelete &&
      toggleDriverIncludedStatus({ duid: deleteSelectedDetails.duid });
    setState({ ...state, deleteDialog: false, deleteSelectedDetails: null });
  };

  return (
    <>
      {driver &&
        driver.driversList &&
        driver.driversList.map((currentDriver: any, i: number) => (
          <Grid
            container
            spacing={2}
            className={`m-0 w-100 list-wrap ${
              currentDriver.duid === driver.duid ? 'active-container' : 'list-container'
            }`}
            key={`${i}-${currentDriver.ssn}-${currentDriver.gender}`}
          >
            <AutoSidePanel
              includedLimitReached={shouldDisableSwitch(currentDriver.isIncluded)}
              isIncluded={currentDriver.isIncluded}
              icon={true}
              details={{
                isPrimaryDriver: currentDriver.isPrimaryDriver,
                duid: currentDriver.duid,
                title: `${currentDriver.first_name} ${currentDriver.last_name}`,
                index: i,
                loader: deleteDetailsLoader,
                disabled: currentDriver.driverLoader,
                gender: currentDriver.gender,
                isIncluded: currentDriver.isIncluded,
              }}
              toggleEnabled={toggleEnabled}
              edithandler={editDriverHandler}
            />
          </Grid>
        ))}

      {state.deleteDialog && (
        <ConfirmationModal
          open={state.deleteDialog}
          contentValue={`${state.deleteSelectedDetails.title}`}
          closehandler={modalCloseHandler}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ common, driver }: AppComponents.DriverSidePanelStore) => {
  const { loading, deleteDetailsLoader } = common;
  return { loading, driver, deleteDetailsLoader };
};

const mapDispatchToProps = (dispatch: any): AppComponents.DriverSidePanelDispatch =>
  bindActionCreators({ toggleDriverIncludedStatus, setHasFormChanged }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DriverSidePanel);
