/* istanbul ignore file */
import { AxiosError } from 'axios';
import { NotificationManager } from 'react-notifications';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { assignIn, filter, find, forEach, get, has, isEmpty, map, omit, values } from 'lodash';
import moment from 'moment';
import { push } from 'connected-react-router';
import { v4 } from 'uuid';
import * as actions from '../constants';
import axiosRequest from '../../api';
import config from '../../../config/config';
import {
  DEFAULT_BULK_QUOTE_PATH,
  DEFAULT_LOB_PATH,
  EARTH_QUAKE_LOB,
  HIPPO_LABEL,
  INSURANCE_PRODUCTS,
  LOB_REFRESH_TIMEOUT,
  PRODUCT_SELECTION_ERROR_MESSAGE,
  STATEDATA,
} from '../../../constants';
import {
  errorHandler,
  flushAppDetails,
  homeTimeoutPolling,
  homeViewQuotesLoader,
  initHomeQuotePolling,
  setApplicantDetails,
  setAnnexPrefill,
  setConfigLoader,
  setDownloadQuoteLoader,
  setFloodDetailsLoader,
  setHomeCode,
  setHomeState,
  setLineOfBusiness,
  setLobRefreshLoader,
  setPconfig,
  setPolicyBounded,
  setValidatingAddress,
  showFloodConfirmationModal,
  stopHomeQuotePolling,
  applicantDetailsFromHomeInsurance,
  updateDriverName,
  setFloodQuotes,
  setAppointments,
  setPollingDetails,
  setQuotesLoaded,
  updateDriverData,
  updateVehicleName,
  updateGeneralCoverages,
  setRequestId,
  setNoQuotesAvailableForHome,
  setPrefillDetailsForRequestBind,
} from '../actions';
import {
  clearLobQuotes,
  createPageTracking,
  dateFormat,
  downloadFileUrl,
  goToDashboardUrl,
  inIframe,
  setHeapProjectId,
  simplifyPhoneNumber,
  trackFirstLoadQuoteInHeap,
  trackLastLoadQuoteInHeap,
  mappedResponseToStoreForPropertyDetails,
  shouldApplicantHasPreviousAddress,
  getIsCondoProduct,
  getPreferredDeductible,
  getResidencyStatus,
} from '../../../utils';
import {
  hippoLeadDataDeductiblesMap,
  showFields,
  updateApostrophe,
  getProductTypeByValue,
} from '../../home/utils';
import { ReduxRootState } from '../store';
import { PrefillDetails } from '../reducers/applicant';

const homeActions: any = actions;
const pollConfig: any = {
  publicUrl: config.serverURL,
  pollingTimerTimeout: config.pollingTimerTimeout,
  pollingInterval: config.pollingInterval,
};

// Set visibility for phone number and email fields as per partner configuration
function* formFieldsVisibility() {
  const {
    home: { pconfig },
  } = yield select();
  return {
    phone_number: showFields('phone_number', false),
    email: showFields('email', pconfig.dale_config.form_parameters_visibility),
  };
}

const pollingDelay = (duration: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
};

function* fetchPartnerConfigDetails({ payload: { code } }: AppRedux.ActionPayload): any {
  yield put(setConfigLoader(true));
  try {
    const {
      home: { defaultPConfig },
      partner: { isProviderOneLogin },
    } = yield select();
    let partnerCode = code ? code : defaultPConfig.code;
    partnerCode = partnerCode.trim().toLowerCase();
    // Update code details
    yield put(setHomeCode(partnerCode));
    const details = yield axiosRequest('POST', '/partners/config', isProviderOneLogin, {
      code: partnerCode,
    });
    if (details && details.data) {
      yield put(setHomeState({ pconfig: { ...details.data } } as any));
      yield put(setPconfig({ defaultPConfig, pconfig: { pconfig: { ...details.data } } } as any));
      setHeapProjectId(details.data, false)
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setConfigLoader(false));
}

export function* fetchPconfigDetailsWatcher() {
  yield takeEvery(homeActions.FETCH_PCONFIG, fetchPartnerConfigDetails);
}

const validateAddressApi = async (
  address: AppComponents.ApplicantAddress,
  isProviderOneLogin: boolean
) =>
  await axiosRequest('POST', '/address/validate', isProviderOneLogin, {
    ...address,
  });

// todo: maybe rename this
function* homeApplicationFormSubmit({
  payload: {
    address,
    previousAddress,
    isMovedInPastMonths,
    personal_details,
    loanOfficer_details,
    is_condo,
  },
}: AppRedux.ActionPayload): any {
  yield put(setValidatingAddress(true)); // migrate
  const reduxState: ReduxRootState = yield select();
  const {
    driver,
    common: { isHomeRedirect },
    partner: { isProviderOneLogin },
  } = reduxState;
  let isValidAddress = false;
  let isValidPreviousAddress = false;
  const isPreviousAddressRequired = shouldApplicantHasPreviousAddress() && isMovedInPastMonths;
  try {
    const details = yield call(validateAddressApi, address, isProviderOneLogin);
    if (details.data) {
      const isStateExist = (state: string | undefined) =>
        STATEDATA.states.find((stateData: any) => state?.toUpperCase() === stateData.value);
      isValidAddress = !!isStateExist(details?.data?.state);
      const homeStatePayload: any = {
        address: {
          ...details.data,
        },
        personal_details: {
          ...personal_details,
          preferredDeductible: getPreferredDeductible(),
        },
        loanOfficer_details: {
          ...loanOfficer_details,
        },
        preferredResidencyStatus: getResidencyStatus(),
      };
      const applicantDetailsPayload: any = {
        personal_details,
        isMovedInPastMonths,
        address: details.data,
        isApplicantSubmittedOnceForAuto: shouldApplicantHasPreviousAddress(),
      };
      if (isPreviousAddressRequired) {
        const response = yield call(validateAddressApi, previousAddress, isProviderOneLogin);
        if (response?.data) {
          isValidPreviousAddress = !!isStateExist(response?.data?.state);
          homeStatePayload.previousAddress = {
            ...response.data,
          };
          applicantDetailsPayload.previousAddress = response.data;
        }
      }
      yield put(
        setHomeState({
          // migrate
          ...homeStatePayload,
          isAlreadyValidated: !isValidAddress,
          isPreviousAddressAlreadyValidated:
            isValidAddress && isPreviousAddressRequired && !isValidPreviousAddress,
        })
      );
      yield put(applicantDetailsFromHomeInsurance(applicantDetailsPayload));
      if (!isHomeRedirect) {
        yield put(
          setHomeState({
            // migrate
            address: {
              ...details.data,
            },
          } as any)
        );
      }
      // updating driver info here as we are using home applicant page
      // todo: move this once we start to use applicant page
      if (driver.driversList.length === 0) {
        yield put(
          updateDriverName({
            first_name: personal_details.first_name,
            last_name: personal_details.last_name,
            date_of_birth: personal_details.date_of_birth,
            industry: personal_details.industry,
            occupation: personal_details.occupation,
            education: personal_details.education,
          } as any)
        );
      }
    }
  } catch (error) {
    const homeStatePayload: any = {
      showError: false,
      address,
      isMovedInPastMonths,
      previousAddress,
      personal_details,
      loanOfficer_details,
      is_condo,
      isAlreadyValidated: !isValidAddress,
      isPreviousAddressAlreadyValidated:
        isValidAddress && isPreviousAddressRequired && !isValidPreviousAddress,
    };
    yield put(setHomeState(homeStatePayload)); // migrate
    yield put(errorHandler(error));
  }
  yield put(setValidatingAddress(false)); // migrate
}

export function* homeApplicationFormSubmitWatcher() {
  yield takeEvery(homeActions.HOME_APPLICATION_SUBMIT, homeApplicationFormSubmit);
}

export function* getQuoteDetails(): any {
  const reduxStore = yield select();
  const {
    home: {
      code,
      herd_id,
      address,
      loanOfficer_details,
      pconfig,
      is_condo,
      personal_details,
      productType,
    },
    common: { pageTracking },
    auth,
    partner: { storedRequestId, producerDetails, appointments },
    applicant: { address: applicantAddress },
  } = reduxStore;
  const showValidFields = yield call(formFieldsVisibility);
  const quoteBy = !isEmpty(get(auth, 'email', ''))
    ? get(auth, 'email')
    : producerDetails && !isEmpty(producerDetails.producerEmail)
    ? producerDetails.producerEmail
    : '';
  const { clearAutoInfo } = clearLobQuotes();
  let pageTrackings = createPageTracking(!clearAutoInfo ? pageTracking : {}, appointments);
  return {
    pageTracking: pageTrackings,
    herd_id,
    lob: INSURANCE_PRODUCTS[0],
    address: address ? address : applicantAddress,
    loanOfficer_details: values(loanOfficer_details).every(isEmpty)
      ? null
      : {
          ...loanOfficer_details,
          loan_officer_fname: updateApostrophe(loanOfficer_details.loan_officer_fname),
          loan_officer_lname: updateApostrophe(loanOfficer_details.loan_officer_lname),
          loan_officer_phone: simplifyPhoneNumber(loanOfficer_details.loan_officer_phone),
        },
    is_condo: getIsCondoProduct(is_condo),
    home_type: productType,
    quote_by: quoteBy,
    id: get(auth, 'userId', ''),
    first_name: updateApostrophe(personal_details.first_name),
    middle_name: updateApostrophe(personal_details.middle_name),
    last_name: updateApostrophe(personal_details.last_name),
    dob: moment(personal_details.date_of_birth, dateFormat).format(dateFormat),
    phone_number: showValidFields.phone_number
      ? simplifyPhoneNumber(personal_details.phone_number)
      : '',
    email: showValidFields.email ? personal_details.email : '',
    partner_code: code ? code : 'naaisg',
    request_id: v4(),
    auth_token: pconfig?.dale_config?.auth_token,
    organizationId: pconfig?.dale_config?.id,
    industry: personal_details.industry,
    occupation: personal_details.occupation,
    education: personal_details.education,
    preferredDeductible: getPreferredDeductible(),
    provider_request_id: storedRequestId,
    residency_status: getResidencyStatus(),
    retrieveQuote: true,
  };
}

function* handleHomeQuoteError(error: any) {
  if (has(error, 'response')) {
    const { response } = error;
    const { data } = response;
    if (
      get(response, 'status', 0) === 404 &&
      get(response, 'data.error_details.display_errors_on_ui', false) &&
      !isEmpty(get(response, 'data.error_details.errors', []))
    ) {
      const quoteErrorList = filter(data.error_details.errors, quoteErrorDetails =>
        assignIn(quoteErrorDetails, { quoteError: true })
      );
      yield put(
        setHomeState({
          quoteList: [...quoteErrorList],
          viewQuotes: true,
          isPolling: false,
        } as any)
      );
      yield put(stopHomeQuotePolling());
    } else {
      yield put(setNoQuotesAvailableForHome(true));
      yield put(
        setHomeState({
          quoteList: [],
          viewQuotes: true,
          isPolling: false,
          upcomingCarrierList: [],
        } as any)
      );
      yield put(stopHomeQuotePolling());
    }
  }
}

function* handleEzlynxQuotes() {
  const {
    home: { quoteList, tempErrorQuoteList },
  } = yield select();
  yield put(
    setHomeState({
      isPolling: false,
      ezlynxQuotesLoaded: true,
    } as any)
  );
  if (quoteList.length > 0) {
    yield put(
      setHomeState({
        quoteListError: tempErrorQuoteList,
        tempErrorQuoteList: [],
        viewQuotes: true,
      } as any)
    );
  } else {
    yield put(
      setHomeState({
        quoteListError: tempErrorQuoteList,
        tempErrorQuoteList: [],
        hippoQuoteLoaded: true,
        showError: false,
      } as any)
    );
    // yield put(push('/error'));
  }
}

function* updateEzlynxQuoteList(
  totalEzlynxQuotes: any,
  isHippoPresent: any,
  quotingCarriers: Array<any>
) {
  if (parseFloat(totalEzlynxQuotes) !== 0) {
    yield put(
      setHomeState({
        isPolling: true,
        isQuoteRefresh: false,
        totalEzlynxQuotes: (quotingCarriers?.length || 0) + (isHippoPresent ? 1 : 0),
      } as any)
    );
    yield put(initHomeQuotePolling());
    yield call(pollingDelay, pollConfig.pollingTimerTimeout);
    yield put(homeTimeoutPolling());
    const {
      home: { quoteList },
    } = yield select();
    if (quoteList.length > 0) {
      yield put(
        setHomeState({
          viewQuotes: true,
          isPolling: false,
        } as any)
      );
    }
  } else {
    yield call(handleEzlynxQuotes);
  }
}

function* updateHomeErrorQuotes(errorDetails: any, isHippoPresent: boolean) {
  let hippoQuotePresent = isHippoPresent;
  if (
    get(errorDetails, 'display_errors_on_ui', false) &&
    !isEmpty(get(errorDetails, 'errors', ''))
  ) {
    const { home } = yield select();
    const quoteErrorList = filter(errorDetails.errors, error => {
      if (String(error.Carrier).toLowerCase() === config.hippo.quote_name.toLowerCase()) {
        hippoQuotePresent = true;
      }
      return assignIn(error, { quoteError: true });
    });
    yield put(
      setHomeState({ quoteList: home.quoteList, tempErrorQuoteList: quoteErrorList } as any)
    );
  }
  return hippoQuotePresent;
}

function* handleAnnexPrefillInCaseOfHomeError(details: any, lineOfBusiness: string) {
  const data = details?.response?.data || details?.data;
  if (data?.annexRequiredPrefill) {
    const annexDetails = data.annexRequiredPrefill;
    yield put(
      setHomeState({
        totalEzlynxQuotes: 1,
      } as any)
    );
    yield put(setAnnexPrefill(annexDetails));
    if (lineOfBusiness === INSURANCE_PRODUCTS[0]) {
      yield put(showFloodConfirmationModal(true));
    }
    yield put(setFloodDetailsLoader(false));
  }
}

function* fetchHomeQuotes(): any {
  const reduxStore = yield select();
  const {
    applicant: { userPropertyInformation },
    common: { lineOfBusiness },
    partner: { isProviderOneLogin, appointments },
  } = reduxStore;
  try {
    let isHippoPresent = false;
    yield put(setNoQuotesAvailableForHome(false));
    const keys = Object.keys(appointments);
    const quoteDetails = yield call(getQuoteDetails);
    quoteDetails.selected_lob = keys;
    quoteDetails.prefillData = !isEmpty(userPropertyInformation)
      ? { ...userPropertyInformation }
      : null;
    yield put(
      setHomeState({
        hippoQuoteLoaded: false,
        ezlynxQuotesLoaded: false,
        quoteList: [],
        quoteListError: [],
        upcomingCarrierList: [],
        quoteStatus: '',
      } as any)
    );
    const details = yield axiosRequest('POST', '/partners/getquotes', isProviderOneLogin, {
      ...quoteDetails,
    });

    if (details.data) {
      const {
        herd_id,
        ezlynxQuoteExecutionID,
        totalEzlynxQuotes,
        error_details,
        quotingCarriers,
        prefillData,
        upcomingCarrierList,
      } = details.data;
      const totalEzlynxAndFloodQuotes = totalEzlynxQuotes;
      yield put(
        setApplicantDetails({
          prefillDetails: !isEmpty(prefillData)
            ? (mappedResponseToStoreForPropertyDetails(prefillData) as PrefillDetails)
            : {
                propertyData: {},
                label: {},
              },
        })
      );
      if (!isEmpty(prefillData)) {
        const prefillDetails: any = mappedResponseToStoreForPropertyDetails(prefillData);
        if (!isEmpty(prefillDetails)) yield put(setPrefillDetailsForRequestBind(prefillDetails));
      }
      if (keys.includes('HOME')) {
        trackFirstLoadQuoteInHeap();
      }
      yield call(handleAnnexPrefillInCaseOfHomeError, details, lineOfBusiness);
      yield put(
        setHomeState({
          herd_id: herd_id,
          hippoQuoteLoaded: true,
          ezlynxQuoteExecutionID,
          quotingCarriers: !isEmpty(quotingCarriers) ? quotingCarriers : [],
          upcomingCarrierList: !isEmpty(upcomingCarrierList) ? upcomingCarrierList : [],
        } as any)
      );
      // Set hippo quote
      if (get(details, 'data.carrier_name', '') && get(details, 'data.quote_id', '')) {
        const { data } = details;
        const { home } = yield select();
        isHippoPresent = true;
        yield put(
          setHomeState({
            headerData: { deductibles: { ...data.deductibles } },
            quoteList: [
              {
                ...omit(details.data, ['annexRequiredPrefill']),
                carrier_id: config.hippo.carrier_id,
                enabled: true,
                coverages: {
                  coverage_a: get(data, 'cov_a', 0),
                  coverage_b: get(data, 'cov_b', 0),
                  coverage_c: get(data, 'cov_c', 0),
                  coverage_d: get(data, 'cov_d', 0),
                  coverage_e: get(data, 'cov_e', 0),
                  coverage_f: get(data, 'cov_f', 0),
                },
                header_coverages: {
                  cov_a: get(data, 'cov_a', 0),
                  cov_b: get(data, 'cov_b', 0),
                  cov_c: get(data, 'cov_c', 0),
                  cov_d: get(data, 'cov_d', 0),
                  cov_e: get(data, 'cov_e', 0),
                  cov_f: get(data, 'cov_f', 0),
                },
              },
              ...home.quoteList,
            ],
          } as any)
        );
      } else if (totalEzlynxQuotes === 0 && isEmpty(error_details?.errors)) {
        yield put(setNoQuotesAvailableForHome(true));
      }
      isHippoPresent = yield call(updateHomeErrorQuotes, error_details, isHippoPresent);
      yield call(updateEzlynxQuoteList, totalEzlynxAndFloodQuotes, isHippoPresent, quotingCarriers);
      !totalEzlynxAndFloodQuotes && trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[0]);
    }
  } catch (error) {
    if (lineOfBusiness !== EARTH_QUAKE_LOB) {
      yield call(handleAnnexPrefillInCaseOfHomeError, error, lineOfBusiness);
      yield call(handleHomeQuoteError, error);
      trackLastLoadQuoteInHeap(INSURANCE_PRODUCTS[0]);
    }
  }
}

// Fetch init quote details from server
export function* fetchHomeQuotesDetailsWatcher() {
  yield takeLatest(homeActions.FETCH_HOME_QUOTE_DETAILS, fetchHomeQuotes);
}
const getErrorQuotes = (errors: Array<any>, quoteList: Array<any>) =>
  filter(
    errors,
    error =>
      !find(quoteList, { CarrierId: error.CarrierId }) && assignIn(error, { quoteError: true })
  );

const getUniqueQuoteList = (newQuoteList: Array<any>, quoteList: Array<any>) =>
  filter(newQuoteList, quote => !find(quoteList, { carrier_id: quote.carrier_id }));

function* updateErrorQuoteList(data: any, quoteList: Array<any>) {
  if (!isEmpty(get(data, 'error_details'))) {
    const {
      error_details: { errors },
    } = data;
    const newQuoteErrorList: Array<any> = getErrorQuotes(errors, quoteList);
    if (newQuoteErrorList.length > 0) {
      yield put(
        setHomeState({ quoteList: quoteList, tempErrorQuoteList: newQuoteErrorList } as any)
      );
    }
  }
}

function* updateNormalQuoteList(data: any, quoteList: Array<any>, headerData: any) {
  const newQuoteList = get(data, 'message', []);
  let updatedQuoteList: Array<any> = [];
  let deductibles: any = null;
  const messageList: Array<any> = getUniqueQuoteList(newQuoteList, quoteList);
  if (messageList.length > 0) {
    updatedQuoteList = messageList.map(quote => {
      if (
        isEmpty(headerData) &&
        (quote.deductibles.Other > 0 ||
          quote.deductibles.Wind > 0 ||
          quote.deductibles.Hurricane > 0)
      ) {
        deductibles = { deductibles: quote.deductibles };
      }
      return assignIn(quote, {
        enabled: false,
        price: quote.price,
        header_coverages: data.header,
        show_lowest_price: false,
        deductibles: { ...quote.deductibles },
      });
    });
  }
  updatedQuoteList = [...updatedQuoteList, ...quoteList];
  updatedQuoteList = updatedQuoteList.reduce((result: Array<any>, quoteDetails: any) => {
    String(quoteDetails.carrier_name).trim().toLowerCase() === config.hippo.quote_name.toLowerCase()
      ? result.unshift(quoteDetails)
      : result.push(quoteDetails);
    return result;
  }, []);
  yield put(
    setHomeState({
      quoteList: [...updatedQuoteList],
      ...(!isEmpty(deductibles) ? { headerData: deductibles } : {}),
    } as any)
  );
}

export function* pollHomeQuotes() {
  while (true) {
    const {
      home: { herd_id, ezlynxQuoteExecutionID, totalEzlynxQuotes, headerData, isQuoteRefresh },
      partner: { isProviderOneLogin },
    } = yield select();
    try {
      if (herd_id) {
        const { data } = yield axiosRequest('POST', '/ezlynx/getezlynxquotes', isProviderOneLogin, {
          ezlynxQuoteExecutionID,
          herd_id,
        });
        // Update errors from quote list
        const {
          home: { quoteList, tempErrorQuoteList, quoteListError },
        } = yield select();
        yield call(updateErrorQuoteList, data, quoteList);
        let quoteCount: number = quoteList?.length + tempErrorQuoteList?.length;
        const remainingDifference: number = totalEzlynxQuotes - quoteCount;
        if (!isEmpty(get(data, 'message', [])) && remainingDifference <= totalEzlynxQuotes) {
          yield call(updateNormalQuoteList, data, quoteList, headerData);
        }
        if (quoteCount >= totalEzlynxQuotes) {
          yield put(
            setHomeState({
              quoteListError: tempErrorQuoteList.length ? tempErrorQuoteList : quoteListError,
              tempErrorQuoteList: [],
            } as any)
          );
          yield put(setHomeState({ viewQuotes: true } as any));
          yield put(stopHomeQuotePolling());
          if (isQuoteRefresh) yield put(setLobRefreshLoader(false));
        } else if (!isQuoteRefresh) {
          yield call(pollingDelay, pollConfig.pollingInterval);
        }
      }
    } catch (error) {
      yield put(errorHandler(error));
    }
  }
}

function* initHomeQuotePollingWatcher() {
  while (true) {
    yield take(homeActions.INIT_HOME_QUOTE_POLLING);
    yield race([call(pollHomeQuotes), take(homeActions.STOP_HOME_QUOTE_POLLING)]);
  }
}

function* homePollingTimeout() {
  const {
    home: { quoteList, totalEzlynxQuotes, tempErrorQuoteList, isPolling, quoteListError },
  } = yield select();
  if (isPolling) {
    yield put(stopHomeQuotePolling());
    let quoteCount: number = quoteList?.length + tempErrorQuoteList?.length;
    if (quoteCount <= totalEzlynxQuotes) {
      yield put(
        setHomeState({
          quoteList,
          quoteListError: !isEmpty(tempErrorQuoteList) ? tempErrorQuoteList : quoteListError,
          tempErrorQuoteList: [],
        } as any)
      );
    }
  }
}

function* homePollingTimeoutWatcher() {
  yield takeLatest(homeActions.HOME_TIMEOUT_POLLING, homePollingTimeout);
}

function* stopHomePolling() {
  const {
    home: { quoteListError, tempErrorQuoteList },
  } = yield select();
  yield put(
    setHomeState({
      quoteListError: tempErrorQuoteList.length ? tempErrorQuoteList : quoteListError,
      tempErrorQuoteList: [],
      ezlynxQuotesLoaded: true,
      hippoQuoteLoaded: true,
      isPolling: false,
    } as any)
  );
}

function* stopHomeQuotePollingWatcher() {
  yield takeEvery(homeActions.STOP_HOME_QUOTE_POLLING, stopHomePolling);
}

// Get hippo 'Purchase Online' link with lead id
const getHippoLink = (configuration: any, leadId: string) => {
  return `${configuration.hippo.host}${configuration.hippo.purchase_online_link
    .split('__LEADID_PLACEHOLDER__')
    .join(leadId)}`;
};

const getHippoDeductibles = (adjustments: any) => {
  let allDeductible = {};
  forEach(hippoLeadDataDeductiblesMap, (value, type) => {
    let currentDeductible: any = {};
    if (Object.prototype.hasOwnProperty.call(adjustments, type)) {
      currentDeductible = {
        [hippoLeadDataDeductiblesMap[type]]: adjustments[type].options.find((opt: any) => {
          return adjustments[type].value === opt.value;
        }).display,
      };
      allDeductible = { ...allDeductible, ...currentDeductible };
    }
  });
  return allDeductible;
};

function* getHippoQuoteObject(hippoFullQuote: any, hippoFullQuoteAdjustments: any, link: any): any {
  return {
    name: config.hippo.quote_name,
    carrier_id: config.hippo.carrier_id,
    premium: hippoFullQuote.yearly_price,
    logo_url: config.hippo.quote_logo_url,
    policyBounded: get(hippoFullQuote, 'policyBounded', false),
    coverages: {
      coverage_a: !isEmpty(hippoFullQuoteAdjustments.dwelling_rebuilding)
        ? hippoFullQuoteAdjustments.dwelling_rebuilding.value
        : 0,
      coverage_b: !isEmpty(hippoFullQuoteAdjustments.separate_structure)
        ? hippoFullQuoteAdjustments.separate_structure.value
        : 0,
      coverage_c: !isEmpty(hippoFullQuoteAdjustments.personal_property)
        ? hippoFullQuoteAdjustments.personal_property.value
        : 0,
      coverage_d: !isEmpty(hippoFullQuoteAdjustments.loss_of_use)
        ? hippoFullQuoteAdjustments.loss_of_use.value
        : 0,
      coverage_e: !isEmpty(hippoFullQuoteAdjustments.personal_liability)
        ? hippoFullQuoteAdjustments.personal_liability.value
        : 0,
      coverage_f: !isEmpty(hippoFullQuoteAdjustments.medical_payments)
        ? hippoFullQuoteAdjustments.medical_payments.value
        : 0,
      deductibles: { ...(yield call(getHippoDeductibles, hippoFullQuoteAdjustments)) },
    },
    header_coverages: {
      cov_a: !isEmpty(hippoFullQuoteAdjustments.dwelling_rebuilding)
        ? hippoFullQuoteAdjustments.dwelling_rebuilding.value
        : 0,
      cov_b: !isEmpty(hippoFullQuoteAdjustments.separate_structure)
        ? hippoFullQuoteAdjustments.separate_structure.value
        : 0,
      cov_c: !isEmpty(hippoFullQuoteAdjustments.personal_property)
        ? hippoFullQuoteAdjustments.personal_property.value
        : 0,
      cov_d: !isEmpty(hippoFullQuoteAdjustments.loss_of_use)
        ? hippoFullQuoteAdjustments.loss_of_use.value
        : 0,
      cov_e: !isEmpty(hippoFullQuoteAdjustments.personal_liability)
        ? hippoFullQuoteAdjustments.personal_liability.value
        : 0,
      cov_f: !isEmpty(hippoFullQuoteAdjustments.medical_payments)
        ? hippoFullQuoteAdjustments.medical_payments.value
        : 0,
    },
    purchase_type: config.hippo.purchase_type,
    enabled: true,
    link,
  };
}

const checkForDeductibles = (deductibles: any) =>
  !isEmpty(deductibles) &&
  ((Object.prototype.hasOwnProperty.call(deductibles, 'Other') && deductibles.Other > 0) ||
    (Object.prototype.hasOwnProperty.call(deductibles, 'Wind') && deductibles.Wind > 0) ||
    (Object.prototype.hasOwnProperty.call(deductibles, 'Hurricane') && deductibles.Hurricane > 0));

function* getHippoQuoteDetails(data: any, allQuotes: Array<any>, headerData: any): any {
  let hippoQuote: any = {};
  if (!isEmpty(get(data, 'lead_data', null)) && !isEmpty(get(data, 'lead_data.full_quote'))) {
    const hippoFullQuote = data.lead_data.full_quote;
    const hippoFullQuoteAdjustments = hippoFullQuote.adjustments;
    const link = yield data.lead_data.link || call(getHippoLink, config, data.lead_data.leadId);
    hippoQuote = yield call(getHippoQuoteObject, hippoFullQuote, hippoFullQuoteAdjustments, link);
    if (checkForDeductibles(hippoQuote.coverages.deductibles)) {
      headerData = { deductibles: hippoQuote.coverages.deductibles };
    }
    allQuotes = [hippoQuote];
  }
  return {
    headerDetails: { ...headerData },
    quoteList: [...allQuotes],
  };
}
function* getCommonData(data: any, allQuotes: Array<any>, headerData: any, herdId: any) {
  const {
    home: { defaultPConfig },
  } = yield select();
  const { address, applicant, organization, quoteBounded } = data;
  yield put(setPolicyBounded(quoteBounded));
  const productType = applicant?.product && get(applicant, 'product', '').split('-').join('');
  const lob = get(data, 'lob', '').trim().toLowerCase();
  yield put(setLineOfBusiness(lob));
  yield put(
    setHomeState({
      herd_id: herdId,
      hippoQuoteLoaded: true,
      ezlynxQuotesLoaded: true,
      lob,
      pconfig: assignIn({}, { dale_config: organization }),
      code: get(organization, 'code', defaultPConfig.code),
      is_condo: getProductTypeByValue(productType),
      productType,
      address: {
        street: get(address, 'street', ''),
        city: get(address, 'city', ''),
        state: get(address, 'state', ''),
        zip: get(address, 'zip', ''),
        unit: get(address, 'unit', ''),
        county: get(address, 'county', ''),
      },
      personal_details: {
        first_name: get(applicant, 'first_name', ''),
        middle_name: get(applicant, 'middle_name', ''),
        last_name: get(applicant, 'last_name', ''),
        date_of_birth: get(applicant, 'date_of_birthdob', ''),
        phone_number: get(applicant, 'phone_number', ''),
        email: get(applicant, 'email', ''),
        industry: get(applicant, 'industry', ''),
        occupation: get(applicant, 'occupation', ''),
        education: get(applicant, 'education', ''),
      },
    } as any)
  );
  const { headerDetails, quoteList } = yield call(
    getHippoQuoteDetails,
    data,
    allQuotes,
    headerData
  );

  return { allQuotesList: [...quoteList], headerDataList: { ...headerDetails } };
}

function* fetchHomeViewQuoteList(payload: any) {
  const { herdId, requestId } = payload.payload;
  yield put(homeViewQuotesLoader(true));
  yield put(
    setHomeState({
      quoteList: [],
      quoteListError: [],
      tempErrorQuoteList: [],
      upcomingCarrierList: [],
    } as any)
  );
  try {
    const {
      home: { defaultPConfig },
      partner: { isProviderOneLogin },
    } = yield select();
    const { data } = yield axiosRequest('POST', '/quotes/view', isProviderOneLogin, {
      requestId: requestId ? requestId : '',
      herdId: herdId,
    });
    if (data) {
      let details = data;
      const { address, applicant, organization, providerRequestId } = details;
      setHeapProjectId(organization, true);
      yield put(flushAppDetails());
      (window as any).jQuery(document.body).addClass(`theme-${config.hippo.template_name}`);
      let appointments: any = {};
      details.quotesByLOB &&
        Object.keys(details.quotesByLOB).forEach(key => {
          appointments[key.toUpperCase()] = {};
        });
      yield put(setAppointments({ appointments }));
      //Set Home Quote Details
      if (appointments?.HOME) {
        let allQuotes: Array<any> = [];
        let headerData: any = {};
        let data = details;
        let quoteData = get(data, 'quotesByLOB.home', {});
        const { allQuotesList } = yield call(getCommonData, data, allQuotes, headerData, herdId);
        if (!isEmpty(get(quoteData, 'quotes', []))) {
          const { quotes } = quoteData;
          const ezlynxQuotes = map(quotes, ezlynxquote => {
            if (
              isEmpty(headerData) &&
              (get(ezlynxquote, 'coverages.deductibles.Other', 0) > 0 ||
                get(ezlynxquote, 'coverages.deductibles.Wind', 0) ||
                get(ezlynxquote, 'coverages.deductibles.Hurricane', 0) > 0)
            ) {
              headerData = { deductibles: { ...ezlynxquote.coverages.deductibles } };
            }
            return assignIn(ezlynxquote, {
              enabled: false,
              purchase_type: get(ezlynxquote, 'purchase_type', config.ezlynxPurchaseType),
              cov_a: get(ezlynxquote, 'coverages.coverage_a', 0),
              cov_b: get(ezlynxquote, 'coverages.coverage_b', 0),
              cov_c: get(ezlynxquote, 'coverages.coverage_c', 0),
              cov_d: get(ezlynxquote, 'coverages.coverage_d', 0),
              cov_e: get(ezlynxquote, 'coverages.coverage_e', 0),
              cov_f: get(ezlynxquote, 'coverages.coverage_f', 0),
              header_coverages: {
                cov_a: get(ezlynxquote, 'adjustments.coverage_a', 0),
                cov_b: get(ezlynxquote, 'adjustments.coverage_b', 0),
                cov_c: get(ezlynxquote, 'adjustments.coverage_c', 0),
                cov_d: get(ezlynxquote, 'adjustments.coverage_d', 0),
                cov_e: get(ezlynxquote, 'adjustments.coverage_e', 0),
                cov_f: get(ezlynxquote, 'adjustments.coverage_f', 0),
              },
            });
          });
          allQuotes = [...allQuotesList, ...ezlynxQuotes];
        }
        if (
          !isEmpty(get(quoteData, 'error_details.errors', null)) &&
          get(quoteData, 'error_details.display_errors_on_ui', false)
        ) {
          const {
            error_details: { errors },
          } = quoteData;
          let allErrors = map(errors, error => assignIn(error, { quoteError: true }));
          // Added carrier_name and isHippoQuote key to Hippo element
          let updatedHomeQuotesList = !isEmpty(quoteData?.quotes)
            ? quoteData.quotes.map((item: any) => ({
                ...item,
                price: item.price,
                carrier_name: item.name,
                isHippoQuote: item.name === HIPPO_LABEL ? true : false,
              }))
            : [];
          allQuotes = [...updatedHomeQuotesList, ...allErrors];
        }
        if (allQuotes.length > 0) {
          yield put(
            setHomeState({
              quoteList: [...allQuotes],
              hippoQuoteLoaded: true,
              ezlynxQuotesLoaded: true,
              viewQuotes: true,
              headerData,
            } as any)
          );
        }
      }
      //Set Flood Quote Details
      if (appointments?.FLOOD) {
        let allQuotes: Array<any> = [];
        let headerData: any = {};
        let data = details;
        let quoteData = get(data, 'quotesByLOB.flood', {});
        const { allQuotesList } = yield call(getCommonData, data, allQuotes, headerData, herdId);
        if (!isEmpty(get(quoteData, 'quotes', []))) {
          allQuotes = [...allQuotesList, ...quoteData.quotes];
        }
        if (
          !isEmpty(get(quoteData, 'error_details.errors', null)) &&
          get(quoteData, 'error_details.display_errors_on_ui', false)
        ) {
          const {
            error_details: { errors },
          } = quoteData;
          const allErrors = map(errors, error => assignIn(error, { quoteError: true }));
          allQuotes = [...allQuotes, ...allErrors];
        }
        if (allQuotes.length > 0) {
          yield put(
            setFloodQuotes({
              quoteList: [...allQuotes],
              selectedCarrier: data ? data?.selectedCarriers?.flood : {},
            }) as any
          );
        }
      }
      //Set Auto Quote Details
      if (appointments?.AUTO) {
        let allQuotes: Array<any> = [];
        let headerData: any = {};
        yield put(setQuotesLoaded(false));
        let auto = get(details, 'quotesByLOB.auto', {});
        const { driver_details, vehicle_details, insurance_other_details } = auto;
        yield put(updateDriverData({ ...driver_details }));
        yield put(updateVehicleName({ ...vehicle_details }));
        yield put(updateGeneralCoverages({ ...insurance_other_details }));
        yield put(setPollingDetails({ ...auto }));
        yield call(getCommonData, details, allQuotes, headerData, herdId);
      }
      yield put(setRequestId(providerRequestId));
      yield put(
        setPconfig({
          defaultPConfig,
          pconfig: assignIn(
            {},
            {
              dale_config: organization,
            }
          ),
        } as any)
      );
      if (applicant && address) {
        yield put(
          applicantDetailsFromHomeInsurance({
            personal_details: applicant,
            address: address,
          } as any)
        );
      }
    }
  } catch (error) {
    const { auth } = yield select();
    yield put(
      setHomeState({
        quoteList: [],
        quoteListError: [],
        viewQuotes: false,
        showResult: false,
        headerData: null,
        upcomingCarrierList: [],
      } as any)
    );
    const pathToGo = auth.bulkUser ? DEFAULT_BULK_QUOTE_PATH : DEFAULT_LOB_PATH;
    if (inIframe() && pathToGo === DEFAULT_BULK_QUOTE_PATH && window.top) {
      window.top.location = goToDashboardUrl();
    } else {
      yield put(push(pathToGo));
    }
    yield put(push(auth.bulkUser ? DEFAULT_BULK_QUOTE_PATH : DEFAULT_LOB_PATH));
    yield put(errorHandler(error));
  }
  yield put(homeViewQuotesLoader(false));
}

function* fetchHomeViewQuotesWatcher() {
  yield takeEvery(homeActions.FETCH_HOME_VIEW_QUOTES, fetchHomeViewQuoteList);
}

function* cancelHomeQuotePollingDetails() {
  yield put(stopHomeQuotePolling());
  yield put(
    setHomeState({
      quoteList: [],
      quoteListError: [],
      tempErrorQuoteList: [],
      hippoQuoteLoaded: false,
      ezlynxQuotesLoaded: false,
      showError: false,
      viewQuotes: false,
      showResult: false,
      headerData: null,
      totalEzlynxQuotes: 0,
      herd_id: '',
      ezlynxQuoteExecutionID: [],
      upcomingCarrierList: [],
    } as any)
  );
}

function* cancelHomeQuotePollingWatcher() {
  yield takeEvery(homeActions.CANCEL_HOME_QUOTE_POLLING, cancelHomeQuotePollingDetails);
}

function* homeSidebarEdit() {
  const {
    home: { pconfig },
  } = yield select();

  yield put(
    setHomeState({
      quoteList: [],
      quoteListError: [],
      hippoQuoteLoaded: false,
      ezlynxQuotesLoaded: false,
      showError: false,
      viewQuotes: false,
      showResult: false,
      headerData: null,
      totalEzlynxQuotes: 0,
      ezlynxQuoteExecutionID: [],
      isPolling: false,
      upcomingCarrierList: [],
    } as any)
  );

  yield put(
    push({
      pathname: 'home',
      search: !isEmpty(pconfig.code) ? `?code=${pconfig.code}` : '',
    })
  );
}

function* homeSidebarEditWatcher() {
  yield takeEvery(homeActions.HOME_SIDEBAR_EDIT, homeSidebarEdit);
}

function* startHomeQuotePolling(): any {
  const reduxStore = yield select();
  const {
    viewQuoteDetails: { pollingDetails, isRedirectToApplicant },
    home: { quotingCarriers },
  } = reduxStore;
  const { lineOfBusiness, isPollingStart, totalEzlynxQuotes } = pollingDetails;
  if (isPollingStart && !isRedirectToApplicant && lineOfBusiness === INSURANCE_PRODUCTS[0]) {
    yield put(
      setHomeState({
        ezlynxQuotesLoaded: false,
        hippoQuoteLoaded: false,
        isPolling: true,
      } as any)
    );
    yield call(updateEzlynxQuoteList, totalEzlynxQuotes, false, quotingCarriers || []);
  }
}

function* startHomeQuotePollingWatcher() {
  yield takeEvery(actions.START_HOME_QUOTE_POLLING, startHomeQuotePolling);
}

function* refreshHomeQuotesWorker() {
  const {
    home: { isQuoteRefresh },
  } = yield select();
  if (!isQuoteRefresh) {
    yield put(
      setHomeState({
        isPolling: true,
        isQuoteRefresh: true,
      } as any)
    );
  }
  yield put(setLobRefreshLoader(true));
  yield put(initHomeQuotePolling());
  yield call(pollingDelay, LOB_REFRESH_TIMEOUT);
  yield put(setHomeState({ isQuoteRefresh: false } as any));
  yield put(homeTimeoutPolling());
  yield put(setLobRefreshLoader(false));
}

function* refreshHomeQuotesWatcher() {
  yield takeEvery(actions.REFRESH_HOME_QUOTES, refreshHomeQuotesWorker);
}

export function* downloadQuote({ payload }: any): any {
  yield put(
    setDownloadQuoteLoader({
      carrier_id: payload?.carrierId || '',
      loader: true,
    })
  );
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const details: any = yield axiosRequest(
      'POST',
      '/documents/quote-document',
      isProviderOneLogin,
      {
        ...payload,
      }
    );
    if (!!details?.data?.success && details?.data?.response) {
      downloadFileUrl(details?.data?.response);
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response?.data && [400, 404, 500].includes(axiosError?.response?.status)) {
      yield NotificationManager.error(PRODUCT_SELECTION_ERROR_MESSAGE);
      yield put(
        setDownloadQuoteLoader({
          carrier_id: payload?.carrierId || '',
          loader: false,
        })
      );
      return;
    }
    yield put(errorHandler(axiosError));
  }
  yield put(
    setDownloadQuoteLoader({
      carrier_id: payload?.carrierId || '',
      loader: false,
    })
  );
}

function* downloadQuoteWatcher() {
  yield takeEvery(actions.DOWNLOAD_QUOTE_DETAILS, downloadQuote);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPconfigDetailsWatcher),
    fork(homeApplicationFormSubmitWatcher),
    fork(fetchHomeQuotesDetailsWatcher),
    fork(initHomeQuotePollingWatcher),
    fork(homePollingTimeoutWatcher),
    fork(stopHomeQuotePollingWatcher),
    fork(fetchHomeViewQuotesWatcher),
    fork(cancelHomeQuotePollingWatcher),
    fork(homeSidebarEditWatcher),
    fork(startHomeQuotePollingWatcher),
    fork(refreshHomeQuotesWatcher),
    fork(downloadQuoteWatcher),
  ]);
}
