import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import { bindActionCreators } from 'redux';
import { cloneDeep, isEmpty } from 'lodash';
import { Typography } from '@material-ui/core';
import Feedback from '../feedback';
import FeedbackSuccessModal from '../feedback-success-modal';
import { addFeedback, storeFeedbackResponse } from '../../redux/actions';
import { FEEDBACK, REQUIRED, SUCCESSFULLY } from '../../../constants';
import FeedbackIcon from '../../../assets/images/feedback-icon.svg';
import './feedback-button.scss';
import { getHerdId } from '../../../utils';

const INIT_FORM_DATA = {
  subject: '',
  description: '',
};

const INIT_FORM_ERRORS = {
  subjectError: '',
  descriptionError: '',
};

export class FeedbackButton extends Component<
  AppComponents.FeedBackButtonProps,
  AppComponents.FeedBackButtonState
> {
  state: AppComponents.FeedBackButtonState = {
    feedBackFormData: { ...INIT_FORM_DATA },
    feedBackFormDataError: {
      ...INIT_FORM_ERRORS,
    },
    openFeedbackMadal: false,
    openFeedbackSuccessModal: false,
  };

  componentWillReceiveProps = (newProps: any) => {
    if (
      !!newProps?.feedbackResponseData?.message &&
      newProps?.feedbackResponseData?.message.includes(SUCCESSFULLY)
    ) {
      this.setState((prevState: any) => ({
        ...prevState,
        openFeedbackMadal: false,
        openFeedbackSuccessModal: true,
      }));
      setTimeout(() => this.onCloseModal(), 2000);
    }
  };

  onCloseModal = () => {
    const { feedbackResponseData } = this.props;
    this.setState((prevState: any) => ({
      ...prevState,
      openFeedbackMadal: false,
      openFeedbackSuccessModal: false,
      feedBackFormDataError: {
        ...INIT_FORM_ERRORS,
      },
      feedBackFormData: { ...INIT_FORM_DATA },
    }));
    !!feedbackResponseData?.message &&
      feedbackResponseData?.message.includes(SUCCESSFULLY) &&
      this.props.storeFeedbackResponse({ message: '' });
  };

  onFeedbackButtonClick = () => {
    this.setState((prevState: any) => ({
      ...prevState,
      openFeedbackMadal: true,
      openFeedbackSuccessModal: false,
    }));
  };

  onInputChanges = (event: any) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState((prevState: any) => ({
      ...prevState,
      feedBackFormData: {
        ...prevState.feedBackFormData,
        [name]: value,
      },
    }));
  };

  validate = () => {
    let error = { ...INIT_FORM_ERRORS };
    const { subject, description } = this.state.feedBackFormData;
    error.subjectError = isEmpty(subject?.trim()) ? REQUIRED : '';
    error.descriptionError = isEmpty(description?.trim()) ? REQUIRED : '';
    this.setState((prevState: any) => ({
      ...prevState,
      feedBackFormDataError: cloneDeep(error),
    }));
    if (!!error?.subjectError || !!error?.descriptionError) {
      return false;
    }
    return true;
  };

  onSaveFeedback = () => {
    const isValid = this.validate();
    if (!!isValid) {
      const { email, producerDetails } = this.props;
      const { description, subject } = this.state.feedBackFormData;
      const { height, width } = !!window?.screen && window.screen;
      let payload = {
        email: !!email
          ? email
          : !!producerDetails?.producerEmail
          ? producerDetails.producerEmail
          : '',
        subject: !!subject ? subject?.trim() : '',
        description: !!description ? description?.trim() : '',
        webBrowser: `${browserName} ${!!browserVersion ? browserVersion : ''}`,
        osVersion: `${osName} ${!!osVersion ? osVersion : ''}`,
        screenSize: `${width}*${height}`,
        herdId: getHerdId(),
        url: window.location.href,
      };
      this.props.addFeedback(payload);
    }
  };

  render() {
    const { feedbackLoader } = this.props;
    const { feedBackFormData, feedBackFormDataError, openFeedbackMadal, openFeedbackSuccessModal } =
      this.state;
    return (
      <div>
        <div className='feedback-wrap'>
          <Typography onClick={() => this.onFeedbackButtonClick()}>
            <img src={FeedbackIcon} alt={FEEDBACK} />
            {FEEDBACK}
          </Typography>
        </div>
        {!!openFeedbackMadal && (
          <Feedback
            onClose={this.onCloseModal}
            open={openFeedbackMadal}
            feedBackFormData={feedBackFormData}
            feedBackFormDataError={feedBackFormDataError}
            loader={feedbackLoader}
            onInputChanges={this.onInputChanges}
            onSaveFeedback={this.onSaveFeedback}
          />
        )}
        {!!openFeedbackSuccessModal && (
          <FeedbackSuccessModal onClose={this.onCloseModal} open={openFeedbackSuccessModal} />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  common,
  feedback,
  home,
  partner,
  quote,
}: AppComponents.FeedBackButtonStore) => {
  const { email } = auth;
  const { selectedBusinessOption } = common;
  const { feedbackLoader, feedbackResponseData } = feedback;
  const { producerDetails } = partner;
  return {
    email,
    feedbackLoader,
    feedbackResponseData,
    home,
    producerDetails,
    quote,
    selectedBusinessOption,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.FeedBackButtonDispatch => {
  return bindActionCreators({ addFeedback, storeFeedbackResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackButton);
