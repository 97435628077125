import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import history from '../../history';
import { userSignIn } from '../redux/actions';
import { ADMIN_ROUTE, DEFAULT_BULK_QUOTE_PATH, DEFAULT_REPORT_PATH } from '../../constants';
import { goToDashboard } from '../../utils';

class Login extends React.Component<AppComponents.LoginProps> {
  componentDidMount() {
    const { adminUser, authUser, bulkUser, reportUser } = this.props;
    if (!isEmpty(authUser)) {
      if (bulkUser) {
        history.push(DEFAULT_BULK_QUOTE_PATH);
      } else if (reportUser) {
        history.push(DEFAULT_REPORT_PATH);
      } else if (adminUser) {
        history.push(ADMIN_ROUTE);
      } else {
        goToDashboard();
      }
    } else {
      const { token } = queryString.parse(history.location.search);
      this.props.userSignIn({ authToken: !isEmpty(token) ? token : null });
    }
  }
  render() {
    return null;
  }
}

const mapStateToProps = (state: AppComponents.LoginStore): AppComponents.LoginStoreProps => {
  const { adminUser, authUser, bulkUser, reportUser } = state.auth;
  return { adminUser, authUser, bulkUser, reportUser };
};

const mapDispatchToProps = (dispatchProp: any): AppComponents.LoginDispatch =>
  bindActionCreators({ userSignIn }, dispatchProp);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
