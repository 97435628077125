import React, { useState } from 'react';
import { isEmpty, startCase } from 'lodash';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Switch,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MultiSelect from '../../components/multi-select';
import { integrationTypeList, productList } from '../../../utils';
import {
  ALL_LOB,
  ASTERISK,
  CARRIER_APPOINTMENT,
  EARTH_QUAKE_LOB,
  EZLYNX,
  INSURANCE_BUSINESS_TYPES,
  LOB_KEY,
  LOB_LIST,
  NAME,
  PASSWORD,
  PRODUCER_CODE,
  PRODUCER_CODE_KEY,
  PRODUCTS,
  SELECT,
  TEXT_TYPE,
  USERNAME,
} from '../../../constants';

export const CarrierAppointmentForm = ({
  formDetails,
  isProductRequired,
  onCarrierApoointmentChange,
  nameList,
  loader,
  errors,
  onCheckBoxChange,
  isValidatePassword,
}: any) => {
  const [isViewPassword, setIsViewPassword] = useState(false);

  return (
    <div className='add-new-org-model'>
      <form>
        <div className='row'>
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor='Name' error={!!errors?.nameError ? true : false}>
                {startCase(NAME)}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <NativeSelect
                id='code'
                name='code'
                value={
                  !isEmpty(formDetails.carrier_credentials_grid.code)
                    ? formDetails.carrier_credentials_grid.code
                    : ''
                }
                onChange={onCarrierApoointmentChange}
                className='form-item__select'
                disabled={loader}
              >
                <option value=''>{startCase(SELECT)}</option>
                {nameList.map((item: any) => (
                  <option className='select__item' key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormHelperText className='text-red'>{errors?.nameError}</FormHelperText>
          </div>
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={PRODUCER_CODE}
                error={!!errors?.producerCodeError ? true : false}
              >
                {PRODUCER_CODE}
              </InputLabel>
              <Input
                inputProps={{
                  name: PRODUCER_CODE_KEY,
                  id: PRODUCER_CODE_KEY,
                  spellCheck: false,
                  maxLength: 100,
                }}
                className='form-item__input'
                value={formDetails.carrier_credentials_grid.producer_code || ''}
                onChange={onCarrierApoointmentChange}
                type={TEXT_TYPE}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.producerCodeError}</FormHelperText>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={CARRIER_APPOINTMENT.API_USERNAME}
                error={!!errors?.usernameError ? true : false}
              >
                {CARRIER_APPOINTMENT.API_USERNAME}
              </InputLabel>
              <Input
                inputProps={{
                  name: USERNAME,
                  id: USERNAME,
                  spellCheck: false,
                  maxLength: 100,
                }}
                className='form-item__input'
                value={formDetails.carrier_credentials_grid.username || ''}
                onChange={onCarrierApoointmentChange}
                type={TEXT_TYPE}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.usernameError}</FormHelperText>
          </div>
          <div className='col-md-6 margin-bottom-35 api-password-form-control'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor=' Email' error={!!errors?.passwordError ? true : false}>
                {CARRIER_APPOINTMENT.API_PASSWORD}
                {isValidatePassword && <span className='star-error'>{ASTERISK}</span>}
              </InputLabel>
              <Input
                inputProps={{
                  name: PASSWORD,
                  id: PASSWORD,
                  spellCheck: false,
                  maxLength: 100,
                }}
                className='form-item__input'
                value={formDetails.carrier_credentials_grid.password || ''}
                onChange={onCarrierApoointmentChange}
                type={!!isViewPassword ? TEXT_TYPE : PASSWORD}
                disabled={loader}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      className='eye-icon'
                      onClick={() => setIsViewPassword(!isViewPassword)}
                    >
                      {isViewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.passwordError}</FormHelperText>
          </div>
        </div>
        <div className='row mb-4'>
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel
                shrink
                htmlFor={CARRIER_APPOINTMENT.MARKETS_LABEL}
                error={!!errors?.marketError ? true : false}
              >
                {CARRIER_APPOINTMENT.MARKETS_LABEL}
              </InputLabel>
              <Input
                id={CARRIER_APPOINTMENT.MARKET_KEY}
                name={CARRIER_APPOINTMENT.MARKET_KEY}
                value={
                  !isEmpty(formDetails.carrier_credentials_grid.market)
                    ? formDetails.carrier_credentials_grid.market
                    : ''
                }
                onChange={onCarrierApoointmentChange}
                className='form-item__input'
                type={TEXT_TYPE}
                disabled={loader}
              />
            </FormControl>
            <FormHelperText className='text-red'>{errors?.marketError}</FormHelperText>
          </div>
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={CARRIER_APPOINTMENT.CONSUMER_MARKET_LABEL}>
                {CARRIER_APPOINTMENT.CONSUMER_MARKET_LABEL}
              </InputLabel>
              <Input
                id={CARRIER_APPOINTMENT.CONSUMER_MARKET_KEY}
                name={CARRIER_APPOINTMENT.CONSUMER_MARKET_KEY}
                value={
                  !isEmpty(formDetails?.carrier_credentials_grid?.consumer_market)
                    ? formDetails.carrier_credentials_grid.consumer_market
                    : ''
                }
                onChange={onCarrierApoointmentChange}
                className='form-item__input'
                type={TEXT_TYPE}
                disabled={loader}
              />
            </FormControl>
          </div>
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor='lob'>
                {LOB_KEY}
              </InputLabel>
              <NativeSelect
                id={LOB_KEY?.toLowerCase()}
                name={LOB_KEY?.toLowerCase()}
                value={
                  !isEmpty(formDetails.carrier_credentials_grid.lob)
                    ? formDetails.carrier_credentials_grid.lob
                    : ''
                }
                onChange={onCarrierApoointmentChange}
                className='form-item__select'
                disabled={loader}
              >
                <option value={''}>{ALL_LOB}</option>
                {LOB_LIST.map((item: any) => (
                  <option className='select__item' key={item} value={item?.toUpperCase()}>
                    {!!item && item?.toLowerCase() === EARTH_QUAKE_LOB
                      ? INSURANCE_BUSINESS_TYPES.QUAKE
                      : item?.toUpperCase()}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormHelperText className='text-red'>{errors?.lobError}</FormHelperText>
          </div>
          {isProductRequired(formDetails.carrier_credentials_grid.lob) && (
            <div className='col-md-6 margin-bottom-35 product-select'>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor={PRODUCTS?.toLowerCase()}>
                  {startCase(PRODUCTS)}
                  <span className='star-error'>{ASTERISK}</span>
                </InputLabel>
                <MultiSelect
                  id={PRODUCTS?.toLowerCase()}
                  name={PRODUCTS?.toLowerCase()}
                  values={
                    !isEmpty(formDetails.carrier_credentials_grid.products)
                      ? formDetails.carrier_credentials_grid.products
                      : []
                  }
                  onChange={onCarrierApoointmentChange}
                  className='form-item__select'
                  options={productList}
                />
              </FormControl>
              <FormHelperText className='text-red'>{errors?.productError}</FormHelperText>
            </div>
          )}
          <div className='col-md-6 margin-bottom-35'>
            <FormControl fullWidth>
              <InputLabel shrink htmlFor={CARRIER_APPOINTMENT.INTEGRATION_TYPE_LABEL}>
                {CARRIER_APPOINTMENT.INTEGRATION_TYPE_LABEL}
              </InputLabel>
              <NativeSelect
                id={CARRIER_APPOINTMENT.INTEGRATION_TYPE_KEY}
                name={CARRIER_APPOINTMENT.INTEGRATION_TYPE_KEY}
                value={
                  !isEmpty(formDetails.carrier_credentials_grid.integration_type)
                    ? formDetails.carrier_credentials_grid.integration_type
                    : EZLYNX
                }
                onChange={onCarrierApoointmentChange}
                className='form-item__select'
                disabled={loader}
              >
                {integrationTypeList.map((item: any) => (
                  <option className='select__item' key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div className='col-md-6 margin-bottom-35'>
            <Grid container spacing={2} className='d-flex align-items-center'>
              <Grid item xs={8} lg={8} className='form-item'>
                <InputLabel className='form-item__label'>
                  {CARRIER_APPOINTMENT.ENABLED_FOR_LEAD_FLOW_LABEL}:
                </InputLabel>
              </Grid>
              <Grid item xs={4} lg={4}>
                <span className='p-0 d-flex content-class'>
                  <Switch
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    value={!!formDetails?.carrier_credentials_grid?.is_enable_for_lead_flow}
                    checked={!!formDetails?.carrier_credentials_grid?.is_enable_for_lead_flow}
                    onChange={event => onCheckBoxChange(event)}
                    name={CARRIER_APPOINTMENT.ENABLED_FOR_LEAD_KEY}
                    disabled={loader}
                  />
                </span>
              </Grid>
            </Grid>
          </div>
          <div className='col-md-6 margin-bottom-35'>
            <Grid container spacing={2} className='d-flex align-items-center'>
              <Grid item xs={8} lg={8} className='form-item'>
                <InputLabel className='form-item__label'>
                  {CARRIER_APPOINTMENT.IS_ACTIVE_LABEL}:
                </InputLabel>
              </Grid>
              <Grid item xs={4} lg={4}>
                <span className='p-0 d-flex content-class'>
                  <Switch
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    value={!!formDetails?.carrier_credentials_grid?.is_active}
                    checked={!!formDetails?.carrier_credentials_grid?.is_active}
                    onChange={event => onCheckBoxChange(event)}
                    name={CARRIER_APPOINTMENT.IS_ACTIVE_KEY}
                    disabled={loader}
                  />
                </span>
              </Grid>
            </Grid>
          </div>
          <div className='col-md-6 margin-bottom-35'>
            <Grid container spacing={2} className='d-flex align-items-center'>
              <Grid item xs={8} lg={8} className='form-item'>
                <InputLabel className='form-item__label'>
                  {CARRIER_APPOINTMENT.IS_AVAILABLE_FOR_QUOTING_LABEL}:
                </InputLabel>
              </Grid>
              <Grid item xs={4} lg={4}>
                <span className='p-0 d-flex content-class'>
                  <Switch
                    color='primary'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    value={!!formDetails?.carrier_credentials_grid?.is_available_for_quoting}
                    checked={!!formDetails?.carrier_credentials_grid?.is_available_for_quoting}
                    onChange={event => onCheckBoxChange(event)}
                    name={CARRIER_APPOINTMENT.IS_AVAILABLE_FOR_QUOTING_KEY}
                    disabled={loader}
                  />
                </span>
              </Grid>
            </Grid>
          </div>
        </div>
        <FormHelperText className='text-red'>{errors?.commonErrorMessage}</FormHelperText>
      </form>
    </div>
  );
};
export default CarrierAppointmentForm;
