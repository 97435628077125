import React from 'react';
import { Typography } from '@material-ui/core';
import errorLogo from '../../../assets/images/error-logo.svg';
import { PAGES_TEXT } from '../../../constants';
import './not-found-error.scss';

const NotFoundError = () => {
  return (
    <div className='error-page-wrapper'>
      <div className='container error-wrapper'>
        <div className='error-div'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <img src={errorLogo} alt='error' className='img-fluid' />
            </div>
            <div className='col-lg-12'>
              <Typography className='ops-label text-center'>
                {PAGES_TEXT.notFoundOops}
              </Typography>
              <Typography className='error-msg text-center'>
                {PAGES_TEXT.notfoundError}
              </Typography>
              <Typography className='error-code text-center'>
                {PAGES_TEXT.notfoundErrorCode}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(NotFoundError);

