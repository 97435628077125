/* istanbul ignore file */
import isEmpty from 'lodash/isEmpty';

import * as types from '../constants';
import { PrefillDetails } from './applicant';
import { DefaultPartnerConfiguration, PartnerConfig } from './home';
import { CommonQuote } from '../../../quote-application/utils/types';

interface HelperSteps {
  vehicles?: boolean;
  drivers?: boolean;
  details?: boolean;
  quotes?: boolean;
}

export interface ObjectEntity {
  [key: string]: string;
}

export interface HelperState {
  adhocBoundedLobs: string[];
  loading: boolean;
  quotingOrganizationList: Array<{ name: string; code: string; id: string; logoUrl: string }>;
  quotingOrganizationlistLoader: boolean;
  deleteDetailsLoader: boolean;
  stepSubmitLoader: boolean;
  defaultPConfig?: DefaultPartnerConfiguration;
  pconfig: null | {
    herd_id?: string;
    dale_config?: PartnerConfig['dale_config'];
    defaultPConfig?: DefaultPartnerConfiguration;
    pconfig?: PartnerConfig;
  };
  homeQuote: boolean;
  homeAddressCheck: boolean;
  isValidDriverPresent: boolean;
  spouseMissing: boolean;
  missingDetailsDrivers: Array<any>;
  vehiclesMissing: boolean;
  orgConfigLoader: boolean;
  isAutoRedirect: boolean;
  isHomeRedirect: boolean;
  completed_steps: HelperSteps;
  isVehicleEdit: boolean;
  getAutoQuotesFailed: boolean;
  AMSDetailsLoading: boolean;
  SfdcAMSDetailsLoading: boolean;
  openAMSModal: boolean;
  AMSCommonError: string;
  SfdcAMSCommonError: string;
  AMSSuccess: boolean;
  isSfdcSubmitSuccess: boolean;
  carrierUnderwriterLoader: boolean;
  maxAttemptReached: boolean;
  policyBounded: Record<string, boolean>;
  disableDivision: boolean;
  AMSLOBDetails: any;
  AMSDetails: {
    email: string;
    dob: string;
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    addressDetails: AppRedux.ApplicantAddress;
    phoneNumber: string;
    address: string;
    lob: string;
    effectiveDate: string;
    carrierName: string;
    premium: string;
    term: any;
    carrierUnderwriter: string;
    policyNumber: string;
    division: string;
    executive: string;
    group: string;
    department: string;
    representative: string;
    employee: string;
    broker: string;
    customId: string;
    loanNumber: string;
    carrierOptions: object;
    carrierUnderwriterOptions: Array<any>;
    divisionOptions: Array<any>;
    executiveOptions: Array<any>;
    representativeOptions: Array<any>;
    groupOptions: Array<any>;
    departmentOptions: Array<any>;
    employeeOptions: Array<any>;
    brokerOptions: Array<any>;
    lobOption: Array<any>;
  };
  sfdcAmsFormData: any;
  lineOfBusiness: string;
  redirectedFrom: Array<string>;
  isLOBAMSDetails: boolean;
  newProducts: {
    [key: string]: string;
  };
  isAddingProducts: boolean;
  redirectToViewQuote: boolean;
  showGetQuoteButton: boolean;
  selectedBusinessOption: string;
  has_form_changed: boolean;
  applicantFormChange: boolean;
  isVeriskFetched: boolean;
  isVeriskLoading: boolean;
  showVeriskVehicleModal: boolean;
  showVeriskDriverModal: boolean;
  selectFirstDriver: boolean;
  viewBoundedPolicyLoader: boolean;
  boundedPolicyData: any;
  boundedPolicyDetails: Record<string, CommonQuote>;
  selectedCarriers: Record<string, CommonQuote>;
  selectedCarrierLoader: boolean;
  switchFromDriver: any;
  enableRatePage: boolean;
  updateSelectedProductLoader: boolean;
  requoteLobs: Array<string>;
  isSaveApplicantError: boolean;
  allowEditQuotes: boolean;
  pageTracking: any;
  prefillDetailsForRequestBind: PrefillDetails;
  redirectionStep: string;
  isHeapDetailLoaded: boolean;
  adhocOrganizationDetails: any;
  sfdcOrganizationDetails: any;
  isPrefillCall: boolean;
  requestBindDetails: any;
  lobRefreshLoader: boolean;
  requestBindLoader: boolean;
  isGetPolicyHeaderSuccess: boolean;
  quotingListErrorMessage: string;
  emptyLobErrorMessage: string;
  loanNumber?: string;
  customId?: string;
}

const INIT_STATE: HelperState = {
  adhocBoundedLobs: [],
  loading: false,
  quotingOrganizationList: [],
  quotingOrganizationlistLoader: false,
  deleteDetailsLoader: false,
  stepSubmitLoader: false,
  pconfig: null,
  homeQuote: false,
  homeAddressCheck: false,
  isValidDriverPresent: true,
  spouseMissing: false,
  missingDetailsDrivers: [],
  vehiclesMissing: false,
  orgConfigLoader: false,
  isAutoRedirect: false,
  isHomeRedirect: false,
  completed_steps: {
    vehicles: false,
    drivers: false,
    details: false,
    quotes: false,
  },
  isVehicleEdit: false,
  getAutoQuotesFailed: false,
  AMSDetailsLoading: false,
  SfdcAMSDetailsLoading: false,
  carrierUnderwriterLoader: false,
  maxAttemptReached: false,
  openAMSModal: false,
  AMSCommonError: '',
  SfdcAMSCommonError: '',
  AMSSuccess: false,
  isSfdcSubmitSuccess: false,
  policyBounded: {},
  disableDivision: false,
  AMSLOBDetails: {},
  AMSDetails: {
    email: '',
    dob: '',
    fullName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    addressDetails: {
      city: '',
      state: '',
      street: '',
      unit: '',
      zip: '',
      county: '',
    },
    phoneNumber: '',
    address: '',
    lob: '',
    effectiveDate: '',
    carrierName: '',
    premium: '',
    term: '',
    carrierUnderwriter: '',
    policyNumber: '',
    division: '',
    executive: '',
    representative: '',
    group: '',
    department: '',
    employee: '',
    broker: '',
    customId: '',
    loanNumber: '',
    carrierOptions: {},
    carrierUnderwriterOptions: [],
    divisionOptions: [],
    executiveOptions: [],
    representativeOptions: [],
    groupOptions: [],
    departmentOptions: [],
    employeeOptions: [],
    brokerOptions: [],
    lobOption: [],
  },
  sfdcAmsFormData: null,
  lineOfBusiness: '',
  redirectedFrom: [],
  isLOBAMSDetails: false,
  newProducts: {},
  isAddingProducts: false,
  redirectToViewQuote: false,
  showGetQuoteButton: false,
  selectedBusinessOption: '',
  has_form_changed: false,
  applicantFormChange: false,
  isVeriskFetched: false,
  isVeriskLoading: false,
  showVeriskVehicleModal: false,
  showVeriskDriverModal: false,
  selectFirstDriver: false,
  viewBoundedPolicyLoader: false,
  boundedPolicyData: null,
  selectedCarriers: {},
  selectedCarrierLoader: false,
  boundedPolicyDetails: {},
  switchFromDriver: {},
  enableRatePage: false,
  updateSelectedProductLoader: false,
  requoteLobs: [],
  isSaveApplicantError: false,
  allowEditQuotes: true,
  pageTracking: {},
  prefillDetailsForRequestBind: {
    propertyData: {},
    label: {},
    updatedPropertyData: {},
  },
  redirectionStep: '',
  isHeapDetailLoaded: false,
  adhocOrganizationDetails: null,
  sfdcOrganizationDetails: null,
  isPrefillCall: false,
  requestBindDetails: null,
  lobRefreshLoader: false,
  requestBindLoader: false,
  emptyLobErrorMessage: '',
  isGetPolicyHeaderSuccess: false,
  quotingListErrorMessage: '',
};

const getActiveSteps = (page: string): any => {
  let steps;
  if (page === 'vehicles') {
    steps = { vehicles: true };
  } else if (page === 'drivers') {
    steps = {
      vehicles: true,
      drivers: true,
    };
  } else if (page === 'details') {
    steps = {
      vehicles: true,
      drivers: true,
      details: true,
    };
  } else if (page === 'quotes') {
    steps = {
      vehicles: true,
      drivers: true,
      details: true,
      quotes: true,
    };
  }
  return steps;
};

const getInitialState = (state: any) => ({
  ...state,
  loading: false,
  deleteDetailsLoader: false,
  stepSubmitLoader: false,
  homeAddressCheck: false,
  isValidDriverPresent: true,
  vehiclesMissing: false,
  getAutoQuotesFailed: false,
  AMSDetailsLoading: false,
  SfdcAMSDetailsLoading: false,
  isLOBAMSDetails: false,
  openAMSModal: false,
  carrierUnderwriterLoader: false,
  maxAttemptReached: false,
  AMSSuccess: false,
  isSfdcSubmitSuccess: false,
  AMSCommonError: '',
  SfdcAMSCommonError: '',
  disableDivision: false,
  AMSLOBDetails: {},
  AMSDetails: {
    ...INIT_STATE.AMSDetails,
  },
  sfdcAmsFormData: null,
});

const getUpdatedState = (state: any, key: string, value: any) => ({
  ...state,
  [key]: value,
});

const addNewProduct = (state: any, value: any) => ({
  ...state,
  newProducts: value,
  isAddingProducts: true,
});

const getCarrierUnderwriterListState = (state: any, payload: any) => ({
  ...state,
  AMSDetails: {
    ...state.AMSDetails,
    carrierUnderwriterOptions: [...payload],
  },
});

const getCarrierUnderwriterListForSfdcState = (state: any, payload: any) => ({
  ...state,
  sfdcAmsFormData: {
    ...state.sfdcAmsFormData,
    carrierUnderwriterOptions: [...payload],
  },
});

const getAMSDetailsState = (state: any, payload: any) => ({
  ...state,
  sfdcOrganizationDetails: {},
  AMSDetails: {
    ...state.AMSDetails,
    ...payload,
  },
});

const getSfdcAMSFormDetailsState = (state: any, payload: any) => ({
  ...state,
  sfdcAmsFormData: {
    ...state.sfdcAmsFormData,
    ...payload,
  },
});

const getAMSLOBDetailsState = (state: any, payload: any) => ({
  ...state,
  AMSLOBDetails: {
    ...state.AMSLOBDetails,
    ...payload,
  },
});

const getRedirectedFromState = (state: any, payload: any) => ({
  ...state,
  redirectedFrom: [...state.redirectedFrom, payload],
});

const checkOtherActions = (state: any, action: any) => {
  switch (action.type) {
    case types.SET_POLICY_BOUNDED: {
      return getUpdatedState(state, 'policyBounded', action.payload);
    }

    case types.SHOW_AMS_MODAL: {
      return getUpdatedState(state, 'openAMSModal', action.payload);
    }

    case types.SET_AMS_LOB_DETAILS: {
      return getAMSLOBDetailsState(state, action.payload);
    }

    default:
      return state;
  }
};

// tslint:disable-next-line:cyclomatic-complexity
export default (state = INIT_STATE, action: AppRedux.Action): HelperState => {
  switch (action.type) {
    case types.APP_INIT: {
      return getInitialState(state);
    }

    case types.AUTOLINE_DETAILS_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case types.SET_PCONFIG: {
      const { pconfig, herd_id, defaultPConfig } = action.payload;
      return {
        ...state,
        pconfig: {
          ...pconfig,
          herd_id,
          ...(!isEmpty(defaultPConfig) ? { defaultPConfig: { ...defaultPConfig } } : {}),
        },
        ...(!isEmpty(defaultPConfig) && { defaultPConfig: { ...defaultPConfig } }),
      };
    }

    case types.SET_VALID_DRIVER_FLAG: {
      return {
        ...state,
        isValidDriverPresent: action.payload,
      };
    }

    case types.UPDATE_PCONFIG: {
      return {
        ...state,
        pconfig: {
          ...state.pconfig,
          ...action.payload,
        },
      };
    }

    // On each step form submit show loader
    case types.STEP_SUBMITTED_LOADER: {
      return {
        ...state,
        stepSubmitLoader: action.payload,
      };
    }

    case types.STEP_CHANGED: {
      const { entity, value } = action.payload;
      const activeSteps = getActiveSteps(entity);
      return {
        ...state,
        completed_steps: {
          ...state.completed_steps,
          ...activeSteps,
          [entity]: value,
        },
      };
    }

    // On confirmation model delete for list
    case types.DELETE_DETAILS_LOADER: {
      return {
        ...state,
        deleteDetailsLoader: action.payload,
      };
    }

    case types.SET_EDIT_VEHICLE_FLAG: {
      return {
        ...state,
        isVehicleEdit: action.payload,
      };
    }

    case types.SET_HOMEQUOTE_FLAG: {
      return {
        ...state,
        homeQuote: action.payload,
      };
    }

    case types.SET_HOMEADDRESS_CHECK: {
      return {
        ...state,
        homeAddressCheck: action.payload,
      };
    }

    // Set missing spouse flag for primary driver
    case types.SET_MISSING_SPOUSE: {
      return {
        ...state,
        spouseMissing: action.payload,
      };
    }

    case types.MISSING_DETAILS_DRIVERS: {
      return {
        ...state,
        missingDetailsDrivers: [...action.payload],
      };
    }

    case types.SET_VEHICLES_MISSING: {
      return {
        ...state,
        vehiclesMissing: action.payload,
      };
    }

    case types.SET_ORG_CONFIG_LOADER: {
      return {
        ...state,
        orgConfigLoader: action.payload,
      };
    }

    case types.HOME_REDIRECT_FLAG: {
      return {
        ...state,
        isHomeRedirect: action.payload,
        AMSSuccess: false,
        disableDivision: false,
      };
    }

    case types.AUTO_REDIRECT_FLAG: {
      return {
        ...state,
        isAutoRedirect: action.payload,
        AMSSuccess: false,
        disableDivision: false,
      };
    }

    case types.GET_AUTO_QUOTES_FAILED: {
      return {
        ...state,
        getAutoQuotesFailed: action.payload,
      };
    }

    case types.SET_AMS_DETAILS_LOADER: {
      return getUpdatedState(state, 'AMSDetailsLoading', action.payload);
    }

    case types.SET_SFDC_AMS_DETAILS_LOADER: {
      return getUpdatedState(state, 'SfdcAMSDetailsLoading', action.payload);
    }

    case types.SET_AMS_ERROR: {
      return getUpdatedState(state, 'AMSCommonError', action.payload);
    }

    case types.SET_SFDC_AMS_ERROR: {
      return getUpdatedState(state, 'SfdcAMSCommonError', action.payload);
    }

    case types.SET_AMS_DETAILS: {
      return getAMSDetailsState(state, action.payload);
    }

    case types.SET_CARRIER_UNDERWRITER_LIST: {
      return getCarrierUnderwriterListState(state, action.payload);
    }

    case types.SET_CARRIER_UNDERWRITER_LIST_FOR_SFDC: {
      return getCarrierUnderwriterListForSfdcState(state, action.payload);
    }

    case types.SET_MAX_ATTEMPT_REACHED: {
      return getUpdatedState(state, 'maxAttemptReached', action.payload);
    }

    case types.SET_CARRIER_UNDERWRITER_LOADER: {
      return getUpdatedState(state, 'carrierUnderwriterLoader', action.payload);
    }

    case types.AMS_DETAILS_SUCCESS: {
      return getUpdatedState(state, 'AMSSuccess', action.payload);
    }

    case types.IS_SFDC_SUBMIT_SUCCESS: {
      return getUpdatedState(state, 'isSfdcSubmitSuccess', action.payload);
    }

    case types.DISABLE_DIVISION: {
      return getUpdatedState(state, 'disableDivision', action.payload);
    }

    case types.SET_LINE_OF_BUSINESS: {
      return getUpdatedState(state, 'lineOfBusiness', action.payload);
    }

    case types.SET_REDIRECTED_FROM: {
      return getRedirectedFromState(state, action.payload);
    }

    case types.SHOW_LOB_AMS_DETAILS: {
      return getUpdatedState(state, 'isLOBAMSDetails', action.payload);
    }

    case types.ADD_NEW_PRODUCT_TO_APPOINTMENTS_HELPER: {
      return addNewProduct(state, action.payload.helperAppointments);
    }

    case types.CLEAN_APPOINTMENTS_HELPER: {
      return {
        ...state,
        newProducts: {},
        isAddingProducts: false,
      };
    }
    case types.SET_REDIRECT_TO_VIEW_QUOTE_VALUE: {
      return {
        ...state,
        redirectToViewQuote: action.payload,
      };
    }

    case types.SHOW_GET_QUOTE_BUTTON_FLAG: {
      return {
        ...state,
        showGetQuoteButton: action.payload,
      };
    }

    case types.SET_SELECTED_BUSINESS_OPTION: {
      return {
        ...state,
        selectedBusinessOption: action.payload,
      };
    }

    case types.SET_FORM_HAS_CHANGED: {
      return {
        ...state,
        has_form_changed: action.payload,
      };
    }

    case types.SET_POLICY_CARRIER_RESPONSE: {
      return {
        ...state,
        isGetPolicyHeaderSuccess: action.payload,
      };
    }

    case types.SET_APPLICANT_FORM_CHANGE: {
      return {
        ...state,
        applicantFormChange: action.payload,
      };
    }

    case types.SET_IS_VERISK_FETCHED: {
      return {
        ...state,
        isVeriskFetched: action.payload,
      };
    }

    case types.SET_IS_VERISK_LOADING: {
      return {
        ...state,
        isVeriskLoading: action.payload,
      };
    }

    case types.SET_SHOW_VERISK_VEHICLE_MODAL: {
      return {
        ...state,
        showVeriskVehicleModal: action.payload,
      };
    }

    case types.SET_SHOW_VERISK_DRIVER_MODAL: {
      return {
        ...state,
        showVeriskDriverModal: action.payload,
      };
    }

    case types.SET_SELECT_FIRST_DRIVER: {
      return {
        ...state,
        selectFirstDriver: action.payload,
      };
    }

    case types.VIEW_POLICY_BOUND_LOADER: {
      return {
        ...state,
        viewBoundedPolicyLoader: action.payload,
      };
    }

    case types.STORE_BOUNDED_POLICY_DATA: {
      return {
        ...state,
        boundedPolicyData: action.payload,
      };
    }

    case types.BOUNDED_POLICY_DETAILS: {
      return {
        ...state,
        boundedPolicyDetails: action?.payload?.isAmsBind
          ? { ...action?.payload, ...state?.boundedPolicyDetails }
          : action?.payload,
      };
    }

    case types.CLEAR_HELPERS: {
      return {
        ...INIT_STATE,
      };
    }

    case types.UPDATE_SELECTED_CARRIERS: {
      return {
        ...state,
        selectedCarrierLoader: false,
        selectedCarriers: action.payload,
      };
    }

    case types.SAVE_SELECTED_CARRIER_LOADER: {
      return {
        ...state,
        selectedCarrierLoader: action.payload,
      };
    }

    case types.SET_SWITCH_DETAIL_FROM_DRIVER: {
      return {
        ...state,
        switchFromDriver: action.payload,
      };
    }

    case types.QUOTING_ORGANIZATION_LIST_LOADER: {
      return {
        ...state,
        quotingOrganizationlistLoader: action.payload,
      };
    }

    case types.STORE_QUOTING_ORGANIZATION_LIST: {
      return {
        ...state,
        quotingOrganizationList: action.payload,
      };
    }

    case types.STORE_QUOTING_ORGANIZATION_LIST_ERROR: {
      return {
        ...state,
        quotingListErrorMessage: action.payload,
      };
    }

    case types.SET_RATE_PAGE_ENABLE: {
      return {
        ...state,
        enableRatePage: action.payload,
      };
    }

    case types.STORE_EMPTY_LOB_ERROR_MESSAGE: {
      return {
        ...state,
        emptyLobErrorMessage: action.payload,
      };
    }

    case types.UPDATE_SELECTED_PRODUCT_LOADER: {
      return {
        ...state,
        updateSelectedProductLoader: action.payload,
      };
    }

    case types.UPDATE_REQUOTE_PRODUCTS: {
      return {
        ...state,
        requoteLobs: action.payload,
      };
    }

    case types.SAVE_APPLICANT_ERROR: {
      return {
        ...state,
        isSaveApplicantError: action.payload,
      };
    }

    case types.STORE_COMMON_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.SET_ALLOW_EDIT_QUOTES: {
      return {
        ...state,
        allowEditQuotes: action.payload,
      };
    }

    case types.SET_PAGE_TRACKING: {
      return {
        ...state,
        pageTracking: action.payload,
      };
    }
    case types.SET_HEAP_DETAILS_LOADED: {
      return {
        ...state,
        isHeapDetailLoaded: action.payload,
      };
    }

    case types.SET_LOB_PRIORITY: {
      return {
        ...state,
        redirectionStep: action.payload,
      };
    }

    case types.SET_ADHOC_ORGANIZATION_DETAILS: {
      return {
        ...state,
        adhocOrganizationDetails: action.payload,
      };
    }

    case types.SET_SFDC_ORGANIZATION_DETAILS: {
      return {
        ...state,
        sfdcOrganizationDetails: action.payload,
      };
    }

    case types.SET_SFDC_AMS_FORM_DATA: {
      return getSfdcAMSFormDetailsState(state, action.payload);
    }

    case types.SET_PREFILL_CALL: {
      return {
        ...state,
        isPrefillCall: action.payload,
      };
    }

    case types.SET_REQUEST_BIND_RESPONSE_DETAILS: {
      return {
        ...state,
        requestBindDetails: action.payload,
      };
    }

    case types.REQUEST_BIND_FOR_WHOLESALE_LOADER: {
      return {
        ...state,
        requestBindLoader: action.payload,
      };
    }

    case types.SET_LOB_REFRESH_LOADER: {
      return {
        ...state,
        lobRefreshLoader: action.payload,
      };
    }

    case types.SET_PREFILL_DETAILS_FOR_REQUEST_BIND: {
      const { propertyData, label } = action.payload;
      return {
        ...state,
        prefillDetailsForRequestBind: {
          ...state.prefillDetailsForRequestBind,
          propertyData: {
            ...state?.prefillDetailsForRequestBind?.propertyData,
            ...propertyData,
          },
          label: { ...state?.prefillDetailsForRequestBind?.label, ...label },
        },
      };
    }

    case types.SET_ORGANIZATION_ID: {
      return {
        ...state,
        pconfig: {
          ...state.pconfig,
          pconfig: {
            ...state.pconfig?.pconfig,
            id: action.payload,
          },
        },
      };
    }

    default:
      return checkOtherActions(state, action);
  }
};
