import React from 'react';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import queryString from 'query-string';
import remove from 'lodash/remove';
import {
  DOLLAR_ZERO,
  ERROR_TEXT_MAX_LENGTH,
  INSURANCE_PRODUCTS,
  QUOTES_LOADING_MESSAGE,
} from '../../../constants';
import {
  flushQuoteDetails,
  initAutoQuotes,
  initViewQuotes,
  setAMSDetails,
  setAutoRedirectFlag,
  setLineOfBusiness,
  setQuotesLoaded,
  setQuotesLoading,
  setRedirectedFrom,
  stepChanged,
  stopPolling,
} from '../../redux/actions';
import {
  formatNumberToCurrency,
  numberFormatter,
  numberFormatterWithDecimal,
} from '../../../utils';
import HomeIcon from '../../../assets/images/home.svg';
import HomeIconFc from '../../../assets/images/home-fc.svg';
import HomeQuoteLegend from '../../components/home-quote-legend';
import Loader from '../../components/loader';
import OtherQuotesButton from '../../components/other-quotes-button';
import PropertyError from '../../components/property-error';
import PolicyBoundRibbon from '../../../assets/images/policy-bound-ribbon.png';
import config from '../../../config/config';
import { FIRST_CONNECT_THEME } from '../../../constants';
import './quotes.scss';
const homeButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? HomeIconFc : HomeIcon;

class AutoQuotes extends React.PureComponent<
  AppComponents.AutoQuotesProps,
  AppComponents.AutoQuotesState
> {
  state: AppComponents.AutoQuotesState = {
    expandedRows: [],
    quoteList: [],
    quoteErrorList: [],
    isViewQuotes: false,
    expandedErrorText: [],
  };

  getDeductibleValue(deductibleValue: any): number | string {
    let value: number | string = 0;
    if (
      String(deductibleValue).toLowerCase() === 'none' ||
      String(deductibleValue).toLowerCase() === 'no coverage'
    ) {
      value = '-NA-';
    } else {
      if (
        Number.isInteger(parseInt(deductibleValue)) &&
        !String(deductibleValue).includes('/') &&
        !String(deductibleValue).includes('CSL')
      ) {
        value = `$${numberFormatterWithDecimal(String(deductibleValue).replace(/,/g, ''))}`;
      } else {
        value = deductibleValue;
      }
    }
    return value;
  }

  getCoverageValue(coverageValue: any): number | string {
    let value: number | string = 0;
    if (
      String(coverageValue).toLowerCase() === 'none' ||
      String(coverageValue).toLowerCase() === 'no coverage'
    ) {
      value = '-NA-';
    } else {
      if (
        Number.isInteger(parseInt(coverageValue)) &&
        !String(coverageValue).includes('/') &&
        !String(coverageValue).includes('CSL')
      ) {
        value = `$${numberFormatter(String(coverageValue).replace(/,/g, ''))}`;
      } else {
        value = coverageValue;
      }
    }
    return value;
  }

  componentDidMount() {
    const { location } = this.props;
    const { herd_id } = queryString.parse(location.search);
    this.setState({ ...this.state });
    if (!isEmpty(herd_id)) {
      this.props.stopPolling();
      this.props.flushQuoteDetails();
      this.setState({ isViewQuotes: true }, () => this.props.initViewQuotes(herd_id as string));
    }
    this.props.setAMSDetails({
      selectedFloodQuote: null,
    });
  }

  UNSAFE_componentWillMount() {
    const { history, quotesLoaded, quotingDetails, quotesLoading } = this.props;
    const { herd_id } = queryString.parse(this.props.location.search);
    this.props.stepChanged({ entity: 'quotes', value: true });
    if (isEmpty(herd_id) && !quotesLoading) {
      if (!quotesLoaded) {
        this.props.stopPolling();
        this.props.flushQuoteDetails();
        this.props.initAutoQuotes();
      } else {
        this.setState({ isViewQuotes: true }, () => {
          quotingDetails && history.push({ search: `?herd_id=${quotingDetails.herd_id}` });
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.setQuotesLoading(false);
    this.props.stopPolling();
    this.props.setQuotesLoaded(false);
  }

  handleExpandedErrorText = (expandedErrorText: any, key: any) => {
    let updatedexpandedErrorText;
    if (expandedErrorText.length > 0 && expandedErrorText.includes(key)) {
      updatedexpandedErrorText = remove(expandedErrorText, rowIndex => rowIndex !== key);
    } else {
      updatedexpandedErrorText = [...expandedErrorText, key];
    }
    this.setState({
      expandedErrorText: [...updatedexpandedErrorText],
    });
  };

  getDeductibleTableDetails = (quoteList: any, expandedRows: any) => {
    if (quoteList.length > 0) {
      return map(quoteList, (quote, key) => (
        <div
          key={`quote-container-${key}`}
          className='container content-wrapper-grey mx-w mb-4 d-grid'
        >
          <div className='quote-content-box p-0'>
            <div className='row result-col m-0'>
              <div className='col-md-12 col-lg-2 col-img '>
                <img
                  className='img-fluid '
                  src={get(quote, 'logo_url', '')}
                  alt={get(quote, 'carrier_name', '')}
                  title={get(quote, 'carrier_name', '')}
                />
                {get(quote, 'policyBounded', false) && (
                  <img
                    src={PolicyBoundRibbon}
                    alt='policy bind'
                    className='img-fluid policy-bound-ribbon-auto'
                  />
                )}
              </div>

              <div className='col-md-12 p-0 col-lg-5 details-div align-self-center'>
                <div className='div1'>
                  <div className='coverage-label'>Liability (BI)</div>
                  <div className='card-font'>{this.getCoverageValue(quote.cov_bi)}</div>
                </div>
                <div className='div1'>
                  <div className='coverage-label'>Property Damage</div>
                  <div className='card-font'>{this.getCoverageValue(quote.cov_pd)}</div>
                </div>
                <div className='div1'>
                  <div className='coverage-label'>Medical Payments</div>
                  <div className='card-font'>{this.getCoverageValue(quote.cov_mp)}</div>
                </div>
              </div>
              <div className='col-lg-5 p-0'>
                <div className='row center-content h-100 ml-0 mr-0'>
                  <div className='col-lg-4 padding-3 h-100 green-div-bg'>
                    <div className='price-div-padding w-100'>
                      <p className='coverage-label'>Price </p>

                      <div className='price-font'>
                        {!!quote?.price ? formatNumberToCurrency(quote.price) : DOLLAR_ZERO}
                      </div>

                      <p className='coverage-label'>{quote.term_lable}</p>
                    </div>
                  </div>
                  <div className='col-lg-8 align-self-center'>
                    <button
                      className='purchase-btn'
                      onClick={() => {
                        window.open(get(quote, 'agent_url', ''), '_blank');
                      }}
                    >
                      {quote.purchase_type.toUpperCase()}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`row m-0 p-0 view-details-wrap ${
              expandedRows.includes(key) ? 'expand-wrap' : ''
            }`}
          >
            <div className='col-lg-9 details-div p-0'>
              <table className='table details-table table-responsive-md'>
                <thead>
                  <tr>
                    <td></td>
                    <td>
                      <div className='details-label'>Comprehensive Deductible</div>
                    </td>
                    <td>
                      <div className='details-label'>Collision Deductible</div>
                    </td>
                    <td>
                      <div className='details-label'>Uninsured Motorist</div>
                    </td>
                    <td>
                      <div className='details-label'>Towing and Labor</div>
                    </td>
                    <td>
                      <div className='details-label'>Ext Trans Expense</div>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {map(quote.vehichle_deductible_other, (deductible, deductibleKey) => {
                    const {
                      Year,
                      Make,
                      Model,
                      comprehensive_deductible,
                      collision_deductible,
                      towing_labor,
                      extranse_expense,
                    } = deductible;
                    return (
                      <tr className='white-row' key={deductibleKey}>
                        <td>
                          <p>{`${Year} ${Make} ${Model}`}</p>
                        </td>
                        <td>
                          <div className='white-div-label'>
                            {this.getDeductibleValue(comprehensive_deductible)}
                          </div>
                        </td>
                        <td>
                          <div className='white-div-label'>
                            {this.getDeductibleValue(collision_deductible)}
                          </div>
                        </td>
                        <td>
                          <div className='white-div-label'>
                            {this.getCoverageValue(quote.cov_um)}
                          </div>
                        </td>
                        <td>
                          <div className='white-div-label'>
                            {this.getDeductibleValue(towing_labor)}
                          </div>
                        </td>
                        <td>
                          <div className='white-div-label'>
                            {this.getDeductibleValue(extranse_expense)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {!isEmpty(get(quote, 'applied_discounts', null)) && (
              <div className='col-md-12 col-lg-3 discount-div align-self-center'>
                <p className='discount-head'>Discounts Applied</p>
                <ul className='ml-2'>
                  {map(quote.applied_discounts, (quoteData, quoteDataKey) => (
                    <li key={`discount-${quoteDataKey}`}>{quoteData.Discount}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className='row m-0'>
            <div className='col-lg-2'>
              <div
                className='view-details-span'
                onClick={() => {
                  let updatedExpandedRows;
                  if (expandedRows.length > 0 && expandedRows.includes(key)) {
                    updatedExpandedRows = remove(expandedRows, rowIndex => rowIndex !== key);
                  } else {
                    updatedExpandedRows = [...expandedRows, key];
                  }
                  this.setState({
                    expandedRows: [...updatedExpandedRows],
                  });
                }}
              >
                {expandedRows.includes(key) ? 'Close' : 'View'} Details
              </div>
            </div>
            <div className='col-md-12 col-lg-5 p-0'></div>
            <div className='col-lg-5 p-0'>
              <div className='row ml-0 mr-0'>
                <div className='col-lg-4'>
                  <span className='green-seperator'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
    return '';
  };

  showBackButton() {
    return !this.state.isViewQuotes && !this.props.policyBounded;
  }

  getHomeQuoteButton() {
    return (
      <Button
        variant='outlined'
        className='btnHomeQuotes ml-1'
        onClick={() => {
          this.props.history.push({
            pathname: '/home',
            search: `?code=${get(this.props, 'pconfig.pconfig.code')}`,
            state: {
              isRedirectedFromAuto: true,
            },
          });
          this.props.setAutoRedirectFlag(true);
          this.props.setRedirectedFrom(this.props.lineOfBusiness);
          this.props.setLineOfBusiness(INSURANCE_PRODUCTS[0]);
          window.scrollTo(0, 0);
        }}
      >
        <img alt='home quote' className='img-fluid' src={homeButtonIcon} />
        <span style={{ verticalAlign: 'middle' }}>Home Quote</span>
      </Button>
    );
  }

  otherQuoteSelected = (product: any) => {
    this.props.history.push({
      pathname: '/home',
      search: `?code=${get(this.props, 'pconfig.pconfig.code')}`,
      state: {
        isRedirectedFromAuto: true,
      },
    });
    this.props.setAutoRedirectFlag(true);
    this.props.setRedirectedFrom(this.props.lineOfBusiness);
    this.props.setLineOfBusiness(product.value);
    window.scrollTo(0, 0);
  };

  getOtherQuotesButton() {
    return <OtherQuotesButton onSelect={this.otherQuoteSelected} />;
  }

  getHomeQuoteContent() {
    if (!this.props.redirectedFrom.includes(INSURANCE_PRODUCTS[0])) {
      return <React.Fragment>{this.getHomeQuoteButton()}</React.Fragment>;
    }
    return '';
  }

  getErrorContainerClasses = () =>
    `col-md-12 col-lg-10 details-div align-self-center error-content p-2`;

  render() {
    const { pconfig, quoteList, quotesLoading, quoteErrorList, getAutoQuotesFailed } = this.props;
    const { expandedRows, expandedErrorText } = this.state;
    return (
      <div className='autoline-steps autoline-step-1-wrapper auto-quotes-wrapper'>
        {!quotesLoading && <HomeQuoteLegend showLegends={false} />}

        <section className='quotes-sec'>
          <React.Fragment>
            {quotesLoading && (
              <BlockUi
                className='quote-loader'
                tag='div'
                blocking={true}
                message={QUOTES_LOADING_MESSAGE}
                renderChildren={true}
                defaults=''
                loader={<Loader />}
              />
            )}

            {this.getDeductibleTableDetails(quoteList, expandedRows)}
          </React.Fragment>
        </section>

        {getAutoQuotesFailed && (
          <section className='quotes-sec'>
            <PropertyError
              {...({
                pstate: {
                  pconfig: { ...pconfig.pconfig },
                  defaultPConfig: { ...pconfig.defaultPConfig },
                },
              } as any)}
            />
          </section>
        )}

        <section className='quotes-error-sec'>
          <React.Fragment>
            {quoteErrorList.length > 0 &&
              map(quoteErrorList, (quote, key) => (
                <div
                  key={`quote-container-${key}`}
                  className={`container content-wrapper-grey mx-w d-grid ${
                    key === quoteErrorList.length - 1 ? 'mb-0' : 'mb-5'
                  }`}
                >
                  <div className={`row quote-content-box p-0 quote-container-${key}`}>
                    <div className='col-md-12 col-lg-2 col-img '>
                      <img
                        className='img-fluid'
                        src={get(quote, 'logo_url', '')}
                        alt={get(quote, 'Carrier', '')}
                        title={get(quote, 'tooltip', '')}
                      />
                    </div>

                    <div className={this.getErrorContainerClasses()}>
                      {quote.StatusText.length > ERROR_TEXT_MAX_LENGTH ? (
                        <div>
                          {expandedErrorText.includes(key) ? (
                            <React.Fragment>
                              <span>{quote.StatusText}</span>{' '}
                              <span
                                className='error-status-text'
                                onClick={() => this.handleExpandedErrorText(expandedErrorText, key)}
                              >
                                Less More
                              </span>{' '}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span>{quote.StatusText.substring(0, ERROR_TEXT_MAX_LENGTH)}</span>
                              <span>
                                {'...'}
                                &nbsp;&nbsp;
                              </span>
                              <span
                                className='error-status-text'
                                onClick={() => this.handleExpandedErrorText(expandedErrorText, key)}
                              >
                                Show More
                              </span>{' '}
                            </React.Fragment>
                          )}{' '}
                        </div>
                      ) : (
                        <span>{quote.StatusText}</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </React.Fragment>
        </section>

        {!quotesLoading && (
          <section className='btn-section'>
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='btnwrap'>
                    {this.showBackButton() && (
                      <Button
                        variant='outlined'
                        className='btnBack'
                        onClick={() => {
                          const { history } = this.props;
                          this.props.stopPolling();
                          this.props.flushQuoteDetails();
                          // todo: check this
                          history.push('details');
                        }}
                      >
                        <span className='link-label'>Back</span>
                      </Button>
                    )}

                    {this.getHomeQuoteContent()}

                    {this.getOtherQuotesButton()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ common, quote }: AppComponents.AutoQuotesStore) => {
  const {
    pconfig,
    isHomeRedirect,
    getAutoQuotesFailed,
    policyBounded,
    lineOfBusiness,
    redirectedFrom,
  } = common;
  const { quoteList, quotesLoading, quotesLoaded, quotingDetails, quoteErrorList } = quote;
  return {
    pconfig,
    quoteList,
    quotesLoading,
    quotesLoaded,
    quotingDetails,
    quoteErrorList,
    isHomeRedirect,
    getAutoQuotesFailed,
    policyBounded,
    lineOfBusiness,
    redirectedFrom,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AutoQuotesDispatch => {
  return bindActionCreators(
    {
      stepChanged,
      initAutoQuotes,
      stopPolling,
      flushQuoteDetails,
      setQuotesLoading,
      initViewQuotes,
      setQuotesLoaded,
      setAutoRedirectFlag,
      setRedirectedFrom,
      setLineOfBusiness,
      setAMSDetails,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoQuotes);
