import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, startCase } from 'lodash';
import { InputLabel, NativeSelect, Switch } from '@material-ui/core';
import {
  fetchAccountRecordTypeList,
  fetchLeadTypeListData,
  setOrgAccountsListData,
} from '../../redux/actions';
import { withStyles } from '@material-ui/core/styles';
import { SELECT, SFDC_KEY } from '../../../constants';

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#ccc',
    '&$checked': {
      color: '#00cf83',
    },
    '&$checked + $track': {
      backgroundColor: '#00cf83',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ViewCrmDetails = (props: AppComponents.ViewCrmDetailsProps) => {
  const { editOrganizationData, organizations } = props;
  useEffect(() => {
    if (!organizations?.getOrganizationLoader) {
      const { accountRecordTypeList, leadTypeList } = organizations;
      if (!!editOrganizationData?.salesforce_enabled) {
        isEmpty(leadTypeList) && props.fetchLeadTypeListData();
        isEmpty(accountRecordTypeList) && props.fetchAccountRecordTypeList();
        if (
          editOrganizationData?.crm_account_id &&
          !isEmpty(editOrganizationData?.crm_account_name)
        ) {
          props.setOrgAccountsListData([
            { value: '', label: startCase(SELECT) },
            {
              value: editOrganizationData?.crm_account_id,
              label: editOrganizationData?.crm_account_name,
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line
  }, [organizations?.addEditOrgLoader, organizations?.getOrganizationLoader]);

  const getDropDownValue = (dropDownList: any) => {
    return (
      <>
        {!isEmpty(dropDownList)
          ? dropDownList.map((item: any, index: number) => (
              <option value={item.id} key={index}>
                {item.name}
              </option>
            ))
          : ''}
      </>
    );
  };

  return (
    <div className='row mt-3'>
      <div className='col-lg-8'>
        <div className='justify-content-lg-start form-item'>
          <InputLabel className='form-item__label width-25-100'>
            {SFDC_KEY.ENABLE_SFDC_INTEGRATION_LABEL}:
          </InputLabel>

          <CustomSwitch
            checked={!!editOrganizationData?.salesforce_enabled ? true : false}
            name={SFDC_KEY.SALES_FORCE_ENABLED_KEY}
            disabled={true}
          />
        </div>
      </div>
      <div className='col-lg-8 mt-3'>
        <div className='justify-content-lg-start form-item'>
          <InputLabel className='form-item__label width-25-100'>
            {SFDC_KEY.ALLOW_EDITING_QUOTES_LABEL}:
          </InputLabel>
          <CustomSwitch
            checked={!!editOrganizationData?.iframe_editing_enabled}
            name={SFDC_KEY.ALLOW_EDITING_QUOTES_KEY}
            disabled={true}
          />
        </div>
      </div>
      <div className='col-lg-8 mt-3'>
        <div className='justify-content-lg-start form-item'>
          <InputLabel className='form-item__label width-25-100'>
            {SFDC_KEY.ALLOW_ADDING_ADD_PRODUCT_LABEL}:
          </InputLabel>
          <CustomSwitch
            checked={!!editOrganizationData?.crm_allow_add_products}
            name={SFDC_KEY.ALLOW_ADDING_ADD_PRODUCT_KEY}
            disabled={true}
          />
        </div>
      </div>
      <div className='col-lg-8 mt-4'>
        <div className='justify-content-start form-item'>
          <InputLabel className='form-item__label width-25-100'>
            {SFDC_KEY.LEAD_RECORD_TYPE_LABEL}:
          </InputLabel>
          <NativeSelect
            className='w-30 native-select'
            value={
              !!editOrganizationData?.crm_lead_record_type_id
                ? editOrganizationData?.crm_lead_record_type_id
                : ''
            }
            inputProps={{
              name: SFDC_KEY.LEAD_TYPE_ID_KEY,
              id: SFDC_KEY.LEAD_TYPE_ID_KEY,
            }}
            disabled={true}
          >
            <option value=''>{startCase(SELECT)}</option>
            {getDropDownValue(organizations?.leadTypeList)}
          </NativeSelect>
        </div>
      </div>
      <div className='col-lg-8 mt-4'>
        <div className='justify-content-start form-item'>
          <InputLabel className='form-item__label width-25-100'>
            {SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_LABEL}:
          </InputLabel>
          <NativeSelect
            className='w-30 native-select'
            value={
              !!editOrganizationData?.crm_account_record_type_id
                ? editOrganizationData?.crm_account_record_type_id
                : ''
            }
            inputProps={{
              name: SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_KEY,
              id: SFDC_KEY.ACCOUNT_LEAD_RECORD_TYPE_KEY,
            }}
            disabled={true}
          >
            <option value=''>{startCase(SELECT)}</option>
            {getDropDownValue(organizations?.accountRecordTypeList)}
          </NativeSelect>
        </div>
      </div>
      <div className='col-lg-8 mt-4'>
        <div className='justify-content-start form-item'>
          <InputLabel className='form-item__label width-25-100'>
            {SFDC_KEY.ORGANIZATION_ACCOUNT_ID_LABEL}:
          </InputLabel>
          <NativeSelect
            className='w-30 native-select'
            value={editOrganizationData?.crm_account_id || ''}
            inputProps={{
              name: SFDC_KEY.ORGANIZATION_ACCOUNT_ID_KEY,
              id: SFDC_KEY.ORGANIZATION_ACCOUNT_ID_KEY,
            }}
            disabled={true}
          >
            <option value=''>{startCase(SELECT)}</option>
            {organizations?.orgAccountsList?.map((item: any) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </NativeSelect>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ organizations }: AppComponents.OrganizationDetailsStore) => {
  const { editOrganizationData } = organizations;
  return { organizations, editOrganizationData };
};

const mapDispatchToProps = (dispatch: any): AppComponents.ViewCrmDetailsDispatch => {
  return bindActionCreators(
    {
      fetchAccountRecordTypeList,
      fetchLeadTypeListData,
      setOrgAccountsListData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCrmDetails);
