import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, startCase } from 'lodash';
import MaterialTable from 'material-table';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { AssignmentTurnedInOutlined, FileCopyOutlined } from '@material-ui/icons';
import AddEditHcrIframeSettings from '../add-edit-hcr-iframe-setting';
import ConfirmationModal from '../../../components/confirmation-modal';
import { getCode, getIframeLobDropDown } from '../../utils';
import {
  deleteHcrIframeSetting,
  fetchHcrIFrameClient,
  setOrgUpdatedResponseData,
  storeHcrIframeSettingsList,
} from '../../../redux/actions';
import { arrayToStringObject, getNestedTernaryData, stringSort } from '../../../../utils';
import { tableIcons } from '../../../../utils/table-icons';
import {
  ACTION,
  ADD,
  DARK_GREEN_THEME,
  DELETE,
  EDIT,
  HCR_IFRAME_LABELS,
  HTML_EM,
  LOB_KEY,
  PHONE_NUMBER,
  PHONE_NUMBER_LABEL,
  THEME,
} from '../../../../constants';

const createData = (
  clientId: string,
  origins: any,
  status: string,
  theme: string,
  lob: string,
  phone_number: string,
  disclaimer: any,
  isCopyExternalCode: boolean,
  originsDetail: string
) => {
  return {
    clientId,
    origins,
    status,
    theme,
    lob,
    phone_number,
    disclaimer,
    isCopyExternalCode,
    originsDetail,
  };
};

export class HcrIframeGrid extends Component<
  AppComponents.HcrIframeGridProps,
  AppComponents.HcrIframeGridState
> {
  state: AppComponents.HcrIframeGridState = {
    columns: [
      {
        title: HCR_IFRAME_LABELS?.CLIENT_ID,
        field: HCR_IFRAME_LABELS?.CLIENT_ID_KEY,
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '125px',
          minWidth: '110px',
        },
        cellStyle: {
          minWidth: '125px',
          maxWidth: '110px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.clientId, b.clientId),
        render: (rowData: any) => {
          return (
            <span title={!isEmpty(rowData?.clientId) ? rowData.clientId : ''}>
              {!isEmpty(rowData?.clientId) ? rowData.clientId : HTML_EM}
            </span>
          );
        },
      },
      {
        title: HCR_IFRAME_LABELS?.ORIGINS,
        field: HCR_IFRAME_LABELS?.ORIGINS?.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '135px',
          maxWidth: '145px',
        },
        cellStyle: {
          minWidth: '135px',
          maxWidth: '145px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.originsDetail, b.originsDetail),
        render: (rowData: any) => {
          const { originsDetail } = rowData;
          return (
            <span title={!isEmpty(originsDetail) ? originsDetail : ''}>
              {!isEmpty(originsDetail) ? originsDetail : HTML_EM}
            </span>
          );
        },
      },
      {
        title: THEME?.LABEL,
        field: THEME?.LABEL?.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '95px',
          maxWidth: '100px',
        },
        cellStyle: {
          minWidth: '95px',
          maxWidth: '100px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.theme, b.theme),
        render: (rowData: any) => {
          let themeName: string = getNestedTernaryData(
            !isEmpty(rowData?.theme),
            getNestedTernaryData(
              rowData?.theme === DARK_GREEN_THEME.value,
              DARK_GREEN_THEME.label,
              startCase(rowData?.theme)
            ),
            ''
          );
          return (
            <span title={!isEmpty(themeName) ? startCase(themeName) : ''}>
              {!isEmpty(themeName) ? startCase(themeName) : HTML_EM}
            </span>
          );
        },
      },
      {
        title: LOB_KEY,
        field: LOB_KEY?.toLowerCase(),
        headerStyle: {
          whiteSpace: 'nowrap',
          maxWidth: '70px',
        },
        cellStyle: {
          minWidth: '70px',
          maxWidth: '80px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.lob, b.lob),
        render: (rowData: any) => {
          return (
            <span title={!isEmpty(rowData?.lob) ? startCase(rowData?.lob) : ''}>
              {!isEmpty(rowData?.lob) ? startCase(rowData?.lob) : HTML_EM}
            </span>
          );
        },
      },
      {
        title: PHONE_NUMBER_LABEL,
        field: PHONE_NUMBER,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '125px',
          maxWidth: '140px',
        },
        cellStyle: {
          minWidth: '125px',
          maxWidth: '140px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.phone_number, b.phone_number),
        render: (rowData: any) => {
          return (
            <span title={!isEmpty(rowData?.phone_number) ? rowData?.phone_number : ''}>
              {!isEmpty(rowData?.phone_number) ? rowData?.phone_number : HTML_EM}
            </span>
          );
        },
      },
      {
        title: HCR_IFRAME_LABELS?.COPY_CODE,
        field: HCR_IFRAME_LABELS?.DISCLAIMER,
        sorting: false,
        headerStyle: {
          whiteSpace: 'nowrap',
          minWidth: '90px',
          maxWidth: '100px',
        },
        cellStyle: {
          minWidth: '90px',
          maxWidth: '100px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        render: (rowData: any) => {
          const { isCopyExternalCode, clientId, lob } = rowData;
          let externalCode: any = !isEmpty(getCode(clientId, lob)) ? getCode(clientId, lob) : '';
          return (
            <div
              className='d-flex api-password-div align-items-center justify-content-center cursor-default'
              title={!isEmpty(externalCode) ? externalCode : ''}
            >
              <CopyToClipboard onCopy={() => this.onCopyExternalCode(rowData)} text={externalCode}>
                {isCopyExternalCode ? (
                  <AssignmentTurnedInOutlined className='cursor-pointer text-color' />
                ) : (
                  <FileCopyOutlined className='cursor-pointer text-color' />
                )}
              </CopyToClipboard>
            </div>
          );
        },
      },
    ],
    data: [],
    hcrIframeRowData: null,
    openAddHcrIframeModel: false,
    openEditHcrIframeModel: false,
    openDeleteHcrIframeModel: false,
  };

  componentDidMount = () => {
    const { editOrganizationData } = this.props;
    this.props?.fetchHcrIFrameClient(editOrganizationData?.id);
    this.mapHrcIframeSettingData(editOrganizationData?.hcrIframeClientList);
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { editOrganizationData, organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.closeModel();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
    this.mapHrcIframeSettingData(editOrganizationData?.hcrIframeClientList);
  };

  onCopyExternalCode = (rowData: any) => {
    const { clientId, isCopyExternalCode } = rowData;
    let hcrSettingsList = [...this.state.data];
    const hcrSettingsIndex = hcrSettingsList.findIndex(item => item?.clientId === clientId);
    if (hcrSettingsIndex !== -1) {
      hcrSettingsList[hcrSettingsIndex].isCopyExternalCode = !isCopyExternalCode;
    }
    this.setState((prevState: any) => ({
      ...prevState,
      data: hcrSettingsList,
    }));
  };

  mapHrcIframeSettingData = (list: Array<any>) => {
    let rowDetails: Array<any> = [];
    !isEmpty(list) &&
      list.forEach((item: any) => {
        let row = createData(
          !isEmpty(item?.id) ? item.id : '',
          !isEmpty(item?.origins) ? item.origins : '',
          !isEmpty(item?.status) ? item?.status : '',
          !isEmpty(item?.theme) ? item.theme : '',
          !isEmpty(item?.lob) ? item?.lob : '',
          item?.phone_number || '',
          !isEmpty(item.disclaimer) ? item.disclaimer : '',
          false,
          !isEmpty(item?.origins) ? arrayToStringObject(item?.origins) : ''
        );
        return rowDetails.push(row);
      });
    this.setState({
      data: rowDetails,
    });
  };

  onHcrIframeSettingAction = (type: any, rowData?: any) => {
    this.setState({
      openAddHcrIframeModel: type === ADD ? true : false,
      openEditHcrIframeModel: type === EDIT?.toLowerCase() ? true : false,
      openDeleteHcrIframeModel: type === DELETE?.toLowerCase() ? true : false,
      hcrIframeRowData: !!rowData ? rowData : null,
    });
  };

  closeModel = () => {
    this.setState({
      openAddHcrIframeModel: false,
      openEditHcrIframeModel: false,
      openDeleteHcrIframeModel: false,
      hcrIframeRowData: null,
    });
  };

  deletHcrClientSettingsData = (data: any) => {
    if (!!data) {
      const { hcrIframeRowData } = this.state;
      let payload = {
        id: hcrIframeRowData?.clientId,
      };
      this.props.deleteHcrIframeSetting({ ...payload });
    }
    this.closeModel();
  };

  componentWillUnmount = () => {
    this.props.storeHcrIframeSettingsList([]);
  };

  render() {
    const {
      columns,
      data,
      openAddHcrIframeModel,
      openEditHcrIframeModel,
      hcrIframeRowData,
      openDeleteHcrIframeModel,
    } = this.state;
    const { organizations } = this.props;
    let lobDropDownList: Array<any> = getIframeLobDropDown();
    return (
      <div className='container carrier-wrapper carrier-appointments-wrapper mt-5'>
        <div className='carrier-apooinment-table iframe-setting-tbl'>
          <MaterialTable
            title={
              !isEmpty(lobDropDownList) ? (
                <div className='new-org-btn-div'>
                  <Button
                    variant='outlined'
                    className='new-org-btn'
                    onClick={() => this.onHcrIframeSettingAction(ADD, null)}
                  >
                    <AddIcon className='add-icon-border mr-3' />
                    {HCR_IFRAME_LABELS?.ADD_IFRAME_SETTINGS}
                  </Button>
                </div>
              ) : (
                ''
              )
            }
            icons={tableIcons}
            columns={columns}
            data={data}
            localization={{
              header: {
                actions: ACTION,
              },
            }}
            options={{
              draggable: false,
              search: true,
              paging: true,
              headerStyle: {
                fontSize: '14px',
                color: 'black',
                position: 'inherit',
              },
              actionsColumnIndex: -1,
              pageSizeOptions: [],
            }}
            isLoading={!!organizations?.hcrIframeClientListLoader}
            actions={[
              {
                icon: () => <span className='icon-edit1 edit-svg-tbl text-color'></span>,
                tooltip: EDIT,
                onClick: (_e, rowData: any) =>
                  this.onHcrIframeSettingAction(EDIT?.toLowerCase(), rowData),
              },
              {
                icon: () => <span className='icon-delete edit-svg-tbl text-color'></span>,
                tooltip: DELETE,
                onClick: (_e, rowData: any) =>
                  this.onHcrIframeSettingAction(DELETE?.toLowerCase(), rowData),
              },
            ]}
          />
          {(openAddHcrIframeModel || openEditHcrIframeModel) && (
            <>
              <AddEditHcrIframeSettings
                onCloseModal={this.closeModel}
                openEditHcrIframeModel={openEditHcrIframeModel}
                hcrIframeRowData={hcrIframeRowData}
              />
            </>
          )}
          {openDeleteHcrIframeModel && (
            <ConfirmationModal
              open={openDeleteHcrIframeModel}
              closehandler={this.deletHcrClientSettingsData}
              heading={''}
              message={''}
              contentValue={!isEmpty(hcrIframeRowData?.lob) ? startCase(hcrIframeRowData?.lob) : ''}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.HcrIframeGridStore) => {
  const { editOrganizationData } = organizations;
  return { organizations, editOrganizationData };
};

const mapDispatchToProps = (dispatch: any): AppComponents.HcrIframeGridDispatch => {
  return bindActionCreators(
    {
      deleteHcrIframeSetting,
      fetchHcrIFrameClient,
      setOrgUpdatedResponseData,
      storeHcrIframeSettingsList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HcrIframeGrid);
