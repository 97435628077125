import React, { useEffect, useRef } from 'react';
import { isEmpty, map, startCase } from 'lodash';
import { FormControl, FormHelperText, Grid, Input, Select } from '@material-ui/core';
import DatePicker from '../../../date-picker';
import { getNestedTernaryData, numberRegex } from '../../../../../utils';
import { filterUniqueCodeString, getUniqueKeyForQuestionnaire } from '../../../../../utils/umbrella';
import { SELECT } from '../../../../../constants';
import {
  OFF,
  UMBRELLA_QUESTION_FIELD_TYPES,
} from '../../../../../constants/umbrella-questionnaire';
import './question-handler.scss';

const { DATE_PICKER, DROPDOWN, INTEGER, TEXT_SINGLE } = UMBRELLA_QUESTION_FIELD_TYPES;

export const QuestionHandler = ({ question, onAnswer, errorQuestionKeys }: AppComponents.QuestionHandlerProps) => {
  const {
    options,
    longLanguage,
    helpLanguage,
    required,
    type,
    response,
    validationErrorMessage,
    isDisabled,
    onFocus = false,
  } = question;
  
  const questionnaireId = getUniqueKeyForQuestionnaire(question);

  const onChangeHandler = (event: any) => {
    if (type === DATE_PICKER) {
      onAnswer({ ...question, response: event, validationErrorMessage: '' });
      return;
    } else if (type === TEXT_SINGLE) {
      onAnswer({ ...question, response: event.target.value.trim(), validationErrorMessage: '' });
      return;
    } else if (type === INTEGER) {
      if (event.target.value && event.target.value.match(numberRegex) == null) return;
      onAnswer({ ...question, response: event.target.value, validationErrorMessage: '' });
      return;
    }
    onAnswer({ ...question, response: event.target.value, validationErrorMessage: '' });
  };

  useEffect(() => {
    if (type === DROPDOWN && !response) {
      let defaultSelectedOption = options[0]?.value?.trim();
      if (question?.required) {
        const selectedDefaultValue =
          isNaN(Number(defaultSelectedOption)) || Number(defaultSelectedOption) > 0;
        defaultSelectedOption = getNestedTernaryData(
          !!defaultSelectedOption && selectedDefaultValue,
          defaultSelectedOption,
          ''
        );
      }
      onAnswer({ ...question, response: defaultSelectedOption, validationErrorMessage: '' });
    }
    // eslint-disable-next-line
  }, []);

  const questionLabel = filterUniqueCodeString(longLanguage[0]?.richText);

  const labelRef = useRef<HTMLLabelElement | null>(null)

  useEffect(() => {
    if (onFocus && errorQuestionKeys?.[0] === questionnaireId){
      labelRef?.current?.focus();
    }
    // eslint-disable-next-line
  },[errorQuestionKeys.length])

  return (
    <div className='mb-2 que-handler'>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            error={!!validationErrorMessage}
            className='w-100'
            disabled={isDisabled}
          >
            <label htmlFor={questionnaireId} ref={labelRef}>
              <span
                className='w-100'
                dangerouslySetInnerHTML={{ __html: `${questionLabel}${required ? "<span class='star-error'>*</span>" : ''}` }}
              />
            </label>
            {type === DROPDOWN && (
              <Select
                native
                value={response}
                inputProps={{
                  name: questionnaireId,
                  id: questionnaireId,
                }}
                onChange={onChangeHandler}
                error={!!validationErrorMessage}
                disabled={isDisabled}
              >
                {map(options, data => (
                  <option key={data.value} value={data.value}>
                    {!isEmpty(data?.description?.trim()) ? data?.description : startCase(SELECT)}
                  </option>
                ))}
              </Select>
            )}
            {type === DATE_PICKER && (
              <DatePicker
                styles={{
                  marginBottom: '0px !important',
                  marginTop: '0px !important',
                }}
                label={''}
                id={questionnaireId}
                name={questionnaireId}
                onChange={onChangeHandler}
                dateValue={response ? response : null}
                error={!!validationErrorMessage}
                disabled={isDisabled}
              />
            )}
            {type === INTEGER && (
              <Input
                autoComplete={OFF.KEY}
                value={response}
                onChange={onChangeHandler}
                name={questionnaireId}
                id={questionnaireId}
                disabled={isDisabled}
                error={!!validationErrorMessage}
              />
            )}
            {type === TEXT_SINGLE && (
              <Input
                autoComplete={OFF.KEY}
                value={response}
                onChange={onChangeHandler}
                name={questionnaireId}
                id={questionnaireId}
                inputProps={{ maxLength: 255 }}
                disabled={isDisabled}
                error={!!validationErrorMessage}
              />
            )}
            <FormHelperText className='text-red'>{validationErrorMessage}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {!!helpLanguage[0]?.richText && (
            <div
              className='helper-text'
              dangerouslySetInnerHTML={{ __html: `${helpLanguage[0]?.richText}` }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default QuestionHandler;
