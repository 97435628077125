/* istanbul ignore file */
import * as types from '../constants';

interface FeedbackState {
  feedbackLoader: boolean;
  feedbackResponseData: any;
}

const INIT_FEEDBACK_STATE: FeedbackState = {
  feedbackLoader: false,
  feedbackResponseData: '',
};

const INIT_STATE = { ...INIT_FEEDBACK_STATE };

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        feedbackLoader: false,
        feedbackResponseData: '',
      };
    }
    case types.FEEDBACK_LOADER: {
      return {
        ...state,
        feedbackLoader: action.payload,
      };
    }
    case types.STORE_FEEDBACK_RESPONSE: {
      return {
        ...state,
        feedbackResponseData: action.payload,
      };
    }
    default:
      return state;
  }
};
