/* istanbul ignore file */
import { AxiosError } from 'axios';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import axiosRequest from '../../api';
import * as feedbackActions from '../constants';
import { errorHandler, feedbackLoader, storeFeedbackResponse } from '../actions';
import { SUCCESSFULLY } from '../../../constants';

function* addFeedback({ payload }: any): any {
  yield put(feedbackLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/feedback/send-feedback',
      isProviderOneLogin,
      {
        ...payload,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      if (
        !!responseDetails?.data?.Message &&
        responseDetails?.data?.Message.includes(SUCCESSFULLY)
      ) {
        yield put(storeFeedbackResponse({ message: responseDetails?.data?.Message }));
        yield NotificationManager.success(responseDetails?.data?.Message);
      } else {
        yield put(storeFeedbackResponse({ message: responseDetails?.data?.Message }));
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError.response && axiosError.response.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(feedbackLoader(false));
}

export function* addFeedbackWatcher() {
  yield takeEvery(feedbackActions.ADD_FEEDBACK, addFeedback);
}

export default function* rootSaga() {
  yield all([fork(addFeedbackWatcher)]);
}
