import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import ThemesImages from '../themes-images';
import config from '../../../config/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    successImg: {
      width: 500,
      height: 500,
      marginBottom: 30,
    },
    backdrop: {
      zIndex: 1111,
      color: '#fff',
    },
  })
);

const AMSSuccessModal = (props: AppComponents.AMSSuccessModalProps) => {
  const { open, closeModal, home } = props;
  const classes = useStyles();
  const [openModal, setOpen] = React.useState(false);
  let themeType = !!home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  return (
    <Backdrop className={`${classes.backdrop}`} open={openModal} onClick={handleClose}>
      <div className='text-center'>
        <img
          alt='AMS Success'
          src={`${ThemesImages[themeType].amsSuccess}?time=${new Date().getTime()}`}
          className={`${classes.successImg} bg-white`}
        />
      </div>
    </Backdrop>
  );
};

const mapStateToProps = ({ home }: AppComponents.AMSSuccessModalStore) => ({
  home,
});

export default connect(mapStateToProps, {})(AMSSuccessModal);
