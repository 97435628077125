import React from 'react';
import { isEmpty, range, startCase } from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CONTINUE, ORGANIZATION_MODAL_HEADING } from '../../../constants';
import { Button, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import './adhoc-organization-modal.scss';

const ActiveMarker = () => (
  <div className='active-marker'>
    <CheckCircleIcon />
  </div>
);

export const AdHocOrganizationModel = (props: any) => {
  const {
    error,
    isOpen,
    loader,
    onChange,
    onCloseModal,
    organizationListErrorMessage,
    orgList,
    onSaveOrganization,
    selectedAdhocOrganizationCode,
  } = props;

  return (
    <Dialog
      className='adhoc-org-modal'
      maxWidth='md'
      fullWidth
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby='org-dialog-title'
    >
      <DialogContent className='adhoc-dialogContentWrap'>
        {!loader && isEmpty(organizationListErrorMessage) && (
          <div id='org-dialog-title' className='padding-16-24'>
            <Typography className='text-center heading'>{ORGANIZATION_MODAL_HEADING}</Typography>
          </div>
        )}
        <div className='row d-flex justify-content-center m-0'>
          {!isEmpty(orgList) && !loader
            ? orgList.map((subOrganization: any) => (
                <div className='col-md-3 col-sm-3 mb-3' key={subOrganization.code}>
                  <div
                    className={
                      selectedAdhocOrganizationCode === subOrganization.code
                        ? 'adhoc-org-item active'
                        : 'adhoc-org-item'
                    }
                    onClick={() => onChange(subOrganization)}
                  >
                    {selectedAdhocOrganizationCode === subOrganization.code && <ActiveMarker />}
                    <img
                      src={subOrganization.logoUrl}
                      className='img-fluid'
                      alt={startCase(subOrganization.name)}
                      title={startCase(subOrganization.name)}
                    />
                  </div>
                </div>
              ))
            : ''}
          {!isEmpty(organizationListErrorMessage) && (
            <div className='row red-color justify-content-center'>
              {organizationListErrorMessage}
            </div>
          )}
        </div>
        <div className='row red-color justify-content-center'>{!isEmpty(error) ? error : ''}</div>
      </DialogContent>
      <DialogActions className='d-flex justify-content-center'>
        {isEmpty(organizationListErrorMessage) && (
          <Button
            className='btn-continue'
            variant='contained'
            onClick={() => onSaveOrganization()}
            disabled={loader}
          >
            {loader ? (
              <div className='loader-wrapper'>
                <div className='sk-circle'>
                  {range(12, 0).map(n => (
                    <div key={n} className={`sk-circle${n} sk-child`} />
                  ))}
                </div>
              </div>
            ) : (
              CONTINUE?.toUpperCase()
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AdHocOrganizationModel;
