import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import { forEach, get, isEmpty, omit, assignIn, filter } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 } from 'uuid';
import {
  APPLICANT_LABEL_NAME,
  CYBER_DEFAULT_PLAN,
  CYBER_SECURITY,
  FOUNDATION_TYPES,
  HOME_WARRANTY,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  LOB_PRODUCTS_MAPPING,
  PAGE_TRACKING_KEYS,
  PROPERTY_INFO,
  PRODUCT_MAPPING,
  RATES_LABEL_NAME,
  SLAB,
  STEPPER_KEYS_MAPPING
} from '../../constants';
import QuestionList from '../../constants/flood-questionnaire';
import {
  checkSpouseMissing,
  getHerdId,
  getHerdIdFromUrl,
  getIsPropertyInfoEnabledDetails,
  getSeperateQuoteList,
  goToDashboard,
  inIframe,
  isApplicantDataRequired,
  isAutoQuote,
  updateApplicantinfo,
  loadQuotesOnRatePage,
  getStepperTabLength,
  getLobHerdId,
  getQuoteListOfLob,
  getIsLobIncludesInAppointments,
  getLobToOverridePropertyInfo,
  isPropertyInfoCovered,
} from '../../utils';
import { createUmbrellaQuestionsMapping, getMappedUmbrellaQuestionnaireGroups } from '../../utils/umbrella';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import FeedbackButton from '../components/feedback-button';
import TabPanel from '../components/questionnaire-tab-wrapper';
import {
  addDetails,
  addDriver,
  addVehicle,
  clearHomeState,
  clearFloodState,
  clearQuakeState,
  clearPetState,
  clearAutoQuoteSate,
  cleanHelperProduct,
  clearDriverInfo,
  clearVehiclesList,
  clearInsurenaceDetails,
  clearHomeWarrantyState,
  clearCyberSecurityState,
  clearLifeState,
  clearUmbrellaState,
  driverSelectedForEdit,
  fetchPconfig,
  fetchViewQuotes,
  getQuoteLobDetails,
  setAnnexPrefill,
  setAppointments,
  setAutoQuoteDetails,
  setFloodQuotes,
  setHomeState,
  setPrefillData,
  setQuakeQuotes,
  setQuoteCompleted,
  setPetQuotes,
  storePetListData,
  setRedirectToViewQuoteFlag,
  setRequestId,
  setSwitchDetailFromDriver,
  startHomePolling,
  setViewQuote,
  setRatePageEnable,
  setMissingSpouse,
  setVehicleDetails,
  setQuakeHerdId,
  setFloodHerdId,
  setHomeWarrantyQuoteDetails,
  storeBoundedCarrierDetails,
  setLobPriority,
  setCyberSecurityQuoteDetails,
  storeLifeQuoteDetails,
  storeUmbrellaQuoteDetails,
  updateApplicantAddress,
  updateApplicantDetails,
  updateDriverName,
  updateQuoteDetails,
  updateSelectedCarriers,
  vehicleSelect,
  vinSubmitSuccess,
  flushAppDetails,
  setIsUpdatedPropertyInfo,
} from '../redux/actions';
import ApplicantForm from './applicant';
import PropertyInfo from './property-info';
import './form.scss';
import ProductRatesDetails from './rates';
import './stepper-list.scss';
import { allProps, tabElements } from './tabs';
import CustomIcon from './tabs/icon';
import history from '../../history';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const AUTO_TABS_COUNT = 3;
const OTHER_TABS_COUNT = 2;
const BASE_DRIVER = 2;
const BASE_COVERAGES = 3;
const excludeLOBFromStepper = ['HOME', 'QUAKE', 'HOMEWARRANTY', 'CYBERSECURITY'];

const QuoteQuestionnaire = ({
  addDetails,
  addDriver,
  addVehicle,
  appointments,
  setHomeState,
  setRequestId,
  setAnnexPrefill,
  setPrefillData,
  storedRequestId,
  setAppointments,
  updateApplicantAddress,
  updateApplicantDetails,
  vehicleSelect,
  vinSubmitSuccess,
  getQuoteLobDetails,
  updateQuoteDetails,
  setAutoQuoteDetails,
  setFloodQuotes,
  newProducts,
  fetchPconfig,
  pconfig,
  redirectToViewQuote,
  setRedirectToViewQuoteFlag,
  home,
  cleanHelperProduct,
  updateDriverName,
  setQuoteCompleted,
  isVeriskLoading,
  vinLoader,
  setQuakeQuotes,
  updateSelectedCarriers,
  setPetQuotes,
  storePetListData,
  petDetails,
  clearHomeState,
  clearFloodState,
  clearQuakeState,
  clearPetState,
  clearAutoQuoteSate,
  clearDriverInfo,
  fetchViewQuotes,
  viewQuoteDetails,
  startHomePolling,
  setViewQuote,
  quakeDetails,
  flushAppDetails,
  vehiclesList,
  floodDetails,
  quote,
  driversList,
  insurance,
  applicant,
  setSwitchDetailFromDriver,
  enableRatePage,
  setRatePageEnable,
  storeBoundedCarrierDetails,
  policyBounded,
  setMissingSpouse,
  driverSelectedForEdit,
  setVehicleDetails,
  pageTracking,
  homeWarrantyDetails,
  setHomeWarrantyQuoteDetails,
  clearHomeWarrantyState,
  clearCyberSecurityState,
  clearLifeState,
  setLobPriority,
  isHeapDetailLoaded,
  setCyberSecurityQuoteDetails,
  storeLifeQuoteDetails,
  lifeDetails,
  cyberSecurityDetails,
  clearVehiclesList,
  clearInsurenaceDetails,
  setQuakeHerdId,
  setFloodHerdId,
  umbrellaDetails,
  clearUmbrellaState,
  storeUmbrellaQuoteDetails,
  loader,
  selectedBusinessOption,
  setIsUpdatedPropertyInfo,
}: AppComponents.QuoteQuestionnaireProps) => {
  const [value, setValue] = useState(0);
  const [tabsCount, setTabsCount] = useState(0);
  const [tabKey, setTabKey] = useState<Array<string>>([]);
  const [goToRates, setGoToRates] = useState<boolean>(false);
  const [lobTab, setLobTab] = useState<{ [key: string]: number }>({});
  const [add, setAdd] = useState(false);
  const [requestIdInState, setRequestIdInState] = useState<any>(storedRequestId);
  const [veriskLoading, setVeriskLoading] = useState(false);
  const [quoteType, setQuoteType] = useState('');
  let ref = useRef<any>();
  const classes = useStyles();
  const enableFeedbackButton =
    !!pconfig?.dale_config?.feedback_enabled || !!pconfig?.pconfig?.dale_config?.feedback_enabled;
  const appointmentList: Array<any> = !isEmpty(appointments) ? Object.keys(appointments) : [];
  const stepperList: Array<any> = appointmentList.concat(Object.keys(newProducts));
  const isHomeOrFloodLob: boolean =
    stepperList.includes(INSURANCE_BUSINESS_TYPES.HOME) ||
    stepperList.includes(INSURANCE_BUSINESS_TYPES.FLOOD);
  const isShowPropertyInfoForHome: boolean =
    getIsPropertyInfoEnabledDetails() &&
    !isEmpty(home?.herd_id) &&
    stepperList.includes(INSURANCE_BUSINESS_TYPES.HOME);
  const isShowPropertyInfo: boolean =
    getIsPropertyInfoEnabledDetails() &&
    isHomeOrFloodLob &&
    (!isEmpty(home?.herd_id) || !isEmpty(floodDetails?.herdId));
  const isDisabledPropertyInfo: boolean =
    getIsPropertyInfoEnabledDetails() &&
    ((stepperList.includes(INSURANCE_BUSINESS_TYPES.HOME) &&
      isEmpty(getLobHerdId(LOB_PRODUCTS_MAPPING.home))) ||
      (stepperList.includes(INSURANCE_BUSINESS_TYPES.FLOOD) &&
        isEmpty(getLobHerdId(LOB_PRODUCTS_MAPPING.flood))));

  const setQuestionnaireData = () => {
    // @TODO add flood auto-population
    // @TODO check deductibles issue on home quote
    // @TODO check back/continue flow

    const appointmentData: any = {
      HOME: {},
      QUAKE: {},
      HOMEWARRANTY: {},
      CYBERSECURITY: {},
      FLOOD: {},
      AUTO: {},
      PET: {},
      LIFE: {},
      UMBRELLA: {},
    };
    setAppointments({ appointments: appointmentData });
    const { quoteLobDetails } = quote;
    if (quoteLobDetails?.quotesByLOB && !isEmpty(Object.keys(quoteLobDetails?.quotesByLOB))) {
      Object.keys(appointmentData).forEach(key => {
        let lobkey = PRODUCT_MAPPING[key] || key;
        if (!quoteLobDetails.quotesByLOB[lobkey.toLowerCase()]) {
          delete appointmentData[key];
        }
      });
    }

    if (
      quoteLobDetails?.quotesByLOB?.home &&
      document.location.href.includes('/quote?id')
    ) {
      startHomePolling();
    }

    if(quoteLobDetails?.carriersBounded) {
      const { carriersBounded } = quoteLobDetails;
      if (!!carriersBounded?.earthquake) {
        carriersBounded.quake = carriersBounded?.earthquake;
        delete carriersBounded.earthquake;
      }
      storeBoundedCarrierDetails(carriersBounded);
    } else {
      storeBoundedCarrierDetails({});
    }

    const createAnnexQuestion = (annexData: any) => {
      if (annexData) {
        setAnnexPrefill(annexData);
        const mappedDetails = {
          foundationType: annexData?.structureType || '',
          propertyType: annexData?.product || '',
          stories: annexData?.stories || annexData?.floodQuestionnaireAdditionalData?.stories || 0,
          foundationOptions: [...FOUNDATION_TYPES],
        };

        let { foundationType } = mappedDetails;
        let currentQuestion: any = {};
        let checkFloodStories = false;
        if (mappedDetails.stories === 0) {
          checkFloodStories = true;
          currentQuestion = {
            ...get(QuestionList, 'stories'),
          };
        } else if (isEmpty(foundationType)) {
          const question = get(QuestionList, foundationType, QuestionList.foundationType);
          const currentOptions = isEmpty(foundationType)
            ? get(mappedDetails, 'foundationOptions', [])
            : get(question, 'options', []);
          currentQuestion = {
            ...question,
            options: [...currentOptions],
          };
        } else {
          foundationType =
            foundationType.toLowerCase() === SLAB && annexData?.isFloodInfo
              ? 'stories'
              : foundationType;
          const question = get(QuestionList, foundationType);
          currentQuestion = {
            ...question,
          };
        }
        if(!isEmpty(annexData?.floodQuestionnaireAdditionalData)) {
          const { floodQuestionnaireAdditionalData } = annexData;
          if (
            !checkFloodStories &&
            !!floodQuestionnaireAdditionalData?.enclosureSqft &&
            !!currentQuestion
          ) {
            currentQuestion = {
              ...currentQuestion,
              linkQuestion: {
                ...currentQuestion?.linkQuestion,
                value: floodQuestionnaireAdditionalData?.enclosureSqft,
              },
            };
          }
          if(checkFloodStories || (annexData?.structureType?.toLowerCase() === SLAB && annexData?.isFloodInfo)) {
            currentQuestion.value = floodQuestionnaireAdditionalData?.stories || annexData?.stories || 1;
          } else {
            currentQuestion.value = floodQuestionnaireAdditionalData?.additionalStructureType || '';
          }
        }
        setPrefillData({
          ...mappedDetails,
          currentQuestion,
        });
      }
    };

    if (quoteLobDetails?.quotesByLOB?.home) {
      const homeData: any = {
        herd_id: quoteLobDetails.quotesByLOB.home.herdId,
      };
      if (quoteLobDetails.quotesByLOB.home.quotes) {
        homeData.quoteList = !isEmpty(quoteLobDetails?.quotesByLOB?.home?.quotes)
          ? [...quoteLobDetails.quotesByLOB.home.quotes]
          : [];
        const errors = quoteLobDetails.quotesByLOB.home.error_details &&
            quoteLobDetails.quotesByLOB.home.error_details.errors
            ? Object.values(quoteLobDetails.quotesByLOB.home.error_details.errors)
            : []
        if (quoteLobDetails.isPollingStart) {
          homeData.tempErrorQuoteList = errors;
        } else {
          homeData.quoteListError = errors
        }
        homeData.hippoQuoteLoaded = true;
        homeData.ezlynxQuotesLoaded = true;
      }
      setHomeState(homeData);
    } else {
      clearHomeState();
    }

    if (quoteLobDetails?.quotesByLOB?.flood) {
      setFloodHerdId(quoteLobDetails?.quotesByLOB?.flood?.herdId);
      if (quoteLobDetails.quotesByLOB.flood.quotes) {
        const { errorQuotes } = getSeperateQuoteList(
          quoteLobDetails?.quotesByLOB?.flood?.error_details,
          {}
        );
        setFloodQuotes({
          quoteList: quoteLobDetails?.quotesByLOB?.flood?.quotes ? quoteLobDetails.quotesByLOB.flood.quotes : [],
          quoteErrorList: errorQuotes,
          selectedCarrier: quoteLobDetails?.selectedCarriers?.flood?.carrierId
            ? quoteLobDetails.selectedCarriers.flood
            : {},
        });
      }
    } else {
      clearFloodState();
    }

    if (quoteLobDetails?.quotesByLOB?.quake) {
      setQuakeHerdId(quoteLobDetails?.quotesByLOB?.quake?.herdId);
      const { errorQuotes } = getSeperateQuoteList(
        quoteLobDetails?.quotesByLOB?.quake?.error_details,
        {}
      );
      setQuakeQuotes({
        quoteList: [
          ...Object.values(quoteLobDetails?.quotesByLOB?.quake?.quotes || []),
          ...Object.values(errorQuotes),
        ],
        selectedCarrier: quoteLobDetails?.selectedCarriers?.quake?.carrierId
          ? quoteLobDetails.selectedCarriers.quake
          : {},
      });
    } else {
      clearQuakeState();
    }

    if (quoteLobDetails?.quotesByLOB?.pet) {
      let errorQuotes: any = [];
      if (quoteLobDetails?.quotesByLOB?.pet?.error_details) {
        errorQuotes = filter(Object.values(quoteLobDetails?.quotesByLOB?.pet?.error_details?.errors), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      setPetQuotes({
        quoteList: [
          ...Object.values(quoteLobDetails?.quotesByLOB?.pet?.quotes),
          ...errorQuotes,
        ],
        herdId: quoteLobDetails?.quotesByLOB?.pet?.herdId,
      });
      storePetListData(quoteLobDetails?.quotesByLOB?.pet?.pet_details);
    } else {
      clearPetState();
    }

    if (quoteLobDetails?.quotesByLOB[HOME_WARRANTY]) {
      let errorQuotes: any = [];
      let homeWarrantyQuoteDetails = quoteLobDetails?.quotesByLOB[HOME_WARRANTY];
      if (homeWarrantyQuoteDetails?.error_details) {
        errorQuotes = filter(Object.values(homeWarrantyQuoteDetails?.error_details?.errors), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      setHomeWarrantyQuoteDetails({
        quoteList: [
          ...Object.values(homeWarrantyQuoteDetails?.quotes),
          ...errorQuotes,
        ],
        herdId: homeWarrantyQuoteDetails?.herdId,
      });
    } else {
      clearHomeWarrantyState();
    }

    if (quoteLobDetails?.quotesByLOB[CYBER_SECURITY]) {
      let errorQuotes: any = [];
      let cyberSecurityQuoteDetails = quoteLobDetails?.quotesByLOB[CYBER_SECURITY];
      if (cyberSecurityQuoteDetails?.error_details?.errors) {
        errorQuotes = filter(Object.values(cyberSecurityQuoteDetails?.error_details?.errors), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      if (cyberSecurityQuoteDetails?.quotes?.length) {
        let selectedCarrier = quoteLobDetails?.selectedCarriers && quoteLobDetails?.selectedCarriers[CYBER_SECURITY];
       filter(cyberSecurityQuoteDetails.quotes, quote => {
          let selectedPlan = (selectedCarrier && selectedCarrier?.carrierId?.toLowerCase() === quote?.carrier_id?.toLowerCase() && selectedCarrier?.selectedPlan) || CYBER_DEFAULT_PLAN;
          return assignIn(quote, {
            premiumDetails: quote[selectedPlan],
            selectedPlan: selectedPlan
          });
        });
      }
      setCyberSecurityQuoteDetails({
        quoteList: !isEmpty(cyberSecurityQuoteDetails?.quotes) ? cyberSecurityQuoteDetails?.quotes : [],
        quoteErrorList: !isEmpty(errorQuotes) ? errorQuotes : [],
        quoteCompleted: true,
        herdId: cyberSecurityQuoteDetails?.herdId,
      });
    } else {
      setCyberSecurityQuoteDetails({
        quoteList: [],
        quoteErrorList: [],
        upcomingCarrierList: [],
      });
      clearCyberSecurityState();
    }

    if (quoteLobDetails?.quotesByLOB?.life) {
      let lifeQuoteDetails = quoteLobDetails?.quotesByLOB?.life;
      let errorQuotes: any = [];
      if (lifeQuoteDetails?.error_details?.errors) {
        errorQuotes = filter(Object.values(lifeQuoteDetails?.error_details?.errors), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      storeLifeQuoteDetails({
        quoteList: Object.values(lifeQuoteDetails?.quotes),
        quoteErrorList: errorQuotes,
        quoteCompleted: true,
        herdId: lifeQuoteDetails?.herdId,
        lifeInfo: quoteLobDetails?.quotesByLOB?.life?.lifeInfo
      });
    } else {
      storeLifeQuoteDetails({
        quoteList: [],
        quoteErrorList: [],
        upcomingCarrierList: [],
      });
      clearLifeState();
    }

    if(quoteLobDetails?.quotesByLOB?.umbrella) {
      let umbrellaQuoteDetails = quoteLobDetails?.quotesByLOB?.umbrella;
      let errorQuotes: any = [];
      if (umbrellaQuoteDetails?.error_details?.errors) {
        errorQuotes = filter(Object.values(umbrellaQuoteDetails?.error_details?.errors), error => {
          return assignIn(error, { quoteError: true });
        });
      }
      storeUmbrellaQuoteDetails({
        quoteList: Object.values(umbrellaQuoteDetails?.quotes),
        quoteErrorList: errorQuotes,
        quoteCompleted: true,
        herdId: umbrellaQuoteDetails?.herdId,
        umbrellaInfo: {
          questionGroups:
            umbrellaQuoteDetails?.rliQuestionInfo && !isEmpty(umbrellaQuoteDetails?.rliQuestionInfo)
              ? getMappedUmbrellaQuestionnaireGroups(
                  createUmbrellaQuestionsMapping([...umbrellaQuoteDetails?.rliQuestionInfo])
                )
              : [],
        },
      });
    } else {
      storeUmbrellaQuoteDetails({
        quoteList: [],
        quoteErrorList: [],
        upcomingCarrierList: [],
      });
      clearUmbrellaState()
    }

    if (quoteLobDetails?.quotesByLOB?.auto) {
      if (!isEmpty(quoteLobDetails?.quotesByLOB?.auto?.vehicle_details)) {
        quoteLobDetails.quotesByLOB.auto.vehicle_details.forEach((vehicle: any, index: number) => {
          setTimeout(() => {
            const vehicleData = {
              ...vehicle,
              bodyTypeList: vehicle.body_type
                ? [{ label: vehicle.body_type, value: vehicle.body_type }]
                : [],
              clearedVehicle: {},
              vinInput: true,
              vinLoader: false,
              vinServerError: false,
              vehicleSelectorDetails: {
                make: vehicle.make,
                model: vehicle.model,
                year: vehicle.year,
                makeList: [],
                modelList: [],
                yearList: [],
                vehicleSelectorLoader: false,
              },
              defaultVin: vehicle.vin,
              collision: vehicle.coverage.collision,
              towing_labor: vehicle.coverage.towing_labor,
              comprehensive: vehicle.coverage.comprehensive,
              ext_trans_expense: vehicle.coverage.ext_trans_expense,
              clear: true,
              make: vehicle.make,
              model: vehicle.model,
              year: vehicle.year,
              isIncluded: get(vehicle, 'isIncluded', true),
            };
            vinSubmitSuccess(vehicleData);
            addVehicle(vehicleData);
            if (index === quoteLobDetails.quotesByLOB.auto.vehicle_details.length - 1) {
              vehicleSelect(vehicle.vuid);
            }
          }, 100);
        });
      } else {
        clearVehiclesList();
      }

      if (!isEmpty(quoteLobDetails?.quotesByLOB?.auto?.driver_details)) {
        quoteLobDetails?.quotesByLOB?.auto?.driver_details?.forEach((driver: any) => {
          const driverData = {
            ...driver,
            isIncluded: get(driver, 'isIncluded', true),
          };
          addDriver(omit(driverData, ['isFetchAutoQuotes']));
        });
      } else {
        clearDriverInfo();
      }

      if (quoteLobDetails?.quotesByLOB?.auto?.insurance_other_details) {
        Object.assign(
          quoteLobDetails.quotesByLOB.auto.insurance_other_details,
          quoteLobDetails?.quotesByLOB?.auto?.insurance_other_details?.coverage
        );
        addDetails(quoteLobDetails.quotesByLOB.auto.insurance_other_details);
      } else {
        clearInsurenaceDetails();
      }

      updateQuoteDetails({ ...quoteLobDetails.quotesByLOB.auto });
      setAutoQuoteDetails({
        quotes: quoteLobDetails?.quotesByLOB?.auto?.quotes ? Object.values(quoteLobDetails.quotesByLOB.auto.quotes) : [],
        errors: quoteLobDetails.quotesByLOB?.auto?.error_details?.errors ? Object.values(quoteLobDetails.quotesByLOB.auto.error_details.errors) : [],
      });
      if (quoteLobDetails.quotesByLOB.auto.quotes) {
        setQuoteCompleted({ quoteCompleted: true } as any);
      }
    } else {
      setAutoQuoteDetails({
        quotes: [],
        errors: [],
      });
      clearDriverInfo();
      clearAutoQuoteSate();
    }

    if (quoteLobDetails) {
      updateApplicantinfo(quoteLobDetails?.applicant, quoteLobDetails?.address);
    }

    if (quoteLobDetails?.selectedCarriers) {
      updateSelectedCarriers(quoteLobDetails?.selectedCarriers);
    }

    if (!isEmpty(quoteLobDetails?.annexRequiredPrefill)) {
      createAnnexQuestion(quoteLobDetails?.annexRequiredPrefill);
    } else {
      setAnnexPrefill({});
    }
  };

  useEffect(() => {
    if (home.pconfig?.dale_config?.is_verisk_prefill_available) {
      if (
        newProducts &&
        Object.keys(newProducts).length &&
        isAutoQuote(Object.keys(newProducts)) &&
        tabsCount === value
      ) {
        const loading = vinLoader || isVeriskLoading;

        setVeriskLoading(loading);
      }
    }
    // eslint-disable-next-line
  }, [vinLoader, isVeriskLoading]);

  useEffect(() => {
    // Clearing appointments to show rate page in dashboard row click
    const { isHerdIdPresent } = getHerdIdFromUrl();
    if (isHerdIdPresent && !quote?.quoteLobDetails && Object.keys(appointments).length) {
      setAppointments({ appointments: {} });
    }
    // eslint-disable-next-line
  }, [appointments]);

  useEffect(() => {
    if (
      tabKey.length &&
      value === tabsCount &&
      Object.keys(appointments).length &&
      !enableRatePage
    ) {
      setRatePageEnable(true);
      setViewQuote({ isRedirectToApplicant: false });
    }
    // eslint-disable-next-line
  }, [
    home,
    floodDetails.submitLoader,
    quote.quotesLoading,
    quakeDetails.quakeSubmitLoader,
    petDetails,
    policyBounded
  ]);

  useEffect(() => {
    const keys = Object.keys(appointments);
    if (
      home.pconfig?.dale_config?.is_verisk_prefill_available &&
      newProducts &&
      Object.keys(newProducts).length &&
      isAutoQuote(Object.keys(newProducts))
    ) {
      setVeriskLoading(state => {
        if (!state) {
          setTabKey(keys);
        }
        return state;
      });
    } else {
      setTabKey(keys);
    }
    // eslint-disable-next-line
  }, [appointments, newProducts, veriskLoading]);

  const getStepperIndex = (type: any, tabsElements: any) => {
      let indexValue = 1;
      let isAutoLob = tabsElements.includes(INSURANCE_BUSINESS_TYPES.AUTO);
      if (
        (type === INSURANCE_BUSINESS_TYPES.PET ||
          type === INSURANCE_BUSINESS_TYPES.LIFE ||
          type === INSURANCE_BUSINESS_TYPES.UMBRELLA) &&
        isAutoLob
      ) {
        indexValue = 3;
      }
      return indexValue;
  }

  const createStepper = (tabKeys: any) => {
    let count = 0;
    forEach(
      tabKeys.filter((lob: any) => !excludeLOBFromStepper.includes(lob)),
      (element, index) => {
        let stepperIndex = getStepperIndex(element, tabKeys);
        setLobTab((prevState: any) => ({ ...prevState, [element]: index + stepperIndex }));
        if (element === INSURANCE_BUSINESS_TYPES.AUTO) {
          setLobTab((prevState: any) => ({
            ...prevState,
            DRIVER: index + BASE_DRIVER,
            COVERAGES: index + BASE_COVERAGES,
          }));
          count += AUTO_TABS_COUNT;
        } else {
          count++;
        }
      }
    );
    if (count > 0) {
      count += OTHER_TABS_COUNT;
    } else {
      count = OTHER_TABS_COUNT;
      forEach(tabKeys, (element, index) => {
        setLobTab((prevState: any) => ({ ...prevState, [element]: index + 1 }));
      });
    }
    return count;
  };

  const getStepRedirectValue = (appointments: any) => {
    let redirectionTab = 0;
    if (appointments.includes(INSURANCE_BUSINESS_TYPES.FLOOD)) {
      redirectionTab = lobTab[INSURANCE_BUSINESS_TYPES.FLOOD];
    } else if (appointments.includes(INSURANCE_BUSINESS_TYPES.AUTO)) {
      redirectionTab = isApplicantDataRequired() ? 0 : lobTab[INSURANCE_BUSINESS_TYPES.AUTO];
    } else if (appointments.includes(INSURANCE_BUSINESS_TYPES.PET)) {
      redirectionTab = isApplicantDataRequired() ? 0 : lobTab[INSURANCE_BUSINESS_TYPES.PET];
    } else if (appointments.includes(INSURANCE_BUSINESS_TYPES.LIFE)) {
      redirectionTab = lobTab[INSURANCE_BUSINESS_TYPES.LIFE];
    } else if (appointments.includes(INSURANCE_BUSINESS_TYPES.UMBRELLA)) {
      redirectionTab = lobTab[INSURANCE_BUSINESS_TYPES.UMBRELLA];
    }
    return redirectionTab;
  }

  useEffect(() => {
    const { annexPrefill } = floodDetails;
    if (
      getIsLobIncludesInAppointments(LOB_PRODUCTS_MAPPING.flood) &&
      !applicant?.savePropertyInfoloader &&
      (isEmpty(getQuoteListOfLob(LOB_PRODUCTS_MAPPING.flood)) || !!applicant?.isUpdatedPropertyInfo)
    ) {
      if (
        value &&
        tabsCount &&
        getIsPropertyInfoEnabledDetails() &&
        !!floodDetails?.isPropertyInfoRequiredForFlood &&
        value !== tabsCount + 1
      ) {
        return redirectToStep(PROPERTY_INFO.KEY);
      }
      if (
        !!applicant?.isUpdatedPropertyInfo &&
        !floodDetails?.isPropertyInfoRequiredForFlood &&
        !isEmpty(annexPrefill) &&
        getLobToOverridePropertyInfo() === LOB_PRODUCTS_MAPPING.flood
      ) {
        setIsUpdatedPropertyInfo(false);
        return redirectToStep(STEPPER_KEYS_MAPPING.FLOOD_INFO);
      }
    }
    // eslint-disable-next-line
  }, [applicant, floodDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    let { isHerdIdPresent, herdId } = getHerdIdFromUrl();
    herdId = herdId ? herdId : getHerdId();
    if (
      !isHerdIdPresent &&
      (document.location.href.includes('/questionnaire?herd_id') ||
        document.location.href.includes('/quote?id'))
    ) {
      setHomeState({
        showError: true,
        notFoundError: true,
        expiredError: false,
      } as any);
      history.push('/error');
    } else if (
      (document.location.href.includes('/quote?id') &&
        isHerdIdPresent &&
        isEmpty(quote?.quoteLobDetails) &&
        !Object.keys(appointments).length) ||
      (redirectToViewQuote && document.location.href.includes('/quote'))
    ) {
      if (newProducts && Object.keys(newProducts).length) {
        cleanHelperProduct();
      }
      if (!viewQuoteDetails?.viewQuoteLoader && !viewQuoteDetails?.validateQuoteUrlLoader) {
        fetchViewQuotes({ herdId: herdId });
        setRedirectToViewQuoteFlag(false);
      }
    } else if (
      (document.location.href.includes('/questionnaire?herd_id') &&
        isHerdIdPresent &&
        isEmpty(quote?.quoteLobDetails) &&
        !Object.keys(appointments).length) ||
      (redirectToViewQuote && document.location.href.includes('/questionnaire'))
    ) {
      if (newProducts && Object.keys(newProducts).length) {
        cleanHelperProduct();
      }
      if (!quote?.viewQuoteLoader && !loader) {
        getQuoteLobDetails({ herdId: herdId });
        setRedirectToViewQuoteFlag(false);
      }
    } else if (herdId && !Object.keys(appointments).length) {
      setQuestionnaireData();
    } else if (Object.keys(appointments).length) {
      if (tabKey.length) {
        let isPrefillCall = isAutoQuote(Object.keys(newProducts));
        let enablePrefillCall =
          isPrefillCall &&
          (Object.keys(appointments).includes('HOMEWARRANTY') ||
            Object.keys(appointments).includes('CYBERSECURITY') ||
            Object.keys(appointments).includes('LIFE') ||
            Object.keys(appointments).includes('UMBRELLA')) &&
          isApplicantDataRequired();
        let stepperKeys =
          !enablePrefillCall &&
          newProducts &&
          home?.pconfig?.dale_config?.is_verisk_prefill_available &&
          Object.keys(newProducts).length &&
          isPrefillCall ?
          tabKey : Object.keys(appointments);
        const count = createStepper(stepperKeys);
        setTabsCount(count);
        if (newProducts && Object.keys(newProducts).length) {
          let newAppointmentKey = Object.keys(newProducts);
          const isApplicantMissing = isEmpty(home?.productType) || isEmpty(floodDetails?.annexPrefill);
          if (
            newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.HOME) &&
            (isApplicantMissing || isShowPropertyInfoForHome || applicant.isAddProductSaveApplicantLoading)
          ) {
            if (!applicant.isAddProductSaveApplicantLoading) {
              if (isApplicantMissing) {
                setValue(0);
              } else {
                redirectToStep(PROPERTY_INFO.KEY);
              }
            }
          } else if (
            ((newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.FLOOD) ||
              newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.QUAKE)) &&
              isApplicantMissing) ||
            ((isEmpty(home?.personal_details?.education) ||
              isEmpty(home?.personal_details?.industry) ||
              isEmpty(home?.personal_details?.occupation)) &&
              !newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.HOMEWARRANTY) &&
              !(
                (newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.CYBERSECURITY) ||
                  newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.LIFE) ||
                  newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.UMBRELLA)) &&
                !isEmpty(home?.personal_details?.date_of_birth)
              ))
          ) {
            setValue(0);
          } else {
            if (
              loadQuotesOnRatePage(floodDetails?.annexPrefill, newProducts) ||
              (newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.QUAKE) &&
                !newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.HOME) &&
                isEmpty(floodDetails?.annexPrefill))
            ) {
              setValue(count);
            } else if (newAppointmentKey.includes(INSURANCE_BUSINESS_TYPES.HOME)) {
              setValue(0);
            } else {
              if (home.pconfig?.dale_config?.is_verisk_prefill_available) {
                if (isAutoQuote(Object.keys(newProducts)) && !enablePrefillCall) {
                  let redirectToAuto = tabKey.includes('FLOOD') ? 2 : 1;
                  setVeriskLoading(state => {
                    if (!state) {
                      setValue(redirectToAuto);
                    }
                    return state;
                  });
                } else {
                  let redirectionTab = getStepRedirectValue(newAppointmentKey);
                  setValue(redirectionTab);
                }
              } else {
                let redirectionTab = getStepRedirectValue(newAppointmentKey);
                setValue(redirectionTab);
              }
            }
          }
        }

        if (
          !(newProducts && Object.keys(newProducts).length) &&
          goToRates
        ) {
          setQuoteType('');
          setGoToRates(false);
          setTabsCount(state => {
            setValue(() => state);
            return state;
          });
        }

        if (
          !(newProducts && Object.keys(newProducts).length) &&
          value > 0 &&
          tabsCount === value
        ) {
          setValue(count);
        }
      }

      // Maintain same request id for a quote
      if (!requestIdInState) {
        let uniqueID = v4();
        uniqueID = !isEmpty(quote?.quoteLobDetails?.providerRequestId) ? quote?.quoteLobDetails?.providerRequestId : uniqueID;
        setRequestIdInState(uniqueID);
        ref.current = uniqueID;
        setRequestId(uniqueID);
      }
    }
  // eslint-disable-next-line
  }, [appointments, quote?.quoteLobDetails, tabKey, viewQuoteDetails.validateQuoteUrlLoader, loader, applicant.isAddProductSaveApplicantLoading]);

  const calculateActiveTab = useCallback(() => {
    let selectedLOBListArray: any = quote?.quoteLobDetails?.selectedLOBs;
    const appointmentData: any = {
      HOME: {},
      QUAKE: {},
      HOMEWARRANTY: {},
      CYBERSECURITY: {},
      FLOOD: {},
      AUTO: {},
      PET: {},
      LIFE: {},
      UMBRELLA: {},
    };
    if (!isEmpty(selectedLOBListArray)) {
      Object.keys(appointmentData).forEach((key: any) => {
        if (!selectedLOBListArray.includes(key.toUpperCase())) {
          delete appointmentData[key];
        }
      });
      setAppointments({ appointments: appointmentData });
      const count = createStepper(selectedLOBListArray);
      setTabKey(selectedLOBListArray);
      setTabsCount(count);
      if (
        viewQuoteDetails &&
        (viewQuoteDetails?.isRedirectToApplicant || quote?.quoteLobDetails?.isValidationErrorExists)
      ) {
        setValue(0);
      } else {
        setValue(count);
      }
    }
    // eslint-disable-next-line
  }, [quote?.quoteLobDetails]);

  useEffect(() => {
    calculateActiveTab();
    if (quote?.quoteLobDetails && quote?.quoteLobDetails?.organization?.code && !pconfig) {
      fetchPconfig({ code: quote?.quoteLobDetails?.organization?.code });
    }
    // eslint-disable-next-line
  }, [calculateActiveTab, fetchPconfig]);

  const handleNext = (curTabValue: number) => {
    // todo: define tabs count properly
    if (curTabValue !== tabsCount) {
      setValue(curTabValue + 1);
      setAdd(false);
    }
  };

  const redirectToStep = (stepValue: string) => {
    let lobStepMapping: any = {
      floodInfo: INSURANCE_BUSINESS_TYPES.FLOOD,
      petInfo: INSURANCE_BUSINESS_TYPES.PET,
      vehicleInfo: INSURANCE_BUSINESS_TYPES.AUTO,
      lifeInfo: INSURANCE_BUSINESS_TYPES.LIFE,
      umbrellaInfo: INSURANCE_BUSINESS_TYPES.UMBRELLA,
      propertyInfo: PROPERTY_INFO.KEY,
    };
    if (lobStepMapping[stepValue] === PROPERTY_INFO.KEY) {
      setValue(tabsCount + 1);
    } else if (!isEmpty(lobStepMapping[stepValue])) {
      setValue(lobTab[lobStepMapping[stepValue]]);
      setAdd(false);
    } else {
      setValue(tabsCount);
      setAdd(false);
    }
  };

  const goToLastTab = (curTabValue: number) => {
    if (curTabValue < tabsCount) {
      setValue(tabsCount);
      setQuoteType('');
      setAdd(false);
    }
  };

  const setTab = (lobTabValue: string, addInfo: boolean = false) => {
    if (addInfo) {
      setAdd(addInfo);
    }
    if (lobTab[lobTabValue] < tabsCount) {
      setValue(lobTab[lobTabValue]);
    }
  };

  const handleBack = (curTabValue: number) => {
    let firstTab = 0;
    if (goToRates) {
      firstTab = 1;
    }
    if (curTabValue > firstTab) {
      setValue(curTabValue - 1);
      setAdd(false);
    } else {
      setValue(curTabValue);
    }
  };

  const goToFirstTab = (index: number) => {
    setValue(index);
  };

  const goBackToCertainTab = (targetTab: number, type: any) => {
    targetTab = (INSURANCE_BUSINESS_TYPES.CYBERSECURITY === type && !isEmpty(home?.personal_details?.date_of_birth)) ||
    ((INSURANCE_BUSINESS_TYPES.HOME === type || INSURANCE_BUSINESS_TYPES.QUAKE === type) && !isEmpty(home?.productType)) ?
    tabsCount : targetTab;
    if (targetTab < tabsCount) {
      let appointmentKeys = Object.keys(appointments);
      let checkLobExist = (appointmentKeys.includes('HOME') ||
      appointmentKeys.includes('QUAKE') ||
      appointmentKeys.includes('PET') ||
      appointmentKeys.includes('AUTO') ||
      appointmentKeys.includes('FLOOD')) &&
      INSURANCE_BUSINESS_TYPES.LIFE !== type &&
      INSURANCE_BUSINESS_TYPES.UMBRELLA !== type;
      if (!isEmpty(pageTracking) && !isEmpty(type) && pageTracking[type?.toLowerCase()]) {
        let redirectTo: any = pageTracking[type?.toLowerCase()];
        let getStep = !isEmpty(redirectTo) && PAGE_TRACKING_KEYS[redirectTo];
        if (
          INSURANCE_BUSINESS_TYPES.HOME === type ||
          INSURANCE_BUSINESS_TYPES.QUAKE === type ||
          INSURANCE_BUSINESS_TYPES.HOMEWARRANTY === type ||
          ((INSURANCE_BUSINESS_TYPES.CYBERSECURITY === type ||
            INSURANCE_BUSINESS_TYPES.LIFE === type ||
            type === INSURANCE_BUSINESS_TYPES.UMBRELLA) &&
            isEmpty(home?.personal_details?.date_of_birth)) ||
          (getStep === INSURANCE_BUSINESS_TYPES.FLOOD && isEmpty(home?.productType)) ||
          (isApplicantDataRequired() && checkLobExist)
        ) {
          targetTab = 0;
        } else {
          targetTab = !isEmpty(getStep) ? lobTab[getStep] : lobTab[type];
        }
        if (getStep === 'DRIVER') {
          let { missingSpouse, driverDetails } = checkSpouseMissing(driversList);
          if (missingSpouse && driverDetails) {
            driverSelectedForEdit(driverDetails?.duid);
            setMissingSpouse(true);
          }
        }
        targetTab = (INSURANCE_BUSINESS_TYPES.CYBERSECURITY === type && !isEmpty(home?.personal_details?.date_of_birth)) ? tabsCount : targetTab;
      } else {
        targetTab = lobTab[type];
        if (
          INSURANCE_BUSINESS_TYPES.HOME === type ||
          INSURANCE_BUSINESS_TYPES.QUAKE === type ||
          INSURANCE_BUSINESS_TYPES.HOMEWARRANTY === type ||
          ((INSURANCE_BUSINESS_TYPES.CYBERSECURITY === type ||
            INSURANCE_BUSINESS_TYPES.LIFE === type ||
            INSURANCE_BUSINESS_TYPES.UMBRELLA === type) &&
            isEmpty(home?.personal_details?.date_of_birth)) ||
          (INSURANCE_BUSINESS_TYPES.FLOOD === type && isEmpty(home?.productType)) ||
          (isApplicantDataRequired() && checkLobExist)
        ) {
          targetTab = 0;
        }
        targetTab = (INSURANCE_BUSINESS_TYPES.CYBERSECURITY === type && !isEmpty(home?.personal_details?.date_of_birth)) ||
          (INSURANCE_BUSINESS_TYPES.HOME === type && !isEmpty(home?.productType)) ?
          tabsCount : targetTab;
      }
    }
    setValue(targetTab);
    setQuoteType(type);
  };

  const disableSteps = () => {
    let isAutoPolicyBounded = !isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[1]];
    return {
      flood:
        isEmpty(floodDetails?.annexPrefill) ||
        !floodDetails?.annexPrefill?.isFloodInfo ||
        (!isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[2]]) ||
        applicant.propertyInfoLoader ||
        (!isDisabledPropertyInfo && !isPropertyInfoCovered()),
      vehicle: !vehiclesList?.length || isAutoPolicyBounded,
      drvier: !driversList?.length || isAutoPolicyBounded,
      policy: isEmpty(insurance?.residency_status) || isAutoPolicyBounded,
      pet: petDetails?.isBreedError || !petDetails?.petList?.length || petDetails?.isPolicyBounded,
      life: isEmpty(home?.personal_details?.date_of_birth) || isEmpty(lifeDetails?.lifeInfo),
      umbrella:
        isEmpty(home?.personal_details?.date_of_birth) ||
        isEmpty(umbrellaDetails?.umbrellaInfo?.questionGroups),
      propertyInfo: isDisabledPropertyInfo,
    };
  };

  const tabs: { [key: string]: any } = tabElements({
    handleBack,
    handleNext,
    value,
    annexPrefill: floodDetails?.annexPrefill,
    goToLastTab,
    add,
    disabledSteps: disableSteps(),
    tabsCount,
    redirectToStep,
  });
  const drawTabPanels = () => {
    return tabKey
      .filter(e => !excludeLOBFromStepper.includes(e))
      .map((lobType: any, index: number) => {
        let tabIndex = getStepperIndex(lobType, tabKey);
        return tabs[lobType].tabPanel(index + tabIndex);
      });
  };

  const drawTabs = () => {
    return tabKey
      .filter(e => !excludeLOBFromStepper.includes(e))
      .map((lobType: any, index: number) => {
        let tabIndex = getStepperIndex(lobType, tabKey);
        return tabs[lobType].tab(index + tabIndex);
      });
  };

  const handleStepperChange = (event: any, newValue: any) => {
    if (newValue !== value) {
      if (
        (lobTab['DRIVER'] === value ||
          lobTab[INSURANCE_BUSINESS_TYPES.AUTO] === value) &&
        ((newValue === (value + 1) && !enableRatePage) ||
          newValue === tabsCount)
      ) {
        const switchData = {
          isVehicleToDriver: (value + 1) === newValue && lobTab[INSURANCE_BUSINESS_TYPES.AUTO] === value,
          isDriverToPolicy: (value + 1) === newValue && lobTab['DRIVER'] === value,
          isRedirectToRatePage: newValue === tabsCount
        }
        setSwitchDetailFromDriver(switchData);
      } else {
        setValue(newValue);
        setSwitchDetailFromDriver({});
      }
      if (lobTab['AUTO'] !== value) {
        setVehicleDetails({
          isVinCleared: false,
        });
      }
      setLobPriority('');
      setAdd(false);
    }
  };

  const handleDashboardRedirect = () => {
    goToDashboard();
    flushAppDetails();
  };
  return (
    <div
      className={
        tabsCount === value
          ? `container quote-container rates-container  ${classes.root}`
          : `container quote-container ${classes.root}`
      }
    >
      {!inIframe() && tabKey.length ? (
        <button onClick={() => handleDashboardRedirect()} className='link-label dashboard-btn'>
          <KeyboardBackspaceIcon className='mr-2 left-arrow-icon' />
          Dashboard
        </button>
      ) : (
        ''
      )}
      {!!enableFeedbackButton && <FeedbackButton />}
      <div className='steps-wrap'>
        <AppBar
          elevation={0}
          position='static'
          className={`bg-white ${
            getStepperTabLength(appointmentList) > 8 ? 'step-header-8' : 'step-header'
          }`}
        >
          <Tabs
            variant='scrollable'
            scrollButtons='auto'
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
            value={value}
            onChange={handleStepperChange}
            aria-label='Questionnaires'
          >
            {tabKey.length && (
              <Tab
                value={0}
                label={APPLICANT_LABEL_NAME}
                wrapped
                {...allProps(0)}
                icon={
                  <CustomIcon
                    label={APPLICANT_LABEL_NAME}
                    icon='User'
                    index={0}
                    value={value}
                    enableStep={false}
                  />
                }
              />
            )}
            {tabKey.length && isShowPropertyInfo && (
              <Tab
                value={tabsCount + 1}
                label={PROPERTY_INFO.LABEL}
                disabled={disableSteps().propertyInfo}
                wrapped
                {...allProps(0)}
                icon={
                  <CustomIcon
                    label={PROPERTY_INFO.LABEL}
                    icon='Property'
                    index={tabsCount + 1}
                    value={value}
                    enableStep={false}
                  />
                }
              />
            )}
            {drawTabs()}
            {tabKey.length && (
              <Tab
                value={tabsCount}
                disabled={!enableRatePage}
                label={RATES_LABEL_NAME}
                wrapped
                {...allProps(0)}
                icon={
                  <CustomIcon
                    label={RATES_LABEL_NAME}
                    icon='Rates'
                    index={tabsCount}
                    value={value}
                    enableStep={!enableRatePage}
                  />
                }
              />
            )}
          </Tabs>
        </AppBar>
        {tabKey.length ? (
          <TabPanel label={APPLICANT_LABEL_NAME} value={value} index={0}>
            <ApplicantForm
              onNextTab={(index: number) => handleNext(index)}
              onPrevTab={(index: number) => handleBack(index)}
              currentIndex={value}
              goToLastTab={(currValue: number) => goToLastTab(currValue)}
              tabsCount={tabsCount}
              quoteType={quoteType}
              redirectToStep={redirectToStep}
            />
          </TabPanel>
        ) : null}
        {tabKey.length ? (
          <TabPanel label={PROPERTY_INFO.LABEL} value={value} index={tabsCount + 1}>
            <PropertyInfo
              onNextTab={(index: number) => handleNext(index)}
              onPrevTab={(index: number) => handleBack(index)}
              currentIndex={tabsCount + 1}
              goToLastTab={() => goToLastTab(tabsCount - 1)}
              tabsCount={tabsCount}
              quoteType={quoteType}
              redirectToStep={redirectToStep}
            />
          </TabPanel>
        ) : null}
        {drawTabPanels()}
        {tabKey.length ? (
          <TabPanel label={RATES_LABEL_NAME} value={value} index={tabsCount}>
            <ProductRatesDetails
              appointments={appointments}
              onPrevTab={(index: number) => goToFirstTab(index)}
              goBackToCertainTab={(targetTab: number, type: string) =>
                goBackToCertainTab(targetTab, type)
              }
              veriskIsLoading={veriskLoading}
              goToLastTab={(shouldGoToLastTab: boolean) => setGoToRates(shouldGoToLastTab)}
              tabsCount={tabsCount}
              setTab={setTab}
              tabValue={value}
              quoteType={quoteType}
              isHeapDetailLoaded={isHeapDetailLoaded}
              redirectToStep={redirectToStep}
            />
          </TabPanel>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  home,
  partner: { appointments, storedRequestId, loader },
  floodDetails,
  quote,
  common: {
    newProducts,
    pconfig,
    redirectToViewQuote,
    isVeriskLoading,
    enableRatePage,
    policyBounded,
    pageTracking,
    isHeapDetailLoaded,
    selectedBusinessOption,
  },
  vehicle: { vinLoader, vehiclesList },
  petDetails,
  viewQuoteDetails,
  quakeDetails,
  driver: { driversList },
  insurance,
  applicant,
  homeWarrantyDetails,
  lifeDetails,
  cyberSecurityDetails,
  umbrellaDetails
}: AppComponents.QuoteQuestionnaireStore) => ({
  appointments,
  storedRequestId,
  newProducts,
  pconfig,
  redirectToViewQuote,
  home,
  isVeriskLoading,
  vinLoader,
  petDetails,
  viewQuoteDetails,
  quakeDetails,
  floodDetails,
  quote,
  vehiclesList,
  driversList,
  insurance,
  applicant,
  enableRatePage,
  policyBounded,
  pageTracking,
  homeWarrantyDetails,
  isHeapDetailLoaded,
  lifeDetails,
  cyberSecurityDetails,
  umbrellaDetails,
  loader,
  selectedBusinessOption,
});

const mapDispatchToProps = (dispatch: any): AppComponents.QuoteQuestionnaireDispatch =>
  bindActionCreators(
    {
      setHomeState,
      setAppointments,
      setFloodQuotes,
      setRequestId,
      updateApplicantAddress,
      updateApplicantDetails,
      addVehicle,
      vehicleSelect,
      vinSubmitSuccess,
      addDriver,
      addDetails,
      getQuoteLobDetails,
      updateQuoteDetails,
      setAutoQuoteDetails,
      fetchPconfig,
      setAnnexPrefill,
      setPrefillData,
      setRedirectToViewQuoteFlag,
      cleanHelperProduct,
      updateDriverName,
      setQuoteCompleted,
      setQuakeQuotes,
      updateSelectedCarriers,
      setPetQuotes,
      storePetListData,
      clearHomeState,
      clearFloodState,
      clearQuakeState,
      clearPetState,
      clearAutoQuoteSate,
      clearDriverInfo,
      fetchViewQuotes,
      startHomePolling,
      setViewQuote,
      flushAppDetails,
      setSwitchDetailFromDriver,
      setRatePageEnable,
      storeBoundedCarrierDetails,
      setMissingSpouse,
      driverSelectedForEdit,
      setVehicleDetails,
      setHomeWarrantyQuoteDetails,
      clearHomeWarrantyState,
      clearCyberSecurityState,
      clearLifeState,
      clearUmbrellaState,
      setLobPriority,
      setCyberSecurityQuoteDetails,
      storeLifeQuoteDetails,
      clearVehiclesList,
      clearInsurenaceDetails,
      setQuakeHerdId,
      setFloodHerdId,
      storeUmbrellaQuoteDetails,
      setIsUpdatedPropertyInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QuoteQuestionnaire);
