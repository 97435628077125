import React, { useEffect } from 'react';

import AutoApplicantForm from '../../components/auto-applicant-form';

const AutoApplicant = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='applicant-form-wrapper'>
      <AutoApplicantForm />
    </div>
  );
};

export default AutoApplicant;
