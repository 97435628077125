import React from 'react';
import type { ChangeEvent, FocusEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, isEqual, startCase, xorWith } from 'lodash';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import type { Theme, WithStyles } from '@material-ui/core';
import { v4 } from 'uuid';
import {
  Add as AddIcon,
  ArrowBackIos as ArrowBackIosIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import config from '../../../config/config';
import {
  fetchPetBreedsDetails,
  updateBreedsDetails,
  fetchPetQuotes,
  setApplicantSuccess,
  setPetQuotes,
  saveApplicantError,
  updateRequoteProducts,
} from '../../redux/actions';
import SpinnerLoader from '../spinner-loader';
import ThemesImages from '../themes-images';
import {
  allowEditingQuote,
  getIsHeapAnalyticsEnabled,
  getStartCase,
  removeExistingRate,
  stringRegex,
} from '../../../utils';
import {
  AGE,
  ASTERISK,
  BACK,
  CAT,
  CONTINUE,
  DELETE,
  DOG,
  FEMALE,
  GENDER,
  GET_QUOTE,
  INSURANCE_PRODUCTS,
  INSURANCE_BUSINESS_TYPES,
  MALE,
  MAX_PET_BREEDS_INFO,
  NEUTERED,
  NO,
  PET_INFO,
  QUOTING_STEPS_PAGES_NAME,
  REQUIRED,
  SELECT,
  SPAY,
  YES,
} from '../../../constants';
import './pet-form.scss';

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  submitSpinner: {
    color: 'white !important',
    marginBottom: '2px',
    marginRight: '0px',
  },
});

const getInitPetInfoData = (): AppComponents.PetInfoDataType => ({
  breedName: { error: false, helperText: '', value: '' },
  isSpayedOrNeutered: true,
  petAge: { error: false, helperText: '', value: '' },
  petGender: startCase(MALE),
  petName: { error: false, helperText: '', value: '' },
  petType: DOG,
  puid: v4(),
  spayedOrNeutered: '',
});

const petInfoForm = (component: Pet) => {
  const {
    state: { petInfoList },
    props: { petDetails },
    onChangePetInfo,
    onPetNameOnBlur,
    removePetInfo,
  } = component;
  const disablePetEdit = allowEditingQuote();
  const { petBreedsDetails, petBreedsDetailsLoader } = petDetails;
  return !isEmpty(petInfoList) ? (
    petInfoList.map((item, index) => (
      <div key={item.puid}>
        {petInfoList.length > 1 && (
          <div className='row mb-3'>
            <div className='col-lg-6'>
              <Typography className='pet-heading d-flex justify-content-start' variant='h6'>
                {`Pet ${index + 1}`}
              </Typography>
            </div>
            {!disablePetEdit?.pet ? (
              <div className='col-lg-6 position-sm'>
                <div className='d-flex justify-content-end delete-icon'>
                  <Tooltip
                    arrow
                    onClick={() => {
                      removePetInfo(item.puid);
                    }}
                    placement='top'
                    title={DELETE}
                  >
                    <DeleteOutlineIcon />
                  </Tooltip>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        <div className='row marginBottom-4'>
          <div className='col-lg-4 mb-6'>
            <TextField
              disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
              error={item.petName.error}
              fullWidth
              inputProps={{ maxLength: 250 }}
              helperText={item.petName.helperText}
              InputLabelProps={{
                shrink: true,
              }}
              label={
                <>
                  {PET_INFO.PET_NAME_LABEL}
                  <span className='red-star-20'>{ASTERISK}</span>
                </>
              }
              name={PET_INFO.PET_NAME}
              onBlur={event => onPetNameOnBlur(event, item.puid)}
              onChange={event => onChangePetInfo(item.puid, event)}
              value={item?.petName.value}
              variant='standard'
            />
          </div>
          <div className='col-lg-4 mb-6'>
            <FormControl className='radio-wrap w-100' component='fieldset'>
              <FormLabel
                className='radio-label'
                component='legend'
                disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
              >
                {PET_INFO.ANIMAL_TYPE_LABEL}
                <span className='red-star'>{ASTERISK}</span>
              </FormLabel>
              <RadioGroup
                className='w-100 pet-radio'
                name={PET_INFO.PET_TYPE}
                onChange={event => onChangePetInfo(item.puid, event)}
                row
                value={item?.petType}
              >
                <FormControlLabel
                  control={<Radio size='small' />}
                  disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                  label={DOG}
                  value={DOG}
                />
                <FormControlLabel
                  control={<Radio size='small' />}
                  disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                  label={CAT}
                  value={CAT}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='col-lg-4 mb-6'>
            <FormControl error={item.breedName.error} fullWidth>
              <InputLabel
                htmlFor='uncontrolled-native'
                variant='standard'
                disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
              >
                {PET_INFO.BREED}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <NativeSelect
                disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                error={item.breedName.error}
                inputProps={{
                  name: PET_INFO.BREED_NAME,
                }}
                onChange={event => onChangePetInfo(item.puid, event)}
                value={item?.breedName.value}
              >
                <option value={startCase(SELECT)}>{startCase(SELECT)}</option>
                {!isEmpty(petBreedsDetails[item?.petType?.toLowerCase()]?.breedList) &&
                  petBreedsDetails[item?.petType?.toLowerCase()].breedList.map((petBreed: any) => (
                    <option key={petBreed.value} value={petBreed.value}>
                      {petBreed?.label}
                    </option>
                  ))}
              </NativeSelect>
              <FormHelperText>{item.breedName.helperText}</FormHelperText>
            </FormControl>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 mb-6'>
            <FormControl className='radio-wrap w-100' component='fieldset'>
              <FormLabel
                component='legend'
                disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
              >
                {GENDER}
                <span className='red-star'>{ASTERISK}</span>
              </FormLabel>
              <RadioGroup
                className='pet-radio'
                name={PET_INFO.PET_GENDER}
                onChange={event => onChangePetInfo(item.puid, event)}
                row
                value={item?.petGender}
              >
                <FormControlLabel
                  className='w-50'
                  control={<Radio size='small' />}
                  disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                  label={startCase(MALE)}
                  value={startCase(MALE)}
                />
                <FormControlLabel
                  className='w-50'
                  control={<Radio size='small' />}
                  disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                  label={startCase(FEMALE)}
                  value={startCase(FEMALE)}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='col-lg-4 mb-6'>
            <FormControl className='radio-wrap w-100' component='fieldset'>
              <FormLabel className='radio-label' component='legend' disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}>
                {PET_INFO.IS_SPAYED_LABEL}
                <span className='red-star'>{ASTERISK}</span>
              </FormLabel>
              <RadioGroup
                className='pet-radio'
                name={PET_INFO.IS_SPAYED_OR_NEUTERED}
                onChange={event => onChangePetInfo(item.puid, event)}
                row
                value={!!item?.isSpayedOrNeutered}
              >
                <FormControlLabel
                  control={<Radio size='small' />}
                  disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                  label={YES}
                  value
                />
                <FormControlLabel
                  control={<Radio size='small' />}
                  disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                  label={NO}
                  value={false}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className='col-lg-4 mb-6'>
            <FormControl error={item.petAge.error} fullWidth>
              <InputLabel htmlFor='uncontrolled-native' variant='standard' disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}>
                {AGE}
                <span className='star-error'>{ASTERISK}</span>
              </InputLabel>
              <NativeSelect
                disabled={!!petBreedsDetailsLoader || disablePetEdit?.pet}
                inputProps={{
                  id: 'uncontrolled-native',
                  name: PET_INFO.PET_AGE,
                }}
                onChange={event => onChangePetInfo(item.puid, event)}
                value={item.petAge.value}
              >
                <option value={startCase(SELECT)}>{startCase(SELECT)}</option>
                {!isEmpty(petBreedsDetails[item?.petType?.toLowerCase()]?.ageList) &&
                  petBreedsDetails[item?.petType?.toLowerCase()].ageList.map((petAge: any) => (
                    <option key={petAge.value} value={petAge.value}>
                      {petAge?.label}
                    </option>
                  ))}
              </NativeSelect>
              <FormHelperText>{item.petAge.helperText}</FormHelperText>
            </FormControl>
          </div>
        </div>
        {!isEmpty(petInfoList) && petInfoList.length > 1 && petInfoList.length !== index + 1 && (
          <div className='mb-6'>
            <Divider />
          </div>
        )}
      </div>
    ))
  ) : (
    <></>
  );
};

class Pet extends React.PureComponent<
  AppComponents.PetProps & WithStyles<typeof styles>,
  AppComponents.PetState
> {
  themeType = '';
  constructor(props: AppComponents.PetProps & WithStyles<typeof styles>) {
    super(props);
    const { petList } = props.petDetails;
    const petInfoListState = this.petListStoreToState(petList);
    this.state = {
      petInfoList: petInfoListState,
      shouldChangeTab: false,
      hideBackButton: false,
    };
    const { pconfig } = props.home;
    this.themeType = !!pconfig?.dale_config?.branding_on_dale_details?.template_name
      ? pconfig?.dale_config?.branding_on_dale_details?.template_name
      : config.hippo.template_name;
  }

  componentDidMount = () => {
    const {
      fetchPetBreedsDetails,
      add,
      petDetails,
      applicant,
      common,
      partner,
      insurance,
      floodDetails,
    } = this.props;
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.PET_INFO);
    }
    const { petList } = petDetails;
    if (
      (!common?.isSaveApplicantError &&
        isEmpty(petList) &&
        !applicant?.saveApplicantLoader &&
        !isEmpty(petDetails?.herdId) &&
        !!applicant?.isApplicantSuccess) ||
      (!isEmpty(petList) &&
        isEmpty(petDetails?.petBreedsDetails?.dog) &&
        isEmpty(petDetails?.petBreedsDetails?.cat))
    ) {
      fetchPetBreedsDetails();
      this.props.setApplicantSuccess(false);
    }
    if (!isEmpty(petDetails?.petList)) {
      const petInfoListState = this.petListStoreToState(petDetails?.petList);
      this.setState(prevState => ({
        ...prevState,
        petInfoList: petInfoListState,
      }));
    }
    const { policyBounded } = common;
    const appointmentKeys = Object.keys(partner?.appointments);
    const hideBackStep =
      (isEmpty(insurance?.residency_status) && appointmentKeys.includes('AUTO')) ||
      (appointmentKeys.includes('FLOOD') &&
        !appointmentKeys.includes('AUTO') &&
        (isEmpty(floodDetails?.annexPrefill) || !floodDetails?.annexPrefill?.isFloodInfo));
    if (
      (!isEmpty(policyBounded) &&
        (policyBounded[INSURANCE_PRODUCTS[1]] ||
          (!appointmentKeys.includes('AUTO') && policyBounded[INSURANCE_PRODUCTS[2]]))) ||
      hideBackStep
    ) {
      this.setState({
        hideBackButton: true,
      });
    }
    if (add) {
      this.addNewPet();
    }
  };

  UNSAFE_componentWillReceiveProps(newProps: any) {
    const { currentIndex, goToLastTab, petDetails, applicant, common } = newProps;
    const { petBreedsDetailsLoader, isBreedError } = petDetails;
    if (
      !common?.isSaveApplicantError &&
      !isEmpty(petDetails?.herdId) &&
      !applicant?.saveApplicantLoader &&
      !isBreedError &&
      !!applicant?.isApplicantSuccess
    ) {
      this.props.fetchPetBreedsDetails();
      this.props.setApplicantSuccess(false);
    }
    if ((petBreedsDetailsLoader && isBreedError) || common?.isSaveApplicantError) {
      goToLastTab(currentIndex);
      this.props.saveApplicantError(false);
    }
  }

  componentDidUpdate = (prevProps: any) => {
    const {
      applicant,
      add,
      petDetails: { petBreedsDetailsLoader, herdId },
    } = this.props;
    if (
      !applicant?.saveApplicantLoader &&
      prevProps.petDetails.petBreedsDetailsLoader !== petBreedsDetailsLoader &&
      add &&
      !petBreedsDetailsLoader &&
      herdId
    ) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  };

  petListStoreToState = (petList: Array<AppComponents.petListStoreDataType>) => {
    return !isEmpty(petList)
      ? petList.map(item => ({
        ...item,
        breedName: { error: false, helperText: '', value: item.breedName },
        petAge: { error: false, helperText: '', value: item.petAge },
        petName: { error: false, helperText: '', value: item.petName },
      }))
      : [getInitPetInfoData()];
  };

  validate = (): boolean => {
    let isValid = true;
    const { petInfoList } = this.state;
    const petInfoListData = [...petInfoList];
    for (let i = 0; i < petInfoList.length; i++) {
      const updatedInfo = petInfoList[i];
      const errorKeys = ['breedName', 'petName', 'petAge'] as Array<AppComponents.petFormErrorKeys>;
      for (const key of errorKeys) {
        if (!petInfoList[i][key].value || petInfoList[i][key].value === startCase(SELECT)) {
          updatedInfo[key].error = true;
          updatedInfo[key].helperText = REQUIRED;
          isValid = false;
        } else if (updatedInfo[key].error) {
          updatedInfo[key].error = false;
          updatedInfo[key].helperText = '';
        }
      }
      petInfoListData[i] = updatedInfo;
    }
    this.setState(prevState => ({
      ...prevState,
      petInfoList: petInfoListData,
    }));
    return isValid;
  };

  onContinueClick = (event: any) => {
    event.preventDefault();
    const isValid = this.validate();
    const { petInfoList } = this.state;
    const {
      goToLastTab,
      currentIndex,
      updateBreedsDetails,
      fetchPetQuotes,
      petDetails,
      setPetQuotes,
      updateRequoteProducts,
      common,
    } = this.props;
    const disablePetEdit = allowEditingQuote();
    if (disablePetEdit?.pet) {
      goToLastTab(currentIndex);
    } else if (isValid) {
      let isHeapAnalyticsEnabled: boolean =
        !!window.heap && getIsHeapAnalyticsEnabled();
      if (isHeapAnalyticsEnabled) {
        window.heap.track(QUOTING_STEPS_PAGES_NAME?.PET_INFO_SUBMIT);
      }
      let { requoteLobs } = common;
      if (!isEmpty(requoteLobs) && requoteLobs?.includes(INSURANCE_PRODUCTS[4])) {
        const lobList = requoteLobs?.filter((item: string) => item !== INSURANCE_PRODUCTS[4]);
        updateRequoteProducts(lobList);
      }
      const updatedDetails = petInfoList.map((petinfo, index) => ({
        ...petinfo,
        breedName: petinfo.breedName.value,
        petAge: petinfo.petAge.value,
        petName: petinfo.petName.value,
        order: index + 1,
      }));
      const isSamePetList: boolean = isEmpty(xorWith(updatedDetails, petDetails?.petList, isEqual));
      if (
        !isSamePetList ||
        (!isEmpty(petDetails?.quoteList) && !!petDetails?.quoteList[0]?.quoteError) ||
        isEmpty(petDetails?.quoteList)
      ) {
        if (sessionStorage.selectedQuoteList) {
          const selectedQuotes = removeExistingRate(
            JSON.parse(sessionStorage.selectedQuoteList),
            INSURANCE_BUSINESS_TYPES.PET
          );
          sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
        }
        setPetQuotes({ quoteList: [], upcomingCarrierList: [], });
        fetchPetQuotes(updatedDetails);
        updateBreedsDetails(updatedDetails);
      }
      goToLastTab(currentIndex);
    }
  };

  onBackClick = () => {
    const { onPrevTab, currentIndex } = this.props;
    return onPrevTab(currentIndex);
  };

  addNewPet = (scroll?: boolean) => {
    const { petInfoList } = this.state;
    if (!isEmpty(petInfoList) && petInfoList.length >= MAX_PET_BREEDS_INFO) {
      return;
    }
    let isHeapAnalyticsEnabled: boolean =
      !!window.heap && getIsHeapAnalyticsEnabled();
    if (isHeapAnalyticsEnabled) {
      window.heap.track(QUOTING_STEPS_PAGES_NAME?.ADD_PET);
    }
    this.setState(
      prevState => ({
        ...prevState,
        petInfoList: [...prevState.petInfoList, getInitPetInfoData()],
      }),
      () => {
        if (scroll) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }
    );
  };

  onPetNameOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    event.preventDefault();
    const petInfoListData = [...this.state.petInfoList];
    const updateIndex = petInfoListData.findIndex(item => item.puid === key);
    petInfoListData[updateIndex].petName.value = getStartCase(event.target.value);
    this.setState(prevState => ({ ...prevState, petInfoList: petInfoListData }));
  };

  onChangePetInfo = (
    key: string,
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const { petInfoList } = this.state;
    const { name, value } = event.target;
    if (
      !isEmpty(name) &&
      name === PET_INFO.PET_NAME &&
      !isEmpty(value) &&
      !stringRegex.test(value)
    ) {
      return;
    }
    const petInfoListData = [...petInfoList];
    const updateIndex = petInfoListData.findIndex(item => item.puid === key);
    if (updateIndex !== -1) {
      let updateObj = petInfoListData[updateIndex];
      if (name === PET_INFO.IS_SPAYED_OR_NEUTERED) {
        updateObj.isSpayedOrNeutered = value === 'true';
      } else if (name === PET_INFO.PET_TYPE) {
        updateObj = {
          ...updateObj,
          breedName: { ...updateObj.breedName, value: startCase(SELECT) },
          petAge: { ...updateObj.petAge, value: startCase(SELECT) },
          petType: value,
        };
      } else if (name === PET_INFO.PET_GENDER) {
        updateObj.petGender = value;
      } else {
        const textFieldName = name as AppComponents.petFormErrorKeys;
        const nameVal = updateObj[textFieldName];
        updateObj = {
          ...updateObj,
          [name]: {
            ...nameVal,
            value,
          },
        };
      }
      updateObj.spayedOrNeutered = updateObj?.isSpayedOrNeutered
        ? petInfoList[updateIndex]?.petGender === startCase(MALE)
          ? NEUTERED
          : petInfoList[updateIndex]?.petGender === startCase(FEMALE)
            ? SPAY
            : ''
        : '';
      petInfoListData[updateIndex] = updateObj;
      this.setState(prevState => ({
        ...prevState,
        petInfoList: petInfoListData,
      }));
    }
  };

  removePetInfo = (key: string) => {
    const { petInfoList } = this.state;
    this.setState(prevState => ({
      ...prevState,
      petInfoList: petInfoList.filter(item => item.puid !== key),
    }));
  };

  render() {
    const { applicant, classes, common, currentIndex, petDetails } = this.props;
    const { stepSubmitLoader, showGetQuoteButton } = !!common && common;
    const { petBreedsDetailsLoader } = petDetails;
    const { petInfoList, hideBackButton } = this.state;
    const disablePetEdit = allowEditingQuote();
    return (
      <>
        {petBreedsDetailsLoader || !petDetails?.herdId || applicant?.saveApplicantLoader ? (
          <div className='launch-loader-wrapper row justify-content-center align-items-start'>
            <img
              className='launch-loader-border'
              src={ThemesImages[this.themeType].petInfoLoader}
              alt='Please wait...'
            />
          </div>
        ) : (
          <div className='petDetails-wrapper pt-0'>
            <div className='row form-wrapper d-flex justify-content-center'>
              <div className='col-lg-10'>
                {petInfoForm(this)}
                {!isEmpty(petInfoList) &&
                  petInfoList?.length < MAX_PET_BREEDS_INFO &&
                  !disablePetEdit?.pet ? (
                  <div className='row'>
                    <div className='col-lg-12'>
                      <button className='Add-another-btn' onClick={() => this.addNewPet(true)}>
                        <AddIcon className='mr-2' />
                        {PET_INFO.ADD_ANOTHER_PET}
                      </button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <Divider />
              </div>
            </div>
            <div className='row form-submit-action d-flex justify-content-center'>
              <div className='col-lg-12'>
                {(common?.isAddingProducts && currentIndex === 1) || hideBackButton ? (
                  ''
                ) : (
                  <button className='btn m-2 btn-back mh-button' onClick={this.onBackClick}>
                    <ArrowBackIosIcon className='mr-2' />
                    {BACK}
                  </button>
                )}
                <button className='btn m-2 btn-success mh-button' onClick={this.onContinueClick}>
                  <span>{CONTINUE}</span>
                  {petBreedsDetailsLoader && <SpinnerLoader styleData={classes.submitSpinner} />}
                </button>
                {showGetQuoteButton && (
                  <button
                    className='btn m-2 btn-success mh-button'
                    onClick={this.onContinueClick}
                  >
                    <span>{GET_QUOTE}</span>
                    {stepSubmitLoader && <SpinnerLoader styleData={classes.submitSpinner} />}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  common,
  home,
  petDetails,
  applicant,
  partner,
  insurance,
  floodDetails,
}: AppComponents.PetStore): AppComponents.PetStore => ({
  common,
  home,
  petDetails,
  applicant,
  partner,
  insurance,
  floodDetails,
});

const mapDispatchToProps = (dispatchProp: any): AppComponents.PetDispatch =>
  bindActionCreators(
    {
      fetchPetBreedsDetails,
      updateBreedsDetails,
      fetchPetQuotes,
      setApplicantSuccess,
      setPetQuotes,
      updateRequoteProducts,
      saveApplicantError,
    },
    dispatchProp
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)<any>(Pet));
