export const MA_AGE_LICENSED = ['16', '17', '18', '19', '20', '21 || 21+'];
export const MA_STATE_COVERAGES = {
  BI: [
    '20/40 || 20/40 (State Minimum) || false',
    '20/50 || 20/50 || false',
    '25/50 || 25/50 || false',
    '50/50 || 50/50 || false',
    '50/100 || 50/100 || false',
    '100/100 || 100/100 || false',
    '100/300 || 100/300 || true',
    '250/500 || 250/500 || false',
    '300/300 || 300/300 || false',
    '500/500 || 500/500 || false',
    '1000/1000 || 1000/1000 || false',
    '55 CSL || 55 CSL || false',
    '100 CSL || 100 CSL || false',
    '300 CSL || 300 CSL || false',
    '500 CSL || 500 CSL || false',
  ],
  UM: [
    '20/40 || 20/40 (State Minimum) || false',
    '20/50 || 20/50 || false',
    '25/50 || 25/50 || false',
    '25/60 || 25/60 || false',
    '35/80 || 35/80 || false',
    '50/100 || 50/100 || false',
    '100/100 || 100/100 || false',
    '100/200 || 100/200 || false',
    '100/300 || 100/300 || true',
    '200/400 || 200/400 || false',
    '250/500 || 250/500 || false',
    '250/1000 || 250/1000 || false',
    '300/500 || 300/500 || false',
    '500/500 || 500/500 || false',
    '500/1000 || 500/1000 || false',
  ],
  UIM: [
    '20/40 || 20/40 (State Minimum) || false',
    '20/50 || 20/50 || false',
    '25/50 || 25/50 || false',
    '25/60 || 25/60 || false',
    '35/80 || 35/80 || false',
    '50/100 || 50/100 || false',
    '100/100 || 100/100 || false',
    '100/200 || 100/200 || false',
    '100/300 || 100/300 || true',
    '200/400 || 200/400 || false',
    '250/500 || 250/500 || false',
    '250/1000 || 250/1000 || false',
    '300/500 || 300/500 || false',
    '500/500 || 500/500 || false',
    '500/1000 || 500/1000 || false',
  ],
  PD: [
    '5000 || $5,000 (State Minimum) || false',
    '10000 || $10,000 || false',
    '15000 || $15,000 || false',
    '25000 || $25,000 || false',
    '35000 || $35,000 || false',
    '50000 || $50,000 || false',
    '100000 || $100,000 || true',
    '250000 || $250,000 || false',
    '300000 || $300,000 || false',
    '500000 || $500,000  || false',
  ],
  MP: [
    'None || None || false',
    '500 || $500 || false',
    '1000 || $1,000 || false',
    '2000 || $2,000 || true',
    '2500 || $2,500 || false',
    '5000 || $5,000 || false',
    '10000 || $10,000 || false',
    '15000 || $15,000 || false',
    '25000 || $25,000 || false',
    '50000 || $50,000 || false',
    '100000 || $100,000 || false',
  ],
  OtherCollisionDeductible: [
    'No Coverage || No Coverage || false',
    '300 || 300 || false',
    '500 || 500 || false',
    '1000 || 1000 || true',
    '2000 || 2000 || false',
    '2500 || 2500 || false',
  ],
  CollisionDeductible: [
    'No Coverage || No Coverage || false',
    '300 || 300 || false',
    '500 || 500 || false',
    '1000 || 1000 || true',
    '2000 || 2000 || false',
    '2500 || 2500 || false',
  ],
  TowingDeductible: [
    'No Coverage || No Coverage || true',
    '50 || 50 || false',
    '100 || 100 || false',
  ],
  RentalDeductible: [
    'No Coverage || No Coverage || true',
    '15/450 || 15/450 || false',
    '30/900 || 30/900 || false',
    '45/1350 || 45/1350 || false',
    '100/3000 || 100/3000 || false',
  ],
  PriorLiabilityLimit: [
    '20/40 || 20/40 || false',
    '20/50 || 20/50 || false',
    '25/50 || 25/50 || false',
    '25/60 || 25/60 || false',
    '35/80 || 35/80 || false',
    '50/100 || 50/100 || false',
    '100/100 || 100/100 || false',
    '100/200 || 100/200 || false',
    '100/300 || 100/300 || true',
    '200/400 || 200/400 || false',
    '250/500 || 250/500 || false',
    '250/1000 || 250/1000 || false',
    '300/500 || 300/500 || false',
    '500/500 || 500/500 || false',
    '500/1000 || 500/1000 || false',
  ],
};
export const MA_PREFERRED_DEDUCTIBLE = [
  '100 || 100 || false',
  '250 || 250 || false',
  '500 || 500 || false',
  '750 || 750 || false',
  '1000 || 1000 || true',
  '2000 || 2000 || false',
  '2500 || 2500 || false',
  '3000 || 3000 || false',
  '4000 || 4000 || false',
  '5000 || 5000 || false',
];
