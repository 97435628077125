/* istanbul ignore file */
import { all } from 'redux-saga/effects';
import AMSDetailsSagas from './ams-details';
import AMSReportSagas from './ams-report';
import applicantSagas from './applicant';
import authSagas from './auth';
import bulkQuote from './bulk-quote';
import commonSagas from './helper';
import dashboardSagas from './dashboard';
import driverSagas from './driver';
import errorDetails from './error';
import homeSagas from './home';
import quotesSagas from './quote';
import vehicleSagas from './vehicle';
import floodDetailsSagas from './flood-details';
import partnerSagas from './partner';
import organizationsSagas from './organizations';
import quakeDetailsSagas from './quake-details';
import feedback from './feedback';
import petDetailsSagas from './pet-details';
import viewQuoteDetailsSagas from './view-quote';
import homeWarrantyDetails from './home-warranty';
import lifeDetails from './life';
import cyberSecurityDetails from './cyber-security';
import umbrellaDetails from './umbrella-details';
import propertyInfoDetails from './property-info';

export default function* rootSaga() {
  yield all([
    AMSDetailsSagas(),
    AMSReportSagas(),
    authSagas(),
    applicantSagas(),
    bulkQuote(),
    commonSagas(),
    dashboardSagas(),
    driverSagas(),
    errorDetails(),
    homeSagas(),
    quotesSagas(),
    vehicleSagas(),
    floodDetailsSagas(),
    partnerSagas(),
    organizationsSagas(),
    quakeDetailsSagas(),
    feedback(),
    petDetailsSagas(),
    viewQuoteDetailsSagas(),
    homeWarrantyDetails(),
    lifeDetails(),
    cyberSecurityDetails(),
    umbrellaDetails(),
    propertyInfoDetails(),
  ]);
}
