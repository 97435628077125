import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// Dashboard table head title
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name of Insured',
  },
  {
    id: 'phone_number',
    numeric: false,
    disablePadding: false,
    label: 'Phone No.',
  },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'state', numeric: false, disablePadding: false, label: 'State' },
  {
    id: 'quote_date',
    numeric: false,
    disablePadding: false,
    label: 'Quote Date',
  },
  { id: 'quote_by', numeric: false, disablePadding: false, label: 'Quoted By' },
  { id: 'lob', numeric: false, disablePadding: false, label: 'LOB' },
  { id: 'org', numeric: false, disablePadding: false, label: 'Org' },
  { id: 'carrierBound', numeric: false, disablePadding: false, label: 'Carrier' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

const EnhancedTableHead = (props: AppComponents.TableHeadProps) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}

              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

export default EnhancedTableHead;
