import { Card, CardContent } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HIPPO_THEME, INSURANCE_PRODUCTS } from '../../../constants';
import ThemesImages from '../../../pages/components/themes-images';
import {
  fetchFloodQuoteDetails,
  questionnaireSubmitLoader,
  setFloodQuotes,
  setLineOfBusiness,
} from '../../../pages/redux/actions';
import { ReduxRootState } from '../../../pages/redux/store';
import FloodQuestionnaire from '../../components/flood-questionnaire';
import { useOrganizationQuery } from '../../redux/apis';
import { FloodFormProps } from './types';

const FloodDetails = ({
  onNextTab,
  onPrevTab,
  currentIndex,
  setLineOfBusiness,
  floodDetails,
  goToLastTab,
  redirectToStep,
}: FloodFormProps) => {
  const { data: { organization } = {} } = useOrganizationQuery();
  useEffect(() => {
    window.scrollTo(0, 0);
    setLineOfBusiness(INSURANCE_PRODUCTS[2]);
  }, [setLineOfBusiness]);
  const { detailsLoader } = floodDetails;
  const themeType = organization?.templateName;

  return (
    <>
      {!detailsLoader ? (
        <section className='result-wrapper position-relative flood-details-wrapper'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <Card elevation={0} className='applicant-home-form'>
                  <CardContent>
                    <FloodQuestionnaire
                      onNextTab={onNextTab}
                      onPrevTab={onPrevTab}
                      currentIndex={currentIndex}
                      goToLastTab={goToLastTab}
                      redirectToStep={redirectToStep}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className='launch-loader-wrapper row justify-content-center align-items-start'>
          <img
            className='launch-loader-border'
            src={ThemesImages[themeType ?? HIPPO_THEME].floodInfoLoader}
            alt='Please wait...'
          />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any): AppComponents.FloodFormDispatch =>
  bindActionCreators(
    { setLineOfBusiness, fetchFloodQuoteDetails, setFloodQuotes, questionnaireSubmitLoader },
    dispatch
  );

const mapStateToProps = ({ floodDetails }: ReduxRootState) => ({
  floodDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(FloodDetails);
