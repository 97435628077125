import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, startCase } from 'lodash';
import { fetchOrganizationsList } from '../../redux/actions';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MaterialTable from 'material-table';
import AddIcon from '@material-ui/icons/Add';
import AddEditOrganizationModal from '../add-edit-organization-modal';
import ShowImageAfterLoading from '../../components/show-image-after-loading';
import { dateSort, formatDate, getNestedTernaryData, stringSort } from '../../../utils';
import { tableIcons } from '../../../utils/table-icons';
import history from '../../../history';
import {
  ORGANIZATION_DETAILS_PATH,
  CREATED_DATE,
  EDIT,
  HTML_EM,
  ORG_CODE,
  ORG_LOGO,
  ORG_NAME,
  STATUS,
} from '../../../constants';
import './organization-table.scss';

const createData = (data: {
  orgId: any;
  orgName: string;
  orgCode: string;
  createdDate: any;
  orgLogo: any;
  tempName: any;
  partnerId: any;
  orgEmail: any;
  orgPhone: any;
  authToken: any;
  status: string;
}) => {
  return {
    ...data,
  };
};
export class OrganizationTable extends React.Component<
  AppComponents.OrganizationProps,
  AppComponents.OrganizationTableState
> {
  state: AppComponents.OrganizationTableState = {
    columns: [
      {
        title: 'Logo',
        field: ORG_LOGO,
        headerStyle: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        cellStyle: {
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        render: (rowData: any) => {
          const { orgLogo, orgName } = rowData;
          return (
            <>
              {!isEmpty(orgLogo) ? (
                <ShowImageAfterLoading url={orgLogo} imageName={orgName} />
              ) : (
                <span title={!isEmpty(orgName) && orgName}>
                  {getNestedTernaryData(!!orgName, orgName, HTML_EM)}
                </span>
              )}
            </>
          );
        },
      },
      {
        title: 'Organization',
        field: ORG_NAME,
        headerStyle: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        cellStyle: {
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.orgName, b.orgName),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.orgName ? rowData.orgName : ''}>
              {!!rowData?.orgName ? rowData.orgName : HTML_EM}
            </span>
          );
        },
      },
      {
        title: 'Organization Code',
        field: ORG_CODE,
        headerStyle: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        cellStyle: {
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => stringSort(a.orgCode, b.orgCode),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.orgCode ? rowData.orgCode : ''}>
              {!!rowData?.orgCode ? rowData.orgCode : HTML_EM}
            </span>
          );
        },
      },
      {
        title: 'Created Date',
        field: CREATED_DATE,
        headerStyle: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        cellStyle: {
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        customSort: (a: any, b: any) => dateSort(a.createdDate, b.createdDate),
        render: (rowData: any) => {
          return (
            <span title={!!rowData?.createdDate ? rowData.createdDate : ''}>
              {!!rowData?.createdDate ? rowData.createdDate : HTML_EM}
            </span>
          );
        },
      },
      {
        title: 'Status',
        field: STATUS,
        headerStyle: {
          whiteSpace: 'nowrap',
          width: '20%',
        },
        sorting: true,
        cellStyle: {
          width: '20%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#f55467',
          letterSpacing: '0.03em',
          fontWeight: 'bold',
        },
        customSort: (a: any, b: any) => stringSort(a.status, b.status),
        render: (rowData: any) => {
          return (
            <span
              className={`btnWidth font-weight ${
                !!rowData?.status
                  ? getNestedTernaryData(
                      rowData.status.toLowerCase() === 'active',
                      'text-success bold',
                      'text-red'
                    )
                  : ''
              }`}
            >
              {!isEmpty(rowData?.status) ? startCase(rowData.status) : HTML_EM}
            </span>
          );
        },
      },
    ],
    data: [],
    isAddOrganization: false,
    organizationRowData: null,
    organization: null,
  };

  componentDidMount = () => {
    this.props.fetchOrganizationsList();
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { organizations } = newProps;
    if (organizations?.organizationsList && !organizations?.organizationListLoader) {
      this.mapOrganizationListData();
    }
  };
  mapOrganizationListData = () => {
    const { organizationsList } = this.props.organizations;
    let rowDetails: Array<any> = [];
    !isEmpty(organizationsList) &&
      organizationsList.map((item: any) => {
        let row = createData({
          orgId: !!item?.id ? item.id : '',
          orgName: !!item?.name ? item.name : '',
          orgCode: !!item?.code ? item.code : '',
          createdDate: !!item?.created_at ? formatDate(item.created_at) : '',
          orgLogo: !!item?.logo_url
            ? item.logo_url
            : getNestedTernaryData(
                !!item?.branding_on_dale_details?.logo_url,
                item?.branding_on_dale_details?.logo_url,
                ''
              ),
          tempName: !!item?.branding_on_dale_details?.template_name
            ? item.branding_on_dale_details?.template_name
            : '',
          partnerId: !!item?.partner_id ? item.partner_id : '',
          orgEmail: !!item?.contact?.email ? item.contact.email : '',
          orgPhone: !!item?.contact?.phone ? item.contact.phone : '',
          authToken: !!item?.auth_token ? item.auth_token : '',
          status: !!item?.status ? item.status : '',
        });
        return rowDetails.push(row);
      });
    this.setState({
      data: rowDetails,
    });
  };

  onEditOrgRow = (rowData: any) => {
    const { organizationsList } = this.props.organizations;
    const organization = organizationsList.filter((org: any) => rowData.orgId === org.id);
    this.setState(
      {
        organization: organization[0],
        organizationRowData: rowData,
      },
      () => {
        history.push(`${ORGANIZATION_DETAILS_PATH}/${rowData.orgId}`);
      }
    );
  };

  closeEditOrganizationModal = () => {
    this.setState({
      isAddOrganization: false,
      organizationRowData: null,
    });
  };

  render() {
    const { organizations } = this.props;
    const { columns, data, isAddOrganization, organizationRowData } = this.state;

    return (
      <>
        <div className='container organization-container-wraper'>
          <Card className='oraganization-table'>
            <CardContent>
              <MaterialTable
                title={
                  <Button
                    variant='outlined'
                    className='new-org-btn'
                    onClick={() =>
                      this.setState({
                        isAddOrganization: true,
                      })
                    }
                  >
                    <AddIcon className='add-icon-border mr-3' />
                    Add New Organization
                  </Button>
                }
                icons={tableIcons}
                columns={columns}
                data={data}
                options={{
                  draggable: false,
                  search: true,
                  paging: true,
                  headerStyle: {
                    fontSize: '14px',
                    color: 'black',
                    position: 'inherit',
                  },
                  actionsColumnIndex: -1,
                  pageSizeOptions: [],
                }}
                isLoading={!!organizations?.organizationListLoader}
                actions={[
                  {
                    icon: () => <span className='icon-edit1 edit-svg-tbl text-color'></span>,
                    tooltip: EDIT,
                    onClick: (_e, rowData: any) => this.onEditOrgRow(rowData),
                  },
                ]}
              />
            </CardContent>
          </Card>
        </div>
        {!!isAddOrganization && (
          <AddEditOrganizationModal
            orgTitle={'Add New Organization'}
            onClose={this.closeEditOrganizationModal}
            organizationRowData={organizationRowData}
            loader={organizations.addEditOrgLoader}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.OrganizationTableStore) => {
  return { organizations };
};

const mapDispatchToProps = (dispatch: any): AppComponents.OrganizationTableDispatch => {
  return bindActionCreators({ fetchOrganizationsList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationTable);
