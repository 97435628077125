import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  NativeSelect,
  Select,
  TextField,
} from '@material-ui/core';
import { isEmpty, map, range } from 'lodash';
import DatePicker from '../date-picker';
import { EDUCATION_DATA } from '../../../constants/education';
import {
  DP1_SUBTITLE,
  DP3_SUBTITLE,
  H03_SUBTITLE,
  H05_SUBTITLE,
  H06_SUBTITLE,
  HO4_SUBTITLE,
} from '../../../constants/home-questionnaire';
import { INDUSTRY_DATA } from '../../../constants/industry';
import AutoComplete from '../auto-complete';
import PhoneMask from '../phone-mask';
import ProducerLicenseModal from '../producer-license';
import RadioGroupField from '../radio-group-field';
import { showFields } from '../../home/utils';
import {
  disabledApplicantFormField,
  parseError,
  propertyTypeMapping,
  shouldApplicantHasPreviousAddress,
} from '../../../utils';
import {
  ASTERISK,
  BOOLEAN_OPTIONS,
  CITY,
  CONDO,
  DATE_OF_BIRTH,
  DP1,
  DP3,
  EMAIL_ADDRESS,
  ENTER_PREVIOUS_ADDRESS,
  FIRST_NAME,
  HEAP_LABELS,
  HO3,
  HO4,
  HO5,
  HO6,
  HOME,
  HOUSE,
  LAST_NAME,
  MOVED_IN_PAST_MONTHS,
  NONE,
  OCCUPATION_LABEL,
  PHONE_NUMBER_LABEL,
  QUOTE_TYPE,
  STATE,
  STREET_ADDRESS,
  ZIP_CODE,
} from '../../../constants';

const showPhoneField = (component: any, formErrors: AppComponents.HomeContentFormErrors, disableFieldData: any) => {
  const { classes } = component.props;
  const {
    visibilityParams,
    personal_details: { phone_number },
  } = component.state;
  return showFields('phone_number', visibilityParams) ? (
    <div className={`fs-exclude phone_number div-display col-md-${component.adjustFieldWidth()}`}>
      <FormControl
        className={`w-100 ${classes.homeFormControl}`}
        error={formErrors.phone_number.length > 0}
      >
        <InputLabel shrink htmlFor='phone_number' disabled={!!disableFieldData?.isDisableFormField}>
          {PHONE_NUMBER_LABEL}<span className='star-error'>{ASTERISK}</span>
        </InputLabel>
        <Input
          autoComplete='off'
          value={phone_number === '' ? '' : phone_number}
          onChange={component.handleHomePersonalDetailsChange}
          name='phone_number'
          id='phone_number'
          inputComponent={PhoneMask}
          inputProps={{
            autoComplete: NONE?.toLowerCase(),
          }}
          disabled={!!disableFieldData?.isDisableFormField}
        />
      </FormControl>
      <div className={classes.errorText}>{formErrors.phone_number}</div>
    </div>
  ) : (
    ''
  );
};

const showEmailField = (component: any, formErrors: AppComponents.HomeContentFormErrors, disableFieldData: any) => {
  const { classes } = component.props;
  const {
    visibilityParams,
    personal_details: { email },
  } = component.state;

  return showFields('email', visibilityParams) ? (
    <div className={`email fs-exclude div-display col-md-${component.adjustFieldWidth()}`}>
      <FormControl className={`w-100 ${classes.homeFormControl}`}>
        <TextField
          error={formErrors.email.length > 0}
          type='text'
          label={
            <>
              {EMAIL_ADDRESS}<span className='star-error'>{ASTERISK}</span>
            </>
          }
          autoComplete='off'
          className={`${classes.homeTextField} text-margin-class`}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            name: 'email',
            id: 'email',
            spellCheck: false,
            maxLength: 50,
            autoComplete: NONE?.toLowerCase(),
          }}
          value={email}
          onChange={component.handleHomePersonalDetailsChange}
          disabled={!!disableFieldData?.isDisableFormField}
        />
      </FormControl>
      <div className={classes.errorText}>{formErrors.email}</div>
    </div>
  ) : (
    ''
  );
};

const addressErrorShow = (component: any, formErrors: string) => {
  const { classes } = component.props;

  return isEmpty(formErrors) ? (
    <div className={classes.errorText} />
  ) : (
    <div className={classes.errorText}>{formErrors}</div>
  );
};

const showLoanOfficerFields = (component: any, formErrors: AppComponents.HomeContentFormErrors) => {
  const { classes } = component.props;
  const {
    loanOfficer_details: {
      loan_officer_fname,
      loan_officer_lname,
      loan_officer_phone,
      loan_officer_email,
      loan_number,
    },
    enable_loan_officer_details,
  } = component.state;
  return enable_loan_officer_details ? (
    <div className='loan-officer-container row '>
      <div className='loan_title col-md-12'>
        <FormLabel>
          <b>LOAN DETAILS</b>
        </FormLabel>
      </div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='loan_officer_fname div-display col-md-6'>
            <TextField
              type='text'
              label='LOAN OFFICER FIRST NAME'
              autoComplete='off'
              className={classes.homeTextField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ spellCheck: false, maxLength: 50, autoComplete: NONE?.toLowerCase() }}
              name='loan_officer_fname'
              value={loan_officer_fname}
              onChange={component.handleOfficerDetailsChange}
              onBlur={component.handleOfficerDetailsBlur}
            />
          </div>
          <div className='loan_officer_lname div-display col-md-6'>
            <TextField
              type='text'
              label='LOAN OFFICER LAST NAME'
              autoComplete='off'
              className={classes.homeTextField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ spellCheck: false, maxLength: 50, autoComplete: NONE?.toLowerCase() }}
              name='loan_officer_lname'
              value={loan_officer_lname}
              onChange={component.handleOfficerDetailsChange}
              onBlur={component.handleOfficerDetailsBlur}
            />
          </div>
        </div>
        <div className='row'>
          <div className='loan_officer_phone div-display col-md-4'>
            <FormControl
              className={classes.homeFormControl}
              error={formErrors.loan_officer_phone.length > 0}
            >
              <InputLabel shrink htmlFor='formatted-text-mask-input'>
                LOAN OFFICER PHONE
              </InputLabel>
              <Input
                inputProps={{
                  autoComplete: NONE?.toLowerCase(),
                }}
                value={loan_officer_phone === '' ? '' : loan_officer_phone}
                onChange={component.phoneNumberDetails('loan_officer_phone')}
                name='loan_officer_phone'
                id='formatted-text-mask-input'
                inputComponent={PhoneMask}
              />
            </FormControl>
            <div className='error'>{formErrors.loan_officer_phone}</div>
          </div>
          <div className='loan_officer_email div-display col-md-4'>
            <FormControl className={classes.homeFormControl}>
              <TextField
                error={formErrors.loan_officer_email.length > 0}
                type='text'
                label='LOAN OFFICER EMAIL'
                autoComplete='off'
                className={`${classes.homeTextField} text-margin-class`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                name='loan_officer_email'
                value={loan_officer_email}
                onChange={component.handleOfficerDetailsChange}
              />
            </FormControl>
            <div className='error'>{formErrors.loan_officer_email}</div>
          </div>
          <div className='loan_number div-display col-md-4'>
            <FormControl className={classes.homeFormControl}>
              <TextField
                type='text'
                label='LOAN NUMBER'
                autoComplete='off'
                className={`${classes.homeTextField} text-margin-class`}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  spellCheck: false,
                  maxLength: 50,
                  autoComplete: NONE?.toLowerCase(),
                }}
                name='loan_number'
                value={loan_number}
                onChange={component.handleOfficerDetailsChange}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

const addressFieldError = (errorText: string, addressInvalid: boolean) =>
  errorText.length > 0 || addressInvalid;

const getHomePersonalDetailsFields = (
  component: any,
  classes: IClass,
  formErrors: AppComponents.HomeContentFormErrors,
  disableFieldData: any
) => {
  const { first_name, middle_name, last_name } = component.state.personal_details;
  return (
    <div className='personal-details-wrapper row mb-4'>
      <div className='col-md-4'>
        <div className='first_name fs-exclude'>
          <TextField
            type='text'
            label={
              <>
                {FIRST_NAME}<span className='star-error'>{ASTERISK}</span>
              </>
            }
            autoComplete='off'
            className={classes.homeTextField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              name: 'first_name',
              id: 'first_name',
              spellCheck: false,
              maxLength: 50,
              autoComplete: NONE?.toLowerCase(),
            }}
            error={formErrors.first_name.length > 0}
            value={first_name}
            onChange={component.handleHomePersonalDetailsChange}
            onBlur={component.handleHomePersonalDetailsBlur}
            disabled={!!disableFieldData?.isDisableFormField}
          />
          <div className={classes.errorText}>{formErrors.first_name}</div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='middle_name fs-exclude'>
          <TextField
            type='text'
            label='Middle Name'
            autoComplete='off'
            className={classes.homeTextField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              name: 'middle_name',
              id: 'middle_name',
              spellCheck: false,
              maxLength: 50,
              autoComplete: NONE?.toLowerCase(),
            }}
            value={middle_name}
            onChange={component.handleHomePersonalDetailsChange}
            onBlur={component.handleHomePersonalDetailsBlur}
            disabled={!!disableFieldData?.isDisableFormField}
          />
        </div>
      </div>
      <div className='col-md-4'>
        <div className='last_name fs-exclude'>
          <TextField
            type='text'
            label={
              <>
                {LAST_NAME}<span className='star-error'>{ASTERISK}</span>
              </>
            }
            autoComplete='off'
            className={classes.homeTextField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              name: 'last_name',
              id: 'last_name',
              spellCheck: false,
              maxLength: 50,
              autoComplete: NONE?.toLowerCase(),
            }}
            error={formErrors.last_name.length > 0}
            name='last_name'
            value={last_name}
            onChange={component.handleHomePersonalDetailsChange}
            onBlur={component.handleHomePersonalDetailsBlur}
            disabled={!!disableFieldData?.isDisableFormField}
          />
          <div className={classes.errorText}>{formErrors.last_name}</div>
        </div>
      </div>
    </div>
  );
};

const renderAddressFields = (
  component: any,
  classes: IClass,
  formErrors: AppComponents.HomeContentFormErrors,
  disableFieldData: any,
  isPreviousAddress: boolean = false
) => {
  const {
    disableForm,
    addressInvalid,
    previousAddressInvalid,
    address,
    previousAddress,
  } = component.state;

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    component.handleHomeAddressDetailsChange(event, isPreviousAddress);

  const onAddressBlur = (event: React.FocusEvent<HTMLInputElement>) =>
    component.handleAddressBlur(event, isPreviousAddress);

  const { formLoader } = component.props;
  const { street, city, zip, unit, state } = isPreviousAddress? previousAddress : address
  const errors = isPreviousAddress? formErrors.previousAddress : formErrors;
  const invalidAddress: boolean = isPreviousAddress ? !!previousAddressInvalid : !!addressInvalid;
  return (
    <div className={`address-container ${!isPreviousAddress ? 'mb-4' : ''}`}>
      <div className='row m-0 mb-4'>
        {isPreviousAddress && (
          <div className='col-12 text-left mb-3 previous-address-label'>
            <FormLabel>
              <b>{ENTER_PREVIOUS_ADDRESS}</b>
            </FormLabel>
          </div>
        )}
        <div className='col-md-8'>
          <div className='street fs-exclude'>
            <AutoComplete
              value={street}
              onChange={onAddressChange}
              onAutoCompleteAddressSelected={(addressDetails: AppComponents.ApplicantAddress) =>
                component.onAddressSelected(addressDetails, isPreviousAddress)
              }
              onInputBlur={onAddressBlur}
              clearAutoCompleteInput={() => component.clearAddressInputs(isPreviousAddress)}
              error={addressFieldError(parseError(errors?.street), invalidAddress)}
              title={
                <>
                  {STREET_ADDRESS}
                  <span className='star-error'>{ASTERISK}</span>
                </>
              }
              disabled={!!disableFieldData?.isDisableFormField}
            />

            {addressErrorShow(component, parseError(errors?.street))}
          </div>
        </div>
        <div className=' col-md-4'>
          <div className='unit fs-exclude'>
            <TextField
              type='text'
              label='Unit #'
              autoComplete='off'
              className={classes.homeTextField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                name: 'unit',
                id: 'unit',
                spellCheck: false,
                maxLength: 50,
                autoComplete: NONE?.toLowerCase(),
              }}
              error={parseError(errors?.unit).length > 0}
              value={unit}
              onChange={onAddressChange}
              onBlur={(event: any) => onAddressBlur(event)}
              disabled={!!disableFieldData?.isDisableFormField}
            />
            <div className={classes.errorText}>{errors.unit}</div>
          </div>
        </div>
      </div>
      <div className={`row m-0 ${!isPreviousAddress ? 'mb-4' : ''}`}>
        <div className='city col-md-4 fs-exclude'>
          <TextField
            type='text'
            label={
              <>
                {CITY}<span className='star-error'>{ASTERISK}</span>
              </>
            }
            autoComplete='off'
            className={classes.homeTextField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              id: 'city',
              name: 'city',
              spellCheck: false,
              maxLength: 50,
              autoComplete: NONE?.toLowerCase(),
            }}
            value={city}
            onChange={onAddressChange}
            onBlur={onAddressBlur}
            error={addressFieldError(parseError(errors?.city), invalidAddress)}
            disabled={!!disableFieldData?.isDisableFormField}
          />

          {addressErrorShow(component, parseError(errors?.city))}
        </div>
        <div className='state col-md-4 fs-exclude'>
          <FormControl
            className={`${classes.homeFormControl} classmargin`}
            error={addressFieldError(parseError(errors?.state), invalidAddress)}
          >
            <InputLabel
              shrink
              htmlFor='state-simple'
              disabled={disableForm || formLoader || !!disableFieldData?.isDisableFormField}
            >
              {STATE}
              <span className='star-error'>{ASTERISK}</span>
            </InputLabel>
            <NativeSelect
              className='state-class'
              value={state}
              defaultValue={state}
              onChange={(event: any) => onAddressChange(event)}
              disabled={disableForm || formLoader || !!disableFieldData?.isDisableFormField}
              inputProps={{
                name: 'state',
                id: 'state-simple',
              }}
            >
              <option value=''>Select</option>
              {component.state.stateName.map((stateName: any) => (
                <option key={stateName.label} value={stateName.value}>
                  {stateName.value}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {addressErrorShow(component, parseError(errors?.state))}
        </div>
        <div className='zip col-md-4 fs-exclude'>
          <TextField
            type='text'
            label={
              <>
                {ZIP_CODE}
                <span className='star-error'>{ASTERISK}</span>
              </>
            }
            autoComplete='off'
            className={classes.homeTextField}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              id: 'zip',
              name: 'zip',
              autoComplete: NONE?.toLowerCase(),
            }}
            value={zip}
            onChange={onAddressChange}
            error={addressFieldError(parseError(errors?.zip), invalidAddress)}
            disabled={!!disableFieldData?.isDisableFormField}
          />

          {addressErrorShow(component, parseError(errors?.zip))}
        </div>
      </div>
    </div>
  );
};

const getIndustryField = (component: any, disableFieldData: any) => {
  const { classes } = component.props;
  const {
    personal_details: { industry },
    formErrors,
  } = component.state;
  return (
    <FormControl
      fullWidth
      className={classes.homeFormControl}
      error={formErrors.industry.length > 0}
    >
      <InputLabel shrink htmlFor='industry' disabled={!!disableFieldData?.isDisableFormField}>
        {HEAP_LABELS?.INDUSTRY}<span className='star-error'>{ASTERISK}</span>
      </InputLabel>
      <Select
        native
        value={industry}
        inputProps={{
          name: 'industry',
          id: 'industry',
        }}
        onChange={component.handleHomePersonalDetailsChange}
        error={formErrors.industry !== ''}
        disabled={!!disableFieldData?.isDisableFormField}
      >
        <option value=''>Select</option>
        {map(INDUSTRY_DATA, data => (
          <option key={`industry_${data.value}`} value={data.value}>
            {data.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

const getOccupationField = (component: any, disableFieldData: any) => {
  const {
    formErrors,
    disableOccupation,
    occupationData,
    personal_details: { occupation },
  } = component.state;
  const { classes } = component.props;
  return (
    <FormControl
      fullWidth
      className={classes.homeFormControl}
      error={formErrors.occupation.length > 0}
      disabled={disableOccupation || !!disableFieldData?.isDisableFormField}
    >
      <InputLabel shrink htmlFor='occupation'>
        {OCCUPATION_LABEL}
        {!isEmpty(occupationData) && <span className='star-error'>{ASTERISK}</span>}
      </InputLabel>
      <Select
        native
        value={occupation}
        inputProps={{
          name: 'occupation',
          id: 'occupation',
        }}
        onChange={component.handleHomePersonalDetailsChange}
        error={formErrors.occupation !== ''}
      >
        <option value=''>Select</option>
        {map(occupationData, data => (
          <option key={`occupation_${data.value}`} value={data.value}>
            {data.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const getEducationField = (component: any, disableFieldData: any) => {
  const {
    personal_details: { education },
    formErrors,
  } = component.state;
  const { classes } = component.props;

  return (
    <FormControl
      fullWidth
      className={classes.homeFormControl}
      error={formErrors.education.length > 0}
      disabled={!!disableFieldData?.isDisableFormField}
    >
      <InputLabel shrink htmlFor='education'>
        {HEAP_LABELS.EDUCATION}<span className='star-error'>{ASTERISK}</span>
      </InputLabel>
      <Select
        native
        value={education}
        inputProps={{
          name: 'education',
          id: 'education',
        }}
        onChange={component.handleHomePersonalDetailsChange}
        error={formErrors.education !== ''}
      >
        <option value=''>Select</option>
        {map(EDUCATION_DATA, data => (
          <option key={`education_${data.value}`} value={data.value}>
            {data.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

const getDateOfBirthField = (component: any, disableFieldData: any): React.JSX.Element => {
  const {
    personal_details: { date_of_birth },
    disableForm,
    formErrors,
  } = component.state;
  const { classes, formLoader } = component.props;
  return (
    <FormControl className={`fs-exclude ${classes.homeFormControl}`}>
      <DatePicker
        styles={{
          marginBottom: '0px !important',
          marginTop: '0px !important',
        }}
        label={
          <span>
            {DATE_OF_BIRTH}<span className='star-error'>{ASTERISK}</span>
          </span>
        }
        name='date_of_birth'
        onChange={component.handleCalendarDateChange}
        dateValue={date_of_birth}
        error={formErrors.date_of_birth.length > 0}
        disabled={disableForm || formLoader || !!disableFieldData?.isDisableFormField}
      />
    </FormControl>
  );
};
const showHomeInfoFields = (component: any, formErrors: AppComponents.HomeContentFormErrors, disableFieldData: any, quoteType: any) => {
  const { isDP1Allowed, isDP3Allowed, isHO4Allowed, isHO5Allowed } = component.state;
  const { isDisableFormField, isDisablePropertyTypeAndDeductible } = disableFieldData;
  let selectedProductList: Array<any> = [];
  if (!!quoteType && quoteType.toLowerCase() === QUOTE_TYPE) {
    selectedProductList = [
      {mapping: HO3.toLowerCase(), id: HOME.toLowerCase(), title: HOUSE, subtitle: H03_SUBTITLE, isAllowed: true},
      {mapping: HO6.toLowerCase(), id: CONDO.toLowerCase(), title: CONDO, subtitle: H06_SUBTITLE, isAllowed: true},
      {mapping: DP3, id: DP3, title: DP3.toUpperCase(), subtitle: DP3_SUBTITLE, isAllowed: isDP3Allowed},
    ];
  } else {
    const productsList: {mapping: string, id: string, title: string, subtitle: string, isAllowed: boolean}[] = [
      {mapping: HO3.toLowerCase(), id: HOME.toLowerCase(), title: HOUSE, subtitle: H03_SUBTITLE, isAllowed: true},
      {mapping: HO6.toLowerCase(), id: CONDO.toLowerCase(), title: CONDO, subtitle: H06_SUBTITLE, isAllowed: true},
      {mapping: HO4.toLowerCase(), id: HO4.toLowerCase(), title: HO4, subtitle: HO4_SUBTITLE, isAllowed: isHO4Allowed},
      {mapping: HO5.toLowerCase(), id: HO5.toLowerCase(), title: HO5, subtitle: H05_SUBTITLE, isAllowed: isHO5Allowed},
      {mapping: DP1, id: DP1, title: DP1.toUpperCase(), subtitle: DP1_SUBTITLE, isAllowed: isDP1Allowed},
      {mapping: DP3, id: DP3, title: DP3.toUpperCase(), subtitle: DP3_SUBTITLE, isAllowed: isDP3Allowed},
    ];
    selectedProductList = productsList.filter(product => product.isAllowed)
  }
  const isGridChange: boolean = (selectedProductList.length % 2 === 0)
  return (
    <>
      <div className='m-8'>
        <div className='row m-0 insurance-type d-flex justify-content-center'>
          {selectedProductList.map((product: any) => (
            <div
              className={`${
                isGridChange ? 'col-md-6 mb-3' : 'col-md-4 mb-3'
              } pr-0 mob-margin-bottom-10`}
            >
              <div
                tabIndex={0}
                className={`${component.homeTypeSelectionClass(
                  propertyTypeMapping[product.mapping],
                  !!isDisableFormField && !!isDisablePropertyTypeAndDeductible
                )} insurance-${product.id} h-100 ${
                  !!isDisableFormField && !!isDisablePropertyTypeAndDeductible
                    ? 'cursor-default box-shodow-0'
                    : 'cursor-pointer box-shodow-1'
                }`}
                onClick={
                  !!isDisableFormField && !!isDisablePropertyTypeAndDeductible
                    ? ''
                    : component.propertyTypeClicked
                }
                onKeyDown={component.propertyTypeClicked}
                id={product.id}
              >
                <table className='table-responsive'>
                  <tbody>
                    <tr>
                      <td className='insu-icon-img img-fluid width-td'>
                        <div className={`property-${product.id}`}></div>
                      </td>
                      <td className='card-container'>
                        <p className='p-head'>{product.title}</p>
                        <div
                          className={`${
                            !!isDisableFormField && !!isDisablePropertyTypeAndDeductible
                              ? 'disable-icon'
                              : 'check-mark'
                          }`}
                        ></div>
                        <p className='p-para'>{product.subtitle}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <div className='col-lg-12'>
            <div
              className={`error ml-0 pl-0 homeTypeError ${
                !isGridChange && (selectedProductList.length >= 5 || selectedProductList.length === 1) ? 'text-center' : 'text-left'
              }`}
            >
              {formErrors.condo}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const template = (component: any) => {
  let disableFieldData = disabledApplicantFormField();
  const {
    formErrors,
    disableForm,
    showHomeInfo,
    showModal,
    address: { state },
    hideApplicantFieldsForHW,
    isLifeOrCyberOrUmbrellaLob,
  } = component.state;
  const { classes, isValidatingAddress, formLoader, isVeriskLoading, pconfig, vinLoader, quoteType, saveApplicantLoader } =
    component.props;
  const showLoader = () => {
    if (pconfig?.dale_config?.is_verisk_prefill_available) {
      return isValidatingAddress || isVeriskLoading || vinLoader;
    } else {
      return isValidatingAddress;
    }
  };
  return (
    <>
      {!component.state.producerHasLicence && (
        <ProducerLicenseModal
          state={component.getStateName(state)}
          open={showModal}
          closehandler={() => component.setState({ ...component.state, showModal: false })}
        />
      )}
      <div className='applicant-home-form'>
        <div className='container'>
          <div className='row form-wrapper d-flex justify-content-center'>
            <form
              onSubmit={event => {
                component.handleSubmit(event, false);
              }}
              className='col-lg-11'
            >
              <fieldset disabled={disableForm || formLoader}>
                {getHomePersonalDetailsFields(component, classes, formErrors, disableFieldData)}

                {renderAddressFields(component, classes, formErrors, disableFieldData)}

                <div className='personal-details-wrapper row mb-4'>
                  {showPhoneField(component, formErrors, disableFieldData)}
                  {showEmailField(component, formErrors, disableFieldData)}
                </div>
                {!hideApplicantFieldsForHW && !isLifeOrCyberOrUmbrellaLob && (
                  <>
                    <div className='personal-details-wrapper row mb-4'>
                      <div className='date_of_birth col-md-6'>
                        {getDateOfBirthField(component, disableFieldData)}
                        <div className={classes.errorText}>{formErrors.date_of_birth}</div>
                      </div>
                      <div className='first_name div-display col-md-6'>
                        {getIndustryField(component, disableFieldData)}
                        <div className='error'>{formErrors.industry}</div>
                      </div>
                    </div>
                    <div className='personal-details-wrapper row mb-4'>
                      <div className='first_name div-display col-md-6'>
                        {getOccupationField(component, disableFieldData)}
                        <div className='error'>{formErrors.occupation}</div>
                      </div>
                      <div className='first_name div-display col-md-6'>
                        {getEducationField(component, disableFieldData)}
                        <div className='error'>{formErrors.education}</div>
                      </div>
                    </div>
                    {shouldApplicantHasPreviousAddress() && (
                      <>
                        <div className='personal-details-wrapper row'>
                          <div className='col-12 moved-in-past-months-wrapper'>
                            <div className='d-flex justify-content-between align-items-center moved-in-past-months'>
                              <span className='text-black moved-in-past-text'>
                                {MOVED_IN_PAST_MONTHS.LABEL}
                              </span>
                              <div className='bg-white'>
                                <RadioGroupField
                                  onChange={e => component.onChangesRadioButton(e)}
                                  options={BOOLEAN_OPTIONS}
                                  name={MOVED_IN_PAST_MONTHS.KEY}
                                  value={component.state.isMovedInPastMonths.toString()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {component.state.isMovedInPastMonths && (
                          <div className='row mt-4'>
                            <div className='col-12'>
                              {renderAddressFields(
                                component,
                                classes,
                                formErrors,
                                disableFieldData,
                                true
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {showHomeInfo
                      ? showHomeInfoFields(component, formErrors, disableFieldData, quoteType)
                      : ''}
                    {showLoanOfficerFields(component, formErrors)}
                  </>
                )}
                {isLifeOrCyberOrUmbrellaLob && (
                  <div
                    className={showHomeInfo ? 'date_of_birth col-md-4' : 'date_of_birth col-md-6'}
                  >
                    {getDateOfBirthField(component, disableFieldData)}
                    <div className={classes.errorText}>{formErrors.date_of_birth}</div>
                  </div>
                )}
                <div className='form-submit-action'>
                  <button
                    className='btn btn-success mh-button mr-10'
                    type='submit'
                    id='continueButton'
                    disabled={isValidatingAddress || !!saveApplicantLoader}
                  >
                    {showLoader() || !!saveApplicantLoader ? (
                      <div className='loader-wrapper'>
                        <div className='sk-circle'>
                          {range(12, 0).map(n => (
                            <div key={n} className={`sk-circle${n} sk-child`} />
                          ))}
                        </div>
                      </div>
                    ) : (
                      <span className='submit-label'>Continue</span>
                    )}
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default template;
