import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import './spinner-loader.scss';

const useStyles = makeStyles(theme => ({
  root: {
    width: '20px !important',
    height: '20px !important',
    verticalAlign: 'middle',
  },
  loaderWrapper: {
    position: 'relative',
    float: 'right',
    display: 'inline-block',
    marginLeft: '5px',
  },
}));

const SpinnerLoader = (props: any) => {
  const classes = useStyles();
  return (
    <div className={`spinner-loader-wrapper ${classes.loaderWrapper}`}>
      <CircularProgress classes={{ root: `${classes.root} ${props.styleData}` }} />
    </div>
  );
};

export default SpinnerLoader;
