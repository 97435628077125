import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import PropertyError from '../components/property-error';
import NotFoundError from '../components/not-found-error';
import QuoteExpiredError from '../components/quote-expired-error';
import { goToDashboard } from '../../utils';

class NotFound extends PureComponent<AppComponents.NotFoundStoreProps, {}> {
  UNSAFE_componentWillMount() {
    const { showError, authUser } = this.props;
    if (!showError && !isEmpty(authUser)) {
      goToDashboard();
    }
  }

  render() {
    const { showError, authUser, pconfig, defaultPConfig, isAutoRedirect, notFoundError, expiredError } = this.props;
    return (
      <React.Fragment>
        {(showError || isEmpty(authUser)) && !notFoundError && !expiredError  ? (
          <PropertyError
            {...({ pstate: { pconfig, defaultPConfig, authUser }, isAutoRedirect } as any)}
          />
        ) : (
          <>
            {expiredError ? <QuoteExpiredError /> : <NotFoundError />}
          </>)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: AppComponents.NotFoundStore): AppComponents.NotFoundStoreProps => {
  const { showError, personal_details, pconfig, code, defaultPConfig, notFoundError, expiredError } = store.home;
  const { isAutoRedirect } = store.common;
  return {
    pconfig,
    code,
    defaultPConfig,
    authUser: store.auth.authUser,
    showError,
    personal_details,
    isAutoRedirect,
    notFoundError,
    expiredError,
  };
};

export default connect(mapStateToProps, {})(NotFound);
