/* istanbul ignore file */
import * as applicantActionType from '../constants';

// Applicant form submit
export const applicantFormSubmit = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.APPLICANT_FORM_SUBMIT,
  payload,
});

// Applicant form data submitted to server successfully
export const applicantFormSubmitSuccess = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.APPLICANT_FORM_SUBMIT_SUCCESS,
  payload,
});

// Applicant address validation failed
export const applicantFormSubmitFailed = (): AppRedux.Action => ({
  type: applicantActionType.APPLICANT_FORM_SUBMIT_FAIL,
});

// Loader for applicant form submit
export const applicantFormLoader = (payload: boolean): AppRedux.Action => ({
  type: applicantActionType.APPLICANT_FORM_LOADER,
  payload,
});

// Validate input address
export const validateAddress = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.VALIDATE_ADDRESS,
  payload,
});

// Applicant details receive from home insurance
export const applicantDetailsFromHomeInsurance = (
  payload: Partial<ApplicantState>
): AppRedux.Action => ({
  type: applicantActionType.APPLICANT_DETAILS_FROM_HOME_INSURANCE,
  payload,
});

// Validate address from home product
export const validateHomeAddress = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.VALIDATE_HOME_ADDRESS,
  payload,
});

// Applicant details receive from home insurance
export const updateApplicantDetails = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.UPDATE_APPLICANT_DETAILS,
  payload,
});

// Applicant address when view quote initialize
export const updateApplicantAddress = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.UPDATE_APPLICANT_ADDRESS,
  payload,
});

// Reset addressInvalid flag on refresh
export const updateAddressInvalidFlag = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.UPDATE_ADDRESS_INVALID_FLAG,
  payload,
});

export const clearApplicantState = (): AppRedux.Action => ({
  type: applicantActionType.CLEAR_APPLICANT_STATE,
});

export const saveApplicantDetails = (payload: string): AppRedux.Action => ({
  type: applicantActionType.SAVE_APPLICANT_DETAILS,
  payload,
});

export const setApplicantSuccess = (payload: boolean): AppRedux.Action => ({
  type: applicantActionType.SET_APPLICANT_SUCCESS,
  payload,
});

export const setSaveApplicantLoader = (payload: boolean): AppRedux.Action => ({
  type: applicantActionType.SAVE_APPLICANT_LOADER,
  payload,
});

export const setApplicantDetails = (payload: Partial<ApplicantState>): AppRedux.Action => ({
  type: applicantActionType.SET_APPLICANT_DETAILS,
  payload,
});

export const setPrefillDetails = (payload: any): AppRedux.Action => ({
  type: applicantActionType.SET_PREFILL_DETAILS,
  payload,
});

export const setUserPropertyInformation = (payload: any): AppRedux.Action => ({
  type: applicantActionType.SET_USER_PROPERTY_INFORMATION,
  payload,
});

export const setAddProductSaveApplicantLoader = (payload: boolean): AppRedux.Action => ({
  type: applicantActionType.SET_ADD_PRODUCT_SAVE_APPLICANT_LOADER,
  payload,
});

export const getPropertyInfoDetaills = (payload: AppRedux.ActionPayload): AppRedux.Action => ({
  type: applicantActionType.GET_PROPERTY_INFO_DETAILS,
  payload,
});

export const setPropertyInfoLoader = (payload: boolean): AppRedux.Action => ({
  type: applicantActionType.SET_PROPERTY_INFO_LOADER,
  payload,
});
