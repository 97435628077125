/* istanbul ignore file */
import * as types from '../constants';
import { BULK_QUOTE_BATCH_STATUS } from '../../../constants';

interface BulkQuoteState {
  selectorLoader: boolean;
  modalOpen: boolean;
  activeStep: number;
  inputOrganization: any;
  inputCode: string;
  selectedOrganization: any;
  serverError: string;
  successMessage: string;
  selectedFile: any;
  stepsCompleted: boolean;
  carrierList: Array<any>;
  selectedCarrierList: Array<any>;
  bulkQuoteTableLoader: boolean;
  terminateRecordLoader: boolean;
  terminateMessageShow: boolean;
  quoteList: Array<any>;
  terminateSuccess: boolean;
  terminateMessage: string;
  actionLoader: boolean;
}

const BULK_QUOTE_INIT_STATE: BulkQuoteState = {
  selectorLoader: false,
  modalOpen: false,
  activeStep: 0,
  inputOrganization: null,
  inputCode: '',
  selectedOrganization: null,
  serverError: '',
  successMessage: '',
  selectedFile: null,
  stepsCompleted: false,
  carrierList: [],
  selectedCarrierList: [],
  bulkQuoteTableLoader: false,
  terminateRecordLoader: false,
  terminateMessageShow: false,
  quoteList: [],
  terminateSuccess: false,
  terminateMessage: '',
  actionLoader: false,
};

// tslint:disable-next-line: cyclomatic-complexity
export default (state = BULK_QUOTE_INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return {
        ...state,
        selectorLoader: false,
        serverError: '',
        successMessage: '',
        terminateRecordLoader: false,
        terminateMessageShow: false,
        terminateSuccess: false,
        terminateMessage: '',
      };
    }

    case types.SET_ORGANIZATION_DETAILS:
    case types.RESTORE_BULK_QUOTE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.RESET_STEP_DETAILS: {
      return {
        ...BULK_QUOTE_INIT_STATE,
        quoteList: [...state.quoteList],
      };
    }

    case types.OPEN_MODAL: {
      return {
        ...state,
        modalOpen: action.payload,
      };
    }

    case types.UPDATE_STEP: {
      return {
        ...state,
        activeStep: action.payload,
      };
    }

    case types.SET_SELECTED_CARRIER_LIST: {
      return {
        ...state,
        selectedCarrierList: [...action.payload],
      };
    }

    case types.SELECTOR_LOADER: {
      return {
        ...state,
        selectorLoader: action.payload,
      };
    }

    case types.SET_SERVER_ERROR: {
      return {
        ...state,
        serverError: action.payload,
        successMessage: '',
      };
    }

    case types.UPLOAD_SELECTED_FILE: {
      return {
        ...state,
        selectedFile: action.payload,
      };
    }

    case types.STEPS_COMPLETED: {
      return {
        ...state,
        stepsCompleted: action.payload,
      };
    }

    case types.SET_BULK_QUOTE_TABLE_LOADER: {
      return {
        ...state,
        bulkQuoteTableLoader: action.payload,
      };
    }

    case types.BULK_QUOTE_INIT: {
      return {
        ...state,
        quoteList: [],
      };
    }

    case types.SET_BULK_QUOTE_LIST: {
      const { quotes } = action.payload;
      return {
        ...state,
        quoteList: [...quotes],
      };
    }

    case types.SET_PAGE_LOADER: {
      return {
        ...state,
        terminateRecordLoader: action.payload,
      };
    }

    case types.SET_TERMINATED_RECORD: {
      const { batchNo } = action.payload;

      const updatedQuoteList = state.quoteList.map(list => {
        if (list.id === batchNo) {
          return { ...list, status: BULK_QUOTE_BATCH_STATUS.terminated };
        } else {
          return { ...list };
        }
      });
      return {
        ...state,
        quoteList: [...updatedQuoteList],
      };
    }

    case types.SHOW_TERMINATED_MESSAGE: {
      return {
        ...state,
        terminateMessageShow: action.payload,
        terminateSuccess: false,
        terminateMessage: '',
      };
    }

    case types.SET_TERMINATE_SUCCESS: {
      return {
        ...state,
        terminateSuccess: action.payload,
      };
    }

    case types.SET_TERMINATE_MESSAGE: {
      return {
        ...state,
        terminateMessage: action.payload,
      };
    }

    case types.SET_ACTION_LOADER: {
      return {
        ...state,
        actionLoader: action.payload,
      };
    }

    case types.SET_SERVER_MESSAGE: {
      return {
        ...state,
        serverError: '',
        successMessage: action.payload,
      };
    }

    default:
      return state;
  }
};
