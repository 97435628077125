export const INDUSTRY_DATA = [
  { label: 'Homemaker/House person', value: 'Homemaker/Houseprsn', id: 1 },
  { label: 'Retired', value: 'Retired', id: 2 },
  { label: 'Disabled', value: 'Disabled', id: 3 },
  { label: 'Unemployed', value: 'Unemployed', id: 4 },
  { label: 'Student', value: 'Student', id: 5 },
  { label: 'Agriculture/Forestry/Fishing', value: 'Agriclt/Forestry/Fish', id: 6 },
  { label: 'Art/Design/Media', value: 'Art/Design/Media', id: 7 },
  { label: 'Banking/Finance/Real Estate', value: 'Banking/Finance/RE', id: 8 },
  { label: 'Business/Sales/Office', value: 'Business/Sales/Offi', id: 9 },
  { label: 'Construction/Energy Trades', value: 'Construct/EnrgyTrds', id: 10 },
  { label: 'Education/Library', value: 'Education/Library', id: 11 },
  { label: 'Engineer/Architect/Science/Math', value: 'Engr/Archt/Sci/Math', id: 12 },
  { label: 'Government/Military', value: 'Government/Military', id: 13 },
  { label: 'Information Technology', value: 'Info Tech', id: 14 },
  { label: 'Insurance', value: 'Insurance', id: 15 },
  { label: 'Legal/Law Enforcement/Security', value: 'Lgl/Law Enfcmt/Sec', id: 16 },
  { label: 'Maintenance/Repair/Housekeeping', value: 'Maint/Rpr/Hsekeep', id: 17 },
  { label: 'Manufacturing/Production', value: 'Mfg/Production', id: 18 },
  { label: 'Medical/Social Services/Religion', value: 'Med/Soc Svcs/Relig', id: 19 },
  { label: 'Personal Care/Service', value: 'Person.Care/Service', id: 20 },
  { label: 'Restaurant/Hotel Services', value: 'Rest/Hotel Services', id: 21 },
  { label: 'Sports/Recreation', value: 'Sports/Recreation', id: 22 },
  { label: 'Travel/Transportation/Warehousing', value: 'Trvl/Trnsprt/Warehs', id: 23 },
  { label: 'Other', value: 'Other', id: 24 },
];
