import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import BlockUi from 'react-block-ui';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { Redirect, Route, Router } from 'react-router-dom';
import 'react-block-ui/style.css';
import 'react-notifications/lib/notifications.css';
import {
  FragmentSwitch,
  inIframe,
  getRequestedUrlFromCookie,
  removeRequestedUrlFromCookie,
  setHeapProjectId,
} from '../utils';
import config from '../config/config';
import history from '../history';
import initializeGoogleAnalytics from '../analytics';
import {
  appInit,
  authLoaderShow,
  autoLogout,
  cancelHomeQuotePolling,
  fetchPconfig,
  switchApp,
  userLoginPage,
} from './redux/actions';
import AutoLine from './auto';
import BulkQuote from './bulk-quote';
import Cache from './cache';
import { cancelAllRequests } from './api';
import Footer from './components/footer';
import Header from './components/header';
import FloodDetails from './home/flood-details';
import HomeApplicant from './home/applicant';
import LineOfBusiness from './lob';
import Loader from './components/loader';
import Login from './login';
import Logout from './logout';
import NotFound from './not-found';
import Quotes from './home/quotes';
import ResultComponent from './home/result';
import Report from './report';
import PartnerLaunch from './partner-launch';
import QuoteQuestionnaire from './quote-questionnaire';
import SFDCAMSDetails from './components/sfdc-ams-details';
import ValidateQuoteURL from './components/validate-quote-url';
import Admin from './admin';
import OrganizationDetails from './admin/organization-details';
import { NotificationContainer } from 'react-notifications';
import QuoteApplication from '../quote-application';
import {
  ADMIN_ROUTE,
  AUTO_ROUTE,
  DEFAULT_BULK_QUOTE_PATH,
  DEFAULT_LOB_PATH,
  DEFAULT_REPORT_PATH,
  HOME_ROUTE,
  LOG_OUT_ROUTE,
  ORGANIZATION_DETAILS_PATH,
  PUBLIC_URL_WITHOUT_ONE_LOGIN,
  QUOTE_APPLICATION_ROUTE,
  SFDC_AMS_DETAILS,
  VALIDATE_QUOTE_ROUTE,
} from '../constants';
import { isEnabledPRSv2Flow } from '../quote-application/utils';


const RestrictedRoute = ({
  component: RestrictedComponent,
  authUser,
  extraProps,
  ...rest
}: AppComponents.RestrictedPrams) => {
  return (
    <Route
      {...rest}
      render={props => {
        return authUser && <RestrictedComponent {...props} {...extraProps} />;
      }}
    />
  );
};

class App extends Component<AppComponents.AppProps, AppComponents.AppState> {
  state: AppComponents.AppState = {
    appLoader: true,
    autoLine: false,
    homeResult: false,
    floodDetails: false,
    isAdminUser: false,
    code: '',
    pconfig: null,
    defaultPConfig: {
      name: config.hippo.name,
      code: config.hippo.code,
      dale_config: {
        customized_error_page_details: {
          error_logo_url: config.hippo.error_logo_url,
          error_text: config.hippo.error_text,
        },
        meta_details: {
          title: config.hippo.title,
          favicon: config.hippo.favicon,
          description: config.hippo.description,
        },
        auth_token: '',
        contact: { email: config.hippo.email, phone: config.hippo.phone },
        total_number_of_quotes: config.hippo.total_number_of_quotes,
        hippo_branding_on_dale: config.hippo.hippo_branding_on_dale,
        direct_appointment_access_details: {
          partner_license: config.hippo.partner_license,
          license_text: config.hippo.license_text,
        },
        branding_on_dale_details: {
          logo_url: config.hippo.logo_url,
          template_name: config.hippo.template_name,
        },
      },
    },
    headerData: null,
  };
  unlisten: any;

  constructor(props: AppComponents.AppProps) {
    super(props);

    this.props.appInit();
    this.unlisten = history.listen((location: any, action: any) => {
      this.setState({
        autoLine: location.pathname.includes('auto'),
        homeResult: location.pathname === '/result' || location.pathname === '/quotes',
        floodDetails: location.pathname === '/flood-details',
        isAdminUser: location.pathname === '/admin',
      });
      if (action === 'POP' && location.pathname === '/home') {
        cancelAllRequests();
        this.props.cancelHomeQuotePolling();
        this.setState({ headerData: null });
      }
      if (
        action === 'PUSH' &&
        location.pathname === '/auto' &&
        !isEmpty(get(location, 'state.pconfig', null))
      ) {
        this.setState({
          ...(!isEmpty(location.state.pconfig)
            ? ({ pconfig: { ...location.state.pconfig } } as any)
            : {}),
        });
      }
      if (action === 'PUSH' && location.pathname === '/home') {
        const details: any = queryString.parse(location.search);
        if (this.state.code !== details.code) {
          this.setState({
            code: this.state.defaultPConfig.code,
            ...(location.state !== undefined && !isEmpty(location.state.pconfig)
              ? ({ pconfig: { ...location.state.pconfig } } as any)
              : {}),
          });
        }
      }
    });
    initializeGoogleAnalytics();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const { auth } = this.props;
    const { adminUser, authUser, bulkUser, reportUser } = auth;
    setHeapProjectId({}, true);
    const isLogoutPath = [
      '/logout',
      `${DEFAULT_BULK_QUOTE_PATH}/logout`,
      `${DEFAULT_REPORT_PATH}/logout`,
      `${ADMIN_ROUTE}/logout`,
    ].includes(history.location.pathname);
    (window as any).jQuery(document.body).addClass(`theme-${config.hippo.template_name}`);
    const isAppSwitched = this.checkForAppSwitch();
    if (isEmpty(authUser) && !isLogoutPath) {
      const { token } = queryString.parse(history.location.search);
      this.props.authLoaderShow(false);
      this.props.autoLogout(false);
      if (!PUBLIC_URL_WITHOUT_ONE_LOGIN.includes(history.location.pathname)) {
        if (
          ![
            '/login',
            `${DEFAULT_BULK_QUOTE_PATH}/login`,
            `${DEFAULT_REPORT_PATH}/login`,
            `${ADMIN_ROUTE}/login`,
          ].includes(history.location.pathname) ||
          isEmpty(token)
        ) {
          this.props.userLoginPage();
          return false;
        }
      }
    } else if (
      !isEmpty(authUser) &&
      ['/', AUTO_ROUTE, HOME_ROUTE, DEFAULT_LOB_PATH, ADMIN_ROUTE].includes(
        history.location.pathname
      )
    ) {
      let requestedUrl = getRequestedUrlFromCookie();
      if (!isEmpty(requestedUrl)) {
        history.push(requestedUrl);
        removeRequestedUrlFromCookie();
      } else if (bulkUser) {
        history.push(DEFAULT_BULK_QUOTE_PATH);
      } else if (reportUser) {
        history.push(DEFAULT_REPORT_PATH);
      } else if (adminUser) {
        history.push(ADMIN_ROUTE);
      } else {
        history.push(history.location.pathname);
      }
      this.props.autoLogout(false);
    } else if (isLogoutPath) {
      this.props.authLoaderShow(false);
      if (bulkUser) {
        history.push(`${DEFAULT_BULK_QUOTE_PATH}/logout`);
      } else if (reportUser) {
        history.push(`${DEFAULT_REPORT_PATH}/logout`);
      } else if (adminUser) {
        history.push(`${ADMIN_ROUTE}/logout`);
      } else {
        history.push(LOG_OUT_ROUTE);
      }
    }

    setTimeout(() => {
      this.setState({ appLoader: isAppSwitched });
    }, 150);
    this.setState({
      autoLine: history.location.pathname.includes('auto'),
      homeResult:
        history.location.pathname === '/result' || history.location.pathname === '/quotes',
      floodDetails: history.location.pathname === '/flood-details',
      isAdminUser:
        history.location.pathname.includes('admin') || history.location.pathname === ADMIN_ROUTE,
    });
    return true;
  }

  checkForAppSwitch = () => {
    const { auth } = this.props;
    const { adminUser, authUser, bulkUser, reportUser } = auth;
    if (!isEmpty(authUser)) {
      const { location } = window;
      if (
        (!bulkUser && location.pathname === DEFAULT_BULK_QUOTE_PATH) ||
        (bulkUser &&
          [ADMIN_ROUTE, DEFAULT_REPORT_PATH, DEFAULT_LOB_PATH].includes(location.pathname)) ||
        (!reportUser && location.pathname === DEFAULT_REPORT_PATH) ||
        (reportUser &&
          [ADMIN_ROUTE, DEFAULT_BULK_QUOTE_PATH, DEFAULT_LOB_PATH].includes(location.pathname)) ||
        (!adminUser && location.pathname === ADMIN_ROUTE) ||
        (adminUser &&
          [DEFAULT_BULK_QUOTE_PATH, DEFAULT_LOB_PATH, DEFAULT_REPORT_PATH].includes(
            location.pathname
          ))
      ) {
        this.props.switchApp();
        return true;
      }
    }
    return false;
  };

  handleSubmit = (data: any) => {
    this.setState({ headerData: null, ...data }, () => {
      const { code } = this.state.defaultPConfig;
      const params = new URLSearchParams(history.location.search);
      if (!params.has('code')) {
        params.set('code', code);
        history.push(`?${params.toString()}`);
      }
    });
  };

  updateConfigDetails = (pconfig: AppComponents.PartnerConfig) => {
    this.setState({ pconfig: !isEmpty(pconfig) ? pconfig : this.state.pconfig }, () => {
      this.updateTheme(this.state.pconfig.dale_config.hippo_branding_on_dale);
      (window as any).jQuery('.header-wrapper, .footer-wrapper').css('opacity', 1);
    });
  };

  updateTheme = (hippoBrandingOnDale: boolean) => {
    let themeType;
    if (hippoBrandingOnDale) {
      themeType = this.state.defaultPConfig.dale_config.branding_on_dale_details.template_name;
    } else {
      themeType = !isEmpty(this.state.pconfig.dale_config.branding_on_dale_details.template_name)
        ? this.state.pconfig.dale_config.branding_on_dale_details.template_name
        : this.state.defaultPConfig.dale_config.branding_on_dale_details.template_name;
    }
    document.body.className = '';
    (window as any).jQuery(document.body).addClass(`theme-${themeType}`);
  };

  // Get page meta details for favicon, title and description
  getPageMetaDetails = (): any => {
    const { defaultPConfig, pconfig } = this.props.home;
    if (pconfig) {
      return !isEmpty(pconfig?.dale_config?.meta_details)
        ? pconfig.dale_config.meta_details
        : defaultPConfig.dale_config.meta_details;
    }
    return defaultPConfig.dale_config.meta_details;
  };

  clearHeaderDetails = () => {
    this.setState({ headerData: null });
  };

  getDefaultPath = () => {
    const {
      auth: { adminUser, bulkUser, reportUser },
    } = this.props;
    let requestedUrl = getRequestedUrlFromCookie();
    if (!isEmpty(requestedUrl)) {
      history.push(requestedUrl);
      removeRequestedUrlFromCookie();
    } else if (bulkUser) {
      return DEFAULT_BULK_QUOTE_PATH;
    } else if (reportUser) {
      return DEFAULT_REPORT_PATH;
    } else if (adminUser) {
      return ADMIN_ROUTE;
    } else {
      return DEFAULT_LOB_PATH;
    }
  };

  getAppRoutes = () => {
    const { autoLine, floodDetails, homeResult } = this.state;
    const {
      auth: { authUser, bulkUser, reportUser },
      home: { viewQuotesLoader },
    } = this.props;
    return (
      <Router history={history}>
        <Route path='/' render={() => <App {...this.props} />}>
          <div
            className={`App ${
              history.location.pathname.includes('admin') ? 'background-grey' : 'background-white'
            }`}
          >
            {!viewQuotesLoader && <Header />}
            <div className='main-wrap-height position-relative'>
              <FragmentSwitch>
                <RestrictedRoute
                  path={DEFAULT_LOB_PATH}
                  authUser={authUser && !bulkUser && !reportUser}
                  component={LineOfBusiness}
                  extraProps={{}}
                />

                {!bulkUser && !reportUser && (
                  <React.Fragment>
                    <RestrictedRoute
                      exact
                      path='/home'
                      authUser={authUser}
                      component={HomeApplicant}
                      extraProps={{}}
                    />
                    <RestrictedRoute
                      exact
                      path='/result'
                      authUser={authUser}
                      component={ResultComponent}
                      extraProps={{}}
                    />
                    <RestrictedRoute
                      exact
                      path='/flood-details'
                      authUser={authUser}
                      component={FloodDetails}
                      extraProps={{}}
                    />
                    <RestrictedRoute
                      path='/auto'
                      authUser={authUser}
                      component={AutoLine}
                      extraProps={{ pstate: this.state }}
                    />
                    <Route exact path='/error' render={() => <NotFound />} />
                  </React.Fragment>
                )}
                <Route exact path='/quotes' component={Quotes} />
                <Route
                  exact
                  path={`${DEFAULT_BULK_QUOTE_PATH}/login`}
                  render={() => {
                    return <Login />;
                  }}
                />
                <Route exact path={`${DEFAULT_BULK_QUOTE_PATH}/logout`} render={() => <Logout />} />
                <RestrictedRoute
                  path={DEFAULT_BULK_QUOTE_PATH}
                  authUser={authUser && bulkUser}
                  component={BulkQuote}
                  extraProps={{}}
                />
                <RestrictedRoute
                  exact
                  path='/questionnaire'
                  extraProps={{ pstate: this.state }}
                  component={QuoteQuestionnaire}
                  authUser={authUser}
                />

                <RestrictedRoute
                  exact
                  path={QUOTE_APPLICATION_ROUTE}
                  extraProps={{ pstate: this.state }}
                  component={QuoteApplication}
                  authUser={authUser}
                />

                <RestrictedRoute
                  exact
                  path='/quote'
                  extraProps={{ pstate: this.state }}
                  component={
                    isEnabledPRSv2Flow() ? QuoteApplication : QuoteQuestionnaire
                  }
                  authUser={authUser}
                />
                <RestrictedRoute
                  path={VALIDATE_QUOTE_ROUTE}
                  extraProps={{ pstate: this.state }}
                  component={ValidateQuoteURL}
                  authUser={authUser}
                />
                <RestrictedRoute
                  path={SFDC_AMS_DETAILS}
                  extraProps={{ pstate: this.state }}
                  component={SFDCAMSDetails}
                  authUser={authUser}
                />
                <Route
                  exact
                  path={`${DEFAULT_REPORT_PATH}/login`}
                  render={() => {
                    return <Login />;
                  }}
                />
                <Route exact path={`${DEFAULT_REPORT_PATH}/logout`} render={() => <Logout />} />
                <RestrictedRoute
                  path={DEFAULT_REPORT_PATH}
                  authUser={authUser}
                  component={Report}
                  extraProps={{}}
                />
                <Route
                  exact
                  path={`${ADMIN_ROUTE}/login`}
                  render={() => {
                    return <Login />;
                  }}
                />
                {!!authUser && (
                  <React.Fragment>
                    <RestrictedRoute
                      exact
                      path={ADMIN_ROUTE}
                      authUser={!!authUser}
                      component={Admin}
                      extraProps={{}}
                    />
                    <RestrictedRoute
                      path={ORGANIZATION_DETAILS_PATH}
                      authUser={!!authUser}
                      component={OrganizationDetails}
                      extraProps={{}}
                    />
                  </React.Fragment>
                )}
                <Route exact path={`${ADMIN_ROUTE}/logout`} render={() => <Logout />} />
                {!bulkUser && reportUser && (
                  <React.Fragment>
                    <RestrictedRoute
                      exact
                      path='/cache'
                      authUser={authUser}
                      component={Cache}
                      extraProps={{}}
                    />
                  </React.Fragment>
                )}
                <Route exact path='/login' render={() => <Login />} />
                <Route exact path='/logout' render={() => <Logout />} />
                <Route exact path='/launch-partner' component={PartnerLaunch} />
                <Route
                  render={({ location }) => (
                    <Redirect
                      to={{
                        ...location,
                        pathname: this.getDefaultPath(),
                      }}
                    />
                  )}
                />
              </FragmentSwitch>
            </div>

            {!autoLine && !homeResult && !floodDetails && !inIframe() && <Footer />}
          </div>
        </Route>
      </Router>
    );
  };

  render() {
    const { appLoader } = this.state;
    const { auth, home } = this.props;
    const { loader } = auth;
    const { configLoader, viewQuotesLoader } = home;
    let themeType = !!home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
      ? home?.pconfig?.dale_config?.branding_on_dale_details?.template_name
      : this.state.defaultPConfig.dale_config.branding_on_dale_details.template_name;
    document.body.className = '';
    (window as any).jQuery(document.body).addClass(`theme-${themeType}`);

    return (
      <BlockUi
        tag='div'
        blocking={appLoader || configLoader || loader}
        renderChildren={false}
        loader={<Loader />}
      >
        {!configLoader && !viewQuotesLoader && (
          <Helmet>
            <title>{this.getPageMetaDetails().title}</title>
            <meta name='description' content={this.getPageMetaDetails().description} />
            <link rel='icon' type='image/png' href={this.getPageMetaDetails().favicon} />
          </Helmet>
        )}

        <div className='container-fluid p-0'>{this.getAppRoutes()}</div>
        <NotificationContainer />
      </BlockUi>
    );
  }
}

const mapStateToProps = ({
  auth,
  common,
  home,
}: AppComponents.AppStore): AppComponents.AppStoreProps => {
  return { auth, common, home };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AppDispatch => {
  return bindActionCreators(
    {
      appInit,
      fetchPconfig,
      userLoginPage,
      authLoaderShow,
      autoLogout,
      cancelHomeQuotePolling,
      switchApp,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
