import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Tooltip, Typography } from '@material-ui/core';
import {
  Add as AddIcon,
} from '@material-ui/icons';
import { v4 } from 'uuid';
import QuestionHandler from './question-handler';
import LobLoader from '../../lob-loader';
import { getNestedTernaryData } from '../../../../utils';
import {
  getMappedUmbrellaQuestionnaireGroups,
  getQuestionGroupLabelByGroupName,
  getQuestionGroupLabelBySubGroupName,
  validateUmbrellaDropdown,
} from '../../../../utils/umbrella';
import { DELETE, REQUIRED, UMBRELLA } from '../../../../constants';
import {
  ADD_MORE,
  ADDITIONAL_MEMBER,
  GROUP,
  MAX_REPEATABLE_MEMBERS,
  PRIMARY_MEMBER,
  SUB_GROUP,
  UMBRELLA_QUESTION_FIELD_TYPES,
} from '../../../../constants/umbrella-questionnaire';
import './umbrella-form.scss';
const { DROPDOWN } = UMBRELLA_QUESTION_FIELD_TYPES;

export const UmbrellaFormCompoment = (props: AppComponents.UmbrellaFormCompomentProps) => {
  const { loader, questionnaireSet, onAnswer, errorQuestionKeys } = props;

  const [questionGroups, setQuestionGroups] = useState<
    Array<AppComponents.UmbrellaQuestionnaireGroup>
  >([]);
  const [isComponentRenderedFirstTime, setIsComponentRenderedFirstTime] = useState<boolean>(false);

  const onAnswerHandler = (questionDetails: AppComponents.UmbrellaQuestionnaire) => {
    const tempQuestionGroups: Array<AppComponents.UmbrellaQuestionnaireGroup> = questionGroups;
    const {
      groupName,
      subGroupName,
      questionId: { questionKey },
      response,
      repeatableQuestionsId,
    } = questionDetails;
    questionGroups?.forEach((group, groupIndex) => {
      if (group?.groupName === groupName && !subGroupName) {
        if (!isEmpty(repeatableQuestionsId)) {
          group?.questions.forEach((repeatableQuestionSet, repeatableQuestionSetIndex) => {
            if (repeatableQuestionSet?.repeatableQuestionsId === repeatableQuestionsId) {
              repeatableQuestionSet?.repeatableQuestions.forEach(
                (question: any, questionIndex: any) => {
                  if (question?.questionId?.questionKey === questionKey) {
                    tempQuestionGroups[groupIndex].questions[
                      repeatableQuestionSetIndex
                    ].repeatableQuestions[questionIndex].response = response;
                  }
                }
              );
            }
          });
        } else {
          group?.questions.forEach((question, questionIndex) => {
            if (question?.questionId?.questionKey === questionKey) {
              tempQuestionGroups[groupIndex].questions[questionIndex].response = response;
            }
          });
        }
      } else if (!!subGroupName && !isEmpty(group?.subGroups)) {
        group?.subGroups?.forEach((subGroup, subGroupIndex) => {
          if (subGroup?.groupName === subGroupName) {
            if (!isEmpty(repeatableQuestionsId)) {
              subGroup?.questions.forEach((repeatableQuestionSet, repeatableQuestionSetIndex) => {
                if (repeatableQuestionSet?.repeatableQuestionsId === repeatableQuestionsId) {
                  repeatableQuestionSet?.repeatableQuestions.forEach(
                    (question: any, questIndex: any) => {
                      if (question?.questionId?.questionKey === questionKey) {
                        const tempSubGroups = tempQuestionGroups[groupIndex].subGroups;
                        if (!!tempSubGroups && !isEmpty(tempSubGroups)) {
                          tempSubGroups[subGroupIndex].questions[
                            repeatableQuestionSetIndex
                          ].repeatableQuestions[questIndex].response = response;
                        }
                      }
                    }
                  );
                }
              });
            } else {
              subGroup?.questions.forEach((quest, questIndex) => {
                if (quest?.questionId?.questionKey === questionKey) {
                  const tempSubGroups = tempQuestionGroups[groupIndex].subGroups;
                  if (!!tempSubGroups && !isEmpty(tempSubGroups)) {
                    tempSubGroups[subGroupIndex].questions[questIndex].response = response;
                  }
                }
              });
            }
          }
        });
      }
    });
    setQuestionGroups([...tempQuestionGroups]);
    onAnswer({ ...questionnaireSet, questionGroups: tempQuestionGroups });
  };

  useEffect(() => {
    if (!isEmpty(questionnaireSet) && !isEmpty(questionnaireSet?.questionGroups)) {
      if (!isComponentRenderedFirstTime) {
        const tempQuestionGroups = getMappedUmbrellaQuestionnaireGroups(
          questionnaireSet?.questionGroups
        );
        setQuestionGroups([...tempQuestionGroups]);
        onAnswer({ ...questionnaireSet, questionGroups: tempQuestionGroups });
        setIsComponentRenderedFirstTime(true);
      } else {
        setQuestionGroups([...questionnaireSet?.questionGroups]);
      }
    }
    // eslint-disable-next-line
  }, [questionnaireSet]);

  const onAddNewRepeatableData = (details: any, type: string) => {
    let questionGroupsList: Array<any> = questionGroups || [];
    const isValid: boolean = validateRepeatableQuestions() || false;
    if (!isValid) {
      return;
    }
    if (!isEmpty(questionGroupsList)) {
      questionGroupsList?.forEach((item: any, itemIndex: number) => {
        if (type === GROUP && item?.groupName === details?.groupName) {
          let initQuestions: any = {
            repeatableQuestionsId: v4(),
            repeatableQuestions: [],
          };
          item?.questions[0]?.repeatableQuestions?.forEach((questionField: any) => {
            let questionItem: any = {
              ...questionField,
              repeatableQuestionsId: initQuestions?.repeatableQuestionsId,
              response: '',
              validationErrorMessage: '',
            };
            initQuestions.repeatableQuestions.push(questionItem);
          });
          questionGroupsList[itemIndex].questions = [
            ...questionGroupsList[itemIndex].questions,
            { ...initQuestions },
          ];
        } else if (type === SUB_GROUP && !isEmpty(item?.subGroups)) {
          let initSubGroupQuestions: any = {
            repeatableQuestionsId:  v4(),
            repeatableQuestions: [],
          };
          item?.subGroups?.forEach((subGroup: any, subGroupIndex: number) => {
            if (subGroup?.groupName === details?.groupName) {
              details?.questions[0]?.repeatableQuestions?.forEach((questionField: any) => {
                let questionItem: any = {
                  ...questionField,
                  repeatableQuestionsId: initSubGroupQuestions?.repeatableQuestionsId,
                  response: '',
                  validationErrorMessage: '',
                };
                initSubGroupQuestions.repeatableQuestions.push(questionItem);
              });
              questionGroupsList[itemIndex].subGroups[subGroupIndex].questions = [
                ...questionGroupsList[itemIndex].subGroups[subGroupIndex].questions,
                { ...initSubGroupQuestions },
              ];
            }
          });
        }
      });
    }
    setQuestionGroups([...questionGroupsList]);
  };

  const onDeleteRepeatableDetails = (details: any, type: string, repeatableSet: any) => {
    let questionGroupsList: Array<any> = questionGroups || [];
    if (!isEmpty(questionGroupsList)) {
      questionGroupsList?.forEach((item: any, itemIndex: number) => {
        if (type === GROUP && item?.groupName === details?.groupName) {
          let groupQuestionList: Array<any> = item?.questions?.filter(
            (groupItem: any) =>
              groupItem?.repeatableQuestionsId !== repeatableSet?.repeatableQuestionsId
          );
          questionGroupsList[itemIndex].questions = groupQuestionList;
        } else if (type === SUB_GROUP && !isEmpty(item?.subGroups)) {
          item?.subGroups?.forEach((subGroup: any, subGroupIndex: number) => {
            if (subGroup?.groupName === details?.groupName) {
              let subGroupQuestionList: Array<any> = subGroup?.questions?.filter(
                (subGroupItem: any) =>
                  subGroupItem?.repeatableQuestionsId !== repeatableSet?.repeatableQuestionsId
              );
              questionGroupsList[itemIndex].subGroups[subGroupIndex].questions =
                subGroupQuestionList;
            }
          });
        }
      });
    }
    setQuestionGroups([...questionGroupsList]);
  };

  const validateRepeatableQuestions = () => {
    let isValid: boolean = true;
    let tempQuestionGroups: Array<any> = questionGroups || [];
    tempQuestionGroups.forEach((group, groupIndex) => {
      if (group?.repeatable) {
        group?.questions.forEach(
          (repeatableQuestionSet: any, repeatableQuestionSetIndex: number) => {
            repeatableQuestionSet?.repeatableQuestions.forEach(
              (question: any, questionIndex: any) => {
                if (
                  question?.required &&
                  (!question?.response ||
                    (question.type === DROPDOWN && validateUmbrellaDropdown(question?.response)))
                ) {
                  isValid = false;
                  tempQuestionGroups[groupIndex].questions[
                    repeatableQuestionSetIndex
                  ].repeatableQuestions[questionIndex].validationErrorMessage = REQUIRED;
                } else {
                  tempQuestionGroups[groupIndex].questions[
                    repeatableQuestionSetIndex
                  ].repeatableQuestions[questionIndex].validationErrorMessage = '';
                }
              }
            );
          }
        );
      }
      if (!isEmpty(group?.subGroups)) {
        group?.subGroups?.forEach((subGroup: any, subGroupIndex: number) => {
          if (subGroup?.repeatable) {
            subGroup?.questions.forEach(
              (repeatableQuestionSet: any, repeatableQuestionSetIndex: number) => {
                repeatableQuestionSet?.repeatableQuestions.forEach(
                  (quest: any, questIndex: number) => {
                    const tempSubGroups = tempQuestionGroups[groupIndex]?.subGroups;
                    if (!!tempSubGroups && !isEmpty(tempSubGroups)) {
                      if (
                        quest?.required &&
                        (!quest?.response ||
                          (quest?.type === DROPDOWN && validateUmbrellaDropdown(quest?.response)))
                      ) {
                        isValid = false;
                        tempSubGroups[subGroupIndex].questions[
                          repeatableQuestionSetIndex
                        ].repeatableQuestions[questIndex].validationErrorMessage = REQUIRED;
                      } else {
                        tempSubGroups[subGroupIndex].questions[
                          repeatableQuestionSetIndex
                        ].repeatableQuestions[questIndex].validationErrorMessage = '';
                      }
                    }
                  }
                );
              }
            );
          }
        });
      }
    });
    setQuestionGroups([...tempQuestionGroups]);
    return isValid;
  };

  return (
    <div>
      {getNestedTernaryData(
        isEmpty(questionGroups) && loader,
        <div className='w-100 mt-4 d-flex justify-content-center'>
          <LobLoader lob={UMBRELLA} />
        </div>,
        questionGroups?.map((group: any) => {
          return (
            <div key={group?.groupName} className='umbrell-wraper'>
              {group?.repeatable ? (
                <>
                  <div className='question-group-label text-left'>
                    {getQuestionGroupLabelByGroupName(group?.groupName)}
                  </div>

                  {group?.questions.map((repeatableQuestionSet: any, index: number) => {
                    return (
                      <div key={index} className={`${index % 2 === 0 ? 'white-bg' : 'light-bg'}`}>
                        {group?.questions?.length && (
                          <div className='row m-0'>
                            <div className={`d-flex justify-content-between  col-lg-12 p-0 primary-member-div ${ index % 2 === 0 ? 'white-bg-spacing' : 'light-bg-spacing'}`}>
                              <Typography>
                                {index === 0 ? PRIMARY_MEMBER : `${ADDITIONAL_MEMBER} ${index}`}
                              </Typography>
                              {index !== 0 && (
                                <div className='d-flex justify-content-end delete-icon'>
                                  <Tooltip
                                    arrow
                                    onClick={() =>
                                      onDeleteRepeatableDetails(group, GROUP, repeatableQuestionSet)
                                    }
                                    placement='top'
                                    title={DELETE}
                                  >
                                    <span className='icon-delete1'></span>
                                  </Tooltip>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {repeatableQuestionSet?.repeatableQuestions.map(
                          (question: any, questionIndex: number) => {
                            const {
                              questionId: { questionKey },
                            } = question;
                            return (
                              <div key={questionIndex}>
                                <QuestionHandler
                                  question={question}
                                  onAnswer={onAnswerHandler}
                                  key={questionKey + questionIndex}
                                  errorQuestionKeys={errorQuestionKeys}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    );
                  })}
                  {!isEmpty(group?.questions) && group?.questions?.length < MAX_REPEATABLE_MEMBERS && (
                    <div className='row'>
                      <div className='col-lg-12 d-flex justify-content-left'>
                        <button
                          className='add-member-btn'
                          onClick={() => {
                            onAddNewRepeatableData(group, GROUP);
                          }}
                        >
                          <AddIcon className='mr-2' />
                          {ADD_MORE}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className='question-group-label text-left'>
                    {getQuestionGroupLabelByGroupName(group?.groupName)}
                  </div>
                  {group?.questions.map((question: any) => {
                    const {
                      questionId: { questionKey },
                    } = question;
                    return (
                      <QuestionHandler
                        question={question}
                        onAnswer={onAnswerHandler}
                        key={questionKey}
                        errorQuestionKeys={errorQuestionKeys}
                      />
                    );
                  })}
                </>
              )}

              {group?.subGroups?.map((subGroup: any) => {
                return (
                  <div key={subGroup.groupName}>
                    {subGroup?.repeatable ? (
                      <>
                        <div className='question-sub-group-label font-weight-bold text-left'>
                          {getQuestionGroupLabelBySubGroupName(subGroup?.groupName)}
                        </div>
                        {subGroup?.questions.map((repeatableQuestionSet: any, index: number) => {
                          return (
                            <div key={index}>
                              {subGroup?.questions?.length && (
                                <div className='row'>
                                  <div className='d-flex justify-content-between repeatable-delete-row col-lg-12'>
                                    {index === 0 ? PRIMARY_MEMBER : `${ADDITIONAL_MEMBER} ${index}`}
                                    {index !== 0 && (
                                      <div className='d-flex justify-content-end delete-icon'>
                                        <Tooltip
                                          arrow
                                          onClick={() =>
                                            onDeleteRepeatableDetails(
                                              subGroup,
                                              SUB_GROUP,
                                              repeatableQuestionSet
                                            )
                                          }
                                          placement='top'
                                          title={DELETE}
                                        >
                                          <span className='icon-delete1'></span>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {repeatableQuestionSet?.repeatableQuestions?.map(
                                (quest: any, questIndex: number) => {
                                  return (
                                    <div key={questIndex}>
                                      <QuestionHandler
                                        question={quest}
                                        onAnswer={onAnswerHandler}
                                        key={quest?.questionId?.questionKey + questIndex}
                                        errorQuestionKeys={errorQuestionKeys}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        })}
                        {!isEmpty(subGroup?.questions) &&
                          subGroup?.questions?.length < MAX_REPEATABLE_MEMBERS && (
                            <div className='row'>
                              <div className='col-lg-12 mt-2'>
                                <button
                                  className='add-more-btn'
                                  onClick={() => {
                                    onAddNewRepeatableData(subGroup, SUB_GROUP);
                                  }}
                                >
                                  <AddIcon className='mr-2' />
                                  {ADD_MORE}
                                </button>
                              </div>
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        <div className='question-sub-group-label font-weight-bold text-left'>
                          {getQuestionGroupLabelBySubGroupName(subGroup?.groupName)}
                        </div>
                        {subGroup?.questions?.map((quest: any) => {
                          return (
                            <QuestionHandler
                              question={quest}
                              onAnswer={onAnswerHandler}
                              key={quest?.questionId?.questionKey}
                              errorQuestionKeys={errorQuestionKeys}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </div>
  );
};

export default UmbrellaFormCompoment;
