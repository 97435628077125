export const NON_ADMITTED_HOVER_DETAILS: any = {
  HOME: {
    TITLE: 'Notice Before Bridging',
    SUB_TITLE:
      'The following are sample reasons to why an agent would offer non-admitted vs admitted:',
    BODY: [
      `Excess and Surplus Insurance is a specialty market that insures homes that standard carriers won't cover. Non-admitted markets are <strong>not</strong> covered by the state if the insurance company becomes insolvent. In this situation, the customer's claim may not be paid. It's often considered high risk to write with a non-admitted insurance carrier.`,
      `Examples for Non-Admitted: <br/>
      <ol>
        <li>Living in a high-risk area for wildfires, floods, or earthquakes.<br/></li>
        <li>Have an unusual situation. Any number of characteristics can put you outside of a standard insurer's underwriting guidelines. These might include unique building materials, a severe loss history, or criminal convictions.</li>
        <li>Require higher limits. Every insurer has a coverage limit that makes them tap out, and your mansion on 30 acres may hit their max.</li>
      </ol>
      `
    ],
  },
  FLOOD: {
    TITLE: 'VALID REASONS FOR NON-ADMITTED',
    SUB_TITLE:
      'The following are sample reasons to why an agent would offer non-admitted vs admitted:',
    BODY: [
      'Building Coverage exceeds what the admitted market offers.',
      'Admitted market does not offer Loss of Use or Additional Living Expense or other coverage.',
      'Coinsurance clause (e.g. ACV payout) for building or contents.',
      'Admitted market products have a waiting period and the customer needs the policy sooner due to mortgage closing or other reasons.',
      'No diligent effort required because the insured qualifies as an "Exempt Commercial Purchaser" (NY).',
    ],
  },
};

export const NON_ADMITTED_AMS_CONSENT = {
  HOME: 'As a licensed insurance agent, I attest that I have conducted a diligent search and filled out due diligence forms as defined by the law of the state in which this policy is written, and that I was unable to procure this insurance from an admitted insurance company. If required by the carrier, the signed due diligence form is saved in Salesforce.',
  FLOOD: 'As a licensed insurance agent, I attest that I have conducted a diligent search as defined by the law of the state in which this policy is written and that I was unable to procure this insurance from an admitted insurance company.'
};
