import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { THANK_YOU, XS } from '../../../constants';
import './feedback-success.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: theme.palette.grey[500],
    },
  })
);

const FeedbackSuccessModal = (props: AppComponents.FeedbackSuccessModalProps) => {
  const classes = useStyles();
  const { open } = props;
  const onCloseModal = () => {
    props.onClose();
  };

  return (
    <div className='text-center'>
      <Dialog
        maxWidth={XS}
        className='feedback-success-modal'
        onClose={onCloseModal}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <DialogTitle id='form-dialog-title'>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='text-center'>
          <div className='row'>
            <div className='col-lg-12 d-flex justify-content-center'>
              <CheckIcon className="feedback-check-icon"/>
            </div>
            <div className='col-lg-12'>
              <Typography className='lbl-thank-u'>{`${THANK_YOU}!`}</Typography>
              <Typography className='success-msg-lbl mb-4'>
                We appreciate your feedback.
                <br /> A ticket has been created and <br /> assigned to the Prodsupport team.
              </Typography>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FeedbackSuccessModal;
