import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormControl, FormHelperText, Tooltip } from '@material-ui/core';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import TextField from '@material-ui/core/TextField';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  setVinErrorFlag,
  setVinServerError,
  vinCleared,
  vinSubmitted,
  setHasFormChanged,
  setEditVehicleFlag,
  setVehicleDetails
} from '../../redux/actions';
import { validateVin } from '../../auto/utils';
import { allowEditingQuote, deleteDiffKeys, getIsHeapAnalyticsEnabled } from '../../../utils';
import { HEAP_LABELS, QUOTING_STEPS_PAGES_NAME } from '../../../constants';
import './vin-input.scss';

const styles = () => ({
  errorMsg: {
    color: '#FF0000',
    height: '0px',
  },
});

class VINInput extends React.PureComponent<
  AppComponents.VINInputProps & WithStyles<typeof styles>,
  AppComponents.VINInputState
> {
  state: AppComponents.VINInputState = {
    vin: '',
    vinError: '',
  };

  componentDidMount() {
    const { vehicle } = this.props;
    this.setState({ vin: vehicle.vinInput ? vehicle.vin : '' });
    this.props.setVinErrorFlag(false);
    this.props.setVinServerError(false);
  }

  UNSAFE_componentWillReceiveProps(newProps: AppComponents.VINInputProps) {
    const { vehicle, common } = newProps;
    const { vinError } = this.state;
    const vin = get(vehicle, 'vin', '');
    if (!vehicle.vinInput && !isEmpty(vinError) && !isEmpty(vehicle.vehicleSelectorDetails.year)) {
      this.setState({ vin: '', vinError: '' });
    }
    if (
      vehicle &&
      !vehicle.vinLoader &&
      vehicle.vinInput &&
      vin.length > 0 &&
      !vehicle.isVinErrorCleared &&
      !vehicle?.isVinCleared
    ) {
      this.setState({ vin });
    }
    if (vin.length === 0 && !vehicle.vehicleAdded && !vehicle.vinLoader && vin.length) {
      this.setState({ vin, vinError: '' });
    }
    if (isEmpty(vin) && !vehicle.vinLoader && !common?.loding) {
      this.setState({ vin: '', vinError: '' });
    }
    if (
      !isEmpty(this.state.vin) &&
      vehicle.vinServerError &&
      !vehicle.vinLoader
    ) {
      this.setState({ vin: this.state.vin, vinError: 'Invalid VIN number.' });
    }

    const copiedState = {
      ...deleteDiffKeys(this.props.vehicle, vehicle),
    };
    const copiedVehicle = { ...deleteDiffKeys(vehicle, this.props.vehicle) };

    if (!isEqual(copiedState, copiedVehicle)) {
      this.props.setHasFormChanged(true);
    }
  }

  handleVINInputChange = (event: any) => {
    this.setState({ vinError: '', vin: String(event.target.value).trim() });
  };

  handleVINInputBlur = (event: any) => {
    const { vehicle } = this.props;
    const vinValue = event.target.value;
    if (vinValue && validateVin(vinValue) && vinValue.length > 3) {
      let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
      if (isHeapAnalyticsEnabled) {
        window.heap.track(QUOTING_STEPS_PAGES_NAME?.VEHICLE_INFO_P1_SUBMIT, {
          [HEAP_LABELS.ENTRY_TYPE]: HEAP_LABELS?.VIN,
        });
      }
      this.setState({ vinError: '' }, () => {
        this.props.setVehicleDetails({
          how_long: '',
          approx_miles: '',
          isVinCleared: false,
        });
        this.props.vinSubmitted(vinValue);
        this.props.setEditVehicleFlag(false);
      });
    } else {
      if (vinValue.length > 0) {
        if (vehicle.vinInput) {
          this.props.setVinErrorFlag(true);
        }
        this.setState({ vinError: 'Invalid VIN number.' });
      }
    }
    if (
      vinValue.length === 0 &&
      vehicle.vinInput
    ) {
      this.props.setVehicleDetails({
        isVinCleared: true,
      });
    }
  };

  render() {
    const { vehicle, classes } = this.props;
    const { vinError } = this.state;
    const disableAutoEditing = allowEditingQuote();
    return (
      <>
        <div className='col-md-9 vin-input-container'>
          <FormControl fullWidth style={{ width: '100%' } as any}>
            <TextField
              disabled={disableAutoEditing?.auto || vehicle.vinLoader || vehicle.vehicleSelectorDetails.vehicleSelectorLoader}
              style={{ width: '100%' } as any}
              fullWidth 
              placeholder='VIN number'
              value={this.state.vin}
              inputProps={{
                id: 'vin',
                name: 'vin',
                maxLength: 17,
                className: 'vin-auto-capitalize fs-exclude',
              }}
              variant='outlined'
              onChange={this.handleVINInputChange}
              onBlur={this.handleVINInputBlur}
              error={vinError === '' ? false : true}
            />
            <FormHelperText className={classes.errorMsg}>{vinError}</FormHelperText>
          </FormControl>
        </div>
        <div className='vin-tooltip'>
          <Tooltip
            title='Supplying the VIN increases the likelihood of returning a rate and improves accuracy.'
            arrow
           >
            <InfoOutlinedIcon className='info-circle' />
          </Tooltip>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ vehicle, common }: AppComponents.VINInputStore) => {
  return { vehicle, common };
};

const mapDispatchToProps = (dispatch: any): AppComponents.VINInputDispatch => {
  return bindActionCreators(
    {
      vinCleared,
      vinSubmitted,
      setVinErrorFlag,
      setVinServerError,
      setHasFormChanged,
      setEditVehicleFlag,
      setVehicleDetails
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VINInput));
