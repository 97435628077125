/* istanbul ignore file */
import * as types from '../constants';

interface InsuranceState {
  insurance_today: string;
  expiration_date: string;
  new_policy_term: string;
  policy_start_date: string;
  reason_no_prior: string;
  reason_for_lapse: string;
  motorist: string;
  injury_limits: string;
  property_damage: string;
  medical_payments: string;
  prior_carrier: string;
  prior_liability_limits: string;
}

const INIT_STATE: InsuranceState = {
  insurance_today: 'yes',
  expiration_date: '',
  new_policy_term: '6',
  policy_start_date: '',
  reason_no_prior: '',
  reason_for_lapse: '',
  motorist: '',
  injury_limits: '',
  property_damage: '',
  medical_payments: '',
  prior_carrier: '',
  prior_liability_limits: '',
};

export default (state = INIT_STATE, action: AppRedux.Action) => {
  switch (action.type) {
    case types.APP_INIT: {
      return { ...state };
    }

    case types.ADD_INSURANCE_DETAILS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case types.GENERAL_COVERAGES: {
      const { coverage } = action.payload;
      return {
        ...state,
        ...action.payload,
        motorist: coverage.motorist,
        injury_limits: coverage.injury_limits,
        property_damage: coverage.property_damage,
        medical_payments: coverage.medical_payments,
      };
    }

    case types.CLEAR_INSURANCE_DETAILS: {
      return {
        ...INIT_STATE,
      };
    }

    default:
      return state;
  }
};
