import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'lodash';
import history from '../../../history';
import { getOrganizationData } from '../../redux/actions';
import { ADMIN_ROUTE } from '../../../constants';
import OrganizationEditor from '../organization-editor';

export class OrganizationDetails extends Component<AppComponents.OrganizationDetailProps> {
  componentDidMount = () => {
    const { pathname } = history.location;
    const urlEndPoint = pathname.substring(pathname.lastIndexOf('/') + 1);
    if (!isEmpty(urlEndPoint) && pathname.includes(urlEndPoint)) {
      this.props.getOrganizationData({ id: urlEndPoint });
    } else {
      history.push(ADMIN_ROUTE);
    }
  };

  render() {
    const { addEditOrgLoader, editOrganizationData } = this.props;
    return (
      <div>
        <OrganizationEditor
          organization={editOrganizationData}
          organizationEdit={addEditOrgLoader}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.OrganizationDetailsStore) => {
  const { addEditOrgLoader, editOrganizationData } = organizations;
  return { addEditOrgLoader, editOrganizationData, organizations };
};

const mapDispatchToProps = (dispatch: any): AppComponents.OrganizationDetailsDispatch => {
  return bindActionCreators({ getOrganizationData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetails);
