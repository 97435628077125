export const PRIOR_LIABILITY_LIMIT = [
  { label: 'Other Standard', value: 'Other Standard' },
  { label: 'Other Non-Standard', value: 'Other Non-Standard' },
  { label: '21st Century', value: '21st Century' },
  { label: 'A.Central', value: 'A.Central' },
  { label: 'AAA', value: 'AAA' },
  { label: 'AARP', value: 'AARP' },
  { label: 'Acadia', value: 'Acadia' },
  { label: 'Access General', value: 'Access General' },
  { label: 'Ace', value: 'Ace' },
  { label: 'Acuity', value: 'Acuity' },
  { label: 'Adirondack Ins Exchange', value: 'Adirondack Ins Exchange' },
  { label: 'Aegis', value: 'Aegis' },
  { label: 'Affirmative', value: 'Affirmative' },
  { label: 'AIC', value: 'AIC' },
  { label: 'AIG', value: 'AIG' },
  { label: 'Alfa Alliance', value: 'Alfa Alliance' },
  { label: 'Allied Trust Insurance Company', value: 'Allied Trust Insurance Company' },
  { label: 'Allied', value: 'Allied' },
  { label: 'Allstate', value: 'Allstate' },
  { label: 'America First', value: 'America First' },
  { label: 'American Commerce', value: 'American Commerce' },
  { label: 'American Family', value: 'American Family' },
  { label: 'American Freedom Insurance Company', value: 'American Freedom Insurance Company' },
  { label: 'American National', value: 'American National' },
  { label: 'Amerisure', value: 'Amerisure' },
  { label: 'Amica', value: 'Amica' },
  { label: 'AmShield', value: 'AmShield' },
  { label: 'AmWINS Star', value: 'AmWINS Star' },
  { label: 'Anchor General', value: 'Anchor General' },
  { label: 'Arrowhead Everest', value: 'Arrowhead Everest' },
  { label: 'Arrowhead', value: 'Arrowhead' },
  { label: 'ASI Lloyds', value: 'ASI Lloyds' },
  { label: 'ASI Select Auto Insurance Corp', value: 'ASI Select Auto Insurance Corp' },
  { label: 'Aspen', value: 'Aspen' },
  { label: 'Aspire', value: 'Aspire' },
  { label: 'Atlantic Mutual', value: 'Atlantic Mutual' },
  { label: 'Austin Mutual', value: 'Austin Mutual' },
  { label: 'Autoone', value: 'Autoone' },
  { label: 'Auto-Owners', value: 'Auto-Owners' },
  { label: 'AutoTex', value: 'AutoTex' },
  { label: 'Badger Mutual', value: 'Badger Mutual' },
  { label: 'Balboa', value: 'Balboa' },
  { label: 'Bankers', value: 'Bankers' },
  { label: 'Beacon National', value: 'Beacon National' },
  { label: 'Bear River Mutual', value: 'Bear River Mutual' },
  { label: 'Brethern Mutual', value: 'Brethern Mutual' },
  { label: 'Bristol West', value: 'Bristol West' },
  { label: 'Buckeye', value: 'Buckeye' },
  { label: 'California Casualty', value: 'California Casualty' },
  { label: 'Cameron Mutual', value: 'Cameron Mutual' },
  { label: 'Capital Insurance Group', value: 'Capital Insurance Group' },
  { label: 'Capitol Insurance Company', value: 'Capitol Insurance Company' },
  { label: 'Celina', value: 'Celina' },
  { label: 'Centennial', value: 'Centennial' },
  { label: 'Central Mutual of OH', value: 'Central Mutual of OH' },
  { label: 'Century National', value: 'Century National' },
  { label: 'Charter', value: 'Charter' },
  { label: 'Chubb', value: 'Chubb' },
  { label: 'Cincinnati Casualty', value: 'Cincinnati Casualty' },
  { label: 'Cincinnati Insurance', value: 'Cincinnati Insurance' },
  { label: 'Citizens', value: 'Citizens' },
  { label: 'CNA', value: 'CNA' },
  { label: 'Colonial Penn', value: 'Colonial Penn' },
  { label: 'Colorado Casualty', value: 'Colorado Casualty' },
  { label: 'Columbia', value: 'Columbia' },
  { label: 'Commerce West', value: 'Commerce West' },
  { label: 'Commonwealth', value: 'Commonwealth' },
  { label: 'Concord Group Insurance', value: 'Concord Group Insurance' },
  { label: 'Constitutional Casualty', value: 'Constitutional Casualty' },
  { label: 'Consumers', value: 'Consumers' },
  { label: 'Cornerstone', value: 'Cornerstone' },
  { label: 'Country Insurance', value: 'Country Insurance' },
  { label: 'Countryway Insurance', value: 'Countryway Insurance' },
  { label: 'Countrywide', value: 'Countrywide' },
  { label: 'CSE', value: 'CSE' },
  { label: 'Cumberland', value: 'Cumberland' },
  { label: 'Dairyland', value: 'Dairyland' },
  { label: 'Deerbrook', value: 'Deerbrook' },
  { label: 'Delta Lloyds Insurance Company', value: 'Delta Lloyds Insurance Company' },
  { label: 'Depositors', value: 'Depositors' },
  { label: 'Direct General', value: 'Direct General' },
  { label: 'Direct', value: 'Direct' },
  { label: 'Discovery', value: 'Discovery' },
  { label: 'Donegal', value: 'Donegal' },
  { label: 'Drive', value: 'Drive' },
  { label: 'Electric', value: 'Electric' },
  { label: 'EMC', value: 'EMC' },
  { label: 'Empower', value: 'Empower' },
  { label: 'Encompass', value: 'Encompass' },
  { label: 'Enumclaw Insurance', value: 'Enumclaw Insurance' },
  { label: 'Erie', value: 'Erie' },
  { label: 'Esurance', value: 'Esurance' },
  { label: 'Eveready', value: 'Eveready' },
  { label: 'Explorer', value: 'Explorer' },
  { label: 'Farm Bureau', value: 'Farm Bureau' },
  { label: 'Farmers', value: 'Farmers' },
  { label: 'Federated', value: 'Federated' },
  { label: 'Fidelity', value: 'Fidelity' },
  { label: 'Financial Indemnity', value: 'Financial Indemnity' },
  { label: 'Firemans Fund', value: 'Firemans Fund' },
  { label: 'First Acceptance', value: 'First Acceptance' },
  { label: 'First American', value: 'First American' },
  { label: 'First Auto', value: 'First Auto' },
  { label: 'First Chicago', value: 'First Chicago' },
  { label: 'First Connect', value: 'First Connect' },
  { label: 'Fitchburg Mutual', value: 'Fitchburg Mutual' },
  { label: 'Flagship Insurance', value: 'Flagship Insurance' },
  { label: 'Foremost', value: 'Foremost' },
  { label: 'Founders', value: 'Founders' },
  { label: 'Frankenmuth', value: 'Frankenmuth' },
  { label: 'Fred Loya', value: 'Fred Loya' },
  { label: 'GAINSCO Auto Insurance', value: 'GAINSCO Auto Insurance' },
  { label: 'Gateway', value: 'Gateway' },
  { label: 'Geico', value: 'Geico' },
  { label: 'General Casualty', value: 'General Casualty' },
  { label: 'Germantown Mutual', value: 'Germantown Mutual' },
  { label: 'GMAC', value: 'GMAC' },
  { label: 'Goodville Mutual', value: 'Goodville Mutual' },
  { label: 'Grange Insurance Association', value: 'Grange Insurance Association' },
  { label: 'Grange', value: 'Grange' },
  { label: 'GRE/Go America', value: 'GRE/Go America' },
  { label: 'Great American', value: 'Great American' },
  { label: 'Grinnell', value: 'Grinnell' },
  { label: 'Guide One', value: 'Guide One' },
  { label: 'Hallmark Insurance Company', value: 'Hallmark Insurance Company' },
  { label: 'Hanover', value: 'Hanover' },
  { label: 'Harbor', value: 'Harbor' },
  { label: 'Harleysville', value: 'Harleysville' },
  { label: 'Hartford OMNI', value: 'Hartford OMNI' },
  { label: 'Hartford', value: 'Hartford' },
  { label: 'Hastings Mutual', value: 'Hastings Mutual' },
  { label: 'Haulers Insurance Company', value: 'Haulers Insurance Company' },
  { label: 'Hawkeye Security', value: 'Hawkeye Security' },
  { label: 'HDI', value: 'HDI' },
  { label: 'Horace Mann', value: 'Horace Mann' },
  { label: 'Houston General', value: 'Houston General' },
  { label: 'IFA', value: 'IFA' },
  { label: 'Imperial Casualty', value: 'Imperial Casualty' },
  { label: 'IMT Ins', value: 'IMT Ins' },
  { label: 'Indiana Farmers', value: 'Indiana Farmers' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Infinity', value: 'Infinity' },
  { label: 'Insuremax', value: 'Insuremax' },
  { label: 'Insurequest', value: 'Insurequest' },
  { label: 'Integon', value: 'Integon' },
  { label: 'Integrity', value: 'Integrity' },
  { label: 'Iowa Mutual Insurance Company', value: 'Iowa Mutual Insurance Company' },
  { label: 'Kemper Specialty', value: 'Kemper Specialty' },
  { label: 'Kemper', value: 'Kemper' },
  { label: 'Kingsway', value: 'Kingsway' },
  { label: 'Legacy - Arizona Auto Ins. Co', value: 'Legacy - Arizona Auto Ins. Co' },
  { label: 'LeMars Insurance', value: 'LeMars Insurance' },
  { label: 'Liberty Mutual', value: 'Liberty Mutual' },
  { label: 'Liberty Northwest', value: 'Liberty Northwest' },
  { label: 'Madison Mutual Insurance Company', value: 'Madison Mutual Insurance Company' },
  { label: 'Maidstone Insurance', value: 'Maidstone Insurance' },
  { label: 'MAIF', value: 'MAIF' },
  { label: 'Main Street America', value: 'Main Street America' },
  { label: 'Mapfre', value: 'Mapfre' },
  { label: 'Markel', value: 'Markel' },
  { label: 'Maryland Auto Insurance', value: 'Maryland Auto Insurance' },
  { label: 'Mendakota', value: 'Mendakota' },
  { label: 'Mendota', value: 'Mendota' },
  { label: 'Merchants Group', value: 'Merchants Group' },
  { label: 'Mercury', value: 'Mercury' },
  { label: 'MetLife', value: 'MetLife' },
  { label: 'Metropolitan', value: 'Metropolitan' },
  { label: 'Michigan Insurance Company', value: 'Michigan Insurance Company' },
  {
    label: 'Michigan Millers Mutual Insurance Company',
    value: 'Michigan Millers Mutual Insurance Company',
  },
  { label: 'Mid-Continent', value: 'Mid-Continent' },
  { label: 'Midwestern Indemnity', value: 'Midwestern Indemnity' },
  { label: 'MMG Insurance Company', value: 'MMG Insurance Company' },
  { label: 'Montgomery', value: 'Montgomery' },
  { label: 'Motor Club Insurance Company', value: 'Motor Club Insurance Company' },
  { label: 'Motorists Mutual', value: 'Motorists Mutual' },
  { label: 'MSA', value: 'MSA' },
  { label: 'Mt. Washington', value: 'Mt. Washington' },
  { label: 'Mutual Benefit', value: 'Mutual Benefit' },
  { label: 'Mutual of Enumclaw', value: 'Mutual of Enumclaw' },
  { label: 'National General', value: 'National General' },
  { label: 'National Lloyds Insurance Company', value: 'National Lloyds Insurance Company' },
  { label: 'Nationwide', value: 'Nationwide' },
  { label: 'New York Central Mutual', value: 'New York Central Mutual' },
  { label: 'NJ Manufacturers', value: 'NJ Manufacturers' },
  { label: 'NJ Skylands', value: 'NJ Skylands' },
  { label: 'NLC Insurance Companies', value: 'NLC Insurance Companies' },
  { label: 'Nodak Mutual', value: 'Nodak Mutual' },
  { label: 'Northern Neck Insurance Company', value: 'Northern Neck Insurance Company' },
  { label: 'Northstar', value: 'Northstar' },
  { label: 'NYAIP', value: 'NYAIP' },
  { label: 'NYCM Standard', value: 'NYCM Standard' },
  { label: 'Occidental', value: 'Occidental' },
  { label: 'Ocean Harbor', value: 'Ocean Harbor' },
  { label: 'Ohio Casualty', value: 'Ohio Casualty' },
  { label: 'Ohio Mutual', value: 'Ohio Mutual' },
  { label: 'Omaha P/C', value: 'Omaha P/C' },
  { label: 'Omni Insurance Co', value: 'Omni Insurance Co' },
  { label: 'One Beacon', value: 'One Beacon' },
  { label: 'Oregon Mutual', value: 'Oregon Mutual' },
  { label: 'Palisades', value: 'Palisades' },
  { label: 'Partners Mutual Insurance', value: 'Partners Mutual Insurance' },
  { label: 'Patriot', value: 'Patriot' },
  { label: 'Patrons Oxford', value: 'Patrons Oxford' },
  { label: 'Peerless/Montgomery', value: 'Peerless/Montgomery' },
  { label: 'Pekin', value: 'Pekin' },
  { label: 'Pemco', value: 'Pemco' },
  { label: 'Peninsula Insurance Companies', value: 'Peninsula Insurance Companies' },
  { label: 'Penn National', value: 'Penn National' },
  { label: 'Personal Service Insurance', value: 'Personal Service Insurance' },
  { label: 'Phoenix Indemnity', value: 'Phoenix Indemnity' },
  { label: 'Pioneer State Mutual', value: 'Pioneer State Mutual' },
  { label: 'Plymouth Rock', value: 'Plymouth Rock' },
  { label: 'Preferred Mutual', value: 'Preferred Mutual' },
  { label: 'Proformance', value: 'Proformance' },
  { label: 'Progressive', value: 'Progressive' },
  {
    label: 'Providence Mutual Fire Insurance Company',
    value: 'Providence Mutual Fire Insurance Company',
  },
  { label: 'Prudential', value: 'Prudential' },
  { label: 'QBE', value: 'QBE' },
  { label: 'Quincy Mutual', value: 'Quincy Mutual' },
  { label: 'RAM Mutual Insurance Company', value: 'RAM Mutual Insurance Company' },
  { label: 'Republic', value: 'Republic' },
  { label: 'Response', value: 'Response' },
  { label: 'Rockford Mutual', value: 'Rockford Mutual' },
  { label: 'Rockingham Casualty Company', value: 'Rockingham Casualty Company' },
  { label: 'Royal and Sun Alliance', value: 'Royal and Sun Alliance' },
  { label: 'Safe Auto', value: 'Safe Auto' },
  { label: 'Safeco', value: 'Safeco' },
  { label: 'Safeway', value: 'Safeway' },
  { label: 'Sagamore', value: 'Sagamore' },
  { label: 'SECURA', value: 'SECURA' },
  { label: 'Selective', value: 'Selective' },
  { label: 'Sentry Ins', value: 'Sentry Ins' },
  { label: 'Sheboygan Falls Insurance', value: 'Sheboygan Falls Insurance' },
  { label: 'Shelter Insurance', value: 'Shelter Insurance' },
  { label: 'Southern County', value: 'Southern County' },
  { label: 'Southern Mutual', value: 'Southern Mutual' },
  { label: 'Southern Trust', value: 'Southern Trust' },
  { label: 'St. Paul/Travelers', value: 'St. Paul/Travelers' },
  { label: 'Standard Mutual', value: 'Standard Mutual' },
  { label: 'Star Casualty', value: 'Star Casualty' },
  { label: 'State Auto', value: 'State Auto' },
  { label: 'State Farm', value: 'State Farm' },
  { label: 'StillWater', value: 'StillWater' },
  { label: 'Stonegate', value: 'Stonegate' },
  { label: 'Sublimity Insurance Company', value: 'Sublimity Insurance Company' },
  { label: 'Sun Coast Platinum', value: 'Sun Coast Platinum' },
  { label: 'The General', value: 'The General' },
  { label: 'Titan', value: 'Titan' },
  { label: 'Topa', value: 'Topa' },
  { label: 'Tower', value: 'Tower' },
  { label: 'Travelers', value: 'Travelers' },
  { label: 'TWFG', value: 'TWFG' },
  { label: 'Unigard', value: 'Unigard' },
  { label: 'United Automobile', value: 'United Automobile' },
  { label: 'United Fire and Casualty', value: 'United Fire and Casualty' },
  {
    label: 'United Heritage Property and Casualty Company',
    value: 'United Heritage Property and Casualty Company',
  },
  { label: 'United Home', value: 'United Home' },
  { label: 'Unitrin', value: 'Unitrin' },
  { label: 'Universal', value: 'Universal' },
  { label: 'USAA', value: 'USAA' },
  { label: 'Utica National', value: 'Utica National' },
  { label: 'Victoria', value: 'Victoria' },
  { label: 'Wadena Insurance Company', value: 'Wadena Insurance Company' },
  { label: 'West Bend', value: 'West Bend' },
  { label: 'Western National', value: 'Western National' },
  { label: 'Western Reserve Group', value: 'Western Reserve Group' },
  { label: 'Westfield', value: 'Westfield' },
  { label: 'White Mountains', value: 'White Mountains' },
  { label: 'Wilshire', value: 'Wilshire' },
  { label: 'Wilson Mutual', value: 'Wilson Mutual' },
  { label: 'Wind Haven', value: 'Wind Haven' },
  { label: 'Windsor', value: 'Windsor' },
  { label: 'Wisconsin Mutual', value: 'Wisconsin Mutual' },
  { label: 'Workmens Auto Insurance Company', value: 'Workmens Auto Insurance Company' },
  { label: 'Worth Casualty Insurance Company', value: 'Worth Casualty Insurance Company' },
  { label: 'Zurich', value: 'Zurich' },
];
