import { Button, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { filter, get, isEmpty, map, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AMSButtonImg from '../../../assets/images/ams-button.png';
import PolicyBoundRibbon from '../../../assets/images/policy-bound-ribbon.png';
import amsadhocbtn from '../../../assets/images/ams-adhoc-btn.svg';
import config from '../../../config/config';
import {
  APPLICANT,
  AMS_360_ADHOC,
  BUSINESS_TYPE_ICON_MAPPING,
  COVERAGES,
  CYBER_SECURITY,
  DEFAULT_PAGE_TRACKING,
  DESELECT,
  DOLLAR_ZERO,
  DRIVER,
  EARTH_QUAKE_LOB,
  ESTIMATED_ANNUAL_PRICE,
  HOME_WARRANTY,
  HEAP_LABELS,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  LIFE_INFO,
  LOAN_NUMBER_LABEL,
  LOB_PRODUCTS_MAPPING,
  MAX_PET_BREEDS_INFO,
  NA_VALUE,
  PAGES_TEXT,
  PARTNER_ID_LABEL,
  PET_INFO,
  PROPERTY_INFO,
  QUOTING_STEPS_PAGES_NAME,
  REFRESH,
  SELECT,
  QUOTE_APPLICANTION_VIEW_ROUTE,
  QUESTIONNAIRE_VIEW_PATH_ROUTE,
} from '../../../constants';
import {
  adhocLobMapping,
  checkForNonPremiumBoundedCarrier,
  checkSpouseMissing,
  excludeAmsForLobs,
  formatNumberToCurrency,
  formatUSPhoneNumber,
  getAmsDetails,
  getAnnualPremiumValue,
  getApplicantLobKey,
  getHerdIdFromUrl,
  getIsCarrierTimeOutSettingEnabled,
  getIsHeapAnalyticsEnabled,
  getIsLobQuotesLoading,
  getIsPropertyInfoEnabledDetails,
  getLineOfBusiness,
  getMappedQuotingCarriers,
  getOrgDetails,
  getQuoteAndErrQuoteCount,
  getQuoteListOfLob,
  getSortedQuoteList,
  getSelectedCarrierIndex,
  isApplicantDataRequired,
  isAutoQuote,
  isNoQuotesAvailableForLob,
  loadQuotesOnRatePage,
  orderLOBRates,
  removeExistingRate,
  setProductsPriority,
  showAmsButtonAsPerLob,
  getInsuranceProducts,
  sortAdmittedAndNonAdmittedCarrier,
  getMappedUpComingSoonCarriersList,
  getLobDetails,
  isPropertyInfoCovered,
} from '../../../utils';
import { isNumberCheck } from '../../auto/utils';
import {
  addProduct,
  cleanHelperProduct,
  clearQuoteDetails,
  clearQuoteLobDetails,
  cyberSecurityQuotesLoader,
  fetchCyberSecurityQuotes,
  flushAppDetails,
  fetchLifeQuotes,
  fetchQuakeQuoteDetails,
  fetchPetQuotes,
  fetchUmbrellaQuotes,
  initAutoQuotes,
  initViewQuotes,
  questionnaireSubmit,
  refreshAutoQuotes,
  refreshHomeQuotes,
  refreshCyberSecurityQuotes,
  refreshLifeQuotes,
  refreshUmbrellaQuotes,
  refreshFloodQuotes,
  saveAutoQuoteDetails,
  saveSelectedCarrier,
  setAMSDetails,
  setAMSDetailsSuccess,
  setGoToRatesTabFromPolicy,
  setHomeState,
  setLineOfBusiness,
  setRedirectToViewQuoteFlag,
  setSelectedBusinessOption,
  setShowGetQuoteButton,
  setVehicleList,
  showAMSModal,
  stopPolling,
  stopUmbrellaPolling,
  storeEmptyLobErrorMessage,
  setAddProductSaveApplicantLoader,
  setAdhocOrganizationDetails,
  setLobPriority,
  setLobRefreshLoader,
  saveApplicantDetails,
  stopLifePolling,
  updateSelectedCarriers,
  updateSelectedProducts,
  updateRequoteProducts,
  vinCleared,
  vehicleSelectedForEdit,
} from '../../redux/actions';
import { TOBACCO_USE_DATA } from '../../../constants/life-questionnaire';
import AMSDetailsModal from '../ams-details-modal';
import AMSSuccessModal from '../ams-success-modal';
import BridgeToCarrierModal from '../bridge-to-carrier-modal';
import ConfirmationModal from '../confirmation-modal';
import OrganizationModal from '../organization-modal';
import ThemesImages from '../themes-images';
import ViewAmsBoundData from '../view-bound-quote';
import { AddProductsDetails } from '../rate-page-common-component';
import './products-rates.scss';
import QuoteList from './quotes-list';
import { isEnabledPRSv2Flow } from '../../../quote-application/utils';

const { AUTO, CYBERSECURITY, FLOOD, HOME, HOMEWARRANTY, LIFE, PET, QUAKE, UMBRELLA } =
INSURANCE_BUSINESS_TYPES;

const ratePageTypes: { [key: string]: string } = {
  carousel: 'carousel',
  navigation: 'navigation',
};

const ProductRates = ({
  driver,
  quotesLoaded,
  quotingDetails,
  quotesLoading,
  quoteErrorList,
  initAutoQuotes,
  stopPolling,
  flushAppDetails,
  initViewQuotes,
  setAMSDetails,
  appointments,
  onPrevTab,
  setTab,
  submitLoader,
  hippoQuoteLoaded,
  ezlynxQuotesLoaded,
  homeQuoteList,
  floodQuoteList,
  autoQuoteList,
  applicant,
  vehicle,
  annexPrefill,
  pconfig,
  auth,
  producerDetails,
  AMSSuccess,
  openAMSModal,
  policyBounded,
  lineOfBusiness,
  addProduct,
  cleanHelperProduct,
  goToLastTab,
  goBackToCertainTab,
  tabsCount,
  motorist,
  injury_limits,
  property_damage,
  medical_payments,
  detailsLoader,
  setRedirectToViewQuoteFlag,
  setAdhocOrganizationDetails,
  tabValue,
  setShowGetQuoteButton,
  common,
  home,
  quote,
  floodDetails,
  showAMSModal,
  setSelectedBusinessOption,
  selectedBusinessOption,
  showGetQuoteButton,
  storedRequestId,
  newProducts,
  homeErrorQuoteList,
  questionnaireSubmit,
  clearQuoteLobDetails,
  clearQuoteDetails,
  setLineOfBusiness,
  setHomeState,
  quoteType,
  isProviderOneLogin,
  quakeDetails,
  saveAutoQuoteDetails,
  veriskIsLoading,
  setGoToRatesTabFromPolicy,
  boundedPolicyDetails,
  saveSelectedCarrier,
  selectedCarriers,
  selectedCarrierLoader,
  updateSelectedCarriers,
  redirectToViewQuote,
  petDetails,
  saveApplicantDetails,
  residency_status,
  updateSelectedProducts,
  storeEmptyLobErrorMessage,
  vinCleared,
  setVehicleList,
  vehicleSelectedForEdit,
  updateRequoteProducts,
  fetchPetQuotes,
  homeWarrantyDetails,
  setLobPriority,
  setLobRefreshLoader,
  redirectionStep,
  lifeDetails,
  cyberSecurityDetails,
  stopLifePolling,
  fetchLifeQuotes,
  cyberSecurityQuotesLoader,
  fetchCyberSecurityQuotes,
  fetchQuakeQuoteDetails,
  umbrellaDetails,
  isProviderAms,
  fetchUmbrellaQuotes,
  stopUmbrellaPolling,
  refreshHomeQuotes,
  refreshAutoQuotes,
  refreshCyberSecurityQuotes,
  refreshLifeQuotes,
  refreshUmbrellaQuotes,
  lobRefreshLoader,
  redirectToStep,
  floodQuoteErrorList,
  refreshFloodQuotes,
  setAddProductSaveApplicantLoader,
}: AppComponents.RatesProps) => {
  const amsDetails: any = getAmsDetails();
  const { isAmsEnabled, isAdhocBindEnabled } = amsDetails;
  let isHeapAnalyticsEnabled: boolean = !!window.heap && getIsHeapAnalyticsEnabled();
  let themeType = !!pconfig?.dale_config?.branding_on_dale_details?.template_name
    ? pconfig?.dale_config?.branding_on_dale_details?.template_name
    : config.hippo.template_name;
  const insuranceProducts = getInsuranceProducts(producerDetails, home?.pconfig?.dale_config?.lob);
  const logoUrl: { [key: string]: string } = {
    [HOME]: ThemesImages[themeType].homeInsuranceIcon,
    [FLOOD]: ThemesImages[themeType].floodIcon,
    [AUTO]: ThemesImages[themeType].autoIcon,
    [QUAKE]: ThemesImages[themeType].quakeIcon,
    [PET]: ThemesImages[themeType].petIcon,
    [HOMEWARRANTY]: ThemesImages[themeType].homewarrantyIcon,
    [LIFE]: ThemesImages[themeType].lifeIcon,
    [CYBERSECURITY]: ThemesImages[themeType].cybersecurityIcon,
    [UMBRELLA]: ThemesImages[themeType].umbrellaIcon,
  };
  const [annualTotalPrice, setAnnualTotalPrice] = useState('0');
  const { first_name, last_name, phone_number, date_of_birth, email } = applicant.personal_details;
  const { street, unit, city, zip, state } = applicant.address;
  const { vehiclesList } = vehicle;
  const { driversList } = driver;
  const address = `${street ? street : ''} ${unit ? unit : ''} ${city ? city : ''}, ${
    state ? state.toUpperCase() : ''
  } ${zip ? zip : ''}`;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showApplicationEdit, setShowApplicationEdit] = useState(false);
  const [isAutoPolicyBounded, setIsAutoPolicyBounded] = useState(false);
  const [isPetPolicyBounded, setIsPetPolicyBounded] = useState(false);
  const firstOption = selectedBusinessOption || Object.keys(appointments)[0];
  const [businessOption, setBusinessOption] = useState(firstOption);
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState<Array<string>>([]);
  const ratePageType = !!pconfig?.dale_config?.rater_page_type
    ? pconfig?.dale_config?.rater_page_type
    : ratePageTypes.navigation;
  const [carouselLobData, setCarouselLobData] = useState('');
  const [isVeriskLoading, setIsVeriskLoading] = useState(false);
  // Used to redirect after fetching verisk api
  const [redirectToTab, setRedirectToTab] = useState<{ targetTab: null | number; type: string }>({
    targetTab: null,
    type: '',
  });
  const [showAMSModalForAdhoc360, setShowAMSModalForAdhoc360] = useState(false);
  const carrierTimeoutMessageEnabled: boolean = getIsCarrierTimeOutSettingEnabled(businessOption, pconfig);

  useEffect(() => {
    if (!isEmpty(redirectionStep) && isEmpty(newProducts)) {
      setLobPriority('');
    }
    // eslint-disable-next-line
  }, [redirectionStep, newProducts]);

  useEffect(() => {
    if (
      (!!policyBounded && showApplicationEdit) ||
      (!isEmpty(petDetails?.quoteList) && !petDetails?.petBreedsDetailsLoader)
    ) {
      let selectedQuoteList: any = !!sessionStorage?.selectedQuoteList
        ? JSON.parse(sessionStorage.selectedQuoteList)
        : [];
      updateAnnualPrice(selectedQuoteList);
    }
    // eslint-disable-next-line
  }, [
    policyBounded,
    boundedPolicyDetails,
    petDetails.quoteList,
    petDetails.petBreedsDetailsLoader,
  ]);

  useEffect(() => {
    const { driversList, isFetchAutoQuotes } = driver;
    let { missingSpouse } = checkSpouseMissing(driversList);
    let { requoteLobs, has_form_changed } = common;
    const { herdId } = getHerdIdFromUrl();
    if (!isEmpty(requoteLobs) && !reloadViewQuote(herdId)) {
      if (
        !has_form_changed &&
        !isFetchAutoQuotes &&
        requoteLobs.includes(INSURANCE_PRODUCTS[1]) &&
        !missingSpouse &&
        !isEmpty(vehicle?.vehiclesList) &&
        !isEmpty(driversList) &&
        !isEmpty(residency_status)
      ) {
        stopPolling();
        initAutoQuotes();
      }
      if (
        requoteLobs.includes(INSURANCE_PRODUCTS[4]) &&
        !isEmpty(petDetails?.petList) &&
        !petDetails?.isBreedError
      ) {
        fetchPetQuotes(petDetails?.petList);
      }
      if (requoteLobs.includes(INSURANCE_PRODUCTS[6]) && !isEmpty(lifeDetails?.lifeInfo)) {
        stopLifePolling();
        fetchLifeQuotes();
      }
      if (
        requoteLobs.includes(INSURANCE_PRODUCTS[8]) &&
        !isEmpty(umbrellaDetails?.umbrellaQuestionsResponse)
      ) {
        stopUmbrellaPolling();
        fetchUmbrellaQuotes();
      }
      updateRequoteProducts([]);
    }
    // eslint-disable-next-line
  }, [common?.requoteLobs]);
  useEffect(() => {
    if (Object.keys(appointments).length || Object.keys(newProducts).length) {
      const setDefaultOption =
        !!newProducts && Object.keys(newProducts).length
          ? Object.keys(newProducts)[0]
          : !Object.keys(appointments).includes(firstOption)
          ? Object.keys(appointments)[0]
          : firstOption;
      setBusinessOption(setDefaultOption);
      setSelectedBusinessOption(setDefaultOption);
    }
    // eslint-disable-next-line
  }, [appointments, newProducts]);

  useEffect(() => {
    const { targetTab, type } = redirectToTab;
    if (common.isVeriskFetched && !vehicle.vinLoader && !common.isVeriskLoading) {
      if (targetTab && type) {
        goBackToCertainTab(targetTab, type);
      }
    }
    // eslint-disable-next-line
  }, [common.isVeriskFetched, vehicle.vinLoader, common.isVeriskLoading]);

  useEffect(() => {
    if (
      (!!appointments && Object.keys(appointments).length) ||
      (!!newProducts && Object.keys(newProducts).length)
    ) {
      if (
        ((!!annexPrefill &&
          !!newProducts &&
          loadQuotesOnRatePage(annexPrefill, newProducts) &&
          quoteType !== 'AUTO' &&
          !showGetQuoteButton) ||
          isEmpty(annexPrefill)) &&
        !isAutoQuote(Object.keys(newProducts)) &&
        !applicant.isAddProductSaveApplicantLoading
      ) {
        setShowModal(false);
        !isEmpty(annexPrefill) && setShowApplicationEdit(false);
      }
    }
    // eslint-disable-next-line
  }, [newProducts, applicant.isAddProductSaveApplicantLoading]);

  useEffect(() => {
    showGetQuoteButton && setShowGetQuoteButton(false);
    // eslint-disable-next-line
  }, [showGetQuoteButton]);

  useEffect(() => {
    if (policyBounded && !isEmpty(policyBounded) && policyBounded[INSURANCE_PRODUCTS[1]]) {
      setIsAutoPolicyBounded(true);
    }
    if (petDetails?.isPolicyBounded) {
      setIsPetPolicyBounded(true);
    }
    // eslint-disable-next-line
  }, [policyBounded, petDetails?.isPolicyBounded]);

  useEffect(() => {
    const { isFetchAutoQuotes, driversList } = driver;
    const { has_form_changed } = common;
    if (
      isFetchAutoQuotes &&
      has_form_changed &&
      vehicle?.vehiclesList?.length &&
      driversList?.length &&
      !isEmpty(residency_status)
    ) {
      stopPolling();
      if (isEmpty(quotingDetails?.herd_id) && !quotesLoading) {
        if (!quotesLoaded) {
          if (sessionStorage.selectedQuoteList) {
            const selectedQuotes = removeExistingRate(
              JSON.parse(sessionStorage.selectedQuoteList),
              AUTO
            );

            sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
          }
          initAutoQuotes();
        } else {
          initViewQuotes(quotingDetails.herd_id as string);
          setAMSDetails({
            selectedFloodQuote: null,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [driver.isFetchAutoQuotes, common.has_form_changed]);

  useEffect(() => {
    const selectedQuoteList = sessionStorage.selectedQuoteList
      ? JSON.parse(sessionStorage.selectedQuoteList)
      : [];
    let selectedQuoteListIndex = selectedQuoteList.findIndex((el: any) => {
      return businessOption === el.type;
    });
    //Update default selected carrier to session storage
    sessionStorage.selectedQuoteList =
      selectedQuoteListIndex > -1
        ? JSON.stringify([selectedQuoteList[selectedQuoteListIndex]])
        : JSON.stringify([]);
    getCurrentProducts();
    setGoToRatesTabFromPolicy(false);
    cleanHelperProduct();
    const filteredList = filter(vehiclesList, item => !isEmpty(item.own_lease));
    const incompleteList = filter(vehiclesList, item => isEmpty(item.own_lease));
    if (incompleteList.length && filteredList.length) {
      vinCleared();
      setVehicleList(filteredList);
    }
    if (filteredList.length && filteredList[0]?.vuid) {
      filteredList[0].selectedVehicle = true;
      vehicleSelectedForEdit(filteredList[0].vuid);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (floodQuoteList.length && ratePageType === ratePageTypes.navigation && !submitLoader) {
      selectDefaultQuote(getFloodDefault);
    }
    // eslint-disable-next-line
  }, [floodQuoteList, submitLoader, policyBounded, boundedPolicyDetails]);
  useEffect(() => {
    if (
      quakeDetails?.quoteList?.length &&
      ratePageType === ratePageTypes.navigation &&
      !quakeDetails?.quakeSubmitLoader
    ) {
      selectDefaultQuote(getQuakeDefault);
    }
    // eslint-disable-next-line
  }, [quakeDetails.quoteList, quakeDetails.quakeSubmitLoader, policyBounded, boundedPolicyDetails]);

  useEffect(() => {
    if (autoQuoteList.length && ratePageType === ratePageTypes.navigation && !quotesLoading) {
      selectDefaultQuote(getAutoDefault);
    }
    // eslint-disable-next-line
  }, [autoQuoteList, quotesLoading, policyBounded, boundedPolicyDetails]);

  useEffect(() => {
    if (hippoQuoteLoaded && ezlynxQuotesLoaded && ratePageType === ratePageTypes.navigation) {
      selectDefaultQuote(getHomeDefault);
    }
    // eslint-disable-next-line
  }, [ezlynxQuotesLoaded, hippoQuoteLoaded, policyBounded, boundedPolicyDetails]);

  useEffect(() => {
    if (
      !petDetails?.quoteListLoader &&
      petDetails?.quoteList?.length &&
      ratePageType === ratePageTypes.navigation
    ) {
      selectDefaultQuote(getPetDefault);
    }
    // eslint-disable-next-line
  }, [petDetails?.quoteListLoader, petDetails?.quoteList]);

  useEffect(() => {
    if (
      !homeWarrantyDetails?.quoteListLoader &&
      homeWarrantyDetails?.quoteList?.length &&
      ratePageType === ratePageTypes.navigation
    ) {
      selectDefaultQuote(getHomeWarrantyDefault);
    }
    // eslint-disable-next-line
  }, [homeWarrantyDetails?.quoteListLoader, homeWarrantyDetails?.quoteList]);

  useEffect(() => {
    if (
      !cyberSecurityDetails?.quoteListLoader &&
      cyberSecurityDetails?.quoteList?.length &&
      ratePageType === ratePageTypes.navigation
    ) {
      selectDefaultQuote(getCyberSecurityDefault);
    }
    // eslint-disable-next-line
  }, [cyberSecurityDetails?.quoteListLoader, cyberSecurityDetails?.quoteList]);

  useEffect(() => {
    if (
      !lifeDetails?.quoteListLoader &&
      lifeDetails?.quoteList?.length &&
      ratePageType === ratePageTypes.navigation
    ) {
      selectDefaultQuote(getLifeDefault);
    }
    // eslint-disable-next-line
  }, [lifeDetails?.quoteListLoader, lifeDetails?.quoteList]);

  useEffect(() => {
    if (
      !umbrellaDetails?.quoteListLoader &&
      umbrellaDetails?.quoteList?.length &&
      ratePageType === ratePageTypes.navigation
    ) {
      selectDefaultQuote(getUmbrellaDefault);
    }
    // eslint-disable-next-line
  }, [umbrellaDetails?.quoteListLoader, umbrellaDetails?.quoteList]);

  useEffect(() => {
    if (products.length) {
      deleteRatesFromStorage(products);
    }
    // eslint-disable-next-line
  }, [products]);

  useEffect(()=>{
    if(showAMSModalForAdhoc360){
      showAMSModal(true)
    }
    // eslint-disable-next-line
  },[showAMSModalForAdhoc360])

  useEffect(()=>{
    if(!openAMSModal){
      setShowAMSModalForAdhoc360(false)
    }
    // eslint-disable-next-line
  },[openAMSModal])

  const deleteRatesFromStorage = (products: Array<string>) => {
    const selectedQuoteList = JSON.parse(sessionStorage.selectedQuoteList);
    if (selectedQuoteList && Array.isArray(selectedQuoteList)) {
      const nonexistentRates: Array<string> = [];
      selectedQuoteList.forEach(item => {
        if (!products.includes(item.type.toLowerCase())) {
          nonexistentRates.push(item.type);
        }
      });

      if (nonexistentRates.length) {
        const existingRates = selectedQuoteList.filter(
          item => !nonexistentRates.includes(item.type)
        );
        sessionStorage.selectedQuoteList = JSON.stringify(existingRates);
        updateAnnualPrice(existingRates);
      }
    }
  };

  const showViewQuoteCheck = () => {
    let viewQuoteRedirect: any =
      lineOfBusiness === 'home' ? hippoQuoteLoaded && ezlynxQuotesLoaded : true;
    return (
      !quotesLoading &&
      !submitLoader &&
      !detailsLoader &&
      viewQuoteRedirect &&
      tabsCount &&
      tabsCount === tabValue &&
      (document.location.href.includes(QUESTIONNAIRE_VIEW_PATH_ROUTE) ||
        (isEnabledPRSv2Flow() &&
          document.location.href.includes(QUOTE_APPLICANTION_VIEW_ROUTE)))
    );
  };

  const getHerdId = (type: any) => {
    switch (type) {
      case AUTO:
        return quote?.herdId;
      case HOME:
        return home?.herd_id;
      case PET:
        return petDetails?.herdId;
      case HOMEWARRANTY:
        return homeWarrantyDetails?.herdId;
      case CYBERSECURITY:
        return cyberSecurityDetails?.herdId;
      case LIFE:
        return lifeDetails?.herdId;
      case FLOOD:
        return floodDetails?.herdId;
      case QUAKE:
        return quakeDetails?.herdId;
      case UMBRELLA:
        return umbrellaDetails?.herdId;
      default:
        return (
          quote?.herdId ||
          home?.herd_id ||
          petDetails?.herdId ||
          homeWarrantyDetails?.herdId ||
          cyberSecurityDetails?.herdId ||
          lifeDetails?.herdId ||
          floodDetails?.herdId ||
          quakeDetails?.herdId ||
          umbrellaDetails?.herdId
        );
    }
  };
  const shouldUpdateSelectedCarrier = (type: any, selectedQuote: any) => {
    let isCarrierSelect = false;
    type = !!type && type.toLowerCase();
    if (
      type &&
      selectedCarriers &&
      selectedCarriers[type] &&
      selectedQuote?.price &&
      !selectedQuote?.quoteError
    ) {
      const selectedCarrier = selectedCarriers[type];
      let price: any = Number(selectedCarrier?.premium);
      let quoteSelectedPrice: any = selectedQuote?.price;
      if (
        ratePageType === ratePageTypes.carousel &&
        (type === INSURANCE_PRODUCTS[2] || type === INSURANCE_PRODUCTS[3])
      ) {
        let selectedQuoteList =
          sessionStorage.selectedQuoteList && JSON.parse(sessionStorage.selectedQuoteList);
        const premiumSelected =
          !!selectedQuoteList &&
          selectedQuoteList.length &&
          selectedQuoteList.find((quote: any) => quote?.type?.toLowerCase() === type);
        if (!!premiumSelected) {
          quoteSelectedPrice = premiumSelected?.quote?.price;
        }
      }
      if (
        (type === INSURANCE_PRODUCTS[0] || type === INSURANCE_PRODUCTS[1]) &&
        selectedQuote?.carrier_id?.toString() !== selectedCarrier?.carrierId?.toString()
      ) {
        isCarrierSelect = true;
      } else if (
        (type === INSURANCE_PRODUCTS[2] ||
          type === INSURANCE_PRODUCTS[3] ||
          type === INSURANCE_PRODUCTS[4] ||
          type === INSURANCE_PRODUCTS[5] ||
          type === INSURANCE_PRODUCTS[6] ||
          type === INSURANCE_PRODUCTS[7] ||
          type === INSURANCE_PRODUCTS[8]) &&
        (price !== quoteSelectedPrice ||
          selectedQuote?.carrier_id?.toString() !== selectedCarrier?.carrierId?.toString())
      ) {
        isCarrierSelect = true;
      }
    } else if (type && selectedQuote?.price && !selectedQuote?.quoteError) {
      isCarrierSelect = true;
    }
    return isCarrierSelect;
  };

  const updateSelectedQuoteList = (
    selectedQuote: any,
    checked: boolean,
    type: string,
    selectedPremiumType?: any,
    term?: any
  ) => {
    // @TODO this is a temporary solution, when we use selectedQuoteList as a state we have issues
    //  on quotes-list first render checkbox check, this need to be reviewed and refactored
    let tmpList: any = sessionStorage.selectedQuoteList
      ? [...JSON.parse(sessionStorage.selectedQuoteList)]
      : [];
    let lobType = !!type && type.toLowerCase();
    let lobKey: any = lobType === INSURANCE_PRODUCTS[3] ? EARTH_QUAKE_LOB : lobType;
    if (checked) {
      if (
        shouldUpdateSelectedCarrier(type, selectedQuote) &&
        !selectedCarrierLoader &&
        !redirectToViewQuote
      ) {
        if (!policyBounded || (!!policyBounded && !Object.keys(policyBounded).includes(lobKey))) {
          saveSelectedCarrier({
            action: SELECT,
            lobType: type,
            selectedQuote: selectedQuote,
            herdId: getHerdId(type),
          });
        }
      }
      const el = tmpList.find((item: any) => item.type === type);
      if (
        (type === QUAKE ||
          type === PET ||
          type === LIFE ||
          type === CYBERSECURITY ||
          type === UMBRELLA) &&
        selectedPremiumType
      ) {
        selectedQuote.selectedPremiumType = selectedPremiumType;
        selectedQuote.term = term || '';
      }
      if (
        !el ||
        ((type === FLOOD ||
          type === QUAKE ||
          type === PET ||
          type === HOMEWARRANTY ||
          type === CYBERSECURITY ||
          type === LIFE ||
          type === UMBRELLA) &&
          !selectedQuote.price)
      ) {
        tmpList.push({ type: type, quote: selectedQuote });
        // todo: show some error
      } else {
        const index = tmpList.indexOf(el);
        if (index > -1) {
          tmpList.splice(index, 1);
        }
        tmpList.push({ type: type, quote: selectedQuote });
      }
      const orderedList = orderLOBRates(tmpList);
      sessionStorage.selectedQuoteList = JSON.stringify(orderedList);
      updateAnnualPrice(tmpList);
    } else {
      saveSelectedCarrier({
        action: DESELECT,
        lobType: type,
        selectedQuote: {},
        herdId: getHerdId(type),
      });
      const tmpArr = tmpList.filter((item: any) => item.type !== type);
      sessionStorage.selectedQuoteList = JSON.stringify(tmpArr);
      updateAnnualPrice(tmpArr);
    }
  };

  const getCurrentProducts = () => {
    const initialProducts: Array<string> = [];
    Object.keys(appointments).forEach((appointment: string) => {
      initialProducts.push(appointment.toLowerCase());
    });
    setProducts(initialProducts);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    getCurrentProducts();
    storeEmptyLobErrorMessage('');
    setShowModal(false);
  };

  const selectProduct = (product: string) => {
    if (Object.keys(appointments).includes(product.toUpperCase())) {
      return;
    } else {
      const currentProducts = [...products];
      const productIndex = currentProducts.indexOf(product);
      let addNewProducts;
      if (productIndex > -1) {
        addNewProducts = currentProducts.filter(p => p !== currentProducts[productIndex]);
      } else {
        addNewProducts = [...currentProducts, product];
      }
      setProducts(addNewProducts);
    }
  };

  const submitQuote = (value: any) => {
    if (products.length !== Object.keys(appointments).length) {
      addProduct({ isNewProduct: true, products, newProducts: value });
      goToLastTab(true);
      updateSelectedProducts({ isNewProduct: true, products, newProducts: value });
    }
  };

  const updateAnnualPrice = (tmpList: any) => {
    let tmpPrice = 0;
    tmpList.forEach((item: any) => {
      let selectedPremium = getAnnualPremiumValue(item?.quote);
      tmpPrice += item?.quote?.price
        ? parseFloat(item?.quote?.price) * parseInt(selectedPremium?.term)
        : 0;
    });
    setAnnualTotalPrice(() => formatNumberToCurrency(tmpPrice));
  };

  const showAMSModalForAdhoc360Handler = () => {
     const orgDetails = getOrgDetails()
     setAdhocOrganizationDetails({
      selectedAdhocOrganizationCode: orgDetails.organizationCode,
      selectedAdhocOrganizationId: orgDetails.organizationId,
      eoiUploadRequired: {},
    });
    setShowAMSModalForAdhoc360(true)
  }

  const selectBusinessOption = () => {
    return (
      <>
        <nav className='nav col-lg-9 mobbottom-4'>
          <div className='business-options col-md-12 col-lg-12'>
            {appointments &&
              Object.keys(appointments).map((el: string) => {
                let lobProduct: string = !isEmpty(LOB_PRODUCTS_MAPPING[el.toLowerCase()])
                  ? LOB_PRODUCTS_MAPPING[el.toLowerCase()]?.toUpperCase()
                  : '';
                return (
                  <Button
                    key={el}
                    onClick={() => {
                      setBusinessOption(el);
                      setSelectedBusinessOption(el);
                      if (lobRefreshLoader) {
                        setLobRefreshLoader(false);
                      }
                    }}
                    title={Object.keys(appointments).length > 3 ? lobProduct : ''}
                    className={`btn-green ${businessOption === el ? 'active' : ''}`}
                  >
                    <span className='width-40'>
                      <img className='img-fluid icon' alt='Button Icon' src={logoUrl[el]} />
                    </span>
                      <span className={`text-color width-60 ${Object.keys(appointments).length <= 3 ? 'd-block' : 'lob-name-none'}`}>{lobProduct}</span>
                  </Button>
                );
              })}
            {!!isAmsEnabled && !!isProviderOneLogin && !!isAdhocBindEnabled && (
              <Button
                onClick={() => {
                  showAMSModalForAdhoc360Handler();
                }}
                title={AMS_360_ADHOC.label} className="ams-adhoc-button"
                >
                <span className='width-40'>
                  <img className='img-fluid icon' src={amsadhocbtn} alt={AMS_360_ADHOC.label} />
                </span>
                <span className={`width-60 ams-label ${Object.keys(appointments).length <= 3 ? 'd-block' : 'lob-name-none'}`}>
                {AMS_360_ADHOC.label}</span>
              </Button>
            )}
          </div>
        </nav>
      </>
    );
  };

  const getLoaderImage = (type: any) => {
    switch (type) {
      case HOME:
        return ThemesImages[themeType].homeLoader;
      case FLOOD:
        return ThemesImages[themeType].floodLoader;
      case AUTO:
        return ThemesImages[themeType].autoLoader;
      case QUAKE:
        return ThemesImages[themeType].quakeLoader;
      case PET:
        return ThemesImages[themeType].petLoader;
      case HOMEWARRANTY:
        return ThemesImages[themeType].homeWarrantyLoader;
      case CYBERSECURITY:
        return ThemesImages[themeType].cyberSecurityLoader;
      case LIFE:
        return ThemesImages[themeType].lifeLoader;
      case UMBRELLA:
        return ThemesImages[themeType].umbrellaLoader;
      default:
        return ThemesImages[themeType].homeLoader;
    }
  };

  const viewAmsBoundForNonPremiumCarrier = () => {
    let quoteListData = getQuoteListOfLob(selectedBusinessOption || '');
    let boundedCarrierDetails = checkForNonPremiumBoundedCarrier(
      selectedBusinessOption,
      boundedPolicyDetails,
      quoteListData
    );
    return (
      <>
        {!isEmpty(boundedCarrierDetails) &&
        !!boundedCarrierDetails?.carrierLogo &&
        ratePageType === ratePageTypes.navigation ? (
          <Grid className='annual-price-right-section applicant-wrap mt-4 ' container spacing={2}>
            <Grid item xs={12} sm={12} className='position-relative policy-bound-wrap'>
              <img
                src={PolicyBoundRibbon}
                alt={
                  !!selectedBusinessOption ? startCase(selectedBusinessOption?.toLowerCase()) : ''
                }
                className='img-fluid policy-bond'
                onClick={() => setViewBoundQuote(true)}
              />
              <div className='d-flex justify-content-center policy-bound-div'>
                <img
                  src={boundedCarrierDetails?.carrierLogo}
                  alt={
                    !!selectedBusinessOption ? startCase(selectedBusinessOption?.toLowerCase()) : ''
                  }
                  className='img-fluid'
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </>
    );
  };

  const selectDefaultQuote = (defaultQuoteFunction: Function) => {
    const defaultQuote: any = defaultQuoteFunction();
    const selectedQuoteList = JSON.parse(sessionStorage.selectedQuoteList);
    if (selectedQuoteList && Array.isArray(selectedQuoteList)) {
      let shouldAdd: boolean = true;
      let selectedCarrier: any = [];
      selectedQuoteList.forEach(quote => {
        if (quote?.type === defaultQuote.type && quote?.price === defaultQuote?.quote?.price) {
          shouldAdd = false;
        }
        selectedCarrier.push(quote?.type?.toLowerCase());
      });
      if (shouldAdd) {
        const tmpList = [...selectedQuoteList, defaultQuote];
        const orderedList = orderLOBRates(tmpList);
        sessionStorage.selectedQuoteList = JSON.stringify(orderedList);
        if (
          shouldUpdateSelectedCarrier(defaultQuote?.type, defaultQuote?.quote) &&
          !selectedCarrierLoader &&
          !selectedCarrier.includes(defaultQuote?.type?.toLowerCase())
        ) {
          saveSelectedCarrier({
            action: SELECT,
            lobType: defaultQuote?.type,
            selectedQuote: defaultQuote?.quote,
            herdId: getHerdId(defaultQuote?.type),
          });
        }
        updateAnnualPrice(orderedList);
      }
    }
  };

  const getSelectedCarrierDetails = (type: any, premiumDetails: any, carrierId: any) => {
    let premiumDetail = null;
    let carrierPrice = Number.MAX_SAFE_INTEGER;
    let selectedCarrierResponse: any = {};
    if (!!boundedPolicyDetails[type]?.premium) {
      carrierPrice = Number(boundedPolicyDetails[type]?.premium);
      premiumDetail = premiumDetails?.find((el: any) => Number(el?.premium) === carrierPrice);
    }
    if (!premiumDetail && !!selectedCarriers[type]?.premium) {
      carrierPrice = Number(selectedCarriers[type]?.premium);
      premiumDetail = premiumDetails?.find((el: any) => Number(el?.premium) === carrierPrice);
    }
    if (
      !!premiumDetail &&
      carrierPrice === Number(premiumDetail?.premium) &&
      (carrierId?.toString() === boundedPolicyDetails[type]?.carrierId?.toString() ||
        carrierId?.toString() === selectedCarriers[type]?.carrierId?.toString())
    ) {
      selectedCarrierResponse.carrierPrice = premiumDetail?.premium;
      if (type === INSURANCE_PRODUCTS[6]) {
        selectedCarrierResponse.term = premiumDetail?.term || '';
        selectedCarrierResponse.selectedPremiumType = premiumDetail?.coverage || '';
      } else if (type === CYBER_SECURITY) {
        selectedCarrierResponse.selectedPremiumType = premiumDetail?.coverageType || '';
      } else if (type === INSURANCE_PRODUCTS[4]) {
        selectedCarrierResponse.selectedPremiumType = premiumDetail?.plan || '';
      } else {
        selectedCarrierResponse.selectedPremiumType = premiumDetail?.selectedPremiumType || '';
        selectedCarrierResponse.coverageType = premiumDetail?.coverageType || '';
      }
    }
    return selectedCarrierResponse;
  };

  const getFloodDefault = () => {
    let price = Number.MAX_SAFE_INTEGER;
    let defaultQuote = {};
    const result = floodQuoteList.find(
      carrier =>
        carrier.carrier_name === config.prioritizeFloodCarrier ||
        carrier.name === config.prioritizeFloodCarrier
    );
    floodQuoteList.forEach(carrier => {
      if (!isEmpty(boundedPolicyDetails?.flood) || !isEmpty(selectedCarriers?.flood)) {
        const carrierSelectedData = getSelectedCarrierDetails(
          INSURANCE_PRODUCTS[2],
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice } = carrierSelectedData;
          defaultQuote = { ...carrier, price: carrierPrice };
        }
      } else if (result) {
        result?.premiumDetails?.forEach((detail: any) => {
          if (detail?.premium < price) {
            price = detail?.premium;
            defaultQuote = { ...carrier, price };
          }
        });
      } else {
        carrier?.premiumDetails?.forEach((detail: any) => {
          if (detail?.premium < price) {
            price = detail?.premium;
            defaultQuote = { ...carrier, price };
          }
        });
      }
    });

    return { type: FLOOD, quote: defaultQuote };
  };

  const getQuakeDefault = () => {
    let price = Number.MAX_SAFE_INTEGER;
    let defaultQuote = {};
    quakeDetails?.quoteList?.forEach((carrier: any) => {
      if (!isEmpty(boundedPolicyDetails?.quake) || !isEmpty(selectedCarriers?.quake)) {
        const carrierSelectedData = getSelectedCarrierDetails(
          INSURANCE_PRODUCTS[3],
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice, coverageType } = carrierSelectedData;
          defaultQuote = {
            ...carrier,
            price: carrierPrice,
            selectedPremiumType: coverageType,
            coverageType,
          };
        }
      } else {
        carrier?.premiumDetails?.forEach((detail: any) => {
          if (detail?.premium < price) {
            price = detail?.premium;
            defaultQuote = { ...carrier, price };
          }
        });
      }
    });
    return { type: QUAKE, quote: defaultQuote };
  };

  const getAutoDefault = () => {
    let price = Number.MAX_SAFE_INTEGER;
    let defaultQuote = {};
    autoQuoteList.forEach(carrier => {
      if (!isEmpty(boundedPolicyDetails?.auto) || !isEmpty(selectedCarriers?.auto)) {
        const autoSelectedCarrier = boundedPolicyDetails?.auto || selectedCarriers?.auto;
        if (carrier?.carrier_id?.toString() === autoSelectedCarrier?.carrierId?.toString()) {
          price = carrier.price;
          defaultQuote = carrier;
        }
      } else if (carrier?.price < price) {
        price = carrier.price;
        defaultQuote = carrier;
      }
    });

    return { type: AUTO, quote: defaultQuote };
  };

  const getHomeDefault = () => {
    let defaultQuote;

    const result = homeQuoteList.find(
      carrier => carrier.carrier_name === 'Hippo' || carrier.name === 'Hippo'
    );
    let price = Number.MAX_SAFE_INTEGER;
    homeQuoteList.forEach(carrier => {
      if (!isEmpty(boundedPolicyDetails?.home) || !isEmpty(selectedCarriers?.home)) {
        const homeSelectedCarrier = boundedPolicyDetails?.home || selectedCarriers?.home;
        if (carrier?.carrier_id?.toString() === homeSelectedCarrier?.carrierId?.toString()) {
          defaultQuote = carrier;
        }
      } else if (result) {
        defaultQuote = result;
      } else if (carrier.price < price) {
        price = carrier.price;
        defaultQuote = carrier;
      }
    });

    return { type: HOME, quote: defaultQuote };
  };

  const getPetDefault = () => {
    let price = Number.MAX_SAFE_INTEGER;
    let defaultQuote = {};
    let isNoQuoteSelected: boolean = false;
    petDetails?.quoteList?.forEach((carrier: any) => {
      if (!isEmpty(boundedPolicyDetails?.pet) || !isEmpty(selectedCarriers?.pet)) {
        const carrierSelectedData = getSelectedCarrierDetails(
          INSURANCE_PRODUCTS[4],
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice, selectedPremiumType } = carrierSelectedData;
          defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType };
        } else {
          isNoQuoteSelected = true;
        }
      }
      if (
        isNoQuoteSelected ||
        (isEmpty(boundedPolicyDetails?.pet) && isEmpty(selectedCarriers?.pet))
      ) {
        carrier?.premiumDetails?.forEach((detail: any) => {
          if (detail?.premium < price) {
            price = detail?.premium;
            let selectedPremiumType = detail?.plan;
            defaultQuote = { ...carrier, price, selectedPremiumType };
          }
        });
      }
    });
    return { type: PET, quote: defaultQuote };
  };

  const getHomeWarrantyDefault = () => {
    let price = Number.MAX_SAFE_INTEGER;
    let defaultQuote = {};
    let isNoQuoteSelected: boolean = false;
    homeWarrantyDetails?.quoteList?.forEach((carrier: any) => {
      if (
        !isEmpty(boundedPolicyDetails[HOME_WARRANTY]) ||
        !isEmpty(selectedCarriers[HOME_WARRANTY])
      ) {
        const carrierSelectedData = getSelectedCarrierDetails(
          HOME_WARRANTY,
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice } = carrierSelectedData;
          defaultQuote = { ...carrier, price: carrierPrice };
        } else {
          isNoQuoteSelected = true;
        }
      }
      if (
        isNoQuoteSelected ||
        (isEmpty(boundedPolicyDetails[HOME_WARRANTY]) && isEmpty(selectedCarriers[HOME_WARRANTY]))
      ) {
        carrier?.premiumDetails?.forEach((detail: any) => {
          if (detail?.premium < price) {
            price = detail?.premium;
            defaultQuote = { ...carrier, price };
          }
        });
      }
    });
    return { type: HOMEWARRANTY, quote: defaultQuote };
  };

  const getCyberSecurityDefault = () => {
    let price = Number.MAX_SAFE_INTEGER;
    let defaultQuote = {};
    let isNoQuoteSelected: boolean = false;
    cyberSecurityDetails?.quoteList?.forEach((carrier: any) => {
      if (
        !isEmpty(boundedPolicyDetails[CYBER_SECURITY]) ||
        !isEmpty(selectedCarriers[CYBER_SECURITY])
      ) {
        const carrierSelectedData = getSelectedCarrierDetails(
          CYBER_SECURITY,
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice, selectedPremiumType } = carrierSelectedData;
          defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType };
        } else {
          isNoQuoteSelected = true;
        }
      }
      if (
        isNoQuoteSelected ||
        (isEmpty(boundedPolicyDetails[CYBER_SECURITY]) && isEmpty(selectedCarriers[CYBER_SECURITY]))
      ) {
        carrier?.premiumDetails?.forEach((detail: any) => {
          if (detail?.premium < price) {
            price = detail?.premium;
            let selectedPremiumType = detail?.coverageType;
            defaultQuote = { ...carrier, price, selectedPremiumType };
          }
        });
      }
    });
    return { type: CYBERSECURITY, quote: defaultQuote };
  };

  const getSelectedCarrier = (carrier: any) => {
    carrier = carrier || lifeDetails.quoteList[0];
    let defaultQuote = {};
    let price = Number.MAX_SAFE_INTEGER;
    carrier?.premiumDetails?.forEach((detail: any) => {
      if (detail?.premium < price) {
        price = detail?.premium;
        const term = detail?.term;
        const selectedPremiumType = detail?.coverage;
        defaultQuote = { ...carrier, price, selectedPremiumType, term };
      }
    });
    return defaultQuote;
  };

  const getUmbrellaDefault = () => {
    let defaultQuote: any = {};
    let isCarrierSelected: boolean = false;
    umbrellaDetails?.quoteList?.forEach((carrier: any) => {
      if (!isEmpty(boundedPolicyDetails?.umbrella) || !isEmpty(selectedCarriers?.umbrella)) {
        const carrierSelectedData = getSelectedCarrierDetails(
          INSURANCE_PRODUCTS[8],
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice, selectedPremiumType, term } = carrierSelectedData;
          defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType, term };
          return;
        }
      }
      if (
        isEmpty(boundedPolicyDetails?.umbrella) &&
        isEmpty(selectedCarriers?.umbrella) &&
        !isCarrierSelected
      ) {
        isCarrierSelected = true;
        defaultQuote = getSelectedCarrier(carrier);
      }
    });
    if (isEmpty(defaultQuote) && umbrellaDetails?.quoteList?.length) {
      defaultQuote = getSelectedCarrier(null);
    }
    return { type: UMBRELLA, quote: defaultQuote };
  };

  const getLifeDefault = () => {
    let defaultQuote: any = {};
    lifeDetails?.quoteList?.forEach((carrier: any) => {
      if (!isEmpty(boundedPolicyDetails?.life) || !isEmpty(selectedCarriers?.life)) {
        const carrierSelectedData = getSelectedCarrierDetails(
          INSURANCE_PRODUCTS[6],
          carrier?.premiumDetails,
          carrier?.carrier_id
        );
        if (!isEmpty(carrierSelectedData)) {
          const { carrierPrice, selectedPremiumType, term } = carrierSelectedData;
          defaultQuote = { ...carrier, price: carrierPrice, selectedPremiumType, term };
          return;
        }
      }
      if (isEmpty(boundedPolicyDetails?.life) && isEmpty(selectedCarriers?.life)) {
        defaultQuote = getSelectedCarrier(carrier);
      }
    });
    if (isEmpty(defaultQuote) && lifeDetails?.quoteList?.length) {
      defaultQuote = getSelectedCarrier(null);
    }
    return { type: LIFE, quote: defaultQuote };
  };

  const drawRatesAsLoaded = (quoteList: Array<any>, loading: boolean) => {
    return quoteList.map((carrier: any, index: number) => (
      <QuoteList
        type={businessOption}
        loading={loading}
        quoteList={quoteList}
        carrierSelected={index}
        carrier={carrier}
        key={`_${carrier.carrier_id}${index}`}
        updateSelectedQuoteList={(
          q: any,
          checked: boolean,
          type: string,
          selectedPremiumType?: any,
          term?: any
        ) => updateSelectedQuoteList(q, checked, type, selectedPremiumType, term)}
        selectedQuoteList={
          sessionStorage.selectedQuoteList ? JSON.parse(sessionStorage.selectedQuoteList) : []
        }
        ratePageType={ratePageType}
        ratePageTypes={ratePageTypes}
      />
    ));
  };

  const redirectToCertainTab = (targetTab: number, type: string) => {
    const { annexPrefill } = floodDetails;
    const { dale_config } = pconfig;
    const { driversList } = driver;
    setProductsPriority([type]);
    if (
      !isEmpty(home?.productType) &&
      !isEmpty(annexPrefill) &&
      !annexPrefill.isFloodInfo &&
      Object.keys(appointments).includes(FLOOD) &&
      type === FLOOD
    ) {
      if (getIsPropertyInfoEnabledDetails() && !isPropertyInfoCovered()) {
        redirectToStep(PROPERTY_INFO.KEY);
      } else {
        questionnaireSubmit(floodDetails.currentQuestion);
      }
    } else if (
      type === AUTO &&
      dale_config.is_verisk_prefill_available &&
      !driversList.length &&
      !isApplicantDataRequired()
    ) {
      saveAutoQuoteDetails(APPLICANT);
      setIsVeriskLoading(true);
      setRedirectToTab({ targetTab, type });
    } else if (
      (type === PET && !isApplicantDataRequired()) ||
      ((type === LIFE || type === UMBRELLA) &&
        !isEmpty(home?.personal_details?.date_of_birth))
    ) {
      const lobKey = getApplicantLobKey('', [type]);
      saveApplicantDetails(lobKey);
      goBackToCertainTab(targetTab, type);
    } else if (
      type === CYBERSECURITY &&
      !isEmpty(home?.personal_details?.date_of_birth)
    ) {
      cyberSecurityQuotesLoader(true);
      fetchCyberSecurityQuotes();
      goBackToCertainTab(targetTab, type);
    } else if (type === HOME && !isEmpty(home?.productType)) {
      if (getIsPropertyInfoEnabledDetails()) {
        redirectToStep(PROPERTY_INFO.KEY);
      } else {
        setHomeState({
          hippoQuoteLoaded: false,
          ezlynxQuotesLoaded: false,
          quoteList: [],
          quoteListError: [],
          upcomingCarrierList: [],
        });
        saveApplicantDetails(INSURANCE_PRODUCTS[0]);
        goBackToCertainTab(targetTab, type);
      }
    } else if (type === QUAKE && !isEmpty(home?.productType)) {
      fetchQuakeQuoteDetails();
      goBackToCertainTab(targetTab, type);
    } else if (
      getIsPropertyInfoEnabledDetails() &&
      Object.keys(appointments).includes(FLOOD) &&
      type === FLOOD &&
      !isEmpty(home?.productType) &&
      !isPropertyInfoCovered()
    ) {
      redirectToStep(PROPERTY_INFO.KEY);
    } else {
      goBackToCertainTab(targetTab, type);
    }
  };

  const incompleteQuoteText = (type: string) => {
    if (type === AUTO && isVeriskLoading) {
      return drawLoading();
    } else {
      return (
        <>
          <h1>Incomplete Quote</h1>
          <p className='clickhere-text'>Please Click Here To Finish</p>
        </>
      );
    }
  };

  const drawIncompleteQuote = (targetTab: number, type: any) => {
    const boundedPolicy: any = showAmsButtonAsPerLob();
    const isDisabledIncompleteQuoteBtn: boolean =
      (!isEmpty(type) &&
        !boundedPolicy?.[type?.toLowerCase()] &&
        !excludeAmsForLobs.includes(type?.toLowerCase())) ||
      false;
    return (
      <Grid container spacing={2} className='mt-3'>
        <Grid item xs={12}>
          <Card className='rates-block incomplete-quote-card' elevation={0}>
            <CardContent className='padding-left-0'>
              <div className='row m-0 incomplete-quote-wrap'>
                <Grid item xs={12} sm={4} className='padding-left-0'>
                  <div className='insurence-logo-div'>
                    <img
                      src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type]]}
                      alt='business'
                      className='img-fluid mx-auto d-flex'
                    />
                    <Typography className='product-label'>
                      {LOB_PRODUCTS_MAPPING[type.toLowerCase()]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div
                    className={`d-flex w-100 justify-content-center align-items-center flex-column incomplete-quote ${
                      !!isDisabledIncompleteQuoteBtn ? 'disabled-imcomplete-quote' : ''
                    }`}
                    onClick={() => {
                      if (!isDisabledIncompleteQuoteBtn) {
                        redirectToCertainTab(targetTab, type);
                      }
                    }}
                  >
                    {incompleteQuoteText(type)}
                  </div>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const drawNoQuoteAvailable = (type: any) => {
    return (
      <Grid container spacing={2} className='mt-3'>
        <Grid item xs={12}>
          <Card className='rates-block incomplete-quote-card' elevation={0}>
            <CardContent className='padding-left-0'>
              <div className='row m-0 incomplete-quote-wrap'>
                <Grid item xs={12} sm={3} className='padding-left-0'>
                  <div className='insurence-logo-div'>
                    <img
                      src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type]]}
                      alt='business'
                      className='img-fluid mx-auto d-flex'
                    />
                    <Typography className='product-label'>
                      {LOB_PRODUCTS_MAPPING[type.toLowerCase()]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <div className='d-flex w-100 justify-content-center align-items-center flex-column incomplete-quote'>
                    <h1 className='cursor-default'>No Quotes Available</h1>
                  </div>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const drawLoading = () => {
    return (
      <div className='w-100 mt-4 d-flex justify-content-center'>
        <img src={getLoaderImage(businessOption)} alt='loader' />
      </div>
    );
  };

  const drawCarouselLoading = (type: any) => {
    return (
      <Grid container spacing={2} className='mt-3'>
        <Grid item xs={12}>
          <Card className='rates-block quote-loader-card' elevation={0}>
            <CardContent className='padding-left-0'>
              <div className='row m-0 quote-loader-wrap'>
                <Grid item xs={12} sm={3}>
                  <div className='insurence-logo-div'>
                    <img
                      src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type]]}
                      alt='business'
                      className='img-fluid mx-auto d-flex'
                    />
                    <Typography className='product-label'>
                      {LOB_PRODUCTS_MAPPING[type.toLowerCase()]}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <div className='loader-div'>
                    <img src={getLoaderImage(type)} alt='loader' />
                  </div>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const compareBusinessType = (currentQuoteTab: any = businessOption) => {
    let loading = false;
    let quoteList: Array<any> = [];
    const displayWithErrors = pconfig?.dale_config?.display_errors_on_ui;
    let isRefereshButtonEnabled: boolean =
      (!!carrierTimeoutMessageEnabled && showApplicationEdit) || false;
    const quoteDetails: any = getQuoteAndErrQuoteCount(currentQuoteTab);
    const isQuotePresentForLob: boolean =
      quoteDetails?.quoteCount || quoteDetails?.errorCount || false;
    switch (currentQuoteTab) {
      case HOME:
        loading = !hippoQuoteLoaded || !ezlynxQuotesLoaded;
        quoteList = displayWithErrors ? [...homeQuoteList, ...homeErrorQuoteList] : homeQuoteList;
        if (!isEmpty(home?.quotingCarriers) && isRefereshButtonEnabled && isQuotePresentForLob) {
          quoteList = getMappedQuotingCarriers(
            home.quotingCarriers,
            homeQuoteList,
            homeErrorQuoteList,
            displayWithErrors
          );
        }
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          home?.upcomingCarrierList,
          loading,
          home?.quoteStatus,
        );
        break;
      case FLOOD:
        loading = submitLoader;
        currentQuoteTab = INSURANCE_BUSINESS_TYPES.FLOOD;
        quoteList =
          !submitLoader && displayWithErrors
            ? [...floodQuoteList, ...floodQuoteErrorList]
            : floodQuoteList;
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          floodDetails?.upcomingCarrierList,
          loading,
          floodDetails?.quoteStatus,
        );
        break;
      case AUTO:
        loading = quotesLoading;
        quoteList = displayWithErrors ? [...autoQuoteList, ...quoteErrorList] : autoQuoteList;
        if (!isEmpty(quote?.quotingCarriers) && isRefereshButtonEnabled && isQuotePresentForLob) {
          quoteList = getMappedQuotingCarriers(
            quote.quotingCarriers,
            autoQuoteList,
            quoteErrorList,
            displayWithErrors
          );
        }
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          quote?.upcomingCarrierList,
          loading,
          quote?.quoteStatus,
        );
        break;
      case QUAKE:
        loading = quakeDetails?.quakeSubmitLoader;
        quoteList = quakeDetails?.quoteList;
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          quakeDetails?.upcomingCarrierList,
          loading,
          quakeDetails?.quoteStatus,
        );
        break;
      case PET:
        loading = petDetails?.quoteListLoader || petDetails?.petBreedsDetailsLoader;
        quoteList = petDetails?.quoteList;
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          petDetails?.upcomingCarrierList,
          loading,
          petDetails?.quoteStatus,
        );
        break;
      case HOMEWARRANTY:
        loading = homeWarrantyDetails?.quoteListLoader;
        quoteList = homeWarrantyDetails?.quoteList;
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          homeWarrantyDetails?.upcomingCarrierList,
          loading,
          homeWarrantyDetails?.quoteStatus,
        );
        break;
      case CYBERSECURITY:
        let cyberSecurityQuoteList: Array<any> = !isEmpty(cyberSecurityDetails?.quoteList)
          ? [...cyberSecurityDetails.quoteList]
          : [];
        let cyberSecurityQuoteErrorList: Array<any> = !isEmpty(cyberSecurityDetails?.quoteErrorList)
          ? [...cyberSecurityDetails.quoteErrorList]
          : [];
        loading = cyberSecurityDetails?.quoteListLoader;
        quoteList = displayWithErrors
          ? [...cyberSecurityQuoteList, ...cyberSecurityQuoteErrorList]
          : cyberSecurityDetails?.quoteList;
        if (
          !isEmpty(cyberSecurityDetails?.quotingCarriers) &&
          isRefereshButtonEnabled &&
          isQuotePresentForLob
        ) {
          quoteList = getMappedQuotingCarriers(
            cyberSecurityDetails.quotingCarriers,
            cyberSecurityQuoteList,
            cyberSecurityQuoteErrorList,
            displayWithErrors
          );
        }
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          cyberSecurityDetails?.upcomingCarrierList,
          loading,
          cyberSecurityDetails?.quoteStatus,
        );
        break;
      case LIFE:
        loading = lifeDetails?.quoteListLoader;
        quoteList = displayWithErrors
          ? [...lifeDetails?.quoteList, ...lifeDetails?.quoteErrorList]
          : lifeDetails?.quoteList;
        if (
          !isEmpty(lifeDetails?.quotingCarriers) &&
          isRefereshButtonEnabled &&
          isQuotePresentForLob
        ) {
          quoteList = getMappedQuotingCarriers(
            lifeDetails.quotingCarriers,
            lifeDetails?.quoteList,
            lifeDetails?.quoteErrorList,
            displayWithErrors
          );
        }
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          lifeDetails?.upcomingCarrierList,
          loading,
          lifeDetails?.quoteStatus,
        );
        break;
      case UMBRELLA:
        loading = umbrellaDetails?.quoteListLoader;
        quoteList = displayWithErrors
          ? [...umbrellaDetails?.quoteList, ...umbrellaDetails?.quoteErrorList]
          : umbrellaDetails?.quoteList;
        if (
          !isEmpty(umbrellaDetails?.quotingCarriers) &&
          isRefereshButtonEnabled &&
          isQuotePresentForLob
        ) {
          quoteList = getMappedQuotingCarriers(
            umbrellaDetails.quotingCarriers,
            umbrellaDetails?.quoteList,
            umbrellaDetails?.quoteErrorList,
            displayWithErrors
          );
        }
        quoteList = getMappedUpComingSoonCarriersList(
          quoteList,
          umbrellaDetails?.upcomingCarrierList,
          loading,
          umbrellaDetails?.quoteStatus,
        );
        break;
    }
    return { loading, quoteList, currentQuoteTab, displayWithErrors };
  };

  const incompleteQuoteDrawer = (currentQuoteTab: string) => {
    if ([QUAKE, HOMEWARRANTY, CYBERSECURITY, HOME].includes(currentQuoteTab))
      return drawIncompleteQuote(0, currentQuoteTab);
    if ([PET, LIFE, UMBRELLA, AUTO].includes(currentQuoteTab))
      return drawIncompleteQuote(1, currentQuoteTab);
    if (currentQuoteTab === FLOOD) {
      return drawIncompleteQuote(isEmpty(home?.productType) ? 0 : 1, currentQuoteTab);
    }
    return null;
  };

  const drawBusinessOptionRates = () => {
    // todo: should use appointmnets from props
    let isFloodNoQuotesAvailable: boolean =
      (!detailsLoader &&
        isEmpty(annexPrefill) &&
        !isEmpty(home?.productType) &&
        !isEmpty(floodDetails?.herdId)) ||
      false;
    if (ratePageType === ratePageTypes.carousel) {
      return Object.keys(appointments).map(el => {
        let { loading, quoteList, currentQuoteTab, displayWithErrors } = compareBusinessType(el);
        quoteList = getSortedQuoteList(quoteList, currentQuoteTab);
        quoteList = sortAdmittedAndNonAdmittedCarrier(quoteList);
        let selectedQuoteIndex = 0;
        const carrierSelect =
          boundedPolicyDetails[el.toLowerCase()] || selectedCarriers[el.toLowerCase()];
        if (!isEmpty(carrierSelect) && !isEmpty(quoteList)) {
          const quoteIndex = getSelectedCarrierIndex(carrierSelect, quoteList);
          selectedQuoteIndex = quoteIndex > 0 ? quoteIndex : 0;
        }
        if (loading) return drawCarouselLoading(currentQuoteTab);
        let isNoQuotesAvailable: boolean =
          currentQuoteTab === FLOOD
            ? isFloodNoQuotesAvailable
            : isNoQuotesAvailableForLob(displayWithErrors, currentQuoteTab);
        if (isNoQuotesAvailable && !getLobDetails(currentQuoteTab, 'upcomingCarrierList'))
          return drawNoQuoteAvailable(currentQuoteTab);
        else if (!quoteList.length) return incompleteQuoteDrawer(currentQuoteTab);
        return (
          <QuoteList
            type={el}
            loading={loading}
            quoteList={quoteList}
            key={el}
            carrierSelected={selectedQuoteIndex}
            updateSelectedQuoteList={(q: any, checked: boolean, type: string) =>
              updateSelectedQuoteList(q, checked, type)
            }
            ratePageType={ratePageType}
            ratePageTypes={ratePageTypes}
            getLoaderImage={getLoaderImage}
          />
        );
      });
    } else if (ratePageType === ratePageTypes.navigation) {
      let { loading, quoteList, currentQuoteTab, displayWithErrors } = compareBusinessType();
      quoteList = getSortedQuoteList(quoteList, currentQuoteTab);
      quoteList = sortAdmittedAndNonAdmittedCarrier(quoteList);
      if ([HOME, AUTO, FLOOD].includes(currentQuoteTab) && !isEmpty(quoteList)) {
        const quotesDraw = !displayWithErrors
          ? !!autoQuoteList.length || !!homeQuoteList.length || !!floodQuoteList.length
          : true;
        if (quotesDraw) {
          const list: Array<any> = [];
          list.unshift(drawRatesAsLoaded(quoteList, loading));
          if (loading) {
            list.push(drawLoading());
          }
          return list;
        }
      }
      if (loading) return drawLoading();
      let isNoQuotesAvailable: boolean =
          currentQuoteTab === FLOOD
            ? isFloodNoQuotesAvailable
            : isNoQuotesAvailableForLob(displayWithErrors, currentQuoteTab);
      if (isNoQuotesAvailable && !getLobDetails(currentQuoteTab, 'upcomingCarrierList'))
        return drawNoQuoteAvailable(currentQuoteTab);
      else if (!quoteList.length) return incompleteQuoteDrawer(currentQuoteTab);
      return quoteList.map((carrier, index) => (
        <QuoteList
          type={businessOption}
          loading={loading}
          quoteList={quoteList}
          carrierSelected={index}
          carrier={carrier}
          key={`_${carrier.carrier_id}${index}`}
          updateSelectedQuoteList={(
            q: any,
            checked: boolean,
            type: string,
            selectedPremiumType?: any,
            term?: any
          ) => updateSelectedQuoteList(q, checked, type, selectedPremiumType, term)}
          selectedQuoteList={
            sessionStorage.selectedQuoteList ? JSON.parse(sessionStorage.selectedQuoteList) : []
          }
          ratePageType={ratePageType}
          ratePageTypes={ratePageTypes}
        />
      ));
    }
  };

  const drawSelectedQuoteOptionInLabel = (type: string, quote: any) => {
    let selectedPremium = getAnnualPremiumValue(quote);
    let annualPremiumValue: any = parseFloat(selectedPremium?.price) * selectedPremium?.term;
    return (
      <Grid
        key={`${type}_${quote.carrier_id}`}
        container
        spacing={0}
        className='mt-1 mb-1 d-flex justify-content-center align-items-center'
      >
        <Grid item xs={5}>
          <Typography className='d-flex'>
            <img
              className='img-fluid icon'
              alt='Button Icon'
              src={ThemesImages[themeType][BUSINESS_TYPE_ICON_MAPPING[type]]}
            />
            <span className='icon-label'>
              <img src={get(quote, 'logo_url', '')} alt='logo' className='img-fluid logo-img' />
            </span>
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <span className='icon-label'>------</span>
        </Grid>
        <Grid item xs={3}>
          <span className='icon-label text-right'>
            {!!annualPremiumValue ? formatNumberToCurrency(annualPremiumValue) : DOLLAR_ZERO}
          </span>
        </Grid>
      </Grid>
    );
  };

  const drawSelectedQuotesLabel = () => {
    const selectedQuoteList = sessionStorage.selectedQuoteList
      ? JSON.parse(sessionStorage.selectedQuoteList)
      : [];
    return (
      <Grid className='annual-price-right-section  mt-4' container spacing={2}>
        <Grid item xs={12}>
          <Typography className='text-label text-center'>{ESTIMATED_ANNUAL_PRICE}</Typography>
        </Grid>
        <Grid item xs={12} className='price-green-div'>
          <Typography className='price-div-label text-center'>{annualTotalPrice}</Typography>
        </Grid>
        <Grid item xs={12} className='product-total-list'>
          {map(selectedQuoteList, (sq: any) => {
            return sq?.quote?.price ? drawSelectedQuoteOptionInLabel(sq.type, sq.quote) : null;
          })}
        </Grid>
      </Grid>
    );
  };

  const filterByIncluded = (list: Array<any>) => {
    return filter(list, item => item.isIncluded);
  };

  const filterVehicleList = (list: Array<any>) => {
    return filter(list, item => item.isIncluded && item.own_lease);
  };

  const includedDriversList = filterByIncluded(driversList);
  const includedVehiclesList = filterVehicleList(vehiclesList);

  const getAutoAMSSuccessModal = () => {
    return AMSSuccess ? (
      <AMSSuccessModal
        open={AMSSuccess}
        closeModal={() => {
          setAMSDetailsSuccess(false);
          showAMSModal(false);
          AMSModalCloseHandler();
        }}
      />
    ) : (
      ''
    );
  };

  const validQuotesPresent = () => {
    let selectedLob: string = !isEmpty(common?.selectedBusinessOption)
      ? common?.selectedBusinessOption?.toLowerCase()
      : '';
    let showAMSForNoQuotes: any = showAmsButtonAsPerLob();
    let lobList: any = getLineOfBusiness();
    if (
      !!ratePageType &&
      !!ratePageTypes?.navigation &&
      ratePageType === ratePageTypes.navigation
    ) {
      return !isEmpty(selectedLob) && !!showAMSForNoQuotes[selectedLob] && isAmsEnabled
        ? true
        : false;
    } else {
      if (
        !isEmpty(lobList) &&
        ((!!carouselLobData && carouselLobData.toLowerCase() !== lobList[0].toLowerCase()) ||
          isEmpty(carouselLobData))
      ) {
        setCarouselLobData(lobList[0].toLowerCase());
      }
      return isAmsEnabled &&
        !isEmpty(lobList) &&
        !excludeAmsForLobs.includes(businessOption.toLowerCase())
        ? true
        : false;
    }
  };

  const getAutoConfirmationAMSModal = () => {
    return showConfirmationDialog ? (
      <ConfirmationModal
        open={showConfirmationDialog}
        closehandler={policyConfirmationCloseHandler}
        heading={PAGES_TEXT.AMSPolicyBoundModalTitle}
        message={PAGES_TEXT.AMSPolicyBoundModalContent}
        contentValue=''
      />
    ) : (
      ''
    );
  };

  const AMSModalCloseHandler = () => {
    showAMSModal(false);
  };

  const policyConfirmationCloseHandler = (isPolicyBound: boolean) => {
    if (isPolicyBound) {
      showAMSModal(true);
    }
    setShowConfirmationDialog(false);
  };

  const editApplicantInfo = () => {
    setShowGetQuoteButton(true);
    onPrevTab(0);
    if (isHeapAnalyticsEnabled) {
      window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_EDIT_QUOTE, {
        [HEAP_LABELS?.EDIT_TYPE]: startCase(APPLICANT),
        [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
      });
    }
  };

  const editVehicleInfo = (isAdd: boolean) => {
    setSelectedBusinessOption(AUTO);
    if (!isAdd) {
      setTab(AUTO);
      setShowGetQuoteButton(true);
      if (isHeapAnalyticsEnabled) {
        window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_EDIT_QUOTE, {
          [HEAP_LABELS?.EDIT_TYPE]: startCase(DEFAULT_PAGE_TRACKING?.auto),
          [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
        });
      }
    } else {
      setTab(AUTO, true);
    }
  };

  const editCoverageInfo = () => {
    if (isHeapAnalyticsEnabled) {
      window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_EDIT_QUOTE, {
        [HEAP_LABELS?.EDIT_TYPE]: startCase(COVERAGES),
        [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
      });
    }
    setSelectedBusinessOption(AUTO);
    setShowGetQuoteButton(true);
    setTab(COVERAGES?.toUpperCase());
  };

  const editDriverInfo = (isAdd: boolean) => {
    setSelectedBusinessOption(AUTO);
    if (isHeapAnalyticsEnabled) {
      if (!isAdd) {
        window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_EDIT_QUOTE, {
          [HEAP_LABELS?.EDIT_TYPE]: startCase(DRIVER),
          [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
        });
      } else {
        window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.ADD_DRIVER);
      }
    }
    if (!isAdd) {
      setShowGetQuoteButton(true);
      setTab(DRIVER?.toUpperCase());
    } else {
      setTab(DRIVER?.toUpperCase(), true);
    }
  };

  const addEditPetInfo = (isAdd: boolean) => {
    if (isHeapAnalyticsEnabled) {
      if (!isAdd) {
        window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_EDIT_QUOTE, {
          [HEAP_LABELS?.EDIT_TYPE]: startCase(DEFAULT_PAGE_TRACKING?.pet),
          [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
        });
      } else {
        window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.ADD_PET);
      }
    }
    if (!isAdd) {
      setShowGetQuoteButton(true);
      setTab(PET);
    } else {
      setTab(PET, true);
    }
  };

  const editLifeInfo = () => {
    if (isHeapAnalyticsEnabled) {
      window?.heap?.track(QUOTING_STEPS_PAGES_NAME?.RATES_EDIT_QUOTE, {
        [HEAP_LABELS?.EDIT_TYPE]: startCase(INSURANCE_PRODUCTS[6]),
        [HEAP_LABELS?.PRODUCT_COUNT]: Object.keys(appointments)?.length || 0,
      });
    }
    setSelectedBusinessOption(LIFE);
    setShowGetQuoteButton(true);
    setTab(LIFE);
  };

  const showAddProductButton = () => {
    const { adhocBoundedLobs } = common;
    let isShowAddProductButton: boolean = false;
    const remainingLobs = insuranceProducts.filter(
      (lob: any) => lob && !Object.keys(appointments).includes(lob?.toUpperCase())
    );
    if (!isEmpty(remainingLobs)) {
      let adhocBoundedLobsList = adhocBoundedLobs.map((lobCode: string) => {
        const selectedLobData: any = Object.values(adhocLobMapping).filter(lobData => {
          return lobData.code.toLowerCase() === lobCode.toLowerCase();
        });
        if (!isEmpty(selectedLobData[0])) {
          return selectedLobData[0].key;
        } else {
          return lobCode.toLowerCase();
        }
      });
      if (!isEmpty(adhocBoundedLobsList)) {
        let isAllLobBounded = true;
        remainingLobs.forEach((lob: string) => {
          if (!adhocBoundedLobsList.includes(lob)) {
            isAllLobBounded = false;
          }
        });
        isShowAddProductButton = !isAllLobBounded;
      } else {
        isShowAddProductButton = true;
      }
    }
    return isShowAddProductButton;
  };

  const drawAutoInfo = () => (
    <>
      <Grid className='vehicles-right-section mt-4' container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className='vehicles-label mt-2 mb-3'>
            Vehicles
            {showApplicationEdit && !isAutoPolicyBounded && !lobRefreshLoader && (
              <EditOutlinedIcon
                className='text-right edit-icon'
                onClick={() => editVehicleInfo(false)}
              />
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className='vehicles-list mb-2 pb-0'>
              {includedVehiclesList.map((vehicle: any, index: number) => (
                <div key={vehicle.vuid} className='vehicle-item'>
                  <Typography className='vehicle-item__info'>{``}</Typography>
                  <Typography className='vehicle-item__info'>
                    {`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                  </Typography>
                  <Typography className='normal-font'>{`${vehicle.own_lease}`}</Typography>
                  {index + 1 !== includedVehiclesList.length && <Divider className='mt-2 mb-2' />}
                </div>
              ))}
              {showApplicationEdit &&
                !lobRefreshLoader &&
                !isAutoPolicyBounded &&
                vehiclesList.length < config.maxVehicleAddLimit && (
                  <>
                    <Divider className='mt-2 mb-2' />
                    <Typography className='add' onClick={() => editVehicleInfo(true)}>
                      <AddIcon className='add-circle-icon' />
                      <b className='add-label'>Add Vehicle</b>
                    </Typography>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className='coverages-right-section mt-4' container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className='coverages-label mt-2 mb-3'>
            Coverages
            {showApplicationEdit && !isAutoPolicyBounded && !lobRefreshLoader && (
              <EditOutlinedIcon
                className='text-right edit-icon'
                onClick={() => editCoverageInfo()}
              />
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className='applicant-list mb-2'>
              <Typography>Body Injury</Typography>
              <Typography className='normal-font'>{`${injury_limits}`}</Typography>
              <Divider className='mt-2 mb-2' />
              <Typography>Property Damage</Typography>
              <Typography className='normal-font'>
                {!!property_damage ? formatNumberToCurrency(property_damage) : DOLLAR_ZERO}
              </Typography>
              <Divider className='mt-2 mb-2' />
              <Typography>Uninsured/Underinsured Motorist</Typography>
              <Typography className='normal-font'>{motorist}</Typography>
              <Divider className='mt-2 mb-2' />
              <Typography>Medical Payments</Typography>
              <Typography className='normal-font'>
                {isNumberCheck.test(medical_payments)
                  ? !!medical_payments
                    ? formatNumberToCurrency(medical_payments)
                    : DOLLAR_ZERO
                  : NA_VALUE}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className='drivers-right-section mt-4' container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className='drivers-label mt-2 mb-3'>
            Drivers
            {showApplicationEdit && !isAutoPolicyBounded && !lobRefreshLoader && (
              <EditOutlinedIcon
                className='text-right edit-icon'
                onClick={() => editDriverInfo(false)}
              />
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className='drivers-list mb-2 pb-0'>
              {includedDriversList.map((driver: any, index: number) => (
                <div key={driver.vuid} className='driver-item'>
                  <Typography className='driver-item__info fs-exclude'>
                    {`${driver.first_name} ${driver.last_name}`}
                  </Typography>
                  <Typography className='normal-font capitalize'>
                    <span className='capitalize'>{`${driver.gender}`}</span>
                    {`, ${driver.marital_status}`}
                  </Typography>
                  {index + 1 !== includedDriversList.length && <Divider className='mt-2 mb-2' />}
                </div>
              ))}
              {showApplicationEdit &&
                !lobRefreshLoader &&
                !isAutoPolicyBounded &&
                driversList.length < config.maxDriverAddLimit && (
                  <>
                    <Divider className='mt-2 mb-2' />
                    <Typography className='add' onClick={() => editDriverInfo(true)}>
                      <AddIcon className='add-circle-icon' />
                      <b className='add-label'>Add Driver</b>
                    </Typography>
                  </>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const petInfo = () => {
    const { petList } = petDetails;
    let isShowAddPet =
      showApplicationEdit &&
      !isPetPolicyBounded &&
      !isEmpty(petList) &&
      petList?.length < MAX_PET_BREEDS_INFO &&
      !isEmpty(petDetails?.quoteList) &&
      (!petDetails?.quoteList[0]?.quoteError ||
        (!!petDetails?.quoteList[0]?.quoteError &&
          !!petDetails?.quoteList[0]?.isPetDetailsEditable))
        ? true
        : false;
    return (
      <Grid className='drivers-right-section mt-4' container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className='drivers-label mt-2 mb-3'>
            {PET_INFO.PET_DETAILS}
            {showApplicationEdit &&
              !lobRefreshLoader &&
              !isPetPolicyBounded &&
              !isEmpty(petDetails?.petList) &&
              !isEmpty(petDetails?.quoteList) &&
              (!petDetails?.quoteList[0]?.quoteError ||
                (!!petDetails?.quoteList[0]?.quoteError &&
                  !!petDetails?.quoteList[0]?.isPetDetailsEditable)) && (
                <EditOutlinedIcon
                  className='text-right edit-icon'
                  onClick={() => addEditPetInfo(false)}
                />
              )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className='pet-list'>
              {!isEmpty(petList) &&
                petList.map((item: any, index: number) => (
                  <div key={driver.vuid} className='pet-item'>
                    <Typography className='pet-item__info'>{item.petName}</Typography>
                    <Typography className='normal-font capitalize'>
                      <span className='capitalize'>{`${item.petGender}`}</span>
                      {`, ${item.petType}`}
                    </Typography>
                    {index + 1 !== petList.length && <Divider className='mt-2 mb-2' />}
                  </div>
                ))}
              {isShowAddPet && !lobRefreshLoader ? (
                <>
                  <Divider className='mt-2 mb-2' />
                  <Typography className='add' onClick={() => addEditPetInfo(true)}>
                    <AddIcon className='add-circle-icon' />
                    <b className='add-label'>{PET_INFO.ADD_PET}</b>
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getHeight = (height: any) => {
    return `${height?.ft}'${height?.in}"`;
  };

  const getTobaccoUseValue = (tobaccoUseKey: any) => {
    const tobaccoDetail = TOBACCO_USE_DATA.find(tobacco => tobacco.value === tobaccoUseKey);
    return tobaccoDetail?.label;
  };

  const lifeInfo = () => {
    const { lifeInfo } = lifeDetails;
    return (
      <Grid className='drivers-right-section mt-4' container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography className='drivers-label mt-2 mb-3'>
            {LIFE_INFO.LIFE_DETAILS}
            {showApplicationEdit && !lobRefreshLoader && (
              <EditOutlinedIcon className='text-right edit-icon' onClick={() => editLifeInfo()} />
            )}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className='pet-list'>
              <div className='pet-item'>
                <Typography className='pet-item__info'>
                  {startCase(LIFE_INFO.GENDER_KEY)}
                </Typography>
                <Typography className='normal-font capitalize'>
                  <span className='capitalize'>{lifeInfo?.gender}</span>
                </Typography>
                <Divider className='mt-2 mb-2' />
                <Typography className='pet-item__info'>{LIFE_INFO.HEIGHT_LABEL}</Typography>
                <Typography className='normal-font capitalize'>
                  <span className='capitalize'>{getHeight(lifeInfo?.height)}</span>
                </Typography>
                <Divider className='mt-2 mb-2' />
                <Typography className='pet-item__info'>{LIFE_INFO.WEIGHT_LABEL}</Typography>
                <Typography className='normal-font capitalize'>
                  <span className='capitalize'>{`${lifeInfo?.weight_lbs} lbs`}</span>
                </Typography>
                <Divider className='mt-2 mb-2' />
                <Typography className='pet-item__info'>
                  {LIFE_INFO.HOUSEHOLD_INCOME_LABEL}
                </Typography>
                <Typography className='normal-font capitalize'>
                  <span className='capitalize'>
                    {formatNumberToCurrency(lifeInfo?.household_income)}
                  </span>
                </Typography>
                <Divider className='mt-2 mb-2' />
                <Typography className='pet-item__info'>{LIFE_INFO.TOBACCO_USE_LABEL}</Typography>
                <Typography className='normal-font capitalize'>
                  <span className='capitalize'>{getTobaccoUseValue(lifeInfo?.tobacco_use)}</span>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const reloadViewQuote = (herdId: any) => {
    let isHerdIdExist =
      quote?.herdId ||
      home?.herd_id ||
      petDetails?.herdId ||
      homeWarrantyDetails?.herdId ||
      cyberSecurityDetails?.herdId ||
      lifeDetails?.herdId ||
      floodDetails?.herdId ||
      quakeDetails?.herdId ||
      umbrellaDetails?.herdId;
    if (
      herdId &&
      isHerdIdExist &&
      quote?.herdId !== herdId &&
      home?.herd_id !== herdId &&
      petDetails?.herdId !== herdId &&
      homeWarrantyDetails?.herdId !== herdId &&
      cyberSecurityDetails?.herdId !== herdId &&
      lifeDetails?.herdId !== herdId &&
      floodDetails?.herdId !== herdId &&
      quakeDetails?.herdId !== herdId &&
      umbrellaDetails?.herdId !== herdId
    ) {
      return true;
    }
    return false;
  };

  const quotePollingHandlerOnRefreshClick = () => {
    const selectedBusinessOption = common?.selectedBusinessOption?.toLowerCase() || '';
    if (
      isEmpty(selectedBusinessOption) ||
      lobRefreshLoader ||
      getIsLobQuotesLoading(selectedBusinessOption)
    ) {
      return;
    }
    if (selectedBusinessOption === INSURANCE_PRODUCTS[0]) {
      refreshHomeQuotes();
    } else if (selectedBusinessOption === INSURANCE_PRODUCTS[1]) {
      refreshAutoQuotes();
    } else if (selectedBusinessOption === INSURANCE_PRODUCTS[6]) {
      refreshLifeQuotes();
    } else if (selectedBusinessOption === INSURANCE_PRODUCTS[7]) {
      refreshCyberSecurityQuotes();
    } else if (selectedBusinessOption === INSURANCE_PRODUCTS[8]) {
      refreshUmbrellaQuotes();
    } else if (selectedBusinessOption === INSURANCE_PRODUCTS[2]) {
      refreshFloodQuotes();
    }
  };

  useEffect(() => {
    let areAllQuotesLoaded = true;
    Object.keys(appointments).forEach(el => {
      switch (el) {
        case HOME:
          areAllQuotesLoaded =
            areAllQuotesLoaded &&
            ((hippoQuoteLoaded && ezlynxQuotesLoaded && !isEmpty(home?.herd_id)) ||
              isEmpty(home?.productType));
          break;
        case FLOOD:
          areAllQuotesLoaded = areAllQuotesLoaded && !submitLoader;
          break;
        case AUTO:
          areAllQuotesLoaded = areAllQuotesLoaded && !quotesLoading;
          break;
        case QUAKE:
          areAllQuotesLoaded = areAllQuotesLoaded && !quakeDetails?.quakeSubmitLoader;
          break;
        case PET:
          areAllQuotesLoaded = areAllQuotesLoaded && !petDetails?.quoteListLoader;
          break;
        case HOMEWARRANTY:
          areAllQuotesLoaded = areAllQuotesLoaded && !homeWarrantyDetails?.quoteListLoader;
          break;
        case CYBERSECURITY:
          areAllQuotesLoaded = areAllQuotesLoaded && !cyberSecurityDetails?.quoteListLoader;
          break;
        case LIFE:
          areAllQuotesLoaded = areAllQuotesLoaded && !lifeDetails?.quoteListLoader;
          break;
        case UMBRELLA:
          areAllQuotesLoaded = areAllQuotesLoaded && !umbrellaDetails?.quoteListLoader;
          break;
      }
    });
    setShowApplicationEdit(areAllQuotesLoaded);
    // eslint-disable-next-line
  }, [
    hippoQuoteLoaded,
    ezlynxQuotesLoaded,
    submitLoader,
    quotesLoading,
    quakeDetails.quakeSubmitLoader,
    petDetails.quoteListLoader,
    homeWarrantyDetails.quoteListLoader,
    lifeDetails.quoteListLoader,
    cyberSecurityDetails.quoteListLoader,
    umbrellaDetails.quoteListLoader,
  ]);
  const [openViewBoundQuote, setViewBoundQuote] = useState(false);
  window.onbeforeunload = function () {
    const { herdId } = getHerdIdFromUrl();
    if (showViewQuoteCheck() && !reloadViewQuote(herdId)) {
      clearQuoteLobDetails();
      clearQuoteDetails();
      updateRequoteProducts([]);
    } else if (
      (tabsCount &&
        tabsCount === tabValue &&
        (document.location.href.includes(QUESTIONNAIRE_VIEW_PATH_ROUTE) ||
          (isEnabledPRSv2Flow() &&
            document.location.href.includes(QUOTE_APPLICANTION_VIEW_ROUTE)))) ||
      reloadViewQuote(herdId)
    ) {
      setRedirectToViewQuoteFlag(true);
    }
  };
  return (
    <div className='rates-wrapper mt-3'>
      <div className='row'>
        {ratePageType === ratePageTypes.navigation && <>{selectBusinessOption()}</>}
        {validQuotesPresent() && (
          <div className={'col-md-12 col-lg-3 d-flex justify-content-end '}>
            <Button
              variant='outlined'
              className={`w-100 btn-green h-63 ${
                ratePageType === ratePageTypes.navigation ? 'ams-button__navigation' : ''
              }`}
              style={{ marginRight: '0px' }}
              onClick={() => setShowConfirmationDialog(true)}
            >
              <img alt='ams button' className='ams-button-text' src={AMSButtonImg} />
            </Button>
          </div>
        )}
        {getAutoConfirmationAMSModal()}
        {getAutoAMSSuccessModal()}
        {openAMSModal && (
          <AMSDetailsModal
            open={openAMSModal}
            type={
              ratePageType === ratePageTypes.navigation
                ? !!businessOption
                  ? businessOption.toLowerCase()
                  : carouselLobData
                : carouselLobData
            }
            quoteList={autoQuoteList}
            closehandler={AMSModalCloseHandler}
            isQuote={!showAMSModalForAdhoc360}
            isQuoteAdhocBind={showAMSModalForAdhoc360}
            ratePageTypes={ratePageType}
          />
        )}
      </div>
      <div className='row mt-2'>
        <div className='col-lg-9 rates-left-section'>
          <AddProductsDetails
            isShowAddProductButton={showAddProductButton()}
            openModal={openModal}
            loader={!!lobRefreshLoader}
          />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-lg-9 mb-30 rates-left-section'>
          {drawBusinessOptionRates()}
          {lobRefreshLoader && drawLoading()}
          <Grid container spacing={2}>
            <Grid item xs={12} className='d-flex pl-0'>
              <div className='row'>
                <div className='col-lg-12' />
              </div>
            </Grid>
          </Grid>
          <Grid container className='d-none'>
            <Grid item xs={12}>
              <Button color='primary' variant='contained' className='more-product-button'>
                <AddIcon className='add-icon mr-2' />
                SEE (3) MORE RATES
              </Button>
            </Grid>
          </Grid>
          <Grid container className='btn-wrap-content'>
            <AddProductsDetails
              isShowAddProductButton={showAddProductButton()}
              openModal={openModal}
              loader={!!lobRefreshLoader}
            />
            <Grid item md={6} className='refresh-btn-wrap'>
              {!!carrierTimeoutMessageEnabled && (
                <Button
                  variant='outlined'
                  size='large'
                  className='mt-3 mb-3 refresh-btn'
                  onClick={() => {
                    quotePollingHandlerOnRefreshClick();
                  }}
                >
                  {REFRESH}
                  <RefreshIcon className='ml-2 refreshIcon' />
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        <div className='col-lg-3 rates-right-section'>
          {drawSelectedQuotesLabel()}
          {!!openViewBoundQuote && (
            <ViewAmsBoundData
              open={openViewBoundQuote}
              onClose={() => setViewBoundQuote(false)}
              lob={selectedBusinessOption}
            />
          )}
          {viewAmsBoundForNonPremiumCarrier()}
          <Grid className='annual-price-right-section applicant-wrap mt-4' container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography className='applicant-label mt-2 mb-3'>
                Applicant
                {showApplicationEdit && !lobRefreshLoader && (
                  <EditOutlinedIcon
                    className='text-right edit-icon'
                    onClick={() => editApplicantInfo()}
                  />
                )}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className='applicant-list fs-exclude mb-2'>
                  <Typography>Name</Typography>
                  <Typography className='normal-font'>{`${first_name} ${last_name}`}</Typography>
                  <Divider className='mt-2 mb-2' />
                  <Typography>Address</Typography>
                  <Typography className='normal-font'>{address}</Typography>
                  <Divider className='mt-2 mb-2' />
                  <Typography>Email Address</Typography>
                  <Typography className='normal-font'>{email}</Typography>
                  <Divider className='mt-2 mb-2' />
                  <Typography>Phone Number</Typography>
                  <Typography className='normal-font'>
                    {!isEmpty(phone_number) ? formatUSPhoneNumber(String(phone_number)) : '--'}
                  </Typography>
                  {!isEmpty(date_of_birth) ? (
                    <>
                      <Divider className='mt-2 mb-2' />
                      <Typography>Date of Birth</Typography>
                      <Typography className='normal-font'>{date_of_birth}</Typography>
                    </>
                  ) : (
                    ''
                  )}
                  {!isEmpty(common?.loanNumber) ? (
                    <>
                      <Divider className='mt-2 mb-2' />
                      <Typography>{LOAN_NUMBER_LABEL}</Typography>
                      <Typography className='normal-font'>{common?.loanNumber}</Typography>
                    </>
                  ) : (
                    ''
                  )}
                  {!isEmpty(common?.customId) ? (
                    <>
                      <Divider className='mt-2 mb-2' />
                      <Typography>{PARTNER_ID_LABEL}</Typography>
                      <Typography className='normal-font'>{common?.customId}</Typography>
                    </>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {appointments[AUTO] && (autoQuoteList.length || quoteErrorList.length)
            ? drawAutoInfo()
            : ''}
          {appointments[PET] && !isEmpty(petDetails?.petList) ? petInfo() : ''}
          {appointments[LIFE] && !isEmpty(lifeDetails?.lifeInfo) ? lifeInfo() : ''}
        </div>
      </div>
      <BridgeToCarrierModal />
      {showModal && (
        <OrganizationModal
          organization={''}
          selectOrganization={() => {}}
          products={products}
          selectProduct={selectProduct}
          showModal={showModal}
          closeModal={closeModal}
          submitQuote={submitQuote}
          addProduct
          veriskIsLoading={veriskIsLoading}
          isAddProductSaveApplicantLoading={applicant?.isAddProductSaveApplicantLoading}
          appointments={appointments}
          home={home}
          insuranceProductList={insuranceProducts}
          common={common}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  driver,
  quote,
  home,
  floodDetails,
  applicant,
  common,
  vehicle,
  insurance,
  auth,
  partner,
  quakeDetails,
  petDetails,
  homeWarrantyDetails,
  lifeDetails,
  cyberSecurityDetails,
  umbrellaDetails,
}: AppComponents.RatesStore) => {
  // todo: quote list should be renamed
  const {
    quotesLoaded,
    quotingDetails,
    quotesLoading,
    quoteErrorList,
    quoteList: autoQuoteList,
  } = quote;
  const {
    pconfig,
    hippoQuoteLoaded,
    ezlynxQuotesLoaded,
    quoteList: homeQuoteList,
    quoteListError: homeErrorQuoteList,
  } = home;
  const { motorist, injury_limits, property_damage, medical_payments, residency_status } =
    insurance;
  const { submitLoader, annexPrefill, quoteList: floodQuoteList, quoteErrorList: floodQuoteErrorList, detailsLoader } = floodDetails;
  const { producerDetails, storedRequestId, isProviderOneLogin, isProviderAms } = partner;
  const {
    AMSSuccess,
    openAMSModal,
    policyBounded,
    lineOfBusiness,
    selectedBusinessOption,
    showGetQuoteButton,
    newProducts,
    boundedPolicyDetails,
    selectedCarriers,
    selectedCarrierLoader,
    redirectToViewQuote,
    redirectionStep,
    lobRefreshLoader
  } = common;
  return {
    driver,
    quotesLoaded,
    quotingDetails,
    quotesLoading,
    quoteErrorList,
    autoQuoteList,
    submitLoader,
    hippoQuoteLoaded,
    ezlynxQuotesLoaded,
    homeQuoteList,
    floodQuoteList,
    applicant,
    vehicle,
    annexPrefill,
    pconfig,
    auth,
    producerDetails,
    AMSSuccess,
    openAMSModal,
    policyBounded,
    lineOfBusiness,
    motorist,
    injury_limits,
    property_damage,
    medical_payments,
    detailsLoader,
    common,
    home,
    quote,
    floodDetails,
    selectedBusinessOption,
    showGetQuoteButton,
    storedRequestId,
    newProducts,
    homeErrorQuoteList,
    isProviderOneLogin,
    quakeDetails,
    boundedPolicyDetails,
    selectedCarriers,
    selectedCarrierLoader,
    redirectToViewQuote,
    petDetails,
    residency_status,
    homeWarrantyDetails,
    redirectionStep,
    lifeDetails,
    cyberSecurityDetails,
    umbrellaDetails,
    isProviderAms,
    lobRefreshLoader,
    floodQuoteErrorList,
  };
};

const mapDispatchToProps = (dispatch: any): AppComponents.RatesDispatch =>
  bindActionCreators(
    {
      initAutoQuotes,
      stopPolling,
      initViewQuotes,
      setAMSDetails,
      showAMSModal,
      setAMSDetailsSuccess,
      addProduct,
      cleanHelperProduct,
      setRedirectToViewQuoteFlag,
      setShowGetQuoteButton,
      setSelectedBusinessOption,
      questionnaireSubmit,
      clearQuoteLobDetails,
      clearQuoteDetails,
      flushAppDetails,
      setLineOfBusiness,
      setHomeState,
      saveAutoQuoteDetails,
      setGoToRatesTabFromPolicy,
      setAdhocOrganizationDetails,
      saveSelectedCarrier,
      updateSelectedCarriers,
      saveApplicantDetails,
      updateSelectedProducts,
      storeEmptyLobErrorMessage,
      vinCleared,
      setVehicleList,
      vehicleSelectedForEdit,
      updateRequoteProducts,
      fetchPetQuotes,
      setLobPriority,
      setLobRefreshLoader,
      stopLifePolling,
      fetchLifeQuotes,
      cyberSecurityQuotesLoader,
      fetchCyberSecurityQuotes,
      fetchQuakeQuoteDetails,
      fetchUmbrellaQuotes,
      stopUmbrellaPolling,
      refreshHomeQuotes,
      refreshAutoQuotes,
      refreshCyberSecurityQuotes,
      refreshLifeQuotes,
      refreshUmbrellaQuotes,
      refreshFloodQuotes,
      setAddProductSaveApplicantLoader,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductRates);
