import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import queryString from 'query-string';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import Tooltip from '@material-ui/core/Tooltip';
import values from 'lodash/values';
import AMSButton from '../../components/ams-button';
import AMSDetailsModal from '../../components/ams-details-modal';
import AMSSuccessModal from '../../components/ams-success-modal';
import AutoIcon from '../../../assets/images/auto.svg';
import AutoIconFc from '../../../assets/images/auto-fc.svg';
import config from '../../../config/config';
import { FIRST_CONNECT_THEME } from '../../../constants';
import ConfirmationModal from '../confirmation-modal';
import {
  deleteVehicleDetails,
  driverInit,
  driverSelectedForEdit,
  initVehicle,
  setAMSDetailsSuccess,
  setEditVehicleFlag,
  setQuotesLoaded,
  showAMSModal,
  vehicleSelect,
} from '../../redux/actions';
import {
  checkPolicyBounded,
  formatNumberToCurrency,
  formatPhoneNumberWithBrackets,
} from '../../../utils';
import HomeIcon from '../../../assets/images/home.svg';
import HomeIconFc from '../../../assets/images/home-fc.svg';
import { isNumberCheck } from '../../auto/utils';
import person from '../../../assets/images/person.png';
import personFc from '../../../assets/images/person-fc.png';
import quotes from '../../../assets/images/quotes.svg';
import quotesFc from '../../../assets/images/quotes-fc.svg';
import {
  DOLLAR_ZERO,
  INSURANCE_PRODUCTS,
  PAGES_TEXT,
  UNSAVED_VEHICLE_MESSAGE,
} from '../../../constants';

import './auto-edit-sidebar.scss';

const CarButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? AutoIconFc : AutoIcon;
const QuotesButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? quotesFc : quotes;
const PersonButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? personFc : person;
const HomeButtonIcon = config.hippo.template_name === FIRST_CONNECT_THEME ? HomeIconFc : HomeIcon;

class EditSidebar extends React.PureComponent<
  AppComponents.EditSidebarProps,
  AppComponents.EditSidebarState
> {
  state: AppComponents.EditSidebarState = {
    viewQuote: false,
    vehicleDetailsMissingDialog: false,
    operationDetails: '',
    showConfirmationDialog: false,
  };

  componentDidMount() {
    const { viewQuote } = this.state;
    const { herd_id } = queryString.parse(this.props.location.search);
    if (!viewQuote && !isEmpty(herd_id)) {
      this.setState({ viewQuote: isEmpty(herd_id) ? false : true });
    }
  }

  onEditAction = (page: string) => {
    const {
      driver: { driversList },
      location,
      vehicle,
    } = this.props;
    if (!location.pathname.includes(`auto/${page}`) && !vehicle.vinLoader) {
      if (page === 'driver' && driversList.length > 1) {
        this.props.driverSelectedForEdit(driversList[0].duid);
      }
      this.updateDetails(page);
      this.props.setQuotesLoaded(false);
    }
  };

  updateDetails = (pageName: string) => {
    const {
      history,
      vehicle: { vehiclesList },
      driver: { driversList },
    } = this.props;
    if (pageName !== 'vehicle' && vehiclesList.length >= 1) {
      const deleteVehicle = find(vehiclesList, ['vehicleAdded', false]);
      const currentVehicle = find(vehiclesList, ['vehicleAdded', true]);
      const isBodyTypeEmpty = some(vehiclesList, ['body_type', '']);
      if ((isUndefined(deleteVehicle) && isBodyTypeEmpty) || deleteVehicle) {
        return this.setState({ vehicleDetailsMissingDialog: true, operationDetails: pageName });
      } else {
        currentVehicle && this.props.vehicleSelect(currentVehicle.vuid);
      }
    } else if (pageName === 'vehicle' && (vehiclesList.length > 1 || vehiclesList[0].viewQuote)) {
      this.props.vehicleSelect(vehiclesList[0].vuid);
    }
    if (pageName !== 'driver' && driversList.length >= 1) {
      this.props.driverSelectedForEdit(driversList[0].duid);
    }
    history.push(pageName);
    return true;
  };

  addAnotherVehicleAction = () => {
    const { history } = this.props;
    history.location.pathname.includes('/auto/vehicle') && this.props.setEditVehicleFlag(true);
    this.props.initVehicle();
    this.props.setQuotesLoaded(false);
    history.push('vehicle');
  };

  addAnotherDriverAction = () => {
    const {
      driver,
      vehicle: { vehiclesList, vinLoader },
      history,
    } = this.props;
    const deleteVehicle = find(vehiclesList, ['vehicleAdded', false]);
    const isBodyTypeEmpty = some(vehiclesList, ['body_type', '']);
    if (vinLoader) {
      return false;
    }
    if (vehiclesList.length >= 1 && deleteVehicle) {
      return this.setState({
        vehicleDetailsMissingDialog: true,
        operationDetails: 'addAnotherDriver',
      });
    } else {
      if (isBodyTypeEmpty) {
        return this.setState({
          vehicleDetailsMissingDialog: true,
          operationDetails: 'addAnotherDriver',
        });
      }
      this.props.driverInit({ clear: true, sidebarEdit: true, ...driver });
      this.props.setQuotesLoaded(false);
      history.push('driver');
      this.updateDetails('driver');
    }
    return true;
  };

  getAddDriverLimit = (): boolean => {
    const { driver } = this.props;
    const currentDriver = find(driver.driversList, ['duid', driver.duid]);
    if (currentDriver) {
      return driver.driversList.length < config.maxDriverLimit;
    } else {
      return false;
    }
  };

  vehicleDetailsModalCloseHandler = (isDiscard: boolean) => {
    const {
      history,
      driver,
      vehicle: { vehiclesList },
    } = this.props;
    if (isDiscard) {
      const deleteVehicle = find(vehiclesList, ['vehicleAdded', false]);
      const updatedVehicle = find(vehiclesList, { body_type: '', vehicleAdded: true });
      (deleteVehicle || updatedVehicle) &&
        this.props.deleteVehicleDetails({
          vuid: deleteVehicle ? deleteVehicle.vuid : updatedVehicle.vuid,
        });
      if (this.state.operationDetails !== 'addAnotherDriver') {
        history.push(this.state.operationDetails);
      } else {
        this.props.driverInit({ clear: true, ...driver });
        history.push('driver');
      }
    }
    this.setState({ operationDetails: '', vehicleDetailsMissingDialog: false });
  };

  showAddAnotherVehicleButton = (
    { vehiclesList, vehicleAdded, body_type, year, make, model }: any,
    quotesLoading: boolean
  ): boolean =>
    vehiclesList.length < config.maxVehicleLimit &&
    vehicleAdded &&
    !this.isBoundedQuotePresent() &&
    !values([year, make, model, body_type]).some(isEmpty) &&
    !quotesLoading;

  AMSModalCloseHandler = () => {
    this.props.showAMSModal(false);
  };

  isBoundedQuotePresent = () =>
    checkPolicyBounded(
      get(this.props, 'common.policyBounded', {}),
      this.props.common.lineOfBusiness
    );

  showAutoAMSButton = () => {
    const {
      quote: { quoteList, quotesLoading },
    } = this.props;
    return quoteList.length > 0 && !this.isBoundedQuotePresent() && !quotesLoading;
  };

  policyConfirmationCloseHandler = (isPolicyBound: boolean) => {
    if (isPolicyBound) {
      this.props.showAMSModal(true);
    }
    this.setState({
      showConfirmationDialog: false,
    });
  };

  getAutoConfirmationAMSModal = () =>
    this.state.showConfirmationDialog ? (
      <ConfirmationModal
        open={this.state.showConfirmationDialog}
        closehandler={this.policyConfirmationCloseHandler}
        heading={PAGES_TEXT.AMSPolicyBoundModalTitle}
        message={PAGES_TEXT.AMSPolicyBoundModalContent}
        contentValue=''
      />
    ) : (
      ''
    );

  showEditIcon = () =>
    !this.isBoundedQuotePresent() && !get(this.props, 'quote.quotesLoading', false);

  showAddAnotherDriverButton = () =>
    !this.isBoundedQuotePresent() &&
    this.getAddDriverLimit() &&
    !get(this.props, 'quote.quotesLoading', false);

  getAutoAMSSuccessModal = () =>
    this.props.common.AMSSuccess ? (
      <AMSSuccessModal
        open={this.props.common.AMSSuccess}
        closeModal={() => {
          this.props.setAMSDetailsSuccess(false);
        }}
      />
    ) : (
      ''
    );

  render() {
    const {
      driver: { driversList },
      vehicle: { vehiclesList },
      applicant: { address, personal_details },
      insurance: { injury_limits, motorist, property_damage, medical_payments },
      quote: { quotesLoading, quoteList },
      common: { completed_steps, openAMSModal },
    } = this.props;
    return (
      <div className='quotes-sidebar'>
        <div id='sidebar'>
          {this.showAutoAMSButton() && (
            <React.Fragment>
              <AMSButton
                handleclick={() => {
                  this.setState({
                    showConfirmationDialog: true,
                  });
                }}
              />
            </React.Fragment>
          )}

          {vehiclesList.length >= 1 && !isEmpty(vehiclesList[0].own_lease) && (
            <React.Fragment>
              <img src={CarButtonIcon} alt='vehicle' className='img-fluid vehicle-icon'></img>
              <ul>
                <li className='vehicle-li'>
                  Vehicles
                  {this.showEditIcon() && (
                    <div
                      style={{ display: 'inline' }}
                      onClick={this.onEditAction.bind(this, 'vehicle')}
                    >
                      <Tooltip title='Edit' arrow placement='top'>
                        <EditIcon className='edit-icon' />
                      </Tooltip>
                    </div>
                  )}
                  {map(
                    vehiclesList,
                    vehicle =>
                      vehicle.vehicleAdded && (
                        <React.Fragment key={vehicle.vuid}>
                          <span className='d-block lbl-span'>
                            {vehicle.year} {vehicle.make} {vehicle.model}
                          </span>
                          <span className='d-block ans-span'>{get(vehicle, 'own_lease', '-')}</span>
                        </React.Fragment>
                      )
                  )}
                  {this.showAddAnotherVehicleButton(this.props.vehicle, quotesLoading) && (
                    <span
                      className='d-block addvehicle-span'
                      onClick={this.addAnotherVehicleAction}
                    >
                      <AddIcon className='add-icon-border mr-3' />
                      add another vehicle
                    </span>
                  )}
                </li>
              </ul>
              <hr></hr>
            </React.Fragment>
          )}

          {!isEmpty(injury_limits) && completed_steps.quotes && (
            <React.Fragment>
              <img alt='coverage' className='img-fluid vehicle-icon' src={QuotesButtonIcon} />
              <ul>
                <li className='vehicle-li'>
                  Coverages
                  {this.showEditIcon() && (
                    <div
                      style={{ display: 'inline' }}
                      onClick={this.onEditAction.bind(this, 'details')}
                    >
                      <Tooltip title='Edit' arrow placement='top'>
                        <EditIcon className='edit-icon' />
                      </Tooltip>
                    </div>
                  )}
                  <span className='d-block ans-span'>Bodily Injury:</span>
                  <span className='d-block lbl-span'>{injury_limits}</span>
                  <span className='d-block ans-span'>Property Damage:</span>
                  <span className='d-block lbl-span'>
                    {isNumberCheck.test(property_damage)
                      ? formatNumberToCurrency(property_damage)
                      : property_damage}
                  </span>
                  <span className='d-block ans-span'>Uninsured/Underinsured Motorist:</span>
                  <span className='d-block lbl-span'>{motorist}</span>
                  <span className='d-block ans-span'>Medical Payments:</span>
                  <span className='d-block lbl-span'>
                    {isNumberCheck.test(medical_payments)
                      ? !!medical_payments
                        ? formatNumberToCurrency(medical_payments)
                        : DOLLAR_ZERO
                      : medical_payments}
                  </span>
                </li>
              </ul>
              <hr></hr>
            </React.Fragment>
          )}

          {driversList.length > 0 && (
            <React.Fragment>
              <img alt='Person' className='img-fluid vehicle-icon' src={PersonButtonIcon} />
              <ul>
                <li className='vehicle-li'>
                  Drivers
                  {this.showEditIcon() && (
                    <div
                      style={{ display: 'inline' }}
                      onClick={this.onEditAction.bind(this, 'driver')}
                    >
                      <Tooltip title='Edit' arrow placement='top'>
                        <EditIcon className='edit-icon' />
                      </Tooltip>
                    </div>
                  )}
                  {map(driversList, driver => (
                    <React.Fragment key={driver.duid}>
                      <span className='d-block lbl-span driver-name'>
                        {driver.first_name} {driver.last_name}
                      </span>
                      <span className='d-block ans-span'>
                        {startCase(driver.gender)}, {driver.marital_status}
                      </span>
                    </React.Fragment>
                  ))}
                  {this.showAddAnotherDriverButton() && (
                    <span className='d-block addvehicle-span' onClick={this.addAnotherDriverAction}>
                      <AddIcon className='add-icon-border mr-2' />
                      add another driver
                    </span>
                  )}
                </li>
              </ul>
              <hr></hr>
            </React.Fragment>
          )}

          {!isEmpty(address.state) && (
            <React.Fragment>
              <img alt='address' className='img-fluid vehicle-icon' src={HomeButtonIcon} />
              <ul>
                <li className='vehicle-li'>
                  Address
                  {this.showEditIcon() && (
                    <div
                      style={{ display: 'inline' }}
                      onClick={this.onEditAction.bind(this, 'applicant')}
                    >
                      <Tooltip title='Edit' arrow placement='top'>
                        <EditIcon className='edit-icon' />
                      </Tooltip>
                    </div>
                  )}
                  <span className='d-block lbl-span'>{address.street}</span>
                  <span className='d-block lbl-span'>
                    {address.city} {address.state} {address.zip}
                  </span>
                  <span className='d-block lbl-span mt-1 mb-1' x-ms-format-detection='none'>
                    {formatPhoneNumberWithBrackets(personal_details.phone_number)}
                  </span>
                  <span className='d-block ans-span email'>{personal_details.email}</span>{' '}
                </li>
              </ul>
            </React.Fragment>
          )}
        </div>

        <ConfirmationModal
          contentValue=''
          open={this.state.vehicleDetailsMissingDialog}
          closehandler={this.vehicleDetailsModalCloseHandler}
          heading={'Unsaved Changes'}
          message={UNSAVED_VEHICLE_MESSAGE}
        />

        {quoteList.length > 0 && openAMSModal && !quotesLoading && (
          <AMSDetailsModal
            open={openAMSModal}
            type={INSURANCE_PRODUCTS[1]}
            quoteList={quoteList}
            closehandler={this.AMSModalCloseHandler}
            isQuote={true}
          />
        )}

        {this.getAutoAMSSuccessModal()}

        {this.getAutoConfirmationAMSModal()}
      </div>
    );
  }
}

const mapStateToProps = ({
  vehicle,
  driver,
  applicant,
  insurance,
  quote,
  common,
}: AppComponents.EditSidebarStore) => ({ vehicle, driver, applicant, insurance, quote, common });

const mapDispatchToProps = (dispatch: any): AppComponents.EditSidebarDispatch =>
  bindActionCreators(
    {
      initVehicle,
      deleteVehicleDetails,
      driverInit,
      vehicleSelect,
      driverSelectedForEdit,
      setAMSDetailsSuccess,
      setQuotesLoaded,
      setEditVehicleFlag,
      showAMSModal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditSidebar);
