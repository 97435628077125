/* istanbul ignore file */
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from '../constants';
import { PARTNER_PRODUCT_MAPPING } from '../../../constants';
import { appointmentsLoaderShow, errorHandler, setAppointments } from '../actions';
import axiosRequest from '../../api';

const commonActions: any = actions;
function* fetchAppointmentDetails(data: any): any {
  try {
    const { requestId, code } = data.payload;
    yield put(appointmentsLoaderShow(true));
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const appointmentDetails = yield axiosRequest(
      'GET',
      `/partners/appointments/${requestId}/${code}`,
      isProviderOneLogin,
      {}
    );
    const appointments: any = {
      HOME: {},
      QUAKE: {},
      HOMEWARRANTY: {},
      CYBERSECURITY: {},
      FLOOD: {},
      AUTO: {},
      PET: {},
      LIFE: {},
      UMBRELLA: {},
    };
    Object.keys(appointments).forEach(key => {
      if (!appointmentDetails?.data?.appointments[PARTNER_PRODUCT_MAPPING[key] || key]) {
        delete appointments[key];
      }
    });
    let producerDetails = null;
    if (appointmentDetails && appointmentDetails?.data) {
      const {
        abbreviation,
        logo,
        producerEmail,
        producerPhone,
        producerLicensedStates,
        agencyName,
        producerCode,
        availableLobs,
      } = appointmentDetails?.data;
      producerDetails = {
        abbreviation: abbreviation,
        logo: logo,
        producerEmail: producerEmail,
        producerPhone: producerPhone,
        producerLicensedStates: producerLicensedStates,
        agencyName: agencyName,
        producerCode: producerCode,
        availableLobs: availableLobs,
      };
    }
    yield put(setAppointments({ appointments, producerDetails }));
    yield put(appointmentsLoaderShow(false));
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(appointmentsLoaderShow(false));
}

export function* getAppointmentsWatcher() {
  yield takeEvery(commonActions.PARTNER_REQUEST_APPOINTMENTS, fetchAppointmentDetails);
}

export default function* rootSaga() {
  yield all([fork(getAppointmentsWatcher)]);
}
